import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import throttle from 'lodash/throttle';
import { UPDATE } from '../../../constants/actionTypes';
import { getMutationQueryString } from '../../../queries/project';

const SelectableStage = ({
  children,
  className,
  dataType,
  onChange,
  project,
  recordId,
  stagesField,
  disableEditing,
  value,
}: any) => {
  const updateQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(UPDATE, dataType.name, dataType.fields, {
        id: true,
        uuid: true,
        [stagesField.apiName]: true,
      })}
    `,
    [dataType.name, dataType.fields, stagesField.apiName],
  );

  const queryOptionsObj = useMemo(
    () => ({
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [project.name],
  );

  const [updateDataItem] = useMutation(updateQueryString, queryOptionsObj);
  const throttledUpdateDataItem = useMemo(
    () => throttle(updateDataItem, 500),
    [updateDataItem],
  );

  const onClick = useCallback(() => {
    if (disableEditing) {
      return null;
    }

    throttledUpdateDataItem({
      variables: {
        id: recordId,
        [stagesField.apiName]: value,
      },
    });
    onChange(value);
  }, [
    onChange,
    recordId,
    stagesField.apiName,
    throttledUpdateDataItem,
    value,
    disableEditing,
  ]);

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

export default SelectableStage;
