import createReactComponent from '../createReactComponent';
export default createReactComponent('layout-grid-add', 'IconLayoutGridAdd', [
  [
    'path',
    {
      d: 'M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M14 17h6m-3 -3v6', key: 'svg-3' }],
]);
