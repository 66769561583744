import { useCallback, useMemo, useState } from 'react';
import SafeStorage from '../SafeStorage';

export type LocalStorageBoolean = [
  boolean,
  (value: boolean) => void,
  () => void,
];

const useBoolLocalStorageState = (
  localStorageKey: string,
  defaultValue: boolean,
): LocalStorageBoolean => {
  const safeStorage = useMemo(() => new SafeStorage(), []);
  const [value, setValue] = useState(
    safeStorage.getBoolean(localStorageKey, defaultValue),
  );

  const onChangeValue = useCallback(
    (nextValue: boolean) => {
      safeStorage.set(localStorageKey, nextValue);
      setValue(nextValue);
      return nextValue;
    },
    [localStorageKey, safeStorage],
  );

  const onClearValue = useCallback(() => {
    safeStorage.remove(localStorageKey);
    setValue(defaultValue);
  }, [defaultValue, localStorageKey, safeStorage]);

  return [value, onChangeValue, onClearValue];
};

export default useBoolLocalStorageState;
