import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-search',
  'IconDeviceDesktopSearch',
  [
    [
      'path',
      {
        d: 'M11.5 16h-7.5a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v6.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h4', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-3' }],
    ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-4' }],
  ],
);
