import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-sublime-text',
  'IconBrandSublimeText',
  [
    ['path', { d: 'M19 8l-14 4.5v-5.5l14 -4.5z', key: 'svg-0' }],
    ['path', { d: 'M19 17l-14 4.5v-5.5l14 -4.5z', key: 'svg-1' }],
    ['path', { d: 'M19 11.5l-14 -4.5', key: 'svg-2' }],
    ['path', { d: 'M5 12.5l14 4.5', key: 'svg-3' }],
  ],
);
