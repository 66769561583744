import createReactComponent from '../createReactComponent';
export default createReactComponent('device-watch', 'IconDeviceWatch', [
  [
    'path',
    {
      d: 'M6 9a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-6a3 3 0 0 1 -3 -3v-6z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 18v3h6v-3', key: 'svg-1' }],
  ['path', { d: 'M9 6v-3h6v3', key: 'svg-2' }],
]);
