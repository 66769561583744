import classNames from 'classnames';
import BuildModeMarkdownHelp from '../editor/BuildModeMarkdownHelp';
import BuildModeLabel from './BuildModeLabel';

type BuildModeInputProps = {
  children: JSX.Element;
  className?: string;
  inline?: boolean;
  label: string | JSX.Element;
  markdown?: boolean;
};

const BuildModeInput = ({
  children,
  className,
  inline = false,
  label,
  markdown = false,
}: BuildModeInputProps) => (
  <div
    className={classNames(
      'flex w-full',
      { 'flex-col space-y-2': !inline, 'items-center space-x-2': inline },
      className,
    )}
  >
    <div className="flex items-center justify-between">
      <BuildModeLabel
        className={classNames('flex-none truncate', { 'w-20': inline })}
      >
        {label}
      </BuildModeLabel>
      {markdown && <BuildModeMarkdownHelp />}
    </div>
    <div className="w-full flex-grow overflow-hidden">{children}</div>
  </div>
);

export default BuildModeInput;
