import createReactComponent from '../createReactComponent';
export default createReactComponent('square-number-4', 'IconSquareNumber4', [
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 8v3a1 1 0 0 0 1 1h3', key: 'svg-1' }],
  ['path', { d: 'M14 8v8', key: 'svg-2' }],
]);
