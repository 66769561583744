import createReactComponent from '../createReactComponent';
export default createReactComponent('logic-or', 'IconLogicOr', [
  ['path', { d: 'M22 12h-6', key: 'svg-0' }],
  ['path', { d: 'M2 9h7', key: 'svg-1' }],
  ['path', { d: 'M2 15h7', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M8 5c10.667 2.1 10.667 12.6 0 14c1.806 -4.667 1.806 -9.333 0 -14z',
      key: 'svg-3',
    },
  ],
]);
