import React from 'react';
import FormField from './FormField';

const PEM_PRIVATE_KEY_PLACEHOLDER = `-----BEGIN RSA PRIVATE KEY-----
...
-----END RSA PRIVATE KEY-----`;

const PrivateKeyFormField = ({ name, onChange, ...rest }: any) => (
  <FormField
    inputType="text-area"
    name={name || 'private-key'}
    onChange={({ target: { value } }: any) => onChange(value)}
    placeholder={PEM_PRIVATE_KEY_PLACEHOLDER}
    type="text"
    {...rest}
  />
);

export default PrivateKeyFormField;
