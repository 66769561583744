import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'building-fortress',
  'IconBuildingFortress',
  [
    [
      'path',
      {
        d: 'M7 21h1a1 1 0 0 0 1 -1v-1h0a3 3 0 0 1 6 0m3 2h1a1 1 0 0 0 1 -1v-15l-3 -2l-3 2v6h-4v-6l-3 -2l-3 2v15a1 1 0 0 0 1 1h2m8 -2v1a1 1 0 0 0 1 1h2',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 7h0v.01', key: 'svg-1' }],
    ['path', { d: 'M7 10h0v.01', key: 'svg-2' }],
    ['path', { d: 'M7 13h0v.01', key: 'svg-3' }],
    ['path', { d: 'M17 7h0v.01', key: 'svg-4' }],
    ['path', { d: 'M17 10h0v.01', key: 'svg-5' }],
    ['path', { d: 'M17 13h0v.01', key: 'svg-6' }],
  ],
);
