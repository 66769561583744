import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'message-circle-search',
  'IconMessageCircleSearch',
  [
    [
      'path',
      {
        d: 'M11.303 19.955a9.818 9.818 0 0 1 -3.603 -.955l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c1.73 1.476 2.665 3.435 2.76 5.433',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
    ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-2' }],
  ],
);
