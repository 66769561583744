import { IconDotsVertical } from '@tabler/icons-react';
import classNames from 'classnames';
import { Popover } from '@noloco/components';
import { CollectionLayout } from '../../../constants/collectionLayouts';
import useIsTable from '../../../utils/hooks/useIsTable';

type MobileActionButtonsWrapperProps = {
  children: any;
  layout: CollectionLayout;
};

const MobileCollectionActionButtonsWrapper = ({
  children,
  layout,
}: MobileActionButtonsWrapperProps): JSX.Element => {
  const isTable = useIsTable(layout);

  return (
    <Popover
      placement="bottom-end"
      content={<div className="flex w-48 flex-col space-y-1">{children}</div>}
    >
      <button
        className={classNames(
          'flex w-8 items-center justify-center bg-white text-gray-800 dark:bg-gray-800 dark:text-gray-100',
          {
            'h-full border-l': isTable,
            'h-8 rounded-lg shadow-lg': !isTable,
          },
        )}
      >
        <IconDotsVertical size={16} />
      </button>
    </Popover>
  );
};

export default MobileCollectionActionButtonsWrapper;
