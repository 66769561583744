import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { SwitchButton } from '@noloco/components/src';
import { LG } from '@noloco/components/src/constants/tShirtSizes';
import StringPropEditor from '@noloco/ui/src/components/canvas/StringPropEditor';
import RecordTitleEditor from '@noloco/ui/src/components/editor/customerEditors/RecordTitleEditor';
import { UpdateProjectCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { HEADER } from '../../constants/buildMode';
import { COLLECTION, PAGE, RECORD } from '../../constants/elements';
import { DataType } from '../../models/DataTypes';
import { ElementPath } from '../../models/Element';
import { Project } from '../../models/Project';
import { getText } from '../../utils/lang';
import BuildModeImageEditor from './BuildModeImageEditor';
import BuildModeInput from './BuildModeInput';
import BuildModeSection from './BuildModeSection';
import BuildModeSwitchSection from './BuildModeSwitchSection';

type BuildModeHeaderProps = {
  dataType: DataType;
  debouncedUpdateProperty: UpdateProjectCallback;
  elementPath: ElementPath;
  elementType: string;
  project: Project;
  props: Record<string, any>;
  section?: any;
  showBreadcrumbs?: boolean;
  showImage?: boolean;
  updateProperty: UpdateProjectCallback;
};

const LANG_KEY = 'elements.VIEW';

const BuildModeHeader = ({
  dataType,
  debouncedUpdateProperty,
  elementPath,
  elementType,
  project,
  props,
  section,
  showBreadcrumbs = true,
  showImage = true,
  updateProperty,
}: BuildModeHeaderProps) => {
  const {
    title,
    subtitle,
    coverPhoto,
    image,
    hideBreadcrumbs = false,
    headerWidth,
  } = props || {};

  const headerWidthOptions = useMemo(
    () => [
      {
        label: getText('core.sections.editor.width.options.default'),
        value: undefined,
      },
      ...[9, LG, 12].map((value) => ({
        label: getText('core.sections.editor.width.options', value),
        value,
      })),
    ],
    [],
  );

  const coverPhotoProp = useMemo(
    () => (elementType === RECORD ? image : coverPhoto),
    [elementType, image, coverPhoto],
  );

  const coverPhotoHidden = useMemo(
    () => !coverPhotoProp || get(coverPhotoProp, 'hidden', false),
    [coverPhotoProp],
  );

  const imageUpdatePath = useMemo(
    () => (elementType === RECORD ? 'image' : 'coverPhoto'),
    [elementType],
  );

  const updateImageProps = useCallback(
    (path, value) => updateProperty([imageUpdatePath, ...path], value),
    [updateProperty, imageUpdatePath],
  );

  const debouncedUpdateImageProps = useCallback(
    (path, value) => debouncedUpdateProperty([imageUpdatePath, ...path], value),
    [debouncedUpdateProperty, imageUpdatePath],
  );

  const hideImage = useCallback(
    (value) => updateProperty([imageUpdatePath, 'hidden'], value),
    [updateProperty, imageUpdatePath],
  );

  const updateHeaderWidth = useCallback(
    (value) => updateProperty(['headerWidth'], value),
    [updateProperty],
  );

  return (
    <BuildModeSection
      className="border-t p-2"
      id={HEADER}
      sticky={true}
      title={getText('rightSidebar.editor.header')}
    >
      <div className="mb-2 p-2 text-sm">
        <div className="flex flex-col space-y-2 text-gray-400">
          <label className="text-xs">{getText(LANG_KEY, 'header.title')}</label>
          {elementType === RECORD &&
          (!section || (section && section.type !== COLLECTION)) ? (
            <RecordTitleEditor
              dataType={dataType}
              value={title}
              onChange={(option: any) => updateProperty(['title'], option)}
            />
          ) : (
            <StringPropEditor
              // @ts-expect-error TS(2322): Type '{ contained: boolean; project: any; onChange... Remove this comment to see the full error message
              contained={true}
              elementPath={elementPath}
              onChange={(value: any) =>
                debouncedUpdateProperty(['title'], value)
              }
              project={project}
              value={title}
            />
          )}
        </div>
        <div className="mt-4 flex flex-col space-y-2 text-gray-400">
          <BuildModeInput
            markdown={true}
            label={getText(LANG_KEY, 'header.subtitle')}
          >
            <StringPropEditor
              // @ts-expect-error TS(2322): Type '{ contained: boolean; project: any; onChange... Remove this comment to see the full error message
              contained={true}
              elementPath={elementPath}
              multiLine={true}
              onChange={(value: any) =>
                debouncedUpdateProperty(['subtitle'], value)
              }
              project={project}
              value={subtitle}
            />
          </BuildModeInput>
        </div>
        {showBreadcrumbs && (
          <BuildModeSwitchSection
            className="my-2"
            label={getText(LANG_KEY, 'header.showBreadcrumbs')}
            value={!hideBreadcrumbs}
            onChange={(value: boolean) =>
              updateProperty(['hideBreadcrumbs'], !value)
            }
          />
        )}
        {(elementType === RECORD || elementType === PAGE) && (
          <div className="mt-4 flex flex-col justify-center">
            <BuildModeInput
              inline={true}
              label={getText(LANG_KEY, 'header.headerWidth')}
            >
              <SwitchButton
                className="h-8 w-full rounded-lg"
                inverseColors={true}
                onChange={updateHeaderWidth}
                options={headerWidthOptions}
                value={headerWidth}
              />
            </BuildModeInput>
          </div>
        )}
        {showImage && (
          <div className="mt-4 flex flex-col">
            <BuildModeImageEditor
              contained={true}
              dataType={dataType}
              debouncedUpdateProperty={debouncedUpdateImageProps}
              elementPath={elementPath}
              elementProps={coverPhotoProp}
              hidden={coverPhotoHidden}
              hideImage={hideImage}
              label={getText(
                LANG_KEY,
                'header',
                elementType === RECORD ? 'image' : 'coverPhoto',
              )}
              project={project}
              updateProperty={updateImageProps}
            />
          </div>
        )}
      </div>
    </BuildModeSection>
  );
};

export default BuildModeHeader;
