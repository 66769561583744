import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-skype', 'IconBrandSkype', [
  [
    'path',
    {
      d: 'M12 3a9 9 0 0 1 8.603 11.65a4.5 4.5 0 0 1 -5.953 5.953a9 9 0 0 1 -11.253 -11.253a4.5 4.5 0 0 1 5.953 -5.954a8.987 8.987 0 0 1 2.65 -.396z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8 14.5c.5 2 2.358 2.5 4 2.5c2.905 0 4 -1.187 4 -2.5c0 -1.503 -1.927 -2.5 -4 -2.5s-4 -1 -4 -2.5c0 -1.313 1.095 -2.5 4 -2.5c1.642 0 3.5 .5 4 2.5',
      key: 'svg-1',
    },
  ],
]);
