import createReactComponent from '../createReactComponent';
export default createReactComponent('thumb-up', 'IconThumbUp', [
  [
    'path',
    {
      d: 'M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3',
      key: 'svg-0',
    },
  ],
]);
