import createReactComponent from '../createReactComponent';
export default createReactComponent('table-import', 'IconTableImport', [
  [
    'path',
    {
      d: 'M12 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 10h18', key: 'svg-1' }],
  ['path', { d: 'M10 3v18', key: 'svg-2' }],
  ['path', { d: 'M19 22v-6', key: 'svg-3' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-4' }],
]);
