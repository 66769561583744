import React, { useMemo } from 'react';
import classNames from 'classnames';
import { startCase } from 'lodash';
import { Loader, Tooltip } from '@noloco/components';
import { Button } from '@noloco/components';
import { ButtonType } from '@noloco/components/src/components/button/buttonTypes';
import { ShirtSize } from '@noloco/components/src/constants/tShirtSizes';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import useValidFeaturePlan from '@noloco/ui/src/utils/hooks/useValidFeaturePlan';
import MarkdownText from '../../../components/MarkdownText';
import { SCAN_BARCODE } from '../../../constants/actionTypes';
import { darkModeColors } from '../../../constants/darkModeColors';
import { BARCODE_SCAN } from '../../../constants/features';
import { ActionButton } from '../../../models/Element';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getText } from '../../../utils/lang';
import Icon from '../../Icon';

type ActionButtonButtonProps = {
  actionButton: ActionButton;
  buttonType?: ButtonType;
  disabled: boolean;
  editorMode: boolean;
  index: number;
  isLoading: boolean;
  onClick: (e: MouseEvent) => void;
  size?: ShirtSize;
};

const ActionButtonButton = ({
  actionButton,
  buttonType,
  disabled,
  editorMode,
  index,
  isLoading,
  onClick,
  size,
  ...rest
}: ActionButtonButtonProps) => {
  const [isDarkModeEnabled] = useDarkMode();
  const isBarcodeScanEnabled = useIsFeatureEnabled(BARCODE_SCAN);
  const lowestPlan = useValidFeaturePlan(BARCODE_SCAN);
  const lowestPlanName = getText('billing.plans', lowestPlan!, 'name');

  const hasScanBarcodeAction = useMemo(
    () =>
      actionButton.actions?.some((action) => action.type === SCAN_BARCODE) ??
      false,
    [actionButton.actions],
  );

  const barcodeScannerNotAvailable = useMemo(
    () => !isBarcodeScanEnabled && hasScanBarcodeAction,
    [isBarcodeScanEnabled, hasScanBarcodeAction],
  );

  const buttonText = useMemo(() => {
    if (actionButton.buttonText) {
      return actionButton.buttonText;
    }

    if (!actionButton.icon?.name) {
      return getText(
        { index: index + 1 },
        'elements.VIEW.actionButtons.defaultButtonText',
      );
    }
  }, [actionButton.buttonText, actionButton.icon, index]);

  return (
    <Tooltip
      delayHide={300}
      disabled={
        barcodeScannerNotAvailable
          ? false
          : !editorMode && !actionButton.tooltip
      }
      content={
        <span
          className={classNames({
            [darkModeColors.text.primary]: isDarkModeEnabled,
          })}
        >
          {barcodeScannerNotAvailable &&
          !editorMode &&
          !actionButton.tooltip ? (
            getText(
              {
                plan: lowestPlanName,
                action: startCase(BARCODE_SCAN),
              },
              'elements.VIEW.actionButtons.featureNotAvailableInPlan',
            )
          ) : editorMode || !actionButton.tooltip ? (
            getText('elements.VIEW.actionButtons.tooltip')
          ) : (
            <MarkdownText small={true}>{actionButton.tooltip}</MarkdownText>
          )}
        </span>
      }
    >
      <div>
        <Button
          {...rest}
          className={classNames(
            'action-button flex items-center space-x-2 whitespace-nowrap disabled:cursor-not-allowed disabled:opacity-50',
            `action-button-${actionButton.id}`,
          )}
          compactBadge={true}
          data-testid="action-button"
          disabled={editorMode || disabled || barcodeScannerNotAvailable}
          key={actionButton.id}
          type={buttonType}
          variant={actionButton.appearance || SECONDARY}
          onClick={onClick}
          size={size}
        >
          {isLoading && <Loader size="xs" />}
          {!isLoading && (
            <>
              {actionButton.icon && actionButton.icon.name && (
                <Icon icon={actionButton.icon} className="h-4 w-4 opacity-75" />
              )}
              {buttonText && <span>{buttonText}</span>}
            </>
          )}
        </Button>
      </div>
    </Tooltip>
  );
};

export default ActionButtonButton;
