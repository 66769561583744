import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'keyframe-align-horizontal',
  'IconKeyframeAlignHorizontal',
  [
    [
      'path',
      {
        d: 'M12.816 16.58c-.207 .267 -.504 .42 -.816 .42c-.312 0 -.61 -.153 -.816 -.42l-2.908 -3.748a1.39 1.39 0 0 1 0 -1.664l2.908 -3.748c.207 -.267 .504 -.42 .816 -.42c.312 0 .61 .153 .816 .42l2.908 3.748a1.39 1.39 0 0 1 0 1.664l-2.908 3.748z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 12h2', key: 'svg-1' }],
    ['path', { d: 'M19 12h2', key: 'svg-2' }],
  ],
);
