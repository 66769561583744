import { countries } from 'countries-list';
import { Country } from 'react-phone-number-input/input';

export type CountryCode = keyof typeof countries;

export type PhoneNumber = {
  country: Country;
  extension?: string;
  number: string;
};
