import { KEY_MAP } from '../../constants/elementPropTypeTypes';
import { Project } from '../Project';
import ComboType from './ComboPropType';

class KeyMapPropType extends ComboType {
  constructor(
    shape: Record<any, any>,
    resolver?: (
      props: Record<any, any>,
      project: Project,
      scope: Record<any, any>,
      editorMode: boolean,
    ) => any,
    propMapper?: (props: any) => any,
    display?: () => boolean,
  ) {
    super(shape, resolver, propMapper, display);
    this.shape = shape;
    this.type = KEY_MAP;
  }
}

export default KeyMapPropType;
