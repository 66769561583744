import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-mobile-rotated',
  'IconDeviceMobileRotated',
  [
    [
      'path',
      {
        d: 'M3 6m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M20 11v2', key: 'svg-1' }],
    ['path', { d: 'M7 12h-.01', key: 'svg-2' }],
  ],
);
