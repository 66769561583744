import React, { useCallback, useMemo } from 'react';
import { IconFileImport, IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Button, Popover, Tooltip } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import NolocoIcon from '@noloco/core/src/components/NolocoIcon';
import {
  AIRTABLE,
  API,
  CSV,
  GOOGLE_SHEETS,
  HUBSPOT,
  INTERNAL,
  MYSQL,
  POSTGRES,
  SALESFORCE,
  SMART_SUITE,
  XANO,
} from '@noloco/core/src/constants/dataSources';
import { DATA_SOURCES } from '@noloco/core/src/constants/features';
import { trackNewDataSourceClicked } from '@noloco/core/src/utils/analytics';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { draftDataTypesSelector } from '../../../../../core/src/selectors/projectSelectors';
import { HUBSPOT_INTEGRATION } from '../../../../../core/src/utils/hooks/useHasFeatureFlag';
import airtableIcon from '../../img/airtable-icon.svg';
import apiIcon from '../../img/cloud-code.png';
import googleSheetsLogo from '../../img/google-sheets-logo.png';
import hubSpotLogo from '../../img/hubspot-logo.png';
import mysqlLogo from '../../img/mysql-square-logo.png';
import postgresLogo from '../../img/postgres-logo.png';
import salesforceLogo from '../../img/salesforce-logo.png';
import smartsuiteLogo from '../../img/smartsuite-logo.png';
import xanoLogo from '../../img/xano-logo.png';
import useIsFeatureEnabled from '../../utils/hooks/useIsFeatureEnabled';
import FeatureLockedLink from '../FeatureLockedLink';

const dataSourceIcons = {
  [AIRTABLE]: airtableIcon,
  [API]: apiIcon,
  [POSTGRES]: postgresLogo,
  [GOOGLE_SHEETS]: googleSheetsLogo,
  [HUBSPOT]: hubSpotLogo,
  [MYSQL]: mysqlLogo,
  [SALESFORCE]: salesforceLogo,
  [SMART_SUITE]: smartsuiteLogo,
  [XANO]: xanoLogo,
};

const LANG_KEY = 'leftSidebar.data';

const NewDataSourcePopover = () => {
  const {
    query: { __new },
    replaceQueryParams,
    location,
  } = useRouter();

  const onOpenNewDataSourceChange = useCallback(
    (nextIsOpen) => {
      replaceQueryParams({ __new: nextIsOpen ? 'true' : undefined });
    },
    [replaceQueryParams],
  );

  const handleOpenNewDataSourceClicked = useCallback(
    (dataSource) => (event: any) => {
      event.stopPropagation();
      trackNewDataSourceClicked(dataSource);
    },
    [],
  );
  const isHubSpotEnabled = useIsFeatureEnabled(HUBSPOT_INTEGRATION);
  const existingDataTypes = useSelector(draftDataTypesSelector);
  const hasHubSpotDataSource = useMemo(
    () =>
      isHubSpotEnabled &&
      !!existingDataTypes?.some((dt) => dt.source.type === HUBSPOT),
    [existingDataTypes, isHubSpotEnabled],
  );

  return (
    <Popover
      closeOnOutsideClick={true}
      onOpenChange={onOpenNewDataSourceChange}
      isOpen={__new === 'true'}
      content={
        <div className="flex w-64 flex-col space-y-1 py-2 text-left text-xs">
          <Link
            to={`/_/data/internal/~new`}
            onClick={handleOpenNewDataSourceClicked(INTERNAL)}
            className="flex items-center rounded-lg px-3 py-2 hover:bg-gray-100"
          >
            <div className="flex w-6 justify-center">
              <NolocoIcon className="w-auto" />
            </div>
            <span className="ml-3">
              {getText(LANG_KEY, 'sources', INTERNAL)}
            </span>
          </Link>
          <Link
            to={`/_/data/internal/~import`}
            onClick={handleOpenNewDataSourceClicked(CSV)}
            className="flex h-8 items-center rounded-lg px-3 py-1 hover:bg-gray-100"
          >
            <div className="flex w-6 justify-center">
              <IconFileImport className="w-auto" />
            </div>
            <span className="ml-3">{getText(LANG_KEY, 'sources', CSV)}</span>
          </Link>
          <Link
            to={`/_/setup`}
            onClick={handleOpenNewDataSourceClicked(AIRTABLE)}
            className="flex h-8 items-center rounded-lg px-3 py-1 hover:bg-gray-100"
          >
            <div className="flex w-6 justify-center">
              <img src={airtableIcon} alt="Airtable logo" />
            </div>
            <span className="ml-3">
              {getText(LANG_KEY, 'sources', AIRTABLE)}
            </span>
          </Link>
          <Link
            to={`/_/setup/google-sheets`}
            onClick={handleOpenNewDataSourceClicked(GOOGLE_SHEETS)}
            className="flex h-8 items-center rounded-lg px-3 py-1 hover:bg-gray-100"
          >
            <div className="flex w-6 justify-center">
              <img
                src={googleSheetsLogo}
                alt="Google sheets logo"
                className="px-1"
              />
            </div>
            <span className="ml-3">
              {getText(LANG_KEY, 'sources', GOOGLE_SHEETS)}
            </span>
          </Link>
          {isHubSpotEnabled && (
            <Tooltip
              content={getText(
                {
                  source: getText(LANG_KEY, 'sources', HUBSPOT),
                },
                LANG_KEY,
                'alreadyConnected',
              )}
              disabled={!hasHubSpotDataSource}
            >
              <Link
                to={
                  !hasHubSpotDataSource
                    ? '/_/setup/hubspot'
                    : `${location.pathname}${location.search}`
                }
                onClick={() => {
                  if (!hasHubSpotDataSource) {
                    handleOpenNewDataSourceClicked(HUBSPOT);
                  }
                }}
                className={classNames(
                  'flex h-8 items-center rounded-lg px-3 py-1 hover:bg-gray-100',
                  {
                    'cursor-not-allowed opacity-50': hasHubSpotDataSource,
                  },
                )}
              >
                <div className="flex w-6 justify-center">
                  <img src={hubSpotLogo} alt="HubSpot logo" />
                </div>
                <span className="ml-3">
                  {getText(LANG_KEY, 'sources', HUBSPOT)}
                </span>
                <Badge
                  className="whitespace-nowrap text-center"
                  color="pink"
                  ignoreDarkMode={true}
                  m={{ l: 'auto' }}
                  w={16}
                >
                  {getText(LANG_KEY, 'beta')}
                </Badge>
              </Link>
            </Tooltip>
          )}
          <Link
            to="/_/setup/smart-suite"
            onClick={handleOpenNewDataSourceClicked(SMART_SUITE)}
            className="flex h-8 items-center rounded-lg px-3 py-1 hover:bg-gray-100"
          >
            <div className="flex w-6 justify-center">
              <img
                src={dataSourceIcons[SMART_SUITE]}
                alt={getText(LANG_KEY, 'sources', SMART_SUITE)}
              />
            </div>
            <span className="mx-3">
              {getText(LANG_KEY, 'sources', SMART_SUITE)}
            </span>
          </Link>
          <Link
            to="/_/setup/xano"
            onClick={handleOpenNewDataSourceClicked(XANO)}
            className="flex h-8 items-center rounded-lg px-3 py-1 hover:bg-gray-100"
          >
            <div className="flex w-6 justify-center">
              <img src={xanoLogo} alt="Xano logo" />
            </div>
            <span className="mx-3">{getText(LANG_KEY, 'sources', XANO)}</span>
          </Link>
          <FeatureLockedLink
            feature={DATA_SOURCES}
            blockTrial={true}
            currentValue={MYSQL}
            tooltipPlacement="right"
            iconClassName="ml-auto text-gray-800"
            to="/_/setup/mysql"
            onClick={handleOpenNewDataSourceClicked(MYSQL)}
            className="flex h-8 items-center rounded-lg px-3 py-1 hover:bg-gray-100"
          >
            <div className="flex w-6 justify-center">
              <img src={mysqlLogo} alt="MySQL logo" />
            </div>
            <span className="mx-3">{getText(LANG_KEY, 'sources', MYSQL)}</span>
          </FeatureLockedLink>
          <FeatureLockedLink
            feature={DATA_SOURCES}
            blockTrial={true}
            currentValue={POSTGRES}
            tooltipPlacement="right"
            iconClassName="ml-auto text-gray-800"
            to="/_/setup/postgres"
            onClick={handleOpenNewDataSourceClicked(POSTGRES)}
            className="flex h-8 items-center rounded-lg px-3 py-1 hover:bg-gray-100"
          >
            <div className="flex w-6 justify-center">
              <img src={postgresLogo} alt="Postgres logo" />
            </div>
            <span className="mx-3">
              {getText(LANG_KEY, 'sources', POSTGRES)}
            </span>
          </FeatureLockedLink>
          <FeatureLockedLink
            feature={DATA_SOURCES}
            blockTrial={true}
            currentValue={API}
            tooltipPlacement="right"
            iconClassName="ml-auto text-gray-800"
            to="/_/setup/api"
            onClick={handleOpenNewDataSourceClicked(API)}
            className="flex h-8 items-center rounded-lg px-3 py-1 hover:bg-gray-100"
          >
            <div className="flex w-6 justify-center">
              <img src={apiIcon} alt="API icon" />
            </div>
            <span className="mx-3">{getText(LANG_KEY, 'sources', API)}</span>
          </FeatureLockedLink>
          {!isHubSpotEnabled && (
            <a
              key={HUBSPOT}
              className="flex h-8 cursor-pointer items-center rounded-lg px-3 py-1 hover:bg-gray-100"
              href="https://noloco.io/data/hubspot"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex w-6 justify-center">
                <img
                  src={dataSourceIcons[HUBSPOT]}
                  alt={getText(LANG_KEY, 'sources', HUBSPOT)}
                />
              </div>
              <span className="mx-3">
                {getText(LANG_KEY, 'sources', HUBSPOT)}
              </span>
              <Badge
                className="text-center"
                color="gray"
                ignoreDarkMode={true}
                m={{ l: 'auto' }}
                w={16}
              >
                {getText(LANG_KEY, 'soon')}
              </Badge>
            </a>
          )}
          <a
            key={SALESFORCE}
            className="flex h-8 cursor-pointer items-center rounded-lg px-3 py-1 hover:bg-gray-100"
            href="https://noloco.io/data/salesforce"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex w-6 justify-center">
              <img
                src={dataSourceIcons[SALESFORCE]}
                alt={getText(LANG_KEY, 'sources', SALESFORCE)}
              />
            </div>
            <span className="mx-3">
              {getText(LANG_KEY, 'sources', SALESFORCE)}
            </span>
            <Badge
              className="text-center"
              color="gray"
              ignoreDarkMode={true}
              m={{ l: 'auto' }}
              w={16}
            >
              {getText(LANG_KEY, 'planned')}
            </Badge>
          </a>
          <hr />
          <a
            className="cursor-pointer rounded-lg p-3 text-center text-gray-600 hover:bg-gray-100"
            key="other"
            href="https://feedback.noloco.co/+/jKhwPTsIU/LkG08e7GC"
            target="_blank"
            rel="noreferrer"
          >
            {getText(LANG_KEY, 'sources.other')}
          </a>
        </div>
      }
      placement="bottom"
      trigger="none"
      surface={LIGHT}
    >
      <Button
        tooltip={getText(LANG_KEY, 'locked')}
        className="mx-2 flex items-center whitespace-nowrap"
        onClick={() => onOpenNewDataSourceChange(true)}
        size="sm"
        type="text"
      >
        <IconPlus className="mr-2" size={16} />
        <span>{getText(LANG_KEY, 'new')}</span>
      </Button>
    </Popover>
  );
};

export default NewDataSourcePopover;
