export const PLAIN = 'PLAIN';
export const LINK = 'LINK';
export const PREVIEW_MODAL = 'PREVIEW_MODAL';
export const DOWNLOAD = 'DOWNLOAD';

const fileFieldElementTypes = [PLAIN, LINK, PREVIEW_MODAL, DOWNLOAD];

export type FileFieldElementType =
  | 'PLAIN'
  | 'LINK'
  | 'PREVIEW_MODAL'
  | 'DOWNLOAD';

export default fileFieldElementTypes;
