import createReactComponent from '../createReactComponent';
export default createReactComponent('device-imac-pin', 'IconDeviceImacPin', [
  [
    'path',
    {
      d: 'M12 17h-8a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v7.5',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-2' }],
  ['path', { d: 'M3 13h11', key: 'svg-3' }],
  ['path', { d: 'M8 21h4.5', key: 'svg-4' }],
  ['path', { d: 'M10 17l-.5 4', key: 'svg-5' }],
]);
