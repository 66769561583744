import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-badge-down-filled',
  'IconArrowBadgeDownFilled',
  [
    [
      'path',
      {
        d: 'M16.375 6.22l-4.375 3.498l-4.375 -3.5a1 1 0 0 0 -1.625 .782v6a1 1 0 0 0 .375 .78l5 4a1 1 0 0 0 1.25 0l5 -4a1 1 0 0 0 .375 -.78v-6a1 1 0 0 0 -1.625 -.78z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
  ],
);
