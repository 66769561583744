import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-mobile-dollar',
  'IconDeviceMobileDollar',
  [
    [
      'path',
      {
        d: 'M13 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M11 4h2', key: 'svg-1' }],
    ['path', { d: 'M12 17v.01', key: 'svg-2' }],
    [
      'path',
      {
        d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
        key: 'svg-3',
      },
    ],
    ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-4' }],
  ],
);
