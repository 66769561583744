import { useCallback } from 'react';
import { Modal } from '@noloco/components';
import MarkdownText from '../../../components/MarkdownText';
import { CREATE } from '../../../constants/actionTypes';
import { useClearFormFieldValuesOnNavigateAway } from '../../../utils/hooks/useClearFormFieldValuesOnNavigateAway';
import useFormFields from '../../../utils/hooks/useFormFields';
import { useFormSections } from '../../../utils/hooks/useFormSections';
import useRouter from '../../../utils/hooks/useRouter';
import useSubmitModalForm from '../../../utils/hooks/useSubmitNestedForm';
import useTrackAppPage, {
  PageTypes,
} from '../../../utils/hooks/useTrackAppPage';
import { getText } from '../../../utils/lang';
import { useFormRecordDeps, useOnSuccessRedirect } from '../../utils/newForm';
import FormSection from '../FormSection';
import { CARDS } from '../forms/AutoFormSection';
import { NewFormProps } from './NewForm';

type NewFormModalProps = Omit<NewFormProps, 'coverPhoto'>;
const NewFormModal = ({
  dataType,
  fields,
  project,
  rootPathname,
  scope,
  isPublicForm = false,
  onSave,
  subtitle,
  title,
  saveButtonText,
  successMessage,
  redirectOnSuccess,
  hideFormOnSuccess,
  disableFeatureCheck = false,
  neverAllowNewRecords = false,
}: NewFormModalProps) => {
  const { location, push } = useRouter();
  const formClassNameIdentifier = 'new-form-modal';
  const { submitModalForm } = useSubmitModalForm(`.${formClassNameIdentifier}`);

  const redirect = `${rootPathname}/${location.search}`;
  const onClose = useCallback(() => {
    push(redirect);
  }, [redirect, push]);

  useTrackAppPage(PageTypes.NEW);

  const formFields = useFormFields(fields, dataType, project);
  const formSections = useFormSections(fields);

  useClearFormFieldValuesOnNavigateAway(dataType);

  const recordDeps = useFormRecordDeps(onSave, project);

  const onSuccessRedirect = useOnSuccessRedirect(
    project,
    dataType,
    onSave,
    scope,
    rootPathname,
    isPublicForm,
  );

  return (
    <Modal
      onClose={onClose}
      canCancel={true}
      canConfirm={true}
      onCancel={onClose}
      onConfirm={submitModalForm}
      confirmText={saveButtonText ?? getText('save')}
      submitButton={true}
      closeOnOutsideClick={true}
      title={title}
    >
      {subtitle && (
        <div className="mb-6 w-full text-base">
          <MarkdownText disabledHeadings={false}>{subtitle}</MarkdownText>
        </div>
      )}
      <FormSection
        className={formClassNameIdentifier}
        dataType={dataType.name}
        fields={formFields}
        sectionFormat={CARDS}
        sections={formSections}
        type={CREATE}
        project={project}
        recordDeps={recordDeps}
        onSuccess={redirectOnSuccess ? onSuccessRedirect : null}
        successMessage={successMessage}
        errorMessage={{
          message: getText('core.COLLECTION.form.invalid'),
        }}
        hideFormOnSuccess={hideFormOnSuccess}
        disableFeatureCheck={disableFeatureCheck}
        neverAllowNewRecords={neverAllowNewRecords}
      />
    </Modal>
  );
};

export default NewFormModal;
