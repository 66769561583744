import createReactComponent from '../createReactComponent';
export default createReactComponent('chart-pie', 'IconChartPie', [
  [
    'path',
    {
      d: 'M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-6.8a2 2 0 0 1 -2 -2v-7a.9 .9 0 0 0 -1 -.8',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a1 1 0 0 1 -1 -1v-4.5', key: 'svg-1' },
  ],
]);
