import createReactComponent from '../createReactComponent';
export default createReactComponent('message-2-plus', 'IconMessage2Plus', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12.5 20.5l-.5 .5l-3 -3h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-3' }],
  ['path', { d: 'M19 16v6', key: 'svg-4' }],
]);
