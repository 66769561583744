import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circuit-switch-closed',
  'IconCircuitSwitchClosed',
  [
    ['path', { d: 'M2 12h2', key: 'svg-0' }],
    ['path', { d: 'M20 12h2', key: 'svg-1' }],
    ['path', { d: 'M6 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
    ['path', { d: 'M18 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
    ['path', { d: 'M8 12h8', key: 'svg-4' }],
  ],
);
