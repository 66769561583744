import createReactComponent from '../createReactComponent';
export default createReactComponent('user-down', 'IconUserDown', [
  ['path', { d: 'M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0', key: 'svg-0' }],
  [
    'path',
    { d: 'M6 21v-2a4 4 0 0 1 4 -4h4c.342 0 .674 .043 .99 .124', key: 'svg-1' },
  ],
  ['path', { d: 'M19 16v6', key: 'svg-2' }],
  ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-3' }],
]);
