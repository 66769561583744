import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'language-katakana',
  'IconLanguageKatakana',
  [
    [
      'path',
      { d: 'M5 5h6.586a1 1 0 0 1 .707 1.707l-1.293 1.293', key: 'svg-0' },
    ],
    ['path', { d: 'M8 8c0 1.5 .5 3 -2 5', key: 'svg-1' }],
    ['path', { d: 'M12 20l4 -9l4 9', key: 'svg-2' }],
    ['path', { d: 'M19.1 18h-6.2', key: 'svg-3' }],
  ],
);
