import { useCallback, useMemo } from 'react';
import { get } from 'lodash';
import { BuildModeEditorTabs, OPTIONS } from '../../constants/buildMode';
import useLocalStorageState from './useLocalStorageState';

const BUILD_MODE = 'noloco.buildMode';

const useEditorTabs = (
  tabNamePath: string,
  defaultTab: BuildModeEditorTabs = OPTIONS,
) => {
  const [editorTabs, setEditorTabs] = useLocalStorageState(BUILD_MODE, {});
  const editorTab = useMemo(
    () => get(editorTabs, tabNamePath, defaultTab),
    [defaultTab, editorTabs, tabNamePath],
  );
  const setEditorTab = useCallback(
    (tab) =>
      setEditorTabs({
        ...editorTabs,
        [tabNamePath]: tab,
      }),
    [setEditorTabs, editorTabs, tabNamePath],
  );

  return [editorTab, setEditorTab];
};

export default useEditorTabs;
