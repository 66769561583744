import { DebouncedFunc, ThrottleSettings } from 'lodash';
import first from 'lodash/first';
import identity from 'lodash/identity';
import throttle from 'lodash/throttle';

const throttleByFirstArg = <T extends (...args: Parameters<T>) => any>(
  func: T,
  waitMs?: number,
  options?: ThrottleSettings,
  formatKey: (v: any) => any = identity,
) => {
  const memory: Record<any, DebouncedFunc<T>> = {};

  return (...args: Parameters<T>) => {
    // use first argument as a key
    const firstArg = first(args);
    const key = formatKey(firstArg);

    if (memory[key]) {
      return memory[key](...args);
    }

    memory[key] = throttle(func, waitMs, options);
    return memory[key](...args);
  };
};

export default throttleByFirstArg;
