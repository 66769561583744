import createReactComponent from '../createReactComponent';
export default createReactComponent('broadcast-off', 'IconBroadcastOff', [
  [
    'path',
    {
      d: 'M18.364 19.364a9 9 0 0 0 -9.721 -14.717m-2.488 1.509a9 9 0 0 0 -.519 13.208',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M15.536 16.536a5 5 0 0 0 -3.536 -8.536m-3 1a5 5 0 0 0 -.535 7.536',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 12a1 1 0 1 0 1 1', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
