import React, { forwardRef, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import last from 'lodash/last';
import { cloneElement } from '@noloco/ui/src/utils/elements';
import {
  useAddSibling,
  useRemoveSelected,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { DIVIDER, LINK } from '../../../constants/elements';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import useRouter from '../../../utils/hooks/useRouter';
import { Page, getPagesConfig } from '../../../utils/pages';
import SidebarDivider from '../../SidebarDivider';
import { removeParentPageSettingsFromRemovedPage } from '../../editor/PageEditor';
import BuildModeNavItem from '../sidebar/BuildModeNavItem';

type BuildModeSidebarItemProps = {
  active: boolean;
  elementPath: ElementPath;
  hasChildPages?: boolean;
  hideIcon?: boolean;
  icon?: string;
  isDragging: boolean;
  isOver: boolean;
  page: Page;
  project: Project;
  sidebarExpanded?: boolean;
  to: string;
};

const BuildModeSidebarItem = forwardRef(
  (
    {
      active,
      elementPath,
      hasChildPages,
      hideIcon,
      icon,
      isDragging,
      isOver,
      page,
      project,
      sidebarExpanded = true,
      to,
    }: BuildModeSidebarItemProps,
    dragRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { push } = useRouter();
    const parentPage = get(page, 'props.parentPage', null);

    const [removePage] = useRemoveSelected(
      project,
      elementPath,
      // @ts-expect-error TS2345: Argument of type '(newPages: Element[], removedPage: Element) => Element[]' is not assignable to parameter of type '{  (value: T): T; (): undefined; }'.
      removeParentPageSettingsFromRemovedPage,
    );

    const handlePageRemove = useCallback(() => removePage(), [removePage]);

    const [addSibling] = useAddSibling(
      project,
      elementPath,
      get(page, 'props.isSubPage') ? [] : ['children'],
    );

    const pages = useMemo(
      () =>
        get(
          getPagesConfig(project.elements, project.settings),
          'projectPages',
          [],
        ).filter((page) => ![DIVIDER, LINK].includes(page.type)),
      [project],
    );
    const parentPageElement = useMemo(
      () =>
        parentPage ? pages.find((page) => page.id === parentPage) : undefined,
      [pages, parentPage],
    );

    const handlePageClone = useCallback(
      (pageElement?: Page) => {
        const pageIndex = last(elementPath) as number;
        const clonedPage = cloneElement(pageElement ?? page);
        const clonedParentPageId = get(clonedPage, 'props.parentPage');
        const pageRoute = get(page, 'props.routePath');
        const clonedPageRoute = get(clonedPage, 'props.routePath');
        const parentPageRoute = get(parentPageElement, 'props.routePath');

        addSibling(clonedPage, pageIndex + 1);

        setTimeout(() => {
          if (clonedParentPageId) {
            if (clonedParentPageId !== parentPage) {
              push(`/${pageRoute}/${clonedPageRoute}`);
            } else if (parentPageElement) {
              push(`/${parentPageRoute}/${clonedPageRoute}`);
            }
          } else {
            push(`/${clonedPageRoute}`);
          }
        }, 100);
      },
      [addSibling, elementPath, page, parentPage, parentPageElement, push],
    );

    if (page.type === DIVIDER) {
      return (
        <SidebarDivider
          elementPath={elementPath}
          handleDividerClone={handlePageClone}
          handleDividerRemove={handlePageRemove}
          page={page}
          project={project}
          ref={dragRef}
          sidebarExpanded={sidebarExpanded}
        />
      );
    }

    return (
      <BuildModeNavItem
        active={active}
        elementPath={elementPath}
        handlePageClone={handlePageClone}
        handlePageRemove={handlePageRemove}
        hasChildPages={hasChildPages}
        hideIcon={hideIcon}
        icon={icon}
        isDragging={isDragging}
        isOver={isOver}
        page={page}
        project={project}
        ref={dragRef}
        sidebarExpanded={sidebarExpanded}
        to={to}
      />
    );
  },
);

export default BuildModeSidebarItem;
