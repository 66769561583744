import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'folder-exclamation',
  'IconFolderExclamation',
  [
    [
      'path',
      {
        d: 'M15 19h-10a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v3.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 16v3', key: 'svg-1' }],
    ['path', { d: 'M19 22v.01', key: 'svg-2' }],
  ],
);
