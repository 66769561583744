import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { useSelector } from 'react-redux';
import { queryStateSelector } from '../../selectors/queriesSelectors';
import { removeDataItemFromCollectionCache } from '../apolloCache';

const useRemoveRecordFromCollection = (collectionId: any) => {
  const queryObject = useSelector(queryStateSelector(collectionId));
  const apolloClient = useApolloClient();

  const handleRemove = useCallback(
    (recordId: any) => {
      if (queryObject) {
        const { variables, dataType, query, valuePath } = queryObject;
        removeDataItemFromCollectionCache(
          recordId,
          apolloClient,
          gql`
            ${query}
          `,
          dataType,
          variables,
          { collectionPathInput: valuePath.replace(/\.$/, '') },
        );
      } else {
        console.error(
          `Query: ${collectionId} does not exist. Record ${recordId} cannot be removed`,
        );
      }
    },
    [apolloClient, collectionId, queryObject],
  );

  return handleRemove;
};

export default useRemoveRecordFromCollection;
