import React, { forwardRef } from 'react';
import { Button as BaseButton } from '@noloco/components';
import { ShirtSize } from '@noloco/components/src/constants/tShirtSizes';
import { CUSTOM, Variant } from '@noloco/components/src/constants/variants';

export type ButtonProps = {
  buttonType?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  'data-testid'?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: ShirtSize;
  style?: any;
  submitFormOnClick?: boolean;
  type?: any;
  variant?: Variant;
};

const Button = forwardRef<any, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      type,
      buttonType,
      'data-testid': dataTestId,
      // @ts-expect-error TS(2339): Property 'is' does not exist on type 'Props'.
      is,
      // @ts-expect-error TS(2339): Property 'href' does not exist on type 'Props'.
      href,
      // @ts-expect-error TS(2339): Property 'target' does not exist on type 'Props'.
      target,
      // @ts-expect-error TS(2339): Property 'rel' does not exist on type 'Props'.
      rel,
      size,
      style,
      submitFormOnClick,
      variant,
    },
    ref,
  ) => (
    <BaseButton
      ref={ref}
      className={className}
      data-testid={dataTestId}
      disabled={disabled}
      type={type}
      buttonType={submitFormOnClick ? 'submit' : buttonType}
      href={href}
      is={is}
      onClick={onClick}
      rel={rel}
      size={size}
      style={style}
      target={target}
      variant={variant}
    >
      {children}
    </BaseButton>
  ),
);

Button.displayName = 'Button';

Button.defaultProps = {
  variant: CUSTOM,
};

export default Button;
