import get from 'lodash/get';
import BaseArrayTypeMap, { BaseJsonMaps, JsonMaps } from './BaseArrayTypeMap';
import FieldPermissions from './FieldPermissions';
import { FieldPermission, Permission } from './Permission';

class DataTypePermissions extends BaseArrayTypeMap<Permission> {
  roleMap: Record<string | number, number[]>;

  constructor(permissions: Permission[]) {
    if (permissions instanceof DataTypePermissions) {
      //Will copy maps for permissions in constructor & reuse current instances of DataTypePermissions
      super(permissions);
      this.roleMap = permissions.roleMap;
    } else if (typeof permissions !== 'number') {
      const permissionsWithFieldPermissions = permissions.map((permission) => ({
        ...permission,
        fieldPermissions: new FieldPermissions(
          permission.fieldPermissions || [],
        ),
      }));

      super(permissionsWithFieldPermissions);
      this.roleMap = {};
    } else {
      super(permissions);
      this.roleMap = {};
    }

    this.maps.roleMap = this.roleMap;
  }

  static fromJSON(
    json: JsonMaps<
      Permission & {
        fieldPermissions?: JsonMaps<
          FieldPermission,
          { fieldMap: Record<string, number> }
        >;
      },
      {
        roleMap: Record<string | number, number[]>;
      }
    >,
  ) {
    const jsonMap = json;

    Object.keys(json.idMap).forEach((permissionKey) => {
      const permissionId = Number(permissionKey);
      const permission = jsonMap.idMap[permissionId];

      if (permission.fieldPermissions) {
        // This thinks it should be JsonMaps<FieldPermission> but it should be FieldPermissions
        (permission.fieldPermissions as FieldPermissions) =
          FieldPermissions.fromJSON(permission.fieldPermissions);
      }

      jsonMap.idMap[permissionId] = permission;
    });

    return DataTypePermissions._fromJSON(
      jsonMap as BaseJsonMaps<Permission>,
    ) as DataTypePermissions;
  }

  _mapEntry(permission: Permission) {
    super._mapEntry(permission);
    if (this.idMap) {
      permission.roleIds.forEach((roleId) => {
        if (this.roleMap[roleId]) {
          this.roleMap[roleId] = [...this.roleMap[roleId], permission.id];
        } else {
          this.roleMap[roleId] = [permission.id];
        }
      });
    }
  }

  getByRoleId(roleId: string | number): Permission[] {
    if (!this.idMap) {
      this._mapEntries();
    }
    return this.getByIds(get(this.roleMap, roleId, []));
  }
}

export default DataTypePermissions;
