import createReactComponent from '../createReactComponent';
export default createReactComponent('sailboat', 'IconSailboat', [
  [
    'path',
    {
      d: 'M2 20a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 18l-1 -3h18l-1 3', key: 'svg-1' }],
  ['path', { d: 'M11 12h7l-7 -9v9', key: 'svg-2' }],
  ['path', { d: 'M8 7l-2 5', key: 'svg-3' }],
]);
