import createReactComponent from '../createReactComponent';
export default createReactComponent('bone', 'IconBone', [
  [
    'path',
    {
      d: 'M15 3a3 3 0 0 1 3 3a3 3 0 1 1 -2.12 5.122l-4.758 4.758a3 3 0 1 1 -5.117 2.297l0 -.177l-.176 0a3 3 0 1 1 2.298 -5.115l4.758 -4.758a3 3 0 0 1 2.12 -5.122z',
      key: 'svg-0',
    },
  ],
]);
