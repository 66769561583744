import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import first from 'lodash/first';
import BaseConfetti from '../../components/BaseConfetti';
import ActionButtonExecutionWrapper, {
  QueuedAction,
} from '../../elements/sections/view/ActionButtonExecutionWrapper';

const CONFETTI_TIME_MS = 100;

type ActionButtonContext = {
  activeActionItem: QueuedAction | undefined;
  hasActiveAction: boolean;
  queue: QueuedAction[];
  queueAction: (actionItem: QueuedAction) => void;
  finishAction: (executionId: string) => void;
  showConfetti: boolean;
  startConfetti: (executionId: string) => void;
};

const actionButtonContext = createContext<ActionButtonContext>({
  activeActionItem: undefined,
  hasActiveAction: false,
  queue: [],
  queueAction: () => {},
  finishAction: () => {},
  showConfetti: false,
  startConfetti: () => {},
});

const useProvideActionButtons = (): ActionButtonContext => {
  const [actionQueue, setActionQueue] = useState<QueuedAction[]>([]);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);

  const activeActionItem = useMemo(() => first(actionQueue), [actionQueue]);

  const addActionItemToQueue = useCallback((actionItem: QueuedAction) => {
    setActionQueue((existingQueue: QueuedAction[]) => [
      ...existingQueue,
      actionItem,
    ]);
  }, []);

  const removeActionItemFromQueue = useCallback((executionId: string) => {
    setShowConfetti(false);
    return setActionQueue((existingQueue: QueuedAction[]) =>
      existingQueue.filter(
        (actionItem) => actionItem.executionId !== executionId,
      ),
    );
  }, []);

  const startConfetti = useCallback(
    (executionId: string) => {
      setShowConfetti(true);
      setTimeout(() => {
        removeActionItemFromQueue(executionId);
      }, CONFETTI_TIME_MS);
    },
    [removeActionItemFromQueue],
  );

  return {
    activeActionItem,
    hasActiveAction: !!activeActionItem,
    queue: actionQueue,
    queueAction: addActionItemToQueue,
    finishAction: removeActionItemFromQueue,
    showConfetti,
    startConfetti,
  };
};

export const ProvideActionButtons = ({ children }: { children: any }) => {
  const actionButtons = useProvideActionButtons();
  return (
    <actionButtonContext.Provider value={actionButtons}>
      <>
        <BaseConfetti active={actionButtons.showConfetti} />
        {children}
        {actionButtons.activeActionItem && (
          <ActionButtonExecutionWrapper
            actionItem={actionButtons.activeActionItem}
            finishAction={actionButtons.finishAction}
            startConfetti={actionButtons.startConfetti}
            key={actionButtons.activeActionItem.executionId}
          />
        )}
      </>
    </actionButtonContext.Provider>
  );
};

export const useActionButtons = () => {
  return useContext(actionButtonContext);
};
