import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-narrow-down',
  'IconArrowNarrowDown',
  [
    ['path', { d: 'M12 5l0 14', key: 'svg-0' }],
    ['path', { d: 'M16 15l-4 4', key: 'svg-1' }],
    ['path', { d: 'M8 15l4 4', key: 'svg-2' }],
  ],
);
