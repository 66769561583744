import { getText } from '@noloco/core/src/utils/lang';

export const getPlanDescription = (plan: any) =>
  `${plan.amount} ${getText(
    'core.BILLING.form.currency',
    plan.currency,
  )} - ${getText(
    'core.BILLING.form.interval.options',
    plan.interval || 'one-time',
  )}`;
