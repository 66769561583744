import createReactComponent from '../createReactComponent';
export default createReactComponent('math-integrals', 'IconMathIntegrals', [
  [
    'path',
    {
      d: 'M3 19a2 2 0 0 0 2 2c2 0 2 -4 3 -9s1 -9 3 -9a2 2 0 0 1 2 2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M11 19a2 2 0 0 0 2 2c2 0 2 -4 3 -9s1 -9 3 -9a2 2 0 0 1 2 2',
      key: 'svg-1',
    },
  ],
]);
