import createReactComponent from '../createReactComponent';
export default createReactComponent('layers-union', 'IconLayersUnion', [
  [
    'path',
    {
      d: 'M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2v-2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-2',
      key: 'svg-0',
    },
  ],
]);
