import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import { Theme } from '../../constants/tailwindStyles';

type Props = {
  className?: string;
  theme: Theme;
};

const Notifications = ({ className, theme }: Props) => (
  <svg
    className={classNames(
      `text-${getColorShade(theme.brandColors.primary, 400)}`,
      className,
    )}
    data-name="Layer 1"
    height="658.54932"
    viewBox="0 0 865.57286 658.54932"
    width="865.57286"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M475.13088,273.95982a1.42065,1.42065,0,0,1-1.4157-1.32118,50.21123,50.21123,0,0,0-49.94074-46.52628c-1.51455,0-3.10558.077-4.72921.22892A1.42019,1.42019,0,0,1,417.68,225.633a59.66227,59.66227,0,0,0-109.10474,12.97625,1.41413,1.41413,0,0,1-1.39713,1.025l-.37773-.01145c-.1965-.00659-.39282-.01352-.59087-.01352a42.07839,42.07839,0,0,0-41.00433,33.223,1.42071,1.42071,0,0,1-1.3902,1.12763H168.63429a1.42073,1.42073,0,0,1,0-2.84145H262.678a44.94513,44.94513,0,0,1,43.48245-34.35058,62.50318,62.50318,0,0,1,113.52733-13.33317c1.39749-.10891,2.76844-.16371,4.08666-.16371a53.062,53.062,0,0,1,52.77525,49.16864,1.42061,1.42061,0,0,1-1.31788,1.5168C475.198,273.95878,475.16435,273.95982,475.13088,273.95982Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M458.227,292.176H225.22826a1.42072,1.42072,0,1,1,0-2.84144H458.227a1.42072,1.42072,0,1,1,0,2.84144Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M282.05722,312.77652H203.9174a1.42073,1.42073,0,1,1,0-2.84145h78.13982a1.42073,1.42073,0,0,1,0,2.84145Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M355.11428,589.21139c0,57.75962-60.55444,85.39729-60.55444,85.39729s-61.17551-27.63767-62.41765-85.39729c-.73011-33.95,27.52824-61.48605,61.486-61.48605A61.486,61.486,0,0,1,355.11428,589.21139Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#e5e5e5"
    />
    <polygon
      points="147.842 557.299 106.851 557.299 101.882 537.425 152.81 537.425 147.842 557.299"
      fill="#cbcbcb"
    />
    <rect
      x="108.24833"
      y="591.28757"
      width="38.19588"
      height="19.90489"
      fill="#fff"
    />
    <path
      d="M313.96832,732.22835h-38.817v-20.526h38.817Zm-38.19588-.62107h37.57481V712.32344H275.77244Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <polygon
      points="135.176 559.643 119.517 559.643 117.72 556.819 136.973 556.819 135.176 559.643"
      fill="#cbcbcb"
    />
    <rect
      x="107.16146"
      y="557.29923"
      width="0.62107"
      height="30.43249"
      fill="#cbcbcb"
    />
    <rect
      x="146.91002"
      y="557.29923"
      width="0.62107"
      height="30.43249"
      fill="#cbcbcb"
    />
    <path
      d="M300.78526,657.81368l-.37922-.49189c17.94222-13.83627,39.33014-37.54008,39.33014-71.89a61.09179,61.09179,0,0,0-37.36337-56.36812l.242-.572a61.71164,61.71164,0,0,1,37.74244,56.94007C340.35725,620.034,318.8378,643.89219,300.78526,657.81368Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M280.91076,657.81383l-.37923-.49189c17.9423-13.83627,39.33037-37.54008,39.33037-71.8902A61.16369,61.16369,0,0,0,282.924,529.24542l.24624-.57012A61.78432,61.78432,0,0,1,320.483,585.43174C320.483,620.03417,298.96344,643.89249,280.91076,657.81383Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M306.34549,657.81368c-18.05254-13.92134-39.572-37.77951-39.572-72.38194a61.78407,61.78407,0,0,1,37.31325-56.7566l.24624.57013a61.16357,61.16357,0,0,0-36.93842,56.18647c0,34.35,21.38791,58.05378,39.33014,71.89Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M286.47128,657.81368c-18.05261-13.92134-39.57206-37.77936-39.57206-72.38194a61.66876,61.66876,0,0,1,37.99459-57.04606l.23943.57316a61.04905,61.04905,0,0,0-37.613,56.4729c0,34.35027,21.388,58.05393,39.33022,71.89Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <rect
      x="126.10413"
      y="407"
      width="0.62107"
      height="130.42495"
      fill="#cbcbcb"
    />
    <line
      x1="108.24833"
      y1="598.38284"
      x2="119.63694"
      y2="586.99424"
      fill="#fff"
    />
    <rect
      x="273.10322"
      y="713.10337"
      width="16.10597"
      height="0.621"
      transform="translate(-589.32461 287.0362) rotate(-45)"
      fill="#cbcbcb"
    />
    <line
      x1="108.24833"
      y1="604.60334"
      x2="125.17986"
      y2="586.80011"
      fill="#fff"
    />
    <rect
      x="271.64323"
      y="716.11655"
      width="24.56889"
      height="0.62099"
      transform="translate(-598.12788 307.80663) rotate(-46.44446)"
      fill="#cbcbcb"
    />
    <rect
      x="103.43503"
      y="586.80011"
      width="46.58034"
      height="5.58964"
      fill="#fff"
    />
    <path
      d="M317.53948,713.42563H270.33807v-6.21071h47.20141Zm-46.58034-.62107H316.9184V707.836H270.95914Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M472.30444,442.83091c0,38.12387-39.96858,56.36594-39.96858,56.36594s-40.37851-18.24207-41.19838-56.36594c-.4819-22.40846,18.16984-40.58348,40.58348-40.58348A40.58348,40.58348,0,0,1,472.30444,442.83091Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#e5e5e5"
    />
    <polygon
      points="278.65 380.726 251.594 380.726 248.315 367.608 281.93 367.608 278.65 380.726"
      fill="#cbcbcb"
    />
    <rect
      x="252.51682"
      y="403.15993"
      width="25.21095"
      height="13.1381"
      fill="#fff"
    />
    <path
      d="M445.1463,537.22835H419.52542v-13.548H445.1463Zm-25.21095-.40994h24.801V524.09024h-24.801Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <polygon
      points="270.29 382.273 259.954 382.273 258.768 380.409 271.476 380.409 270.29 382.273"
      fill="#cbcbcb"
    />
    <rect
      x="251.79943"
      y="380.72614"
      width="0.40993"
      height="20.08677"
      fill="#cbcbcb"
    />
    <rect
      x="278.03522"
      y="380.72614"
      width="0.40993"
      height="20.08677"
      fill="#cbcbcb"
    />
    <path
      d="M436.44491,488.11141l-.2503-.32466c11.84265-9.13254,25.9596-24.7781,25.9596-47.45058a40.32325,40.32325,0,0,0-24.66145-37.20542l.15973-.37751a40.73238,40.73238,0,0,1,24.91166,37.58293C462.56415,463.17519,448.36037,478.92262,436.44491,488.11141Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M423.32687,488.11151l-.25031-.32466c11.84271-9.13254,25.95976-24.7781,25.95976-47.45068a40.3707,40.3707,0,0,0-24.38062-37.08542l.16253-.37631a40.78035,40.78035,0,0,1,24.628,37.46173C449.44626,463.17529,435.24243,478.92282,423.32687,488.11151Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M440.1149,488.11141c-11.91546-9.18869-26.11924-24.93612-26.11924-47.77524A40.78018,40.78018,0,0,1,438.624,402.87434l.16253.37631a40.37063,40.37063,0,0,0-24.381,37.08552c0,22.67248,14.117,38.318,25.95961,47.45058Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M426.99706,488.11141c-11.91551-9.18869-26.11929-24.936-26.11929-47.77524a40.704,40.704,0,0,1,25.07809-37.65288l.158.3783a40.295,40.295,0,0,0-24.82618,37.27458c0,22.67268,14.117,38.31814,25.95965,47.45058Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <rect
      x="264.30242"
      y="281.52209"
      width="0.40993"
      height="86.08616"
      fill="#cbcbcb"
    />
    <line
      x1="252.51682"
      y1="407.84313"
      x2="260.0338"
      y2="400.32615"
      fill="#fff"
    />
    <rect
      x="418.17355"
      y="524.60503"
      width="10.63065"
      height="0.40989"
      transform="translate(-414.27324 332.4398) rotate(-45)"
      fill="#cbcbcb"
    />
    <line
      x1="252.51682"
      y1="411.94892"
      x2="263.69236"
      y2="400.19802"
      fill="#fff"
    />
    <rect
      x="417.2099"
      y="526.59386"
      width="16.21654"
      height="0.40988"
      transform="translate(-416.73868 351.30978) rotate(-46.44446)"
      fill="#cbcbcb"
    />
    <rect
      x="249.33983"
      y="400.19802"
      width="30.74506"
      height="3.68941"
      fill="#fff"
    />
    <path
      d="M447.50342,524.81773h-31.155v-4.09934h31.155Zm-30.74506-.40993h30.33513v-3.27948H416.75836Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M812.07517,494.95319a1.0183,1.0183,0,0,0,1.01475-.947,35.99077,35.99077,0,0,1,35.79689-33.34945c1.08561,0,2.226.05519,3.38985.16409a1.018,1.018,0,0,0,.97857-.50769,42.76517,42.76517,0,0,1,78.20491,9.30121,1.01364,1.01364,0,0,0,1.00145.73468l.27075-.00821c.14085-.00472.28157-.00969.42353-.00969a30.16126,30.16126,0,0,1,29.39139,23.81379,1.01834,1.01834,0,0,0,.99648.80827h68.22433a1.01836,1.01836,0,1,0,0-2.03671H964.35872a32.21611,32.21611,0,0,0-31.16767-24.62207,44.8015,44.8015,0,0,0-81.375-9.557c-1.0017-.07807-1.98438-.11735-2.92927-.11735a38.03414,38.03414,0,0,0-37.82863,35.24346,1.01828,1.01828,0,0,0,.94464,1.08723C812.02706,494.95245,812.05117,494.95319,812.07517,494.95319Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M824.19165,508.01034H991.20223a1.01835,1.01835,0,1,0,0-2.03671H824.19165a1.01835,1.01835,0,0,0,0,2.03671Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M950.46794,522.77652h56.00965a1.01836,1.01836,0,0,0,0-2.03671H950.46794a1.01835,1.01835,0,1,0,0,2.03671Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <polygon
      points="522.992 647.768 519.639 647.768 518.044 634.839 522.992 634.839 522.992 647.768"
      fill="#9f616a"
    />
    <path
      d="M691.06018,771.743H680.2515v-.13671a4.21189,4.21189,0,0,1,4.20717-4.20711h6.60151Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#2f2e41"
    />
    <polygon
      points="566.219 641.416 563.345 643.143 555.319 632.881 559.56 630.333 566.219 641.416"
      fill="#9f616a"
    />
    <path
      d="M726.57374,770.05331l-.07043-.11722a4.212,4.212,0,0,1,1.43949-5.773l5.65874-3.39979,2.23713,3.72341Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#2f2e41"
    />
    <path
      d="M691.07674,765.82538l-.05781-.00027a45.01358,45.01358,0,0,1-4.88046-.2809c-.61767-.061-1.28768-.12724-2.09494-.19346l-.0277-.0052c-.55079-.16368-.56608-5.32925-.33257-23.01079.11962-9.05639.25513-19.32089.09379-25.85785l-.0016-.06422.04853-.04232a24.5749,24.5749,0,0,1,21.62317-5.48959l.05908.01229.0307.05193c3.29451,5.57436,8.26883,13.60381,13.07932,21.369,11.04286,17.82547,14.20119,23.06793,13.855,23.46338l-.04172.03231c-.75406.377-1.3202.66727-1.80156.91413a59.40563,59.40563,0,0,1-5.86341,2.64453l-.08852.03645-.06455-.07076c-11.20473-12.27794-20.52907-25.9931-26.29656-38.67183-.67394,4.32847-1.49195,9.98054-2.35628,15.95249-2.48545,17.17288-4.16525,28.48088-4.84147,29.16938Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#2f2e41"
    />
    <path
      d="M694.09569,717.79784a58.69173,58.69173,0,0,1,19.62556,3.28577,44.67268,44.67268,0,0,0-10.01451-29.12533l-.04927-.05928.02523-.07276c1.76011-5.08572,3.57936-10.34247,5.205-15.57319l-.02463-.08785c-1.14009-4.05317-3.58723-8.14893-7.48106-12.52159a2.39636,2.39636,0,0,0-3.27122-.16568,30.476,30.476,0,0,0-9.82125,24.81008l.00253.0263-.00754.0255c-3.049,10.32965-4.15584,15.63527-5.13238,20.31633-.71234,3.41473-1.32914,6.37154-2.5675,10.59627A63.62216,63.62216,0,0,1,694.09569,717.79784Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <polygon
      points="536.719 564.419 526.8 586.013 521.133 580.277 536.719 564.419"
      opacity="0.1"
    />
    <circle cx="532.47798" cy="533.75944" r="7.0569" fill="#9f616a" />
    <path
      d="M691.13527,650.49848h11.20551V645.614c-2.45948-.97711-4.86621-1.80809-6.32106,0a4.88448,4.88448,0,0,0-4.88445,4.88445Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#2f2e41"
    />
    <path
      d="M702.96116,644.75206c6.69887,0,8.57389,8.39678,8.57389,13.13382,0,2.64179-1.19474,3.58667-3.07214,3.90638l-.663-3.53611-1.55291,3.68828c-.52736.00263-1.08138-.00758-1.65574-.01824l-.52652-1.08416-1.17412,1.06471c-4.70249.007-8.50318.69249-8.50318-4.02086C694.38741,653.14884,696.03179,644.75206,702.96116,644.75206Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#2f2e41"
    />
    <path
      className="fill-current"
      d="M905.30444,318.72534c0,186-195,275-195,275s-197-89-201-275c-2.35112-109.3271,88.64762-198,198-198S905.30444,209.373,905.30444,318.72534Z"
      transform="translate(-167.21357 -120.72534)"
    />
    <polygon
      points="547.274 646.307 543.163 646.307 541.208 630.453 547.274 630.453 547.274 646.307"
      fill="#ffb7b7"
    />
    <path
      d="M540.22741,645.13222h7.92694a0,0,0,0,1,0,0v4.991a0,0,0,0,1,0,0h-12.918a0,0,0,0,1,0,0v0A4.991,4.991,0,0,1,540.22741,645.13222Z"
      fill="#2f2e41"
    />
    <polygon
      points="562.696 646.307 558.586 646.307 556.63 630.453 562.697 630.453 562.696 646.307"
      fill="#ffb7b7"
    />
    <path
      d="M555.64958,645.13222h7.927a0,0,0,0,1,0,0v4.991a0,0,0,0,1,0,0h-12.918a0,0,0,0,1,0,0v0A4.991,4.991,0,0,1,555.64958,645.13222Z"
      fill="#2f2e41"
    />
    <path
      d="M729.40736,719.814a3.60166,3.60166,0,0,1-.69139-5.47924l-2.70642-38.40719,7.7959.75606.21413,37.61221a3.62116,3.62116,0,0,1-4.61222,5.51816Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#ffb7b7"
    />
    <path
      d="M714.293,759.04523l-4.52484-.21576a1.50837,1.50837,0,0,1-1.43691-1.49625l-.31578-45.78261a1.50905,1.50905,0,0,1,1.72543-1.50378l18.10216,2.62777a1.49992,1.49992,0,0,1,1.292,1.48184l2.32819,42.42243a1.50886,1.50886,0,0,1-1.50869,1.52015h-4.87812a1.50159,1.50159,0,0,1-1.49035-1.27427s-3.68464-31.82068-4.19057-31.802c-.5087.00949-3.53263,32.69486-3.53263,32.69486a1.51443,1.51443,0,0,1-1.49822,1.3296Q714.32906,759.04719,714.293,759.04523Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#2f2e41"
    />
    <path
      d="M727.77429,688.83291a1.50258,1.50258,0,0,1-.62316-1.14012l-.57124-10.35168a4.15682,4.15682,0,0,1,8.1625-1.31653l2.5093,9.255a1.51039,1.51039,0,0,1-1.06132,1.85092l-7.138,1.93533A1.50293,1.50293,0,0,1,727.77429,688.83291Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#3f3d56"
    />
    <circle cx="553.89292" cy="535.27109" r="8.23444" fill="#ffb7b7" />
    <path
      d="M726.93359,662.51115a2.65975,2.65975,0,0,1-4.58922-1.47757,2.70079,2.70079,0,0,1,.0034-.52157c.10335-.99022.67543-1.88923.53839-2.93477a1.539,1.539,0,0,0-.28165-.72045c-1.22414-1.63922-4.0977.73318-5.253-.75076-.7084-.90992.12432-2.34253-.4193-3.35952-.71748-1.34226-2.84264-.68012-4.17534-1.4152-1.48279-.81788-1.3941-3.0929-.418-4.47669a7.04185,7.04185,0,0,1,5.33854-2.71786,14.3202,14.3202,0,0,1,6.03218,1.17727,12.05493,12.05493,0,0,1,5.69972,3.95194,9.64786,9.64786,0,0,1,.97534,8.21456C729.88685,659.14346,728.188,661.17489,726.93359,662.51115Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#2f2e41"
    />
    <path
      d="M729.32568,669.73127q-.21461-.15422-.43757-.30285a10.84928,10.84928,0,0,0-2.54657-1.24859v-2.16367h-6.37v2.00746a11.17082,11.17082,0,0,0-8.101,9.2822L707.533,709.50825a1.50116,1.50116,0,0,0,.312,1.14036,1.48163,1.48163,0,0,0,1.02381.56085,22.7729,22.7729,0,0,1,8.60949,2.94895,12.78206,12.78206,0,0,0,6.27131,1.60069,15.11672,15.11672,0,0,0,5.10476-.90888,1.49748,1.49748,0,0,0,.97829-1.3427c.15618-3.4656,1.06964-21.304,3.7295-32.18507A11.12326,11.12326,0,0,0,729.32568,669.73127Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#3f3d56"
    />
    <polygon
      points="609.091 484 477.091 484 461.091 420 625.091 420 609.091 484"
      fill="#3f3d56"
    />
    <rect
      x="481.59087"
      y="593.4507"
      width="123"
      height="64.09859"
      fill="#fff"
    />
    <path
      d="M772.80444,779.27466h-125V713.176h125Zm-123-2h121V715.176h-121Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#3f3d56"
    />
    <polygon
      points="568.304 491.547 517.878 491.547 512.091 482.453 574.091 482.453 568.304 491.547"
      fill="#3f3d56"
    />
    <rect x="478.09087" y="484" width="2" height="98" fill="#3f3d56" />
    <rect x="606.09087" y="484" width="2" height="98" fill="#3f3d56" />
    <path
      d="M730.3518,539.64136l-1.22119-1.584C786.90893,493.50122,855.7832,417.16919,855.7832,306.554A196.7304,196.7304,0,0,0,735.46411,125.03491l.77929-1.84179A198.72645,198.72645,0,0,1,857.7832,306.554C857.7832,417.98169,788.48535,494.81079,730.3518,539.64136Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#3f3d56"
    />
    <path
      d="M666.35107,539.64185l-1.22119-1.584C722.90844,493.50171,791.7832,417.16968,791.7832,306.554a196.96192,196.96192,0,0,0-118.949-180.9336l.793-1.83594A198.96056,198.96056,0,0,1,793.7832,306.554C793.7832,417.98218,724.4851,494.81177,666.35107,539.64185Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#3f3d56"
    />
    <path
      d="M748.25707,539.64136C690.12353,494.81128,620.82568,417.98218,620.82568,306.554a198.95968,198.95968,0,0,1,120.15771-182.77l.793,1.83593A196.9616,196.9616,0,0,0,622.82568,306.554c0,110.61523,68.87427,186.94726,126.65259,231.50341Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#3f3d56"
    />
    <path
      d="M684.25732,539.64136C626.12353,494.81128,556.82568,417.98267,556.82568,306.554a198.5883,198.5883,0,0,1,122.3518-183.70215l.771,1.8457A196.59273,196.59273,0,0,0,558.82568,306.554c0,110.61621,68.87451,186.94775,126.65283,231.50341Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#3f3d56"
    />
    <rect x="539.09087" width="2" height="420" fill="#3f3d56" />
    <line
      x1="481.59087"
      y1="616.29921"
      x2="518.26494"
      y2="579.62514"
      fill="#fff"
    />
    <rect
      x="641.2089"
      y="717.68761"
      width="51.86514"
      height="1.99979"
      transform="translate(-480.00116 561.51361) rotate(-45)"
      fill="#3f3d56"
    />
    <line x1="481.59087" y1="636.33071" x2="536.11449" y2="579" fill="#fff" />
    <rect
      x="636.50738"
      y="727.39076"
      width="79.1178"
      height="1.99973"
      transform="translate(-484.86538 595.71211) rotate(-46.44446)"
      fill="#3f3d56"
    />
    <rect x="466.09087" y="579" width="150" height="18" fill="#fff" />
    <path
      d="M784.30444,718.72534h-152v-20h152Zm-150-2h148v-16h-148Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#3f3d56"
    />
    <path
      d="M681.81868,708.09726a2.74948,2.74948,0,0,0,2.47585-3.41248l8.17284-5.35471-4.661-2.01364-7.04648,5.39363a2.7644,2.7644,0,0,0,1.05884,5.3872Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#9f616a"
    />
    <path
      d="M689.68278,704.23969l-.10307-.0729c-1.94508-1.4316-4.10444-2.91207-6.19271-4.34382l-.12543-.086.0988-.11562c4.45717-5.21483,8.91914-10.66183,13.235-15.93112l-.00828-.019.04066-.0207.01722-.021.00921.00761.026-.01321-.11762.00907-1.3302-10.43178a6.6868,6.6868,0,0,1,.14305-7.165,5.67453,5.67453,0,0,1,7.38427-1.68608,4.84545,4.84545,0,0,1,2.12772,3.61847,4.37007,4.37007,0,0,1-1.15839,3.57842,150.47526,150.47526,0,0,1,.60806,15.22847l.00021.04766-.02951.03925c-4.601,5.84525-9.65484,11.6594-14.5423,17.28209Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M691.2667,672.1745a3.52839,3.52839,0,0,1,.08022.54988L705.749,681.0329l3.50063-2.01525,3.73189,4.88556-7.48885,5.3376-16.43062-12.96222a3.51887,3.51887,0,1,1,2.20461-4.10409Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#ffb7b7"
    />
    <path
      d="M706.26342,680.32639a1.50256,1.50256,0,0,1,.43355-1.22484l7.33-7.33173a4.15681,4.15681,0,0,1,6.42637,5.202l-5.22,8.04373a1.51039,1.51039,0,0,1-2.08685.44426l-6.20383-4.026A1.503,1.503,0,0,1,706.26342,680.32639Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#3f3d56"
    />
    <path
      d="M606.53371,608.95319a1.01829,1.01829,0,0,1-1.01475-.947,35.99077,35.99077,0,0,0-35.7969-33.34945c-1.08561,0-2.226.05519-3.38984.16409a1.018,1.018,0,0,1-.97858-.50769,42.76517,42.76517,0,0,0-78.20491,9.30121,1.01364,1.01364,0,0,1-1.00145.73468l-.27075-.00821c-.14084-.00472-.28156-.00969-.42352-.00969a30.16126,30.16126,0,0,0-29.39139,23.81379,1.01836,1.01836,0,0,1-.99648.80827H386.8408a1.01836,1.01836,0,1,1,0-2.03671h67.40935a32.21612,32.21612,0,0,1,31.16768-24.62207,44.80149,44.80149,0,0,1,81.375-9.557c1.0017-.07807,1.98438-.11735,2.92927-.11735a38.03415,38.03415,0,0,1,37.82864,35.24346,1.01828,1.01828,0,0,1-.94464,1.08723C606.58182,608.95245,606.5577,608.95319,606.53371,608.95319Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M594.41722,622.01034H427.40665a1.01836,1.01836,0,1,1,0-2.03671H594.41722a1.01835,1.01835,0,1,1,0,2.03671Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
    <path
      d="M468.14093,636.77652H412.13129a1.01836,1.01836,0,1,1,0-2.03671h56.00964a1.01836,1.01836,0,1,1,0,2.03671Z"
      transform="translate(-167.21357 -120.72534)"
      fill="#cbcbcb"
    />
  </svg>
);

export default withTheme(Notifications);
