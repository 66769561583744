import React from 'react';
import Editor from '@monaco-editor/react';
import { DARK, LIGHT } from '../../constants/surface';
import { Surface } from '../../models';

export type Props = {
  defaultValue: string;
  language: string;
  onChange: (value: string | undefined) => void;
  readOnly?: boolean;
  surface: Surface;
};

const CodeEditor = ({
  defaultValue,
  language,
  onChange,
  readOnly,
  surface,
}: Props) => {
  const theme = surface === DARK ? 'vs-dark' : LIGHT;

  return (
    <Editor
      defaultLanguage={language}
      defaultValue={defaultValue}
      height="100%"
      onChange={onChange}
      options={{
        minimap: { enabled: false },
        readOnly,
        scrollbar: { vertical: 'hidden' },
      }}
      theme={theme}
    />
  );
};

export default CodeEditor;
