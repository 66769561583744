import { IS_PRODUCTION, IS_QA } from './env';

const uriOverwrite =
  typeof localStorage !== 'undefined' && localStorage.getItem('BASE_URI');

const coreUriOverwrite =
  typeof localStorage !== 'undefined' && localStorage.getItem('BASE_CORE_URI');

export const BASE_URI =
  process.env.REACT_APP_BACKEND_URI ||
  uriOverwrite ||
  'https://api.nolocolocal.io';

export const BASE_CORE_URI =
  process.env.REACT_APP_CORE_BACKEND_URI ||
  process.env.REACT_APP_BACKEND_URI ||
  coreUriOverwrite ||
  'https://api-core.nolocolocal.io';

export const BASE_WS_URI = BASE_CORE_URI.replace('https://', 'wss://');

const GOOGLE_CLIENT_ID_PROD =
  '35670819852-mvfc35e8m51i9gq8f3qj6p7qicbioips.apps.googleusercontent.com';
const GOOGLE_CLIENT_ID_QA =
  '987810958514-i463l8lp272bmq2nt5jrjasias3jprjm.apps.googleusercontent.com';
const GOOGLE_CLIENT_ID_DEV =
  '673720277369-c88df4r4khlb6gnu2dvaj19losovbe3b.apps.googleusercontent.com';

const getGoogleClientId = () => {
  if (IS_PRODUCTION) {
    return GOOGLE_CLIENT_ID_PROD;
  } else if (IS_QA) {
    return GOOGLE_CLIENT_ID_QA;
  }
  return GOOGLE_CLIENT_ID_DEV;
};

export const GOOGLE_CLIENT_ID = getGoogleClientId();
export const GOOGLE_PROJECT_ID = GOOGLE_CLIENT_ID.split('-')[0];

export const AUTH_TOKEN = 'noloco:auth';
export const CLIENT_AUTH_TOKEN = 'noloco-client:auth';
export const CLIENT_GHOST_AUTH_TOKEN = 'noloco-client:ghost-auth';

export const AJS_ANONYMOUS_ID = 'ajs_anonymous_id';

export const AUTH_WRAPPER_ID = 'auth-wrapper';

export const PUBLIC_FORMS_AUTH_TOKEN_TYPE = 'PUBLIC_FORM';
