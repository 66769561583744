import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import {
  IconCode,
  IconFile,
  IconFolder,
  IconLineDashed,
  IconLink,
  IconPlus,
} from '@tabler/icons-react';
import classNames from 'classnames';
import omit from 'lodash/fp/omit';
import get from 'lodash/get';
import initial from 'lodash/initial';
import { useDispatch } from 'react-redux';
import shortId from 'shortid';
import { Popover, Switch } from '@noloco/components';
import { getGroupedDataTypeOptions } from '@noloco/ui/src/utils/dataTypes';
import {
  useAddChild,
  useUpdateProject,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { getUniqueRoutePath } from '@noloco/ui/src/utils/layout';
import { darkModeColors } from '../../constants/darkModeColors';
import { INTERNAL } from '../../constants/dataSources';
import {
  BILLING,
  DIVIDER,
  FILE_SHARING,
  FOLDER,
  IFRAME,
  LINK,
  MESSAGING,
  ONBOARDING_TASKS,
  PAGE,
} from '../../constants/elements';
import { URL } from '../../constants/linkTypes';
import { XL } from '../../constants/screens';
import compoundElements from '../../elements/compoundElements';
import elementConfig from '../../elements/elementConfig';
import { Project } from '../../models/Project';
import {
  setSelectedElement,
  setSelectedPagePath,
} from '../../reducers/elements';
import { NEW_PAGE_SAVED, trackEvent } from '../../utils/analytics';
import useAddPage from '../../utils/hooks/useAddPage';
import { useAddView } from '../../utils/hooks/useAddView';
import useDarkMode from '../../utils/hooks/useDarkMode';
import useHasFeatureFlag, {
  LEGACY_UI_MODULES,
} from '../../utils/hooks/useHasFeatureFlag';
import useRouter from '../../utils/hooks/useRouter';
import { getText } from '../../utils/lang';
import { Page, getPagesConfig } from '../../utils/pages';

const MODULES = [BILLING, FILE_SHARING, MESSAGING, ONBOARDING_TASKS];

const moduleIconNames = {
  [BILLING]: 'CreditCard',
  [FILE_SHARING]: 'Files',
  [MESSAGING]: 'Messages',
  [ONBOARDING_TASKS]: 'ListCheck',
};

const LANG_KEY = 'leftSidebar.pages.newPageOptions';

type Props = {
  children: any;
  className?: string;
  modules?: boolean;
  parentPage?: Page;
  project: Project;
  placement?: 'top-start' | 'right';
  setEditingPage?: (id: string) => void;
  fromBuildModeSwitch?: boolean;
  newPageIndex?: number;
};

const SidebarItemOptions = forwardRef(
  (
    {
      children,
      className,
      modules,
      parentPage,
      project,
      placement = 'right',
      setEditingPage,
      fromBuildModeSwitch = false,
      newPageIndex,
    }: Props,
    ref,
  ) => {
    const qsSuffix = useMemo(
      () => (parentPage ? parentPage.id : ''),
      [parentPage],
    );
    const {
      push,
      query: { [`__new-view${qsSuffix}`]: newViewOpen, ...restParams },
      replaceQueryParams,
    } = useRouter();
    const dispatch = useDispatch();
    const [localNewViewOpen, setLocalNewViewOpen] = useState(false);
    const legacyModulesEnabled = useHasFeatureFlag(LEGACY_UI_MODULES);
    const [isDarkModeEnabled] = useDarkMode();

    const {
      isV2,
      pagesPath,
      projectPages: elements,
    } = useMemo(
      () => getPagesConfig(project.elements, project.settings),
      [project],
    );

    const hasExternalSources = useMemo(
      () =>
        project.dataTypes.some((dataType) => dataType.source.type !== INTERNAL),
      [project.dataTypes],
    );

    const modulesToShow = useMemo(
      () =>
        legacyModulesEnabled
          ? MODULES
          : MODULES.filter((module) =>
              elements.find(
                (element: any) => get(element, 'children.0.type') === module,
              ),
            ),
      // If we leave the deps empty for this it will only get recalculated on reload
      // This will allow you to toggle off and then back on a module that you had on
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const [updateProject] = useUpdateProject(project);
    const [addChild] = useAddChild(
      project,
      isV2 ? [] : initial(pagesPath),
      isV2 ? [] : ['children'],
    );

    const onAddView = useAddView(project, parentPage);

    const onOpenChange = useCallback(
      (nextIsOpen: boolean) => {
        if (fromBuildModeSwitch) {
          return replaceQueryParams({
            ...restParams,
            [`__new-view${qsSuffix}`]: nextIsOpen ? 'true' : undefined,
          });
        }

        setLocalNewViewOpen(nextIsOpen ? true : false);
      },
      [
        fromBuildModeSwitch,
        qsSuffix,
        replaceQueryParams,
        restParams,
        setLocalNewViewOpen,
      ],
    );

    const dataListGroups = useMemo(
      () => getGroupedDataTypeOptions(project.dataTypes),
      [project.dataTypes],
    );

    const onAddPage = useAddPage(project);

    const onAddPageFolder = useCallback(() => {
      trackEvent(NEW_PAGE_SAVED);
      const existingFolders = elements.filter(
        (element: any) => element.type === FOLDER,
      );
      const folderName = getText(
        { count: existingFolders.length + 1 },
        'leftSidebar.pages.folder',
      );
      const newFolder = {
        id: shortId.generate(),
        type: FOLDER,
        children: [],
        props: {
          name: folderName,
          routePath: getUniqueRoutePath(String(folderName), project),
          icon: { name: 'Folder' },
        },
      };
      addChild(newFolder, newPageIndex);
      setLocalNewViewOpen(false);
      setEditingPage && setEditingPage(newFolder.id);
      dispatch(
        setSelectedElement([...pagesPath, newPageIndex ?? elements.length]),
      );
      dispatch(
        setSelectedPagePath([...(isV2 ? [] : ['PORTAL']), newFolder.id]),
      );
      setTimeout(() => {
        push(`/${newFolder.props.routePath}`);
      }, 500);
    }, [
      addChild,
      dispatch,
      elements,
      isV2,
      pagesPath,
      project,
      push,
      setEditingPage,
      newPageIndex,
    ]);

    const onAddSidebarLink = useCallback(() => {
      const existingLinks = elements.filter(
        (element: any) => element.type === LINK,
      );
      const linkName = getText(
        { count: existingLinks.length + 1 },
        'leftSidebar.pages.link',
      );
      const newLink = {
        id: shortId.generate(),
        type: LINK,
        children: [],
        props: {
          name: linkName,
          link: {
            url: [{ text: 'https://noloco.io' }],
            type: URL,
          },
          icon: { name: 'Link' },
        },
      };
      addChild(newLink, newPageIndex);
      setLocalNewViewOpen(false);
      setEditingPage && setEditingPage(newLink.id);
      dispatch(
        setSelectedElement([...pagesPath, newPageIndex ?? elements.length]),
      );
      dispatch(setSelectedPagePath([...(isV2 ? [] : ['PORTAL']), newLink.id]));
    }, [
      addChild,
      dispatch,
      elements,
      isV2,
      pagesPath,
      setEditingPage,
      newPageIndex,
    ]);

    const onAddSidebarDivider = useCallback(() => {
      const newDivider = {
        id: shortId.generate(),
        type: DIVIDER,
        props: {},
      };
      addChild(newDivider, newPageIndex);
      setLocalNewViewOpen(false);
    }, [addChild, newPageIndex]);

    const onAddBlankPage = useCallback(() => {
      onAddPage(
        {
          props: {
            V2: true,
            ...(parentPage ? { parentPage: parentPage.id } : {}),
          },
        },
        newPageIndex,
      );
      setLocalNewViewOpen(false);
    }, [onAddPage, parentPage, newPageIndex]);

    const onAddIframe = useCallback(() => {
      onAddPage(
        {
          props: {
            V2: true,
            sections: [
              {
                id: shortId.generate(),
                type: IFRAME,
                props: {
                  name: 'Iframe',
                  fullScreen: true,
                  size: XL,
                },
              },
            ],
          },
        },
        newPageIndex,
      );
      setLocalNewViewOpen(false);
    }, [onAddPage, newPageIndex]);

    const onToggleModule = useCallback(
      (module: any) => (newEnabled: any) => {
        if (newEnabled) {
          // Add module
          addChild(
            {
              type: PAGE,
              id: shortId.generate(),
              props: {
                name: getText('elements', module, 'label'),
                icon: { name: moduleIconNames[module] },
                routePath: getUniqueRoutePath(module, project),
              },
              children: [
                {
                  id: shortId.generate(),
                  type: module,
                  props: omit(
                    ['pl', 'pr', 'mt', 'mb'],
                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                    compoundElements.SECTIONS.find(
                      ({ type }) => type === module,
                    ).props,
                  ),
                },
              ],
            },
            newPageIndex,
          );
          setLocalNewViewOpen(false);
        } else {
          updateProject(
            ['elements', ...pagesPath],
            elements.filter(
              (page: any) => get(page, 'children.0.type') !== module,
            ),
          );
        }
      },
      [addChild, elements, pagesPath, project, updateProject, newPageIndex],
    );

    return (
      <Popover
        closeOnOutsideClick={true}
        onOpenChange={onOpenChange}
        isOpen={fromBuildModeSwitch ? newViewOpen : localNewViewOpen}
        trigger="none"
        content={
          <div
            className={classNames(
              'flex w-screen max-w-xl overflow-hidden p-2 text-sm',
              {
                [darkModeColors.text.secondary]: isDarkModeEnabled,
              },
            )}
          >
            <div className="flex w-1/2 flex-col pr-2">
              <h3
                className={`mb-2 font-medium uppercase tracking-wider ${
                  isDarkModeEnabled
                    ? darkModeColors.text.primary
                    : 'text-gray-500'
                }`}
              >
                {getText(LANG_KEY, 'collection')}
              </h3>
              <div className="sidebar-item-collection-options flex max-h-80 flex-col space-y-3 overflow-y-auto pr-2">
                {dataListGroups.map(
                  ({ label, key, icon, options: dataTypes }: any) => (
                    <div className="flex flex-col" key={key}>
                      <div
                        className={`my- flex items-center rounded-lg px-2 py-1 font-medium ${
                          isDarkModeEnabled
                            ? darkModeColors.surfaces.elevation1
                            : 'bg-gray-100'
                        }`}
                      >
                        {icon}
                        <span className="ml-3">{label}</span>
                      </div>
                      <div className="mt-2 flex flex-col space-y-2 pl-2">
                        {dataTypes.map(({ dataType }: any) => (
                          <button
                            className={`group flex w-full items-center justify-between pr-4 font-medium hover:text-pink-500 ${
                              isDarkModeEnabled
                                ? darkModeColors.text.primary
                                : 'text-gray-800'
                            }`}
                            key={dataType.name}
                            onClick={() => {
                              onAddView(dataType, newPageIndex);
                              setLocalNewViewOpen(false);
                            }}
                          >
                            <span>{dataType.display}</span>
                            <IconPlus
                              className="ml-2 hidden opacity-75 group-hover:block"
                              size={16}
                            />
                          </button>
                        ))}
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
            <div className="flex w-1/2 flex-col border-l border-gray-200 pl-8">
              {modules ? (
                <div
                  className={classNames('pb-4', {
                    'border-b border-gray-200': hasExternalSources,
                  })}
                >
                  {modulesToShow.length > 0 && (
                    <div className="mb-6 flex flex-col">
                      <h3
                        className={`mb-2 text-xs font-medium uppercase tracking-wider ${
                          isDarkModeEnabled
                            ? darkModeColors.text.primary
                            : 'text-gray-500'
                        }`}
                      >
                        {getText(LANG_KEY, 'modules')}
                      </h3>
                      <div className="flex flex-col space-y-3 overflow-y-auto pr-2">
                        {modulesToShow.map((module) => {
                          const isEnabled = elements.find(
                            (element: any) =>
                              get(element, 'children.0.type') === module,
                          );
                          return (
                            <div
                              className="flex w-full items-center justify-between font-medium text-gray-800"
                              key={module}
                            >
                              <div className="flex items-center">
                                <Box
                                  is={elementConfig[module].Icon}
                                  className="mr-4 opacity-75"
                                  size={16}
                                />
                                <span>
                                  {getText('elements', module, 'label')}
                                </span>
                              </div>
                              <Switch
                                value={isEnabled}
                                onChange={onToggleModule(module)}
                                size="sm"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <h3
                    className={`mb-2 text-sm font-medium uppercase tracking-wider ${
                      isDarkModeEnabled
                        ? darkModeColors.text.primary
                        : 'text-gray-500'
                    }`}
                  >
                    {getText(LANG_KEY, 'advanced.label')}
                  </h3>
                  <div className="space-y-3 pr-2">
                    <button
                      className={`group flex w-full items-center justify-between font-medium hover:text-pink-500 ${
                        isDarkModeEnabled
                          ? darkModeColors.text.primary
                          : 'text-gray-800'
                      }`}
                      onClick={onAddPageFolder}
                    >
                      <div className="flex items-center">
                        <IconFolder className="mr-4 opacity-75" size={16} />
                        <span>{getText(LANG_KEY, 'advanced.folder')}</span>
                      </div>
                      <IconPlus
                        className="ml-2 hidden opacity-75 group-hover:block"
                        size={12}
                      />
                    </button>
                    <button
                      className={`group flex w-full items-center justify-between font-medium hover:text-pink-500 ${
                        isDarkModeEnabled
                          ? darkModeColors.text.primary
                          : 'text-gray-800'
                      }`}
                      onClick={onAddSidebarLink}
                    >
                      <div className="flex items-center">
                        <IconLink className="mr-4 opacity-75" size={16} />
                        <span>{getText(LANG_KEY, 'advanced.link')}</span>
                      </div>
                      <IconPlus
                        className="ml-2 hidden opacity-75 group-hover:block"
                        size={12}
                      />
                    </button>
                    <button
                      className={`group flex w-full items-center justify-between font-medium hover:text-pink-500 ${
                        isDarkModeEnabled
                          ? darkModeColors.text.primary
                          : 'text-gray-800'
                      }`}
                      onClick={onAddBlankPage}
                    >
                      <div className="flex items-center">
                        <IconFile className="mr-4 opacity-75" size={16} />
                        <span>{getText(LANG_KEY, 'advanced.blank')}</span>
                      </div>
                      <IconPlus
                        className="ml-2 hidden opacity-75 group-hover:block"
                        size={12}
                      />
                    </button>
                    <button
                      className={`group flex w-full items-center justify-between font-medium hover:text-pink-500 ${
                        isDarkModeEnabled
                          ? darkModeColors.text.primary
                          : 'text-gray-800'
                      }`}
                      onClick={onAddIframe}
                    >
                      <div className="flex items-center">
                        <IconCode className="mr-4 opacity-75" size={16} />
                        <span>{getText(LANG_KEY, 'advanced.iframe')}</span>
                      </div>
                      <IconPlus
                        className="ml-2 hidden opacity-75 group-hover:block"
                        size={16}
                      />
                    </button>
                    <button
                      className={`group flex w-full items-center justify-between font-medium hover:text-pink-500 ${
                        isDarkModeEnabled
                          ? darkModeColors.text.primary
                          : 'text-gray-800'
                      }`}
                      onClick={onAddSidebarDivider}
                    >
                      <div className="flex items-center">
                        <IconLineDashed className="mr-4 opacity-75" size={16} />
                        <span>{getText(LANG_KEY, 'advanced.divider')}</span>
                      </div>
                      <IconPlus
                        className="ml-2 hidden opacity-75 group-hover:block"
                        size={16}
                      />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <button
                    className={`group flex w-full items-center justify-between font-medium hover:text-pink-500 ${
                      isDarkModeEnabled
                        ? darkModeColors.text.primary
                        : 'text-gray-800'
                    }`}
                    onClick={onAddBlankPage}
                  >
                    <div className="flex items-center">
                      <IconFile className="mr-4 opacity-75" size={16} />
                      <span>{getText(LANG_KEY, 'advanced.blank')}</span>
                    </div>
                    <IconPlus
                      className="ml-2 hidden opacity-75 group-hover:block"
                      size={12}
                    />
                  </button>
                  <hr className="my-4 w-full border-gray-200" />
                </>
              )}
              {hasExternalSources && (
                <div
                  className={classNames({
                    'mt-4': modules,
                    [darkModeColors.text.primary]: isDarkModeEnabled,
                    'text-gray-500': !isDarkModeEnabled,
                  })}
                >
                  <h3 className="mb-2 text-sm font-medium uppercase tracking-wider">
                    {getText(LANG_KEY, 'missing.label')}
                  </h3>
                  <p className="text-xs">
                    {getText(LANG_KEY, 'missing.paragraph1')}
                    <a
                      className="text-blue-400 hover:text-blue-500"
                      href="https://guides.noloco.io/data/data-overview#choosing-which-of-your-collections-are-synced"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getText(LANG_KEY, 'missing.link')}
                    </a>
                    {getText(LANG_KEY, 'missing.paragraph2')}
                  </p>
                </div>
              )}
            </div>
          </div>
        }
        placement={placement}
      >
        <div
          className={classNames(className, 'flex w-full items-center')}
          onClick={() => onOpenChange(true)}
          // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
          ref={ref}
        >
          {children}
        </div>
      </Popover>
    );
  },
);

SidebarItemOptions.defaultProps = {
  modules: true,
};

export default SidebarItemOptions;
