import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-samsungpass',
  'IconBrandSamsungpass',
  [
    [
      'path',
      {
        d: 'M4 10m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M7 10v-1.862c0 -2.838 2.239 -5.138 5 -5.138s5 2.3 5 5.138v1.862',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M10.485 17.577c.337 .29 .7 .423 1.515 .423h.413c.323 0 .633 -.133 .862 -.368a1.27 1.27 0 0 0 .356 -.886c0 -.332 -.128 -.65 -.356 -.886a1.203 1.203 0 0 0 -.862 -.368h-.826a1.2 1.2 0 0 1 -.861 -.367a1.27 1.27 0 0 1 -.356 -.886c0 -.332 .128 -.651 .356 -.886a1.2 1.2 0 0 1 .861 -.368h.413c.816 0 1.178 .133 1.515 .423',
        key: 'svg-2',
      },
    ],
  ],
);
