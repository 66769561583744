import createReactComponent from '../createReactComponent';
export default createReactComponent('presentation-off', 'IconPresentationOff', [
  ['path', { d: 'M3 4h1m4 0h13', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M4 4v10a2 2 0 0 0 2 2h10m3.42 -.592c.359 -.362 .58 -.859 .58 -1.408v-10',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 16v4', key: 'svg-2' }],
  ['path', { d: 'M9 20h6', key: 'svg-3' }],
  ['path', { d: 'M8 12l2 -2m4 0l2 -2', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
