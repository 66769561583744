import createReactComponent from '../createReactComponent';
export default createReactComponent('currency-dollar', 'IconCurrencyDollar', [
  [
    'path',
    {
      d: 'M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 3v3m0 12v3', key: 'svg-1' }],
]);
