import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrows-double-nw-se',
  'IconArrowsDoubleNwSe',
  [
    ['path', { d: 'M14 21l-11 -11', key: 'svg-0' }],
    ['path', { d: 'M3 14v-4h4', key: 'svg-1' }],
    ['path', { d: 'M17 14h4v-4', key: 'svg-2' }],
    ['path', { d: 'M10 3l11 11', key: 'svg-3' }],
  ],
);
