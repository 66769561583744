import { CURSOR, Endpoint, OFFSET, PAGE } from '../constants/apis';
import { ARRAY, DataSchemaField, NESTED } from '../constants/dataSchema';
import { DataFieldType, INTEGER, TEXT } from '../constants/dataTypes';
import { GET, POST } from '../constants/endpointMethodTypes';
import { QUERY } from '../constants/endpointTypes';
import { UNFORMATTED_NUMBER } from '../constants/fieldFormats';
import PRIMITIVE_DATA_TYPES from '../constants/primitiveDataTypes';
import { API } from '../constants/scopeTypes';
import { FieldTypeOptions } from '../models/DataTypeFields';
import StateItem from '../models/StateItem';
import { formatStateItemAsBaseOption } from './options';

export const buildFieldTypeOptions = (
  idField: string | null,
  field: DataSchemaField,
): FieldTypeOptions | undefined => {
  switch (field.type) {
    case INTEGER:
      if (idField && field.path.join('.') === idField) {
        return { format: UNFORMATTED_NUMBER };
      } else {
        return undefined;
      }
    default:
      return undefined;
  }
};

const getCollectionScopeFromDataShape = (
  id: string,
  endpoint: any,
  name: string,
  dataShape: any,
  dataPath: string[] = [],
): any[] | Record<any, any> | null => {
  if (Array.isArray(dataShape)) {
    if (dataShape.length > 0) {
      return formatStateItemAsBaseOption(
        new StateItem({
          id,
          path: [...dataPath, name].join('.'),
          source: API,
          dataType: `ENDPOINT:${endpoint.id}`,
          display: name,
        }),
        [
          ...dataPath.map((pathKey) => ({ display: pathKey })),
          { display: name },
        ],
      );
    }
  }

  if (typeof dataShape === 'object') {
    const options = Object.entries(dataShape)
      .reduce((scopeAcc, [key, value]) => {
        const valueScopeItem = getCollectionScopeFromDataShape(
          id,
          endpoint,
          key,
          value,
          [...dataPath, name],
        );
        if (valueScopeItem) {
          return [...scopeAcc, valueScopeItem];
        }
        return scopeAcc;
      }, [] as any[])
      .filter(Boolean);

    if (dataPath.length === 0) {
      return options;
    }

    return {
      label: name,
      options,
    };
  }

  return null;
};

const getDataTypeScopeFromDataShape = (
  id: string,
  name: string,
  dataShape: any,
  acceptableDataTypes: DataFieldType[],
  dataPath: any[] = [],
): any[] | Record<any, any> | null => {
  if (Array.isArray(dataShape) && dataShape.length === 0) {
    return null;
  }

  if (typeof dataShape === 'object') {
    const options = Object.entries(dataShape)
      .reduce((scopeAcc, [key, value]) => {
        const valueScopeItem = getDataTypeScopeFromDataShape(
          id,
          key,
          value,
          acceptableDataTypes,
          [...dataPath, name],
        ) as Record<any, any>;
        if (valueScopeItem) {
          return [
            ...scopeAcc,
            {
              ...valueScopeItem,
              label: key === '0' ? 'first' : valueScopeItem.label,
            },
          ];
        }
        return scopeAcc;
      }, [] as any[])
      .filter(Boolean);

    if (dataPath.length === 0) {
      return options;
    }

    return {
      label: name,
      options,
    };
  }

  if (acceptableDataTypes.includes(dataShape)) {
    return formatStateItemAsBaseOption(
      new StateItem({
        id,
        path: [...dataPath, name].join('.'),
        source: API,
        dataType: dataShape,
        display: name,
      }),
      [...dataPath.map((pathKey) => ({ display: pathKey })), { display: name }],
    );
  }

  return null;
};

export const getApiEndpointDataTypeOptions = (
  id: string,
  endpoint: any,
  acceptableDataTypes: DataFieldType[] = PRIMITIVE_DATA_TYPES,
  baseDataPath: any[] = [],
) => {
  const scope = [];

  if (acceptableDataTypes.includes(INTEGER)) {
    scope.push(
      new StateItem({
        id,
        path: 'status',
        source: API,
        display: 'Status',
        dataType: INTEGER,
      }),
    );
  }

  if (endpoint.dataShape) {
    const dataShapeScope = getDataTypeScopeFromDataShape(
      id,
      'data',
      endpoint.dataShape,
      acceptableDataTypes,
      baseDataPath,
    );
    if (dataShapeScope && Array.isArray(dataShapeScope)) {
      scope.push({
        label: 'Data',
        options: dataShapeScope.filter(Boolean),
      });
    } else if (dataShapeScope) {
      scope.push({
        label: 'Data',
        options: [dataShapeScope],
      });
    }
  }

  return scope;
};

export const getApiEndpointCollectionOptions = (id: string, endpoint: any) => {
  const scope = [];

  if (endpoint.dataShape) {
    const dataShapeScope = getCollectionScopeFromDataShape(
      id,
      endpoint,
      'data',
      endpoint.dataShape,
      [],
    );
    if (dataShapeScope && Array.isArray(dataShapeScope)) {
      scope.push({
        label: 'Data',
        options: dataShapeScope.filter(Boolean),
      });
    } else if (dataShapeScope) {
      scope.push({
        label: 'Data',
        options: [dataShapeScope],
      });
    }
  }

  return scope;
};

export const toInput = (endpoint: Endpoint) => ({
  body: endpoint.body,
  display: endpoint.display,
  headers: endpoint.headers.filter(({ key, value }) => !!key && !!value),
  method: endpoint.method,
  path: endpoint.path,
  pagination: endpoint.pagination,
  firstPage: endpoint.firstPage,
  parameters: endpoint.parameters
    .filter(({ key, value }) => !!key && !!value)
    .map(({ key, value }) => ({
      name: key,
      testValue: value,
      dataType: TEXT,
    })),
  type: QUERY,
});

export const toPlaceholder = (placeholder: string) => `{{${placeholder}}}`;

export const placeholderExists =
  (endpoint: Endpoint) => (placeholder: string) =>
    endpoint.parameters.some(
      ({ value }) => value === toPlaceholder(placeholder),
    ) ||
    (endpoint.method !== GET &&
      endpoint.body &&
      endpoint.body.includes(toPlaceholder(placeholder)));

export const isEndpointValid = (endpoint: Endpoint) => {
  if (!endpoint.display) {
    return false;
  }

  // Configuration
  if (!endpoint.path || (endpoint.path === POST && !endpoint.body)) {
    return false;
  }

  // Pagination
  if (endpoint.pagination === CURSOR && !placeholderExists(endpoint)(CURSOR)) {
    return false;
  }
  if (endpoint.pagination === OFFSET && !placeholderExists(endpoint)(OFFSET)) {
    return false;
  }
  if (
    endpoint.pagination === PAGE &&
    (!placeholderExists(endpoint)(PAGE) || endpoint.firstPage === null)
  ) {
    return false;
  }

  // Format
  if (!endpoint.idField || !endpoint.resultPath) {
    return false;
  }

  // Fields
  if (
    !endpoint.fields ||
    endpoint.fields.length === 0 ||
    endpoint.fields.some(({ type }) => type === ARRAY || type === NESTED)
  ) {
    return false;
  }

  return true;
};
