import createReactComponent from '../createReactComponent';
export default createReactComponent('gender-epicene', 'IconGenderEpicene', [
  [
    'path',
    {
      d: 'M15.536 15.536a5 5 0 1 0 -7.072 -7.072a5 5 0 0 0 7.072 7.072z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15.536 15.535l5.464 -5.535', key: 'svg-1' }],
  ['path', { d: 'M3 14l5.464 -5.535', key: 'svg-2' }],
  ['path', { d: 'M12 12h.01', key: 'svg-3' }],
]);
