import React, { useCallback, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';
import ct from 'countries-and-timezones';
import { countries, getEmojiFlag } from 'countries-list';
import get from 'lodash/get';
import Input, {
  Country,
  formatPhoneNumber,
} from 'react-phone-number-input/input';
import { DARK, LIGHT } from '../../constants/surface';
import { CountryCode, PhoneNumber, Surface, Theme } from '../../models';
import { validationBorder } from '../../utils';
import { Dropdown } from '../dropdown';

type PhoneNumberInputProps = {
  disabled?: boolean;
  inline?: boolean;
  onChange: (value: any) => void;
  surface: Surface;
  theme: Theme;
  validationError?: string;
  value: PhoneNumber;
};
const DEFAULT_COUNTRY = 'US';

export const getAreaCodeFromCountry = (country: CountryCode) =>
  get(countries, [country, 'phone']);

const dissectPhoneNumber = (phoneNumber: PhoneNumber) => {
  if (phoneNumber) {
    const countryCode = `+${getAreaCodeFromCountry(
      phoneNumber.country as CountryCode,
    )}`;

    const number = get(phoneNumber, 'number', null);

    if (number) {
      if (number.startsWith(countryCode)) {
        return `${number}`;
      }

      return `${countryCode} ${number}`;
    }
  }

  return '';
};

export const getCountryFromTimeZone = (timezone?: string) =>
  (ct.getCountryForTimezone(
    timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  )?.id ?? DEFAULT_COUNTRY) as Country;

const PhoneNumberInput = ({
  disabled,
  inline,
  onChange,
  surface,
  theme,
  validationError,
  value,
}: PhoneNumberInputProps) => {
  const options = useMemo(
    () =>
      Object.entries(countries).map(([country, countryData]) => ({
        label: `${countryData.emoji} ${countryData.name} +${countryData.phone}`,
        phone: countryData.phone,
        value: country,
      })),
    [],
  );
  const number = useMemo(() => dissectPhoneNumber(value), [value]);
  const defaultCountry = getCountryFromTimeZone();
  const country = useMemo<Country>(
    () => get(value, 'country', null) ?? defaultCountry,
    [value, defaultCountry],
  );

  const handleCountryChange = useCallback(
    (newCountry) =>
      onChange({
        country: newCountry,
        number,
      }),
    [onChange, number],
  );

  const handlePhoneNumberChange = useCallback(
    (newPhoneNumber) => {
      const countryCode = country ? get(countries, [country, 'phone']) : null;
      let number = (formatPhoneNumber(newPhoneNumber) || newPhoneNumber) ?? '';

      if (newPhoneNumber && !number && countryCode) {
        number = newPhoneNumber.replace(`+${countryCode}`, '');
      }

      onChange({
        country,
        number,
      });
    },
    [onChange, country],
  );

  const errorBorder = useMemo(
    () => validationBorder(validationError || '', theme),
    [validationError, theme],
  );

  return (
    <div
      className={classNames(
        'flex h-8 items-center rounded-lg border border-gray-300',
        errorBorder,
      )}
    >
      <Dropdown
        Button={({ children }: any) => <span>{children}</span>}
        className="flex h-full w-16 items-center"
        disabled={disabled}
        direction="bottom"
        minW={16}
        onChange={handleCountryChange}
        options={options}
        placeholder=""
        searchable={true}
        surface={surface}
        value={country}
        w={16}
      >
        <div className="flex h-full items-center justify-center">
          {getEmojiFlag(country)}
          <IconChevronDown size={16} className="ml-2 text-gray-400" />
        </div>
      </Dropdown>
      <Input
        country={country}
        disabled={disabled}
        defaultCountry={defaultCountry}
        international
        onChange={handlePhoneNumberChange}
        value={number}
        className={classNames(
          'flex h-8 w-full items-center justify-center rounded-lg bg-transparent sm:h-10 sm:text-base',
          {
            'focus:ring-2': !inline,
            'text-gray-900': surface === LIGHT,
            'text-white': surface === DARK,
          },
        )}
      />
    </div>
  );
};

export default withTheme(PhoneNumberInput);
