import React from 'react';
import classNames from 'classnames';
import { CopyButton } from '@noloco/components';
import {
  BOARD,
  CALENDAR,
  CARDS,
  CollectionLayout,
  GANTT,
  MAP,
  TIMELINE,
} from '../../../constants/collectionLayouts';
import { getText } from '../../../utils/lang';

type CopyFieldValueButtonProps = {
  layout: CollectionLayout;
  value: any;
};

const RIGHT_LAYOUTS = [CARDS, BOARD, MAP, CALENDAR, TIMELINE, GANTT];

const CopyFieldValueButton = ({ layout, value }: CopyFieldValueButtonProps) => {
  if (value === undefined) {
    return null;
  }

  return (
    <div
      className={classNames(
        'absolute hidden flex-shrink-0 items-center justify-center text-gray-500 opacity-50 hover:opacity-100 group-hover/field-cell:flex dark:text-gray-100',
        {
          '-left-5 pr-4': !RIGHT_LAYOUTS.includes(layout),
          'right-2 pl-2': RIGHT_LAYOUTS.includes(layout),
        },
      )}
    >
      <CopyButton
        tooltipContent={getText('elements.VIEW.fields.copied')}
        value={value}
        size={16}
      />
    </div>
  );
};

export default CopyFieldValueButton;
