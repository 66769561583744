import createReactComponent from '../createReactComponent';
export default createReactComponent('creative-commons', 'IconCreativeCommons', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10.5 10.5c-.847 -.71 -2.132 -.658 -2.914 .116a1.928 1.928 0 0 0 0 2.768c.782 .774 2.067 .825 2.914 .116',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M16.5 10.5c-.847 -.71 -2.132 -.658 -2.914 .116a1.928 1.928 0 0 0 0 2.768c.782 .774 2.067 .825 2.914 .116',
      key: 'svg-2',
    },
  ],
]);
