import createReactComponent from '../createReactComponent';
export default createReactComponent('bulb-off', 'IconBulbOff', [
  [
    'path',
    {
      d: 'M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M11.089 7.083a5 5 0 0 1 5.826 5.84m-1.378 2.611a5.012 5.012 0 0 1 -.537 .466a3.5 3.5 0 0 0 -1 3a2 2 0 1 1 -4 0a3.5 3.5 0 0 0 -1 -3a5 5 0 0 1 -.528 -7.544',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9.7 17h4.6', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
