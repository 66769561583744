import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { SwitchButton } from '@noloco/components';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import {
  ActionButtonExecutionType,
  MODAL,
  ONE_CLICK,
} from '@noloco/core/src/constants/actionButtons';
import { CREATE, UPDATE } from '@noloco/core/src/constants/actionTypes';
import { IFRAME } from '@noloco/core/src/constants/elements';
import { Action } from '@noloco/core/src/models/Element';
import { getText } from '@noloco/core/src/utils/lang';

type ActionButtonExecutionEditorProps = {
  actions: Action[];
  availableExecutions: ActionButtonExecutionType[];
  execution: ActionButtonExecutionType;
  onChange: (value: ActionButtonExecutionType) => void;
};

const LANG_KEY = 'elements.VIEW.actionButtons.execution';

export const EXECUTION_OPTIONS = [MODAL, ONE_CLICK];

const ActionButtonExecutionEditor = ({
  actions,
  availableExecutions,
  execution,
  onChange,
}: ActionButtonExecutionEditorProps) => {
  const noIFrameActions = useMemo(
    () =>
      !Array.isArray(actions) ||
      actions.every((action: any) => get(action, 'type') !== IFRAME),
    [actions],
  );
  const allFieldsHidden = useMemo(
    () =>
      !Array.isArray(actions) ||
      actions.every(
        (action: any) =>
          ![UPDATE, CREATE].includes(action.type) ||
          get(action, 'formFields', []).every(({ hidden }: any) => hidden),
      ),
    [actions],
  );

  useEffect(() => {
    if ((!noIFrameActions || !allFieldsHidden) && execution === ONE_CLICK) {
      onChange(MODAL);
    }
  }, [allFieldsHidden, noIFrameActions, onChange, execution]);

  return (
    <>
      <BuildModeInput
        inline={true}
        label={getText('elements.VIEW.actionButtons.execution.title')}
      >
        <SwitchButton
          className="h-8 w-full rounded-lg"
          inverseColors={true}
          onChange={(newValue) =>
            onChange(newValue as ActionButtonExecutionType)
          }
          options={availableExecutions.map((availableExecution) => ({
            disabled:
              (!allFieldsHidden || !noIFrameActions) &&
              availableExecution === ONE_CLICK,
            label: getText(LANG_KEY, availableExecution, 'label'),
            value: availableExecution,
          }))}
          value={execution}
        />
      </BuildModeInput>
      <p
        className={classNames('mb-4 mt-2 text-xs text-gray-400', {
          'pb-2': execution === MODAL,
        })}
      >
        {getText(LANG_KEY, execution || MODAL, 'description')}
      </p>
    </>
  );
};

export default ActionButtonExecutionEditor;
