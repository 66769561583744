import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'text-wrap-disabled',
  'IconTextWrapDisabled',
  [
    ['path', { d: 'M4 6l10 0', key: 'svg-0' }],
    ['path', { d: 'M4 18l10 0', key: 'svg-1' }],
    ['path', { d: 'M4 12h17l-3 -3m0 6l3 -3', key: 'svg-2' }],
  ],
);
