import React, { Suspense, lazy, memo, useMemo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import { getInputStyles } from '@noloco/components/src/components/input/TextInput';
import { ROUNDED_LARGE } from '@noloco/components/src/components/input/inputStyles';
import Loader from '@noloco/components/src/components/loading/Loader';

const LazyRichTextEditor = lazy(
  () => import('../../../components/richTextEditor/RichTextEditor'),
);

const SuspenseRichTextEditor = memo((props) => (
  <Suspense fallback={<Loader />}>
    <LazyRichTextEditor {...props} />
  </Suspense>
));

const RichTextInput = ({
  disabled,
  onChange,
  placeholder,
  surface,
  theme,
  value,
}: any) => {
  const inputStyles = useMemo(
    () => getInputStyles({ disabled, theme, surface, style: ROUNDED_LARGE }),
    [disabled, surface, theme],
  );

  return (
    <Box
      className="px-1.5 py-1.5"
      is={SuspenseRichTextEditor}
      {...inputStyles}
      onChange={onChange}
      placeholder={placeholder}
      richTextControls={true}
      shouldFocus={false}
      value={value}
    />
  );
};

export default withTheme(RichTextInput);
