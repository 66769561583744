import { useMemo } from 'react';
import {
  CollectionLayout,
  PIVOT_TABLE,
  TABLE,
  TABLE_FULL,
} from '../../constants/collectionLayouts';

const useIsTable = (layout: CollectionLayout) =>
  useMemo(() => [TABLE, TABLE_FULL, PIVOT_TABLE].includes(layout), [layout]);

export default useIsTable;
