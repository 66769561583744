import { useCallback } from 'react';
import {
  UpdatePropertyCallback,
  useOnCloneTab,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { PAGE } from '../../../constants/elements';
import { DataType } from '../../../models/DataTypes';
import { ElementPath, Section } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { Page } from '../../../utils/pages';
import BuildModeHeader from '../BuildModeHeader';
import BuildModeNavigation from '../BuildModeNavigation';
import BuildModeTabEditor from '../BuildModeTabEditor';

type BuildModePageOptionsTabProps = {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  element: Page;
  elementPath: ElementPath;
  project: Project;
  updateProperty: UpdatePropertyCallback;
};

const BuildModePageOptionsTab = ({
  dataType,
  debouncedUpdateProperty,
  element,
  elementPath,
  project,
  updateProperty,
}: BuildModePageOptionsTabProps) => {
  const { V2 = false, tabs = [], sections = [] } = element.props;

  const updateSections = useCallback(
    (newSections: Section[]) => updateProperty(['sections'], newSections),
    [updateProperty],
  );
  const cloneTabElements = useOnCloneTab(sections, updateSections);

  if (!V2) {
    return null;
  }

  return (
    <>
      <BuildModeHeader
        dataType={dataType}
        debouncedUpdateProperty={debouncedUpdateProperty}
        elementPath={elementPath}
        elementType={PAGE}
        project={project}
        props={element.props}
        updateProperty={updateProperty}
        showBreadcrumbs={false}
        showImage={false}
      />
      <BuildModeTabEditor
        cloneTabElements={cloneTabElements}
        tabs={tabs}
        dataType={dataType}
        debouncedUpdateProperty={debouncedUpdateProperty}
        elementPath={elementPath}
        project={project}
        updateProperty={updateProperty}
      />
      <BuildModeNavigation
        element={element}
        project={project}
        updateProperty={updateProperty}
      />
    </>
  );
};

export default BuildModePageOptionsTab;
