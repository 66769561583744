import React from 'react';
import { EMBED } from '../../../constants/elements';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { RECORD_SCOPE } from '../../../utils/scope';

const RecordEmbed = ({ elementPath, project, recordScope, ...rest }: any) => {
  const { code } = useSectionScopeVariables(
    EMBED,
    rest,
    project,
    elementPath,
    recordScope,
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  if (!code) {
    return null;
  }

  return <div className="w-full" dangerouslySetInnerHTML={{ __html: code }} />;
};

export default RecordEmbed;
