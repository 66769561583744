export type ShirtSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const XS: ShirtSize = 'xs';
export const SM: ShirtSize = 'sm';
export const MD: ShirtSize = 'md';
export const LG: ShirtSize = 'lg';
export const XL: ShirtSize = 'xl';

const tShirtSizes: ShirtSize[] = [SM, MD, LG, XL];

export default tShirtSizes;
