import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'ease-in-control-point',
  'IconEaseInControlPoint',
  [
    ['path', { d: 'M3 19c8 0 18 -16 18 -16', key: 'svg-0' }],
    ['path', { d: 'M17 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0z', key: 'svg-1' }],
    ['path', { d: 'M17 19h-2', key: 'svg-2' }],
    ['path', { d: 'M12 19h-2', key: 'svg-3' }],
  ],
);
