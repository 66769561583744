import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-imac-share',
  'IconDeviceImacShare',
  [
    [
      'path',
      {
        d: 'M12.5 17h-8.5a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v9',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 13h18', key: 'svg-1' }],
    ['path', { d: 'M8 21h4', key: 'svg-2' }],
    ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
    ['path', { d: 'M16 22l5 -5', key: 'svg-4' }],
    ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-5' }],
  ],
);
