import createReactComponent from '../createReactComponent';
export default createReactComponent('vector-off', 'IconVectorOff', [
  [
    'path',
    {
      d: 'M6.68 6.733a1 1 0 0 1 -.68 .267h-2a1 1 0 0 1 -1 -1v-2c0 -.276 .112 -.527 .293 -.708',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M20.72 20.693a1 1 0 0 1 -.72 .307h-2a1 1 0 0 1 -1 -1v-2c0 -.282 .116 -.536 .304 -.718',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M3 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M5 7v10', key: 'svg-4' }],
  ['path', { d: 'M19 7v8', key: 'svg-5' }],
  ['path', { d: 'M9 5h8', key: 'svg-6' }],
  ['path', { d: 'M7 19h10', key: 'svg-7' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-8' }],
]);
