import createReactComponent from '../createReactComponent';
export default createReactComponent('switch-3', 'IconSwitch3', [
  [
    'path',
    {
      d: 'M3 17h2.397a5 5 0 0 0 4.096 -2.133l.177 -.253m3.66 -5.227l.177 -.254a5 5 0 0 1 4.096 -2.133h3.397',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 4l3 3l-3 3', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M3 7h2.397a5 5 0 0 1 4.096 2.133l4.014 5.734a5 5 0 0 0 4.096 2.133h3.397',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M18 20l3 -3l-3 -3', key: 'svg-3' }],
]);
