import T from 'i18n-react';

type LangKeySegment = number | string | boolean;

export const joinLangKey = (...rest: LangKeySegment[]) => rest.join('.');

export const getText = (
  firstKeyOrOptions: LangKeySegment | Record<any, string | number | undefined>,
  ...rest: LangKeySegment[]
): string => {
  if (typeof firstKeyOrOptions === 'object') {
    return T.translate(joinLangKey(...rest), firstKeyOrOptions) as string;
  }

  return T.translate(joinLangKey(firstKeyOrOptions, ...rest)) as string;
};
