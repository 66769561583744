import createReactComponent from '../createReactComponent';
export default createReactComponent('shield-pause', 'IconShieldPause', [
  [
    'path',
    {
      d: 'M13.004 20.692c-.329 .117 -.664 .22 -1.004 .308a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 -.081 7.034',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 17v5', key: 'svg-1' }],
  ['path', { d: 'M21 17v5', key: 'svg-2' }],
]);
