import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'message-exclamation',
  'IconMessageExclamation',
  [
    ['path', { d: 'M8 9h8', key: 'svg-0' }],
    ['path', { d: 'M8 13h6', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M15 18h-2l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M19 16v3', key: 'svg-3' }],
    ['path', { d: 'M19 22v.01', key: 'svg-4' }],
  ],
);
