import createReactComponent from '../createReactComponent';
export default createReactComponent('terminal-2', 'IconTerminal2', [
  ['path', { d: 'M8 9l3 3l-3 3', key: 'svg-0' }],
  ['path', { d: 'M13 15l3 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-2',
    },
  ],
]);
