import { Draft } from 'immer';
import { FILE } from '../constants/builtInDataTypes';
import DataTypeFields from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { buildFormattedReverseRelateField } from '../utils/data';
import { ProjectState, ProjectUpdate } from './project';

export const updateDataFieldHelper = (
  state: Draft<{
    data: null | ProjectState;
    undoStack: ProjectUpdate[];
    redoStack: ProjectUpdate[];
    draftDataTypes: DataTypes | undefined;
  }>,
  dataTypeId: number,
  dataField: any,
) => {
  const typeIndex = state.data?.dataTypes.findIndex(
    ({ id }) => id === dataTypeId,
  );
  const fieldIndex =
    typeIndex !== undefined
      ? state.data?.dataTypes[typeIndex].fields.findIndex(
          ({ id }) => id === dataField.id,
        )
      : undefined;
  if (state.data && typeIndex !== undefined && fieldIndex !== undefined) {
    const previousField = state.data.dataTypes[typeIndex].fields[fieldIndex];
    state.data.dataTypes[typeIndex].fields[fieldIndex] = dataField;

    const isLinkedField = dataField.relationship && dataField.type !== FILE;
    const wasLinkedField =
      previousField.relationship && previousField.type !== FILE;

    if (
      wasLinkedField &&
      (!isLinkedField || previousField.type !== dataField.type)
    ) {
      //delete previous reverse field
      const previousRelatedTypeIndex = state.data.dataTypes.findIndex(
        (types) => types.apiName === previousField.type,
      );
      if (previousRelatedTypeIndex !== -1) {
        state.data.dataTypes[previousRelatedTypeIndex].fields =
          new DataTypeFields(
            state.data.dataTypes[previousRelatedTypeIndex].fields.filter(
              ({ relatedField }) => relatedField?.id !== previousField.id,
            ),
          );
      }
    }
    //upsert new reverse field
    if (isLinkedField) {
      const relatedType = state.data?.dataTypes.find(
        ({ name }) => name === dataField.type,
      );
      if (!relatedType) {
        return;
      }

      const reverseField = buildFormattedReverseRelateField(
        dataField,
        state.data.dataTypes[typeIndex] as DataType,
        relatedType,
      );
      if (wasLinkedField && previousField.type === dataField.type) {
        //update
        const reverseFieldIndex = relatedType.fields.findIndex(
          (f) => f.relatedField && f.relatedField.id === dataField.id,
        );
        if (reverseFieldIndex) {
          relatedType.fields[reverseFieldIndex] = reverseField;
        }
      } else {
        //create
        relatedType.fields.push(reverseField);
      }
    }
    if (dataField.lookupValueFields) {
      //update lookup fields related to this field
      dataField.lookupValueFields.map(({ dataField, dataTypeId }: any) => {
        return updateDataFieldHelper(state, dataTypeId, dataField);
      });
    }
  }
};
