import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import { darkModeColors } from '../../../../constants/darkModeColors';

const CalendarDayColumnWrapper = ({ className, children, theme }: any) => {
  const primaryColor = theme.brandColors.primary;

  return (
    <div
      className={classNames(
        className,
        `relative flex min-h-full w-full flex-col text-sm text-gray-600 dark:${darkModeColors.text.primary}`,
        {
          [`bg-${getColorShade(primaryColor, 100)} bg-opacity-10`]:
            className &&
            (className.includes('rbc-now') || className.includes('rbc-today')),
        },
      )}
    >
      {children}
    </div>
  );
};

export default withTheme(CalendarDayColumnWrapper);
