import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-apple-podcast',
  'IconBrandApplePodcast',
  [
    ['path', { d: 'M18.364 18.364a9 9 0 1 0 -12.728 0', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M11.766 22h.468a2 2 0 0 0 1.985 -1.752l.5 -4a2 2 0 0 0 -1.985 -2.248h-1.468a2 2 0 0 0 -1.985 2.248l.5 4a2 2 0 0 0 1.985 1.752z',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M12 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ],
);
