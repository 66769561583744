import React, { useMemo } from 'react';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { COMMENTS } from '../constants/elements';
import { CUSTOM_VISIBILITY_RULES } from '../constants/features';
import { Element, Section, ViewTab } from '../models/Element';
import { scopeSelector } from '../selectors/dataSelectors';
import { projectDataSelector } from '../selectors/projectSelectors';
import useRouter from '../utils/hooks/useRouter';
import useScopeUser from '../utils/hooks/useScopeUser';
import {
  getAllowedViewForDataType,
  getViewRoutePrefixForView,
} from '../utils/urls';

const commentsSidebarEnabled = (view: Element): boolean =>
  view?.props?.record?.comments?.show === true;

const getCommentsSection = (view: Element): Section | undefined => {
  const sections = get(view, 'props.record.sections', []);

  return sections.find((section: Element) => section?.type === COMMENTS);
};

const commentsAreEnabledForView = (view: Element) => {
  const commentsEnabled = commentsSidebarEnabled(view);
  if (commentsEnabled) {
    return true;
  }

  const section = getCommentsSection(view);

  return !!section;
};

type CommentRedirectProps = {
  firstRoutePath: string | undefined;
};

const CommentRedirect = ({ firstRoutePath }: CommentRedirectProps) => {
  const {
    query: { dataTypeId, recordId },
  } = useRouter();

  const user = useScopeUser();
  const scope = useSelector(scopeSelector);
  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);

  const project = useSelector(projectDataSelector);
  const dataType = project.dataTypes.getById(parseInt(dataTypeId));

  const commentsPath = useMemo(() => {
    if (!dataType) {
      return null;
    }

    const view = getAllowedViewForDataType(
      dataType.name,
      project,
      user,
      scope,
      customRulesEnabled,
      commentsAreEnabledForView,
    );

    if (!view) {
      return null;
    }

    const routePrefix = getViewRoutePrefixForView(view, project);
    const basePath = `${routePrefix}/view/${recordId}`;

    if (commentsSidebarEnabled(view)) {
      return `${basePath}?_comments=true`;
    }

    const commentSection = getCommentsSection(view)!;
    if (commentSection.tab) {
      const tab = (view?.props?.record?.tabs ?? []).find(
        (pageTab: ViewTab) => pageTab.id === commentSection.tab,
      );

      if (tab) {
        const tabPath = kebabCase(tab.title);
        return `${basePath}/${tabPath}`;
      }
    }

    return basePath;
  }, [customRulesEnabled, dataType, project, recordId, scope, user]);

  if (!commentsPath) {
    if (firstRoutePath) {
      return <Redirect to={firstRoutePath} />;
    }

    return null;
  }

  return <Redirect to={commentsPath} />;
};

export default CommentRedirect;
