import createReactComponent from '../createReactComponent';
export default createReactComponent('folder-down', 'IconFolderDown', [
  [
    'path',
    {
      d: 'M12 19h-7a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v3.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 16v6', key: 'svg-1' }],
  ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-2' }],
]);
