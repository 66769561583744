import React, { forwardRef, useCallback, useMemo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import { Badge, Tooltip } from '@noloco/components';
import { ChartAggregation, SUM } from '../../../../constants/chartAggregations';
import {
  BOARD,
  CARDS,
  COLUMNS,
  CollectionLayout,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
} from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import DataTypes, { DataType } from '../../../../models/DataTypes';
import { BaseRecord, RecordEdge } from '../../../../models/Record';
import StateItem from '../../../../models/StateItem';
import { CollectionField } from '../../../../models/View';
import { aggregateDataForCollectionSummary } from '../../../../utils/aggregationDataTypes';
import { getFieldPathFromPath } from '../../../../utils/charts';
import { getColorByIndex } from '../../../../utils/colors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useDarkModeSurface from '../../../../utils/hooks/useDarkModeSurface';
import { getText } from '../../../../utils/lang';
import { FieldConfig } from '../../../../utils/permissions';
import { axisFormatter } from '../../../Chart';
import Checkbox from '../../../Checkbox';
import { Group } from '../../Collection';
import CollectionColumnSummaryCell from '../CollectionColumnSummaryCell';

const DEFAULT_COL_SPAN = 3;

const GROUP_LABEL_STYLES = {
  [ROWS]: (isDarkModeEnabled = false): string =>
    `sticky z-20 top-0 w-full border-b px-2 py-3 h-12 ${
      isDarkModeEnabled
        ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
        : 'bg-gray-50'
    }`,
  [CARDS]: (): string => 'px-2',
  [COLUMNS]: (): string => 'px-2',
  [BOARD]: (): string => '',
  [TABLE]: (isDarkModeEnabled = false): string =>
    `border-b ${
      isDarkModeEnabled
        ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
        : 'bg-gray-50'
    } z-20 sticky w-full shadow-sm`,
  [TABLE_FULL]: (isDarkModeEnabled = false): string =>
    `border-b ${
      isDarkModeEnabled
        ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
        : 'bg-gray-50'
    } z-20 sticky w-full shadow-sm`,
  [SPLIT]: (isDarkModeEnabled = false): string =>
    `sticky z-20 top-0 w-full border-b px-1 py-2 h-12 ${
      isDarkModeEnabled
        ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
        : 'bg-gray-50'
    } `,
};

export interface GroupHeaderProps {
  className?: string;
  bulkActionsEnabled?: boolean;
  dataType: DataType;
  dataTypes: DataTypes;
  group: Group;
  isCollapsed: boolean;
  isTable: boolean;
  layout: CollectionLayout;
  fields: FieldConfig<CollectionField>[];
  firstSummaryIndex: number;
  toggleGroupCollapsedState: (groupKey: string) => () => void;
  selectedRows: BaseRecord[];
  setSelectedRows: (
    setter: (currentValue: BaseRecord[]) => BaseRecord[],
  ) => void;
  [key: string]: any;
  summary?: { field: StateItem; type: ChartAggregation };
}

const getGroupRows = (group: Group): RecordEdge[] =>
  group.groups
    ? group.groups.reduce(
        (acc, group) => [...acc, ...getGroupRows(group)],
        [] as RecordEdge[],
      )
    : (group.rows ?? []);

const GroupHeader = forwardRef<any, GroupHeaderProps>(
  (
    {
      bulkActionsEnabled,
      className,
      dataType,
      dataTypes,
      group,
      isTable,
      isCollapsed,
      fields,
      firstSummaryIndex,
      layout,
      toggleGroupCollapsedState,
      selectedRows,
      setSelectedRows,
      summary,
      ...rest
    },
    ref,
  ) => {
    const [isDarkModeEnabled] = useDarkMode();
    const surface = useDarkModeSurface();
    const Component = isTable ? 'tr' : 'div';
    const rows = useMemo(() => getGroupRows(group), [group]);
    const isRows = layout === ROWS || layout === SPLIT;
    const canMultiGroup = isTable || isRows;
    const isExpandDisabled = canMultiGroup && rows.length === 0;

    const shouldShowBoardSummary = useMemo(
      () => layout === BOARD && summary && summary.field && rows.length > 0,
      [layout, summary, rows],
    );

    const valueFormatter = useCallback(
      (field: Pick<StateItem, 'path' | 'dataType'>, rawValue: any) =>
        axisFormatter(
          field,
          dataType,
          dataTypes,
          rows.map(({ node }) => node),
        )(rawValue),
      [dataType, rows, dataTypes],
    );

    const summaryValue = useMemo(() => {
      if (!shouldShowBoardSummary) {
        return null;
      }

      const { field: summaryField, type = SUM } = summary || {};
      const fieldName = getFieldPathFromPath(summaryField?.path!);
      const field = dataType.fields.getByName(fieldName);

      if (!field) {
        return null;
      }

      return aggregateDataForCollectionSummary(
        fieldName,
        rows,
        type,
        field.type,
        valueFormatter,
      );
    }, [summary, rows, shouldShowBoardSummary, dataType, valueFormatter]);

    const isGroupChecked = useMemo(
      () =>
        bulkActionsEnabled &&
        rows.length > 0 &&
        rows.every((row) =>
          selectedRows.find((selectedRow) => selectedRow.id === row.node.id),
        ),
      [bulkActionsEnabled, rows, selectedRows],
    );

    const colSpan = useMemo(
      () =>
        isTable
          ? Math.min(DEFAULT_COL_SPAN, firstSummaryIndex) +
            (bulkActionsEnabled ? 1 : 0)
          : undefined,
      [isTable, firstSummaryIndex, bulkActionsEnabled],
    );

    const handleCheckboxChange = useCallback(
      (event) => {
        event.stopPropagation();

        const groups = rows.map((row) => row.node);
        setSelectedRows((currentSelectedRows: BaseRecord[]) => {
          const selectionWithoutGroup = currentSelectedRows.filter(
            (row) => !groups.find((group) => group.id === row.id),
          );

          if (isGroupChecked) {
            return selectionWithoutGroup;
          }

          return [...selectionWithoutGroup, ...groups];
        });
      },
      [isGroupChecked, rows, setSelectedRows],
    );

    return (
      <Component
        {...rest}
        className={classNames(
          className,
          'group flex-shrink-0 cursor-pointer truncate text-sm font-medium uppercase text-gray-600',
          {
            'flex items-center': !isTable,
            'text-sm': !isTable && layout !== SPLIT,
            'text-xs': isTable || layout === SPLIT,
          },
          GROUP_LABEL_STYLES[layout](isDarkModeEnabled),
          {
            'top-10': isTable && group.depth === 0,
            'top-18': isTable && group.depth === 1,
            'top-26': isTable && group.depth === 2,
            'top-12': isRows && group.depth === 1,
            'top-24': isRows && group.depth === 2,
          },
        )}
        data-testid="collection-group-header"
        onClick={toggleGroupCollapsedState(group.id)}
        ref={ref}
      >
        <Box
          is={isTable ? 'td' : React.Fragment}
          className={classNames('sticky left-0', {
            [isDarkModeEnabled
              ? darkModeColors.surfaces.elevation1
              : 'bg-gray-50']: isTable,
          })}
          colSpan={colSpan}
        >
          <div
            className={classNames('flex items-stretch', {
              'w-full': layout === BOARD,
            })}
          >
            {(isTable || isRows) && (
              <div
                className={classNames(
                  'w-1 rounded',
                  `bg-${getColorByIndex(group.depth, 400)}`,
                  {
                    'mr-px': bulkActionsEnabled,
                    'ml-2': isRows && group.depth === 1,
                    'ml-4':
                      (isTable && group.depth === 1) ||
                      (isRows && group.depth === 2),
                    'ml-8': isTable && group.depth === 2,
                  },
                )}
              />
            )}
            {bulkActionsEnabled && layout !== BOARD && (
              <div
                className={classNames(
                  'flex w-6 flex-shrink-0 items-center justify-center pl-2 pr-1',
                  { 'ml-auto': !isTable },
                )}
              >
                <Checkbox
                  className={classNames(
                    'flex h-3 w-3 cursor-pointer disabled:opacity-50',
                    {
                      hidden: !isGroupChecked,
                      'group-hover:block': !isGroupChecked && rows.length > 0,
                      block: isGroupChecked,
                      'mb-2': !isTable && layout !== SPLIT && !isGroupChecked,
                    },
                  )}
                  size="sm"
                  disabled={rows.length === 0}
                  checked={isGroupChecked}
                  value={isGroupChecked}
                  elementId={group.id}
                  onChange={handleCheckboxChange}
                />
              </div>
            )}
            <div
              className={classNames('flex items-center space-x-2', {
                'bg-gray-100': layout === BOARD && !isDarkModeEnabled,
                'bg-gray-50': !isDarkModeEnabled && isTable,
                'h-8 w-full rounded-lg px-6 py-2': !shouldShowBoardSummary,
                'h-16 w-full rounded-lg px-6 py-2': shouldShowBoardSummary,
                'pl-0 pr-6': (isTable || isRows) && bulkActionsEnabled,
                'px-6': (isTable || isRows) && !bulkActionsEnabled,
                [darkModeColors.surfaces.elevation1]:
                  isDarkModeEnabled && isTable,
                [darkModeColors.surfaces.elevation2]:
                  layout === BOARD && isDarkModeEnabled,
              })}
            >
              {layout !== BOARD && (
                <button
                  className={classNames(
                    'mr-4 text-gray-800 opacity-75 disabled:opacity-25',
                    { 'hover:opacity-100': !isExpandDisabled },
                  )}
                  disabled={isExpandDisabled}
                >
                  {isCollapsed || isExpandDisabled ? (
                    <IconChevronRight
                      size={16}
                      color={
                        isDarkModeEnabled
                          ? darkModeColors.icons.primary
                          : 'currentColor'
                      }
                    />
                  ) : (
                    <IconChevronDown
                      size={16}
                      color={
                        isDarkModeEnabled
                          ? darkModeColors.icons.primary
                          : 'currentColor'
                      }
                    />
                  )}
                </button>
              )}
              <div className="flex w-full flex-col">
                <div
                  className={classNames('flex w-full items-center space-x-2', {
                    'h-8 justify-between': shouldShowBoardSummary,
                  })}
                >
                  <span
                    data-testid="collection-group-header-label"
                    className={
                      isDarkModeEnabled ? darkModeColors.text.secondary : ''
                    }
                  >
                    {group.label ||
                      group.key ||
                      getText('elements.VIEW.display.groupBy.noValue')}
                  </span>
                  <div className="flex items-center">
                    <Badge
                      className="rounded-full"
                      color={getColorByIndex(group.depth)}
                      variant="secondary"
                    >
                      {rows.length}
                    </Badge>
                  </div>
                </div>
                {shouldShowBoardSummary && (
                  <Tooltip
                    content={
                      <span
                        className={
                          isDarkModeEnabled ? darkModeColors.text.primary : ''
                        }
                      >
                        {`${summary?.field?.display} ${getText('elements.VIEW.fields.groupSummary.summaries', summary?.type!).toLowerCase()}: ${summaryValue}`}
                      </span>
                    }
                    placement="bottom"
                    showArrow={false}
                    surface={surface}
                  >
                    <div className="flex h-4 items-center text-sm text-gray-500">
                      {summaryValue}
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </Box>
        {isTable &&
          fields
            .slice(Math.min(DEFAULT_COL_SPAN, firstSummaryIndex))
            .map(({ config, parent, field }) => (
              <CollectionColumnSummaryCell
                className="py-2"
                config={config}
                field={field}
                key={field.id}
                parent={parent}
                rows={rows}
                valueFormatter={valueFormatter}
              />
            ))}
      </Component>
    );
  },
);

export default GroupHeader;
