import set from 'lodash/fp/set';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { AuthWrapperUser } from './hooks/useAuthWrapper';

export const updateCurrentUserCacheItem = (
  updates: Partial<AuthWrapperUser>,
  currentUser: AuthWrapperUser,
  isGhost: boolean,
  apolloClient: any,
  query: any,
) => {
  if (currentUser) {
    const updatedCurrentUser = {
      id: currentUser.id,
      user: {
        ...currentUser,
        ...updates,
      },
      __typename: 'AuthPayload',
    };
    updateCacheItem(
      updatedCurrentUser,
      apolloClient,
      query,
      isGhost ? 'ghostUser' : 'currentUser',
      {},
    );
  }
};

export const updateCacheItem = (
  updates: any,
  apolloClient: any,
  query: any,
  dataTypeName: any,
  variables: any,
) => {
  const existingItem = apolloClient.readQuery({
    query,
    variables,
  });

  const data = {
    [dataTypeName]: {
      ...get(existingItem, dataTypeName),
      ...updates,
    },
  };
  if (existingItem) {
    try {
      apolloClient.writeQuery({
        query,
        data,
        variables: variables,
      });

      return data;
    } catch (e) {
      console.log('e', e);
    }
  }
};

export const addDataItemToCollectionCache = (
  newItem: any,
  apolloClient: any,
  query: any,
  dataTypeName: any,
  variables: any,
  { collectionPathInput, first = true }: any = {},
) => {
  try {
    const existingCollection = apolloClient.readQuery({
      query,
      variables,
    });
    const dataPath = collectionPathInput || `${dataTypeName}Collection`;
    const key = dataTypeName === 'user' ? 'invite' : 'create';
    if (existingCollection) {
      const newEdge = {
        node: {
          ...newItem[`${key}${upperFirst(dataTypeName)}`],
          __typename: upperFirst(dataTypeName),
        },
        __typename: `${upperFirst(dataTypeName)}Edge`,
      };
      const exists = get(existingCollection, `${dataPath}.edges`, []).find(
        (edge: any) => edge.node.id === newEdge.node.id,
      );
      if (!exists) {
        const newCollection = set(
          `${dataPath}.edges`,
          [
            ...(first ? [newEdge] : []),
            ...(get(existingCollection, `${dataPath}.edges`) ?? []),
            ...(!first ? [newEdge] : []),
          ],
          existingCollection,
        );
        apolloClient.writeQuery({
          query,
          data: newCollection,
          variables: variables,
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateCollectionCacheItem = (
  updatedItem: any,
  apolloClient: any,
  query: any,
  dataTypeName: any,
  variables: any,
  { collectionPathInput }: any = {},
) => {
  try {
    const existingCollection = apolloClient.readQuery({
      query,
      variables,
    });
    const dataPath = collectionPathInput || `${dataTypeName}Collection`;
    if (existingCollection) {
      const collection = get(existingCollection, `${dataPath}.edges`, []);
      const existingItem = collection.find(
        (edge: any) => edge.node.id === updatedItem.id,
      );

      if (!existingItem) {
        return null;
      }

      const existingIndex = collection.indexOf(existingItem);

      const updatedNode = {
        ...existingItem.node,
        ...updatedItem,
        __typename: upperFirst(dataTypeName),
      };
      const newCollection = set(
        `${dataPath}.edges.${existingIndex}.node`,
        updatedNode,
        existingCollection,
      );
      apolloClient.writeQuery({
        query,
        data: newCollection,
        variables: variables,
      });

      return newCollection;
    }
  } catch (e) {
    console.log(e);
  }
};

export const removeDataItemFromCollectionCache = (
  itemId: any,
  apolloClient: any,
  query: any,
  dataTypeName: any,
  variables: any,
  { collectionPathInput }: any = {},
) => {
  try {
    const existingCollection = apolloClient.readQuery({
      query,
      variables,
    });
    const dataPath = collectionPathInput || `${dataTypeName}Collection`;

    if (existingCollection) {
      const newCollection = set(
        `${dataPath}.edges`,
        get(existingCollection, `${dataPath}.edges`, []).filter(
          (edge: any) => edge.node.id !== itemId,
        ),
        existingCollection,
      );

      apolloClient.writeQuery({
        query,
        data: newCollection,
        variables: variables,
      });
    }
  } catch {
    // We don't do anything to explicitly handle
  }
};
