import createReactComponent from '../createReactComponent';
export default createReactComponent('military-award', 'IconMilitaryAward', [
  ['path', { d: 'M12 13m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M8.5 10.5l-1 -2.5h-5.5l2.48 5.788a2 2 0 0 0 1.84 1.212h2.18',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M15.5 10.5l1 -2.5h5.5l-2.48 5.788a2 2 0 0 1 -1.84 1.212h-2.18',
      key: 'svg-2',
    },
  ],
]);
