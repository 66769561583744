import createReactComponent from '../createReactComponent';
export default createReactComponent('logic-nor', 'IconLogicNor', [
  ['path', { d: 'M22 12h-4', key: 'svg-0' }],
  ['path', { d: 'M2 9h5', key: 'svg-1' }],
  ['path', { d: 'M2 15h5', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M6 5c10.667 2.1 10.667 12.6 0 14c1.806 -4.667 1.806 -9.333 0 -14z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M16 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
]);
