import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrows-right-left',
  'IconArrowsRightLeft',
  [
    ['path', { d: 'M21 7l-18 0', key: 'svg-0' }],
    ['path', { d: 'M18 10l3 -3l-3 -3', key: 'svg-1' }],
    ['path', { d: 'M6 20l-3 -3l3 -3', key: 'svg-2' }],
    ['path', { d: 'M3 17l18 0', key: 'svg-3' }],
  ],
);
