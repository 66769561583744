import createReactComponent from '../createReactComponent';
export default createReactComponent('http-connect', 'IconHttpConnect', [
  ['path', { d: 'M7 10a2 2 0 1 0 -4 0v4a2 2 0 1 0 4 0', key: 'svg-0' }],
  ['path', { d: 'M17 16v-8l4 8v-8', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z',
      key: 'svg-2',
    },
  ],
]);
