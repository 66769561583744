import React, { memo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getColorShade } from '@noloco/components';
import { COLLECTION } from '../../../constants/elements';
import { scopeSelector } from '../../../selectors/dataSelectors';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import DataListWrapper from '../../DataListWrapper';

const ViewCollectionCount = memo(
  // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
  ({ className, element, editorMode, elementPath, project, theme }) => {
    const primaryColor = theme.brandColors.primary;
    const scope = useSelector(scopeSelector);
    const { dataList } = useSectionScopeVariables(
      COLLECTION,
      { dataList: element.props.dataList },
      project,
      elementPath,
    );

    return (
      <DataListWrapper
        countOnly={true}
        project={project}
        elementPath={elementPath}
        editorMode={editorMode}
        scope={scope}
        {...dataList}
        autoRefresh={true}
        limit={1}
      >
        {({ loading, totalCount }) =>
          !loading &&
          totalCount !== undefined && (
            <Box
              className={classNames(
                className,
                'flex h-6 min-w-6 items-center justify-center rounded-full p-1.5 text-center text-xs',
              )}
              bg={getColorShade(primaryColor, '800')}
              text={[getColorShade(primaryColor, '200')]}
            >
              {parseInt(totalCount, 10).toLocaleString()}
            </Box>
          )
        }
      </DataListWrapper>
    );
  },
);

export default withTheme(ViewCollectionCount);
