import createReactComponent from '../createReactComponent';
export default createReactComponent('building-tunnel', 'IconBuildingTunnel', [
  [
    'path',
    {
      d: 'M5 21h14a2 2 0 0 0 2 -2v-7a9 9 0 0 0 -18 0v7a2 2 0 0 0 2 2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 21v-9a4 4 0 1 1 8 0v9', key: 'svg-1' }],
  ['path', { d: 'M3 17h4', key: 'svg-2' }],
  ['path', { d: 'M17 17h4', key: 'svg-3' }],
  ['path', { d: 'M21 12h-4', key: 'svg-4' }],
  ['path', { d: 'M7 12h-4', key: 'svg-5' }],
  ['path', { d: 'M12 3v5', key: 'svg-6' }],
  ['path', { d: 'M6 6l3 3', key: 'svg-7' }],
  ['path', { d: 'M15 9l3 -3l-3 3z', key: 'svg-8' }],
]);
