import createReactComponent from '../createReactComponent';
export default createReactComponent('mail-forward', 'IconMailForward', [
  [
    'path',
    {
      d: 'M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 6l9 6l9 -6', key: 'svg-1' }],
  ['path', { d: 'M15 18h6', key: 'svg-2' }],
  ['path', { d: 'M18 15l3 3l-3 3', key: 'svg-3' }],
]);
