import createReactComponent from '../createReactComponent';
export default createReactComponent('dice-3', 'IconDice3', [
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'circle',
    { cx: '8.5', cy: '8.5', r: '.5', fill: 'currentColor', key: 'svg-1' },
  ],
  [
    'circle',
    { cx: '15.5', cy: '15.5', r: '.5', fill: 'currentColor', key: 'svg-2' },
  ],
  [
    'circle',
    { cx: '12', cy: '12', r: '.5', fill: 'currentColor', key: 'svg-3' },
  ],
]);
