export const IS_NOLOCO_DOMAIN = /.+\.noloco(local|qa)?\.(app|dev|co)/.test(
  document.location as unknown as string,
);
export const PROJECT_NAME = IS_NOLOCO_DOMAIN
  ? document.location.hostname.split('.')[0]
  : null;
export const ENV = IS_NOLOCO_DOMAIN
  ? document.location.hostname.split('.').slice(-1)[0]
  : 'app';

export const IS_SERVER = process.env.SERVER === 'true';
