import { useCallback } from 'react';
import { BaseRecord } from '../../models/Record';
import { RECORD_SCOPE } from '../scope';

export const useBuildDataItemRecordScope = (
  formatDataItem: (record: BaseRecord) => BaseRecord,
  transformRecordScope: (
    scope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>,
  dataTypeName: string,
) => {
  const buildRecordScope = useCallback(
    (dataItem: any) => {
      const formattedRecord = formatDataItem(dataItem);
      return transformRecordScope(
        {
          [RECORD_SCOPE]: {
            _dataType: dataTypeName,
            ...formattedRecord,
          },
        },
        formattedRecord,
      );
    },
    [dataTypeName, formatDataItem, transformRecordScope],
  );

  return buildRecordScope;
};
