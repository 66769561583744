import createReactComponent from '../createReactComponent';
export default createReactComponent('vector-bezier-2', 'IconVectorBezier2', [
  [
    'path',
    {
      d: 'M3 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 5l7 0', key: 'svg-2' }],
  ['path', { d: 'M10 19l7 0', key: 'svg-3' }],
  ['path', { d: 'M9 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-4' }],
  ['path', { d: 'M15 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-5' }],
  ['path', { d: 'M7 5.5a5 6.5 0 0 1 5 6.5a5 6.5 0 0 0 5 6.5', key: 'svg-6' }],
]);
