import { useSelector } from 'react-redux';
import { EXPERT } from '@noloco/core/src/constants/accountPlans';
import { FeatureValue } from '@noloco/core/src/constants/features';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import {
  isPartnerTrialExpired,
  isTrialActive,
} from '@noloco/core/src/utils/billing';
import { isFeatureEnabled } from '@noloco/core/src/utils/features';
import { useFeatureUsage } from './useFeatureUsage';

const useIsFeatureEnabled = (
  feature: any,
  options: { blockTrial?: boolean; currentValue?: FeatureValue } = {},
) => {
  const project = useSelector(projectDataSelector);
  const billingPlan = useSelector(billingPlanSelector);
  const currentUsage = useFeatureUsage(feature);

  const hasActiveTrial = isTrialActive(billingPlan);
  const blockTrial = options.blockTrial === true;

  if (blockTrial && hasActiveTrial) {
    return billingPlan?.customConfig?.[feature] === true;
  }

  const isPartnerPlanExpired = isPartnerTrialExpired(billingPlan, project);

  if (billingPlan?.type === EXPERT && isPartnerPlanExpired) {
    return false;
  }

  return isFeatureEnabled(
    billingPlan,
    feature,
    currentUsage ?? options.currentValue,
  );
};

export default useIsFeatureEnabled;
