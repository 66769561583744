import React, { forwardRef, memo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import YouTube from 'react-youtube';

const urlPatterns = [
  '(https:\\/\\/www\\.)?youtube\\.com\\/watch\\?v=([a-zA-Z0-9_-]+)',
  '(https:\\/\\/)?youtu\\.be\\/([a-zA-Z0-9_-]+)',
  '(https:\\/\\/www\\.)?youtube\\.com\\/embed\\/([a-zA-Z0-9_-]+)',
];

const extractVideoId = (potentialVideoId: any) => {
  for (let i = 0; i < urlPatterns.length; i++) {
    const match = potentialVideoId.toString().match(urlPatterns[i]);
    if (match) {
      return match[2];
    }
  }

  return potentialVideoId;
};

const YoutubeVideoElement = memo(
  // @ts-expect-error TS(2339): Property 'autoPlay' does not exist on type '{}'.
  ({ autoPlay, showControls, startSeconds, videoId }) => (
    <>
      {/* @ts-expect-error TS2786: 'YouTube' cannot be used as a JSX component. */}
      <YouTube
        className="absolute left-0 top-0 h-full w-full"
        videoId={extractVideoId(videoId)}
        opts={{
          height: '100%',
          width: '100%',
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: autoPlay ? 1 : 0,
            controls: showControls ? 1 : 0,
            start: startSeconds,
          },
        }}
      />
    </>
  ),
);

type YoutubeVideoProps = {
  className?: string;
  autoPlay?: boolean;
  videoId: number | string;
  showControls?: boolean;
  startSeconds?: number;
};

const YoutubeVideo = forwardRef<any, YoutubeVideoProps>(
  (
    {
      className,
      autoPlay,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Youtube... Remove this comment to see the full error message
      onClick,
      showControls,
      startSeconds,
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'YoutubeVi... Remove this comment to see the full error message
      style,
      videoId,
    },
    ref,
  ) => {
    return (
      <Box ref={ref} className={className} style={style} onClick={onClick}>
        <div className="pb-fluid-video relative h-0">
          <YoutubeVideoElement
            // @ts-expect-error TS(2322): Type '{ autoPlay: boolean | undefined; showControl... Remove this comment to see the full error message
            autoPlay={autoPlay}
            showControls={showControls}
            startSeconds={startSeconds}
            videoId={extractVideoId(videoId)}
          />
        </div>
      </Box>
    );
  },
);

YoutubeVideo.defaultProps = {
  autoPlay: false,
  // @ts-expect-error TS(2322): Type '{ autoPlay: false; children: undefined; clas... Remove this comment to see the full error message
  children: undefined,
  className: '',
  videoId: 'EngW7tLk6R8', // 'https://www.youtube.com/watch?v=EngW7tLk6R8'
  showControls: true,
  startSeconds: 0,
};

export default YoutubeVideo;
