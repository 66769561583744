import { useSelector } from 'react-redux';
import { billingPlanSelector } from '../../selectors/billingPlanSelectors';
import { projectDataSelector } from '../../selectors/projectSelectors';
import { isPartnerTrialExpired } from '../billing';

const useIsPartnerTrialExpired = () => {
  const billingPlan = useSelector(billingPlanSelector);
  const project = useSelector(projectDataSelector);
  const isPartnerPlanExpired = isPartnerTrialExpired(billingPlan, project);

  return isPartnerPlanExpired;
};

export default useIsPartnerTrialExpired;
