import { DATA_PROP } from '../../constants/elementPropTypeTypes';
import { Element } from '../Element';
import ElementPropType from './ElementPropType';

class DataPropType extends ElementPropType {
  calculateDataTypes: (element: Element) => any[];

  constructor(calculateDataTypes: (...varArgs: any) => any[] = () => []) {
    super(DATA_PROP);
    this.calculateDataTypes = calculateDataTypes;
  }

  getDataTypes(element: Element) {
    return this.calculateDataTypes(element);
  }
}

export default DataPropType;
