import { DataField } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';
import { DepValue } from '../models/Element';
import StateItem from '../models/StateItem';

export type DataItemOption = {
  label: any;
  help?: string;
  heading?: boolean;
  value?: StateItem;
  field?: DataField;
  disabled?: boolean;
  options?: DataItemOption[];
  getOptions?: () => DataItemOption[];
  args?: Record<string, any>;
};

export type DataItemValueOption = Omit<DataItemOption, 'heaing' | 'options'> & {
  icon?: any;
  buttonLabel: string[] | string;
  field?: DataField;
  dataType: DataType | undefined;
  options?: DataItemValueOption[];
};

export type SelectItemOption = {
  label: any;
  value?: string;
  disabled?: boolean;
  options?: SelectItemOption[];
  getOptions?: () => SelectItemOption[];
};

export const getElementStateKey = (screen: string, selectedState: string) => {
  if (!screen && !selectedState) {
    return null;
  }

  if (!screen) {
    return selectedState;
  }

  if (!selectedState) {
    return screen;
  }

  return `${screen}.${selectedState}`;
};

export const flattenStateItem = (value: StateItem | DepValue): string =>
  value ? `${value.id}${value.path ? `.${value.path}` : ''}` : '';
