export const FILE_NAME: FileDisplay = 'FILE_NAME';
export const INLINE: FileDisplay = 'INLINE';
export const CIRCLE: FileDisplay = 'CIRCLE';
export const THUMBNAIL: FileDisplay = 'THUMBNAIL';

export type FileDisplay = 'FILE_NAME' | 'CIRCLE' | 'INLINE' | 'THUMBNAIL';

const fileDisplays = [FILE_NAME, CIRCLE, INLINE, THUMBNAIL];

export default fileDisplays;
