import createReactComponent from '../createReactComponent';
export default createReactComponent('arrow-big-left', 'IconArrowBigLeft', [
  [
    'path',
    {
      d: 'M20 15h-8v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1z',
      key: 'svg-0',
    },
  ],
]);
