import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconDeviceFloppy, IconFlask, IconTrash } from '@tabler/icons-react';
import classNames from 'classnames';
import { PropertyPath } from 'lodash';
import set from 'lodash/fp/set';
import isArray from 'lodash/isArray';
import { Button, Loader } from '@noloco/components';
import { DANGER, SECONDARY } from '@noloco/components/src/constants/variants';
import { Endpoint } from '@noloco/core/src/constants/apis';
import { DataSchemaField } from '@noloco/core/src/constants/dataSchema';
import { Project } from '@noloco/core/src/models/Project';
import { isEndpointValid } from '@noloco/core/src/utils/apis';
import { findArrayPaths } from '@noloco/core/src/utils/dataSchema';
import { getText } from '@noloco/core/src/utils/lang';
import ApiEndpointConfigurationEditor from './ApiEndpointConfigurationEditor';
import ApiEndpointFormatEditor from './ApiEndpointFormatEditor';
import ApiEndpointHeadersEditor from './ApiEndpointHeadersEditor';
import ApiEndpointPaginationEditor from './ApiEndpointPaginationEditor';
import ApiEndpointParametersEditor from './ApiEndpointParametersEditor';
import ApiEndpointTestResult from './ApiEndpointTestResult';
import DeleteApiEndpointModal from './DeleteApiEndpointModal';

const LANG_KEY = 'data.api.endpoints';

type ApiEndpointEditorTab =
  | 'configuration'
  | 'headers'
  | 'parameters'
  | 'pagination'
  | 'format';

type Props = {
  api: any;
  data: any;
  endpoint: Endpoint;
  endpointOptions: any[];
  error: any;
  isSaving: boolean;
  onDeleteEndpoint: () => void;
  onUpdateEndpoint: (endpoint: Endpoint) => void;
  onTest: (endpoint: Endpoint) => Promise<void>;
  project: Project;
};

const ApiEndpointEditor = ({
  data,
  error,
  endpoint,
  endpointOptions,
  isSaving,
  onDeleteEndpoint,
  onUpdateEndpoint,
  onTest,
}: Props) => {
  const [draftEndpoint, setDraftEndpoint] = useState(endpoint);

  const [activeTab, setActiveTab] =
    useState<ApiEndpointEditorTab>('configuration');
  const onClickTab = useCallback(
    (tab: ApiEndpointEditorTab) => () => setActiveTab(tab),
    [setActiveTab],
  );

  const canSave = useMemo(
    () => isEndpointValid(draftEndpoint) && !error,
    [draftEndpoint, error],
  );
  const onSave = useCallback(
    () => onUpdateEndpoint(draftEndpoint),
    [draftEndpoint, onUpdateEndpoint],
  );

  const [testing, setTesting] = useState(false);
  const onClickTest = useCallback(() => {
    setTesting(true);
    onTest(draftEndpoint).finally(() => setTesting(false));
  }, [draftEndpoint, onTest]);

  const arrayPaths = useMemo(() => {
    if (isArray(data)) {
      return [];
    }

    const arrayPaths = findArrayPaths(data);
    if (arrayPaths.length === 0) {
      return null;
    }

    return arrayPaths;
  }, [data]);

  const resultPathOptions = useMemo(() => {
    if (arrayPaths === null) {
      return [];
    }

    return arrayPaths.map((arrayPath: string[]) => ({
      value: arrayPath,
      label: <span className="font-mono">{arrayPath.join(' > ')}</span>,
    }));
  }, [arrayPaths]);

  useEffect(() => {
    if (arrayPaths !== null && draftEndpoint.resultPath === null) {
      if (arrayPaths.length === 0) {
        setDraftEndpoint(set('resultPath', [], draftEndpoint));
      }

      if (arrayPaths.length === 1) {
        setDraftEndpoint(set('resultPath', arrayPaths[0], draftEndpoint));
      }
    }
  }, [arrayPaths, draftEndpoint]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div className="h-full w-full">
      <div className="flex w-full border-b border-gray-100 bg-slate-800">
        <div className="flex text-white">
          <div
            className={classNames('cursor-pointer p-4 hover:bg-slate-700', {
              'bg-slate-900': activeTab === 'configuration',
            })}
            onClick={onClickTab('configuration')}
          >
            {getText(LANG_KEY, 'tabs.configuration')}
          </div>
          <div
            className={classNames('cursor-pointer p-4 hover:bg-slate-700', {
              'bg-slate-900': activeTab === 'headers',
            })}
            onClick={onClickTab('headers')}
          >
            {getText(LANG_KEY, 'tabs.headers')}
          </div>
          <div
            className={classNames('cursor-pointer p-4 hover:bg-slate-700', {
              'bg-slate-900': activeTab === 'parameters',
            })}
            onClick={onClickTab('parameters')}
          >
            {getText(LANG_KEY, 'tabs.parameters')}
          </div>
          <div
            className={classNames('cursor-pointer p-4 hover:bg-slate-700', {
              'bg-slate-900': activeTab === 'pagination',
            })}
            onClick={onClickTab('pagination')}
          >
            {getText(LANG_KEY, 'tabs.pagination')}
          </div>
          <div
            className={classNames('cursor-pointer p-4 hover:bg-slate-700', {
              'bg-slate-900': activeTab === 'format',
            })}
            onClick={onClickTab('format')}
          >
            {getText(LANG_KEY, 'tabs.format')}
          </div>
        </div>
        <div className="my-auto ml-auto mr-2 flex space-x-2">
          <Button
            className="flex space-x-1"
            disabled={testing || !draftEndpoint.path}
            onClick={onClickTest}
            variant={SECONDARY}
          >
            <span className="my-auto">{getText(LANG_KEY, 'test.button')}</span>
            {testing ? (
              <Loader size="xs" className="ml-2" />
            ) : (
              <IconFlask className="ml-2 opacity-75" size={16} />
            )}
          </Button>
          <Button
            className="flex space-x-1"
            disabled={!canSave}
            onClick={onSave}
          >
            <span>{getText(LANG_KEY, 'save')}</span>
            {isSaving ? (
              <Loader size="xs" className="ml-2" />
            ) : (
              <IconDeviceFloppy className="ml-2 opacity-75" size={16} />
            )}
          </Button>
          <Button
            className="flex space-x-1"
            onClick={() => setShowDeleteModal(true)}
            variant={DANGER}
          >
            <span className="my-auto">
              {getText(LANG_KEY, 'delete.button')}
            </span>
            <IconTrash className="ml-2 opacity-75" size={16} />
          </Button>
        </div>
      </div>
      <div className="max-h-screen-75 flex h-full w-full flex-grow items-center justify-center overflow-hidden border-b">
        {activeTab === 'configuration' && (
          <ApiEndpointConfigurationEditor
            endpoint={draftEndpoint}
            onUpdateEndpoint={setDraftEndpoint}
          />
        )}
        {activeTab === 'headers' && (
          <ApiEndpointHeadersEditor
            endpoint={draftEndpoint}
            onUpdateEndpoint={setDraftEndpoint}
          />
        )}
        {activeTab === 'parameters' && (
          <ApiEndpointParametersEditor
            endpoint={draftEndpoint}
            onUpdateEndpoint={setDraftEndpoint}
          />
        )}
        {activeTab === 'pagination' && (
          <ApiEndpointPaginationEditor
            data={data}
            endpoint={draftEndpoint}
            onUpdateEndpoint={setDraftEndpoint}
          />
        )}
        {activeTab === 'format' && (
          <ApiEndpointFormatEditor
            fields={draftEndpoint.fields}
            idField={draftEndpoint.idField}
            onUpdateIdField={(value: string) =>
              setDraftEndpoint(set('idField', value, draftEndpoint))
            }
            onUpdateResultPath={(value: PropertyPath) =>
              setDraftEndpoint(set('resultPath', value, draftEndpoint))
            }
            resultPath={draftEndpoint.resultPath}
            resultPathOptions={resultPathOptions}
          />
        )}
      </div>
      {showDeleteModal && (
        <DeleteApiEndpointModal
          endpoint={draftEndpoint}
          onClose={() => setShowDeleteModal(false)}
          onDelete={onDeleteEndpoint}
        />
      )}
      <div className="max-h-screen-25 flex h-screen flex-shrink-0 overflow-x-auto overflow-y-auto">
        <ApiEndpointTestResult
          data={data}
          endpointOptions={endpointOptions}
          error={error}
          fields={draftEndpoint.fields}
          idField={draftEndpoint.idField}
          onOpenFormatTab={onClickTab('format')}
          onUpdateFields={(value: DataSchemaField[]) =>
            setDraftEndpoint(set('fields', value, draftEndpoint))
          }
          resultPath={draftEndpoint.resultPath}
        />
      </div>
    </div>
  );
};

export default ApiEndpointEditor;
