import createReactComponent from '../createReactComponent';
export default createReactComponent('map-pin-off', 'IconMapPinOff', [
  [
    'path',
    {
      d: 'M9.442 9.432a3 3 0 0 0 4.113 4.134m1.445 -2.566a3 3 0 0 0 -3 -3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17.152 17.162l-3.738 3.738a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 0 1 -.476 -10.794m2.18 -1.82a8.003 8.003 0 0 1 10.91 10.912',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
