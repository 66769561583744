import createReactComponent from '../createReactComponent';
export default createReactComponent('certificate', 'IconCertificate', [
  ['path', { d: 'M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M13 17.5v4.5l2 -1.5l2 1.5v-4.5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M6 9l12 0', key: 'svg-3' }],
  ['path', { d: 'M6 12l3 0', key: 'svg-4' }],
  ['path', { d: 'M6 15l2 0', key: 'svg-5' }],
]);
