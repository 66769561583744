import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-beats', 'IconBrandBeats', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12.5 12.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 12v-8', key: 'svg-2' }],
]);
