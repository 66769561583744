import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-tablet-off',
  'IconDeviceTabletOff',
  [
    [
      'path',
      {
        d: 'M7 3h11a1 1 0 0 1 1 1v11m0 4v1a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1v-15',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M11 17a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-1' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
  ],
);
