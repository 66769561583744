import createReactComponent from '../createReactComponent';
export default createReactComponent('scale-outline', 'IconScaleOutline', [
  [
    'path',
    {
      d: 'M3 3m0 4a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 7c1.956 0 3.724 .802 5 2.095l-2.956 2.904a3 3 0 0 0 -2.038 -.799a3 3 0 0 0 -2.038 .798l-2.956 -2.903a6.979 6.979 0 0 1 5 -2.095z',
      key: 'svg-1',
    },
  ],
]);
