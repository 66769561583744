import React, { useMemo } from 'react';
import classNames from 'classnames';
import { SIDE_MENU } from '../../constants/menuStyles';
import DraggableSidebarItem from '../../elements/DraggableSidebarItem';
import { replaceDoubleSlashes } from '../../utils/pages';
import SidebarItem from '../SidebarItem';
import withVisibilityRules from '../canvas/withVisibilityRules';

const SubNavItem = ({
  debouncedUpdateProperty,
  editorMode,
  elementPath,
  editingPage,
  index,
  handleSetEditingPage,
  menuStyle,
  parentTo,
  page,
  pagesPath,
  portalPath,
  portalPages,
  project,
  onEditSelectPage,
  splitPathname,
  showExpandedNav,
  updateProject,
  updateProperty,
}: any) => {
  const SubSidebarItemVisibilityRules = useMemo(
    () =>
      editorMode && page.id === editingPage
        ? DraggableSidebarItem
        : withVisibilityRules(
            DraggableSidebarItem,
            editorMode,
            page.visibilityRules,
          ),
    [editingPage, editorMode, page.id, page.visibilityRules],
  );
  const childIndex = useMemo(
    () => portalPages.indexOf(page),
    [page, portalPages],
  );

  const subTo = useMemo(
    () => replaceDoubleSlashes(`${parentTo}/${page.props.routePath}`),
    [page.props.routePath, parentTo],
  );

  const subActive = useMemo(
    () =>
      subTo
        .split('/')
        .every(
          (pathSegment, index) =>
            splitPathname.length - 1 >= index &&
            pathSegment === splitPathname[index],
        ),
    [splitPathname, subTo],
  );

  return (
    <SubSidebarItemVisibilityRules
      draggable={editorMode}
      key={page.id}
      navId={page.id}
      page={page}
      path={[...pagesPath, index]}
      project={project}
      updateProject={updateProject}
    >
      <SidebarItem
        className={classNames({ 'w-full': menuStyle === SIDE_MENU })}
        active={subActive}
        allowSubPages={false}
        debouncedUpdateProperty={debouncedUpdateProperty}
        depth={1}
        editingPage={editingPage}
        elementPath={elementPath}
        editorMode={editorMode}
        index={childIndex}
        isNavExpanded={showExpandedNav}
        onEditSelectPage={onEditSelectPage}
        page={page}
        portalPath={portalPath}
        project={project}
        setEditingPage={handleSetEditingPage}
        to={subTo}
        updateProperty={updateProperty}
      />
    </SubSidebarItemVisibilityRules>
  );
};

export default SubNavItem;
