import { useCallback, useMemo, useRef } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { AutocompleteTextInput } from '@noloco/components/src/components/input';
import { TextInputProps } from '@noloco/components/src/components/input/TextInput';
import { Theme } from '@noloco/components/src/models';
import { getAddressComponentsObject } from '../../../../utils/addressAutocomplete';

type Props = TextInputProps & {
  onChange: (path: string[], nextValue: any) => void;
  theme: Theme;
  mapsApiKey: string;
  isObjectInput?: boolean;
  isMultiline?: boolean;
};

const AddressAutocompleteInput = ({
  disabled,
  readOnly,
  onChange,
  surface,
  value,
  placeholder,
  isObjectInput = true,
  isMultiline = false,
  mapsApiKey,
  ...rest
}: Props) => {
  const isLoading = useRef(true);
  const setIsLoading = (nextValue: boolean) => {
    isLoading.current = nextValue;
  };

  const {
    placePredictions,
    placesService,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesAutocompleteService({ apiKey: mapsApiKey });

  const onPlaceSelected = useCallback(
    (e: any) => {
      if (!placesService) return;

      placesService.getDetails(
        { placeId: e.value.place_id },
        (placeDetails: any) => {
          const addressComponents = placeDetails?.address_components || [];
          const addressValue = isObjectInput
            ? getAddressComponentsObject(addressComponents)
            : placeDetails?.formatted_address;
          onChange([], addressValue);
        },
      );
      setIsLoading(false);
    },
    [isObjectInput, onChange, placesService],
  );

  const placeOptions = useMemo(
    () =>
      placePredictions.map((prediction: any) => ({
        value: prediction,
        label: prediction.description,
        onClick: onPlaceSelected,
      })),
    [placePredictions, onPlaceSelected],
  );

  const handleChange = useCallback(
    (nextValue: any) => {
      if (typeof nextValue.target.value !== 'object') {
        getPlacePredictions({ input: nextValue.target.value });
        onChange(['street'], nextValue.target.value);
      }
    },
    [onChange, getPlacePredictions],
  );

  const formattedValue = useMemo(() => {
    if (value && isMultiline && !isLoading.current) {
      return String(value).replace(/,\s*/g, ',\n');
    }
    return value || '';
  }, [value, isMultiline]);

  return (
    <AutocompleteTextInput
      disabled={disabled}
      readOnly={readOnly}
      onChange={handleChange}
      options={placeOptions}
      loadingOptions={isPlacePredictionsLoading}
      rows={isMultiline ? String(formattedValue).split('\n').length : 1}
      isLoading={isLoading.current}
      isMultiline={isMultiline}
      setIsLoading={setIsLoading}
      surface={surface}
      placeholder={placeholder}
      value={formattedValue}
      {...rest}
    />
  );
};

export default withTheme(AddressAutocompleteInput);
