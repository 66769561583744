import React, { forwardRef } from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
  items: string | number | React.ReactNode;
  elementPath: (string | number)[];
};

// @ts-expect-error TS(2339): Property 'loading' does not exist on type 'Props'.
const Content = forwardRef<any, Props>(({ items, loading }, ref) => {
  return (
    <span
      className={classNames({
        'animate-pulse rounded bg-gray-400':
          loading && (!items || (items as any).length === 0),
      })}
      ref={ref}
    >
      {(items || !loading) && items}
      {loading && (!items || (items as any).length === 0) && (
        <span className="text-gray-400">------------</span>
      )}
    </span>
  );
});

Content.defaultProps = {
  className: '',
};

export default Content;
