import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'letter-case-lower',
  'IconLetterCaseLower',
  [
    [
      'path',
      {
        d: 'M6.5 15.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M10 12v7', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M17.5 15.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M21 12v7', key: 'svg-3' }],
  ],
);
