import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-check',
  'IconDeviceDesktopCheck',
  [
    [
      'path',
      {
        d: 'M12 16h-8a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-1' }],
    ['path', { d: 'M7 20h4', key: 'svg-2' }],
    ['path', { d: 'M9 16v4', key: 'svg-3' }],
  ],
);
