import React, { forwardRef, useMemo } from 'react';
import {
  TailwindThemeProvider,
  withTheme,
} from '@darraghmckay/tailwind-react-ui';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { DARK, LIGHT } from '../../constants/surface';
import { MD, ShirtSize } from '../../constants/tShirtSizes';
import { PRIMARY } from '../../constants/variants';
import { Theme, ThemeVariant } from '../../models/index';
import { getColorShade } from '../../utils';
import Button from '../button/Button';
import { Loader } from '../loading';
import BaseModal from './BaseModal';

type Props = {
  additionalFooterButtons?: React.ReactNode;
  children?: string | React.ReactNode;
  className?: string;
  cancelText?: string | React.ReactNode;
  confirmText?: string | React.ReactNode;
  title: string | React.ReactNode;
  closeOnOutsideClick?: boolean;
  contextual?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  onCancel?: (...args: any[]) => any;
  onConfirm?: (...args: any[]) => any;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  confirmDisabled?: boolean;
  open?: boolean;
  canCancel?: boolean;
  canConfirm?: boolean;
  rootSelector?: string;
  theme: Theme;
  size?: ShirtSize;
  variant?: ThemeVariant;
};

const stopPropagation = (e: any) => {
  try {
    if (
      !e.target ||
      !get(e, ['target', 'className'], '').includes('editor-listitem')
    ) {
      e.stopPropagation();
    }
  } catch (error) {
    console.log('Error stopping propagation. Target:', e.target);
  }
};

const Modal = forwardRef<any, Props>(
  (
    {
      additionalFooterButtons,
      className,
      children,
      canCancel = true,
      canConfirm = true,
      cancelText = 'Cancel',
      confirmText = 'Okay',
      closeOnOutsideClick = true,
      confirmDisabled = false,
      contextual = false,
      loading = false,
      open = true,
      onConfirm,
      onCancel,
      onClose,
      icon,
      size = MD,
      variant = PRIMARY as ThemeVariant,
      theme,
      title,
      rootSelector = '#root',
      ...rest
    },
    ref,
  ) => {
    const handleConfirm = (event: any) => {
      if (!confirmDisabled) {
        if (onConfirm) {
          onConfirm(event);
        }
      }
    };

    const handleCancel = (event: any) => {
      if (onCancel) {
        onCancel(event);
      }
    };

    const variantColor = theme.brandColors[variant];
    const iconBg = getColorShade(variantColor, '200');

    const lightTheme = useMemo(() => {
      const lightThemeObject = {
        surface: LIGHT,
        textColor: 'text-gray-800',
      };
      return set(
        'textInput',
        lightThemeObject,
        set(
          'selectInput',
          lightThemeObject,
          set('dropzone', lightThemeObject, theme),
        ),
      );
    }, [theme]);

    const darkTheme = useMemo(() => {
      const lightThemeObject = {
        surface: DARK,
        textColor: 'white',
      };
      return set(
        'textInput',
        lightThemeObject,
        set(
          'selectInput',
          lightThemeObject,
          set('dropzone', lightThemeObject, theme),
        ),
      );
    }, [theme]);

    return (
      <BaseModal
        className={className}
        closeOnOutsideClick={closeOnOutsideClick}
        contextual={contextual}
        onClose={onClose}
        open={open}
        ref={ref}
        rootSelector={rootSelector}
        size={size}
        {...rest}
      >
        <TailwindThemeProvider
          theme={theme?.modal?.surface === DARK ? darkTheme : lightTheme}
        >
          <div
            className={`flex w-full flex-shrink-0 p-6 pb-4 sm:p-4 ${
              theme?.modal?.backgroundColor
            } border-b ${theme?.modal?.borderColor ?? ''}`}
            onClick={stopPropagation}
          >
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center">
                {icon && (
                  <div
                    className={`ml-0 mr-4 flex flex-shrink-0 items-center justify-center rounded-full sm:h-12 sm:w-12 bg-${iconBg} h-10 w-10 bg-opacity-75 text-gray-700 sm:mx-auto`}
                  >
                    {icon}
                  </div>
                )}
                <h3
                  className={`text-lg font-medium leading-6 ${theme?.modal?.textColor}`}
                  id="modal-headline"
                >
                  {title}
                </h3>
              </div>
              {canCancel && (
                <IconX
                  onClick={handleCancel}
                  className="cursor-pointer text-slate-400"
                  size={16}
                />
              )}
            </div>
          </div>
          {children && (
            <div
              className={`overflow-y-auto p-6 pb-4 pt-0 sm:px-4 sm:pb-4 ${theme?.modal?.backgroundColor} sm:h-screen`}
              onClick={stopPropagation}
            >
              {children && (
                <div className="mt-2">
                  <div
                    className={`modal-body flex flex-grow flex-col text-sm leading-5 ${theme?.modal?.secondaryTextColor}`}
                  >
                    {children}
                  </div>
                </div>
              )}
            </div>
          )}
          {(canConfirm || canCancel) && (
            <div
              className={classNames(
                `flex flex-shrink-0 justify-end space-x-3 border-t px-6 py-3 sm:px-4`,
                theme?.modal?.secondaryBackgroundColor,
                theme?.modal?.borderColor,
                {
                  'sm:grid sm:grid-cols-2 sm:gap-2 sm:space-x-0':
                    (canCancel && canConfirm) || additionalFooterButtons,
                },
              )}
              onClick={stopPropagation}
            >
              {canCancel && (
                <span className="mt-0 flex w-auto sm:w-full">
                  <Button
                    disabled={loading}
                    onClick={handleCancel}
                    type="text"
                    className="w-full hover:bg-gray-200"
                    data-testid="modal-cancel"
                    variant="secondary"
                  >
                    {cancelText}
                  </Button>
                </span>
              )}
              {additionalFooterButtons}
              {canConfirm && (
                <span className="flex w-auto shadow-sm sm:w-full">
                  <Button
                    disabled={confirmDisabled || loading}
                    variant={variant}
                    className="w-full border border-transparent text-sm font-medium leading-5 shadow-sm sm:text-base sm:leading-6"
                    data-testid="modal-confirm"
                    onClick={handleConfirm}
                  >
                    {!loading ? (
                      confirmText
                    ) : (
                      <Loader className="mx-auto" size="sm" />
                    )}
                  </Button>
                </span>
              )}
            </div>
          )}
        </TailwindThemeProvider>
      </BaseModal>
    );
  },
);

export default withTheme(Modal);
