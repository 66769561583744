import createReactComponent from '../createReactComponent';
export default createReactComponent('beer', 'IconBeer', [
  [
    'path',
    {
      d: 'M9 21h6a1 1 0 0 0 1 -1v-3.625c0 -1.397 .29 -2.775 .845 -4.025l.31 -.7c.556 -1.25 .845 -2.253 .845 -3.65v-4a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v4c0 1.397 .29 2.4 .845 3.65l.31 .7a9.931 9.931 0 0 1 .845 4.025v3.625a1 1 0 0 0 1 1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 8h12', key: 'svg-1' }],
]);
