import createReactComponent from '../createReactComponent';
export default createReactComponent('calendar-share', 'IconCalendarShare', [
  [
    'path',
    {
      d: 'M12 21h-6a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3v4', key: 'svg-1' }],
  ['path', { d: 'M8 3v4', key: 'svg-2' }],
  ['path', { d: 'M4 11h16', key: 'svg-3' }],
  ['path', { d: 'M16 22l5 -5', key: 'svg-4' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-5' }],
]);
