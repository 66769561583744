import createReactComponent from '../createReactComponent';
export default createReactComponent('bible', 'IconBible', [
  [
    'path',
    { d: 'M19 4v16h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12z', key: 'svg-0' },
  ],
  ['path', { d: 'M19 16h-12a2 2 0 0 0 -2 2', key: 'svg-1' }],
  ['path', { d: 'M12 7v6', key: 'svg-2' }],
  ['path', { d: 'M10 9h4', key: 'svg-3' }],
]);
