import createReactComponent from '../createReactComponent';
export default createReactComponent('speedboat', 'IconSpeedboat', [
  [
    'path',
    {
      d: 'M3 17h13.4a3 3 0 0 0 2.5 -1.34l3.1 -4.66h0h-6.23a4 4 0 0 0 -1.49 .29l-3.56 1.42a4 4 0 0 1 -1.49 .29h-3.73h0h-1l-1.5 4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 13l1.5 -5', key: 'svg-1' }],
  ['path', { d: 'M6 8h8l2 3', key: 'svg-2' }],
]);
