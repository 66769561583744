import React, { useCallback, useState } from 'react';
import { IconTableImport } from '@tabler/icons-react';
import { Button } from '@noloco/components';
import { ButtonType } from '@noloco/components/src/components/button/buttonTypes';
import { ShirtSize } from '@noloco/components/src/constants/tShirtSizes';
import { Variant } from '@noloco/components/src/constants/variants';
import { DataType } from '../../../models/DataTypes';
import { Project } from '../../../models/Project';
import { ImportField } from '../../../models/View';
import { getText } from '../../../utils/lang';
import ImportModal from './ImportModal';

const BASE_LANG_KEY = 'data.import';

type Props = {
  buttonText?: string;
  buttonType?: string;
  dataType: DataType;
  fields?: ImportField[];
  project: Project;
  size?: ShirtSize;
  type?: ButtonType;
  variant?: Variant;
};

const ImportButton: React.FunctionComponent<Props> = ({
  buttonText,
  buttonType,
  fields,
  dataType,
  project,
  variant = 'primary',
  type = variant === 'primary' ? 'outline' : buttonType,
  size,
}) => {
  const [showImportModal, setShowImportModal] = useState(false);

  const onClose = useCallback(() => {
    setShowImportModal(false);
  }, []);

  return (
    <>
      <Button
        className="import-button flex items-center space-x-2"
        onClick={() => setShowImportModal(true)}
        type={type}
        variant={variant}
        size={size}
      >
        <IconTableImport className="mr-2 opacity-75" size={16} />
        <span>{buttonText || getText(BASE_LANG_KEY, 'button')}</span>
      </Button>
      {showImportModal && (
        <ImportModal
          fields={fields}
          dataType={dataType}
          onClose={onClose}
          project={project}
        />
      )}
    </>
  );
};

export default ImportButton;
