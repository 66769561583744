import { useCallback, useMemo } from 'react';
import set from 'lodash/fp/set';
import shortId from 'shortid';
import { SelectInput } from '@noloco/components/src';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import chartAggregations, {
  COUNT,
  ChartAggregation,
} from '../../../constants/chartAggregations';
import { PIVOT_TABLE } from '../../../constants/collectionLayouts';
import { PivotTable, getRowGrouping } from '../../../constants/pivotTable';
import { DataType } from '../../../models/DataTypes';
import { DepValue, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import StateItem from '../../../models/StateItem';
import { GroupBy } from '../../../models/View';
import { getText } from '../../../utils/lang';
import { BuildModeGroupByInput } from '../BuildModeGroupByInput';
import BuildModeSwitchSection from '../BuildModeSwitchSection';

const LANG_KEY = 'elements.VIEW';

type BuildModePivotTableEditorProps = {
  canMultiGroup: boolean;
  dataType: DataType;
  hasAdvancedGrouping: boolean;
  hideEmptyGroups: boolean;
  pivotTable: PivotTable;
  project: Project;
  stateItem: StateItem;
  updateProperty: UpdatePropertyCallback;
};

const BuildModePivotTableEditor = ({
  canMultiGroup,
  dataType,
  hasAdvancedGrouping,
  hideEmptyGroups,
  pivotTable,
  project,
  stateItem,
  updateProperty,
}: BuildModePivotTableEditorProps) => {
  const {
    aggregation = COUNT,
    columnGrouping = [],
    showTableAggregation = true,
  } = pivotTable;
  const rowGrouping = useMemo(() => getRowGrouping(pivotTable!), [pivotTable]);

  const groupByGroups: GroupBy[] = useMemo(() => {
    if (columnGrouping.length > 0) {
      return columnGrouping;
    }

    return [{ id: 'new' }];
  }, [columnGrouping]);

  const aggregationOptions = useMemo(
    () =>
      chartAggregations.map((aggregationOption) => ({
        value: aggregationOption,
        label: getText('elements.CHART.aggregation', aggregationOption),
      })),
    [],
  );

  const onChangePivotTableProps = useCallback(
    (path, value) => updateProperty(['pivotTable', ...path], value),
    [updateProperty],
  );

  const updateGroupsProperty = useCallback(
    (grouping) => (path: ElementPath, value: DepValue) => {
      if (
        grouping === 'rowGrouping' &&
        !Array.isArray(pivotTable.rowGrouping)
      ) {
        // To support pivot tables that have single row grouping field
        const arrayValue = [
          { field: pivotTable.rowGrouping as DepValue, id: shortId.generate() },
        ];

        // Update either the field or the sort order
        const nextValue = set(path, value, arrayValue);

        return updateProperty(['pivotTable', 'rowGrouping'], nextValue);
      }

      updateProperty(['pivotTable', grouping, ...path], value);
    },
    [updateProperty, pivotTable],
  );

  return (
    <>
      <BuildModeGroupByInput
        canMultiGroup={false}
        clearable={false}
        dataType={dataType}
        dataTypes={project.dataTypes}
        groupByGroups={[rowGrouping]}
        groups={[rowGrouping]}
        hasAdvancedGrouping={false}
        hideEmptyGroups={false}
        label={getText(LANG_KEY, 'pivotTable.rowGrouping')}
        layout={PIVOT_TABLE}
        stateItem={stateItem}
        updateProperty={updateGroupsProperty('rowGrouping')}
      />
      <BuildModeGroupByInput
        canMultiGroup={canMultiGroup}
        clearable={false}
        dataType={dataType}
        dataTypes={project.dataTypes}
        groupByGroups={groupByGroups}
        groups={columnGrouping}
        hasAdvancedGrouping={hasAdvancedGrouping}
        hideEmptyGroups={hideEmptyGroups}
        label={getText(LANG_KEY, 'pivotTable.columnGrouping')}
        layout={PIVOT_TABLE}
        stateItem={stateItem}
        updateProperty={updateGroupsProperty('columnGrouping')}
      />
      <BuildModeSwitchSection
        label={getText(LANG_KEY, 'pivotTable.showTableSummary')}
        onChange={(value) =>
          onChangePivotTableProps(['showTableAggregation'], value)
        }
        value={showTableAggregation}
      >
        <SelectInput
          className="m-2"
          onChange={(aggregation: ChartAggregation) =>
            onChangePivotTableProps(['aggregation'], aggregation)
          }
          options={aggregationOptions}
          placeholder={getText(LANG_KEY, 'pivotTable.tableSummaryType')}
          value={aggregation}
        />
      </BuildModeSwitchSection>
    </>
  );
};

export default BuildModePivotTableEditor;
