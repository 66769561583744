import createReactComponent from '../createReactComponent';
export default createReactComponent('coin-bitcoin', 'IconCoinBitcoin', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 8h4.09c1.055 0 1.91 .895 1.91 2s-.855 2 -1.91 2c1.055 0 1.91 .895 1.91 2s-.855 2 -1.91 2h-4.09',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M10 12h4', key: 'svg-2' }],
  ['path', { d: 'M10 7v10v-9', key: 'svg-3' }],
  ['path', { d: 'M13 7v1', key: 'svg-4' }],
  ['path', { d: 'M13 16v1', key: 'svg-5' }],
]);
