import { DATA_TYPE_NAME } from '../../constants/elementPropTypeTypes';
import ElementPropType from './ElementPropType';

class DataTypeNamePropType extends ElementPropType {
  constructor() {
    super(DATA_TYPE_NAME);
  }
}

export default DataTypeNamePropType;
