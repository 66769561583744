import createReactComponent from '../createReactComponent';
export default createReactComponent('binary-tree', 'IconBinaryTree', [
  ['path', { d: 'M6 20a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-0' }],
  ['path', { d: 'M16 4a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-1' }],
  ['path', { d: 'M16 20a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-2' }],
  ['path', { d: 'M11 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-3' }],
  ['path', { d: 'M21 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-4' }],
  ['path', { d: 'M5.058 18.306l2.88 -4.606', key: 'svg-5' }],
  ['path', { d: 'M10.061 10.303l2.877 -4.604', key: 'svg-6' }],
  ['path', { d: 'M10.065 13.705l2.876 4.6', key: 'svg-7' }],
  ['path', { d: 'M15.063 5.7l2.881 4.61', key: 'svg-8' }],
]);
