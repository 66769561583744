import SafeStorage from '../utils/SafeStorage';
import { IS_PRODUCTION } from './env';

const POLL_INTERVAL_MS =
  IS_PRODUCTION || new SafeStorage().getBoolean('noloco.poll', true)
    ? 1500
    : undefined;

export const getJitteredPollInterval = (
  baseInterval = POLL_INTERVAL_MS,
  varianceFactor = 0.25,
) => {
  if (!baseInterval) {
    return undefined;
  }

  if (varianceFactor >= 1) {
    return baseInterval;
  }

  const maximum = baseInterval * (1 + varianceFactor);
  const minimum = baseInterval * (1 - varianceFactor);

  return Math.floor(Math.random() * (maximum - minimum) + minimum);
};
