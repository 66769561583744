import createReactComponent from '../createReactComponent';
export default createReactComponent('server', 'IconServer', [
  [
    'path',
    {
      d: 'M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M3 12m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 8l0 .01', key: 'svg-2' }],
  ['path', { d: 'M7 16l0 .01', key: 'svg-3' }],
]);
