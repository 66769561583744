import React, { forwardRef, useCallback, useMemo } from 'react';
import reverse from 'lodash/fp/reverse';
import { Element, ElementPath } from '../../../../models/Element';
import { Project } from '../../../../models/Project';
import { BaseRecord, RecordEdge } from '../../../../models/Record';
import cappedMemoize from '../../../../utils/cappedMemoize';
import useDraggableCollectionGroups, {
  DraggableCollectionGroupsConfig,
} from '../../../../utils/hooks/useDraggableCollectionGroups';
import { Group } from '../../Collection';
import DraggableRecordLayout from './DraggableRecordLayout';
import DroppableGroupHeader from './DroppableGroupHeader';
import { TableLayoutProps } from './TableLayout';

interface RecordLayoutByEdge {
  className?: string;
  edge: RecordEdge;
  index: number;
  columnWidths: Record<number, number>;
  'data-index': number;
  'data-group-key'?: string;
  draggable: boolean;
  ref: React.Ref<HTMLTableRowElement>;
}

type GroupedVirtualizedLayoutProps = Omit<
  TableLayoutProps,
  'count' | 'fieldConfigs' | 'RecordLayoutByIndex' | 'isGroup'
> &
  DraggableCollectionGroupsConfig & {
    dropIndicator: JSX.Element;
    element: Element;
    elementPath: ElementPath;
    project: Project;
    RecordLayoutByEdge: React.FC<RecordLayoutByEdge>;
    selectedRows: BaseRecord[];
    setSelectedRows: (
      setter: (currentValue: BaseRecord[]) => BaseRecord[],
    ) => void;
    VirtualizedLayout: React.FC<TableLayoutProps>;
  };

export const flattenGroupsWithParentGroups = (
  groups: Group[],
  groupCollapsedStates: Record<string, boolean>,
  parentGroups: number[] = [],
  initialState: { group: Group | null; parentGroups: number[] }[] = [],
): { group: Group | null; parentGroups: number[] }[] =>
  groups.reduce((flatGroups, group) => {
    const index = flatGroups.length;
    flatGroups.push({ group: group, parentGroups });

    if (!groupCollapsedStates[group.id] && group.groups) {
      flattenGroupsWithParentGroups(
        group.groups,
        groupCollapsedStates,
        [...parentGroups, index],
        initialState,
      );
    } else if (group.rows && !groupCollapsedStates[group.id]) {
      flatGroups.push(
        ...Array(group.rows.length).fill({
          group: null,
          parentGroups: [...parentGroups, index],
        }),
      );
    }

    return flatGroups;
  }, initialState);

export const getGroupByIndexesFromFlatGroups = (
  flatGroups: { group: Group | null }[],
) =>
  flatGroups.reduce(
    (acc, { group }, index) => {
      if (group) {
        acc[index] = group;
      }

      return acc;
    },
    {} as Record<number, Group>,
  );

const GroupedVirtualizedLayout = ({
  allRowsSelected,
  bulkActionsEnabled,
  className,
  customFilters,
  dataType,
  dropIndicator,
  edges,
  element,
  elementId,
  elementPath,
  enableDragAndDropEdit,
  fields,
  groupByFields,
  groupOptions,
  handleSetOrderBy,
  hideEmptyGroups,
  layout,
  limitPerGroup,
  maxStickyColumnIndex,
  nodeQueryObject,
  orderBy,
  pageInfo,
  project,
  RecordLayoutByEdge,
  selectedRows,
  setPaginationQuery,
  setSelectAllRows,
  setSelectedRows,
  showPagination,
  showTableSummary,
  totalCount,
  VirtualizedLayout,
}: GroupedVirtualizedLayoutProps) => {
  const {
    canUpdateViaDrag,
    firstSummaryIndex,
    groupCollapsedStates,
    handleCardDrop,
    toggleGroupCollapsedState,
    visibleGroups,
  } = useDraggableCollectionGroups({
    customFilters,
    dataType,
    edges,
    elementId,
    enableDragAndDropEdit,
    fields,
    groupByFields,
    groupOptions,
    hideEmptyGroups,
    layout,
    limitPerGroup,
    nodeQueryObject,
  });

  const flatGroups = useMemo(
    () => flattenGroupsWithParentGroups(visibleGroups, groupCollapsedStates),
    [groupCollapsedStates, visibleGroups],
  );

  const groupHeadersByIndex = useMemo(
    () => getGroupByIndexesFromFlatGroups(flatGroups),
    [flatGroups],
  );

  const GroupHeader = useMemo(
    () =>
      forwardRef<any, { group: Group; index: number }>(
        ({ group, index }, indexRef) => (
          <DroppableGroupHeader
            bulkActionsEnabled={bulkActionsEnabled}
            data-index={index}
            dataType={dataType}
            dataTypes={project.dataTypes}
            dropIndicator={dropIndicator}
            fields={fields}
            firstSummaryIndex={firstSummaryIndex}
            group={group}
            index={index}
            isCollapsed={!!groupCollapsedStates[group.id]}
            layout={layout}
            onDrop={handleCardDrop}
            ref={indexRef}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            toggleGroupCollapsedState={toggleGroupCollapsedState}
          />
        ),
      ),
    [
      bulkActionsEnabled,
      dataType,
      dropIndicator,
      fields,
      firstSummaryIndex,
      groupCollapsedStates,
      handleCardDrop,
      layout,
      project,
      selectedRows,
      setSelectedRows,
      toggleGroupCollapsedState,
    ],
  );

  const groupHeaderIndexes: number[] = useMemo(
    () =>
      Object.keys(groupHeadersByIndex)
        .map(parseFloat)
        .sort((a, b) => a - b),
    [groupHeadersByIndex],
  );

  const getGroupHeaderForEdgeIndex = useMemo(
    () =>
      cappedMemoize(
        (index: number) =>
          groupHeaderIndexes.length -
          reverse(groupHeaderIndexes).findIndex(
            (headerIndex) => index > headerIndex,
          ) -
          1,
        { maxKeys: 1000 },
      ),
    [groupHeaderIndexes],
  );

  const isGroup = useCallback(
    (index: number) => !!flatGroups[index].group,
    [flatGroups],
  );

  const getStickyIndexesForIndex = useCallback(
    (index: number): number[] => flatGroups[index].parentGroups ?? [],
    [flatGroups],
  );

  const RecordLayoutOrGroupHeaderByIndex = useMemo(
    () =>
      forwardRef<
        HTMLTableRowElement,
        {
          index: number;
          columnWidths: Record<number, number>;
        }
      >(({ index, columnWidths }, indexRef) => {
        if (groupHeadersByIndex[index]) {
          const group = groupHeadersByIndex[index];

          return <GroupHeader group={group} index={index} />;
        }

        const groupIndex = getGroupHeaderForEdgeIndex(index);

        const groupHeaderIndex = groupHeaderIndexes[groupIndex];

        const group = groupHeadersByIndex[groupHeaderIndex];
        const indexInGroup = index - groupHeaderIndex - 1;
        const edge = group.rows && group.rows[indexInGroup];

        if (edge) {
          return (
            <DraggableRecordLayout
              columnWidths={columnWidths}
              data-index={index}
              draggable={canUpdateViaDrag}
              dropIndicator={dropIndicator}
              edge={edge}
              group={group}
              index={edge.index}
              onDrop={handleCardDrop}
              record={edge.node}
              RecordLayoutByEdge={RecordLayoutByEdge}
              ref={indexRef}
            />
          );
        }

        return null;
      }),
    [
      canUpdateViaDrag,
      dropIndicator,
      getGroupHeaderForEdgeIndex,
      GroupHeader,
      groupHeaderIndexes,
      groupHeadersByIndex,
      handleCardDrop,
      RecordLayoutByEdge,
    ],
  );

  return (
    <VirtualizedLayout
      allRowsSelected={allRowsSelected}
      bulkActionsEnabled={bulkActionsEnabled}
      className={className}
      count={flatGroups.length}
      dataType={dataType}
      dataTypes={project.dataTypes}
      edges={edges}
      element={element}
      elementPath={elementPath}
      fieldConfigs={fields}
      getStickyIndexesForIndex={getStickyIndexesForIndex}
      handleSetOrderBy={handleSetOrderBy}
      isGroup={isGroup}
      layout={layout}
      maxStickyColumnIndex={maxStickyColumnIndex}
      orderBy={orderBy}
      pageInfo={pageInfo}
      project={project}
      RecordLayoutByIndex={RecordLayoutOrGroupHeaderByIndex}
      setPaginationQuery={setPaginationQuery}
      setSelectAllRows={setSelectAllRows}
      showPagination={showPagination}
      showTableSummary={showTableSummary}
      totalCount={totalCount}
    />
  );
};

export default GroupedVirtualizedLayout;
