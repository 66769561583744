import createReactComponent from '../createReactComponent';
export default createReactComponent('database-dollar', 'IconDatabaseDollar', [
  [
    'path',
    {
      d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 6v6c0 1.657 3.582 3 8 3c.415 0 .822 -.012 1.22 -.035',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M20 10v-4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M4 12v6c0 1.657 3.582 3 8 3c.352 0 .698 -.009 1.037 -.025',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-5' }],
]);
