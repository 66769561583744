import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-rotary-left',
  'IconArrowRotaryLeft',
  [
    ['path', { d: 'M16 10a3 3 0 1 1 0 -6a3 3 0 0 1 0 6z', key: 'svg-0' }],
    ['path', { d: 'M16 10v10', key: 'svg-1' }],
    ['path', { d: 'M13 7h-10', key: 'svg-2' }],
    ['path', { d: 'M7 11l-4 -4l4 -4', key: 'svg-3' }],
  ],
);
