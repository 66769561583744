import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'picture-in-picture-top',
  'IconPictureInPictureTop',
  [
    [
      'path',
      {
        d: 'M11 5h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2 -2v-4',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M15 10h5a1 1 0 0 0 1 -1v-3a1 1 0 0 0 -1 -1h-5a1 1 0 0 0 -1 1v3a1 1 0 0 0 1 1z',
        key: 'svg-1',
      },
    ],
  ],
);
