import { createSlice } from '@reduxjs/toolkit';
import { DashboardUser, User } from '../models/User';

const userSlice = createSlice({
  name: 'user',
  initialState: null as null | User | DashboardUser,
  reducers: {
    setUser: (state, { payload }) => payload,
    addTeamToUser: (state, { payload }) => {
      if (state && state.teams) {
        (state as DashboardUser).teams.push(payload);
      }
    },
    addUserToTeam: (state, { payload }) => {
      if (state && state.teams) {
        const team = (state as DashboardUser).teams.find(
          (t) => t.id === String(payload.teamId),
        );
        if (team) {
          team.users.push(payload.user);
        }
      }
    },
    updateTeam: (state, { payload }) => {
      if (state && state.teams) {
        const team = (state as DashboardUser).teams.find(
          (t) => t.id === String(payload.id),
        );
        if (team) {
          team.name = payload.name;
        }
      }
    },
  },
});

export const { setUser, addTeamToUser, addUserToTeam, updateTeam } =
  userSlice.actions;

export default userSlice.reducer;
