import createReactComponent from '../createReactComponent';
export default createReactComponent('flask-off', 'IconFlaskOff', [
  ['path', { d: 'M9 3h6', key: 'svg-0' }],
  ['path', { d: 'M13 9h1', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10 3v3m-.268 3.736l-3.732 10.264a.7 .7 0 0 0 .5 1h11a.7 .7 0 0 0 .5 -1l-1.143 -3.142m-2.288 -6.294l-.569 -1.564v-6',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
