import createReactComponent from '../createReactComponent';
export default createReactComponent('file-text-ai', 'IconFileTextAi', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10 21h-3a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v3.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 9h1', key: 'svg-2' }],
  ['path', { d: 'M9 13h2.5', key: 'svg-3' }],
  ['path', { d: 'M9 17h1', key: 'svg-4' }],
  ['path', { d: 'M14 21v-4a2 2 0 1 1 4 0v4', key: 'svg-5' }],
  ['path', { d: 'M14 19h4', key: 'svg-6' }],
  ['path', { d: 'M21 15v6', key: 'svg-7' }],
]);
