import React, { useMemo } from 'react';
import first from 'lodash/first';
import identity from 'lodash/identity';
import { FILE } from '../../../constants/builtInDataTypes';
import { DETAILS, TITLE } from '../../../constants/elements';
import { FIELD } from '../../../constants/imageTypes';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import { getImageFilesFromFieldValues } from '../../../utils/files';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { RECORD_SCOPE } from '../../../utils/scope';
import Title from '../Title';
import ActionButton from './ActionButton';
import RecordEmptyState from './RecordEmptyState';

const RecordTitle = ({
  dataType,
  elementPath,
  editorMode,
  record,
  project,
  recordScope,
  ...rest
}: any) => {
  const rawImageProp = rest.image?.value;
  const { title, subtitle, coverPhoto, image } = useSectionScopeVariables(
    TITLE,
    rest,
    project,
    elementPath,
    recordScope,
  );

  const { actionButtons = [] } = useSectionScopeVariables(
    DETAILS,
    rest,
    project,
    elementPath,
    recordScope,
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  const isTitleAndSubtitleEmpty = useMemo(
    () => !title?.value && !subtitle?.value,
    [title, subtitle],
  );

  const recordImage = useMemo(() => {
    if (!rawImageProp || rawImageProp.hidden) {
      return undefined;
    }

    if (rawImageProp.type !== FIELD) {
      return image;
    }

    if (rawImageProp.type === FIELD && rawImageProp.fieldName) {
      const fileField = dataType.fields.getByName(rawImageProp.fieldName);

      if (fileField && fileField.type === FILE) {
        const fileValues = getImageFilesFromFieldValues(fileField, record);

        const firstFile = first(fileValues);

        return firstFile ? { value: firstFile, hidden: false } : undefined;
      }
    }

    return image;
  }, [dataType.fields, image, rawImageProp, record]);

  if (isTitleAndSubtitleEmpty && !editorMode) {
    return null;
  }

  if (isTitleAndSubtitleEmpty && editorMode) {
    return <RecordEmptyState />;
  }

  return (
    <Title
      image={recordImage?.value?.src ? recordImage : { hidden: true }}
      subtitle={subtitle}
      coverPhoto={coverPhoto}
      project={project}
      title={title}
      editorMode={editorMode}
    >
      {actionButtons.map((actionButton: any, actionButtonIndex: any) => (
        <ActionButton
          actionButton={actionButton}
          dataType={dataType}
          editorMode={editorMode}
          index={actionButtonIndex}
          key={actionButtonIndex.id}
          record={record}
          onDeleteRecord={identity}
          recordScope={recordScope}
          project={project}
        />
      ))}
    </Title>
  );
};

RecordTitle.defaultProps = {};

export default RecordTitle;
