import createReactComponent from '../createReactComponent';
export default createReactComponent('keyboard-show', 'IconKeyboardShow', [
  [
    'path',
    {
      d: 'M2 3m0 2a2 2 0 0 1 2 -2h16a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-16a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 7l0 .01', key: 'svg-1' }],
  ['path', { d: 'M10 7l0 .01', key: 'svg-2' }],
  ['path', { d: 'M14 7l0 .01', key: 'svg-3' }],
  ['path', { d: 'M18 7l0 .01', key: 'svg-4' }],
  ['path', { d: 'M6 11l0 .01', key: 'svg-5' }],
  ['path', { d: 'M18 11l0 .01', key: 'svg-6' }],
  ['path', { d: 'M10 11l4 0', key: 'svg-7' }],
  ['path', { d: 'M10 19l2 2l2 -2', key: 'svg-8' }],
]);
