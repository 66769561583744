import React, { memo, useMemo } from 'react';
import get from 'lodash/get';
import { Tooltip } from '@noloco/components';
import fieldTypes, {
  MULTIPLE_OPTION,
  OBJECT,
  SINGLE_OPTION,
} from '@noloco/core/src/constants/dataTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import DataTypes from '@noloco/core/src/models/DataTypes';
import { getColorByIndex } from '@noloco/core/src/utils/colors';
import { sortOptions } from '@noloco/core/src/utils/fields';
import { getText } from '@noloco/core/src/utils/lang';
import { ColorSwatch } from './ColorOptionInput';

type Props = {
  className?: string;
  dataTypes: DataTypes;
  field?: DataField;
};

const DataFieldType = memo(({ className, field, dataTypes }: Props) => {
  const typeLabel = useMemo(() => {
    if (field?.type === OBJECT) {
      return getText(
        'data.types',
        field.type,
        field.typeOptions?.format as string,
        'label',
      );
    }

    if ([...fieldTypes, MULTIPLE_OPTION].includes(field?.type as string)) {
      return getText('data.types', field?.type as string, 'label');
    }

    return get(dataTypes.getByName(field?.type as string), 'display', '---');
  }, [dataTypes, field]);

  return (
    <Tooltip
      content={
        <div className="flex max-h-60 flex-col overflow-y-auto">
          {sortOptions((field as any).options || []).map(
            (fieldOption, optionIndex) => (
              <div
                key={fieldOption.name}
                className="my-1 flex items-center space-x-2"
              >
                <ColorSwatch
                  color={fieldOption.color || getColorByIndex(optionIndex)}
                />
                <span>{fieldOption.display}</span>
              </div>
            ),
          )}
        </div>
      }
      disabled={field?.type !== SINGLE_OPTION || !(field as any).options}
      placement="bottom"
      bg="white"
    >
      <span className={className}>{typeLabel}</span>
    </Tooltip>
  );
});

export default DataFieldType;
