import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-appgallery', 'IconBrandAppgallery', [
  [
    'path',
    {
      d: 'M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 8a3 3 0 0 0 6 0', key: 'svg-1' }],
]);
