import { useMemo } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { projectUsersSelector } from '../../selectors/projectSelectors';
import { isNolocoAdmin as userIsNolocoAdmin } from '../auth';
import { useAuth } from './useAuth';
import useUserPermissions from './useUserPermissions';

export const useIsBuilder = () => {
  const { user } = useAuth();
  const { isBuilder: userHasModifyAppPermission } = useUserPermissions();

  const currentUserEmail = get(user, ['email']);
  const isNolocoAdmin = user && userIsNolocoAdmin(user);

  const users = useSelector(projectUsersSelector);

  const isBuilder = useMemo(
    () =>
      !!currentUserEmail &&
      !!users &&
      (isNolocoAdmin ||
        users.some(
          (user: any) => get(user, ['user', 'email']) === currentUserEmail,
        )) &&
      userHasModifyAppPermission,
    [currentUserEmail, isNolocoAdmin, userHasModifyAppPermission, users],
  );

  return {
    isBuilder,
    isNolocoAdmin,
  };
};
