import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { formatBg } from '../../../utils/styles';

const getSampleImageUrlByIndex = (index: any) =>
  `https://picsum.photos/id/${(index % 10) + 1020}/600/600`;

const CollectionCardGallery = ({ h, image, images, index, isView }: any) => {
  const heroImagesToShow = useMemo(() => {
    if (image.hidden) {
      return [];
    }

    if (images) {
      return images;
    }

    if (image.value && (!isView || image.value.src)) {
      return [image.value];
    }

    return [];
  }, [image.hidden, image.value, images, isView]);
  const [imageIndex, setImageIndex] = useState(0);

  const onHandlePreviousImage = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      if (imageIndex > 0) {
        setImageIndex(imageIndex - 1);
      }
    },
    [imageIndex],
  );

  const onHandleNextImage = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      if (imageIndex < heroImagesToShow.length - 1) {
        setImageIndex(imageIndex + 1);
      }
    },
    [imageIndex, heroImagesToShow.length],
  );

  return (
    !image.hidden &&
    heroImagesToShow.length > 0 && (
      <Box
        is="div"
        className={classNames(
          'group mb-2 flex w-full items-center rounded-tl-lg rounded-tr-lg bg-cover bg-center',
        )}
        style={{
          ...formatBg(
            get(heroImagesToShow, [imageIndex, 'src']) ||
              (isView ? '' : getSampleImageUrlByIndex(index)),
          ),
        }}
        h={h}
        title={get(heroImagesToShow, [imageIndex, 'name'])}
      >
        {heroImagesToShow.length > 0 && (
          <div className="hidden w-full items-center justify-between px-2 group-hover:flex">
            <button
              className={classNames(
                'rounded-full border border-gray-200 bg-white p-1 text-gray-700 shadow-lg',
                { invisible: imageIndex === 0 },
              )}
              onClick={onHandlePreviousImage}
            >
              <IconChevronLeft size={14} />
            </button>
            <button
              className={classNames(
                'rounded-full border border-gray-200 bg-white p-1 text-gray-700 shadow-lg',
                {
                  invisible: imageIndex === heroImagesToShow.length - 1,
                },
              )}
              onClick={onHandleNextImage}
            >
              <IconChevronRight size={14} />
            </button>
          </div>
        )}
      </Box>
    )
  );
};

CollectionCardGallery.defaultProps = {
  h: 56,
};

export default CollectionCardGallery;
