import createReactComponent from '../createReactComponent';
export default createReactComponent('file-shredder', 'IconFileShredder', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  ['path', { d: 'M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4', key: 'svg-1' }],
  ['path', { d: 'M3 12l18 0', key: 'svg-2' }],
  ['path', { d: 'M6 16l0 2', key: 'svg-3' }],
  ['path', { d: 'M10 16l0 6', key: 'svg-4' }],
  ['path', { d: 'M14 16l0 2', key: 'svg-5' }],
  ['path', { d: 'M18 16l0 4', key: 'svg-6' }],
]);
