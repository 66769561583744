import { DateTime, Zone } from 'luxon';

export const dtToLocalTZMaintainWallTime = (
  originalDateTime: DateTime,
  inlcudeTime: boolean = true,
): DateTime =>
  DateTime.local(
    originalDateTime.year,
    originalDateTime.month,
    originalDateTime.day,
    inlcudeTime ? originalDateTime.hour : 0,
    inlcudeTime ? originalDateTime.minute : 0,
    inlcudeTime ? originalDateTime.second : 0,
    inlcudeTime ? originalDateTime.millisecond : 0,
  );

export const dtToUTCMaintainWallTime = (
  originalDateTime: DateTime,
  inlcudeTime: boolean = true,
): DateTime =>
  DateTime.utc(
    originalDateTime.year,
    originalDateTime.month,
    originalDateTime.day,
    inlcudeTime ? originalDateTime.hour : 0,
    inlcudeTime ? originalDateTime.minute : 0,
    inlcudeTime ? originalDateTime.second : 0,
    0,
  );

export const dtToTzMaintainWallTime = (
  originalDateTime: DateTime,
  timeZone: string | Zone | undefined = 'UTC',
  inlcudeTime: boolean = true,
): DateTime =>
  DateTime.local(
    originalDateTime.year,
    originalDateTime.month,
    originalDateTime.day,
    inlcudeTime ? originalDateTime.hour : 0,
    inlcudeTime ? originalDateTime.minute : 0,
    inlcudeTime ? originalDateTime.second : 0,
    0,
    { zone: timeZone },
  );
