import React from 'react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { getText } from '@noloco/core/src/utils/lang';

const ProjectErrorMessage = ({ children }: { children?: JSX.Element }) => (
  <div className="flex h-screen w-full items-center justify-center bg-gray-100 p-8 text-gray-800">
    <IconAlertTriangle size={48} />
    <div className="ml-6 flex flex-col">
      <h1 className="text-lg">{getText('errors.errorBoundary.title')}</h1>
      <p className="text-gray-600">
        {getText('errors.errorBoundary.subtitle')}
      </p>
      {children}
    </div>
  </div>
);

export default ProjectErrorMessage;
