export const AUDIO = 'AUDIO';
export const IMAGE = 'IMAGE';
export const VIDEO = 'VIDEO';
export const TEXT = 'TEXT';
export const DOCUMENT = 'DOCUMENT';

const fileTypes = [AUDIO, IMAGE, VIDEO, TEXT, DOCUMENT];

export type FileType = 'AUDIO' | 'IMAGE' | 'VIDEO' | 'TEXT' | 'DOCUMENT';

export default fileTypes;
