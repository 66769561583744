import createReactComponent from '../createReactComponent';
export default createReactComponent('archive-off', 'IconArchiveOff', [
  [
    'path',
    {
      d: 'M8 4h11a2 2 0 1 1 0 4h-7m-4 0h-3a2 2 0 0 1 -.826 -3.822',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 1.824 -1.18m.176 -3.82v-7',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M10 12h2', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
