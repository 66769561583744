import createReactComponent from '../createReactComponent';
export default createReactComponent('device-imac-cog', 'IconDeviceImacCog', [
  [
    'path',
    {
      d: 'M12 17h-8a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 13h13', key: 'svg-1' }],
  ['path', { d: 'M8 21h4', key: 'svg-2' }],
  ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
  ['path', { d: 'M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
  ['path', { d: 'M19.001 15.5v1.5', key: 'svg-5' }],
  ['path', { d: 'M19.001 21v1.5', key: 'svg-6' }],
  ['path', { d: 'M22.032 17.25l-1.299 .75', key: 'svg-7' }],
  ['path', { d: 'M17.27 20l-1.3 .75', key: 'svg-8' }],
  ['path', { d: 'M15.97 17.25l1.3 .75', key: 'svg-9' }],
  ['path', { d: 'M20.733 20l1.3 .75', key: 'svg-10' }],
]);
