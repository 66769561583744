import createReactComponent from '../createReactComponent';
export default createReactComponent('brightness-up', 'IconBrightnessUp', [
  ['path', { d: 'M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M12 5l0 -2', key: 'svg-1' }],
  ['path', { d: 'M17 7l1.4 -1.4', key: 'svg-2' }],
  ['path', { d: 'M19 12l2 0', key: 'svg-3' }],
  ['path', { d: 'M17 17l1.4 1.4', key: 'svg-4' }],
  ['path', { d: 'M12 19l0 2', key: 'svg-5' }],
  ['path', { d: 'M7 17l-1.4 1.4', key: 'svg-6' }],
  ['path', { d: 'M6 12l-2 0', key: 'svg-7' }],
  ['path', { d: 'M7 7l-1.4 -1.4', key: 'svg-8' }],
]);
