import createReactComponent from '../createReactComponent';
export default createReactComponent('view-360', 'IconView360', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  ['path', { d: 'M12 12m-4 0a4 9 0 1 0 8 0a4 9 0 1 0 -8 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M3 12c0 2.21 4.03 4 9 4s9 -1.79 9 -4s-4.03 -4 -9 -4s-9 1.79 -9 4z',
      key: 'svg-2',
    },
  ],
]);
