import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'health-recognition',
  'IconHealthRecognition',
  [
    ['path', { d: 'M4 8v-2a2 2 0 0 1 2 -2h2', key: 'svg-0' }],
    ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-1' }],
    ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-2' }],
    ['path', { d: 'M16 20h2a2 2 0 0 0 2 -2v-2', key: 'svg-3' }],
    [
      'path',
      {
        d: 'M8.603 9.61a2.04 2.04 0 0 1 2.912 0l.485 .39l.5 -.396a2.035 2.035 0 0 1 2.897 .007a2.104 2.104 0 0 1 0 2.949l-3.397 3.44l-3.397 -3.44a2.104 2.104 0 0 1 0 -2.95z',
        key: 'svg-4',
      },
    ],
  ],
);
