import createReactComponent from '../createReactComponent';
export default createReactComponent('world-dollar', 'IconWorldDollar', [
  ['path', { d: 'M20.876 10.51a9 9 0 1 0 -7.839 10.43', key: 'svg-0' }],
  ['path', { d: 'M3.6 9h16.8', key: 'svg-1' }],
  ['path', { d: 'M3.6 15h9.9', key: 'svg-2' }],
  ['path', { d: 'M11.5 3a17 17 0 0 0 0 18', key: 'svg-3' }],
  ['path', { d: 'M12.5 3a16.986 16.986 0 0 1 2.578 9.02', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-5',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-6' }],
]);
