import createReactComponent from '../createReactComponent';
export default createReactComponent('camera-question', 'IconCameraQuestion', [
  [
    'path',
    {
      d: 'M15 20h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v2.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14.975 12.612a3 3 0 1 0 -1.507 3.005', key: 'svg-1' }],
  ['path', { d: 'M19 22v.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-3',
    },
  ],
]);
