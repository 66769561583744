import createReactComponent from '../createReactComponent';
export default createReactComponent('slideshow', 'IconSlideshow', [
  ['path', { d: 'M15 6l.01 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 3m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 13l4 -4a3 5 0 0 1 3 0l4 4', key: 'svg-2' }],
  ['path', { d: 'M13 12l2 -2a3 5 0 0 1 3 0l3 3', key: 'svg-3' }],
  ['path', { d: 'M8 21l.01 0', key: 'svg-4' }],
  ['path', { d: 'M12 21l.01 0', key: 'svg-5' }],
  ['path', { d: 'M16 21l.01 0', key: 'svg-6' }],
]);
