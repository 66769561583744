import React, { useState } from 'react';
import { IconTrash, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { Popover } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';

const ConfirmDeleteButton = ({
  className,
  disabled,
  onDelete,
  tooltip = false,
}: any) => {
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);

  return (
    <div className={classNames(className, 'flex items-center space-x-1')}>
      {!tooltip && showConfirmRemove && (
        <span>{getText('data.dataTypes.workflows.delete.confirmDelete')}</span>
      )}
      <Popover
        content={
          <div className="flex items-center space-x-1">
            <span>
              {getText('data.dataTypes.workflows.delete.confirmDelete')}
            </span>
            <button
              disabled={disabled}
              type="button"
              onClick={
                showConfirmRemove && onDelete
                  ? () => onDelete()
                  : () => setShowConfirmRemove(true)
              }
              className="p-1 opacity-50 hover:opacity-100"
            >
              <IconTrash size={16} />
            </button>
            <button
              disabled={disabled}
              type="button"
              onClick={() => setShowConfirmRemove(false)}
              className="p-1 opacity-50 hover:opacity-100"
            >
              <IconX size={16} />
            </button>
          </div>
        }
        disabled={!tooltip || !showConfirmRemove}
        trigger="none"
        closeOnOutsideClick={true}
        onOpenChange={setShowConfirmRemove}
        isOpen={tooltip && showConfirmRemove}
      >
        <button
          disabled={disabled}
          type="button"
          onClick={
            showConfirmRemove && onDelete
              ? () => onDelete()
              : () => setShowConfirmRemove(true)
          }
          className="p-1 opacity-50 hover:opacity-100"
        >
          <IconTrash size={16} />
        </button>
      </Popover>
      {!tooltip && showConfirmRemove && (
        <button
          disabled={disabled}
          type="button"
          onClick={() => setShowConfirmRemove(false)}
          className="p-1 opacity-50 hover:opacity-100"
        >
          <IconX size={16} />
        </button>
      )}
    </div>
  );
};

export default ConfirmDeleteButton;
