import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-analytics',
  'IconDeviceDesktopAnalytics',
  [
    [
      'path',
      {
        d: 'M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h10', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    ['path', { d: 'M15 16v4', key: 'svg-3' }],
    ['path', { d: 'M9 12v-4', key: 'svg-4' }],
    ['path', { d: 'M12 12v-1', key: 'svg-5' }],
    ['path', { d: 'M15 12v-2', key: 'svg-6' }],
    ['path', { d: 'M12 12v-1', key: 'svg-7' }],
  ],
);
