import createReactComponent from '../createReactComponent';
export default createReactComponent('mist-off', 'IconMistOff', [
  ['path', { d: 'M12 5h9', key: 'svg-0' }],
  ['path', { d: 'M3 10h7', key: 'svg-1' }],
  ['path', { d: 'M18 10h1', key: 'svg-2' }],
  ['path', { d: 'M5 15h5', key: 'svg-3' }],
  ['path', { d: 'M14 15h1m4 0h2', key: 'svg-4' }],
  ['path', { d: 'M3 20h9m4 0h3', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
