import createReactComponent from '../createReactComponent';
export default createReactComponent('sort-9-0', 'IconSort90', [
  [
    'path',
    {
      d: 'M4 15a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-6a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 10v4a2 2 0 1 0 4 0v-4a2 2 0 1 0 -4 0z', key: 'svg-1' }],
  ['path', { d: 'M11 12h2', key: 'svg-2' }],
]);
