import { forwardRef, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { getColorShade } from '@noloco/components';
import {
  LAYOUT_SUPPORTS_RECORD_COLORING,
  SPLIT,
} from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { getColorBasedOnConditions } from '../../../../utils/colors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useRouter from '../../../../utils/hooks/useRouter';
import useSelectRecordOnCmdClick from '../../../../utils/hooks/useSelectRecordOnCmdClick';
import RowFieldCell from './RowFieldCell';

const RowRecordLayout = forwardRef<HTMLDivElement, any>(
  (
    {
      actionButtons,
      className,
      dataType,
      elementId,
      element,
      fieldConfigs,
      layout,
      record,
      scope,
      project,
      rowLink,
      transformRecordScope,
      bulkActionsEnabled = false,
      isRowChecked = false,
      handleCheckboxChange,
      selectedRows,
      theme,
      ...rest
    },
    ref,
  ) => {
    const {
      query: { recordId },
    } = useRouter();

    const isRecordOpen = useMemo(() => {
      if (layout === SPLIT) {
        return recordId === record.uuid;
      }
    }, [layout, record, recordId]);

    const Row = rowLink ? Link : 'div';
    const [isDarkModeEnabled] = useDarkMode();
    const selectRecordOnCmdClick = useSelectRecordOnCmdClick(
      handleCheckboxChange,
      isRowChecked,
      selectedRows,
    );

    const primaryColor = theme.brandColors.primary;

    const colorConditions = useMemo(
      () => get(element, ['props', 'recordColoring'], []),
      [element],
    );
    const conditionBasedColor = useMemo(
      () => getColorBasedOnConditions(colorConditions, scope, project),
      [colorConditions, scope, project],
    );

    return (
      <Row
        to={rowLink}
        onClick={selectRecordOnCmdClick}
        className={classNames(
          className,
          'group relative flex w-full flex-col items-center px-6 py-3 text-sm',
          {
            [`bg-${getColorShade(
              primaryColor,
              100,
            )} bg-opacity-75 dark:bg-${getColorShade(primaryColor, 900)}`]:
              isRowChecked,
            [`bg-${getColorShade(primaryColor, 200)} dark:bg-${getColorShade(
              primaryColor,
              700,
            )} bg-opacity-25`]: isRecordOpen,
            [`bg-${getColorShade(
              conditionBasedColor,
              50,
            )} hover:bg-${getColorShade(conditionBasedColor, 100)}`]:
              !isRecordOpen && !isDarkModeEnabled && conditionBasedColor,
            [`bg-${getColorShade(
              conditionBasedColor,
              900,
            )} hover:bg-${getColorShade(conditionBasedColor, 800)}`]:
              !isRecordOpen &&
              isDarkModeEnabled &&
              conditionBasedColor &&
              LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout),
            [`hover:${
              isDarkModeEnabled
                ? darkModeColors.surfaces.elevation2
                : 'bg-gray-100'
            } hover:bg-opacity-50`]: !isRecordOpen && !conditionBasedColor,
          },
        )}
        data-testid="collection-record"
        ref={ref}
        {...rest}
      >
        <div className="my-4 grid w-full grid-cols-12 gap-x-6 gap-y-4 sm:pl-0">
          {fieldConfigs.map(
            ({ field, parent, parentFieldType, config, permissions }: any) => (
              <RowFieldCell
                // @ts-expect-error TS(2322): Type '{ config: any; dataType: any; elementId: any... Remove this comment to see the full error message
                config={config}
                dataType={parentFieldType || dataType}
                elementId={elementId}
                field={field}
                key={`${field.name}:${(parentFieldType || dataType).name}`}
                parent={parent}
                layout={layout}
                permissions={permissions}
                record={record}
                project={project}
                transformRecordScope={transformRecordScope}
                bulkActionsEnabled={bulkActionsEnabled}
                isRowChecked={isRowChecked}
                selectedRows={selectedRows}
              />
            ),
          )}
        </div>
        {actionButtons}
      </Row>
    );
  },
);

export default withTheme(RowRecordLayout);
