import createReactComponent from '../createReactComponent';
export default createReactComponent('wash-dry-2', 'IconWashDry2', [
  [
    'path',
    {
      d: 'M3 3m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 12m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0', key: 'svg-1' }],
  ['path', { d: 'M10 12h.01', key: 'svg-2' }],
  ['path', { d: 'M14 12h.01', key: 'svg-3' }],
]);
