import { VARIABLE } from '../../constants/elementPropTypeTypes';
import { TEXT } from '../../constants/elements';
import ElementPropType from './ElementPropType';
import StringPropType from './StringPropType';

class VariablePropPropType extends ElementPropType {
  propType: ElementPropType;
  elementType: string;
  placeholder: (...varArgs: any[]) => string;
  hideable: boolean;
  hasLabel: (...varArgs: any[]) => boolean;

  constructor(
    {
      type = new StringPropType(true),
      elementType = TEXT,
      placeholder = () => 'Text',
      hideable = true,
      hasLabel = () => false,
    }: {
      type?: ElementPropType;
      elementType?: string;
      placeholder?: (...varArgs: any[]) => string;
      hideable?: boolean;
      hasLabel?: (...varArgs: any[]) => boolean;
    } = {},
    display = () => true,
  ) {
    super(VARIABLE);
    this.propType = type;
    this.elementType = elementType;
    this.placeholder = placeholder;
    this.hideable = hideable;
    this.hasLabel = hasLabel;
    this.display = display;
  }
}

export default VariablePropPropType;
