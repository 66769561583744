import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-ember', 'IconBrandEmber', [
  [
    'path',
    {
      d: 'M3 12.958c8.466 1.647 11.112 -1.196 12.17 -2.294c2.116 -2.196 0 -6.589 -2.646 -5.49c-2.644 1.096 -6.35 7.686 -3.174 12.078c2.116 2.928 6 2.178 11.65 -2.252',
      key: 'svg-0',
    },
  ],
]);
