import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-pushover', 'IconBrandPushover', [
  [
    'path',
    {
      d: 'M6.16 10.985c-.83 -1.935 1.53 -7.985 8.195 -7.985c3.333 0 4.645 1.382 4.645 3.9c0 2.597 -2.612 6.1 -9 6.1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12.5 6l-5.5 15', key: 'svg-1' }],
]);
