import React, { forwardRef } from 'react';
import { IconGripVertical } from '@tabler/icons-react';
import { ElementPath } from '../models/Element';
import { Project } from '../models/Project';
import { Page } from '../utils/pages';
import BuildModeOptionsMenu from './buildMode/BuildModeOptionsMenu';

type SidebarDividerProps = {
  elementPath: ElementPath;
  handleDividerClone: () => void;
  handleDividerRemove: () => void;
  page: Page;
  project: Project;
  sidebarExpanded?: boolean;
};

const SidebarDivider = forwardRef(
  (
    {
      elementPath,
      handleDividerClone,
      handleDividerRemove,
      page,
      project,
      sidebarExpanded = true,
    }: SidebarDividerProps,
    dragRef: React.ForwardedRef<HTMLDivElement>,
  ) => (
    <div className="group mx-3 my-0.5 flex h-8 items-center justify-center rounded-lg px-1 hover:bg-slate-600">
      {sidebarExpanded && (
        <div
          className="absolute -left-1 ml-0.5 hidden cursor-move items-center opacity-75 hover:opacity-100 group-hover:flex"
          ref={dragRef}
        >
          <IconGripVertical size={16} />
        </div>
      )}
      <hr className="w-full border-slate-500" />
      {sidebarExpanded && (
        <BuildModeOptionsMenu
          elementPath={elementPath}
          handleClone={handleDividerClone}
          handleRemove={handleDividerRemove}
          hidden={true}
          page={page}
          project={project}
        />
      )}
    </div>
  ),
);

export default SidebarDivider;
