import createReactComponent from '../createReactComponent';
export default createReactComponent('pig', 'IconPig', [
  ['path', { d: 'M15 11v.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M16 3l0 3.803a6.019 6.019 0 0 1 2.658 3.197h1.341a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-1.342a6.008 6.008 0 0 1 -1.658 2.473v2.027a1.5 1.5 0 0 1 -3 0v-.583a6.04 6.04 0 0 1 -1 .083h-4a6.04 6.04 0 0 1 -1 -.083v.583a1.5 1.5 0 0 1 -3 0v-2l0 -.027a6 6 0 0 1 4 -10.473h2.5l4.5 -3z',
      key: 'svg-1',
    },
  ],
]);
