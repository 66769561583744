import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-onedrive', 'IconBrandOnedrive', [
  [
    'path',
    {
      d: 'M18.456 10.45a6.45 6.45 0 0 0 -12 -2.151a4.857 4.857 0 0 0 -4.44 5.241a4.856 4.856 0 0 0 5.236 4.444h10.751a3.771 3.771 0 0 0 3.99 -3.54a3.772 3.772 0 0 0 -3.538 -3.992z',
      key: 'svg-0',
    },
  ],
]);
