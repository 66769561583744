import createReactComponent from '../createReactComponent';
export default createReactComponent('polygon-off', 'IconPolygonOff', [
  ['path', { d: 'M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M19 8m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M5 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M15 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M6.5 9.5l1.546 -1.311', key: 'svg-4' }],
  ['path', { d: 'M14 5.5l3 1.5', key: 'svg-5' }],
  ['path', { d: 'M18.5 10l-1.185 3.318m-1.062 2.972l-.253 .71', key: 'svg-6' }],
  ['path', { d: 'M13.5 17.5l-7 -5', key: 'svg-7' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-8' }],
]);
