import createReactComponent from '../createReactComponent';
export default createReactComponent('circle-filled', 'IconCircleFilled', [
  [
    'path',
    {
      d: 'M7 3.34a10 10 0 1 1 -4.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 4.995 -8.336z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
]);
