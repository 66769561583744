import React from 'react';

type OwnProps = {
  routePath?: string;
  dataType?: string;
  dataProperty?: string;
  dataTypes: {
    name: string;
    display: string;
  }[];
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof PagePath.defaultProps;

// @ts-expect-error TS(7022): 'PagePath' implicitly has type 'any' because it do... Remove this comment to see the full error message
const PagePath = ({ routePath, dataType, dataProperty, dataTypes }: Props) => {
  const selectedDataType = dataTypes.getByName(dataType);
  return (
    <>
      {routePath && (!routePath.startsWith('/') || routePath === '/') && (
        <span>/</span>
      )}
      {routePath !== '/' && <span>{routePath}</span>}
      {routePath && dataType && dataProperty && <span>/</span>}
      {dataType && dataProperty && selectedDataType && (
        <span className="rounded bg-teal-500 px-2 py-1 text-white">
          {selectedDataType.display} <span>-&gt;</span> {dataProperty}
        </span>
      )}
    </>
  );
};

PagePath.defaultProps = {
  first: true,
  routePath: '',
};

export default PagePath;
