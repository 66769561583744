import createReactComponent from '../createReactComponent';
export default createReactComponent('armchair-off', 'IconArmchairOff', [
  [
    'path',
    {
      d: 'M17 13a2 2 0 1 1 4 0v4m-2 2h-14a2 2 0 0 1 -2 -2v-4a2 2 0 1 1 4 0v2h8.036',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M5 11v-5a3 3 0 0 1 .134 -.89m1.987 -1.98a3 3 0 0 1 .879 -.13h8a3 3 0 0 1 3 3v5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M6 19v2', key: 'svg-2' }],
  ['path', { d: 'M18 19v2', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
