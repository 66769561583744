import React, { useMemo } from 'react';
import { SwitchButton } from '@noloco/components';
import { LG, MD, SM, XL } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeLabel from '@noloco/core/src/components/buildMode/BuildModeLabel';
import { DETAILS } from '@noloco/core/src/constants/elements';
import { VIEW } from '@noloco/core/src/constants/elements';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Action, ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import { UpdatePropertyCallback } from '../../../utils/hooks/projectHooks';
import SectionFieldsListEditor from './SectionFieldsListEditor';

type ViewRecordActionEditorProps = {
  action: Action;
  dataType: DataType;
  elementPath: ElementPath;
  fields: DataField[];
  fieldsPath?: any[];
  isRecordView: boolean;
  project: Project;
  sectionPropPath: ElementPath;
  updateProperty: UpdatePropertyCallback;
};

const ViewRecordActionEditor = ({
  action,
  dataType,
  elementPath,
  fields = [],
  isRecordView = true,
  project,
  fieldsPath = ['fields'],
  sectionPropPath,
  updateProperty,
}: ViewRecordActionEditorProps) => {
  const sizeOptions = [SM, MD, LG, XL];
  const size = action.size || LG;

  const section = useMemo(
    () => ({
      ...action,
      type: DETAILS,
      props: { fields: action.detailsFields, rootField: null },
    }),
    [action],
  );

  return (
    <>
      <SectionFieldsListEditor
        dataType={dataType}
        elementPath={elementPath}
        isRecordView={isRecordView}
        fields={fields}
        project={project}
        section={section}
        sectionPropPath={sectionPropPath}
        fieldsPath={fieldsPath}
        isFormHelpText={false}
        updateProperty={(path: any, value: any) => {
          updateProperty(['detailsFields', ...path], value);
        }}
      />
      <BuildModeLabel className="ml-2">
        {getText('elements', VIEW, 'size.label')}
      </BuildModeLabel>
      <SwitchButton
        className="m-2 h-8 rounded-lg"
        inverseColors={true}
        onChange={(value) => updateProperty(['size'], value)}
        options={sizeOptions.map((sizeOption) => ({
          label: getText('elements.SELECT_INPUT.smallSizes', sizeOption),
          value: sizeOption,
        }))}
        fullWidth={false}
        value={size || LG}
      />
    </>
  );
};

export default ViewRecordActionEditor;
