import createReactComponent from '../createReactComponent';
export default createReactComponent('lock-share', 'IconLockShare', [
  ['path', { d: 'M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 21h-5a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 11v-4a4 4 0 1 1 8 0v4', key: 'svg-2' }],
  ['path', { d: 'M16 22l5 -5', key: 'svg-3' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-4' }],
]);
