import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-miniprogram',
  'IconBrandMiniprogram',
  [
    ['path', { d: 'M21 12a9 9 0 1 1 -18 0a9 9 0 0 1 18 0z', key: 'svg-0' }],
    [
      'path',
      { d: 'M8 11.503a2.5 2.5 0 1 0 4 2v-3a2.5 2.5 0 1 1 4 2', key: 'svg-1' },
    ],
  ],
);
