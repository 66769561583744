import { AddressComponent } from '../models/Record';

const getAddressComponentValue = (
  addressComponents: AddressComponent[],
  type: string,
  long = true,
) => {
  if (long) {
    return (
      addressComponents.find((component: any) => component.types.includes(type))
        ?.long_name || ''
    );
  } else {
    return (
      addressComponents.find((component: any) => component.types.includes(type))
        ?.short_name || ''
    );
  }
};

export const getAddressComponentsObject = (
  addressComponents: AddressComponent[],
) => {
  const streetNumber = getAddressComponentValue(
    addressComponents,
    'street_number',
  );
  const streetName = getAddressComponentValue(addressComponents, 'route');
  const suiteAptBldg = getAddressComponentValue(
    addressComponents,
    'subpremise',
  );
  const city =
    getAddressComponentValue(addressComponents, 'locality') ||
    getAddressComponentValue(
      addressComponents,
      'administrative_area_level_2',
    ) ||
    getAddressComponentValue(addressComponents, 'administrative_area_level_3');
  const country = getAddressComponentValue(addressComponents, 'country');
  const postalCode = getAddressComponentValue(addressComponents, 'postal_code');

  let street = `${streetNumber} ${streetName}`;
  let stateRegion = getAddressComponentValue(
    addressComponents,
    'administrative_area_level_1',
  );

  if (country === 'United Kingdom') {
    stateRegion = getAddressComponentValue(addressComponents, 'postal_town');
  }
  if (country === 'Netherlands') {
    street = `${streetName} ${streetNumber}`;
  }

  return { street, city, suiteAptBldg, stateRegion, country, postalCode };
};
