import createReactComponent from '../createReactComponent';
export default createReactComponent('chart-dots-2', 'IconChartDots2', [
  ['path', { d: 'M3 3v18h18', key: 'svg-0' }],
  ['path', { d: 'M9 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M13 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M18 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M21 3l-6 1.5', key: 'svg-4' }],
  ['path', { d: 'M14.113 6.65l2.771 3.695', key: 'svg-5' }],
  ['path', { d: 'M16 12.5l-5 2', key: 'svg-6' }],
]);
