import React from 'react';

const MapPin = ({ strokeWidth = 8, ...rest }) => (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 102 122"
    {...rest}
  >
    <path
      d="m16.402 84.501.001.001 32.77 32.716 1.412 1.411 1.413-1.411-1.413-1.415L52 117.218v-.001l.005-.005.018-.017.07-.07.273-.272 1.035-1.034 3.695-3.689 11.226-11.206 16.45-16.423C93.887 75.385 98.91 63.24 98.91 50.318c0-12.922-5.025-25.067-14.141-34.184-9.13-9.13-21.275-14.16-34.184-14.16-12.909 0-25.056 5.03-34.183 14.158-18.851 18.85-18.851 49.518 0 68.37Z"
      fill="currentColor"
      stroke="#FFF"
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default MapPin;
