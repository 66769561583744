import createReactComponent from '../createReactComponent';
export default createReactComponent('infinity', 'IconInfinity', [
  [
    'path',
    {
      d: 'M9.828 9.172a4 4 0 1 0 0 5.656a10 10 0 0 0 2.172 -2.828a10 10 0 0 1 2.172 -2.828a4 4 0 1 1 0 5.656a10 10 0 0 1 -2.172 -2.828a10 10 0 0 0 -2.172 -2.828',
      key: 'svg-0',
    },
  ],
]);
