import createReactComponent from '../createReactComponent';
export default createReactComponent('math-symbols', 'IconMathSymbols', [
  ['path', { d: 'M3 12l18 0', key: 'svg-0' }],
  ['path', { d: 'M12 3l0 18', key: 'svg-1' }],
  ['path', { d: 'M16.5 4.5l3 3', key: 'svg-2' }],
  ['path', { d: 'M19.5 4.5l-3 3', key: 'svg-3' }],
  ['path', { d: 'M6 4l0 4', key: 'svg-4' }],
  ['path', { d: 'M4 6l4 0', key: 'svg-5' }],
  ['path', { d: 'M18 16l.01 0', key: 'svg-6' }],
  ['path', { d: 'M18 20l.01 0', key: 'svg-7' }],
  ['path', { d: 'M4 18l4 0', key: 'svg-8' }],
]);
