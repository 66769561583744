import createReactComponent from '../createReactComponent';
export default createReactComponent('confetti-off', 'IconConfettiOff', [
  ['path', { d: 'M4 5h1', key: 'svg-0' }],
  ['path', { d: 'M5 5v1', key: 'svg-1' }],
  ['path', { d: 'M11.5 4l-.5 2', key: 'svg-2' }],
  ['path', { d: 'M18 5h2', key: 'svg-3' }],
  ['path', { d: 'M19 4v2', key: 'svg-4' }],
  ['path', { d: 'M15 9l-1 1', key: 'svg-5' }],
  ['path', { d: 'M18 13l2 -.5', key: 'svg-6' }],
  ['path', { d: 'M18 19h1', key: 'svg-7' }],
  ['path', { d: 'M19 19v1', key: 'svg-8' }],
  [
    'path',
    {
      d: 'M14 16.518l-6.518 -6.518l-4.39 9.58a1 1 0 0 0 1.329 1.329l9.579 -4.39v0z',
      key: 'svg-9',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-10' }],
]);
