import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdateAllRecords as setUpdateAllRecordsAction } from '../../reducers/formFields';
import { formFieldsUpdateAllRecordsSelector } from '../../selectors/formFieldsSelector';

export const useUpdateAllRecordsSwitch = () => {
  const dispatch = useDispatch();

  const updateAllRecords = useSelector(formFieldsUpdateAllRecordsSelector);

  const setUpdateAllRecords = useCallback(
    (value: boolean) => dispatch(setUpdateAllRecordsAction(value)),
    [dispatch],
  );

  return {
    updateAllRecords,
    setUpdateAllRecords,
  };
};
