import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'creative-commons-nd',
  'IconCreativeCommonsNd',
  [
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M9 10h6', key: 'svg-1' }],
    ['path', { d: 'M9 14h6', key: 'svg-2' }],
  ],
);
