import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'alphabet-cyrillic',
  'IconAlphabetCyrillic',
  [
    [
      'path',
      { d: 'M6 10h2a2 2 0 0 1 2 2v5h-3a2 2 0 1 1 0 -4h3', key: 'svg-0' },
    ],
    [
      'path',
      {
        d: 'M19 7h-3a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h1a2 2 0 0 0 2 -2v-3a2 2 0 0 0 -2 -2h-3',
        key: 'svg-1',
      },
    ],
  ],
);
