import React from 'react';
import {
  IconFile,
  IconFileCode,
  IconFileInfo,
  IconFileMusic,
  IconMovie,
  IconPhoto,
} from '@tabler/icons-react';
import {
  AUDIO_MIMETYPES,
  DOCUMENT_MIMETYPES,
  IMAGE_MIMETYPES,
  TEXT_MIMETYPES,
  VIDEO_MIMETYPES,
} from '../../../constants/mimetypes';

const getIcon = (mimetype: any) => {
  if (AUDIO_MIMETYPES.includes(mimetype)) {
    return IconFileMusic;
  }

  if (IMAGE_MIMETYPES.includes(mimetype)) {
    return IconPhoto;
  }

  if (VIDEO_MIMETYPES.includes(mimetype)) {
    return IconMovie;
  }

  if (DOCUMENT_MIMETYPES.includes(mimetype)) {
    return IconFileInfo;
  }

  if (TEXT_MIMETYPES.includes(mimetype)) {
    return IconFileCode;
  }

  return IconFile;
};

const AttachmentIcon = ({ mimetype, ...rest }: any) => {
  const Icon = getIcon(mimetype);
  return <Icon {...rest} />;
};

export default AttachmentIcon;
