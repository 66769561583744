import React, { useCallback, useState } from 'react';
import { IconCopy } from '@tabler/icons-react';
import { LIGHT } from '../../constants/surface';
import { Surface } from '../../models';
import { Tooltip } from '../popover';

type CopyButtonProps = {
  className?: string;
  tooltipContent: string | JSX.Element;
  value: string;
  size?: number;
  surface?: Surface;
};

const CopyButton = ({
  className,
  size,
  surface = LIGHT,
  tooltipContent,
  value,
}: CopyButtonProps) => {
  const [copied, setCopied] = useState(false);

  const copyValue = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      navigator.clipboard.writeText(value);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    },
    [value],
  );

  return (
    <Tooltip
      content={tooltipContent}
      isOpen={copied}
      surface={surface}
      trigger="none"
    >
      <button className={className} onClick={copyValue}>
        <IconCopy size={size} className="flex-shrink-0" />
      </button>
    </Tooltip>
  );
};

export default CopyButton;
