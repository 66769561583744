import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-deezer', 'IconBrandDeezer', [
  ['path', { d: 'M3 16.5h2v.5h-2z', key: 'svg-0' }],
  ['path', { d: 'M8 16.5h2.5v.5h-2.5z', key: 'svg-1' }],
  ['path', { d: 'M16 17h-2.5v-.5h2.5z', key: 'svg-2' }],
  ['path', { d: 'M21.5 17h-2.5v-.5h2.5z', key: 'svg-3' }],
  ['path', { d: 'M21.5 13h-2.5v.5h2.5z', key: 'svg-4' }],
  ['path', { d: 'M21.5 9.5h-2.5v.5h2.5z', key: 'svg-5' }],
  ['path', { d: 'M21.5 6h-2.5v.5h2.5z', key: 'svg-6' }],
  ['path', { d: 'M16 13h-2.5v.5h2.5z', key: 'svg-7' }],
  ['path', { d: 'M8 13.5h2.5v-.5h-2.5z', key: 'svg-8' }],
  ['path', { d: 'M8 9.5h2.5v.5h-2.5z', key: 'svg-9' }],
]);
