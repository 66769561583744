import createReactComponent from '../createReactComponent';
export default createReactComponent('signal-6g', 'IconSignal6g', [
  [
    'path',
    { d: 'M18 8h-2a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2v-4h-1', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M10 9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-3',
      key: 'svg-1',
    },
  ],
]);
