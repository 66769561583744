import get from 'lodash/get';
import { OBJECT } from '../constants/dataTypes';
import DataTypeFields, { DataField } from '../models/DataTypeFields';
import DataTypePermissions from '../models/DataTypePermissions';
import DataTypes, { DataType } from '../models/DataTypes';
import { Permission } from '../models/Permission';
import { User } from '../models/User';
import { BaseFieldConfig, FormFieldConfig } from '../models/View';
import { getFieldReverseApiName } from './fields';
import { getSubFieldsAsDataFields } from './objects';
import { isMultiField } from './relationships';
import { isDataAdmin } from './user';

export type PermissionConfig = {
  read: boolean;
  update: boolean;
  create: boolean;
};

export type PermissionType = keyof PermissionConfig;

const NO_PERMISSIONS: PermissionConfig = {
  read: false,
  update: false,
  create: false,
};

export type FieldConfig<T> = {
  field: DataField;
  config: T & BaseFieldConfig;
  index: number;
  parent?: DataField;
  parentFieldType?: DataType;
};

export type FieldConfigWithPermissions<T> = FieldConfig<T> & {
  permissions: PermissionConfig;
};

export type FormFieldConfigWithPermissions =
  FieldConfigWithPermissions<FormFieldConfig>;

type PermissionPathReduce = {
  previousField?: DataField;
  reversePathComponents: string[];
  previousDataType?: DataType;
};

export const permissionPathToFilterField = (
  path: string,
  dataTypesWithRelations: DataTypes,
  dataType: DataType,
) => {
  const pathComponents: string[] = path.split('.');

  const {
    reversePathComponents,
    previousField: resolvedField,
    previousDataType,
  } = pathComponents.reduce(
    (
      {
        previousField,
        reversePathComponents,
        previousDataType,
      }: PermissionPathReduce,
      currentValue: any,
    ): PermissionPathReduce => {
      let currentDataType = previousDataType;

      if (!previousDataType) {
        return { reversePathComponents: [] };
      }

      if (previousField) {
        currentDataType = dataTypesWithRelations.getByName(previousField.type);
      }

      if (!currentDataType) {
        return { reversePathComponents: [] };
      }

      const currentField = currentDataType.fields.getByName(currentValue);

      if (!currentField) {
        return { reversePathComponents: [] };
      }

      /*
       * The reverseName can be incorrect depending on the context it's computed in
       * e.g. `apiName` instead of `apiNameCollection` so we calculate it via utils instead
       */
      const reverseApiName = currentField.relatedField
        ? currentField.relatedField.apiName
        : getFieldReverseApiName(currentField, {
            apiName: currentField.type,
          } as DataType);

      const updatedReversePath: string[] = [
        ...reversePathComponents,
        reverseApiName as string,
      ];

      return {
        previousField: currentField,
        reversePathComponents: updatedReversePath,
        previousDataType: currentDataType,
      };
    },
    {
      previousDataType: dataType,
      reversePathComponents: [],
    } as PermissionPathReduce,
  );

  if (!resolvedField) {
    console.log(
      `Field ${path} does not exist for ${dataType.name} on project.`,
    );
    return null;
  }

  const newFilterField = {
    field: resolvedField,
    dataTypeName: previousDataType ? previousDataType.name : null,
    path: path,
    reversePath: reversePathComponents.reverse(),
    name: resolvedField.name,
    display: resolvedField.display,
    id: resolvedField.id,
  };
  return newFilterField;
};

export const dataTypePermissions = (
  dataType: DataType,
  user: User,
): PermissionConfig => {
  if (!dataType.permissionsEnabled || isDataAdmin(user)) {
    return {
      read: true,
      update: true,
      create: !dataType.readOnly,
    };
  }

  const roleId = get(user, 'role.id') as number | undefined;
  if (roleId) {
    return dataType.permissions
      .filter((permission) => permission.roleIds.includes(roleId))
      .reduce((acc, permission) => {
        // Permissions are additive, so if any matching permission rule matches their roles
        // and that rule gives them any access, then no other rule can revoke that access
        return {
          read: acc.read,
          update: permission.update || acc.update,
          create: (!dataType.readOnly && permission.create) || acc.create,
        };
      }, NO_PERMISSIONS);
  }

  return NO_PERMISSIONS;
};

export const fieldPermissions = (
  field: DataField,
  permissionsEnabled: boolean,
  permissions: DataTypePermissions,
  user: User,
): PermissionConfig => {
  if (!field) {
    return NO_PERMISSIONS;
  }

  const userIsDataAdmin = isDataAdmin(user);
  if (!permissionsEnabled || userIsDataAdmin || field.internal) {
    return { read: true, update: true, create: true };
  }

  const roleId = get(user, 'role.id') as number | undefined;
  if (roleId) {
    return permissions.getByRoleId(roleId).reduce((acc, permission) => {
      const fieldPermission = permission.fieldPermissions.getByFieldId(
        field.id,
      );

      if (!fieldPermission) {
        return acc;
      }

      // Field permissions are additive, so if any matching permission rule matches their roles
      // and that rule gives them any access, then no other rule can revoke that access
      return {
        read: fieldPermission.read || acc.read,
        update: (permission.update && fieldPermission.update) || acc.update,
        create: (permission.create && fieldPermission.create) || acc.create,
      };
    }, NO_PERMISSIONS);
  }

  return NO_PERMISSIONS;
};

export const nestedFieldPermissions = (
  field: DataField,
  dataType: DataType,
  parentField: DataField | undefined,
  parentFieldType: DataType | undefined,
  user: User,
  fieldPermissionsEnabled: boolean,
): PermissionConfig => {
  if (!parentField || !parentFieldType) {
    return fieldPermissions(
      field,
      fieldPermissionsEnabled && dataType.permissionsEnabled,
      dataType.permissions,
      user,
    );
  }

  const parentPermissions = fieldPermissions(
    parentField,
    fieldPermissionsEnabled && dataType.permissionsEnabled,
    dataType.permissions,
    user,
  );

  if (!parentPermissions.read) {
    return parentPermissions;
  }

  const permissions = fieldPermissions(
    field,
    fieldPermissionsEnabled && parentFieldType.permissionsEnabled,
    parentFieldType.permissions,
    user,
  );

  return {
    read: parentPermissions.read && permissions.read,
    update: parentPermissions.update && permissions.update,
    create: parentPermissions.create && permissions.create,
  };
};

export const getFieldFromConfig = (
  config: BaseFieldConfig,
  index: number,
  dataType: DataType,
  dataTypes: DataTypes,
): FieldConfig<BaseFieldConfig> => {
  const parent = config.parent
    ? dataType.fields.getByName(config.parent)
    : undefined;

  if (!parent) {
    return {
      config,
      index,
      field: dataType.fields.getByName(config.name) as DataField,
    };
  }

  if (parent.type === OBJECT) {
    const subFields = getSubFieldsAsDataFields(parent, { includeRoot: true });

    return {
      config,
      index,
      field: subFields.find(({ name }) => name === config.name) as DataField,
      parent,
    };
  }

  const parentFieldType = dataTypes && dataTypes.getByName(parent.type);

  const field = parentFieldType
    ? parentFieldType.fields.getByName(config.name)
    : dataType.fields.getByName(config.name);

  return {
    config,
    index,
    field: field as DataField,
    parent,
    parentFieldType,
  };
};

export const mapFieldsFromConfig =
  (dataType: DataType, dataTypes: DataTypes) =>
  (config: BaseFieldConfig, index: number): FieldConfig<BaseFieldConfig> =>
    getFieldFromConfig(config, index, dataType, dataTypes);

export const mapFieldsWithPermissionsAndConfig = <T>(
  fields: FormFieldConfig[],
  dataType: DataType,
  user: User,
  dataTypes: DataTypes,
  fieldPermissionsEnabled: boolean,
): FieldConfigWithPermissions<T>[] =>
  fields
    .filter(Boolean)
    .map(mapFieldsFromConfig(dataType, dataTypes))
    .filter(({ field, parent }) => field && (!parent || !isMultiField(parent)))
    .map(({ field, parent, parentFieldType, config }) => ({
      field,
      config,
      parent,
      parentFieldType,
      permissions: nestedFieldPermissions(
        field,
        dataType,
        parent,
        parentFieldType,
        user,
        fieldPermissionsEnabled,
      ),
    }))
    .filter(
      (fieldConfig) =>
        !dataType.permissionsEnabled || fieldConfig.permissions.read,
    ) as FieldConfigWithPermissions<T>[];

export const filterValidPermissionFields = (field: DataField) =>
  !field.internal && !get(field, 'relatedField.internal');

export const ruleHasFieldPermissionForEachField = (
  permissionRule: Permission,
  fields: DataTypeFields,
) => {
  const fieldIds = fields
    .filter(filterValidPermissionFields)
    .map((field) => field.id);

  const permissionFieldIds = permissionRule.fieldPermissions.map(
    (fieldPermission) => fieldPermission.dataField.id,
  );

  return fieldIds.every((fieldId) => permissionFieldIds.includes(fieldId));
};

export const dataTypeHasFieldsWithoutRules = (dataType: DataType) =>
  dataType.permissionsEnabled &&
  !dataType.permissions.some((permissionRule) =>
    ruleHasFieldPermissionForEachField(permissionRule, dataType.fields),
  );
