import createReactComponent from '../createReactComponent';
export default createReactComponent('devices-search', 'IconDevicesSearch', [
  ['path', { d: 'M13 13v-4a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v2.5', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h7',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-3' }],
  ['path', { d: 'M16 9h2', key: 'svg-4' }],
]);
