import createReactComponent from '../createReactComponent';
export default createReactComponent('browser-off', 'IconBrowserOff', [
  [
    'path',
    {
      d: 'M8 4h11a1 1 0 0 1 1 1v11m-.288 3.702a1 1 0 0 1 -.712 .298h-14a1 1 0 0 1 -1 -1v-14c0 -.276 .112 -.526 .293 -.707',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 8h4m4 0h8', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
