import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import useDarkMode from './useDarkMode';

const useDarkModeSurface = () => {
  const [isDarkModeEnabled] = useDarkMode();

  return isDarkModeEnabled ? DARK : LIGHT;
};

export default useDarkModeSurface;
