import {
  IconAdjustmentsHorizontal,
  IconAlignLeft,
  IconAt,
  IconBoxMultiple,
  IconBraces,
  IconCalendar,
  IconCalendarEvent,
  IconCalendarTime,
  IconClearFormatting,
  IconClock,
  IconCreditCard,
  IconCurrencyDollar,
  IconFileUpload,
  IconFlag,
  IconHash,
  IconHexagon,
  IconHome2,
  IconId,
  IconLink,
  IconList,
  IconListCheck,
  IconNetwork,
  IconPercentage,
  IconPhone,
  IconRotateClockwise2,
  IconShieldLock,
  IconStar,
  IconToggleRight,
  IconTypography,
  IconUser,
  IconWorld,
  IconWorldWww,
  IconZoomInArea,
} from '@tabler/icons-react';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
  MULTIPLE_OPTION,
  OBJECT,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import {
  ADDRESS,
  COORDINATES,
  CURRENCY,
  DATE as DATE_FORMAT,
  DATE_RANGE,
  DUE_DATE,
  EMAIL,
  FULL_NAME,
  IP_ADDRESS,
  PHONE_NUMBER,
  RATING,
  SINGLE_LINE_TEXT,
  SLIDER,
  UNFORMATTED_NUMBER,
  URL,
} from '../constants/fieldFormats';
import { DataField } from '../models/DataTypeFields';
import { isMultiField } from './relationships';

export const getIconForDataField = (field: DataField) => {
  if (field.name === 'id') {
    return IconFlag;
  }

  if (field.name === 'uuid') {
    return IconHexagon;
  }

  if (field.lookup) {
    return IconZoomInArea;
  }

  if (field.rollup) {
    return IconRotateClockwise2;
  }

  switch (field.type) {
    case TEXT: {
      if (field.typeOptions?.format === EMAIL) {
        return IconAt;
      }

      if (field.typeOptions?.format === IP_ADDRESS) {
        return IconNetwork;
      }

      if (field.typeOptions?.format === SINGLE_LINE_TEXT) {
        return IconTypography;
      }

      if (field.typeOptions?.format === URL) {
        return IconWorldWww;
      }

      return IconAlignLeft;
    }
    case DATE: {
      if (field.typeOptions?.format === DATE_FORMAT) {
        return IconCalendar;
      }

      return IconCalendarTime;
    }
    case INTEGER: {
      if (field.typeOptions?.format === RATING) {
        return IconStar;
      }

      if (field.typeOptions?.format === CURRENCY) {
        return IconCurrencyDollar;
      }

      if (field.typeOptions?.format === UNFORMATTED_NUMBER) {
        return IconClearFormatting;
      }

      if (field.typeOptions?.format === SLIDER) {
        return IconAdjustmentsHorizontal;
      }

      return IconHash;
    }
    case DECIMAL: {
      if (field.typeOptions?.format === CURRENCY) {
        return IconCurrencyDollar;
      }

      if (field.typeOptions?.format === UNFORMATTED_NUMBER) {
        return IconClearFormatting;
      }

      return IconPercentage;
    }
    case DURATION:
      return IconClock;
    case BOOLEAN:
      return IconToggleRight;
    case SINGLE_OPTION:
      return IconList;
    case MULTIPLE_OPTION:
      return IconListCheck;
    case OBJECT: {
      if (field.typeOptions?.format === ADDRESS) {
        return IconHome2;
      }

      if (field.typeOptions?.format === COORDINATES) {
        return IconWorld;
      }

      if (field.typeOptions?.format === DATE_RANGE) {
        return IconCalendarEvent;
      }

      if (field.typeOptions?.format === DUE_DATE) {
        return IconCalendarTime;
      }

      if (field.typeOptions?.format === FULL_NAME) {
        return IconId;
      }

      if (field.typeOptions?.format === PHONE_NUMBER) {
        return IconPhone;
      }

      return IconBraces;
    }
    case 'user':
      return IconUser;
    case 'file':
      return IconFileUpload;
    case 'membership':
      return IconCreditCard;
    case 'role':
      return IconShieldLock;
    default: {
      if (field.relationship || field.relatedField) {
        if (isMultiField(field)) {
          return IconBoxMultiple;
        }
      }

      return IconLink;
    }
  }
};
