import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-ipad-horizontal-star',
  'IconDeviceIpadHorizontalStar',
  [
    [
      'path',
      {
        d: 'M10.5 20h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v5.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 17h1', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z',
        key: 'svg-2',
      },
    ],
  ],
);
