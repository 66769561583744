import React, { useMemo } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import get from 'lodash/get';
import {
  CollectionLayout,
  ROWS,
  SPLIT,
} from '../../../../constants/collectionLayouts';
import { PageInfo, RecordEdge } from '../../../../models/Record';
import { CollectionField } from '../../../../models/View';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import { FieldConfig } from '../../../../utils/permissions';
import { PAGINATION_STYLES } from '../../Collection';
import RowPagination from '../pagination/RowPagination';
import { RecordLayoutByIndexProps } from './TableLayout';

const INNER_CONTAINER_STYLES: Record<string, string> = {
  [ROWS]: `flex flex-col divide-y divide-gray-200 dark:divide-gray-500 overflow-y-auto`,
  [SPLIT]: `flex flex-col divide-y divide-gray-200 dark:divide-gray-500 overflow-y-auto max-h-full split-scroll-container`,
};

type VirtualizedCollectionBodyProps = {
  className?: string;
  count: number;
  edges: RecordEdge[];
  fieldConfigs: FieldConfig<CollectionField>[];
  layout: CollectionLayout;
  pageInfo: PageInfo;
  RecordLayoutByIndex: React.FC<RecordLayoutByIndexProps>;
  showPagination: boolean;
  setPaginationQuery: (paginationArgs: {
    after?: string;
    before?: string;
  }) => void;
  totalCount: number;
  isGroup?: (index: number) => boolean;
  getStickyIndexesForIndex?: (index: number) => number[];
};

const GROUP_HEIGHT_ESTIMATE = 48;
const FIELD_HEIGHT_ESTIMATE = 80;
const FIELDS_PER_ROW_ESTIMATE = 4;

const COLUMN_WIDTHS = {};

const DEFAULT_GET_STICKY_INDEXES = () => [];
const DEFAULT_IS_GROUP = () => false;

const VirtualizedCollectionBody = ({
  className,
  count,
  edges,
  fieldConfigs,
  layout,
  pageInfo,
  RecordLayoutByIndex,
  setPaginationQuery,
  showPagination,
  totalCount,
  isGroup = DEFAULT_IS_GROUP,
  getStickyIndexesForIndex = DEFAULT_GET_STICKY_INDEXES,
}: VirtualizedCollectionBodyProps) => {
  const [isDarkModeEnabled] = useDarkMode();
  const parentRef = React.useRef<HTMLTableElement>(null);

  const estimatedSize = useMemo(
    () =>
      Math.ceil(fieldConfigs.length / FIELDS_PER_ROW_ESTIMATE) *
      FIELD_HEIGHT_ESTIMATE,
    [fieldConfigs],
  );

  const virtualizer = useVirtualizer({
    count,
    horizontal: false,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) =>
      isGroup(index) ? GROUP_HEIGHT_ESTIMATE : estimatedSize,
    overscan: 15,
  });

  const items = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();

  const stickyIndexes = useMemo(
    () => (items.length > 0 ? getStickyIndexesForIndex(items[0].index) : []),
    [getStickyIndexesForIndex, items],
  );

  // This padding makes sure the containers's scrollbar is the right size
  // despite only displaying a subset of elements
  const paddingTop = get(items, [0, 'start'], 0);
  const paddingBottom =
    items.length > 0 ? totalSize - get(items, [items.length - 1, 'end'], 0) : 0;

  return (
    <>
      <div
        className={classNames(
          className,
          INNER_CONTAINER_STYLES[layout ?? ROWS],
        )}
        ref={parentRef}
      >
        {paddingTop > 0 && <div style={{ minHeight: `${paddingTop}px` }} />}
        {items.length > 0 &&
          stickyIndexes.length > 0 &&
          stickyIndexes.map((stickyIndex) => (
            <RecordLayoutByIndex
              key={stickyIndex}
              data-index={stickyIndex}
              index={stickyIndex}
              columnWidths={COLUMN_WIDTHS}
              ref={(node) => {
                virtualizer.measureElement(node);
                return node;
              }}
            />
          ))}
        {items.map((virtualRow) => (
          <RecordLayoutByIndex
            key={virtualRow.key}
            columnWidths={COLUMN_WIDTHS}
            data-index={virtualRow.index}
            index={virtualRow.index}
            ref={(node) => {
              virtualizer.measureElement(node);
              return node;
            }}
          />
        ))}
        {paddingBottom > 0 && (
          <div style={{ minHeight: `${paddingBottom}px` }} />
        )}
        {showPagination && (
          <RowPagination
            className={classNames(PAGINATION_STYLES[layout](isDarkModeEnabled))}
            pageInfo={pageInfo}
            totalCount={totalCount}
            setPaginationQuery={setPaginationQuery}
            currentLength={edges.length}
          />
        )}
      </div>
    </>
  );
};

export default VirtualizedCollectionBody;
