import createReactComponent from '../createReactComponent';
export default createReactComponent('at-off', 'IconAtOff', [
  [
    'path',
    {
      d: 'M9.174 9.17a4 4 0 0 0 5.646 5.668m1.18 -2.838a4 4 0 0 0 -4 -4',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M19.695 15.697a2.5 2.5 0 0 0 1.305 -2.197v-1.5a9 9 0 0 0 -13.055 -8.047m-2.322 1.683a9 9 0 0 0 9.877 14.644',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
