const sampleFieldNames = [
  'Name',
  'Status',
  'Notes',
  'Email',
  'Created',
  'Attachments',
  'Description',
  'Last Name',
  'Type',
  'First Name',
  'Last Modified',
  'Phone',
  'Date',
  'Company',
  'Title',
  'Calculation',
  'Location',
  'Category',
  'Role',
  'Address',
  'Phone Number',
  'Full Name',
  'Client',
  'City',
  'Website',
  'Start Date',
  'Country',
  'Logo',
  'Url',
  'State',
  'Price',
  'Company Name',
  'Image',
  'Email Address',
  'User',
  'Project',
  'End Date',
  'Due Date',
  'Amount',
  'Quantity',
  'Link',
  'Department',
  'Order',
  'Owner',
  'Photo',
  'Industry',
  'Team',
  'Account',
  'Created By',
  'Month',
  'Priority',
  'Year',
  'UserId',
  'Source',
  'Created Time',
  'Product',
  'Number',
  'Created Date',
  'Users',
  'Tags',
  'Contact',
  'Comments',
  'Job Title',
  'Autonumber',
  'Prenom',
  'Label',
  'LinkedIn',
  'Total',
  'Client Name',
  'Gender',
  'Stage',
  'Product Name',
  'Invoice',
  'Duration',
  'Value',
  'Currency',
  'SKU',
  'Brand',
  'Telephone',
  'Customer',
  'Code',
  'Clients',
  'Rating',
  'Done',
  'Size',
  'Date Created',
  'Contact Name',
  'Message',
  'Order Date',
  'Projects',
  'Site',
  'Region',
  'Interactions',
  'Street Address',
  'Date of Birth',
  'Payment Method',
  'Latitude',
  'Longitude',
  'Note',
  'Select',
  'Updated',
  'Event',
  'Project Name',
  'Contacts',
  'Tasks',
];

export default sampleFieldNames;
