import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'building-monument',
  'IconBuildingMonument',
  [
    ['path', { d: 'M8 18l2 -13l2 -2l2 2l2 13', key: 'svg-0' }],
    ['path', { d: 'M5 21v-3h14v3', key: 'svg-1' }],
    ['path', { d: 'M3 21l18 0', key: 'svg-2' }],
  ],
);
