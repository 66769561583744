import createReactComponent from '../createReactComponent';
export default createReactComponent('chess-king', 'IconChessKing', [
  [
    'path',
    {
      d: 'M8 16l-1.447 .724a1 1 0 0 0 -.553 .894v2.382h12v-2.382a1 1 0 0 0 -.553 -.894l-1.447 -.724h-8z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8.5 16a3.5 3.5 0 1 1 3.163 -5h.674a3.5 3.5 0 1 1 3.163 5z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 6h6', key: 'svg-2' }],
  ['path', { d: 'M12 3v8', key: 'svg-3' }],
]);
