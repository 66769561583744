import createReactComponent from '../createReactComponent';
export default createReactComponent('bong-off', 'IconBongOff', [
  [
    'path',
    {
      d: 'M9 5v-2h4v6m1.5 1.5l2.5 -2.5l2 2l-2.5 2.5m-.5 3.505a5 5 0 1 1 -7 -4.589v-2.416',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 3h6', key: 'svg-1' }],
  ['path', { d: 'M6.1 17h9.8', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
