import { useMemo } from 'react';
import Fuse from 'fuse.js';

type FuzzySearchProps = {
  keys: string[];
  list: any[];
};

const useFuzzySearch = ({ keys, list }: FuzzySearchProps) => {
  const fuse = useMemo(
    () => new Fuse(list, { keys, shouldSort: true, threshold: 0.25 }),
    [keys, list],
  );

  return fuse;
};

export default useFuzzySearch;
