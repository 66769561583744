import createReactComponent from '../createReactComponent';
export default createReactComponent('wand-off', 'IconWandOff', [
  [
    'path',
    {
      d: 'M10.5 10.5l-7.5 7.5l3 3l7.5 -7.5m2 -2l5.5 -5.5l-3 -3l-5.5 5.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 6l3 3', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M8.433 4.395c.35 -.36 .567 -.852 .567 -1.395a2 2 0 0 0 2 2c-.554 0 -1.055 .225 -1.417 .589',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M18.418 14.41c.36 -.36 .582 -.86 .582 -1.41a2 2 0 0 0 2 2c-.555 0 -1.056 .226 -1.419 .59',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
