import React from 'react';
import { useSelector } from 'react-redux';
import PoweredByNoloco from '../components/PoweredByNoloco';
import { sidebarRecordViewTitleSelector } from '../selectors/elementsSelectors';

type RecordViewNavTitleProps = {
  projectName: string;
  utmSource: string;
};

const RecordViewNavTitle = ({
  projectName,
  utmSource,
}: RecordViewNavTitleProps) => {
  const recordViewTitle = useSelector(sidebarRecordViewTitleSelector);

  return recordViewTitle ? (
    <div className="flex items-center">{recordViewTitle.title}</div>
  ) : (
    <PoweredByNoloco
      projectName={projectName}
      utmSource={utmSource}
      isMobileTopNav={true}
    />
  );
};

export default RecordViewNavTitle;
