import createReactComponent from '../createReactComponent';
export default createReactComponent('heart-handshake', 'IconHeartHandshake', [
  [
    'path',
    {
      d: 'M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 6l-3.293 3.293a1 1 0 0 0 0 1.414l.543 .543c.69 .69 1.81 .69 2.5 0l1 -1a3.182 3.182 0 0 1 4.5 0l2.25 2.25',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12.5 15.5l2 2', key: 'svg-2' }],
  ['path', { d: 'M15 13l2 2', key: 'svg-3' }],
]);
