import createReactComponent from '../createReactComponent';
export default createReactComponent('plug', 'IconPlug', [
  [
    'path',
    {
      d: 'M9.785 6l8.215 8.215l-2.054 2.054a5.81 5.81 0 1 1 -8.215 -8.215l2.054 -2.054z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 20l3.5 -3.5', key: 'svg-1' }],
  ['path', { d: 'M15 4l-3.5 3.5', key: 'svg-2' }],
  ['path', { d: 'M20 9l-3.5 3.5', key: 'svg-3' }],
]);
