import createReactComponent from '../createReactComponent';
export default createReactComponent('backpack', 'IconBackpack', [
  [
    'path',
    {
      d: 'M5 18v-6a6 6 0 0 1 6 -6h2a6 6 0 0 1 6 6v6a3 3 0 0 1 -3 3h-8a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 6v-1a2 2 0 1 1 4 0v1', key: 'svg-1' }],
  ['path', { d: 'M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4', key: 'svg-2' }],
  ['path', { d: 'M11 10h2', key: 'svg-3' }],
]);
