import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'playstation-square',
  'IconPlaystationSquare',
  [
    [
      'path',
      {
        d: 'M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M8 8m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
        key: 'svg-1',
      },
    ],
  ],
);
