import React, { useCallback, useState } from 'react';
import Loader from '../loading/Loader';
import Modal from '../modal/Modal';
import BaseButton from './Button';

type Props<T extends React.ElementType> = {
  Button?: T;
  children: React.ReactNode;
  confirmText: React.ReactNode;
  description: React.ReactNode;
  icon?: React.ReactNode;
  onConfirm?: () => Promise<any>;
  onOpenChange?: (isOpen: boolean) => void;
  title: React.ReactNode;
  variant?: string;
} & React.ComponentPropsWithoutRef<T>;

const ConfirmationButton = <T extends React.ElementType = typeof BaseButton>({
  Button = BaseButton,
  children,
  confirmText,
  description,
  icon,
  onConfirm = async () => {},
  onOpenChange,
  title,
  variant,
  ...rest
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onOpen = useCallback(() => {
    setIsOpen(true);
    if (onOpenChange) {
      onOpenChange(true);
    }
  }, [onOpenChange]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
  }, [onOpenChange]);

  const handleOnConfirm = useCallback(() => {
    setIsLoading(true);
    onConfirm().finally(() => {
      setIsLoading(false);
      onClose();
    });
  }, [onClose, onConfirm]);

  return (
    <>
      {isOpen && (
        <Modal
          confirmText={isLoading ? <Loader size="sm" /> : confirmText}
          icon={icon}
          onCancel={onClose}
          onClose={onClose}
          onConfirm={handleOnConfirm}
          onOpen
          title={title}
          variant={variant}
        >
          <p>{description}</p>
        </Modal>
      )}
      {/* @ts-expect-error TS2786: Button cannot be used as a JSX component. */}
      <Button {...rest} onClick={onOpen} variant={variant}>
        {children}
      </Button>
    </>
  );
};

export default ConfirmationButton;
