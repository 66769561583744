import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-rounded-letter-u',
  'IconSquareRoundedLetterU',
  [
    ['path', { d: 'M10 8v6a2 2 0 1 0 4 0v-6', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-1',
      },
    ],
  ],
);
