import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circuit-capacitor',
  'IconCircuitCapacitor',
  [
    ['path', { d: 'M22 12h-8', key: 'svg-0' }],
    ['path', { d: 'M2 12h8', key: 'svg-1' }],
    ['path', { d: 'M10 7v10', key: 'svg-2' }],
    ['path', { d: 'M14 7v10', key: 'svg-3' }],
  ],
);
