import { LG, MD, SM, XS } from '../../constants/tShirtSizes';

const styleForSizes = (size: any, showIndicator = false, multi = false) =>
  ({
    [XS]: {
      p: { l: 0, r: showIndicator ? 6 : 0, y: multi ? 0.5 : 0 },
      text: ['xs'],
    },
    [SM]: {
      p: { l: 2, r: showIndicator ? 6 : 2, y: multi ? 0.5 : 1 },
      text: ['sm'],
    },
    [MD]: { p: { l: 3, r: showIndicator ? 10 : 3, y: multi ? 0.5 : 1.5 } },
    [LG]: { p: { l: 6, r: showIndicator ? 12 : 6, y: multi ? 2 : 4 } },
  })[size];

export default styleForSizes;
