import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'layout-align-bottom',
  'IconLayoutAlignBottom',
  [
    ['path', { d: 'M4 20l16 0', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M9 4m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
        key: 'svg-1',
      },
    ],
  ],
);
