import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'rectangle-vertical-filled',
  'IconRectangleVerticalFilled',
  [
    [
      'path',
      {
        d: 'M17 2h-10a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3 -3v-14a3 3 0 0 0 -3 -3z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
  ],
);
