import createReactComponent from '../createReactComponent';
export default createReactComponent('home-edit', 'IconHomeEdit', [
  [
    'path',
    {
      d: 'M9 21v-6a2 2 0 0 1 2 -2h2c.645 0 1.218 .305 1.584 .78',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M20 11l-8 -8l-9 9h2v7a2 2 0 0 0 2 2h4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z',
      key: 'svg-2',
    },
  ],
]);
