import createReactComponent from '../createReactComponent';
export default createReactComponent('news', 'IconNews', [
  [
    'path',
    {
      d: 'M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 8l4 0', key: 'svg-1' }],
  ['path', { d: 'M8 12l4 0', key: 'svg-2' }],
  ['path', { d: 'M8 16l4 0', key: 'svg-3' }],
]);
