import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import OnboardingModal from '@noloco/ui/src/components/onboarding/OnboardingModal';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { ChartAggregation } from '../constants/chartAggregations';
import {
  BOARD,
  CALENDAR,
  CARDS,
  CHARTS,
  COLUMNS,
  CollectionLayout,
  GANTT,
  MAP,
  PIVOT_TABLE,
  ROWS,
  SINGLE_RECORD,
  SPLIT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '../constants/collectionLayouts';
import { darkModeColors } from '../constants/darkModeColors';
import { FOLDER } from '../constants/elements';
import { PREMIUM_LAYOUTS } from '../constants/features';
import { OrderByDirection } from '../constants/orderByDirections';
import { PivotTable } from '../constants/pivotTable';
import { DataType } from '../models/DataTypes';
import {
  ActionButton,
  DepValue,
  ElementPath,
  VisibilityRules,
} from '../models/Element';
import { IconValue } from '../models/IconValue';
import { Project } from '../models/Project';
import { BaseRecord } from '../models/Record';
import StateItem from '../models/StateItem';
import { User } from '../models/User';
import {
  DataList,
  ExportButton,
  FormFieldConfig,
  GroupBy,
  ImportButton,
  MapProps,
  Search,
} from '../models/View';
import { setSelectedElement } from '../reducers/elements';
import { scopeSelector } from '../selectors/dataSelectors';
import { isSelectedSelector } from '../selectors/elementsSelectors';
import { verifyIfPremium } from '../utils/collectionLayouts';
import {
  useErrorAlert,
  useRemoveAllAlerts,
  useSuccessAlert,
} from '../utils/hooks/useAlerts';
import useAuthWrapper from '../utils/hooks/useAuthWrapper';
import useDarkMode from '../utils/hooks/useDarkMode';
import useIsWindowOnline from '../utils/hooks/useIsWIndowOnline';
import usePagePathname from '../utils/hooks/usePagePathname';
import useRouter from '../utils/hooks/useRouter';
import { getText } from '../utils/lang';
import { dataTypePermissions } from '../utils/permissions';
import ViewCollection from './ViewCollection';
import SplitLayout from './sections/collections/SplitLayout';
import NewForm, { NewFormProps } from './sections/view/NewForm';
import NewFormModal from './sections/view/NewFormModal';
import RecordView from './sections/view/RecordView';
import ViewBreadcrumbs from './sections/view/ViewBreadcrumbs';

const EMPTY_SUCCESS_MESSAGE = { message: '', icon: '' };
const LANG_KEY = 'settings.pwa';

type ViewProps = {
  actionButtons?: ActionButton[];
  calendarView: string;
  charts: any[];
  className: string;
  coverPhoto: { src: string };
  dataList: DataList;
  dataType: DataType;
  dateEnd: DepValue | undefined;
  dateStart: DepValue | undefined;
  editorMode: boolean;
  elementPath: ElementPath;
  emptyState: any;
  enableDragAndDropEdit: boolean;
  endTime: number;
  exportButton: ExportButton;
  fields: FormFieldConfig[];
  filters: any[];
  ganttDependency?: DepValue;
  groupBy?: StateItem;
  groupBySort: OrderByDirection;
  groupOptions: { [key: string]: { hidden: boolean } };
  groups: GroupBy[];
  hideBreadcrumbs?: boolean;
  hideEmptyGroups: boolean;
  hideNewButton: boolean;
  icon?: IconValue;
  importButton: ImportButton;
  inOnboarding?: boolean;
  layout: CollectionLayout;
  limitPerGroup: number;
  map?: MapProps;
  name?: string;
  new?: any;
  newButton: { visibilityRules?: VisibilityRules };
  newButtonText: string;
  newLink: string | null;
  onClick?: () => void;
  parentPage?: string | null;
  pivotTable?: PivotTable;
  project: Project;
  record?: BaseRecord;
  recordTitle: string;
  routePath: string;
  rowLink: string;
  search: Search;
  sidebarSize?: any;
  startTime: number;
  summary?: { field: StateItem; type: ChartAggregation };
  subtitle: string;
  title: string;
  openFormInModal?: boolean;
};

const View = memo(
  forwardRef(
    (
      {
        actionButtons = [],
        calendarView,
        charts = [],
        className,
        coverPhoto,
        dataList,
        dataType,
        dateEnd,
        dateStart,
        editorMode,
        elementPath,
        openFormInModal,
        emptyState,
        enableDragAndDropEdit,
        endTime,
        exportButton,
        fields = [],
        filters = [],
        ganttDependency,
        groupBy,
        groupBySort,
        groupOptions,
        groups,
        hideBreadcrumbs,
        hideEmptyGroups,
        hideNewButton,
        icon,
        importButton,
        inOnboarding,
        layout: viewLayout,
        limitPerGroup,
        map,
        name,
        new: newProps = {},
        newButton = {},
        newButtonText,
        newLink,
        onClick,
        parentPage,
        pivotTable,
        project,
        record,
        recordTitle,
        routePath,
        rowLink,
        search,
        sidebarSize,
        startTime,
        summary,
        subtitle,
        title,
      }: ViewProps,
      ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
      // This could be a risk, but it solves the issue of figuring out which sub-routes to render
      const scope = useSelector(scopeSelector);
      const [isDarkModeEnabled] = useDarkMode();

      const { user } = useAuthWrapper();
      const isSelected = useSelector(isSelectedSelector(elementPath));
      const dispatch = useDispatch();
      const element = useMemo(
        () => get(project.elements, elementPath, {}),
        [elementPath, project.elements],
      );

      const formatRecordScope = (record: any) => ({
        [`${element.id}:VIEW`]: record,
      });

      const permissions = useMemo(
        () => dataTypePermissions(dataType, user as User),
        [dataType, user],
      );

      const handleOnClickOverlay = useCallback(
        (event: any) => {
          event.stopPropagation();
          dispatch(setSelectedElement(elementPath));
        },
        [dispatch, elementPath],
      );

      const { routePrefix, viewRoutePrefix, parentPageElement } =
        usePagePathname(routePath, parentPage, project);

      const premiumLayoutsEnabled = useIsFeatureEnabled(PREMIUM_LAYOUTS);
      const layout = useMemo(
        () => verifyIfPremium(viewLayout, premiumLayoutsEnabled),
        [premiumLayoutsEnabled, viewLayout],
      );

      const hasGroupByField = (groups && groups.length > 0) || groupBy;

      const isSingleRecordLayout = layout === SINGLE_RECORD;
      const isCalendarLayout = layout === CALENDAR;
      const isTimelineOrGanttLayout = layout === TIMELINE || layout === GANTT;
      const isMapLayout = layout === MAP;
      const isSplitLayout = layout === SPLIT;
      const isFullScreenLayout =
        isCalendarLayout ||
        isTimelineOrGanttLayout ||
        isMapLayout ||
        layout === ROWS ||
        layout === TABLE ||
        layout === PIVOT_TABLE ||
        layout === TABLE_FULL ||
        (layout === BOARD && hasGroupByField) ||
        isSplitLayout;

      const newFormProps: NewFormProps = useMemo(
        () => ({
          ...newProps,
          onClick,
          icon,
          name,
          dataType,
          elementPath,
          project,
          ref,
          scope,
          rootPathname: routePrefix,
          viewRootPathname: viewRoutePrefix,
          redirectOnSuccess: true,
          hideFormOnSuccess: false,
          successMessage: EMPTY_SUCCESS_MESSAGE,
        }),
        [
          newProps,
          onClick,
          icon,
          name,
          dataType,
          elementPath,
          project,
          ref,
          scope,
          routePrefix,
          viewRoutePrefix,
        ],
      );
      const newFormPropsModal = useMemo(
        () => omit(newFormProps, 'coverPhoto'),
        [newFormProps],
      );

      const mainCollection = (
        <div
          className={classNames(
            'flex w-full flex-col',
            {
              relative:
                layout !== TABLE_FULL &&
                layout !== BOARD &&
                !isCalendarLayout &&
                !isMapLayout,
              'max-w-screen-lg':
                layout === CARDS ||
                layout === ROWS ||
                layout === COLUMNS ||
                layout === CHARTS,
              'max-w-screen-xl px-4 sm:px-2':
                layout === TABLE || layout === PIVOT_TABLE,
              'absolute inset-0 overflow-hidden':
                isFullScreenLayout && !inOnboarding,
              [`${
                isDarkModeEnabled
                  ? `${darkModeColors.surfaces.elevation0} ${darkModeColors.borders.one}`
                  : 'border border-gray-200 bg-white'
              } h-full`]: isSplitLayout,
              'mb-6 mt-8 sm:mb-3 sm:mt-3':
                hideBreadcrumbs && layout !== SINGLE_RECORD,
              'h-full': isTimelineOrGanttLayout,
            },
            `view-${element.id}`,
          )}
          data-testid="view-collection"
        >
          {!hideBreadcrumbs && !inOnboarding && !isSingleRecordLayout && (
            <ViewBreadcrumbs
              className={classNames(
                'mb-6 mt-8 flex sm:mb-3 sm:mt-3 sm:text-xs',
                layout === BOARD || layout === TABLE_FULL || isMapLayout
                  ? 'mx-8 sm:mx-4'
                  : 'mx-4',
              )}
              additionalLinks={
                parentPageElement
                  ? [{ to: `/${routePath}`, name: name as string }]
                  : []
              }
              icon={
                parentPageElement ? get(parentPageElement, 'props.icon') : icon
              }
              name={
                parentPageElement ? get(parentPageElement, 'props.name') : name
              }
              rootPathname={routePrefix}
            />
          )}
          <ViewCollection
            actionButtons={actionButtons}
            calendarView={calendarView}
            charts={charts}
            className={classNames(className, 'mt-0', {
              'mx-4 pb-16': !isFullScreenLayout,
              'mx-4': layout === ROWS,
              'flex flex-grow flex-col overflow-hidden': isFullScreenLayout,
            })}
            coverPhoto={coverPhoto}
            dataList={dataList}
            dateEnd={dateEnd}
            dateStart={dateStart}
            editorMode={editorMode}
            elementPath={elementPath}
            emptyState={emptyState}
            /* Not all views have a enableDragAndDropEdit prop, we want to default to drag and drop enabled if they don't */
            enableDragAndDropEdit={enableDragAndDropEdit ?? true}
            endTime={endTime}
            exportButton={exportButton}
            fields={fields}
            filters={filters}
            formatRecordScope={formatRecordScope}
            ganttDependency={ganttDependency}
            groupBy={groupBy}
            groupBySort={groupBySort}
            groupOptions={groupOptions}
            groups={groups}
            hideEmptyGroups={hideEmptyGroups}
            hideNewButton={hideNewButton}
            icon={icon}
            importButton={importButton}
            layout={layout}
            limitPerGroup={limitPerGroup}
            map={map}
            name={name}
            newButton={newButton}
            newButtonText={newButtonText}
            newLink={newLink}
            openFormInModal={openFormInModal}
            onClick={onClick}
            pivotTable={pivotTable}
            project={project}
            record={record}
            recordTitle={recordTitle}
            rootPathname={routePrefix}
            rowLink={rowLink}
            scope={scope}
            search={search}
            sidebarSize={sidebarSize}
            startTime={startTime}
            summary={summary}
            subtitle={subtitle}
            title={title}
            track={true}
            viewId={element.id}
            viewRootPathname={viewRoutePrefix}
          />
          {editorMode && !isSelected && (
            <div
              className="absolute inset-x-0 inset-y-0"
              onClick={handleOnClickOverlay}
            />
          )}
          {permissions.create &&
            (!parentPageElement || parentPageElement.type === FOLDER) && (
              <Route path={`${viewRoutePrefix}/new-modal`}>
                <NewFormModal {...newFormPropsModal} hideBreadcrumbs={true} />
              </Route>
            )}
        </div>
      );

      const recordView = (
        <RecordView
          {...record}
          className={classNames({ 'overflow-y-auto': isSplitLayout })}
          dataType={dataType}
          editorMode={editorMode}
          element={element}
          elementPath={elementPath}
          icon={icon}
          isSplitLayout={isSplitLayout}
          name={name}
          onClick={onClick}
          permissions={permissions}
          project={project}
          rootPathname={routePrefix}
          scope={scope}
        />
      );

      return (
        <Route path={routePrefix}>
          <Switch>
            {permissions.create &&
              (!parentPageElement || parentPageElement.type === FOLDER) && (
                <Route path={`${viewRoutePrefix}/new`}>
                  <NewForm {...newFormProps} />
                </Route>
              )}
            {(!parentPageElement || parentPageElement.type === FOLDER) &&
              !isSplitLayout && (
                <Route path={`${viewRoutePrefix}/view/:recordId/:tab?`}>
                  {recordView}
                </Route>
              )}
            {isSplitLayout && (
              <Route path={`${viewRoutePrefix}/(view)?/:recordId?/:tab?`}>
                <SplitLayout
                  // @ts-expect-error TS(2322): Type '{ mainCollection: Element; sidebarSize: any;... Remove this comment to see the full error message
                  mainCollection={mainCollection}
                  sidebarSize={sidebarSize}
                  recordView={recordView}
                  routePrefix={routePrefix}
                />
              </Route>
            )}
            {!isSplitLayout && <Route>{mainCollection}</Route>}
          </Switch>
        </Route>
      );
    },
  ),
);

const ViewWrapper = memo(
  ({ dataList, editorMode, project, ...rest }: ViewProps) => {
    const {
      query: { __onboardingModal = 'false' },
      replaceQueryParams,
    } = useRouter();
    const errorAlert = useErrorAlert();
    const successAlert = useSuccessAlert();
    const removeAllAlerts = useRemoveAllAlerts();

    const dataType = useMemo(
      () => project.dataTypes.getByName(dataList.dataType),
      [dataList.dataType, project.dataTypes],
    );

    const handleConnectionChange = useCallback(
      (isOnline) => {
        removeAllAlerts();

        if (isOnline) {
          successAlert(getText(LANG_KEY, 'online.title'));
        } else {
          errorAlert(
            getText(LANG_KEY, 'offline.title'),
            getText(LANG_KEY, 'offline.subtitle'),
            { timeout: 0 },
          );
        }
      },
      [errorAlert, removeAllAlerts, successAlert],
    );

    useIsWindowOnline(handleConnectionChange);

    if (dataType) {
      return (
        <>
          <View
            dataList={dataList}
            editorMode={editorMode}
            project={project}
            {...rest}
            dataType={dataType}
          />
          {__onboardingModal === 'true' && (
            <OnboardingModal replaceQueryParams={replaceQueryParams} />
          )}
        </>
      );
    }

    if (editorMode) {
      return (
        <div className="m-8 flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white p-8 shadow-md">
          <div className="w-full py-24 text-center text-gray-600">
            {getText('elements.VIEW.error')}
          </div>
        </div>
      );
    }

    return null;
  },
);

View.displayName = 'View';

export default ViewWrapper;
