import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'player-track-next-filled',
  'IconPlayerTrackNextFilled',
  [
    [
      'path',
      {
        d: 'M2 5v14c0 .86 1.012 1.318 1.659 .753l8 -7a1 1 0 0 0 0 -1.506l-8 -7c-.647 -.565 -1.659 -.106 -1.659 .753z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M13 5v14c0 .86 1.012 1.318 1.659 .753l8 -7a1 1 0 0 0 0 -1.506l-8 -7c-.647 -.565 -1.659 -.106 -1.659 .753z',
        fill: 'currentColor',
        key: 'svg-1',
        strokeWidth: '0',
      },
    ],
  ],
);
