import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'building-carousel',
  'IconBuildingCarousel',
  [
    ['path', { d: 'M12 12m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0', key: 'svg-0' }],
    ['path', { d: 'M5 8m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
    ['path', { d: 'M12 4m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
    ['path', { d: 'M19 8m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
    ['path', { d: 'M5 16m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
    ['path', { d: 'M19 16m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-5' }],
    ['path', { d: 'M8 22l4 -10l4 10', key: 'svg-6' }],
  ],
);
