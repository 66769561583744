import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { IconLink } from '@tabler/icons-react';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import {
  ApiConnection,
  Endpoint,
  NONE,
  OAUTH2,
} from '@noloco/core/src/constants/apis';
import { BASE_URI } from '@noloco/core/src/constants/auth';
import { API } from '@noloco/core/src/constants/dataSources';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { updateSource } from '@noloco/core/src/reducers/project';
import { toInput } from '@noloco/core/src/utils/apis';
import { extractErrorMessages } from '@noloco/core/src/utils/errors';
import { useInfoAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import useSetDocumentTitle from '@noloco/core/src/utils/hooks/useSetDocumentTitle';
import { getText } from '@noloco/core/src/utils/lang';
import {
  CONNECT_DATA_SOURCE,
  TEST_DATA_SOURCE_ENDPOINT,
  UPDATE_DATA_SOURCE,
} from '../../queries/project';
import { useExistingDataSource } from '../../utils/hooks/useExistingDataSource';
import { useIsDataSourceNameValid } from '../../utils/hooks/useIsDataSourceNameValid';
import { openAuthorizationWindow } from '../../utils/oauth';
import Guide from '../Guide';
import ApiEditor from './api/ApiEditor';
import ApiEndpointEditor from './api/ApiEndpointEditor';

const OAUTH2_CALLBACK_URI = `${BASE_URI}/data-sources/api/oauth/callback/:projectName/:dataSourceId`;

const LANG_KEY = 'data.api';

type Props = {
  project: Project;
};

const AddApi = ({ project }: Props) => {
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const dispatch = useDispatch();
  const {
    query: { id, sourceName },
  }: any = useRouter();
  const successAlert = useInfoAlert();

  const [dataSourceId, setDataSourceId] = useState<number | undefined>(id);
  const [externalId, setExternalId] = useState<string | null>(null);

  const [display, setDisplay] = useState(sourceName ?? 'My API');
  const [api, setApi] = useState<ApiConnection>({
    authentication: NONE,
    baseUrl: '',
    headers: [
      {
        key: 'Content-Type',
        value: 'application/json',
      },
      {
        key: 'Accept',
        value: 'application/json',
      },
    ],
    options: null,
  });
  const [endpoints, setEndpoints] = useState<Endpoint[]>([]);

  const onUpdateApi = useCallback(
    (path: string[], value: any) => setApi(set(path, value, api)),
    [api],
  );

  const [selectedEndpoint, setSelectedEndpoint] = useState<number | null>(null);
  const [selectedEndpointData, setSelectedEndpointData] = useState(null);
  const [selectedEndpointError, setSelectedEndpointError] = useState<
    String[] | null
  >(null);
  const [testEndpoint] = useMutation(TEST_DATA_SOURCE_ENDPOINT);

  const onSelectEndpoint = useCallback(
    (index: number) => () => {
      setSelectedEndpointData(null);
      setSelectedEndpointError(null);
      setSelectedEndpoint(index);
    },
    [setSelectedEndpoint, setSelectedEndpointData, setSelectedEndpointError],
  );

  const onAddEndpoint = useCallback(() => {
    const nextIndex = endpoints.length;

    setEndpoints([
      ...endpoints,
      {
        body: null,
        cursorPath: null,
        display: `Endpoint ${endpoints.length + 1}`,
        fields: null,
        firstPage: null,
        headers: [{ key: null, value: null }],
        idField: null,
        method: 'GET',
        pagination: null,
        parameters: [{ key: null, value: null }],
        path: '',
        pathPath: null,
        resultPath: null,
      },
    ]);
    setSelectedEndpointData(null);
    setSelectedEndpointError(null);
    setSelectedEndpoint(nextIndex);
  }, [endpoints, setEndpoints]);
  const onDeleteEndpoint = useCallback(
    (index: number) => () => {
      setSelectedEndpoint(null);
      setEndpoints(
        endpoints.filter((_, endpointIndex: number) => endpointIndex !== index),
      );
    },
    [endpoints, setEndpoints],
  );
  const endpointOptions = useMemo(
    () =>
      endpoints
        .filter((_, index) => index !== selectedEndpoint)
        .map((endpoint) => ({
          label: (
            <span className="flex space-x-2">
              <IconLink className="my-auto" size={14} />
              <p>{endpoint.display}</p>
            </span>
          ),
          value: camelCase(endpoint.display),
        })),
    [endpoints, selectedEndpoint],
  );

  const {
    existingDataSource: dataSource,
    isUpdate: dataSourceExists,
    loading,
  } = useExistingDataSource(dataSourceId, project);

  useEffect(() => {
    if (id && dataSource && Object.keys(dataSource).length > 0) {
      setDisplay(dataSource.display);
      setExternalId(dataSource.externalId);

      setApi(dataSource.connection);

      setEndpoints(
        dataSource.types.map(
          (dataType: DataType) => dataType.source.connection,
        ),
      );
    }
  }, [dataSource, id]);

  useSetDocumentTitle(getText(LANG_KEY, 'title'));

  const apiInput = useMemo(
    () => ({
      ...api,
      display,
    }),
    [api, display],
  );
  const onTest = useCallback(
    async (endpoint: Endpoint) => {
      setSelectedEndpointError(null);
      try {
        const { data } = await testEndpoint({
          variables: {
            projectName: project.name,
            dataSourceId,
            api: apiInput,
            endpoint: toInput(endpoint),
          },
        });

        setSelectedEndpointData(data.testDataSourceEndpoint.data);
      } catch (error) {
        setSelectedEndpointError(extractErrorMessages(error as ApolloError));
      }
    },
    [apiInput, dataSourceId, project, testEndpoint],
  );

  // Data Source validity

  const isDisplayValid = useIsDataSourceNameValid(
    project,
    display,
    dataSourceExists,
    dataSource,
  );

  // Form steps

  const [step, setStep] = useState(id ? 1 : 0);

  // Connect source

  const [connectDataSource, { loading: isConnecting }] =
    useMutation(CONNECT_DATA_SOURCE);

  const onConnectDataSource = useCallback(() => {
    connectDataSource({
      variables: {
        projectName: project.name,
        display,
        connection: apiInput,
        type: API,
      },
    }).then(({ data }) => {
      if (data && data.connectDataSource) {
        setDataSourceId(data.connectDataSource.id);
        setExternalId(data.connectDataSource.externalId);
        onAddEndpoint();
        setStep(step + 2);
      }
    });
  }, [apiInput, connectDataSource, display, onAddEndpoint, project.name, step]);

  // Update source

  const [updateDataSource, { loading: isUpdating }] =
    useMutation(UPDATE_DATA_SOURCE);

  const onUpdateDataSource = useCallback(
    (connection: any, { skipAlert } = { skipAlert: false }) =>
      updateDataSource({
        variables: {
          connection,
          display,
          externalId,
          id: dataSourceId,
          projectName: project.name,
          type: API,
        },
      }).then(({ data }) => {
        dispatch(updateSource(data.updateDataSource));

        if (!skipAlert) {
          successAlert(getText(LANG_KEY, 'saved'));
        }
      }),
    [
      dataSourceId,
      dispatch,
      display,
      externalId,
      project.name,
      successAlert,
      updateDataSource,
    ],
  );
  const onSaveApi = useCallback(
    (options = { skipAlert: false }) =>
      onUpdateDataSource({ ...apiInput, endpoints }, options),
    [apiInput, endpoints, onUpdateDataSource],
  );

  const onUpdateEndpoint = useCallback(
    (index: number) => (endpoint: Endpoint) => {
      const newEndpoints = set([index], endpoint, endpoints);
      setEndpoints(newEndpoints);

      const connection = { ...apiInput, endpoints: newEndpoints };

      return onUpdateDataSource(connection);
    },
    [apiInput, endpoints, onUpdateDataSource],
  );

  // Auth

  const callbackUrl = useMemo(() => {
    if (api.authentication !== OAUTH2 || !dataSourceId) {
      return null;
    }

    return OAUTH2_CALLBACK_URI.replace(':projectName', project.name).replace(
      ':dataSourceId',
      `${dataSourceId}`,
    );
  }, [api.authentication, dataSourceId, project.name]);

  const onAuthorize = useCallback(() => {
    if (api.authentication !== OAUTH2 || !api.options?.authorizationUrl) {
      return;
    }

    onSaveApi({ skipAlert: true }).then(() => {
      const state = Object.entries({
        dataSourceId: `${dataSourceId}`,
        projectName: project.name,
      })
        .map(([key, value]: [string, string]) => `${key}=${value}`)
        .join(',');

      openAuthorizationWindow(
        api.options?.authorizationUrl as string,
        new URLSearchParams({
          client_id: api.options?.clientId as string,
          redirect_uri: callbackUrl as string,
          approval_prompt: 'force',
          response_type: 'code',
          nonce: String(Math.random()),
          scope: api.options?.scopes as string,
          access_type: 'offline',
          include_granted_scopes: 'true',
          state: state,
        }),
        {
          redirectUri: document.location.href.endsWith(`/${dataSourceId}`)
            ? document.location.href
            : `${document.location.href}/${dataSourceId}`,
        },
      );
    });
  }, [
    api.authentication,
    api.options?.authorizationUrl,
    api.options?.clientId,
    api.options?.scopes,
    callbackUrl,
    dataSourceId,
    onSaveApi,
    project.name,
  ]);

  return (
    <div className="flex w-full overflow-y-auto">
      <div className="w-full max-w-xl overflow-y-auto border-r border-slate-900 bg-slate-800 p-8 text-white">
        <h1 className="flex items-center text-xl">
          <span>{getText(LANG_KEY, 'title')}</span>
        </h1>
        <Guide
          className="mb-8 mt-4"
          href="https://guides.noloco.io/data/rest-apis"
        >
          {getText(LANG_KEY, 'help')}
        </Guide>
        <ApiEditor
          api={api}
          callbackUrl={callbackUrl}
          display={display}
          endpoints={endpoints}
          hasDataSourceId={!!dataSourceId}
          isDisplayValid={isDisplayValid}
          loading={loading}
          onAddEndpoint={onAddEndpoint}
          onAuthorize={onAuthorize}
          onCreateApi={onConnectDataSource}
          onSaveApi={onSaveApi}
          onSelectEndpoint={onSelectEndpoint}
          onUpdateApi={onUpdateApi}
          onUpdateDisplay={setDisplay}
          saving={isConnecting || isUpdating}
          selectedEndpoint={selectedEndpoint}
        />
      </div>
      <div
        className="max-h-screen w-full overflow-y-auto bg-slate-700"
        ref={bottomRef}
      >
        <div
          className={classNames('flex w-full items-center justify-center', {
            'h-screen': selectedEndpoint !== null,
          })}
        >
          {selectedEndpoint !== null && (
            <ApiEndpointEditor
              api={api}
              data={selectedEndpointData}
              endpoint={get(endpoints, [selectedEndpoint])}
              endpointOptions={endpointOptions}
              error={selectedEndpointError}
              isSaving={isUpdating}
              onDeleteEndpoint={onDeleteEndpoint(selectedEndpoint)}
              onUpdateEndpoint={onUpdateEndpoint(selectedEndpoint)}
              onTest={onTest}
              project={project}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddApi;
