import createReactComponent from '../createReactComponent';
export default createReactComponent('pennant-off', 'IconPennantOff', [
  ['path', { d: 'M8 21h4', key: 'svg-0' }],
  ['path', { d: 'M10 21v-11m0 -4v-3', key: 'svg-1' }],
  [
    'path',
    { d: 'M10 4l9 4l-4.858 2.16m-2.764 1.227l-1.378 .613', key: 'svg-2' },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
