import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import DataTypes, {
  DataSource,
  DataType,
} from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { getTextFromError } from '@noloco/core/src/utils/hooks/useAlerts';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { forEachPromise } from '@noloco/core/src/utils/promises';
import { setProject } from '../../../../../core/src/reducers/project';
import { projectDataSelector } from '../../../../../core/src/selectors/projectSelectors';
import {
  ADD_PRE_BUILT_DATA_SOURCE_VIEWS,
  GET_FULL_DATA_SOURCE,
  PROJECT_DOCUMENT_QUERY,
} from '../../queries/project';
import { useAddElements } from './useAddElements';
import { useBuildDataTypeLayout } from './useBuildDataTypeLayout';
import { useAddDataTypes } from './useUpdateDataTypes';

const PAGE_LIMIT = 10;

export const useBuildDataSourceLayouts = ({
  project,
  onFinish,
  skipWebSockets = false,
  usePreBuiltLayout = false,
}: {
  project: Project;
  onFinish: () => void;
  skipWebSockets?: boolean;
  usePreBuiltLayout?: boolean;
}) => {
  const addDataTypes = useAddDataTypes();
  const { push } = useRouter();
  const dispatch = useDispatch();
  const baseProject = useSelector(projectDataSelector);

  const [inProgressPages, setInProgressPages] = useState<DataType[]>([]);
  const [builtPages, setBuiltPages] = useState<DataType[]>([]);
  const [skippedPages, setSkippedPages] = useState<DataType[]>([]);

  const [getFullDataSourceById] = usePromiseQuery(GET_FULL_DATA_SOURCE);
  const [addPreBuiltElements, { data }] = useMutation(
    ADD_PRE_BUILT_DATA_SOURCE_VIEWS,
  );
  const [fetchProject] = usePromiseQuery(PROJECT_DOCUMENT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      projectId: project.name,
    },
  });

  const addPreBuiltLayout = useCallback(
    async (projectName: string, dataSource: DataSource) => {
      await addPreBuiltElements({
        variables: {
          projectName: projectName,
          dataSourceId: dataSource.id,
        },
      })
        .then(() => fetchProject())
        .then(({ data: projectData }) => {
          if (projectData && projectData.project) {
            const project = {
              ...baseProject,
              elements: projectData.project.elements,
              workflows: projectData.project.workflows,
              settings: projectData.project.settings,
            };
            dispatch(setProject(project));
          }
        })
        .finally(onFinish);
    },
    [addPreBuiltElements, baseProject, dispatch, fetchProject, onFinish],
  );

  const addElements = useAddElements(project, skipWebSockets);
  const [views, setViews] = useState<any[]>([]);

  const addBuiltDataType = useCallback(
    (dataType: DataType) =>
      setBuiltPages((prevBuiltPages) => [...prevBuiltPages, dataType]),
    [setBuiltPages],
  );
  const buildLayoutForDataType = useBuildDataTypeLayout(addBuiltDataType);

  const buildLayoutsForDataSource = useCallback(
    async (dataSource: any) => {
      try {
        const projectWithNewTypes = set(
          'dataTypes',
          new DataTypes([...project.dataTypes, ...dataSource.types]),
          project,
        );

        const pagesToBuild = new DataTypes(
          dataSource.types.slice(0, PAGE_LIMIT),
        );
        setInProgressPages(pagesToBuild);
        setSkippedPages(dataSource.types.slice(PAGE_LIMIT));

        const newViews = await forEachPromise(
          pagesToBuild,
          async (dataType: DataType) =>
            buildLayoutForDataType(projectWithNewTypes, dataType),
        );
        setViews(newViews);
        addElements(newViews);

        setTimeout(onFinish, 2000);
      } catch (e) {
        console.error(e);
      }
    },
    [addElements, buildLayoutForDataType, onFinish, project],
  );

  const generateLayouts = useCallback(
    (projectName: string, dataSource: DataSource) =>
      getFullDataSourceById({
        variables: {
          projectId: projectName,
          id: dataSource.id,
        },
      })
        .then(({ data }) => {
          const hydratedSource = data.dataSource;
          if (hydratedSource) {
            // Update project with new data types
            addDataTypes(hydratedSource.types);
            return buildLayoutsForDataSource(hydratedSource);
          }
        })
        .catch((error: Error) => {
          const errorText = getTextFromError(error);
          // @ts-expect-error TS(2345): Argument of type '{ title?: undefined; message?: u... Remove this comment to see the full error message
          setError(errorText);
        }),
    [addDataTypes, buildLayoutsForDataSource, getFullDataSourceById],
  );

  const redirectToFirstNewView = useCallback(() => {
    if (!usePreBuiltLayout) {
      const firstNewView = views.length > 0 && views[0];
      if (firstNewView) {
        push(`/${get(firstNewView, 'props.routePath')}`);
      }
    } else if (usePreBuiltLayout) {
      const firstViewPath = first(data.addPreBuiltDataSourceViews as any[])
        ?.props?.routePath;
      if (firstViewPath) {
        push(`/${firstViewPath}`);
      } else {
        push('/');
      }
    }
  }, [data?.addPreBuiltDataSourceViews, push, usePreBuiltLayout, views]);

  return {
    buildLayouts: usePreBuiltLayout ? addPreBuiltLayout : generateLayouts,
    redirectToFirstNewView,
    builtPages,
    inProgressPages,
    skippedPages,
  };
};
