import createReactComponent from '../createReactComponent';
export default createReactComponent('bulb', 'IconBulb', [
  [
    'path',
    {
      d: 'M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9.7 17l4.6 0', key: 'svg-2' }],
]);
