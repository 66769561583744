import React, { memo, useLayoutEffect } from 'react';
import { IconListDetails } from '@tabler/icons-react';
import classNames from 'classnames';
import { Route, Switch } from 'react-router-dom';
import { LG, MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import useRouter from '../../../utils/hooks/useRouter';

const SplitLayout = memo(
  // @ts-expect-error TS(2339): Property 'mainCollection' does not exist on type '... Remove this comment to see the full error message
  ({ mainCollection, sidebarSize, recordView, routePrefix }) => {
    const {
      query: { recordId },
    } = useRouter();

    useLayoutEffect(() => {
      if (recordId) {
        setTimeout(() => {
          const scrollContainer = document.querySelector(
            '.split-scroll-container',
          ) as HTMLElement | null;
          const recordEl = document.querySelector(
            `.record-${recordId}`,
          ) as HTMLElement | null;
          if (recordEl && scrollContainer) {
            scrollContainer.scrollTo({
              top: Math.max(recordEl.offsetTop - scrollContainer.offsetTop, 0),
            });
          }
        }, 400);
      }
    }, [recordId]);

    return (
      <div className="absolute inset-0 flex w-full items-start">
        <div
          className={classNames(
            'mr-auto flex h-full max-h-full w-full flex-col',
            {
              'md:hidden': recordId,
              'max-w-lg md:max-w-full xl:max-w-sm': sidebarSize === SM,
              'max-w-2xl md:max-w-full xl:max-w-lg': sidebarSize === MD,
              'max-w-4xl md:max-w-full xl:max-w-2xl': sidebarSize === LG,
            },
          )}
        >
          {mainCollection}
        </div>
        <div
          className={classNames(
            'flex h-full max-h-full w-full flex-shrink flex-col overflow-y-hidden',
            {
              'md:hidden': !recordId,
            },
          )}
        >
          <Switch>
            <Route path={`${routePrefix}/view/:recordId/:tab?`}>
              {recordView}
            </Route>
            <Route>
              <div className="flex h-full w-full items-center justify-center text-center">
                <IconListDetails
                  size={96}
                  className="text-gray-700 opacity-50"
                />
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    );
  },
);

(SplitLayout as any).defaultProps = {
  sidebarSize: MD,
};

export default SplitLayout;
