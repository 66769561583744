import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'droplet-half-filled',
  'IconDropletHalfFilled',
  [
    [
      'path',
      {
        d: 'M6.8 11a6 6 0 1 0 10.396 0l-5.197 -8l-5.2 8zm5.2 -8v17m0 -8l3.544 -3.544m-3.544 8.844l5.558 -5.558',
        key: 'svg-0',
      },
    ],
  ],
);
