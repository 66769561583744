import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import { DataFieldType, INTEGER } from '../constants/dataTypes';
import { DEFAULT_PRECISION } from '../constants/numbers';

export const getDecimalPlaces = (number: number) => {
  const stringValue = String(number);
  return stringValue.includes('.')
    ? Math.max(stringValue.length - stringValue.indexOf('.') - 1, 0)
    : 0;
};

export const getFieldPrecision = (
  type: DataFieldType | string,
  typeOptions: Record<any, any> | undefined,
  defaultPrecision?: number,
) => (type === INTEGER ? 0 : get(typeOptions, 'precision', defaultPrecision));

export const formatPercentageForDisplay = (
  value: number,
  precision: number,
) => {
  const decimalPlaces = isUndefined(precision)
    ? DEFAULT_PRECISION
    : Math.max(precision, 0);
  return (value * 100).toFixed(decimalPlaces);
};

export const formatPercentageForInput = (value: number, precision: number) => {
  const decimalPlaces = isUndefined(precision)
    ? DEFAULT_PRECISION
    : Math.max(precision, 0);
  return parseFloat((value / 100).toFixed(decimalPlaces + 2));
};
