import React, { useCallback, useMemo } from 'react';
import { IconLock } from '@tabler/icons-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Tooltip } from '@noloco/components';
import { Feature, FeatureValue } from '@noloco/core/src/constants/features';
import { getText } from '@noloco/core/src/utils/lang';
import { APP_DASHBOARD_URL } from '../constants/env';
import { useFeatureLockedTooltip } from '../utils/hooks/useFeatureLockedTooltip';
import useIsFeatureEnabled from '../utils/hooks/useIsFeatureEnabled';
import ProFeatureBadge from './ProFeatureBadge';

type Props = {
  blockTrial?: boolean;
  className?: string;
  compactBadge?: boolean;
  children?: React.ReactNode;
  checkEnabled?: boolean;
  currentValue?: FeatureValue;
  onClick?: (...args: any[]) => any;
  feature: Feature;
  iconClassName?: string;
  to: string;
  tooltip?: React.ReactNode | string;
  tooltipPlacement?: string;
};

const FeatureLockedLink = ({
  blockTrial,
  children,
  className,
  compactBadge,
  onClick,
  feature,
  checkEnabled,
  currentValue,
  iconClassName,
  to,
  tooltipPlacement,
  ...rest
}: Props) => {
  const isEnabled = useIsFeatureEnabled(feature, {
    blockTrial,
    currentValue,
  });
  const tooltip = useFeatureLockedTooltip(feature, currentValue);

  const featureEnabled = useMemo(
    () => !checkEnabled || isEnabled,
    [checkEnabled, isEnabled],
  );

  const handleClick = useCallback(
    (event: any) => {
      if (!featureEnabled) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        if (onClick) {
          onClick(event);
        }
      }
    },
    [featureEnabled, onClick],
  );

  return (
    <>
      <Tooltip
        delayHide={300}
        disabled={featureEnabled}
        placement={tooltipPlacement}
        content={
          <div className="flex flex-col text-sm">
            {tooltip}
            <a
              href={`${APP_DASHBOARD_URL}/billing`}
              className="mt-2 text-teal-500 hover:underline"
            >
              {getText('features.explore')}
            </a>
          </div>
        }
        bg="white"
      >
        <Link
          to={featureEnabled ? to : '#'}
          className={classNames('group relative', className)}
          onClick={handleClick}
          {...rest}
        >
          <div className="flex items-center">
            {children}
            {!featureEnabled && (
              <IconLock
                size={16}
                className={classNames('ml-2', iconClassName)}
              />
            )}
            {checkEnabled && (
              <ProFeatureBadge
                compact={compactBadge}
                currentNumber={currentValue}
                feature={feature}
                tooltipPlacement={tooltipPlacement}
              />
            )}
          </div>
        </Link>
      </Tooltip>
    </>
  );
};

FeatureLockedLink.defaultProps = {
  checkEnabled: true,
  enabled: true,
  iconClassName: 'text-gray-200',
};

export default FeatureLockedLink;
