import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-disqus', 'IconBrandDisqus', [
  [
    'path',
    {
      d: 'M11.847 21c-2.259 0 -4.323 -.667 -5.919 -2h-3.928l1.708 -3.266c-.545 -1.174 -.759 -2.446 -.758 -3.734c0 -4.97 3.84 -9 8.898 -9c5.052 0 9.152 4.03 9.152 9c0 4.972 -4.098 9 -9.153 9z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M11.485 15h-1.485v-6h1.485c2.112 0 3.515 .823 3.515 2.981v.035c0 2.18 -1.403 2.984 -3.515 2.984z',
      key: 'svg-1',
    },
  ],
]);
