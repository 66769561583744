import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'layout-bottombar-expand',
  'IconLayoutBottombarExpand',
  [
    [
      'path',
      {
        d: 'M20 6v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M20 15h-16', key: 'svg-1' }],
    ['path', { d: 'M14 10l-2 -2l-2 2', key: 'svg-2' }],
  ],
);
