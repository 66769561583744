import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-sketch', 'IconBrandSketch', [
  [
    'path',
    {
      d: 'M3.262 10.878l8 8.789c.4 .44 1.091 .44 1.491 0l8 -8.79c.313 -.344 .349 -.859 .087 -1.243l-3.537 -5.194a1 1 0 0 0 -.823 -.436h-8.926a1 1 0 0 0 -.823 .436l-3.54 5.192c-.263 .385 -.227 .901 .087 1.246z',
      key: 'svg-0',
    },
  ],
]);
