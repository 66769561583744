import { useEffect, useMemo, useRef } from 'react';
import useWebSocket, { Options as WebsocketOptions } from 'react-use-websocket';

type HeartbeatOptions = {
  heartBeatInterval?: number;
};

/*
 * Heavily inspired by discussion
 * https://github.com/robtaussig/react-use-websocket/issues/133
 */
const useWebsocketWithHeartbeat = (
  url: string | (() => string | Promise<string>) | null,
  settings: WebsocketOptions & HeartbeatOptions,
  connect?: boolean,
) => {
  // Sends a periodical heartbeat message through the websocket connection.
  const heartbeatIntervalRef = useRef<number>(0);
  let previousHeartbeat = useRef<number>(0);
  const settingsWithPongHandler = useMemo(
    () => ({
      ...settings,
      onMessage: (event: any) => {
        if (event.data !== 'PONG' && settings?.onMessage) {
          settings.onMessage(event);
        }
      },
      filter: (event: any) => event.data !== 'PONG',
    }),
    [settings],
  );
  const websocket = useWebSocket(url, settingsWithPongHandler, connect);
  const { readyState, sendMessage } = websocket;

  useEffect(() => {
    if (readyState === 1) {
      heartbeatIntervalRef.current = window.setInterval(() => {
        const deltaFromNow = (Date.now() - previousHeartbeat.current) / 1000;
        // Send a heartbeat message if it hasn't already been sent within the last 10 seconds.
        if (!previousHeartbeat || deltaFromNow > 10) {
          // Send the heartbeat message and update the heartbeat history.

          sendMessage('PING');
          previousHeartbeat.current = Date.now();
        }
      }, settings.heartBeatInterval ?? 20000);
    }

    return () => {
      clearInterval(heartbeatIntervalRef.current);
    };
  }, [readyState, sendMessage, settings.heartBeatInterval]);

  return websocket;
};

export default useWebsocketWithHeartbeat;
