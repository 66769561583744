import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'currency-dollar-brunei',
  'IconCurrencyDollarBrunei',
  [
    ['path', { d: 'M21 6h-4a3 3 0 0 0 0 6h1a3 3 0 0 1 0 6h-4', key: 'svg-0' }],
    ['path', { d: 'M17 20v-2', key: 'svg-1' }],
    ['path', { d: 'M18 6v-2', key: 'svg-2' }],
    [
      'path',
      { d: 'M3 6v12h4a3 3 0 0 0 0 -6h-4h4a3 3 0 0 0 0 -6h-4z', key: 'svg-3' },
    ],
  ],
);
