import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { SelectInput, TextInput } from '@noloco/components';
import {
  TimeDirection,
  TimeFrame,
  getDateFromCustomFilterValue,
} from '@noloco/core/src/utils/baseScope';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'contentEditor.values.DATE';
export type CustomTimeFilter = {
  frame: TimeFrame;
  count: string;
  direction: TimeDirection;
};

const timeFrames: TimeFrame[] = [
  'hour',
  'day',
  'week',
  'month',
  'quarter',
  'year',
];
const getDateStringFromFilter = ({
  frame,
  direction,
  count = '1',
}: CustomTimeFilter): string => {
  const date = getDateFromCustomFilterValue({ frame, direction, count });
  if (frame === 'hour') {
    return date.toLocaleString(DateTime.DATETIME_SHORT);
  }
  return date.toLocaleString(DateTime.DATE_SHORT);
};

export const CustomDateFilter = ({
  onChange,
  value,
}: {
  onChange: (value: CustomTimeFilter) => void;
  value: CustomTimeFilter;
}) => {
  const frameOptions = useMemo(
    (): { value: TimeFrame; label: string }[] =>
      timeFrames.map((frame) => ({
        value: frame,
        label: getText(LANG_KEY, frame, 'label'),
      })),
    [],
  );

  const directionOptions = useMemo(
    (): { value: TimeDirection; label: string }[] => [
      { value: 'from', label: getText(LANG_KEY, 'from') },
      { value: 'ago', label: getText(LANG_KEY, 'ago') },
    ],
    [],
  );

  const handleNewValue = (args: Partial<CustomTimeFilter>) => {
    const newValues = { ...value, ...args };
    onChange(newValues);
  };

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex w-full space-x-1">
        <div className="w-10 flex-shrink-0">
          <TextInput
            type="number"
            placeholder=""
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              handleNewValue({ count: value });
            }}
            value={value.count}
          />
        </div>
        <SelectInput
          onChange={(value: string) => {
            handleNewValue({ frame: value as TimeFrame });
          }}
          options={frameOptions}
          value={value.frame}
          className={'flex w-full'}
        />
        <SelectInput
          onChange={(value: string) => {
            handleNewValue({ direction: value as TimeDirection });
          }}
          options={directionOptions}
          value={value.direction}
          className={'flex w-full'}
        />
      </div>
      <div className="mt-2 flex w-full justify-center">
        <span className="text-xs text-gray-400">
          {getDateStringFromFilter(value)}
        </span>
      </div>
    </div>
  );
};
