import createReactComponent from '../createReactComponent';
export default createReactComponent('glass', 'IconGlass', [
  ['path', { d: 'M8 21l8 0', key: 'svg-0' }],
  ['path', { d: 'M12 15l0 6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M17 3l1 7c0 3.012 -2.686 5 -6 5s-6 -1.988 -6 -5l1 -7h10z',
      key: 'svg-2',
    },
  ],
]);
