import React from 'react';
import { Switch } from '@noloco/components';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeBaseSwitchSection, {
  BuildModeBaseSwitchSectionProps,
} from './BuildModeBaseSwitchSection';

type BuildModeSwitchSectionProps = Omit<
  BuildModeBaseSwitchSectionProps,
  'switchEl'
> & {
  disabled?: boolean;
  onChange: (value: boolean) => void;
  switchEl?: JSX.Element;
};

const BuildModeSwitchSection = ({
  button,
  children,
  className,
  disabled = false,
  guide,
  label,
  onChange,
  onClick,
  showOnlyButton = false,
  switchEl,
  value,
}: BuildModeSwitchSectionProps) => (
  <BuildModeBaseSwitchSection
    button={button}
    className={className}
    guide={guide}
    label={label}
    onClick={onClick}
    showOnlyButton={showOnlyButton}
    switchEl={
      switchEl ?? (
        <Switch
          disabled={disabled}
          onChange={onChange}
          size={SM}
          value={value}
        />
      )
    }
    value={value}
  >
    {children}
  </BuildModeBaseSwitchSection>
);

export default BuildModeSwitchSection;
