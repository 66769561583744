import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-watch-bolt',
  'IconDeviceWatchBolt',
  [
    [
      'path',
      {
        d: 'M13 18h-4a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v3',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 18v3h4.5', key: 'svg-1' }],
    ['path', { d: 'M9 6v-3h6v3', key: 'svg-2' }],
    ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-3' }],
  ],
);
