import createReactComponent from '../createReactComponent';
export default createReactComponent('details', 'IconDetails', [
  [
    'path',
    {
      d: 'M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 3v16', key: 'svg-1' }],
]);
