import createReactComponent from '../createReactComponent';
export default createReactComponent('hourglass-high', 'IconHourglassHigh', [
  ['path', { d: 'M6.5 7h11', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z',
      key: 'svg-2',
    },
  ],
]);
