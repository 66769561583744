import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-graphql', 'IconBrandGraphql', [
  ['path', { d: 'M5.308 7.265l5.385 -3.029', key: 'svg-0' }],
  ['path', { d: 'M13.308 4.235l5.384 3.03', key: 'svg-1' }],
  ['path', { d: 'M20 9.5v5', key: 'svg-2' }],
  ['path', { d: 'M18.693 16.736l-5.385 3.029', key: 'svg-3' }],
  ['path', { d: 'M10.692 19.765l-5.384 -3.03', key: 'svg-4' }],
  ['path', { d: 'M4 14.5v-5', key: 'svg-5' }],
  ['path', { d: 'M12.772 4.786l6.121 10.202', key: 'svg-6' }],
  ['path', { d: 'M18.5 16h-13', key: 'svg-7' }],
  ['path', { d: 'M5.107 14.988l6.122 -10.201', key: 'svg-8' }],
  [
    'path',
    { d: 'M12 3.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0', key: 'svg-9' },
  ],
  [
    'path',
    {
      d: 'M12 20.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0',
      key: 'svg-10',
    },
  ],
  [
    'path',
    { d: 'M4 8m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0', key: 'svg-11' },
  ],
  [
    'path',
    { d: 'M4 16m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0', key: 'svg-12' },
  ],
  [
    'path',
    { d: 'M20 16m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0', key: 'svg-13' },
  ],
  [
    'path',
    { d: 'M20 8m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0', key: 'svg-14' },
  ],
]);
