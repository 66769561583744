import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-watch-stats',
  'IconDeviceWatchStats',
  [
    [
      'path',
      {
        d: 'M6 6m0 3a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-6a3 3 0 0 1 -3 -3z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 18v3h6v-3', key: 'svg-1' }],
    ['path', { d: 'M9 6v-3h6v3', key: 'svg-2' }],
    ['path', { d: 'M9 14v-4', key: 'svg-3' }],
    ['path', { d: 'M12 14v-1', key: 'svg-4' }],
    ['path', { d: 'M15 14v-3', key: 'svg-5' }],
  ],
);
