import tailwindColors from 'tailwindcss/colors';

export const darkModeColors = {
  surfaces: {
    elevation0: 'bg-gray-900',
    elevation1: 'bg-gray-800',
    elevation1LiteralColor: 'gray-800',
    elevation2: 'bg-gray-700',
    elevation2LiteralColor: 'gray-700',
    elevation3: 'bg-gray-600',
  },
  borders: {
    one: 'border-gray-700',
    oneLiteralColor: 'gray-700',
    two: 'border-gray-600',
    three: 'border-gray-500',
  },
  divides: {
    one: 'divide-gray-500',
    two: 'divide-gray-600',
  },
  text: {
    primary: 'text-gray-200',
    primaryLiteralColor: 'gray-200',
    secondary: 'text-gray-400',
    secondaryLiteralColor: 'gray-400',
    tertiary: 'text-gray-600',
  },
  icons: {
    primary: tailwindColors.gray['300'],
    secondary: tailwindColors.gray['400'],
    tertiary: tailwindColors.gray['600'],
  },
};
