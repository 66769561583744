import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-rotary-right',
  'IconArrowRotaryRight',
  [
    ['path', { d: 'M8 7m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
    ['path', { d: 'M8 10v10', key: 'svg-1' }],
    ['path', { d: 'M17 11l4 -4l-4 -4', key: 'svg-2' }],
    ['path', { d: 'M11 7h10', key: 'svg-3' }],
  ],
);
