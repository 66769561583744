import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-mobile-pin',
  'IconDeviceMobilePin',
  [
    [
      'path',
      {
        d: 'M12.5 21h-4.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v6',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M11 4h2', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M19 18v.01', key: 'svg-3' }],
    ['path', { d: 'M12 17v.01', key: 'svg-4' }],
  ],
);
