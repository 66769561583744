import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IconColorSwatch,
  IconPalette,
  IconRefresh,
  IconShieldLock,
  IconUserCircle,
  IconUserPlus,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BaseModal, Button, Tooltip } from '@noloco/components';
import { EXPERT } from '@noloco/core/src/constants/accountPlans';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import { Project } from '@noloco/core/src/models/Project';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { isPremium } from '@noloco/core/src/utils/collectionLayouts';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import useIsPartnerTrialExpired from '@noloco/core/src/utils/hooks/useIsPartnerTrialExpired';
import useIsTrialExpired from '@noloco/core/src/utils/hooks/useIsTrialExpired';
import { getText } from '@noloco/core/src/utils/lang';
import { getPagesConfig } from '@noloco/core/src/utils/pages';
import useBillingPlanUsage from '../utils/hooks/useBillingPlanUsage';

const LANG_KEY = 'billing.trial';

const getPremiumFeatures = (project: Project, usage: any) => {
  const { projectPages } = getPagesConfig(project.elements, project.settings);

  const connectedDataSources = project.dataTypes.filter(
    (dataType: any) => dataType.source.type !== INTERNAL,
  ).length;

  const permissionsEnabled = project.dataTypes.filter(
    (dataType: any) => dataType.permissionsEnabled,
  ).length;

  return [
    {
      key: 'users',
      Icon: IconUserPlus,
      usage: usage.plan.activeUsers,
    },
    {
      key: 'branding',
      Icon: IconColorSwatch,
      usage: project.domains?.length ?? 0,
    },
    {
      key: 'display',
      Icon: IconPalette,
      usage: projectPages.filter(
        (page: any) => page && isPremium(page?.props?.layout),
      ).length,
    },
    {
      key: 'syncing',
      Icon: IconRefresh,
      ignore: connectedDataSources === 0,
      usage: connectedDataSources,
    },
    {
      key: 'permissions',
      Icon: IconShieldLock,
      usage: permissionsEnabled,
    },
    {
      key: 'roles',
      Icon: IconUserCircle,
    },
  ];
};

const TrialEndModal = () => {
  const project = useSelector(projectDataSelector);
  const billingPlan = useSelector(billingPlanSelector);
  const planUsage = useBillingPlanUsage(project);

  const isExpired = useIsTrialExpired();
  const isPartnerTrialExpired = useIsPartnerTrialExpired();

  const { isBuilder } = useIsBuilder();
  const [counter, setCounter] = useState(0);

  const increaseCounter = useCallback(() => {
    setTimeout(() => {
      if (isExpired || isPartnerTrialExpired) {
        setCounter((currentVal) => currentVal + 1);
        increaseCounter();
      }
    }, 2000);
  }, [isExpired, isPartnerTrialExpired]);

  useEffect(() => {
    if ((isExpired || isPartnerTrialExpired) && counter === 0) {
      increaseCounter();
    }
  }, [counter, increaseCounter, isExpired, isPartnerTrialExpired]);

  const premiumFeatures = useMemo(() => {
    if (
      !isExpired ||
      !isBuilder ||
      !project.users ||
      !planUsage ||
      !project.domains
    ) {
      return [];
    }

    return getPremiumFeatures(project, planUsage);
  }, [isBuilder, isExpired, planUsage, project]);

  const modal =
    typeof document !== 'undefined' &&
    document.querySelector('.trial-expired-modal');

  if (!modal && counter % 2 === 0) {
    return null;
  }

  if (
    (!isExpired && billingPlan.type !== EXPERT) ||
    (!isPartnerTrialExpired && billingPlan.type === EXPERT)
  ) {
    return null;
  }

  return (
    <BaseModal size="lg" onClose={() => null}>
      <div className="trial-expired-modal w-full bg-gray-100">
        <div className="bg-gray-100 px-8 py-16 text-center">
          <h1 className="mx-auto max-w-lg text-2xl font-medium text-gray-900">
            {getText(LANG_KEY, 'expired.title')}
          </h1>
          <p className="mx-auto mt-6 max-w-lg text-base text-gray-700">
            {getText(
              {
                endDate: DateTime.fromISO(
                  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
                  billingPlan.trialEnd,
                  // @ts-expect-error TS(2559): Type '"DDD"' has no properties in common with type... Remove this comment to see the full error message
                ).toLocaleString('DDD'),
              },
              LANG_KEY,
              'expired.summary',
            )}
          </p>
          <div className="mx-auto my-8 grid grid-cols-3 gap-3 sm:hidden md:grid-cols-2">
            {premiumFeatures
              .filter((permiumFeature) => !permiumFeature.ignore)
              .map(({ key, Icon, usage }) => (
                <Tooltip
                  content={
                    <div className="flex flex-col">
                      <span>
                        {getText(LANG_KEY, 'features', key, 'description')}
                      </span>
                      {usage !== undefined && usage > 0 && (
                        <span className="mt-2 text-gray-600">
                          {getText(
                            { context: usage },
                            LANG_KEY,
                            'features',
                            key,
                            'usage',
                          )}
                        </span>
                      )}
                    </div>
                  }
                  bg="white"
                >
                  <div
                    className={classNames(
                      'flex cursor-pointer items-center rounded-lg border p-4 hover:border-teal-200 hover:bg-teal-50',
                    )}
                    key={key}
                  >
                    <Icon size={20} className="mr-4 flex-shrink-0" />
                    <div className="flex flex-col text-left">
                      <span className="whitespace-nowrap text-sm font-medium text-gray-600">
                        {getText(LANG_KEY, 'features', key, 'title')}
                      </span>
                      {usage !== undefined && usage > 0 && (
                        <span className="text-xs text-gray-500">
                          {getText(
                            { context: usage },
                            LANG_KEY,
                            'features',
                            key,
                            'shortUsage',
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </Tooltip>
              ))}
          </div>
          <p className="mx-auto mb-1 max-w-lg text-base text-gray-700 sm:mt-4">
            {getText(
              LANG_KEY,
              'expired',
              isBuilder ? 'continue' : 'contactAdmin',
            )}
          </p>
          {isBuilder && (
            <div className="mx-auto mt-16 flex max-w-lg flex-col items-center justify-center space-y-4">
              <Link to="/_/settings/billing" className="block w-full">
                <Button size="lg" className="w-full rounded-lg">
                  <span>{getText(LANG_KEY, 'compare')}</span>
                </Button>
              </Link>
              <Link
                to="/_/users"
                className="block w-full text-sm text-teal-500 hover:underline"
              >
                <span>{getText(LANG_KEY, 'manageUsers')}</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </BaseModal>
  );
};

export default TrialEndModal;
