import {
  CALENDAR,
  CollectionLayout,
  GANTT,
  MAP,
  PIVOT_TABLE,
  SPLIT,
  TABLE,
  TIMELINE,
} from '../constants/collectionLayouts';

export const isPremium = (layout: CollectionLayout): boolean =>
  [CALENDAR, GANTT, MAP, SPLIT, TIMELINE, PIVOT_TABLE].includes(layout);

export const verifyIfPremium = (
  layout: CollectionLayout,
  premiumLayoutsEnabled: boolean,
): CollectionLayout =>
  !isPremium(layout) || premiumLayoutsEnabled ? layout : TABLE;
