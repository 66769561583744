import createReactComponent from '../createReactComponent';
export default createReactComponent('zoom-reset', 'IconZoomReset', [
  ['path', { d: 'M21 21l-6 -6', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3.268 12.043a7.017 7.017 0 0 0 6.634 4.957a7.012 7.012 0 0 0 7.043 -6.131a7 7 0 0 0 -5.314 -7.672a7.021 7.021 0 0 0 -8.241 4.403',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 4v4h4', key: 'svg-2' }],
]);
