import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'letter-case-upper',
  'IconLetterCaseUpper',
  [
    ['path', { d: 'M3 19v-10.5a3.5 3.5 0 0 1 7 0v10.5', key: 'svg-0' }],
    ['path', { d: 'M3 13h7', key: 'svg-1' }],
    ['path', { d: 'M14 19v-10.5a3.5 3.5 0 0 1 7 0v10.5', key: 'svg-2' }],
    ['path', { d: 'M14 13h7', key: 'svg-3' }],
  ],
);
