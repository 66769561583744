import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'adjustments-heart',
  'IconAdjustmentsHeart',
  [
    ['path', { d: 'M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M6 4v4', key: 'svg-1' }],
    ['path', { d: 'M6 12v8', key: 'svg-2' }],
    ['path', { d: 'M12 4v8.5', key: 'svg-3' }],
    ['path', { d: 'M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-4' }],
    ['path', { d: 'M18 4v1', key: 'svg-5' }],
    ['path', { d: 'M18 9v2.5', key: 'svg-6' }],
    [
      'path',
      {
        d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
        key: 'svg-7',
      },
    ],
  ],
);
