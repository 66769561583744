import createReactComponent from '../createReactComponent';
export default createReactComponent('message-search', 'IconMessageSearch', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M11.008 19.195l-3.008 1.805v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-3' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-4' }],
]);
