import createReactComponent from '../createReactComponent';
export default createReactComponent('clipboard-heart', 'IconClipboardHeart', [
  [
    'path',
    {
      d: 'M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M11.993 16.75l2.747 -2.815a1.9 1.9 0 0 0 0 -2.632a1.775 1.775 0 0 0 -2.56 0l-.183 .188l-.183 -.189a1.775 1.775 0 0 0 -2.56 0a1.899 1.899 0 0 0 0 2.632l2.738 2.825z',
      key: 'svg-2',
    },
  ],
]);
