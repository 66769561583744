const isTargetAnInput = (target: any) => {
  const tag = target.tagName;
  const role = target.getAttribute('role');
  const contenteditable = Boolean(target.getAttribute('contenteditable'));

  return (
    tag === 'INPUT' || (tag === 'DIV' && role === 'textbox' && contenteditable)
  );
};

export default isTargetAnInput;
