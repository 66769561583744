import createReactComponent from '../createReactComponent';
export default createReactComponent('s-turn-left', 'IconSTurnLeft', [
  ['path', { d: 'M19 7a2 2 0 1 1 0 -4a2 2 0 0 1 0 4z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17 5h-9.5a3.5 3.5 0 0 0 0 7h9a3.5 3.5 0 0 1 0 7h-13.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M6 16l-3 3l3 3', key: 'svg-2' }],
]);
