import { ADD_COMMENT } from './actionTypes';

export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';

const workflowTriggerTypes = [CREATE, UPDATE, DELETE, ADD_COMMENT];

export type WorkflowTrigger = 'CREATE' | 'UPDATE' | 'DELETE' | 'ADD_COMMENT';

export default workflowTriggerTypes;
