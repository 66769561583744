import createReactComponent from '../createReactComponent';
export default createReactComponent('file-symlink', 'IconFileSymlink', [
  ['path', { d: 'M4 21v-4a3 3 0 0 1 3 -3h5', key: 'svg-0' }],
  ['path', { d: 'M9 17l3 -3l-3 -3', key: 'svg-1' }],
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-2' }],
  [
    'path',
    { d: 'M5 11v-6a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-9.5', key: 'svg-3' },
  ],
]);
