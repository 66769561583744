import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-google-big-query',
  'IconBrandGoogleBigQuery',
  [
    [
      'path',
      {
        d: 'M17.73 19.875a2.225 2.225 0 0 1 -1.948 1.125h-7.283a2.222 2.222 0 0 1 -1.947 -1.158l-4.272 -6.75a2.269 2.269 0 0 1 0 -2.184l4.272 -6.75a2.225 2.225 0 0 1 1.946 -1.158h7.285c.809 0 1.554 .443 1.947 1.158l3.98 6.75a2.33 2.33 0 0 1 0 2.25l-3.98 6.75v-.033z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M11.5 11.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M14 14l2 2', key: 'svg-2' }],
  ],
);
