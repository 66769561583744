import createReactComponent from '../createReactComponent';
export default createReactComponent('dialpad-off', 'IconDialpadOff', [
  ['path', { d: 'M7 7h-4v-4', key: 'svg-0' }],
  ['path', { d: 'M17 3h4v4h-4z', key: 'svg-1' }],
  ['path', { d: 'M10 6v-3h4v4h-3', key: 'svg-2' }],
  ['path', { d: 'M3 10h4v4h-4z', key: 'svg-3' }],
  ['path', { d: 'M17 13v-3h4v4h-3', key: 'svg-4' }],
  ['path', { d: 'M14 14h-4v-4', key: 'svg-5' }],
  ['path', { d: 'M10 17h4v4h-4z', key: 'svg-6' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
