import { useCallback } from 'react';
import { BaseRecord } from '../../models/Record';
import useIsMacOs from './useIsMacOs';

const useSelectRecordOnCmdClick = (
  handleCheckboxChange: (checked: { target: { checked: boolean } }) => void,
  isRowChecked: boolean,
  selectedRows: BaseRecord[],
) => {
  const isMacOs = useIsMacOs();

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      if ((isMacOs && event.metaKey) || (!isMacOs && event.ctrlKey)) {
        if (selectedRows.length >= 1) {
          event.stopPropagation();
          event.preventDefault();
          return handleCheckboxChange({ target: { checked: !isRowChecked } });
        }
      }
    },
    [isMacOs, isRowChecked, selectedRows, handleCheckboxChange],
  );

  return selectedRows.length > 0 ? onClick : undefined;
};
export default useSelectRecordOnCmdClick;
