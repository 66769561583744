import operators from '../../../constants/operators';
import ArrayType from '../ArrayPropType';
import EnumType from '../EnumPropType';
import RawDataPropType from '../RawDataPropPropType';
import StringType from '../StringPropType';

const buildBaseCustomFilters = (autoResolve: boolean) => ({
  field: new RawDataPropType(() => []).setPropTransformation((value) => value),
  operator: new EnumType(operators),
  result: new StringType()
    .setUseRawValue(true)
    .setAutomaticallyResolve(autoResolve),
});

const buildCustomFilter = (autoResolve = true) => ({
  ...buildBaseCustomFilters(autoResolve),
  filters: new ArrayType(buildBaseCustomFilters(autoResolve)),
});

export const buildCustomFilterProp = (autoResolve = true) => ({
  ...buildCustomFilter(autoResolve),
  branches: new ArrayType({
    filters: new ArrayType(buildCustomFilter(autoResolve)),
  }),
});
