import React, { useMemo } from 'react';
import { SelectInput } from '@noloco/components';
import {
  DataSchemaField,
  ID_FIELD_TYPES,
} from '@noloco/core/src/constants/dataSchema';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'data.api.endpoints.data';

type Props = {
  fields: DataSchemaField[] | null;
  idField: string | null;
  onUpdateIdField: (idField: string) => void;
  onUpdateResultPath: (resultPath: string[]) => void;
  resultPath: string[] | null;
  resultPathOptions: any[] | null;
};

const ApiEndpointFormatEditor = ({
  fields,
  idField,
  onUpdateIdField,
  onUpdateResultPath,
  resultPath,
  resultPathOptions,
}: Props) => {
  const idFieldOptions = useMemo(() => {
    if (!fields) {
      return [];
    }

    return fields
      .filter(({ type }) => ID_FIELD_TYPES.includes(type))
      .map((field: DataSchemaField) => ({
        value: field.path.join('.'),
        label: field.display,
      }));
  }, [fields]);

  return (
    <div className="mb-auto flex w-full max-w-2xl flex-col overflow-y-auto px-4 py-16 text-sm text-white">
      <div className="mt-6 w-full">
        <label className="mb-2 mt-6 font-medium leading-5">
          {getText(LANG_KEY, 'path.label')}
        </label>
        <p className="mb-2 text-sm text-gray-400">
          {getText(LANG_KEY, 'path.help')}
        </p>
        <SelectInput
          disabled={!resultPathOptions || resultPathOptions.length < 2}
          placeholder={getText(LANG_KEY, 'path.placeholder')}
          options={resultPathOptions}
          value={resultPath}
          onChange={onUpdateResultPath}
        />
      </div>
      <div className="mt-6 w-full">
        <label className="mb-2 mt-6 font-medium leading-5">
          {getText(LANG_KEY, 'id.label')}
        </label>
        <p className="mb-2 text-sm text-gray-400">
          {getText(LANG_KEY, 'id.help')}
        </p>
        <SelectInput
          disabled={!fields}
          placeholder={getText(LANG_KEY, 'id.placeholder')}
          options={idFieldOptions}
          value={idField}
          onChange={onUpdateIdField}
        />
      </div>
    </div>
  );
};

export default ApiEndpointFormatEditor;
