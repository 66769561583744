import React, { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { PropertyPath } from 'lodash';
import isNil from 'lodash/isNil';
import { NumberFormatValues } from 'react-number-format';
import { FormattedNumberInput, Surface } from '@noloco/components';
import { DECIMAL, OBJECT } from '../../../../constants/dataTypes';
import { COORDINATES } from '../../../../constants/fieldFormats';
import { DataField } from '../../../../models/DataTypeFields';
import { getText } from '../../../../utils/lang';
import { getFieldPrecision } from '../../../../utils/numbers';

type Props = {
  disabled?: boolean;
  field: DataField;
  onBlur: () => void;
  onUpdateDraftValue: (path: PropertyPath) => (value: any) => void;
  surface: Surface;
  validationError?: string;
  value: any;
};

const isValidLatitude = ({ floatValue }: NumberFormatValues) =>
  isNil(floatValue) || (floatValue >= -90 && floatValue <= 90);

const isValidLongitude = ({ floatValue }: NumberFormatValues) =>
  isNil(floatValue) || (floatValue >= -180 && floatValue <= 180);

const CoordinatesInput = ({
  disabled,
  field,
  onBlur,
  onUpdateDraftValue,
  surface,
  validationError,
  value,
}: Props) => {
  const decimalScale = useMemo(
    () => getFieldPrecision(DECIMAL, field.typeOptions!),
    [field.typeOptions],
  );

  return (
    <div
      className="transparent flex w-full space-x-4 sm:flex-wrap"
      data-testid="coordinates-input"
    >
      <div className="w-full">
        <FormattedNumberInput
          decimalScale={decimalScale}
          disabled={disabled}
          isAllowed={isValidLatitude}
          onBlur={onBlur}
          onChange={onUpdateDraftValue(['latitude'])}
          placeholder={getText(
            'data.types',
            OBJECT,
            COORDINATES,
            'latitude.label',
          )}
          surface={surface}
          validationError={validationError}
          value={!isNil(value?.latitude) ? Number(value.latitude) : null}
        />
      </div>
      <div className="w-full">
        <FormattedNumberInput
          decimalScale={decimalScale}
          disabled={disabled}
          isAllowed={isValidLongitude}
          onBlur={onBlur}
          onChange={onUpdateDraftValue(['longitude'])}
          placeholder={getText(
            'data.types',
            OBJECT,
            COORDINATES,
            'longitude.label',
          )}
          surface={surface}
          validationError={validationError}
          value={!isNil(value?.longitude) ? Number(value.longitude) : null}
        />
      </div>
    </div>
  );
};

export default withTheme(CoordinatesInput);
