import createReactComponent from '../createReactComponent';
export default createReactComponent('s-turn-up', 'IconSTurnUp', [
  ['path', { d: 'M7 19a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5 17v-9.5a3.5 3.5 0 0 1 7 0v9a3.5 3.5 0 0 0 7 0v-13.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 6l3 -3l3 3', key: 'svg-2' }],
]);
