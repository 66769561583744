import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-imac-cancel',
  'IconDeviceImacCancel',
  [
    [
      'path',
      {
        d: 'M12.5 17h-8.5a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 13h12.5', key: 'svg-1' }],
    ['path', { d: 'M8 21h4.5', key: 'svg-2' }],
    ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
    ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-4' }],
    ['path', { d: 'M17 21l4 -4', key: 'svg-5' }],
  ],
);
