import createReactComponent from '../createReactComponent';
export default createReactComponent('cake', 'IconCake', [
  [
    'path',
    { d: 'M3 20h18v-8a3 3 0 0 0 -3 -3h-12a3 3 0 0 0 -3 3v8z', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M3 14.803c.312 .135 .654 .204 1 .197a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1c.35 .007 .692 -.062 1 -.197',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M12 4l1.465 1.638a2 2 0 1 1 -3.015 .099l1.55 -1.737z', key: 'svg-2' },
  ],
]);
