import React from 'react';
import {
  IconCodePlus,
  IconLink,
  IconMail,
  IconNetwork,
  IconRuler2,
  IconX,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Button } from '@noloco/components';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  INTEGER,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import { FIELD_VALIDATION_RULES } from '@noloco/core/src/constants/features';
import {
  EMAIL as EMAIL_FORMAT,
  IP_ADDRESS as IP_ADDRESS_FORMAT,
  URL as URL_FORMAT,
} from '@noloco/core/src/constants/fieldFormats';
import {
  EMAIL,
  IP_ADDRESS,
  REGEX,
  URL,
} from '@noloco/core/src/constants/operators';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { getIconForDataField } from '@noloco/core/src/utils/dataFieldIcons';
import { getText } from '@noloco/core/src/utils/lang';
import { RECORD_SCOPE } from '@noloco/core/src/utils/scope';
import { flattenStateItem } from '@noloco/core/src/utils/state';
import useIsFeatureEnabled from '../../../utils/hooks/useIsFeatureEnabled';
import FeatureLockedSwitch from '../../FeatureLockedSwitch';
import FieldValidationRuleEditor from './FieldValidationRuleEditor';

const LANG_KEY = 'elements.VIEW.fields.validationRules';

const getTextValidationRules = (field: DataField, fieldValue: any) => {
  const baseTextValidationRules = [
    {
      icon: <IconCodePlus />,
      label: getText(LANG_KEY, 'labels.regex.label'),
      help: getText(LANG_KEY, 'labels.regex.help'),
      value: {
        field: fieldValue,
        operator: REGEX,
      },
    },
  ];

  if (
    field.typeOptions?.format === EMAIL_FORMAT ||
    field.typeOptions?.format === URL_FORMAT ||
    field.typeOptions?.format === IP_ADDRESS_FORMAT
  ) {
    return baseTextValidationRules;
  }

  return [
    {
      icon: <IconMail />,
      label: getText(LANG_KEY, 'labels.email'),
      value: {
        field: fieldValue,
        operator: EMAIL,
      },
    },
    {
      icon: <IconLink />,
      label: getText(LANG_KEY, 'labels.url'),
      value: {
        field: fieldValue,
        operator: URL,
      },
    },
    {
      icon: <IconNetwork />,
      label: getText(LANG_KEY, 'labels.ipAddress'),
      value: {
        field: fieldValue,
        operator: IP_ADDRESS,
      },
    },
    ...baseTextValidationRules,
  ];
};

const getBaseValidationRules = (field: any) => {
  const fieldValue = {
    dataType: TEXT,
    id: RECORD_SCOPE,
    path: field.name,
  };

  switch (field.type) {
    case TEXT:
      return getTextValidationRules(field, fieldValue);
    default:
      return [];
  }
};

const getValidationRuleTypes = (field: any) => {
  const Icon = getIconForDataField(field);

  switch (field.type) {
    case DATE:
    case DECIMAL:
    case INTEGER:
      return [
        {
          icon: <Icon />,
          value: {
            dataType: field.type,
            id: RECORD_SCOPE,
            path: field.name,
          },
        },
      ];
    case TEXT:
      return [
        {
          icon: <Icon />,
          label: getText(LANG_KEY, 'labels.text'),
          value: {
            dataType: field.type,
            id: RECORD_SCOPE,
            path: field.name,
          },
        },
        {
          icon: <IconRuler2 />,
          label: getText(LANG_KEY, 'labels.characterLength'),
          value: {
            dataType: INTEGER,
            id: RECORD_SCOPE,
            path: `${field.name}.length`,
          },
        },
      ];
    case BOOLEAN:
      return [
        {
          icon: <Icon />,
          label: getText(LANG_KEY, 'labels.value'),
          value: {
            dataType: field.type,
            id: RECORD_SCOPE,
            path: field.name,
          },
        },
      ];
    default:
      return null;
  }
};

// @ts-expect-error TS(7006): Parameter 'field' implicitly has an 'any' type.
const getNewConditionRule = (field, fieldOptions) => ({
  field: {
    ...get(fieldOptions, [0, 'value']),
    value: get(fieldOptions, [0, 'label']),
  },
  operator: null,
  value: null,
});

const FieldValidationRulesEditor = ({
  className,
  dataType,
  elementPath,
  field,
  onChange,
  project,
  recordScopeOptions,
  value,
}: any) => {
  const isEnabled = useIsFeatureEnabled(FIELD_VALIDATION_RULES);
  const fieldValidationRuleTypes = getValidationRuleTypes(field);
  const baseValidationRules = getBaseValidationRules(field);

  if (!fieldValidationRuleTypes) {
    return null;
  }

  const addRule = () =>
    onChange(
      [],
      [...value, getNewConditionRule(field, fieldValidationRuleTypes)],
    );

  const updateRule = (index: any) => (propertyPath: any, newValue: any) =>
    onChange([index, ...propertyPath], newValue);

  const removeRule = (conditionIndex: any) => {
    const conditionsInGroup = value.filter(
      (__: any, index: any) => index !== conditionIndex,
    );

    onChange([], conditionsInGroup);
  };

  return (
    <BuildModeSwitchSection
      label={getText(LANG_KEY, 'label')}
      onChange={(nextValue: any) =>
        onChange(
          [],
          nextValue
            ? [getNewConditionRule(field, fieldValidationRuleTypes)]
            : null,
        )
      }
      switchEl={
        <FeatureLockedSwitch
          feature={FIELD_VALIDATION_RULES}
          size={SM}
          value={!!value && isEnabled}
          onChange={(nextValue: boolean) =>
            onChange(
              [],
              nextValue
                ? [getNewConditionRule(field, fieldValidationRuleTypes)]
                : null,
            )
          }
        />
      }
      value={!!value && isEnabled}
    >
      <div className="flex max-w-full flex-col items-center justify-between px-2 py-4">
        <div
          className={classNames(
            'flex w-full flex-col justify-center',
            className,
          )}
        >
          {(value || []).map((conditionX: any, index: any) => (
            <div
              className="flex flex-col"
              key={`${index}.${flattenStateItem(conditionX.field)}.${
                conditionX.operator
              }`}
            >
              <div
                className={classNames(
                  'border-brand-light relative mr-2 rounded-lg border border-dotted p-2',
                )}
              >
                <button
                  onClick={() => removeRule(index)}
                  className="bg-brand-light absolute right-0 top-0 -mr-2 -mt-2 flex items-center justify-center rounded-full p-1 text-white hover:bg-pink-500"
                >
                  <IconX size={12} />
                </button>
                <FieldValidationRuleEditor
                  condition={conditionX}
                  dataType={dataType}
                  elementPath={elementPath}
                  fieldOptions={fieldValidationRuleTypes}
                  inline={false}
                  project={project}
                  recordScopeOptions={recordScopeOptions}
                  updateCondition={updateRule(index)}
                  validationRules={baseValidationRules}
                />
              </div>
              <div className="border-brand-light ml-4 h-4 w-1 border-l border-dotted" />
            </div>
          ))}
          <div className="flex items-end space-x-2">
            <Button onClick={addRule} size="sm" variant="secondary">
              {getText(LANG_KEY, 'add')}
            </Button>
          </div>
        </div>
      </div>
    </BuildModeSwitchSection>
  );
};

FieldValidationRulesEditor.defaultProps = {
  className: '',
};

export default FieldValidationRulesEditor;
