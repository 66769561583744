import createReactComponent from '../createReactComponent';
export default createReactComponent('droplet-dollar', 'IconDropletDollar', [
  [
    'path',
    {
      d: 'M17.668 10.29l-4.493 -6.673c-.421 -.625 -1.288 -.803 -1.937 -.397a1.376 1.376 0 0 0 -.41 .397l-4.893 7.26c-1.695 2.838 -1.035 6.441 1.567 8.546a7.175 7.175 0 0 0 5.493 1.51',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-2' }],
]);
