import { useMemo } from 'react';
import { EVENT_BASED_LAYOUTS } from '../../constants/collectionLayouts';
import { getViewRoutePrefixForViewId } from '../urls';

const useRecordRowLink = (
  rowLink: any,
  project: any,
  layout: any,
  viewRootPathname: any,
  uuid: any,
  recordQueryString = '',
) => {
  const recordRowLink = useMemo(() => {
    if (rowLink === '#' || EVENT_BASED_LAYOUTS.includes(layout)) {
      return null;
    }

    if (rowLink === undefined) {
      if (!viewRootPathname) {
        return null;
      }

      return `${viewRootPathname}/view/${uuid}${recordQueryString}`;
    }

    const customRootPathName = getViewRoutePrefixForViewId(rowLink, project);
    if (!customRootPathName) {
      return null;
    }

    return `${customRootPathName}/view/${uuid}${recordQueryString}`;
  }, [layout, project, recordQueryString, rowLink, uuid, viewRootPathname]);
  return recordRowLink;
};

export default useRecordRowLink;
