import createReactComponent from '../createReactComponent';
export default createReactComponent('inner-shadow-top', 'IconInnerShadowTop', [
  [
    'path',
    {
      d: 'M5.636 5.636a9 9 0 1 0 12.728 12.728a9 9 0 0 0 -12.728 -12.728z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16.243 7.757a6 6 0 0 0 -8.486 0', key: 'svg-1' }],
]);
