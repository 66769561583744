import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-ramp-right-3',
  'IconArrowRampRight3',
  [
    ['path', { d: 'M6 3v6', key: 'svg-0' }],
    ['path', { d: 'M16 16l4 -4l-4 -4', key: 'svg-1' }],
    ['path', { d: 'M6 21v-6a3 3 0 0 1 3 -3h11', key: 'svg-2' }],
  ],
);
