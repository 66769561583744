import React, { memo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import useScopeVariables from '../../../utils/hooks/useScopeVariables';
import { formatBg } from '../../../utils/styles';
import Link from '../../Link';

const CollectionConversation = memo(
  ({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type '{}'.
    index,
    // @ts-expect-error TS(2339): Property 'additionalElementsRenderer' does not exi... Remove this comment to see the full error message
    additionalElementsRenderer,
    // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type '{}'.
    editorMode,
    // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
    element,
    // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'theme' does not exist on type '{}'.
    theme,
    // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
    scope,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'rowLink' does not exist on type '{}'.
    rowLink,
    // @ts-expect-error TS(2339): Property 'rawVariables' does not exist on type '{}... Remove this comment to see the full error message
    rawVariables,
  }) => {
    const secondaryColor = theme.brandColorGroups.secondary;
    const primaryColor = theme.brandColorGroups.primary;
    const {
      link = rowLink,
      variables: { title, image, description, secondaryText } = rawVariables,
    } = useScopeVariables(scope, element, project, elementPath);

    const additionalElements = additionalElementsRenderer(
      scope,
      'w-full grid grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-4 mt-8',
      'flex flex-col min-w-0',
    );

    return (
      <Link
        className="w-full flex-col items-center px-8 py-4 hover:bg-gray-100 hover:bg-opacity-50"
        editorMode={editorMode}
        link={link}
      >
        <div className="flex w-full items-start">
          {!image.hidden && (
            <div className="flex w-10 min-w-0 flex-shrink-0">
              <Box
                is="div"
                className={classNames(
                  'sm:rounded-0 h-10 w-10 rounded-full bg-cover bg-center text-white sm:mb-4 sm:mr-4',
                )}
                bg={getColorShade(secondaryColor, 400)}
                style={formatBg(
                  image.value.src ||
                    `https://picsum.photos/id/${(index % 10) + 1020}/600/600`,
                )}
              >
                <span className="block h-10 w-10" />
              </Box>
            </div>
          )}
          <div className="flex min-w-0 max-w-full flex-shrink flex-grow flex-col justify-center px-4">
            <Box
              is="span"
              className="text-lg font-medium"
              text={getColorShade(primaryColor, 800)}
            >
              {title.value}
            </Box>
            {!description.hidden && (
              <span className="text-lg text-gray-400">{description.value}</span>
            )}
            {additionalElements}
          </div>
          {!secondaryText.hidden && (
            <div className="flex min-w-0 flex-shrink">
              <span className="whitespace-nowrap text-sm text-gray-400">
                {secondaryText.value}
              </span>
            </div>
          )}
        </div>
      </Link>
    );
  },
);

(CollectionConversation as any).propTypes = {};

(CollectionConversation as any).defaultProps = {
  index: 0,
};

export default withTheme(CollectionConversation);
