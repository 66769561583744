import createReactComponent from '../createReactComponent';
export default createReactComponent('clipboard-list', 'IconClipboardList', [
  [
    'path',
    {
      d: 'M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 12l.01 0', key: 'svg-2' }],
  ['path', { d: 'M13 12l2 0', key: 'svg-3' }],
  ['path', { d: 'M9 16l.01 0', key: 'svg-4' }],
  ['path', { d: 'M13 16l2 0', key: 'svg-5' }],
]);
