import createReactComponent from '../createReactComponent';
export default createReactComponent('credit-card-off', 'IconCreditCardOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  ['path', { d: 'M9 5h9a3 3 0 0 1 3 3v8a3 3 0 0 1 -.128 .87', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M18.87 18.872a3 3 0 0 1 -.87 .128h-12a3 3 0 0 1 -3 -3v-8c0 -1.352 .894 -2.495 2.124 -2.87',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 11l8 0', key: 'svg-3' }],
  ['path', { d: 'M15 11l6 0', key: 'svg-4' }],
  ['path', { d: 'M7 15l.01 0', key: 'svg-5' }],
  ['path', { d: 'M11 15l2 0', key: 'svg-6' }],
]);
