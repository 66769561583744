import createReactComponent from '../createReactComponent';
export default createReactComponent('beach-off', 'IconBeachOff', [
  [
    'path',
    { d: 'M15.071 15.102a7.502 7.502 0 0 0 -8.124 1.648', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M10.27 6.269l9.926 5.731a6 6 0 0 0 -10.32 -6.123', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M16.732 10c1.658 -2.87 2.225 -5.644 1.268 -6.196c-.957 -.552 -3.075 1.326 -4.732 4.196',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M15 9l-.739 1.279', key: 'svg-3' }],
  ['path', { d: 'M12.794 12.82l-.794 1.376', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M3 19.25a2.4 2.4 0 0 1 1 -.25a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 1.135 -.858',
      key: 'svg-5',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
