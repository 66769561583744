import createReactComponent from '../createReactComponent';
export default createReactComponent('database-share', 'IconDatabaseShare', [
  [
    'path',
    {
      d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 6v6c0 1.657 3.582 3 8 3c.361 0 .716 -.009 1.065 -.026',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M20 13v-7', key: 'svg-2' }],
  ['path', { d: 'M4 12v6c0 1.657 3.582 3 8 3', key: 'svg-3' }],
  ['path', { d: 'M16 22l5 -5', key: 'svg-4' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-5' }],
]);
