import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-snowflake', 'IconBrandSnowflake', [
  ['path', { d: 'M14 21v-5.5l4.5 2.5', key: 'svg-0' }],
  ['path', { d: 'M10 21v-5.5l-4.5 2.5', key: 'svg-1' }],
  ['path', { d: 'M3.5 14.5l4.5 -2.5l-4.5 -2.5', key: 'svg-2' }],
  ['path', { d: 'M20.5 9.5l-4.5 2.5l4.5 2.5', key: 'svg-3' }],
  ['path', { d: 'M10 3v5.5l-4.5 -2.5', key: 'svg-4' }],
  ['path', { d: 'M14 3v5.5l4.5 -2.5', key: 'svg-5' }],
  ['path', { d: 'M12 11l1 1l-1 1l-1 -1z', key: 'svg-6' }],
]);
