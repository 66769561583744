export const GOOGLE_SHEETS_SYNCING_SCOPES = [
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/spreadsheets',
];

export type GenericOAuthIntegration =
  | 'airtable'
  | 'google-drive'
  | 'hubspot'
  | 'slack'
  | 'smart-suite';

export type OAuthCallbackState = {
  codeVerifierId?: string;
  project?: string;
  redirectPath?: string;
  redirectUrl?: string;
  userEmail?: string;
  user?: string;
  scopes?: string;
};
