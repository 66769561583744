import createReactComponent from '../createReactComponent';
export default createReactComponent('drone-off', 'IconDroneOff', [
  ['path', { d: 'M14 14h-4v-4', key: 'svg-0' }],
  ['path', { d: 'M10 10l-3.5 -3.5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M9.957 5.95a3.503 3.503 0 0 0 -2.917 -2.91m-3.02 .989a3.5 3.5 0 0 0 1.98 5.936',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M14 10l3.5 -3.5', key: 'svg-3' }],
  ['path', { d: 'M18 9.965a3.5 3.5 0 1 0 -3.966 -3.965', key: 'svg-4' }],
  ['path', { d: 'M14 14l3.5 3.5', key: 'svg-5' }],
  [
    'path',
    {
      d: 'M14.035 18a3.5 3.5 0 0 0 5.936 1.98m.987 -3.026a3.503 3.503 0 0 0 -2.918 -2.913',
      key: 'svg-6',
    },
  ],
  ['path', { d: 'M10 14l-3.5 3.5', key: 'svg-7' }],
  ['path', { d: 'M6 14.035a3.5 3.5 0 1 0 3.966 3.965', key: 'svg-8' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-9' }],
]);
