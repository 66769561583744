import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'creative-commons-sa',
  'IconCreativeCommonsSa',
  [
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M12 16a4 4 0 1 0 -4 -4v1', key: 'svg-1' }],
    ['path', { d: 'M6 12l2 2l2 -2', key: 'svg-2' }],
  ],
);
