import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-up-left-circle',
  'IconArrowUpLeftCircle',
  [
    ['path', { d: 'M15.536 15.536l-9.536 -9.536', key: 'svg-0' }],
    ['path', { d: 'M10 6h-4v4', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M15.586 15.586a2 2 0 1 0 2.828 2.828a2 2 0 0 0 -2.828 -2.828',
        key: 'svg-2',
      },
    ],
  ],
);
