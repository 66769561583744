import createReactComponent from '../createReactComponent';
export default createReactComponent('border-none', 'IconBorderNone', [
  ['path', { d: 'M4 4l0 .01', key: 'svg-0' }],
  ['path', { d: 'M8 4l0 .01', key: 'svg-1' }],
  ['path', { d: 'M12 4l0 .01', key: 'svg-2' }],
  ['path', { d: 'M16 4l0 .01', key: 'svg-3' }],
  ['path', { d: 'M20 4l0 .01', key: 'svg-4' }],
  ['path', { d: 'M4 8l0 .01', key: 'svg-5' }],
  ['path', { d: 'M12 8l0 .01', key: 'svg-6' }],
  ['path', { d: 'M20 8l0 .01', key: 'svg-7' }],
  ['path', { d: 'M4 12l0 .01', key: 'svg-8' }],
  ['path', { d: 'M8 12l0 .01', key: 'svg-9' }],
  ['path', { d: 'M12 12l0 .01', key: 'svg-10' }],
  ['path', { d: 'M16 12l0 .01', key: 'svg-11' }],
  ['path', { d: 'M20 12l0 .01', key: 'svg-12' }],
  ['path', { d: 'M4 16l0 .01', key: 'svg-13' }],
  ['path', { d: 'M12 16l0 .01', key: 'svg-14' }],
  ['path', { d: 'M20 16l0 .01', key: 'svg-15' }],
  ['path', { d: 'M4 20l0 .01', key: 'svg-16' }],
  ['path', { d: 'M8 20l0 .01', key: 'svg-17' }],
  ['path', { d: 'M12 20l0 .01', key: 'svg-18' }],
  ['path', { d: 'M16 20l0 .01', key: 'svg-19' }],
  ['path', { d: 'M20 20l0 .01', key: 'svg-20' }],
]);
