import createReactComponent from '../createReactComponent';
export default createReactComponent('currency-off', 'IconCurrencyOff', [
  [
    'path',
    {
      d: 'M18.531 14.524a7 7 0 0 0 -9.06 -9.053m-2.422 1.582a7 7 0 0 0 9.903 9.896',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 4l3 3', key: 'svg-1' }],
  ['path', { d: 'M20 4l-3 3', key: 'svg-2' }],
  ['path', { d: 'M4 20l3 -3', key: 'svg-3' }],
  ['path', { d: 'M20 20l-3 -3', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
