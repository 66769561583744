import createReactComponent from '../createReactComponent';
export default createReactComponent('candy', 'IconCandy', [
  [
    'path',
    {
      d: 'M7.05 11.293l4.243 -4.243a2 2 0 0 1 2.828 0l2.829 2.83a2 2 0 0 1 0 2.828l-4.243 4.243a2 2 0 0 1 -2.828 0l-2.829 -2.831a2 2 0 0 1 0 -2.828z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16.243 9.172l3.086 -.772a1.5 1.5 0 0 0 .697 -2.516l-2.216 -2.217a1.5 1.5 0 0 0 -2.44 .47l-1.248 2.913',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M9.172 16.243l-.772 3.086a1.5 1.5 0 0 1 -2.516 .697l-2.217 -2.216a1.5 1.5 0 0 1 .47 -2.44l2.913 -1.248',
      key: 'svg-2',
    },
  ],
]);
