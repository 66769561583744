import createReactComponent from '../createReactComponent';
export default createReactComponent('matchstick', 'IconMatchstick', [
  ['path', { d: 'M3 21l14 -9', key: 'svg-0' }],
  ['path', { d: 'M17 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M17 3l3.62 7.29a4.007 4.007 0 0 1 -.764 4.51a4 4 0 0 1 -6.493 -4.464l3.637 -7.336z',
      key: 'svg-2',
    },
  ],
]);
