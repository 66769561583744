import React, { forwardRef } from 'react';
import classNames from 'classnames';

const OptionGroup = forwardRef<
  any,
  { className?: string; children: any; [rest: string]: any }
>(
  // @ts-expect-error TS(2339): Property 'children' does not exist on type '{}'.
  ({ children, className, onOptionClick }: { children: any }, ref) => (
    <li
      className={classNames('relative block font-semibold', className)}
      ref={ref}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (onOptionClick) {
          onOptionClick();
        }
      }}
    >
      {children({})}
    </li>
  ),
);

export default OptionGroup;
