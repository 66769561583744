import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circle-chevrons-left',
  'IconCircleChevronsLeft',
  [
    ['path', { d: 'M15 15l-3 -3l3 -3', key: 'svg-0' }],
    ['path', { d: 'M11 15l-3 -3l3 -3', key: 'svg-1' }],
    ['path', { d: 'M21 12a9 9 0 1 0 0 .265l0 -.265z', key: 'svg-2' }],
  ],
);
