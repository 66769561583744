import { useMemo } from 'react';
import SectionEditor from '@noloco/ui/src/components/editor/customerEditors/SectionEditor';
import { FORM, PAGE, RECORD } from '../../constants/elements';
import { DataType } from '../../models/DataTypes';
import { ElementPath } from '../../models/Element';
import { Project } from '../../models/Project';
import { Page } from '../../utils/pages';

type StandaloneRightBuildModeProps = {
  dataType: DataType;
  debouncedUpdateProperty: (elementPath: ElementPath, value: any) => void;
  element: Page;
  elementPath: ElementPath;
  elementType: string;
  isRecordView?: boolean;
  project: Project;
  section?: any;
  selectedSectionPath?: ElementPath;
  updateProperty: (elementPath: ElementPath, value: any) => void;
};

const StandaloneRightBuildMode = ({
  dataType,
  debouncedUpdateProperty,
  element,
  elementPath,
  elementType,
  isRecordView = true,
  project,
  section,
  selectedSectionPath,
  updateProperty,
}: StandaloneRightBuildModeProps) => {
  const elementProps = useMemo(() => {
    switch (elementType) {
      case FORM:
        return element.props.new;

      case RECORD:
        return element.props.record;

      default:
        return element.props;
    }
  }, [elementType, element]);

  if (!section || !selectedSectionPath || selectedSectionPath.length === 0) {
    return null;
  }

  return (
    <SectionEditor
      dataType={dataType}
      debouncedUpdateProperty={debouncedUpdateProperty}
      element={element}
      elementPath={elementPath}
      elementProps={elementProps}
      isRecordView={isRecordView}
      project={project}
      propPath={elementType === PAGE ? [] : ['record']}
      section={section}
      selectedSectionPath={selectedSectionPath}
      source={PAGE}
      updateProperty={updateProperty}
    />
  );
};

export default StandaloneRightBuildMode;
