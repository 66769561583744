import createReactComponent from '../createReactComponent';
export default createReactComponent('window', 'IconWindow', [
  [
    'path',
    {
      d: 'M12 3c-3.866 0 -7 3.272 -7 7v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1 -1v-10c0 -3.728 -3.134 -7 -7 -7z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 13l14 0', key: 'svg-1' }],
  ['path', { d: 'M12 3l0 18', key: 'svg-2' }],
]);
