import createReactComponent from '../createReactComponent';
export default createReactComponent('chalkboard-off', 'IconChalkboardOff', [
  [
    'path',
    {
      d: 'M8 19h-3a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2m4 0h10a2 2 0 0 1 2 2v10',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 17v1a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h4',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
