import createReactComponent from '../createReactComponent';
export default createReactComponent('contrast-off', 'IconContrastOff', [
  [
    'path',
    {
      d: 'M12 12v5a4.984 4.984 0 0 0 3.522 -1.45m1.392 -2.623a5 5 0 0 0 -4.914 -5.927v1',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M5.641 5.631a9 9 0 1 0 12.719 12.738m1.68 -2.318a9 9 0 0 0 -12.074 -12.098',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
