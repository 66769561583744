import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'currency-dollar-off',
  'IconCurrencyDollarOff',
  [
    [
      'path',
      {
        d: 'M16.7 8a3 3 0 0 0 -2.7 -2h-4m-2.557 1.431a3 3 0 0 0 2.557 4.569h2m4.564 4.558a3 3 0 0 1 -2.564 1.442h-4a3 3 0 0 1 -2.7 -2',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12 3v3m0 12v3', key: 'svg-1' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
  ],
);
