import createReactComponent from '../createReactComponent';
export default createReactComponent('cell-signal-off', 'IconCellSignalOff', [
  [
    'path',
    {
      d: 'M20 20h-15.269a.731 .731 0 0 1 -.517 -1.249l7.265 -7.264m2 -2l5.272 -5.272a.731 .731 0 0 1 1.249 .517v11.269',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
]);
