import createReactComponent from '../createReactComponent';
export default createReactComponent('logic-xor', 'IconLogicXor', [
  ['path', { d: 'M22 12h-4', key: 'svg-0' }],
  ['path', { d: 'M2 9h6', key: 'svg-1' }],
  ['path', { d: 'M2 15h6', key: 'svg-2' }],
  ['path', { d: 'M7 19c1.778 -4.667 1.778 -9.333 0 -14', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M10 5c10.667 2.1 10.667 12.6 0 14c1.806 -4.667 1.806 -9.333 0 -14z',
      key: 'svg-4',
    },
  ],
]);
