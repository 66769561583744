import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'triangle-square-circle',
  'IconTriangleSquareCircle',
  [
    ['path', { d: 'M12 3l-4 7h8z', key: 'svg-0' }],
    ['path', { d: 'M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
        key: 'svg-2',
      },
    ],
  ],
);
