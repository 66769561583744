import createReactComponent from '../createReactComponent';
export default createReactComponent('home-signal', 'IconHomeSignal', [
  ['path', { d: 'M15 22v-2', key: 'svg-0' }],
  ['path', { d: 'M18 22v-4', key: 'svg-1' }],
  ['path', { d: 'M21 22v-6', key: 'svg-2' }],
  [
    'path',
    { d: 'M19 12.494v-.494h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h4', key: 'svg-3' },
  ],
  ['path', { d: 'M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v.5', key: 'svg-4' }],
]);
