import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-javascript', 'IconBrandJavascript', [
  ['path', { d: 'M20 4l-2 14.5l-6 2l-6 -2l-2 -14.5z', key: 'svg-0' }],
  ['path', { d: 'M7.5 8h3v8l-2 -1', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M16.5 8h-2.5a.5 .5 0 0 0 -.5 .5v3a.5 .5 0 0 0 .5 .5h1.423a.5 .5 0 0 1 .495 .57l-.418 2.93l-2 .5',
      key: 'svg-2',
    },
  ],
]);
