import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Loader, Modal, TextInput } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { DISCONNECT_DATA_SOURCE } from '../../../queries/project';
import { useRemoveDataSource } from '../../../utils/hooks/useUpdateDataTypes';

const LANG_KEY = 'data.dataSources';

type Props = {
  dataSource: any;
  onClose: (...args: any[]) => any;
  projectName: string;
};

const DeleteDataSourceModal = ({ dataSource, projectName, onClose }: Props) => {
  const removeDataSource = useRemoveDataSource();
  const { push } = useRouter();

  const [disconnectDataSource] = useMutation(DISCONNECT_DATA_SOURCE);

  const [loading, setLoading] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const handleConfirmDelete = () => {
    setLoading(true);

    disconnectDataSource({
      variables: {
        projectName,
        id: dataSource.id,
      },
    })
      .then(({ data }) => {
        if (data.disconnectDataSource) {
          removeDataSource({ dataSource: dataSource });
          push(`/_/data/internal`);
        }
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Modal
      icon={<IconAlertTriangle size={18} />}
      title={getText(LANG_KEY, 'delete.title')}
      confirmText={
        loading ? (
          <Loader type="Bars" size="sm" className="text-white" />
        ) : (
          getText(LANG_KEY, 'delete.confirm')
        )
      }
      cancelText={getText(LANG_KEY, 'delete.cancel')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirmDelete}
      confirmDisabled={confirmText !== dataSource.display || loading}
      variant="danger"
    >
      <>
        <p className="mb-4">
          {getText(
            { sourceName: dataSource.display },
            LANG_KEY,
            'delete.disclaimer',
          )}
        </p>
        <p className="mb-4">
          {getText(
            { sourceName: dataSource.display },
            LANG_KEY,
            'delete.check',
          )}
        </p>
        <span className="mt-2 font-medium">
          {getText(
            { sourceName: dataSource.display },
            LANG_KEY,
            'delete.confirmName.label',
          )}
        </span>
        <TextInput
          className="my-3"
          placeholder={dataSource.display}
          surface={LIGHT}
          onChange={({ target: { value } }: any) => setConfirmText(value)}
          value={confirmText}
        />
      </>
    </Modal>
  );
};

export default DeleteDataSourceModal;
