import { createSlice } from '@reduxjs/toolkit';

const elementsSlice = createSlice({
  name: 'elements',
  initialState: {
    selected: [],
    newElementPath: [],
    selectedSectionPath: [],
    highlightedSectionPath: [],
    leftEditorSection: null,
    selectedPagePath: undefined,
    editingPage: null,
    elements: [],
    editorMode: false,
    rightTab: null,
    clipboard: null,
    showAdvancedPageSettings: false,
    canvasShortcutsEnabled: false,
    sidebarRecordViewTitle: null,
    fieldListEditorOpen: false,
  },
  reducers: {
    setCanvasShortcutsEnabled: (state, { payload }) => {
      state.canvasShortcutsEnabled = payload;
    },
    setEditorMode: (state, { payload }) => {
      state.editorMode = payload;
    },
    setRightTab: (state, { payload }) => {
      state.rightTab = payload;
    },
    setEditingPage: (state, { payload }) => {
      state.editingPage = payload;
    },
    setShowAdvancedPageSettings: (state, { payload }) => {
      state.showAdvancedPageSettings = payload;
    },
    setElements: (state, { payload }) => {
      state.elements = payload;
    },
    setNewElementPath: (state, { payload }) => {
      state.newElementPath = payload;
      state.selected = [];
    },
    setSelectedSectionPath: (state, { payload }) => {
      state.selectedSectionPath = payload;
    },
    setHighlightedSectionPath: (state, { payload }) => {
      state.highlightedSectionPath = payload;
    },
    setLeftEditorSection: (state, { payload }) => {
      state.leftEditorSection = payload;
    },
    setSelectedElement: (state, { payload }) => {
      state.selected = payload;
      state.newElementPath = [];
    },
    setSelectedPagePath: (state, { payload }) => {
      state.selectedPagePath = payload;
    },
    setClipboard: (state, { payload }) => {
      state.clipboard = payload;
    },
    setSidebarRecordViewTitle: (state, { payload }) => {
      state.sidebarRecordViewTitle = payload;
    },
    setIsFieldListEditorOpen: (state, { payload }) => {
      state.fieldListEditorOpen = payload;
    },
  },
});

export const {
  setElements,
  setEditorMode,
  setCanvasShortcutsEnabled,
  setRightTab,
  setEditingPage,
  setSelectedPagePath,
  setSelectedElement,
  setSelectedSectionPath,
  setHighlightedSectionPath,
  setLeftEditorSection,
  setNewElementPath,
  setShowAdvancedPageSettings,
  setClipboard,
  setSidebarRecordViewTitle,
  setIsFieldListEditorOpen,
} = elementsSlice.actions;

export default elementsSlice.reducer;
