import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-pocket', 'IconBrandPocket', [
  [
    'path',
    {
      d: 'M5 4h14a2 2 0 0 1 2 2v6a9 9 0 0 1 -18 0v-6a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 11l4 4l4 -4', key: 'svg-1' }],
]);
