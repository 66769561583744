import React, { useMemo } from 'react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { TextInput } from '@noloco/components';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { OBJECT } from '@noloco/core/src/constants/dataTypes';
import { RATING, SLIDER } from '@noloco/core/src/constants/fieldFormats';
import { LIST } from '@noloco/core/src/constants/inputTypes';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionType } from '@noloco/core/src/utils/options';
import { UPDATE_DEBOUNCE_MS } from '../../../utils/hooks/projectHooks';
import FileTypeOptionsEditor from './FileTypeOptionsEditor';
import FormFieldTypeEditor from './FormFieldTypeEditor';
import OptionFieldEditor from './OptionFieldEditor';

const LANG_KEY = 'elements.VIEW';

const BasicFormFieldEditor = ({
  config,
  dataType,
  elementPath,
  field,
  index,
  updateFields,
  recordScopeOptions,
  showFieldTypeOptions,
  project,
}: any) => {
  const inputType = get(config, 'inputType', null);
  const format = get(field, 'typeOptions.format', null);
  const showPlaceholderInput = useMemo(
    () =>
      field.type !== OBJECT &&
      inputType !== LIST &&
      ![RATING, SLIDER].includes(format),
    [field, inputType, format],
  );

  const fileTypeValidation = useMemo(
    () => get(config, 'fileTypeValidation', {}),
    [config],
  );

  return (
    <div className="flex flex-col space-y-4">
      {showPlaceholderInput && (
        <BuildModeInput label={getText(LANG_KEY, 'filters.placeholderLabel')}>
          <TextInput
            debounceMs={UPDATE_DEBOUNCE_MS}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) =>
              updateFields([index, 'placeholder'], value)
            }
            placeholder={getText(
              { field: upperFirst(field.display) },
              LANG_KEY,
              'filters.placeholder',
            )}
            value={get(config, 'placeholder')}
          />
        </BuildModeInput>
      )}
      {showFieldTypeOptions && (
        <FormFieldTypeEditor
          config={config}
          field={field}
          index={index}
          updateFields={updateFields}
        />
      )}
      {isOptionType(field.type) && (
        <OptionFieldEditor
          dataType={dataType}
          elementPath={elementPath}
          field={field}
          inputType={inputType}
          onChange={(path, value) => {
            updateFields([index, 'optionsConfig', ...path], value);
          }}
          project={project}
          recordScopeOptions={recordScopeOptions}
          values={config.optionsConfig}
        />
      )}
      {field.type === FILE && (
        <BuildModeInput label={getText(LANG_KEY, 'fileType.label')}>
          <FileTypeOptionsEditor
            onChange={(path, value) => {
              updateFields([index, 'fileTypeValidation', ...path], value);
            }}
            value={fileTypeValidation}
          />
        </BuildModeInput>
      )}
    </div>
  );
};

BasicFormFieldEditor.defaultProps = {
  showFieldTypeOptions: true,
};

export default BasicFormFieldEditor;
