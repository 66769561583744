import createReactComponent from '../createReactComponent';
export default createReactComponent('braces', 'IconBraces', [
  [
    'path',
    {
      d: 'M7 4a2 2 0 0 0 -2 2v3a2 3 0 0 1 -2 3a2 3 0 0 1 2 3v3a2 2 0 0 0 2 2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 4a2 2 0 0 1 2 2v3a2 3 0 0 0 2 3a2 3 0 0 0 -2 3v3a2 2 0 0 1 -2 2',
      key: 'svg-1',
    },
  ],
]);
