import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'battery-automotive',
  'IconBatteryAutomotive',
  [
    [
      'path',
      {
        d: 'M3 6m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M6 6v-2', key: 'svg-1' }],
    ['path', { d: 'M19 4l0 2', key: 'svg-2' }],
    ['path', { d: 'M6.5 13l3 0', key: 'svg-3' }],
    ['path', { d: 'M14.5 13l3 0', key: 'svg-4' }],
    ['path', { d: 'M16 11.5l0 3', key: 'svg-5' }],
  ],
);
