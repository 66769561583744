import createReactComponent from '../createReactComponent';
export default createReactComponent('help-circle', 'IconHelpCircle', [
  ['path', { d: 'M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0', key: 'svg-0' }],
  ['path', { d: 'M12 16v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-2',
    },
  ],
]);
