import createReactComponent from '../createReactComponent';
export default createReactComponent('wash-dry-hang', 'IconWashDryHang', [
  [
    'path',
    {
      d: 'M3 3m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 4.01c5.333 5.323 10.667 5.32 16 -.01', key: 'svg-1' }],
]);
