import { IconCommand } from '@tabler/icons-react';
import useIsMacOs from './useIsMacOs';

const useCmdKey = () => {
  const isMacOs = useIsMacOs();

  return isMacOs ? <IconCommand size={16} /> : 'Ctrl';
};

export default useCmdKey;
