import React from 'react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { formatValue } from '../elements/sections/collections/FieldCell';
import { getText } from './lang';

const BASE_LANG = 'elements.VIEW.fields.validationRules';

export const stringifyValidationRuleError = (rule: any, field: any) => {
  let validationProperty = '';
  let units = '';
  const path = get(rule, ['condition', 'field', 'path']);
  const operator = get(rule, ['condition', 'operator']);

  if (!path || !operator) {
    return getText(BASE_LANG, 'invalidRule');
  }

  if (path.endsWith('.length')) {
    validationProperty = getText(BASE_LANG, 'labels.characterLength');
    units = getText(BASE_LANG, 'suffixes.characterLength');
  }

  const operatorLabel = getText(
    {
      name: validationProperty,
      value: formatValue(rule.resolvedValues.value, field, {}) as number,
      units,
    },
    'operators',
    operator,
    'label.named',
  );
  return upperFirst(operatorLabel.trim());
};

export const renderValidationRuleErrors = (rules: any, field: any) => (
  <div>
    <ul>
      {rules.map((rule: any, index: any) => (
        <li key={index}>{stringifyValidationRuleError(rule, field)}</li>
      ))}
    </ul>
  </div>
);

export class ValidationError extends Error {}
