import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-cpp', 'IconBrandCpp', [
  ['path', { d: 'M18 12h4', key: 'svg-0' }],
  ['path', { d: 'M20 10v4', key: 'svg-1' }],
  ['path', { d: 'M11 12h4', key: 'svg-2' }],
  ['path', { d: 'M13 10v4', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M9 9a3 3 0 0 0 -3 -3h-.5a3.5 3.5 0 0 0 -3.5 3.5v5a3.5 3.5 0 0 0 3.5 3.5h.5a3 3 0 0 0 3 -3',
      key: 'svg-4',
    },
  ],
]);
