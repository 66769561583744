import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-netease-music',
  'IconBrandNeteaseMusic',
  [
    [
      'path',
      {
        d: 'M9 4c-2.93 1.346 -5 5.046 -5 8.492c0 4.508 4 7.508 8 7.508s8 -3 8 -7c0 -3.513 -3.5 -5.513 -6 -5.513s-5 1.513 -5 4.513c0 2 1.5 3 3 3s3 -1 3 -3c0 -3.513 -2 -4.508 -2 -6.515c0 -3.504 3.5 -2.603 4 -1.502',
        key: 'svg-0',
      },
    ],
  ],
);
