import createReactComponent from '../createReactComponent';
export default createReactComponent('folder-check', 'IconFolderCheck', [
  [
    'path',
    {
      d: 'M11 19h-6a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-1' }],
]);
