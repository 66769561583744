import { useEffect, useMemo } from 'react';
import first from 'lodash/first';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { LEFT_PAGE_SETTINGS } from '../../constants/buildMode';
import { USER } from '../../constants/builtInDataTypes';
import { IFRAME, PAGE } from '../../constants/elements';
import { DataType } from '../../models/DataTypes';
import { Element, ElementPath } from '../../models/Element';
import {
  setLeftEditorSection,
  setSelectedSectionPath,
} from '../../reducers/elements';
import { selectedSectionSelector } from '../../selectors/elementsSelectors';
import { projectDataSelector } from '../../selectors/projectSelectors';
import useSelectedTab from '../../utils/hooks/useSelectedTab';
import { getText } from '../../utils/lang';
import { Page } from '../../utils/pages';
import { filterSections } from '../../utils/tabs';
import SectionEditorList from '../editor/SectionEditorList';
import BuildModeSection from './BuildModeSection';
import BuildModeSectionOptions from './BuildModeSectionOptions';

export type LeftBuildModePageEditorProps = {
  elementPath: ElementPath;
  page: Page;
};

export const isPageFullScreenIframe = (page: Page | Element) => {
  const sections = get(page, 'props.sections', []);
  if (sections.length === 1) {
    return get(first(sections), 'props.fullScreen', false);
  }

  return false;
};

const LeftBuildModePageEditor = ({
  elementPath,
  page,
}: LeftBuildModePageEditorProps) => {
  const dispatch = useDispatch();
  const project = useSelector(projectDataSelector);
  const tabs = get(page, 'props.tabs', []);
  const sections = get(page, 'props.sections', []);
  const selectedSectionPath = useSelector(selectedSectionSelector);
  const section = get(sections, selectedSectionPath);
  const selectedTab = useSelectedTab(tabs, tabs);
  const dataType = useMemo(
    () => project.dataTypes.getByName(USER) as DataType,
    [project.dataTypes],
  );

  const visibleSections = useMemo(
    () => filterSections(tabs, tabs, sections, selectedTab),
    [tabs, sections, selectedTab],
  );

  const pageIsIframe = useMemo(() => isPageFullScreenIframe(page), [page]);

  useEffect(() => {
    if (pageIsIframe) {
      dispatch(setSelectedSectionPath([0]));
      dispatch(setLeftEditorSection(IFRAME));
    }
  }, [pageIsIframe, dispatch, elementPath]);

  if (pageIsIframe) {
    return null;
  }

  return (
    <div className="sticky bottom-4 z-10">
      <BuildModeSection
        className="border-t"
        id={LEFT_PAGE_SETTINGS}
        title={getText('leftSidebar.auth.PAGE')}
        endComponent={
          <BuildModeSectionOptions
            dataType={dataType}
            elementPath={elementPath}
            elementType={PAGE}
            project={project}
            sections={sections}
            selectedTab={selectedTab}
          />
        }
      >
        {/* @ts-expect-error TS(2786): 'SimpleBar' cannot be used as a JSX component. */}
        <SimpleBar className="max-h-screen-40 min-h-40 overflow-y-auto bg-slate-800">
          {visibleSections.length === 0 ? (
            <div className="flex items-center justify-center py-4 text-center text-slate-400">
              {getText('leftSidebar.editor.sectionEditorEmptyState')}
            </div>
          ) : (
            <div className="px-3 pb-4 pt-2">
              <SectionEditorList
                activeSection={section?.id}
                dataType={dataType}
                elementPath={elementPath}
                elementType={PAGE}
                project={project}
                sectionPath={['sections']}
                sections={sections}
                selectedTab={selectedTab}
                visibleSections={visibleSections}
              />
            </div>
          )}
        </SimpleBar>
      </BuildModeSection>
    </div>
  );
};

export default LeftBuildModePageEditor;
