import get from 'lodash/get';
import { DateTime } from 'luxon';
import { Relationship } from '../constants/relationships';
import { Event } from '../elements/sections/collections/CollectionEvents';
import {
  isMultiRelationship,
  isReverseMultiRelationship,
} from './relationships';

const getFieldDependencies = (
  event: Event,
  fieldName: string,
  isMulti: boolean,
  dateStartField: string,
  dateEndField: string,
) =>
  isMulti
    ? get(event, ['record', fieldName, 'edges'], []).map(
        ({ node: predecessor }: any) => ({
          id: predecessor.id,
          start: DateTime.fromISO(predecessor[dateStartField]).toMillis(),
          end: DateTime.fromISO(predecessor[dateEndField]).toMillis(),
        }),
      )
    : [get(event, ['record', fieldName], null)];

export const getDependencies = (
  event: Event,
  relationship: Relationship,
  ganttDependencyFieldName: string,
  ganttDependencyReverseField: string,
  dateStartField: string,
  dateEndField: string,
) => ({
  predecessors: getFieldDependencies(
    event,
    ganttDependencyFieldName,
    isMultiRelationship(relationship),
    dateStartField,
    dateEndField,
  ),
  successors: getFieldDependencies(
    event,
    ganttDependencyReverseField,
    isReverseMultiRelationship(relationship),
    dateStartField,
    dateEndField,
  ),
});
