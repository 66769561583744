import { ARRAY } from '../../constants/elementPropTypeTypes';
import { Project } from '../Project';
import ElementPropType from './ElementPropType';

const defaultDynamicPropShape = () => ({});

class ArrayType extends ElementPropType {
  shape: Record<any, any>;
  display: () => boolean;
  getDynamicPropShape: (
    elementProps: any,
    currentValue: any,
    propPath: string[] | null,
    project: Project,
  ) => Record<any, any>;
  minimum: number;

  constructor(shape = {}, display = () => true) {
    super(ARRAY);
    this.shape = shape;
    this.display = display;
    this.getDynamicPropShape = defaultDynamicPropShape;
    this.minimum = 0;
  }

  setDynamicPropShape(
    getDynamicPropShape: (
      elementProps: any,
      currentValue: any,
      propPath: string[] | null,
      project: Project,
    ) => Record<any, any>,
  ) {
    this.getDynamicPropShape = getDynamicPropShape;
    return this;
  }

  mergeDynamicPropsShape(
    elementProps: any,
    currentValue: any,
    propPath: string[] | null,
    project: Project,
  ) {
    return {
      ...this.shape,
      ...this.getDynamicPropShape(
        elementProps,
        currentValue,
        propPath,
        project,
      ),
    };
  }

  setMinimum(minimum: number) {
    this.minimum = minimum;
    return this;
  }
}

export default ArrayType;
