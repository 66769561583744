import createReactComponent from '../createReactComponent';
export default createReactComponent('file-code-2', 'IconFileCode2', [
  ['path', { d: 'M10 12h-1v5h1', key: 'svg-0' }],
  ['path', { d: 'M14 12h1v5h-1', key: 'svg-1' }],
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z',
      key: 'svg-3',
    },
  ],
]);
