export const ROWS: CollectionLayout = 'ROWS';
export const COLUMNS: CollectionLayout = 'COLUMNS';
export const CARDS: CollectionLayout = 'CARDS';
export const TABLE: CollectionLayout = 'TABLE';
export const PIVOT_TABLE: CollectionLayout = 'PIVOT_TABLE';
export const TABLE_FULL: CollectionLayout = 'TABLE_FULL';
export const BOARD: CollectionLayout = 'BOARD';
export const SPLIT: CollectionLayout = 'SPLIT';
export const SINGLE_RECORD: CollectionLayout = 'SINGLE_RECORD';
export const CONVERSATION: CollectionLayout = 'CONVERSATION';
export const CALENDAR: CollectionLayout = 'CALENDAR';
export const TIMELINE: CollectionLayout = 'TIMELINE';
export const GANTT: CollectionLayout = 'GANTT';
export const CHARTS: CollectionLayout = 'CHARTS';
export const MAP: CollectionLayout = 'MAP';

const collectionLayouts: CollectionLayout[] = [
  ROWS,
  COLUMNS,
  CARDS,
  TABLE,
  PIVOT_TABLE,
  TABLE_FULL,
  CONVERSATION,
  BOARD,
  CALENDAR,
  TIMELINE,
  GANTT,
  CHARTS,
  MAP,
];

export const EVENT_BASED_LAYOUTS: CollectionLayout[] = [
  TIMELINE,
  GANTT,
  CALENDAR,
];

export const LAYOUT_SUPPORTS_RECORD_COLORING: CollectionLayout[] = [
  ROWS,
  COLUMNS,
  CARDS,
  TABLE,
  TABLE_FULL,
  BOARD,
];

export type CollectionLayout =
  | 'ROWS'
  | 'COLUMNS'
  | 'CARDS'
  | 'TABLE'
  | 'PIVOT_TABLE'
  | 'TABLE_FULL'
  | 'CONVERSATION'
  | 'BOARD'
  | 'SPLIT'
  | 'CALENDAR'
  | 'TIMELINE'
  | 'GANTT'
  | 'CHARTS'
  | 'MAP'
  | 'SINGLE_RECORD';

export default collectionLayouts;
