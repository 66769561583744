import { extractDataListDependencies } from '../../../utils/data';
import DataTypes from '../../DataTypes';
import ArrayType from '../ArrayPropType';
import BoolType from '../BoolPropType';
import ComboType from '../ComboPropType';
import DataTypeNamePropType from '../DataTypeNamePropType';
import EnumType from '../EnumPropType';
import RawDataPropType from '../RawDataPropPropType';
import StringType from '../StringPropType';
import { buildCustomFilterProp } from './CustomFilterPropType';

class DataListPropType extends ComboType {
  constructor(propMapper: any, display?: any) {
    super(
      {
        dataType: new DataTypeNamePropType(),
        dataSource: new EnumType([]),
        filter: new RawDataPropType(() => []),
        customFilters: new ArrayType({
          ...buildCustomFilterProp(true),
        }),
        limit: new StringType(),
        autoRefresh: new BoolType(),
        showPagination: new BoolType(),
      },
      undefined,
      propMapper,
      display,
    );

    this.setExtractPropTypesDependencies(
      (
        prop,
        dependencies,
        elementBaseProps,
        propsShape,
        element,
        dataTypes,
        includeSelf,
      ) =>
        extractDataListDependencies(
          element,
          prop,
          dependencies,
          dataTypes as DataTypes,
          includeSelf,
        ),
    );
  }
}

export default DataListPropType;
