import { HubSpotObjectGroup } from './HubSpotObjectGroup';
import { HubSpotScopeGroup } from './HubSpotScopeGroups';

export enum HubSpotObject {
  // Commerce
  CARTS = 'cart',
  DISCOUNTS = 'discount',
  FEES = 'fee',
  INVOICES = 'invoices',
  LINE_ITEMS = 'line_items',
  ORDERS = 'order',
  PAYMENTS = 'commerce_payments',
  PRODUCTS = 'products',
  QUOTES = 'quotes',
  SUBSCRIPTIONS = 'subscriptions',
  TAXES = 'tax',

  // CRM
  CONTACTS = 'contacts',
  COMPANIES = 'companies',
  DEALS = 'deals',
  FEEDBACK_SUBMISSIONS = 'feedback_submissions',
  GOALS = 'goal_targets',
  LEADS = 'leads',
  TICKETS = 'tickets',

  // HubSpot Users
  //USERS = 'users', Synced as Noloco Users

  // Engagements
  CALLS = 'calls',
  COMMUNICATIONS = 'communications',
  EMAILS = 'emails',
  MEETINGS = 'meetings',
  NOTES = 'notes',
  POSTAL_MAIL = 'postal_mail',
  TASKS = 'tasks',
}

export const HubSpotObjectsByGroup: Record<
  HubSpotObjectGroup,
  HubSpotObject[]
> = {
  [HubSpotObjectGroup.CRM]: [
    HubSpotObject.CONTACTS,
    HubSpotObject.COMPANIES,
    HubSpotObject.DEALS,
    HubSpotObject.FEEDBACK_SUBMISSIONS,
    HubSpotObject.GOALS,
    HubSpotObject.LEADS,
    HubSpotObject.TICKETS,
  ],
  [HubSpotObjectGroup.ENGAGEMENTS]: [
    HubSpotObject.CALLS,
    HubSpotObject.COMMUNICATIONS,
    HubSpotObject.EMAILS,
    HubSpotObject.MEETINGS,
    HubSpotObject.NOTES,
    HubSpotObject.POSTAL_MAIL,
    HubSpotObject.TASKS,
  ],
  [HubSpotObjectGroup.COMMERCE]: [
    HubSpotObject.CARTS,
    HubSpotObject.DISCOUNTS,
    HubSpotObject.FEES,
    HubSpotObject.INVOICES,
    HubSpotObject.LINE_ITEMS,
    HubSpotObject.ORDERS,
    HubSpotObject.PAYMENTS,
    HubSpotObject.PRODUCTS,
    HubSpotObject.QUOTES,
    HubSpotObject.SUBSCRIPTIONS,
    HubSpotObject.TAXES,
  ],
};

export const HubSpotObjectToScopeGroup: Record<
  HubSpotObject,
  HubSpotScopeGroup
> = {
  [HubSpotObject.CARTS]: HubSpotScopeGroup.CARTS,
  [HubSpotObject.DISCOUNTS]: HubSpotScopeGroup.LINE_ITEMS,
  [HubSpotObject.FEES]: HubSpotScopeGroup.LINE_ITEMS,
  [HubSpotObject.INVOICES]: HubSpotScopeGroup.INVOICES,
  [HubSpotObject.LINE_ITEMS]: HubSpotScopeGroup.LINE_ITEMS,
  [HubSpotObject.ORDERS]: HubSpotScopeGroup.ORDERS,
  [HubSpotObject.PAYMENTS]: HubSpotScopeGroup.PAYMENTS,
  [HubSpotObject.PRODUCTS]: HubSpotScopeGroup.PRODUCTS,
  [HubSpotObject.QUOTES]: HubSpotScopeGroup.QUOTES,
  [HubSpotObject.SUBSCRIPTIONS]: HubSpotScopeGroup.SUBSCRIPTIONS,
  [HubSpotObject.TAXES]: HubSpotScopeGroup.LINE_ITEMS,
  [HubSpotObject.CONTACTS]: HubSpotScopeGroup.CONTACTS_AND_ENGAGEMENTS,
  [HubSpotObject.COMPANIES]: HubSpotScopeGroup.COMPANIES,
  [HubSpotObject.DEALS]: HubSpotScopeGroup.DEALS,
  [HubSpotObject.FEEDBACK_SUBMISSIONS]: HubSpotScopeGroup.FEEDBACK_SUBMISSIONS,
  [HubSpotObject.GOALS]: HubSpotScopeGroup.GOALS,
  [HubSpotObject.LEADS]: HubSpotScopeGroup.LEADS,
  [HubSpotObject.TICKETS]: HubSpotScopeGroup.TICKETS,
  [HubSpotObject.CALLS]: HubSpotScopeGroup.CONTACTS_AND_ENGAGEMENTS,
  [HubSpotObject.COMMUNICATIONS]: HubSpotScopeGroup.CONTACTS_AND_ENGAGEMENTS,
  [HubSpotObject.EMAILS]: HubSpotScopeGroup.EMAIL_ENGAGEMENTS,
  [HubSpotObject.MEETINGS]: HubSpotScopeGroup.CONTACTS_AND_ENGAGEMENTS,
  [HubSpotObject.NOTES]: HubSpotScopeGroup.CONTACTS_AND_ENGAGEMENTS,
  [HubSpotObject.POSTAL_MAIL]: HubSpotScopeGroup.CONTACTS_AND_ENGAGEMENTS,
  [HubSpotObject.TASKS]: HubSpotScopeGroup.CONTACTS_AND_ENGAGEMENTS,
};

export const HubSpotScopeGroupToObjects: Record<
  HubSpotScopeGroup,
  HubSpotObject[]
> = Object.entries(HubSpotObjectToScopeGroup).reduce(
  (acc, [object, scopeGroup]) => {
    if (!acc[scopeGroup]) {
      acc[scopeGroup] = [];
    }
    acc[scopeGroup].push(object as HubSpotObject);
    return acc;
  },
  {} as Record<HubSpotScopeGroup, HubSpotObject[]>,
);

export const BUILT_IN_OBJECTS = new Set(Object.values(HubSpotObject));
