import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circle-arrow-left',
  'IconCircleArrowLeft',
  [
    ['path', { d: 'M12 21a9 9 0 1 0 0 -18a9 9 0 0 0 0 18', key: 'svg-0' }],
    ['path', { d: 'M8 12l4 4', key: 'svg-1' }],
    ['path', { d: 'M8 12h8', key: 'svg-2' }],
    ['path', { d: 'M12 8l-4 4', key: 'svg-3' }],
  ],
);
