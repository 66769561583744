import createReactComponent from '../createReactComponent';
export default createReactComponent('adjustments-cog', 'IconAdjustmentsCog', [
  ['path', { d: 'M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M6 4v4', key: 'svg-1' }],
  ['path', { d: 'M6 12v8', key: 'svg-2' }],
  ['path', { d: 'M13.199 14.399a2 2 0 1 0 -1.199 3.601', key: 'svg-3' }],
  ['path', { d: 'M12 4v10', key: 'svg-4' }],
  ['path', { d: 'M12 18v2', key: 'svg-5' }],
  ['path', { d: 'M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-6' }],
  ['path', { d: 'M18 4v1', key: 'svg-7' }],
  ['path', { d: 'M18 9v2.5', key: 'svg-8' }],
  ['path', { d: 'M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-9' }],
  ['path', { d: 'M19.001 15.5v1.5', key: 'svg-10' }],
  ['path', { d: 'M19.001 21v1.5', key: 'svg-11' }],
  ['path', { d: 'M22.032 17.25l-1.299 .75', key: 'svg-12' }],
  ['path', { d: 'M17.27 20l-1.3 .75', key: 'svg-13' }],
  ['path', { d: 'M15.97 17.25l1.3 .75', key: 'svg-14' }],
  ['path', { d: 'M20.733 20l1.3 .75', key: 'svg-15' }],
]);
