import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'building-broadcast-tower',
  'IconBuildingBroadcastTower',
  [
    ['path', { d: 'M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
    ['path', { d: 'M16.616 13.924a5 5 0 1 0 -9.23 0', key: 'svg-1' }],
    ['path', { d: 'M20.307 15.469a9 9 0 1 0 -16.615 0', key: 'svg-2' }],
    ['path', { d: 'M9 21l3 -9l3 9', key: 'svg-3' }],
    ['path', { d: 'M10 19h4', key: 'svg-4' }],
  ],
);
