import React, { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { getColorShade } from '@noloco/components';
import { FILE } from '../../../../constants/builtInDataTypes';
import {
  BOARD,
  CALENDAR,
  CARDS,
  COLUMNS,
  GANTT,
  LAYOUT_SUPPORTS_RECORD_COLORING,
  MAP,
  TIMELINE,
} from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { getColorBasedOnConditions } from '../../../../utils/colors';
import { getImageFilesFromFieldValues } from '../../../../utils/files';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useSelectRecordOnCmdClick from '../../../../utils/hooks/useSelectRecordOnCmdClick';
import CollectionCardGallery from '../CollectionCardGallery';
import CardFieldCell from './CardFieldCell';

const CardRecordLayout = ({
  actionButtons,
  className,
  children,
  dataType,
  elementId,
  element,
  scope,
  fieldConfigs,
  layout,
  record,
  project,
  rowLink,
  showCardHeroImage,
  transformRecordScope,
  bulkActionsEnabled = false,
  isRowChecked = false,
  handleCheckboxChange,
  selectedRows,
  theme,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  const selectRecordOnCmdClick = useSelectRecordOnCmdClick(
    handleCheckboxChange,
    isRowChecked,
    selectedRows,
  );

  const colorConditions = useMemo(
    () => get(element, ['props', 'recordColoring'], []),
    [element],
  );
  const conditionBasedColor = useMemo(
    () => getColorBasedOnConditions(colorConditions, scope, project),
    [colorConditions, scope, project],
  );

  const primaryColor = theme.brandColors.primary;

  const images = useMemo(() => {
    if (!showCardHeroImage) {
      return null;
    }

    const imageFieldConfig = first(fieldConfigs);
    if (!imageFieldConfig || (imageFieldConfig as any).field.type !== FILE) {
      return null;
    }

    // @ts-expect-error TS(2339): Property 'field' does not exist on type 'unknown'.
    const { field, parent } = imageFieldConfig;

    return getImageFilesFromFieldValues(field, record, parent);
  }, [fieldConfigs, record, showCardHeroImage]);

  const Row = rowLink ? Link : 'div';

  const backgroundColor = isDarkModeEnabled
    ? darkModeColors.surfaces.elevation1
    : 'bg-white';

  const hover = `hover:${
    isDarkModeEnabled ? darkModeColors.surfaces.elevation2 : 'bg-gray-100'
  }`;

  const cardBorderColor = isDarkModeEnabled
    ? darkModeColors.borders.one
    : 'border-gray-200';

  return (
    // @ts-expect-error TS(2322): Type '{ children: any[]; to: any; className: strin... Remove this comment to see the full error message
    <Row
      to={rowLink}
      onClick={selectRecordOnCmdClick}
      className={classNames(
        className,
        'group relative flex overflow-hidden',
        {
          'h-full w-full flex-col items-center sm:flex-wrap':
            layout === CARDS || layout === BOARD,
          'relative mb-3 w-80 flex-shrink-0 flex-wrap text-xs sm:w-full':
            layout === COLUMNS,
          [`border ${cardBorderColor} rounded-lg shadow-lg`]:
            layout !== CALENDAR &&
            layout !== TIMELINE &&
            layout !== GANTT &&
            layout !== MAP,
          'flex w-screen max-w-xs flex-col sm:max-w-full': [
            CALENDAR,
            TIMELINE,
            GANTT,
          ].includes(layout),
        },
        {
          [darkModeColors.text.primary]: isDarkModeEnabled,
          [backgroundColor && hover]: !conditionBasedColor,
          [`bg-${getColorShade(
            primaryColor,
            100,
          )} bg-opacity-75 dark:bg-${getColorShade(primaryColor, 900)}`]:
            isRowChecked,
          [`bg-${getColorShade(
            conditionBasedColor,
            50,
          )} hover:bg-${getColorShade(conditionBasedColor, 100)}`]:
            conditionBasedColor &&
            !isDarkModeEnabled &&
            LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout),
          [`bg-${getColorShade(
            conditionBasedColor,
            900,
          )} hover:bg-${getColorShade(conditionBasedColor, 800)}`]:
            conditionBasedColor &&
            isDarkModeEnabled &&
            LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout),
        },
      )}
      data-testid="collection-record"
    >
      {showCardHeroImage && images && (
        // @ts-expect-error TS(2786): 'CollectionCardGallery' cannot be used as a JSX co... Remove this comment to see the full error message
        <CollectionCardGallery
          image={{ hidden: false }}
          images={images}
          index={0}
        />
      )}
      <div className="'w-full flex w-full flex-col space-y-4 p-4 text-xs">
        {children}
        {fieldConfigs.map(
          (
            { field, parent, parentFieldType, config, permissions }: any,
            index: any,
          ) =>
            (index > 0 || !(showCardHeroImage && images)) && (
              <CardFieldCell
                // @ts-expect-error TS(2322): Type '{ config: any; dataType: any; elementId: any... Remove this comment to see the full error message
                config={config}
                dataType={parentFieldType || dataType}
                elementId={elementId}
                field={field}
                key={`${field.name}:${(parentFieldType || dataType).name}`}
                parent={parent}
                permissions={permissions}
                record={record}
                project={project}
                transformRecordScope={transformRecordScope}
                bulkActionsEnabled={bulkActionsEnabled}
                isRowChecked={isRowChecked}
                selectedRows={selectedRows}
              />
            ),
        )}
      </div>
      {actionButtons}
    </Row>
  );
};

export default withTheme(CardRecordLayout);
