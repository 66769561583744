import React, { useLayoutEffect } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import reverse from 'lodash/fp/reverse';
import { Loader, Theme, getColorShade } from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import MessagesIcon from '../../../img/MessagesIcon';
import { PageInfo } from '../../../models/Record';
import { getText } from '../../../utils/lang';
import RecordComment from '../view/RecordComment';

const LANG_KEY = 'core.COMMENT';

type RecordCommentsBodyProps = {
  canDelete?: boolean;
  className?: string;
  comments: any[];
  isDarkModeEnabled: boolean;
  loaderRef: any;
  loading: boolean;
  onDelete?: (comment: any) => () => Promise<void>;
  pageInfo: PageInfo;
  theme: Theme;
};

const RecordCommentsBody = ({
  canDelete,
  className,
  comments,
  isDarkModeEnabled,
  loaderRef,
  loading,
  onDelete,
  pageInfo,
  theme,
}: RecordCommentsBodyProps) => {
  const scrollRef = React.useRef<null | HTMLDivElement>(null);
  const scrollHeight = scrollRef.current?.scrollHeight;
  useLayoutEffect(
    () =>
      scrollRef.current?.scrollTo({
        behavior: 'smooth',
        top: scrollHeight,
      }),
    [scrollHeight, comments.length],
  );

  return (
    <div
      className={classNames(className, 'mb-2 flex h-full flex-grow flex-col')}
    >
      {comments.length > 0 && (
        <div
          className={`mx-auto flex h-full max-h-full w-full flex-col overflow-y-auto p-0 ${
            isDarkModeEnabled ? darkModeColors.text.primary : ''
          }`}
          ref={scrollRef}
        >
          <div className="comment-scroll-container relative space-y-1 px-1 py-4">
            {pageInfo && pageInfo.hasNextPage && (
              <div className="flex w-full justify-center py-4" ref={loaderRef}>
                <Loader size="sm" />
              </div>
            )}
            {reverse(comments).map((comment: any) => (
              <RecordComment
                canDelete={!!canDelete}
                comment={comment}
                key={comment.id}
                onDelete={onDelete ? onDelete(comment) : undefined}
              />
            ))}
          </div>
        </div>
      )}
      {!loading && comments.length === 0 && (
        <div className="flex h-full w-full flex-col items-center justify-center py-16 text-center">
          <MessagesIcon
            className="mb-8 w-36"
            text={[getColorShade(theme.brandColors.primary, 400)]}
            isDarkModeEnabled={isDarkModeEnabled}
          />
          <h2
            className={`text-base font-medium ${
              isDarkModeEnabled ? darkModeColors.text.primary : 'text-gray-800'
            }`}
          >
            {getText(LANG_KEY, 'empty.title')}
          </h2>
        </div>
      )}
      {loading && comments.length === 0 && (
        <div className="flex h-full w-full items-center justify-center py-16">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default withTheme(RecordCommentsBody) as React.FC<
  Omit<RecordCommentsBodyProps, 'theme'>
>;
