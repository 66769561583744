import createReactComponent from '../createReactComponent';
export default createReactComponent('cloud-code', 'IconCloudCode', [
  [
    'path',
    {
      d: 'M11 18.004h-4.343c-2.572 -.004 -4.657 -2.011 -4.657 -4.487c0 -2.475 2.085 -4.482 4.657 -4.482c.393 -1.762 1.794 -3.2 3.675 -3.773c1.88 -.572 3.956 -.193 5.444 1c1.488 1.19 2.162 3.007 1.77 4.769h.99a3.468 3.468 0 0 1 3.307 2.444',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-1' }],
  ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-2' }],
]);
