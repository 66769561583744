import createReactComponent from '../createReactComponent';
export default createReactComponent('arrow-merge', 'IconArrowMerge', [
  ['path', { d: 'M8 7l4 -4l4 4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 3v5.394a6.737 6.737 0 0 1 -3 5.606a6.737 6.737 0 0 0 -3 5.606v1.394',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M12 3v5.394a6.737 6.737 0 0 0 3 5.606a6.737 6.737 0 0 1 3 5.606v1.394',
      key: 'svg-2',
    },
  ],
]);
