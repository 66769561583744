import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrows-right-down',
  'IconArrowsRightDown',
  [
    ['path', { d: 'M3 17l4 4l4 -4', key: 'svg-0' }],
    ['path', { d: 'M7 21v-11a3 3 0 0 1 3 -3h11', key: 'svg-1' }],
    ['path', { d: 'M17 11l4 -4l-4 -4', key: 'svg-2' }],
  ],
);
