import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-vscode', 'IconBrandVscode', [
  ['path', { d: 'M16 3v18l4 -2.5v-13z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9.165 13.903l-4.165 3.597l-2 -1l4.333 -4.5m1.735 -1.802l6.932 -7.198v5l-4.795 4.141',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 16.5l-11 -10l-2 1l13 13.5', key: 'svg-2' }],
]);
