import React, {
  Suspense,
  forwardRef,
  lazy,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IconDotsVertical, IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import initial from 'lodash/initial';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  HorizontalNav,
  HorizontalNavItem,
  Loader,
  Popover,
} from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import {
  ElementRenderer,
  getElementPath,
} from '@noloco/ui/src/components/canvas/ProjectRenderer';
import { generateNewElementFromNodeShape } from '@noloco/ui/src/utils/elements';
import withVisibilityRules from '../../components/canvas/withVisibilityRules';
import { PAGE } from '../../constants/elements';
import {
  setSelectedElement,
  setSelectedPagePath,
} from '../../reducers/elements';
import {
  selectedElementPathSelector,
  selectedPagePathSelector,
} from '../../selectors/elementsSelectors';
import { NEW_PAGE_SAVED, trackEvent } from '../../utils/analytics';
import useRouter from '../../utils/hooks/useRouter';
import { getText } from '../../utils/lang';
import { replaceDoubleSlashes } from '../../utils/pages';
import DummyBlock from '../DummyBlock';
import Icon from '../Icon';

const LazyPageEditor = lazy(() => import('../../components/editor/PageEditor'));

type TabsProps = {
  useAddChild: (...args: any[]) => any;
  useUpdateProperty: (...args: any[]) => any;
};

const Tabs = forwardRef<any, TabsProps>(
  (
    {
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'TabsP... Remove this comment to see the full error message
      className,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Tabs... Remove this comment to see the full error message
      editorMode,
      // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type 'Tab... Remove this comment to see the full error message
      elementPath,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'TabsPro... Remove this comment to see the full error message
      onClick,
      // @ts-expect-error TS(2339): Property 'tabType' does not exist on type 'TabsPro... Remove this comment to see the full error message
      tabType,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type 'TabsPro... Remove this comment to see the full error message
      project,
      // @ts-expect-error TS(2339): Property 'scope' does not exist on type 'TabsProps... Remove this comment to see the full error message
      scope,
      useAddChild,
      useUpdateProperty,
    },
    ref,
  ) => {
    const {
      match,
      location: { pathname },
    } = useRouter();

    const dispatch = useDispatch();
    const selectedPagePath = useSelector(selectedPagePathSelector);
    const selectedPath = useSelector(selectedElementPathSelector);

    const childPagesPathStr = 'children.0.children';
    const childPagesPath = childPagesPathStr.split('.');
    const [editingPage, setEditingPage] = useState(null);
    const [updateProperty] = useUpdateProperty(selectedPath, project);
    const debouncedUpdateProperty = debounce(updateProperty, 1500);
    const [addTab] = useAddChild(
      project,
      [...elementPath, ...initial(childPagesPath)],
      ['children'],
    );

    const onEditSelectPage = (id: any, path: any) => {
      dispatch(setSelectedElement(path));
      dispatch(setSelectedPagePath([...rootPagePath, id]));
      setEditingPage(id);
    };

    const element = get(project.elements, elementPath);
    const childPages = get(element, childPagesPathStr, []);

    const rootPagePath = useMemo(() => {
      if (editorMode) {
        for (let i = 0; i < childPages.length; i++) {
          const childPage = childPages[i];
          const index = selectedPagePath.indexOf(childPage.id);
          if (index >= 0) {
            return selectedPagePath.slice(0, index);
          }
        }
      }

      return selectedPagePath;
    }, [editorMode, childPages, selectedPagePath]);

    const onAddTab = (pageProps = {}) => {
      trackEvent(NEW_PAGE_SAVED);
      const pageName = getText(
        { count: childPages.length + 1 },
        'elements.TABS.new',
      );
      const newPage = generateNewElementFromNodeShape({
        type: PAGE,
        props: {
          name: pageName,
          routePath: pageName.toLowerCase().replace(/\s/g, '-'),
          ...pageProps,
        },
      });
      addTab(newPage);
      setEditingPage(newPage.id);
      dispatch(
        setSelectedElement([
          ...elementPath,
          ...childPagesPath,
          childPages.length,
        ]),
      );
      dispatch(setSelectedPagePath([...rootPagePath, newPage.id]));
    };

    const selectedTabIndex = useMemo(
      () =>
        childPages.findIndex(
          (page: any) =>
            pathname &&
            get(page, 'props.routePath') &&
            pathname.endsWith(get(page, 'props.routePath')),
        ),
      [childPages, pathname],
    );

    useEffect(() => {
      if (
        editorMode &&
        !childPages.some((child: any) => selectedPagePath.includes(child.id))
      ) {
        const firstPage = first(childPages);
        if (firstPage) {
          dispatch(
            setSelectedPagePath([...selectedPagePath, (firstPage as any).id]),
          );
        }
      }
    }, [childPages, dispatch, editorMode, selectedPagePath]);

    const firstTab = first(childPages);

    return (
      <div className={classNames(className)} ref={ref} onClick={onClick}>
        <HorizontalNav type={tabType}>
          {childPages.map((tab: any, index: any) => {
            const to = replaceDoubleSlashes(
              `${match.path}/${tab.props.routePath}`,
            );
            const active = pathname.startsWith(to);
            const NavItemWithVisibilityRules = withVisibilityRules(
              HorizontalNavItem,
              editorMode,
              tab.visibilityRules,
            );
            const tabElementPath = [...elementPath, ...childPagesPath, index];
            const modifiedTab = set('props.isSubPage', true, tab);
            return (
              <NavItemWithVisibilityRules
                className={classNames('group', `tab-${tab.id}`)}
                is={Link}
                active={active}
                onClick={() => {
                  if (editorMode) {
                    onEditSelectPage(tab.id, tabElementPath);
                  }
                }}
                key={tab.id}
                project={project}
                to={to}
              >
                <Popover
                  placement="bottom"
                  trigger="none"
                  disabled={editingPage !== tab.id || !editorMode}
                  isOpen={editingPage === tab.id}
                  onOpenChange={(isOpen: any) => {
                    if (!isOpen) {
                      setEditingPage(null);
                    }
                  }}
                  rounded="lg"
                  bg="white"
                  surface={DARK}
                  key={tab.id}
                  content={
                    <div
                      className="flex w-64 flex-col"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {editorMode && (
                        <Suspense fallback={<Loader />}>
                          <LazyPageEditor
                            cloneable={false}
                            project={project}
                            debouncedUpdateProperty={debouncedUpdateProperty}
                            element={modifiedTab}
                            elementPath={tabElementPath}
                            pagePath={[...rootPagePath, tab.id]}
                            onSetEditing={(newPageId: any) => {
                              setEditingPage(newPageId);
                              if (!newPageId) {
                                setTimeout(() => {
                                  dispatch(setSelectedPagePath(rootPagePath));
                                }, 100);
                              }
                            }}
                            updateProperty={updateProperty}
                          />
                        </Suspense>
                      )}
                    </div>
                  }
                >
                  <div className="flex items-center">
                    {tab.props.icon && (
                      <Icon
                        icon={tab.props.icon}
                        className="mr-2 h-6 w-6 opacity-75"
                      />
                    )}
                    <span className="mr-2 whitespace-nowrap">
                      {tab.props.name}
                    </span>
                    {editorMode && (
                      <div
                        className={classNames(
                          'ml-2 items-center opacity-75',
                          editingPage !== tab.id &&
                            !selectedPagePath.includes(tab.id)
                            ? 'hidden'
                            : 'flex',
                        )}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          onEditSelectPage(tab.id, [
                            ...elementPath,
                            ...childPagesPath,
                            index,
                          ]);
                        }}
                      >
                        <IconDotsVertical size={12} />
                      </div>
                    )}
                  </div>
                </Popover>
              </NavItemWithVisibilityRules>
            );
          })}
          {editorMode && (
            <HorizontalNavItem onClick={() => onAddTab()}>
              <div className="flex items-center">
                <IconPlus className="mr-2 h-6 w-6 opacity-75" />
                <span>{getText('elements.TABS.add')}</span>
              </div>
            </HorizontalNavItem>
          )}
        </HorizontalNav>
        <div className="mt-4 flex flex-col">
          <Switch>
            {childPages.map((childPage: any, index: any) => (
              <Route
                key={childPage.id}
                path={`${match.path}/${childPage.props.routePath}`}
              >
                {get(childPage, 'children', []).map(
                  (subPageChild: any, childIndex: any) => (
                    <ElementRenderer
                      editorMode={editorMode}
                      element={subPageChild}
                      elementPath={getElementPath(
                        [...elementPath, ...childPagesPath, index, 'children'],
                        childIndex,
                      )}
                      key={subPageChild.id}
                      index={childIndex}
                      project={project}
                      scope={scope}
                    />
                  ),
                )}
              </Route>
            ))}
            {firstTab && (
              <Redirect
                to={`${match.path}/${(firstTab as any).props.routePath}`}
              />
            )}
          </Switch>
          {editorMode && selectedTabIndex >= 0 && (
            <DummyBlock
              elementPath={[
                ...elementPath,
                ...childPagesPath,
                selectedTabIndex,
              ]}
            />
          )}
        </div>
      </div>
    );
  },
);

const nullIdentity = () => null;

Tabs.defaultProps = {
  useAddChild: () => [nullIdentity, null],
  useUpdateProperty: () => [nullIdentity, null],
};

export default Tabs;
