import createReactComponent from '../createReactComponent';
export default createReactComponent('user-edit', 'IconUserEdit', [
  ['path', { d: 'M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0', key: 'svg-0' }],
  ['path', { d: 'M6 21v-2a4 4 0 0 1 4 -4h3.5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z',
      key: 'svg-2',
    },
  ],
]);
