import React from 'react';
import { darkModeColors } from '../../../../constants/darkModeColors';

const CalendarLabel = ({ label }: { label: string }) => (
  <div
    className={`py-3 text-xs font-medium tracking-wider text-gray-600 dark:${darkModeColors.text.secondary}`}
  >
    {label}
  </div>
);

export default CalendarLabel;
