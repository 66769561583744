import createReactComponent from '../createReactComponent';
export default createReactComponent('binary-tree-2', 'IconBinaryTree2', [
  ['path', { d: 'M14 6a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-0' }],
  ['path', { d: 'M7 14a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-1' }],
  ['path', { d: 'M21 14a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-2' }],
  ['path', { d: 'M14 18a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-3' }],
  ['path', { d: 'M12 8v8', key: 'svg-4' }],
  ['path', { d: 'M6.316 12.496l4.368 -4.992', key: 'svg-5' }],
  ['path', { d: 'M17.684 12.496l-4.366 -4.99', key: 'svg-6' }],
]);
