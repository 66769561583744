import { shouldOperatorHaveValue } from './operator';

export const conditionsAreValid = (conditions: any) =>
  Array.isArray(conditions) &&
  conditions.length > 0 &&
  conditions.every(
    (conditionBranch) =>
      Array.isArray(conditionBranch) &&
      conditionBranch.length > 0 &&
      conditionBranch.every(
        (condition) =>
          condition.field &&
          condition.operator &&
          (!shouldOperatorHaveValue(condition.operator) || condition.value),
      ),
  );
