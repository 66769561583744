import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-threejs', 'IconBrandThreejs', [
  ['path', { d: 'M8 22l-5 -19l19 5.5z', key: 'svg-0' }],
  [
    'path',
    { d: 'M12.573 17.58l-6.152 -1.576l8.796 -9.466l1.914 6.64', key: 'svg-1' },
  ],
  ['path', { d: 'M12.573 17.58l-1.573 -6.58l6.13 2.179', key: 'svg-2' }],
  ['path', { d: 'M9.527 4.893l1.473 6.107l-6.31 -1.564z', key: 'svg-3' }],
]);
