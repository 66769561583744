import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrows-double-sw-ne',
  'IconArrowsDoubleSwNe',
  [
    ['path', { d: 'M14 3l-11 11', key: 'svg-0' }],
    ['path', { d: 'M3 10v4h4', key: 'svg-1' }],
    ['path', { d: 'M17 10h4v4', key: 'svg-2' }],
    ['path', { d: 'M10 21l11 -11', key: 'svg-3' }],
  ],
);
