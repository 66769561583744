import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-watch-code',
  'IconDeviceWatchCode',
  [
    [
      'path',
      {
        d: 'M11 18h-2a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v4',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-1' }],
    ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-2' }],
    ['path', { d: 'M9 18v3h3', key: 'svg-3' }],
    ['path', { d: 'M9 6v-3h6v3', key: 'svg-4' }],
  ],
);
