import { useCallback, useState } from 'react';
import { DataType } from '../../models/DataTypes';
import { Project } from '../../models/Project';
import { BaseRecord } from '../../models/Record';
import { getText } from '../lang';
import { useGraphQlErrorAlert } from './useAlerts';
import useRecordCommentMutations from './useRecordCommentMutations';

const canSubmit = (cleanText: any, filesToSend: any) =>
  (cleanText && cleanText.trim()) || filesToSend.length > 0;

type RecordCommentInputProps = {
  dataType: DataType;
  record: BaseRecord;
  project: Project;
  note?: boolean;
};

const useRecordCommentInput = ({
  dataType,
  record,
  project,
  note = false,
}: RecordCommentInputProps) => {
  const [sending, setSending] = useState(false);
  const [sendNote, setSendNote] = useState(note);
  const [filesToSend, setFilesToSend] = useState([]);
  const [commentText, setCommentText] = useState('');

  const errorAlert = useGraphQlErrorAlert();

  const { createComment, deleteComment, context } = useRecordCommentMutations({
    dataType,
    project,
  });

  const onChange = useCallback(
    (nextValue: any) => {
      setCommentText(nextValue);
    },
    [setCommentText],
  );

  const onSend = useCallback(
    async (event: Event) => {
      event.preventDefault();
      if (!sending) {
        setSending(true);
      }

      const currentValue = commentText
        // @ts-expect-error TS(2550): Property 'replaceAll' does not exist on type 'stri... Remove this comment to see the full error message
        .replaceAll('<br>', '\n')
        .replaceAll('&nbsp;', ' ')
        .trim();

      let commentResult;
      if (canSubmit(currentValue, filesToSend)) {
        try {
          commentResult = await createComment(
            record.id,
            currentValue,
            filesToSend.map((file) => file[0]),
            sendNote,
          );
        } catch (error) {
          errorAlert(getText('core.MESSAGING.errors.send'), error);
          setSending(false);
        }
      }
      setCommentText('');

      setFilesToSend([]);
      setSending(false);

      return commentResult;
    },
    [
      commentText,
      createComment,
      errorAlert,
      filesToSend,
      record.id,
      sendNote,
      sending,
    ],
  );

  return {
    sending,
    sendNote,
    setSendNote,
    filesToSend,
    setFilesToSend,
    commentText,
    errorAlert,
    deleteComment,
    context,
    onChange,
    onSend,
  };
};

export default useRecordCommentInput;
