import createReactComponent from '../createReactComponent';
export default createReactComponent('needle', 'IconNeedle', [
  [
    'path',
    {
      d: 'M3 21c-.667 -.667 3.262 -6.236 11.785 -16.709a3.5 3.5 0 1 1 5.078 4.791c-10.575 8.612 -16.196 12.585 -16.863 11.918z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17.5 6.5l-1 1', key: 'svg-1' }],
]);
