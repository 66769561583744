import createReactComponent from '../createReactComponent';
export default createReactComponent('binary', 'IconBinary', [
  ['path', { d: 'M11 10v-5h-1m8 14v-5h-1', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15 5m0 .5a.5 .5 0 0 1 .5 -.5h2a.5 .5 0 0 1 .5 .5v4a.5 .5 0 0 1 -.5 .5h-2a.5 .5 0 0 1 -.5 -.5z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M10 14m0 .5a.5 .5 0 0 1 .5 -.5h2a.5 .5 0 0 1 .5 .5v4a.5 .5 0 0 1 -.5 .5h-2a.5 .5 0 0 1 -.5 -.5z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M6 10h.01m-.01 9h.01', key: 'svg-3' }],
]);
