import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-days-counter',
  'IconBrandDaysCounter',
  [
    ['path', { d: 'M20.779 10.007a9 9 0 1 0 -10.77 10.772', key: 'svg-0' }],
    ['path', { d: 'M13 21h8v-7', key: 'svg-1' }],
    ['path', { d: 'M12 8v4l3 3', key: 'svg-2' }],
  ],
);
