import createReactComponent from '../createReactComponent';
export default createReactComponent('message-bolt', 'IconMessageBolt', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M13 18l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-3' }],
]);
