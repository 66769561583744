import React from 'react';
import { Surface } from '../../../models';
import FormField from '../../form/FormField';

type PasswordInputProps = {
  errorMessage: string;
  label: JSX.Element | string;
  onChange: (value: string) => void;
  value: string | undefined;
  surface: Surface;
};

const PasswordInput = ({
  errorMessage,
  label,
  onChange,
  value,
  surface,
}: PasswordInputProps) => (
  <FormField
    aria-label="Password"
    autoComplete="current-password"
    className="mt-3"
    name="password"
    type="password"
    onChange={({ target: { value } }: any) => onChange(value)}
    required
    errorType="below-solid"
    label={label}
    placeholder=""
    errorMessage={errorMessage}
    surface={surface}
    value={value}
  />
);

export default PasswordInput;
