import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-back-up-double',
  'IconArrowBackUpDouble',
  [
    ['path', { d: 'M13 14l-4 -4l4 -4', key: 'svg-0' }],
    ['path', { d: 'M8 14l-4 -4l4 -4', key: 'svg-1' }],
    ['path', { d: 'M9 10h7a4 4 0 1 1 0 8h-1', key: 'svg-2' }],
  ],
);
