import createReactComponent from '../createReactComponent';
export default createReactComponent('building-cottage', 'IconBuildingCottage', [
  ['path', { d: 'M3 21l18 0', key: 'svg-0' }],
  [
    'path',
    { d: 'M4 21v-11l2.5 -4.5l5.5 -2.5l5.5 2.5l2.5 4.5v11', key: 'svg-1' },
  ],
  ['path', { d: 'M12 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M9 21v-5a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v5', key: 'svg-3' }],
]);
