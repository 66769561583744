import {
  MULTIPLE_OPTION,
  ROLLUP,
  SINGLE_OPTION,
} from '../../constants/dataTypes';
import { getText } from '../lang';
import { getDependenciesWithErrors } from './formulaDependencies';

const ERROR_LANG_KEY = 'errors.formula';

export const TYPES_INELIGIBLE_FOR_FORMULAS = [
  MULTIPLE_OPTION,
  ROLLUP,
  SINGLE_OPTION,
];

export const isValidFormulaOutputType = (formulaOutputType: any) =>
  !TYPES_INELIGIBLE_FOR_FORMULAS.includes(formulaOutputType);

export const isFormulaValid = (
  formula: any,
  dataTypeFields: any,
  explicitDependencies: any,
) => {
  if (!formula || formula.length === 0) {
    return {
      error: getText(ERROR_LANG_KEY, 'empty'),
      valid: false,
    };
  }

  const formulaDependencies = getDependenciesWithErrors(formula);

  if (formulaDependencies.error || !formulaDependencies.valid) {
    return formulaDependencies;
  }
  let invalidDependencies = formulaDependencies.dependencies.filter(
    (dependency: any) => !explicitDependencies.includes(dependency),
  );
  if (invalidDependencies.length > 0) {
    return {
      valid: false,
      error: getText(ERROR_LANG_KEY, 'invalidFormula'),
    };
  }
  const eligibleFields = dataTypeFields
    .filter((field: any) => !field.typeOptions || !field.typeOptions.formula)
    .map(({ name }: any) => name);

  invalidDependencies = formulaDependencies.dependencies.filter(
    (dependency: any) => !eligibleFields.includes(dependency),
  );

  if (invalidDependencies.length > 0) {
    return {
      valid: false,
      error: getText(ERROR_LANG_KEY, 'invalidFormula'),
    };
  }
  return { valid: true };
};
