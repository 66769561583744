import React from 'react';
import classNames from 'classnames';
import Loader from '@noloco/components/src/components/loading/Loader';
import { LG } from '@noloco/components/src/constants/tShirtSizes';

const AdminLoadableFallback = (options: { bgShade?: 700 | 800 } = {}) => (
  <div
    className={classNames(
      `flex h-full w-full flex-grow items-center justify-center p-6 text-center text-white`,
      {
        'bg-slate-700': options.bgShade === 700,
        'bg-slate-800': options.bgShade !== 700,
      },
    )}
  >
    <Loader size={LG} />
  </div>
);

export default AdminLoadableFallback;
