import createReactComponent from '../createReactComponent';
export default createReactComponent('sql', 'IconSql', [
  [
    'path',
    {
      d: 'M12 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 8v8h4', key: 'svg-1' }],
  ['path', { d: 'M13 15l1 1', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M3 15a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1',
      key: 'svg-3',
    },
  ],
]);
