import createReactComponent from '../createReactComponent';
export default createReactComponent('bucket', 'IconBucket', [
  ['path', { d: 'M12 7m-8 0a8 4 0 1 0 16 0a8 4 0 1 0 -16 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M4 7c0 .664 .088 1.324 .263 1.965l2.737 10.035c.5 1.5 2.239 2 5 2s4.5 -.5 5 -2c.333 -1 1.246 -4.345 2.737 -10.035a7.45 7.45 0 0 0 .263 -1.965',
      key: 'svg-1',
    },
  ],
]);
