import { BUTTON, LINK } from './elements';

export const H1 = 'h1';
export const H2 = 'h2';
export const H3 = 'h3';
export const H4 = 'h4';
export const H5 = 'h5';
export const H6 = 'h6';
export const PARAGRAPH = 'p';
export const BOLD = 'b';
export const SPAN = 'span';
export const BLOCK_QUOTE = 'blockquote';

export const textTypeIcons = {
  [H1]: 'H1',
  [H2]: 'H2',
  [H3]: 'H3',
  [H4]: 'H4',
  [H5]: 'H5',
  [H6]: 'H6',
  [PARAGRAPH]: 'AlignJustified',
  [BOLD]: 'Bold',
  [SPAN]: 'Space',
  [BLOCK_QUOTE]: 'Blockquote',
  [LINK]: 'Link',
  [BUTTON]: 'BoxPadding',
};

const textTypes = [H1, H2, H3, H4, H5, H6, PARAGRAPH, BOLD, SPAN, BLOCK_QUOTE];

export type TextType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'b'
  | 'span'
  | 'blockquote';

export default textTypes;
