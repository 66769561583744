import createReactComponent from '../createReactComponent';
export default createReactComponent('lighter', 'IconLighter', [
  [
    'path',
    {
      d: 'M10 3v16a2 2 0 0 0 2 2h5a2 2 0 0 0 2 -2v-7h-12a2 2 0 0 1 -2 -2v-5a2 2 0 0 1 2 -2h3z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M16 4l1.465 1.638a2 2 0 1 1 -3.015 .099l1.55 -1.737z', key: 'svg-1' },
  ],
]);
