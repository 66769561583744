export const CMD: ShortcutKeyType = 'cmd';
export const COMMA: ShortcutKeyType = ',';
export const ENTER: ShortcutKeyType = 'Enter';
export const ESCAPE: ShortcutKeyType = 'Escape';
export const KEY_2: ShortcutKeyType = '2';
export const KEY_ARROW_DOWN: ShortcutKeyType = 'ArrowDown';
export const KEY_ARROW_UP: ShortcutKeyType = 'ArrowUp';
export const KEY_BACKSPACE: ShortcutKeyType = 'Backspace';
export const KEY_D: ShortcutKeyType = 'd';
export const KEY_E: ShortcutKeyType = 'e';
export const KEY_G: ShortcutKeyType = 'g';
export const KEY_M: ShortcutKeyType = 'm';
export const KEY_SLASH: ShortcutKeyType = '/';
export const KEY_SQUARE_BRACKETS_CLOSE: ShortcutKeyType = ']';
export const KEY_T: ShortcutKeyType = 't';
export const KEY_U: ShortcutKeyType = 'u';
export const KEY_W: ShortcutKeyType = 'w';
export const KEY_X: ShortcutKeyType = 'x';
export const KEY_Z: ShortcutKeyType = 'z';
export const PERIOD: ShortcutKeyType = '.';
export const SHIFT: ShortcutKeyType = 'shift';

export type ShortcutKeyType =
  | ','
  | '.'
  | ']'
  | '/'
  | '2'
  | 'ArrowDown'
  | 'ArrowUp'
  | 'Backspace'
  | 'cmd'
  | 'd'
  | 'e'
  | 'Enter'
  | 'Escape'
  | 'g'
  | 'm'
  | 'shift'
  | 't'
  | 'u'
  | 'w'
  | 'x'
  | 'z';
