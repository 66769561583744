import React, { useCallback, useMemo, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import classNames from 'classnames';
import gql from 'graphql-tag';
import { useSelector } from 'react-redux';
import { Modal } from '@noloco/components';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import commentNotificationTypes, {
  CommentNotificationType,
  REPLIES,
} from '../constants/commentNotificationTypes';
import RadioInputGroup from '../elements/Radio';
import { UPDATE_NOTIFICATION_PREFERENCES } from '../queries/notifications';
import { projectNameSelector } from '../selectors/projectSelectors';
import { authWrapperQuerySelector } from '../selectors/queriesSelectors';
import { updateCurrentUserCacheItem } from '../utils/apolloCache';
import useAuthWrapper from '../utils/hooks/useAuthWrapper';
import { getText } from '../utils/lang';

type Props = {
  children?: any;
  onClose: () => void;
};

const LANG_KEY = 'notificationPreferences';

const NotificationPreferencesModal = ({ children, onClose }: Props) => {
  const { sm: isSmScreen } = useBreakpoints();
  const apolloClient = useApolloClient();
  const projectName = useSelector(projectNameSelector);
  const { user, isGhost } = useAuthWrapper();
  const authWrapperQs = useSelector(authWrapperQuerySelector);
  const authWrapperQuery = useMemo(
    () => gql`
      ${authWrapperQs}
    `,
    [authWrapperQs],
  );

  const [value, setValue] = useState<CommentNotificationType>(
    (user?._commentNotificationPreference ??
      REPLIES) as CommentNotificationType,
  );

  const [updateNotificationPreferences, { loading }] = useMutation(
    UPDATE_NOTIFICATION_PREFERENCES,
    {
      context: { projectQuery: true, projectName: projectName },
    },
  );

  const commentNotificationOptions = useMemo(
    () =>
      commentNotificationTypes.map((type) => ({
        label: getText(LANG_KEY, 'comments.types', type, 'label'),
        help: getText(LANG_KEY, 'comments.types', type, 'help'),
        value: type,
      })),
    [],
  );

  const onChange = useCallback(
    (type: CommentNotificationType) => {
      setValue(type);
      updateNotificationPreferences({
        variables: { comments: type },
      }).then(() => {
        updateCurrentUserCacheItem(
          { _commentNotificationPreference: type },
          user,
          isGhost,
          apolloClient,
          authWrapperQuery,
        );
      });
    },
    [
      apolloClient,
      authWrapperQuery,
      isGhost,
      updateNotificationPreferences,
      user,
    ],
  );

  return (
    <Modal
      className={classNames({ 'bg-white dark:bg-gray-800': isSmScreen })}
      loading={loading}
      onCancel={() => onClose()}
      onClose={() => onClose()}
      onConfirm={() => onClose()}
      confirmText={getText(LANG_KEY, 'done')}
      title={getText(LANG_KEY, 'label')}
    >
      {children}
      <h2 className="font-bold">{getText(LANG_KEY, 'comments.title')}</h2>
      <p className="mb-4 text-xs">
        {getText(LANG_KEY, 'comments.description')}
      </p>
      <RadioInputGroup
        options={commentNotificationOptions}
        name="comment-notification-types"
        value={value}
        onChange={onChange}
      />
    </Modal>
  );
};

NotificationPreferencesModal.displayName = 'NotificationConsentModal';

export default NotificationPreferencesModal;
