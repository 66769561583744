import createReactComponent from '../createReactComponent';
export default createReactComponent('car-off', 'IconCarOff', [
  ['path', { d: 'M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M15.584 15.588a2 2 0 0 0 2.828 2.83', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M5 17h-2v-6l2 -5h1m4 0h4l4 5h1a2 2 0 0 1 2 2v4m-6 0h-6m-6 -6h8m4 0h3m-6 -3v-2',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
