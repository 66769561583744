import createReactComponent from '../createReactComponent';
export default createReactComponent('currency-lyd', 'IconCurrencyLyd', [
  ['path', { d: 'M11 15h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M21 5v10a2 2 0 0 1 -2 2h-2.764a2 2 0 0 1 -1.789 -1.106l-.447 -.894',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M5 8l2.773 4.687c.427 .697 .234 1.626 -.43 2.075a1.38 1.38 0 0 1 -.773 .238h-2.224a.93 .93 0 0 1 -.673 -.293l-.673 -.707',
      key: 'svg-2',
    },
  ],
]);
