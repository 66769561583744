import { AUTO_RELATIONSHIP_FIELD_TYPES } from '../constants/autoRelationships';
import { DataField } from '../models/DataTypeFields';
import { filterDefaultFields, isIdField } from './defaultFields';

export const canBeAutoRelationshipLookupFieldOption = (field: DataField) =>
  !field.relationship &&
  !field.relatedField &&
  AUTO_RELATIONSHIP_FIELD_TYPES.includes(field.type) &&
  (isIdField(field) || filterDefaultFields(field));
export const canBeAutoRelationshipSourceFieldOption = (field: DataField) =>
  !field.rollup &&
  !field.lookup &&
  !field.typeOptions?.formula &&
  canBeAutoRelationshipLookupFieldOption(field);
