import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'chart-infographic',
  'IconChartInfographic',
  [
    ['path', { d: 'M7 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-0' }],
    ['path', { d: 'M7 3v4h4', key: 'svg-1' }],
    ['path', { d: 'M9 17l0 4', key: 'svg-2' }],
    ['path', { d: 'M17 14l0 7', key: 'svg-3' }],
    ['path', { d: 'M13 13l0 8', key: 'svg-4' }],
    ['path', { d: 'M21 12l0 9', key: 'svg-5' }],
  ],
);
