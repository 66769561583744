import get from 'lodash/get';
import actionDataItems from '../constants/baseActions';
import { FORMULA } from '../constants/dataTypes';
import {
  ARRAY,
  COMBO,
  DATA_PROP,
  GROUP,
  KEY_MAP,
  RAW_DATA_PROP,
  STRING,
  VARIABLE,
} from '../constants/elementPropTypeTypes';
import * as elements from '../constants/elements';
import { PAGE_FIELD } from '../constants/scopeTypes';
import baseElementsConfig from '../elements/baseElementConfig';
import BaseElementConfig from '../models/BaseElementConfig';
import DataTypes, { DataType } from '../models/DataTypes';
import StringPropType from '../models/elementPropTypes/StringPropType';
import { QueryObject } from '../queries/data';
import { ensureArray } from './arrays';
import {
  getConditionDataItems,
  getDepsForField,
  getVisibilityCustomRulesDataItems,
  transformDepsToQueryObject,
} from './data';
import { removeEdgesNodeFromPath } from './queries';
import { RECORD_SCOPE } from './scope';

const isNotListDep = (value: any) =>
  !value.path || !value.path.startsWith('edges.node');

const getDataDepsFromValue = (value: any): any[] => {
  if (!value || !Array.isArray(value)) {
    return [];
  }
  return value
    .filter(({ data }) => !!data)
    .filter(
      (item) =>
        item.data.source !== PAGE_FIELD &&
        isNotListDep(item.data) &&
        item.data.id,
    )
    .reduce((itemAcc, item) => {
      if (item.data.dataType === FORMULA) {
        return [...itemAcc, ...getDataDepsFromValue(item.formula)];
      }
      return [...itemAcc, item.data];
    }, []);
};

const getActionDataItems = (actions: any) =>
  ensureArray(actions).reduce((depsAcc: any, action: any) => {
    const actionDataItemGetter = actionDataItems[action.type];
    if (!actionDataItemGetter) {
      return depsAcc;
    }

    depsAcc.push(...actionDataItemGetter(action));
    return depsAcc;
  }, []);

const getDataScopeDepsFromPropsShape = (props: any, propsShape: any): any =>
  Object.entries(propsShape)
    .reduce((depsAcc, [propKey, propDefinition]) => {
      const propValue = props && props[propKey];
      if (!propValue && (propDefinition as any).type !== GROUP) {
        return depsAcc;
      }

      if ((propDefinition as any).extractPropTypesDependencies) {
        return (propDefinition as any).extractPropTypesDependencies(
          propValue,
          depsAcc,
          props,
          propsShape,
          null,
        );
      }

      switch ((propDefinition as any).type) {
        case RAW_DATA_PROP:
        case DATA_PROP: {
          return propValue.source !== PAGE_FIELD &&
            propValue.id &&
            isNotListDep(propValue)
            ? [...depsAcc, propValue]
            : depsAcc;
        }
        case ARRAY: {
          if (!Array.isArray(propValue)) {
            return depsAcc;
          }

          const nestedDeps = propValue.reduce(
            (nestedDepsAcc, nestedProps) => [
              ...nestedDepsAcc,
              ...getDataScopeDepsFromPropsShape(
                nestedProps,
                (propDefinition as any).shape,
              ),
            ],
            [],
          );
          return [...depsAcc, ...nestedDeps];
        }
        case VARIABLE: {
          const nestedDeps = getDataScopeDepsFromPropsShape(propValue, {
            label: new StringPropType(),
            value: (propDefinition as any).propType,
          });
          return [...depsAcc, ...nestedDeps];
        }
        case GROUP: {
          const nestedDeps = getDataScopeDepsFromPropsShape(
            props,
            (propDefinition as any).shape,
          );
          return [...depsAcc, ...nestedDeps];
        }
        case COMBO: {
          const nestedDeps = getDataScopeDepsFromPropsShape(
            propValue,
            (propDefinition as any).shape,
          );
          return [...depsAcc, ...nestedDeps];
        }
        case KEY_MAP: {
          if (typeof propValue === 'object') {
            return Object.values(propValue).reduce(
              (acc, nestedPropValue) => [
                // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
                ...acc,
                ...getDataScopeDepsFromPropsShape(
                  nestedPropValue,
                  (propDefinition as any).shape,
                ),
              ],
              depsAcc,
            );
          }

          return depsAcc;
        }
        case STRING:
          return [...depsAcc, ...getDataDepsFromValue(propValue)];
        default:
          return depsAcc;
      }
    }, [])
    .filter(Boolean);
export const getDataScopeDeps = (element: any) => {
  if (!element) {
    return [];
  }

  const { props: propsConfig = {} } =
    baseElementsConfig[element.type] || new BaseElementConfig();
  if (!element.props && !element.conditions) {
    return [];
  }

  return [
    ...getDataScopeDepsFromPropsShape(element.props, propsConfig),
    ...getConditionDataItems(element.conditions),
    ...getVisibilityCustomRulesDataItems(
      get(element, 'visibilityRules.customRules', []),
    ),
    ...getActionDataItems(element.actions),
  ];
};

export const getDepFieldsForForm = (
  fields: any,
  dataType: DataType,
  dataTypes: DataTypes,
  type = elements.FORM_SECTION,
): QueryObject => {
  const dataScopeDeps = getDataScopeDeps({
    type,
    props: { fields },
  });
  const fieldDeps = fields.flatMap((field: any) =>
    getDepsForField(field.field, dataTypes, RECORD_SCOPE),
  );

  const allDeps = [...dataScopeDeps, ...fieldDeps].map(removeEdgesNodeFromPath);
  return transformDepsToQueryObject(dataType, dataTypes, allDeps);
};

export const getAdditionalFieldsForForm = (
  fields: any,
  dataType: DataType,
  dataTypes: DataTypes,
  type = elements.FORM_SECTION,
): QueryObject =>
  transformDepsToQueryObject(
    dataType,
    dataTypes,
    getDataScopeDeps({
      type,
      props: { fields },
    }).filter((dep) => dep.id === RECORD_SCOPE && dep.path.includes('.')),
  );
