import createReactComponent from '../createReactComponent';
export default createReactComponent('snowflake-off', 'IconSnowflakeOff', [
  ['path', { d: 'M10 4l2 1l2 -1', key: 'svg-0' }],
  ['path', { d: 'M12 2v6m1.196 1.186l1.804 1.034', key: 'svg-1' }],
  ['path', { d: 'M17.928 6.268l.134 2.232l1.866 1.232', key: 'svg-2' }],
  ['path', { d: 'M20.66 7l-5.629 3.25l-.031 .75', key: 'svg-3' }],
  ['path', { d: 'M19.928 14.268l-1.015 .67', key: 'svg-4' }],
  ['path', { d: 'M14.212 14.226l-2.171 1.262', key: 'svg-5' }],
  ['path', { d: 'M14 20l-2 -1l-2 1', key: 'svg-6' }],
  ['path', { d: 'M12 22v-6.5l-3 -1.72', key: 'svg-7' }],
  ['path', { d: 'M6.072 17.732l-.134 -2.232l-1.866 -1.232', key: 'svg-8' }],
  ['path', { d: 'M3.34 17l5.629 -3.25l-.01 -3.458', key: 'svg-9' }],
  ['path', { d: 'M4.072 9.732l1.866 -1.232l.134 -2.232', key: 'svg-10' }],
  ['path', { d: 'M3.34 7l5.629 3.25l.802 -.466', key: 'svg-11' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-12' }],
]);
