import createReactComponent from '../createReactComponent';
export default createReactComponent('function', 'IconFunction', [
  [
    'path',
    {
      d: 'M4 4m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h10.666a2.667 2.667 0 0 1 2.667 2.667v10.666a2.667 2.667 0 0 1 -2.667 2.667h-10.666a2.667 2.667 0 0 1 -2.667 -2.667z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 15.5v.25c0 .69 .56 1.25 1.25 1.25c.71 0 1.304 -.538 1.374 -1.244l.752 -7.512a1.381 1.381 0 0 1 1.374 -1.244c.69 0 1.25 .56 1.25 1.25v.25',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 12h6', key: 'svg-2' }],
]);
