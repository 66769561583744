import createReactComponent from '../createReactComponent';
export default createReactComponent('spray', 'IconSpray', [
  [
    'path',
    {
      d: 'M4 10m0 2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 10v-4a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v4', key: 'svg-1' }],
  ['path', { d: 'M15 7h.01', key: 'svg-2' }],
  ['path', { d: 'M18 9h.01', key: 'svg-3' }],
  ['path', { d: 'M18 5h.01', key: 'svg-4' }],
  ['path', { d: 'M21 3h.01', key: 'svg-5' }],
  ['path', { d: 'M21 7h.01', key: 'svg-6' }],
  ['path', { d: 'M21 11h.01', key: 'svg-7' }],
  ['path', { d: 'M10 7h1', key: 'svg-8' }],
]);
