import createReactComponent from '../createReactComponent';
export default createReactComponent('ballpen', 'IconBallpen', [
  ['path', { d: 'M14 6l7 7l-4 4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5.828 18.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M4 20l1.768 -1.768', key: 'svg-2' }],
]);
