import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-angular', 'IconBrandAngular', [
  [
    'path',
    {
      d: 'M5.428 17.245l6.076 3.471a1 1 0 0 0 .992 0l6.076 -3.471a1 1 0 0 0 .495 -.734l1.323 -9.704a1 1 0 0 0 -.658 -1.078l-7.4 -2.612a1 1 0 0 0 -.665 0l-7.399 2.613a1 1 0 0 0 -.658 1.078l1.323 9.704a1 1 0 0 0 .495 .734z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 15l3 -8l3 8', key: 'svg-1' }],
  ['path', { d: 'M10 13h4', key: 'svg-2' }],
]);
