import createReactComponent from '../createReactComponent';
export default createReactComponent('device-sim-2', 'IconDeviceSim2', [
  [
    'path',
    {
      d: 'M6 3h8.5l4.5 4.5v12.5a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M10 9h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-1',
    },
  ],
]);
