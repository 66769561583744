import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-off',
  'IconDeviceDesktopOff',
  [
    [
      'path',
      {
        d: 'M8 4h12a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1m-4 0h-12a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h10', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    ['path', { d: 'M15 16v4', key: 'svg-3' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
  ],
);
