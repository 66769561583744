import { useMemo } from 'react';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import { DataSource } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import { DISPLAY_NAME_REGEX } from '../../constants/regex';
import { GET_DATA_SOURCES } from '../../queries/project';

export const useIsDataSourceNameValid = (
  project: Project,
  display: string,
  isUpdate: boolean,
  existingDataSource: DataSource | undefined,
) => {
  const dataSourcesQueryResult = useCacheQuery(GET_DATA_SOURCES, {
    variables: { projectId: project.name },
  });

  const dataSources = useMemo(
    () => get(dataSourcesQueryResult, 'data.dataSources', []),
    [dataSourcesQueryResult],
  );

  const cleanName = camelCase(display);
  const isDisplayNameValid =
    display && display.length > 3 && DISPLAY_NAME_REGEX.test(display);

  const isUnique = dataSources.every(
    ({ name, id }: any) =>
      name !== cleanName ||
      (isUpdate && existingDataSource && id === existingDataSource.id),
  );

  return isDisplayNameValid && isUnique;
};
