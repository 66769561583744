import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'photo-exclamation',
  'IconPhotoExclamation',
  [
    ['path', { d: 'M15 8h.01', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M15 21h-9a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M3 16l5 -5c.928 -.893 2.072 -.893 3 0l4 4', key: 'svg-2' }],
    [
      'path',
      { d: 'M14 14l1 -1c.665 -.64 1.44 -.821 2.167 -.545', key: 'svg-3' },
    ],
    ['path', { d: 'M19 16v3', key: 'svg-4' }],
    ['path', { d: 'M19 22v.01', key: 'svg-5' }],
  ],
);
