import createReactComponent from '../createReactComponent';
export default createReactComponent('gas-station', 'IconGasStation', [
  [
    'path',
    { d: 'M14 11h1a2 2 0 0 1 2 2v3a1.5 1.5 0 0 0 3 0v-7l-3 -3', key: 'svg-0' },
  ],
  ['path', { d: 'M4 20v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14', key: 'svg-1' }],
  ['path', { d: 'M3 20l12 0', key: 'svg-2' }],
  ['path', { d: 'M18 7v1a1 1 0 0 0 1 1h1', key: 'svg-3' }],
  ['path', { d: 'M4 11l10 0', key: 'svg-4' }],
]);
