import React, { forwardRef } from 'react';
import { useAddChild, useUpdateProperty } from '../../utils/hooks/projectHooks';

const WithUpdateProjectHooks = forwardRef(({ children }, ref) =>
  // @ts-expect-error TS(2322): Type 'DetailedReactHTMLElement<any, HTMLElement>[]... Remove this comment to see the full error message
  React.Children.map(children, (child) =>
    // @ts-expect-error TS(2769): No overload matches this call.
    React.cloneElement(child, {
      ref,
      useAddChild: useAddChild,
      useUpdateProperty: useUpdateProperty,
    }),
  ),
);

export default WithUpdateProjectHooks;
