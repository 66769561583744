import { useCallback, useEffect, useMemo, useState } from 'react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { BOARD, CollectionLayout } from '../../constants/collectionLayouts';
import {
  BOOLEAN,
  DataFieldType,
  SINGLE_OPTION,
} from '../../constants/dataTypes';
import { FALSE, TRUE } from '../../constants/operators';
import {
  Group,
  GroupByConfig,
  getGroupByDepPaths,
} from '../../elements/sections/Collection';
import { DataField } from '../../models/DataTypeFields';
import { DataType } from '../../models/DataTypes';
import { RecordEdge, RecordValue } from '../../models/Record';
import { CollectionField, GroupByWithField } from '../../models/View';
import { projectDataSelector } from '../../selectors/projectSelectors';
import SafeStorage from '../SafeStorage';
import { getColorByIndex } from '../colors';
import { FieldConfig } from '../permissions';
import { cleanEdgesNodeFromDepPath } from '../queries';
import usePrevious from './usePrevious';

export type CollectionGroupsConfig = {
  dataType: DataType;
  edges: RecordEdge[];
  elementId: string;
  fields?: FieldConfig<CollectionField>[];
  groupByFields: GroupByWithField[];
  groupOptions?: Record<string, { collapsed?: boolean; hidden?: boolean }>;
  hideEmptyGroups?: boolean;
  layout: CollectionLayout;
  limitPerGroup?: number;
};

const groupEdges = (
  edges: RecordEdge[],
  key: string,
  labelPathFixed: string,
  renderLabel: (val: RecordValue) => any = identity,
  getColor: (value: RecordValue, groupIndex: number) => string,
  groupValue: (val: RecordValue) => RecordValue = identity,
  initialGroups: Omit<Group, 'depth' | 'id'>[] = [],
  fieldType?: DataFieldType | null,
): Group[] =>
  edges.reduce((groupsAcc: any, dataRow: any, index: any) => {
    const groupByValue = groupValue(get(dataRow, key));
    const groupIndex = groupsAcc.findIndex((g: any) => g.key === groupByValue);
    const rawValue = get(dataRow, labelPathFixed);
    const label =
      fieldType === BOOLEAN ? (rawValue ? TRUE : FALSE) : renderLabel(rawValue);

    if (groupIndex < 0) {
      return [
        ...groupsAcc,
        {
          id: groupByValue,
          key: groupByValue,
          rawValue,
          label,
          color: isNil(rawValue)
            ? undefined
            : getColor(rawValue, groupsAcc.length),
          rows: [{ ...dataRow, index }],
        },
      ];
    }

    return set(
      [groupIndex, 'rows'],
      [...groupsAcc[groupIndex].rows, { ...dataRow, index }],
      groupsAcc,
    );
  }, initialGroups);

const defaultGetColor = (__: RecordValue, groupIndex: number) =>
  getColorByIndex(groupIndex);

export const getGroupsFromEdgesForLevel = (
  edges: RecordEdge[],
  groupByConfig: GroupByConfig,
) => {
  const {
    key: keyPath,
    label: labelPath,
    groupValue = identity,
    renderLabel = identity,
    getColor = defaultGetColor,
    sortBy,
    initialGroups,
    type: fieldType = null,
  } = groupByConfig;
  const key = cleanEdgesNodeFromDepPath(keyPath, 'node.');
  const labelPathFixed = cleanEdgesNodeFromDepPath(labelPath, 'node.');
  const groups = groupEdges(
    edges,
    key,
    labelPathFixed,
    renderLabel,
    getColor,
    groupValue,
    initialGroups,
    fieldType,
  );

  if (sortBy) {
    groups.sort(sortBy);
  }

  return groups;
};

const getGroupsFromEdges = (
  edges: RecordEdge[],
  groupByConfigs: GroupByConfig[],
  parentGroup?: Group,
): Group[] => {
  const [groupByConfig, ...restConfigs] = groupByConfigs;

  if (!groupByConfig) {
    return [];
  }

  const groups = getGroupsFromEdgesForLevel(edges, groupByConfig).map(
    (group) => ({
      ...group,
      id: parentGroup ? `${parentGroup.id}.${group.key}` : group.key,
      depth: parentGroup ? parentGroup.depth + 1 : 0,
    }),
  );

  if (restConfigs.length > 0) {
    return groups.map((group) => {
      const subGroups = getGroupsFromEdges(
        group.rows ?? [],
        restConfigs,
        group,
      ).map((subGroup) => ({
        ...subGroup,
        id: `${group.id}.${subGroup.key}`,
        depth: group.depth + 1,
      }));

      group.groups = subGroups;
      delete group.rows;

      return group;
    });
  }

  return groups;
};

const filterGroups = (
  group: Group,
  hideEmptyGroups: boolean,
  groupOptions: any = {},
  field?: DataField,
) => {
  if (hideEmptyGroups && (group.groups ?? group.rows ?? []).length === 0) {
    return false;
  }

  const optionConfig = groupOptions[group.key];
  if (field && field.type === SINGLE_OPTION && optionConfig) {
    return !get(optionConfig, 'hidden', false);
  }

  return true;
};

const getVisibleGroups = (
  groups: Group[],
  hideEmptyGroups: boolean,
  groupOptions: Record<string, { hidden?: boolean }> = {},
  field?: DataField,
): Group[] =>
  groups
    .filter((group) =>
      filterGroups(group, hideEmptyGroups, groupOptions, field),
    )
    .map((group) => {
      if (group.groups) {
        const subGroups = getVisibleGroups(group.groups, hideEmptyGroups);

        if (hideEmptyGroups && subGroups.length === 0) {
          return false;
        }

        return {
          ...group,
          groups: subGroups,
        };
      }

      return group;
    })
    .filter(Boolean) as Group[];

const getGroupsById = (
  groups: Group[],
  parents: Group[] = [],
): Record<string, Group[]> =>
  groups.reduce(
    (acc, group) => ({
      ...acc,
      [group.id]: [...parents, group],
      ...(group.groups ? getGroupsById(group.groups, [...parents, group]) : []),
    }),
    {},
  );

const getCollapsedStateKey = (groupKey: any, elementId: any) =>
  `group.${elementId}.${groupKey}.collapse`;

const getInitialCollapsedState = (
  groupKeys: string[],
  groupOptions: any,
  elementId: any,
) =>
  groupKeys.reduce(
    (groupsAcc, groupKey) => ({
      ...groupsAcc,

      [groupKey]: new SafeStorage().getBoolean(
        getCollapsedStateKey(groupKey, elementId),
        get(groupOptions, [groupKey, 'collapsed'], false),
      ),
    }),
    {},
  );

type CollectionGroupsResult = {
  firstSummaryIndex: number;
  groupCollapsedStates: Record<string, boolean>;
  groupConfigs: GroupByConfig[];
  groupOpenStates: Record<string, boolean>;
  groupsById: Record<string, Group[]>;
  isLimited: boolean;
  numFields: number;
  toggleGroupCollapsedState: (groupKey: string) => () => void;
  toggleGroupState: (groupKey: string) => () => void;
  visibleGroups: Group[];
};

const useCollectionGroups = ({
  dataType,
  edges,
  elementId,
  fields = [],
  groupByFields,
  groupOptions = {},
  hideEmptyGroups = false,
  layout,
  limitPerGroup,
}: CollectionGroupsConfig): CollectionGroupsResult => {
  const project = useSelector(projectDataSelector);
  const numFields = fields.length;

  const groupConfigs = useMemo(
    () =>
      (groupByFields ?? []).map(
        ({ dataField, field: groupByDep, sort: groupBySort, timePeriod }) =>
          getGroupByDepPaths({
            dataField,
            dataType,
            dataTypes: project.dataTypes,
            groupByDep,
            groupBySort,
            timePeriod,
          }),
      ),
    [dataType, groupByFields, project.dataTypes],
  );

  const groups = useMemo(
    () => getGroupsFromEdges(edges, groupConfigs),
    [edges, groupConfigs],
  );

  const groupsById = useMemo(() => getGroupsById(groups), [groups]);

  const [groupOpenStates, setGroupOpenStates] = useState({});
  const groupKeys = useMemo(() => Object.keys(groupsById), [groupsById]);

  const [groupCollapsedStates, setGroupCollapsedStates] = useState(
    getInitialCollapsedState(groupKeys, groupOptions, elementId),
  );

  const previousGroupKeys = usePrevious(groupKeys);

  useEffect(() => {
    if (
      previousGroupKeys &&
      previousGroupKeys.join('.') !== groupKeys.join('.')
    ) {
      setGroupCollapsedStates(
        getInitialCollapsedState(groupKeys, groupOptions, elementId),
      );
    }
  }, [elementId, groupKeys, groupOptions, previousGroupKeys]);

  const toggleGroupState = useCallback(
    (groupId: string) => () => {
      setGroupOpenStates((currentGroupOpenStates) => ({
        ...currentGroupOpenStates,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [groupId]: !currentGroupOpenStates[groupId],
      }));
    },
    [],
  );

  const toggleGroupCollapsedState = useCallback(
    (groupId: string) => () => {
      const storage = new SafeStorage();
      const localStorageKey = getCollapsedStateKey(groupId, elementId);

      setGroupCollapsedStates((currentGroupCollapsedStates: any) => {
        storage.set(localStorageKey, !currentGroupCollapsedStates[groupId]);
        return {
          ...currentGroupCollapsedStates,
          [groupId]: !currentGroupCollapsedStates[groupId],
        };
      });
    },
    [elementId],
  );

  const isLimited = !!limitPerGroup && layout === BOARD;
  const firstSummaryIndex = useMemo(() => {
    const summaryIndex = fields.findIndex(
      ({ config }: any, idx: any) => idx > 0 && !!config.groupSummary,
    );
    return summaryIndex < 0 ? fields.length : summaryIndex;
  }, [fields]);

  const visibleGroups = useMemo(
    () =>
      groupByFields && groupByFields.length > 0
        ? getVisibleGroups(
            groups,
            hideEmptyGroups,
            groupOptions,
            groupByFields[0].dataField.field,
          )
        : [],
    [groupByFields, groupOptions, groups, hideEmptyGroups],
  );

  return {
    firstSummaryIndex,
    groupCollapsedStates,
    groupConfigs,
    groupOpenStates,
    groupsById,
    isLimited,
    numFields,
    toggleGroupCollapsedState,
    toggleGroupState,
    visibleGroups,
  };
};

export default useCollectionGroups;
