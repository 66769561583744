import { useCallback } from 'react';
import {
  IconArrowBack,
  IconArrowBigUp,
  IconArrowDown,
  IconArrowUp,
  IconBackspace,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Surface } from '@noloco/components/src';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import {
  CMD,
  ENTER,
  ESCAPE,
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
  KEY_BACKSPACE,
  SHIFT,
  ShortcutKeyType,
} from '../../../constants/shortcuts';
import useCmdKey from '../../../utils/hooks/useCmdKey';

type ShortcutKeysProps = {
  background?: boolean;
  buildMode?: boolean;
  keys: ShortcutKeyType[];
  surface?: Surface;
};

const ShortcutKeys = ({
  background = true,
  buildMode = false,
  keys,
  surface = LIGHT,
}: ShortcutKeysProps) => {
  const cmdKey = useCmdKey();

  const getKey = useCallback(
    (key) => {
      switch (key) {
        case CMD:
          return cmdKey;

        case SHIFT:
          return <IconArrowBigUp size={16} />;

        case KEY_BACKSPACE:
          return <IconBackspace size={16} />;

        case KEY_ARROW_DOWN:
          return <IconArrowDown size={16} />;

        case KEY_ARROW_UP:
          return <IconArrowUp size={16} />;

        case ENTER:
          return <IconArrowBack size={16} />;

        case ESCAPE:
          return (
            <span className="flex w-fit items-center justify-center">ESC</span>
          );

        default:
          return (
            <span className="flex w-4 items-center justify-center">{key}</span>
          );
      }
    },
    [cmdKey],
  );

  return (
    <span className="flex items-center space-x-0.5">
      {keys.map((key) => (
        <span
          className={classNames(
            'flex items-center justify-center rounded-sm p-0.5 uppercase',
            {
              ...(background
                ? {
                    'bg-slate-600 text-slate-300':
                      buildMode || surface === DARK,
                    'bg-slate-200': !buildMode || surface === LIGHT,
                  }
                : {}),
            },
          )}
          key={key}
        >
          {getKey(key)}
        </span>
      ))}
    </span>
  );
};

export default ShortcutKeys;
