import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-wave-left-down',
  'IconArrowWaveLeftDown',
  [
    ['path', { d: 'M7 14h-4v-4', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M21 12c-.887 1.284 -2.48 2.033 -4 2c-1.52 .033 -3.113 -.716 -4 -2s-2.48 -2.033 -4 -2c-1.52 -.033 -3 1 -4 2l-2 2',
        key: 'svg-1',
      },
    ],
  ],
);
