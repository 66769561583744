import React from 'react';
import classNames from 'classnames';
import { Theme, getColorShade } from '@noloco/components';
import Checkbox from '../../Checkbox';

type BulkActionCheckboxCellProps = {
  elementId: string;
  handleCheckboxChange: () => void;
  isRowChecked: boolean;
  isSticky: boolean;
  theme: Theme;
};

const BulkActionCheckboxCell = ({
  elementId,
  handleCheckboxChange,
  isRowChecked,
  isSticky,
  theme,
}: BulkActionCheckboxCellProps) => {
  const primaryColor = theme.brandColors.primary;
  return (
    <td
      className={classNames('w-9 items-center justify-center', {
        'sticky left-0 z-10': isSticky,
        [`bg-${getColorShade(
          primaryColor,
          100,
        )} bg-opacity-75 dark:bg-${getColorShade(primaryColor, 900)}`]:
          isRowChecked,
        'bg-white dark:bg-gray-800': !isRowChecked && isSticky,
      })}
    >
      <Checkbox
        className={classNames('flex w-3 pl-3', {
          'hidden group-hover:block': !isRowChecked,
          block: isRowChecked,
        })}
        size="sm"
        checked={isRowChecked}
        value={isRowChecked}
        elementId={elementId}
        onChange={handleCheckboxChange}
      />
    </td>
  );
};

export default BulkActionCheckboxCell;
