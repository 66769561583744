import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-rounded-letter-d',
  'IconSquareRoundedLetterD',
  [
    [
      'path',
      { d: 'M10 8v8h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-2z', key: 'svg-0' },
    ],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-1',
      },
    ],
  ],
);
