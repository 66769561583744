import createReactComponent from '../createReactComponent';
export default createReactComponent('home-up', 'IconHomeUp', [
  [
    'path',
    {
      d: 'M9 21v-6a2 2 0 0 1 2 -2h2c.641 0 1.212 .302 1.578 .771',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20.136 11.136l-8.136 -8.136l-9 9h2v7a2 2 0 0 0 2 2h6.344',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 22v-6', key: 'svg-2' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-3' }],
]);
