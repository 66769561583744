import createReactComponent from '../createReactComponent';
export default createReactComponent('grill-fork', 'IconGrillFork', [
  ['path', { d: 'M5 5l11.5 11.5', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M19.347 16.575l1.08 1.079a1.96 1.96 0 0 1 -2.773 2.772l-1.08 -1.079a1.96 1.96 0 0 1 2.773 -2.772z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M3 7l3.05 3.15a2.9 2.9 0 0 0 4.1 -4.1l-3.15 -3.05', key: 'svg-2' },
  ],
]);
