import createReactComponent from '../createReactComponent';
export default createReactComponent('table-share', 'IconTableShare', [
  [
    'path',
    {
      d: 'M12 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 10h18', key: 'svg-1' }],
  ['path', { d: 'M10 3v18', key: 'svg-2' }],
  ['path', { d: 'M16 22l5 -5', key: 'svg-3' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-4' }],
]);
