import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  MutationHookOptions,
  QueryHookOptions,
} from '@apollo/client/react/types/types';
import { CredentialResponse } from '@react-oauth/google';
import flagsmith from 'flagsmith';
import { DocumentNode } from 'graphql/language/ast';
import get from 'lodash/get';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { failedPasswordChecks, isEmailValid } from '@noloco/components';
import { IS_PRODUCTION } from '@noloco/ui/src/constants/env';
import { USER } from '../../constants/builtInDataTypes';
import { IS_SSR } from '../../constants/env';
import { Project } from '../../models/Project';
import { BaseRecord } from '../../models/Record';
import { BaseUser, DashboardUser, User } from '../../models/User';
import {
  ACCEPT_INVITE_MUTATION,
  CURRENT_USER_QUERY,
  GOOGLE_LOGIN_MUTATION,
  LOGIN_MUTATION,
  MAGIC_LINK_LOGIN_MUTATION,
  RECOVER_PASSWORD_MUTATION,
  REGISTRATION_MUTATION,
  RESET_PASSWORD_MUTATION,
  buildAcceptInviteMutation,
  buildCurrentUserQuery,
  buildGoogleLoginMutation,
  buildLoginMutation,
  buildRecoverPasswordMutation,
  buildRegisterMutation,
  buildResetPasswordMutation,
} from '../../queries/auth';
import { getDataTypeFieldsArgs } from '../../queries/project';
import { setUser } from '../../reducers/user';
import { userSelector } from '../../selectors/userSelectors';
import SafeStorage from '../SafeStorage';
import { SESSION_START, analyticsIdentity, trackEvent } from '../analytics';
import { getText } from '../lang';
import { getFullName } from '../user';
import { useAuthWrapperUserFields } from './useAuthWrapper';
import { useOTPAuth } from './useOTPAuth';

type AuthContext<AuthUser extends BaseUser> = {
  user: AuthUser | null;
  fetchedUser: boolean;
  login: (
    email: string,
    password: string,
  ) => Promise<AuthUser> | Promise<Record<string, any>>;
  magicLinkLogin: (email: string) => Promise<void>;
  token: string | null;
  acceptInvite: (
    password: string,
    confirmPassword: string,
    userProperties?: Partial<AuthUser>,
  ) => Promise<AuthUser>;
  recoverPassword: (email: string) => Promise<void>;
  resetPassword: (
    email: string,
    password: string,
    confirmPassword: string,
    userProperties?: Partial<AuthUser>,
  ) => Promise<AuthUser>;
  register: (
    email: string,
    password: string,
    confirmPassword: string,
    userProperties?: Partial<AuthUser>,
    phoneNumber?: string,
  ) => Promise<AuthUser>;
  signOut: () => Promise<unknown>;
  googleLogin: (credentialResponse: CredentialResponse) => Promise<AuthUser>;
  generateOTP?: (
    secondFactorAuthToken?: string,
  ) => Promise<Record<string, any>>;
  verifyOTP?: (
    otp: string,
    secondFactorAuthToken?: string,
  ) => Promise<AuthUser>;
  generateOTPBackupCodes?: (
    secondFactorAuthToken?: string,
  ) => Promise<Record<string, any>>;
  verifyOTPBackupCode?: (
    otp: string,
    secondFactorAuthToken?: string,
  ) => Promise<AuthUser>;
};

const authContext = createContext<Partial<AuthContext<BaseUser>>>({
  user: null,
  token: null,
  fetchedUser: false,
});

export const saveUserData = (authTokenKey: string, token: string) => {
  const expiry = new Date();
  expiry.setTime(expiry.getTime() + 14 * 24 * 60 * 60 * 1000); // 14 days
  document.cookie = `${authTokenKey}=${token}; expires=${expiry.toUTCString()}; path=/;`;
  new SafeStorage().set(authTokenKey, token);
};

export const clearUserData = (authTokenKey: string) => {
  document.cookie = `${authTokenKey}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  new SafeStorage().remove(authTokenKey);
};

const validatePassword = (password: string, confirmPassword: string) => {
  if (!password || !confirmPassword) {
    throw new Error(getText('auth.register.validation.emptyFields'));
  }

  if (confirmPassword !== password) {
    throw new Error(
      getText('auth.register.validation.confirmPassword.invalid'),
    );
  }
  const passwordErrors = failedPasswordChecks(password);
  if (passwordErrors.length > 0) {
    throw new Error(
      getText('auth.register.validation.password', passwordErrors[0]),
    );
  }
};

type ProjectWindow = Window & {
  __PROJECT__?: Project;
};

export const getUserIdentifier = <AuthUser extends BaseUser>(
  user: AuthUser & { _nolocoUserId?: string | null },
  basePrefix = '',
): string => {
  const projectWindow = window as ProjectWindow;
  const nolocoUserId = user._nolocoUserId;

  if (!nolocoUserId && projectWindow.__PROJECT__) {
    return `${projectWindow.__PROJECT__.name}:${user.id}`;
  }
  const id = nolocoUserId ?? user.id;

  return `${basePrefix}${id}`;
};

const setupTracking = <AuthUser extends BaseUser>(
  user: AuthUser,
  getUserTrackingAttributes: (user: AuthUser) => Record<string, any>,
) => {
  const isNolocoUser = user.email.includes('@noloco.io');

  if (IS_PRODUCTION && !isNolocoUser) {
    window.intercomSettings = {
      ...(window.intercomSettings || {}),
      email: user.email,
      user_id: getUserIdentifier(user, 'NOLOCO:'),
      created_at: user.createdAt
        ? new Date(user.createdAt).getTime() / 1000
        : undefined,
      name: getFullName(user),
    };

    if (window.Intercom) {
      window.Intercom('update');
    }
  }

  if (window.analytics && IS_PRODUCTION) {
    const userAttributes = getUserTrackingAttributes(user);
    analyticsIdentity({
      userId: getUserIdentifier(user),
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      ...userAttributes,
    });
  }

  if (
    window._NolocoOnLoadUser &&
    typeof window._NolocoOnLoadUser === 'function'
  ) {
    try {
      window._NolocoOnLoadUser(user);
    } catch {
      // We don't do anything to explicitly handle
    }
  }
};

export const useProvideAuth = <AuthUser extends BaseUser>(
  authTokenKey: string,
  {
    queryOptions = {},
    loginQuery = LOGIN_MUTATION,
    registrationQuery = REGISTRATION_MUTATION,
    acceptInviteQuery = ACCEPT_INVITE_MUTATION,
    recoverPasswordQuery = RECOVER_PASSWORD_MUTATION,
    resetPasswordQuery = RESET_PASSWORD_MUTATION,
    currentUserQuery = CURRENT_USER_QUERY,
    onLoadUser = (_: AuthUser) => {},
    googleLoginQuery = GOOGLE_LOGIN_MUTATION,
    getUserTrackingAttributes = () => ({}),
    userQueryObject = {},
    isProjectAuth = false,
  }: {
    queryOptions?: QueryHookOptions & MutationHookOptions;
    loginQuery?: DocumentNode;
    registrationQuery?: DocumentNode;
    acceptInviteQuery?: DocumentNode;
    recoverPasswordQuery?: DocumentNode;
    resetPasswordQuery?: DocumentNode;
    currentUserQuery?: DocumentNode;
    onLoadUser?: (user: AuthUser) => void;
    googleLoginQuery?: DocumentNode;
    userQueryObject?: Record<string, any>;
    getUserTrackingAttributes: (user: AuthUser) => Record<string, any>;
    isProjectAuth?: boolean;
  },
): AuthContext<AuthUser> => {
  const {
    client: apolloClient,
    data: userData,
    error: userError,
    loading: userLoading,
    called,
  } = useQuery(currentUserQuery, queryOptions);
  const [token, setToken] = useState<string | null>(null);
  const [ignoreNetworkUser, setIgnoreNetworkUser] = useState(false);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const [loginMutation, { client }] = useMutation(loginQuery, queryOptions);
  const [magicLinkLoginMutation] = useMutation(
    MAGIC_LINK_LOGIN_MUTATION,
    queryOptions,
  );
  const [registerMutation] = useMutation(registrationQuery, queryOptions);
  const [acceptInviteMutation] = useMutation(acceptInviteQuery, queryOptions);
  const [recoverPasswordMutation] = useMutation(
    recoverPasswordQuery,
    queryOptions,
  );
  const [resetPasswordMutation] = useMutation(resetPasswordQuery, queryOptions);
  const [googleLoginMutation] = useMutation(googleLoginQuery, queryOptions);

  const setUserState = useCallback(
    (newUser: AuthUser | null) => {
      if (!user || !newUser || user.id !== newUser.id) {
        dispatch(setUser(newUser));
      }
    },
    [dispatch, user],
  );

  // useEffect doesn't work in server-side rendering
  if (IS_SSR && userData && !user) {
    setUserState(get(userData, 'currentUser.user'));
  }

  const updateAuthToken = useCallback(
    (newToken: string) => {
      if (newToken !== token) {
        setToken(newToken);
        saveUserData(authTokenKey, newToken);
      }
    },
    [authTokenKey, token],
  );

  const finishLogin = useCallback(
    (authPayload: { token: string; user: AuthUser }) => {
      const { token: loginToken, user: newUser } = authPayload;
      updateAuthToken(loginToken);
      setUserState(newUser);
      setIgnoreNetworkUser(false);
      setupTracking(newUser, getUserTrackingAttributes);
      onLoadUser(newUser);
      apolloClient.writeQuery({
        query: currentUserQuery,
        data: {
          currentUser: {
            id: newUser ? newUser.id : null,
            token: loginToken,
            user: newUser,
            __typename: 'AuthPayload',
          },
        },
      });
      return newUser;
    },
    [
      apolloClient,
      currentUserQuery,
      getUserTrackingAttributes,
      onLoadUser,
      setUserState,
      updateAuthToken,
    ],
  );

  const signOut = useCallback(() => {
    return new Promise<void>((resolve) => {
      apolloClient.writeQuery({
        query: currentUserQuery,
        data: {
          currentUser: {
            id: null,
            token: null,
            user: null,
            __typename: 'AuthPayload',
          },
        },
      });
      clearUserData(authTokenKey);
      setUserState(null);
      setToken(null);
      setIgnoreNetworkUser(true);

      if (window.Intercom) {
        window.Intercom('shutdown');
      }
      return resolve(undefined);
    });
  }, [authTokenKey, setUserState, apolloClient, currentUserQuery]);

  const login = useCallback(
    (email: string, password: string) => {
      if (!email || !password) {
        throw new Error(getText('auth.register.validation.emptyFields'));
      }
      return loginMutation({
        variables: { email, password: password.substring(0, 256) },
      }).then(({ data, errors }): Promise<AuthUser | Record<string, any>> => {
        if (errors && !data.login) {
          throw errors;
        }

        if (data.login.requiresSecondFactor) {
          return signOut().then(() => ({
            requiresSecondFactor: data.login.requiresSecondFactor,
            secondFactorAuthToken: data.login.token,
          }));
        }

        return signOut().then(() => finishLogin(data.login) as AuthUser);
      });
    },
    [loginMutation, signOut, finishLogin],
  );

  const googleLogin = useCallback(
    (credentialResponse: CredentialResponse) =>
      googleLoginMutation({
        variables: { tokenId: credentialResponse.credential },
      }).then(({ data, errors }) => {
        if (errors && !data.googleLogin) {
          throw errors;
        }

        return signOut().then(() => finishLogin(data.googleLogin));
      }),
    [finishLogin, googleLoginMutation, signOut],
  );

  const magicLinkLogin = useCallback(
    async (email: string) => {
      if (!email) {
        throw new Error(getText('auth.register.validation.emptyFields'));
      }

      await magicLinkLoginMutation({
        variables: { email },
      });
    },
    [magicLinkLoginMutation],
  );

  const register = useCallback(
    (
      email: string,
      password: string,
      confirmPassword: string,
      restUserProps: Partial<AuthUser> = {},
      phoneNumber?: string,
    ) => {
      if (!email || !password || !confirmPassword) {
        throw new Error(getText('auth.register.validation.emptyFields'));
      }

      if (!isEmailValid(email)) {
        throw new Error(getText('auth.register.validation.email.invalid'));
      }

      validatePassword(password, confirmPassword);
      return registerMutation({
        variables: {
          email,
          password,
          confirmPassword,
          phoneNumber,
          ...restUserProps,
        },
      }).then(({ data, errors }) => {
        if (errors && !data.register) {
          throw errors;
        }
        return signOut().then(() => finishLogin(data.register));
      });
    },
    [registerMutation, signOut, finishLogin],
  );

  const recoverPassword = useCallback(
    (email: string) => {
      if (!email || !isEmailValid(email)) {
        throw new Error(getText('auth.recoverPassword.invalid'));
      }

      return recoverPasswordMutation({
        variables: { email },
      }).then(({ data }) => data.recoverPassword);
    },
    [recoverPasswordMutation],
  );

  const resetPassword = useCallback(
    (
      email: string,
      password: string,
      confirmPassword: string,
      restUserProps: Partial<AuthUser> & {
        resetToken?: string;
      } = {},
    ) => {
      if (!email || !password || !confirmPassword) {
        throw new Error(getText('auth.register.validation.emptyFields'));
      }

      validatePassword(password, confirmPassword);
      const resetToken =
        restUserProps.resetToken ||
        queryString.parse(document.location.search).resetToken;

      if (!resetToken) {
        throw new Error(getText('auth.resetPassword.resetTokenInvalid'));
      }

      return resetPasswordMutation({
        variables: {
          email,
          resetToken,
          password,
          confirmPassword,
          ...restUserProps,
        },
      }).then(({ data, errors }) => {
        if (errors && !data.resetPassword) {
          throw errors;
        }
        return signOut().then(() => finishLogin(data.resetPassword));
      });
    },
    [resetPasswordMutation, signOut, finishLogin],
  );

  const acceptInvite = useCallback(
    (
      password: string,
      confirmPassword: string,
      restUserProps: Partial<AuthUser> & {
        invitationToken?: string;
      } = {},
    ) => {
      validatePassword(password, confirmPassword);
      const invitationToken =
        restUserProps.invitationToken ||
        queryString.parse(document.location.search).invitationToken;
      if (!invitationToken) {
        throw new Error(
          getText('auth.register.validation.invitationToken.invalid'),
        );
      }
      return acceptInviteMutation({
        variables: {
          password,
          confirmPassword,
          invitationToken,
          ...restUserProps,
        },
      }).then(({ data, errors }) => {
        if (errors && !data.acceptInvite) {
          throw errors;
        }
        return signOut().then(() => finishLogin(data.acceptInvite));
      });
    },
    [acceptInviteMutation, finishLogin, signOut],
  );

  const {
    generateOTP,
    verifyOTP,
    generateOTPBackupCodes,
    verifyOTPBackupCode,
  } = useOTPAuth<AuthUser>({
    userQueryObject,
    signOut,
    finishLogin,
    queryOptions,
  });

  const appUser = user || get(userData, 'currentUser.user');

  useEffect(() => {
    if (appUser) {
      if (
        !ignoreNetworkUser &&
        userData &&
        userData.currentUser &&
        userData.currentUser.user &&
        (!token || !user)
      ) {
        finishLogin(userData.currentUser);
      }
    }

    if (userError && !user) {
      setUserState(null);
    }
  }, [
    userError,
    userData,
    setUserState,
    onLoadUser,
    user,
    token,
    finishLogin,
    appUser,
    ignoreNetworkUser,
  ]);

  // Return the user object and auth methods
  return useMemo(
    () => ({
      client,
      user: appUser as AuthUser,
      fetchedUser: !!user || (called && !userLoading),
      login,
      magicLinkLogin,
      token,
      acceptInvite,
      recoverPassword,
      resetPassword,
      register,
      signOut,
      googleLogin,
      ...(isProjectAuth && {
        generateOTP,
        verifyOTP,
        generateOTPBackupCodes,
        verifyOTPBackupCode,
      }),
    }),
    [
      acceptInvite,
      appUser,
      called,
      client,
      googleLogin,
      login,
      magicLinkLogin,
      recoverPassword,
      register,
      resetPassword,
      signOut,
      token,
      user,
      userLoading,
      generateOTP,
      verifyOTP,
      generateOTPBackupCodes,
      verifyOTPBackupCode,
      isProjectAuth,
    ],
  );
};

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth = ({ authTokenKey, children }: any) => {
  const onLoadProjectUser = useCallback((user) => {
    flagsmith.identify(`NOLOCO:${user.id}`);
    flagsmith.setTrait('isAdmin', user.email.endsWith('@noloco.io'));
    flagsmith.setTrait('teamPlanId', user.team.plan.id);
  }, []);

  const auth = useProvideAuth<DashboardUser>(authTokenKey, {
    onLoadUser: onLoadProjectUser,
    getUserTrackingAttributes: (user) => {
      const {
        status,
        trialEnd,
        periodEnd,
        id: teamPlanId,
        type,
        interval,
      } = user.team.plan ?? {};

      return {
        teamName: user.team.name,
        status,
        trialEnd,
        periodEnd,
        teamPlanId,
        planType: type,
        interval,
        isBuilder: true,
      };
    },
  });
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const ProvideProjectAuth = ({
  authTokenKey,
  children,
  project,
}: any) => {
  const userType = project.dataTypes.getByName(USER);
  const userQueryObject = useAuthWrapperUserFields(project.dataTypes);
  const currentUserQuery = buildCurrentUserQuery(userQueryObject);
  const loginQuery = buildLoginMutation(userQueryObject);
  const googleLoginQuery = buildGoogleLoginMutation(userQueryObject);
  const userArgs = getDataTypeFieldsArgs(userType.apiName, userType.fields);
  const registrationQuery = buildRegisterMutation(userQueryObject, userArgs);
  const acceptInviteQuery = buildAcceptInviteMutation(
    userQueryObject,
    userArgs,
  );
  const recoverPasswordQuery = buildRecoverPasswordMutation();
  const resetPasswordQuery = buildResetPasswordMutation(userQueryObject);

  const onLoadProjectUser = useCallback(
    (user: any) => {
      const userId = getUserIdentifier(user, 'NOLOCO:');
      flagsmith.identify(userId);
      flagsmith.setTrait('isAdmin', user.email.endsWith('@noloco.io'));
      flagsmith.setTrait('project', project.name);
      flagsmith.setTrait(
        'projectCreatedAt',
        new Date(project.createdAt).getTime(),
      );

      if (window.analytics) {
        trackEvent(SESSION_START, 'project', project.name);
      }
    },
    [project.createdAt, project.name],
  );

  const auth = useProvideAuth<User>(authTokenKey, {
    queryOptions: {
      context: {
        projectQuery: true,
        projectName: project.name,
        authQuery: true,
      },
      errorPolicy: 'all',
      nextFetchPolicy: 'no-cache',
    },
    loginQuery,
    registrationQuery,
    acceptInviteQuery,
    recoverPasswordQuery,
    resetPasswordQuery,
    currentUserQuery,
    googleLoginQuery,
    userQueryObject: userQueryObject,
    onLoadUser: onLoadProjectUser,
    getUserTrackingAttributes: (user) => ({
      avatar: (user.profilePicture as BaseRecord | undefined)?.url,
      project: project.name,
      isBuilder: !!user._nolocoUserId,
    }),
    isProjectAuth: true,
  });
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): AuthContext<User> => {
  return useContext(authContext) as AuthContext<User>;
};

export const useDashboardAuth = (): AuthContext<DashboardUser> => {
  return useContext(authContext) as AuthContext<DashboardUser>;
};
