import React, { forwardRef } from 'react';
import {
  Box,
  getTailwindClassNames,
  withTheme,
} from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getValidationBorderColor } from '@noloco/components/src/utils';
import { MAX_FILE_SIZE_BYTES } from '../../constants/files';
import BaseDropzone from './BaseDropzone';

type Props = {
  acceptedMimetypes?: string[];
  className?: string;
  bg?: string;
  maxFiles?: number;
  maxSize?: number;
  noClick?: boolean;
  noDrag?: boolean;
  errorBg?: string;
  dragOverBg?: string;
  disabled?: boolean;
};

const Dropzone = forwardRef<any, Props>(
  (
    {
      acceptedMimetypes,
      children,
      className,
      bg,
      errorBg,
      dragOverBg,
      disabled,
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'Props'.
      id,
      // @ts-expect-error TS(2339): Property 'onChange' does not exist on type 'Props'... Remove this comment to see the full error message
      onChange,
      maxFiles,
      maxSize,
      noClick,
      noDrag,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
      theme,
      // @ts-expect-error TS(2339): Property 'rounded' does not exist on type 'Props'.
      rounded,
      // @ts-expect-error TS(2339): Property 'surface' does not exist on type 'Props'.
      surface,
      // @ts-expect-error TS(2339): Property 'p' does not exist on type 'Props'.
      p,
      // @ts-expect-error TS(2339): Property 'validationError' does not exist on type ... Remove this comment to see the full error message
      validationError,
      ...rest
    },
    ref,
  ) => {
    const { surface: themeSurface = 'default' } = theme.dropzone || {};
    const elSurface = surface || themeSurface;
    const bgValue = bg || theme.surfaceColors[elSurface];
    const dragOverBgColor = dragOverBg || theme.brandColors['primary'];
    const errorBgColor = errorBg || theme.brandColors['danger'];
    const textColor = theme.textColors.on[elSurface] || theme.textColors.body;
    const twClassNames = getTailwindClassNames({
      flex: [true, 'col'],
      justify: 'center',
      items: 'center',
      text: ['center', textColor],
      rounded,
      w: 'full',
      ...rest,
    });

    return (
      <BaseDropzone
        acceptedMimetypes={acceptedMimetypes}
        className={classNames(twClassNames, className)}
        disabled={disabled}
        id={id}
        maxSize={maxSize}
        noClick={noClick}
        noDrag={noDrag}
        maxFiles={maxFiles}
        onChange={onChange}
        ref={ref}
      >
        {({ isDragAccept, isDragReject, onClick }: any) => {
          const background = isDragAccept
            ? dragOverBgColor
            : isDragReject
              ? errorBgColor
              : bgValue;

          const onClickWithPreventDefault = (event: Event) => {
            event.preventDefault();
            onClick(event);
          };

          return (
            <Box
              className="flex h-full w-full p-2"
              bg={background}
              rounded={rounded}
              onClick={onClickWithPreventDefault}
            >
              <Box
                p={p}
                className={classNames(
                  'relative flex w-full flex-col items-center justify-center rounded-lg border-2 border-dashed p-3 text-xs focus:shadow-none focus:outline-none',
                  validationError
                    ? `border-${getValidationBorderColor(theme)}`
                    : isDragAccept || isDragReject
                      ? 'border-white'
                      : 'border-gray-300',
                )}
              >
                {children}
              </Box>
            </Box>
          );
        }}
      </BaseDropzone>
    );
  },
);

Dropzone.defaultProps = {
  className: '',
  disabled: false,
  noClick: false,
  noDrag: false,
  maxFiles: 1,
  maxSize: MAX_FILE_SIZE_BYTES,
  // @ts-expect-error TS(2322): Type '{ className: string; disabled: false; noClic... Remove this comment to see the full error message
  p: 8,
  rounded: 'lg',
};

export default withTheme(Dropzone);
