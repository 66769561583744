import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-autofit-right',
  'IconArrowAutofitRight',
  [
    [
      'path',
      { d: 'M20 12v-6a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v8', key: 'svg-0' },
    ],
    ['path', { d: 'M4 18h17', key: 'svg-1' }],
    ['path', { d: 'M18 15l3 3l-3 3', key: 'svg-2' }],
  ],
);
