import createReactComponent from '../createReactComponent';
export default createReactComponent('screenshot', 'IconScreenshot', [
  ['path', { d: 'M7 19a2 2 0 0 1 -2 -2', key: 'svg-0' }],
  ['path', { d: 'M5 13v-2', key: 'svg-1' }],
  ['path', { d: 'M5 7a2 2 0 0 1 2 -2', key: 'svg-2' }],
  ['path', { d: 'M11 5h2', key: 'svg-3' }],
  ['path', { d: 'M17 5a2 2 0 0 1 2 2', key: 'svg-4' }],
  ['path', { d: 'M19 11v2', key: 'svg-5' }],
  ['path', { d: 'M19 17v4', key: 'svg-6' }],
  ['path', { d: 'M21 19h-4', key: 'svg-7' }],
  ['path', { d: 'M13 19h-2', key: 'svg-8' }],
]);
