import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { LIGHT } from '../../constants/surface';
import {
  BOTTOM_BORDER,
  CONTAINED,
  PILLS,
  ROUNDED,
  TABS,
} from './horizontalNavTypes';

// @ts-expect-error TS(7006): Parameter 'color' implicitly has an 'any' type.
export const typeStyles = (color, bg = 'white') => ({
  [CONTAINED]: { overflow: 'hidden' },

  [ROUNDED]: {
    border: true,
    bg: bg,
    rounded: 'full',
    overflow: 'hidden',
    p: { x: 2, y: 1 },
  },

  [TABS]: { overflow: 'hidden' },
  [BOTTOM_BORDER]: { border: 'gray-100', borderB: 2 },
  [PILLS]: {},
});

// @ts-expect-error TS(7006): Parameter 'isFirst' implicitly has an 'any' type.
export const navItemTypeStyles = (isFirst, isLast) => ({
  [CONTAINED]: { borderR: isLast, roundedL: isFirst, roundedR: isLast },
  [ROUNDED]: {},
  [TABS]: {},
  [BOTTOM_BORDER]: {},
  [PILLS]: {},
});

const BottomBorder = ({ color, bg }: any) => (
  <Box
    className="mt-auto flex-grow"
    {...typeStyles(color, bg)[BOTTOM_BORDER]}
  />
);

type HorizontalNavProps = {
  className?: string;
  center?: boolean;
  type?: any; // TODO: PropTypes.oneOf(horizontalNavTypes)
  theme: {};
};

const HorizontalNav = forwardRef<any, HorizontalNavProps>(
  // @ts-expect-error TS(2339): Property 'bg' does not exist on type 'HorizontalNa... Remove this comment to see the full error message
  ({ bg, center, children, className, surface, type, theme, ...rest }, ref) => {
    const color = (theme as any).brandColors['primary'];
    return (
      <Box
        className={classNames(className, 'items-center overflow-y-hidden', {
          'justify-center': center,
        })}
        ref={ref}
        flex={true}
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        {...typeStyles(color, bg)[type]}
        {...rest}
      >
        {type === TABS && <BottomBorder color={color} bg={bg} />}
        {React.Children.map(
          children,
          (child, index) =>
            child &&
            React.cloneElement(child as any, {
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              ...navItemTypeStyles(index === 0, index === children.length - 1)[
                type
              ],
              surface,
              type,
            }),
        )}
        {type === TABS && <BottomBorder color={color} bg={bg} />}
      </Box>
    );
  },
);

HorizontalNav.defaultProps = {
  className: '',
  center: true,
  // @ts-expect-error TS(2322): Type '{ className: string; center: true; navOption... Remove this comment to see the full error message
  navOptions: [],
  surface: LIGHT,
  type: CONTAINED,
};

export default withTheme(HorizontalNav);
