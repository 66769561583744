import { useMemo } from 'react';
import { getJitteredPollInterval } from '../../constants/polling';
import useTabActive from './useTabActive';

const useActivePollInterval = (pollInterval?: number) => {
  const isTabActive = useTabActive();

  const jitteredPollInterval = useMemo(
    () => (isTabActive ? getJitteredPollInterval(pollInterval) : undefined),
    [isTabActive, pollInterval],
  );

  return jitteredPollInterval;
};

export default useActivePollInterval;
