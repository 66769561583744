import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-reason', 'IconBrandReason', [
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 18h-3v-6h3', key: 'svg-1' }],
  ['path', { d: 'M18 15h-3', key: 'svg-2' }],
  ['path', { d: 'M8 18v-6h2.5a1.5 1.5 0 0 1 0 3h-2.5', key: 'svg-3' }],
  ['path', { d: 'M12 18l-2 -3', key: 'svg-4' }],
]);
