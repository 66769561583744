import createReactComponent from '../createReactComponent';
export default createReactComponent('plant', 'IconPlant', [
  [
    'path',
    { d: 'M7 15h10v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2v-4z', key: 'svg-0' },
  ],
  ['path', { d: 'M12 9a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3', key: 'svg-1' }],
  ['path', { d: 'M12 11a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3', key: 'svg-2' }],
  ['path', { d: 'M12 15l0 -6', key: 'svg-3' }],
]);
