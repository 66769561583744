import createReactComponent from '../createReactComponent';
export default createReactComponent('solar-panel-2', 'IconSolarPanel2', [
  ['path', { d: 'M8 2a4 4 0 1 0 8 0', key: 'svg-0' }],
  ['path', { d: 'M4 3h1', key: 'svg-1' }],
  ['path', { d: 'M19 3h1', key: 'svg-2' }],
  ['path', { d: 'M12 9v1', key: 'svg-3' }],
  ['path', { d: 'M17.2 7.2l.707 .707', key: 'svg-4' }],
  ['path', { d: 'M6.8 7.2l-.7 .7', key: 'svg-5' }],
  [
    'path',
    {
      d: 'M4.28 21h15.44a1 1 0 0 0 .97 -1.243l-1.5 -6a1 1 0 0 0 -.97 -.757h-12.44a1 1 0 0 0 -.97 .757l-1.5 6a1 1 0 0 0 .97 1.243z',
      key: 'svg-6',
    },
  ],
  ['path', { d: 'M4 17h16', key: 'svg-7' }],
  ['path', { d: 'M10 13l-1 8', key: 'svg-8' }],
  ['path', { d: 'M14 13l1 8', key: 'svg-9' }],
]);
