import { useMemo } from 'react';
import { SelectInput, SwitchButton } from '@noloco/components';
import { LG, MD, SM, XL } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeNavigation from '@noloco/core/src/components/buildMode/BuildModeNavigation';
import { isPageFullScreenIframe } from '@noloco/core/src/components/buildMode/LeftBuildModePageEditor';
import { TEXT } from '@noloco/core/src/constants/dataTypes';
import { IFRAME, VIDEO } from '@noloco/core/src/constants/elements';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Element, ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import { Page } from '@noloco/core/src/utils/pages';
import { UpdatePropertyCallback } from '../../../utils/hooks/projectHooks';
import DataFieldIcon from '../../DataFieldIcon';
import StringPropEditor from '../../canvas/StringPropEditor';

type IframeSectionEditorProps = {
  children?: React.ReactNode;
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  element?: Page;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
  updateIframe?: UpdatePropertyCallback;
  updateProperty: UpdatePropertyCallback;
};

const IframeSectionEditor = ({
  children,
  dataType,
  debouncedUpdateProperty,
  element,
  project,
  section,
  sectionPropPath,
  updateIframe,
  updateProperty,
}: IframeSectionEditorProps) => {
  const { source, field, type, size, fullScreen = false } = section.props || {};
  const typeOptions = useMemo(
    () =>
      ['field', 'url'].map((typeOption) => ({
        value: typeOption,
        label: getText('elements', VIDEO, 'options', typeOption),
      })),
    [],
  );
  const sizeOptions = [SM, MD, LG, XL];
  const fieldOptions = useMemo(
    () =>
      dataType.fields
        .filter((field: any) => field.type === TEXT)
        .map((field: any) => ({
          value: field.name,

          label: (
            <div className="flex items-center">
              <DataFieldIcon
                field={field}
                className="mr-2 opacity-75"
                size={18}
              />
              {field.display}
            </div>
          ),
        })),
    [dataType.fields],
  );

  return (
    <div className="flex w-full flex-col">
      <div className="space-y-4 p-2">
        <p className="text-slate-400">{getText('elements', IFRAME, 'help')}</p>
        <BuildModeInput label={getText('elements', IFRAME, 'options.label')}>
          <SelectInput
            value={type}
            onChange={(value: any) => updateProperty(['type'], value)}
            options={typeOptions}
            placeholder={getText('elements', IFRAME, 'options.placeholder')}
          />
        </BuildModeInput>
        {type === 'field' && (
          <BuildModeInput label={getText('elements', IFRAME, 'fields.title')}>
            <SelectInput
              value={field}
              onChange={(value: any) => updateProperty(['field'], value)}
              options={fieldOptions}
              placeholder={getText('elements', IFRAME, 'fields.placeholder')}
            />
          </BuildModeInput>
        )}
        {type === 'url' && (
          <BuildModeInput label={getText('elements', IFRAME, 'url.title')}>
            <StringPropEditor
              // @ts-expect-error TS(2322): Type '{ elementPath: any; project: any; onChange: ... Remove this comment to see the full error message
              elementPath={sectionPropPath}
              multiLine={true}
              project={project}
              onChange={(option: any) =>
                debouncedUpdateProperty(['source'], option)
              }
              value={source}
            />
          </BuildModeInput>
        )}
        {!fullScreen && (
          <BuildModeInput label={getText('elements', IFRAME, 'size.label')}>
            <SwitchButton
              className="h-8 w-full rounded-lg"
              inverseColors={true}
              onChange={(value) => updateProperty(['size'], value)}
              options={sizeOptions.map((sizeOption) => ({
                label: getText('elements.SELECT_INPUT.smallSizes', sizeOption),
                value: sizeOption,
              }))}
              value={size || MD}
            />
          </BuildModeInput>
        )}
        {children}
      </div>
      {fullScreen && element && (
        <BuildModeNavigation
          element={element as Page}
          project={project}
          updateProperty={
            (isPageFullScreenIframe(element)
              ? updateIframe
              : updateProperty) as UpdatePropertyCallback
          }
        />
      )}
    </div>
  );
};

export default IframeSectionEditor;
