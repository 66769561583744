import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import { BaseRecord } from '../../../models/Record';

export type DragggableRecordProps = {
  elementRef: React.ForwardedRef<any>;
  record: BaseRecord;
  draggable: boolean;
  groupKey: string;
};

export const useSetRef = (
  elementRef: React.ForwardedRef<any>,
  onSetRef: (node: Element) => void,
) => {
  const setRef = useCallback(
    (node: Element | null) => {
      if (!node) {
        return null;
      }
      onSetRef(node);
      if (typeof elementRef === 'function') {
        elementRef(node);
      } else if (elementRef?.current) {
        elementRef.current = node;
      }
      return node;
    },
    [onSetRef, elementRef],
  );

  return setRef;
};

export const useDraggableRecord = ({
  elementRef,
  record,
  draggable,
  groupKey,
}: DragggableRecordProps) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'record',
      canDrag: draggable,
      item: { groupKey, record },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [record, draggable, groupKey],
  );

  const dragRef = useSetRef(elementRef, drag);

  return { isDragging, dragRef };
};

const DraggableRecord = ({
  className,
  children,
  draggable,
  groupKey,
  record,
}: any) => {
  const elementRef = useRef<HTMLDivElement | undefined>();

  const { isDragging, dragRef } = useDraggableRecord({
    elementRef,
    draggable,
    groupKey,
    record,
  });

  return (
    <div
      className={classNames(className, {
        'opacity-50': isDragging,
      })}
      ref={dragRef}
    >
      {children}
    </div>
  );
};

export default DraggableRecord;
