import createReactComponent from '../createReactComponent';
export default createReactComponent('certificate-off', 'IconCertificateOff', [
  [
    'path',
    {
      d: 'M12.876 12.881a3 3 0 0 0 4.243 4.243m.588 -3.42a3.012 3.012 0 0 0 -1.437 -1.423',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 17.5v4.5l2 -1.5l2 1.5v-4.5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2m4 0h10a2 2 0 0 1 2 2v10',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M6 9h3m4 0h5', key: 'svg-3' }],
  ['path', { d: 'M6 12h3', key: 'svg-4' }],
  ['path', { d: 'M6 15h2', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
