import createReactComponent from '../createReactComponent';
export default createReactComponent('player-eject', 'IconPlayerEject', [
  ['path', { d: 'M5 12h14l-7 -8z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5 16m0 1a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
]);
