import createReactComponent from '../createReactComponent';
export default createReactComponent('device-sim-1', 'IconDeviceSim1', [
  [
    'path',
    {
      d: 'M6 3h8.5l4.5 4.5v12.5a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 11l2 -2v8', key: 'svg-1' }],
]);
