import createReactComponent from '../createReactComponent';
export default createReactComponent('layers-off', 'IconLayersOff', [
  [
    'path',
    {
      d: 'M8.59 4.581c.362 -.359 .86 -.581 1.41 -.581h8a2 2 0 0 1 2 2v8c0 .556 -.227 1.06 -.594 1.422m-3.406 .578h-6a2 2 0 0 1 -2 -2v-6',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
