import createReactComponent from '../createReactComponent';
export default createReactComponent('http-options', 'IconHttpOptions', [
  [
    'path',
    {
      d: 'M5 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 12h2a2 2 0 1 0 0 -4h-2v8', key: 'svg-1' }],
  ['path', { d: 'M17 8h4', key: 'svg-2' }],
  ['path', { d: 'M19 8v8', key: 'svg-3' }],
]);
