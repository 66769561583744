import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'gender-genderqueer',
  'IconGenderGenderqueer',
  [
    ['path', { d: 'M12 11a5 5 0 1 1 0 10a5 5 0 0 1 0 -10z', key: 'svg-0' }],
    ['path', { d: 'M12 11v-8', key: 'svg-1' }],
    ['path', { d: 'M14.5 4.5l-5 3', key: 'svg-2' }],
    ['path', { d: 'M9.5 4.5l5 3', key: 'svg-3' }],
  ],
);
