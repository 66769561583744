import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-left-square',
  'IconArrowLeftSquare',
  [
    ['path', { d: 'M17 12h-14', key: 'svg-0' }],
    ['path', { d: 'M6 9l-3 3l3 3', key: 'svg-1' }],
    ['path', { d: 'M21 14h-4v-4h4z', key: 'svg-2' }],
  ],
);
