import createReactComponent from '../createReactComponent';
export default createReactComponent('ladder-off', 'IconLadderOff', [
  ['path', { d: 'M8 3v1m0 4v13', key: 'svg-0' }],
  ['path', { d: 'M16 3v9m0 4v5', key: 'svg-1' }],
  ['path', { d: 'M8 14h6', key: 'svg-2' }],
  ['path', { d: 'M8 10h2m4 0h2', key: 'svg-3' }],
  ['path', { d: 'M10 6h6', key: 'svg-4' }],
  ['path', { d: 'M8 18h8', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
