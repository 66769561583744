import React from 'react';
import FormField from '../form/FormField';
import SimpleLayout from './SimpleLayout';
import SubmitButton from './SubmitButton';

const SimpleRegister = ({
  disabled,
  errors,
  logoUrl,
  emailLabel,
  phoneNumberLabel,
  onSubmit,
  buttonText,
  confirmPasswordLabel,
  passwordLabel,
  hideEmail,
  loginText,
  titleText,
  email,
  phoneNumber,
  password,
  confirmPassword,
  setEmail,
  setPhoneNumber,
  setPassword,
  setConfirmPassword,
  emailErrorMessage,
  passwordErrorMessage,
  confirmPasswordErrorMessage,
  surface,
  socialLogins,
}: any) => {
  return (
    <SimpleLayout
      errors={errors}
      logoUrl={logoUrl}
      onSubmit={onSubmit}
      subTitleText={loginText}
      titleText={titleText}
    >
      <>
        {!hideEmail && (
          <div>
            <FormField
              aria-label="Email address"
              autoComplete="email"
              name="email"
              type="email"
              onChange={({ target: { value } }: any) => setEmail(value)}
              required
              placeholder={emailLabel}
              value={email}
              errorMessage={emailErrorMessage}
              errorType="below-solid"
              surface={surface}
            />
          </div>
        )}
        <div className="mt3">
          <FormField
            aria-label="Phone number"
            autoComplete="phoneNumber"
            className="mt-3"
            name="phoneNumber"
            type="phoneNumber"
            onChange={({ target: { value } }: any) => setPhoneNumber(value)}
            errorType="below-solid"
            label={phoneNumberLabel}
            placeholder=""
            value={phoneNumber}
            errorMessage={null}
            surface={surface}
          />
        </div>
        <div className="mt-3">
          <FormField
            aria-label="Password"
            autoComplete="current-password"
            name="password"
            type="password"
            onChange={({ target: { value } }: any) => setPassword(value)}
            required
            placeholder={passwordLabel}
            value={password}
            errorMessage={passwordErrorMessage}
            errorType="below-solid"
            surface={surface}
          />
        </div>
        <div className="mt-3">
          <FormField
            aria-label="Confirm password"
            name="confirm-password"
            type="password"
            onChange={({ target: { value } }: any) => setConfirmPassword(value)}
            required
            placeholder={confirmPasswordLabel}
            value={confirmPassword}
            errorMessage={confirmPasswordErrorMessage}
            errorType="below-solid"
            surface={surface}
          />
        </div>
        <SubmitButton disabled={disabled}>{buttonText}</SubmitButton>
        {socialLogins}
      </>
    </SimpleLayout>
  );
};

export default SimpleRegister;
