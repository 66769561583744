import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Userpilot } from 'userpilot';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import {
  projectIdSelector,
  projectNameSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import {
  getUserIdentifier,
  useAuth,
} from '@noloco/core/src/utils/hooks/useAuth';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import useScript from '@noloco/core/src/utils/hooks/useScript';
import { getFullName } from '@noloco/core/src/utils/user';
import { IS_PRODUCTION } from '../../constants/env';

const SATISMETER_SCRIPT_SRC = 'https://app.satismeter.com/js';
const SATISMETER_WRITE_KEY = 'SepBhhfrncJqVOtc';

const USER_PILOT_APP_TOKEN = 'NX-4bb30743';

const useBuilderOnlyScripts = () => {
  useScript(SATISMETER_SCRIPT_SRC);

  const { location } = useRouter();
  const { user } = useAuth();
  const { isNolocoAdmin } = useIsBuilder();
  const projectName = useSelector(projectNameSelector);
  const projectId = useSelector(projectIdSelector);

  const billingPlan = useSelector(billingPlanSelector);

  useEffect(() => {
    if (IS_PRODUCTION) {
      Userpilot.initialize(USER_PILOT_APP_TOKEN);
    }
  }, []);

  const attributes = useMemo(() => {
    if (
      billingPlan &&
      user &&
      user._nolocoUserId !== undefined &&
      IS_PRODUCTION &&
      !isNolocoAdmin
    ) {
      const attribs = {
        writeKey: SATISMETER_WRITE_KEY,
        userId: getUserIdentifier(user, `NOLOCO:`),
        traits: {
          email: user.email,
          created_at: user.createdAt,
          name: getFullName(user),
          plan: billingPlan.type,
          status: billingPlan.status,
          trial_end_at: billingPlan.trialEnd,
          period_end_at: billingPlan.periodEnd,
          project_name: projectName,
          project_id: projectId,
        },
      };

      return attribs;
    }
  }, [billingPlan, isNolocoAdmin, projectId, projectName, user]);

  const shouldLoadScripts = useMemo(() => !!attributes, [attributes]);

  // Satismeter
  useEffect(() => {
    if (attributes) {
      const satismeter = window.satismeter;
      if (satismeter && !satismeter.initialized) {
        satismeter(attributes);
      } else {
        console.error('SatisMeter not yet loaded');
      }
    }
  }, [attributes]);

  // UserPilot
  useEffect(() => {
    if (attributes) {
      Userpilot.identify(attributes.userId, attributes.traits);
    }
  }, [attributes]);

  useEffect(() => {
    if (IS_PRODUCTION && shouldLoadScripts) {
      Userpilot.reload();
    }
  }, [location, shouldLoadScripts]);
};

export default useBuilderOnlyScripts;
