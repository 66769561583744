import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'transition-bottom',
  'IconTransitionBottom',
  [
    ['path', { d: 'M21 18a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M3 3m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v0a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M12 9v8', key: 'svg-2' }],
    ['path', { d: 'M9 14l3 3l3 -3', key: 'svg-3' }],
  ],
);
