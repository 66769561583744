import createReactComponent from '../createReactComponent';
export default createReactComponent('json', 'IconJson', [
  ['path', { d: 'M20 16v-8l3 8v-8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M1 8h3v6.5a1.5 1.5 0 0 1 -3 0v-.5', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M7 15a1 1 0 0 0 1 1h1a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h1a1 1 0 0 1 1 1',
      key: 'svg-3',
    },
  ],
]);
