import createReactComponent from '../createReactComponent';
export default createReactComponent('play-card-off', 'IconPlayCardOff', [
  [
    'path',
    {
      d: 'M7 3h10a2 2 0 0 1 2 2v10m0 4a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-14',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 18h.01', key: 'svg-1' }],
  ['path', { d: 'M13.716 13.712l-1.716 2.288l-3 -4l1.29 -1.72', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
