import createReactComponent from '../createReactComponent';
export default createReactComponent('password', 'IconPassword', [
  ['path', { d: 'M12 10v4', key: 'svg-0' }],
  ['path', { d: 'M10 13l4 -2', key: 'svg-1' }],
  ['path', { d: 'M10 11l4 2', key: 'svg-2' }],
  ['path', { d: 'M5 10v4', key: 'svg-3' }],
  ['path', { d: 'M3 13l4 -2', key: 'svg-4' }],
  ['path', { d: 'M3 11l4 2', key: 'svg-5' }],
  ['path', { d: 'M19 10v4', key: 'svg-6' }],
  ['path', { d: 'M17 13l4 -2', key: 'svg-7' }],
  ['path', { d: 'M17 11l4 2', key: 'svg-8' }],
]);
