import createReactComponent from '../createReactComponent';
export default createReactComponent('mountain', 'IconMountain', [
  [
    'path',
    {
      d: 'M3 20h18l-6.921 -14.612a2.3 2.3 0 0 0 -4.158 0l-6.921 14.612z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7.5 11l2 2.5l2.5 -2.5l2 3l2.5 -2', key: 'svg-1' }],
]);
