import createReactComponent from '../createReactComponent';
export default createReactComponent('helicopter', 'IconHelicopter', [
  ['path', { d: 'M3 10l1 2h6', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 9a2 2 0 0 0 -2 2v3c0 1.1 .9 2 2 2h7a2 2 0 0 0 2 -2c0 -3.31 -3.13 -5 -7 -5h-2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M13 9l0 -3', key: 'svg-2' }],
  ['path', { d: 'M5 6l15 0', key: 'svg-3' }],
  ['path', { d: 'M15 9.1v3.9h5.5', key: 'svg-4' }],
  ['path', { d: 'M15 19l0 -3', key: 'svg-5' }],
  ['path', { d: 'M19 19l-8 0', key: 'svg-6' }],
]);
