import createReactComponent from '../createReactComponent';
export default createReactComponent('droplet-star', 'IconDropletStar', [
  [
    'path',
    {
      d: 'M17.496 10.034l-4.321 -6.417c-.421 -.625 -1.288 -.803 -1.937 -.397a1.376 1.376 0 0 0 -.41 .397l-4.893 7.26c-1.695 2.838 -1.035 6.441 1.567 8.546a7.106 7.106 0 0 0 3.547 1.517',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z',
      key: 'svg-1',
    },
  ],
]);
