import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'chart-arrows-vertical',
  'IconChartArrowsVertical',
  [
    ['path', { d: 'M18 21v-14', key: 'svg-0' }],
    ['path', { d: 'M9 15l3 -3l3 3', key: 'svg-1' }],
    ['path', { d: 'M15 10l3 -3l3 3', key: 'svg-2' }],
    ['path', { d: 'M3 21l18 0', key: 'svg-3' }],
    ['path', { d: 'M12 21l0 -9', key: 'svg-4' }],
    ['path', { d: 'M3 6l3 -3l3 3', key: 'svg-5' }],
    ['path', { d: 'M6 21v-18', key: 'svg-6' }],
  ],
);
