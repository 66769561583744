import { DependencyList, useCallback, useEffect, useRef } from 'react';

const useRefCallback = <TArgs extends any[]>(
  callback: (...args: TArgs) => void,
  deps: DependencyList,
) => {
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return useCallback((...args: TArgs) => {
    ref.current(...args);
  }, []);
};

export default useRefCallback;
