import { DateTime } from 'luxon';

export const presetRanges = [
  'last7Days',
  'last14Days',
  'last28Days',
  'last30Days',
  'today',
  'yesterday',
  'thisWeek',
  'thisWeekToDate',
  'lastWeek',
  'thisMonth',
  'thisMonthToDate',
  'lastMonth',
  'thisQuarter',
  'thisQuarterToDate',
  'lastQuarter',
  'thisYear',
  'thisYearToDate',
  'lastYear',
] as const;

export type PresetRangeOptions = (typeof presetRanges)[number];

export const getDateRangeFromPresetString = (
  preset: PresetRangeOptions,
): [Date | null, Date | null] => {
  const now = DateTime.now();
  let startDate: DateTime | null;
  let endDate: DateTime | null;

  switch (preset) {
    case 'last7Days':
      startDate = now.minus({ days: 6 });
      endDate = now;
      break;
    case 'last14Days':
      startDate = now.minus({ days: 13 });
      endDate = now;
      break;
    case 'last28Days':
      startDate = now.minus({ days: 27 });
      endDate = now;
      break;
    case 'last30Days':
      startDate = now.minus({ days: 29 });
      endDate = now;
      break;
    case 'today':
      startDate = now;
      endDate = now;
      break;
    case 'yesterday':
      startDate = now.minus({ days: 1 });
      endDate = now.minus({ days: 1 });
      break;
    case 'thisWeek':
      startDate = now.startOf('week');
      endDate = now.endOf('week');
      break;
    case 'thisWeekToDate':
      startDate = now.startOf('week');
      endDate = now;
      break;
    case 'lastWeek':
      startDate = now.minus({ weeks: 1 }).startOf('week');
      endDate = now.minus({ weeks: 1 }).endOf('week');
      break;
    case 'thisMonth':
      startDate = now.startOf('month');
      endDate = now.endOf('month');
      break;
    case 'thisMonthToDate':
      startDate = now.startOf('month');
      endDate = now;
      break;
    case 'lastMonth':
      startDate = now.minus({ months: 1 }).startOf('month');
      endDate = now.minus({ months: 1 }).endOf('month');
      break;
    case 'thisQuarter':
      startDate = now.startOf('quarter');
      endDate = now.endOf('quarter');
      break;
    case 'thisQuarterToDate':
      startDate = now.startOf('quarter');
      endDate = now;
      break;
    case 'lastQuarter':
      startDate = now.minus({ quarters: 1 }).startOf('quarter');
      endDate = now.minus({ quarters: 1 }).endOf('quarter');
      break;
    case 'thisYear':
      startDate = now.startOf('year');
      endDate = now.endOf('year');
      break;
    case 'thisYearToDate':
      startDate = now.startOf('year');
      endDate = now;
      break;
    case 'lastYear':
      startDate = now.minus({ years: 1 }).startOf('year');
      endDate = now.minus({ years: 1 }).endOf('year');
      break;
    default:
      startDate = null;
      endDate = null;
  }
  if (!startDate || !endDate) {
    return [null, null];
  }
  return [startDate.toJSDate(), endDate.toJSDate()];
};
