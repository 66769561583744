import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-codecov', 'IconBrandCodecov', [
  [
    'path',
    {
      d: 'M9.695 12.985a5.972 5.972 0 0 0 -3.295 -.985c-1.257 0 -2.436 .339 -3.4 1a9 9 0 1 1 18 0c-.966 -.664 -2.14 -1 -3.4 -1a6 6 0 0 0 -5.605 8.144',
      key: 'svg-0',
    },
  ],
]);
