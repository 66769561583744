import React from 'react';
import classNames from 'classnames';
import { Badge, Surface } from '@noloco/components';
import { RecordValue } from '../../../models/Record';

type ArrayValueBadgeProps = {
  className?: string;
  m?: Record<string, number>;
  value: RecordValue | JSX.Element;
  surface?: Surface;
};
const ArrayValueBadge: React.FunctionComponent<ArrayValueBadgeProps> = ({
  className,
  m,
  value,
  surface,
}: any) => (
  <Badge
    className={classNames(className, 'h-auto break-words leading-normal')}
    color="gray"
    surface={surface}
    m={m}
  >
    {value}
  </Badge>
);

export default ArrayValueBadge;
