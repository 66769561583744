import createReactComponent from '../createReactComponent';
export default createReactComponent('tie', 'IconTie', [
  [
    'path',
    {
      d: 'M12 22l4 -4l-2.5 -11l.993 -2.649a1 1 0 0 0 -.936 -1.351h-3.114a1 1 0 0 0 -.936 1.351l.993 2.649l-2.5 11l4 4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10.5 7h3l5 5.5', key: 'svg-1' }],
]);
