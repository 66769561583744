import createReactComponent from '../createReactComponent';
export default createReactComponent('microphone-off', 'IconMicrophoneOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 5a3 3 0 0 1 6 0v5a3 3 0 0 1 -.13 .874m-2 2a3 3 0 0 1 -3.87 -2.872v-1',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M5 10a7 7 0 0 0 10.846 5.85m2 -2a6.967 6.967 0 0 0 1.152 -3.85',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M8 21l8 0', key: 'svg-3' }],
  ['path', { d: 'M12 17l0 4', key: 'svg-4' }],
]);
