import { forwardRef, useMemo } from 'react';
import get from 'lodash/get';
import { Item } from '../../../constants/buildMode';
import { DIVIDER, FOLDER, VIEW } from '../../../constants/elements';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import useRouter from '../../../utils/hooks/useRouter';
import { Page } from '../../../utils/pages';
import { getPageTo, isPageActive } from '../../../utils/urls';
import withVisibilityRules from '../../canvas/withVisibilityRules';
import BaseBuildModePages from '../BaseBuildModePages';
import BuildModeSidebarItem from './BuildModeSidebarItem';

type DraggableBuildModeSidebarItemProps = {
  elementPath: ElementPath;
  isDragging?: boolean;
  isOver?: boolean;
  listExpanded?: boolean;
  item: Item;
  portalPages: Page[];
  project: Project;
  showHiddenPages?: boolean;
  sidebarExpanded?: boolean;
};

const DraggableBuildModeSidebarItem = forwardRef(
  (
    {
      elementPath,
      isDragging = false,
      isOver = false,
      listExpanded = false,
      item,
      portalPages,
      project,
      showHiddenPages = false,
      sidebarExpanded = true,
    }: DraggableBuildModeSidebarItemProps,
    dragRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { pathname } = useRouter();
    const to = useMemo(
      () => getPageTo(['PORTAL', item.id], {}, portalPages, {}),
      [item.id, portalPages],
    );

    const active = useMemo(
      () => isPageActive(item.page, pathname, to),
      [item, pathname, to],
    );

    const hasChildPages = useMemo(
      () =>
        (item.childItems && item.childItems.length > 0) ||
        get(item, 'page.props.SubPages', []).length > 0,
      [item],
    );

    const SidebarItemVisibilityRules = useMemo(
      () => withVisibilityRules('div', true, get(item, 'page.visibilityRules')),
      [item],
    );

    const isPageSubView = useMemo(() => {
      if (active) {
        const pageDataType = get(item, 'page.props.dataList.dataType', null);

        if (item.parentPageId) {
          const parentPage = portalPages.find(
            (portalPage) => portalPage.id === item.parentPageId,
          );

          if (parentPage?.type === FOLDER) {
            return true;
          }

          if (parentPage?.type === VIEW) {
            const parentDataType = get(
              parentPage,
              'props.dataList.dataType',
              null,
            );

            return parentDataType === pageDataType;
          }
        }
      }

      return false;
    }, [active, item, portalPages]);

    const shouldRenderBaseBuildModeItems = useMemo(
      () => item.page.type === VIEW && !isPageSubView && sidebarExpanded,
      [item, isPageSubView, sidebarExpanded],
    );

    const shouldRenderNavItem = useMemo(
      () =>
        item &&
        !!item.id &&
        !!item.page.type &&
        (get(item, 'page.props') || item.page.type === DIVIDER) &&
        !get(item, 'page.props.dataType', null) &&
        showHiddenPages
          ? item.parentPageId
            ? portalPages.find(
                (portalPage) => portalPage.id === item.parentPageId,
              )?.props.hide
            : get(item, 'page.props.hide', false)
          : !get(item, 'page.props.hide', false) && item.id !== 'PROFILE',
      [item, showHiddenPages, portalPages],
    );

    if (!shouldRenderNavItem) {
      return null;
    }

    return (
      <div ref={dragRef}>
        <SidebarItemVisibilityRules
          key={item.id}
          page={item.page}
          path={elementPath}
          project={project}
        >
          <div className="flex w-full flex-col">
            <BuildModeSidebarItem
              active={active}
              elementPath={elementPath}
              hasChildPages={hasChildPages}
              isDragging={isDragging}
              isOver={isOver}
              page={item.page}
              project={project}
              ref={dragRef}
              sidebarExpanded={sidebarExpanded}
              to={to}
            />
            {(active || listExpanded) && shouldRenderBaseBuildModeItems && (
              <BaseBuildModePages
                element={get(project, ['elements', ...elementPath])}
              />
            )}
          </div>
        </SidebarItemVisibilityRules>
      </div>
    );
  },
);

export default DraggableBuildModeSidebarItem;
