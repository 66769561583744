import { BOOL } from '../../constants/elementPropTypeTypes';
import ElementPropType from './ElementPropType';

class BoolType extends ElementPropType {
  constructor() {
    super(BOOL);
  }
}

export default BoolType;
