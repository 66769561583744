import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-flipboard', 'IconBrandFlipboard', [
  [
    'path',
    {
      d: 'M3.973 3h16.054c.537 0 .973 .436 .973 .973v4.052a.973 .973 0 0 1 -.973 .973h-5.025v4.831c0 .648 -.525 1.173 -1.173 1.173h-4.829v5.025a.973 .973 0 0 1 -.974 .973h-4.053a.973 .973 0 0 1 -.973 -.973v-16.054c0 -.537 .436 -.973 .973 -.973z',
      key: 'svg-0',
    },
  ],
]);
