import createReactComponent from '../createReactComponent';
export default createReactComponent('brightness-down', 'IconBrightnessDown', [
  ['path', { d: 'M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M12 5l0 .01', key: 'svg-1' }],
  ['path', { d: 'M17 7l0 .01', key: 'svg-2' }],
  ['path', { d: 'M19 12l0 .01', key: 'svg-3' }],
  ['path', { d: 'M17 17l0 .01', key: 'svg-4' }],
  ['path', { d: 'M12 19l0 .01', key: 'svg-5' }],
  ['path', { d: 'M7 17l0 .01', key: 'svg-6' }],
  ['path', { d: 'M5 12l0 .01', key: 'svg-7' }],
  ['path', { d: 'M7 7l0 .01', key: 'svg-8' }],
]);
