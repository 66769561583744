import createReactComponent from '../createReactComponent';
export default createReactComponent('server-off', 'IconServerOff', [
  [
    'path',
    {
      d: 'M12 12h-6a3 3 0 0 1 -3 -3v-2c0 -1.083 .574 -2.033 1.435 -2.56m3.565 -.44h10a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16 12h2a3 3 0 0 1 3 3v2m-1.448 2.568a2.986 2.986 0 0 1 -1.552 .432h-12a3 3 0 0 1 -3 -3v-2a3 3 0 0 1 3 -3h6',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 8v.01', key: 'svg-2' }],
  ['path', { d: 'M7 16v.01', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
