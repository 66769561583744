import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'alert-square-rounded',
  'IconAlertSquareRounded',
  [
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12 8v4', key: 'svg-1' }],
    ['path', { d: 'M12 16h.01', key: 'svg-2' }],
  ],
);
