import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-tabler', 'IconBrandTabler', [
  ['path', { d: 'M8 9l3 3l-3 3', key: 'svg-0' }],
  ['path', { d: 'M13 15l3 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z',
      key: 'svg-2',
    },
  ],
]);
