import createReactComponent from '../createReactComponent';
export default createReactComponent('speakerphone', 'IconSpeakerphone', [
  ['path', { d: 'M18 8a3 3 0 0 1 0 6', key: 'svg-0' }],
  [
    'path',
    { d: 'M10 8v11a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1v-5', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M12 8h0l4.524 -3.77a.9 .9 0 0 1 1.476 .692v12.156a.9 .9 0 0 1 -1.476 .692l-4.524 -3.77h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h8',
      key: 'svg-2',
    },
  ],
]);
