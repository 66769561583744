import React from 'react';
import { TablerIconsProps } from '@tabler/icons-react';
import classNames from 'classnames';

const Star = ({
  className,
  filled = false,
  size,
}: TablerIconsProps & {
  className?: string;
  filled?: boolean;
}) => (
  <svg
    className={classNames('icon icon-tabler icon-tabler-star', className)}
    viewBox="0 0 24 24"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    d="M0 0h24v24H0z"
  >
    <path
      d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
      fill={filled ? 'currentColor' : undefined}
    />
  </svg>
);

export default Star;
