import createReactComponent from '../createReactComponent';
export default createReactComponent('photo-question', 'IconPhotoQuestion', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15 21h-9a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 16l5 -5c.928 -.893 2.072 -.893 3 0l3 3', key: 'svg-2' }],
  ['path', { d: 'M19 22v.01', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-4',
    },
  ],
]);
