import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'git-branch-deleted',
  'IconGitBranchDeleted',
  [
    ['path', { d: 'M7 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M7 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
    ['path', { d: 'M7 8v8', key: 'svg-2' }],
    ['path', { d: 'M9 18h6a2 2 0 0 0 2 -2v-5', key: 'svg-3' }],
    ['path', { d: 'M14 14l3 -3l3 3', key: 'svg-4' }],
    ['path', { d: 'M15 4l4 4', key: 'svg-5' }],
    ['path', { d: 'M15 8l4 -4', key: 'svg-6' }],
  ],
);
