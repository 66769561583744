import createReactComponent from '../createReactComponent';
export default createReactComponent('alphabet-greek', 'IconAlphabetGreek', [
  ['path', { d: 'M10 10v7', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5 10m0 2a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M14 20v-11a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2',
      key: 'svg-2',
    },
  ],
]);
