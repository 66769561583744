import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-down-rhombus',
  'IconArrowDownRhombus',
  [
    ['path', { d: 'M12 8v13', key: 'svg-0' }],
    ['path', { d: 'M15 18l-3 3l-3 -3', key: 'svg-1' }],
    ['path', { d: 'M14.5 5.5l-2.5 -2.5l-2.5 2.5l2.5 2.5z', key: 'svg-2' }],
  ],
);
