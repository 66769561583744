import createReactComponent from '../createReactComponent';
export default createReactComponent('e-passport', 'IconEPassport', [
  [
    'path',
    {
      d: 'M2 5m0 2a2 2 0 0 1 2 -2h16a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-16a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M9 12h-7', key: 'svg-2' }],
  ['path', { d: 'M15 12h7', key: 'svg-3' }],
]);
