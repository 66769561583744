import createReactComponent from '../createReactComponent';
export default createReactComponent('droplet-x', 'IconDropletX', [
  [
    'path',
    {
      d: 'M18.953 13.467a6.572 6.572 0 0 0 -.889 -2.59l-4.89 -7.26c-.42 -.625 -1.287 -.803 -1.936 -.397a1.376 1.376 0 0 0 -.41 .397l-4.893 7.26c-1.695 2.838 -1.035 6.441 1.567 8.546a7.179 7.179 0 0 0 5.633 1.49',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-1' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-2' }],
]);
