import createReactComponent from '../createReactComponent';
export default createReactComponent('mood-off', 'IconMoodOff', [
  [
    'path',
    {
      d: 'M5.634 5.638a9 9 0 0 0 12.732 12.724m1.679 -2.322a9 9 0 0 0 -12.08 -12.086',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 10h.01', key: 'svg-1' }],
  ['path', { d: 'M15 10h.01', key: 'svg-2' }],
  ['path', { d: 'M9.5 15a3.5 3.5 0 0 0 5 0', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
