class SafeStorage {
  _handleError(e: any) {
    console.warn('Error using local storage', e);
  }

  get(key: string): any {
    try {
      return typeof localStorage !== 'undefined' && localStorage.getItem(key);
    } catch (e) {
      this._handleError(e);
    }
    return null;
  }

  getBoolean(key: string, defaultValue: boolean): boolean {
    const value = this.get(key);
    if (defaultValue !== undefined && value === null) {
      return defaultValue;
    }

    return this.get(key) === 'true';
  }

  getJson(key: string, defaultValue?: any): any {
    const value = this.get(key);
    if (defaultValue !== undefined && value === null) {
      return defaultValue;
    }

    try {
      return JSON.parse(value);
    } catch {
      return defaultValue;
    }
  }

  set(key: string, value: any) {
    if (typeof localStorage !== 'undefined') {
      try {
        localStorage.setItem(key, value);
      } catch (e) {
        this._handleError(e);
      }
    }
  }

  setJson(key: string, value: any) {
    return this.set(key, JSON.stringify(value));
  }

  remove(key: string): void {
    if (typeof localStorage !== 'undefined') {
      try {
        localStorage.removeItem(key);
      } catch (e) {
        this._handleError(e);
      }
    }
  }
}

export default SafeStorage;
