import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { AlertInstance } from 'react-alert';
import NotificationConsentModal from '../../components/NotificationConsentModal';
import { VAPID_PUBLIC_KEY } from '../../constants/env';
import { getText } from '../lang';
import { useInfoAlert } from './useAlerts';
import { useAuth } from './useAuth';
import useLocalStorageState from './useLocalStorageState';

const SUBSCRIBE_QUERY_STRING = gql`
  mutation createPushSubscription(
    $auth: String!
    $endpoint: String!
    $expirationTime: DateTime
    $p256dh: String!
  ) {
    createPushSubscription(
      auth: $auth
      endpoint: $endpoint
      expirationTime: $expirationTime
      p256dh: $p256dh
    ) {
      id
    }
  }
`;

export const useServiceWorkerNotifications = (projectName: string) => {
  const subscribeQueryOptions = useMemo(
    () => ({
      context: {
        authQuery: true,
        projectQuery: true,
        projectName: projectName,
      },
    }),
    [projectName],
  );
  const [subscribeMutation] = useMutation(
    SUBSCRIBE_QUERY_STRING,
    subscribeQueryOptions,
  );

  const [hidden, setHidden] = useLocalStorageState('noloco.push.hidden', false);
  const [subscription] = useLocalStorageState('noloco.push.subscription', null);
  const [isSubscribed, setSubscribed] = useLocalStorageState(
    'noloco.push.subscribed',
    false,
  );

  const [vapidPublicKey, setVapidPublicKey] = useLocalStorageState(
    'noloco.push.vapidPublicKey',
    null,
  );
  useEffect(() => {
    if (!vapidPublicKey) {
      setVapidPublicKey(VAPID_PUBLIC_KEY);
    }
  }, [setVapidPublicKey, vapidPublicKey]);

  const [subscribing, setSubscribing] = useState(false);

  const hide = useCallback(() => {
    setHidden(true);
  }, [setHidden]);

  const subscribe = useCallback(() => {
    setSubscribing(true);

    window.addEventListener('noloco.push.registeredSubscription', () => {
      const subscription = localStorage.getItem('noloco.push.subscription');

      if (subscription) {
        const { endpoint, expirationTime, keys } = JSON.parse(subscription);

        subscribeMutation({
          variables: {
            auth: keys.auth,
            endpoint,
            expirationTime: expirationTime,
            p256dh: keys.p256dh,
          },
        })
          .then(() => setSubscribed(true))
          .finally(() => setSubscribing(false));
      }
    });

    return Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        window.dispatchEvent(new Event('noloco.push.registerSubscription'));
      }
    });
  }, [setSubscribed, subscribeMutation]);

  return {
    hidden,
    hide,
    subscribe,
    isSubscribed,
    subscribing,
    subscription,
  };
};

const useNotificationConsentAlert = (
  isHidden: boolean,
  hide: () => void,
  isSubscribed: boolean,
  subscribe: () => Promise<any>,
  subscribing: boolean,
) => {
  const { user } = useAuth();

  const browserNotificationsEnabled =
    process.env.REACT_APP_SW_NOTIFICATIONS_DISABLED !== 'true';

  const showAlert = useInfoAlert();
  const [persistentAlert, setPersistentAlert] = useState<AlertInstance | null>(
    null,
  );

  const [clicked, setClicked] = useState(false);
  const [dismissed, setDismissed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const shouldAskForNotificationConsent = useMemo(
    () =>
      browserNotificationsEnabled &&
      !!user &&
      !isHidden &&
      !isSubscribed &&
      'Notification' in window &&
      Notification.permission !== 'denied',
    [browserNotificationsEnabled, isHidden, isSubscribed, user],
  );
  const [haveAskedForNotificationConsent, setHaveAskedForNotificationConsent] =
    useState(false);

  useEffect(() => {
    if (shouldAskForNotificationConsent && !haveAskedForNotificationConsent) {
      setHaveAskedForNotificationConsent(true);
      const alert = showAlert(
        getText('notifications.consent.prompt'),
        undefined,
        {
          onDismiss: () => setDismissed(true),
          onClick: () => {
            setClicked(true);
            setIsModalOpen(true);
          },
          timeout: 0,
        },
      );
      setPersistentAlert(alert);
    }
  }, [
    haveAskedForNotificationConsent,
    shouldAskForNotificationConsent,
    showAlert,
  ]);

  useEffect(() => {
    if (clicked && persistentAlert) {
      persistentAlert.close();
    }
  }, [clicked, dismissed, persistentAlert]);

  useEffect(() => {
    if (dismissed && persistentAlert) {
      persistentAlert.close();
      hide();
    }
  }, [dismissed, hide, persistentAlert]);

  return (
    <NotificationConsentModal
      loading={subscribing}
      onSubscribe={subscribe}
      open={isModalOpen}
      setOpen={setIsModalOpen}
    />
  );
};

type PushNotificationContext = {
  consentHidden: boolean;
  subscribed: boolean;
};

const pushNotificationContext = createContext<
  PushNotificationContext | undefined
>(undefined);

export const ProvidePushNotifications = ({ children, projectName }: any) => {
  const { hidden, hide, isSubscribed, subscribe, subscribing } =
    useServiceWorkerNotifications(projectName);

  const context = useMemo(
    () => ({
      consentHidden: hidden,
      subscribed: isSubscribed,
    }),
    [hidden, isSubscribed],
  );

  const modal = useNotificationConsentAlert(
    hidden,
    hide,
    isSubscribed,
    subscribe,
    subscribing,
  );

  return (
    <pushNotificationContext.Provider value={context}>
      {modal}
      {children}
    </pushNotificationContext.Provider>
  );
};
