import { useCallback, useMemo } from 'react';
import { MutationResult, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useSelector } from 'react-redux';
import { INVITE_EMAIL_QUERY } from '@noloco/core/src/queries/project';
import { projectNameSelector } from '@noloco/core/src/selectors/projectSelectors';
import {
  useGraphQlErrorAlert,
  useInfoAlert,
} from '@noloco/core/src/utils/hooks/useAlerts';
import { getText } from '@noloco/core/src/utils/lang';

const useInviteUser = (additionalContext?: {
  authQuery?: boolean;
}): [(userEmail: string) => Promise<void>, MutationResult<null>] => {
  const projectName = useSelector(projectNameSelector);
  const errorAlert = useGraphQlErrorAlert();
  const successAlert = useInfoAlert();
  const inviteUserQueryString = useMemo(
    () => gql`
      ${INVITE_EMAIL_QUERY}
    `,
    [],
  );

  const context = useMemo(
    () => ({
      projectName,
      projectQuery: true,
      ...additionalContext,
    }),
    [additionalContext, projectName],
  );

  const [inviteUser, result] = useMutation(inviteUserQueryString, { context });

  const onInviteUser = useCallback(
    (userEmail: string) =>
      inviteUser({ variables: { email: userEmail } })
        .then(() => {
          successAlert(getText({ email: userEmail }, 'notifications.invite'));
        })
        .catch((error) => {
          errorAlert(
            getText({ email: userEmail }, 'errors.data.invite'),
            error,
          );
        }),
    [errorAlert, inviteUser, successAlert],
  );

  return [onInviteUser, result];
};

export default useInviteUser;
