import createReactComponent from '../createReactComponent';
export default createReactComponent('map-pin-search', 'IconMapPinSearch', [
  ['path', { d: 'M14.916 11.707a3 3 0 1 0 -2.916 2.293', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M11.991 21.485a1.994 1.994 0 0 1 -1.404 -.585l-4.244 -4.243a8 8 0 1 1 13.651 -5.351',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-3' }],
]);
