import createReactComponent from '../createReactComponent';
export default createReactComponent('thermometer', 'IconThermometer', [
  [
    'path',
    {
      d: 'M19 5a2.828 2.828 0 0 1 0 4l-8 8h-4v-4l8 -8a2.828 2.828 0 0 1 4 0z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 7l-1.5 -1.5', key: 'svg-1' }],
  ['path', { d: 'M13 10l-1.5 -1.5', key: 'svg-2' }],
  ['path', { d: 'M10 13l-1.5 -1.5', key: 'svg-3' }],
  ['path', { d: 'M7 17l-3 3', key: 'svg-4' }],
]);
