import createReactComponent from '../createReactComponent';
export default createReactComponent('podium-off', 'IconPodiumOff', [
  [
    'path',
    {
      d: 'M12 8h7l-.621 2.485a2 2 0 0 1 -1.94 1.515h-.439m-4 0h-4.439a2 2 0 0 1 -1.94 -1.515l-.621 -2.485h3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M7 8v-1m.864 -3.106a2.99 2.99 0 0 1 2.136 -.894', key: 'svg-1' },
  ],
  ['path', { d: 'M8 12l1 9', key: 'svg-2' }],
  ['path', { d: 'M15.599 15.613l-.599 5.387', key: 'svg-3' }],
  ['path', { d: 'M7 21h10', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
