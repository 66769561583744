export const URL = 'URL';
export const PAGE = 'PAGE';
export const EMAIL = 'EMAIL';
export const PHONE = 'PHONE';

const linkTypes = [URL, PAGE, EMAIL, PHONE];

export type LinkType = 'URL' | 'PAGE' | 'EMAIL' | 'PHONE';

export default linkTypes;
