import createReactComponent from '../createReactComponent';
export default createReactComponent('aerial-lift', 'IconAerialLift', [
  [
    'path',
    {
      d: 'M4 5l16 -2m-8 1v10m-5.106 -6h10.306c2.45 3 2.45 9 -.2 12h-10.106c-2.544 -3 -2.544 -9 0 -12zm-1.894 6h14',
      key: 'svg-0',
    },
  ],
]);
