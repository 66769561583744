import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-right-rhombus',
  'IconArrowRightRhombus',
  [
    ['path', { d: 'M8 12h13', key: 'svg-0' }],
    ['path', { d: 'M18 9l3 3l-3 3', key: 'svg-1' }],
    ['path', { d: 'M5.5 9.5l-2.5 2.5l2.5 2.5l2.5 -2.5z', key: 'svg-2' }],
  ],
);
