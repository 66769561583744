import React, { useRef } from 'react';
import classNames from 'classnames';
import { Identifier } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

export const WithDraggable = (Component: any, itemType: any) => {
  function Draggable({
    className,

    findItem,
    onSaveOrder,
    item = {},
    draggable = false,
    droppable,
    ...rest
  }: any) {
    const ref = useRef(null);
    const dragRef = useRef(null);
    const canDrop = droppable ?? draggable;

    const [{ handlerId, isOver }, drop] = useDrop<
      { id: string | number },
      undefined,
      { handlerId: Identifier | null; isOver: boolean }
    >(
      () => ({
        accept: itemType,
        collect(monitor) {
          return {
            handlerId: monitor.getHandlerId(),
            isOver: monitor.isOver(),
          };
        },
        drop({ id: droppedId }) {
          const { index: overIndex } = findItem(item.id);
          const { index: droppedIndex } = findItem(droppedId);
          if (overIndex >= 0 && droppedIndex >= 0) {
            onSaveOrder(droppedIndex, overIndex);
          }
        },
      }),
      [findItem, item.id, onSaveOrder],
    );

    const [{ isDragging }, drag, preview] = useDrag(
      () => ({
        type: itemType,
        canDrag: draggable,
        item: item,
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      }),
      [draggable, item],
    );

    if ((!draggable && !canDrop) || item.id === undefined) {
      return (
        <Component
          className={classNames(className)}
          draggable={draggable}
          {...rest}
        />
      );
    }

    if (draggable) {
      drag(dragRef && dragRef.current ? dragRef : ref);
    }
    drop(preview(ref));

    return (
      <Component
        className={classNames(className, { invisible: isDragging })}
        draggable={draggable || canDrop}
        isOver={isOver}
        isDragging={isDragging}
        {...rest}
        ref={ref}
        dragRef={dragRef}
        data-field-name={item.id}
        data-handler-id={handlerId}
      />
    );
  }
  Draggable.displayName = `Draggable${itemType}`;
  return Draggable;
};

export const WithDropable = (Component: any, itemType: any) => {
  const Dropable = (props: any) => {
    const [, drop] = useDrop(() => ({
      accept: itemType,
    }));
    return <Component ref={drop} {...props} />;
  };
  Dropable.displayName = `Dropable${itemType}`;
  return Dropable;
};
