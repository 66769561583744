import createReactComponent from '../createReactComponent';
export default createReactComponent('lock-heart', 'IconLockHeart', [
  [
    'path',
    {
      d: 'M11.5 21h-4.5a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h10c.38 0 .734 .106 1.037 .29',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 11v-4a4 4 0 1 1 8 0v4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
      key: 'svg-2',
    },
  ],
]);
