import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'building-bridge-2',
  'IconBuildingBridge2',
  [
    [
      'path',
      {
        d: 'M6 7h12a2 2 0 0 1 2 2v9a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a4 4 0 0 0 -8 0v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-9a2 2 0 0 1 2 -2',
        key: 'svg-0',
      },
    ],
  ],
);
