import React, { memo } from 'react';
import classNames from 'classnames';
import useScopeVariables from '../../../utils/hooks/useScopeVariables';
import CollectionCardGallery from './CollectionCardGallery';

const CollectionCalendarPopoverContent = memo(
  ({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type '{}'.
    index,
    // @ts-expect-error TS(2339): Property 'additionalElementsRenderer' does not exi... Remove this comment to see the full error message
    additionalElementsRenderer,
    children,
    // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
    element,
    // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
    scope,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'isView' does not exist on type '{}'.
    isView,
    // @ts-expect-error TS(2339): Property 'rawVariables' does not exist on type '{}... Remove this comment to see the full error message
    rawVariables,
  }) => {
    const { images } = rawVariables || {};
    const { variables: { image } = rawVariables } = useScopeVariables(
      scope,
      element,
      project,
      elementPath,
    );

    return (
      <div
        className={classNames(
          'flex w-80 flex-col items-center overflow-hidden bg-white sm:flex-wrap',
        )}
      >
        <>
          {/* @ts-expect-error TS(2786): 'CollectionCardGallery' cannot be used as a JSX co... Remove this comment to see the full error message */}
          <CollectionCardGallery
            image={image}
            images={images}
            h={48}
            index={index}
            isView={isView}
          />
          {children}
          {additionalElementsRenderer(
            scope,
            'w-full flex flex-col space-y-3 px-3 pb-2 text-xs',
            'flex flex-col w-full mr-auto',
          )}
        </>
      </div>
    );
  },
);

(CollectionCalendarPopoverContent as any).propTypes = {};

(CollectionCalendarPopoverContent as any).defaultProps = {
  index: 0,
};

export default CollectionCalendarPopoverContent;
