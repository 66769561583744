import createReactComponent from '../createReactComponent';
export default createReactComponent('border-style', 'IconBorderStyle', [
  ['path', { d: 'M4 20v-14a2 2 0 0 1 2 -2h14', key: 'svg-0' }],
  ['path', { d: 'M20 8v.01', key: 'svg-1' }],
  ['path', { d: 'M20 12v.01', key: 'svg-2' }],
  ['path', { d: 'M20 16v.01', key: 'svg-3' }],
  ['path', { d: 'M8 20v.01', key: 'svg-4' }],
  ['path', { d: 'M12 20v.01', key: 'svg-5' }],
  ['path', { d: 'M16 20v.01', key: 'svg-6' }],
  ['path', { d: 'M20 20v.01', key: 'svg-7' }],
]);
