import React, { useCallback, useMemo, useState } from 'react';
import { IconFileDownload } from '@tabler/icons-react';
import gql from 'graphql-tag';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import { Button, Loader } from '@noloco/components';
import useLocale from '@noloco/components/src/utils/hooks/useLocale';
import { FILE } from '../../../constants/builtInDataTypes';
import { OBJECT } from '../../../constants/dataTypes';
import { BaseRecord, PageInfo, RecordEdge } from '../../../models/Record';
import { formatValueForField } from '../../../utils/data';
import { getPreviewFieldsForField } from '../../../utils/dataTypes';
import { YMD_TIME_FORMAT } from '../../../utils/dates';
import { formatFieldValue } from '../../../utils/fieldValues';
import { downloadCsvStringAsFile } from '../../../utils/files';
import { useGraphQlErrorAlert } from '../../../utils/hooks/useAlerts';
import useDataListQueryObject from '../../../utils/hooks/useDataListQueryObject';
import useIsWindows from '../../../utils/hooks/useIsWindows';
import usePromiseQuery from '../../../utils/hooks/usePromiseQuery';
import { getText } from '../../../utils/lang';
import { isMultiField } from '../../../utils/relationships';

const LANG_KEY = 'elements.VIEW.buttons.exportButton';

const PAGE_AMOUNT = 250;

const ExportButton = ({
  additionalDeps,
  buttonType,
  queryContext = {},
  customFilters = [],
  dataList,
  dataType,
  elementPath,
  fieldConfigs,
  project,
  text,
  size,
  fileNamePrefix,
  ...rest
}: any) => {
  const locale = useLocale();
  const [isLoading, setIsLoading] = useState(false);
  const errorAlert = useGraphQlErrorAlert();
  const isWindows = useIsWindows();

  // @ts-expect-error TS(2339): Property 'variables' does not exist on type '{}'.
  const { variables, query, valuePath } = useDataListQueryObject(
    dataType.name,
    project,
    elementPath,
    {
      additionalArgs: { __variables: { after: 'String' } },
      additionalDeps,
      ...dataList,
      customFilters: [...customFilters, ...(dataList.customFilters || [])],
      limit: PAGE_AMOUNT,
    },
  );

  const gqlQueryString = useMemo(
    () => gql`
      ${query}
    `,
    [query],
  );

  const [exportData] = usePromiseQuery(gqlQueryString, {
    variables,
    fetchPolicy: 'no-cache',
    context: { projectQuery: true, projectName: project.name, ...queryContext },
    errorPolicy: 'all',
  });

  const exportAllData = useCallback(
    (after?: string): Promise<BaseRecord[]> => {
      return exportData({
        variables: { ...variables, after },
      }).then(({ errors, data }: any) => {
        if (!data) {
          errorAlert(getText(LANG_KEY, 'error'), {
            graphQLErrors: errors,
          });

          throw new Error(errors);
        }

        const splitPath = valuePath.split('.');
        const nodes = get(data, [...splitPath, 'edges'], []).map(
          (edge: RecordEdge) => edge.node,
        );

        const pageInfo = get(data, [...splitPath, 'pageInfo'], {}) as PageInfo;

        if (!pageInfo.hasNextPage || !pageInfo.endCursor) {
          return nodes;
        }

        const nextAfter = pageInfo.endCursor;
        return exportAllData(nextAfter).then((rows: BaseRecord[]) =>
          nodes.concat(rows),
        );
      });
    },
    [errorAlert, exportData, valuePath, variables],
  );

  const shouldExportIdValue = useCallback((config: any) => {
    return config ? config.exportIdValue !== false : true;
  }, []);

  const onClick = useCallback(() => {
    setIsLoading(true);
    exportAllData()
      .then((nodes) => {
        const columns = fieldConfigs.reduce(
          (acc: any, { field, config, parent }: any) => {
            const fieldPath = [
              ...(parent ? [parent.apiName] : []),
              field.apiName,
            ];
            const label = config.label.value || field.apiName;

            if (
              !field.relationship &&
              !field.relatedField &&
              field.type !== OBJECT &&
              !field.multiple
            ) {
              return [
                ...acc,
                {
                  label,
                  getValue: (node: any) =>
                    formatValueForField(get(node, fieldPath), field, true, {
                      locale,
                    }),
                },
              ];
            }

            if (field.multiple) {
              return [
                ...acc,
                {
                  label,
                  getValue: (node: any) => {
                    const value = get(node, fieldPath);
                    if (!value) {
                      return null;
                    }
                    if (Array.isArray(value)) {
                      return value.map((val) => formatFieldValue(val, field));
                    } else {
                      return formatFieldValue(value, field);
                    }
                  },
                },
              ];
            }

            if (field.type === OBJECT) {
              return [
                ...acc,
                {
                  label,
                  getValue: (node: any) =>
                    formatFieldValue(get(node, fieldPath), field),
                },
              ];
            }

            const isMultiRelationship = isMultiField(field);
            if (field.type === FILE) {
              return [
                ...acc,
                {
                  label,
                  getValue: (node: any) => {
                    if (isMultiRelationship) {
                      return get(node, [...fieldPath, 'edges'], [])
                        .map((edge) => (edge as any).node.url)
                        .filter(Boolean)
                        .join(',');
                    }
                    return get(node, [...fieldPath, 'url']);
                  },
                },
              ];
            }
            const previewFields = getPreviewFieldsForField(
              field,
              project.dataTypes,
            );
            if (!previewFields) {
              return acc;
            }

            const baseObject = {
              label,
              getValue: (node: any) => {
                if (isMultiRelationship) {
                  return get(node, [...fieldPath, 'edges'], [])
                    .map((edge) =>
                      previewFields.textFields
                        .map((textField) =>
                          get(edge, ['node', textField.apiName], ''),
                        )
                        .join(' '),
                    )
                    .filter(Boolean)
                    .join(',');
                }
                return previewFields.textFields
                  .map((textField) =>
                    get(node, [...fieldPath, textField.apiName], ''),
                  )
                  .join(' ');
              },
            };

            acc.push(baseObject);

            if (shouldExportIdValue(config)) {
              acc.push({
                label: `${field.display} ID`,
                getValue: (node: any) => {
                  if (isMultiRelationship) {
                    return get(node, [...fieldPath, 'edges'], [])
                      .map((edge) => get(edge, ['node', 'id']))
                      .filter(Boolean)
                      .join(',');
                  }
                  return get(node, [...fieldPath, 'id']);
                },
              });
            }
            return acc;
          },
          [],
        );
        const rows = nodes.map((node) =>
          columns.reduce(
            (nodeAcc: any, { label, getValue }: any) =>
              set([label], getValue(node), nodeAcc),
            {},
          ),
        );
        const rowsCSV = Papa.unparse(rows);
        downloadCsvStringAsFile(
          rowsCSV,
          `${fileNamePrefix ? `${fileNamePrefix}-` : ''}${getText(
            LANG_KEY,
            'filename',
          )}-${DateTime.now().toFormat(YMD_TIME_FORMAT)}`,
          isWindows,
        );
      })
      .catch((e: any) => {
        errorAlert(getText(LANG_KEY, 'error'), e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    exportAllData,
    fieldConfigs,
    fileNamePrefix,
    isWindows,
    project.dataTypes,
    shouldExportIdValue,
    locale,
    errorAlert,
  ]);

  return (
    <Button
      className="export-button flex items-center space-x-2 whitespace-nowrap disabled:cursor-not-allowed disabled:opacity-50"
      type={buttonType}
      variant="secondary"
      disabled={isLoading}
      onClick={onClick}
      size={size}
      {...rest}
    >
      {isLoading ? (
        <Loader size="sm" />
      ) : (
        <IconFileDownload className="opacity-75" size={16} />
      )}
      <span>{text || getText(LANG_KEY, 'defaultText')}</span>
    </Button>
  );
};

export default ExportButton;
