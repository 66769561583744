import React, { ReactNode } from 'react';
import {
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Button } from '@noloco/components';
import {
  FILLED,
  TEXT,
} from '@noloco/components/src/components/button/buttonTypes';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import { getText } from '@noloco/core/src/utils/lang';
import NewDataSourcePopover from './NewDataSourcePopover';

type Props = {
  children?: ReactNode;
  isSidebarOpen: boolean;
  setSidebarOpen: (newValue: boolean) => void;
  showNewDataSourceButton?: boolean;
};

const DataTabActionBar = ({
  children,
  isSidebarOpen,
  setSidebarOpen,
  showNewDataSourceButton = true,
}: Props) => {
  return (
    <div className="item-center flex space-x-2 bg-slate-800 px-2 py-2">
      <Button
        className={classNames(
          'mr-2 flex items-center space-x-2 whitespace-nowrap',
          { 'bg-slate-900 hover:bg-slate-700': isSidebarOpen },
        )}
        onClick={() => setSidebarOpen(!isSidebarOpen)}
        size={SM}
        type={isSidebarOpen ? FILLED : TEXT}
      >
        {isSidebarOpen ? (
          <IconLayoutSidebarLeftCollapse size={16} />
        ) : (
          <IconLayoutSidebarLeftExpand size={16} />
        )}
        <span>{getText('leftSidebar.data.label')}</span>
      </Button>
      {showNewDataSourceButton && <NewDataSourcePopover />}
      <div className="h-full min-h-1 w-px self-stretch bg-slate-200"></div>
      <div className="flex flex-grow justify-end">{children}</div>
    </div>
  );
};

export default DataTabActionBar;
