import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'map-pin-exclamation',
  'IconMapPinExclamation',
  [
    ['path', { d: 'M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M15.005 19.31l-1.591 1.59a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 13.592 -4.638',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M19 16v3', key: 'svg-2' }],
    ['path', { d: 'M19 22v.01', key: 'svg-3' }],
  ],
);
