import React, { memo, useMemo } from 'react';
import {
  MULTIPLE_OPTION,
  SINGLE_OPTION,
} from '@noloco/core/src/constants/dataTypes';
import PRIMITIVE_DATA_TYPES from '@noloco/core/src/constants/primitiveDataTypes';
import { getOptionScopeItemsForOptionField } from '@noloco/core/src/utils/renderedOptions';
import StringPropEditor from './StringPropEditor';

const FieldStringPropEditor = memo(
  ({
    // @ts-expect-error TS(2339): Property 'additionalScopeItems' does not exist on ... Remove this comment to see the full error message
    additionalScopeItems = [],
    // @ts-expect-error TS(2339): Property 'contained' does not exist on type '{ chi... Remove this comment to see the full error message
    contained,
    // @ts-expect-error TS(2339): Property 'field' does not exist on type '{ childre... Remove this comment to see the full error message
    field,
    // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{ chil... Remove this comment to see the full error message
    dataType,
    // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{ c... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{ child... Remove this comment to see the full error message
    project,
    // @ts-expect-error TS(2339): Property 'onChange' does not exist on type '{ chil... Remove this comment to see the full error message
    onChange,
    // @ts-expect-error TS(2339): Property 'includeUniqueColumnar' does not exist on... Remove this comment to see the full error message
    includeUniqueColumnar,
    // @ts-expect-error TS(2339): Property 'includeSelf' does not exist on type '{ c... Remove this comment to see the full error message
    includeSelf,
    // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type '{ c... Remove this comment to see the full error message
    placeholder,
    // @ts-expect-error TS(2339): Property 'value' does not exist on type '{ childre... Remove this comment to see the full error message
    value,
  }) => {
    const fieldOptions = useMemo(() => {
      if (!field || !dataType) {
        return [];
      }

      if (field.type === SINGLE_OPTION || field.type === MULTIPLE_OPTION) {
        return [getOptionScopeItemsForOptionField(field, dataType)];
      }

      return [];
    }, [dataType, field]);

    const additionalItemsWithFieldOptions = useMemo(
      () => [...fieldOptions, ...additionalScopeItems],
      [additionalScopeItems, fieldOptions],
    );

    const acceptableDataTypes = useMemo(() => {
      if (!field || field.relationship || field.relatedField) {
        return undefined;
      }

      // Make sure the current field type is included
      return [...PRIMITIVE_DATA_TYPES, field.type];
    }, [field]);

    return (
      <StringPropEditor
        // @ts-expect-error TS(2322): Type '{ additionalScopeItems: any[]; acceptableDat... Remove this comment to see the full error message
        additionalScopeItems={additionalItemsWithFieldOptions}
        acceptableDataTypes={acceptableDataTypes}
        contained={contained}
        project={project}
        onChange={onChange}
        value={value}
        elementPath={elementPath}
        includeSelf={includeSelf}
        includeUniqueColumnar={includeUniqueColumnar}
        placeholder={placeholder}
      />
    );
  },
);

(FieldStringPropEditor as any).defaultProps = {
  acceptableDataTypes: undefined,
  includeUniqueColumnar: false,
};

export default FieldStringPropEditor;
