import createReactComponent from '../createReactComponent';
export default createReactComponent('geometry', 'IconGeometry', [
  [
    'path',
    { d: 'M7 21l4 -12m2 0l1.48 4.439m.949 2.847l1.571 4.714', key: 'svg-0' },
  ],
  ['path', { d: 'M12 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M4 12c1.526 2.955 4.588 5 8 5c3.41 0 6.473 -2.048 8 -5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M12 5v-2', key: 'svg-3' }],
]);
