import DataTypes, { DataType } from '../models/DataTypes';
import { BaseRollup } from '../models/Rollup';

export const getDependencies = (
  rollup: BaseRollup,
  dataType: DataType,
  dataTypes: DataTypes,
) => {
  const relatedField = dataType.fields.getByName(rollup.relatedField);
  if (!relatedField) {
    return;
  }

  const relatedDataType = dataTypes.getByName(relatedField.type);
  if (!relatedDataType) {
    return;
  }

  const rolledUpField = relatedDataType.fields.getByName(rollup.field);
  if (!rolledUpField) {
    return;
  }

  return { relatedDataType, relatedField, rolledUpField };
};
