import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrows-diagonal-2',
  'IconArrowsDiagonal2',
  [
    ['path', { d: 'M16 20l4 0l0 -4', key: 'svg-0' }],
    ['path', { d: 'M14 14l6 6', key: 'svg-1' }],
    ['path', { d: 'M8 4l-4 0l0 4', key: 'svg-2' }],
    ['path', { d: 'M4 4l6 6', key: 'svg-3' }],
  ],
);
