import createReactComponent from '../createReactComponent';
export default createReactComponent('shadow-off', 'IconShadowOff', [
  [
    'path',
    {
      d: 'M5.634 5.638a9 9 0 0 0 12.728 12.727m1.68 -2.32a9 9 0 0 0 -12.086 -12.088',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 12h2', key: 'svg-1' }],
  ['path', { d: 'M13 15h2', key: 'svg-2' }],
  ['path', { d: 'M13 18h1', key: 'svg-3' }],
  ['path', { d: 'M13 9h4', key: 'svg-4' }],
  ['path', { d: 'M13 6h1', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
