import createReactComponent from '../createReactComponent';
export default createReactComponent('360', 'Icon360', [
  [
    'path',
    {
      d: 'M17 15.328c2.414 -.718 4 -1.94 4 -3.328c0 -2.21 -4.03 -4 -9 -4s-9 1.79 -9 4s4.03 4 9 4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 13l3 3l-3 3', key: 'svg-1' }],
]);
