import createReactComponent from '../createReactComponent';
export default createReactComponent('first-aid-kit-off', 'IconFirstAidKitOff', [
  [
    'path',
    { d: 'M8.595 4.577a2 2 0 0 1 1.405 -.577h4a2 2 0 0 1 2 2v2', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M12 8h6a2 2 0 0 1 2 2v6m-.576 3.405a2 2 0 0 1 -1.424 .595h-12a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M10 14h4', key: 'svg-2' }],
  ['path', { d: 'M12 12v4', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
