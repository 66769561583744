import { memo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { Theme, getColorShade } from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import {
  ASC,
  DESC,
  OrderByDirection,
} from '../../../constants/orderByDirections';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import usePrevious from '../../../utils/hooks/usePrevious';
import Checkbox from '../../Checkbox';

type AdditionalElement = {
  label: string;
  alignRight: boolean;
  isSortActive: boolean;
  onSort: (newSortDirection: any) => void;
};

type CollectionTableHeadProps = {
  additionalElements: AdditionalElement[];
  allRowsSelected?: boolean;
  bulkActionsEnabled?: boolean;
  className: string;
  columnWidths: Record<number, number>;
  isSmScreen: boolean;
  maxStickyColumnIndex: number;
  orderBy: { field: string; direction: OrderByDirection };
  setColumnWidths: (
    columnWidths: (currentWidthState: Record<number, number>) => {
      [x: number]: number;
    },
  ) => void;
  setSelectAllRows?: (allRowsSelected: boolean) => void;
  sticky: boolean;
  theme: Theme;
  variables: any;
};

const CollectionTableHead = memo(
  ({
    additionalElements,
    allRowsSelected = false,
    bulkActionsEnabled = false,
    className,
    columnWidths = {},
    isSmScreen,
    maxStickyColumnIndex,
    orderBy,
    setColumnWidths,
    setSelectAllRows,
    sticky,
    theme,
    variables,
  }: CollectionTableHeadProps) => {
    const previousIsSmScreen = usePrevious(isSmScreen);
    const primaryColor = theme.brandColors.primary;
    const [isDarkModeEnabled] = useDarkMode();

    return (
      <thead
        className={classNames(
          className,
          {
            [`${
              isDarkModeEnabled
                ? darkModeColors.surfaces.elevation1
                : 'bg-gray-200 bg-opacity-25'
            }`]: !sticky,
            [`sticky top-0 ${
              isDarkModeEnabled
                ? darkModeColors.surfaces.elevation2
                : 'bg-gray-100'
            } shadow-xs z-30`]: sticky,
          },
          `rounded-tl-lg rounded-tr-lg border-b text-left ${
            isDarkModeEnabled
              ? `${darkModeColors.borders.one} ${darkModeColors.text.secondary}`
              : 'border-gray-200 text-gray-500'
          } text-xs font-medium uppercase tracking-wider`,
        )}
      >
        <tr>
          {!variables.title.hidden && (
            <th>
              <div className="whitespace-nowrap py-2 pl-6 pr-3">
                {variables.title.label}
              </div>
            </th>
          )}
          {!variables.secondaryText.hidden && (
            <th>
              <div
                className={classNames('whitespace-nowrap px-3 py-2', {
                  'pl-6': variables.title.hidden,
                })}
              />
            </th>
          )}
          {bulkActionsEnabled && setSelectAllRows && (
            <th
              className={classNames(
                'flex h-10 w-10 items-center justify-center',
                {
                  'sticky left-0 z-30': !isNil(maxStickyColumnIndex),
                },
                isDarkModeEnabled
                  ? darkModeColors.surfaces.elevation2
                  : 'shadow-r bg-gray-100',
              )}
            >
              <Checkbox
                className="flex w-3"
                size="sm"
                value={allRowsSelected}
                onChange={({
                  target: { checked },
                }: {
                  target: { checked: boolean };
                }) => setSelectAllRows(checked)}
              />
            </th>
          )}
          {additionalElements &&
            additionalElements.map((elementConfig: any, index: any) => (
              <th
                className={classNames(
                  {
                    [`${
                      bulkActionsEnabled ? 'left-10' : 'left-0'
                    } sticky z-20`]:
                      !isNil(maxStickyColumnIndex) &&
                      index <= maxStickyColumnIndex,
                    'table-fixed overflow-hidden': columnWidths[index],
                  },
                  `${
                    isDarkModeEnabled
                      ? darkModeColors.surfaces.elevation2
                      : 'shadow-r bg-gray-100'
                  }`,
                )}
                ref={
                  setColumnWidths
                    ? (el: HTMLTableCellElement) => {
                        if (
                          el &&
                          (columnWidths[index] === undefined ||
                            isSmScreen !== previousIsSmScreen)
                        ) {
                          setColumnWidths(
                            (currentWidthState: Record<number, number>) => ({
                              ...currentWidthState,
                              [index]:
                                isSmScreen &&
                                !isNil(maxStickyColumnIndex) &&
                                index <= maxStickyColumnIndex
                                  ? Math.min(
                                      document.documentElement.clientWidth *
                                        0.4,
                                      el.clientWidth,
                                    )
                                  : el.clientWidth,
                            }),
                          );
                        }
                      }
                    : undefined
                }
                style={{
                  minWidth: columnWidths[index]
                    ? `${columnWidths[index]}px`
                    : undefined,
                  maxWidth: columnWidths[index]
                    ? `${columnWidths[index]}px`
                    : undefined,
                }}
                key={elementConfig.label}
              >
                <div
                  className={classNames(
                    'flex items-center whitespace-nowrap px-3 py-2',
                    {
                      'justify-end': elementConfig.alignRight,
                      'pl-6':
                        index === 0 &&
                        variables.title.hidden &&
                        variables.secondaryText.hidden &&
                        !bulkActionsEnabled,
                    },
                  )}
                >
                  {elementConfig.label}
                  {elementConfig.onSort && (
                    <div className="ml-3 flex flex-col">
                      <button
                        className={classNames(
                          `hover:text-${getColorShade(
                            primaryColor,
                            500,
                          )} hover:opacity-100`,

                          elementConfig.isSortActive &&
                            orderBy &&
                            orderBy.direction === ASC
                            ? `text-${getColorShade(primaryColor, 500)}`
                            : 'opacity-50',
                        )}
                        onClick={() => elementConfig.onSort(ASC)}
                      >
                        <IconChevronUp size={13} strokeWidth={3} />
                      </button>
                      <button
                        className={classNames(
                          `-mt-1 hover:text-${getColorShade(
                            primaryColor,
                            500,
                          )} hover:opacity-100`,

                          elementConfig.isSortActive &&
                            orderBy &&
                            orderBy.direction === DESC
                            ? `text-${getColorShade(primaryColor, 500)}`
                            : 'opacity-50',
                        )}
                        onClick={() => elementConfig.onSort(DESC)}
                      >
                        <IconChevronDown size={13} strokeWidth={3} />
                      </button>
                    </div>
                  )}
                </div>
              </th>
            ))}
        </tr>
      </thead>
    );
  },
);

export default withTheme(CollectionTableHead);
