import createReactComponent from '../createReactComponent';
export default createReactComponent('currency-dirham', 'IconCurrencyDirham', [
  ['path', { d: 'M8.5 19h-3.5', key: 'svg-0' }],
  ['path', { d: 'M8.599 16.479a1.5 1.5 0 1 0 -1.099 2.521', key: 'svg-1' }],
  ['path', { d: 'M7 4v9', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M15 13h1.888a1.5 1.5 0 0 0 1.296 -2.256l-2.184 -3.744',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M11 13.01v-.01', key: 'svg-4' }],
]);
