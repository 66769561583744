import createReactComponent from '../createReactComponent';
export default createReactComponent('drop-circle', 'IconDropCircle', [
  [
    'path',
    {
      d: 'M10.07 15.34c1.115 .88 2.74 .88 3.855 0c1.115 -.88 1.398 -2.388 .671 -3.575l-2.596 -3.765l-2.602 3.765c-.726 1.187 -.443 2.694 .672 3.575z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-1' }],
]);
