import React, { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { Button, SelectInput, SwitchButton } from '@noloco/components';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import { DANGER } from '@noloco/components/src/constants/variants';
import { DataTypeValue } from '@noloco/ui/src/components/canvas/DataTypeInput';
import StringPropEditor from '@noloco/ui/src/components/canvas/StringPropEditor';
import PagePath from '@noloco/ui/src/components/editor/PagePath';
import {
  getDestinationPage,
  getPageOptions,
  getTabOptions,
} from '@noloco/ui/src/utils/pages';
import { DECIMAL, INTEGER, TEXT } from '../../constants/dataTypes';
import { EMAIL, LinkType, PAGE, PHONE, URL } from '../../constants/linkTypes';
import { getText } from '../../utils/lang';
import { findPageInPath, getPages } from '../../utils/pages';
import { getDataTypeOptionsOfTypes } from '../../utils/renderedOptions';
import BuildModeInput from './BuildModeInput';
import BuildModeLabel from './BuildModeLabel';
import BuildModeSwitchSection from './BuildModeSwitchSection';

const LANG_KEY = 'elements.LINK';
const getTranslation = (...rest: any[]) => getText(LANG_KEY, ...rest);

type BuildModeLinkEditorProps = {
  additionalScopeItems?: any[];
  elementPath: any[];
  elementProps: any;
  label?: string;
  project: any;
  propDefinition?: any;
  config?: any;
  element?: any;
  updateProperty: (...args: any[]) => any;
  debouncedUpdateProperty: (...args: any[]) => any;
  options?: LinkType[];
};

const BuildModeLinkEditor = ({
  additionalScopeItems = [],
  elementPath,
  elementProps,
  label,
  project,
  propDefinition,
  updateProperty,
  debouncedUpdateProperty,
  options = [URL, PAGE, EMAIL, PHONE],
}: BuildModeLinkEditorProps) => {
  const pages = useMemo(() => getPages(project.elements), [project.elements]);
  const {
    newTab,
    page,
    pageData = {},
    email,
    phone,
    subject,
    url,
    type,
    tab,
  } = elementProps;

  const hasValue = useMemo(
    () => type || url || page || email || phone || subject || newTab,
    [email, newTab, page, phone, subject, type, url],
  );

  const isSectionId = useMemo(
    () =>
      url &&
      Array.isArray(url) &&
      url
        .map((data) => data.text)
        .filter(Boolean)
        .join('')
        .startsWith('#'),
    [url],
  );

  useEffect(() => {
    if (!type && options.length === 1) {
      updateProperty(['type'], options[0]);
    }
  }, [options, type, updateProperty]);

  const lastPageInPath = useMemo(() => {
    if (!page) {
      return null;
    }
    return getDestinationPage(page as string[], pages);
  }, [page, pages]);

  const pageOptions = useMemo(
    () => getPageOptions(pages, pages, project.dataTypes),
    [pages, project.dataTypes],
  );
  const tabOptionsWithPath = useMemo(
    () =>
      page && lastPageInPath
        ? getTabOptions(lastPageInPath, page as string[])
        : [],
    [lastPageInPath, page],
  );

  const pageOptionsWithPath = pageOptions.map((option: any) => ({
    value: option.value,
    label: option.label,
    help: option.pagePath,
  }));

  const pageOption = ({ placeholder, value }: any) => {
    const selectedOption = pageOptions.find(
      (option: any) => option.value.join('.') === value,
    );
    if (selectedOption) {
      return selectedOption.label;
    }
    return placeholder;
  };
  const pageContainsTabs = useMemo(() => {
    return tabOptionsWithPath.length > 1;
  }, [tabOptionsWithPath.length]);

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center">
        <BuildModeInput
          inline={true}
          label={label || getTranslation('type.label')}
        >
          <div className="flex w-full justify-around">
            <SwitchButton
              className="h-8 w-full rounded-lg"
              inverseColors={true}
              onChange={(value) => updateProperty(['type'], value)}
              options={options.map((option: LinkType) => ({
                label: getText(`elements.LINK.types.${option}`),
                value: option,
              }))}
              value={type}
            />
          </div>
        </BuildModeInput>
      </div>
      {type === URL && (
        <div className="flex flex-col justify-center">
          <BuildModeInput
            inline={true}
            label={getTranslation('types.URL.url.label')}
          >
            <StringPropEditor
              // @ts-expect-error TS(2322): Type '{ project: any; onChange: (value: any) => an... Remove this comment to see the full error message
              project={project}
              onChange={(value: any) => debouncedUpdateProperty(['url'], value)}
              elementPath={elementPath}
              value={url}
              includeSelf={propDefinition && propDefinition.includeSelf}
              additionalScopeItems={additionalScopeItems}
            />
          </BuildModeInput>
        </div>
      )}
      {type === PAGE && (
        <div className="flex flex-col justify-center gap-2">
          <BuildModeInput
            inline={true}
            label={getTranslation('types.PAGE.page.label')}
          >
            <SelectInput
              Button={pageOption}
              className="w-full"
              onChange={(option: any) => updateProperty(['page'], option)}
              options={pageOptionsWithPath}
              placeholder={getTranslation('types.PAGE.page.placeholder')}
              shiftRight={true}
              searchable={true}
              value={page ? page.join('.') : null}
            />
          </BuildModeInput>
          {pageContainsTabs && (
            <BuildModeInput
              inline={true}
              label={getTranslation('types.PAGE.tab.label')}
            >
              <SelectInput
                className="w-full"
                onChange={(option: any) => updateProperty(['tab'], option)}
                options={tabOptionsWithPath}
                placeholder={getTranslation('types.PAGE.tab.placeholder')}
                shiftRight={true}
                searchable={true}
                value={tab ? tab : null}
              />
            </BuildModeInput>
          )}
          {page &&
            page
              .map((pagePathItemId: any) =>
                findPageInPath(pagePathItemId, page, pages),
              )
              .filter(
                (pageItem: any) =>
                  pageItem &&
                  pageItem.props.dataType &&
                  pageItem.props.dataProperty,
              )
              .map((pageItem: any) => {
                const { dataType, dataProperty } = pageItem.props;

                const dataOptions = [
                  ...additionalScopeItems,
                  ...getDataTypeOptionsOfTypes(
                    project,
                    elementPath,
                    [DECIMAL, INTEGER, TEXT],
                    {
                      acceptableParentType:
                        dataProperty === 'id' ? dataType : undefined,
                      includeSelf: propDefinition && propDefinition.includeSelf,
                    },
                  ),
                ];

                const pageDataItem = get(pageData, dataType);
                return (
                  <div className="my-2 flex flex-col justify-center">
                    <BuildModeLabel className="mb-2">
                      <PagePath
                        dataTypes={project.dataTypes}
                        dataType={dataType}
                        dataProperty={dataProperty}
                      />
                    </BuildModeLabel>
                    <SelectInput
                      Button={DataTypeValue}
                      className="mb-4 w-full"
                      options={dataOptions}
                      value={pageDataItem}
                      onChange={(option: any) =>
                        updateProperty(['pageData', dataType], option)
                      }
                    />
                  </div>
                );
              })}
        </div>
      )}
      {type === EMAIL && (
        <>
          <div className="flex flex-col justify-center">
            <BuildModeInput
              inline={true}
              label={getTranslation('types.EMAIL.email.label')}
            >
              <StringPropEditor
                // @ts-expect-error TS(2322): Type '{ project: any; onChange: (value: any) => an... Remove this comment to see the full error message
                project={project}
                onChange={(value: any) =>
                  debouncedUpdateProperty(['email'], value)
                }
                elementPath={elementPath}
                value={email}
                includeSelf={propDefinition && propDefinition.includeSelf}
                placeholder={getTranslation('types.EMAIL.email.placeholder')}
                additionalScopeItems={additionalScopeItems}
              />
            </BuildModeInput>
          </div>
          <div className="flex flex-col justify-center">
            <BuildModeInput
              inline={true}
              label={getTranslation('types.EMAIL.subject.label')}
            >
              <StringPropEditor
                // @ts-expect-error TS(2322): Type '{ project: any; onChange: (value: any) => an... Remove this comment to see the full error message
                project={project}
                onChange={(value: any) =>
                  debouncedUpdateProperty(['subject'], value)
                }
                elementPath={elementPath}
                value={subject}
                includeSelf={propDefinition && propDefinition.includeSelf}
                placeholder={getTranslation('types.EMAIL.subject.placeholder')}
                additionalScopeItems={additionalScopeItems}
              />
            </BuildModeInput>
          </div>
        </>
      )}
      {type === PHONE && (
        <div className="flex flex-col justify-center space-y-2">
          <BuildModeInput
            inline={true}
            label={getTranslation('types.PHONE.phone.label')}
          >
            <StringPropEditor
              // @ts-expect-error TS(2322): Type '{ project: any; onChange: (value: any) => an... Remove this comment to see the full error message
              project={project}
              onChange={(value: any) =>
                debouncedUpdateProperty(['phone'], value)
              }
              elementPath={elementPath}
              value={phone}
              includeSelf={propDefinition && propDefinition.includeSelf}
              placeholder={getTranslation('types.PHONE.phone.placeholder')}
              additionalScopeItems={additionalScopeItems}
            />
          </BuildModeInput>
          <p className="text-sm text-gray-400">
            {getTranslation('types.PHONE.description')}
          </p>
        </div>
      )}
      {type === URL && !isSectionId && (
        <BuildModeSwitchSection
          label={getText(LANG_KEY, 'newTab.label')}
          onChange={(value: boolean) => updateProperty(['newTab'], value)}
          value={newTab}
        />
      )}
      {hasValue && (
        <Button
          className="mt-2"
          onClick={() => updateProperty([], undefined)}
          size={SM}
          variant={DANGER}
        >
          {getTranslation('clear')}
        </Button>
      )}
    </div>
  );
};

BuildModeLinkEditor.defaultProps = {
  additionalScopeItems: [],
  options: [URL, PAGE, EMAIL, PHONE],
};

export default BuildModeLinkEditor;
