import createReactComponent from '../createReactComponent';
export default createReactComponent('map-pin-down', 'IconMapPinDown', [
  ['path', { d: 'M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12.736 21.345a2 2 0 0 1 -2.149 -.445l-4.244 -4.243a8 8 0 1 1 13.59 -4.624',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 16v6', key: 'svg-2' }],
  ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-3' }],
]);
