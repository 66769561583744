import createReactComponent from '../createReactComponent';
export default createReactComponent('device-tablet-up', 'IconDeviceTabletUp', [
  [
    'path',
    {
      d: 'M12.5 21h-6.5a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12.906 16.576a1 1 0 1 0 -.906 1.424', key: 'svg-1' }],
  ['path', { d: 'M19 22v-6', key: 'svg-2' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-3' }],
]);
