import createReactComponent from '../createReactComponent';
export default createReactComponent('tent-off', 'IconTentOff', [
  [
    'path',
    {
      d: 'M11 14l4 6h5m-2.863 -6.868l-5.137 -9.132l-1.44 2.559m-1.44 2.563l-6.12 10.878h6l4 -6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
]);
