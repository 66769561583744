import createReactComponent from '../createReactComponent';
export default createReactComponent('world-up', 'IconWorldUp', [
  ['path', { d: 'M20.985 12.52a9 9 0 1 0 -8.451 8.463', key: 'svg-0' }],
  ['path', { d: 'M3.6 9h16.8', key: 'svg-1' }],
  ['path', { d: 'M3.6 15h10.9', key: 'svg-2' }],
  ['path', { d: 'M11.5 3a17 17 0 0 0 0 18', key: 'svg-3' }],
  ['path', { d: 'M12.5 3a16.996 16.996 0 0 1 2.391 11.512', key: 'svg-4' }],
  ['path', { d: 'M19 22v-6', key: 'svg-5' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-6' }],
]);
