import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-ipad-exclamation',
  'IconDeviceIpadExclamation',
  [
    [
      'path',
      {
        d: 'M15 21h-9a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 18h6', key: 'svg-1' }],
    ['path', { d: 'M19 16v3', key: 'svg-2' }],
    ['path', { d: 'M19 22v.01', key: 'svg-3' }],
  ],
);
