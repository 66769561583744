import createReactComponent from '../createReactComponent';
export default createReactComponent('topology-ring-3', 'IconTopologyRing3', [
  ['path', { d: 'M8 18a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-0' }],
  ['path', { d: 'M20 18a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-1' }],
  ['path', { d: 'M20 6a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-2' }],
  ['path', { d: 'M8 6a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-3' }],
  ['path', { d: 'M6 8v8', key: 'svg-4' }],
  ['path', { d: 'M18 16v-8', key: 'svg-5' }],
  ['path', { d: 'M8 6h8', key: 'svg-6' }],
  ['path', { d: 'M16 18h-8', key: 'svg-7' }],
]);
