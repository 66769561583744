import createReactComponent from '../createReactComponent';
export default createReactComponent('calendar-pause', 'IconCalendarPause', [
  [
    'path',
    {
      d: 'M13 21h-7a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3v4', key: 'svg-1' }],
  ['path', { d: 'M8 3v4', key: 'svg-2' }],
  ['path', { d: 'M4 11h16', key: 'svg-3' }],
  ['path', { d: 'M17 17v5', key: 'svg-4' }],
  ['path', { d: 'M21 17v5', key: 'svg-5' }],
]);
