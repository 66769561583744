import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-cog',
  'IconDeviceDesktopCog',
  [
    [
      'path',
      {
        d: 'M12 16h-8a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h5', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    [
      'path',
      { d: 'M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' },
    ],
    ['path', { d: 'M19.001 15.5v1.5', key: 'svg-4' }],
    ['path', { d: 'M19.001 21v1.5', key: 'svg-5' }],
    ['path', { d: 'M22.032 17.25l-1.299 .75', key: 'svg-6' }],
    ['path', { d: 'M17.27 20l-1.3 .75', key: 'svg-7' }],
    ['path', { d: 'M15.97 17.25l1.3 .75', key: 'svg-8' }],
    ['path', { d: 'M20.733 20l1.3 .75', key: 'svg-9' }],
  ],
);
