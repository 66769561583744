import createReactComponent from '../createReactComponent';
export default createReactComponent('solar-panel', 'IconSolarPanel', [
  [
    'path',
    {
      d: 'M4.28 14h15.44a1 1 0 0 0 .97 -1.243l-1.5 -6a1 1 0 0 0 -.97 -.757h-12.44a1 1 0 0 0 -.97 .757l-1.5 6a1 1 0 0 0 .97 1.243z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 10h16', key: 'svg-1' }],
  ['path', { d: 'M10 6l-1 8', key: 'svg-2' }],
  ['path', { d: 'M14 6l1 8', key: 'svg-3' }],
  ['path', { d: 'M12 14v4', key: 'svg-4' }],
  ['path', { d: 'M7 18h10', key: 'svg-5' }],
]);
