import React, { forwardRef, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { Surface } from '../../models/index';
import CardLogin from './CardLogin';
import SimpleLogin from './SimpleLogin';
import SplitLogin from './SplitLogin';
import { AuthLayoutType, CARD, SIMPLE, SPLIT } from './authLayoutTypes';

const loginLayoutTypeMap = {
  [SIMPLE]: SimpleLogin,
  [CARD]: CardLogin,
  [SPLIT]: SplitLogin,
};

const isFormValid = (email: any, password: any) =>
  email && email.length > 6 && password;

type LoginProps = {
  className?: string;
  errors?: (string | React.ReactNode)[];
  onSubmit?: (...args: any[]) => any;
  buttonText: string | React.ReactNode;
  emailLabel: string | React.ReactNode;
  forgotText?: string | React.ReactNode;
  initialEmail?: string;
  logoUrl: string;
  onClick?: (...args: any[]) => any;
  passwordLabel: string | React.ReactNode;
  registerText?: string | React.ReactNode;
  rememberLabel: string | React.ReactNode;
  titleText: string | React.ReactNode;
  type?: AuthLayoutType;
  splitChildren?: React.ReactNode;
  splitImageUrl: string;
  surface?: Surface;
};

const Login = forwardRef<any, LoginProps>(
  (
    {
      className,
      children,
      errors,
      initialEmail = '',
      logoUrl,
      emailLabel,
      onSubmit = () => null,
      buttonText,
      forgotText,
      onClick,
      passwordLabel,
      registerText,
      rememberLabel,
      splitImageUrl,
      titleText,
      type = SIMPLE,
      splitChildren,
      surface,
    },
    ref,
  ) => {
    const [email, setEmail] = useState(initialEmail);
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const handleOnSubmit = (event: any) => {
      event.preventDefault();
      if (isFormValid(email, password)) {
        onSubmit({
          email,
          password,
        });
      }
    };

    const LoginLayout = loginLayoutTypeMap[type];

    if (!LoginLayout) {
      return null;
    }

    return (
      <div
        className={`${className} flex min-h-screen w-full items-center justify-center overflow-hidden bg-gray-100`}
        ref={ref}
        onClick={onClick}
      >
        <LoginLayout
          email={email}
          errors={errors}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          rememberMe={rememberMe}
          setRememberMe={setRememberMe}
          logoUrl={logoUrl}
          emailLabel={emailLabel}
          buttonText={buttonText}
          forgotText={forgotText}
          passwordLabel={passwordLabel}
          registerText={registerText}
          rememberLabel={rememberLabel}
          splitImageUrl={splitImageUrl}
          titleText={titleText}
          splitChildren={splitChildren}
          onSubmit={handleOnSubmit}
          surface={surface}
        >
          {children}
        </LoginLayout>
      </div>
    );
  },
);

export default withTheme(Login);
