import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-rotary-last-left',
  'IconArrowRotaryLastLeft',
  [
    ['path', { d: 'M15 15a3 3 0 1 1 0 -6a3 3 0 0 1 0 6z', key: 'svg-0' }],
    ['path', { d: 'M15 15v6', key: 'svg-1' }],
    ['path', { d: 'M12.5 9.5l-6.5 -6.5', key: 'svg-2' }],
    ['path', { d: 'M11 3h-5v5', key: 'svg-3' }],
  ],
);
