import createReactComponent from '../createReactComponent';
export default createReactComponent('http-get', 'IconHttpGet', [
  [
    'path',
    { d: 'M7 8h-2a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2v-4h-1', key: 'svg-0' },
  ],
  ['path', { d: 'M14 8h-4v8h4', key: 'svg-1' }],
  ['path', { d: 'M10 12h2.5', key: 'svg-2' }],
  ['path', { d: 'M17 8h4', key: 'svg-3' }],
  ['path', { d: 'M19 8v8', key: 'svg-4' }],
]);
