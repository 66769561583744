import React, { useCallback, useMemo, useState } from 'react';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { LG, MD, SM, XL } from '@noloco/components/src/constants/tShirtSizes';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { darkModeColors } from '../../../constants/darkModeColors';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { fieldPermissions } from '../../../utils/permissions';
import { isMultiField } from '../../../utils/relationships';
import FilePreviewElement from './FilePreviewElement';
import RecordEmptyState from './RecordEmptyState';

const getFileValues = (
  fieldName: any,
  dataType: any,
  user: any,
  record: any,
  recordScope: any,
  fieldPermissionsEnabled: any,
) => {
  const field = dataType.fields.getByName(fieldName);
  if (!field) {
    return [];
  }

  const permissions = fieldPermissions(
    field,
    fieldPermissionsEnabled && dataType.permissionsEnabled,
    dataType.permissions,
    user,
  );

  if (!permissions.read) {
    return [];
  }

  if (isMultiField(field)) {
    const fieldFiles = get(record, [field.apiName, 'edges'], []);
    return fieldFiles.map((fileEdge: any) => get(fileEdge, 'node'));
  }

  const fieldValue = get(record, [field.apiName]);

  if (fieldValue !== undefined) {
    return [fieldValue];
  }

  return [];
};

const RecordFileGallery = ({
  record,
  size,
  showFileName,
  field,
  dataType,
  editorMode,
}: any) => {
  const { user } = useAuthWrapper();
  const [isDarkModeEnabled] = useDarkMode();
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const files = useMemo(
    // @ts-expect-error TS(2554): Expected 6 arguments, but got 5.
    () => getFileValues(field, dataType, user, record, fieldPermissionsEnabled),
    [field, dataType, user, record, fieldPermissionsEnabled],
  );

  const [localFileIndex, setFileIndex] = useState(0);
  const hasPreviousFile = localFileIndex > 0 && files[localFileIndex - 1];
  const hasNextFile = localFileIndex < files.length - 1;

  const previousFile = useCallback(() => {
    if (hasPreviousFile) {
      setFileIndex(localFileIndex - 1);
    }
  }, [localFileIndex, hasPreviousFile]);

  const nextFile = useCallback(() => {
    if (hasNextFile) {
      setFileIndex(localFileIndex + 1);
    }
  }, [localFileIndex, hasNextFile]);

  const file = useMemo(
    () => (files.length > 0 ? files[localFileIndex] : null),
    [localFileIndex, files],
  );

  if ((files.length === 0 || isNil(file)) && !editorMode) {
    return null;
  }

  if ((files.length === 0 || isNil(file)) && editorMode) {
    return <RecordEmptyState />;
  }

  const shouldShowArrows = files.length > 1;

  return (
    <div
      className={classNames('h-screen w-full overflow-hidden rounded-lg', {
        'max-h-screen-25': size === SM,
        'max-h-screen-50': size === MD,
        'max-h-screen-75': size === LG,
        'max-h-screen': size === XL,
      })}
    >
      <div
        className={`relative flex h-screen max-h-full w-full flex-col overflow-hidden border ${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two}`
            : 'border-gray-200 bg-white'
        }`}
      >
        {showFileName && (
          <div className="pb-1 pl-2 pr-2 pt-1 text-lg font-medium tracking-wider">
            {file?.name || ''}
          </div>
        )}
        <div className="relative flex h-full w-full items-center justify-center overflow-hidden">
          <FilePreviewElement file={file} />
        </div>
        {shouldShowArrows && hasPreviousFile && (
          <div className="absolute bottom-0 left-4 top-0 z-50 flex items-center">
            <button
              onClick={previousFile}
              disabled={!hasPreviousFile}
              className={`rounded-full border p-1 shadow-lg ${
                isDarkModeEnabled
                  ? `${darkModeColors.surfaces.elevation2} ${darkModeColors.text.secondary} ${darkModeColors.borders.two}`
                  : 'border-gray-200 bg-white text-gray-700'
              }`}
            >
              <IconArrowLeft size={20} />
            </button>
          </div>
        )}
        {shouldShowArrows && hasNextFile && (
          <div className="absolute bottom-0 right-4 top-0 z-50 ml-auto flex items-center">
            <button
              onClick={nextFile}
              disabled={!hasNextFile}
              className={`rounded-full border p-1 shadow-lg ${
                isDarkModeEnabled
                  ? `${darkModeColors.surfaces.elevation2} ${darkModeColors.text.secondary} ${darkModeColors.borders.two}`
                  : 'border-gray-200 bg-white text-gray-700'
              }`}
            >
              <IconArrowRight size={20} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

RecordFileGallery.defaultProps = { size: SM };

export default RecordFileGallery;
