import createReactComponent from '../createReactComponent';
export default createReactComponent('key-off', 'IconKeyOff', [
  [
    'path',
    {
      d: 'M10.17 6.159l2.316 -2.316a2.877 2.877 0 0 1 4.069 0l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.33 2.33',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14.931 14.948a2.863 2.863 0 0 1 -1.486 -.79l-.301 -.302l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.863 2.863 0 0 1 -.794 -1.504',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M15 9h.01', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
