import createReactComponent from '../createReactComponent';
export default createReactComponent('home-question', 'IconHomeQuestion', [
  [
    'path',
    {
      d: 'M20.136 11.136l-8.136 -8.136l-9 9h2v7a2 2 0 0 0 2 2h7',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M9 21v-6a2 2 0 0 1 2 -2h2c.467 0 .896 .16 1.236 .428', key: 'svg-1' },
  ],
  ['path', { d: 'M19 22v.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M19 19a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-3',
    },
  ],
]);
