import createReactComponent from '../createReactComponent';
export default createReactComponent('versions-off', 'IconVersionsOff', [
  [
    'path',
    {
      d: 'M10.184 6.162a2 2 0 0 1 1.816 -1.162h6a2 2 0 0 1 2 2v9m-1.185 2.827a1.993 1.993 0 0 1 -.815 .173h-6a2 2 0 0 1 -2 -2v-7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 7v10', key: 'svg-1' }],
  ['path', { d: 'M4 8v8', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
