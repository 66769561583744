import createReactComponent from '../createReactComponent';
export default createReactComponent('grill-off', 'IconGrillOff', [
  [
    'path',
    {
      d: 'M8 8h-3a6 6 0 0 0 6 6h2c.315 0 .624 -.024 .926 -.071m2.786 -1.214a5.99 5.99 0 0 0 2.284 -4.49l0 -.225h-7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18.827 18.815a2 2 0 1 1 -2.663 -2.633', key: 'svg-1' }],
  ['path', { d: 'M9 14l-3 6', key: 'svg-2' }],
  ['path', { d: 'M15 18h-8', key: 'svg-3' }],
  ['path', { d: 'M15 5v-1', key: 'svg-4' }],
  ['path', { d: 'M12 5v-1', key: 'svg-5' }],
  ['path', { d: 'M9 5v-1', key: 'svg-6' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
