import createReactComponent from '../createReactComponent';
export default createReactComponent('lock-exclamation', 'IconLockExclamation', [
  [
    'path',
    {
      d: 'M15 21h-8a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h10a2 2 0 0 1 1.734 1.002',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M8 11v-4a4 4 0 1 1 8 0v4', key: 'svg-2' }],
  ['path', { d: 'M19 16v3', key: 'svg-3' }],
  ['path', { d: 'M19 22v.01', key: 'svg-4' }],
]);
