import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'ironing-steam-off',
  'IconIroningSteamOff',
  [
    [
      'path',
      {
        d: 'M9 4h7.459a3 3 0 0 1 2.959 2.507l.577 3.464l.81 4.865a1 1 0 0 1 -.821 1.15',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M16 16h-13a7 7 0 0 1 6.056 -6.937', key: 'svg-1' }],
    ['path', { d: 'M13 9h6.8', key: 'svg-2' }],
    ['path', { d: 'M12 19v2', key: 'svg-3' }],
    ['path', { d: 'M8 19l-1 2', key: 'svg-4' }],
    ['path', { d: 'M16 19l1 2', key: 'svg-5' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
  ],
);
