import createReactComponent from '../createReactComponent';
export default createReactComponent('vector-triangle', 'IconVectorTriangle', [
  [
    'path',
    {
      d: 'M10 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M3 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M17 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M6.5 17.1l5 -9.1', key: 'svg-3' }],
  ['path', { d: 'M17.5 17.1l-5 -9.1', key: 'svg-4' }],
  ['path', { d: 'M7 19l10 0', key: 'svg-5' }],
]);
