import { useCallback } from 'react';
import merge from 'lodash/fp/merge';
import { useSelector } from 'react-redux';
import { BaseRecord } from '../../models/Record';
import { scopeSelector } from '../../selectors/dataSelectors';
import { useBuildDataItemRecordScope } from './useBuildDataItemRecordScope';

export const useBuildDataItemMergedScope = (
  formatDataItem: (record: BaseRecord) => BaseRecord,
  transformRecordScope: (
    scope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>,
  dataTypeName: string,
) => {
  const buildDataItemRecordScope = useBuildDataItemRecordScope(
    formatDataItem,
    transformRecordScope,
    dataTypeName,
  );
  const userScope = useSelector(scopeSelector);

  return useCallback(
    (dataItem: any) => merge(buildDataItemRecordScope(dataItem), userScope),
    [buildDataItemRecordScope, userScope],
  );
};
