import createReactComponent from '../createReactComponent';
export default createReactComponent('file-database', 'IconFileDatabase', [
  [
    'path',
    { d: 'M12 12.75m-4 0a4 1.75 0 1 0 8 0a4 1.75 0 1 0 -8 0', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M8 12.5v3.75c0 .966 1.79 1.75 4 1.75s4 -.784 4 -1.75v-3.75',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z',
      key: 'svg-3',
    },
  ],
]);
