import isNil from 'lodash/isNil';
import { Duration } from 'luxon';

export const getDurationFromString = (durationString: any) => {
  const duration = Duration.fromISOTime(String(durationString));
  // @ts-expect-error TS(2576): Property 'invalid' does not exist on type 'Duratio... Remove this comment to see the full error message
  if (duration.invalid) {
    const formattedValue = String(durationString)
      .replace(/[^-\d:.]/g, '')
      .replace(/::/g, ':')
      .replace(/:$/g, '');

    const splitValues = formattedValue.split(':');
    if (isNil(splitValues[0])) {
      return null;
    }

    const hours = parseInt(splitValues[0], 10);
    const isNegative = hours < 0;

    return Duration.fromObject({
      hours,
      minutes:
        splitValues.length > 1
          ? parseInt(splitValues[1], 10) * (isNegative ? -1 : 1)
          : undefined,
      seconds:
        splitValues.length > 2
          ? parseFloat(splitValues[2]) * (isNegative ? -1 : 1)
          : undefined,
    });
  }

  return duration;
};

const DURATION_STRING_FORMAT = 'h:mm:ss';

export const durationToString = (duration: Duration) => {
  if (duration.toMillis() < 0) {
    return `-${duration.negate().toFormat(DURATION_STRING_FORMAT)}`;
  }

  return duration.toFormat(DURATION_STRING_FORMAT);
};

export const millisecondsToDuration = (milliseconds: number) =>
  Duration.fromMillis(milliseconds);
