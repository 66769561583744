import createReactComponent from '../createReactComponent';
export default createReactComponent('schema-off', 'IconSchemaOff', [
  ['path', { d: 'M6 2h4v4m-4 0h-1v-1', key: 'svg-0' }],
  ['path', { d: 'M15 11v-1h5v4h-2', key: 'svg-1' }],
  ['path', { d: 'M5 18h5v4h-5z', key: 'svg-2' }],
  ['path', { d: 'M5 10h5v4h-5z', key: 'svg-3' }],
  ['path', { d: 'M10 12h2', key: 'svg-4' }],
  ['path', { d: 'M7.5 7.5v2.5', key: 'svg-5' }],
  ['path', { d: 'M7.5 14v4', key: 'svg-6' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
