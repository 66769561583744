import React, { forwardRef } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { AROUND, BETWEEN, CENTER, END, START } from '../constants/align';
import screens, { MD, SM, XL } from '../constants/screens';
import { omitElementProps } from '../utils/elementProps';
import DummyBlock from './DummyBlock';

const getFlexProps = (column: any, align: any, justify: any, suffix = '') => {
  const alignItems = column ? justify : align;
  const justifyContent = column ? align : justify;
  return {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    [`justify${suffix}`]: {
      [START]: 'start',
      [BETWEEN]: 'between',
      [AROUND]: 'around',
      [CENTER]: 'center',
      [END]: 'end',
    }[justifyContent],
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    [`items${suffix}`]: {
      [START]: 'start',
      [CENTER]: 'center',
      [END]: 'end',
    }[alignItems],
  };
};

const getRawProps = (props: any, screen: any) => {
  const suffix = screen ? `-${screen}` : '';
  const align = props[`align${suffix}`];
  const justify = props[`justify${suffix}`];
  return {
    ...(align !== undefined ? { align } : {}),
    ...(justify !== undefined ? { justify } : {}),
  };
};

const getFlexPropsForScreen = (props: any, screen: any) => {
  const screenIndex = screens.indexOf(screen);
  const inheritedColumn = screens
    .slice(0, screenIndex + 1)
    .reduce((baseCol, screenX) => {
      const screenColumn = props[`column-${screenX}`];
      return screenColumn !== undefined ? screenColumn : baseCol;
    }, props.column);
  const { align, justify } = getRawProps(props, screen);
  return getFlexProps(inheritedColumn, align, justify, `-${screen}`);
};

const getResponsiveFlexProps = (props: any) => {
  return {
    ...getFlexProps(props.column, props.align, props.justify),
    ...getFlexPropsForScreen(props, SM),
    ...getFlexPropsForScreen(props, MD),
    ...getFlexPropsForScreen(props, XL),
  };
};

type GroupProps = {
  className?: string;
  children: React.ReactNode;
};

const Group = forwardRef<any, GroupProps>(
  (
    {
      children,
      className,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Grou... Remove this comment to see the full error message
      editorMode,
      // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type 'Gro... Remove this comment to see the full error message
      elementPath,
      // @ts-expect-error TS(2339): Property 'is' does not exist on type 'GroupProps'.
      is,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'GroupPr... Remove this comment to see the full error message
      onClick,
      // @ts-expect-error TS(2339): Property 'onSubmit' does not exist on type 'GroupP... Remove this comment to see the full error message
      onSubmit,
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'GroupProp... Remove this comment to see the full error message
      style,
      ...rest
    },
    ref,
  ) => {
    const responsiveProps = getResponsiveFlexProps(rest);
    return (
      <Box
        className={classNames('flex max-w-screen-lg', className, {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          'space-y-8': className.includes('flex-col'),
          'space-x-8 md:space-x-0 md:space-y-8':
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            !className.includes('flex-col'),
        })}
        is={is}
        ref={ref}
        style={style}
        onClick={onClick}
        onSubmit={onSubmit}
        {...omitElementProps(rest)}
        align={undefined}
        {...responsiveProps}
      >
        {children}
        {editorMode && !is && (rest as any).column === true && (
          <DummyBlock elementPath={elementPath} />
        )}
      </Box>
    );
  },
);

Group.defaultProps = {
  className: '',
};

export default Group;
