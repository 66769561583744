import { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useDrag, useDrop } from 'react-dnd';
import { Item, ItemTypes } from '../../../constants/buildMode';
import { DIVIDER, FOLDER, LINK, VIEW } from '../../../constants/elements';
import { Project } from '../../../models/Project';
import { Page } from '../../../utils/pages';
import AddPageLine from '../../sidebar/AddPageLine';
import DraggableBuildModeSidebarItem from './DraggableBuildModeSidebarItem';

type BuildModeBaseItemProps = {
  handleDrop: (target: Item, draggedItem: Item, targetType?: string) => void;
  isNestedOrFolder?: boolean;
  listExpanded: boolean;
  item: Item;
  portalPages: Page[];
  project: Project;
  showHiddenPages?: boolean;
  sidebarExpanded?: boolean;
};

const BuildModeBaseItem = ({
  handleDrop,
  isNestedOrFolder = false,
  listExpanded,
  item,
  portalPages,
  project,
  showHiddenPages = false,
  sidebarExpanded = true,
}: BuildModeBaseItemProps) => {
  const [{ draggedItem, isDragging }, dragRef] = useDrag({
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
      isDragging: monitor.isDragging(),
    }),
    item,
    type: ItemTypes.SINGLE_ITEM,
  });

  const [{ isOver: isAbove }, aboveDropRef] = useDrop({
    accept: [
      ItemTypes.SINGLE_ITEM,
      ...(isNestedOrFolder ? [] : [ItemTypes.FOLDER, ItemTypes.NESTED_ITEM]),
    ],
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: () => handleDrop(item, draggedItem),
  });

  const [{ isOver }, dropRef] = useDrop({
    accept: [
      ItemTypes.SINGLE_ITEM,
      ...(isNestedOrFolder ? [] : [ItemTypes.FOLDER, ItemTypes.NESTED_ITEM]),
    ],
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: () => handleDrop(item, draggedItem, ItemTypes.NESTED_ITEM),
  });

  const dataType = get(item, 'page.props.dataList.dataType');
  const draggedItemDataType = get(draggedItem, 'page.props.dataList.dataType');

  const canDropAbove = useMemo(() => {
    if (
      draggedItem &&
      draggedItem.new &&
      ((draggedItem.type === ItemTypes.SINGLE_ITEM &&
        draggedItem.page.type === LINK) ||
        draggedItem.type === ItemTypes.FOLDER) &&
      item.parentPageType === FOLDER
    ) {
      return false;
    }

    return (
      !showHiddenPages &&
      (!(isNestedOrFolder && get(draggedItem, 'page.type') === DIVIDER) ||
        (item.parentPageType !== FOLDER &&
          get(item, 'page.type') === VIEW &&
          get(draggedItem, 'page.type') === VIEW &&
          dataType === draggedItemDataType))
    );
  }, [
    showHiddenPages,
    isNestedOrFolder,
    item,
    draggedItem,
    dataType,
    draggedItemDataType,
  ]);

  const canDrop = useMemo(
    () =>
      !showHiddenPages &&
      item.parentPageType !== FOLDER &&
      item.page.type === VIEW &&
      dataType === draggedItemDataType &&
      item.id !== draggedItem.id &&
      draggedItem.type !== ItemTypes.NESTED_ITEM &&
      (!get(item, 'parentPageId') || !get(item, 'page.props.parentPage')),
    [showHiddenPages, item, draggedItem, dataType, draggedItemDataType],
  );

  const parentPage = useMemo(() => {
    if (item.parentPageId) {
      return portalPages.find(
        (portalPage) => portalPage.id === item.parentPageId,
      );
    }
  }, [item, portalPages]);

  return (
    <>
      <div
        className={classNames('h-1 w-full rounded-full', {
          'bg-slate-600': isAbove,
        })}
        id={`sidebar-item-${item.id}`}
        ref={canDropAbove ? aboveDropRef : null}
      />
      <div ref={canDrop ? dropRef : null}>
        {!showHiddenPages && parentPage?.type !== VIEW && (
          <AddPageLine
            page={item.page}
            parentPage={parentPage}
            portalPages={portalPages}
            project={project}
          />
        )}
        <DraggableBuildModeSidebarItem
          elementPath={item.elementPath}
          isDragging={isDragging}
          isOver={isOver}
          listExpanded={listExpanded}
          item={item}
          portalPages={portalPages}
          project={project}
          ref={sidebarExpanded ? dragRef : undefined}
          showHiddenPages={showHiddenPages}
          sidebarExpanded={sidebarExpanded}
        />
      </div>
    </>
  );
};

export default BuildModeBaseItem;
