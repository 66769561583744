import merge from 'lodash/fp/merge';
import omit from 'lodash/omit';
import { Variant } from '@noloco/components/src/constants/variants';
import { Theme } from '@noloco/components/src/models/theme';
import { getColorShade } from '@noloco/components/src/utils/colors';
import {
  CUSTOM_PRIMARY_COLOR as BASE_CUSTOM_PRIMARY_COLOR,
  DEFAULT_THEME,
  displays,
  positions,
} from '../constants/tailwindStyles';

export const CUSTOM_PRIMARY_COLOR = BASE_CUSTOM_PRIMARY_COLOR;

const validProps = (propsArr: any) => {
  const nonNullProps = propsArr.filter((prop: any) => prop !== undefined);

  return nonNullProps.length > 0 ? nonNullProps : undefined;
};

const isColor = (bg: string) => /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(bg);

export const formatBg = (
  bg: string | Record<string, string> | undefined,
): Record<string, string> | undefined => {
  if (!bg) {
    return undefined;
  }

  // If bg is already an object with style properties, return as-is
  if (typeof bg === 'object') {
    return bg;
  }

  const trimmedBg = bg.trim();

  // Handle hex colors
  if (isColor(trimmedBg)) {
    return { backgroundColor: trimmedBg };
  }

  // Handle gradients
  if (trimmedBg.includes('gradient')) {
    return { backgroundImage: trimmedBg };
  }

  // Handle data URLs
  if (trimmedBg.startsWith('data:')) {
    return { backgroundImage: `url('${trimmedBg}')` };
  }

  // Handle URLs, escaping parentheses
  return {
    backgroundImage: `url('${trimmedBg
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')}')`,
  };
};

const compoundPropKeys = [
  'wrap',
  'bgColor',
  'bgOpacity',
  'bgSize',
  'bgRepeat',
  'bgPosition',
  'textSize',
  'textColor',
  'textAlign',
  'fontWeight',
  'borderColor',
  'borderStyle',
  'borderWidth',
];

const objectToArray = (obj: any) => {
  const arr = Object.entries(obj).reduce(
    // @ts-expect-error TS(2769): No overload matches this call.
    (acc, [key, value]) => [...acc, ...(value ? [key] : [])],
    [],
  );

  return arr.length > 0 ? arr : [];
};

const reduceOptions = (options: any, comboValue: any) =>
  options.reduce(
    // @ts-expect-error TS(7006): Parameter 'propAcc' implicitly has an 'any' type.
    (propAcc, option) => ({
      ...propAcc,
      [option]: option === comboValue ? true : undefined,
    }),
    {},
  );

const getSpacing = (
  spacingKey: any,
  oldSpacing: any,
  _t: any,
  _r: any,
  _b: any,
  _l: any,
) => {
  const t = _t || oldSpacing.t;
  const r = _r || oldSpacing.r;
  const b = _b || oldSpacing.b;
  const l = _l || oldSpacing.l;
  const spacing = {};

  if (t !== undefined) {
    (spacing as any).t = t;
  }

  if (r !== undefined) {
    (spacing as any).r = r;
  }

  if (b !== undefined) {
    (spacing as any).b = b;
  }

  if (l !== undefined) {
    (spacing as any).l = l;
  }

  return Object.keys(spacing).length > 0 ? { [spacingKey]: spacing } : {};
};

export const transformTailwindProps = (props: any) => {
  if (!props) {
    return props;
  }
  const bgImage =
    props.bgImage && props.bgImage.src
      ? formatBg(props.bgImage.src)
      : undefined;
  const {
    m = {},
    mt,
    mr,
    mb,
    ml,
    p = {},
    pt,
    pr,
    pb,
    pl,
    ...restProps
  } = props;

  return omit(
    {
      ...restProps,
      ...reduceOptions(displays, props.display),
      ...reduceOptions(positions, props.position),
      ...getSpacing('m', m, mt, mr, mb, ml),
      ...getSpacing('p', p, pt, pr, pb, pl),
      flex: validProps([
        ...objectToArray({
          grow: props.flexGrow,
          wrap: props.wrap,
          col: props.column,
          flex: props.display === 'flex',
        }),
        props.display === 'flex' ? true : undefined,
      ]),
      text: validProps([props.textSize, props.textColor, props.textAlign]),
      font: validProps([props.fontWeight]),
      bg: validProps([
        props.bgColor,
        props.bgOpacity,
        props.bgSize,
        props.bgRepeat,
        props.bgPosition,
      ]),
      ...(bgImage ? { style: { ...bgImage } } : {}),
      border: validProps([
        props.borderColor,
        props.borderStyle,
        props.borderWidth,
      ]),
    },
    compoundPropKeys,
  );
};

const getGroupColorShade = (
  theme: any,
  colorCroup: Exclude<Exclude<Variant | 'success', 'info'>, 'custom'>,
  shade = 500,
) => {
  const color =
    theme.brandColorGroups[colorCroup] ??
    DEFAULT_THEME.brandColorGroups[colorCroup];

  return getColorShade(color, shade);
};

export const mergeThemes = (settingsTheme = {}): Theme => {
  const theme = merge(DEFAULT_THEME, settingsTheme);

  // TODO @darraghmckay: set this when we want to drop support for the existing class names
  // theme = set('brandColorGroups.primary', CUSTOM_PRIMARY_COLOR, theme);
  return {
    ...theme,
    brandColors: {
      primary: getGroupColorShade(theme, 'primary'),
      danger: getGroupColorShade(theme, 'danger'),
      secondary: getGroupColorShade(theme, 'secondary'),
      success: getGroupColorShade(theme, 'success', 400),
      warning: getGroupColorShade(theme, 'warning', 300),
    },
  };
};

export const stringifyPercent = (v: any) =>
  typeof v === 'string' ? v : v + '%';
