import createReactComponent from '../createReactComponent';
export default createReactComponent('calendar-bolt', 'IconCalendarBolt', [
  [
    'path',
    {
      d: 'M13.5 21h-7.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3v4', key: 'svg-1' }],
  ['path', { d: 'M8 3v4', key: 'svg-2' }],
  ['path', { d: 'M4 11h16', key: 'svg-3' }],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-4' }],
]);
