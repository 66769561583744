import shortId from 'shortid';
import { XL } from '@noloco/components/src/constants/tShirtSizes';
import {
  DIVIDER,
  FOLDER,
  IFRAME,
  LINK,
  PAGE,
} from '@noloco/core/src/constants/elements';
import { URL } from '@noloco/core/src/constants/linkTypes';

export const getSidebarItemProps = ({
  name,
  parentPage,
  routePath,
  type,
}: {
  name?: string;
  parentPage?: string;
  routePath?: string;
  type: string;
}): any => {
  switch (type) {
    case PAGE:
      return {
        id: shortId.generate(),
        props: {
          V2: true,
          ...(parentPage ? { parentPage } : {}),
        },
      };

    case IFRAME:
      return {
        id: shortId.generate(),
        props: {
          V2: true,
          ...(parentPage ? { parentPage } : {}),
          sections: [
            {
              id: shortId.generate(),
              type: IFRAME,
              props: {
                name: 'Iframe',
                fullScreen: true,
                size: XL,
              },
            },
          ],
        },
      };

    case DIVIDER:
      return { id: shortId.generate(), props: {}, type: DIVIDER };

    case LINK:
      return {
        id: shortId.generate(),
        type: LINK,
        children: [],
        props: {
          name,
          link: {
            url: [{ text: 'https://noloco.io' }],
            type: URL,
          },
          icon: { name: 'Link' },
        },
      };

    case FOLDER:
      return {
        id: shortId.generate(),
        type: FOLDER,
        children: [],
        props: {
          icon: { name: 'Folder' },
          name,
          routePath,
        },
      };
  }
};
