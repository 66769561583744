import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { BaseRecord, RecordEdge } from '../../../../models/Record';
import { Group } from '../../Collection';
import { DragggableRecordProps, useDraggableRecord } from '../DraggableRecord';
import { useDroppableGroup } from '../DroppableBoardGroup';

type Props = Omit<DragggableRecordProps, 'elementRef' | 'groupKey'> & {
  columnWidths: Record<number, number>;
  'data-index': number;
  edge: RecordEdge;
  group: Group;
  index: number;
  onDrop: (droppedRecord: BaseRecord, droppedGroup: Group) => void;
  dropIndicator: JSX.Element;
  RecordLayoutByEdge: React.FC<any>;
};

const DraggableRecordLayout = forwardRef<HTMLTableRowElement, Props>(
  (
    {
      columnWidths,
      'data-index': dataIndex,
      draggable,
      edge,
      group,
      index,
      onDrop,
      record,
      dropIndicator,
      RecordLayoutByEdge,
    },
    ref,
  ) => {
    const { isDragging, dragRef } = useDraggableRecord({
      elementRef: ref,
      draggable,
      groupKey: group.key,
      record,
    });

    const { isOver, dropRef } = useDroppableGroup({
      elementRef: ref,
      group,
      onDrop,
    });

    return (
      <>
        <RecordLayoutByEdge
          className={classNames({
            'opacity-50': isDragging,
          })}
          columnWidths={columnWidths}
          data-index={dataIndex}
          edge={edge}
          dataTestid="collection-group"
          data-group-key={group.key}
          index={index}
          ref={(element: Element) => dropRef(dragRef(element))}
        />
        {isOver && dropIndicator}
      </>
    );
  },
);

export default DraggableRecordLayout;
