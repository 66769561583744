import { useCallback, useEffect, useMemo } from 'react';
import first from 'lodash/first';
import { useSelector } from 'react-redux';
import { SwitchButton, Tooltip } from '@noloco/components';
import { OptionValue } from '@noloco/components/src/components/switch/SwitchButton';
import { DARK } from '@noloco/components/src/constants/surface';
import {
  BuildModeEditorTabs as BuildModeEditorTabsType,
  CHARTS_TAB,
  EMPTY_COLLECTION,
  FIELDS,
  GENERAL,
  OPTIONS,
  PAGES,
  SPACES,
  VALUES,
  VISIBILITY,
} from '../../constants/buildMode';
import { CHARTS, PIVOT_TABLE } from '../../constants/collectionLayouts';
import { CHART_ITEM, SIDEBAR_ITEM } from '../../constants/draggableItemTypes';
import {
  ACTION_BUTTONS,
  COLLECTION,
  CONTAINER,
  DETAILS,
  DIVIDER,
  EXPORT_BUTTON,
  FOLDER,
  FORM,
  HIGHLIGHTS,
  IMPORT_BUTTON,
  NEW_RECORD_BUTTON,
  PAGE,
  QUICK_LINKS,
  RECORD,
  SECTION,
  TABS,
  VIEW,
} from '../../constants/elements';
import { CMD, COMMA, PERIOD, SHIFT } from '../../constants/shortcuts';
import ShortcutKeys from '../../elements/sections/view/ShortcutKeys';
import { isFieldListEditorOpenSelector } from '../../selectors/elementsSelectors';
import useOnKeyPress from '../../utils/hooks/useOnKeyPress';
import { getText } from '../../utils/lang';

type BuildModeEditorTabsProps = {
  editorTab: BuildModeEditorTabsType;
  elementType: string;
  isNavEnabled?: boolean;
  setEditorTab: (editorTab: BuildModeEditorTabsType) => void;
};

const EditorTabs: { [key: string]: BuildModeEditorTabsType[] } = {
  [ACTION_BUTTONS]: [OPTIONS, VISIBILITY],
  [CHART_ITEM]: [OPTIONS, VISIBILITY],
  [CHARTS]: [GENERAL, OPTIONS, CHARTS_TAB, VISIBILITY],
  [COLLECTION]: [GENERAL, OPTIONS, FIELDS, VISIBILITY],
  [CONTAINER]: [VISIBILITY],
  [DETAILS]: [OPTIONS, FIELDS, VISIBILITY],
  [DIVIDER]: [VISIBILITY],
  [EMPTY_COLLECTION]: [GENERAL],
  [EXPORT_BUTTON]: [FIELDS, VISIBILITY],
  [FOLDER]: [VISIBILITY],
  [FORM]: [OPTIONS, FIELDS],
  [HIGHLIGHTS]: [FIELDS, VISIBILITY],
  [IMPORT_BUTTON]: [FIELDS, VISIBILITY],
  [NEW_RECORD_BUTTON]: [OPTIONS, VISIBILITY],
  [PAGE]: [OPTIONS, VISIBILITY],
  [PIVOT_TABLE]: [GENERAL, OPTIONS, VALUES, VISIBILITY],
  [QUICK_LINKS]: [OPTIONS, VISIBILITY],
  [RECORD]: [OPTIONS, VISIBILITY],
  [SECTION]: [OPTIONS, VISIBILITY],
  [SIDEBAR_ITEM]: [OPTIONS, VISIBILITY],
  [SPACES]: [PAGES, OPTIONS, VISIBILITY],
  [TABS]: [OPTIONS, VISIBILITY],
  [VIEW]: [GENERAL, OPTIONS, FIELDS, VISIBILITY],
};

const BuildModeEditorTabs = ({
  editorTab,
  elementType,
  setEditorTab,
  isNavEnabled = true,
}: BuildModeEditorTabsProps) => {
  const isFieldListEditorOpen = useSelector(isFieldListEditorOpenSelector);
  const editorTabs = EditorTabs[elementType].map((editorTab) => ({
    label: getText('rightSidebar.editor.tabs', editorTab),
    value: editorTab,
  }));

  const newEditorTab = useMemo(() => {
    const tabs = EditorTabs[elementType];
    if (tabs.includes(editorTab)) {
      return editorTab;
    }

    return first(tabs);
  }, [editorTab, elementType]);

  const activeTabIndex = useMemo(
    () => editorTabs.findIndex((tab) => tab.value === newEditorTab),
    [editorTabs, newEditorTab],
  );

  const handlePrevTab = useCallback(() => {
    const newIndex = activeTabIndex - 1;

    setEditorTab(
      editorTabs[newIndex >= 0 ? newIndex : editorTabs.length - 1].value,
    );
  }, [setEditorTab, activeTabIndex, editorTabs]);

  const handleNextTab = useCallback(() => {
    const newIndex = activeTabIndex + 1;

    setEditorTab(
      newIndex <= editorTabs.length - 1
        ? editorTabs[newIndex].value
        : editorTabs[0].value,
    );
  }, [setEditorTab, activeTabIndex, editorTabs]);

  const shortcutEnabled = useMemo(
    () => isNavEnabled && !isFieldListEditorOpen,
    [isNavEnabled, isFieldListEditorOpen],
  );

  useOnKeyPress(',', handlePrevTab, {
    ctrlKey: true,
    shiftKey: true,
    enabled: shortcutEnabled,
  });

  useOnKeyPress('.', handleNextTab, {
    ctrlKey: true,
    shiftKey: true,
    enabled: shortcutEnabled,
  });

  useEffect(() => {
    if (newEditorTab !== editorTab) {
      setEditorTab(newEditorTab as BuildModeEditorTabsType);
    }
  }, [editorTab, newEditorTab, setEditorTab]);

  if (editorTabs.length === 1) {
    return null;
  }

  return (
    <Tooltip
      content={
        <div className="flex items-center space-x-2 text-slate-200">
          <span className="flex items-center space-x-2">
            <span>{getText('rightSidebar.editor.tabs.prevTab')}</span>
            <ShortcutKeys buildMode={true} keys={[CMD, SHIFT, COMMA]} />
          </span>
          <span className="flex items-center space-x-2">
            <span>{getText('rightSidebar.editor.tabs.nextTab')}</span>
            <ShortcutKeys buildMode={true} keys={[CMD, SHIFT, PERIOD]} />
          </span>
        </div>
      }
      delayShow={400}
      disabled={!shortcutEnabled}
      placement="left"
      showArrow={false}
      surface={DARK}
    >
      <div className="p-2">
        <SwitchButton
          className="h-8 rounded-lg"
          onChange={(value) => setEditorTab(value as BuildModeEditorTabsType)}
          options={editorTabs}
          value={newEditorTab as OptionValue}
        />
      </div>
    </Tooltip>
  );
};

export default BuildModeEditorTabs;
