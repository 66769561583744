import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-chevrons-down',
  'IconSquareChevronsDown',
  [
    ['path', { d: 'M15 9l-3 3l-3 -3', key: 'svg-0' }],
    ['path', { d: 'M15 13l-3 3l-3 -3', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-2',
      },
    ],
  ],
);
