import createReactComponent from '../createReactComponent';
export default createReactComponent('lock-access', 'IconLockAccess', [
  ['path', { d: 'M4 8v-2a2 2 0 0 1 2 -2h2', key: 'svg-0' }],
  ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-1' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-2' }],
  ['path', { d: 'M16 20h2a2 2 0 0 0 2 -2v-2', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M8 11m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M10 11v-2a2 2 0 1 1 4 0v2', key: 'svg-5' }],
]);
