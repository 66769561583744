import React, { useLayoutEffect, useRef, useState } from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';

type GoogleLoginButtonProps = {
  defaultButtonWidth: number;
  onSuccess: (credentialResponse: CredentialResponse) => void;
  preventNonWorkspaceAccounts: boolean;
};

const GoogleLoginButton = ({
  defaultButtonWidth,
  onSuccess,
  preventNonWorkspaceAccounts = false,
}: GoogleLoginButtonProps) => {
  const buttonWrapperRef = useRef();
  const [buttonWidth, setButtonWidth] = useState<string>(
    defaultButtonWidth.toString(),
  );

  useLayoutEffect(() => {
    if (buttonWrapperRef.current) {
      const wrapperWidth = (buttonWrapperRef.current as any).clientWidth;
      if (wrapperWidth !== buttonWidth) {
        setButtonWidth(wrapperWidth);
      }
    }
  }, [buttonWidth]);

  return (
    // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
    <div ref={buttonWrapperRef}>
      <GoogleLogin
        onSuccess={onSuccess}
        text="continue_with"
        width={buttonWidth}
        hosted_domain={preventNonWorkspaceAccounts ? '*' : ''} // This will show only Google Workspace accounts in the Google Login popup.
      />
    </div>
  );
};

GoogleLoginButton.defaultProps = {
  defaultButtonWidth: 312,
};

export default GoogleLoginButton;
