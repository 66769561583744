import createReactComponent from '../createReactComponent';
export default createReactComponent('notebook', 'IconNotebook', [
  [
    'path',
    {
      d: 'M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 8l2 0', key: 'svg-1' }],
  ['path', { d: 'M13 12l2 0', key: 'svg-2' }],
]);
