import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'building-factory-2',
  'IconBuildingFactory2',
  [
    ['path', { d: 'M3 21h18', key: 'svg-0' }],
    ['path', { d: 'M5 21v-12l5 4v-4l5 4h4', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M19 21v-8l-1.436 -9.574a.5 .5 0 0 0 -.495 -.426h-1.145a.5 .5 0 0 0 -.494 .418l-1.43 8.582',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M9 17h1', key: 'svg-3' }],
    ['path', { d: 'M14 17h1', key: 'svg-4' }],
  ],
);
