import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-imac-heart',
  'IconDeviceImacHeart',
  [
    [
      'path',
      {
        d: 'M10 17h-6a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 13h9', key: 'svg-1' }],
    ['path', { d: 'M8 21h3.5', key: 'svg-2' }],
    ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
    [
      'path',
      {
        d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
        key: 'svg-4',
      },
    ],
  ],
);
