import React, { useMemo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconFileDescription, IconFilePlus } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Element } from '../../models/Element';
import useRouter from '../../utils/hooks/useRouter';
import { getText } from '../../utils/lang';

type BaseBuildModePagesProps = { element: Element };

const BASE_FORM = 'BASE_FORM';
const BASE_RECORD = 'BASE_RECORD';

const BaseBuildModePages = ({ element }: BaseBuildModePagesProps) => {
  const { pathname } = useRouter();
  const routes = useMemo(() => pathname.slice(1).split('/'), [pathname]);
  const childRoute = useMemo(
    () => (routes.length > 1 ? routes[1] : null),
    [routes],
  );
  const formTitle = get(element, 'props.new.title', null);
  const routePath = `/${get(element, 'props.routePath')}`;
  const newFormRoutePath = `${routePath}/new`;

  const basePages = useMemo(
    () => [
      ...(formTitle
        ? [
            {
              id: BASE_FORM,
              name:
                get(first(formTitle), 'text') ||
                getText('leftSidebar.auth.baseForm'),
              icon: IconFilePlus,
              link: newFormRoutePath,
            },
          ]
        : []),
      ...(childRoute === 'view'
        ? [
            {
              id: BASE_RECORD,
              name: getText('leftSidebar.auth.baseRecord'),
              icon: IconFileDescription,
              link: routePath,
            },
          ]
        : []),
    ],
    [formTitle, newFormRoutePath, routePath, childRoute],
  );

  return (
    <div className="flex w-full flex-col pl-6">
      {basePages.map(({ icon: Icon, id, link, name }) => {
        const isBaseForm = id === BASE_FORM;
        const isBaseRecord = id === BASE_RECORD;
        const isFormActive = childRoute === 'new';
        const isRecordActive = childRoute === 'view';
        const isActive =
          (isBaseForm && isFormActive) || (isBaseRecord && isRecordActive);

        return (
          <Box
            className={classNames(
              'mx-3 my-0.5 flex h-8 items-center space-x-2 rounded-lg px-3 py-0.5 hover:bg-slate-600',
              {
                'bg-slate-900 text-white': isActive,
                'text-slate-200 hover:bg-slate-600': !isActive,
              },
            )}
            is={Link}
            key={id}
            to={link}
          >
            <Icon size={16} />
            <span>{name}</span>
          </Box>
        );
      })}
    </div>
  );
};

export default BaseBuildModePages;
