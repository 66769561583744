import createReactComponent from '../createReactComponent';
export default createReactComponent('eye-table', 'IconEyeTable', [
  ['path', { d: 'M8 18h-.011', key: 'svg-0' }],
  ['path', { d: 'M12 18h-.011', key: 'svg-1' }],
  ['path', { d: 'M16 18h-.011', key: 'svg-2' }],
  ['path', { d: 'M4 3h16', key: 'svg-3' }],
  ['path', { d: 'M5 3v17a1 1 0 0 0 1 1h12a1 1 0 0 0 1 -1v-17', key: 'svg-4' }],
  ['path', { d: 'M14 7h-4', key: 'svg-5' }],
  ['path', { d: 'M9 15h1', key: 'svg-6' }],
  ['path', { d: 'M14 15h1', key: 'svg-7' }],
  ['path', { d: 'M12 11v-4', key: 'svg-8' }],
]);
