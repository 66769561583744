import createReactComponent from '../createReactComponent';
export default createReactComponent('arrow-big-up', 'IconArrowBigUp', [
  [
    'path',
    {
      d: 'M9 20v-8h-3.586a1 1 0 0 1 -.707 -1.707l6.586 -6.586a1 1 0 0 1 1.414 0l6.586 6.586a1 1 0 0 1 -.707 1.707h-3.586v8a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
]);
