import createReactComponent from '../createReactComponent';
export default createReactComponent('xbox-y', 'IconXboxY', [
  [
    'path',
    {
      d: 'M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 8l3 4', key: 'svg-1' }],
  ['path', { d: 'M15 8l-2.988 3.984l-.012 4.016', key: 'svg-2' }],
]);
