import { DebounceSettings, DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import identity from 'lodash/identity';

const debounceByFirstArg = <T extends (...args: Parameters<T>) => any>(
  func: T,
  waitMs?: number,
  options?: DebounceSettings,
  formatKey: (v: any) => any = identity,
) => {
  const memory: Record<any, DebouncedFunc<T>> = {};

  return (...args: Parameters<T>) => {
    // use first argument as a key
    const firstArg = first(args);
    const key = formatKey(firstArg);

    if (memory[key]) {
      return memory[key](...args);
    }

    memory[key] = debounce(func, waitMs, options);
    return memory[key](...args);
  };
};

export default debounceByFirstArg;
