import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-blackberry', 'IconBrandBlackberry', [
  [
    'path',
    { d: 'M7 6a1 1 0 0 0 -1 -1h-2l-.5 2h2.5a1 1 0 0 0 1 -1z', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M6 12a1 1 0 0 0 -1 -1h-2l-.5 2h2.5a1 1 0 0 0 1 -1z', key: 'svg-1' },
  ],
  [
    'path',
    { d: 'M13 12a1 1 0 0 0 -1 -1h-2l-.5 2h2.5a1 1 0 0 0 1 -1z', key: 'svg-2' },
  ],
  [
    'path',
    { d: 'M14 6a1 1 0 0 0 -1 -1h-2l-.5 2h2.5a1 1 0 0 0 1 -1z', key: 'svg-3' },
  ],
  [
    'path',
    { d: 'M12 18a1 1 0 0 0 -1 -1h-2l-.5 2h2.5a1 1 0 0 0 1 -1z', key: 'svg-4' },
  ],
  [
    'path',
    { d: 'M20 15a1 1 0 0 0 -1 -1h-2l-.5 2h2.5a1 1 0 0 0 1 -1z', key: 'svg-5' },
  ],
  [
    'path',
    { d: 'M21 9a1 1 0 0 0 -1 -1h-2l-.5 2h2.5a1 1 0 0 0 1 -1z', key: 'svg-6' },
  ],
]);
