import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-youtube-kids',
  'IconBrandYoutubeKids',
  [
    [
      'path',
      {
        d: 'M17.782 17.03l-3.413 .235l-.023 0c-1.117 .09 -2.214 .335 -3.257 .725l-2.197 .794a3.597 3.597 0 0 1 -2.876 -.189a3.342 3.342 0 0 1 -1.732 -2.211l-1.204 -5.293a3.21 3.21 0 0 1 .469 -2.503a3.468 3.468 0 0 1 2.177 -1.452l9.843 -2.06c1.87 -.392 3.716 .744 4.124 2.537l1.227 5.392a3.217 3.217 0 0 1 -.61 2.7a3.506 3.506 0 0 1 -2.528 1.323z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M10 10l.972 4l4.028 -3z', key: 'svg-1' }],
  ],
);
