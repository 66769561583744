import createReactComponent from '../createReactComponent';
export default createReactComponent('atom', 'IconAtom', [
  ['path', { d: 'M12 12v.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M19.071 4.929c-1.562 -1.562 -6 .337 -9.9 4.243c-3.905 3.905 -5.804 8.337 -4.242 9.9c1.562 1.561 6 -.338 9.9 -4.244c3.905 -3.905 5.804 -8.337 4.242 -9.9',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M4.929 4.929c-1.562 1.562 .337 6 4.243 9.9c3.905 3.905 8.337 5.804 9.9 4.242c1.561 -1.562 -.338 -6 -4.244 -9.9c-3.905 -3.905 -8.337 -5.804 -9.9 -4.242',
      key: 'svg-2',
    },
  ],
]);
