import createReactComponent from '../createReactComponent';
export default createReactComponent('square-root-2', 'IconSquareRoot2', [
  [
    'path',
    {
      d: 'M13 12h1c1 0 1 1 2.016 3.527c.984 2.473 .984 3.473 1.984 3.473h1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 19c1.5 0 3 -2 4 -3.5s2.5 -3.5 4 -3.5', key: 'svg-1' }],
  ['path', { d: 'M3 12h1l3 8l3 -16h10', key: 'svg-2' }],
]);
