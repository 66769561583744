import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'align-box-left-middle',
  'IconAlignBoxLeftMiddle',
  [
    [
      'path',
      {
        d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 15h-2', key: 'svg-1' }],
    ['path', { d: 'M13 12h-6', key: 'svg-2' }],
    ['path', { d: 'M11 9h-4', key: 'svg-3' }],
  ],
);
