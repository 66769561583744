import React from 'react';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';

const RightSidebarContainer = () => {
  const {
    query: { _comments },
  } = useRouter();

  const commentsAreOpen = _comments === 'true';

  if (!commentsAreOpen) {
    return <div className="right-sidebar h-screen sm:h-full" />;
  }

  return (
    <div className="right-sidebar noloco-comments-container max-w-screen sticky top-0 flex max-h-screen w-96 flex-shrink-0 overflow-hidden sm:w-screen xl:w-0" />
  );
};

export default RightSidebarContainer;
