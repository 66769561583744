import createReactComponent from '../createReactComponent';
export default createReactComponent('refresh-off', 'IconRefreshOff', [
  [
    'path',
    {
      d: 'M20 11a8.1 8.1 0 0 0 -11.271 -6.305m-2.41 1.624a8.083 8.083 0 0 0 -1.819 2.681m-.5 -4v4h4',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M4 13a8.1 8.1 0 0 0 13.671 4.691m2.329 -1.691v-1h-1', key: 'svg-1' },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
