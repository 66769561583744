import createReactComponent from '../createReactComponent';
export default createReactComponent('adjustments-x', 'IconAdjustmentsX', [
  ['path', { d: 'M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M6 4v4', key: 'svg-1' }],
  ['path', { d: 'M6 12v8', key: 'svg-2' }],
  ['path', { d: 'M13.653 14.874a2 2 0 1 0 -.586 2.818', key: 'svg-3' }],
  ['path', { d: 'M12 4v10', key: 'svg-4' }],
  ['path', { d: 'M12 18v2', key: 'svg-5' }],
  ['path', { d: 'M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-6' }],
  ['path', { d: 'M18 4v1', key: 'svg-7' }],
  ['path', { d: 'M18 9v4', key: 'svg-8' }],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-9' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-10' }],
]);
