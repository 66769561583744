import React, { forwardRef, useState } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Register as RegisterLayout } from '@noloco/components';
import SimpleLayout from '@noloco/components/src/components/auth/SimpleLayout';
import SubmitButton from '@noloco/components/src/components/auth/SubmitButton';
import FormField from '@noloco/components/src/components/form/FormField';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { ProjectSettings } from '../models/Project';
import { projectMediaSelector } from '../selectors/projectSelectors';
import { extractErrorMessages } from '../utils/errors';
import { useAuth } from '../utils/hooks/useAuth';
import { useUpdateUserCache } from '../utils/hooks/useAuthWrapper';
import useRouter from '../utils/hooks/useRouter';
import { getProjectAuthLogo } from '../utils/image';
import { getText } from '../utils/lang';

type Props = {
  settings: ProjectSettings;
  type?: any; // TODO: PropTypes.oneOf(authLayoutTypes)
};

const ForgotPassword = forwardRef<any, Props>(({ settings }, ref) => {
  const {
    query: { resetToken },
    push,
  } = useRouter();

  const media = useSelector(projectMediaSelector);

  const updateUserCache = useUpdateUserCache();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState([]);
  const { recoverPassword, resetPassword } = useAuth();

  const handleRecoverSubmit = (e: any) => {
    e.preventDefault();
    recoverPassword(email)
      .then(() => {
        setSubmitted(true);
      })
      .catch((error: any) => {
        setErrors(error.graphQLErrors.map((er: any) => er.message));
        const networkError = get(error, 'networkError.result.message');
        if (networkError) {
          // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
          setErrors([networkError]);
        }
        console.warn('ERROR', JSON.stringify(error, undefined, 2));
      });
  };

  const handleResetSubmit = ({
    email: confirmedEmail,
    password,
    confirmPassword,
  }: any) => {
    resetPassword(confirmedEmail, password, confirmPassword, { resetToken })
      .then((user: any) => {
        updateUserCache(user);
        push('/');
      })
      .catch((error: any) => {
        const errors = extractErrorMessages(error);
        if (errors.length > 0) {
          // @ts-expect-error TS(2345): Argument of type 'String[]' is not assignable to p... Remove this comment to see the full error message
          setErrors(errors);
        }
        console.warn('ERROR', JSON.stringify(error, undefined, 2));
      });
  };

  const logoUrl = getProjectAuthLogo(settings, media);

  if (resetToken) {
    return (
      <RegisterLayout
        ref={ref}
        errors={errors}
        logoUrl={logoUrl}
        hideEmail={false}
        showPhoneNumber={false}
        buttonText={getText('auth.resetPassword.confirm')}
        emailLabel={getText('auth.join.fields.email')}
        passwordLabel={getText('auth.resetPassword.password.label')}
        confirmPasswordLabel={getText(
          'auth.resetPassword.confirmPassword.label',
        )}
        titleText={getText('appAuth.resetPassword.title')}
        onSubmit={handleResetSubmit}
        type="CARD"
        errorTexts={{
          empty: getText('auth.register.validation.empty'),
          email: {
            invalid: getText('auth.register.validation.email.invalid'),
          },
          password: {
            invalid: getText('auth.register.validation.password.invalid'),
            tooShort: getText('auth.register.validation.password.tooShort'),
            tooLong: getText('auth.register.validation.password.tooLong'),
            numbers: getText('auth.register.validation.password.numbers'),
            case: getText('auth.register.validation.password.case'),
            symbol: getText('auth.register.validation.password.symbol'),
          },
          confirmPassword: {
            invalid: getText(
              'auth.register.validation.confirmPassword.invalid',
            ),
          },
        }}
        surface={LIGHT}
      />
    );
  }

  return (
    <div
      className="flex min-h-screen w-full items-center justify-center overflow-hidden bg-gray-100"
      ref={ref}
    >
      <SimpleLayout
        errors={errors}
        logoUrl={logoUrl}
        onSubmit={handleRecoverSubmit}
        titleText={getText('appAuth.forgotPassword.title')}
      >
        <div className="mt-8 overflow-hidden rounded-lg bg-white p-10 shadow sm:rounded-none sm:px-6">
          {!submitted && (
            <>
              <FormField
                aria-label="email"
                autoComplete="email"
                name="email"
                type="text"
                onChange={({ target: { value } }: any) => setEmail(value)}
                required
                errorType="below-solid"
                label={getText('auth.forgotPassword.email.label')}
                placeholder={getText('auth.forgotPassword.email.placeholder')}
                value={email}
                surface={LIGHT}
              />
              <SubmitButton disabled={!email || submitted}>
                {getText('auth.forgotPassword.confirm')}
              </SubmitButton>
            </>
          )}
          {submitted && (
            <p className="my-3">{getText('auth.forgotPassword.submitted')}</p>
          )}
        </div>
      </SimpleLayout>
    </div>
  );
});

ForgotPassword.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ className: string; }' is not assignable to... Remove this comment to see the full error message
  className: '',
};

export default ForgotPassword;
