export const ACTION_BUTTON = 'ActionButton';
export const CHART_ITEM = 'ChartItem';
export const LINK = 'LINK';
export const RECORD_FIELD = 'RecordField';
export const RECORD_TAB = 'RecordTab';
export const SECTION = 'SECTION';
export const SIDEBAR_ITEM = 'SIDEBAR_ITEM';
export const SIDEBAR_SECTION = 'SIDEBAR_SECTION';
export const SECTION_LIST_ITEM = 'SECTION_LIST_ITEM';
export const SPACE = 'SPACE';
