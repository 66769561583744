import createReactComponent from '../createReactComponent';
export default createReactComponent('headphones', 'IconHeadphones', [
  [
    'path',
    {
      d: 'M4 13m0 2a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M15 13m0 2a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M4 15v-3a8 8 0 0 1 16 0v3', key: 'svg-2' }],
]);
