import createReactComponent from '../createReactComponent';
export default createReactComponent('forklift', 'IconForklift', [
  ['path', { d: 'M5 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M14 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M7 17l5 0', key: 'svg-2' }],
  ['path', { d: 'M3 17v-6h13v6', key: 'svg-3' }],
  ['path', { d: 'M5 11v-4h4', key: 'svg-4' }],
  ['path', { d: 'M9 11v-6h4l3 6', key: 'svg-5' }],
  ['path', { d: 'M22 15h-3v-10', key: 'svg-6' }],
  ['path', { d: 'M16 13l3 0', key: 'svg-7' }],
]);
