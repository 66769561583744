import React from 'react';
import RegisterForm from './RegisterForm';
import SplitLayout from './SplitLayout';

const SplitRegister = ({
  children,
  disabled,
  errors,
  logoUrl,
  emailLabel,
  phoneNumberLabel,
  footer,
  onSubmit,
  buttonText,
  confirmPasswordLabel,
  passwordLabel,
  loginText,
  titleText,
  hideEmail,
  email,
  phoneNumber,
  showPhoneNumber,
  password,
  confirmPassword,
  setEmail,
  setPhoneNumber,
  setPassword,
  splitImageUrl,
  setConfirmPassword,
  emailErrorMessage,
  phoneNumberErrorMessage,
  passwordErrorMessage,
  confirmPasswordErrorMessage,
  surface,
  splitChildren,
  socialLogins,
}: any) => (
  <SplitLayout
    errors={errors}
    logoUrl={logoUrl}
    onSubmit={onSubmit}
    splitChildren={splitChildren}
    subTitleText={loginText}
    titleText={titleText}
    splitImageUrl={splitImageUrl}
  >
    <RegisterForm
      disabled={disabled}
      emailLabel={emailLabel}
      phoneNumberLabel={phoneNumberLabel}
      buttonText={buttonText}
      confirmPasswordLabel={confirmPasswordLabel}
      passwordLabel={passwordLabel}
      email={email}
      phoneNumber={phoneNumber}
      footer={footer}
      hideEmail={hideEmail}
      showPhoneNumber={showPhoneNumber}
      password={password}
      confirmPassword={confirmPassword}
      setEmail={setEmail}
      setPhoneNumber={setPhoneNumber}
      setPassword={setPassword}
      setConfirmPassword={setConfirmPassword}
      emailErrorMessage={emailErrorMessage}
      phoneNumberErrorMessage={phoneNumberErrorMessage}
      passwordErrorMessage={passwordErrorMessage}
      confirmPasswordErrorMessage={confirmPasswordErrorMessage}
      surface={surface}
      socialLogins={socialLogins}
    >
      {children}
    </RegisterForm>
  </SplitLayout>
);

export default SplitRegister;
