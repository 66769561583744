import createReactComponent from '../createReactComponent';
export default createReactComponent('viewfinder-off', 'IconViewfinderOff', [
  [
    'path',
    {
      d: 'M5.65 5.623a9 9 0 1 0 12.71 12.745m1.684 -2.328a9 9 0 0 0 -12.094 -12.08',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 3v4', key: 'svg-1' }],
  ['path', { d: 'M12 21v-3', key: 'svg-2' }],
  ['path', { d: 'M3 12h4', key: 'svg-3' }],
  ['path', { d: 'M21 12h-3', key: 'svg-4' }],
  ['path', { d: 'M12 12v.01', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
