import createReactComponent from '../createReactComponent';
export default createReactComponent('photo-dollar', 'IconPhotoDollar', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13 21h-7a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M3 16l5 -5c.928 -.893 2.072 -.893 3 0l2.5 2.5', key: 'svg-2' },
  ],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-4' }],
]);
