import createReactComponent from '../createReactComponent';
export default createReactComponent('folder', 'IconFolder', [
  [
    'path',
    {
      d: 'M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
]);
