import React, { forwardRef } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { DARK, LIGHT } from '../../constants/surface';
import { Surface } from '../../models';

type Props = {
  className?: string;
  children: any;
  m?: number | { r?: number; l?: number; b?: number; t?: number };
  surface?: Surface | 'default';
};

const Label = forwardRef<any, Props>(
  ({ children, className, surface, ...rest }, ref) => (
    <Box
      is="label"
      m={{ b: 1 }}
      className={classNames(
        'block text-sm font-medium leading-5',
        {
          'text-gray-700': surface === LIGHT,
          'text-white': !surface || surface === DARK,
        },
        className,
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </Box>
  ),
);

Label.defaultProps = {
  className: '',
};

export default Label;
