import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-down-circle',
  'IconArrowDownCircle',
  [
    ['path', { d: 'M12 7v14', key: 'svg-0' }],
    ['path', { d: 'M9 18l3 3l3 -3', key: 'svg-1' }],
    ['path', { d: 'M12 7a2 2 0 1 0 0 -4a2 2 0 0 0 0 4', key: 'svg-2' }],
  ],
);
