import createReactComponent from '../createReactComponent';
export default createReactComponent('barbell', 'IconBarbell', [
  ['path', { d: 'M2 12h1', key: 'svg-0' }],
  ['path', { d: 'M6 8h-2a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M6 7v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1 -1v-10a1 1 0 0 0 -1 -1h-1a1 1 0 0 0 -1 1z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M9 12h6', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M15 7v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1 -1v-10a1 1 0 0 0 -1 -1h-1a1 1 0 0 0 -1 1z',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M18 8h2a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-2', key: 'svg-5' }],
  ['path', { d: 'M22 12h-1', key: 'svg-6' }],
]);
