import createReactComponent from '../createReactComponent';
export default createReactComponent('color-swatch', 'IconColorSwatch', [
  [
    'path',
    {
      d: 'M19 3h-4a2 2 0 0 0 -2 2v12a4 4 0 0 0 8 0v-12a2 2 0 0 0 -2 -2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M13 7.35l-2 -2a2 2 0 0 0 -2.828 0l-2.828 2.828a2 2 0 0 0 0 2.828l9 9',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M7.3 13h-2.3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h12', key: 'svg-2' },
  ],
  ['path', { d: 'M17 17l0 .01', key: 'svg-3' }],
]);
