import createReactComponent from '../createReactComponent';
export default createReactComponent('marquee-2', 'IconMarquee2', [
  [
    'path',
    {
      d: 'M4 6v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1m-5 0h-2m-5 0h-1a1 1 0 0 1 -1 -1v-1m0 -5v-2',
      key: 'svg-0',
    },
  ],
]);
