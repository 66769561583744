import createReactComponent from '../createReactComponent';
export default createReactComponent('crutches', 'IconCrutches', [
  [
    'path',
    {
      d: 'M8 3m0 2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 21h2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 21v-4.092a3 3 0 0 1 .504 -1.664l.992 -1.488a3 3 0 0 0 .504 -1.664v-5.092',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M12 21v-4.092a3 3 0 0 0 -.504 -1.664l-.992 -1.488a3 3 0 0 1 -.504 -1.664v-5.092',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M10 11h4', key: 'svg-4' }],
]);
