import React, { useMemo } from 'react';
import {
  IconCreditCard,
  IconDots,
  IconFileDownload,
  IconMail,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Badge, Loader, Popover } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { DATE_SHORT } from '../../../constants/dateFormatOptions';
import RelationalDataFieldInput from '../../../elements/sections/collections/filters/RelationalDataFieldInput';
import { currencyToSymbol, formatCurrency } from '../../../utils/currency';
import { getDataTypeByName } from '../../../utils/data';
import { useGraphQlErrorAlert } from '../../../utils/hooks/useAlerts';
import useRouter from '../../../utils/hooks/useRouter';
import { getText } from '../../../utils/lang';
import Title from '../Title';
import CollectionTableHead from '../collections/CollectionTableHead';
import RelatedCellItem from '../collections/RelatedCellItem';
import StripeCustomerPortalButton from './StripeCustomerPortalButton';

const getStatusVariant = (status: any) =>
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  ({
    draft: 'secondary',
    open: 'primary',
    paid: 'success',
    uncollectable: 'danger',
    void: 'warning',
  })[status];

export const Td = ({ children, className, isLast, isFirst }: any) => (
  <td>
    <div
      className={classNames(
        className,
        'flex flex-col justify-center whitespace-nowrap py-1.5 text-sm',
        isLast ? 'pr-6' : 'pr-3',
        isFirst ? 'pl-6' : 'pl-3',
      )}
    >
      {children}
    </div>
  </td>
);

type InvoiceTableProps = {};

const InvoiceTable = ({
  // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Invoi... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'Invoice... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Invoice... Remove this comment to see the full error message
  onClick,
  // @ts-expect-error TS(2339): Property 'isInternal' does not exist on type 'Invo... Remove this comment to see the full error message
  isInternal,
  // @ts-expect-error TS(2339): Property 'isStripeConnected' does not exist on typ... Remove this comment to see the full error message
  isStripeConnected,
  // @ts-expect-error TS(2339): Property 'invoices' does not exist on type 'Invoic... Remove this comment to see the full error message
  invoices,
  // @ts-expect-error TS(2339): Property 'emptyState' does not exist on type 'Invo... Remove this comment to see the full error message
  emptyState,
  // @ts-expect-error TS(2339): Property 'customerField' does not exist on type 'I... Remove this comment to see the full error message
  customerField,
  // @ts-expect-error TS(2339): Property 'title' does not exist on type 'InvoiceTa... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(2339): Property 'showCustomerPortalButton' does not exist... Remove this comment to see the full error message
  showCustomerPortalButton,
  // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'Invoic... Remove this comment to see the full error message
  subtitle,
  // @ts-expect-error TS(2339): Property 'finalizeLoading' does not exist on type ... Remove this comment to see the full error message
  finalizeLoading,
  // @ts-expect-error TS(2339): Property 'finalizeInvoice' does not exist on type ... Remove this comment to see the full error message
  finalizeInvoice,
  // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Invoice... Remove this comment to see the full error message
  project,
}: InvoiceTableProps) => {
  const {
    pushQueryParams,
    query: { customerId },
  } = useRouter();
  const errorAlert = useGraphQlErrorAlert();

  const headers = useMemo(
    () =>
      ['description', 'amount', 'status', 'created', 'due', ''].map(
        (heading) => ({
          label: heading ? (
            <span
              className={classNames('w-full', {
                'block text-right': heading === 'amount',
              })}
            >
              {getText('core.BILLING.list', heading)}
            </span>
          ) : (
            ''
          ),
        }),
      ),
    [],
  );

  const EmptyState = useMemo(
    () => () => (
      <div className="flex w-full flex-col items-center justify-center p-16 text-center">
        <h2 className="text-base font-medium">{emptyState.title.value}</h2>
        {!emptyState.image.hidden && emptyState.image.value.src && (
          <img
            className="mt-4 w-full max-w-xs rounded-lg"
            src={emptyState.image.value.src}
            alt={emptyState.title.value}
          />
        )}
      </div>
    ),
    [emptyState],
  );

  const onFinalizeInvoice = (invoice: any) => {
    finalizeInvoice({
      variables: { id: invoice.id },
      context: { projectQuery: true, projectName: project.name },
    }).catch((e: any) => {
      errorAlert(getText('core.BILLING.errors.invoice.finalize'), e);
    });
  };

  const invoiceDataType = getDataTypeByName(project.dataTypes, 'invoice');

  return (
    <div className={classNames(className, 'flex flex-col')} onClick={onClick}>
      {(title || subtitle) && (
        <Title
          subtitle={{
            hidden: !subtitle,
            value: subtitle,
          }}
          title={{
            hidden: !title,
            value: title,
          }}
          className="mb-3"
        >
          {isStripeConnected && showCustomerPortalButton && !isInternal && (
            <StripeCustomerPortalButton project={project} />
          )}
          {isInternal && (
            <>
              <RelationalDataFieldInput
                field={invoiceDataType.fields.getByName('customer')}
                resultFilter={(customer: any) => customer.customerPaymentsId}
                dataTypes={project.dataTypes}
                multiple={false}
                projectName={project.name}
                placeholder={getText('core.BILLING.list.filter')}
                value={customerId}
                onChange={(value: any) =>
                  pushQueryParams({ customerId: value ? value.id : null })
                }
                isPlayground={false}
                surface={LIGHT}
                project={project}
              />
            </>
          )}
        </Title>
      )}
      <div className="w-full max-w-full overflow-x-auto rounded-lg border border-gray-200 bg-white shadow-lg">
        {loading && (
          <div className="flex w-full items-center justify-center p-8">
            <Loader type="Bars" size="sm" />
          </div>
        )}
        {!loading && invoices.length === 0 && <EmptyState />}
        {!loading && invoices.length > 0 && (
          <table className="min-w-full divide-y divide-gray-200">
            <CollectionTableHead
              additionalElements={headers}
              variables={{
                title: {
                  label: getText('core.BILLING.list.recipient'),
                  hidden: !isInternal,
                },
                secondaryText: {
                  hidden: true,
                },
              }}
            />
            <tbody className="divide-y divide-gray-200 bg-white">
              {invoices.map((invoice: any) => (
                <tr
                  key={invoice.id}
                  className="group w-full px-8 hover:bg-gray-100 hover:bg-opacity-25"
                >
                  {isInternal && (
                    <Td isFirst={true}>
                      <RelatedCellItem
                        dataTypes={project.dataTypes}
                        value={invoice.customer}
                        field={customerField}
                      />
                    </Td>
                  )}
                  <Td isFirst={!isInternal}>
                    <div className="flex items-center justify-between">
                      <span className="mr-4 block max-w-lg truncate">
                        {invoice.description}
                      </span>
                      {invoice.isSubscription && (
                        <div className="ml-auto">
                          <Badge>
                            {getText('core.BILLING.list.subscription')}
                          </Badge>
                        </div>
                      )}
                    </div>
                  </Td>
                  <Td className="text-right">
                    {currencyToSymbol(invoice.currency)}
                    {formatCurrency(invoice.total)}
                  </Td>
                  <Td>
                    <Badge variant={getStatusVariant(invoice.status)}>
                      {getText(
                        'core.BILLING.list.invoiceStatus',
                        invoice.status,
                      )}
                    </Badge>
                  </Td>
                  <Td>
                    {DateTime.fromISO(invoice.createdAt).toLocaleString(
                      // @ts-expect-error TS(2559): Type '"D"' has no properties in common with type '... Remove this comment to see the full error message
                      DATE_SHORT,
                    )}
                  </Td>
                  <Td>
                    {invoice.dueDate
                      ? DateTime.fromISO(invoice.dueDate).toLocaleString(
                          // @ts-expect-error TS(2559): Type '"D"' has no properties in common with type '... Remove this comment to see the full error message
                          DATE_SHORT,
                        )
                      : ''}
                  </Td>
                  <Td>
                    <Popover
                      closeOnOutsideClick={true}
                      placement="bottom-end"
                      className="overflow-hidden bg-white"
                      p={0}
                      rounded="lg"
                      shadow="lg"
                      content={
                        <div className="flex w-56 flex-col text-left">
                          {invoice.status === 'draft' && isInternal && (
                            <>
                              <button
                                onClick={() => onFinalizeInvoice(invoice)}
                                className="flex items-center px-6 py-4 text-left hover:bg-gray-100"
                              >
                                {!finalizeLoading && (
                                  <IconMail className="opacity-75" size={14} />
                                )}
                                {finalizeLoading && (
                                  <Loader className="opacity-75" size="sm" />
                                )}
                                <span className="ml-2">
                                  {getText('core.BILLING.list.actions.send')}
                                </span>
                              </button>
                            </>
                          )}
                          {invoice.pdf && (
                            <a
                              href={invoice.pdf}
                              target="_blank"
                              rel="noreferrer noopener"
                              className="flex items-center px-6 py-4 text-left hover:bg-gray-100"
                            >
                              <IconFileDownload
                                className="opacity-75"
                                size={14}
                              />
                              <span className="ml-2">
                                {getText('core.BILLING.list.actions.download')}
                              </span>
                            </a>
                          )}
                          {invoice.url && !isInternal && (
                            <a
                              href={invoice.url}
                              target="_blank"
                              rel="noreferrer noopener"
                              className="flex items-center px-6 py-4 text-left hover:bg-gray-100"
                            >
                              <IconCreditCard
                                className="opacity-75"
                                size={14}
                              />
                              <span className="ml-2">
                                {getText(
                                  'core.BILLING.list.actions',
                                  invoice.status === 'paid' ? 'details' : 'pay',
                                )}
                              </span>
                            </a>
                          )}
                        </div>
                      }
                    >
                      <button className="invisible flex items-center justify-center rounded-full p-2 text-gray-500 hover:bg-gray-100 focus:visible focus:bg-gray-100 group-hover:visible">
                        <IconDots size={16} />
                      </button>
                    </Popover>
                  </Td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default InvoiceTable;
