import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-intercom', 'IconBrandIntercom', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 8v3', key: 'svg-1' }],
  ['path', { d: 'M10 7v6', key: 'svg-2' }],
  ['path', { d: 'M14 7v6', key: 'svg-3' }],
  ['path', { d: 'M17 8v3', key: 'svg-4' }],
  ['path', { d: 'M7 15c4 2.667 6 2.667 10 0', key: 'svg-5' }],
]);
