import { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { UPDATE } from '../../constants/actionTypes';
import { getMutationQueryString } from '../../queries/project';
import { getFieldFromDependency } from '../fields';
import { getFieldKey } from '../fields';
import {
  isMultiRelationship,
  isReverseMultiRelationship,
} from '../relationships';

export const useUpdateRelatedRecordCollection = (
  dataList: any,
  project: any,
  rootDataType: any,
  dataType: any,
) => {
  const dataListField = useMemo(
    () =>
      getFieldFromDependency(
        get(dataList, ['filter', 'path'], '').split('.'),
        rootDataType,
        project.dataTypes,
      ),
    [dataList, rootDataType, project.dataTypes],
  );

  const isReverseMulti = useMemo(() => {
    if (dataListField?.field.relatedField) {
      return isMultiRelationship(
        dataListField?.field.relatedField.relationship,
      );
    } else {
      return isReverseMultiRelationship(dataListField?.field.relationship);
    }
  }, [dataListField]);

  const dataTypeField = useMemo(() => {
    if (dataListField?.field.relatedField) {
      return dataListField?.field.relatedField;
    }

    return dataType.fields.find(
      ({ relatedField }: any) =>
        get(relatedField, ['id']) === dataListField?.field.id,
    );
  }, [dataListField, dataType.fields]);
  const dataTypeFieldKey = useMemo(
    () => getFieldKey(dataTypeField),
    [dataTypeField],
  );

  const updateMutationQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(UPDATE, dataType.apiName, [dataTypeField])}
    `,
    [dataType.apiName, dataTypeField],
  );
  const mutationOptions = useMemo(
    () => ({
      context: {
        authQuery: null,
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [project.name],
  );
  const [update, { loading }] = useMutation(
    updateMutationQueryString,
    mutationOptions,
  );

  return {
    dataTypeField,
    fieldApiArgName: dataTypeFieldKey,
    isMultiRelationship: isReverseMulti,
    relationalDataField: dataListField?.field,
    update,
    updateLoading: loading,
  };
};
