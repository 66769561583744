import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  IconCheck,
  IconCircleNumber1,
  IconCircleNumber2,
  IconCircleNumber3,
  IconExternalLink,
  IconInfoCircle,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import {
  Button,
  Label,
  Loader,
  SelectInput,
  Surface,
  Tooltip,
} from '@noloco/components';
import { TEXT } from '@noloco/components/src/components/button/buttonTypes';
import { RIGHT } from '@noloco/components/src/components/navigation/headerNavTypes';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { useRefetchIntegrations } from '@noloco/core/src/utils/hooks/useRefetchIntegrations';
import { getText } from '@noloco/core/src/utils/lang';
import { buildConnectUri } from '@noloco/core/src/utils/oauth';
import airtableIcon from '../../img/airtable-icon.svg';
import { GET_AIRTABLE_BASES } from '../../queries/project';
import { openAuthorizationWindow } from '../../utils/oauth';
import Guide from '../Guide';

const LANG_KEY = 'data.airtable';

type Props = {
  authToken: string;
  disabled: boolean | undefined;
  hasAirtableOAuthIntegration: boolean;
  authorizationRedirect?: string;
  onChange: (value: number | null) => void;
  onNameChange?: (value: string | null) => void;
  projectName?: string;
  value: number | undefined | null;
  sharingLinkToClone?: string | undefined;
  surface: Surface;
};

const REMOVE_INVALID_DISPLAY_CHARS = /[^a-zA-ZÀ-Ÿ0-9-_\s]+/g;
const ConnectAirtableBase = ({
  authToken,
  disabled,
  hasAirtableOAuthIntegration,
  authorizationRedirect,
  onChange,
  onNameChange,
  projectName,
  value,
  sharingLinkToClone,
  surface,
}: Props) => {
  const [hasAirtableAccount, setHasAirtableAccount] = useState(
    hasAirtableOAuthIntegration === true ? true : null,
  );

  const [hasClickedCopy, setHasClickedCopy] = useState(false);

  const { data, refetch, loading } = useQuery(GET_AIRTABLE_BASES, {
    skip: !hasAirtableOAuthIntegration,
    variables: { projectId: projectName },
    notifyOnNetworkStatusChange: true,
  });
  const availableBases = useMemo(
    () =>
      get(data, ['airtableBases'], []).filter(
        (base: any) => base.permissionLevel === 'create' && !base.connected,
      ),
    [data],
  );

  const handleOnChange = useCallback(
    (id: number) => {
      onChange(id);
      if (onNameChange) {
        const name = availableBases.find((it: any) => it.id === id)?.name ?? '';
        const cleanedName = name.replace(REMOVE_INVALID_DISPLAY_CHARS, '');
        onNameChange(cleanedName);
      }
    },
    [availableBases, onChange, onNameChange],
  );

  const refetchIntegrations = useRefetchIntegrations(projectName as string);
  const onConnectAirtableAccount = useCallback(
    () =>
      openAuthorizationWindow(
        buildConnectUri('airtable'),
        new URLSearchParams({
          token: authToken,
          ...(projectName ? { project: projectName } : {}),
        }),
        { onClose: refetchIntegrations, redirectUri: authorizationRedirect },
      ),
    [authToken, projectName, refetchIntegrations, authorizationRedirect],
  );

  return (
    <div className="mb-8 flex flex-col">
      {(!hasAirtableAccount || !hasAirtableOAuthIntegration) && (
        <div className="my-6 flex flex-col">
          <div className="flex items-center justify-between">
            <label
              className={classNames(
                'text-sm font-medium leading-5',
                surface === DARK ? 'text-gray-100' : 'text-gray-700',
              )}
            >
              {getText(LANG_KEY, 'copy.noAccount.question')}
            </label>
            <div className="ml-4 flex items-center space-x-2">
              <Button
                variant="secondary"
                className={classNames('flex items-center', {
                  'ring ring-pink-500 ring-opacity-75':
                    hasAirtableAccount === false,
                })}
                onClick={() => setHasAirtableAccount(false)}
              >
                {getText(LANG_KEY, 'copy.noAccount.no')}
              </Button>
              <Button
                variant="secondary"
                className={classNames('flex items-center', {
                  'ring ring-pink-500 ring-opacity-75':
                    hasAirtableAccount === true,
                })}
                onClick={() => setHasAirtableAccount(true)}
              >
                {getText(LANG_KEY, 'copy.noAccount.yes')}
              </Button>
            </div>
          </div>
          <p
            className={classNames(
              'mt-4 text-xs',
              surface === DARK ? 'text-gray-200' : 'text-gray-500',
            )}
          >
            {getText(LANG_KEY, 'copy.noAccount.note')}
          </p>
          {hasAirtableAccount === false && (
            <a
              className="mt-4 block"
              href="https://airtable.com/signup?ref=noloco"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                variant="secondary"
                className="flex items-center"
                opacity={100}
              >
                <img
                  src={airtableIcon}
                  alt="Airtable logo"
                  className="mr-3 h-6 w-auto"
                />
                {getText(LANG_KEY, 'copy.noAccount.createAirtableAccount')}
                <IconExternalLink className="ml-4" size={20} />
              </Button>
            </a>
          )}
        </div>
      )}
      {sharingLinkToClone && hasAirtableAccount && (
        <>
          <div className="mt-6">
            <label className="mb-1 flex items-center text-sm font-medium leading-5 text-gray-700">
              <IconCircleNumber1 size={22} className="mr-2" />
              <span>{getText(LANG_KEY, 'copy.label')}</span>
              <Tooltip
                placement={RIGHT}
                content={getText(LANG_KEY, 'copy.tooltip')}
              >
                <div className="ml-1">
                  <IconInfoCircle className="text-gray-500" size={16} />
                </div>
              </Tooltip>
            </label>
            <p className="mb-1 mt-2 text-sm text-gray-600">
              {getText(LANG_KEY, 'copy.help')}
            </p>
            <a
              className="mt-2 block"
              href={sharingLinkToClone}
              target="_blank"
              onClick={() => setHasClickedCopy(true)}
              rel="noreferrer noopener"
            >
              <Button
                variant="secondary"
                className="flex items-center"
                opacity={100}
              >
                <img
                  src={airtableIcon}
                  alt="Airtable logo"
                  className="mr-3 h-6 w-auto"
                />
                {getText(LANG_KEY, 'copy.cta')}
                <IconExternalLink className="ml-4" size={20} />
              </Button>
            </a>
          </div>
        </>
      )}
      {hasAirtableAccount && (!sharingLinkToClone || hasClickedCopy) && (
        <div>
          <Label
            className={classNames('flex items-center', {
              'text-white': surface !== LIGHT,
              'text-gray-600': surface === LIGHT,
            })}
            m={{ b: 3, t: 6 }}
          >
            {sharingLinkToClone ? (
              <IconCircleNumber2 size={22} className="mr-2" />
            ) : (
              <IconCircleNumber1 size={22} className="mr-2" />
            )}
            <span>{getText(LANG_KEY, 'oauth.label')}</span>
          </Label>
          <Button
            variant="secondary"
            disabled={hasAirtableOAuthIntegration}
            onClick={onConnectAirtableAccount}
            className="flex items-center hover:bg-gray-100"
            opacity={100}
          >
            <img
              src={airtableIcon}
              alt="Airtable logo"
              className="mr-3 h-6 w-auto"
            />
            {getText(
              LANG_KEY,
              'oauth.button',
              hasAirtableOAuthIntegration ? 'connected' : 'connect',
            )}
            {hasAirtableOAuthIntegration && (
              <IconCheck className="ml-4 text-teal-400" size={16} />
            )}
          </Button>
        </div>
      )}
      {hasAirtableAccount && (!sharingLinkToClone || hasClickedCopy) && (
        <div className="mt-6">
          <div className="flex items-center">
            <Label className="flex items-center" surface={surface}>
              {sharingLinkToClone ? (
                <IconCircleNumber3 size={22} className="mr-2" />
              ) : (
                <IconCircleNumber2 size={22} className="mr-2" />
              )}
              <span>{getText(LANG_KEY, 'oauth.base')}</span>
            </Label>
            <div className="order2 ml-auto">
              <Button
                type={TEXT}
                size="sm"
                surface={surface}
                className="flex items-center justify-center"
                onClick={() => {
                  refetch();
                  onChange(null);
                }}
                disabled={loading || disabled || !hasAirtableOAuthIntegration}
              >
                {loading && <Loader size="xs" className="mr-2" />}
                {getText(LANG_KEY, 'refresh')}
              </Button>
            </div>
          </div>
          <p
            className={classNames('mb-2 text-sm', {
              'text-gray-200': surface !== LIGHT,
              'text-gray-600': surface === LIGHT,
            })}
          >
            {getText(
              LANG_KEY,
              'oauth',
              sharingLinkToClone ? 'selectCopiedBase' : 'selectABase',
            )}
          </p>
          <SelectInput
            disabled={!hasAirtableOAuthIntegration || disabled}
            options={availableBases.map((base: any) => ({
              label: base.name,
              value: base.id,
            }))}
            value={value}
            onChange={handleOnChange}
            placeholder={getText(LANG_KEY, 'oauth.selectABasePlaceholder')}
            searchable={true}
            surface={surface}
          />
          <Guide
            className="mt-4 text-sm"
            href="https://guides.noloco.io/data/airtable#why-isnt-my-airtable-base-appearing-in-the-dropdown-to-connect"
          >
            {getText(LANG_KEY, 'oauth.baseMissing')}
          </Guide>
        </div>
      )}
    </div>
  );
};

export default ConnectAirtableBase;
