import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'ball-football-off',
  'IconBallFootballOff',
  [
    [
      'path',
      {
        d: 'M20.041 16.046a9 9 0 0 0 -12.084 -12.09m-2.323 1.683a9 9 0 0 0 12.726 12.73',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M12 7l4.755 3.455l-.566 1.743l-.98 3.014l-.209 .788h-6l-1.755 -5.545l1.86 -1.351l2.313 -1.681z',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M12 7v-4', key: 'svg-2' }],
    ['path', { d: 'M15 16l2.5 3', key: 'svg-3' }],
    ['path', { d: 'M16.755 10.455l3.745 -1.455', key: 'svg-4' }],
    ['path', { d: 'M9.061 16.045l-2.561 2.955', key: 'svg-5' }],
    ['path', { d: 'M7.245 10.455l-3.745 -1.455', key: 'svg-6' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
  ],
);
