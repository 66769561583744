import React, { memo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { getColorShade } from '@noloco/components';
import { TABLE } from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { getValuePathForFieldConfig } from '../../../../utils/fields';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useSelectRecordOnCmdClick from '../../../../utils/hooks/useSelectRecordOnCmdClick';
import FieldCell from '../FieldCell';

const CELL_WIDTH_OFFSET = 16;

const TableFieldCell = memo(
  ({
    // @ts-expect-error TS(2339): Property 'backLink' does not exist on type '{}'.
    backLink,
    // @ts-expect-error TS(2339): Property 'config' does not exist on type '{}'.
    config,
    // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
    dataType,
    // @ts-expect-error TS(2339): Property 'elementId' does not exist on type '{}'.
    elementId,
    // @ts-expect-error TS(2339): Property 'isFirst' does not exist on type '{}'.
    isFirst,
    // @ts-expect-error TS(2339): Property 'isLast' does not exist on type '{}'.
    isLast,
    // @ts-expect-error TS(2339): Property 'isSticky' does not exist on type '{}'.
    isSticky,
    // @ts-expect-error TS(2339): Property 'field' does not exist on type '{}'.
    field,
    // @ts-expect-error TS(2339): Property 'parent' does not exist on type '{}'.
    parent,
    // @ts-expect-error TS(2339): Property 'permissions' does not exist on type '{}'... Remove this comment to see the full error message
    permissions,
    // @ts-expect-error TS(2339): Property 'record' does not exist on type '{}'.
    record,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'rowLink' does not exist on type '{}'.
    rowLink,
    // @ts-expect-error TS(2339): Property 'transformRecordScope' does not exist on ... Remove this comment to see the full error message
    transformRecordScope,
    // @ts-expect-error TS(2339): Property 'width' does not exist on ... Remove this comment to see the full error message
    width,
    // @ts-expect-error TS(2339): Property 'bulkActionsEnabled' does not exist on ... Remove this comment to see the full error message
    bulkActionsEnabled,
    // @ts-expect-error TS(2339): Property 'isRowChecked' does not exist on ... Remove this comment to see the full error message
    isRowChecked = false,
    // @ts-expect-error TS(2339): Property 'handleCheckboxChange' does not exist on ... Remove this comment to see the full error message
    handleCheckboxChange,
    // @ts-expect-error TS(2339): Property 'selectedRows' does not exist on ... Remove this comment to see the full error message
    selectedRows,
    // @ts-expect-error TS(2339): Property 'theme' does not exist on ... Remove this comment to see the full error message
    theme,
  }) => {
    const Row = rowLink ? Link : 'div';
    const [isDarkModeEnabled] = useDarkMode();
    const selectRecordOnCmdClick = useSelectRecordOnCmdClick(
      handleCheckboxChange,
      isRowChecked,
      selectedRows,
    );

    const primaryColor = theme.brandColors.primary;

    return (
      <td
        className={classNames('max-w-sm py-2', {
          [`bg-${getColorShade(
            primaryColor,
            100,
          )} bg-opacity-75 dark:bg-${getColorShade(primaryColor, 900)}`]:
            isRowChecked,
          [`${bulkActionsEnabled ? 'left-10' : 'left-0'} sticky z-10 ${
            isDarkModeEnabled
              ? `${darkModeColors.surfaces.elevation1} border-r ${darkModeColors.borders.one}`
              : 'shadow-r bg-white'
          }`]: isSticky,
          'pl-6 pr-3': isFirst,
          'pr-6': isLast,
          'px-3': !isLast && !isFirst,
        })}
        style={
          width
            ? {
                width: `${width + CELL_WIDTH_OFFSET}px`,
                maxWidth: `${width + CELL_WIDTH_OFFSET}px`,
              }
            : undefined
        }
      >
        {/* @ts-expect-error TS(2322): Type '{ children: Element; className: string; to: ... Remove this comment to see the full error message */}
        <Row className="block" to={rowLink} onClick={selectRecordOnCmdClick}>
          <FieldCell
            backLink={backLink}
            className="block"
            showLabel={false}
            config={config}
            dataType={dataType}
            field={field}
            transformScope={transformRecordScope}
            layout={TABLE}
            permissions={permissions}
            record={record}
            value={get(record, getValuePathForFieldConfig(field, parent))}
            project={project}
            key={field.name}
            skipResolvingForValueIds={[`${elementId}:RECORD`]}
            bulkActionsEnabled={bulkActionsEnabled}
            isRowChecked={isRowChecked}
            selectedRows={selectedRows}
          />
        </Row>
      </td>
    );
  },
);

export default TableFieldCell;
