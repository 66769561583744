export const WORKFLOW_ACTION = 'WORKFLOW_ACTION';
export const API = 'API';
export const DATABASE = 'DATABASE';
export const TYPE = 'TYPE';
export const FIELD = 'FIELD';
export const RELATION = 'RELATION';
export const AGGREGATION = 'AGGREGATION';
export const SCOPE = 'SCOPE';
export const PAGE_FIELD = 'PAGE_FIELD';
export const DERIVED = 'DERIVED';

const scopeTypes = [
  API,
  DATABASE,
  TYPE,
  FIELD,
  DERIVED,
  PAGE_FIELD,
  RELATION,
  AGGREGATION,
  SCOPE,
  WORKFLOW_ACTION,
];

export default scopeTypes;
