import React from 'react';
import last from 'lodash/last';
import { PAGE, PAGE_SWITCH } from '@noloco/core/src/constants/elements';
import DataTypes from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { ensureArray } from '@noloco/core/src/utils/arrays';
import {
  Page,
  findPageInPath,
  pagePathIdIsRecordPage,
} from '@noloco/core/src/utils/pages';
import { getTabsForPage } from '@noloco/core/src/utils/tabs';
import PagePath from '../components/editor/PagePath';

type PageLabelProps = {
  pagePath: ElementPath;
  allPages: Page[];
  dataTypes: DataTypes;
};

const PageLabel = ({ pagePath, allPages, dataTypes }: PageLabelProps) => (
  <div className="mt-1 flex items-center text-sm text-gray-300">
    {pagePath
      .map((pagePathItemId: any) => {
        if (pagePathItemId === 'new') {
          return '/new';
        }

        const pagePathItem = findPageInPath(pagePathItemId, pagePath, allPages);

        if (!pagePathItem) {
          return null;
        }

        const { dataType, dataProperty, routePath, isSubPage, parentPage } =
          pagePathItem.props;

        if (isSubPage && pagePathItem.parent) {
          return `${pagePathItem.parent.props.routePath}/${routePath}`;
        } else if (parentPage && !dataProperty) {
          const parentPageItem = findPageInPath(parentPage, pagePath, allPages);
          if (parentPageItem) {
            return `/${parentPageItem.props.routePath}/${routePath}`;
          }
        }

        return (
          <PagePath
            key={pagePathItemId}
            dataTypes={dataTypes}
            dataType={dataType}
            dataProperty={dataProperty}
            routePath={routePath}
          />
        );
      })
      .filter(Boolean)}
  </div>
);

export const getPageOptions = (
  childPages: any,
  allPages: any,
  projectDataTypes: any,
  filter = () => true,
) =>
  (childPages || []).filter(filter).reduce(
    (pageList: any, pageItem: any) => [
      ...pageList,
      {
        value: pageItem.pagePath,
        label: pageItem.props.name || pageItem.id,
        page: pageItem,
        pagePath: (
          <PageLabel
            pagePath={pageItem.pagePath}
            allPages={allPages}
            dataTypes={projectDataTypes}
          />
        ),
      },
      ...getPageOptions(pageItem.children, allPages, projectDataTypes, filter),
    ],
    [],
  );

export const getDestinationPage = (pageIds: string[], pages: Page[]) =>
  last(
    pageIds
      .filter((pagePathItemId) => !pagePathIdIsRecordPage(pagePathItemId))
      .map((pagePathItemId) => findPageInPath(pagePathItemId, pageIds, pages))
      .filter(Boolean),
  );

export const getTabOptions = (pageItem: Page, pageIds: string[]) => {
  const tabs = getTabsForPage(pageItem, pageIds);
  return ensureArray(tabs)
    ? tabs.map((tab: any) => ({
        value: tab.id,
        label: tab.title,
      }))
    : [];
};

export const getAllPageOptions = (pages: any, projectDataTypes: any) =>
  getPageOptions(pages, pages, projectDataTypes);

export const getPageGroups = (elements: any, parentPage: any) =>
  elements
    ? elements.reduce(
        (pageGroups: any, element: any) => [
          ...pageGroups,
          ...(element.type === PAGE_SWITCH
            ? [
                {
                  ...element,
                  parentPage,
                  childGroups: getPageGroups(element.children, parentPage),
                },
              ]
            : getPageGroups(
                element.children,
                element.type === PAGE
                  ? {
                      ...element,
                      pagePath: [
                        ...(parentPage ? parentPage.pagePath : []),
                        element.id,
                      ],
                    }
                  : parentPage,
              )),
        ],
        [],
      )
    : [];
