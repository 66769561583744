/** @typedef { import('@noloco/components').Color } Color */
/** @typedef { import('@noloco/components').BaseColor } BaseColor */
/** @typedef { import('@noloco/components').Display } Display */
/** @typedef { import('@noloco/components').Position } Position */
/** @typedef { import('@noloco/components').Theme } Theme */

const baseNums = [
  0,
  'px',
  0.5,
  1,
  1.5,
  2,
  2.5,
  3,
  4,
  5,
  6,
  8,
  10,
  12,
  14,
  16,
  20,
  24,
  32,
  36,
  40,
  44,
  48,
  52,
  56,
  64,
  72,
  80,
  96,
];

const negativeBaseNums = baseNums
  .filter((x) => x !== 0)
  // @ts-expect-error it doesn't like that 'px' in the array made it string or number
  .map((x) => (isNaN(x) ? `-${x}` : x * -1));

const sizes = [
  'auto',
  ...baseNums,
  'full',
  'screen',
  '1/2',
  '1/3',
  '2/3',
  '1/4',
  '2/4',
  '3/4',
  '1/5',
  '2/5',
  '3/5',
  '4/5',
  '1/6',
  '2/6',
  '3/6',
  '4/6',
  '5/6',
  '1/12',
  '2/12',
  '3/12',
  '4/12',
  '5/12',
  '6/12',
  '7/12',
  '8/12',
  '9/12',
  '10/12',
  '11/12',
];

const overflow = ['visible', 'hidden', 'scroll', 'auto'];

// Not sure why I can't spread multiple times
const margins = ['auto', ...baseNums].concat(negativeBaseNums);
const paddings = [...baseNums].concat(negativeBaseNums);

const minWSizes = [0, 'full'];
const minHSizes = [0, 'full', 'screen'];

const maxWSizes = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  '6xl',
  '7xl',
  'full',
  'screen-sm',
  'screen-md',
  'screen-lg',
  'screen-xl',
  'none',
];

const maxHSizes = [
  ...baseNums,
  'full',
  'screen-25',
  'screen-50',
  'screen-75',
  'screen',
];

const baseColors = [
  'gray',
  'slate',
  'red',
  'orange',
  'yellow',
  'indigo',
  'green',
  'blue',
  'cyan',
  'teal',
  'purple',
  'pink',
];

const colorShades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

const blackAndWhiteColors = ['black', 'white'];

const colors = baseColors.reduce(
  (acc, color) =>
    acc.concat(
      colorShades.reduce(
        (/** @type {string[]} */ colorAcc, shade) =>
          colorAcc.concat([`${color}-${shade}`]),
        /** @type {string[]} */ [],
      ),
    ),
  blackAndWhiteColors,
);

const bgColors = ['transparent', 'current'].concat(colors);

const CUSTOM_PRIMARY_COLOR = 'nlc_primary';

const bgSizes = ['auto', 'cover', 'contain'];

const bgRepeats = [true, 'repeat-x', 'repeat-y', 'no-repeat'];

const bgPositions = [
  'left-top',
  'top',
  'right-top',
  'left',
  'center',
  'right',
  'left-bottom',
  'bottom',
  'right-bottom',
];

const displays = ['flex', 'block', 'inlineBlock', 'hidden'];

const opacities = [100, 75, 50, 25, 0];

const borderRadii = ['none', 'sm', true, 'md', 'lg', 'full'];

const borderColors = ['transparent', 'current'].concat(colors);
const borderStyles = ['none', 'solid', 'dashed', 'dotted'];
const borderWidths = [0, true, 2, 4, 8];

const fontSizes = [
  'xs',
  'sm',
  'base',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  '6xl',
];

const fontWeights = [
  'hairline',
  'thin',
  'light',
  'normal',
  'medium',
  'semibold',
  'bold',
  'extrabold',
  'black',
];

const fontAligns = ['left', 'center', 'right', 'justify'];

const textColors = ['transparent', 'current'].concat(colors);

const shadows = ['none', 'sm', true, 'md', 'lg', 'xl', '2xl', 'inner'];

const cursors = [
  'auto',
  'default',
  'pointer',
  'notAllowed',
  'wait',
  'text',
  'move',
  'help',
];

const positions = ['static', 'relative', 'absolute', 'fixed', 'sticky'];

const zIndexes = ['auto', 0, 10, 20, 30, 40, 50];

const offsetSizes = [
  ...baseNums,
  'auto',
  '1/2',
  '1/3',
  '2/3',
  '1/4',
  '2/4',
  '3/4',
  'full',
];

const DEFAULT_THEME = {
  spacing: {
    zero: 0,
    sm: 1.5,
    md: 3,
    lg: 6,
    xl: 8,
  },
  brandColors: {
    primary: 'blue-500',
    danger: 'red-500',
    secondary: 'gray-700',
    success: 'green-500',
    warning: 'yellow-300',
  },
  surfaceColors: {
    dark: 'gray-700',
    light: 'white',
  },
  brandColorGroups: {
    primary: 'gray',
    danger: 'red',
    secondary: 'pink',
    success: 'green',
    warning: 'yellow',
  },
  dropzone: {
    surface: 'light',
    textColor: 'brand-lightest',
  },
  borderColors: {
    light: 'gray-300',
    default: 'gray-300',
  },
  datePicker: {
    surface: 'light',
    textColor: 'brand-lightest',
  },
  modal: {
    surface: 'light',
    textColor: 'brand-lightest',
    secondaryTextColor: 'text-gray-500',
    backgroundColor: 'bg-white',
    secondaryBackgroundColor: 'bg-gray-100',
  },
  secondaryButton: {
    surface: 'light',
    textColor: 'gray-600',
    borderColor: 'default',
  },
};

/**
 * @typedef {Object} tailwindStyles
 * @property {Color[]} colors
 * @property {Display[]} displays
 * @property {Position[]} positions
 * @property {Theme} DEFAULT_THEME
 */
const tailwindStyles = {
  baseNums,
  bgColors,
  bgSizes,
  bgRepeats,
  bgPositions,
  blackAndWhiteColors,
  borderColors,
  cursors,
  CUSTOM_PRIMARY_COLOR,
  positions,
  offsetSizes,
  sizes,
  overflow,
  margins,
  paddings,
  minWSizes,
  minHSizes,
  maxWSizes,
  maxHSizes,
  baseColors,
  colorShades,
  colors,
  displays,
  fontAligns,
  fontSizes,
  fontWeights,
  textColors,
  opacities,
  borderRadii,
  borderStyles,
  borderWidths,
  shadows,
  zIndexes,
  DEFAULT_THEME,
};

module.exports = tailwindStyles;
