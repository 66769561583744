import createReactComponent from '../createReactComponent';
export default createReactComponent('abc', 'IconAbc', [
  ['path', { d: 'M3 16v-6a2 2 0 1 1 4 0v6', key: 'svg-0' }],
  ['path', { d: 'M3 13h4', key: 'svg-1' }],
  ['path', { d: 'M10 8v6a2 2 0 1 0 4 0v-1a2 2 0 1 0 -4 0v1', key: 'svg-2' }],
  [
    'path',
    { d: 'M20.732 12a2 2 0 0 0 -3.732 1v1a2 2 0 0 0 3.726 1.01', key: 'svg-3' },
  ],
]);
