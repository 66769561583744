import createReactComponent from '../createReactComponent';
export default createReactComponent('mood-pin', 'IconMoodPin', [
  ['path', { d: 'M21 12a9 9 0 1 0 -8.352 8.977', key: 'svg-0' }],
  ['path', { d: 'M9 10h.01', key: 'svg-1' }],
  ['path', { d: 'M15 10h.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M9.5 15c.658 .672 1.56 1 2.5 1c.102 0 .203 -.004 .304 -.012',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-5' }],
]);
