import createReactComponent from '../createReactComponent';
export default createReactComponent('copy-off', 'IconCopyOff', [
  [
    'path',
    {
      d: 'M19.414 19.415a2 2 0 0 1 -1.414 .585h-8a2 2 0 0 1 -2 -2v-8c0 -.554 .225 -1.055 .589 -1.417m3.411 -.583h6a2 2 0 0 1 2 2v6',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16 8v-2a2 2 0 0 0 -2 -2h-6m-3.418 .59c-.36 .36 -.582 .86 -.582 1.41v8a2 2 0 0 0 2 2h2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
