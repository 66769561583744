import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-deviantart', 'IconBrandDeviantart', [
  [
    'path',
    {
      d: 'M18 3v4l-3.857 6h3.857v4h-6.429l-2.571 4h-3v-4l3.857 -6h-3.857v-4h6.429l2.571 -4z',
      key: 'svg-0',
    },
  ],
]);
