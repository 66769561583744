import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { EXPERT } from '@noloco/core/src/constants/accountPlans';
import { Feature, FeatureValue } from '@noloco/core/src/constants/features';
import { billingPlanTypeSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { getText } from '@noloco/core/src/utils/lang';
import { useFeatureLimit } from './useFeatureLimit';
import useValidFeaturePlan from './useValidFeaturePlan';

export const useFeatureLockedTooltip = (
  feature: Feature,
  currentNumber?: FeatureValue,
) => {
  const featureName = getText('features', feature);
  const billingPlanType = useSelector(billingPlanTypeSelector);

  const { limit } = useFeatureLimit(feature);

  const lowestPlan = useValidFeaturePlan(feature);
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const lowestPlanName = getText('billing.plans', lowestPlan, 'name');

  if (billingPlanType === EXPERT) {
    return getText('features.tooltip.expert');
  }

  if (isNil(currentNumber) || typeof currentNumber !== 'number') {
    return getText(
      {
        feature: featureName.toLowerCase(),
        lowestPlan: lowestPlanName,
      },
      'features.tooltip.boolean',
    );
  } else {
    return getText(
      {
        feature: featureName.toLowerCase(),
        lowestPlan: lowestPlanName,
        usage: getText(
          {
            current: currentNumber as number,
            limit,
          },
          'features.usage',
          feature,
          'usage',
        ),
      },
      'features.tooltip.usage',
    );
  }
};
