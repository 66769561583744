import createReactComponent from '../createReactComponent';
export default createReactComponent('chart-dots-3', 'IconChartDots3', [
  ['path', { d: 'M5 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M16 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M6 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-3' }],
  ['path', { d: 'M9 17l5 -1.5', key: 'svg-4' }],
  ['path', { d: 'M6.5 8.5l7.81 5.37', key: 'svg-5' }],
  ['path', { d: 'M7 7l8 -1', key: 'svg-6' }],
]);
