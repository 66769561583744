import createReactComponent from '../createReactComponent';
export default createReactComponent('sun-wind', 'IconSunWind', [
  ['path', { d: 'M14.468 10a4 4 0 1 0 -5.466 5.46', key: 'svg-0' }],
  ['path', { d: 'M2 12h1', key: 'svg-1' }],
  ['path', { d: 'M11 3v1', key: 'svg-2' }],
  ['path', { d: 'M11 20v1', key: 'svg-3' }],
  ['path', { d: 'M4.6 5.6l.7 .7', key: 'svg-4' }],
  ['path', { d: 'M17.4 5.6l-.7 .7', key: 'svg-5' }],
  ['path', { d: 'M5.3 17.7l-.7 .7', key: 'svg-6' }],
  ['path', { d: 'M15 13h5a2 2 0 1 0 0 -4', key: 'svg-7' }],
  [
    'path',
    {
      d: 'M12 16h5.714l.253 0a2 2 0 0 1 2.033 2a2 2 0 0 1 -2 2h-.286',
      key: 'svg-8',
    },
  ],
]);
