import createReactComponent from '../createReactComponent';
export default createReactComponent('file-vector', 'IconFileVector', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9.5 16.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M14.5 12.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M9.5 15a2.5 2.5 0 0 1 2.5 -2.5h1', key: 'svg-4' }],
]);
