import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'mood-tongue-wink-2',
  'IconMoodTongueWink2',
  [
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z', key: 'svg-1' }],
    ['path', { d: 'M15 10h-.01', key: 'svg-2' }],
    ['path', { d: 'M10 14v2a2 2 0 1 0 4 0v-2m1.5 0h-7', key: 'svg-3' }],
    ['path', { d: 'M7 10c.5 -1 2.5 -1 3 0', key: 'svg-4' }],
  ],
);
