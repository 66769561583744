import React, { forwardRef } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';

type Props = {
  className?: string;
};

const NolocoIcon = forwardRef(({ className, ...rest }: Props, ref) => (
  <Box className={classNames(className)} ref={ref} {...rest}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 609 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_746_158)">
        <path
          d="M478.877 55.0737C482.96 51.0994 482.977 44.5153 478.549 40.9282C476.385 39.1745 474.209 37.5431 472.228 36.222C451.228 22.222 419.228 24.022 402.128 50.122C388.828 70.422 392.028 96.622 410.428 113.922C426.628 129.122 454.028 131.322 473.028 117.522C481.728 111.222 498.428 93.4222 506.228 86.0222C508.072 84.2865 510.087 88.0102 510.763 90.4509C516.59 111.5 536.094 126.922 558.828 126.922C586.028 126.922 608.628 104.722 608.728 77.922C608.828 50.222 586.728 27.822 559.028 27.722C547.837 27.623 537.332 31.349 528.874 37.6373C528.71 37.7597 528.532 37.8677 528.387 38.0127C528.229 38.1701 528.039 38.2906 527.864 38.4285C526.152 39.7783 524.45 41.3001 522.928 42.822C517.128 48.522 509.428 56.522 502.728 62.822C490.028 74.722 479.828 85.822 465.928 97.222C453.428 109.022 434.528 107.922 423.528 95.822C409.728 80.522 416.028 56.622 435.728 50.722C447.628 47.122 458.728 50.522 468.228 59.222C469.947 60.9418 472.778 61.0106 474.521 59.3141L478.877 55.0737ZM558.328 105.422C542.028 105.022 529.628 92.322 529.928 76.122C530.228 61.122 543.728 48.622 559.428 48.922C575.528 49.222 588.228 62.222 587.828 78.222C587.528 93.222 574.028 105.722 558.328 105.422Z"
          fill="currentColor"
        />
        <path
          d="M329.9 127.001C302.2 127.001 280.1 104.901 280 77.0013C279.9 50.0013 302.7 27.6013 330.1 27.8013C357.8 28.0013 379.9 50.3013 379.8 78.0013C379.8 104.801 357.2 127.001 329.9 127.001ZM329.4 105.101C344.5 105.501 357.6 93.2013 357.9 78.4013C358.2 62.7013 346 50.0013 330.5 49.7013C315.4 49.4013 302.3 61.7013 302.1 76.4013C301.8 92.2013 313.7 104.801 329.4 105.101Z"
          fill="currentColor"
        />
        <path
          d="M220.1 6C219.928 3.2429 222.24 1 225.002 1C228.683 1 232.261 1 235.9 1C239.269 1 242 3.73106 242 7.1C242 36.2 242 65.4 242 94.5C242 95.2 242 95.8 242 96.5C242.2 104.7 246.5 107.4 254.2 104.2C257.962 102.588 262.281 103.778 264.385 107.288C264.954 108.239 265.526 109.19 266.1 110.146C269.262 115.408 267.852 122.455 262.115 124.643C254.361 127.599 246.253 127.79 237.3 124.9C226.2 120.9 220.6 112 220.5 100.8C219.9 69.2 220.2 37.6 220.1 6Z"
          fill="currentColor"
        />
        <path
          d="M156.9 127.001C129.2 127.001 107.1 104.901 107 77.0011C106.9 50.0011 129.7 27.6011 157.1 27.8011C184.8 28.0011 206.9 50.3011 206.8 78.0011C206.8 104.801 184.1 127.001 156.9 127.001ZM156.4 105.101C171.5 105.501 184.6 93.2011 184.9 78.4011C185.2 62.7011 173 50.0011 157.5 49.7011C142.4 49.4011 129.3 61.7011 129.1 76.4011C128.7 92.2011 140.7 104.801 156.4 105.101Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 30.9612C22.5 32.4934 24.1891 33.4493 25.5589 32.7627C31.4918 29.7888 38.6044 28 47 28C65.0468 28 77.3013 36.2598 84.6559 46.7463C91.6236 56.6813 94 68.239 94 76V122C94 124.761 91.7614 127 89 127H77C74.2386 127 72 124.761 72 122V76C72 71.9277 70.5763 64.9854 66.6441 59.3787C63.0987 54.3235 57.3532 50 47 50C36.6815 50 31.1453 54.2864 27.7478 59.2639C23.9712 64.7968 22.5749 71.7449 22.5 76.0853V122C22.5 124.761 20.2614 127 17.5 127H5.5C2.73858 127 0.5 124.761 0.5 122V34C0.5 31.2386 2.73858 29 5.5 29H20.5388C21.622 29 22.5 29.8781 22.5 30.9612Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_746_158">
          <rect
            width="609"
            height="126.001"
            fill="white"
            transform="translate(0 1)"
          />
        </clipPath>
      </defs>
    </svg>
  </Box>
));

export default NolocoIcon;
