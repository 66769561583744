import { IconInfoCircle } from '@tabler/icons-react';
import classNames from 'classnames';
import { Tooltip } from '@noloco/components/src';
import { DARK } from '@noloco/components/src/constants/surface';
import { darkModeColors } from '../../constants/darkModeColors';

type BuildModeLabelProps = {
  children: string | JSX.Element;
  className?: string;
  helpText?: string;
};

const BuildModeLabel = ({
  children,
  className,
  helpText,
}: BuildModeLabelProps) => (
  <div className="flex items-center space-x-1">
    <label className={classNames('text-xs text-gray-400', className)}>
      {children}
    </label>
    {helpText && (
      <Tooltip
        content={
          <div className={classNames('text-sm', darkModeColors.text.primary)}>
            {helpText}
          </div>
        }
        placement="left"
        offset={[0, 8]}
        surface={DARK}
      >
        <div className="flex cursor-pointer items-center justify-center rounded-md p-1 opacity-75 hover:opacity-100">
          <IconInfoCircle size={16} />
        </div>
      </Tooltip>
    )}
  </div>
);

export default BuildModeLabel;
