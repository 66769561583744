import get from 'lodash/get';
import { ENABLED, REQUIRED } from '../constants/twoFactorAuth';
import { ProjectSettings } from '../models/Project';
import { User } from '../models/User';

export const SAMPLE_USER_EMAIL = 'sample@noloco.io';

export const isNolocoAdmin = (user: User): boolean =>
  get(user, ['email']) === SAMPLE_USER_EMAIL;

export const isSsoEnabled = (settings: ProjectSettings) =>
  settings?.signInOptions?.saml === true;

export const isSsoEmailMatchingEnabled = (settings: ProjectSettings) =>
  settings?.signInOptions?.ssoEmailMatching === true;

export const isPasswordSignInEnabled = (settings: ProjectSettings) =>
  settings?.signInOptions?.password ?? true;

export const isMagicLinkSignInEnabled = (settings: ProjectSettings) =>
  settings?.signInOptions?.magicLink ?? true;

export const isTwoFactorAuthEnabled = (settings: ProjectSettings) =>
  settings?.auth?.twoFactorAuth === REQUIRED ||
  settings?.auth?.twoFactorAuth === ENABLED;
