import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'inner-shadow-right',
  'IconInnerShadowRight',
  [
    [
      'path',
      {
        d: 'M18.364 18.364a9 9 0 1 1 -12.728 -12.728a9 9 0 0 1 12.728 12.728z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M16.243 7.757a6 6 0 0 1 0 8.486', key: 'svg-1' }],
  ],
);
