import createReactComponent from '../createReactComponent';
export default createReactComponent('recycle-off', 'IconRecycleOff', [
  [
    'path',
    {
      d: 'M12 17l-2 2l2 2m-2 -2h9m1.896 -2.071a2 2 0 0 0 -.146 -.679l-.55 -1',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8.536 11l-.732 -2.732l-2.732 .732m2.732 -.732l-4.5 7.794a2 2 0 0 0 1.506 2.89l1.141 .024',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M15.464 11l2.732 .732l.732 -2.732m-.732 2.732l-4.5 -7.794a2 2 0 0 0 -3.256 -.14l-.591 .976',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
