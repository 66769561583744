import createReactComponent from '../createReactComponent';
export default createReactComponent('steam', 'IconSteam', [
  ['path', { d: 'M12 4m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M4 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M20 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-2' }],
  ['path', { d: 'M12 20m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-3' }],
  ['path', { d: 'M5.5 5.5l3 3', key: 'svg-4' }],
  ['path', { d: 'M15.5 15.5l3 3', key: 'svg-5' }],
  ['path', { d: 'M18.5 5.5l-3 3', key: 'svg-6' }],
  ['path', { d: 'M8.5 15.5l-3 3', key: 'svg-7' }],
]);
