import createReactComponent from '../createReactComponent';
export default createReactComponent('database-cog', 'IconDatabaseCog', [
  [
    'path',
    {
      d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M4 6v6c0 1.657 3.582 3 8 3c.21 0 .42 -.003 .626 -.01', key: 'svg-1' },
  ],
  ['path', { d: 'M20 11.5v-5.5', key: 'svg-2' }],
  ['path', { d: 'M4 12v6c0 1.657 3.582 3 8 3', key: 'svg-3' }],
  ['path', { d: 'M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
  ['path', { d: 'M19.001 15.5v1.5', key: 'svg-5' }],
  ['path', { d: 'M19.001 21v1.5', key: 'svg-6' }],
  ['path', { d: 'M22.032 17.25l-1.299 .75', key: 'svg-7' }],
  ['path', { d: 'M17.27 20l-1.3 .75', key: 'svg-8' }],
  ['path', { d: 'M15.97 17.25l1.3 .75', key: 'svg-9' }],
  ['path', { d: 'M20.733 20l1.3 .75', key: 'svg-10' }],
]);
