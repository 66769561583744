import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-cypress', 'IconBrandCypress', [
  [
    'path',
    {
      d: 'M19.48 17.007a9 9 0 1 0 -7.48 3.993c.896 0 1.691 -.573 1.974 -1.423l3.526 -10.577',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13.5 9l2 6', key: 'svg-1' }],
  ['path', { d: 'M10.764 9.411a3 3 0 1 0 -.023 5.19', key: 'svg-2' }],
]);
