import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Surface } from '@noloco/components';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  BOOLEAN,
  DATE,
  DataFieldType,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import { MULTILINE_TEXT } from '@noloco/core/src/constants/fieldFormats';
import { MANY_TO_ONE } from '@noloco/core/src/constants/relationships';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import {
  ElementPath,
  StringPropSegment,
} from '@noloco/core/src/models/Element';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { expandDataTypes } from '@noloco/core/src/utils/data';
import { useOptionTextValue } from '@noloco/core/src/utils/hooks/useOptionTextValue';
import { isOptionType } from '@noloco/core/src/utils/options';
import { getDataTypeOptionsOfTypes } from '@noloco/core/src/utils/renderedOptions';
import { DataItemValueOption } from '@noloco/core/src/utils/state';
import DraftEditor from '../DraftEditor';
import DynamicDropdownInput from './DynamicDropdownInput';
import StaticBooleanFieldInput from './StaticBooleanFieldInput';
import StaticDateInput from './StaticDateInput';
import StaticOptionFieldInput from './StaticOptionFieldInput';
import StaticRelatedFieldInput from './StaticRelatedFieldInput';

type DynamicValueInputProps = {
  acceptableDataTypes?: DataFieldType[];
  additionalScopeItems?: DataItemValueOption[];
  checkFieldPermissions?: boolean;
  className?: string;
  dataOptions?: DataItemValueOption[];
  dataType?: DataType;
  disabled?: boolean;
  elementPath: ElementPath;
  field?: DataField;
  fieldFilter?: (field: DataField, dataType?: DataType) => boolean;
  includeRelationships?: boolean;
  includeSelf?: boolean;
  includeUniqueColumnar?: boolean;
  multiLine?: boolean;
  multiple?: boolean;
  onChange: (value: StringPropSegment[] | null) => void;
  placeholder?: string;
  surface?: Surface;
  value: StringPropSegment[] | undefined;
};

const DynamicValueInput = ({
  additionalScopeItems = [],
  acceptableDataTypes,
  className,
  checkFieldPermissions = true,
  dataOptions,
  dataType,
  disabled,
  elementPath,
  field,
  fieldFilter,
  includeSelf,
  includeUniqueColumnar,
  includeRelationships,
  multiLine,
  multiple,
  value,
  onChange,
  placeholder,
  surface = 'dark',
}: DynamicValueInputProps) => {
  const project = useSelector(projectDataSelector);
  const inputValue = useMemo(
    () =>
      value && typeof value === 'object' ? value : [{ text: value || '' }],
    [value],
  );

  const [isFocused, setIsFocused] = useState(false);
  const onBlur = useCallback(() => setIsFocused(false), []);

  const updatedAcceptableDataTypes = useMemo(() => {
    if (!field) {
      return acceptableDataTypes;
    }

    if (field.relationship || field.relatedField) {
      return acceptableDataTypes ? acceptableDataTypes : [field.type];
    }

    return expandDataTypes(field.type);
  }, [acceptableDataTypes, field]);

  const options = useMemo(
    () =>
      dataOptions ?? [
        ...additionalScopeItems,
        ...getDataTypeOptionsOfTypes(
          project,
          elementPath,
          updatedAcceptableDataTypes,
          {
            includeSelf,
            includeUniqueColumnar,
            fieldFilter,
          },
        ),
      ],
    [
      dataOptions,
      additionalScopeItems,
      project,
      elementPath,
      updatedAcceptableDataTypes,
      includeSelf,
      includeUniqueColumnar,
      fieldFilter,
    ],
  );

  const { isOption, optionTextValue } = useOptionTextValue(field, value);
  const isRelationship =
    field && (field.relationship || field.relatedField || field.name === 'id');

  const isSelect = useMemo(
    () =>
      (field && (field.type === BOOLEAN || field.type === DATE)) ||
      isOption ||
      (includeRelationships && isRelationship),
    [field, includeRelationships, isOption, isRelationship],
  );

  const isValueDynamic: boolean = useMemo(() => {
    if (inputValue.length === 0) {
      return true;
    }

    if (inputValue[0].static) {
      return false;
    }

    if (
      (inputValue[0].data?.id === 'values' ||
        inputValue[0].data?.id === SINGLE_OPTION) &&
      !inputValue[0].data?.path?.startsWith(DATE) &&
      inputValue[0].data?.path !== 'OTHER.empty'
    ) {
      return false;
    }

    return true;
  }, [inputValue]);

  const [showDynamicInputs, setShowDynamicInputs] = useState(isValueDynamic);

  const onChangeShowDynamicInputs = useCallback((nextDynamic: boolean) => {
    setShowDynamicInputs(nextDynamic);
    setIsFocused(true);
  }, []);

  if (!showDynamicInputs && field) {
    if (field.type === BOOLEAN) {
      return (
        <StaticBooleanFieldInput
          isOpen={isFocused}
          surface={surface}
          onSetDynamic={onChangeShowDynamicInputs}
          onChange={onChange}
          onClose={onBlur}
          placeholder={placeholder}
          value={inputValue}
        />
      );
    }

    if (field.type === DATE) {
      return (
        <StaticDateInput
          isOpen={isFocused}
          field={field}
          surface={surface}
          onSetDynamic={onChangeShowDynamicInputs}
          onChange={onChange}
          onClose={onBlur}
          placeholder={placeholder}
          value={inputValue}
        />
      );
    }

    if (field.name === 'id' && dataType) {
      const mockField = {
        ...field,
        type: dataType.name,
        relationship: MANY_TO_ONE,
      } as DataField;

      return (
        <StaticRelatedFieldInput
          field={mockField}
          multiple={multiple ?? false}
          project={project}
          placeholder={placeholder}
          isOpen={isFocused}
          onClose={onBlur}
          value={inputValue}
          surface={surface}
          onChange={onChange}
          onSetDynamic={onChangeShowDynamicInputs}
        />
      );
    }

    if ((field.relationship || field.relatedField) && field.type !== FILE) {
      return (
        <StaticRelatedFieldInput
          field={field}
          multiple={multiple ?? false}
          project={project}
          placeholder={placeholder}
          isOpen={isFocused}
          onClose={onBlur}
          value={inputValue}
          surface={surface}
          onChange={onChange}
          onSetDynamic={onChangeShowDynamicInputs}
        />
      );
    }

    if (isOptionType(field.type) && dataType) {
      return (
        <StaticOptionFieldInput
          dataType={dataType}
          field={field}
          multiple={multiple ?? false}
          surface={surface}
          isOpen={isFocused}
          onClose={onBlur}
          onSetDynamic={onChangeShowDynamicInputs}
          onChange={onChange}
          placeholder={placeholder}
          value={inputValue}
        />
      );
    }
  }

  if (isSelect) {
    return (
      <DynamicDropdownInput
        checkFieldPermissions={checkFieldPermissions}
        dataOptions={options}
        disabled={disabled}
        setDynamic={onChangeShowDynamicInputs}
        placeholder={placeholder ?? ''}
        onChange={onChange}
        isOpen={isFocused}
        onClose={onBlur}
        optionTextValue={optionTextValue}
        multiple={!!multiple}
        value={value}
      />
    );
  }

  const isMultiLine =
    multiLine ||
    (!!field &&
      field.type === TEXT &&
      (!field.typeOptions?.format ||
        field.typeOptions?.format === MULTILINE_TEXT));

  return (
    <div
      className={classNames(
        'flex h-auto min-h-8 w-full items-center rounded-lg bg-slate-700 px-1 py-1 pl-2 text-slate-200',
        className,
      )}
    >
      <DraftEditor
        //Changing between multiple lines and non-multiple lines causes issues
        key={`draft-editor-multiline-${isMultiLine}`}
        items={inputValue}
        dataOptions={options}
        multiLine={isMultiLine}
        onUpdate={onChange}
        placeholder={placeholder ?? ''}
        checkFieldPermissions={checkFieldPermissions}
        elementPath={[]}
      />
    </div>
  );
};

export default DynamicValueInput;
