import { ApolloError } from '@apollo/client';
import get from 'lodash/get';

export const extractErrorMessages = (
  error: ApolloError | ApolloError[],
): String[] => {
  if (Array.isArray(error)) {
    return error.reduce(
      (acc, err) => [...acc, ...extractErrorMessages(err)],
      [] as String[],
    );
  }

  if (error.graphQLErrors) {
    return error.graphQLErrors.map((er) => er.message);
  }

  const networkError = get(error, 'networkError.result.message');
  if (networkError) {
    return [networkError];
  }

  if (error.message) {
    return [error.message];
  }

  return [] as String[];
};
