import createReactComponent from '../createReactComponent';
export default createReactComponent('sum-off', 'IconSumOff', [
  [
    'path',
    {
      d: 'M18 18a1 1 0 0 1 -1 1h-11l6 -7m-3 -7h8a1 1 0 0 1 1 1v2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
]);
