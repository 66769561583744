import createReactComponent from '../createReactComponent';
export default createReactComponent('virus', 'IconVirus', [
  ['path', { d: 'M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0', key: 'svg-0' }],
  ['path', { d: 'M12 7v-4', key: 'svg-1' }],
  ['path', { d: 'M11 3h2', key: 'svg-2' }],
  ['path', { d: 'M15.536 8.464l2.828 -2.828', key: 'svg-3' }],
  ['path', { d: 'M17.657 4.929l1.414 1.414', key: 'svg-4' }],
  ['path', { d: 'M17 12h4', key: 'svg-5' }],
  ['path', { d: 'M21 11v2', key: 'svg-6' }],
  ['path', { d: 'M15.535 15.536l2.829 2.828', key: 'svg-7' }],
  ['path', { d: 'M19.071 17.657l-1.414 1.414', key: 'svg-8' }],
  ['path', { d: 'M12 17v4', key: 'svg-9' }],
  ['path', { d: 'M13 21h-2', key: 'svg-10' }],
  ['path', { d: 'M8.465 15.536l-2.829 2.828', key: 'svg-11' }],
  ['path', { d: 'M6.343 19.071l-1.413 -1.414', key: 'svg-12' }],
  ['path', { d: 'M7 12h-4', key: 'svg-13' }],
  ['path', { d: 'M3 13v-2', key: 'svg-14' }],
  ['path', { d: 'M8.464 8.464l-2.828 -2.828', key: 'svg-15' }],
  ['path', { d: 'M4.929 6.343l1.414 -1.413', key: 'svg-16' }],
]);
