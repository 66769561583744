import createReactComponent from '../createReactComponent';
export default createReactComponent('device-mobile', 'IconDeviceMobile', [
  [
    'path',
    {
      d: 'M6 5a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-14z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 4h2', key: 'svg-1' }],
  ['path', { d: 'M12 17v.01', key: 'svg-2' }],
]);
