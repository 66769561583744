import createReactComponent from '../createReactComponent';
export default createReactComponent('diamond-off', 'IconDiamondOff', [
  [
    'path',
    {
      d: 'M9 5h9l3 5l-3.308 3.697m-1.883 2.104l-3.309 3.699a.7 .7 0 0 1 -1 0l-8.5 -9.5l2.62 -4.368',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 12l-2 -2.2l.6 -1', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
