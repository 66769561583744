import createReactComponent from '../createReactComponent';
export default createReactComponent('alphabet-latin', 'IconAlphabetLatin', [
  ['path', { d: 'M6 10h2a2 2 0 0 1 2 2v5h-3a2 2 0 1 1 0 -4h3', key: 'svg-0' }],
  ['path', { d: 'M14 7v10', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M14 10m0 2a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2z',
      key: 'svg-2',
    },
  ],
]);
