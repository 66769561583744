import React, { useMemo } from 'react';
import debounce from 'lodash/debounce';
import { SwitchButton } from '@noloco/components/src';
import StringPropEditor from '@noloco/ui/src/components/canvas/StringPropEditor';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import {
  ActionButtonExecutionType,
  ONE_CLICK,
} from '../../../constants/actionButtons';
import { ADD_COMMENT } from '../../../constants/actionTypes';
import { COMMENT_NOTES } from '../../../constants/features';
import { AddCommentActionConfig, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { getText } from '../../../utils/lang';
import BuildModeInput from '../BuildModeInput';

type AddCommentActionButtonEditorProps = {
  elementPath: ElementPath;
  execution: ActionButtonExecutionType;
  onChange: UpdatePropertyCallback;
  config: AddCommentActionConfig | undefined;
  project: Project;
};

const LANG_KEY = `actions.types.${ADD_COMMENT}`;

const AddCommentActionButtonEditor = ({
  elementPath,
  execution,
  onChange,
  config,
  project,
}: AddCommentActionButtonEditorProps) => {
  const debouncedOnChange = useMemo(() => debounce(onChange, 300), [onChange]);
  const notesAreEnabled = useIsFeatureEnabled(COMMENT_NOTES);

  const commentTypeOptions = useMemo(
    () => [
      {
        value: false,
        label: getText(LANG_KEY, 'comment'),
      },
      {
        value: true,
        label: getText(LANG_KEY, 'note'),
        disabled: !notesAreEnabled,
      },
    ],
    [notesAreEnabled],
  );

  return (
    <div className="flex flex-col space-y-2">
      <BuildModeInput
        inline={true}
        label={<span className="uppercase">{getText(LANG_KEY, 'type')}</span>}
      >
        <SwitchButton
          className="h-8 w-full rounded-lg"
          inverseColors={true}
          onChange={(val: any) => onChange(['note'], val)}
          options={commentTypeOptions}
          value={config?.note ?? false}
        />
      </BuildModeInput>
      {execution === ONE_CLICK && (
        <BuildModeInput label={getText(LANG_KEY, 'body')}>
          <StringPropEditor
            // @ts-expect-error TS(2322): Type '{ className: string; project: any; elementPa... Remove this comment to see the full error message
            className="w-full"
            project={project}
            elementPath={elementPath}
            value={config?.body}
            onChange={(value: any) => debouncedOnChange(['body'], value)}
            placeholder=""
          />
        </BuildModeInput>
      )}
    </div>
  );
};

export default AddCommentActionButtonEditor;
