import createReactComponent from '../createReactComponent';
export default createReactComponent('hexagon-letter-f', 'IconHexagonLetterF', [
  [
    'path',
    {
      d: 'M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 12h3', key: 'svg-1' }],
  ['path', { d: 'M14 8h-4v8', key: 'svg-2' }],
]);
