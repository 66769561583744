import { useCallback, useMemo } from 'react';
import { NAVIGATE, VIEW } from '../../constants/actionTypes';
import { PAGE } from '../../constants/linkTypes';
import { DataType } from '../../models/DataTypes';
import { Project } from '../../models/Project';
import { BaseRecord } from '../../models/Record';
import { findDependentValues } from '../../utils/data';
import { useBackLink } from '../../utils/hooks/useBacklink';
import useNavigateAction from '../../utils/hooks/useNavigateAction';
import useRouter from '../../utils/hooks/useRouter';
import { RECORD_SCOPE } from '../../utils/scope';

export const useOnSuccessRedirect = (
  project: Project,
  dataType: DataType,
  onSave?: { navigate: any },
  scope?: Record<string, any>,
  rootPathname?: string,
  isPublicForm: boolean = false,
) => {
  const backLink = useBackLink(project);
  const { push } = useRouter();
  const onSuccessNavigate = useNavigateAction();

  return useCallback(
    (newRecord: BaseRecord) => {
      if (backLink && backLink.to.includes('/view/')) {
        push(backLink.to);
      } else if (onSave && onSave.navigate) {
        if (!isPublicForm || onSave.navigate.type !== PAGE) {
          const action = {
            id: RECORD_SCOPE,
            type: NAVIGATE,
            navigate: onSave.navigate,
          };
          return onSuccessNavigate(
            action,
            {
              ...scope,
              [RECORD_SCOPE]: {
                _dataType: dataType.name,
                ...newRecord,
              },
            },
            () => {},
          );
        }
      } else {
        push(`${rootPathname}/view/${newRecord.uuid}`);
      }
    },
    [
      backLink,
      dataType.name,
      isPublicForm,
      onSave,
      onSuccessNavigate,
      push,
      rootPathname,
      scope,
    ],
  );
};

export const useFormRecordDeps = (onSave: any, project: Project) =>
  useMemo(
    () =>
      findDependentValues(
        RECORD_SCOPE,
        {
          id: RECORD_SCOPE,
          props: {
            new: {
              onSave,
            },
          },
          type: VIEW,
        },
        project.dataTypes,
      ),
    [onSave, project.dataTypes],
  );
