import createReactComponent from '../createReactComponent';
export default createReactComponent('mask-off', 'IconMaskOff', [
  [
    'path',
    {
      d: 'M19.42 19.41a2 2 0 0 1 -1.42 .59h-12a2 2 0 0 1 -2 -2v-12c0 -.554 .225 -1.055 .588 -1.417m3.412 -.583h10a2 2 0 0 1 2 2v10',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9.885 9.872a3 3 0 1 0 4.245 4.24m.582 -3.396a3.012 3.012 0 0 0 -1.438 -1.433',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
