import createReactComponent from '../createReactComponent';
export default createReactComponent('shoe-off', 'IconShoeOff', [
  [
    'path',
    {
      d: 'M13.846 9.868l4.08 .972a4 4 0 0 1 3.074 3.89v2.27m-3 1h-14a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 18v-1a4 4 0 0 0 -4 -4h-1', key: 'svg-1' }],
  ['path', { d: 'M10 12l.663 -1.327', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
