import createReactComponent from '../createReactComponent';
export default createReactComponent('cell-signal-4', 'IconCellSignal4', [
  [
    'path',
    {
      d: 'M20 20h-15.269a.731 .731 0 0 1 -.517 -1.249l14.537 -14.537a.731 .731 0 0 1 1.249 .517v15.269z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 7v13', key: 'svg-1' }],
]);
