import React, { useCallback } from 'react';
import { IconBell, IconBellX, IconMessage } from '@tabler/icons-react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Tooltip } from '@noloco/components';
import useWindowSize from '@noloco/components/src/utils/hooks/useWindowSize';
import RightPanel from '../../../components/RightPanel';
import { darkModeColors } from '../../../constants/darkModeColors';
import { DataType } from '../../../models/DataTypes';
import { Project } from '../../../models/Project';
import { BaseRecord } from '../../../models/Record';
import { editorModeSelector } from '../../../selectors/elementsSelectors';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useDarkModeSurface from '../../../utils/hooks/useDarkModeSurface';
import useRecordComments from '../../../utils/hooks/useRecordComments';
import useRouter from '../../../utils/hooks/useRouter';
import { getText } from '../../../utils/lang';
import { isInternal } from '../../../utils/user';
import RecordCommentsBody from '../comments/RecordCommentsBody';
import RecordCommentsFooter from '../comments/RecordCommentsFooter';

const MAX_PORTAL_WIDTH = 1280;

const LANG_KEY = 'core.COMMENT';

type RecordCommentsProps = {
  allowAttachments: boolean;
  dataType: DataType;
  openByDefault?: boolean;
  project: Project;
  record: BaseRecord;
};

const RecordComments = ({
  allowAttachments,
  dataType,
  openByDefault,
  project,
  record,
}: RecordCommentsProps) => {
  const { user } = useAuthWrapper();
  const windowSize = useWindowSize();
  const [isDarkModeEnabled] = useDarkMode();
  const surface = useDarkModeSurface();
  const { pushQueryParams } = useRouter();
  const editorMode = useSelector(editorModeSelector);

  const onCloseComments = useCallback(
    () => pushQueryParams({ _comments: openByDefault ? 'false' : undefined }),
    [openByDefault, pushQueryParams],
  );

  const {
    comments,
    commentText,
    pageInfo,
    filesToSend,
    setFilesToSend,
    sendNote,
    hasCommentNotificationSubscription,
    onChange,
    loading,
    loaderRef,
    sending,
    totalCount,
    setSendNote,
    onCreateNewMessage,
    onDeleteMessage,
    updateCommentNotificationSubscription,
  } = useRecordComments({
    dataType,
    record,
    project,
  });

  return (
    <RightPanel
      className={classNames('flex', { dark: isDarkModeEnabled })}
      fixed={windowSize.width && windowSize.width <= MAX_PORTAL_WIDTH}
      footer={
        <RecordCommentsFooter
          allowAttachments={allowAttachments}
          commentText={commentText}
          isLoading={loading || sending}
          filesToSend={filesToSend}
          onChange={onChange}
          onCreateNewMessage={onCreateNewMessage}
          sendNote={sendNote}
          setFilesToSend={setFilesToSend}
          setSendNote={setSendNote}
          shouldFocus={!openByDefault && !editorMode}
          project={project}
          user={user}
        />
      }
      onClose={onCloseComments}
      title={
        <div className="flex w-full">
          {hasCommentNotificationSubscription ? (
            <Tooltip
              content={
                <span
                  className={classNames({
                    [darkModeColors.text.primary]: isDarkModeEnabled,
                  })}
                >
                  {getText(LANG_KEY, 'subscribed')}
                </span>
              }
              placement="left"
              surface={surface}
            >
              <IconBell
                className="m-2 cursor-pointer"
                size={18}
                onClick={updateCommentNotificationSubscription(false)}
              />
            </Tooltip>
          ) : (
            <Tooltip
              content={
                <span
                  className={classNames({
                    [darkModeColors.text.primary]: isDarkModeEnabled,
                  })}
                >
                  {getText(LANG_KEY, 'unsubscribed')}
                </span>
              }
              placement="left"
              surface={surface}
            >
              <IconBellX
                className="m-2 cursor-pointer"
                size={18}
                onClick={updateCommentNotificationSubscription(true)}
              />
            </Tooltip>
          )}
          <div className="flex grow items-center justify-center">
            <IconMessage className="mr-2" size={18} />
            <span>{getText({ context: totalCount }, LANG_KEY, 'title')}</span>
          </div>
        </div>
      }
      rootSelector={
        windowSize.width && windowSize.width > MAX_PORTAL_WIDTH
          ? '.right-sidebar'
          : undefined
      }
      usePortal={true}
    >
      <RecordCommentsBody
        canDelete={isInternal(user)}
        comments={comments}
        isDarkModeEnabled={isDarkModeEnabled}
        loaderRef={loaderRef}
        loading={loading}
        onDelete={onDeleteMessage}
        pageInfo={pageInfo}
      />
    </RightPanel>
  );
};

export default RecordComments;
