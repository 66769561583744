import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circle-arrow-down',
  'IconCircleArrowDown',
  [
    ['path', { d: 'M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M8 12l4 4', key: 'svg-1' }],
    ['path', { d: 'M12 8v8', key: 'svg-2' }],
    ['path', { d: 'M16 12l-4 4', key: 'svg-3' }],
  ],
);
