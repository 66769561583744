import { useCallback } from 'react';
import { getText } from '../lang';
import { useGraphQlErrorAlert } from './useAlerts';

const useActionsWithHiddenInputs = (
  createMutation: (additionalVariables?: any) => Promise<any>,
  updateMutation: (additionalVariables?: any) => Promise<any>,
  onNext: any,
) => {
  const networkErrorAlert = useGraphQlErrorAlert();

  const onCreate = useCallback(
    (action: any, setIsLoading: any, additionalVariables?: any) => {
      setIsLoading(true);

      return createMutation(additionalVariables)
        .then((result) => {
          setIsLoading(false);
          onNext(result);
          return { success: true, data: result };
        })
        .catch((e) => {
          console.error('Error running one-click create action', e);
          networkErrorAlert(getText('elements.VIEW.actionButtons.error'), e);
          return { success: false, error: e };
        });
    },
    [createMutation, networkErrorAlert, onNext],
  );

  const onUpdate = useCallback(
    (action: any, setIsLoading: any, additionalVariables?: any) => {
      setIsLoading(true);

      return updateMutation(additionalVariables)
        .then((result) => {
          setIsLoading(false);
          onNext(result);
          return { success: true, data: result };
        })
        .catch((e) => {
          console.error('Error running one-click update action', e);
          networkErrorAlert(getText('elements.VIEW.actionButtons.error'), e);
          return { success: false, error: e };
        });
    },
    [networkErrorAlert, onNext, updateMutation],
  );

  return {
    onCreate,
    onUpdate,
  };
};

export default useActionsWithHiddenInputs;
