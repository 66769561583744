import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-left-circle',
  'IconArrowLeftCircle',
  [
    ['path', { d: 'M17 12h-14', key: 'svg-0' }],
    ['path', { d: 'M6 9l-3 3l3 3', key: 'svg-1' }],
    ['path', { d: 'M19 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ],
);
