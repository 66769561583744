import { useMemo } from 'react';
import { captureException } from '@sentry/react';
import { useSelector } from 'react-redux';
import useLocale, {
  getLocaleName,
} from '@noloco/components/src/utils/hooks/useLocale';
import { getDataFieldsScopeSelector } from '../../components/canvas/withDataFields';
import baseElementsConfig from '../../elements/baseElementConfig';
import { ElementPath } from '../../models/Element';
import ElementConfig from '../../models/ElementConfig';
import { Project } from '../../models/Project';
import ElementPropType from '../../models/elementPropTypes/ElementPropType';
import { editorModeSelector } from '../../selectors/elementsSelectors';
import { reduceDynamicPropValues } from '../elementPropResolvers';
import { getDataScopeDeps } from '../fieldDependencies';
import { merge } from '../scope';

const defaultResolveFn = (__1: any, __2: ElementPropType): boolean => true;

const getScopeDeps = (element: any) => {
  let dataScopeDeps = [];

  try {
    dataScopeDeps = getDataScopeDeps(element);
  } catch (e) {
    console.error(e);
    captureException(e);
  }

  return dataScopeDeps;
};

const useSectionScopeVariables = (
  elementType: any,
  props: any,
  project: Project,
  elementPath: ElementPath,
  defaultScope = {},
  shouldResolveValue: (
    rawPropValue: any,
    propDefinition: ElementPropType,
  ) => boolean = defaultResolveFn,
) => {
  const localeName = getLocaleName();
  const locale = useLocale();

  const editorMode = useSelector(editorModeSelector);
  const element = useMemo(
    () => ({
      type: elementType,
      props,
    }),
    [elementType, props],
  );

  const dataScopeDeps = useMemo(
    () => (!editorMode ? getScopeDeps(element) : []),
    [editorMode, element],
  );

  const dataFieldsScopeSelector = useMemo(
    () =>
      getDataFieldsScopeSelector(
        dataScopeDeps,
        defaultScope,
        project,
        editorMode,
      ),
    [dataScopeDeps, defaultScope, editorMode, project],
  );

  const scope = useSelector(dataFieldsScopeSelector);

  const sectionScope = useMemo(
    () => merge(defaultScope, scope),
    [defaultScope, scope],
  );

  const elementConfig = useMemo(
    () => baseElementsConfig[element.type] || new ElementConfig(),
    [element.type],
  );

  return useMemo(
    () =>
      reduceDynamicPropValues(
        elementConfig.props,
        props,
        sectionScope,
        element,
        project,
        elementPath,
        true,
        shouldResolveValue,
        localeName,
        locale,
      ),
    [
      element,
      elementConfig.props,
      elementPath,
      locale,
      localeName,
      project,
      props,
      sectionScope,
      shouldResolveValue,
    ],
  );
};

export default useSectionScopeVariables;
