import createReactComponent from '../createReactComponent';
export default createReactComponent('ship-off', 'IconShipOff', [
  [
    'path',
    {
      d: 'M2 20a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 18l-1 -5h10m4 0h4l-1.334 2.668', key: 'svg-1' }],
  ['path', { d: 'M5 13v-6h2m4 0h2l4 6', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
