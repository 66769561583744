export type BuilderEventType = 'SCHEMA_INVALIDATION';

export const SCHEMA_INVALIDATION: BuilderEventType = 'SCHEMA_INVALIDATION';

export type UserEventType = 'DATA_INVALIDATION' | 'NOTIFICATION';

export const DATA_INVALIDATION: UserEventType = 'DATA_INVALIDATION';
export const NOTIFICATION: UserEventType = 'NOTIFICATION';

export type PING_PONG = 'PING' | 'PONG';

export const PING: PING_PONG = 'PING';
export const PONG: PING_PONG = 'PONG';

interface Event<Type, Payload> {
  type: Type;
  payload?: Payload;
}

export type BuilderEvent<Payload> =
  | Event<BuilderEventType, Payload>
  | PING_PONG;

export type UserEvent<Payload> = Event<UserEventType, Payload> | PING_PONG;
