import gql from 'graphql-tag';

export const NOTIFICATIONS_QUERY = gql`
  query notifications(
    $after: DateTime
    $before: DateTime
    $limit: Int
    $read: Boolean
  ) {
    notifications(after: $after, before: $before, limit: $limit, read: $read) {
      notifications {
        createdAt
        icon
        iconType
        id
        link
        message
        project
        readAt
        user
      }
      pageInfo {
        hasNextPage
        hasUnread
        newest
        oldest
      }
    }
  }
`;

export const HAS_COMMENT_NOTIFICATION_SUBSCRIPTION = gql`
  query hasCommentNotificationSubscription($dataType: String!, $recordId: ID!) {
    hasCommentNotificationSubscription(dataType: $dataType, recordId: $recordId)
  }
`;

export const MODIFY_COMMENT_NOTIFICATION_SUBSCRIPTION = gql`
  mutation modifyCommentNotificationSubscription(
    $dataType: String!
    $recordId: ID!
    $subscribed: Boolean!
  ) {
    modifyCommentNotificationSubscription(
      dataType: $dataType
      recordId: $recordId
      subscribed: $subscribed
    )
  }
`;

export const READ_NOTIFICATIONS_MUTATION = gql`
  mutation readNotifications($notifications: [ID!]!) {
    readNotifications(notifications: $notifications) {
      notifications {
        createdAt
        icon
        id
        message
        project
        readAt
        user
      }
    }
  }
`;

export const UNREAD_NOTIFICATIONS_MUTATION = gql`
  mutation unreadNotifications($notifications: [ID!]!) {
    unreadNotifications(notifications: $notifications) {
      notifications {
        createdAt
        icon
        id
        message
        project
        readAt
        user
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_PREFERENCES = gql`
  mutation updateNotificationPreference($comments: CommentNotificationType!) {
    updateNotificationPreference(comments: $comments) {
      comments
    }
  }
`;
