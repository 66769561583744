import get from 'lodash/get';
import * as elements from '../constants/elements';

const isIframeFieldAction = (action: any) => {
  return (
    action &&
    get(action, 'type', null) === elements.IFRAME &&
    get(action, 'iframe.type', null) === 'field'
  );
};

export default isIframeFieldAction;
