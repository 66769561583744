import createReactComponent from '../createReactComponent';
export default createReactComponent('gift', 'IconGift', [
  [
    'path',
    {
      d: 'M3 8m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 8l0 13', key: 'svg-1' }],
  [
    'path',
    { d: 'M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7', key: 'svg-2' },
  ],
  [
    'path',
    {
      d: 'M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5',
      key: 'svg-3',
    },
  ],
]);
