import React from 'react';
import { darkModeColors } from '../../../../constants/darkModeColors';

const CalendarTimeSlotWrapper = ({ children }: any) => (
  <div
    className={`h-full w-full text-sm text-gray-600 ${darkModeColors.text.secondary}`}
  >
    {children}
  </div>
);

export default CalendarTimeSlotWrapper;
