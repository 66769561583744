import { forwardRef, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { Loader, Theme } from '@noloco/components';
import withDataFields from '../../../components/canvas/withDataFields';
import { ChartAggregation } from '../../../constants/chartAggregations';
import {
  CARDS,
  CHARTS,
  CollectionLayout,
  EVENT_BASED_LAYOUTS,
  MAP,
  PIVOT_TABLE,
  ROWS,
  SPLIT,
} from '../../../constants/collectionLayouts';
import { OrderByDirection } from '../../../constants/orderByDirections';
import { PivotTable } from '../../../constants/pivotTable';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import {
  ActionButton,
  DepValue,
  Element,
  ElementPath,
} from '../../../models/Element';
import { PermissionValue } from '../../../models/Permission';
import { Project } from '../../../models/Project';
import { BaseRecord, RecordEdge } from '../../../models/Record';
import StateItem from '../../../models/StateItem';
import {
  CollectionField,
  DataList,
  DataListFilter,
  EditRelatedRecordButtons,
  GroupByWithField,
  MapProps,
} from '../../../models/View';
import { QueryObject } from '../../../queries/data';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useDarkModeSurface from '../../../utils/hooks/useDarkModeSurface';
import useIsTable from '../../../utils/hooks/useIsTable';
import { FieldConfig } from '../../../utils/permissions';
import { PAGINATION_STYLES } from '../../sections/Collection';
import CollectionCharts from './CollectionCharts';
import CollectionEvents from './CollectionEvents';
import CollectionMap from './CollectionMap';
import CollectionRecord from './CollectionRecord';
import DraggableGroupView from './DraggableGroupView';
import GroupedVirtualizedLayout from './layouts/GroupedVirtualizedLayout';
import TableLayout from './layouts/TableLayout';
import VirtualizedCollectionBody from './layouts/VirtualizedCollectionBody';
import RowPagination from './pagination/RowPagination';

const DEFAULT_COLUMN_WIDTHS = {};

interface CollectionDataBodyProps {
  actionButtons: ActionButton[];
  allRowsSelected: boolean;
  bulkActionsEnabled: boolean;
  calendarDate: string;
  calendarView: string;
  charts: any[];
  customFilters: DataListFilter[];
  dataList: DataList;
  dataType: DataType;
  dateEnd: DepValue | undefined;
  dateEndField: DataField;
  dateStart: DepValue | undefined;
  dateStartField: DataField;
  edges: RecordEdge[];
  editorMode: boolean;
  editRelatedRecordButtons: EditRelatedRecordButtons;
  element: Element;
  elementPath: ElementPath;
  EmptyState: () => JSX.Element;
  enableDragAndDropEdit: boolean;
  endTime: number;
  error: any;
  fieldConfigs: FieldConfig<CollectionField>[];
  formatRecordScope: (record: BaseRecord) => Record<string, any>;
  ganttDependency: DepValue;
  groupByFields: GroupByWithField[];
  groupOptions: Record<string, { hidden: boolean }>;
  handleSetOrderBy: (field: DataField, direction: OrderByDirection) => void;
  hideEmptyGroups: boolean;
  innerClassName: string;
  isRecordView: boolean;
  layout: CollectionLayout;
  limitPerGroup: number;
  loading: boolean;
  map: MapProps;
  mapLatField: DataField;
  mapLngField: DataField;
  maxStickyColumnIndex: number;
  newButtonLink: string;
  newButtonVisible: boolean;
  nodeQueryObject: QueryObject;
  orderBy: { field: string; direction: OrderByDirection };
  pageInfo: any;
  parentValuePath: string[];
  permissions: PermissionValue;
  pivotTable?: PivotTable;
  project: Project;
  qsSuffix: string;
  rawData: any;
  recordConfig: BaseRecord;
  recordQueryString: string;
  recordTitle: string;
  refetch: any;
  rootDataType?: DataType;
  rowLink: string;
  scope: Record<string, any>;
  selectedRows: BaseRecord[];
  setPaginationQuery: (paginationQuery: any) => void;
  setSelectAllRows: (selectAll: boolean) => void;
  setSelectedRows: (
    setter: (currentValue: BaseRecord[]) => BaseRecord[],
  ) => void;
  showCardHeroImage: boolean;
  showTableSummary: boolean;
  startTime: number;
  summary?: { field: StateItem; type: ChartAggregation };
  theme: Theme;
  totalCount: number;
  transformRecordScope: (
    currentScope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
  viewRootPathname: string | null;
}

const CollectionDataBody = ({
  actionButtons = [],
  allRowsSelected,
  bulkActionsEnabled,
  calendarDate,
  calendarView,
  charts,
  customFilters,
  dataList,
  dataType,
  dateEnd,
  dateEndField,
  dateStart,
  dateStartField,
  edges,
  editorMode,
  editRelatedRecordButtons,
  element,
  elementPath,
  EmptyState,
  enableDragAndDropEdit,
  endTime,
  error,
  fieldConfigs,
  formatRecordScope,
  ganttDependency,
  groupByFields,
  groupOptions,
  handleSetOrderBy,
  hideEmptyGroups,
  innerClassName,
  isRecordView,
  layout,
  limitPerGroup,
  loading,
  map,
  mapLatField,
  mapLngField,
  maxStickyColumnIndex,
  newButtonLink,
  newButtonVisible,
  nodeQueryObject,
  orderBy,
  pageInfo,
  parentValuePath,
  permissions,
  pivotTable,
  project,
  qsSuffix,
  rawData,
  recordConfig,
  recordQueryString,
  recordTitle,
  refetch,
  rootDataType,
  rowLink,
  scope,
  selectedRows,
  setPaginationQuery,
  setSelectAllRows,
  setSelectedRows,
  showCardHeroImage,
  showTableSummary,
  startTime,
  summary,
  theme,
  totalCount,
  transformRecordScope,
  viewRootPathname,
}: CollectionDataBodyProps) => {
  const surface = useDarkModeSurface();
  const [isDarkModeEnabled] = useDarkMode();

  const isTable = useIsTable(layout);

  const WrappedCollectionRecord = useMemo(
    () => withDataFields(CollectionRecord, element, project, editorMode),
    [editorMode, element, project],
  );

  const CollectionRecordWrapper = useMemo(
    () =>
      forwardRef(
        (
          {
            className,
            children,
            edge,
            index,
            isLast,
            'data-index': dataIndex,
            'data-group-key': dataGroupKey,
            draggable,
            record,
            columnWidths,
          }: any,
          recordRef,
        ) => (
          <WrappedCollectionRecord
            actionButtons={actionButtons}
            className={className}
            dataList={dataList}
            dataType={dataType}
            editorMode={editorMode}
            element={element}
            elementPath={elementPath}
            edge={edge}
            editRelatedRecordButtons={editRelatedRecordButtons}
            fieldConfigs={fieldConfigs}
            formatRecordScope={formatRecordScope}
            groupByFields={groupByFields}
            isLast={isLast}
            index={index}
            maxStickyColumnIndex={maxStickyColumnIndex}
            loading={loading}
            layout={layout}
            parentValuePath={parentValuePath}
            project={project}
            record={record}
            rootDataType={rootDataType}
            rowLink={rowLink}
            recordQueryString={recordQueryString}
            refetch={refetch}
            scope={scope}
            showCardHeroImage={showCardHeroImage}
            transformRecordScope={transformRecordScope}
            viewRootPathname={viewRootPathname}
            ref={recordRef}
            data-index={dataIndex}
            data-group-key={dataGroupKey}
            draggable={draggable}
            columnWidths={columnWidths}
            surface={surface}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          >
            {children}
          </WrappedCollectionRecord>
        ),
      ),
    [
      actionButtons,
      dataList,
      dataType,
      editorMode,
      editRelatedRecordButtons,
      element,
      elementPath,
      fieldConfigs,
      formatRecordScope,
      groupByFields,
      layout,
      loading,
      maxStickyColumnIndex,
      parentValuePath,
      project,
      recordQueryString,
      refetch,
      rootDataType,
      rowLink,
      scope,
      selectedRows,
      setSelectedRows,
      showCardHeroImage,
      surface,
      transformRecordScope,
      viewRootPathname,
      WrappedCollectionRecord,
    ],
  );

  const RecordLayoutByEdge = useMemo(
    () =>
      forwardRef<
        HTMLTableRowElement,
        {
          className?: string;
          edge: RecordEdge;
          index: number;
          'data-index': number;
          'data-group-key'?: string;
          draggable: boolean;
          columnWidths: Record<number, number>;
        }
      >(
        (
          {
            className,
            edge,
            index,
            columnWidths,
            'data-index': dataIndex,
            'data-group-key': dataGroupKey,
            draggable,
          },
          recordLayoutRef,
        ) => (
          <CollectionRecordWrapper
            className={className}
            edge={edge}
            isLast={index === edges.length - 1}
            index={index}
            data-index={dataIndex}
            data-group-key={dataGroupKey}
            draggable={draggable}
            key={get(edge, 'node.id')}
            record={edge.node}
            ref={recordLayoutRef}
            columnWidths={columnWidths}
          />
        ),
      ),
    [CollectionRecordWrapper, edges],
  );

  const RecordLayoutByIndex = useMemo(
    () =>
      forwardRef<
        HTMLTableRowElement,
        {
          index: number;
          columnWidths: Record<number, number>;
          'data-index': number;
        }
      >(
        (
          {
            index,
            columnWidths = DEFAULT_COLUMN_WIDTHS,
            'data-index': dataIndex,
          },
          recordLayoutRef,
        ) => {
          const edge = edges[index];

          if (!edge) {
            return null;
          }

          return (
            <RecordLayoutByEdge
              index={index}
              edge={edge}
              data-index={dataIndex}
              draggable={false}
              ref={recordLayoutRef}
              columnWidths={columnWidths}
            />
          );
        },
      ),
    [RecordLayoutByEdge, edges],
  );

  const rows = useMemo(() => {
    if (groupByFields.length > 0) {
      return (
        <DraggableGroupView
          className={innerClassName}
          dataType={dataType}
          dataTypes={project.dataTypes}
          edges={edges}
          elementId={element.id}
          customFilters={customFilters}
          groupByFields={groupByFields}
          groupOptions={groupOptions}
          hideEmptyGroups={hideEmptyGroups}
          layout={layout || ROWS}
          limitPerGroup={limitPerGroup}
          summary={summary}
          fields={fieldConfigs}
          nodeQueryObject={nodeQueryObject}
          Row={CollectionRecordWrapper}
          enableDragAndDropEdit={enableDragAndDropEdit}
          bulkActionsEnabled={bulkActionsEnabled}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      );
    }

    return edges.map(
      (edge: any, index: any) =>
        edge && (
          <RecordLayoutByIndex
            key={edge.node?.uuid}
            columnWidths={DEFAULT_COLUMN_WIDTHS}
            data-index={index}
            index={index}
          />
        ),
    );
  }, [
    bulkActionsEnabled,
    CollectionRecordWrapper,
    customFilters,
    dataType,
    edges,
    element.id,
    enableDragAndDropEdit,
    fieldConfigs,
    groupByFields,
    groupOptions,
    hideEmptyGroups,
    innerClassName,
    layout,
    limitPerGroup,
    nodeQueryObject,
    project.dataTypes,
    RecordLayoutByIndex,
    selectedRows,
    setSelectedRows,
    summary,
  ]);

  const isEventsBasedLayout = useMemo(
    () => EVENT_BASED_LAYOUTS.includes(layout),
    [layout],
  );

  const dropIndicator = useMemo(() => {
    if (isTable) {
      return (
        <tr className={`h-1 bg-${theme.brandColorGroups.primary}-400`}>
          <td colSpan={fieldConfigs.length} />
        </tr>
      );
    }

    return (
      <div className={`h-1 bg-${theme.brandColorGroups.primary}-400 w-full`}>
        &nbsp;
      </div>
    );
  }, [fieldConfigs.length, isTable, theme.brandColorGroups.primary]);

  if (loading && !isEventsBasedLayout) {
    return (
      <div
        className={classNames('flex w-full items-center justify-center p-8', {
          'col-span-3 md:col-span-1': layout === CARDS,
        })}
      >
        <Loader size="sm" />
      </div>
    );
  }

  if (error && !rawData) {
    console.log('error:', error);

    return (
      <div
        className={classNames('flex w-full items-center justify-center p-6', {
          'col-span-3 md:col-span-1': layout === CARDS,
        })}
      >
        <em>Something went wrong</em>
      </div>
    );
  }

  if (layout === MAP) {
    return (
      <CollectionMap
        dataType={dataType}
        dataTypes={project.dataTypes}
        editorMode={editorMode}
        edges={edges}
        EmptyState={EmptyState}
        project={project}
        groupByFields={groupByFields}
        map={map}
        latField={mapLatField}
        lngField={mapLngField}
        pagination={
          dataList.showPagination &&
          pageInfo && (
            <RowPagination
              className={PAGINATION_STYLES[MAP](isDarkModeEnabled as boolean)}
              showSummary={false}
              pageInfo={pageInfo}
              totalCount={totalCount}
              setPaginationQuery={setPaginationQuery}
              currentLength={edges.length}
            />
          )
        }
        recordTitleField={recordTitle ?? get(recordConfig, 'title')}
        Row={CollectionRecordWrapper}
      />
    );
  }

  if (edges.length === 0 && !isEventsBasedLayout && layout !== CHARTS) {
    return <EmptyState />;
  }

  if (layout === CHARTS) {
    return (
      <CollectionCharts
        charts={charts}
        dataType={dataType}
        dataTypes={project.dataTypes}
        edges={edges}
        editorMode={editorMode}
        element={element}
        EmptyState={EmptyState}
        project={project}
        nodeQueryObject={nodeQueryObject}
        scope={scope}
      />
    );
  }

  if (isEventsBasedLayout) {
    const eventEdges = edges.filter(
      (edge: [{ id: string }] | RecordEdge) => !('id' in edge),
    );

    return (
      <CollectionEvents
        calendarView={calendarView}
        canUpdate={permissions.update}
        dataType={dataType}
        date={calendarDate}
        dateEnd={dateEnd}
        dateEndField={dateEndField}
        dateStart={dateStart}
        dateStartField={dateStartField}
        edges={eventEdges}
        elementId={element.id}
        elementType={element.type}
        enableDragAndDropEdit={enableDragAndDropEdit}
        endTime={endTime}
        ganttDependency={ganttDependency}
        groupByFields={groupByFields}
        isRecordView={isRecordView}
        layout={layout}
        loading={loading && !!dateStartField}
        newButtonLink={newButtonLink}
        newButtonVisible={newButtonVisible}
        nodeQueryObject={nodeQueryObject}
        project={project}
        qsSuffix={qsSuffix}
        recordTitleField={recordTitle ?? get(recordConfig, 'title')}
        rootPathname={viewRootPathname}
        Row={CollectionRecordWrapper}
        rowLink={rowLink}
        startTime={startTime}
      />
    );
  }

  if (isTable) {
    if (groupByFields.length > 0 && layout !== PIVOT_TABLE) {
      return (
        <GroupedVirtualizedLayout
          allRowsSelected={allRowsSelected}
          bulkActionsEnabled={bulkActionsEnabled}
          className={innerClassName}
          customFilters={customFilters}
          dataType={dataType}
          dataTypes={project.dataTypes}
          dropIndicator={dropIndicator}
          edges={edges}
          element={element}
          elementId={element.id}
          elementPath={elementPath}
          enableDragAndDropEdit={enableDragAndDropEdit}
          fields={fieldConfigs}
          groupByFields={groupByFields}
          groupOptions={groupOptions}
          handleSetOrderBy={handleSetOrderBy}
          hideEmptyGroups={hideEmptyGroups}
          layout={layout || ROWS}
          limitPerGroup={limitPerGroup}
          maxStickyColumnIndex={maxStickyColumnIndex}
          nodeQueryObject={nodeQueryObject}
          orderBy={orderBy}
          project={project}
          pageInfo={pageInfo}
          RecordLayoutByEdge={RecordLayoutByEdge}
          selectedRows={selectedRows}
          setPaginationQuery={setPaginationQuery}
          setSelectAllRows={setSelectAllRows}
          setSelectedRows={setSelectedRows}
          showPagination={dataList.showPagination && pageInfo}
          showTableSummary={showTableSummary}
          totalCount={totalCount}
          VirtualizedLayout={TableLayout}
        />
      );
    }

    return (
      <TableLayout
        allRowsSelected={allRowsSelected}
        bulkActionsEnabled={bulkActionsEnabled}
        className={innerClassName}
        count={edges.length}
        dataType={dataType}
        dataTypes={project.dataTypes}
        edges={edges}
        element={element}
        elementPath={elementPath}
        fieldConfigs={fieldConfigs}
        handleSetOrderBy={handleSetOrderBy}
        layout={layout}
        maxStickyColumnIndex={maxStickyColumnIndex}
        orderBy={orderBy}
        pageInfo={pageInfo}
        pivotTable={pivotTable}
        project={project}
        RecordLayoutByIndex={RecordLayoutByIndex}
        setPaginationQuery={setPaginationQuery}
        setSelectAllRows={setSelectAllRows}
        showPagination={dataList.showPagination && pageInfo}
        showTableSummary={showTableSummary}
        totalCount={totalCount}
      />
    );
  }

  if (layout === ROWS || layout === SPLIT) {
    if (groupByFields.length > 0) {
      return (
        <GroupedVirtualizedLayout
          bulkActionsEnabled={bulkActionsEnabled}
          className={innerClassName}
          customFilters={customFilters}
          dataType={dataType}
          dataTypes={project.dataTypes}
          dropIndicator={dropIndicator}
          edges={edges}
          element={element}
          elementId={element.id}
          elementPath={elementPath}
          enableDragAndDropEdit={enableDragAndDropEdit}
          fields={fieldConfigs}
          groupByFields={groupByFields}
          groupOptions={groupOptions}
          handleSetOrderBy={handleSetOrderBy}
          hideEmptyGroups={hideEmptyGroups}
          layout={layout || ROWS}
          limitPerGroup={limitPerGroup}
          maxStickyColumnIndex={maxStickyColumnIndex}
          nodeQueryObject={nodeQueryObject}
          orderBy={orderBy}
          pageInfo={pageInfo}
          project={project}
          RecordLayoutByEdge={RecordLayoutByEdge}
          selectedRows={selectedRows}
          setPaginationQuery={setPaginationQuery}
          setSelectedRows={setSelectedRows}
          showPagination={dataList.showPagination && pageInfo}
          showTableSummary={showTableSummary}
          totalCount={totalCount}
          VirtualizedLayout={VirtualizedCollectionBody}
        />
      );
    }

    return (
      <VirtualizedCollectionBody
        className={innerClassName}
        count={edges.length}
        edges={edges}
        layout={layout}
        fieldConfigs={fieldConfigs}
        pageInfo={pageInfo}
        RecordLayoutByIndex={RecordLayoutByIndex}
        setPaginationQuery={setPaginationQuery}
        showPagination={dataList.showPagination && pageInfo}
        totalCount={totalCount}
      />
    );
  }

  const getPaginationStyles = PAGINATION_STYLES[layout];
  const paginationStyles =
    getPaginationStyles !== undefined
      ? getPaginationStyles(isDarkModeEnabled)
      : undefined;

  return (
    <>
      {rows}
      {dataList.showPagination && pageInfo && (
        <RowPagination
          className={paginationStyles}
          showSummary={layout === ROWS}
          pageInfo={pageInfo}
          totalCount={totalCount}
          setPaginationQuery={setPaginationQuery}
          currentLength={edges.length}
        />
      )}
    </>
  );
};

export default withTheme(CollectionDataBody);
