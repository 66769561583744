import createReactComponent from '../createReactComponent';
export default createReactComponent('device-sim-3', 'IconDeviceSim3', [
  [
    'path',
    {
      d: 'M6 3h8.5l4.5 4.5v12.5a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M10 9h2.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-2.5',
      key: 'svg-1',
    },
  ],
]);
