import { useCallback, useMemo } from 'react';
import initial from 'lodash/initial';
import { useDispatch } from 'react-redux';
import shortId from 'shortid';
import { useAddChild } from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  getRandomPageIcon,
  getUniqueRoutePath,
} from '@noloco/ui/src/utils/layout';
import { isPageFullScreenIframe } from '../../components/buildMode/LeftBuildModePageEditor';
import { PAGE } from '../../constants/elements';
import { Project } from '../../models/Project';
import { setEditingPage } from '../../reducers/elements';
import {
  setSelectedElement,
  setSelectedPagePath,
} from '../../reducers/elements';
import useRouter from '../../utils/hooks/useRouter';
import { NEW_PAGE_SAVED, trackEvent } from '../analytics';
import { getText } from '../lang';
import { getPagesConfig } from '../pages';

const useAddPage = (project: Project) => {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const {
    isV2,
    pagesPath,
    projectPages: elements,
  } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project],
  );
  const [addChild] = useAddChild(
    project,
    isV2 ? [] : initial(pagesPath),
    isV2 ? [] : ['children'],
  );

  return useCallback(
    (page: any, newPageIndex?: number) => {
      trackEvent(NEW_PAGE_SAVED);
      const isPageIframe = isPageFullScreenIframe(page);
      const pageName = isPageIframe
        ? getText('leftSidebar.pages.iframe')
        : getText({ count: elements.length + 1 }, 'leftSidebar.pages.page');

      const newPage = {
        id: shortId.generate(),
        type: PAGE,
        children: [],
        ...page,
        props: {
          name: pageName,
          routePath: getUniqueRoutePath(String(pageName), project),
          icon: { name: getRandomPageIcon() },
          V2: true,
          ...page.props,
        },
      };

      addChild(newPage, newPageIndex);
      dispatch(setEditingPage(newPage.id));
      dispatch(
        setSelectedElement([...pagesPath, newPageIndex ?? elements.length]),
      );
      dispatch(setSelectedPagePath([...(isV2 ? [] : ['PORTAL']), newPage.id]));

      const parentPage = elements.find(
        (element: any) => element.id === newPage.props.parentPage,
      );

      setTimeout(() => {
        push(
          `${parentPage ? `/${parentPage.props.routePath}` : ''}/${
            newPage.props.routePath
          }`,
        );
      }, 500);
    },
    [addChild, dispatch, isV2, pagesPath, project, push, elements],
  );
};

export default useAddPage;
