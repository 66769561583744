import createReactComponent from '../createReactComponent';
export default createReactComponent('grill', 'IconGrill', [
  [
    'path',
    {
      d: 'M19 8h-14a6 6 0 0 0 6 6h2a6 6 0 0 0 6 -5.775l0 -.225z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 20a2 2 0 1 1 0 -4a2 2 0 0 1 0 4z', key: 'svg-1' }],
  ['path', { d: 'M15 14l1 2', key: 'svg-2' }],
  ['path', { d: 'M9 14l-3 6', key: 'svg-3' }],
  ['path', { d: 'M15 18h-8', key: 'svg-4' }],
  ['path', { d: 'M15 5v-1', key: 'svg-5' }],
  ['path', { d: 'M12 5v-1', key: 'svg-6' }],
  ['path', { d: 'M9 5v-1', key: 'svg-7' }],
]);
