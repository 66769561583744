import React from 'react';
import PopoutMenu, { PopoutMenuProps } from './PopoutMenu';

const LeftPopoutMenu = ({ children, ...rest }: PopoutMenuProps) => {
  return (
    <PopoutMenu side="left" {...rest}>
      {children}
    </PopoutMenu>
  );
};

export default LeftPopoutMenu;
