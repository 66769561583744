import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-imac-exclamation',
  'IconDeviceImacExclamation',
  [
    [
      'path',
      {
        d: 'M15 17h-11a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 13h13', key: 'svg-1' }],
    ['path', { d: 'M8 21h7', key: 'svg-2' }],
    ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
    ['path', { d: 'M14 17l.5 4', key: 'svg-4' }],
    ['path', { d: 'M19 16v3', key: 'svg-5' }],
    ['path', { d: 'M19 22v.01', key: 'svg-6' }],
  ],
);
