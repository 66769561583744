import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { PropertyPath } from 'lodash';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { SelectInput, Surface, TextInput } from '@noloco/components';
import { OBJECT } from '../../../../constants/dataTypes';
import { ADDRESS } from '../../../../constants/fieldFormats';
import { SUB_FIELD_CONFIG } from '../../../../constants/objects';
import { DataField } from '../../../../models/DataTypeFields';
import { ElementPath } from '../../../../models/Element';
import { projectSettingsSelector } from '../../../../selectors/projectSelectors';
import { getColorByIndex } from '../../../../utils/colors';
import { getText } from '../../../../utils/lang';
import AddressAutocompleteInput from './AddressAutocompleteInput';

const COUNTRY_OPTIONS = [
  {
    color: 'gray',
    display: ' ',
    name: null,
  },
  ...get(SUB_FIELD_CONFIG, [ADDRESS, 'country', 'options'], []),
].map((option, index) => ({
  color: getColorByIndex(index),
  label: option.display,
  value: option.display,
}));

type Props = {
  disabled?: boolean;
  field: DataField;
  onBlur: () => void;
  onUpdateDraftValue: (path: PropertyPath) => (value: any) => void;
  onUpdateValue: (path: ElementPath) => (value: any) => void;
  surface: Surface;
  validationError?: string;
  value: any;
};

const AddressInput = ({
  disabled,
  onBlur,
  onUpdateDraftValue,
  onUpdateValue,
  surface,
  validationError,
  value,
}: Props) => {
  const settings = useSelector(projectSettingsSelector);
  const mapsApiKey = get(settings, 'apiKeys.googleMaps');

  return (
    <div
      className="transparent grid w-full grid-cols-2 gap-2 sm:grid-cols-1"
      data-testid="address-input"
    >
      <div className="col-span-2">
        {!mapsApiKey && (
          <TextInput
            disabled={disabled}
            onBlur={onBlur}
            onChange={(e: any) =>
              onUpdateDraftValue(['street'])(e.target.value)
            }
            placeholder={getText('data.types', OBJECT, ADDRESS, 'street.label')}
            surface={surface}
            validationError={validationError}
            value={value?.street}
          />
        )}
        {mapsApiKey && (
          <AddressAutocompleteInput
            disabled={disabled}
            onBlur={onBlur}
            onChange={(path: string[], nextValue: any) =>
              onUpdateValue(path)(nextValue)
            }
            placeholder={getText('data.types', OBJECT, ADDRESS, 'street.label')}
            surface={surface}
            validationError={validationError}
            value={value?.street}
            mapsApiKey={mapsApiKey}
          />
        )}
      </div>
      <div className="col-span-2">
        <TextInput
          disabled={disabled}
          onBlur={onBlur}
          onChange={(e: any) =>
            onUpdateDraftValue(['suiteAptBldg'])(e.target.value)
          }
          placeholder={getText(
            'data.types',
            OBJECT,
            ADDRESS,
            'suiteAptBldg.label',
          )}
          surface={surface}
          validationError={validationError}
          value={value?.suiteAptBldg}
        />
      </div>
      <div className="col-span-2">
        <TextInput
          disabled={disabled}
          onBlur={onBlur}
          onChange={(e: any) => onUpdateDraftValue(['city'])(e.target.value)}
          placeholder={getText('data.types', OBJECT, ADDRESS, 'city.label')}
          surface={surface}
          validationError={validationError}
          value={value?.city}
        />
      </div>
      <div className="col-span-2">
        <TextInput
          disabled={disabled}
          onBlur={onBlur}
          onChange={(e: any) =>
            onUpdateDraftValue(['stateRegion'])(e.target.value)
          }
          placeholder={getText(
            'data.types',
            OBJECT,
            ADDRESS,
            'stateRegion.label',
          )}
          surface={surface}
          validationError={validationError}
          value={value?.stateRegion}
        />
      </div>
      <div>
        <TextInput
          disabled={disabled}
          onBlur={onBlur}
          onChange={(e: any) =>
            onUpdateDraftValue(['postalCode'])(e.target.value)
          }
          placeholder={getText(
            'data.types',
            OBJECT,
            ADDRESS,
            'postalCode.label',
          )}
          surface={surface}
          validationError={validationError}
          value={value?.postalCode}
        />
      </div>
      <div>
        <SelectInput
          disabled={disabled}
          options={COUNTRY_OPTIONS}
          onBlur={onBlur}
          onChange={onUpdateValue(['country'])}
          placeholder={getText('data.types', OBJECT, ADDRESS, 'country.label')}
          searchable={true}
          surface={surface}
          validationError={validationError}
          value={value?.country}
        />
      </div>
    </div>
  );
};

export default withTheme(AddressInput);
