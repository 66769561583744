import createReactComponent from '../createReactComponent';
export default createReactComponent('report', 'IconReport', [
  [
    'path',
    { d: 'M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697', key: 'svg-0' },
  ],
  ['path', { d: 'M18 14v4h4', key: 'svg-1' }],
  ['path', { d: 'M18 11v-4a2 2 0 0 0 -2 -2h-2', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-4' }],
  ['path', { d: 'M8 11h4', key: 'svg-5' }],
  ['path', { d: 'M8 15h3', key: 'svg-6' }],
]);
