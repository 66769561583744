import createReactComponent from '../createReactComponent';
export default createReactComponent('lock-square', 'IconLockSquare', [
  [
    'path',
    {
      d: 'M8 11m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 11v-2a2 2 0 1 1 4 0v2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-2',
    },
  ],
]);
