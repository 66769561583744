import tailwindColors from 'tailwindcss/colors';

export type ColorPalette =
  | 'theme'
  | 'noloco'
  | 'tableau'
  | 'tableauExtended'
  | 'traffic'
  | 'jewelBright'
  | 'sunset'
  | 'dutchField'
  | 'pastels'
  | 'blueToRed'
  | 'pinkFoam'
  | 'colorBlindPalette';

export const themePalette: ColorPalette = 'theme';
export const nolocoPalette: ColorPalette = 'noloco';
export const tableauPalette: ColorPalette = 'tableau';
export const tableauExtendedPalette: ColorPalette = 'tableauExtended';
export const trafficPalette: ColorPalette = 'traffic';
export const jewelBrightPalette: ColorPalette = 'jewelBright';
export const sunsetPalette: ColorPalette = 'sunset';
export const dutchFieldPalette: ColorPalette = 'dutchField';
export const pastelsPalette: ColorPalette = 'pastels';
export const blueToRedPalette: ColorPalette = 'blueToRed';
export const pinkFoamPalette: ColorPalette = 'pinkFoam';
export const colorBlindPalette: ColorPalette = 'colorBlindPalette';

const colorPalettes: Record<Exclude<ColorPalette, 'theme'>, string[]> = {
  [nolocoPalette]: [
    tailwindColors.blue[500],
    tailwindColors.red[500],
    tailwindColors.yellow[500],
    tailwindColors.cyan[500],
    tailwindColors.pink[500],
    tailwindColors.green[500],
    tailwindColors.purple[500],
    tailwindColors.teal[500],
  ],
  [tableauPalette]: [
    '#4E79A7',
    '#F28E2B',
    '#E15759',
    '#76B7B2',
    '#59A14F',
    '#EDC948',
    '#B07AA1',
    '#FF9DA7',
    '#9C755F',
    '#BAB0AC',
  ],
  [tableauExtendedPalette]: [
    '#4E79A7',
    '#A0CBE8',
    '#F28E2B',
    '#FFBE7D',
    '#59A14F',
    '#8CD17D',
    '#B6992D',
    '#F1CE63',
    '#499894',
    '#86BCB6',
    '#E15759',
    '#FF9D9A',
    '#79706E',
    '#BAB0AC',
    '#D37295',
    '#FABFD2',
    '#B07AA1',
    '#D4A6C8',
    '#9D7660',
    '#D7B5A6',
  ],
  [trafficPalette]: [
    '#b60a1c',
    '#e39802',
    '#309143',
    '#e03531',
    '#f0bd27',
    '#51b364',
    '#ff684c',
    '#ffda66',
    '#8ace7e',
  ],
  [colorBlindPalette]: [
    '#000000',
    '#E69F00',
    '#56B4E9',
    '#009E73',
    '#F0E442',
    '#0072B2',
    '#D55E00',
    '#CC79A7',
  ],
  [jewelBrightPalette]: [
    '#eb1e2c',
    '#fd6f30',
    '#f9a729',
    '#f9d23c',
    '#5fbb68',
    '#64cdcc',
    '#91dcea',
    '#a4a4d5',
    '#bbc9e5',
  ],
  [sunsetPalette]: [
    '#33608c',
    '#9768a5',
    '#e7718a',
    '#f6ba57',
    '#ed7846',
    '#d54c45',
    '#b81840',
  ],
  [dutchFieldPalette]: [
    '#e60049',
    '#0bb4ff',
    '#50e991',
    '#e6d800',
    '#9b19f5',
    '#ffa300',
    '#dc0ab4',
    '#b3d4ff',
    '#00bfa0',
  ],
  [pastelsPalette]: [
    '#fd7f6f',
    '#7eb0d5',
    '#b2e061',
    '#bd7ebe',
    '#ffb55a',
    '#ffee65',
    '#beb9db',
    '#fdcce5',
    '#8bd3c7',
  ],
  [blueToRedPalette]: [
    tailwindColors.blue[600],
    tailwindColors.blue[400],
    tailwindColors.blue[200],
    tailwindColors.blue[50],
    tailwindColors.red[200],
    tailwindColors.red[400],
    tailwindColors.red[600],
  ],
  [pinkFoamPalette]: [
    '#54bebe',
    '#76c8c8',
    '#98d1d1',
    '#badbdb',
    '#dedad2',
    '#e4bcad',
    '#df979e',
    '#d7658b',
    '#c80064',
  ],
};

export default colorPalettes;
