import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'gender-transgender',
  'IconGenderTransgender',
  [
    ['path', { d: 'M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-0' }],
    ['path', { d: 'M15 9l6 -6', key: 'svg-1' }],
    ['path', { d: 'M21 7v-4h-4', key: 'svg-2' }],
    ['path', { d: 'M9 9l-6 -6', key: 'svg-3' }],
    ['path', { d: 'M3 7v-4h4', key: 'svg-4' }],
    ['path', { d: 'M5.5 8.5l3 -3', key: 'svg-5' }],
    ['path', { d: 'M12 16v5', key: 'svg-6' }],
    ['path', { d: 'M9.5 19h5', key: 'svg-7' }],
  ],
);
