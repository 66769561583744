import get from 'lodash/get';
import isNil from 'lodash/isNil';
import builtInDataTypes, {
  COMMENT,
  COMPANY,
  FILE,
  USER,
} from '../constants/builtInDataTypes';
import {
  DECIMAL,
  DataFieldType,
  INTEGER,
  INVALID_FIELD_NAME_LOOKUP,
  OBJECT,
  TEXT,
} from '../constants/dataTypes';
import DataTypeFields, {
  DataField,
  FieldTypeOptions,
} from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { DataTypeIdentifier } from '../models/ProjectArrayTypeMap';
import { BaseRecord } from '../models/Record';
import { formatFieldValue } from './fieldValues';
import { sortFields } from './fields';

export const formatDisplayField = (field: DataField, value: any) => {
  if (isNil(value)) {
    return '';
  }

  const formattedValue = formatFieldValue(value, field);

  if (formattedValue) {
    return formattedValue;
  }

  if (field.type === OBJECT) {
    return '';
  }

  return value;
};

export const findPreviewFields = (
  fields: DataTypeFields,
  dataType: DataType,
) => {
  const sortedFields = fields.slice().sort(sortFields(dataType));

  const textFields = [];
  if (dataType.primaryField) {
    const primaryField = sortedFields.find(
      ({ id }) => id === dataType.primaryField?.id,
    );
    if (primaryField) {
      textFields.push(primaryField);
    }
  }

  if (textFields.length === 0) {
    sortedFields
      .filter(
        ({ hidden, type, name }) =>
          !['id', 'uuid'].includes(name) &&
          !hidden &&
          (type === TEXT || type === DECIMAL || type === INTEGER) &&
          name !== 'email',
      )
      .slice(0, dataType.name === USER ? 2 : 1)
      .forEach((field) => textFields.push(field));
  }

  return {
    imageField: sortedFields.find(({ type }) => type === FILE),
    textFields,
    secondaryField:
      dataType.name === USER
        ? sortedFields.find(({ name }) => name === 'email')
        : null,
  };
};

export const getPreviewFieldsForField = (
  field: DataField,
  dataTypes: DataTypes,
) => {
  const { relatedField, relationship } = field;

  const relatedType = dataTypes.getByName(field.type);

  if (!relatedType) {
    return null;
  }

  if (relationship) {
    return findPreviewFields(relatedType.fields, relatedType);
  } else if (relatedField) {
    const relatedType = dataTypes.getByName(field.type);

    return relatedType
      ? findPreviewFields(relatedType.fields, relatedType)
      : null;
  }

  return null;
};

export const getPreviewValueForRecord = (
  dataType: DataType,
  record: BaseRecord,
) => {
  const { textFields } = findPreviewFields(dataType.fields, dataType);

  return (
    textFields
      .map((textField) =>
        formatDisplayField(textField, get(record, textField.apiName)),
      )
      .join(' ') || `${get(record, 'uuid', '').substring(0, 8)}...`
  );
};

export const fieldNameIsSafeForType = (name: string, type: DataFieldType) =>
  !get(INVALID_FIELD_NAME_LOOKUP, type, [] as string[]).includes(name);

export const VISIBLE_INTERNAL_TYPES = [COMPANY, USER];

export const filterDefaultTypes = ({ internal, name }: DataField) =>
  !internal || VISIBLE_INTERNAL_TYPES.includes(name);

export const filterBuiltInAndCommentDataTypes = ({
  name,
}: DataTypeIdentifier) => ![...builtInDataTypes, COMMENT].includes(name);

export const getValueForFieldTypeInput = (
  type: DataFieldType | string | null,
  typeOptions: FieldTypeOptions | undefined,
) => {
  if (type === OBJECT) {
    return typeOptions?.format ?? type;
  }

  return type;
};
