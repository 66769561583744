import { LG, MD, SM, XS } from '../../constants/tShirtSizes';

const caretSizes = {
  [XS]: 12,
  [SM]: 14,
  [MD]: 18,
  [LG]: 20,
};

export default caretSizes;
