import { useMemo } from 'react';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import DataTypes from '@noloco/core/src/models/DataTypes';

const useRowCounts = (dataTypes: DataTypes) => {
  const rowCounts = useMemo(
    () =>
      dataTypes.reduce(
        ({ rows, syncedRows }, { rowCount, source }) => ({
          rows: rows + rowCount,
          syncedRows:
            source.type !== INTERNAL ? syncedRows + rowCount : syncedRows,
        }),
        { rows: 0, syncedRows: 0 },
      ),
    [dataTypes],
  );

  return rowCounts;
};

export default useRowCounts;
