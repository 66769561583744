import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-sharp-turn-left',
  'IconArrowSharpTurnLeft',
  [
    [
      'path',
      {
        d: 'M17 18v-11.31a.7 .7 0 0 0 -1.195 -.495l-9.805 9.805',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M11 16h-5v-5', key: 'svg-1' }],
  ],
);
