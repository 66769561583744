import createReactComponent from '../createReactComponent';
export default createReactComponent('cpu-off', 'IconCpuOff', [
  [
    'path',
    {
      d: 'M9 5h9a1 1 0 0 1 1 1v9m-.292 3.706a1 1 0 0 1 -.708 .294h-12a1 1 0 0 1 -1 -1v-12c0 -.272 .108 -.518 .284 -.698',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 9h2v2m0 4h-6v-6', key: 'svg-1' }],
  ['path', { d: 'M3 10h2', key: 'svg-2' }],
  ['path', { d: 'M3 14h2', key: 'svg-3' }],
  ['path', { d: 'M10 3v2', key: 'svg-4' }],
  ['path', { d: 'M14 3v2', key: 'svg-5' }],
  ['path', { d: 'M21 10h-2', key: 'svg-6' }],
  ['path', { d: 'M21 14h-2', key: 'svg-7' }],
  ['path', { d: 'M14 21v-2', key: 'svg-8' }],
  ['path', { d: 'M10 21v-2', key: 'svg-9' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-10' }],
]);
