import createReactComponent from '../createReactComponent';
export default createReactComponent('anchor-off', 'IconAnchorOff', [
  ['path', { d: 'M12 12v9', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M4 13a8 8 0 0 0 14.138 5.13m1.44 -2.56a7.99 7.99 0 0 0 .422 -2.57',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M21 13h-2', key: 'svg-2' }],
  ['path', { d: 'M5 13h-2', key: 'svg-3' }],
  ['path', { d: 'M12.866 8.873a3 3 0 1 0 -3.737 -3.747', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
