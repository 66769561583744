import React from 'react';
import { IconShield, IconStar } from '@tabler/icons-react';
import { Button, Tooltip } from '@noloco/components';
import { getText } from '../utils/lang';

const ChangeUserRow = ({
  onSelect,
  onToggleFavourite,
  isFavourite,
  user,
}: any) => (
  <div className="group relative flex h-12 min-h-12 items-center py-2">
    <div className="flex w-1/2 items-center overflow-hidden">
      <button className="mr-4" onClick={() => onToggleFavourite(user.id)}>
        <IconStar
          className={
            isFavourite
              ? 'text-pink-500 hover:text-gray-400'
              : 'text-gray-300 hover:text-pink-400'
          }
          size={16}
        />
      </button>
      <div className="flex flex-col overflow-hidden">
        <span className="truncate text-sm font-medium">
          {user.firstName} {user.lastName}
        </span>
        <span className="truncate text-xs">{user.email}</span>
      </div>
    </div>
    <div className="flex w-1/2 items-center overflow-hidden pl-8">
      <div className="mr-auto flex flex-col space-y-1 truncate">
        {user.company && <span className="text-xs">{user.company.name}</span>}
        {user.role && (
          <div className="mr-auto flex items-center truncate">
            <IconShield size={14} className="mr-1 opacity-75" />
            <span className="text-xs">{user.role.name}</span>
          </div>
        )}
      </div>
      <Tooltip
        disabled={!user.deactivatedAt}
        content={getText('leftSidebar.auth.deactivated')}
      >
        <div className="ml-auto hidden h-full group-hover:block">
          <Button
            className="disabled:opacity-50"
            variant="secondary"
            disabled={user.deactivatedAt}
            onClick={onSelect}
            size="sm"
          >
            {getText('leftSidebar.auth.select')}
          </Button>
        </div>
      </Tooltip>
    </div>
  </div>
);

export default ChangeUserRow;
