import createReactComponent from '../createReactComponent';
export default createReactComponent('message-2-heart', 'IconMessage2Heart', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h3.5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10.5 19.5l-1.5 -1.5h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
      key: 'svg-3',
    },
  ],
]);
