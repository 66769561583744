import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { setQuery } from '../../reducers/queries';
import { queryStateSelector } from '../../selectors/queriesSelectors';

const useSetQuery = (queryObject: any, disabled?: any) => {
  const dispatch = useDispatch();
  const currentQuery = useSelector(
    queryStateSelector(queryObject.id, disabled),
  );
  useEffect(() => {
    if (!disabled && (!currentQuery || !isEqual(queryObject, currentQuery))) {
      dispatch(setQuery(queryObject));
    }
  }, [currentQuery, disabled, dispatch, queryObject]);
};

export default useSetQuery;
