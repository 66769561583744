import createReactComponent from '../createReactComponent';
export default createReactComponent('note', 'IconNote', [
  ['path', { d: 'M13 20l7 -7', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13 20v-6a1 1 0 0 1 1 -1h6v-7a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7',
      key: 'svg-1',
    },
  ],
]);
