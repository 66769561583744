import createReactComponent from '../createReactComponent';
export default createReactComponent('article-off', 'IconArticleOff', [
  [
    'path',
    {
      d: 'M8 4h11a2 2 0 0 1 2 2v11m-1.172 2.821a1.993 1.993 0 0 1 -.828 .179h-14a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 1.156 -1.814',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 8h1m4 0h5', key: 'svg-1' }],
  ['path', { d: 'M7 12h5m4 0h1', key: 'svg-2' }],
  ['path', { d: 'M7 16h9', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
