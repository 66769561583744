import createReactComponent from '../createReactComponent';
export default createReactComponent('rainbow-off', 'IconRainbowOff', [
  [
    'path',
    {
      d: 'M22 17c0 -5.523 -4.477 -10 -10 -10c-.308 0 -.613 .014 -.914 .041m-3.208 .845a10 10 0 0 0 -5.878 9.114',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11.088 11.069a6 6 0 0 0 -5.088 5.931', key: 'svg-1' }],
  ['path', { d: 'M14 17a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
