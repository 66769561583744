import React, { useMemo } from 'react';
import { IconSearch } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import { DateTime } from 'luxon';
import {
  RatingInput,
  SelectInput,
  Surface,
  TextInput,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import DatePicker from '../../../components/DatePicker';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  INTEGER,
  MULTIPLE_OPTION,
  OBJECT,
  SINGLE_OPTION,
  TEXT,
} from '../../../constants/dataTypes';
import { DATE as DATE_FORMAT, RATING } from '../../../constants/fieldFormats';
import { FORMATS_WITH_ROOT } from '../../../constants/objects';
import { EMPTY, EQUAL } from '../../../constants/operators';
import { DataField } from '../../../models/DataTypeFields';
import { Project } from '../../../models/Project';
import { sortOptions } from '../../../utils/fields';
import { formatTextInput } from '../../../utils/filters';
import { getText } from '../../../utils/lang';
import { withNullOption } from '../../../utils/settings';
import RelationalDataFieldInput from './filters/RelationalDataFieldInput';

const LANG_KEY = 'elements.VIEW';

const getBooleanOptions = () =>
  withNullOption(
    [true, false].map((value) => ({
      label: getText('data.dataTypes.boolean', value),
      value,
    })),
  );

const getFormattedTextInput = (fieldType: any, value: any) => {
  const formattedTextInput = formatTextInput(
    fieldType === OBJECT ? TEXT : fieldType,
    value,
  );

  if (isNil(formattedTextInput)) {
    return undefined;
  }

  if ([DECIMAL, INTEGER].includes(fieldType)) {
    return formattedTextInput;
  }

  if (formattedTextInput) {
    return formattedTextInput;
  }

  return undefined;
};

const getMinMaxPlaceholder = (type: any, placeholder: any, display: any) => {
  if (placeholder) {
    return `${upperFirst(type)} ${placeholder}`;
  }

  return getText({ field: upperFirst(display) }, LANG_KEY, 'filters', type);
};

type CollectionFilterProps = {
  authQuery?: boolean;
  field: DataField;
  filter: any;
  project: Project;
  onChange: (value: any) => void;
  value: any;
  surface?: Surface;
};

const CollectionFilter = ({
  authQuery,
  field,
  filter,
  project,
  onChange,
  surface,
  value,
}: CollectionFilterProps) => {
  const isNumericField = useMemo(
    () => [INTEGER, DECIMAL].includes(field.type),
    [field.type],
  );

  if (field.type === BOOLEAN) {
    const booleanOptions = getBooleanOptions();
    return (
      <SelectInput
        className="sm:w-full"
        options={booleanOptions}
        placeholder={filter.placeholder || booleanOptions[0].label}
        onChange={onChange}
        value={value}
      />
    );
  }

  const filterOperator = get(filter, 'filterOperator', EQUAL);
  if (
    field.type === TEXT ||
    (field.type === OBJECT &&
      field.typeOptions?.format &&
      FORMATS_WITH_ROOT.includes(field.typeOptions.format)) ||
    (isNumericField && filterOperator === EQUAL)
  ) {
    if (field.type === INTEGER && field.typeOptions?.format === RATING) {
      return (
        <RatingInput
          className="h-8"
          onChange={onChange}
          value={value}
          maxRating={field.typeOptions?.max}
        />
      );
    }

    return (
      <TextInput
        className="sm:w-full"
        onChange={debounce(
          ({ target: { value: val } }) =>
            onChange(getFormattedTextInput(field.type, val)),
          800,
        )}
        icon={field.name === '_q' ? <IconSearch size={16} /> : undefined}
        value={isNumericField ? String(value) : value}
        clearable={true}
        placeholder={filter.placeholder}
        type={isNumericField ? 'number' : 'text'}
      />
    );
  }

  if ([INTEGER, DECIMAL].includes(field.type)) {
    const [min = null, max = null] = (value || '').split(':');
    const placeholder = get(filter, 'placeholder', null);

    return (
      <div className="flex items-center justify-center gap-x-4">
        <TextInput
          onChange={debounce(
            ({ target: { value } }: any) =>
              onChange({
                min: getFormattedTextInput(field.type, value),
                max: getFormattedTextInput(field.type, max),
              }),
            800,
          )}
          value={min}
          placeholder={getMinMaxPlaceholder('min', placeholder, field.display)}
          type="number"
        />
        <p className="text-gray-400">{getText(LANG_KEY, 'filters.to')}</p>
        <TextInput
          onChange={debounce(
            ({ target: { value } }: any) =>
              onChange({
                min: getFormattedTextInput(field.type, min),
                max: getFormattedTextInput(field.type, value),
              }),
            800,
          )}
          value={max}
          placeholder={getMinMaxPlaceholder('max', placeholder, field.display)}
          type="number"
        />
      </div>
    );
  }

  if (field.type === DATE) {
    let dateValue = null;
    const selectTimeRange = filter.selectRange !== false;

    if (selectTimeRange && value) {
      const startDate = value.start ? DateTime.fromISO(value.start) : null;
      const endDate = value.end ? DateTime.fromISO(value.end) : null;

      dateValue =
        startDate && endDate ? { start: startDate, end: endDate } : null;
    } else if (value) {
      dateValue = DateTime.fromISO(value);
    }

    return (
      <DatePicker
        clearable={true}
        selectTime={
          !selectTimeRange && get(field, 'typeOptions.format') !== DATE_FORMAT
        }
        isRange={selectTimeRange}
        value={dateValue}
        // @ts-expect-error TS(2322): Type '{ clearable: true; selectTime: boolean; isRa... Remove this comment to see the full error message
        onChange={debounce((date: any) => {
          if (!date || (selectTimeRange && !date.start && !date.end)) {
            onChange(null);
          } else if (selectTimeRange && date.start && date.end) {
            onChange({
              start: date.start.toISO(),
              end: date.end.toISO(),
            });
          } else if (!selectTimeRange) {
            onChange(date.toISO());
          }
        }, 800)}
        placeholder={filter.placeholder}
        surface={surface}
        w={56}
      />
    );
  }

  if (field.type === SINGLE_OPTION || field.type === MULTIPLE_OPTION) {
    const options = [
      ...(!isNil(value) && !filter.multiple
        ? [
            {
              label: getText('core.dataTypes.none'),
              value: null,
            },
          ]
        : []),
      ...(filter.filterByEmpty && (isNil(value) || value === EMPTY)
        ? [
            {
              label:
                filter.filterByEmptyLabel ||
                getText(LANG_KEY, 'filters.filterForEmptyLabel'),
              value: EMPTY,
            },
          ]
        : []),
      ...(Array.isArray(field.options) ? sortOptions(field.options) : [])
        .filter(
          (option) =>
            !get(filter, ['optionsConfig', option.name, 'hidden'], false),
        )
        .map((option) => ({
          label: option.display,
          value: option.name,
          color: option.color,
        })),
    ];

    return (
      <SelectInput
        className="sm:w-full"
        options={options}
        searchable={true}
        multiple={filter.multiple || field.type === MULTIPLE_OPTION}
        onChange={onChange}
        placeholder={filter.placeholder}
        surface={surface}
        value={value}
      />
    );
  }

  if ((field.relationship || field.relatedField) && field.type !== 'file') {
    const additionalFields = {
      __args: {
        ...(filter.orderBy ? { orderBy: filter.orderBy } : {}),
        ...(filter.customFilters ? { where: filter.customFilters } : {}),
      },
    };

    return (
      <RelationalDataFieldInput
        authQuery={authQuery}
        className="sm:w-full"
        additionalFields={additionalFields}
        field={field}
        filter={filter.filter}
        filterByEmpty={filter.filterByEmpty}
        filterByEmptyLabel={filter.filterByEmptyLabel}
        multiple={!!filter.multiple}
        dataTypes={project.dataTypes}
        projectName={project.name}
        placeholder={filter.placeholder}
        value={value}
        onChange={onChange}
        //set default to LIGHT so that the select don't look dark on light mode
        surface={surface ?? LIGHT}
        project={project}
      />
    );
  }

  return null;
};

export default CollectionFilter;
