import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'playstation-circle',
  'IconPlaystationCircle',
  [
    [
      'path',
      {
        d: 'M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      { d: 'M12 12m-4.5 0a4.5 4.5 0 1 0 9 0a4.5 4.5 0 1 0 -9 0', key: 'svg-1' },
    ],
  ],
);
