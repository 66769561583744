import createReactComponent from '../createReactComponent';
export default createReactComponent('device-imac', 'IconDeviceImac', [
  [
    'path',
    {
      d: 'M3 4a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-12z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 13h18', key: 'svg-1' }],
  ['path', { d: 'M8 21h8', key: 'svg-2' }],
  ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
  ['path', { d: 'M14 17l.5 4', key: 'svg-4' }],
]);
