import React, { useMemo } from 'react';
import set from 'lodash/fp/set';
import { CodeEditor, SelectInput, TextInput } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { Endpoint } from '@noloco/core/src/constants/apis';
import { GET, POST } from '@noloco/core/src/constants/endpointMethodTypes';
import { getText } from '@noloco/core/src/utils/lang';

const DEFAULT_JSON = '{}';

const LANG_KEY = 'data.api.endpoints';

type Props = {
  endpoint: Endpoint;
  onUpdateEndpoint: (endpoint: Endpoint) => void;
};

const ApiEndpointEditor = ({ endpoint, onUpdateEndpoint }: Props) => {
  const endpointMethodOptions = useMemo(
    () =>
      [GET, POST].map((methodType) => ({
        value: methodType,
        label: methodType,
      })),
    [],
  );

  return (
    <div className="mb-auto flex w-full max-w-2xl flex-col overflow-y-auto px-4 py-16 text-sm text-white">
      <label className="mb-2 mt-6 font-medium leading-5">
        {getText(LANG_KEY, 'name.label')}
      </label>
      <p className="mb-2 text-sm text-gray-400">
        {getText(LANG_KEY, 'name.help')}
      </p>
      <div className="flex">
        <TextInput
          onChange={({ target: { value } }: any) => {
            onUpdateEndpoint(set('display', value, endpoint));
          }}
          placeholder={getText(LANG_KEY, 'name.placeholder')}
          value={endpoint.display}
        />
      </div>
      <label className="mb-2 mt-6 font-medium leading-5">
        {getText(LANG_KEY, 'target.label')}
      </label>
      <p className="mb-2 text-sm text-gray-400">
        {getText(LANG_KEY, 'target.help')}
      </p>
      <div className="flex space-x-2">
        <SelectInput
          placeholder={getText(LANG_KEY, 'target.label')}
          options={endpointMethodOptions}
          value={endpoint.method}
          onChange={(value: any) =>
            onUpdateEndpoint(set('method', value, endpoint))
          }
        />
        <TextInput
          onChange={({ target: { value } }: any) => {
            onUpdateEndpoint(set('path', value, endpoint));
          }}
          placeholder={getText(LANG_KEY, 'target.path.placeholder')}
          value={endpoint.path}
        />
      </div>
      {endpoint.method === POST && (
        <>
          <label className="mb-2 mt-6 font-medium leading-5">
            {getText(LANG_KEY, 'body.label')}
          </label>
          <p className="mb-2 text-sm text-gray-400">
            {getText(LANG_KEY, 'body.help')}
          </p>
          <div className="h-52">
            <CodeEditor
              defaultValue={endpoint.body ?? DEFAULT_JSON}
              language="json"
              onChange={(value: any) => {
                onUpdateEndpoint(set('body', value, endpoint));
              }}
              surface={DARK}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ApiEndpointEditor;
