import createReactComponent from '../createReactComponent';
export default createReactComponent('user-exclamation', 'IconUserExclamation', [
  ['path', { d: 'M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 21v-2a4 4 0 0 1 4 -4h4c.348 0 .686 .045 1.008 .128',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 16v3', key: 'svg-2' }],
  ['path', { d: 'M19 22v.01', key: 'svg-3' }],
]);
