import createReactComponent from '../createReactComponent';
export default createReactComponent('trowel', 'IconTrowel', [
  [
    'path',
    {
      d: 'M14.42 9.058l-5.362 5.363a1.978 1.978 0 0 1 -3.275 -.773l-2.682 -8.044a1.978 1.978 0 0 1 2.502 -2.502l8.045 2.682a1.978 1.978 0 0 1 .773 3.274z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 10l6.5 6.5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M19.347 16.575l1.08 1.079a1.96 1.96 0 0 1 -2.773 2.772l-1.08 -1.079a1.96 1.96 0 0 1 2.773 -2.772z',
      key: 'svg-2',
    },
  ],
]);
