import React, { memo, useMemo } from 'react';
import { expandDataTypes } from '@noloco/core/src/utils/data';
import { BaseConditionValueEditorProps } from '../../models/BaseConditionValueEditorProps';
import ConditionValueInput from './ConditionValueInput';

const ConditionValueEditor = memo(
  ({
    acceptableDataTypes,
    additionalScopeItems = [],
    contained,
    disabled,
    elementPath,
    field,
    includeUniqueColumnar,
    dataType,
    multiple,
    onChange,
    placeholder,
    project,
    fieldFilter,
    includeSelf,
    value,
  }: BaseConditionValueEditorProps) => {
    const updatedAcceptableDataTypes = useMemo(() => {
      if (!field) {
        return undefined;
      }

      if (field.relationship || field.relatedField) {
        return acceptableDataTypes ? acceptableDataTypes : [field.type];
      }

      return expandDataTypes(field.type);
    }, [acceptableDataTypes, field]);

    return (
      <ConditionValueInput
        acceptableDataTypes={updatedAcceptableDataTypes}
        additionalScopeItems={additionalScopeItems}
        contained={contained}
        dataType={dataType}
        disabled={disabled}
        elementPath={elementPath}
        field={field}
        fieldFilter={fieldFilter}
        includeUniqueColumnar={includeUniqueColumnar}
        includeRelationships={true}
        includeSelf={includeSelf}
        multiple={multiple}
        onChange={onChange}
        project={project}
        placeholder={placeholder}
        value={value}
      />
    );
  },
);

(ConditionValueEditor as any).defaultProps = {
  acceptableDataTypes: undefined,
  includeUniqueColumnar: false,
};

export default ConditionValueEditor;
