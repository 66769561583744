import createReactComponent from '../createReactComponent';
export default createReactComponent('flask-2', 'IconFlask2', [
  ['path', { d: 'M6.1 15h11.8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M14 3v7.342a6 6 0 0 1 1.318 10.658h-6.635a6 6 0 0 1 1.317 -10.66v-7.34h4z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 3h6', key: 'svg-2' }],
]);
