import get from 'lodash/get';
import { DateTime, DateTimeOptions } from 'luxon';
import DATE_FORMATS from '../constants/dateTimeFormats';
import {
  DATE as DATE_FORMAT,
  DATE_RANGE,
  DUE_DATE,
} from '../constants/fieldFormats';
import { DataField } from '../models/DataTypeFields';
import { RecordValue } from '../models/Record';

export const YEAR_MONTH_DATE_FORMAT = 'yyyy-MM-dd';
export const YMD_TIME_FORMAT = `${YEAR_MONTH_DATE_FORMAT}-HH-mm`;

export const getDateFromValue = (
  value: any,
  options?: DateTimeOptions,
): DateTime | null => {
  for (let d = 0; d < DATE_FORMATS.length; d++) {
    try {
      const parsedDate = DATE_FORMATS[d](value, options);
      if (parsedDate.isValid) {
        return parsedDate;
      }
    } catch {
      // We don't do anything to explicitly handle
    }
  }

  return null;
};

export const formatDateValue = (
  value: RecordValue,
  field: DataField,
  config: any,
) => {
  let dateValue = getDateFromValue(value);
  const baseFormat =
    field.typeOptions?.format === DATE_FORMAT ||
    (field.typeOptions?.format === DATE_RANGE && !field.typeOptions?.time) ||
    (field.typeOptions?.format === DUE_DATE && !field.typeOptions?.time) ||
    config.format === DATE_FORMAT
      ? DateTime.DATE_SHORT
      : DateTime.DATETIME_SHORT;
  if (dateValue) {
    if (field.typeOptions?.format === DATE_FORMAT) {
      dateValue = dateValue.toUTC();
    }

    const timeZone = get(field, 'typeOptions.timeZone');
    if (timeZone) {
      dateValue = dateValue.setZone(timeZone);
    }

    return dateValue
      .toLocaleString(config.dateFormat || baseFormat)
      .replace(',', '');
  }
};
