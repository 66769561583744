import {
  BUSINESS,
  BUSINESS_V2,
  PRO,
  PROFESSIONAL,
  PRO_V2,
  SCALE,
  STARTER,
  STARTER_V2,
  TEAM,
} from './accountPlans';

export const FLAT_PLANS = [STARTER, PROFESSIONAL, TEAM, SCALE];

export const SEAT_BASED_PLANS = [PRO, BUSINESS];

export const DEPRECATED_PLANS = [STARTER, PROFESSIONAL, ...SEAT_BASED_PLANS];

export const FLAT_PLANS_V2 = [STARTER_V2, PRO_V2, BUSINESS_V2];

export const CURRENT_PLANS = FLAT_PLANS_V2;
