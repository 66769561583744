import createReactComponent from '../createReactComponent';
export default createReactComponent('new-section', 'IconNewSection', [
  ['path', { d: 'M9 12l6 0', key: 'svg-0' }],
  ['path', { d: 'M12 9l0 6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M4 6v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1m-5 0h-2m-5 0h-1a1 1 0 0 1 -1 -1v-1m0 -5v-2m0 -5',
      key: 'svg-2',
    },
  ],
]);
