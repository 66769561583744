import createReactComponent from '../createReactComponent';
export default createReactComponent('map-off', 'IconMapOff', [
  [
    'path',
    {
      d: 'M8.32 4.34l.68 -.34l6 3l6 -3v13m-2.67 1.335l-3.33 1.665l-6 -3l-6 3v-13l2.665 -1.333',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 4v1m0 4v8', key: 'svg-1' }],
  ['path', { d: 'M15 7v4m0 4v5', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
