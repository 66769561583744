import { useMemo } from 'react';
import isNil from 'lodash/isNil';

const MIN_SIZE_FOR_INTERACTIVE_PREVIEW = 16;

export const useFilePreview = (maxH: number | undefined) => {
  const showFileButtons = useMemo(
    () => isNil(maxH) || maxH >= MIN_SIZE_FOR_INTERACTIVE_PREVIEW,
    [maxH],
  );

  const showFileName = useMemo(
    () => isNil(maxH) || maxH >= MIN_SIZE_FOR_INTERACTIVE_PREVIEW,
    [maxH],
  );

  const squareRounding = useMemo(
    () =>
      !isNil(maxH) && maxH < MIN_SIZE_FOR_INTERACTIVE_PREVIEW
        ? 'rounded-md'
        : 'rounded-lg',
    [maxH],
  );

  return {
    showFileButtons,
    showFileName,
    squareRounding,
  };
};
