import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-linkedin', 'IconBrandLinkedin', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 11l0 5', key: 'svg-1' }],
  ['path', { d: 'M8 8l0 .01', key: 'svg-2' }],
  ['path', { d: 'M12 16l0 -5', key: 'svg-3' }],
  ['path', { d: 'M16 16v-3a2 2 0 0 0 -4 0', key: 'svg-4' }],
]);
