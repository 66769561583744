import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-ctemplar', 'IconBrandCtemplar', [
  ['path', { d: 'M6.04 14.831l4.46 -4.331', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12.555 20.82c4.55 -3.456 7.582 -8.639 8.426 -14.405a1.668 1.668 0 0 0 -.934 -1.767a19.647 19.647 0 0 0 -8.047 -1.648a19.647 19.647 0 0 0 -8.047 1.647a1.668 1.668 0 0 0 -.934 1.767c.844 5.766 3.875 10.95 8.426 14.406a.948 .948 0 0 0 1.11 0z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M20 5c-2 0 -4.37 3.304 -8 6.644c-3.63 -3.34 -6 -6.644 -8 -6.644',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M17.738 15l-4.238 -4.5', key: 'svg-3' }],
]);
