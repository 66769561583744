import createReactComponent from '../createReactComponent';
export default createReactComponent('grain', 'IconGrain', [
  ['path', { d: 'M4.5 9.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M9.5 4.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M9.5 14.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-2' }],
  ['path', { d: 'M4.5 19.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-3' }],
  ['path', { d: 'M14.5 9.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-4' }],
  ['path', { d: 'M19.5 4.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-5' }],
  ['path', { d: 'M14.5 19.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-6' }],
  ['path', { d: 'M19.5 14.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-7' }],
]);
