import createReactComponent from '../createReactComponent';
export default createReactComponent('columns-1', 'IconColumns1', [
  [
    'path',
    {
      d: 'M5 3m0 1a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v16a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
]);
