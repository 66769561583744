import React, { forwardRef, useMemo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { TablerIconsProps } from '@tabler/icons-react';
import * as Icons from '@tabler/icons-react';
import { IconValue } from '../models/IconValue';

// Gets typescript to ignore the export of a helper function
const iconsLookup = Icons as unknown as Record<
  string,
  (_props: TablerIconsProps) => JSX.Element
>;

const DEFAULT_ICON_NAME = 'Bulb';

export const getIconFromValue = (icon: IconValue | undefined) => {
  if (!icon) {
    return null;
  }

  const { name = DEFAULT_ICON_NAME } = icon || {};

  const IconComponent = iconsLookup[`Icon${name}`];
  if (!name || !IconComponent) {
    return null;
  }

  return IconComponent;
};

const Icon = forwardRef(
  (
    {
      className,
      icon,
      onClick,
      size,
      style,
    }: {
      className?: string;
      icon: IconValue | undefined;
      onClick?: ((arg: Event) => any) | undefined;
      size?: string | number;
      style?: string;
    },
    ref,
  ) => {
    const IconComponent = useMemo(() => getIconFromValue(icon), [icon]);

    if (!IconComponent) {
      return null;
    }

    return (
      <Box
        is="span"
        className={className}
        block={true}
        onClick={onClick}
        ref={ref}
        style={style}
      >
        <IconComponent width="100%" size={size} height="100%" />
      </Box>
    );
  },
);

Icon.defaultProps = {
  className: '',
  icon: { name: DEFAULT_ICON_NAME },
};

export default Icon;
