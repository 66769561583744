import React, { useCallback, useState } from 'react';
import { PropertyPath } from 'lodash';
import { set } from 'lodash/fp';
import { Surface } from '@noloco/components';
import PhoneNumberInput from '@noloco/components/src/components/input/PhoneNumberInput';
import {
  ADDRESS,
  COORDINATES,
  DATE_RANGE,
  DUE_DATE,
  FULL_NAME,
  FieldFormat,
  PHONE_NUMBER,
} from '../../../constants/fieldFormats';
import { DataField } from '../../../models/DataTypeFields';
import { ElementPath } from '../../../models/Element';
import AddressInput from './objects/AddressInput';
import CoordinatesInput from './objects/CoordinatesInput';
import DateRangeInput from './objects/DateRangeInput';
import DueDateInput from './objects/DueDateInput';
import FullNameInput from './objects/FullNameInput';

type Props = {
  disabled?: boolean;
  field: DataField;
  format: FieldFormat;
  id: string;
  inline?: boolean;
  onBlur: () => void;
  onChange: (value: any) => void;
  surface: Surface;
  value: any;
};

const ObjectInputs = {
  [ADDRESS]: AddressInput,
  [COORDINATES]: CoordinatesInput,
  [DATE_RANGE]: DateRangeInput,
  [DUE_DATE]: DueDateInput,
  [FULL_NAME]: FullNameInput,
  [PHONE_NUMBER]: PhoneNumberInput,
};

const ObjectInput = ({
  disabled,
  field,
  format,
  id,
  inline,
  onChange,
  surface,
  value,
}: Props) => {
  const ObjectInput = ObjectInputs[format];

  const [draftValue, setDraftValue] = useState(
    Object.entries(value ?? {}).reduce((acc: any, [key, val]) => {
      if (key !== '__typename') {
        acc[key] = val;
      }
      return acc;
    }, {}),
  );

  const onUpdateDraftValue = useCallback(
    (path: PropertyPath) => (property: any) =>
      setDraftValue(set(path, property, draftValue)),
    [draftValue],
  );

  const onUpdateValue = useCallback(
    (path: ElementPath) => (property: any) => {
      if (path.length === 0) {
        setDraftValue(property);
        onChange(property);
      } else {
        const newValue = set(path, property, draftValue);
        setDraftValue(newValue);
        onChange(newValue);
      }
    },
    [draftValue, onChange],
  );

  const onBlur = useCallback(() => {
    onChange(draftValue);
  }, [draftValue, onChange]);

  if (!ObjectInput) {
    return null;
  }

  return (
    <ObjectInput
      id={id}
      disabled={disabled}
      field={field}
      inline={inline}
      onBlur={onBlur}
      onChange={onChange}
      onUpdateDraft={setDraftValue}
      onUpdateDraftValue={onUpdateDraftValue}
      onUpdateValue={onUpdateValue}
      surface={surface}
      value={value}
    />
  );
};

export default ObjectInput;
