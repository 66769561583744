import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-left-rhombus',
  'IconArrowLeftRhombus',
  [
    ['path', { d: 'M16 12h-13', key: 'svg-0' }],
    ['path', { d: 'M6 9l-3 3l3 3', key: 'svg-1' }],
    ['path', { d: 'M18.5 9.5l2.5 2.5l-2.5 2.5l-2.5 -2.5z', key: 'svg-2' }],
  ],
);
