import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Loader, Modal, TextInput } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { getText } from '@noloco/core/src/utils/lang';
import {
  DELETE_DATA_FIELD,
  DELETE_LOOKUP_FIELD,
  DELETE_ROLLUP_FIELD,
} from '../../../queries/project';
import { useRemoveDataField } from '../../../utils/hooks/useUpdateDataTypes';

type Props = {
  dataTypeId: number;
  field: DataField;
  onClose: (...args: any[]) => any;
  projectName: string;
  onDeleteSuccess: () => void;
};

const DeleteFieldModal = ({
  dataTypeId,
  projectName,
  field,
  onClose,
  onDeleteSuccess,
}: Props) => {
  const removeDataField = useRemoveDataField();
  const [deleteField] = useMutation(DELETE_DATA_FIELD);
  const [deleteLookup] = useMutation(DELETE_LOOKUP_FIELD);
  const [deleteRollup] = useMutation(DELETE_ROLLUP_FIELD);
  const [loading, setLoading] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const handleConfirmDelete = () => {
    setLoading(true);

    if (field.lookup) {
      deleteLookup({
        variables: {
          projectName,
          dataTypeId,
          lookupId: field.lookup.id,
        },
      })
        .then(({ data }) => {
          if (data && data.deleteDataLookup) {
            removeDataField({ dataTypeId, dataField: field });
          }
        })
        .finally(() => {
          setLoading(false);
          onDeleteSuccess();
        });
    } else if (field.rollup) {
      deleteRollup({
        variables: {
          projectName,
          dataTypeId,
          rollupId: field.rollup.id,
        },
      })
        .then(({ data }) => {
          if (data && data.deleteDataRollup) {
            removeDataField({ dataTypeId, dataField: field });
          }
        })
        .finally(() => {
          setLoading(false);
          onDeleteSuccess();
        });
    } else {
      deleteField({
        variables: {
          projectName,
          dataTypeId,
          dataFieldId: field.id,
        },
      })
        .then(({ data }) => {
          if (data.deleteDataField) {
            removeDataField({ dataTypeId, dataField: field });
          }
        })
        .finally(() => {
          setLoading(false);
          onDeleteSuccess();
        });
    }
  };

  return (
    <Modal
      icon={<IconAlertTriangle size={18} />}
      title={getText('data.fields.delete.title')}
      confirmText={
        loading ? (
          <Loader type="Bars" size="sm" className="text-white" />
        ) : (
          getText('data.fields.delete.confirm')
        )
      }
      cancelText={getText('data.fields.delete.cancel')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirmDelete}
      confirmDisabled={confirmText !== field.display || loading}
      variant="danger"
    >
      <>
        <p className="mb-4">
          {getText(
            { fieldName: field.display },
            'data.fields.delete.disclaimer',
          )}
        </p>
        <p className="mb-4">
          {getText({ fieldName: field.display }, 'data.fields.delete.check')}
        </p>
        <span className="font-medium">
          {getText(
            { fieldName: field.display },
            'data.fields.delete.confirmName.label',
          )}
        </span>
        <TextInput
          className="my-3"
          placeholder={field.display}
          surface={LIGHT}
          onChange={({ target: { value } }: any) => setConfirmText(value)}
          value={confirmText}
        />
      </>
    </Modal>
  );
};

export default DeleteFieldModal;
