import createReactComponent from '../createReactComponent';
export default createReactComponent('source-code', 'IconSourceCode', [
  [
    'path',
    {
      d: 'M14.5 4h2.5a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-10a3 3 0 0 1 -3 -3v-5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 5l-2 2l2 2', key: 'svg-1' }],
  ['path', { d: 'M10 9l2 -2l-2 -2', key: 'svg-2' }],
]);
