import createReactComponent from '../createReactComponent';
export default createReactComponent('network-off', 'IconNetworkOff', [
  [
    'path',
    {
      d: 'M6.537 6.516a6 6 0 0 0 7.932 7.954m2.246 -1.76a6 6 0 0 0 -8.415 -8.433',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 3c1.333 .333 2 2.333 2 6c0 .348 0 .681 -.018 1m-.545 3.43c-.332 .89 -.811 1.414 -1.437 1.57',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M12 3c-.938 .234 -1.547 1.295 -1.825 3.182m-.156 3.837c.117 3.02 .777 4.68 1.981 4.981',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M6 9h3m4 0h5', key: 'svg-3' }],
  ['path', { d: 'M3 19h7', key: 'svg-4' }],
  ['path', { d: 'M14 19h5', key: 'svg-5' }],
  ['path', { d: 'M12 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-6' }],
  ['path', { d: 'M12 15v2', key: 'svg-7' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-8' }],
]);
