import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circuit-changeover',
  'IconCircuitChangeover',
  [
    ['path', { d: 'M2 12h2', key: 'svg-0' }],
    ['path', { d: 'M20 7h2', key: 'svg-1' }],
    ['path', { d: 'M6 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
    ['path', { d: 'M18 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
    ['path', { d: 'M20 17h2', key: 'svg-4' }],
    ['path', { d: 'M18 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-5' }],
    ['path', { d: 'M7.5 10.5l8.5 -3.5', key: 'svg-6' }],
  ],
);
