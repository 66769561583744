import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-google-fit', 'IconBrandGoogleFit', [
  [
    'path',
    {
      d: 'M12 9.314l-2.343 -2.344a3.314 3.314 0 0 0 -4.686 4.686l2.343 2.344l4.686 4.686l7.03 -7.03a3.314 3.314 0 0 0 -4.687 -4.685l-7.03 7.029',
      key: 'svg-0',
    },
  ],
]);
