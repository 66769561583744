import createReactComponent from '../createReactComponent';
export default createReactComponent('ad-off', 'IconAdOff', [
  [
    'path',
    {
      d: 'M9 5h10a2 2 0 0 1 2 2v10m-2 2h-14a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 15v-4a2 2 0 0 1 2 -2m2 2v4', key: 'svg-1' }],
  ['path', { d: 'M7 13h4', key: 'svg-2' }],
  ['path', { d: 'M17 9v4', key: 'svg-3' }],
  ['path', { d: 'M16.115 12.131c.33 .149 .595 .412 .747 .74', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
