import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'align-box-bottom-center',
  'IconAlignBoxBottomCenter',
  [
    [
      'path',
      {
        d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 15v2', key: 'svg-1' }],
    ['path', { d: 'M12 11v6', key: 'svg-2' }],
    ['path', { d: 'M15 13v4', key: 'svg-3' }],
  ],
);
