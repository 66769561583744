import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-off',
  'IconShoppingCartOff',
  [
    ['path', { d: 'M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M17 17a2 2 0 1 0 2 2', key: 'svg-1' }],
    ['path', { d: 'M17 17h-11v-11', key: 'svg-2' }],
    ['path', { d: 'M9.239 5.231l10.761 .769l-1 7h-2m-4 0h-7', key: 'svg-3' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
  ],
);
