import createReactComponent from '../createReactComponent';
export default createReactComponent('file-like', 'IconFileLike', [
  [
    'path',
    {
      d: 'M3 16m0 1a1 1 0 0 1 1 -1h1a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M6 20a1 1 0 0 0 1 1h3.756a1 1 0 0 0 .958 -.713l1.2 -3c.09 -.303 .133 -.63 -.056 -.884c-.188 -.254 -.542 -.403 -.858 -.403h-2v-2.467a1.1 1.1 0 0 0 -2.015 -.61l-1.985 3.077v4z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M5 12.1v-7.1a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-2.3',
      key: 'svg-3',
    },
  ],
]);
