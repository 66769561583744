import createReactComponent from '../createReactComponent';
export default createReactComponent('sum', 'IconSum', [
  [
    'path',
    {
      d: 'M18 16v2a1 1 0 0 1 -1 1h-11l6 -7l-6 -7h11a1 1 0 0 1 1 1v2',
      key: 'svg-0',
    },
  ],
]);
