import React, { useMemo } from 'react';
import get from 'lodash/get';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { FILE } from '../../../constants/builtInDataTypes';
import { YOUTUBE_VIDEO } from '../../../constants/elements';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { fieldPermissions } from '../../../utils/permissions';
import { isMultiField } from '../../../utils/relationships';
import Video from '../../Video';
import YoutubeVideo from '../../YoutubeVideo';
import RecordEmptyState from './RecordEmptyState';

const isYoutubeUrl = (url: any) =>
  url &&
  url.includes(
    'youtube.com' || url.includes('youtu.be') || /^[^"&?./\s]{11}$/.test(url),
  );

const getVideoValue = (
  type: any,
  fieldName: any,
  url: any,
  dataType: any,
  user: any,
  record: any,
  fieldPermissionsEnabled: any,
) => {
  if (type === 'url') {
    return url;
  }

  const field = dataType.fields.getByName(fieldName);
  if (!field) {
    return null;
  }

  const permissions = fieldPermissions(
    field,
    fieldPermissionsEnabled && dataType.permissionsEnabled,
    dataType.permissions,
    user,
  );

  if (!permissions.read) {
    return null;
  }

  if (field.type === FILE) {
    if (isMultiField(field)) {
      return get(record, [field.apiName, 'edges', 0, 'node', 'url']);
    }

    return get(record, [field.apiName, 'url']);
  }

  return get(record, [field.apiName]);
};

const RecordVideo = ({
  dataType,
  elementPath,
  editorMode,
  isSelected,
  field,
  type,
  record,
  project,
  recordScope,
  url,
}: any) => {
  const { user } = useAuthWrapper();
  const { videoId: parsedUrl } = useSectionScopeVariables(
    YOUTUBE_VIDEO,
    { videoId: url },
    project,
    elementPath,
    recordScope,
  );
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const videoValue = useMemo(
    () =>
      getVideoValue(
        type,
        field,
        parsedUrl,
        dataType,
        user,
        record,
        fieldPermissionsEnabled,
      ),
    [dataType, field, fieldPermissionsEnabled, record, type, parsedUrl, user],
  );

  const isYoutube = useMemo(
    () => videoValue && isYoutubeUrl(videoValue),
    [videoValue],
  );

  if (!videoValue && !editorMode) {
    return null;
  }

  if (!videoValue && editorMode) {
    return <RecordEmptyState />;
  }

  return (
    <div className="relative flex items-center justify-center rounded-lg border border-gray-200 bg-white shadow-md">
      {isYoutube && (
        <YoutubeVideo
          className="w-full overflow-hidden rounded-lg"
          videoId={videoValue}
          showControls={true}
        />
      )}
      {!isYoutube && (
        <Video
          className="w-full overflow-hidden rounded-lg"
          // @ts-expect-error TS(2322): Type '{ className: string; video: { src: any; }; c... Remove this comment to see the full error message
          video={{ src: videoValue }}
          controls={true}
        />
      )}
      {editorMode && !isSelected && (
        <div className="absolute inset-x-0 inset-y-0" />
      )}
    </div>
  );
};

RecordVideo.defaultProps = {};

export default RecordVideo;
