import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-big-down-lines',
  'IconArrowBigDownLines',
  [
    [
      'path',
      {
        d: 'M15 12h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-3h6v3z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M15 3h-6', key: 'svg-1' }],
    ['path', { d: 'M15 6h-6', key: 'svg-2' }],
  ],
);
