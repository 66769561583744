import React, { memo } from 'react';
import { FormField } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'data.api';

type Props = {
  baseUrl: string;
  disabled: boolean;
  display: string;
  isDisplayNameValid: boolean;
  onUpdateBaseUrl: (baseUrl: string) => void;
  onUpdateDisplay: (display: string) => void;
};

const ApiBasicsEditor = memo(
  ({
    baseUrl,
    disabled,
    display,
    isDisplayNameValid,
    onUpdateBaseUrl,
    onUpdateDisplay,
  }: Props) => (
    <div className="mb-2 mt-3 flex flex-col text-sm">
      <FormField
        className="mb-4 w-full"
        disabled={disabled}
        label={
          <span className="text-white">{getText(LANG_KEY, 'name.label')}</span>
        }
        errorType="below-solid"
        errorMessage={
          !display || isDisplayNameValid
            ? null
            : getText(LANG_KEY, 'name.error')
        }
        placeholder={getText(LANG_KEY, 'name.placeholder')}
        value={display}
        onChange={({ target: { value } }: any) => onUpdateDisplay(value)}
      />
      <FormField
        className="mb-4 w-full"
        disabled={disabled}
        label={
          <span className="text-white">
            {getText(LANG_KEY, 'baseUrl.label')}
          </span>
        }
        placeholder={getText(LANG_KEY, 'baseUrl.placeholder')}
        value={baseUrl}
        onChange={({ target: { value } }: any) => onUpdateBaseUrl(value)}
      />
    </div>
  ),
);

export default ApiBasicsEditor;
