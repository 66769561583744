import createReactComponent from '../createReactComponent';
export default createReactComponent('blur-off', 'IconBlurOff', [
  ['path', { d: 'M12 3v5m0 4v8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5.641 5.631a9 9 0 1 0 12.719 12.738m1.68 -2.318a9 9 0 0 0 -12.074 -12.098',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 12h5', key: 'svg-2' }],
  ['path', { d: 'M13 9h7', key: 'svg-3' }],
  ['path', { d: 'M12 6h6', key: 'svg-4' }],
  ['path', { d: 'M12 18h6', key: 'svg-5' }],
  ['path', { d: 'M12 15h3m4 0h1', key: 'svg-6' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
