import createReactComponent from '../createReactComponent';
export default createReactComponent('notification-off', 'IconNotificationOff', [
  [
    'path',
    {
      d: 'M6.154 6.187a2 2 0 0 0 -1.154 1.813v9a2 2 0 0 0 2 2h9a2 2 0 0 0 1.811 -1.151',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 7m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
