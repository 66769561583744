import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'barrier-block-off',
  'IconBarrierBlockOff',
  [
    [
      'path',
      {
        d: 'M11 7h8a1 1 0 0 1 1 1v7c0 .27 -.107 .516 -.282 .696',
        key: 'svg-0',
      },
    ],
    [
      'path',
      { d: 'M16 16h-11a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h2', key: 'svg-1' },
    ],
    ['path', { d: 'M7 16v4', key: 'svg-2' }],
    ['path', { d: 'M7.5 16l4.244 -4.244', key: 'svg-3' }],
    ['path', { d: 'M13.745 9.755l2.755 -2.755', key: 'svg-4' }],
    ['path', { d: 'M13.5 16l1.249 -1.249', key: 'svg-5' }],
    ['path', { d: 'M16.741 12.759l3.259 -3.259', key: 'svg-6' }],
    ['path', { d: 'M4 13.5l4.752 -4.752', key: 'svg-7' }],
    ['path', { d: 'M17 17v3', key: 'svg-8' }],
    ['path', { d: 'M5 20h4', key: 'svg-9' }],
    ['path', { d: 'M15 20h4', key: 'svg-10' }],
    ['path', { d: 'M17 7v-2', key: 'svg-11' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-12' }],
  ],
);
