import { useEffect } from 'react';
import { IS_PRODUCTION } from '../../constants/env';
import { trackPage } from '../analytics';

type PageType = 'collection view' | 'record view' | 'blank page' | 'new form';

export const PageTypes: Record<string, PageType> = {
  VIEW: 'collection view',
  RECORD: 'record view',
  BLANK: 'blank page',
  NEW: 'new form',
};

const useTrackAppPage = (
  pageType: PageType,
  properties?: Record<string, any>,
  enabled: boolean = true,
) => {
  useEffect(() => {
    if (window.analytics && IS_PRODUCTION && enabled) {
      trackPage('App', pageType, properties);
    }
  }, [enabled, pageType, properties]);
};

export default useTrackAppPage;
