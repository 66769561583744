import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'currency-rupee-nepalese',
  'IconCurrencyRupeeNepalese',
  [
    ['path', { d: 'M15 5h-11h3a4 4 0 1 1 0 8h-3l6 6', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M21 17l-4.586 -4.414a2 2 0 0 0 -2.828 2.828l.707 .707',
        key: 'svg-1',
      },
    ],
  ],
);
