import createReactComponent from '../createReactComponent';
export default createReactComponent('grid-dots', 'IconGridDots', [
  ['path', { d: 'M5 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M19 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-2' }],
  ['path', { d: 'M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-3' }],
  ['path', { d: 'M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-4' }],
  ['path', { d: 'M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-5' }],
  ['path', { d: 'M5 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-6' }],
  ['path', { d: 'M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-7' }],
  ['path', { d: 'M19 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-8' }],
]);
