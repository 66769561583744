import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'sort-ascending-letters',
  'IconSortAscendingLetters',
  [
    [
      'path',
      { d: 'M15 10v-5c0 -1.38 .62 -2 2 -2s2 .62 2 2v5m0 -3h-4', key: 'svg-0' },
    ],
    ['path', { d: 'M19 21h-4l4 -7h-4', key: 'svg-1' }],
    ['path', { d: 'M4 15l3 3l3 -3', key: 'svg-2' }],
    ['path', { d: 'M7 6v12', key: 'svg-3' }],
  ],
);
