import React, { useCallback, useEffect, useState } from 'react';
import { Portal, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { darkModeColors } from '../constants/darkModeColors';
import useDarkMode from '../utils/hooks/useDarkMode';

type RightPanelProps = {
  children: React.ReactNode;
  className?: string;
  fixed?: boolean;
  footer?: React.ReactNode;
  onClose: (event: React.MouseEventHandler<SVGSVGElement>) => void;
  rootSelector?: string;
  title: string | React.ReactNode;
  usePortal?: boolean;
};

const RightPanel = ({
  fixed = true,
  children,
  className = 'flex',
  footer,
  onClose,
  rootSelector = '#root',
  title,
  usePortal = false,
  ...rest
}: RightPanelProps) => {
  const [isDarkModeEnabled] = useDarkMode();
  const [show, setShow] = useState<boolean | null>(null);

  const handleClose = useCallback(
    (event) => {
      setShow(false);
      setTimeout(() => onClose(event), 250);
    },
    [onClose],
  );

  useEffect(() => {
    if (show === null) {
      setShow(true);
    }
  }, [show]);

  const sidebar = (
    <Transition
      show={!!show}
      enter="ease-out duration-300"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="ease-in duration-300"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
      className={classNames(
        className,
        'max-w-screen w-96 transform border-l transition sm:w-screen sm:border-0',
        'flex-grow flex-col overflow-hidden text-gray-800',
        isDarkModeEnabled ? darkModeColors.surfaces.elevation0 : 'bg-white',
        isDarkModeEnabled ? darkModeColors.borders.three : '',
        { 'fixed inset-y-0 right-0 z-50 shadow': fixed },
      )}
      {...rest}
    >
      <div
        className={`flex h-12 w-full items-center justify-between border-b px-2 shadow ${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation0} ${darkModeColors.borders.one} ${darkModeColors.text.secondary}`
            : 'bg-white text-gray-600'
        }`}
      >
        <span className="w-full text-base">{title}</span>
        <IconX
          size={16}
          onClick={handleClose}
          className="cursor-pointer hover:text-gray-500"
        />
      </div>
      <div
        className={`flex-grow overflow-hidden text-sm ${
          isDarkModeEnabled ? darkModeColors.surfaces.elevation0 : 'bg-gray-50'
        }`}
      >
        {children}
      </div>
      {footer}
    </Transition>
  );

  const root =
    typeof document !== 'undefined' && document.querySelector(rootSelector);

  return usePortal && root ? (
    <Portal>{ReactDOM.createPortal(sidebar, root)}</Portal>
  ) : (
    sidebar
  );
};

export default RightPanel;
