import createReactComponent from '../createReactComponent';
export default createReactComponent('list-numbers', 'IconListNumbers', [
  ['path', { d: 'M11 6h9', key: 'svg-0' }],
  ['path', { d: 'M11 12h9', key: 'svg-1' }],
  ['path', { d: 'M12 18h8', key: 'svg-2' }],
  [
    'path',
    { d: 'M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4', key: 'svg-3' },
  ],
  ['path', { d: 'M6 10v-6l-2 2', key: 'svg-4' }],
]);
