import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-camera-phone',
  'IconDeviceCameraPhone',
  [
    [
      'path',
      {
        d: 'M18.5 8.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M13 7h-8a2 2 0 0 0 -2 2v7a2 2 0 0 0 2 2h13a2 2 0 0 0 2 -2v-2',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M17 15v-1', key: 'svg-2' }],
  ],
);
