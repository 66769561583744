import createReactComponent from '../createReactComponent';
export default createReactComponent('sailboat-2', 'IconSailboat2', [
  [
    'path',
    {
      d: 'M2 20a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 18l-1 -3h18l-1 3', key: 'svg-1' }],
  ['path', { d: 'M12 11v4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M7 3c1.333 2.667 1.333 5.333 0 8h10c1.333 -2.667 1.333 -5.333 0 -8',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M6 3h12', key: 'svg-4' }],
]);
