import createReactComponent from '../createReactComponent';
export default createReactComponent('growth', 'IconGrowth', [
  [
    'path',
    {
      d: 'M16.5 15a4.5 4.5 0 0 0 -4.5 4.5m4.5 -8.5a4.5 4.5 0 0 0 -4.5 4.5m4.5 -8.5a4.5 4.5 0 0 0 -4.5 4.5m-4 3.5c2.21 0 4 2.015 4 4.5m-4 -8.5c2.21 0 4 2.015 4 4.5m-4 -8.5c2.21 0 4 2.015 4 4.5m0 -7.5v6',
      key: 'svg-0',
    },
  ],
]);
