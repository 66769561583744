import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { VIEW } from '@noloco/core/src/constants/elements';
import elementsConfig from '@noloco/core/src/elements/elementConfig';
import { selectedElementPathSelector } from '@noloco/core/src/selectors/elementsSelectors';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import ElementHighlight from './ElementHighlight';

const ElementHighlightWrapper = ({
  displayEditMode,
  editorMode,
}: {
  displayEditMode: boolean;
  editorMode: boolean;
}) => {
  const project = useSelector(projectDataSelector);
  const elements = project.elements;
  const selectedElementPath = useSelector(selectedElementPathSelector);
  const selectedPathString = selectedElementPath.join('.');
  const element = get(elements, selectedPathString);

  const elementConfig = element ? elementsConfig[element.type] : undefined;

  const shouldShow =
    displayEditMode &&
    editorMode &&
    elementConfig &&
    element &&
    element.type !== VIEW &&
    !elementConfig.hidden &&
    elementConfig.styleable;

  if (!shouldShow) {
    return null;
  }

  return (
    <ElementHighlight
      showSpacing={false}
      project={project}
      element={element}
      elementConfig={elementConfig}
      elementPath={selectedElementPath}
      selected={true}
    />
  );
};

export default ElementHighlightWrapper;
