import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { getSubscriptionCollectionQuery } from '../../../queries/project';
import useCacheQuery from '../../../utils/hooks/useCacheQuery';
import useRouter from '../../../utils/hooks/useRouter';
import SubscriptionTable from './SubscriptionTable';

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SubscriptionList.defaultProps;

// @ts-expect-error TS(7022): 'SubscriptionList' implicitly has type 'any' becau... Remove this comment to see the full error message
const SubscriptionList = ({
  isInternal,
  isStripeConnected,
  userId,
  emptyState,
  customerFields,
  customerField,
  onClick,
  title,
  subtitle,
  project,
}: Props) => {
  const {
    query: { customerId },
  } = useRouter();

  const subscriptionCollectionQuery = useMemo(
    () => getSubscriptionCollectionQuery(customerFields),
    [customerFields],
  );
  const { data, loading } = useCacheQuery(
    gql`
      ${subscriptionCollectionQuery}
    `,
    {
      skip: !userId,
      context: { projectQuery: true, projectName: project.name },
      variables:
        isInternal && customerId
          ? { where: { customerId: { equals: customerId } } }
          : null,
    },
  );

  const subscriptions = useMemo(
    () =>
      get(data, ['subscriptionCollection', 'edges'], []).map(
        (edge: any) => edge.node,
      ),
    [data],
  );

  return (
    <SubscriptionTable
      // @ts-expect-error TS(2322): Type '{ project: any; isInternal: any; isStripeCon... Remove this comment to see the full error message
      project={project}
      isInternal={isInternal}
      isStripeConnected={isStripeConnected}
      title={title}
      subtitle={subtitle}
      onClick={onClick}
      customerField={customerField}
      emptyState={emptyState}
      subscriptions={subscriptions}
      loading={loading}
    />
  );
};

SubscriptionList.defaultProps = {
  isStripeConnected: true,
};

export default SubscriptionList;
