import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '../../utils';
import SubmitButton from './SubmitButton';

type Props = {
  buttonText: React.ReactNode | string;
  forgotText: React.ReactNode | string;
};

// @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
const LoginSubmit = ({ buttonText, forgotText, theme }: Props) => {
  const primaryColor = theme.brandColors.primary;

  return (
    <>
      <div className="mt-4 flex items-center justify-between">
        <div className="w-full text-right text-xs leading-5">
          <a
            href="/forgot"
            className={classNames(
              'font-medium transition duration-150 ease-in-out focus:underline focus:outline-none',
              `text-${getColorShade(primaryColor, 500)}`,
              `hover:text-${getColorShade(primaryColor, 600)}`,
            )}
          >
            {forgotText}
          </a>
        </div>
      </div>
      <SubmitButton>{buttonText}</SubmitButton>
    </>
  );
};

export default withTheme(LoginSubmit);
