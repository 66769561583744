import createReactComponent from '../createReactComponent';
export default createReactComponent('exposure-0', 'IconExposure0', [
  [
    'path',
    {
      d: 'M12 19a4 4 0 0 0 4 -4v-6a4 4 0 1 0 -8 0v6a4 4 0 0 0 4 4z',
      key: 'svg-0',
    },
  ],
]);
