import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  IconEditCircle,
  IconGripHorizontal,
  IconGripVertical,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, getColorShade } from '@noloco/components';
import { XS } from '@noloco/components/src/constants/tShirtSizes';
import useWindowSize from '@noloco/components/src/utils/hooks/useWindowSize';
import ChangeUserPopover from '@noloco/core/src/components/ChangeUserPopover';
import { CMD, KEY_E } from '@noloco/core/src/constants/shortcuts';
import KeyboardShortcutTooltip from '@noloco/core/src/elements/sections/view/KeyboardShortcutTooltip';
import { setEditorMode } from '@noloco/core/src/reducers/elements';
import {
  dataTypesSelector,
  projectNameSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import useDarkMode from '@noloco/core/src/utils/hooks/useDarkMode';
import useLocalStorageState from '@noloco/core/src/utils/hooks/useLocalStorageState';
import useOnKeyPress from '@noloco/core/src/utils/hooks/useOnKeyPress';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import useToggleEditorMode from '../utils/hooks/useToggleEditorMode';

type BuildModeTriggerProps = {
  editorMode: boolean;
  isNavExpanded: boolean;
  primaryColor: string;
};

type Sides = 'top' | 'bottom' | 'left' | 'right';

type Translate = {
  x: number;
  y: number;
};

const ADMIN_NAVBAR_WIDTH = 64;
const BUTTON_HEIGHT = 40;
const WINDOW_HEIGHT = window.innerHeight;
const WINDOW_WIDTH = window.innerWidth;

const getClosestSide = (translate: Translate): Sides => {
  const distanceToTop = translate.y;
  const distanceToBottom = WINDOW_HEIGHT - translate.y;
  const distanceToLeft = translate.x;
  const distanceToRight = WINDOW_WIDTH - translate.x;

  const minDistance = Math.min(
    distanceToTop,
    distanceToBottom,
    distanceToLeft,
    distanceToRight,
  );

  if (minDistance === distanceToTop) {
    return 'top';
  } else if (minDistance === distanceToBottom) {
    return 'bottom';
  } else if (minDistance === distanceToLeft) {
    return 'left';
  }

  return 'right';
};

const getTriggerPosition = (closestSide: Sides, translate: Translate) => {
  switch (closestSide) {
    case 'top':
    case 'bottom':
      return {
        ...(closestSide === 'top' ? { top: 0 } : { bottom: 0 }),
        left: translate.x - 50,
        height: 4,
        width: 32,
      };

    case 'left':
    case 'right':
      return {
        top: translate.y,
        ...(closestSide === 'left' ? { left: 0 } : { right: 0 }),
        height: 32,
        width: 4,
      };
  }
};

const getTranslate = (
  closestSide: Sides,
  position: any,
  windowWidth: number,
  windowHeight: number,
) => {
  switch (closestSide) {
    case 'top':
    case 'bottom':
      return {
        x: position.left + 50,
        y: closestSide === 'top' ? 2 : windowHeight - 56,
      };

    case 'left':
    case 'right':
      return {
        x:
          closestSide === 'left'
            ? ADMIN_NAVBAR_WIDTH + 2
            : windowWidth - ADMIN_NAVBAR_WIDTH,
        y: position.top,
      };
  }
};

const BuildModeTrigger = ({
  editorMode,
  isNavExpanded,
  primaryColor,
}: BuildModeTriggerProps) => {
  const {
    query: { __buildMode = 'false' },
    replaceQueryParams,
  } = useRouter();
  const dispatch = useDispatch();
  const projectName = useSelector(projectNameSelector);
  const dataTypes = useSelector(dataTypesSelector);
  const [isDarkModeEnabled] = useDarkMode();
  const {
    width: windowWidth = WINDOW_WIDTH,
    height: windowHeight = WINDOW_HEIGHT,
  } = useWindowSize();
  const [position, setPosition] = useLocalStorageState(
    'noloco:buildModeTrigger:position',
    {
      x: isNavExpanded ? 400 : 220,
      y: windowHeight - 56,
    },
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [triggerPosition, setTriggerPosition] = useState({});
  const [translate, setTranslate] = useState<Translate>({
    x: position.x,
    y: position.y,
  });

  const translateRef = useRef(translate);
  const [initialMousePosition, setInitialMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const closestSide = useMemo(() => getClosestSide(translate), [translate]);
  const tooltipPlacement = useMemo(() => {
    switch (closestSide) {
      case 'bottom':
      case 'top':
        return 'right';

      case 'left':
      case 'right':
        return 'top';
    }
  }, [closestSide]);
  const { isLoading, toggleEditorMode } = useToggleEditorMode(editorMode);

  const textColor = `text-${getColorShade(
    primaryColor,
    isDarkModeEnabled ? 100 : 800,
  )}`;

  const backgroundColor = `bg-${getColorShade(
    primaryColor,
    isDarkModeEnabled ? 800 : 100,
  )}`;

  const hoverBackgroundColor = `hover:bg-${getColorShade(
    primaryColor,
    isDarkModeEnabled ? 700 : 200,
  )}`;

  const borderColor = `border-${getColorShade(
    primaryColor,
    isDarkModeEnabled ? 400 : 300,
  )}`;

  const onMouseDown = useCallback(({ clientX, clientY }) => {
    setInitialMousePosition({ x: clientX, y: clientY });
    setIsDragging(true);
  }, []);

  const onMouseMove = useCallback(
    ({ clientX, clientY }) => {
      const x = translateRef.current.x + clientX - initialMousePosition.x;
      const y = translateRef.current.y + clientY - initialMousePosition.y;

      const newTranslate = {
        x:
          x >= ADMIN_NAVBAR_WIDTH
            ? x >= windowWidth
              ? windowWidth
              : x
            : ADMIN_NAVBAR_WIDTH,
        y:
          y >= 0
            ? y + BUTTON_HEIGHT >= windowHeight
              ? windowHeight - BUTTON_HEIGHT
              : y
            : 0,
      };

      setTranslate(newTranslate);
      setPosition(newTranslate);
      setTriggerPosition(
        getTriggerPosition(getClosestSide(newTranslate), newTranslate),
      );
    },
    [
      initialMousePosition,
      setPosition,
      translateRef,
      windowHeight,
      windowWidth,
    ],
  );

  const onMouseUp = useCallback(() => setIsDragging(false), []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', onMouseMove);
    } else {
      setPosition(
        getTranslate(
          closestSide,
          getTriggerPosition(closestSide, translate),
          windowWidth,
          windowHeight,
        ),
      );
      translateRef.current = translate;
    }

    return () => window.removeEventListener('mousemove', onMouseMove);
  }, [
    closestSide,
    isDragging,
    onMouseMove,
    setPosition,
    translate,
    translateRef,
    windowHeight,
    windowWidth,
  ]);

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);

    return () => window.removeEventListener('mouseup', onMouseUp);
  }, [onMouseUp]);

  useEffect(() => {
    if (__buildMode === 'true' && !editorMode) {
      dispatch(setEditorMode(true));
      replaceQueryParams({ __buildMode: undefined });
    }
  }, [__buildMode, editorMode, dispatch, replaceQueryParams]);

  const shortcutToggleEditorMode = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      toggleEditorMode();
    },
    [toggleEditorMode],
  );

  useOnKeyPress('e', shortcutToggleEditorMode, {
    ctrlKey: true,
    enabled: !isLoading && !isDragging,
  });

  return (
    <>
      {isDragging && (
        <>
          <div className="absolute left-0 top-0 z-40 h-full w-full border-4 border-gray-200 bg-transparent" />
          <div
            className={classNames(
              'absolute z-50 rounded-full border',
              `bg-${getColorShade(primaryColor, 800)}`,
              borderColor,
            )}
            style={triggerPosition}
          />
        </>
      )}
      <div
        className={classNames(
          'group fixed left-0 top-0 z-50 flex items-center justify-center',
          {
            dark: isDarkModeEnabled,
            'cursor-move': isDragging,
            'flex-col': closestSide === 'top',
            'flex-col-reverse': closestSide === 'bottom',
            'flex-row-reverse': closestSide === 'right',
            'hover:-ml-20--': !isDragging && closestSide === 'right',
          },
        )}
        style={{ transform: `translate(${position.x}px,${position.y}px)` }}
      >
        <div
          className={classNames('flex cursor-move items-center justify-center')}
          onMouseDown={onMouseDown}
        >
          {closestSide === 'top' || closestSide === 'bottom' ? (
            <IconGripHorizontal className={textColor} size={16} />
          ) : (
            <IconGripVertical className={textColor} size={16} />
          )}
        </div>
        <div
          className={classNames(
            'flex h-10 select-none items-center justify-center rounded-full border shadow-2xl drop-shadow-2xl',
            { 'animate-pulse': isDragging },
            backgroundColor,
            textColor,
            hoverBackgroundColor,
            borderColor,
          )}
        >
          <KeyboardShortcutTooltip
            buildMode={false}
            delayShow={400}
            disabled={editorMode || isLoading || isDragging}
            keys={[CMD, KEY_E]}
            label={getText('leftSidebar.auth.edit')}
            placement={tooltipPlacement}
          >
            <div
              className={classNames(
                'flex h-full w-10 cursor-pointer items-center justify-center rounded-full opacity-75 hover:opacity-100',
                { 'cursor-not-allowed': isLoading },
              )}
              onClick={toggleEditorMode}
            >
              {isLoading ? <Loader size={XS} /> : <IconEditCircle size={16} />}
            </div>
          </KeyboardShortcutTooltip>
          <div
            className={classNames('absolute items-center justify-center', {
              'hidden group-hover:flex': !isDragging && !isLoading && !isOpen,
              flex: isOpen,
              hidden: isLoading || isDragging,
              'pr-2': closestSide === 'right',
              'pl-2': closestSide === 'left',
              'pt-2': closestSide === 'top',
              'pb-2': closestSide === 'bottom',
              'space-x-2': closestSide === 'left' || closestSide === 'right',
              'space-y-2': closestSide === 'top' || closestSide === 'bottom',
            })}
            style={{
              [closestSide]: '100%',
            }}
          >
            <div className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white shadow dark:bg-slate-700">
              <ChangeUserPopover
                dataTypes={dataTypes}
                isOpen={isOpen}
                onOpenChange={setIsOpen}
                placement={tooltipPlacement}
                projectName={projectName}
                showArrow={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildModeTrigger;
