import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Project } from '@noloco/core/src/models/Project';
import { setBillingPlan } from '@noloco/core/src/reducers/billingPlan';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { BILLING_PLAN_QUERY } from '../../queries/project';

const useBillingPlanUsage = (project: Project) => {
  const dispatch = useDispatch();
  const billingPlan = useSelector(billingPlanSelector);

  const { data: billingPlanData } = useQuery(BILLING_PLAN_QUERY, {
    context: {
      projectQuery: true,
      authQuery: true,
      projectName: project.name,
    },
    skip: !!billingPlan?.usage,
    ssr: false,
  });

  useEffect(() => {
    if (billingPlanData && billingPlanData.billingPlan && !billingPlan?.usage) {
      dispatch(setBillingPlan(billingPlanData.billingPlan));
    }
  }, [billingPlan, billingPlanData, dispatch]);

  return billingPlan?.usage;
};

export default useBillingPlanUsage;
