import createReactComponent from '../createReactComponent';
export default createReactComponent('bell-code', 'IconBellCode', [
  [
    'path',
    {
      d: 'M11.5 17h-7.5a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6a2 2 0 1 1 4 0a7 7 0 0 1 4 6v2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 17v1a3 3 0 0 0 2.498 2.958', key: 'svg-1' }],
  ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-2' }],
  ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-3' }],
]);
