import { getImageSrc } from '../../../utils/image';
import { Project } from '../../Project';
import ComboType from '../ComboPropType';
import StringType from '../StringPropType';

const placeholder = 'https://media.noloco.app/65-placeholder.svg';

const imageResolver = (
  comboProps: Record<any, any>,
  project: Project,
  __1: any,
  editorMode: boolean,
) => {
  const { type, src, mediaId } = comboProps;

  const placeholderImg =
    editorMode && src !== undefined ? placeholder : undefined;

  return {
    src: getImageSrc(
      type,
      src,
      mediaId,
      project.media,
      editorMode,
      placeholderImg as string,
    ),
  };
};

class ImagePropType extends ComboType {
  constructor(propMapper?: any, includeSelf = false) {
    super(
      {
        src: new StringType(includeSelf),
      },
      imageResolver,
      propMapper,
    );
    this.includeSelf = includeSelf;
  }
}

export default ImagePropType;
