import React, { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { useSelector } from 'react-redux';
import { Theme } from '@noloco/components/src';
import { LINE, STACKED_BAR } from '../../../constants/chartTypes';
import { CHARTS } from '../../../constants/collectionLayouts';
import { WEEK } from '../../../constants/timePeriods';
import DataTypes, { DataType } from '../../../models/DataTypes';
import { Element } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { RecordEdge } from '../../../models/Record';
import { GroupBy, GroupByWithField } from '../../../models/View';
import { editorModeSelector } from '../../../selectors/elementsSelectors';
import { isMetricChart } from '../../../utils/charts';
import useDraggableCollectionGroups from '../../../utils/hooks/useDraggableCollectionGroups';
import {
  ChartData,
  metricData,
  sampleData,
  sampleXMetricValue,
  sampleXValue,
  sampleYValue,
} from '../../Chart';
import { getDataFieldPropAsDep } from '../../ViewCollection';

type CollectionChartProps = {
  chart: any;
  data: RecordEdge[];
  dataType: DataType;
  dataTypes: DataTypes;
  element: Element;
  EmptyState: () => JSX.Element;
  project: Project;
  scope: Record<string, any>;
  theme: Theme;
  edges: RecordEdge[];
  nodeQueryObject: any;
};

const CollectionChart = ({
  chart,
  data,
  dataType,
  dataTypes,
  element,
  EmptyState,
  project,
  scope,
  theme,
  edges,
  nodeQueryObject,
}: CollectionChartProps) => {
  const groupByGroups: GroupBy[] = useMemo(() => {
    if (chart?.chartType === STACKED_BAR) {
      const { xAxisValue, groups, series } = chart;

      if (xAxisValue && groups && series) {
        return groups.slice(0, 1);
      }
    }
    return [];
  }, [chart]);

  const groupByFields: GroupByWithField[] = useMemo(() => {
    if (
      chart?.chartType === STACKED_BAR &&
      chart.groups &&
      chart.groups[0].field
    ) {
      return groupByGroups
        .map((group) => {
          const { dependencyField } = getDataFieldPropAsDep(
            group.field,
            dataType,
            project.dataTypes,
            element.type,
          );

          return {
            ...group,
            dataField: dependencyField,
          };
        })
        .filter((group) => group.dataField) as GroupByWithField[];
    }
    return [];
  }, [chart, dataType, element.type, groupByGroups, project.dataTypes]);

  const { visibleGroups } = useDraggableCollectionGroups({
    customFilters: [],
    dataType,
    edges,
    elementId: element.id,
    fields: [],
    groupByFields: chart?.chartType === STACKED_BAR ? groupByFields : [],
    groupOptions: {},
    hideEmptyGroups: true,
    layout: CHARTS,
    limitPerGroup: undefined,
    enableDragAndDropEdit: false,
    nodeQueryObject,
  });

  const stackedGroups =
    chart?.chartType === STACKED_BAR ? visibleGroups : undefined;

  const editorMode = useSelector(editorModeSelector);
  const series = chart.series || [];
  const metricChart = isMetricChart(chart.chartType);

  if (
    !chart.xAxisValue ||
    (!metricChart && (series.length === 0 || !series[0].yAxisValue))
  ) {
    if (!editorMode) {
      return null;
    }

    return (
      <ChartData
        // @ts-expect-error TS(2322): Type '{ aggregation: any; chartType: any; timePeri... Remove this comment to see the full error message
        aggregation={chart.aggregation}
        chartId={chart.id}
        chartType={chart.chartType || LINE}
        dataTypes={dataTypes}
        id="SAMPLE"
        nodes={metricChart ? metricData : sampleData}
        palette={chart.palette}
        series={[{ id: 'sample', yAxisValue: sampleYValue }]}
        theme={theme}
        timePeriod={chart.timePeriod || WEEK}
        useOptionColors={chart.useOptionColors}
        xAxisValue={metricChart ? sampleXMetricValue : sampleXValue}
      />
    );
  }

  return (
    <ChartData
      // @ts-expect-error TS(2322): Type '{ aggregation: any; chartType: any; timePeri... Remove this comment to see the full error message
      aggregation={chart.aggregation}
      chartId={chart.id}
      chartType={chart.chartType}
      dataType={dataType}
      dataTypes={dataTypes}
      element={element}
      EmptyState={EmptyState}
      id={`chart-${chart.id}`}
      max={chart.max}
      nodes={data}
      palette={chart.palette}
      project={project}
      scope={scope}
      series={series}
      theme={theme}
      timePeriod={chart.timePeriod}
      useOptionColors={chart.useOptionColors}
      xAxisLabel={chart.xAxisLabel}
      xAxisValue={chart.xAxisValue}
      yAxisLabel={chart.yAxisLabel}
      stackedGroups={stackedGroups}
    />
  );
};

export default withTheme(CollectionChart);
