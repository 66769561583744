import createReactComponent from '../createReactComponent';
export default createReactComponent('device-cctv', 'IconDeviceCctv', [
  [
    'path',
    {
      d: 'M3 3m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 14m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-1' }],
  ['path', { d: 'M19 7v7a7 7 0 0 1 -14 0v-7', key: 'svg-2' }],
  ['path', { d: 'M12 14l.01 0', key: 'svg-3' }],
]);
