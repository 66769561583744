import React, { forwardRef } from 'react';
import classNames from 'classnames';

const getIframeProps = (source: any) => {
  if (!source) {
    return {
      src: '',
    };
  }

  const iframe = source.match(/<iframe([^>]*)>/);

  if (!iframe) {
    return { src: source }; // No iframe found
  }

  const iframeTag = iframe[0];
  const propRegex = /\s(\w+)(?:=["']([^"']*)["'])?/g;

  return (
    Array.from(iframeTag.matchAll(propRegex)).reduce((acc: any, match: any) => {
      const propName = match[1];
      const propValue = match[2] === undefined ? 'true' : match[2]; // Use true if the value is not explicitly set
      acc[propName] = propValue;

      return acc;
    }, {}) || {}
  );
};

type IframeProps = {
  className?: string;
  source?: string;
  title?: string;
};

const Iframe = forwardRef<any, IframeProps>(
  (
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Ifra... Remove this comment to see the full error message
    { className, isSelected, editorMode, fullScreen, source, title, onClick },
    ref,
  ) => (
    <div
      className={classNames(className, {
        relative: !fullScreen,
        'absolute bottom-0 left-0 right-0 top-0 bg-gray-50': fullScreen,
        'w-full max-w-full': !fullScreen,
      })}
      ref={ref}
    >
      {!!source && (
        <iframe
          {...getIframeProps(source)}
          height="100%"
          style={{}}
          title={title}
          width="100%"
        />
      )}
      {!isSelected && editorMode && (
        <div
          className="absolute bottom-0 left-0 right-0 top-0 h-full w-full"
          onClick={onClick}
        />
      )}
    </div>
  ),
);

Iframe.defaultProps = {
  className: '',
  source: 'https://example.com/',
};

export default Iframe;
