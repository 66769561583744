import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-hbo', 'IconBrandHbo', [
  ['path', { d: 'M2 16v-8', key: 'svg-0' }],
  ['path', { d: 'M6 8v8', key: 'svg-1' }],
  ['path', { d: 'M2 12h4', key: 'svg-2' }],
  [
    'path',
    { d: 'M9 16h2a2 2 0 1 0 0 -4h-2h2a2 2 0 1 0 0 -4h-2v8z', key: 'svg-3' },
  ],
  ['path', { d: 'M19 8a4 4 0 1 1 0 8a4 4 0 0 1 0 -8z', key: 'svg-4' }],
  ['path', { d: 'M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-5' }],
]);
