import createReactComponent from '../createReactComponent';
export default createReactComponent('lock-dollar', 'IconLockDollar', [
  [
    'path',
    { d: 'M13 21h-6a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h10', key: 'svg-0' },
  ],
  ['path', { d: 'M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M8 11v-4a4 4 0 1 1 8 0v4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-4' }],
]);
