import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconExclamationCircle, IconFileImport } from '@tabler/icons-react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import Papa from 'papaparse';
import {
  AutoSizedTextInput,
  ErrorText,
  Modal,
  Tooltip,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { XL } from '@noloco/components/src/constants/tShirtSizes';
import Dropzone from '@noloco/core/src/components/dropzone/Dropzone';
import { DataSchemaField } from '@noloco/core/src/constants/dataSchema';
import { DOCUMENT } from '@noloco/core/src/constants/fileTypes';
import { TEXT_CSV } from '@noloco/core/src/constants/mimetypes';
import DropzonePreview from '@noloco/core/src/elements/sections/forms/DropzonePreview';
import DataTypes, {
  DataType,
  DataTypeArray,
} from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { buildFields } from '@noloco/core/src/utils/dataSchema';
import { useErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import useSetDocumentTitle from '@noloco/core/src/utils/hooks/useSetDocumentTitle';
import { getText } from '@noloco/core/src/utils/lang';
import { ADD_DATA_TYPE } from '../../queries/project';
import { useAddElements } from '../../utils/hooks/useAddElements';
import { useBuildDataTypeLayout } from '../../utils/hooks/useBuildDataTypeLayout';
import { useImportCsvFile } from '../../utils/hooks/useImportCsvFile';
import useIsNameUnique from '../../utils/hooks/useIsNameUnique';
import { useAddDataType } from '../../utils/hooks/useUpdateDataTypes';
import Guide from '../Guide';
import DataSourceImportBuildLayouts from './DataSourceImportBuildLayouts';
import DataSourceImportStatusSection from './DataSourceImportStatusSection';
import DataSchemaEditor from './dataSchema/DataSchemaEditor';

const LANG_KEY = 'data.csv';
const SHARED_LANG_KEY = 'data.dataSources.connect';

type Props = {
  inOnboarding?: boolean;
  onCancel: (...args: any[]) => void;
  onConfirm?: (...args: any[]) => void;
  project: Project;
};

const AddCsv = ({
  inOnboarding = false,
  onCancel,
  onConfirm,
  project,
}: Props) => {
  const addDataType = useAddDataType();
  const errorAlert = useErrorAlert();
  const { push } = useRouter();
  const [step, setStep] = useState(0);

  const [parsingFile, setParsingFile] = useState(false);

  const [file, setFile] = useState(null);
  const [results, setResults] = useState<any>(null);

  const [display, setDisplay] = useState('My Imported CSV');
  const [fields, setFields] = useState<DataSchemaField[] | null>(null);

  const [createDataType] = useMutation(ADD_DATA_TYPE);

  const [creatingDataType, setCreatingDataType] = useState(false);
  const [dataType, setDataType] = useState<DataType | null>(null);

  const [importing, setImporting] = useState(false);
  const importCsvFile = useImportCsvFile(project);

  const [view, setView] = useState<any>(null);
  const buildDataTypeLayout = useBuildDataTypeLayout(() => setStep(4));
  const addElements = useAddElements(project, inOnboarding);

  const redirectToNewView = useCallback(() => {
    if (!view) {
      push('/_/data/internal/');
    }

    push(`/${get(view, 'props.routePath')}`);
  }, [push, view]);

  useSetDocumentTitle(getText(LANG_KEY, 'title'));

  const isDisplayValid = useIsNameUnique(display, project.dataTypes);
  const displayError = useMemo(
    () =>
      !display || isDisplayValid || step > 0
        ? null
        : getText(LANG_KEY, 'display.invalid'),
    [display, isDisplayValid, step],
  );

  const onDropFile = useCallback(([newFile]) => {
    setFile(newFile);
    setParsingFile(true);
    Papa.parse(newFile, {
      dynamicTyping: true,
      header: true,
      skipEmptyLines: true,
      error: (err) => {
        console.log('ERROR', err);
        setParsingFile(false);
      },
      complete: (results: any) => {
        setParsingFile(false);
        setResults(results);
        setFields(buildFields([], results.data, null, false, false));
      },
    });
  }, []);

  const onImport = useCallback(async () => {
    if (!display || !fields || step > 0) {
      return;
    }
    setStep(1);

    try {
      setCreatingDataType(true);
      const { data } = await createDataType({
        variables: {
          display,
          fields: fields.map((field) => ({
            display: field.display,
            type: field.type,
            options: field.options,
            relationship: field.relationship,
          })),
          projectName: project.name,
        },
      });

      addDataType(data.addDataType);
      setDataType(data.addDataType);
      setCreatingDataType(false);
      setStep(2);

      setImporting(true);
      await importCsvFile(data.addDataType, fields, file);
      setStep(3);
      setImporting(false);

      const newView = await buildDataTypeLayout(
        set(
          'dataTypes',
          new DataTypes([...project.dataTypes, data.addDataType!]),
          project,
        ),
        DataTypeArray.formatDataType(data.addDataType),
      );
      setView(newView);
      addElements([newView]);
      setStep(4);
    } catch (e) {
      setCreatingDataType(false);
      setImporting(false);

      setDataType(null);
      setView(null);

      setStep(0);
      console.error(e);
      errorAlert(getText(LANG_KEY, 'error'));
    }
  }, [
    addDataType,
    addElements,
    buildDataTypeLayout,
    createDataType,
    display,
    errorAlert,
    fields,
    file,
    importCsvFile,
    project,
    step,
  ]);

  const confirmDisabled = useMemo(() => {
    if (!displayError && results && step === 0) {
      return false;
    }

    if (step >= 4) {
      return false;
    }

    return true;
  }, [displayError, results, step]);

  return (
    <Modal
      canCancel={!inOnboarding}
      canConfirm={true}
      closeOnOutsideClick={!inOnboarding}
      confirmDisabled={confirmDisabled}
      confirmText={
        step < 4
          ? getText(LANG_KEY, 'confirm')
          : getText(SHARED_LANG_KEY, 'finish')
      }
      icon={<IconFileImport size={18} />}
      onCancel={onCancel}
      onClose={onCancel}
      onConfirm={step < 4 ? onImport : (onConfirm ?? redirectToNewView)}
      size={XL}
      title={
        <div className="flex">
          <AutoSizedTextInput
            disabled={step > 0}
            onChange={({ target: { value } }: any) => setDisplay(value)}
            placeholder={getText(LANG_KEY, 'display.placeholder')}
            readOnly={step > 0}
            value={display}
          />
          {displayError && (
            <Tooltip content={displayError}>
              <IconExclamationCircle
                className="my-auto text-red-600"
                size={20}
              />
            </Tooltip>
          )}
        </div>
      }
    >
      <div className="flex h-96 w-full flex-col overflow-y-auto">
        {displayError && <ErrorText className="mb-8">{displayError}</ErrorText>}
        <div className="mb-8 space-y-4">
          <Guide href="https://guides.noloco.io/data/collections/import-a-file">
            {getText(LANG_KEY, 'help')}
          </Guide>
        </div>
        {!results && step === 0 && (
          <div className="flex h-full flex-col">
            <div className="text-gray-600">
              {getText(LANG_KEY, 'file.help')}
            </div>
            <div className="mt-auto">
              <Dropzone
                acceptedMimetypes={[TEXT_CSV]}
                className="h-64"
                disabled={step > 0}
                maxFiles={1}
                onChange={onDropFile}
                p={4}
                surface={LIGHT}
              >
                <DropzonePreview
                  disabled={step > 0}
                  fileType={DOCUMENT}
                  loading={parsingFile}
                  mediaItem={null}
                  onRemove={() => null}
                  placeholder={getText(LANG_KEY, 'file.placeholder')}
                  showIcon={true}
                />
              </Dropzone>
            </div>
          </div>
        )}
        {results && step === 0 && (
          <div className="flex h-full flex-col">
            <div className="text-gray-600">
              {getText(LANG_KEY, 'schema.help')}
            </div>
            <div className="mt-auto h-64 p-2">
              <DataSchemaEditor
                className="rounded-lg border border-gray-300"
                fields={fields}
                idField={null}
                includeDefault={false}
                includeEmpty={false}
                onUpdateFields={setFields}
                records={results.data}
                surface={LIGHT}
              />
            </div>
          </div>
        )}
        {step > 0 && (
          <div className="mx-auto mb-8 flex w-full max-w-2xl flex-col space-y-4 overflow-y-auto px-4">
            <DataSourceImportStatusSection
              loading={creatingDataType}
              title={getText(LANG_KEY, 'dataType.label')}
              subtitle={getText(LANG_KEY, 'dataType.help')}
              surface={LIGHT}
            />
            {dataType && fields && step > 1 && (
              <DataSourceImportStatusSection
                loading={importing}
                title={getText(LANG_KEY, 'import.label')}
                subtitle={getText(LANG_KEY, 'import.help')}
                surface={LIGHT}
              />
            )}
            {dataType && step > 2 && (
              <DataSourceImportBuildLayouts
                builtPages={step === 4 ? [dataType] : []}
                inProgressPages={[dataType]}
                loading={step < 4}
                skippedPages={[]}
                surface={LIGHT}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddCsv;
