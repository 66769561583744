import createReactComponent from '../createReactComponent';
export default createReactComponent('vector-bezier', 'IconVectorBezier', [
  [
    'path',
    {
      d: 'M3 14m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 14m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M10 6m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M10 8.5a6 6 0 0 0 -5 5.5', key: 'svg-3' }],
  ['path', { d: 'M14 8.5a6 6 0 0 1 5 5.5', key: 'svg-4' }],
  ['path', { d: 'M10 8l-6 0', key: 'svg-5' }],
  ['path', { d: 'M20 8l-6 0', key: 'svg-6' }],
  ['path', { d: 'M3 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-7' }],
  ['path', { d: 'M21 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-8' }],
]);
