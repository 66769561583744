import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import gql from 'graphql-tag';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { useDispatch, useSelector } from 'react-redux';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import useBreakpoints, {
  breakpoints,
} from '@noloco/components/src/utils/hooks/useBreakpoints';
import useWindowSize from '@noloco/components/src/utils/hooks/useWindowSize';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { FILE } from '../../../constants/builtInDataTypes';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import { FIELD } from '../../../constants/imageTypes';
import { DataType } from '../../../models/DataTypes';
import {
  ActionButton,
  Element,
  ElementPath,
  ViewTab,
} from '../../../models/Element';
import { Project } from '../../../models/Project';
import { User } from '../../../models/User';
import { getPageQueryString } from '../../../queries/project';
import { setSidebarRecordViewTitle } from '../../../reducers/elements';
import { sidebarRecordViewTitleSelector } from '../../../selectors/elementsSelectors';
import {
  getDepsForField,
  transformDepsToQueryObject,
} from '../../../utils/data';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import useRouter from '../../../utils/hooks/useRouter';
import { useInvalidateProjectData } from '../../../utils/hooks/useServerEvents';
import useSetQuery from '../../../utils/hooks/useSetQuery';
import useTrackAppPage, {
  PageTypes,
} from '../../../utils/hooks/useTrackAppPage';
import { getText } from '../../../utils/lang';
import { PermissionConfig } from '../../../utils/permissions';
import { getDepsForViewSections } from '../../../utils/sectionDependencies';
import { filterSections } from '../../../utils/tabs';
import { getPageLoadingScope, getPageScope } from '../../Page';
import RecordComments from './RecordComments';
import RecordViewBody from './RecordViewBody';
import { getTitleValue } from './RecordViewTitle';

type RecordViewProps = {
  actionButtons?: ActionButton[];
  className?: string;
  comments?: any;
  dataType: DataType;
  doneButtonText?: any;
  editButton?: any;
  editButtonText?: any;
  editorMode?: boolean;
  element: Element;
  elementPath: ElementPath;
  headerWidth?: number | string;
  hideBreadcrumbs?: boolean;
  hideEditButton?: boolean;
  icon?: any;
  image?: any;
  isSplitLayout?: boolean;
  name?: string;
  onClick?: () => void;
  permissions: PermissionConfig;
  project: Project;
  recordId?: string;
  rootPathname: string | null;
  scope: any;
  sections?: any[];
  subtitle?: any;
  tabs?: ViewTab[];
  title?: any;
};

const SCROLL_THRESHOLD = 200;

const RecordView = ({
  actionButtons = [],
  comments = {},
  className,
  dataType,
  editorMode = false,
  element,
  elementPath,
  headerWidth,
  hideBreadcrumbs,
  hideEditButton,
  doneButtonText,
  editButton = {},
  editButtonText,
  icon,
  image,
  isSplitLayout = false,
  name,
  onClick,
  permissions,
  project,
  recordId,
  rootPathname,
  sections = [],
  scope,
  tabs = [],
  title,
  subtitle,
}: RecordViewProps) => {
  const { sm: isSmScreen } = useBreakpoints();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const recordViewTitle = useSelector(sidebarRecordViewTitleSelector);
  const [showRecordTitle, setShowRecordTitle] = useState(false);
  const { user } = useAuthWrapper();
  const {
    query: { recordId: queryRecordId, tab, _comments },
    replaceQueryParams,
  } = useRouter();

  const selectedTab = useMemo(() => {
    const defaultTab = first(tabs);
    const activeTab = tabs && tabs.find((t: any) => kebabCase(t.title) === tab);
    if (activeTab === defaultTab) {
      return null;
    }

    return activeTab;
  }, [tab, tabs]);

  useTrackAppPage(PageTypes.RECORD);

  const commentsAreOpen = _comments === 'true';

  useEffect(() => {
    if (
      width &&
      width > breakpoints[SM] &&
      _comments === undefined &&
      comments.openByDefault &&
      comments.show
    ) {
      replaceQueryParams({ _comments: 'true' });
    }
  }, [width, _comments, comments, replaceQueryParams]);

  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);

  const dependencies = useMemo(() => {
    const tabSections = filterSections(tabs, tabs, sections, selectedTab);
    const mappedSections = tabSections.map(
      (tabSection: any) => tabSection.section,
    );

    const deps = getDepsForViewSections(
      element,
      mappedSections,
      project.dataTypes,
      dataType,
      user as User,
      actionButtons,
      tabs,
      title,
      fieldPermissionsEnabled,
    );

    if (image && image.type === FIELD && image.fieldName) {
      const fileField = dataType.fields.getByName(image.fieldName);

      if (fileField && fileField.type === FILE) {
        const fileFieldDeps = getDepsForField(
          fileField,
          project.dataTypes,
          element.id,
        );
        deps.push(...fileFieldDeps);
      }
    }

    deps.push({
      id: `${element.id}:VIEW`,
      path: 'nolocoCommentsCollection.totalCount',
    });

    return deps.filter((dep: any) => dep.path);
  }, [
    actionButtons,
    dataType,
    element,
    fieldPermissionsEnabled,
    image,
    project.dataTypes,
    sections,
    selectedTab,
    tabs,
    title,
    user,
  ]);

  const pageId = `${element.id}:VIEW`;
  const elementWithUpdatedId = useMemo(
    () => set('id', pageId, element),
    [element, pageId],
  );

  const rawValue = recordId || queryRecordId;
  const variables = useMemo(
    () => ({
      uuid: rawValue,
    }),
    [rawValue],
  );

  const gqlQueryString = useMemo(
    () =>
      getPageQueryString(
        dataType.apiName,
        { uuid: rawValue },
        transformDepsToQueryObject(dataType, project.dataTypes, dependencies),
      ),
    [dataType, dependencies, project.dataTypes, rawValue],
  );

  const queryObject = useMemo(
    () => ({
      id: elementWithUpdatedId.id,
      variables,
      dataType: dataType.apiName,
      dataProperty: 'uuid',
      type: elementWithUpdatedId.type,
      query: gqlQueryString,
    }),
    [
      dataType.apiName,
      elementWithUpdatedId.id,
      elementWithUpdatedId.type,
      gqlQueryString,
      variables,
    ],
  );
  useSetQuery(queryObject);

  const { loading, error, data, refetch } = useQuery(
    gql`
      ${gqlQueryString}
    `,
    {
      variables,
      errorPolicy: 'all',
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    },
  );
  useInvalidateProjectData(refetch);

  const pageData = useMemo(
    () => get(data, dataType.apiName),
    [data, dataType.apiName],
  );

  if (error) {
    console.log(error);
  }

  const pageScope = useMemo(
    () =>
      pageData && getPageScope(false, pageData, dataType, project.dataTypes),
    [dataType, pageData, project.dataTypes],
  );
  const nextScope = useMemo(
    () => getPageLoadingScope(scope, elementWithUpdatedId.id, loading),
    [elementWithUpdatedId, loading, scope],
  );
  const recordScope = useMemo(
    () => ({
      [pageId]: {
        _dataType: dataType.name,
        ...pageScope,
      },
    }),
    [dataType.name, pageId, pageScope],
  );

  const titleField = useMemo(
    () => title && dataType && dataType.fields.getByName(title),
    [dataType, title],
  );

  const titleValue = useMemo(
    () => getTitleValue(titleField, pageScope),
    [pageScope, titleField],
  );

  const handleScrollCapture = useCallback(
    (event) => {
      if (isSmScreen && event.target.scrollTop >= SCROLL_THRESHOLD) {
        if (!recordViewTitle && !showRecordTitle) {
          setShowRecordTitle(true);
          dispatch(setSidebarRecordViewTitle({ title: titleValue }));
        }
      } else {
        setShowRecordTitle(false);
        dispatch(setSidebarRecordViewTitle(null));
      }
    },
    [isSmScreen, dispatch, recordViewTitle, titleValue, showRecordTitle],
  );

  if (error || (!pageData && !loading)) {
    return (
      <div className="absolute left-0 right-0 top-0 p-8">
        <div className="w-full rounded border border-red-300 bg-red-200 p-4 text-red-900">
          {error && <pre>{String(error)}</pre>}
          {!pageData && (
            <span>
              {getText({ dataType: dataType.display }, 'errors.notFound')}
            </span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames('w-full', className, {
        'pl-2': !!_comments,
        'sm:overflow-y-auto': !editorMode,
      })}
      onClick={onClick}
      data-testid="record-view"
      onScrollCapture={handleScrollCapture}
    >
      <div className="mx-auto flex w-full flex-grow flex-col items-center pb-32">
        <RecordViewBody
          actionButtons={actionButtons}
          comments={comments}
          data={pageScope}
          dataType={dataType}
          doneButtonText={doneButtonText}
          editButtonText={editButtonText}
          editButtonVisibilityRules={(editButton as any).visibilityRules}
          element={element}
          elementPath={elementPath}
          headerWidth={headerWidth}
          hideBreadcrumbs={hideBreadcrumbs}
          hideEditButton={hideEditButton}
          icon={icon}
          image={image}
          isSplitLayout={isSplitLayout}
          loading={loading}
          name={name}
          pageId={pageId}
          permissions={permissions}
          project={project}
          recordId={recordId}
          recordScope={recordScope}
          refetch={refetch}
          rootPathname={rootPathname}
          scope={nextScope}
          sections={sections}
          showRecordTitle={showRecordTitle}
          subtitle={subtitle}
          tabs={tabs}
          title={title}
        />
      </div>
      {commentsAreOpen && pageScope && comments.show && (
        <RecordComments
          dataType={dataType}
          project={project}
          openByDefault={comments.openByDefault}
          record={pageScope}
          allowAttachments={comments.allowAttachments}
        />
      )}
    </div>
  );
};

export default RecordView;
