import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'baseline-density-small',
  'IconBaselineDensitySmall',
  [
    ['path', { d: 'M4 3h16', key: 'svg-0' }],
    ['path', { d: 'M4 9h16', key: 'svg-1' }],
    ['path', { d: 'M4 15h16', key: 'svg-2' }],
    ['path', { d: 'M4 21h16', key: 'svg-3' }],
  ],
);
