import createReactComponent from '../createReactComponent';
export default createReactComponent('bold-off', 'IconBoldOff', [
  [
    'path',
    { d: 'M9 5h4a3.5 3.5 0 0 1 2.222 6.204m-3.222 .796h-5v-5', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M17.107 17.112a3.5 3.5 0 0 1 -3.107 1.888h-7v-7', key: 'svg-1' },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
