import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-soundcloud', 'IconBrandSoundcloud', [
  [
    'path',
    {
      d: 'M17 11h1c1.38 0 3 1.274 3 3c0 1.657 -1.5 3 -3 3l-6 0v-10c3 0 4.5 1.5 5 4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 8l0 9', key: 'svg-1' }],
  ['path', { d: 'M6 17l0 -7', key: 'svg-2' }],
  ['path', { d: 'M3 16l0 -2', key: 'svg-3' }],
]);
