import createReactComponent from '../createReactComponent';
export default createReactComponent('bell-ringing', 'IconBellRinging', [
  [
    'path',
    {
      d: 'M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 17v1a3 3 0 0 0 6 0v-1', key: 'svg-1' }],
  ['path', { d: 'M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727', key: 'svg-2' }],
  ['path', { d: 'M3 6.727a11.05 11.05 0 0 1 2.792 -3.727', key: 'svg-3' }],
]);
