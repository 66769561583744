import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'building-hospital',
  'IconBuildingHospital',
  [
    ['path', { d: 'M3 21l18 0', key: 'svg-0' }],
    [
      'path',
      { d: 'M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16', key: 'svg-1' },
    ],
    ['path', { d: 'M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4', key: 'svg-2' }],
    ['path', { d: 'M10 9l4 0', key: 'svg-3' }],
    ['path', { d: 'M12 7l0 4', key: 'svg-4' }],
  ],
);
