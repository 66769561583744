import createReactComponent from '../createReactComponent';
export default createReactComponent('bus-off', 'IconBusOff', [
  ['path', { d: 'M6 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M16.18 16.172a2 2 0 0 0 2.652 2.648', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M4 17h-2v-11a1 1 0 0 1 1 -1h2m4 0h8c2.761 0 5 3.134 5 7v5h-1m-5 0h-8',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M16 5l1.5 7h4.5', key: 'svg-3' }],
  ['path', { d: 'M2 10h8m4 0h3', key: 'svg-4' }],
  ['path', { d: 'M7 7v3', key: 'svg-5' }],
  ['path', { d: 'M12 5v3', key: 'svg-6' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
