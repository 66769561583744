import { useCallback } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { EMAIL, PAGE, PHONE, URL } from '../../constants/linkTypes';
import { NavigateAction } from '../../models/Element';
import { projectDataSelector } from '../../selectors/projectSelectors';
import { resolveNavigationObject } from '../navigation';
import { useOpenUrl } from './useOpenUrl';
import useRouter from './useRouter';

export type ReturnUseNavigateAction = (
  action: NavigateAction,
  actionsScope: Record<string, any>,
  onNext: () => void,
) => void;
const useNavigateAction = (): ReturnUseNavigateAction => {
  const openUrl = useOpenUrl();
  const { push } = useRouter();
  const project = useSelector(projectDataSelector);

  return useCallback(
    (
      action: NavigateAction,
      actionsScope: Record<string, any>,
      onNext: () => void,
    ) => {
      const { href, to } = resolveNavigationObject(
        action.navigate,
        actionsScope,
        project,
      );

      if ([EMAIL, PHONE, URL].includes(get(action, 'navigate.type')) && href) {
        openUrl(
          href,
          get(action, 'navigate.type') === EMAIL ||
            get(action, 'navigate.newTab', false),
        );
      }

      if (action.navigate.type === PAGE || (!href && to)) {
        push(to);
      }

      onNext();
    },
    [openUrl, project, push],
  );
};

export default useNavigateAction;
