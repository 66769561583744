import React, { useCallback } from 'react';
import { Transition } from '@headlessui/react';
import { IconArrowNarrowRight, IconCheck, IconX } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { Button } from '@noloco/components';
import CircularProgress from '@noloco/core/src/components/CircularProgress';
import { editorModeSelector } from '@noloco/core/src/selectors/elementsSelectors';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import {
  ONBOARDING_CHECKLIST_CLOSED,
  ONBOARDING_CHECKLIST_COMPLETED,
  ONBOARDING_CHECKLIST_OPENED,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { useUpdateSettings } from '../../utils/hooks/projectHooks';
import useOnboardingChecklist from '../../utils/hooks/useOnboardingChecklist';
import useToggleEditorMode from '../../utils/hooks/useToggleEditorMode';
import OnboardingCategory from './OnboardingCategory';

const LANG_KEY = 'onboardingChecklist';

const OnboardingChecklist = () => {
  const project = useSelector(projectDataSelector);

  const {
    query: { __onboarding },
    pushQueryParams,
  } = useRouter();

  const {
    categories,
    tasks,
    progressPx,
    canHideChecklist,
    loading,
    complete,
    hasExternalUsers,
  } = useOnboardingChecklist();

  const [updateSettings] = useUpdateSettings(project);
  const editorMode = useSelector(editorModeSelector);
  const { toggleEditorMode } = useToggleEditorMode(editorMode);

  const onEnableEditorMode = useCallback(() => {
    if (!editorMode) {
      toggleEditorMode();
    }
  }, [editorMode, toggleEditorMode]);

  const isOpen = __onboarding === 'true';
  const onChangeOpen = useCallback(
    (nextShow) => {
      trackEvent(
        nextShow ? ONBOARDING_CHECKLIST_OPENED : ONBOARDING_CHECKLIST_CLOSED,
      );

      pushQueryParams({
        __onboarding: nextShow ? 'true' : undefined,
      });
    },
    [pushQueryParams],
  );

  const onCompleteAndHideChecklist = useCallback(() => {
    trackEvent(ONBOARDING_CHECKLIST_COMPLETED);
    updateSettings(['flags', 'onboardingComplete'], true);
    onChangeOpen(false);
  }, [onChangeOpen, updateSettings]);

  return (
    <>
      {!isOpen && !complete && (
        <div
          onClick={() => onChangeOpen(true)}
          className="cursor-pointer text-blue-400 hover:text-blue-500"
        >
          <CircularProgress
            hideValue={loading}
            progress={progressPx}
            maxProgress={100}
          >
            {progressPx < 100 ? (
              <span className="text-sm">{progressPx}%</span>
            ) : (
              <IconCheck size={22} strokeWidth={4} />
            )}
          </CircularProgress>
        </div>
      )}
      <Transition
        show={isOpen && !complete}
        enter="ease-out duration-300"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="ease-in duration-300"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className="lg:max-w-screen onboarding-checklist absolute inset-y-0 left-16 z-50 flex max-h-screen w-full max-w-3xl transform flex-col border-l border-gray-700 bg-slate-800 text-white shadow-xl transition lg:left-0"
      >
        <div className="relative flex max-h-full flex-col border-l border-r border-gray-800 text-white">
          <button
            type="button"
            className="absolute right-4 top-4 text-gray-400 hover:text-white"
            onClick={() => onChangeOpen(false)}
          >
            <IconX size={18} />
          </button>
          <div className="flex flex-col border-b border-gray-600 px-8 py-4">
            <div className="flex items-center justify-between pr-4">
              <div className="flex flex-col">
                <h1 className="text-xl">{getText(LANG_KEY, 'title')}</h1>
                <h3 className="text-base text-gray-300">
                  {getText(LANG_KEY, 'subtitle')}
                </h3>
              </div>
              <div className="flex items-center text-blue-400">
                <CircularProgress
                  hideValue={true}
                  progress={progressPx}
                  maxProgress={100}
                />
                <div className="flex flex-col">
                  <span className="mt-1 text-xs font-light">
                    {getText(LANG_KEY, 'progress')}
                  </span>
                  <span className="text-2xl font-bold">{progressPx}%</span>
                </div>
              </div>
            </div>
          </div>
          {/* @ts-expect-error TS(2786): 'SimpleBar' cannot be used as a JSX component. */}
          <SimpleBar
            autoHide={false}
            className="dark-bar flex max-h-full flex-col overflow-y-auto px-8 py-4"
          >
            <div className="flex flex-col space-y-12 pb-10">
              {[categories.DATA, categories.USERS, categories.CONFIGURE].map(
                (category) => (
                  <OnboardingCategory
                    key={category}
                    category={category}
                    onEnableEditorMode={onEnableEditorMode}
                    subtitle={getText(
                      LANG_KEY,
                      'categories',
                      category,
                      'subtitle',
                    )}
                    title={getText(LANG_KEY, 'categories', category, 'title')}
                    tasks={tasks[category]}
                    hasExternalUsers={hasExternalUsers}
                    updateSettings={updateSettings}
                  />
                ),
              )}
            </div>
          </SimpleBar>
          {canHideChecklist && (
            <div className="flex items-center justify-between border-t border-gray-600 px-8 py-2">
              <div className="flex flex-col">
                <span className="text-base">
                  {getText(LANG_KEY, 'hide.title')}
                </span>
                <span className="text-xs text-gray-300">
                  {getText(LANG_KEY, 'hide.subtitle')}
                </span>
              </div>
              <Button
                bg="blue-500"
                className="ml-4 flex items-center whitespace-nowrap"
                onClick={onCompleteAndHideChecklist}
              >
                <span className="mr-2">{getText(LANG_KEY, 'hide.label')}</span>
                <IconArrowNarrowRight className="opacity-75" size={16} />
              </Button>
            </div>
          )}
        </div>
      </Transition>
      ,
    </>
  );
};

export default OnboardingChecklist;
