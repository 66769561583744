import createReactComponent from '../createReactComponent';
export default createReactComponent('camera-code', 'IconCameraCode', [
  [
    'path',
    {
      d: 'M11 20h-6a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14.948 13.559a3 3 0 1 0 -2.58 2.419', key: 'svg-1' }],
  ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-2' }],
  ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-3' }],
]);
