import { useEffect, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useDispatch, useSelector } from 'react-redux';
import { getJitteredPollInterval } from '@noloco/core/src/constants/polling';
import { setPublishedVersion } from '@noloco/core/src/reducers/project';
import {
  projectNameSelector,
  projectVersionSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import { useInfoAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import { getText } from '@noloco/core/src/utils/lang';

const QUERY = gql`
  query projectVersionInfo {
    projectVersionInfo {
      publishedVersion
      updatedAt
    }
  }
`;

const POLL_INTERVAL_MS = 60_000; // Refresh every minute

const onRefresh = () => document.location.reload();

type projectVersionInfo = {
  publishedVersion: number | null;
  lastUpdated: string | null;
};

const usePollPublishedVersionInfo = (isBuilder: boolean) => {
  const { user } = useAuth();
  const projectName = useSelector(projectNameSelector);
  const version = useSelector(projectVersionSelector);
  const dispatch = useDispatch();
  const notificationShowingRef = useRef<boolean>(false);

  const pollInterval = useMemo(
    () => getJitteredPollInterval(POLL_INTERVAL_MS),
    [],
  );

  const infoAlert = useInfoAlert();

  const { data } = useQuery(QUERY, {
    context: {
      projectQuery: true,
      projectName: projectName,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: isBuilder || !user,
    pollInterval,
  });

  const projectVersionInfo: projectVersionInfo | undefined = useMemo(
    () => data?.projectVersionInfo,
    [data],
  );

  useEffect(() => {
    if (
      !isBuilder &&
      projectVersionInfo &&
      projectVersionInfo.publishedVersion &&
      projectVersionInfo.publishedVersion !== version &&
      !notificationShowingRef.current
    ) {
      dispatch(setPublishedVersion(projectVersionInfo.publishedVersion));
      infoAlert(
        getText('toolbar.publish.newVersion.title'),
        getText('toolbar.publish.newVersion.subtitle'),
        {
          clearable: false,
          onClick: onRefresh,
          timeout: 0,
        },
      );
      notificationShowingRef.current = true;
    }
  });
};

export default usePollPublishedVersionInfo;
