import React, { forwardRef, useMemo, useState } from 'react';
import { useEffect } from 'react';
import kebabCase from 'lodash/kebabCase';
import { TextInput } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import BuildModeEditorTabs from '@noloco/core/src/components/buildMode/BuildModeEditorTabs';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import IconEditor from '@noloco/core/src/components/editor/IconEditor';
import { WithDraggable } from '@noloco/core/src/components/withDnD';
import { OPTIONS, VISIBILITY } from '@noloco/core/src/constants/buildMode';
import { RECORD_TAB } from '@noloco/core/src/constants/draggableItemTypes';
import { TABS } from '@noloco/core/src/constants/elements';
import { ID } from '@noloco/core/src/elements/sections/collections/CollectionEvents';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import useEditorTabs from '@noloco/core/src/utils/hooks/useEditorTabs';
import usePrevious from '@noloco/core/src/utils/hooks/usePrevious';
import { getText } from '@noloco/core/src/utils/lang';
import {
  UPDATE_DEBOUNCE_MS,
  UpdatePropertyCallback,
} from '../../../utils/hooks/projectHooks';
import VisibilityRulesEditor from '../VisibilityRulesEditor';
import DraggableListItem from './DraggableListItem';

const LANG_KEY = 'elements.VIEW.tabs';

type TabEditorProps = {
  activeListItem: number | null;
  dataType: DataType;
  draggable?: boolean;
  elementPath: ElementPath;
  index: number;
  isDefault: boolean;
  isOver?: boolean;
  listOptions: {
    icon?: JSX.Element;
    label?: string;
    value: string | number;
  }[];
  onClone: (id: ID, newItemId: ID, tab?: boolean) => void;
  onRemove: (id: ID) => void;
  onUpdate: UpdatePropertyCallback;
  popoutOpen: boolean;
  project: Project;
  setActiveListItem: (activeListItem: number | null) => void;
  setPopoutOpen: (popoutOpen: boolean) => void;
  tab: any;
  visibilityRulesEnabled: boolean;
};

const TabEditor = forwardRef(
  (
    {
      activeListItem,
      dataType,
      draggable,
      elementPath,
      index,
      isDefault,
      isOver,
      listOptions,
      onClone,
      onRemove,
      onUpdate,
      popoutOpen,
      project,
      setActiveListItem,
      setPopoutOpen,
      tab,
      visibilityRulesEnabled,
    }: TabEditorProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [editorTab, setEditorTab] = useEditorTabs('record.tabs', OPTIONS);
    const [draftTitle, setDraftTitle] = useState(tab.title);
    const draftUrl = useMemo(() => kebabCase(draftTitle), [draftTitle]);
    const previousDraftUrl = usePrevious(draftUrl);
    const validationError = useMemo(() => {
      if (!draftUrl) {
        return getText(LANG_KEY, 'invalidTitle');
      }
    }, [draftUrl]);

    useEffect(() => {
      if (previousDraftUrl !== draftUrl) {
        if (draftUrl) {
          onUpdate([index, 'title'], draftTitle);
        } else {
          onUpdate(
            [index, 'title'],
            getText({ count: index + 1 }, LANG_KEY, 'newTitle'),
          );
        }
      }
    }, [draftTitle, draftUrl, index, onUpdate, previousDraftUrl]);

    return (
      <DraggableListItem
        activeListItem={activeListItem}
        canDelete={!isDefault}
        draggable={!isDefault && draggable}
        icon={tab.icon}
        id={tab.id}
        index={index}
        isOver={isOver}
        listOptions={listOptions}
        onClone={onClone}
        onRemove={onRemove}
        popoutOpen={popoutOpen}
        ref={ref}
        setActiveListItem={setActiveListItem}
        setPopoutOpen={setPopoutOpen}
        tab={true}
        title={tab.title}
      >
        <>
          <BuildModeEditorTabs
            editorTab={editorTab}
            elementType={TABS}
            isNavEnabled={false}
            setEditorTab={setEditorTab}
          />
          <hr className="w-full border-slate-700" />
          {editorTab === OPTIONS && (
            <div className="mb-2 flex flex-col space-y-4 p-2">
              <BuildModeInput inline={true} label={getText(LANG_KEY, 'title')}>
                <TextInput
                  debounceMs={UPDATE_DEBOUNCE_MS}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setDraftTitle(event.target.value)
                  }
                  placeholder=""
                  validationError={validationError}
                  value={draftTitle}
                />
              </BuildModeInput>
              <BuildModeInput inline={true} label={getText(LANG_KEY, 'url')}>
                <p className="flex h-8 items-center rounded-lg bg-slate-700 px-1.5 tracking-wider text-gray-200">
                  {`/${kebabCase(tab.title)}`}
                </p>
              </BuildModeInput>
              <BuildModeInput inline={true} label={getText(LANG_KEY, 'icon')}>
                <IconEditor
                  clearable={true}
                  elementProps={tab.icon as any}
                  inline={false}
                  placement="right"
                  surface={DARK}
                  updateProperty={(_: ElementPath, iconName: string | null) =>
                    onUpdate([index, 'icon', 'name'], iconName)
                  }
                />
              </BuildModeInput>
            </div>
          )}
          {editorTab === VISIBILITY && visibilityRulesEnabled && (
            <VisibilityRulesEditor
              dataType={dataType}
              element={tab}
              elementPath={elementPath}
              onChange={(path: ElementPath, value: any) =>
                onUpdate([index, 'visibilityRules', ...path], value)
              }
              project={project}
            />
          )}
        </>
      </DraggableListItem>
    );
  },
);
TabEditor.displayName = 'TabEditor';

export default WithDraggable(TabEditor, RECORD_TAB);
