import createReactComponent from '../createReactComponent';
export default createReactComponent('message-question', 'IconMessageQuestion', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M14 18h-1l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 22v.01', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-4',
    },
  ],
]);
