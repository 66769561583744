import createReactComponent from '../createReactComponent';
export default createReactComponent('keyboard-off', 'IconKeyboardOff', [
  [
    'path',
    {
      d: 'M18 18h-14a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2m4 0h10a2 2 0 0 1 2 2v8c0 .554 -.226 1.056 -.59 1.418',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 10l0 .01', key: 'svg-1' }],
  ['path', { d: 'M10 10l0 .01', key: 'svg-2' }],
  ['path', { d: 'M14 10l0 .01', key: 'svg-3' }],
  ['path', { d: 'M18 10l0 .01', key: 'svg-4' }],
  ['path', { d: 'M6 14l0 .01', key: 'svg-5' }],
  ['path', { d: 'M18 14l0 .01', key: 'svg-6' }],
  ['path', { d: 'M10 14l4 0', key: 'svg-7' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-8' }],
]);
