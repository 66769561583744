import React, { forwardRef } from 'react';
import SimpleLayout from '@noloco/components/src/components/auth/SimpleLayout';
import PoweredByNoloco from '../components/PoweredByNoloco';
import SocialLogin from '../components/SocialLogin';
import { User } from '../models/User';

type SocialOnlyLoginProps = {
  errors: string[];
  googleClientId: string;
  logoUrl: string;
  onSocialLogin: (user: User) => void;
  projectName: string;
  redirectPath?: string;
  setErrors: (errors: any) => void;
  subTitleText: JSX.Element;
  titleText: string;
};

const SocialOnlyLogin = forwardRef(
  (
    {
      errors,
      googleClientId,
      logoUrl,
      onSocialLogin,
      projectName,
      redirectPath,
      setErrors,
      subTitleText,
      titleText,
    }: SocialOnlyLoginProps,
    ref,
  ) => {
    return (
      <div
        className="flex min-h-screen w-full items-center justify-center overflow-hidden bg-gray-100"
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
      >
        <SimpleLayout
          errors={errors}
          logoUrl={logoUrl}
          titleText={titleText}
          subTitleText={subTitleText}
        >
          <div className="mt-8 overflow-hidden rounded-lg bg-white p-7 shadow sm:rounded-none sm:px-6">
            <SocialLogin
              clientId={googleClientId}
              hideOr={true}
              loginPath={redirectPath || '/'}
              setErrors={setErrors}
              onLogin={onSocialLogin}
            />
            <PoweredByNoloco
              className="mx-auto mt-8 flex-wrap justify-center text-gray-800"
              projectName={projectName}
              utmSource="noloco_register"
            />
          </div>
        </SimpleLayout>
      </div>
    );
  },
);

export default SocialOnlyLogin;
