import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrows-transfer-down',
  'IconArrowsTransferDown',
  [
    ['path', { d: 'M17 3v6', key: 'svg-0' }],
    ['path', { d: 'M10 18l-3 3l-3 -3', key: 'svg-1' }],
    ['path', { d: 'M7 21v-18', key: 'svg-2' }],
    ['path', { d: 'M20 6l-3 -3l-3 3', key: 'svg-3' }],
    ['path', { d: 'M17 21v-2', key: 'svg-4' }],
    ['path', { d: 'M17 15v-2', key: 'svg-5' }],
  ],
);
