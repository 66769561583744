import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-google-podcasts',
  'IconBrandGooglePodcasts',
  [
    ['path', { d: 'M12 3v2', key: 'svg-0' }],
    ['path', { d: 'M12 19v2', key: 'svg-1' }],
    ['path', { d: 'M12 8v8', key: 'svg-2' }],
    ['path', { d: 'M8 17v2', key: 'svg-3' }],
    ['path', { d: 'M4 11v2', key: 'svg-4' }],
    ['path', { d: 'M20 11v2', key: 'svg-5' }],
    ['path', { d: 'M8 5v8', key: 'svg-6' }],
    ['path', { d: 'M16 7v-2', key: 'svg-7' }],
    ['path', { d: 'M16 19v-8', key: 'svg-8' }],
  ],
);
