import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-ramp-right-2',
  'IconArrowRampRight2',
  [
    ['path', { d: 'M6 3v8.707', key: 'svg-0' }],
    ['path', { d: 'M16 14l4 -4l-4 -4', key: 'svg-1' }],
    ['path', { d: 'M6 21c0 -6.075 4.925 -11 11 -11h3', key: 'svg-2' }],
  ],
);
