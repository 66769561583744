import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-fiverr', 'IconBrandFiverr', [
  [
    'path',
    {
      d: 'M15 3h-2a6 6 0 0 0 -6 6h-3v4h3v8h4v-7h4v7h4v-11h-8v-1.033a1.967 1.967 0 0 1 2 -1.967h2v-4z',
      key: 'svg-0',
    },
  ],
]);
