import createReactComponent from '../createReactComponent';
export default createReactComponent('bookmark', 'IconBookmark', [
  [
    'path',
    {
      d: 'M9 4h6a2 2 0 0 1 2 2v14l-5 -3l-5 3v-14a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
]);
