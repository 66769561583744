import React from 'react';
import { Button } from '@noloco/components';
import useScopeVariables from '../../utils/hooks/useScopeVariables';
import { getText } from '../../utils/lang';

type Props = {};

const EditButtonWrapper = ({
  // @ts-expect-error TS(2339): Property 'scope' does not exist on type 'Props'.
  scope,
  // @ts-expect-error TS(2339): Property 'element' does not exist on type 'Props'.
  element,
  // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type 'Pro... Remove this comment to see the full error message
  elementPath,
  // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
  onClick,
  // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Props'.
  project,
}: Props) => {
  const { editButtonText } = useScopeVariables(
    scope,
    element,
    project,
    elementPath,
  );

  return (
    <Button
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
        return false;
      }}
    >
      {editButtonText || getText('core.COLLECTION.form.editButton')}
    </Button>
  );
};

export default EditButtonWrapper;
