import createReactComponent from '../createReactComponent';
export default createReactComponent('router', 'IconRouter', [
  [
    'path',
    {
      d: 'M3 13m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 17l0 .01', key: 'svg-1' }],
  ['path', { d: 'M13 17l0 .01', key: 'svg-2' }],
  ['path', { d: 'M15 13l0 -2', key: 'svg-3' }],
  ['path', { d: 'M11.75 8.75a4 4 0 0 1 6.5 0', key: 'svg-4' }],
  ['path', { d: 'M8.5 6.5a8 8 0 0 1 13 0', key: 'svg-5' }],
]);
