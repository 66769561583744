import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconEdit } from '@tabler/icons-react';
import { FormField, Loader, Modal } from '@noloco/components';
import { BELOW_SOLID } from '@noloco/components/src/components/form/errorPositions';
import { PRIMARY } from '@noloco/components/src/constants/variants';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import { UPDATE_DATA_TYPE_NAME } from '../../../queries/project';
import { useUpdateDataType } from '../../../utils/hooks/useUpdateDataTypes';

type EditCollectionNameModalProps = {
  dataType: DataType;
  project: Project;
  onClose: () => void;
};

const LANG_KEY = 'leftSidebar.data.edit';

const EditCollectionNameModal = ({
  dataType,
  project,
  onClose,
}: EditCollectionNameModalProps) => {
  const updateDataType = useUpdateDataType();
  const [displayName, setDisplayName] = useState('');
  const [loading, setLoading] = useState(false);
  const [updateDataTypeName] = useMutation(UPDATE_DATA_TYPE_NAME);

  const isDisplayNameUnique = useMemo(
    () =>
      !project.dataTypes.some((dataType) => dataType.display === displayName),
    [project.dataTypes, displayName],
  );

  const isDisplayNameValid = useMemo(
    () => !!displayName && displayName !== '' && isDisplayNameUnique,
    [displayName, isDisplayNameUnique],
  );

  const handleUpdateDataTypeName = useCallback(() => {
    setLoading(true);
    updateDataTypeName({
      variables: {
        projectName: project.name,
        dataTypeId: dataType.id,
        display: displayName,
      },
    })
      .then(({ data }) => {
        if (data.updateDataTypeName) {
          updateDataType(data.updateDataTypeName);
          onClose();
        }
      })
      .finally(() => setLoading(false));
  }, [
    updateDataTypeName,
    project.name,
    dataType.id,
    displayName,
    updateDataType,
    onClose,
  ]);

  return (
    <Modal
      icon={loading ? <Loader size="sm" /> : <IconEdit size={18} />}
      title={getText(LANG_KEY, 'modalTitle')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleUpdateDataTypeName}
      confirmText={loading ? <Loader size="sm" /> : getText(LANG_KEY, 'done')}
      confirmDisabled={loading || !isDisplayNameValid}
      variant={PRIMARY}
    >
      <FormField
        errorMessage={isDisplayNameUnique ? null : getText(LANG_KEY, 'error')}
        errorType={BELOW_SOLID}
        placeholder={dataType.display}
        value={displayName}
        onChange={({ target: { value } }: any) => {
          setDisplayName(value);
        }}
        autoFocus={true}
      />
    </Modal>
  );
};

export default EditCollectionNameModal;
