import createReactComponent from '../createReactComponent';
export default createReactComponent('atom-2', 'IconAtom2', [
  ['path', { d: 'M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M12 21l0 .01', key: 'svg-1' }],
  ['path', { d: 'M3 9l0 .01', key: 'svg-2' }],
  ['path', { d: 'M21 9l0 .01', key: 'svg-3' }],
  ['path', { d: 'M8 20.1a9 9 0 0 1 -5 -7.1', key: 'svg-4' }],
  ['path', { d: 'M16 20.1a9 9 0 0 0 5 -7.1', key: 'svg-5' }],
  ['path', { d: 'M6.2 5a9 9 0 0 1 11.4 0', key: 'svg-6' }],
]);
