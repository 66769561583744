import createReactComponent from '../createReactComponent';
export default createReactComponent('plane-arrival', 'IconPlaneArrival', [
  [
    'path',
    {
      d: 'M15.157 11.81l4.83 1.295a2 2 0 1 1 -1.036 3.863l-14.489 -3.882l-1.345 -6.572l2.898 .776l1.414 2.45l2.898 .776l-.12 -7.279l2.898 .777l2.052 7.797z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 21h18', key: 'svg-1' }],
]);
