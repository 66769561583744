import createReactComponent from '../createReactComponent';
export default createReactComponent('track', 'IconTrack', [
  [
    'path',
    {
      d: 'M4 15l11 -11m5 5l-11 11m-4 -8l7 7m-3.5 -10.5l7 7m-3.5 -10.5l7 7',
      key: 'svg-0',
    },
  ],
]);
