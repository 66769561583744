import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-merge-right',
  'IconArrowMergeRight',
  [
    ['path', { d: 'M16 8l-4 -4l-4 4', key: 'svg-0' }],
    ['path', { d: 'M12 20v-16', key: 'svg-1' }],
    ['path', { d: 'M18 18c-4 -1.333 -6 -4.667 -6 -10', key: 'svg-2' }],
  ],
);
