import createReactComponent from '../createReactComponent';
export default createReactComponent('window-maximize', 'IconWindowMaximize', [
  [
    'path',
    {
      d: 'M3 16m0 1a1 1 0 0 1 1 -1h3a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 12v-6a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-6',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 8h4v4', key: 'svg-2' }],
  ['path', { d: 'M16 8l-5 5', key: 'svg-3' }],
]);
