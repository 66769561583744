import React, { useCallback, useMemo } from 'react';
import { SelectInput, SwitchButton } from '@noloco/components';
import { SelectOption } from '@noloco/components/src/components/select/SelectBase';
import StringPropEditor from '@noloco/ui/src/components/canvas/StringPropEditor';
import DropzoneMediaUpload from '@noloco/ui/src/components/editor/customerEditors/DropzoneMediaUpload';
import { FILE } from '../../constants/builtInDataTypes';
import { IMAGE } from '../../constants/fileTypes';
import imageTypes, {
  DYNAMIC,
  FIELD,
  ImageType,
  UPLOAD,
} from '../../constants/imageTypes';
import { ID } from '../../elements/sections/collections/CollectionEvents';
import { DataType } from '../../models/DataTypes';
import { ElementPath } from '../../models/Element';
import { Project } from '../../models/Project';
import { getText } from '../../utils/lang';
import BuildModeInput from './BuildModeInput';

type BuildModeImageEditorProps = {
  contained: boolean;
  dataType?: DataType;
  debouncedUpdateProperty: (path: ElementPath, value: any) => void;
  defaultType?: ImageType;
  disableDynamic?: boolean;
  elementPath: ElementPath;
  elementProps: any;
  hidden?: boolean;
  hideImage?: (value: boolean) => void;
  label?: string;
  project: Project;
  propDefinition?: any;
  updateProperty: (path: ElementPath, value: any) => void;
};

const NONE = 'NONE';

const BuildModeImageEditor = ({
  contained,
  dataType,
  debouncedUpdateProperty,
  defaultType = DYNAMIC,
  disableDynamic = false,
  elementPath,
  elementProps,
  hidden = false,
  hideImage,
  label,
  project,
  propDefinition,
  updateProperty,
}: BuildModeImageEditorProps) => {
  const { type = defaultType, src, mediaId, fieldName } = elementProps || {};

  const getTranslation = (...rest: any[]) =>
    getText('elements', IMAGE, ...rest);

  const imageTypeOptions = [...imageTypes, ...(hideImage ? [NONE] : [])]
    .map((imageType) => ({
      label: getTranslation('type', imageType, 'title'),
      value: imageType,
    }))
    .filter((option) => option.value !== FIELD || dataType);

  const handleImageTypeChange = useCallback(
    (option) => {
      if (hideImage) {
        if (option === NONE) {
          return hideImage(true);
        }

        hideImage(false);
      }
      updateProperty(['type'], option);
    },
    [hideImage, updateProperty],
  );

  const onUpdateMediaId = useCallback(
    (newMediaId: ID) =>
      updateProperty([], {
        ...elementProps,
        mediaId: newMediaId,
        type: UPLOAD,
      }),
    [elementProps, updateProperty],
  );

  const fieldOptions = useMemo(
    () =>
      dataType
        ? dataType.fields.reduce((acc, field) => {
            if (field.type === FILE) {
              acc.push({
                label: field.display,
                value: field.name,
              });
            }

            return acc;
          }, [] as SelectOption[])
        : [],
    [dataType],
  );

  return (
    <div className="my-1 flex flex-col space-y-2">
      {!disableDynamic && (
        <div className="flex flex-col justify-center">
          <BuildModeInput
            inline={true}
            label={label ?? getTranslation('type.label')}
          >
            <SwitchButton
              className="h-8 w-full rounded-lg"
              inverseColors={true}
              onChange={handleImageTypeChange}
              options={imageTypeOptions}
              value={hidden ? NONE : type}
            />
          </BuildModeInput>
        </div>
      )}
      {!hidden && type === DYNAMIC && !disableDynamic && (
        <BuildModeInput inline={true} label={getTranslation('source.label')}>
          <StringPropEditor
            // @ts-expect-error TS(2322): Type '{ contained: any; project: any; onChange: (v... Remove this comment to see the full error message
            contained={contained}
            project={project}
            onChange={(value: any) => debouncedUpdateProperty(['src'], value)}
            elementPath={elementPath}
            includeSelf={propDefinition && propDefinition.includeSelf}
            placeholder={getTranslation('source.placeholder')}
            value={src}
          />
        </BuildModeInput>
      )}
      {!hidden && (type === UPLOAD || disableDynamic) && (
        <DropzoneMediaUpload
          className="mt-2"
          fileType={IMAGE}
          mediaId={mediaId}
          project={project}
          updateMediaId={onUpdateMediaId}
        />
      )}
      {!hidden && type === FIELD && dataType && (
        <BuildModeInput inline={true} label={getTranslation('type.label')}>
          <SelectInput
            className="w-full"
            contained={contained}
            onChange={(option: any) => updateProperty(['fieldName'], option)}
            options={fieldOptions}
            placement="left"
            searchable={true}
            value={fieldName}
          />
        </BuildModeInput>
      )}
    </div>
  );
};

export default BuildModeImageEditor;
