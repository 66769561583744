import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { DataTypeHint, HintFeedback } from '@noloco/core/src/constants/hints';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { projectNameSelector } from '@noloco/core/src/selectors/projectSelectors';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import { EDITOR_HINT, FEEDBACK_EDITOR_HINT } from '../../queries/project';

const useEditorHint = (
  project: Project,
  dataType: DataType | undefined,
  editorHint: DataTypeHint,
) => {
  const projectName = useSelector(projectNameSelector);

  const { data } = useCacheQuery(EDITOR_HINT, {
    variables: {
      projectName: project.name,
      dataTypeId: dataType?.id,
      type: editorHint,
    },
    skip: !dataType,
  });
  const [feedbackHint] = useMutation(FEEDBACK_EDITOR_HINT);

  const feedback = useCallback(
    async (feedback: HintFeedback) => {
      await feedbackHint({
        variables: {
          projectName: projectName,
          dataTypeId: dataType?.id,
          type: editorHint,
          feedback,
        },
      });
    },
    [dataType?.id, editorHint, feedbackHint, projectName],
  );

  return {
    feedback,
    hint: get(data, ['editorHint', 'hint'], null),
  };
};

export default useEditorHint;
