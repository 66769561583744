import createReactComponent from '../createReactComponent';
export default createReactComponent('square-toggle', 'IconSquareToggle', [
  ['path', { d: 'M12 2l0 20', key: 'svg-0' }],
  [
    'path',
    { d: 'M14 20h-8a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8', key: 'svg-1' },
  ],
  ['path', { d: 'M20 6a2 2 0 0 0 -2 -2', key: 'svg-2' }],
  ['path', { d: 'M18 20a2 2 0 0 0 2 -2', key: 'svg-3' }],
  ['path', { d: 'M20 10l0 4', key: 'svg-4' }],
]);
