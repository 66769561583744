import createReactComponent from '../createReactComponent';
export default createReactComponent('layers-linked', 'IconLayersLinked', [
  [
    'path',
    {
      d: 'M19 8.268a2 2 0 0 1 1 1.732v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M5 15.734a2 2 0 0 1 -1 -1.734v-8a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-3',
      key: 'svg-1',
    },
  ],
]);
