import { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import shortId from 'shortid';
import CollectionFieldEditor from '@noloco/ui/src/components/editor/customerEditors/CollectionFieldEditor';
import FieldsListEditor from '@noloco/ui/src/components/editor/customerEditors/FieldsListEditor';
import { COUNT } from '../../../constants/chartAggregations';
import { NUMERIC_DATATYPES } from '../../../constants/dataTypes';
import { VIEW } from '../../../constants/elements';
import { PivotTableValue } from '../../../constants/pivotTable';
import { DATABASE } from '../../../constants/scopeTypes';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { Element, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import StateItem from '../../../models/StateItem';
import { FormFieldConfig } from '../../../models/View';
import { Page } from '../../../utils/pages';

type BuildModeViewValuesTabProps = {
  dataType: DataType;
  element: Page;
  elementPath: ElementPath;
  project: Project;
  section?: Element;
  sectionPropPath?: ElementPath;
  updateProperty: (elementPath: ElementPath, value: any) => void;
};

const BuildModeViewValuesTab = ({
  dataType: collectionDataType,
  element,
  elementPath,
  project,
  section,
  sectionPropPath = [],
  updateProperty,
}: BuildModeViewValuesTabProps) => {
  const collectionElement = useMemo(
    () => (section ?? element) as Page | Element,
    [section, element],
  );

  const collectionElementPath = useMemo(
    () => (section ? sectionPropPath : elementPath),
    [section, sectionPropPath, elementPath],
  );

  const {
    dataList,
    layout,
    pivotTable: { values = [] } = {},
  } = collectionElement.props || {};

  const dataType = useMemo<DataType>(() => {
    const typeName = get(dataList, 'dataType');

    return (
      (typeName && project.dataTypes.getByName(typeName)) ?? collectionDataType
    );
  }, [dataList, collectionDataType, project.dataTypes]);

  const fields = useMemo<FormFieldConfig[]>(
    () =>
      values.map((value: PivotTableValue) => ({
        ...value,
        name: value.field?.path,
      })),
    [values],
  );

  const stateItem = useMemo(
    () =>
      dataType &&
      new StateItem({
        id: `${collectionElement.id}${
          collectionElement.type === VIEW ? ':VIEW' : ''
        }`,
        path: collectionElement.type === VIEW ? '' : 'edges.node',
        source: DATABASE,
        dataType: dataType.name,
        display: dataType.display,
      }),
    [dataType, collectionElement.id, collectionElement.type],
  );

  const onValuesChange = useCallback(
    (path, values) => {
      if (Array.isArray(values)) {
        const valuesToUpdate = values.map(
          (value: { name: string; label: string }) => {
            const field = dataType.fields.getByName(value.name);

            return {
              aggregation: COUNT,
              field: {
                ...stateItem,
                dataType: field?.type,
                display: field?.display,
                path: value.name,
              },
              id: shortId.generate(),
            };
          },
        );

        return updateProperty(['pivotTable', 'values'], valuesToUpdate);
      }

      updateProperty(['pivotTable', 'values', ...path], values);
    },
    [updateProperty, dataType, stateItem],
  );

  return (
    <div className="text-xs">
      {dataType && layout && (
        <FieldsListEditor
          allowChildFieldsFilter={({ relatedField, relationship }: DataField) =>
            !!relatedField || !!relationship
          }
          dataType={dataType}
          dataTypes={project.dataTypes}
          fields={dataType.fields}
          getNewFieldConfig={(field: DataField) => ({
            label: { value: upperFirst(field.display) },
          })}
          filter={(field: DataField) => NUMERIC_DATATYPES.includes(field.type)}
          onFieldsChange={onValuesChange}
          permissionType="read"
          sticky={true}
          value={fields}
        >
          {({ config, field, index, parent, updateFields }: any) => (
            <CollectionFieldEditor
              config={config}
              dataType={dataType}
              elementId={collectionElement.id}
              elementPath={collectionElementPath}
              field={field}
              index={index}
              layout={layout}
              parent={parent}
              project={project}
              updateFields={updateFields}
            />
          )}
        </FieldsListEditor>
      )}
    </div>
  );
};

export default BuildModeViewValuesTab;
