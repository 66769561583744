import createReactComponent from '../createReactComponent';
export default createReactComponent('shirt', 'IconShirt', [
  [
    'path',
    {
      d: 'M15 4l6 2v5h-3v8a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1v-8h-3v-5l6 -2a3 3 0 0 0 6 0',
      key: 'svg-0',
    },
  ],
]);
