import createReactComponent from '../createReactComponent';
export default createReactComponent('photo-ai', 'IconPhotoAi', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10 21h-4a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 16l5 -5c.928 -.893 2.072 -.893 3 0l1 1', key: 'svg-2' }],
  ['path', { d: 'M14 21v-4a2 2 0 1 1 4 0v4', key: 'svg-3' }],
  ['path', { d: 'M14 19h4', key: 'svg-4' }],
  ['path', { d: 'M21 15v6', key: 'svg-5' }],
]);
