import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';

const CurrentTimeIndicator = ({ position, theme }: any) => {
  const themedBackgroundColour = `bg-${theme.brandColors.primary}`;

  return (
    <div
      className="l-0 r-0 pointer-events-none absolute z-10 -mt-1.5 w-full"
      style={{ top: `${position}%` }}
    >
      <div className="flex">
        <div
          className={classNames(
            '-ml-1.5 flex h-3 w-3 rounded-full',
            themedBackgroundColour,
          )}
        />
        <div
          className={classNames('my-auto h-0.5 flex-1', themedBackgroundColour)}
        />
      </div>
    </div>
  );
};

export default withTheme(CurrentTimeIndicator);
