import React, { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { getDataTypeByName } from '../../../../utils/data';
import { getFieldReverseName } from '../../../../utils/fields';
import DataTypeRecordInput from '../DataTypeRecordInput';
import RelatedCellItem from '../RelatedCellItem';

const RelationalDataFieldInput = ({
  field,
  newRecordFormFields,
  dataTypes,
  renderLabel,
  ...rest
}: any) => {
  const dataType = useMemo(
    () => getDataTypeByName(dataTypes, field.type),
    [dataTypes, field.type],
  );

  const filteredFormFields = useMemo(() => {
    const fieldName = field.relatedField
      ? field.relatedField.name
      : // @ts-expect-error TS(2345): Argument of type '{ name: any; }' is not assignabl... Remove this comment to see the full error message
        getFieldReverseName(field, { name: field.type });
    return newRecordFormFields.filter(
      (formField: any) => formField.field !== fieldName,
    );
  }, [field, newRecordFormFields]);

  return (
    <DataTypeRecordInput
      dataType={dataType}
      dataTypes={dataTypes}
      newRecordFormFields={filteredFormFields}
      renderLabel={renderLabel(field)}
      {...rest}
    />
  );
};

RelationalDataFieldInput.defaultProps = {
  newRecordFormFields: [],
  renderLabel:
    (field: any) => (value: any, _isDisabled: any, dataTypes: any) => (
      <RelatedCellItem
        field={field}
        value={value}
        dataTypes={dataTypes}
        single={true}
      />
    ),
};

export default withTheme(RelationalDataFieldInput);
