import createReactComponent from '../createReactComponent';
export default createReactComponent('mood-edit', 'IconMoodEdit', [
  ['path', { d: 'M20.955 11.104a9 9 0 1 0 -9.895 9.847', key: 'svg-0' }],
  ['path', { d: 'M9 10h.01', key: 'svg-1' }],
  ['path', { d: 'M15 10h.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M9.5 15c.658 .672 1.56 1 2.5 1c.126 0 .251 -.006 .376 -.018',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z',
      key: 'svg-4',
    },
  ],
]);
