export const currencyToSymbol = (currency: any) =>
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  ({
    usd: '$',
    eur: '€',
    gbp: '£',
    aud: '$',
    sek: 'Kr',
    cad: '$',
  })[currency] || '';

export const formatCurrency = (number: any) =>
  number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatShortCurrency = (value: number) =>
  value.toLocaleString(undefined, {
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    style: 'currency',
  });
