import createReactComponent from '../createReactComponent';
export default createReactComponent('volume-3', 'IconVolume3', [
  [
    'path',
    {
      d: 'M6 15h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a.8 .8 0 0 1 1.5 .5v14a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 10l4 4m0 -4l-4 4', key: 'svg-1' }],
]);
