import createReactComponent from '../createReactComponent';
export default createReactComponent('cloud-cog', 'IconCloudCog', [
  [
    'path',
    {
      d: 'M12 18.004h-5.343c-2.572 -.004 -4.657 -2.011 -4.657 -4.487c0 -2.475 2.085 -4.482 4.657 -4.482c.393 -1.762 1.794 -3.2 3.675 -3.773c1.88 -.572 3.956 -.193 5.444 1c1.488 1.19 2.162 3.007 1.77 4.769h.99c.956 0 1.822 .39 2.449 1.02',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M19.001 15.5v1.5', key: 'svg-2' }],
  ['path', { d: 'M19.001 21v1.5', key: 'svg-3' }],
  ['path', { d: 'M22.032 17.25l-1.299 .75', key: 'svg-4' }],
  ['path', { d: 'M17.27 20l-1.3 .75', key: 'svg-5' }],
  ['path', { d: 'M15.97 17.25l1.3 .75', key: 'svg-6' }],
  ['path', { d: 'M20.733 20l1.3 .75', key: 'svg-7' }],
]);
