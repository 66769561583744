import createReactComponent from '../createReactComponent';
export default createReactComponent('address-book-off', 'IconAddressBookOff', [
  [
    'path',
    {
      d: 'M8 4h10a2 2 0 0 1 2 2v10m-.57 3.399c-.363 .37 -.87 .601 -1.43 .601h-10a2 2 0 0 1 -2 -2v-12',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 16h6', key: 'svg-1' }],
  ['path', { d: 'M11 11a2 2 0 0 0 2 2m2 -2a2 2 0 0 0 -2 -2', key: 'svg-2' }],
  ['path', { d: 'M4 8h3', key: 'svg-3' }],
  ['path', { d: 'M4 12h3', key: 'svg-4' }],
  ['path', { d: 'M4 16h3', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
