import createReactComponent from '../createReactComponent';
export default createReactComponent('snowman', 'IconSnowman', [
  [
    'path',
    {
      d: 'M12 3a4 4 0 0 1 2.906 6.75a6 6 0 1 1 -5.81 0a4 4 0 0 1 2.904 -6.75z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17.5 11.5l2.5 -1.5', key: 'svg-1' }],
  ['path', { d: 'M6.5 11.5l-2.5 -1.5', key: 'svg-2' }],
  ['path', { d: 'M12 13h.01', key: 'svg-3' }],
  ['path', { d: 'M12 16h.01', key: 'svg-4' }],
]);
