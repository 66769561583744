import camelCase from 'lodash/camelCase';
import builtInDataTypes from '@noloco/core/src/constants/builtInDataTypes';
import DataTypes from '@noloco/core/src/models/DataTypes';

const reservedDataTypes = [
  ...builtInDataTypes,
  'fileType',
  // Reserved due to `Mutation.updateOnboardingTasks`
  'onboardingTasks',
  'onboardingTaskType',
];

const useIsNameUnique = (collectionName: string, dataTypes: DataTypes) => {
  const cleanName = camelCase(collectionName);

  return (
    !reservedDataTypes.includes(cleanName) && !dataTypes.getByName(cleanName)
  );
};

export default useIsNameUnique;
