import React, { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { PropertyPath } from 'lodash';
import { SelectInput, Surface, TextInput } from '@noloco/components';
import { OBJECT } from '../../../../constants/dataTypes';
import { FULL_NAME } from '../../../../constants/fieldFormats';
import { DataField } from '../../../../models/DataTypeFields';
import { getColorByIndex } from '../../../../utils/colors';
import { getText } from '../../../../utils/lang';

type Props = {
  disabled?: boolean;
  field: DataField;
  onBlur: () => void;
  onUpdateDraftValue: (path: PropertyPath) => (value: any) => void;
  onUpdateValue: (path: PropertyPath) => (value: any) => void;
  surface: Surface;
  validationError?: string;
  value: any;
};

const FullNameInput = ({
  disabled,
  field,
  onBlur,
  onUpdateDraftValue,
  onUpdateValue,
  surface,
  validationError,
  value,
}: Props) => {
  const titleOptions = useMemo(() => {
    const options = field.typeOptions?.subFields?.title?.options;

    if (!options) {
      return [];
    }

    return [
      {
        color: 'gray',
        display: ' ',
        name: null,
      },
      ...options,
    ].map((option, index) => ({
      color: getColorByIndex(index),
      label: option.display,
      value: option.display,
    }));
  }, [field.typeOptions]);

  const titleEnabled = useMemo(
    () => !!field.typeOptions?.subFields?.title,
    [field.typeOptions],
  );
  const middleEnabled = useMemo(
    () => !!field.typeOptions?.subFields?.middle,
    [field.typeOptions],
  );

  return (
    <div
      className="transparent flex grid w-full grid-cols-2 gap-2 sm:grid-cols-1"
      data-testid="full-name-input"
    >
      {field.typeOptions?.subFields?.title && (
        <div>
          <SelectInput
            disabled={disabled}
            options={titleOptions}
            onBlur={onBlur}
            onChange={onUpdateValue(['title'])}
            placeholder={getText(
              'data.types',
              OBJECT,
              FULL_NAME,
              'title.label',
            )}
            searchable={true}
            surface={surface}
            validationError={validationError}
            value={value?.title}
          />
        </div>
      )}
      <div>
        <TextInput
          disabled={disabled}
          onBlur={onBlur}
          onChange={(e: any) => onUpdateDraftValue(['first'])(e.target.value)}
          placeholder={getText('data.types', OBJECT, FULL_NAME, 'first.label')}
          surface={surface}
          validationError={validationError}
          value={value?.first}
        />
      </div>
      {field.typeOptions?.subFields?.middle && (
        <div>
          <TextInput
            disabled={disabled}
            onBlur={onBlur}
            onChange={(e: any) =>
              onUpdateDraftValue(['middle'])(e.target.value)
            }
            placeholder={getText(
              'data.types',
              OBJECT,
              FULL_NAME,
              'middle.label',
            )}
            surface={surface}
            validationError={validationError}
            value={value?.middle}
          />
        </div>
      )}
      <div
        className={classNames({
          'col-span-2 sm:col-span-1':
            (titleEnabled && !middleEnabled) ||
            (!titleEnabled && middleEnabled),
        })}
      >
        <TextInput
          disabled={disabled}
          onBlur={onBlur}
          onChange={(e: any) => onUpdateDraftValue(['last'])(e.target.value)}
          placeholder={getText('data.types', OBJECT, FULL_NAME, 'last.label')}
          surface={surface}
          validationError={validationError}
          value={value?.last}
        />
      </div>
    </div>
  );
};

export default withTheme(FullNameInput);
