import createReactComponent from '../createReactComponent';
export default createReactComponent('circles-filled', 'IconCirclesFilled', [
  [
    'path',
    {
      d: 'M6.5 12a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M17.5 12a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z',
      fill: 'currentColor',
      key: 'svg-1',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M12 2a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z',
      fill: 'currentColor',
      key: 'svg-2',
      strokeWidth: '0',
    },
  ],
]);
