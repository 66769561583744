import React from 'react';
import ErrorText from '../form/ErrorText';

const SplitLayout = ({
  children,
  errors,
  logoUrl,
  onSubmit,
  subTitleText,
  titleText,
  splitChildren,
  splitImageUrl,
}: any) => {
  return (
    <div className="flex max-h-screen min-h-screen w-full overflow-hidden">
      <div className="flex max-h-screen min-h-screen w-5/12 flex-col items-center overflow-y-auto bg-white md:w-full">
        <div className="flex flex-grow" />
        <div className="flex w-full max-w-md flex-col items-center px-4 py-8">
          <div className="flex w-full max-w-md flex-col">
            <div>
              {logoUrl && (
                <img className="h-10 w-auto" src={logoUrl} alt="logo" />
              )}
              <h2 className="mt-6 text-xl font-extrabold leading-9 text-gray-900">
                {titleText}
              </h2>
              {subTitleText && (
                <p className="mt-1.5 text-sm leading-5 text-gray-600">
                  {subTitleText}
                </p>
              )}
            </div>
            {errors && errors.length > 0 && (
              <ErrorText className="mt-6" type="below-solid">
                {errors.map((error: any) => (
                  <div className="block" key={error}>
                    {error}
                  </div>
                ))}
              </ErrorText>
            )}
            <form action="#" className="mt-6" onSubmit={onSubmit}>
              {children}
            </form>
          </div>
        </div>
        <div className="flex flex-grow" />
      </div>
      <div
        className="flex h-screen w-7/12 bg-cover bg-center md:hidden"
        style={{
          backgroundImage: `url(${splitImageUrl})`,
        }}
      >
        {splitChildren}
      </div>
    </div>
  );
};

export default SplitLayout;
