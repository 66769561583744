import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'alarm-plus-filled',
  'IconAlarmPlusFilled',
  [
    [
      'path',
      {
        d: 'M16 6.072a8 8 0 1 1 -11.995 7.213l-.005 -.285l.005 -.285a8 8 0 0 1 11.995 -6.643zm-4 3.928a1 1 0 0 0 -1 1v1h-1l-.117 .007a1 1 0 0 0 .117 1.993h1v1l.007 .117a1 1 0 0 0 1.993 -.117v-1h1l.117 -.007a1 1 0 0 0 -.117 -1.993h-1v-1l-.007 -.117a1 1 0 0 0 -.993 -.883z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M6.412 3.191a1 1 0 0 1 1.273 1.539l-.097 .08l-2.75 2a1 1 0 0 1 -1.273 -1.54l.097 -.08l2.75 -2z',
        fill: 'currentColor',
        key: 'svg-1',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M16.191 3.412a1 1 0 0 1 1.291 -.288l.106 .067l2.75 2a1 1 0 0 1 -1.07 1.685l-.106 -.067l-2.75 -2a1 1 0 0 1 -.22 -1.397z',
        fill: 'currentColor',
        key: 'svg-2',
        strokeWidth: '0',
      },
    ],
  ],
);
