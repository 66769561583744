import createReactComponent from '../createReactComponent';
export default createReactComponent('hourglass-off', 'IconHourglassOff', [
  [
    'path',
    {
      d: 'M18 18v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1v-2a6 6 0 0 1 6 -6',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M6 6a6 6 0 0 0 6 6m3.13 -.88a6 6 0 0 0 2.87 -5.12v-2a1 1 0 0 0 -1 -1h-10',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
