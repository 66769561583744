import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-wordpress', 'IconBrandWordpress', [
  ['path', { d: 'M9.5 9h3', key: 'svg-0' }],
  ['path', { d: 'M4 9h2.5', key: 'svg-1' }],
  ['path', { d: 'M11 9l3 11l4 -9', key: 'svg-2' }],
  ['path', { d: 'M5.5 9l3.5 11l3 -7', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M18 11c.177 -.528 1 -1.364 1 -2.5c0 -1.78 -.776 -2.5 -1.875 -2.5c-.898 0 -1.125 .812 -1.125 1.429c0 1.83 2 2.058 2 3.571z',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-5' }],
]);
