export const USER = 'user';
export const FILE = 'file';
export const MEMBERSHIP = 'membership';
export const MESSAGE = 'message';
export const ROLE = 'role';
export const INVOICE = 'invoice';
export const INVOICE_LINE_ITEM = 'invoiceLineItem';
export const COMPANY = 'company';
export const PUSH_SUBSCRIPTION = 'pushSubscription';
export const SHARED_FILE = 'sharedFile';

export const SUBSCRIPTION = 'subscription';

export const COMMENT = 'comment';
export const ONBOARDING_TASK = 'onboardingTask';

const builtInDataTypes = [
  USER,
  FILE,
  MEMBERSHIP,
  MESSAGE,
  ROLE,
  INVOICE,
  INVOICE_LINE_ITEM,
  COMPANY,
  SHARED_FILE,
  ONBOARDING_TASK,
  PUSH_SUBSCRIPTION,
  SUBSCRIPTION,
];

export default builtInDataTypes;
