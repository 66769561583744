import { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { LEFT_RECORD_SETTINGS } from '../../constants/buildMode';
import { RECORD } from '../../constants/elements';
import { ElementPath } from '../../models/Element';
import { ghostUserIdSelector } from '../../selectors/dataSelectors';
import { selectedSectionSelector } from '../../selectors/elementsSelectors';
import { projectDataSelector } from '../../selectors/projectSelectors';
import useSelectedTab from '../../utils/hooks/useSelectedTab';
import { getText } from '../../utils/lang';
import { Page } from '../../utils/pages';
import { filterSections } from '../../utils/tabs';
import SectionEditorList from '../editor/SectionEditorList';
import BuildModeSection from './BuildModeSection';
import BuildModeSectionOptions from './BuildModeSectionOptions';

export type LeftBuildModeRecordEditorProps = {
  elementPath: ElementPath;
  page: Page;
};

const LeftBuildModeRecordEditor = ({
  elementPath,
  page,
}: LeftBuildModeRecordEditorProps) => {
  const project = useSelector(projectDataSelector);
  const tabs = get(page, 'props.record.tabs', []);
  const sections = get(page, 'props.record.sections', []);
  const ghostUserId = useSelector(ghostUserIdSelector);
  const selectedSectionPath = useSelector(selectedSectionSelector);
  const section = get(sections, selectedSectionPath);
  const selectedTab = useSelectedTab(tabs, tabs);
  const dataType = useMemo(() => {
    const typeName = get(page.props.dataList, 'dataType');

    return typeName && project.dataTypes.getByName(typeName);
  }, [page, project.dataTypes]);

  const visibleSections = useMemo(
    () => filterSections(tabs, tabs, sections, selectedTab),
    [tabs, sections, selectedTab],
  );

  return (
    <div
      className={classNames('sticky bottom-4 z-10', {
        'pb-8': ghostUserId,
      })}
    >
      <BuildModeSection
        className="border-t"
        id={LEFT_RECORD_SETTINGS}
        title={getText('leftSidebar.auth.RECORD')}
        endComponent={
          <BuildModeSectionOptions
            dataType={dataType}
            elementPath={elementPath}
            elementType={RECORD}
            project={project}
            sections={sections}
            selectedTab={selectedTab}
          />
        }
      >
        {/* @ts-expect-error TS(2786): 'SimpleBar' cannot be used as a JSX component. */}
        <SimpleBar className="max-h-screen-40 min-h-40 overflow-y-auto bg-slate-800">
          {visibleSections.length === 0 ? (
            <div className="flex items-center justify-center py-4 text-center text-slate-400">
              {getText('leftSidebar.editor.sectionEditorEmptyState')}
            </div>
          ) : (
            <div className="px-3 pb-4 pt-2">
              <SectionEditorList
                activeSection={section?.id}
                dataType={dataType}
                elementPath={elementPath}
                elementType={RECORD}
                project={project}
                sectionPath={['record', 'sections']}
                sections={sections}
                selectedTab={selectedTab}
                visibleSections={visibleSections}
              />
            </div>
          )}
        </SimpleBar>
      </BuildModeSection>
    </div>
  );
};

export default LeftBuildModeRecordEditor;
