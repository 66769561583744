import createReactComponent from '../createReactComponent';
export default createReactComponent('currency-dinar', 'IconCurrencyDinar', [
  ['path', { d: 'M14 20.01v-.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 13l2.386 -.9a1 1 0 0 0 -.095 -1.902l-1.514 -.404a1 1 0 0 1 -.102 -1.9l2.325 -.894',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M3 14v1a3 3 0 0 0 3 3h4.161a3 3 0 0 0 2.983 -3.32l-1.144 -10.68',
      key: 'svg-2',
    },
  ],
  [
    'path',
    { d: 'M16 17l1 1h2a2 2 0 0 0 1.649 -3.131l-2.653 -3.869', key: 'svg-3' },
  ],
]);
