import { useCallback, useMemo, useState } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CREATE, SCAN_BARCODE } from '../../constants/actionTypes';
import { CUSTOM_VISIBILITY_RULES } from '../../constants/features';
import { DataType } from '../../models/DataTypes';
import { ActionButton, ScanActionType } from '../../models/Element';
import { Project } from '../../models/Project';
import { scopeSelector } from '../../selectors/dataSelectors';
import { getAllowedViewRoutePrefixForDataType } from '../urls';
import useRouter from './useRouter';
import useScopeUser from './useScopeUser';

const getCreatedRecordViewPath = (
  action: any,
  createdRecord: any,
  dataType: any,
  project: any,
  user: any,
  scope: any,
  customRulesEnabled: any,
) => {
  const { field } = action;
  if (!field) {
    return null;
  }

  const f = dataType.fields.getByName(field || 'id');

  const recordLinkRoot =
    f &&
    getAllowedViewRoutePrefixForDataType(
      f.type,
      project,
      user,
      scope,
      customRulesEnabled,
    );

  return recordLinkRoot
    ? `${recordLinkRoot}/view/${get(createdRecord, 'uuid')}`
    : null;
};

const useActionSteps = (
  actionButton: ActionButton,
  dataType: DataType,
  project: Project,
  onFinish: (cancelled?: boolean) => void,
) => {
  const [actionIndex, setActionIndex] = useState(0);
  const { push } = useRouter();
  const user = useScopeUser();
  const scope = useSelector(scopeSelector);

  const action = useMemo(
    () => get(actionButton, ['actions', actionIndex || 0]),
    [actionButton, actionIndex],
  );

  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const onNext = useCallback(
    (actionResult?: any) => {
      if (
        action &&
        action.type === SCAN_BARCODE &&
        action.scanActionType === ScanActionType.CREATE &&
        action.multiScan &&
        actionResult
      ) {
        return;
      }

      if (actionIndex < get(actionButton, 'actions.length', 0) - 1) {
        setActionIndex(actionIndex + 1);
        return;
      }

      if (
        action &&
        action.type === CREATE &&
        action.redirect !== false &&
        actionResult &&
        actionResult.id
      ) {
        const nextUrl = getCreatedRecordViewPath(
          action,
          actionResult,
          dataType,
          project,
          user,
          scope,
          customRulesEnabled,
        );

        if (nextUrl) {
          push(nextUrl);
        }
      }

      onFinish();
    },
    [
      action,
      actionButton,
      actionIndex,
      customRulesEnabled,
      dataType,
      onFinish,
      project,
      push,
      scope,
      user,
    ],
  );

  return {
    action,
    actionIndex,
    onNext,
  };
};

export default useActionSteps;
