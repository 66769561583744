import createReactComponent from '../createReactComponent';
export default createReactComponent('headphones-off', 'IconHeadphonesOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M4 13m0 2a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M17 13h1a2 2 0 0 1 2 2v1m-.589 3.417c-.361 .36 -.86 .583 -1.411 .583h-1a2 2 0 0 1 -2 -2v-3',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M4 15v-3c0 -2.21 .896 -4.21 2.344 -5.658m2.369 -1.638a8 8 0 0 1 11.287 7.296v3',
      key: 'svg-3',
    },
  ],
]);
