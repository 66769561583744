export const FILLED = 'filled';
export const LINK = 'link';
export const OUTLINE = 'outline';
export const TEXT = 'text';

const buttonTypes = [FILLED, LINK, OUTLINE, TEXT];

export type ButtonType = 'filled' | 'link' | 'outline' | 'text';

export const ButtonTypes: Record<
  'FILLED' | 'LINK' | 'OUTLINE' | 'TEXT',
  ButtonType
> = {
  FILLED,
  LINK,
  OUTLINE,
  TEXT,
};

export default buttonTypes;
