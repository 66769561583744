import createReactComponent from '../createReactComponent';
export default createReactComponent('bottle', 'IconBottle', [
  [
    'path',
    { d: 'M10 5h4v-2a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v2z', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M14 3.5c0 1.626 .507 3.212 1.45 4.537l.05 .07a8.093 8.093 0 0 1 1.5 4.694v6.199a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2v-6.2c0 -1.682 .524 -3.322 1.5 -4.693l.05 -.07a7.823 7.823 0 0 0 1.45 -4.537',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M7 14.803a2.4 2.4 0 0 0 1 -.803a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 1 -.805',
      key: 'svg-2',
    },
  ],
]);
