import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'layout-navbar-collapse',
  'IconLayoutNavbarCollapse',
  [
    [
      'path',
      {
        d: 'M4 18v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M4 9h16', key: 'svg-1' }],
    ['path', { d: 'M10 16l2 -2l2 2', key: 'svg-2' }],
  ],
);
