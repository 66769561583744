import get from 'lodash/get';
import initial from 'lodash/initial';
import isNil from 'lodash/isNil';
import { AUTH_WRAPPER_ID } from '../constants/auth';
import { MULTIPLE_OPTION } from '../constants/dataTypes';
import { DATABASE } from '../constants/scopeTypes';
import baseElementsConfig from '../elements/baseElementConfig';
import { DataField } from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { ElementPath } from '../models/Element';
import { Project } from '../models/Project';
import { BaseRecord, CollectionConnection } from '../models/Record';
import StateItem from '../models/StateItem';
import { formatValue } from './data';
import { getText } from './lang';
import { isMultiField, isReverseMultiRelationship } from './relationships';

export const RECORD_SCOPE = 'RECORD_SCOPE';

const getScopeForElementPath = (project: any, elementPath: any, context: any) =>
  getScopeForElement(
    get(project.elements, elementPath),
    project,
    elementPath,
    context,
  );

export const getScopeTypeForElement = (
  element: any,
  project: any,
  elementPath: any,
  context: any,
  getState: any,
) => {
  if (!element) {
    return null;
  }
  const elementConfig = baseElementsConfig[element.type];
  if (!elementConfig) {
    return null;
  }
  const elementScope = getState(elementConfig);
  if (!elementScope) {
    return null;
  }

  if (elementScope.length === 0) {
    return null;
  }

  return {
    label: elementConfig.getLabel(element),
    help: elementConfig.getHelpText(element),
    options: elementScope,
  };
};

export const getScopeForElement = (
  element: any,
  project: any,
  elementPath: any,
  context: any,
) =>
  getScopeTypeForElement(
    element,
    project,
    elementPath,
    context,
    (elementConfig: any) =>
      elementConfig.deriveState(element, project, elementPath, context),
  );

export const buildScope = (
  project: Project,
  elementPath: ElementPath,
  includeSelf = false,
  onlyIncludeSelf = false,
  context: any,
) => {
  const defaultScope = [];

  const userOptions = context.getDataTypeOptions(
    new StateItem({
      dataType: 'user',
      id: AUTH_WRAPPER_ID,
      path: '',
      source: DATABASE,
      display: getText('elements.PAGE.state.user'),
    }),
  );

  if (userOptions.length > 0) {
    defaultScope.push({
      label: getText('elements.PAGE.state.user'),
      help: getText('elements.PAGE.state.help'),
      options: userOptions,
    });
  }

  const path = includeSelf ? elementPath : initial(elementPath);

  if (onlyIncludeSelf) {
    return getScopeForElementPath(project, elementPath, context);
  }

  return path
    .reduce((scope: any, pathSegment: any, pathIndex: any) => {
      if (pathSegment === 'children') {
        return scope;
      }
      const scopeForPath = getScopeForElementPath(
        project,
        path.slice(0, pathIndex + 1),
        context,
      );
      return scopeForPath ? [scopeForPath, ...scope] : scope;
    }, defaultScope)
    .filter(Boolean);
};

const getFieldName = (field: any) => {
  return field.relatedField &&
    field.relationship &&
    isReverseMultiRelationship(field.relatedField.relationship)
    ? `${field.name}Collection`
    : field.name;
};

/*

Transforms this:
{
 totalCount: 1,
 edges: [{ node: { id: 1, uuid: 2, user: {id: 3, uuid: '4444'}],
}

into this:
{
 totalCount: 1,
 edges: [{ node: { id: 1, uuid: 2, user: {id: 3, uuid: '4444'}],
 _columns: { id: [1], uuid: [2], user: { id: [3], uuid: ['4444'] }},
}
 */
const transformColumnarScopeItem = (
  value: CollectionConnection,
  field: DataField,
  dataTypes: DataTypes,
) => {
  const dataType = dataTypes.getByName(field.type);
  if (!dataType) {
    return value;
  }

  if (!value || !value.edges || value.edges.length === 0) {
    return value;
  }

  const transformedValue: any = { ...value };

  transformedValue['_columns'] = dataType.fields.reduce(
    (acc: any, subField: any) => {
      const fieldName = getFieldName(subField);

      if (!subField.relationship && !subField.relatedField) {
        acc[fieldName] = value.edges
          .reduce((subAcc: any, o: any) => {
            if (o.node[fieldName] === undefined) {
              return subAcc;
            }

            if (subField.type === MULTIPLE_OPTION) {
              return subAcc.concat(o.node[fieldName]);
            }

            const formattedValue = formatValue(
              o.node[fieldName],
              subField.type,
              undefined,
              true,
              dataTypes,
              [fieldName],
              field.multiple,
            );

            return subAcc.concat([formattedValue]);
          }, [])
          .filter((i: any) => !isNil(i));

        return acc;
      }

      const fieldDataType = dataTypes.getByName(subField.type);

      if (!fieldDataType) {
        return acc;
      }

      if (isMultiField(subField)) {
        acc[fieldName] = {
          _columns: fieldDataType.fields
            .filter(
              (subFieldField: any) =>
                !subFieldField.relationship && !subFieldField.relatedField,
            )
            .reduce((subAcc: any, subFieldField) => {
              subAcc[subFieldField.name] = value.edges
                .reduce(
                  (multiAcc: any, o: any) =>
                    multiAcc.concat(
                      get(o, ['node', fieldName, 'edges'], []).map(
                        (subEdge: any) =>
                          !isNil(get(subEdge, ['node', subFieldField.name]))
                            ? formatValue(
                                get(subEdge, ['node', subFieldField.name]),
                                subFieldField.type,
                                undefined,
                                true,
                                dataTypes,
                                [subFieldField.name],
                                subFieldField.multiple,
                              )
                            : null,
                      ),
                    ),
                  [],
                )
                .filter((i: any) => !isNil(i));
              return subAcc;
            }, {}),
        };
        return acc;
      }

      acc[fieldName] = fieldDataType.fields
        .filter(
          (subFieldField: any) =>
            !subFieldField.relationship && !subFieldField.relatedField,
        )
        .reduce((subAcc: any, subFieldField) => {
          subAcc[subFieldField.name] = value.edges
            .map((o: any) =>
              !isNil(o.node[fieldName])
                ? formatValue(
                    o.node[fieldName][subFieldField.name],
                    subFieldField.type,
                    undefined,
                    true,
                    dataTypes,
                    [subFieldField.name],
                    subFieldField.multiple,
                  )
                : null,
            )
            .filter((i: any) => !isNil(i));
          return subAcc;
        }, {});
      return acc;
    },
    {},
  );

  return transformedValue;
};

export const transformColumnarScope = <T extends BaseRecord>(
  data: T,
  dataType: DataType,
  dataTypes: DataTypes,
): T & { _dataType: string } => {
  if (!data) {
    return data;
  }

  return dataType.fields.getFieldsWithRelations().reduce(
    (dataAcc: any, field: any) => {
      const fieldName = getFieldName(field);
      const value = data[fieldName];

      if ((!field.relationship && !field.relatedField) || value === undefined) {
        return dataAcc;
      }

      if (isMultiField(field)) {
        dataAcc[fieldName] = transformColumnarScopeItem(
          value as CollectionConnection,
          field,
          dataTypes,
        );
        return dataAcc;
      } else {
        const relatedType = dataTypes.getByName(field.type);
        if (relatedType) {
          dataAcc[fieldName] = transformColumnarScope(
            value as BaseRecord,
            relatedType,
            dataTypes,
          );
          return dataAcc;
        }
      }

      dataAcc[fieldName] = value;
      return dataAcc;
    },
    { _dataType: dataType.name, ...data },
  );
};

export const merge = (
  baseScope: Record<string, any>,
  scope: Record<string, any>,
): any => ({ ...baseScope, ...scope });
