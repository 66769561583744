import createReactComponent from '../createReactComponent';
export default createReactComponent(
  '3d-cube-sphere-off',
  'Icon3dCubeSphereOff',
  [
    ['path', { d: 'M6 17.6l-2 -1.1v-2.5', key: 'svg-0' }],
    ['path', { d: 'M4 10v-2.5l2 -1.1', key: 'svg-1' }],
    ['path', { d: 'M10 4.1l2 -1.1l2 1.1', key: 'svg-2' }],
    ['path', { d: 'M18 6.4l2 1.1v2.5', key: 'svg-3' }],
    ['path', { d: 'M20 14v2', key: 'svg-4' }],
    ['path', { d: 'M14 19.9l-2 1.1l-2 -1.1', key: 'svg-5' }],
    ['path', { d: 'M18 8.6l2 -1.1', key: 'svg-6' }],
    ['path', { d: 'M12 12v2.5', key: 'svg-7' }],
    ['path', { d: 'M12 18.5v2.5', key: 'svg-8' }],
    ['path', { d: 'M12 12l-2 -1.12', key: 'svg-9' }],
    ['path', { d: 'M6 8.6l-2 -1.1', key: 'svg-10' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-11' }],
  ],
);
