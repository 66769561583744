import createReactComponent from '../createReactComponent';
export default createReactComponent('address-book', 'IconAddressBook', [
  [
    'path',
    {
      d: 'M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 16h6', key: 'svg-1' }],
  ['path', { d: 'M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M4 8h3', key: 'svg-3' }],
  ['path', { d: 'M4 12h3', key: 'svg-4' }],
  ['path', { d: 'M4 16h3', key: 'svg-5' }],
]);
