import createReactComponent from '../createReactComponent';
export default createReactComponent('tools-kitchen-2', 'IconToolsKitchen2', [
  [
    'path',
    {
      d: 'M19 3v12h-5c-.023 -3.681 .184 -7.406 5 -12zm0 12v6h-1v-3m-10 -14v17m-3 -17v3a3 3 0 1 0 6 0v-3',
      key: 'svg-0',
    },
  ],
]);
