import createReactComponent from '../createReactComponent';
export default createReactComponent('chart-circles', 'IconChartCircles', [
  [
    'path',
    {
      d: 'M9.5 9.5m-5.5 0a5.5 5.5 0 1 0 11 0a5.5 5.5 0 1 0 -11 0',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14.5 14.5m-5.5 0a5.5 5.5 0 1 0 11 0a5.5 5.5 0 1 0 -11 0',
      key: 'svg-1',
    },
  ],
]);
