import createReactComponent from '../createReactComponent';
export default createReactComponent('color-swatch-off', 'IconColorSwatchOff', [
  [
    'path',
    {
      d: 'M13 13v4a4 4 0 0 0 6.832 2.825m1.168 -2.825v-12a2 2 0 0 0 -2 -2h-4a2 2 0 0 0 -2 2v4',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M13 7.35l-2 -2a2 2 0 0 0 -2.11 -.461m-2.13 1.874l-1.416 1.415a2 2 0 0 0 0 2.828l9 9',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M7.3 13h-2.3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h12', key: 'svg-2' },
  ],
  ['path', { d: 'M17 17v.01', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
