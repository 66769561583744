import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-shopee', 'IconBrandShopee', [
  [
    'path',
    {
      d: 'M4 7l.867 12.143a2 2 0 0 0 2 1.857h10.276a2 2 0 0 0 2 -1.857l.867 -12.143h-16z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M8.5 7c0 -1.653 1.5 -4 3.5 -4s3.5 2.347 3.5 4', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M9.5 17c.413 .462 1 1 2.5 1s2.5 -.897 2.5 -2s-1 -1.5 -2.5 -2s-2 -1.47 -2 -2c0 -1.104 1 -2 2 -2s1.5 0 2.5 1',
      key: 'svg-2',
    },
  ],
]);
