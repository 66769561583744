import createReactComponent from '../createReactComponent';
export default createReactComponent('magnet-off', 'IconMagnetOff', [
  [
    'path',
    {
      d: 'M7 3a2 2 0 0 1 2 2m0 4v4a3 3 0 0 0 5.552 1.578m.448 -3.578v-6a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v8a7.99 7.99 0 0 1 -.424 2.577m-1.463 2.584a8 8 0 0 1 -14.113 -5.161v-8c0 -.297 .065 -.58 .181 -.833',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 8h4', key: 'svg-1' }],
  ['path', { d: 'M15 8h4', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
