import createReactComponent from '../createReactComponent';
export default createReactComponent('square-rounded', 'IconSquareRounded', [
  [
    'path',
    {
      d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
      key: 'svg-0',
    },
  ],
]);
