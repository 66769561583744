import React, { forwardRef, memo } from 'react';
import classNames from 'classnames';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CUSTOM_VISIBILITY_RULES } from '../../constants/features';
import {
  shouldCheckVisibilityRules,
  shouldRenderComponent,
} from '../../utils/elementVisibility';
import useMergedScope from '../../utils/hooks/useMergedScope';
import useScopeUser from '../../utils/hooks/useScopeUser';

const withVisibilityRules = (
  WrappedComponent: any,
  editorMode: any,
  visibilityRules: any,
) => {
  if (!visibilityRules) {
    return WrappedComponent;
  }

  if (!shouldCheckVisibilityRules(visibilityRules)) {
    return WrappedComponent;
  }

  const WithVisibilityRules = memo(
    forwardRef(
      // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
      ({ className, project, visibilityRulesScope = {}, ...rest }, ref) => {
        if (!project) {
          throw new Error(
            `withVisibilityRules: project prop not set on ${
              WrappedComponent.displayName || 'component'
            }`,
          );
        }

        const currentUser = useScopeUser();
        const scope = useMergedScope(visibilityRulesScope);
        const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
        const shouldRender = shouldRenderComponent(
          currentUser,
          visibilityRules,
          project,
          scope,
          customRulesEnabled,
        );

        if (!editorMode && !shouldRender) {
          return null;
        }

        return (
          <WrappedComponent
            {...rest}
            className={classNames(className, {
              'opacity-50': !shouldRender,
            })}
            project={project}
            scope={scope}
            ref={ref}
          />
        );
      },
    ),
  );

  WithVisibilityRules.displayName = 'WithVisibilityRules';

  return WithVisibilityRules;
};

export default withVisibilityRules;
