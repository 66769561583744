import createReactComponent from '../createReactComponent';
export default createReactComponent('tower', 'IconTower', [
  [
    'path',
    {
      d: 'M5 3h1a1 1 0 0 1 1 1v2h3v-2a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2h3v-2a1 1 0 0 1 1 -1h1a1 1 0 0 1 1 1v4.394a2 2 0 0 1 -.336 1.11l-1.328 1.992a2 2 0 0 0 -.336 1.11v7.394a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1v-7.394a2 2 0 0 0 -.336 -1.11l-1.328 -1.992a2 2 0 0 1 -.336 -1.11v-4.394a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 21v-5a2 2 0 1 1 4 0v5', key: 'svg-1' }],
]);
