import React, { useCallback, useMemo, useState } from 'react';
import { IconArrowBarToDown } from '@tabler/icons-react';
import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';
import { Button, Popover, SelectInput, TextInput } from '@noloco/components';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  ARRAY,
  DataSchemaField,
  ID_FIELD_TYPES,
  NESTED,
  RelationshipOption,
} from '@noloco/core/src/constants/dataSchema';
import dataTypes, {
  DATE,
  DECIMAL,
  DataFieldType,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import { DEFAULT_FIELDS } from '@noloco/core/src/constants/defaultFields';
import { MANY_TO_ONE } from '@noloco/core/src/constants/relationships';
import {
  buildFields,
  getSampleOptions,
} from '@noloco/core/src/utils/dataSchema';
import { getText } from '@noloco/core/src/utils/lang';
import DataFieldIcon from '../../DataFieldIcon';

const LANG_KEY = 'data.dataSchema.fields';

type Props = {
  children: React.ReactNode;
  disabled: boolean;
  field: DataSchemaField;
  idField: string | null;
  includeDefault: boolean;
  includeEmpty: boolean;
  included: boolean;
  onIncludeField: () => void;
  onReplaceField: (newFields: DataSchemaField[]) => void;
  relationshipOptions: RelationshipOption[] | undefined;
  sample: any[];
};

const DataSchemaFieldPopover = ({
  children,
  disabled,
  field,
  idField,
  includeDefault,
  includeEmpty,
  included,
  onIncludeField,
  onReplaceField,
  relationshipOptions,
  sample,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const onOpenChange = useCallback(
    (isOpen) => {
      setIsOpen(isOpen);
    },
    [setIsOpen],
  );

  const onChangeFieldType = useCallback(
    (type: string) => {
      onReplaceField([
        {
          ...field,
          options: getSampleOptions(type, sample),
          relationship:
            type === FILE || !dataTypes.includes(type as DataFieldType)
              ? MANY_TO_ONE
              : null,
          type,
        },
      ]);
    },
    [field, onReplaceField, sample],
  );

  const onDeleteField = useCallback(() => onReplaceField([]), [onReplaceField]);
  const onChangeIncluded = useCallback(() => {
    if (included) {
      onDeleteField();
      setIsOpen(false);
    } else {
      onIncludeField();
    }
  }, [included, onDeleteField, onIncludeField]);

  const onFlattenField = useCallback(() => {
    const nestedFields = buildFields(field.path, sample, idField);

    if (nestedFields) {
      onReplaceField(nestedFields);
    } else {
      onDeleteField();
    }
  }, [field.path, idField, onDeleteField, onReplaceField, sample]);

  const couldBeSingleFile = useMemo(() => field.type === TEXT, [field.type]);

  const fieldTypeOptions = useMemo(() => {
    const options = [
      field.type,
      ...(field.type !== DATE ? [DATE] : []),
      ...(field.type !== DECIMAL ? [DECIMAL] : []),
      ...(field.type !== INTEGER ? [INTEGER] : []),
      ...(field.type !== TEXT ? [TEXT] : []),
      ...(couldBeSingleFile ? [FILE] : []),
      ...(field.type === TEXT ? [SINGLE_OPTION] : []),
    ].map((type) => ({
      label: (
        <span className="flex space-x-2">
          <DataFieldIcon
            className="my-auto"
            field={{ ...field, type } as any}
            size={14}
          />
          <p>{getText('data.types', type, 'label')}</p>
        </span>
      ),
      value: type,
    }));

    if (
      ID_FIELD_TYPES.includes(field.type) &&
      relationshipOptions &&
      relationshipOptions.length > 0
    ) {
      options.push(...relationshipOptions);
    }

    return options;
  }, [couldBeSingleFile, field, relationshipOptions]);

  const couldBeMultipleOption = useMemo(() => {
    if (field.type !== ARRAY) {
      return false;
    }

    return sample.every((sampleValue) => {
      if (!isArray(sampleValue)) {
        return false;
      }

      return sampleValue.every((element) => typeof element === 'string');
    });
  }, [field.type, sample]);

  const conflictsWithDefaultField = useMemo(
    () => DEFAULT_FIELDS.includes(camelCase(field.name)),
    [field.name],
  );

  const canInclude = useMemo(() => {
    if (!includeDefault && conflictsWithDefaultField) {
      return false;
    }

    if (!includeEmpty && !field.name) {
      return false;
    }

    return true;
  }, [conflictsWithDefaultField, field.name, includeDefault, includeEmpty]);

  return (
    <Popover
      className="w-72 overflow-hidden bg-white"
      content={
        <div className="flex w-full p-2 text-left">
          <div className="flex w-full flex-col space-y-4 py-2">
            <div className="mx-2 flex flex-col space-y-2">
              <span className="text-xs text-gray-600">
                {getText(LANG_KEY, 'display.help')}
              </span>
              <TextInput
                disabled={true}
                readOnly={true}
                value={field.display}
              />
            </div>
            {included &&
              ![ARRAY, MULTIPLE_OPTION, NESTED].includes(field.type) &&
              fieldTypeOptions.length > 1 && (
                <div className="mx-2 flex flex-col space-y-2">
                  <span className="text-xs text-gray-600">
                    {getText(LANG_KEY, 'type.help')}
                  </span>
                  <SelectInput
                    placeholder={getText(LANG_KEY, 'relate.placeholder')}
                    onChange={onChangeFieldType}
                    options={fieldTypeOptions}
                    value={field.type}
                  />
                </div>
              )}
            {included && field.type === ARRAY && (
              <>
                {couldBeMultipleOption && (
                  <button
                    className="mx-2 flex items-center rounded-lg px-2 py-2 text-left text-xs hover:bg-gray-100"
                    onClick={() => onChangeFieldType(MULTIPLE_OPTION)}
                  >
                    <DataFieldIcon
                      className="mr-4 flex-shrink-0 opacity-75"
                      field={{
                        ...field,
                        id: 0,
                        options: undefined,
                        type: MULTIPLE_OPTION,
                      }}
                      size={16}
                    />
                    <span className="w-72">
                      {getText(LANG_KEY, 'multipleOption.label')}
                    </span>
                  </button>
                )}
              </>
            )}
            {included && field.type === NESTED && (
              <button
                className="mx-2 flex items-center rounded-lg px-2 py-2 text-left text-xs hover:bg-gray-100"
                onClick={onFlattenField}
              >
                <IconArrowBarToDown
                  size={16}
                  className="mr-4 flex-shrink-0 opacity-75"
                />
                <span className="w-72">
                  {getText(LANG_KEY, 'flatten.label')}
                </span>
              </button>
            )}
            <div className="mx-2 flex flex-col space-y-2 text-xs">
              <span className="text-gray-600">
                {included
                  ? getText(LANG_KEY, 'exclude.help')
                  : getText(LANG_KEY, 'include.help')}
              </span>
              <Button
                disabled={!canInclude}
                onClick={onChangeIncluded}
                variant={SECONDARY}
              >
                {included
                  ? getText(LANG_KEY, 'exclude.label')
                  : getText(LANG_KEY, 'include.label')}
              </Button>
            </div>
          </div>
        </div>
      }
      closeOnOutsideClick={true}
      disabled={disabled}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      p={0}
      rounded="lg"
      placement="bottom-start"
      shadow="lg"
      showArrow={false}
      trigger="none"
    >
      <span onClick={() => setIsOpen(true)}>{children}</span>
    </Popover>
  );
};

export default DataSchemaFieldPopover;
