import React, { forwardRef } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import * as SVGLoaders from 'svg-loaders-react';
import { LG, MD, SM, XL, XS } from '../../constants/tShirtSizes';
import Dots from './Dots';

const sizeStyles = {
  [XS]: { h: 4, w: 4 },
  [SM]: { h: 6, w: 6 },
  [MD]: { h: 7, w: 7 },
  [LG]: { h: 12, w: 12 },
  [XL]: { h: 16, w: 16 },
};

const Loaders = {
  ...SVGLoaders,
  Dots,
};

type Props = {
  type?: any; // TODO: PropTypes.oneOf(Object.keys(SVGLoaders))
  size?: any;
  className?: string;
};

const Loader = forwardRef<any, Props>(
  ({ className, size, type, ...rest }, ref) => {
    const LoadingComponent = Loaders[type] || SVGLoaders.Oval;

    return (
      <Box
        className={classNames(className)}
        ref={ref}
        {...(sizeStyles[size] || {})}
        {...rest}
        data-testid="loader"
      >
        <LoadingComponent
          stroke="currentColor"
          fill="currentColor"
          height="100%"
          width="100%"
        />
      </Box>
    );
  },
);

Loader.defaultProps = {
  size: MD,
  type: 'Oval',
};

export default Loader;
