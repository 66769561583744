import get from 'lodash/get';
import * as aggregationTypes from '../constants/aggregationTypes';
import { Aggregation } from '../constants/aggregationTypes';
import {
  COUNT,
  ChartAggregation,
  MAXIMUM,
  MINIMUM,
} from '../constants/chartAggregations';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  DataFieldType,
  INTEGER,
  NUMERIC_DATATYPES,
  TEXT,
} from '../constants/dataTypes';
import { FieldTypeOptions } from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { RecordEdge } from '../models/Record';
import { BaseRollup } from '../models/Rollup';
import StateItem from '../models/StateItem';
import {
  durationToString,
  getDurationFromString,
  millisecondsToDuration,
} from './durations';
import { isMultiField } from './relationships';
import { getDependencies } from './rollups';

const getDefaultFieldTypeForAggregation = (
  aggregation: Aggregation,
): DataFieldType => {
  switch (aggregation) {
    case aggregationTypes.AND:
    case aggregationTypes.OR:
      return BOOLEAN;
    case aggregationTypes.CONCATENATE:
      return TEXT;
    case aggregationTypes.COUNT:
      return INTEGER;
    default:
      return DECIMAL;
  }
};

const getAggregationFieldTypeFromFieldType = (
  fieldType: DataFieldType,
): DataFieldType => {
  switch (fieldType) {
    case DATE:
      return DATE;
    case DURATION:
      return DURATION;
    case TEXT:
    case DECIMAL:
    case INTEGER:
    default:
      return DECIMAL;
  }
};

export const getAggregationFieldType = (
  rollup: BaseRollup,
  dataType: DataType,
  dataTypes: DataTypes,
): { type: DataFieldType; typeOptions?: FieldTypeOptions } => {
  if (!dataType) {
    return { type: getDefaultFieldTypeForAggregation(rollup.aggregation) };
  }

  const dependencies = getDependencies(rollup, dataType, dataTypes);

  if (!dependencies) {
    return { type: getDefaultFieldTypeForAggregation(rollup.aggregation) };
  }

  const { relatedField, rolledUpField } = dependencies;

  if (rollup.aggregation === aggregationTypes.COUNT) {
    return { type: INTEGER };
  }

  if (rollup.aggregation === aggregationTypes.CONCATENATE) {
    return { type: TEXT };
  }

  if (
    NUMERIC_DATATYPES.includes(rolledUpField.type) &&
    [aggregationTypes.MAX, aggregationTypes.MIN].includes(rollup.aggregation)
  ) {
    return {
      type: rolledUpField.type as DataFieldType,
      typeOptions: rolledUpField.typeOptions,
    };
  }

  if (
    rolledUpField &&
    NUMERIC_DATATYPES.includes(rolledUpField.type) &&
    [aggregationTypes.SUM].includes(rollup.aggregation)
  ) {
    return {
      type: rolledUpField.type as DataFieldType,
      typeOptions: {
        ...rolledUpField.typeOptions,
        min: undefined,
        max: undefined,
      },
    };
  }

  if (
    [aggregationTypes.OR, aggregationTypes.AND].includes(rollup.aggregation)
  ) {
    return { type: BOOLEAN };
  }

  if (isMultiField(relatedField)) {
    if (rolledUpField) {
      return {
        type: getAggregationFieldTypeFromFieldType(
          rolledUpField.type as DataFieldType,
        ),
      };
    }
  }

  return { type: getDefaultFieldTypeForAggregation(rollup.aggregation) };
};

export const validAggregationsForFieldType = (
  fieldType: DataFieldType,
): aggregationTypes.Aggregation[] => {
  switch (fieldType) {
    case DECIMAL:
    case INTEGER:
    case TEXT:
      return [
        aggregationTypes.SUM,
        aggregationTypes.COUNT,
        aggregationTypes.MAX,
        aggregationTypes.MIN,
        aggregationTypes.AVERAGE,
        aggregationTypes.CONCATENATE,
      ];
    case DATE:
      return [
        aggregationTypes.COUNT,
        aggregationTypes.MAX,
        aggregationTypes.MIN,
      ];
    case DURATION:
      return [
        aggregationTypes.SUM,
        aggregationTypes.COUNT,
        aggregationTypes.MAX,
        aggregationTypes.MIN,
        aggregationTypes.AVERAGE,
      ];
    case BOOLEAN:
      return [aggregationTypes.OR, aggregationTypes.AND];
    default:
      return [];
  }
};

export const aggregateData = (
  data: any[],
  aggregation: aggregationTypes.Aggregation | ChartAggregation,
  type: string,
): number | string | null => {
  switch (type) {
    case 'INTEGER':
    case 'DECIMAL':
      return aggregateNumericalData(data, aggregation);
    case 'DURATION':
      if (aggregation === aggregationTypes.COUNT) {
        return data.length;
      } else {
        const result = aggregateNumericalData(
          data.map((val) => getDurationFromString(val)?.toMillis()) as number[],
          aggregation,
        );

        return result !== null
          ? durationToString(millisecondsToDuration(result))
          : null;
      }
    default:
      return null;
  }
};

export const aggregateNumericalData = (
  nullableData: (number | null)[],
  aggregation: aggregationTypes.Aggregation | ChartAggregation,
): number | null => {
  const data: number[] = [...nullableData].filter(
    (value) => value !== null,
  ) as number[];

  if (data.length === 0) {
    return aggregation === aggregationTypes.COUNT ||
      aggregation === aggregationTypes.SUM
      ? 0
      : null;
  }

  switch (aggregation) {
    case aggregationTypes.COUNT:
      return data.filter((datum: any) => datum !== null && datum !== undefined)
        .length;

    case aggregationTypes.AVERAGE: {
      const count = aggregateNumericalData(data, aggregationTypes.COUNT);

      if (count === 0 || count === null) {
        return null;
      }

      const sum = aggregateNumericalData(data, aggregationTypes.SUM) ?? 0;

      return sum / count;
    }
    case MINIMUM:
    case aggregationTypes.MIN:
      return data.reduce((min, value) => Math.min(min, value), data[0]);
    case MAXIMUM:
    case aggregationTypes.MAX:
      return data.reduce((max, value) => Math.max(max, value), data[0]);
    default:
    case aggregationTypes.SUM:
      return data.reduce((sum, value) => sum + (value ?? 0), 0);
  }
};

export const aggregateDataForCollectionSummary = (
  path: string,
  rows: RecordEdge[],
  aggregation: ChartAggregation,
  type: string,
  valueFormatter: (
    field: Pick<StateItem, 'path' | 'dataType'>,
    rawValue: any,
  ) => string,
) => {
  const groupData = rows
    .map(({ node }: RecordEdge) => get(node, path))
    .filter(Boolean);

  const summaryValue = aggregateData(groupData, aggregation, type);

  if (summaryValue === null) {
    return null;
  }

  if (aggregation === COUNT) {
    return summaryValue;
  }

  return valueFormatter({ dataType: type, path }, summaryValue);
};
