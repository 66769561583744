import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circle-arrow-down-right',
  'IconCircleArrowDownRight',
  [
    ['path', { d: 'M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M15 15h-6', key: 'svg-1' }],
    ['path', { d: 'M15 9v6l-6 -6', key: 'svg-2' }],
  ],
);
