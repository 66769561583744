import React from 'react';
import classNames from 'classnames';
import { Button } from '@noloco/components';
import { darkModeColors } from '../../../../constants/darkModeColors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import { getText } from '../../../../utils/lang';

type OwnProps = {
  showSummary?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof RowPagination.defaultProps;

// @ts-expect-error TS(7022): 'RowPagination' implicitly has type 'any' because ... Remove this comment to see the full error message
const RowPagination = ({
  className,
  showSummary,
  pageInfo,
  totalCount,
  currentLength,
  setPaginationQuery,
}: Props) => {
  const [isDarkModeEnabled] = useDarkMode();
  if (!pageInfo) {
    return null;
  }

  const buttonBorderColor = isDarkModeEnabled
    ? darkModeColors.borders.two
    : 'border-gray-300';

  const buttonBackgroundColor = isDarkModeEnabled
    ? darkModeColors.surfaces.elevation1
    : '';

  const buttonTextColor = isDarkModeEnabled
    ? darkModeColors.text.secondary
    : 'text-gray-600';

  return (
    <div className={classNames(className, 'flex items-center px-6 py-2')}>
      {showSummary && (
        <span className="text-xs font-medium text-gray-500">
          {getText(
            { count: currentLength, totalCount },
            'core.COLLECTION.pagination.showing',
          )}
        </span>
      )}
      <div className="flex items-center">
        <Button
          className={classNames(
            'rounded-lg border-2 font-medium disabled:opacity-50',
            buttonBorderColor,
            buttonBackgroundColor,
            buttonTextColor,
          )}
          disabled={!pageInfo.hasPreviousPage}
          variant="custom"
          onClick={() => setPaginationQuery({ before: pageInfo.startCursor })}
          size="sm"
          p={{ x: 3, y: 1.5 }}
        >
          {getText('core.COLLECTION.pagination.previous')}
        </Button>
        <Button
          className={classNames(
            'ml-2 rounded-lg border-2 font-medium disabled:opacity-50',
            buttonBorderColor,
            buttonBackgroundColor,
            buttonTextColor,
          )}
          disabled={!pageInfo.hasNextPage}
          variant="custom"
          onClick={() => setPaginationQuery({ after: pageInfo.endCursor })}
          size="sm"
          p={{ x: 3, y: 1.5 }}
        >
          {getText('core.COLLECTION.pagination.next')}
        </Button>
      </div>
    </div>
  );
};

RowPagination.defaultProps = {
  showSummary: true,
};

export default RowPagination;
