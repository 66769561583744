import createReactComponent from '../createReactComponent';
export default createReactComponent('code-asterix', 'IconCodeAsterix', [
  [
    'path',
    {
      d: 'M6 19a2 2 0 0 1 -2 -2v-4l-1 -1l1 -1v-4a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 11.875l3 -1.687', key: 'svg-1' }],
  ['path', { d: 'M12 11.875v3.375', key: 'svg-2' }],
  ['path', { d: 'M12 11.875l-3 -1.687', key: 'svg-3' }],
  ['path', { d: 'M12 11.875l3 1.688', key: 'svg-4' }],
  ['path', { d: 'M12 8.5v3.375', key: 'svg-5' }],
  ['path', { d: 'M12 11.875l-3 1.688', key: 'svg-6' }],
  [
    'path',
    {
      d: 'M18 19a2 2 0 0 0 2 -2v-4l1 -1l-1 -1v-4a2 2 0 0 0 -2 -2',
      key: 'svg-7',
    },
  ],
]);
