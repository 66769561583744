import createReactComponent from '../createReactComponent';
export default createReactComponent('chalkboard', 'IconChalkboard', [
  [
    'path',
    {
      d: 'M8 19h-3a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v11a1 1 0 0 1 -1 1',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M11 16m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
]);
