import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'creative-commons-zero',
  'IconCreativeCommonsZero',
  [
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M12 12m-3 0a3 4 0 1 0 6 0a3 4 0 1 0 -6 0', key: 'svg-1' }],
    ['path', { d: 'M14 9l-4 6', key: 'svg-2' }],
  ],
);
