import { useCallback, useEffect } from 'react';

const useIsWindowOnline = (
  handleConnectionChange: (isOnline: boolean) => void,
) => {
  const onOnline = useCallback(
    () => handleConnectionChange(true),
    [handleConnectionChange],
  );
  const onOffline = useCallback(
    () => handleConnectionChange(false),
    [handleConnectionChange],
  );

  useEffect(() => {
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, [onOnline, onOffline]);
};

export default useIsWindowOnline;
