import createReactComponent from '../createReactComponent';
export default createReactComponent('artboard', 'IconArtboard', [
  [
    'path',
    {
      d: 'M8 8m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 8l1 0', key: 'svg-1' }],
  ['path', { d: 'M3 16l1 0', key: 'svg-2' }],
  ['path', { d: 'M8 3l0 1', key: 'svg-3' }],
  ['path', { d: 'M16 3l0 1', key: 'svg-4' }],
  ['path', { d: 'M20 8l1 0', key: 'svg-5' }],
  ['path', { d: 'M20 16l1 0', key: 'svg-6' }],
  ['path', { d: 'M8 20l0 1', key: 'svg-7' }],
  ['path', { d: 'M16 20l0 1', key: 'svg-8' }],
]);
