import React from 'react';
import useScopeVariables from '../../utils/hooks/useScopeVariables';
import { getText } from '../../utils/lang';
import DeleteItemConfirmButton from './DeleteItemConfirmButton';

type Props = {};

const DeleteConfirmButtonWrapper = ({
  // @ts-expect-error TS(2339): Property 'scope' does not exist on type 'Props'.
  scope,
  // @ts-expect-error TS(2339): Property 'element' does not exist on type 'Props'.
  element,
  // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type 'Pro... Remove this comment to see the full error message
  elementPath,
  // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Props'.
  project,
  ...rest
}: Props) => {
  const { confirmDeleteText } = useScopeVariables(
    scope,
    element,
    project,
    elementPath,
  );

  return (
    <DeleteItemConfirmButton
      confirmDeleteText={confirmDeleteText}
      project={project}
      {...rest}
    >
      {getText('core.COLLECTION.form.deleteButton')}
    </DeleteItemConfirmButton>
  );
};

export default DeleteConfirmButtonWrapper;
