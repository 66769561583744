import useLocale, {
  getLocaleName,
} from '@noloco/components/src/utils/hooks/useLocale';
import baseElementsConfig from '../../elements/baseElementConfig';
import BaseElementConfig from '../../models/BaseElementConfig';
import { reduceDynamicPropValues } from '../elementPropResolvers';

const useScopeVariables = (
  scope: any,
  element: any,
  project: any,
  elementPath: any,
) => {
  const props = element.props;
  const elementConfig =
    baseElementsConfig[element.type] || new BaseElementConfig();

  const localeName = getLocaleName();
  const locale = useLocale();

  return reduceDynamicPropValues(
    elementConfig.props,
    props,
    scope,
    element,
    project,
    elementPath,
    true,
    undefined,
    localeName,
    locale,
  );
};

export default useScopeVariables;
