import createReactComponent from '../createReactComponent';
export default createReactComponent('social-off', 'IconSocialOff', [
  ['path', { d: 'M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M17.57 17.602a2 2 0 0 0 2.83 2.827', key: 'svg-2' }],
  ['path', { d: 'M11.113 11.133a3 3 0 1 0 3.765 3.715', key: 'svg-3' }],
  ['path', { d: 'M12 7v1', key: 'svg-4' }],
  ['path', { d: 'M6.7 17.8l2.8 -2', key: 'svg-5' }],
  ['path', { d: 'M17.3 17.8l-2.8 -2', key: 'svg-6' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
