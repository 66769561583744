export type PasswordValidationType =
  | 'tooShort'
  | 'tooLong'
  | 'numbers'
  | 'case'
  | 'symbol';
export const TOO_SHORT: PasswordValidationType = 'tooShort';
export const TOO_LONG: PasswordValidationType = 'tooLong';
export const NUMBERS: PasswordValidationType = 'numbers';
export const CASE: PasswordValidationType = 'case';
export const SYMBOL: PasswordValidationType = 'symbol';

const passwordValidationTypes: PasswordValidationType[] = [
  TOO_SHORT,
  TOO_LONG,
  NUMBERS,
  CASE,
  SYMBOL,
];

export default passwordValidationTypes;
