import { useCallback, useEffect, useState } from 'react';
import { ENTER, ESCAPE } from '../../constants/shortcuts';
import useIsMacOs from './useIsMacOs';

const useOnKeyPress = (
  targetKey: string,
  onKeyDown: (event: React.KeyboardEvent) => void,
  {
    onKeyUp,
    ctrlKey = false,
    shiftKey = false,
    enabled = true,
  }: {
    onKeyUp?: (event: React.KeyboardEvent) => void;
    ctrlKey?: boolean;
    shiftKey?: boolean;
    enabled?: boolean;
  } = {},
) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  const isMacOs = useIsMacOs();

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    (event: any) => {
      if (
        enabled &&
        event.key === targetKey &&
        shiftKey === event.shiftKey &&
        ((!isMacOs && event.ctrlKey === ctrlKey) ||
          (isMacOs && event.metaKey === ctrlKey))
      ) {
        setKeyPressed(true);
        if (
          onKeyDown &&
          (event.target.tagName !== 'INPUT' ||
            targetKey === ESCAPE ||
            targetKey === ENTER)
        ) {
          onKeyDown(event);
        }
      }
    },
    [enabled, targetKey, shiftKey, ctrlKey, isMacOs, onKeyDown],
  );

  // If released key is our target key then set to false
  const upHandler = useCallback(
    (event: any) => {
      if (
        enabled &&
        event.key === targetKey &&
        shiftKey === event.shiftKey &&
        ((!isMacOs && event.ctrlKey === ctrlKey) ||
          (isMacOs && event.metaKey === ctrlKey))
      ) {
        setKeyPressed(false);
        if (onKeyUp) {
          onKeyUp(event);
        }
      }
    },
    [enabled, targetKey, shiftKey, isMacOs, ctrlKey, onKeyUp],
  );

  // Add event listeners
  useEffect(() => {
    if (enabled) {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
    }
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [enabled, upHandler, downHandler]);

  return keyPressed;
};

export default useOnKeyPress;
