import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'temperature-fahrenheit',
  'IconTemperatureFahrenheit',
  [
    ['path', { d: 'M6 8m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M13 12l5 0', key: 'svg-1' }],
    ['path', { d: 'M20 6h-6a1 1 0 0 0 -1 1v11', key: 'svg-2' }],
  ],
);
