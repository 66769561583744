import createReactComponent from '../createReactComponent';
export default createReactComponent('submarine', 'IconSubmarine', [
  [
    'path',
    {
      d: 'M3 11v6h2l1 -1.5l3 1.5h10a3 3 0 0 0 0 -6h-10h0l-3 1.5l-1 -1.5h-2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 11l-1 -3h-5l-1 3', key: 'svg-1' }],
  ['path', { d: 'M13 8v-2a1 1 0 0 1 1 -1h1', key: 'svg-2' }],
]);
