import { useCallback, useState } from 'react';
import {
  ApolloClient,
  ApolloQueryResult,
  QueryOptions,
  useApolloClient,
} from '@apollo/client';

const usePromiseQuery = (
  query: any,
  options?: Omit<Partial<QueryOptions>, 'query'>,
): [
  (args?: Partial<QueryOptions>) => Promise<ApolloQueryResult<any>>,
  {
    client: ApolloClient<object>;
    called: boolean;
    data: ApolloQueryResult<any> | undefined;
  },
] => {
  const client = useApolloClient();
  const [result, setResult] = useState({
    client,
    called: false,
    data: undefined,
  });

  const triggerCallback = useCallback(
    (queryArgs?: Partial<QueryOptions>) =>
      client
        .query({
          query,
          ...options,
          ...queryArgs,
        })
        .then((queryResult) => {
          setResult({ client, called: true, ...queryResult });
          return queryResult;
        }),
    [client, options, query],
  );

  return [triggerCallback, result];
};

export default usePromiseQuery;
