import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router';
import { LeftEditorSectionOptions } from '../../constants/buildMode';
import { SINGLE_RECORD } from '../../constants/collectionLayouts';
import { DIVIDER, FOLDER, LINK, PAGE, VIEW } from '../../constants/elements';
import { setLeftEditorSection } from '../../reducers/elements';
import usePrevious from '../../utils/hooks/usePrevious';
import { Page } from '../../utils/pages';
import { LeftBuildModePageEditorProps } from './LeftBuildModePageEditor';
import { LeftBuildModeRecordEditorProps } from './LeftBuildModeRecordEditor';
import { BuildModeFormEditorProps } from './form/BuildModeFormEditor';
import { BuildModePageEditorProps } from './page/BuildModePageEditor';
import { BuildModeRecordEditorProps } from './record/BuildModeRecordEditor';
import { BuildModeViewEditorProps } from './view/BuildModeViewEditor';

type BuildModeEditorRoutingProps = {
  FormEditor?: (props: BuildModeFormEditorProps) => JSX.Element;
  leftEditor: LeftEditorSectionOptions | null;
  PageEditor: (
    props: BuildModePageEditorProps | LeftBuildModePageEditorProps,
  ) => JSX.Element | null;
  pages: Page[];
  RecordEditor: (
    props: BuildModeRecordEditorProps | LeftBuildModeRecordEditorProps,
  ) => JSX.Element;
  ViewEditor?: (props: BuildModeViewEditorProps) => JSX.Element;
};

const BuildModeEditorRouting = ({
  FormEditor,
  leftEditor,
  PageEditor,
  pages,
  RecordEditor,
  ViewEditor,
}: BuildModeEditorRoutingProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const prevLocation = usePrevious(location.pathname);
  const getParentPageRoute = useCallback(
    (id: string) => pages.find((page) => page.id === id)?.props.routePath,
    [pages],
  );

  const getElementPath = useCallback(
    (id: string) => [pages.findIndex((page) => page.id === id)],
    [pages],
  );

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      dispatch(setLeftEditorSection(null));
    }
  }, [location, prevLocation, dispatch]);

  const routes = useMemo(
    () =>
      pages
        .filter((page) => page.type !== DIVIDER && page.type !== LINK)
        .map((page) => {
          const routePath = `/${page.props.routePath}`;
          const parentPageRoute = getParentPageRoute(page.props.parentPage);
          const parentRoutePath = parentPageRoute && `/${parentPageRoute}`;
          const path = `${
            page.props.parentPage ? parentRoutePath : ''
          }${routePath}`;

          return {
            elementPath: getElementPath(page.id),
            id: page.id,
            page,
            parentPageId: page.props.parentPage,
            path,
            layout: page.props.layout,
            type: page.type,
            ...(page.type === VIEW
              ? {
                  formPath: `${path}/new`,
                  recordViewPath: `${path}/view/:uuid/:tab?`,
                }
              : {}),
          };
        }),
    [pages, getElementPath, getParentPageRoute],
  );

  return (
    <Switch>
      {routes
        .filter((route) => !route.parentPageId)
        .map((route) => (
          <Route key={route.id} path={route.path} exact={route.path === '/'}>
            {(route.type === VIEW || route.type === FOLDER) && (
              <Switch>
                {route.type === VIEW && FormEditor && (
                  <Route path={`${route.path}/new`}>
                    <FormEditor
                      elementPath={route.elementPath}
                      isRecordView={false}
                      leftEditor={leftEditor}
                      page={route.page}
                    />
                  </Route>
                )}
                <Route path={`${route.path}/view/:uuid/:tab?`}>
                  <RecordEditor
                    elementPath={route.elementPath}
                    leftEditor={leftEditor}
                    page={route.page}
                  />
                </Route>
                <Route path={route.path}>
                  <Switch>
                    {routes
                      .filter(
                        (childRoute) => childRoute.parentPageId === route.id,
                      )
                      .map((childRoute) => (
                        <Route
                          exact={false}
                          key={childRoute.id}
                          path={childRoute.path}
                        >
                          <Switch>
                            {childRoute.layout === SINGLE_RECORD && (
                              <Route path={`${childRoute.path}/:tab?`}>
                                <RecordEditor
                                  elementPath={childRoute.elementPath}
                                  leftEditor={leftEditor}
                                  page={childRoute.page}
                                />
                              </Route>
                            )}
                            {FormEditor && (
                              <Route path={`${childRoute.path}/new`}>
                                <FormEditor
                                  elementPath={childRoute.elementPath}
                                  isRecordView={false}
                                  leftEditor={leftEditor}
                                  page={childRoute.page}
                                />
                              </Route>
                            )}
                            <Route path={`${childRoute.path}/view/:uuid/:tab?`}>
                              <RecordEditor
                                elementPath={childRoute.elementPath}
                                leftEditor={leftEditor}
                                page={childRoute.page}
                              />
                            </Route>
                            {childRoute.type === PAGE && (
                              <Route path={`${childRoute.path}/:tab?`}>
                                <PageEditor
                                  elementPath={childRoute.elementPath}
                                  isRecordView={false}
                                  leftEditor={leftEditor}
                                  page={childRoute.page}
                                />
                              </Route>
                            )}
                            {ViewEditor && (
                              <Route>
                                <ViewEditor
                                  elementPath={childRoute.elementPath}
                                  isRecordView={false}
                                  leftEditor={leftEditor}
                                  page={childRoute.page}
                                />
                              </Route>
                            )}
                          </Switch>
                        </Route>
                      ))}
                    <Route>
                      <Switch>
                        {route.layout === SINGLE_RECORD && (
                          <Route path={`${route.path}/:tab?`}>
                            <RecordEditor
                              elementPath={route.elementPath}
                              leftEditor={leftEditor}
                              page={route.page}
                            />
                          </Route>
                        )}
                        {ViewEditor && (
                          <Route>
                            <ViewEditor
                              elementPath={route.elementPath}
                              isRecordView={false}
                              leftEditor={leftEditor}
                              page={route.page}
                            />
                          </Route>
                        )}
                      </Switch>
                    </Route>
                  </Switch>
                </Route>
              </Switch>
            )}
            {route.type === PAGE && (
              <Route path={`${route.path}/:tab?`}>
                <PageEditor
                  elementPath={route.elementPath}
                  isRecordView={false}
                  leftEditor={leftEditor}
                  page={route.page}
                />
              </Route>
            )}
          </Route>
        ))}
    </Switch>
  );
};

export default BuildModeEditorRouting;
