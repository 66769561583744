import React, { useMemo } from 'react';
import first from 'lodash/first';
import { FILE } from '../../../constants/builtInDataTypes';
import { IMAGE } from '../../../constants/elements';
import { FIELD } from '../../../constants/imageTypes';
import { DataType } from '../../../models/DataTypes';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { BaseRecord } from '../../../models/Record';
import { getImageFilesFromFieldValues } from '../../../utils/files';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { getText } from '../../../utils/lang';
import Image from '../../Image';
import RecordEmptyState from './RecordEmptyState';

type RecordImageProps = {
  dataType: DataType;
  editorMode: boolean;
  elementPath: ElementPath;
  image: any;
  project: Project;
  record: BaseRecord;
  recordScope: Record<string, any>;
};

const RecordImage = ({
  dataType,
  elementPath,
  editorMode,
  record,
  project,
  recordScope,
  image,
}: RecordImageProps) => {
  const rawImageProp = image?.value;
  const { image: resolvedImage } = useSectionScopeVariables(
    IMAGE,
    { image },
    project,
    elementPath,
    recordScope,
  );

  const recordImage = useMemo(() => {
    if (!rawImageProp || rawImageProp.hidden) {
      return undefined;
    }

    if (rawImageProp.type !== FIELD) {
      return resolvedImage;
    }

    if (rawImageProp.type === FIELD && rawImageProp.fieldName) {
      const fileField = dataType.fields.getByName(rawImageProp.fieldName);

      if (fileField && fileField.type === FILE) {
        const fileValues = getImageFilesFromFieldValues(fileField, record);
        const firstFile = first(fileValues);

        return firstFile ? { value: firstFile, hidden: false } : undefined;
      }
    }

    return resolvedImage;
  }, [dataType.fields, resolvedImage, rawImageProp, record]);

  const noImageFound = useMemo(
    () => !recordImage || !recordImage.value || !recordImage.value.src,
    [recordImage],
  );

  if (noImageFound && !editorMode) {
    return null;
  }

  if (noImageFound) {
    return <RecordEmptyState title={getText('elements.IMAGE.empty')} />;
  }

  return (
    <div className="flex w-full items-center justify-center py-6">
      <Image editorMode={editorMode} image={recordImage.value} />
    </div>
  );
};

export default RecordImage;
