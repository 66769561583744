import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { DataType } from '../../models/DataTypes';
import { Project } from '../../models/Project';
import {
  DELETE_COMMENT_MUTATION,
  getCreateCommentMutation,
} from '../../queries/comments';

type RecordCommentMutationsProps = {
  dataType: DataType;
  project: Project;
};

const useRecordCommentMutations = ({
  dataType,
  project,
}: RecordCommentMutationsProps) => {
  const createMutationGql = useMemo(
    () => getCreateCommentMutation(dataType),
    [dataType],
  );

  const context = useMemo(
    () => ({
      projectQuery: true,
      projectName: project.name,
    }),
    [project.name],
  );

  const [createCommentMutation] = useMutation(createMutationGql, { context });
  const [deleteComment] = useMutation(DELETE_COMMENT_MUTATION, { context });

  const createComment = useCallback(
    (recordId: any, text: any, attachments: any, internal: boolean) =>
      createCommentMutation({
        variables: {
          text: text ? text.trim() : null,
          attachments,
          recordId: recordId,
          internal: internal ?? undefined,
        },
      }),
    [createCommentMutation],
  );

  return {
    createComment,
    deleteComment,
    context,
  };
};

export default useRecordCommentMutations;
