import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-ipad-horizontal-x',
  'IconDeviceIpadHorizontalX',
  [
    [
      'path',
      {
        d: 'M13.5 20h-8.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M22 22l-5 -5', key: 'svg-1' }],
    ['path', { d: 'M17 22l5 -5', key: 'svg-2' }],
    ['path', { d: 'M9 17h4', key: 'svg-3' }],
  ],
);
