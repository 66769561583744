import React, { useCallback, useMemo } from 'react';
import { IconCornerRightDown, IconCornerUpRight } from '@tabler/icons-react';
import classNames from 'classnames';
import {
  FormField,
  Label,
  SelectInput,
  Surface,
  Switch,
} from '@noloco/components';
import HelpTooltip from '@noloco/components/src/components/popover/HelpTooltip';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { Relationship } from '@noloco/core/src/constants/relationships';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import {
  canBeAutoRelationshipLookupFieldOption,
  canBeAutoRelationshipSourceFieldOption,
} from '@noloco/core/src/utils/autoRelationships';
import { getText } from '@noloco/core/src/utils/lang';
import { getRelationshipFromMultiOpts } from '@noloco/core/src/utils/relationships';
import DataField from './DataField';
import { AutoRelationshipConfigInput } from './NewFieldForm';

const RelationshipOption = ({
  displayName,
  dataTypeDisplayName,
  value,
}: any) => (
  <div className="flex flex-col">
    <span data-testid="field-relationship-summary">
      {getText(
        { itemA: dataTypeDisplayName, itemB: displayName },
        'data.relationships',
        value,
        'itemA',
      )}
    </span>
    <span data-testid="field-relationship-reverse-summary">
      {getText(
        { itemA: dataTypeDisplayName, itemB: displayName },
        'data.relationships',
        value,
        'itemB',
      )}
    </span>
  </div>
);

type Props = {
  allowAutoRelationships?: boolean;
  autoRelationshipConfig?: AutoRelationshipConfigInput | null;
  dataType: DataType;
  dataTypes: DataTypes;
  displayName: string;
  isInternalDataSource?: boolean;
  isMulti: boolean;
  isReverseMulti: boolean;
  readOnly: boolean;
  relationship: Relationship;
  relatedType: DataType | null;
  requiresReverseName?: boolean;
  reverseName: string;
  reverseNameValidationMessage: string | null;
  setAutoRelationshipConfig?: (value: any) => void;
  setIsMulti: (value: boolean) => void;
  setIsReverseMulti: (value: boolean) => void;
  setRelationship: (value: Relationship) => void;
  setReverseName: (value: string) => void;
  surface: Surface;
  type: string | null;
};

const NewRelationshipFieldForm = ({
  allowAutoRelationships,
  autoRelationshipConfig,
  dataType,
  dataTypes,
  displayName,
  isMulti,
  isReverseMulti,
  readOnly,
  relationship,
  relatedType,
  requiresReverseName,
  reverseName,
  reverseNameValidationMessage,
  setAutoRelationshipConfig,
  setIsMulti,
  setIsReverseMulti,
  setRelationship,
  setReverseName,
  surface,
  type,
}: Props) => {
  const autoRelationshipLookupFieldOptions = useMemo(
    () =>
      relatedType &&
      relatedType.fields
        .filter(canBeAutoRelationshipLookupFieldOption)
        .map((field: any) => ({
          label: (
            <DataField
              dataTypes={dataTypes}
              dataTypeName={relatedType.display}
              field={field}
              key={field.name}
            />
          ),

          value: field.id,
        })),

    [dataTypes, relatedType],
  );

  const onIsMultiChange = useCallback(
    (newIsMulti) => {
      setIsMulti(newIsMulti);
      setRelationship(getRelationshipFromMultiOpts(newIsMulti, isReverseMulti));
    },
    [setIsMulti, setRelationship, isReverseMulti],
  );

  const onIsReverseMultiChange = useCallback(
    (newIsReverseMulti) => {
      setIsReverseMulti(newIsReverseMulti);
      setRelationship(getRelationshipFromMultiOpts(isMulti, newIsReverseMulti));
    },
    [isMulti, setRelationship, setIsReverseMulti],
  );

  const onToggleAutoRelationship = useCallback(
    (enabled) => {
      setAutoRelationshipConfig &&
        setAutoRelationshipConfig(
          enabled
            ? {
                sourceFieldId: null,
                lookupFieldId: null,
              }
            : null,
        );
    },
    [setAutoRelationshipConfig],
  );

  const setAutoRelationshipSourceFieldId = useCallback(
    (newSourceFieldId) =>
      setAutoRelationshipConfig &&
      setAutoRelationshipConfig((currentValue: any) => ({
        ...currentValue,
        sourceFieldId: newSourceFieldId,
      })),
    [setAutoRelationshipConfig],
  );

  const setAutoRelationshipLookupFieldId = useCallback(
    (newLookupFieldId) =>
      setAutoRelationshipConfig &&
      setAutoRelationshipConfig((currentValue: any) => ({
        ...currentValue,
        lookupFieldId: newLookupFieldId,
      })),
    [setAutoRelationshipConfig],
  );

  const autoRelationshipSourceFieldOptions = useMemo(
    () =>
      dataType.fields
        .filter(canBeAutoRelationshipSourceFieldOption)
        .map((field: any) => ({
          label: (
            <DataField
              dataTypes={dataTypes}
              dataTypeName={dataType.display}
              field={field}
              key={field.name}
            />
          ),

          value: field.id,
        })),
    [dataType.display, dataType.fields, dataTypes],
  );

  return (
    <div className="w-full" data-testid="field-relationship-editor">
      <div className="mt-4 flex flex-col space-y-2">
        <div className="flex items-center">
          <IconCornerUpRight
            size={16}
            className={classNames('mr-4 opacity-75', {
              'text-gray-200': surface === DARK,
            })}
          />
          <Label surface={surface} className="mt-1">
            {getText(
              'data.relationshipInput',
              type === FILE ? 'isMultiFile' : 'isMulti',
            )}
          </Label>
          <Switch
            className="ml-auto"
            data-testid="field-relationship-is-multi"
            size="sm"
            disabled={readOnly}
            onChange={onIsMultiChange}
            value={isMulti}
          />
        </div>
        {type !== FILE && (
          <div className="flex items-center">
            <IconCornerRightDown
              size={16}
              className={classNames('mr-4 opacity-75', {
                'text-gray-200': surface === DARK,
              })}
            />
            <Label surface={surface} className="mt-1">
              {getText(
                {
                  dataTypeNameA: dataType.display,
                  dataTypeNameB: relatedType?.display,
                },
                'data.relationshipInput.isReverseMulti',
              )}
            </Label>
            <Switch
              className="ml-auto"
              data-testid="field-relationship-is-reverse-multi"
              size="sm"
              disabled={readOnly}
              onChange={onIsReverseMultiChange}
              value={isReverseMulti}
            />
          </div>
        )}
      </div>
      {type !== FILE && (
        <div
          className={classNames('mt-4 rounded-lg p-4 text-sm', {
            'bg-gray-300 text-gray-900': surface === LIGHT,
            'bg-gray-700 text-gray-100': surface === DARK,
          })}
        >
          <RelationshipOption
            value={relationship}
            dataTypeDisplayName={dataType.display}
            displayName={displayName}
          />
        </div>
      )}
      {requiresReverseName && relatedType && (
        <>
          <div className="mb-2 mt-6 flex items-center">
            <IconCornerRightDown
              size={16}
              className={classNames('mr-4 opacity-75', {
                'text-gray-200': surface === DARK,
              })}
            />
            <Label surface={surface} className="mt-1">
              {getText(
                {
                  dataTypeNameB: relatedType.display,
                },
                'data.fields.reverse.label',
              )}
            </Label>
          </div>
          <FormField
            className="text-black"
            data-testid="field-relationship-reverse-name-input"
            disabled={readOnly}
            errorMessage={
              !reverseNameValidationMessage || !reverseName ? null : (
                <span className="text-white">
                  {reverseNameValidationMessage}
                </span>
              )
            }
            errorType="below-solid"
            label=""
            help={getText(
              {
                parentName: dataType.display,
                relationshipName: displayName,
                relationshipTypeName: relatedType.display,
              },
              'data.fields.reverse',
              relationship,
            )}
            placeholder={`${dataType.display}s`}
            value={reverseName}
            onChange={({ target: { value } }: any) => setReverseName(value)}
            surface={surface}
          />
        </>
      )}
      {allowAutoRelationships && type !== FILE && !isMulti && !readOnly && (
        <>
          <div className="mt-6 flex items-center">
            <Label surface={surface} m={0}>
              {getText('data.autoRelationship.label')}
            </Label>
            <HelpTooltip
              className={classNames('ml-2 hover:text-gray-500', {
                'text-gray-800': surface === LIGHT,
                'text-gray-200': surface === DARK,
              })}
              placement="right"
            >
              <p>{getText('data.autoRelationship.tooltip')}</p>
            </HelpTooltip>
            <Switch
              size="sm"
              data-testid="field-relationship-auto-relationship-switch"
              className="ml-auto"
              value={!!autoRelationshipConfig}
              onChange={onToggleAutoRelationship}
            />
          </div>
          {autoRelationshipConfig && (
            <>
              <div className="mt-4 items-center space-y-2">
                <Label
                  surface={surface}
                  m={0}
                  className="flex flex-shrink-0 items-center truncate"
                >
                  <span>
                    {getText('data.autoRelationship.sourceField.label')}
                  </span>
                </Label>
                <SelectInput
                  className="w-full"
                  data-testid="field-relationship-auto-relationship-source"
                  contained={true}
                  options={autoRelationshipSourceFieldOptions}
                  placeholder={getText(
                    'data.autoRelationship.sourceField.placeholder',
                  )}
                  onChange={setAutoRelationshipSourceFieldId}
                  value={(autoRelationshipConfig as any).sourceFieldId}
                  surface={surface}
                  size="sm"
                />
              </div>
              <div className="mt-4 items-center space-y-2">
                <Label
                  surface={surface}
                  m={0}
                  className="flex flex-shrink-0 items-center truncate"
                >
                  <span>
                    {getText('data.autoRelationship.lookupField.label')}
                  </span>
                </Label>
                <SelectInput
                  className="w-full"
                  data-testid="field-relationship-auto-relationship-target"
                  contained={true}
                  options={autoRelationshipLookupFieldOptions}
                  placeholder={getText(
                    'data.autoRelationship.lookupField.placeholder',
                  )}
                  onChange={setAutoRelationshipLookupFieldId}
                  value={(autoRelationshipConfig as any).lookupFieldId}
                  surface={surface}
                  size="sm"
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default NewRelationshipFieldForm;
