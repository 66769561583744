import { useCallback } from 'react';
import {
  ADD_COMMENT,
  COPY,
  CREATE,
  DELETE,
  NAVIGATE,
  ON_DEMAND,
  SCAN_BARCODE,
  UPDATE,
} from '../../constants/actionTypes';
import { Action, ScanActionType } from '../../models/Element';

export type ActionHandler = (
  action: Action,
  setIsLoading: (isLoading: boolean) => void,
) => void;

const useActions = (
  onCreate: any,
  onDelete: any,
  onNavigate: any,
  onNext: any,
  onRunWorkflow: ActionHandler,
  onUpdate: any,
  onCopy: ActionHandler,
  onAddComment: ActionHandler,
  onScan?: any,
  onFinish?: any,
) => {
  const onExecuteAction = useCallback(
    (
      action: Action,
      setIsLoading: (isLoading: boolean) => void,
      nextAction?: Action,
    ) => {
      if (!action) {
        return onNext();
      }

      switch (action.type) {
        case CREATE:
          return onCreate(action, setIsLoading);
        case DELETE:
          return onDelete(action, setIsLoading);
        case NAVIGATE:
          return onNavigate(action, setIsLoading);
        case ON_DEMAND:
          return onRunWorkflow(action, setIsLoading);
        case UPDATE:
          return onUpdate(action, setIsLoading);
        case ADD_COMMENT:
          return onAddComment(action, setIsLoading);
        case COPY:
          return onCopy(action, setIsLoading);
        case SCAN_BARCODE:
          if (
            action.scanActionType === ScanActionType.CREATE &&
            action.multiScan
          ) {
            if (nextAction) {
              return onNext();
            }

            return onFinish();
          }

          return onScan();
        default:
          return onNext();
      }
    },
    [
      onAddComment,
      onCopy,
      onCreate,
      onDelete,
      onFinish,
      onNavigate,
      onNext,
      onRunWorkflow,
      onScan,
      onUpdate,
    ],
  );

  return {
    onExecuteAction,
  };
};

export default useActions;
