import createReactComponent from '../createReactComponent';
export default createReactComponent('focus-2', 'IconFocus2', [
  [
    'circle',
    { cx: '12', cy: '12', r: '.5', fill: 'currentColor', key: 'svg-0' },
  ],
  ['path', { d: 'M12 12m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0', key: 'svg-1' }],
  ['path', { d: 'M12 3l0 2', key: 'svg-2' }],
  ['path', { d: 'M3 12l2 0', key: 'svg-3' }],
  ['path', { d: 'M12 19l0 2', key: 'svg-4' }],
  ['path', { d: 'M19 12l2 0', key: 'svg-5' }],
]);
