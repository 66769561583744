import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'currency-krone-danish',
  'IconCurrencyKroneDanish',
  [
    ['path', { d: 'M5 6v12', key: 'svg-0' }],
    ['path', { d: 'M5 12c3.5 0 6 -3 6 -6', key: 'svg-1' }],
    ['path', { d: 'M5 12c3.5 0 6 3 6 6', key: 'svg-2' }],
    ['path', { d: 'M15 10v8', key: 'svg-3' }],
    ['path', { d: 'M19 10a4 4 0 0 0 -4 4', key: 'svg-4' }],
    ['path', { d: 'M20 18.01v-.01', key: 'svg-5' }],
  ],
);
