import { ChartType, GAUGE, STATISTIC } from '../constants/chartTypes';
import { DataField } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';
import { DepValue } from '../models/Element';
import { isSingleSideOfRelationship } from './relationships';

export const getFieldPathFromPath = (path: string): string =>
  path.replace(/^edges\.(node\.)?/, '');

export const getFieldFromAxisValuePath = (
  axisValuePath: string,
  dataType: DataType,
): DataField | undefined | null => {
  if (!axisValuePath || !dataType) {
    return null;
  }
  const fieldName = getFieldPathFromPath(axisValuePath);
  return dataType.fields.getByName(fieldName);
};

export const isMetricChart = (chartType: ChartType | null | undefined) =>
  chartType === STATISTIC || chartType === GAUGE;

export const getPathForFieldPath = (dep: DepValue, dataType: DataType) => {
  const field = getFieldFromAxisValuePath(dep.path, dataType);
  const path = getFieldPathFromPath(dep.path);

  if (
    field &&
    isSingleSideOfRelationship(field) &&
    path.split('.').length === 1
  ) {
    // Append id to the path, because it's referencing the linked field
    return `${path}.id`;
  }

  return path;
};
