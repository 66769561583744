import createReactComponent from '../createReactComponent';
export default createReactComponent('droplet-share', 'IconDropletShare', [
  [
    'path',
    {
      d: 'M18.884 13.025a6.591 6.591 0 0 0 -.82 -2.148l-4.89 -7.26c-.42 -.625 -1.287 -.803 -1.936 -.397a1.376 1.376 0 0 0 -.41 .397l-4.893 7.26c-1.695 2.838 -1.035 6.441 1.567 8.546a7.125 7.125 0 0 0 4.498 1.58',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 22l5 -5', key: 'svg-1' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-2' }],
]);
