export const REPLIES: CommentNotificationType = 'REPLIES';
export const MENTIONS: CommentNotificationType = 'MENTIONS';
export const NONE: CommentNotificationType = 'NONE';

export type CommentNotificationType = 'REPLIES' | 'MENTIONS' | 'NONE';

const commentNotificationTypes: CommentNotificationType[] = [
  REPLIES,
  MENTIONS,
  NONE,
];

export default commentNotificationTypes;
