import createReactComponent from '../createReactComponent';
export default createReactComponent('pyramid-off', 'IconPyramidOff', [
  [
    'path',
    {
      d: 'M8.483 8.471l-5.483 8.529l9 4l6.22 -2.764m2.78 -1.236l-9 -14l-1.937 3.014',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 3v5m0 4v9', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
