import { Theme } from '@noloco/components/src/models/theme';
import colorPalettes, {
  ColorPalette,
  themePalette,
} from '../constants/colorPalettes';
import { getPrimaryColorGroup } from './colors';

const themeFillShades: number[] = [600, 800, 400, 200, 700, 500, 300, 900];

export const getChartColorPalette = (
  paletteName: ColorPalette | undefined,
  theme: Theme,
) => {
  if (paletteName && paletteName !== themePalette) {
    const standardColorPalette =
      colorPalettes[paletteName as Exclude<ColorPalette, 'theme'>];

    if (standardColorPalette) {
      return standardColorPalette;
    }
  }

  const primaryColorGroup = getPrimaryColorGroup(theme);
  return themeFillShades.map((shade) => primaryColorGroup[shade]);
};
