import createReactComponent from '../createReactComponent';
export default createReactComponent('lungs', 'IconLungs', [
  [
    'path',
    {
      d: 'M6.081 20c1.612 0 2.919 -1.335 2.919 -2.98v-9.763c0 -.694 -.552 -1.257 -1.232 -1.257c-.205 0 -.405 .052 -.584 .15l-.13 .083c-1.46 1.059 -2.432 2.647 -3.404 5.824c-.42 1.37 -.636 2.962 -.648 4.775c-.012 1.675 1.261 3.054 2.877 3.161l.203 .007z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17.92 20c-1.613 0 -2.92 -1.335 -2.92 -2.98v-9.763c0 -.694 .552 -1.257 1.233 -1.257c.204 0 .405 .052 .584 .15l.13 .083c1.46 1.059 2.432 2.647 3.405 5.824c.42 1.37 .636 2.962 .648 4.775c.012 1.675 -1.261 3.054 -2.878 3.161l-.202 .007z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 12a3 3 0 0 0 3 -3a3 3 0 0 0 3 3', key: 'svg-2' }],
  ['path', { d: 'M12 4v5', key: 'svg-3' }],
]);
