import createReactComponent from '../createReactComponent';
export default createReactComponent('podium', 'IconPodium', [
  [
    'path',
    {
      d: 'M5 8h14l-.621 2.485a2 2 0 0 1 -1.94 1.515h-8.878a2 2 0 0 1 -1.94 -1.515l-.621 -2.485z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 8v-2a3 3 0 0 1 3 -3', key: 'svg-1' }],
  ['path', { d: 'M8 12l1 9', key: 'svg-2' }],
  ['path', { d: 'M16 12l-1 9', key: 'svg-3' }],
  ['path', { d: 'M7 21h10', key: 'svg-4' }],
]);
