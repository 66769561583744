import createReactComponent from '../createReactComponent';
export default createReactComponent('currency-won', 'IconCurrencyWon', [
  [
    'path',
    {
      d: 'M4 6l3.245 11.358a.85 .85 0 0 0 1.624 .035l3.131 -9.393l3.131 9.393a.85 .85 0 0 0 1.624 -.035l3.245 -11.358',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M21 10h-18', key: 'svg-1' }],
  ['path', { d: 'M21 14h-18', key: 'svg-2' }],
]);
