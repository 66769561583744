import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-booking', 'IconBrandBooking', [
  [
    'path',
    {
      d: 'M4 18v-9.5a4.5 4.5 0 0 1 4.5 -4.5h7a4.5 4.5 0 0 1 4.5 4.5v7a4.5 4.5 0 0 1 -4.5 4.5h-9.5a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8 12h3.5a2 2 0 1 1 0 4h-3.5v-7a1 1 0 0 1 1 -1h1.5a2 2 0 1 1 0 4h-1.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 16l.01 0', key: 'svg-2' }],
]);
