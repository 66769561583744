import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-volkswagen', 'IconBrandVolkswagen', [
  [
    'path',
    {
      d: 'M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 7l4.5 11l1.5 -5h2l1.5 5l4.5 -11', key: 'svg-1' }],
  ['path', { d: 'M9 4l2 6h2l2 -6', key: 'svg-2' }],
]);
