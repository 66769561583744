import createReactComponent from '../createReactComponent';
export default createReactComponent('cup-off', 'IconCupOff', [
  ['path', { d: 'M8 8h-3v3h6m4 0h4v-3h-7', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17.5 11l-.323 2.154m-.525 3.497l-.652 4.349h-8l-1.5 -10',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M6 8v-1c0 -.296 .064 -.577 .18 -.83m2.82 -1.17h7a2 2 0 0 1 2 2v1',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M15 5v-2', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
