import { useMemo } from 'react';

const useIsMacOs = () => {
  const isMacOs = useMemo(() => {
    if (typeof navigator === 'undefined') {
      return false;
    }

    const platform =
      ((navigator as any).userAgentData &&
        (navigator as any).userAgentData.platform) ||
      navigator.platform;

    return platform && platform.toLowerCase().includes('mac');
  }, []);

  return isMacOs;
};

export default useIsMacOs;
