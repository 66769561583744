import get from 'lodash/get';
import { DATA_FIELD } from '../../constants/elementPropTypeTypes';
import { Element } from '../Element';
import ElementPropType from './ElementPropType';

class DataFieldPropType extends ElementPropType {
  getDataTypePath: (element: Element) => string | undefined;
  filter: (field: any) => boolean;

  constructor(
    getDataTypePath = (element: Element) => get(element, ['props.dataType']),
    filter: (field: any) => boolean = () => true,
  ) {
    super(DATA_FIELD);
    this.getDataTypePath = getDataTypePath;
    this.filter = filter;
  }
}

export default DataFieldPropType;
