import { DateTime } from 'luxon';
import { DATE } from '../../constants/fieldFormats';
import DataTypes, { DataType } from '../../models/DataTypes';
import { getFieldFromDependency } from '../fields';

export const useFormatDateForChartData = (
  xAxisValue: any,
  dataType: DataType,
  dataTypes: DataTypes,
) => {
  const xAxisFieldFromDependency = getFieldFromDependency(
    xAxisValue.path.split('.'),
    dataType,
    dataTypes,
  );
  const typeOptions = xAxisFieldFromDependency?.field?.typeOptions;
  if (typeOptions) {
    if (typeOptions.format === DATE) {
      return (date: DateTime) => date.toUTC();
    }

    if (typeOptions.timeZone) {
      return (date: DateTime) => date.setZone(typeOptions.timeZone);
    }
  }

  // Fallback if it's DATE_TIME but no timezone
  return (date: DateTime) => date.toLocal();
};
