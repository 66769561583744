import createReactComponent from '../createReactComponent';
export default createReactComponent('camper', 'IconCamper', [
  ['path', { d: 'M5 18a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M15 18a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M5 18h-1a1 1 0 0 1 -1 -1v-11a2 2 0 0 1 2 -2h12a4 4 0 0 1 4 4h-18',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M9 18h6', key: 'svg-3' }],
  ['path', { d: 'M19 18h1a1 1 0 0 0 1 -1v-4l-3 -5', key: 'svg-4' }],
  ['path', { d: 'M21 13h-7', key: 'svg-5' }],
  ['path', { d: 'M14 8v10', key: 'svg-6' }],
]);
