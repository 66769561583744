import createReactComponent from '../createReactComponent';
export default createReactComponent('help-octagon', 'IconHelpOctagon', [
  [
    'path',
    {
      d: 'M9.103 2h5.794a3 3 0 0 1 2.122 .879l4.101 4.1a3 3 0 0 1 .88 2.125v5.794a3 3 0 0 1 -.879 2.122l-4.1 4.101a3 3 0 0 1 -2.123 .88h-5.795a3 3 0 0 1 -2.122 -.88l-4.101 -4.1a3 3 0 0 1 -.88 -2.124v-5.794a3 3 0 0 1 .879 -2.122l4.1 -4.101a3 3 0 0 1 2.125 -.88z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 16v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-2',
    },
  ],
]);
