import elementConfigs from '../../elements/elementConfig';
import { getText } from '../../utils/lang';

type BuildModeSectionOptionProps = {
  element: string;
  Icon?: any;
  isEnabled: (element: string) => boolean;
  isSectionFiltered: (title: any) => boolean;
  isVisible: (element: string) => boolean;
  onAddNewSection: (element: string, props?: any) => void;
  props?: any;
  title: string;
};

const BuildModeSectionOption = ({
  element,
  Icon: OptionIcon,
  isEnabled,
  isSectionFiltered,
  isVisible,
  onAddNewSection,
  props,
  title,
}: BuildModeSectionOptionProps) => {
  const Icon = OptionIcon ?? elementConfigs[element].Icon;
  const enabled = isEnabled(element);

  if (!isVisible(element) || !isSectionFiltered(getText(title))) {
    return null;
  }

  return (
    <button
      className="flex w-full flex-col items-center space-y-2 rounded-lg bg-slate-900 p-2 text-xs text-slate-400 hover:bg-slate-700 hover:text-slate-200 hover:shadow-xl"
      disabled={!enabled}
      onClick={() => onAddNewSection(element, props)}
    >
      <div className="flex flex-col items-center justify-center space-y-2">
        {Icon && <Icon className="h-4 w-4" />}
        <span className="text-xs">{getText(title)}</span>
      </div>
    </button>
  );
};

export default BuildModeSectionOption;
