import { useMemo } from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { darkModeColors } from '../../../constants/darkModeColors';
import { DataField } from '../../../models/DataTypeFields';
import { RecordEdge } from '../../../models/Record';
import StateItem from '../../../models/StateItem';
import { aggregateDataForCollectionSummary } from '../../../utils/aggregationDataTypes';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getText } from '../../../utils/lang';

interface CollectionColumnSummaryCellProps {
  className?: string;
  config: any;
  field: DataField;
  parent: any;
  rows: RecordEdge[];
  valueFormatter: (
    field: Pick<StateItem, 'path' | 'dataType'>,
    rawValue: any,
  ) => string;
}

const CollectionColumnSummaryCell = ({
  className,
  config,
  field,
  parent,
  rows,
  valueFormatter,
}: CollectionColumnSummaryCellProps) => {
  const [isDarkModeEnabled] = useDarkMode();

  const shouldRender = useMemo(
    () => config.groupSummary && rows.length > 0,
    [config.groupSummary, rows.length],
  );

  const summaryValue = useMemo(() => {
    if (!shouldRender) {
      return null;
    }

    const valuePath = [...(parent ? [parent.apiName] : []), field.apiName].join(
      '.',
    );

    return aggregateDataForCollectionSummary(
      valuePath,
      rows,
      config.groupSummary,
      field.type,
      valueFormatter,
    );
  }, [config, field, parent, rows, shouldRender, valueFormatter]);

  if (isNil(summaryValue)) {
    return <td />;
  }

  return (
    <td
      className={classNames(
        className,
        'whitespace-pre-wrap px-3 text-right text-xs font-normal',
      )}
    >
      <span
        className={`${
          isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-500'
        } mr-1`}
      >
        <small>
          {getText(
            'elements.VIEW.fields.groupSummary.summaries',
            config.groupSummary,
          )}
        </small>
      </span>
      <span>{summaryValue}</span>
    </td>
  );
};

export default CollectionColumnSummaryCell;
