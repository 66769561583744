import { useCallback, useMemo } from 'react';
import { IconArrowRight, IconDatabase } from '@tabler/icons-react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { Link } from 'react-router-dom';
import { SelectInput, Tooltip } from '@noloco/components/src';
import { DARK } from '@noloco/components/src/constants/surface';
import DataSourceIcon from '@noloco/ui/src/components/DataSourceIcon';
import { getDataTypeOptionValue } from '@noloco/ui/src/components/editor/customerEditors/RepeatingListEditor';
import { getGroupedDataTypeOptions } from '@noloco/ui/src/utils/dataTypes';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { DATA_SETTINGS } from '../../../constants/buildMode';
import { darkModeColors } from '../../../constants/darkModeColors';
import { INTERNAL } from '../../../constants/dataSources';
import { Element } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { getText } from '../../../utils/lang';
import { Page } from '../../../utils/pages';
import BuildModeInput from '../BuildModeInput';
import BuildModeSection from '../BuildModeSection';

type BuildModeViewDataSettingsProps = {
  element: Page | Element;
  hideDataTypeInput?: boolean;
  project: Project;
  updateProperty: UpdatePropertyCallback;
};

const BuildModeViewDataSettings = ({
  element,
  hideDataTypeInput = true,
  project,
  updateProperty,
}: BuildModeViewDataSettingsProps) => {
  const dataListProps = useMemo(() => element.props.dataList || {}, [element]);
  const dataTypeValue = getDataTypeOptionValue(dataListProps.dataType);

  const sectionHasDataType = useMemo(
    () => get(element, 'props.dataList.dataType', null),
    [element],
  );

  const onUpdateDataType = useCallback(
    (newProps) => {
      const fields = project.dataTypes
        .getByName(newProps.dataType)
        ?.fields.filter((field) => !field.internal)
        .slice(0, 5)
        .map((field) => ({
          name: field.name,
          label: { value: upperFirst(field.display) },
        }));

      const newDataListProps = {
        ...dataListProps,
        customFilters: [],
        filter: undefined,
        orderBy: undefined,
        ...newProps,
      };

      updateProperty(['dataList'], newDataListProps);
      updateProperty(['fields'], fields);
    },
    [project.dataTypes, dataListProps, updateProperty],
  );

  const dataTypeOptions = useMemo(
    () =>
      getGroupedDataTypeOptions(project.dataTypes, {
        getValue: ({ name }: any) => getDataTypeOptionValue(name),
      }),
    [project.dataTypes],
  );

  const viewDataTypeValue = useMemo(() => {
    if (hideDataTypeInput && dataTypeValue) {
      return project.dataTypes.getByName(dataTypeValue.dataType);
    }

    return null;
  }, [hideDataTypeInput, dataTypeValue, project.dataTypes]);

  return (
    <BuildModeSection
      className="border-t p-2"
      id={DATA_SETTINGS}
      keepOpen={!sectionHasDataType}
      sticky={sectionHasDataType}
      title={getText('rightSidebar.editor.view')}
    >
      <div className="space-y-2 p-2">
        {!hideDataTypeInput && (
          <BuildModeInput inline={true} label={getText('elements.LIST.type')}>
            <SelectInput
              className="w-full overflow-hidden text-black"
              onChange={onUpdateDataType}
              options={dataTypeOptions}
              shiftRight={true}
              searchable={true}
              value={dataTypeValue}
            />
          </BuildModeInput>
        )}
        {viewDataTypeValue && (
          <BuildModeInput
            inline={true}
            label={getText('rightSidebar.editor.data')}
          >
            <Tooltip
              content={
                <span className={darkModeColors.text.primary}>
                  {getText(
                    { dataType: viewDataTypeValue.display },
                    'elements.LIST.data.link',
                  )}
                </span>
              }
              surface={DARK}
            >
              <Link
                to={`/_/data/internal/${viewDataTypeValue.name}`}
                className="flex w-full items-center rounded-lg bg-gray-700 p-2 hover:bg-gray-900"
              >
                <DataSourceIcon type={viewDataTypeValue.source.type} />
                <div className="ml-3 flex w-full flex-wrap text-white">
                  {viewDataTypeValue.source.type !== INTERNAL && (
                    <span className="mr-2">
                      {viewDataTypeValue.source.display}
                    </span>
                  )}
                  {viewDataTypeValue.source.type !== INTERNAL && (
                    <IconArrowRight className="mr-2" size={16} />
                  )}
                  <span>{viewDataTypeValue.display}</span>
                </div>
                <IconDatabase className="ml-auto flex-shrink-0" size={16} />
              </Link>
            </Tooltip>
          </BuildModeInput>
        )}
      </div>
    </BuildModeSection>
  );
};

export default BuildModeViewDataSettings;
