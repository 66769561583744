import React from 'react';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import FeatureLockedSwitch from '@noloco/ui/src/components/FeatureLockedSwitch';
import { Feature, FeatureValue } from '../../constants/features';
import BuildModeBaseSwitchSection, {
  BuildModeBaseSwitchSectionProps,
} from './BuildModeBaseSwitchSection';

type BuildModeSwitchSectionProps = Omit<
  BuildModeBaseSwitchSectionProps,
  'switchEl'
> & {
  disabled?: boolean;
  feature: Feature;
  onChange: (value: boolean) => void;
  currentValue?: FeatureValue;
};

const BuildModeFeatureLockedSwitchSection = ({
  button,
  children,
  className,
  currentValue,
  disabled = false,
  feature,
  label,
  onChange,
  onClick,
  showOnlyButton = false,
  value,
}: BuildModeSwitchSectionProps) => (
  <BuildModeBaseSwitchSection
    button={button}
    className={className}
    label={label}
    onClick={onClick}
    showOnlyButton={showOnlyButton}
    switchEl={
      <FeatureLockedSwitch
        feature={feature}
        currentNumber={currentValue}
        disabled={disabled}
        onChange={onChange}
        size={SM}
        value={value}
      />
    }
    value={value}
  >
    {children}
  </BuildModeBaseSwitchSection>
);

export default BuildModeFeatureLockedSwitchSection;
