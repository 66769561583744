import React, { memo } from 'react';
import { IconCloudCode } from '@tabler/icons-react';
import NolocoIcon from '@noloco/core/src/components/NolocoIcon';
import {
  AIRTABLE,
  API,
  DataSourceType,
  GOOGLE_SHEETS,
  HUBSPOT,
  INTERNAL,
  MYSQL,
  POSTGRES,
  SMART_SUITE,
  XANO,
} from '@noloco/core/src/constants/dataSources';
import airtableIcon from '../img/airtable-icon.svg';
import googleSheetsIcon from '../img/google-sheets-logo.png';
import hubSpotIcon from '../img/hubspot-logo.png';
import mysqlIcon from '../img/mysql-square-logo.png';
import postgresIcon from '../img/postgres-logo.png';
import smartSuiteIcon from '../img/smartsuite-logo.png';
import xanoLogo from '../img/xano-logo.png';

export const dataSourceIcons = {
  [AIRTABLE]: airtableIcon,
  [GOOGLE_SHEETS]: googleSheetsIcon,
  [HUBSPOT]: hubSpotIcon,
  [MYSQL]: mysqlIcon,
  [POSTGRES]: postgresIcon,
  [SMART_SUITE]: smartSuiteIcon,
  [XANO]: xanoLogo,
};

const dataSourceIconAlts = {
  [AIRTABLE]: 'Airtable Logo',
  [GOOGLE_SHEETS]: 'Google Sheets logo',
  [HUBSPOT]: 'HubSpot logo',
  [MYSQL]: 'MySQL logo',
  [POSTGRES]: 'Postgres logo',
  [SMART_SUITE]: 'SmartSuite logo',
  [XANO]: 'Xano logo',
};

const DataSourceIcon = memo(({ type }: { type: DataSourceType }) => {
  if (type === INTERNAL) {
    return <NolocoIcon className="h-4 w-4" />;
  }

  if (type === API) {
    return <IconCloudCode className="h-4 w-4" />;
  }

  const src = dataSourceIcons[type];
  const alt = dataSourceIconAlts[type];

  return <img src={src} className="h-4 w-4" alt={alt} />;
});

export default DataSourceIcon;
