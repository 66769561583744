import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-gatsby', 'IconBrandGatsby', [
  [
    'path',
    {
      d: 'M3.296 14.297l6.407 6.407a9.018 9.018 0 0 1 -6.325 -6.116l-.082 -.291z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16 13h5c-.41 3.603 -3.007 6.59 -6.386 7.614l-11.228 -11.229a9 9 0 0 1 15.66 -2.985',
      key: 'svg-1',
    },
  ],
]);
