import createReactComponent from '../createReactComponent';
export default createReactComponent('device-ipad-off', 'IconDeviceIpadOff', [
  [
    'path',
    {
      d: 'M6 2h12a2 2 0 0 1 2 2v12m0 4a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-16',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 19h6', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
