import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@noloco/components';
import { setNewElementPath } from '@noloco/core/src/reducers/elements';
import { newElementPathSelector } from '@noloco/core/src/selectors/elementsSelectors';
import { ELEMENTS } from '../constants/leftPopoutMenuTypes';
import Canvas from './canvas/Canvas';
import ProjectRenderer from './canvas/ProjectRenderer';
import LeftPopoutMenuManager from './leftSidebar/LeftPopoutMenuManager';

const CanvasRoute = ({ nolocoTheme }: { nolocoTheme: Theme }) => {
  const dispatch = useDispatch();
  const newElementPath = useSelector(newElementPathSelector);
  const handleClosePopoutMenu = useCallback(() => {
    dispatch(setNewElementPath([]));
  }, [dispatch]);

  const sidebars = useMemo(
    () => (
      <>
        {/* @ts-expect-error TS(2786): 'LeftPopoutMenuManager' cannot be used as a JSX co... Remove this comment to see the full error message */}
        <LeftPopoutMenuManager
          onClose={handleClosePopoutMenu}
          selectedMenu={newElementPath.length > 0 ? ELEMENTS : undefined}
        />
      </>
    ),
    [handleClosePopoutMenu, newElementPath.length],
  );

  return (
    <Canvas isPublic={false} nolocoTheme={nolocoTheme} sidebars={sidebars}>
      <ProjectRenderer />
    </Canvas>
  );
};

export default CanvasRoute;
