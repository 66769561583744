import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Button, Notice, SelectInput } from '@noloco/components';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  BOOLEAN,
  DATE,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import { MULTILINE_TEXT } from '@noloco/core/src/constants/fieldFormats';
import {
  AUTOCOMPLETE_ADDRESS,
  CHECKBOX,
  COLORED_OPTIONS,
  DATE_AND_TIME,
  LIST,
  MARKDOWN,
  OBJECT,
  RADIO,
  SIGNATURE,
} from '@noloco/core/src/constants/inputTypes';
import { projectSettingsSelector } from '@noloco/core/src/selectors/projectSelectors';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'elements.VIEW.fields.inputType';

const getInputTypeOption = (value: any) => ({
  value,
  label: getText(LANG_KEY, value),
});

const getOptionsForField = (field: any) => {
  if ((field.relationship || field.relatedField) && field.type !== FILE) {
    return [getInputTypeOption(LIST)];
  }

  switch (field.type) {
    case TEXT: {
      const isMultiLine = get(field, 'typeOptions.format') === MULTILINE_TEXT;
      if (isMultiLine) {
        return [
          getInputTypeOption(MARKDOWN),
          getInputTypeOption(AUTOCOMPLETE_ADDRESS),
        ];
      }
      return [getInputTypeOption(AUTOCOMPLETE_ADDRESS)];
    }
    case DATE:
      return [getInputTypeOption(DATE_AND_TIME), getInputTypeOption(DATE)];
    case BOOLEAN:
    case SINGLE_OPTION:
      return [getInputTypeOption(RADIO), getInputTypeOption(COLORED_OPTIONS)];
    case MULTIPLE_OPTION:
      return [
        getInputTypeOption(CHECKBOX),
        getInputTypeOption(COLORED_OPTIONS),
      ];
    case OBJECT:
      if (field.typeOptions.format === 'address') {
        return [getInputTypeOption(AUTOCOMPLETE_ADDRESS)];
      }
      return [];
    case FILE:
      return [getInputTypeOption(SIGNATURE)];
    default:
      return [];
  }
};

const FormFieldTypeEditor = ({ config, field, index, updateFields }: any) => {
  const elementType = get(config, 'elementType', null);
  const inputType = get(config, 'inputType', null);

  const settings = useSelector(projectSettingsSelector);
  const mapsApiKey = get(settings, 'apiKeys.googleMaps');

  const { push } = useRouter();

  const noticeText = useMemo(() => {
    if (inputType === AUTOCOMPLETE_ADDRESS) {
      return getText(LANG_KEY, 'updateDisplayAs.title.address');
    } else if (inputType === MARKDOWN && elementType !== MARKDOWN) {
      return getText(LANG_KEY, 'updateDisplayAs.title.markdown');
    } else {
      return '';
    }
  }, [inputType, elementType]);

  const options = useMemo(
    () => [
      { value: null, label: getText(LANG_KEY, 'default') },
      ...getOptionsForField(field),
    ],
    [field],
  );

  const showUpdateDisplayAsOption = useMemo(
    () =>
      (elementType !== MARKDOWN && inputType === MARKDOWN) ||
      (inputType === AUTOCOMPLETE_ADDRESS && !mapsApiKey),
    [elementType, inputType, mapsApiKey],
  );

  const onUpdateInputType = useCallback(
    (newInputType) => updateFields([index, 'inputType'], newInputType),
    [index, updateFields],
  );

  const updateDisplayAs = useCallback(() => {
    if (inputType === AUTOCOMPLETE_ADDRESS) {
      push('/_/settings/integrations/google-maps');
    } else if (inputType === MARKDOWN && elementType !== MARKDOWN) {
      updateFields([index, 'elementType'], MARKDOWN);
    }
  }, [index, updateFields, inputType, elementType, push]);

  if (options.length === 1) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2">
      <BuildModeInput label={getText(LANG_KEY, 'label')}>
        <SelectInput
          contained={true}
          onChange={onUpdateInputType}
          options={options}
          value={inputType}
        />
      </BuildModeInput>
      {showUpdateDisplayAsOption && (
        <div className="flex items-center">
          <Notice className="mt-2" type="info" subtitle={noticeText}>
            <Button className="ml-4" size="sm" onClick={updateDisplayAs}>
              {getText(LANG_KEY, 'updateDisplayAs.cta')}
            </Button>
          </Notice>
        </div>
      )}
    </div>
  );
};

export default FormFieldTypeEditor;
