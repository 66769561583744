import React, { useCallback } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconCircleCheck, IconCircleCheckFilled } from '@tabler/icons-react';
import classNames from 'classnames';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Loader, RelativeDateTimeTooltip, Tooltip } from '@noloco/components';
import Icon from '../../elements/Icon';
import NotificationsGraphic from '../../img/undraw/Notifications';
import { Notification, NotificationIconType } from '../../models/Notification';
import useDarkMode from '../../utils/hooks/useDarkMode';
import useDarkModeSurface from '../../utils/hooks/useDarkModeSurface';
import { useOpenUrl } from '../../utils/hooks/useOpenUrl';
import { useReadNotification } from '../../utils/hooks/useReadNotification';
import useRouter from '../../utils/hooks/useRouter';
import { getText } from '../../utils/lang';

export type Props = {
  hasNextPage: boolean;
  loading: boolean;
  notifications: Notification[];
  onChangeRead: (read: boolean) => void;
  onLoadMore: () => void;
  onOpenChange: (read: boolean) => void;
  read: boolean;
  theme: any;
};

const LANG_KEY = 'notifications';

const NotificationIcon = ({
  icon,
  iconType,
}: {
  icon: string;
  iconType?: NotificationIconType;
}) => {
  if (iconType === 'ICON') {
    return (
      <Icon
        icon={{ name: icon }}
        className="my-auto h-6 w-6 rounded-full text-gray-800 dark:text-gray-200"
        size={16}
      />
    );
  }
  return (
    <img
      alt="Notification icon"
      className="my-auto h-6 w-6 rounded-full"
      src={icon}
    />
  );
};

const NotificationsList = ({
  hasNextPage,
  loading,
  notifications,
  onChangeRead,
  onLoadMore,
  onOpenChange,
  read,
}: Props) => {
  const { push } = useRouter();
  const openUrl = useOpenUrl();
  const surface = useDarkModeSurface();
  const [isDarkModeEnabled] = useDarkMode();
  const { markAsRead, markAsUnread } = useReadNotification();

  const onClickMarkAsRead = useCallback(
    (notification: Notification) => (event: any) => {
      event.stopPropagation();
      markAsRead(notification)();
    },
    [markAsRead],
  );
  const onClickMarkAsUnread = useCallback(
    (notification: Notification) => (event: any) => {
      event.stopPropagation();
      markAsUnread(notification)();
    },
    [markAsUnread],
  );

  const onClickNotification = useCallback(
    (notification: Notification) => () => {
      if (!notification.readAt) {
        markAsRead(notification)();
      }

      if (notification.link) {
        if (notification.link.startsWith('/')) {
          push(notification.link);
        } else {
          openUrl(notification.link, true);
        }

        onOpenChange(false);
      }
    },
    [markAsRead, onOpenChange, openUrl, push],
  );

  const [loaderRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: false,
    rootMargin: '0px 0px 100px 0px',
  });

  return (
    <div
      className={classNames('flex h-screen w-screen max-w-sm flex-col', {
        dark: isDarkModeEnabled,
      })}
    >
      <div className="sticky top-0 flex w-full flex-col bg-white dark:bg-gray-700">
        <div className="flex w-full px-4 py-2">
          <h1 className="my-auto mr-4 text-sm text-gray-800 dark:text-gray-200">
            {getText(LANG_KEY, 'label')}
          </h1>
          <div className="ml-auto flex space-x-4 text-sm">
            <div
              className={classNames(
                'rounded-md p-1 text-gray-800 dark:text-gray-200',
                {
                  'bg-gray-200 dark:bg-gray-600': !read,
                  'cursor-pointer': read,
                },
              )}
              onClick={() => {
                if (!loading && read) {
                  onChangeRead(false);
                }
              }}
            >
              {getText(LANG_KEY, 'unread')}
            </div>
            <div
              className={classNames(
                'rounded-md p-1 text-gray-800 dark:text-gray-200',
                {
                  'bg-gray-200 dark:bg-gray-600': read,
                  'cursor-pointer': !read,
                },
              )}
              onClick={() => {
                if (!loading && !read) {
                  onChangeRead(true);
                }
              }}
            >
              {getText(LANG_KEY, 'read')}
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div className="flex h-full flex-col space-y-2 overflow-y-auto">
        {!loading &&
          notifications.map((notification) => (
            <div
              className={classNames('flex w-full p-2', {
                'cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600':
                  notification.link,
              })}
              key={notification.id}
              onClick={onClickNotification(notification)}
            >
              <div className="flex space-x-4 p-2">
                <NotificationIcon
                  icon={notification.icon}
                  iconType={notification.iconType}
                />
                <div className="space-y-1">
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    {notification.message}
                  </p>
                  <p className="text-xs text-gray-600 dark:text-gray-300">
                    <RelativeDateTimeTooltip
                      date={notification.createdAt}
                      surface={surface}
                    />
                  </p>
                </div>
              </div>
              <div className="my-auto ml-auto p-2">
                {notification.readAt ? (
                  <Tooltip content={getText(LANG_KEY, 'markAsUnread')}>
                    <IconCircleCheckFilled
                      className="cursor-pointer text-gray-600 dark:text-gray-300"
                      onClick={onClickMarkAsUnread(notification)}
                      size={16}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip content={getText(LANG_KEY, 'markAsRead')}>
                    <IconCircleCheck
                      className="cursor-pointer text-gray-600 dark:text-gray-300"
                      onClick={onClickMarkAsRead(notification)}
                      size={16}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          ))}
        {(hasNextPage || loading) && (
          <div className="flex w-full justify-center px-2 py-6" ref={loaderRef}>
            <Loader size="sm" />
          </div>
        )}
        {!loading && notifications.length === 0 && (
          <div className="my-auto flex w-full flex-col p-2">
            <NotificationsGraphic className="mx-auto h-48 w-72 p-4 opacity-75" />
            <p className="mx-auto p-4 text-xs text-gray-800 dark:text-gray-200">
              {getText(LANG_KEY, 'empty')}
            </p>
          </div>
        )}
        {loading && (
          <div className="flex w-full p-2">
            <Loader className="mx-auto" />
          </div>
        )}
      </div>
    </div>
  );
};

export default withTheme(NotificationsList);
