import createReactComponent from '../createReactComponent';
export default createReactComponent('golf', 'IconGolf', [
  ['path', { d: 'M12 18v-15l7 4l-7 4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 17.67c-.62 .36 -1 .82 -1 1.33c0 1.1 1.8 2 4 2s4 -.9 4 -2c0 -.5 -.38 -.97 -1 -1.33',
      key: 'svg-1',
    },
  ],
]);
