import createReactComponent from '../createReactComponent';
export default createReactComponent('mailbox-off', 'IconMailboxOff', [
  [
    'path',
    {
      d: 'M10 21v-6.5a3.5 3.5 0 0 0 -7 0v6.5h18m0 -4v-2a4 4 0 0 0 -4 -4h-2m-4 0h-4.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 8v-5h4l2 2l-2 2h-4', key: 'svg-1' }],
  ['path', { d: 'M6 15h1', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
