import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-question',
  'IconDeviceDesktopQuestion',
  [
    [
      'path',
      {
        d: 'M13.5 16h-9.5a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v6.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h8', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    ['path', { d: 'M19 22v.01', key: 'svg-3' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-4',
      },
    ],
  ],
);
