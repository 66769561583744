import createReactComponent from '../createReactComponent';
export default createReactComponent('calendar-off', 'IconCalendarOff', [
  [
    'path',
    {
      d: 'M9 5h9a2 2 0 0 1 2 2v9m-.184 3.839a2 2 0 0 1 -1.816 1.161h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 1.158 -1.815',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3v4', key: 'svg-1' }],
  ['path', { d: 'M8 3v1', key: 'svg-2' }],
  ['path', { d: 'M4 11h7m4 0h5', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
