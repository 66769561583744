import { RAW_DATA_PROP } from '../../constants/elementPropTypeTypes';
import { Project } from '../Project';
import ElementPropType from './ElementPropType';

class RawDataPropType extends ElementPropType {
  calculateDataTypes: any;
  collections: boolean;

  constructor(calculateDataTypes: (...varargs: any[]) => any[] = () => []) {
    super(RAW_DATA_PROP);
    this.calculateDataTypes = calculateDataTypes;
    this.collections = false;
  }

  getDataTypes(element: any, project: Project, propPath: string[]) {
    return this.calculateDataTypes(element, project, propPath);
  }

  setShowCollections(collections: boolean) {
    this.collections = collections;
    return this;
  }
}

export default RawDataPropType;
