import createReactComponent from '../createReactComponent';
export default createReactComponent('mail-dollar', 'IconMailDollar', [
  [
    'path',
    {
      d: 'M13.5 19h-8.5a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v3.5',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-2' }],
  ['path', { d: 'M3 7l9 6l9 -6', key: 'svg-3' }],
]);
