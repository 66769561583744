import React from 'react';
import { BaseModal, Button } from '@noloco/components';
import { XL } from '@noloco/core/src/constants/screens';
import { getText } from '@noloco/core/src/utils/lang';
import onboardingChecklist from '../../img/onboarding-checklist.png';

const LANG_KEY = 'newProject.onboardingModal';

const OnboardingModal = ({ replaceQueryParams }: any) => (
  <BaseModal
    size={XL}
    closeOnOutsideClick={true}
    onClose={() => replaceQueryParams({ __onboardingModal: undefined })}
  >
    <div className="bg-gray-50">
      <div className="grid w-full grid-cols-2 gap-4 sm:grid-cols-1 md:grid-cols-1">
        <div className="col-span-1 p-2">
          <img
            src={onboardingChecklist}
            alt="onboarding-checklist"
            className="rounded-lg"
          />
        </div>
        <div className="col-span-1 flex items-center p-8">
          <div className="flex h-full flex-col">
            <h1 className="text-3xl font-bold text-gray-700">
              {getText(LANG_KEY, 'title')}
            </h1>
            <p className="my-3 text-gray-700">
              {getText(LANG_KEY, 'subTitle')}
            </p>
            <p className="mt-4 text-gray-700">
              {getText(LANG_KEY, 'onboardingChecklist')}
            </p>
            <Button
              className="mt-auto w-full bg-pink-500 hover:bg-pink-600"
              onClick={() =>
                replaceQueryParams({ __onboardingModal: undefined })
              }
            >
              {getText(LANG_KEY, 'getStarted')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
);

export default OnboardingModal;
