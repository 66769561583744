import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-tablet-minus',
  'IconDeviceTabletMinus',
  [
    [
      'path',
      {
        d: 'M12.5 21h-6.5a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v11',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12.872 16.51a1 1 0 1 0 -.872 1.49', key: 'svg-1' }],
    ['path', { d: 'M16 19h6', key: 'svg-2' }],
  ],
);
