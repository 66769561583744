import createReactComponent from '../createReactComponent';
export default createReactComponent('columns-2', 'IconColumns2', [
  [
    'path',
    {
      d: 'M3 3m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v16a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1zm9 -1v18',
      key: 'svg-0',
    },
  ],
]);
