import createReactComponent from '../createReactComponent';
export default createReactComponent('api-off', 'IconApiOff', [
  ['path', { d: 'M4 13h5', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 16v-4m0 -4h3a2 2 0 0 1 2 2v1c0 .554 -.225 1.055 -.589 1.417m-3.411 .583h-1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M20 8v8', key: 'svg-2' }],
  ['path', { d: 'M9 16v-5.5a2.5 2.5 0 0 0 -5 0v5.5', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
