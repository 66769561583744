import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-mobile-search',
  'IconDeviceMobileSearch',
  [
    [
      'path',
      {
        d: 'M12 21h-4a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v6',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
    ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-2' }],
    ['path', { d: 'M11 4h2', key: 'svg-3' }],
    ['path', { d: 'M12 17v.01', key: 'svg-4' }],
  ],
);
