import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-right-circle',
  'IconArrowRightCircle',
  [
    ['path', { d: 'M18 15l3 -3l-3 -3', key: 'svg-0' }],
    ['path', { d: 'M5 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
    ['path', { d: 'M7 12h14', key: 'svg-2' }],
  ],
);
