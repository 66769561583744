const buildModeTheme = {
  spacing: {
    zero: 0,
    sm: 1.5,
    md: 3,
    lg: 6,
    xl: 8,
  },
  brandColors: {
    primary: 'cyan-500',
    danger: 'red-500',
    secondary: 'slate-500',
    success: 'green-500',
    warning: 'yellow-300',
    info: 'pink-500',
    custom: '',
  },
  brandColorGroups: {
    primary: 'slate',
    secondary: 'slate',
    success: 'green',
    warning: 'yellow',
    danger: 'red',
    info: 'pink',
    custom: '',
  },
  surfaceColors: {
    dark: 'slate-700',
    light: 'white',
  },
  borderColors: {
    dark: 'slate-800',
    light: 'slate-300',
    default: 'slate-800',
  },
  textInput: {
    surface: 'dark',
    textColor: 'slate-400',
  },
  selectInput: {
    surface: 'dark',
    textColor: 'slate-400',
  },
  dropzone: {
    surface: 'dark',
    textColor: 'slate-400',
  },
  datePicker: {
    surface: 'dark',
    textColor: 'slate-400',
  },
};

module.exports = buildModeTheme;
