import React, { useMemo } from 'react';
import { USER_ROLES } from '@noloco/core/src/constants/features';
import { Project } from '@noloco/core/src/models/Project';
import { Role } from '@noloco/core/src/models/User';
import ProFeatureBadge from '../../components/ProFeatureBadge';
import { useUserRoles } from './useUserRoles';

export const useUserRoleOptions = (
  project: Project,
  disabled: (role: Role) => boolean = () => false,
) => {
  const { roles } = useUserRoles(project.name, project.dataTypes, {
    builder: true,
  });

  const roleOptions = useMemo(
    () =>
      roles.map((role: Role) => ({
        disabled: !role.enabled || disabled(role),
        value: parseInt(role.id as string, 10),
        label: (
          <div className="flex">
            {role.name}
            {!role.enabled && (
              <div className="ml-2 mt-0.5">
                <ProFeatureBadge
                  feature={USER_ROLES}
                  compact={true}
                  inline={true}
                  showAfterTrial={true}
                />
              </div>
            )}
          </div>
        ),
      })),
    [disabled, roles],
  );

  return roleOptions;
};
