import createReactComponent from '../createReactComponent';
export default createReactComponent('box-padding', 'IconBoxPadding', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 16v.01', key: 'svg-1' }],
  ['path', { d: 'M8 12v.01', key: 'svg-2' }],
  ['path', { d: 'M8 8v.01', key: 'svg-3' }],
  ['path', { d: 'M16 16v.01', key: 'svg-4' }],
  ['path', { d: 'M16 12v.01', key: 'svg-5' }],
  ['path', { d: 'M16 8v.01', key: 'svg-6' }],
  ['path', { d: 'M12 8v.01', key: 'svg-7' }],
  ['path', { d: 'M12 16v.01', key: 'svg-8' }],
]);
