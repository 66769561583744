import createReactComponent from '../createReactComponent';
export default createReactComponent('face-mask-off', 'IconFaceMaskOff', [
  [
    'path',
    {
      d: 'M5 14.5h-.222c-1.535 0 -2.778 -1.12 -2.778 -2.5s1.243 -2.5 2.778 -2.5h.222',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M19 14.5h.222c1.534 0 2.778 -1.12 2.778 -2.5s-1.244 -2.5 -2.778 -2.5h-.222',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 10h1m4 0h1', key: 'svg-2' }],
  ['path', { d: 'M9 14h5', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M19 15v-6.49a2 2 0 0 0 -1.45 -1.923l-5 -1.429a2 2 0 0 0 -1.1 0l-1.788 .511m-3.118 .891l-.094 .027a2 2 0 0 0 -1.45 1.922v6.982a2 2 0 0 0 1.45 1.923l5 1.429a2 2 0 0 0 1.1 0l4.899 -1.4',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
