import { NUMBER } from '../../constants/elementPropTypeTypes';
import ElementPropType from './ElementPropType';

class NumberType extends ElementPropType {
  constructor() {
    super(NUMBER);
  }
}

export default NumberType;
