import React, { forwardRef } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { PAGE } from '../../constants/elements';
import { linkResolver } from '../../models/elementPropTypes/comboProps/LinkPropType';

const getPagesInPath = (elements: any, elementPath: any) =>
  elementPath.reduce((pages: any, pathSlice: any, index: any) => {
    const path = elementPath.slice(0, index);
    const element = get(elements, path);
    if (element && element.type === PAGE) {
      return [...pages, element];
    }
    return pages;
  }, []);

type BreadcrumbsProps = {};

const Breadcrumbs = forwardRef<any, BreadcrumbsProps>(
  // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Bread... Remove this comment to see the full error message
  ({ className, editorMode, elementPath, onClick, project }, ref) => {
    const pagesInPath = getPagesInPath(project.elements, elementPath);
    const LinkElement = editorMode ? 'span' : Link;
    return (
      <div
        className={classNames(className, 'flex w-auto items-center')}
        onClick={onClick}
        ref={ref}
      >
        {pagesInPath.map(
          (page: any, index: any) =>
            page.props &&
            page.props.routePath && (
              <React.Fragment key={page.id}>
                {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; edito... Remove this comment to see the full error message */}
                <LinkElement
                  {...linkResolver(
                    {
                      type: PAGE,
                      pageData: pagesInPath
                        .slice(0, index)
                        .map((p: any) => p.id),
                    },
                    project,
                    {},
                    {},
                    // @ts-expect-error TS(2554): Expected 4 arguments, but got 5.
                    editorMode,
                  )}
                  className="font-medium"
                  editorMode={editorMode}
                >
                  Jobs
                </LinkElement>
                {index !== pagesInPath.length - 1 && (
                  <IconChevronRight size={12} className="mx-4" />
                )}
              </React.Fragment>
            ),
        )}
      </div>
    );
  },
);

export default Breadcrumbs;
