import { useCallback } from 'react';
import { DataType } from '../../models/DataTypes';
import { Element, ElementPath, ViewTab } from '../../models/Element';
import { Project } from '../../models/Project';
import SectionListItem from './SectionListItem';

type SectionEditorListProps = {
  activeSection?: string;
  dataType: DataType;
  elementPath: ElementPath;
  elementType: string;
  project: Project;
  sectionPath: ElementPath;
  sections: Element[];
  selectedTab?: ViewTab | null;
  visibleSections: { section: Element; index: number }[];
};

const SectionEditorList = ({
  activeSection,
  dataType,
  elementPath,
  elementType,
  project,
  sectionPath,
  sections,
  selectedTab,
  visibleSections,
}: SectionEditorListProps) => {
  const scrollToSection = useCallback((sectionId: string) => {
    const sectionEl = document.getElementById(sectionId);
    if (sectionEl) {
      sectionEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  return (
    <>
      {visibleSections.map(({ section, index }) => {
        if (!section || !section.type || section.container) {
          return null;
        }

        return (
          <SectionListItem
            active={activeSection === section.id}
            activeSection={activeSection}
            dataType={dataType}
            elementPath={elementPath}
            elementType={elementType}
            index={index}
            key={section.id}
            project={project}
            scrollToSection={scrollToSection}
            section={section}
            sectionPath={sectionPath}
            sections={sections}
            selectedTab={selectedTab}
          />
        );
      })}
    </>
  );
};

export default SectionEditorList;
