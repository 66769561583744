export const AIRTABLE: SourceType = 'AIRTABLE';
export const API: SourceType = 'API';
export const GOOGLE_SHEETS: SourceType = 'GOOGLE_SHEETS';
export const HUBSPOT: SourceType = 'HUBSPOT';
export const MYSQL: SourceType = 'MYSQL';
export const POSTGRES: SourceType = 'POSTGRES';
export const SMART_SUITE: SourceType = 'SMART_SUITE';
export const XANO: SourceType = 'XANO';
export const INTERNAL: DataSourceType = 'INTERNAL';

// Potential Candidates for future
export const FIREBASE = 'FIREBASE';
export const SALESFORCE = 'SALESFORCE';

export const CSV = 'CSV';

export const MIN_DATA_TYPES_TO_SHOW_SELECT = 10;

export type SourceType =
  | 'AIRTABLE'
  | 'API'
  | 'GOOGLE_SHEETS'
  | 'HUBSPOT'
  | 'MYSQL'
  | 'POSTGRES'
  | 'SMART_SUITE'
  | 'XANO';

export type DataSourceType = SourceType | 'INTERNAL';

export type DataSourceTypeType = 'TABLE' | 'CUSTOM_QUERY';

export const TABLE: DataSourceTypeType = 'TABLE';
export const CUSTOM_QUERY: DataSourceTypeType = 'CUSTOM_QUERY';

export type OnboardingDataSourceChoice = 'csv' | 'external' | 'noloco';
