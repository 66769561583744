import { useMemo } from 'react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { getInputTypeForOperator } from '../operator';

export const useFieldOperatorConflicts = (andFilters: any[]) => {
  const fieldOperatorCounts = useMemo(
    () =>
      andFilters
        .filter(
          ({ operator, result }: any) =>
            !!result || !getInputTypeForOperator(operator),
        )
        .reduce((acc: any, andFilter: any) => {
          const path = [andFilter.field, andFilter.operator];
          return set(path, get(acc, path, 0) + 1, acc);
        }, {}),
    [andFilters],
  );

  const showFieldOperatorWarning = useMemo(
    () =>
      andFilters.some(
        ({ field, operator }: any) =>
          get(fieldOperatorCounts, [field, operator], 0) > 1,
      ),
    [andFilters, fieldOperatorCounts],
  );

  return {
    fieldOperatorCounts,
    showFieldOperatorWarning,
  };
};
