import createReactComponent from '../createReactComponent';
export default createReactComponent('bell-school', 'IconBellSchool', [
  ['path', { d: 'M10 10m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13.5 15h.5a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-1a2 2 0 0 1 2 -2h.5',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M16 17a5.698 5.698 0 0 0 4.467 -7.932l-.467 -1.068', key: 'svg-2' },
  ],
  ['path', { d: 'M10 10v.01', key: 'svg-3' }],
  ['path', { d: 'M20 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-4' }],
]);
