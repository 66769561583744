import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circuit-cell-plus',
  'IconCircuitCellPlus',
  [
    ['path', { d: 'M2 12h9', key: 'svg-0' }],
    ['path', { d: 'M15 12h7', key: 'svg-1' }],
    ['path', { d: 'M11 5v14', key: 'svg-2' }],
    ['path', { d: 'M15 9v6', key: 'svg-3' }],
    ['path', { d: 'M3 5h4', key: 'svg-4' }],
    ['path', { d: 'M5 3v4', key: 'svg-5' }],
  ],
);
