import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-right-square',
  'IconArrowRightSquare',
  [
    ['path', { d: 'M7 12l14 0', key: 'svg-0' }],
    ['path', { d: 'M18 15l3 -3l-3 -3', key: 'svg-1' }],
    ['path', { d: 'M3 10h4v4h-4z', key: 'svg-2' }],
  ],
);
