import createReactComponent from '../createReactComponent';
export default createReactComponent('pentagram', 'IconPentagram', [
  [
    'path',
    {
      d: 'M5.636 5.636a9 9 0 1 1 12.728 12.728a9 9 0 0 1 -12.728 -12.728z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M15.236 11l5.264 4h-6.5l-2 6l-2 -6h-6.5l5.276 -4l-2.056 -6.28l5.28 3.78l5.28 -3.78z',
      key: 'svg-1',
    },
  ],
]);
