import createReactComponent from '../createReactComponent';
export default createReactComponent('color-filter', 'IconColorFilter', [
  [
    'path',
    {
      d: 'M13.58 13.79c.27 .68 .42 1.43 .42 2.21c0 1.77 -.77 3.37 -2 4.46a5.93 5.93 0 0 1 -4 1.54c-3.31 0 -6 -2.69 -6 -6c0 -2.76 1.88 -5.1 4.42 -5.79',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17.58 10.21c2.54 .69 4.42 3.03 4.42 5.79c0 3.31 -2.69 6 -6 6a5.93 5.93 0 0 1 -4 -1.54',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 8m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0', key: 'svg-2' }],
]);
