import createReactComponent from '../createReactComponent';
export default createReactComponent('traffic-lights', 'IconTrafficLights', [
  [
    'path',
    {
      d: 'M7 2m0 5a5 5 0 0 1 5 -5h0a5 5 0 0 1 5 5v10a5 5 0 0 1 -5 5h0a5 5 0 0 1 -5 -5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 7m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-2' }],
  ['path', { d: 'M12 17m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-3' }],
]);
