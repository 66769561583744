import React, { forwardRef, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { getColorShade } from '@noloco/components/src';
import { LAYOUT_SUPPORTS_RECORD_COLORING } from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { getColorBasedOnConditions } from '../../../../utils/colors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import BulkActionCheckboxCell from '../BulkActionCheckboxCell';
import TableFieldCell from './TableFieldCell';

const TableRecordLayout = forwardRef<HTMLTableRowElement, any>(
  (
    {
      actionButtons,
      className,
      dataType,
      elementId,
      element,
      fieldConfigs,
      maxStickyColumnIndex,
      record,
      scope,
      layout,
      project,
      rowLink,
      transformRecordScope,
      columnWidths = {},
      bulkActionsEnabled = false,
      isRowChecked = false,
      handleCheckboxChange,
      selectedRows,
      theme,
      ...rest
    },
    ref,
  ) => {
    const [isDarkModeEnabled] = useDarkMode();

    const colorConditions = useMemo(
      () => get(element, ['props', 'recordColoring'], []),
      [element],
    );
    const conditionBasedColor = useMemo(
      () => getColorBasedOnConditions(colorConditions, scope, project),
      [colorConditions, scope, project],
    );

    return (
      <tr
        className={classNames(className, `group relative w-full px-6 text-xs`, {
          [`hover:bg-opacity-50 hover:${
            isDarkModeEnabled
              ? darkModeColors.surfaces.elevation2
              : 'bg-gray-100'
          }`]: !conditionBasedColor,
          [`bg-${getColorShade(
            conditionBasedColor,
            50,
          )} hover:bg-${getColorShade(conditionBasedColor, 100)}`]:
            conditionBasedColor &&
            !isDarkModeEnabled &&
            LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout),
          [`bg-${getColorShade(
            conditionBasedColor,
            900,
          )} hover:bg-${getColorShade(conditionBasedColor, 800)}`]:
            conditionBasedColor &&
            isDarkModeEnabled &&
            LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout),
        })}
        data-testid="collection-record"
        ref={ref}
        {...rest}
      >
        {bulkActionsEnabled && (
          <BulkActionCheckboxCell
            elementId={elementId}
            handleCheckboxChange={handleCheckboxChange}
            isRowChecked={isRowChecked}
            isSticky={!isNil(maxStickyColumnIndex)}
            theme={theme}
          />
        )}
        {fieldConfigs.map(
          (
            { field, parent, parentFieldType, config, permissions }: any,
            index: any,
          ) => (
            <TableFieldCell
              // @ts-expect-error TS(2322): Type '{ config: any; dataType: any; elementId: any... Remove this comment to see the full error message
              config={config}
              dataType={parentFieldType || dataType}
              elementId={elementId}
              isFirst={index === 0}
              isLast={index === fieldConfigs.length - 1}
              isSticky={
                !isNil(maxStickyColumnIndex) && index <= maxStickyColumnIndex
              }
              field={field}
              key={`${field.name}:${(parentFieldType || dataType).name}`}
              parent={parent}
              permissions={permissions}
              record={record}
              project={project}
              rowLink={rowLink}
              transformRecordScope={transformRecordScope}
              width={columnWidths[index]}
              bulkActionsEnabled={bulkActionsEnabled}
              isRowChecked={isRowChecked}
              handleCheckboxChange={handleCheckboxChange}
              selectedRows={selectedRows}
              theme={theme}
            />
          ),
        )}
        {actionButtons}
      </tr>
    );
  },
);

export default withTheme(TableRecordLayout);
