import createReactComponent from '../createReactComponent';
export default createReactComponent('filter-edit', 'IconFilterEdit', [
  [
    'path',
    {
      d: 'M10.97 20.344l-1.97 .656v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v1.5',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z',
      key: 'svg-1',
    },
  ],
]);
