import React, { memo, useCallback, useMemo } from 'react';
import { TailwindThemeProvider } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import RightBuildModeEditor from '@noloco/core/src/components/editor/RightBuildModeEditor';
import { darkModeColors } from '@noloco/core/src/constants/darkModeColors';
import {
  MenuStyle,
  SIDE_MENU,
  TOP_MENU,
} from '@noloco/core/src/constants/menuStyles';
import Sidebar from '@noloco/core/src/elements/Sidebar';
import { setGhostUserId } from '@noloco/core/src/reducers/data';
import {
  editorModeSelector,
  leftEditorSectionSelector,
} from '@noloco/core/src/selectors/elementsSelectors';
import { projectSettingsSelector } from '@noloco/core/src/selectors/projectSelectors';
import useAppTheme from '@noloco/core/src/utils/hooks/useAppTheme';
import useBoolLocalStorageState from '@noloco/core/src/utils/hooks/useBoolLocalStorageState';
import useDarkMode, {
  useIsDarkModeDefault,
} from '@noloco/core/src/utils/hooks/useDarkMode';
import useDarkModeSurface from '@noloco/core/src/utils/hooks/useDarkModeSurface';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import usePollPublishedVersionInfo from '../../utils/hooks/usePollPublishedVersion';
import BillingPlanStatusAlert from '../BillingPlanStatusAlert';
import BuildModeTrigger from '../BuildModeTrigger';
import DarkModeSwitch from '../DarkModeSwitch';
import RightSidebarContainer from '../RightSidebarContainer';
import TrialEndModal from '../TrialEndModal';
import ElementHighlightWrapper from './ElementHighlightWrapper';
import buildModeTheme from './buildModeTheme';

type Props = {
  displaySidebar: boolean;
  displayEditMode: boolean;
  children: any;
};

const AppBody = memo(({ displaySidebar, displayEditMode, children }: Props) => {
  const dispatch = useDispatch();
  const settings = useSelector(projectSettingsSelector);
  const leftEditor = useSelector(leftEditorSectionSelector);
  const { md: isMdScreen } = useBreakpoints();
  const [isDarkModeEnabled, _setDarkModeEnabled, clearDarkModeEnabled] =
    useDarkMode();
  const isDarkModeToggleEnabled = get(
    settings,
    'theme.darkModeToggleEnabled',
    false,
  );

  const isDefaultDarkMode = useIsDarkModeDefault(settings);
  const shouldInvalidateUserDarkModeSetting = useMemo(
    () => !isDarkModeToggleEnabled && isDarkModeEnabled !== isDefaultDarkMode,
    [isDarkModeEnabled, isDefaultDarkMode, isDarkModeToggleEnabled],
  );

  /*
   * If the user's dark mode setting is not the default and the dark mode toggle is disabled,
   * we should invalidate the user's dark mode setting so we use the default setting.
   */
  if (shouldInvalidateUserDarkModeSetting) {
    clearDarkModeEnabled();
  }

  const surface = useDarkModeSurface();

  const editorMode = useSelector(editorModeSelector);
  const onSetGhostUserId = useCallback(
    (newGhostUserId) => dispatch(setGhostUserId(newGhostUserId)),
    [dispatch],
  );
  const { isBuilder } = useIsBuilder();
  const [isNavExpanded, setIsNavExpanded] = useBoolLocalStorageState(
    'sidebar.expanded',
    true,
  );
  const theme = useAppTheme(settings);
  const primaryColor = theme.brandColors.primary;

  usePollPublishedVersionInfo(isBuilder);

  const showBuildModeEditor = useMemo(
    () => displaySidebar && editorMode,
    [displaySidebar, editorMode],
  );

  const menuStyle = useMemo(
    () => get(settings, 'navigation.menuStyle', SIDE_MENU) as MenuStyle,
    [settings],
  );

  const shouldRenderBuildModeTrigger = useMemo(
    () => !editorMode && displayEditMode && isBuilder && !isMdScreen,
    [editorMode, displayEditMode, isBuilder, isMdScreen],
  );

  const Wrapper = menuStyle === SIDE_MENU ? SimpleBar : 'div';

  return (
    <div className="flex h-full w-full">
      {/* @ts-expect-error TS(2786): 'SimpleBar' cannot be used as a JSX component. */}
      <Wrapper
        autoHide={true}
        className={classNames(
          'canvas h-100 project-preview z-10 mx-auto max-h-screen min-h-screen w-full sm:max-h-dvh sm:min-h-dvh',
          `${
            isDarkModeEnabled
              ? `${darkModeColors.surfaces.elevation0} dark-mode-scroll-bar dark`
              : 'bg-white'
          }`,
        )}
      >
        <div
          className={classNames(
            'flex max-h-screen w-full bg-opacity-25 sm:fixed sm:left-0 sm:top-0 sm:max-h-full sm:flex-col',
            {
              'min-h-screen': menuStyle === SIDE_MENU,
              'flex-col': menuStyle === TOP_MENU,
            },
            isDarkModeEnabled
              ? darkModeColors.surfaces.elevation1
              : 'bg-gray-100',
          )}
        >
          <TailwindThemeProvider theme={theme}>
            {displaySidebar && (
              <Sidebar
                className={classNames({
                  'min-h-screen': menuStyle === SIDE_MENU,
                })}
                editorMode={editorMode}
                isNavExpanded={isNavExpanded}
                menuStyle={menuStyle}
                setGhostUserId={onSetGhostUserId}
                setIsNavExpanded={setIsNavExpanded}
              />
            )}
            <div
              className={classNames(
                'relative flex h-full w-full flex-grow overflow-hidden',
                {
                  [`${darkModeColors.surfaces.elevation0} ${darkModeColors.text.primary}`]:
                    isDarkModeEnabled,
                },
              )}
              data-testid="project-canvas"
            >
              <div
                className={classNames(
                  'absolute bottom-0 top-0 flex h-full max-h-full w-full flex-shrink-0 flex-grow overflow-x-hidden',
                  {
                    'overflow-y-auto': menuStyle === SIDE_MENU,
                    'overflow-y-hidden': menuStyle === TOP_MENU,
                  },
                )}
                id="inner-project-canvas"
              >
                <div
                  className={classNames(
                    'mx-auto flex w-full flex-grow flex-col items-center',
                    { 'overflow-y-auto': menuStyle === TOP_MENU },
                  )}
                >
                  {children}
                </div>
                {menuStyle === TOP_MENU && <RightSidebarContainer />}
              </div>
              <div className="spacer h-screen w-0" />
              <div
                className={classNames('fixed bottom-3 z-50 flex items-center', {
                  'left-80 ml-5':
                    (isNavExpanded || editorMode) &&
                    displayEditMode &&
                    isBuilder &&
                    menuStyle === SIDE_MENU,
                  'left-40':
                    !isNavExpanded &&
                    !editorMode &&
                    displayEditMode &&
                    isBuilder &&
                    menuStyle === SIDE_MENU,
                  'ml-4': menuStyle === TOP_MENU,
                })}
              >
                {isDarkModeToggleEnabled && (
                  <DarkModeSwitch
                    primaryColor={primaryColor}
                    surface={surface}
                  />
                )}
              </div>
            </div>
            {menuStyle === SIDE_MENU && <RightSidebarContainer />}
          </TailwindThemeProvider>
          <ElementHighlightWrapper
            displayEditMode={displayEditMode}
            editorMode={editorMode}
          />
        </div>
      </Wrapper>
      <TrialEndModal />
      <BillingPlanStatusAlert />
      {showBuildModeEditor && (
        <TailwindThemeProvider theme={buildModeTheme}>
          <RightBuildModeEditor leftEditor={leftEditor} />
        </TailwindThemeProvider>
      )}
      {shouldRenderBuildModeTrigger && (
        <BuildModeTrigger
          editorMode={editorMode}
          isNavExpanded={isNavExpanded}
          primaryColor={primaryColor}
        />
      )}
    </div>
  );
});

export default AppBody;
