import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  IconArrowDown,
  IconArrowUp,
  IconCopy,
  IconSquare,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { Element } from '@noloco/core/src/models/Element';
import ElementConfig from '@noloco/core/src/models/ElementConfig';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import {
  useCloneElement,
  useMoveElementDown,
  useMoveElementUp,
  useRemoveSelected,
} from '../../utils/hooks/projectHooks';

const REFRESH_TIME_MS = 75;

let canvasRef: any;
const getCanvasRef = () => {
  if (!canvasRef || !canvasRef.isConnected) {
    canvasRef = document.querySelector('.canvas .simplebar-content');
  }
  return canvasRef;
};

const ElementHighlight = memo<{
  project: Project;
  element: Element;
  elementPath: string[];
  selected: boolean;
  showSpacing?: boolean;
  elementConfig: ElementConfig | undefined;
}>(
  forwardRef(
    (
      {
        project,
        element,
        elementPath,
        selected,
        showSpacing = false,
        elementConfig,
      },
      ref: ForwardedRef<HTMLDivElement>,
    ) => {
      const [t, setT] = useState(0);
      const [onMoveElementUp, canMoveUp] = useMoveElementUp(
        project,
        elementPath,
      );
      const [onMoveElementDown, canMoveDown] = useMoveElementDown(
        project,
        elementPath,
      );
      const [removeSelectedElement] = useRemoveSelected(project, elementPath);
      const [cloneElement] = useCloneElement(project, elementPath);

      useEffect(() => {
        setTimeout(() => {
          setT(t + 1);
        }, REFRESH_TIME_MS);
      }, [t]);

      const onRemoveSelected = useCallback(
        (event) => {
          event.stopPropagation();
          removeSelectedElement();
        },
        [removeSelectedElement],
      );

      const onCloneSelected = useCallback(
        (event) => {
          event.stopPropagation();
          cloneElement();
        },
        [cloneElement],
      );

      const { props: elementProps = {} } = element || {};

      const { Icon = IconSquare } = elementConfig || {};

      const canvas = getCanvasRef();

      const tooltip = () => {
        const elementReference = document.querySelector(
          `.element-${element.id}`,
        );
        if (!elementReference) {
          return null;
        }

        const { offsetHeight, offsetWidth } = elementReference as HTMLElement;
        const canvasRect = canvas.getBoundingClientRect();
        const boundingRect = elementReference.getBoundingClientRect();
        const zIndex = 9999;
        const highlightStyle: CSSProperties = {
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex,
          transform: `translate(${boundingRect.left - canvasRect.left}px, ${
            boundingRect.top - canvasRect.top
          }px)`,
        };
        const size = 1;

        const positionTooltipLeft = true;

        const controlsTopPosition = 0;

        const bgClassNames = 'bg-pink-300';
        return (
          <div ref={ref} style={highlightStyle}>
            <>
              <div
                className={classNames('absolute', bgClassNames)}
                style={{
                  top: 0 - size,
                  left: 0 - size,
                  height: size,
                  width: offsetWidth + size * 2,
                }}
              />
              <div
                className={classNames('absolute', bgClassNames)}
                style={{
                  top: offsetHeight,
                  left: 0 - size,
                  height: size,
                  width: offsetWidth + size * 2,
                }}
              />
              <div
                className={classNames('absolute', bgClassNames)}
                style={{
                  top: 0 - size,
                  left: 0 - size,
                  height: offsetHeight + size * 2,
                  width: size,
                }}
              />
              <div
                className={classNames('absolute', bgClassNames)}
                style={{
                  top: 0 - size,
                  left: offsetWidth,
                  height: offsetHeight + size * 2,
                  width: size,
                }}
              />

              {/* ----- CONTROLS --- */}
              {!showSpacing && (
                <div
                  className={classNames(
                    'absolute left-0 top-0 -mt-4 ml-4 flex h-8 cursor-default items-center rounded-lg bg-pink-400 px-2 text-white',
                  )}
                  style={{
                    top: controlsTopPosition,
                    ...(positionTooltipLeft
                      ? { left: 0 - size }
                      : { right: -offsetWidth }),
                    height: 24,
                  }}
                >
                  <Icon
                    className={classNames(
                      'w-4',
                      positionTooltipLeft ? 'mr-2' : 'ml-2',
                    )}
                  />
                  <span className="whitespace-nowrap">
                    {elementProps.name ||
                      getText('elements', element.type, 'label')}
                  </span>
                  {selected && (
                    <div
                      className={classNames(
                        'flex items-center',
                        positionTooltipLeft ? 'ml-8' : 'mr-8',
                      )}
                    >
                      <button
                        disabled={!canMoveUp}
                        className="mx-1 w-6 cursor-pointer hover:bg-pink-500 focus:bg-pink-700 disabled:opacity-50"
                        onClick={onMoveElementUp}
                      >
                        <IconArrowUp className="mx-auto w-4" />
                      </button>
                      <button
                        disabled={!canMoveDown}
                        className="mx-1 w-6 cursor-pointer hover:bg-pink-500 focus:bg-pink-700 disabled:opacity-50"
                        onClick={onMoveElementDown}
                      >
                        <IconArrowDown className="mx-auto w-4" />
                      </button>
                      {!element.static && (
                        <>
                          <div
                            className="mx-1 w-6 cursor-pointer hover:bg-pink-500 focus:bg-pink-700"
                            onClick={onCloneSelected}
                          >
                            <IconCopy className="mx-auto w-4" />
                          </div>
                          <div
                            className="mx-1 w-6 cursor-pointer hover:bg-pink-500 focus:bg-pink-700"
                            onClick={onRemoveSelected}
                          >
                            <IconTrash className="mx-auto w-4" />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          </div>
        );
      };

      if (!canvas || !element) {
        return null;
      }

      return ReactDOM.createPortal(tooltip(), canvas);
    },
  ),
);

export default ElementHighlight;
