import { NODE } from '../../constants/elementPropTypeTypes';
import { Element } from '../Element';
import ElementPropType from './ElementPropType';

class NodeType extends ElementPropType {
  defaultNodeShape?: Element;
  showInTree: boolean;
  constructor(defaultNodeShape?: Element) {
    super(NODE);
    this.defaultNodeShape = defaultNodeShape;
    this.showInTree = true;
  }

  setShowInTree(showInTree: boolean) {
    this.showInTree = showInTree;
    return this;
  }
}

export default NodeType;
