import createReactComponent from '../createReactComponent';
export default createReactComponent('devices-heart', 'IconDevicesHeart', [
  ['path', { d: 'M13 12v-3a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v2', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h6',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M16 9h2', key: 'svg-3' }],
]);
