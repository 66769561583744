import createReactComponent from '../createReactComponent';
export default createReactComponent('file-horizontal', 'IconFileHorizontal', [
  ['path', { d: 'M16 5v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 7v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2 -2v-7l-5 -5h-11a2 2 0 0 0 -2 2z',
      key: 'svg-1',
    },
  ],
]);
