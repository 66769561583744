import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-rotated-off',
  'IconSquareRotatedOff',
  [
    [
      'path',
      {
        d: 'M16.964 16.952l-3.462 3.461c-.782 .783 -2.222 .783 -3 0l-6.911 -6.91c-.783 -.783 -.783 -2.223 0 -3l3.455 -3.456m2 -2l1.453 -1.452c.782 -.783 2.222 -.783 3 0l6.911 6.91c.783 .783 .783 2.223 0 3l-1.448 1.45',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
  ],
);
