import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from '@noloco/components';

// Credit: https://stackoverflow.com/questions/40414039/google-docs-viewer-returning-204-responses-no-longer-working-alternatives

const DocumentPreviewIframe = ({ file }: any) => {
  const iframeRef = useRef(null);
  const interval = useRef();
  const [loaded, setLoaded] = useState(false);
  const previewUrl = `https://docs.google.com/gview?embedded=true&url=${file.url}`;

  const clearCheckingInterval = useCallback(() => {
    clearInterval(interval.current);
  }, []);

  const onIframeLoaded = useCallback(() => {
    clearCheckingInterval();
    setLoaded(true);
  }, [clearCheckingInterval]);

  useEffect(() => {
    // @ts-expect-error TS(2322): Type 'Timer' is not assignable to type 'undefined'... Remove this comment to see the full error message
    interval.current = setInterval(() => {
      try {
        // google docs page is blank (204), hence we need to reload the iframe.
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        if (iframeRef.current.contentWindow.document.body.innerHTML === '') {
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          iframeRef.current.src = previewUrl;
        }
      } catch (e) {
        // google docs page is being loaded, but will throw CORS error.
        // it mean that the page won't be blank and we can remove the checking interval.
        onIframeLoaded();
      }
    }, 2000);

    return clearCheckingInterval;
  }, [clearCheckingInterval, onIframeLoaded, previewUrl]);

  return (
    <div className="relative h-full w-full overflow-hidden rounded-lg border">
      <iframe
        id="ms-doc-iframe"
        ref={iframeRef}
        className="h-full w-full overflow-hidden rounded-lg border-0"
        key={file.url}
        frameBorder="0"
        name={file.name}
        onLoad={onIframeLoaded}
        title="pdf-iframe"
        src={previewUrl}
      />
      {!loaded && (
        <div className="absolute top-32 mx-auto w-full">
          <Loader size="md" className="mx-auto" />
        </div>
      )}
    </div>
  );
};

export default DocumentPreviewIframe;
