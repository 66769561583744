import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-plus',
  'IconShoppingCartPlus',
  [
    ['path', { d: 'M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
    ['path', { d: 'M17 17h-11v-14h-2', key: 'svg-2' }],
    ['path', { d: 'M6 5l6 .429m7.138 6.573l-.143 1h-13', key: 'svg-3' }],
    ['path', { d: 'M15 6h6m-3 -3v6', key: 'svg-4' }],
  ],
);
