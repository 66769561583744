import React, { useMemo } from 'react';
import { HelpTooltip, TextInput } from '@noloco/components';
import CopyButton from '@noloco/components/src/components/button/CopyButton';
import { getFieldKey } from '@noloco/core/src/utils/fields';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'data.fields.variablesNames';

const FieldVariableNameDetails = ({ field }: any) => {
  const formUrl = useMemo(() => getFieldKey(field), [field]);

  return (
    <div className="mt-3 flex flex-col text-white">
      <div className="mb-2 flex items-center">
        <label className="mr-2 block text-sm font-medium leading-5">
          {getText(LANG_KEY, 'formUrl.label')}
        </label>
        <HelpTooltip placement="right">
          {getText({ key: formUrl }, LANG_KEY, 'formUrl.tooltip')}
        </HelpTooltip>
      </div>
      <div>
        <div className="mb-3 flex align-middle">
          <TextInput
            className="font-mono"
            p={{ x: 2, y: 2 }}
            type="text"
            readOnly={true}
            disabled={true}
            value={formUrl}
          />
          <CopyButton
            className="ml-2"
            tooltipContent={getText('core.copied')}
            value={formUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default FieldVariableNameDetails;
