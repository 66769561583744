import createReactComponent from '../createReactComponent';
export default createReactComponent('receipt-tax', 'IconReceiptTax', [
  ['path', { d: 'M9 14l6 -6', key: 'svg-0' }],
  [
    'circle',
    { cx: '9.5', cy: '8.5', r: '.5', fill: 'currentColor', key: 'svg-1' },
  ],
  [
    'circle',
    { cx: '14.5', cy: '13.5', r: '.5', fill: 'currentColor', key: 'svg-2' },
  ],
  [
    'path',
    {
      d: 'M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2',
      key: 'svg-3',
    },
  ],
]);
