import createReactComponent from '../createReactComponent';
export default createReactComponent('fountain', 'IconFountain', [
  ['path', { d: 'M9 16v-5a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M15 16v-5a2 2 0 1 1 4 0', key: 'svg-1' }],
  ['path', { d: 'M12 16v-10a3 3 0 0 1 6 0', key: 'svg-2' }],
  ['path', { d: 'M6 6a3 3 0 0 1 6 0', key: 'svg-3' }],
  [
    'path',
    { d: 'M3 16h18v2a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-2z', key: 'svg-4' },
  ],
]);
