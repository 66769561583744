import createReactComponent from '../createReactComponent';
export default createReactComponent('gauge-off', 'IconGaugeOff', [
  [
    'path',
    {
      d: 'M20.038 16.052a9 9 0 0 0 -12.067 -12.102m-2.333 1.686a9 9 0 1 0 12.73 12.726',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11.283 11.303a1 1 0 0 0 1.419 1.41', key: 'svg-1' }],
  ['path', { d: 'M14 10l2 -2', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M7 12c0 -1.386 .564 -2.64 1.475 -3.546m2.619 -1.372c.294 -.054 .597 -.082 .906 -.082',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
