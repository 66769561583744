import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circuit-pushbutton',
  'IconCircuitPushbutton',
  [
    ['path', { d: 'M2 17h2', key: 'svg-0' }],
    ['path', { d: 'M20 17h2', key: 'svg-1' }],
    ['path', { d: 'M6 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
    ['path', { d: 'M18 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
    ['path', { d: 'M6 11h12', key: 'svg-4' }],
    ['path', { d: 'M12 11v-6', key: 'svg-5' }],
  ],
);
