import createReactComponent from '../createReactComponent';
export default createReactComponent('sos', 'IconSos', [
  [
    'path',
    {
      d: 'M7 8h-3a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 8h4v8h-4z', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M17 16h3a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h3',
      key: 'svg-2',
    },
  ],
]);
