import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'bluetooth-connected',
  'IconBluetoothConnected',
  [
    ['path', { d: 'M7 8l10 8l-5 4l0 -16l5 4l-10 8', key: 'svg-0' }],
    ['path', { d: 'M4 12l1 0', key: 'svg-1' }],
    ['path', { d: 'M18 12l1 0', key: 'svg-2' }],
  ],
);
