import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-bilibili', 'IconBrandBilibili', [
  [
    'path',
    {
      d: 'M3 10a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v6a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4v-6z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 3l2 3', key: 'svg-1' }],
  ['path', { d: 'M16 3l-2 3', key: 'svg-2' }],
  ['path', { d: 'M9 13v-2', key: 'svg-3' }],
  ['path', { d: 'M15 11v2', key: 'svg-4' }],
]);
