import createReactComponent from '../createReactComponent';
export default createReactComponent('basket', 'IconBasket', [
  ['path', { d: 'M7 10l5 -6l5 6', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M21 10l-2 8a2 2.5 0 0 1 -2 2h-10a2 2.5 0 0 1 -2 -2l-2 -8z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
]);
