import createReactComponent from '../createReactComponent';
export default createReactComponent('bookmarks', 'IconBookmarks', [
  [
    'path',
    {
      d: 'M13 7a2 2 0 0 1 2 2v12l-5 -3l-5 3v-12a2 2 0 0 1 2 -2h6z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9.265 4a2 2 0 0 1 1.735 -1h6a2 2 0 0 1 2 2v12l-1 -.6',
      key: 'svg-1',
    },
  ],
]);
