import { useMemo } from 'react';
import set from 'lodash/fp/set';
import get from 'lodash/get';

export const useFormSections = (fields: any) =>
  useMemo(() => {
    if (!get(fields, [0, 'isSection'])) {
      return null;
    }

    return fields.reduce((sections: any, field: any) => {
      if (field.isSection) {
        return [...sections, { ...field, fields: [] }];
      }

      const fieldsPath = [sections.length - 1, 'fields'];
      return set(
        fieldsPath,
        [...get(sections, fieldsPath, []), field],
        sections,
      );
    }, []);
  }, [fields]);
