import createReactComponent from '../createReactComponent';
export default createReactComponent('world-code', 'IconWorldCode', [
  ['path', { d: 'M20.942 13.02a9 9 0 1 0 -9.47 7.964', key: 'svg-0' }],
  ['path', { d: 'M3.6 9h16.8', key: 'svg-1' }],
  ['path', { d: 'M3.6 15h9.9', key: 'svg-2' }],
  ['path', { d: 'M11.5 3a17 17 0 0 0 0 18', key: 'svg-3' }],
  ['path', { d: 'M12.5 3c2 3.206 2.837 6.913 2.508 10.537', key: 'svg-4' }],
  ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-5' }],
  ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-6' }],
]);
