import React, { useCallback, useMemo, useState } from 'react';
import { IconCircleNumber0, IconEyeCheck } from '@tabler/icons-react';
import get from 'lodash/get';
import {
  Button,
  ErrorText,
  Notice,
  SelectInput,
  TextInput,
} from '@noloco/components';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import { INFO } from '@noloco/components/src/constants/variants';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { VIEW_NAVIGATION } from '../../constants/buildMode';
import { PIVOT_TABLE, SPLIT } from '../../constants/collectionLayouts';
import { DIVIDER, FOLDER, LINK, PAGE, VIEW } from '../../constants/elements';
import { Element } from '../../models/Element';
import { Project } from '../../models/Project';
import { useErrorAlert } from '../../utils/hooks/useAlerts';
import useRouter from '../../utils/hooks/useRouter';
import { getText } from '../../utils/lang';
import { Page, getPagesConfig } from '../../utils/pages';
import { sanitiseRoutePath } from '../editor/PageEditor';
import BuildModeInput from './BuildModeInput';
import BuildModeSection from './BuildModeSection';
import BuildModeSwitchSection from './BuildModeSwitchSection';

type BuildModeNavigationProps = {
  children?: JSX.Element;
  element: Page | Element;
  project: Project;
  title?: string;
  stickyPosition?: number;
  updateProperty: UpdatePropertyCallback;
};

const BuildModeNavigation = ({
  children,
  element,
  project,
  title,
  updateProperty,
}: BuildModeNavigationProps) => {
  const errorAlert = useErrorAlert();
  const { replace } = useRouter();
  const {
    dataType,
    hide,
    isSubPage,
    layout,
    parentPage,
    routePath,
    showRecordCount,
  } = element.props || {};
  const [localRoutePath, setLocalRoutePath] = useState(routePath);
  const isExternalLink = element.type === LINK;

  const pages = useMemo(
    () =>
      get(
        getPagesConfig(project.elements, project.settings),
        'projectPages',
        [],
      ).filter((page) => ![DIVIDER, LINK].includes(page.type)),
    [project],
  );

  const siblingPages = useMemo(
    () =>
      pages.filter(
        ({ id, props: { parentPage: parentPageId } = {} }: Element) =>
          id !== element.id &&
          ((!parentPage && !parentPageId) ||
            (parentPage && parentPage === parentPageId)),
      ),
    [element.id, pages, parentPage],
  );

  const siblingRoutePaths = siblingPages.map(
    ({ props: { routePath: siblingRoutePath = '' } = {} }) => siblingRoutePath,
  );

  const { pagesPath } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project],
  );

  const topLevelPages = useMemo(
    () =>
      pagesPath.length > 0
        ? get(project.elements, pagesPath, [])
        : project.elements,
    [pagesPath, project.elements],
  );

  const handleRoutePathChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      const nextRoutePath = sanitiseRoutePath(value) || element.id;
      setLocalRoutePath(nextRoutePath);

      if (siblingRoutePaths.includes(localRoutePath)) {
        return errorAlert(getText('elements.PAGE.uniqueUrlPath.label'));
      }
    },
    [element.id, localRoutePath, siblingRoutePaths, errorAlert],
  );

  const saveRoutePath = useCallback(() => {
    updateProperty(['routePath'], localRoutePath);
    replace(localRoutePath);
  }, [updateProperty, localRoutePath, replace]);

  const parentOptions = useMemo(() => {
    if (element.type !== VIEW && element.type !== PAGE) {
      return [];
    }
    return [
      {
        value: null,
        label: getText('elements.PAGE.parentView.none'),
      },
      ...topLevelPages
        .filter(
          ({ id, type, props }: Page) =>
            type === FOLDER ||
            (element.type === VIEW &&
              id !== element.id &&
              get(element, 'props.layout') !== SPLIT &&
              !get(props, 'parentPage') &&
              get(props, 'dataList.dataType') ===
                get(element, 'props.dataList.dataType')),
        )
        .map((parentOption: Page) => ({
          value: parentOption.id,
          label: get(parentOption, 'props.name'),
        })),
    ];
  }, [element, topLevelPages]);

  const isUniqueRoutePath = !siblingRoutePaths.includes(localRoutePath);

  if (element.type === FOLDER) {
    return (
      <div className="flex flex-col justify-center space-y-4">
        <BuildModeInput
          inline={true}
          label={getText('elements.PAGE.routePath.label')}
        >
          <div className="flex items-center space-x-2">
            <TextInput
              className="text-black"
              onChange={handleRoutePathChange}
              placeholder={getText('elements.PAGE.routePath.placeholder')}
              value={localRoutePath === element.id ? '' : localRoutePath}
            />
            <Button onClick={saveRoutePath} size={SM}>
              {getText('leftSidebar.auth.save')}
            </Button>
          </div>
        </BuildModeInput>
        {!isUniqueRoutePath && (
          <ErrorText className="mt-2">
            {getText('elements.PAGE.routePath.notUnique')}
          </ErrorText>
        )}
      </div>
    );
  }

  return (
    <BuildModeSection
      className="border-t"
      id={VIEW_NAVIGATION}
      sticky={true}
      title={title ?? getText('rightSidebar.editor.navigation')}
    >
      <div className="flex flex-col space-y-4 p-2">
        {!isExternalLink && (
          <>
            <BuildModeInput
              inline={true}
              label={getText('elements.PAGE.routePath.label')}
            >
              <div className="flex items-center space-x-2">
                <TextInput
                  className="text-black"
                  onChange={handleRoutePathChange}
                  placeholder={getText('elements.PAGE.routePath.placeholder')}
                  value={localRoutePath === element.id ? '' : localRoutePath}
                />
                <Button onClick={saveRoutePath} size={SM}>
                  {getText('leftSidebar.auth.save')}
                </Button>
              </div>
            </BuildModeInput>
            {!isUniqueRoutePath && (
              <ErrorText className="mt-2">
                {getText('elements.PAGE.routePath.notUnique')}
              </ErrorText>
            )}
          </>
        )}
        {(element.type === VIEW || element.type === PAGE) && (
          <div className="flex flex-col space-y-2">
            <BuildModeInput
              inline={true}
              label={getText('elements.PAGE.parentView.label')}
            >
              <SelectInput
                className="cursor-not-allowed opacity-50"
                contained={true}
                disabled={true}
                onChange={(newParentPage: string) =>
                  updateProperty(['parentPage'], newParentPage)
                }
                options={parentOptions}
                value={get(element, 'props.parentPage', null)}
              />
            </BuildModeInput>
            <Notice
              subtitle={getText('elements.PAGE.parentView.notice')}
              type={INFO}
            />
          </div>
        )}
        {element.type === VIEW && layout !== PIVOT_TABLE && (
          <BuildModeSwitchSection
            label={
              <>
                <IconCircleNumber0 size={16} />
                <label>{getText('elements.PAGE.showRecordCount')}</label>
              </>
            }
            onChange={(value: boolean) =>
              updateProperty(['showRecordCount'], value)
            }
            value={showRecordCount}
          />
        )}
        {!isSubPage &&
          !dataType &&
          element.type !== FOLDER &&
          !isExternalLink &&
          !parentPage && (
            <BuildModeSwitchSection
              label={
                <>
                  <IconEyeCheck size={16} />
                  <label>{getText('elements.PAGE', 'show')}</label>
                </>
              }
              onChange={(value: boolean) => updateProperty(['hide'], !value)}
              value={!hide}
            />
          )}
      </div>
      {children}
    </BuildModeSection>
  );
};

export default BuildModeNavigation;
