import createReactComponent from '../createReactComponent';
export default createReactComponent('bandage-off', 'IconBandageOff', [
  ['path', { d: 'M10 12v.01', key: 'svg-0' }],
  ['path', { d: 'M12 14v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10.513 6.487l1.987 -1.987a4.95 4.95 0 0 1 7 7l-2.018 2.018m-1.982 1.982l-4 4a4.95 4.95 0 0 1 -7 -7l4 -4',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
