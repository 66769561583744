import createReactComponent from '../createReactComponent';
export default createReactComponent('palette-off', 'IconPaletteOff', [
  [
    'path',
    {
      d: 'M15 15h-1a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25a9 9 0 0 1 -6.372 -15.356',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8 4c1.236 -.623 2.569 -1 4 -1c4.97 0 9 3.582 9 8c0 1.06 -.474 2.078 -1.318 2.828a4.516 4.516 0 0 1 -1.127 .73',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8.5 10.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-2' }],
  ['path', { d: 'M12.5 7.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-3' }],
  ['path', { d: 'M16.5 10.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
