import createReactComponent from '../createReactComponent';
export default createReactComponent('mood-dollar', 'IconMoodDollar', [
  ['path', { d: 'M20.87 10.48a9 9 0 1 0 -7.876 10.465', key: 'svg-0' }],
  ['path', { d: 'M9 10h.01', key: 'svg-1' }],
  ['path', { d: 'M15 10h.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M9.5 15c.658 .64 1.56 1 2.5 1c.357 0 .709 -.052 1.043 -.151',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-5' }],
]);
