import createReactComponent from '../createReactComponent';
export default createReactComponent('directions-off', 'IconDirectionsOff', [
  ['path', { d: 'M12 21v-4', key: 'svg-0' }],
  ['path', { d: 'M12 13v-1', key: 'svg-1' }],
  ['path', { d: 'M12 5v-2', key: 'svg-2' }],
  ['path', { d: 'M10 21h4', key: 'svg-3' }],
  ['path', { d: 'M8 8v1h1m4 0h6l2 -2l-2 -2h-10', key: 'svg-4' }],
  ['path', { d: 'M14 14v3h-8l-2 -2l2 -2h7', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
