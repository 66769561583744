import createReactComponent from '../createReactComponent';
export default createReactComponent('messages-off', 'IconMessagesOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M11 11a1 1 0 0 1 -1 -1m0 -3.968v-2.032a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10l-3 -3h-3',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2', key: 'svg-2' },
  ],
]);
