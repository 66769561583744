import createReactComponent from '../createReactComponent';
export default createReactComponent('database-star', 'IconDatabaseStar', [
  [
    'path',
    {
      d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 6v6c0 1.43 2.67 2.627 6.243 2.927', key: 'svg-1' }],
  ['path', { d: 'M20 10.5v-4.5', key: 'svg-2' }],
  ['path', { d: 'M4 12v6c0 1.546 3.12 2.82 7.128 2.982', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z',
      key: 'svg-4',
    },
  ],
]);
