import createReactComponent from '../createReactComponent';
export default createReactComponent('face-mask', 'IconFaceMask', [
  [
    'path',
    {
      d: 'M5 14.5h-.222c-1.535 0 -2.778 -1.12 -2.778 -2.5s1.243 -2.5 2.778 -2.5h.222',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M19 14.5h.222c1.534 0 2.778 -1.12 2.778 -2.5s-1.244 -2.5 -2.778 -2.5h-.222',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 10h6', key: 'svg-2' }],
  ['path', { d: 'M9 14h6', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M12.55 18.843l5 -1.429a2 2 0 0 0 1.45 -1.923v-6.981a2 2 0 0 0 -1.45 -1.923l-5 -1.429a2 2 0 0 0 -1.1 0l-5 1.429a2 2 0 0 0 -1.45 1.922v6.982a2 2 0 0 0 1.45 1.923l5 1.429a2 2 0 0 0 1.1 0z',
      key: 'svg-4',
    },
  ],
]);
