export const SUM = 'SUM';
export const COUNT = 'COUNT';
export const MAX = 'MAX';
export const MIN = 'MIN';
export const AVERAGE = 'AVERAGE';
export const CONCATENATE = 'CONCATENATE';
export const OR = 'OR';
export const AND = 'AND';

const aggregationTypes = [SUM, COUNT, MAX, MIN, AVERAGE, CONCATENATE, OR, AND];

export type Aggregation =
  | 'SUM'
  | 'COUNT'
  | 'MAX'
  | 'MIN'
  | 'AVERAGE'
  | 'CONCATENATE'
  | 'OR'
  | 'AND';

export const NUMERIC_SUMMARY_OPTIONS = [COUNT, SUM, MAX, MIN, AVERAGE];

export default aggregationTypes;
