import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'settings-question',
  'IconSettingsQuestion',
  [
    [
      'path',
      {
        d: 'M14.69 18.498c-.508 .21 -.885 .65 -1.015 1.185c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572a1.67 1.67 0 0 1 1.179 .982',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M14.95 12.553a3 3 0 1 0 -1.211 1.892', key: 'svg-1' }],
    ['path', { d: 'M19 22v.01', key: 'svg-2' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-3',
      },
    ],
  ],
);
