import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';
import {
  adminIntegrationsFragment,
  getProjectQueryWithFragment,
} from '../../queries/core';
import { setIntegrations } from '../../reducers/project';
import usePromiseQuery from './usePromiseQuery';

export const useRefetchIntegrations = (projectName: string) => {
  const dispatch = useDispatch();

  const [fetchProjectIntegrations] = usePromiseQuery(gql`
    ${getProjectQueryWithFragment(adminIntegrationsFragment)}
  `);

  return useCallback(async () => {
    const { data } = await fetchProjectIntegrations({
      variables: {
        projectId: projectName,
      },
    });

    if (data) {
      dispatch(setIntegrations(data.project.integrations));
    }
  }, [dispatch, fetchProjectIntegrations, projectName]);
};
