import React from 'react';
import classNames from 'classnames';
import FeatureLockedButton from '@noloco/ui/src/components/FeatureLockedButton';
import MessageInput from '../../../components/MessageInput';
import { COMMENT_NOTES } from '../../../constants/features';
import { Project } from '../../../models/Project';
import { AuthWrapperUser } from '../../../utils/hooks/useAuthWrapper';
import { getText } from '../../../utils/lang';
import { isInternal } from '../../../utils/user';

type RecordCommentsFooterProps = {
  allowAttachments: boolean;
  className?: string;
  commentText: string;
  filesToSend: never[];
  user: AuthWrapperUser;
  isLoading: boolean;
  onChange: (text: string) => void;
  setSendNote: (sendNote: boolean) => void;
  setFilesToSend: (value: never[]) => void;
  shouldFocus: boolean;
  sendNote: boolean;
  onCreateNewMessage: (event: Event) => Promise<void>;
  project: Project;
};

const LANG_KEY = 'core.COMMENT';

const RecordCommentsFooter = ({
  allowAttachments,
  className,
  commentText,
  isLoading,
  filesToSend,
  onChange,
  onCreateNewMessage,
  sendNote,
  setFilesToSend,
  setSendNote,
  shouldFocus,
  project,
  user,
}: RecordCommentsFooterProps) => (
  <div
    className={classNames(
      className,
      'flex flex-col border-t border-slate-400 pt-4 dark:border-slate-800',
      { 'bg-pink-100 dark:bg-pink-900': sendNote },
    )}
  >
    {isInternal(user) && (
      <div className="flex space-x-2 px-3 py-1">
        <button
          className={classNames(
            'text:gray-800 rounded-lg px-2 py-1 text-xs font-medium dark:text-gray-200',
            {
              'hover:bg-gray-200 dark:hover:bg-gray-600': sendNote,
              'bg-gray-100 dark:bg-gray-500': !sendNote,
            },
          )}
          onClick={() => setSendNote(false)}
        >
          {getText(LANG_KEY, 'internal.reply')}
        </button>
        <FeatureLockedButton
          is="button"
          feature={COMMENT_NOTES}
          iconClassName="text-gray-600 dark:text-gray-100"
          className={classNames(
            'text:gray-800 rounded-lg px-2 py-1 text-xs font-medium dark:text-gray-200',
            {
              'hover:bg-gray-200 dark:hover:bg-gray-600': !sendNote,
              'bg-gray-100 dark:bg-gray-500': sendNote,
            },
          )}
          onClick={() => setSendNote(true)}
        >
          {getText(LANG_KEY, 'internal.note')}
        </FeatureLockedButton>
      </div>
    )}
    <div
      className={classNames(
        'flex justify-end dark:border-gray-700 dark:text-gray-200',
        {
          'bg-pink-100 dark:bg-pink-900': sendNote,
          'bg-white dark:bg-gray-900': !sendNote,
        },
      )}
    >
      <MessageInput
        allowAttachments={allowAttachments}
        dataTypes={project.dataTypes}
        files={filesToSend}
        isLoading={isLoading}
        mentions={true}
        onChange={onChange}
        onChangeFiles={setFilesToSend}
        onSubmit={onCreateNewMessage}
        placeholder={getText(
          LANG_KEY,
          'new',
          sendNote ? 'placeholderNote' : 'placeholderReply',
        )}
        projectName={project.name}
        shouldFocus={shouldFocus}
        value={commentText}
      />
    </div>
  </div>
);

export default RecordCommentsFooter;
