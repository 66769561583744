import createReactComponent from '../createReactComponent';
export default createReactComponent('trophy-off', 'IconTrophyOff', [
  ['path', { d: 'M8 21h8', key: 'svg-0' }],
  ['path', { d: 'M12 17v4', key: 'svg-1' }],
  ['path', { d: 'M8 4h9', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M17 4v8c0 .31 -.028 .612 -.082 .905m-1.384 2.632a5 5 0 0 1 -8.534 -3.537v-5',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M5 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
  ['path', { d: 'M19 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
