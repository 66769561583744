import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import slice from 'lodash/slice';
import sortBy from 'lodash/sortBy';
import { Switch } from '@noloco/components';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { ElementPath } from '@noloco/core/src/models/Element';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionType } from '@noloco/core/src/utils/options';

const MAX_OPTIONS_TO_DISPLAY = 7;
const FORMS_LANG_KEY = 'elements.FORMS';

type FieldOptionsEditorProps = {
  className?: string;
  children?: any;
  field: DataField;
  onChange: (path: ElementPath, value: any) => void;
  values: any;
};

const FieldOptionsEditor = ({
  className,
  children,
  field,
  onChange,
  values,
}: FieldOptionsEditorProps) => {
  const [showMore, setShowMore] = useState(false);
  const fieldOptions = useMemo(() => get(field, 'options', []), [field]);
  const numberOfOptions = useMemo(
    () => (showMore ? fieldOptions.length : MAX_OPTIONS_TO_DISPLAY),
    [showMore, fieldOptions],
  );

  const handleChange = useCallback(
    (option) => (path: any, value: any) =>
      onChange([option.name, ...path], value),
    [onChange],
  );

  const toggleHidden = useCallback(
    (option) => (nextValue: any) =>
      handleChange(option)(['hidden'], !nextValue),
    [handleChange],
  );

  if (!isOptionType(field.type) || !get(field, 'options')) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(
          className,
          'mb-2 mt-6 flex max-w-full flex-col space-y-2 text-white',
        )}
      >
        {slice(sortBy(fieldOptions, 'order'), 0, numberOfOptions).map(
          (option) => (
            <div className="group flex items-center justify-between">
              <span className="max-w-full truncate group-hover:text-teal-500">
                {option.display}
              </span>
              <div className="ml-4 flex items-center space-x-2">
                {!get(values, [option.name, 'hidden'], false) &&
                  children &&
                  children({
                    option,
                    onChange: handleChange(option),
                    value: get(values, option.name),
                  })}
                <Switch
                  onChange={toggleHidden(option)}
                  size="sm"
                  value={!get(values, [option.name, 'hidden'], false)}
                />
              </div>
            </div>
          ),
        )}
      </div>
      {fieldOptions.length > MAX_OPTIONS_TO_DISPLAY && (
        <div className="cursor-pointer text-center text-xs text-gray-500 hover:text-teal-500">
          <span onClick={() => setShowMore(!showMore)}>
            {getText(
              { type: showMore ? 'less' : 'more' },
              FORMS_LANG_KEY,
              'options',
            )}
          </span>
        </div>
      )}
    </>
  );
};

export default FieldOptionsEditor;
