import React, { memo, useCallback, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import {
  Badge,
  ErrorText,
  FormattedNumberInput,
  InlineFormattedNumberInput,
  RangeInput,
  RatingInput,
  SelectInput,
  Surface,
  Switch,
  TextArea,
  TextInput,
  Theme,
  getColorShade,
} from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { MD, XS } from '@noloco/components/src/constants/tShirtSizes';
import { numberOfRows } from '@noloco/components/src/utils/numberOfRows';
import DatePicker from '../../../components/DatePicker';
import { FILE } from '../../../constants/builtInDataTypes';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
  MULTIPLE_OPTION,
  OBJECT,
  SINGLE_OPTION,
  TEXT,
} from '../../../constants/dataTypes';
import TYPES_WITHOUT_MUTATIONS from '../../../constants/dataTypesWithoutMutations';
import { TIME_24 } from '../../../constants/dateFormatOptions';
import {
  CURRENCY,
  DATE as DATE_FORMAT,
  FieldFormat,
  MULTILINE_TEXT,
  PERCENTAGE,
  RATING,
  SLIDER,
  TIME,
  UNFORMATTED_NUMBER,
} from '../../../constants/fieldFormats';
import fileTypes from '../../../constants/fileTypes';
import {
  AUTOCOMPLETE_ADDRESS,
  CHECKBOX,
  COLORED_OPTIONS,
  MARKDOWN,
  RADIO,
  SIGNATURE,
} from '../../../constants/inputTypes';
import { FILE_TYPE_TO_MIMETYPES } from '../../../constants/mimetypes';
import RelationalDataFieldInput from '../../../elements/sections/collections/filters/RelationalDataFieldInput';
import { DataField, DataFieldOption } from '../../../models/DataTypeFields';
import DataTypes from '../../../models/DataTypes';
import { FileLayout } from '../../../models/Element';
import { RecordEdge } from '../../../models/Record';
import { getColorByIndex, getPrimaryColorGroup } from '../../../utils/colors';
import {
  durationToString,
  getDurationFromString,
} from '../../../utils/durations';
import { formatNumberValue } from '../../../utils/fieldValues';
import { sortOptions } from '../../../utils/fields';
import { useUpdateAllRecordsSwitch } from '../../../utils/hooks/useUpdateAllRecordsSwitch';
import { getText } from '../../../utils/lang';
import {
  formatPercentageForInput,
  getFieldPrecision,
} from '../../../utils/numbers';
import { hasNullOption } from '../../../utils/options';
import {
  isMultiRelationship,
  isReverseMultiRelationship,
} from '../../../utils/relationships';
import Checkbox from '../../Checkbox';
import RadioInputGroup from '../../Radio';
import RelatedCellItem from '../collections/RelatedCellItem';
import ArrayFormInput from './ArrayFormInput';
import FilePreview from './FilePreview';
import ObjectInput from './ObjectInput';
import RichTextInput from './RichTextInput';
import SignatureInput from './SignatureInput';
import AddressAutocompleteInput from './objects/AddressAutocompleteInput';

const stopPropagation = (event: any) => event.stopPropagation();

export const formatDateValue = (
  dateValue: DateTime,
  format?: FieldFormat | undefined,
) => {
  if (format === DATE_FORMAT) {
    return dateValue.toUTC().set({ hour: 0, minute: 0, second: 0 }).toISO();
  }

  return dateValue.toUTC().toISO();
};

const formatTextInputOnBlur = (fieldType: any, value: any) => {
  if (fieldType === DURATION) {
    const duration = getDurationFromString(value);
    return !isNil(duration) && duration.isValid
      ? durationToString(duration)
      : '';
  }

  return value;
};

const formatTextInput = (fieldType: any, value: any, typeOptions = {}) => {
  if (fieldType === TEXT) {
    return value;
  }

  if (!value) {
    return value;
  }

  let nextValue = value;

  // @ts-expect-error TS(2339): Property 'format' does not exist on type '{}'.
  const { format } = typeOptions;
  const fieldPrecision = getFieldPrecision(fieldType, typeOptions);

  if (format === PERCENTAGE) {
    nextValue = formatPercentageForInput(nextValue, fieldPrecision);
  }

  return nextValue;
};

const formatDurationTimeTo24Hour = (value: DateTime) => value.toFormat(TIME_24);

const formatDurationValue = (value: string) => {
  const duration = DateTime.fromISO(value);

  return value && value !== '' && duration.isValid
    ? formatDurationTimeTo24Hour(duration)
    : null;
};

export type DataFieldInputProps = {
  ReadOnlyCell?: any;
  additionalRelatedFields?: any;
  allowNewRecords?: boolean;
  authQuery?: any;
  autoFocus?: boolean;
  canBulkUpdate?: boolean;
  canEdit?: boolean;
  collectionFilter?: any;
  customFilters?: any;
  dataTypes: DataTypes;
  disabled?: boolean;
  depth?: number;
  field: DataField;
  fileLayout?: FileLayout;
  id: string;
  inline?: boolean;
  inputType?: string;
  newRecordFormFields?: any;
  newRecordFormTitle?: string;
  onBlur: (event?: Event) => void;
  onChange: (...args: any) => void;
  onRemoveFiles?: (id: number[]) => void;
  onlyAllowNewRecords?: boolean;
  orderBy?: any;
  placeholder?: string;
  project?: any;
  projectName?: string;
  required?: boolean;
  selectMultiple?: boolean;
  surface: Surface;
  validationError?: string | JSX.Element;
  value?: any;
  optionsConfig?: any;
  fileTypeValidation?: any;
  theme: Theme;
};

const DataFieldInput = memo<DataFieldInputProps>(
  (props: DataFieldInputProps) => {
    const {
      allowNewRecords = false,
      onlyAllowNewRecords = false,
      autoFocus,
      authQuery,
      canEdit,
      customFilters,
      collectionFilter,
      dataTypes,
      depth = 0,
      disabled,
      field,
      fileLayout,
      placeholder,
      id,
      inline,
      inputType,
      newRecordFormFields,
      newRecordFormTitle,
      orderBy,
      onBlur,
      onChange,
      onRemoveFiles,
      projectName,
      project,
      selectMultiple,
      surface,
      validationError,
      value,
      ReadOnlyCell,
      required,
      additionalRelatedFields,
      canBulkUpdate,
      optionsConfig,
      fileTypeValidation,
      theme,
    } = props;

    const handleRemoveFile = useCallback(
      (fileIds: number[]) => {
        if (canEdit && onRemoveFiles) {
          onRemoveFiles(fileIds);
          onBlur();
        }
      },
      [canEdit, onBlur, onRemoveFiles],
    );

    const { updateAllRecords, setUpdateAllRecords } =
      useUpdateAllRecordsSwitch();

    const footer = useMemo(
      () =>
        canBulkUpdate && (
          <div className="flex w-full items-center justify-between">
            <label
              className={classNames('mr-4', {
                'text-gray-200': surface === DARK,
                'text-gray-700': surface === LIGHT,
              })}
            >
              {getText('elements.VIEW.display.bulkActions.updateAll')}
            </label>
            <Switch
              className="ml-auto"
              size="sm"
              value={updateAllRecords}
              onChange={(value: boolean) => setUpdateAllRecords(value)}
            />
          </div>
        ),
      [canBulkUpdate, surface, setUpdateAllRecords, updateAllRecords],
    );

    const allowedMimetypes = useMemo(() => {
      return fileTypes.reduce<string[]>((acc, fileType) => {
        const fileMimeTypes =
          fileTypeValidation &&
          fileTypeValidation[fileType] !== false &&
          FILE_TYPE_TO_MIMETYPES[fileType];
        return fileMimeTypes ? [...acc, ...fileMimeTypes] : acc;
      }, []);
    }, [fileTypeValidation]);

    const { format } = field.typeOptions || {};
    const prefix = useMemo(() => {
      if (format === CURRENCY) {
        return get(field.typeOptions, 'symbol', '$');
      }

      if (!format || (format && format === UNFORMATTED_NUMBER)) {
        return get(field.typeOptions, 'prefix');
      }

      return undefined;
    }, [format, field]);

    const suffix = useMemo(() => {
      if (format === PERCENTAGE) {
        return '%';
      }

      if (!format || (format && format === UNFORMATTED_NUMBER)) {
        return get(field.typeOptions, 'suffix');
      }

      return undefined;
    }, [format, field]);

    if (canEdit) {
      const onChangeWithOnBlur = (...args: any[]) => {
        onChange(...args);
        onBlur();
      };

      const formatOnBlur = (field: any) => (event: any) => {
        const originalValue = String(event.target.value);
        event.target.value = formatTextInputOnBlur(
          field.type,
          event.target.value,
          // @ts-expect-error TS(2554): Expected 2 arguments, but got 3.
          field.typeOptions,
        );
        if (originalValue !== event.target.value) {
          onChange(event.target.value);
        }
        onBlur(event);
      };

      if (field.multiple) {
        return <ArrayFormInput {...props} />;
      }

      if (field.type === BOOLEAN) {
        if (inputType === RADIO && !inline) {
          const booleanOptions = [false, true].map((v) => ({
            value: v,
            label: getText('editor.boolean', v),
          }));
          const name = `${id}-${field.name}-bool-radio`;
          return (
            <RadioInputGroup
              disabled={disabled}
              options={booleanOptions}
              name={name}
              validationError={validationError}
              value={value}
              onChange={onChangeWithOnBlur}
            />
          );
        }

        return (
          <Checkbox
            className={classNames(
              'mx-auto flex',
              { 'my-1': inline },
              { 'my-2 w-full': !inline },
              surface !== DARK ? 'text-gray-500' : 'text-gray-900',
            )}
            size="md"
            checked={value}
            disabled={disabled}
            validationError={validationError}
            value={value}
            onClick={stopPropagation}
            onChange={({ target: { checked } }: any) => {
              onChange(checked);
              onBlur();
            }}
            elementId={id}
          />
        );
      }

      if (field.type === DURATION) {
        if (format === TIME) {
          return (
            <DatePicker
              id={id}
              autoFocus={autoFocus}
              disabled={disabled}
              selectTime={true}
              showTimeSelectOnly={true}
              {...(inline
                ? {
                    border: 0,
                    bg: 'transparent',
                    p: { x: 0, y: 0, l: 2 },
                    text: ['xs', surface === DARK ? 'gray-200' : 'gray-700'],
                    h: 8,
                  }
                : {})}
              // @ts-expect-error TS(2322): Type '{ inline: any; validationError: any; value: ... Remove this comment to see the full error message
              inline={inline}
              validationError={validationError}
              value={
                value && DateTime.fromISO(value).isValid
                  ? DateTime.fromISO(value)
                  : null
              }
              onBlur={onBlur}
              onChange={(val: any) => onChange(formatDurationValue(val))}
              surface={surface}
              placeholder={placeholder || ''}
              w="full"
            />
          );
        }

        return (
          <TextInput
            id={id}
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={formatOnBlur(field)}
            onChange={({ target: { value: val } }) =>
              onChange(val === '' ? null : val)
            }
            validationError={validationError}
            value={value}
            placeholder={placeholder || ''}
            type="text"
            surface={surface}
            {...(inline
              ? { bg: 'transparent', border: false, 'ring-focus': false }
              : {})}
          />
        );
      }

      if (field.type === TEXT) {
        const isMultiline = !format || format === MULTILINE_TEXT;

        if (isMultiline && inputType === MARKDOWN) {
          return (
            <RichTextInput
              onChange={onChange}
              disabled={disabled}
              value={value}
              placeholder={placeholder || ''}
              surface={surface}
            />
          );
        }

        const mapsApiKey = get(project, 'settings.apiKeys.googleMaps');

        if (inputType === AUTOCOMPLETE_ADDRESS && mapsApiKey) {
          return (
            <AddressAutocompleteInput
              onChange={(path: string[], val: any) => {
                onChange(val);
              }}
              disabled={disabled}
              value={value}
              placeholder={placeholder || ''}
              isObjectInput={false}
              isMultiline={format === MULTILINE_TEXT}
              surface={surface}
              mapsApiKey={mapsApiKey}
            />
          );
        }

        const rows = numberOfRows(value, 40);

        const TextInputComponent = isMultiline ? TextArea : TextInput;

        return (
          <TextInputComponent
            id={id}
            data-testid={id}
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={onBlur}
            // @ts-expect-error TS(7031): Binding element 'val' implicitly has an 'any' type... Remove this comment to see the full error message
            onChange={({ target: { value: val } }) =>
              onChange(formatTextInput(field.type, val, field.typeOptions))
            }
            validationError={validationError}
            value={value}
            placeholder={placeholder || ''}
            inline={inline}
            type={isMultiline ? 'textarea' : 'text'}
            minRows={isMultiline && !inline ? 2 : undefined}
            rows={rows}
            surface={surface}
            {...(inline
              ? { bg: 'transparent', border: false, 'ring-focus': false }
              : {})}
          />
        );
      }

      if ([INTEGER, DECIMAL].includes(field.type)) {
        const NumberInputComponent = inline
          ? InlineFormattedNumberInput
          : FormattedNumberInput;

        const numberValue = formatNumberValue(
          Number(value),
          field.type,
          field.typeOptions,
        );

        if (format === SLIDER) {
          const min = get(field, 'typeOptions.min', 0);
          const max = get(field, 'typeOptions.max') || 100;
          const step = get(field, 'typeOptions.step') || 1;
          const suffix = get(field, 'typeOptions.suffix');

          return (
            <RangeInput
              activeColor={getPrimaryColorGroup(theme)[500]}
              min={min}
              max={max}
              onChange={onChange}
              step={step}
              suffix={suffix}
              value={numberValue}
            />
          );
        }

        if (format === RATING) {
          return (
            <>
              <RatingInput
                disabled={disabled}
                maxRating={get(field.typeOptions, 'max')}
                onChange={(val: number | null) => onChange(val)}
                value={numberValue}
              />
              {validationError && (
                <ErrorText className="mt-2 text-left">
                  {validationError}
                </ErrorText>
              )}
            </>
          );
        }

        return (
          <NumberInputComponent
            id={id}
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={onBlur}
            onChange={(val: any) => {
              onChange(formatTextInput(field.type, val, field.typeOptions));
            }}
            validationError={validationError}
            value={!isNil(value) ? Number(numberValue) : null}
            placeholder={placeholder || ''}
            decimalScale={getFieldPrecision(field.type, field.typeOptions)}
            thousandSeparator={
              format === CURRENCY ||
              (format !== UNFORMATTED_NUMBER && !isNil(value))
            }
            p={!inline ? { x: 3, y: 1.5 } : undefined}
            prefix={prefix}
            suffix={suffix}
            surface={surface}
          />
        );
      }

      if (field.type === DATE) {
        return (
          <DatePicker
            id={id}
            autoFocus={autoFocus}
            disabled={disabled}
            selectTime={inputType !== DATE && format !== DATE_FORMAT}
            {...(inline
              ? {
                  border: 0,
                  bg: 'transparent',
                  p: { x: 0, y: 0, l: 2 },
                  text: ['xs', surface === DARK ? 'gray-200' : 'gray-700'],
                  h: 8,
                }
              : {})}
            // @ts-expect-error TS(2322): Type '{ inline: any; validationError: any; value: ... Remove this comment to see the full error message
            inline={inline}
            validationError={validationError}
            value={value ? DateTime.fromISO(value) : null}
            onBlur={onBlur}
            onChange={(val: any) =>
              onChange(val ? formatDateValue(val, format) : null)
            }
            surface={surface}
            placeholder={placeholder || ''}
            timeZone={get(field, 'typeOptions.timeZone')}
            w="full"
          />
        );
      }

      if (field.type === SINGLE_OPTION || field.type === MULTIPLE_OPTION) {
        const nullOption = {
          color: 'gray',
          display: getText('core.SELECT_INPUT.none'),
          name: null, //Typescript doesn't like null as a value for name
        } as unknown as DataFieldOption;
        const options = field.options ?? [];

        const sortedOptions = sortOptions([
          ...(!isNil(value) &&
          hasNullOption({ ...field, required: field.required || required })
            ? [nullOption]
            : []),
          ...options,
        ]).map((option) => ({
          label: option.display,
          value: option.name,
          color: option.color,
        }));

        const getOptionLabel = (option: any, index: number) => (
          <Badge
            className="overflow-hidden"
            color={getColorShade(option.color || getColorByIndex(index), 400)}
          >
            {option.label}
          </Badge>
        );
        if (field.type === SINGLE_OPTION && inputType === RADIO && !inline) {
          const name = `${id}-${field.name}-radio`;
          const radioOptions = sortedOptions
            .filter((option) => option.value !== null)
            .map((option, index) => ({
              ...option,
              label: getOptionLabel(option, index),
              help: get(optionsConfig, [option.value, 'helpText']),
            }));
          return (
            <RadioInputGroup
              disabled={disabled}
              options={radioOptions}
              name={name}
              validationError={validationError}
              value={value}
              onChange={onChangeWithOnBlur}
            />
          );
        }

        if (
          field.type === MULTIPLE_OPTION &&
          inputType === CHECKBOX &&
          !inline
        ) {
          const name = `${id}-${field.name}-radio`;
          return (
            <div className="flex flex-col space-y-3" role="radiogroup">
              {sortedOptions.map((option, index) => {
                const checked = value && value.includes(option.value);

                const onCheckboxChange = () => {
                  if (checked) {
                    onChangeWithOnBlur(
                      value.filter((v: any) => v !== option.value),
                    );
                  } else if (!value) {
                    onChangeWithOnBlur([option.value]);
                  } else {
                    onChangeWithOnBlur([...value, option.value]);
                  }
                };

                return (
                  <Checkbox
                    key={option.value}
                    disabled={disabled}
                    className="flex items-center text-blue-400 ring-blue-300 default:ring-2 focus:ring-2"
                    id={`${name}-${option.value}`}
                    name={name}
                    aria-checked={checked}
                    validationError={validationError}
                    help={get(optionsConfig, [option.value, 'helpText'])}
                    value={option.value}
                    checked={checked}
                    onChange={onCheckboxChange}
                    tabIndex={
                      value === option.value ||
                      ((isNil(value) || value.length === 0) && index === 0)
                        ? 0
                        : -1
                    }
                  >
                    {getOptionLabel(option, index)}
                  </Checkbox>
                );
              })}
            </div>
          );
        }

        const selectInputOptions = sortedOptions.map((option, index) => ({
          ...option,
          ...(inputType === COLORED_OPTIONS
            ? {
                bg: getColorShade(
                  option.color || getColorByIndex(index),
                  surface === DARK ? 700 : 200,
                ),
                label: getOptionLabel(option, index),
              }
            : {}),
        }));

        return (
          <SelectInput
            id={id}
            bg={inline ? 'transparent' : undefined}
            border={inline ? 0 : undefined}
            disabled={disabled}
            inline={inline}
            options={selectInputOptions}
            searchable={true}
            onChange={onChangeWithOnBlur}
            placeholder={
              placeholder ||
              getText(
                { dataType: field.display },
                'core.SELECT_INPUT.placeholder',
              )
            }
            multiple={field.type === MULTIPLE_OPTION}
            surface={surface}
            validationError={validationError}
            size={inline ? XS : MD}
            value={value}
            footer={footer}
            coloredOptionType={inputType === COLORED_OPTIONS}
          />
        );
      }

      if (field.type === OBJECT) {
        return (
          <ObjectInput
            disabled={disabled}
            field={field}
            format={field.typeOptions?.format!}
            id={id}
            inline={inline}
            onBlur={onBlur}
            onChange={onChange}
            surface={surface}
            value={value}
          />
        );
      }

      if (
        (field.relationship ||
          (field.relatedField &&
            !TYPES_WITHOUT_MUTATIONS.includes(field.type))) &&
        field.type !== FILE
      ) {
        const additionalFields = {
          __args: {
            ...(orderBy ? { orderBy } : {}),
            ...(customFilters ? { where: customFilters } : {}),
          },
          ...(additionalRelatedFields[field.name] ?? {}),
        };

        return (
          <RelationalDataFieldInput
            id={id}
            additionalFields={additionalFields}
            authQuery={authQuery}
            autoHydrateValue={true}
            allowNewRecords={allowNewRecords}
            onlyAllowNewRecords={onlyAllowNewRecords}
            disabled={disabled}
            newRecordFormFields={newRecordFormFields}
            newRecordFormTitle={newRecordFormTitle}
            field={field}
            depth={depth}
            newRecordStateId={`NEW:${field.id}:${depth}`}
            filter={collectionFilter}
            dataTypes={dataTypes}
            inline={inline}
            inputType={inputType}
            multiple={
              isMultiRelationship(field.relationship) ||
              (field.relatedField &&
                isReverseMultiRelationship(field.relatedField.relationship)) ||
              selectMultiple
            }
            projectName={projectName}
            placeholder={placeholder}
            value={value}
            validationError={validationError}
            onChange={onChangeWithOnBlur}
            isPlayground={false}
            surface={surface}
            project={project}
            footer={footer}
            renderLabel={(field: any) =>
              (value: any, _isDisabled: any, dataTypes: any) => (
                <RelatedCellItem
                  field={field}
                  value={value}
                  dataTypes={dataTypes}
                  single={true}
                  truncate={false}
                />
              )}
          />
        );
      }

      if (field.type === FILE) {
        const isMultiFile = isMultiRelationship(field.relationship);
        const files = isMultiFile
          ? get(value, 'edges', []).map((edge: RecordEdge) => edge.node)
          : [value];

        if (inputType === SIGNATURE) {
          return (
            <SignatureInput
              field={field}
              value={value}
              onChange={onChange}
              onChangeWithOnBlur={onChangeWithOnBlur}
              placeholder={placeholder}
              validationError={validationError}
            />
          );
        }

        return (
          <>
            <FilePreview
              id={String(field.id)}
              files={files}
              placeholder={placeholder}
              isMultiple={isMultiFile}
              layout={fileLayout ? { size: fileLayout.size } : undefined}
              maxFiles={isMultiFile ? 40 : 1}
              readOnly={disabled}
              surface={surface}
              onChange={onChangeWithOnBlur}
              onRemove={(fileId: number) => handleRemoveFile([fileId])}
              acceptedMimetypes={
                fileTypeValidation && allowedMimetypes.length > 0
                  ? allowedMimetypes
                  : undefined
              }
              fileTypeValidation={fileTypeValidation}
            />
            {validationError && <ErrorText>{validationError}</ErrorText>}
          </>
        );
      }
    }

    return (
      <div className="rounded-lg py-1.5">
        <ReadOnlyCell
          id={id}
          dataTypes={dataTypes}
          field={field}
          inline={inline}
          projectName={projectName}
          value={value}
          project={project}
          surface={surface}
        />
      </div>
    );
  },
);

(DataFieldInput as any).defaultProps = {
  onBlur: () => null,
  surface: DARK,
  additionalRelatedFields: {},
};

DataFieldInput.displayName = 'DataFieldInput';

export default withTheme(DataFieldInput);
