import React from 'react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Modal } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';

type Props = {
  onClose: () => void;
  onConfirm: () => void;
};

const ChangeFieldTypeWarningModal = ({ onConfirm, onClose }: Props) => {
  return (
    <Modal
      icon={<IconAlertTriangle size={18} />}
      title={getText('data.fields.editType.title')}
      confirmText={getText('data.fields.editType.confirm')}
      cancelText={getText('data.fields.editType.cancel')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      data-testid="change-field-type-warning-modal"
      variant="primary"
    >
      <p className="mb-4">{getText('data.fields.editType.body')}</p>
    </Modal>
  );
};

export default ChangeFieldTypeWarningModal;
