import React, { forwardRef, useState } from 'react';
import { IconPencil } from '@tabler/icons-react';
import classNames from 'classnames';
import { useSelected } from 'slate-react';
import { Popover } from '@noloco/components';
import ContentDisplayName from './ContentDisplayName';

const ContentItem = forwardRef(
  // @ts-expect-error TS(2339): Property 'selected' does not exist on type '{ chil... Remove this comment to see the full error message
  ({ children, hasOptions, onClick, ...rest }, ref) => {
    return (
      <span
        {...rest}
        className={classNames()}
        onClick={onClick}
        contentEditable={false}
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
      >
        {children}
        {hasOptions && (
          <span className="ml-1 text-white">
            <IconPencil className="inline-block" size={14} />
          </span>
        )}
      </span>
    );
  },
);

type OwnDataVariableBlockProps = {
  className?: string;
  checkFieldPermissions: boolean;
  item: {
    data?: string;
  };
  onChange: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'DataVariableBlockProps' circularly ref... Remove this comment to see the full error message
type DataVariableBlockProps = OwnDataVariableBlockProps &
  typeof DataVariableBlock.defaultProps;

// @ts-expect-error TS(7022): 'DataVariableBlock' implicitly has type 'any' beca... Remove this comment to see the full error message
const DataVariableBlock = ({
  checkFieldPermissions,
  dataOptions,
  elementData,
  elementPath,
  ...rest
}: DataVariableBlockProps) => {
  const { data = {}, options = {} } = elementData;
  const selected = useSelected();
  const [value] = useState({
    data,
    options,
  });

  if (value && value.data && value.data.id) {
    return (
      <Popover
        content={
          <div className="flex max-w-sm flex-col p-2 text-black">
            <div className="flex items-center justify-between">
              <ContentDisplayName
                checkFieldPermissions={false}
                data={value.data}
                dataOptions={dataOptions}
                parentPath={elementPath}
              />
            </div>
          </div>
        }
        trigger="click"
        placement="right"
        closeOnOutsideClick={true}
        usePortal={true}
      >
        {/* @ts-expect-error TS(2322): Type '{ children: Element; hasOptions: boolean; se... Remove this comment to see the full error message */}
        <ContentItem hasOptions={false}>
          <span className="cursor-pointer truncate">
            <ContentDisplayName
              checkFieldPermissions={checkFieldPermissions}
              compact={true}
              data={value.data}
              dataOptions={dataOptions}
              displayAsToken={true}
              parentPath={elementPath}
              selected={selected}
            />
          </span>
        </ContentItem>
      </Popover>
    );
  }

  return (
    <span
      className="cursor-pointer select-none"
      contentEditable={false}
      {...rest}
    >
      <ContentDisplayName
        checkFieldPermissions={checkFieldPermissions}
        compact={true}
        data={value.data}
        dataOptions={dataOptions}
        parentPath={elementPath}
      />
    </span>
  );
};

DataVariableBlock.defaultProps = {
  className: '',
};

export default DataVariableBlock;
