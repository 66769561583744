import React, { useCallback, useEffect, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { Switch as BaseSwitch } from '@headlessui/react';
import classNames from 'classnames';
import { LG, MD, SM } from '../../constants/tShirtSizes';
import { getColorShade } from '../../utils/colors';

type OwnProps = {
  disabled?: boolean;
  size?: any; // TODO: PropTypes.oneOf([SM, MD, LG])
  value?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Switch.defaultProps;

// @ts-expect-error TS(7022): 'Switch' implicitly has type 'any' because it does... Remove this comment to see the full error message
const Switch = ({
  className,
  'data-testid': dataTestid,
  disabled,
  onChange,
  size,
  value,
  theme,
}: Props) => {
  const [enabled, setEnabled] = useState(value);
  const primaryColor = theme.brandColors.primary;

  const handleOnChange = useCallback(
    (newValue: any) => {
      setEnabled(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  useEffect(() => {
    setEnabled(value);
  }, [value]);

  return (
    <BaseSwitch
      checked={enabled}
      disabled={disabled}
      onChange={handleOnChange}
      className={classNames(
        className,
        'relative inline-flex flex-shrink-0 items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 disabled:opacity-50',
        {
          'w-8': size === SM,
          'w-10': size === MD,
          'w-12': size === LG,
          'cursor-pointer': !disabled,
        },
        enabled ? `bg-${getColorShade(primaryColor, 500)}` : `bg-gray-400`,
      )}
      data-testid={dataTestid}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          {
            'translate-x-0': enabled,
            'h-3 w-3': size === SM,
            'h-4 w-4': size === MD,
            'h-6 w-6': size === LG,
            'translate-x-4': enabled && size === SM,
            'translate-x-5': enabled && (size === MD || size === LG),
          },
          'pointer-events-none inline-block transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out',
        )}
      />
    </BaseSwitch>
  );
};

Switch.defaultProps = {
  disabled: false,
  size: MD,
};

export default withTheme(Switch);
