import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { Popover } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { LINK } from '@noloco/core/src/constants/elements';
import { PARAGRAPH, SPAN } from '@noloco/core/src/constants/textTypes';
import {
  ElementPath,
  StringPropSegment,
} from '@noloco/core/src/models/Element';
import { DataItemValueOption } from '@noloco/core/src/utils/state';
import { getNodePropsFromStyleProps } from '../../utils/richText';
import DataVariableBlock from './DataVariableBlock';
import DynamicValuePopoverBody from './dynamicValueInput/DynamicValuePopoverBody';

const DEFAULT_TEXT = [{ text: 'Text' }];
const VARIABLE = 'variable';

const makeVariableElement = ({ data, options, ...styleProps }: any) => ({
  type: VARIABLE,
  data: { data, options },
  ...getNodePropsFromStyleProps(styleProps),
  children: [{ text: '' }],
});

const insertDataVariableElement = (editor: any, data = {}) => {
  const variable = makeVariableElement({ data, options: {} });
  Transforms.insertNodes(editor, [variable]);
  Transforms.move(editor);
};

const withVariables = (editor: any) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element: any) =>
    element.type === VARIABLE || element.type === SPAN || element.type === LINK
      ? true
      : isInline(element);
  editor.isVoid = (element: any) =>
    element.type === VARIABLE ? true : isVoid(element);

  return editor;
};

function withSingleLine(editor: any, isMultiLine: any) {
  const { normalizeNode } = editor;

  if (!isMultiLine) {
    // @ts-expect-error TS(7031): Binding element 'node' implicitly has an 'any' typ... Remove this comment to see the full error message
    editor.normalizeNode = ([node, path]) => {
      if (path.length === 0) {
        if (editor.children.length > 1) {
          Transforms.mergeNodes(editor);
        }
      }

      return normalizeNode([node, path]);
    };
  }

  return editor;
}

const getDataItemsFromEditorState = (paragraphs: any) => {
  const dataItems: any = [];

  paragraphs.forEach((paragraph: any, index: any) => {
    if (index > 0) {
      const lastDataItem =
        dataItems.length > 0 ? dataItems[dataItems.length - 1] : null;
      if (lastDataItem && lastDataItem.text) {
        lastDataItem.text += '\n';
      } else {
        dataItems.push({
          text: '\n',
        });
      }
    }
    paragraph.children.forEach((child: any, childIndex: any) => {
      const isLastChildInLastParagraph =
        index === paragraphs.length - 1 &&
        childIndex === paragraph.children.length - 1;
      const styleProps =
        child.styleProps && Object.keys(child.styleProps).length > 0
          ? { styleProps: child.child.styleProps }
          : {};
      if (child.type === VARIABLE) {
        dataItems.push({ ...child.data, ...styleProps });
      } else if (!isLastChildInLastParagraph || child.text !== ' ') {
        dataItems.push({ text: child.text, ...styleProps });
      }
    });
  });

  // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
  return dataItems.filter((item) => item.text || item.data);
};

const getEmptyParagraph = (children = []) => ({
  type: PARAGRAPH,
  children,
});

const getSlateValue = (rawItems: any) => {
  const paragraphs = [getEmptyParagraph()];
  let currentParagraphIndex = 0;

  const items = rawItems || DEFAULT_TEXT;

  items.forEach((item: any, index: any) => {
    if (!item.text && item.data) {
      const currentParagraph = paragraphs[currentParagraphIndex];
      const variableElement = makeVariableElement(item);
      if (paragraphs[currentParagraphIndex].children.length === 0) {
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
        currentParagraph.children.push({ text: '' });
      }
      // @ts-expect-error TS(2345): Argument of type '{ children: { text: string; }[];... Remove this comment to see the full error message
      currentParagraph.children.push(variableElement);
      if (
        index === items.length - 1 ||
        (items[index].text && items[index].text.startsWith('\n'))
      ) {
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
        currentParagraph.children.push({ text: ' ' });
      }
    } else {
      String(item.text || '')
        .split('\n')
        .forEach((lineText, lineIndex) => {
          if (lineIndex !== 0) {
            paragraphs.push(getEmptyParagraph());
            currentParagraphIndex += 1;
          }
          if (lineText) {
            paragraphs[currentParagraphIndex].children.push({
              text: lineText,
              styleProps: item.styleProps,
            } as never);
          }
        });
    }
  });

  return paragraphs.map((paragraph) => {
    return paragraph.children.length === 0
      ? // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
        getEmptyParagraph([{ text: '' }])
      : paragraph;
  });
};

type DraftEditorProps = {
  checkFieldPermissions: boolean;
  dataOptions: DataItemValueOption[];
  elementPath: ElementPath;
  items: StringPropSegment[];
  multiLine?: boolean;
  onUpdate: (value: StringPropSegment[]) => void;
  placeholder: string;
  disabled?: boolean;
};

const DraftEditor = forwardRef<any, DraftEditorProps>(
  (
    {
      checkFieldPermissions = true,
      dataOptions,
      items,
      multiLine = false,
      onUpdate,
      placeholder,
      disabled,
    },
    ref,
  ) => {
    const editor = useMemo(
      () =>
        withSingleLine(
          // @ts-expect-error TS(2345): Argument of type 'BaseEditor' is not assignable to... Remove this comment to see the full error message
          withVariables(withHistory(withReact(createEditor()))),
          multiLine,
        ),
      [multiLine],
    );
    const blurSelection = useRef(editor.selection);

    const [value, setValue] = useState(getSlateValue(items));

    useImperativeHandle(ref, () => ({
      setInnerValue(newValue: StringPropSegment[]) {
        setValue(getSlateValue(newValue));
      },
    }));

    const debounceOnUpdate = useMemo(
      () =>
        debounce(
          (newValue) => onUpdate(getDataItemsFromEditorState(newValue)),
          100,
        ),
      [onUpdate],
    );

    const onChange = useCallback(
      (newValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(value)) {
          setValue(newValue);
          debounceOnUpdate(newValue);
        }
      },
      [debounceOnUpdate, value],
    );

    const onAddDataVariable = useCallback(
      (data) => {
        if (blurSelection.current) {
          Transforms.select(editor, blurSelection.current);
        }
        insertDataVariableElement(editor, data);
      },
      [editor],
    );

    const Element = useMemo(
      () =>
        ({ attributes, children, element }: any) => {
          switch (element.type) {
            case VARIABLE: {
              return (
                <span {...attributes}>
                  <DataVariableBlock
                    checkFieldPermissions={checkFieldPermissions}
                    elementData={element.data}
                    dataOptions={dataOptions}
                    {...attributes}
                  />
                  {children}
                </span>
              );
            }
            default: {
              return children;
            }
          }
        },
      [checkFieldPermissions, dataOptions],
    );

    const handleSaveBlurSelection = useCallback(() => {
      blurSelection.current = editor.selection;
    }, [editor.selection]);

    return (
      <Popover
        p={0}
        disabled={disabled}
        placement="top-start"
        showArrow={false}
        surface={DARK}
        content={
          <DynamicValuePopoverBody
            options={dataOptions}
            onSelect={onAddDataVariable}
          />
        }
      >
        <span
          className={classNames('draft-editor relative block w-full', {
            'allow-multiple-lines': multiLine,
          })}
          ref={ref}
        >
          <Slate editor={editor} value={value} onChange={onChange}>
            <Editable
              readOnly={disabled}
              renderElement={(props) => <Element {...props} />}
              placeholder={placeholder}
              onBlur={handleSaveBlurSelection}
            />
          </Slate>
        </span>
      </Popover>
    );
  },
);

export default DraftEditor;
