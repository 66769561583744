import { COMBO } from '../../constants/elementPropTypeTypes';
import { Project } from '../Project';
import ElementPropType from './ElementPropType';

const identityResolver = (
  props: Record<any, any>,
  __1: Project,
  __3: Record<any, any>,
  __4: boolean,
): any => props;
const identityMapper = (props: any): any => props;

type Resolver = (
  props: Record<any, any>,
  project: Project,
  scope: Record<any, any>,
  editorMode: boolean,
) => any;

class ComboType extends ElementPropType {
  shape: Record<string, ElementPropType>;
  resolver: Resolver;
  propMapper: (props: any) => any;
  editorProps: Record<string, any>;

  constructor(
    shape = {},
    resolver = identityResolver,
    propMapper = identityMapper,
    display = () => true,
  ) {
    super(COMBO);
    this.shape = shape;
    this.resolver = resolver;
    this.propMapper = propMapper;
    this.display = display;
    this.editorProps = {};
  }

  resolve(props: any, project: Project, scope: any, editorMode: boolean) {
    return this.mapProps(this.resolver(props, project, scope, editorMode));
  }

  mapProps(resolvedProps: any) {
    return this.propMapper(resolvedProps);
  }

  setEditorProps(editorProps: any) {
    this.editorProps = editorProps;
    return this;
  }
}

export default ComboType;
