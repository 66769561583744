import {
  BOX_SHADOW,
  NO_BORDER,
  ROUNDED_FULL,
  ROUNDED_LARGE,
} from '../input/inputStyles';

export const getSelectStyles = (borderColor: any) => ({
  border: [true, borderColor],
  items: 'center',
  select: 'none',
  appearance: 'none',
  w: 'full',
  leading: 'tight',
  outline: 'none',

  // 'border-focus': 'none',
  'outline-focus': 'none',
});

export const listBoxStyles = {
  [ROUNDED_LARGE]: { rounded: 'lg' },
  [ROUNDED_FULL]: { rounded: 'lg' },
  [NO_BORDER]: { border: false },
  [BOX_SHADOW]: { border: false },
};
