import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrows-diagonal-minimize-2',
  'IconArrowsDiagonalMinimize2',
  [
    ['path', { d: 'M18 10h-4v-4', key: 'svg-0' }],
    ['path', { d: 'M20 4l-6 6', key: 'svg-1' }],
    ['path', { d: 'M6 14h4v4', key: 'svg-2' }],
    ['path', { d: 'M10 14l-6 6', key: 'svg-3' }],
  ],
);
