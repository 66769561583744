import React from 'react';
import classNames from 'classnames';
import flow from 'lodash/flow';
import get from 'lodash/fp/get';
import initial from 'lodash/initial';
import {
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from 'react-dnd';
import { moveDraggedToPathAtIndex } from '@noloco/ui/src/utils/dragAndDrop';

type SidebarItemProps = {};

const SidebarItem = ({
  // @ts-expect-error TS(2339): Property 'children' does not exist on type 'Sideba... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Sideb... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(2339): Property 'connectDropTarget' does not exist on typ... Remove this comment to see the full error message
  connectDropTarget,
  // @ts-expect-error TS(2339): Property 'connectDragPreview' does not exist on ty... Remove this comment to see the full error message
  connectDragPreview,
  // @ts-expect-error TS(2339): Property 'connectDragSource' does not exist on typ... Remove this comment to see the full error message
  connectDragSource,
  // @ts-expect-error TS(2339): Property 'isDragging' does not exist on type 'Side... Remove this comment to see the full error message
  isDragging,
  // @ts-expect-error TS(2339): Property 'isOver' does not exist on type 'SidebarI... Remove this comment to see the full error message
  isOver,
  // @ts-expect-error TS(2339): Property 'navId' does not exist on type 'Sideb... Remove this comment to see the full error message
  navId,
  // @ts-expect-error TS(2339): Property 'canDrop' does not exist on type 'Sidebar... Remove this comment to see the full error message
  canDrop,
}: SidebarItemProps) => {
  return connectDragSource(
    connectDropTarget(
      <div
        className={classNames('flex flex-col', className)}
        data-testid="sidebar-nav-item"
        data-nav-item-id={navId}
      >
        {isOver && canDrop && (
          <div className={classNames('mx-6 h-0.5 bg-white bg-opacity-50')} />
        )}
        {connectDragPreview(
          <div
            className={classNames('flex w-full flex-col justify-center', {
              'opacity-50': isDragging,
            })}
          >
            {children}
          </div>,
        )}
      </div>,
    ),
  );
};

const connectProps = ({ project, path, page, updateProject, navId }: any) => ({
  id: page.id,
  page,
  path,
  navId,
  project,
  updateProject,
});

const targetSpec = {
  drop: connectProps,
  canDrop: (item: any, monitor: any) => {
    const draggedItem = monitor.getItem();

    const itemParentPage = get('page.props.parentPage', item);
    const draggedParentPage = get('page.props.parentPage', draggedItem);
    if (draggedParentPage) {
      return draggedParentPage === itemParentPage;
    }

    return !itemParentPage && !draggedParentPage;
  },
};

const targetCollect = (
  dragConnect: DropTargetConnector,
  monitor: DropTargetMonitor,
) => ({
  connectDropTarget: dragConnect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const sourceSpec = {
  canDrag: ({ draggable = true }) => draggable,
  beginDrag: connectProps,
  endDrag: (props: any, monitor: any) => {
    const draggedItem = monitor.getItem();
    const droppedItem = monitor.getDropResult();

    if (!droppedItem || !draggedItem || droppedItem.id === draggedItem.id) {
      return;
    }

    const projectElements = props.project.elements;

    const draggedPage = get(draggedItem.path, projectElements);

    if (!draggedPage) {
      return;
    }

    const droppedSiblings =
      droppedItem.path.length === 1
        ? projectElements
        : // @ts-expect-error TS(2554): Expected 1-2 arguments, but got 3.
          get(initial(droppedItem.path), projectElements, []);

    return moveDraggedToPathAtIndex(
      droppedSiblings,
      droppedItem.path,
      draggedPage,
      draggedItem,
      projectElements,
    );
  },
};

const sourceCollect = (
  dragConnect: DragSourceConnector,
  monitor: DragSourceMonitor,
) => ({
  connectDragSource: dragConnect.dragSource(),
  connectDragPreview: dragConnect.dragPreview(),
  isDragging: monitor.isDragging(),
});

const DraggableSidebarItem = flow([
  DragSource('page', sourceSpec, sourceCollect),
  DropTarget('page', targetSpec, targetCollect),
])(SidebarItem);

export default DraggableSidebarItem;
