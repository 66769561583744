import React, { forwardRef } from 'react';
import { Icon as IconType } from '@tabler/icons-react';
import classNames from 'classnames';

type BuildModeIconProps = {
  disabled?: boolean;
  Icon: IconType;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const BuildModeIcon = forwardRef(
  (
    { disabled, Icon, onClick }: BuildModeIconProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => (
    <div
      className={classNames(
        'flex cursor-pointer items-center justify-center rounded-md p-1 text-slate-300 opacity-75 hover:bg-slate-700 hover:opacity-100',
        { 'cursor-not-allowed opacity-50': disabled },
      )}
      onClick={disabled ? undefined : onClick}
      ref={ref}
    >
      <Icon size={16} />
    </div>
  ),
);

export default BuildModeIcon;
