import createReactComponent from '../createReactComponent';
export default createReactComponent('devices-bolt', 'IconDevicesBolt', [
  [
    'path',
    { d: 'M13 19v-10a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3.5', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-2' }],
  ['path', { d: 'M16 9h2', key: 'svg-3' }],
]);
