import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-cakephp', 'IconBrandCakephp', [
  [
    'path',
    {
      d: 'M12 11l8 2c1.361 -.545 2 -1.248 2 -2v-3.8c0 -1.765 -4.479 -3.2 -10.002 -3.2c-5.522 0 -9.998 1.435 -9.998 3.2v2.8c0 1.766 4.478 4 10 4v-3z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 14v3l8 2c1.362 -.547 2 -1.246 2 -2v-3c0 .754 -.638 1.453 -2 2l-8 -2z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M2 17c0 1.766 4.476 3 9.998 3l.002 -3c-5.522 0 -10 -1.734 -10 -3.5v3.5z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M2 10v4', key: 'svg-3' }],
  ['path', { d: 'M22 10v4', key: 'svg-4' }],
]);
