import createReactComponent from '../createReactComponent';
export default createReactComponent('microwave-off', 'IconMicrowaveOff', [
  [
    'path',
    {
      d: 'M18 18h-14a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h2m4 0h10a1 1 0 0 1 1 1v10',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 6v5m0 4v3', key: 'svg-1' }],
  ['path', { d: 'M18 12h.01', key: 'svg-2' }],
  ['path', { d: 'M18 9h.01', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M6.5 10.5c1 -.667 1.5 -.667 2.5 0c.636 .265 1.272 .665 1.907 .428',
      key: 'svg-4',
    },
  ],
  [
    'path',
    {
      d: 'M6.5 13.5c1 -.667 1.5 -.667 2.5 0c.833 .347 1.667 .926 2.5 0',
      key: 'svg-5',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
