import createReactComponent from '../createReactComponent';
export default createReactComponent('currency-cent', 'IconCurrencyCent', [
  [
    'path',
    {
      d: 'M16.007 7.54a5.965 5.965 0 0 0 -4.008 -1.54a6 6 0 0 0 -5.992 6c0 3.314 2.682 6 5.992 6a5.965 5.965 0 0 0 4 -1.536',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 20v-2', key: 'svg-1' }],
  ['path', { d: 'M12 6v-2', key: 'svg-2' }],
]);
