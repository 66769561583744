import createReactComponent from '../createReactComponent';
export default createReactComponent('map-pin-code', 'IconMapPinCode', [
  ['path', { d: 'M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M11.85 21.48a1.992 1.992 0 0 1 -1.263 -.58l-4.244 -4.243a8 8 0 1 1 13.385 -3.585',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-2' }],
  ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-3' }],
]);
