import createReactComponent from '../createReactComponent';
export default createReactComponent('box-multiple-0', 'IconBoxMultiple0', [
  [
    'path',
    {
      d: 'M14 6a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M7 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2',
      key: 'svg-2',
    },
  ],
]);
