import createReactComponent from '../createReactComponent';
export default createReactComponent('pointer-question', 'IconPointerQuestion', [
  [
    'path',
    {
      d: 'M15.062 12.506l-.284 -.284l3.113 -2.09a1.2 1.2 0 0 0 -.309 -2.228l-13.582 -3.904l3.904 13.563a1.2 1.2 0 0 0 2.228 .308l2.09 -3.093l1.278 1.278',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 22v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-2',
    },
  ],
]);
