import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circuit-capacitor-polarized',
  'IconCircuitCapacitorPolarized',
  [
    ['path', { d: 'M22 12h-8', key: 'svg-0' }],
    ['path', { d: 'M2 12h8', key: 'svg-1' }],
    ['path', { d: 'M10 7v10', key: 'svg-2' }],
    ['path', { d: 'M14 7v10', key: 'svg-3' }],
    ['path', { d: 'M17 5h4', key: 'svg-4' }],
    ['path', { d: 'M19 3v4', key: 'svg-5' }],
  ],
);
