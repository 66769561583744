import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-airpods-case',
  'IconDeviceAirpodsCase',
  [
    ['path', { d: 'M21 10h-18', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M3 4m0 4a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4z',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M7 10v1.5a1.5 1.5 0 0 0 1.5 1.5h7a1.5 1.5 0 0 0 1.5 -1.5v-1.5',
        key: 'svg-2',
      },
    ],
  ],
);
