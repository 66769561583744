import createReactComponent from '../createReactComponent';
export default createReactComponent('om', 'IconOm', [
  [
    'path',
    {
      d: 'M7 12c2.21 0 4 -1.567 4 -3.5s-1.79 -3.5 -4 -3.5c-1.594 0 -2.97 .816 -3.613 2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M3.423 14.483a4.944 4.944 0 0 0 -.423 2.017c0 2.485 1.79 4.5 4 4.5s4 -2.015 4 -4.5s-1.79 -4.5 -4 -4.5',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M14.071 17.01c.327 2.277 1.739 3.99 3.429 3.99c1.933 0 3.5 -2.239 3.5 -5s-1.567 -5 -3.5 -5c-.96 0 -1.868 .606 -2.5 1.5c-.717 1.049 -1.76 1.7 -2.936 1.7c-.92 0 -1.766 -.406 -2.434 -1.087',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M17 3l2 2', key: 'svg-3' }],
  ['path', { d: 'M12 3c1.667 3.667 4.667 5.333 9 5', key: 'svg-4' }],
]);
