import createReactComponent from '../createReactComponent';
export default createReactComponent('quote', 'IconQuote', [
  [
    'path',
    {
      d: 'M10 11h-4a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h3a1 1 0 0 1 1 1v6c0 2.667 -1.333 4.333 -4 5',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M19 11h-4a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h3a1 1 0 0 1 1 1v6c0 2.667 -1.333 4.333 -4 5',
      key: 'svg-1',
    },
  ],
]);
