import createReactComponent from '../createReactComponent';
export default createReactComponent('ankh', 'IconAnkh', [
  ['path', { d: 'M6 13h12', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 21v-8l-.422 -.211a6.472 6.472 0 0 1 -3.578 -5.789a4 4 0 1 1 8 0a6.472 6.472 0 0 1 -3.578 5.789l-.422 .211',
      key: 'svg-1',
    },
  ],
]);
