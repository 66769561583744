import createReactComponent from '../createReactComponent';
export default createReactComponent('message-share', 'IconMessageShare', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M13 18l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M16 22l5 -5', key: 'svg-3' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-4' }],
]);
