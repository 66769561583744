import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'message-circle-share',
  'IconMessageCircleShare',
  [
    [
      'path',
      {
        d: 'M12.58 19.963a9.906 9.906 0 0 1 -4.88 -.963l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c2.13 1.817 3.055 4.368 2.692 6.82',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M16 22l5 -5', key: 'svg-1' }],
    ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-2' }],
  ],
);
