import createReactComponent from '../createReactComponent';
export default createReactComponent('microwave', 'IconMicrowave', [
  [
    'path',
    {
      d: 'M3 6m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 6v12', key: 'svg-1' }],
  ['path', { d: 'M18 12h.01', key: 'svg-2' }],
  ['path', { d: 'M18 15h.01', key: 'svg-3' }],
  ['path', { d: 'M18 9h.01', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M6.5 10.5c1 -.667 1.5 -.667 2.5 0c.833 .347 1.667 .926 2.5 0',
      key: 'svg-5',
    },
  ],
  [
    'path',
    {
      d: 'M6.5 13.5c1 -.667 1.5 -.667 2.5 0c.833 .347 1.667 .926 2.5 0',
      key: 'svg-6',
    },
  ],
]);
