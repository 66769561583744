import createReactComponent from '../createReactComponent';
export default createReactComponent('theater', 'IconTheater', [
  ['path', { d: 'M4 20h16', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M20 16v-10a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v10l4 -6c2.667 1.333 5.333 1.333 8 0l4 6z',
      key: 'svg-1',
    },
  ],
]);
