import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IS_WEBSITE_EXAMPLES } from '../../constants/env';
import {
  projectNameSelector,
  projectTitleSelector,
} from '../../selectors/projectSelectors';

const ENABLED = !IS_WEBSITE_EXAMPLES;

const useSetDocumentTitle = (nextTitle: any) => {
  const projectTitle = useSelector(projectTitleSelector);
  const projectName = useSelector(projectNameSelector);

  useEffect(() => {
    const basetitle = `${projectTitle || projectName}`;
    if (ENABLED && document.title !== nextTitle) {
      document.title = nextTitle ? `${nextTitle} | ${basetitle}` : basetitle;
    }
  }, [nextTitle, projectName, projectTitle]);
};

export default useSetDocumentTitle;
