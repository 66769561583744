import createReactComponent from '../createReactComponent';
export default createReactComponent('currency-hryvnia', 'IconCurrencyHryvnia', [
  [
    'path',
    {
      d: 'M8 7a2.64 2.64 0 0 1 2.562 -2h3.376a2.64 2.64 0 0 1 2.562 2a2.57 2.57 0 0 1 -1.344 2.922l-5.876 2.938a3.338 3.338 0 0 0 -1.78 3.64a3.11 3.11 0 0 0 3.05 2.5h2.888a2.64 2.64 0 0 0 2.562 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 10h12', key: 'svg-1' }],
  ['path', { d: 'M6 14h12', key: 'svg-2' }],
]);
