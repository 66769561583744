import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'air-conditioning-disabled',
  'IconAirConditioningDisabled',
  [
    [
      'path',
      {
        d: 'M3 8m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 16v-3a1 1 0 0 1 1 -1h8a1 1 0 0 1 1 1v3', key: 'svg-1' }],
  ],
);
