import { forwardRef, useCallback, useMemo } from 'react';
import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import get from 'lodash/get';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import {
  BOARD,
  CALENDAR,
  COLUMNS,
  GANTT,
  MAP,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '../../../constants/collectionLayouts';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import { UPDATE } from '../../../constants/workflowTriggerTypes';
import { DataField } from '../../../models/DataTypeFields';
import { BaseRecord } from '../../../models/Record';
import { FormConfigWithField } from '../../../models/View';
import {
  getTextFromError,
  useErrorAlert,
} from '../../../utils/hooks/useAlerts';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import { AutoFormProvider } from '../../../utils/hooks/useAutoForm';
import { useBuildDataItemRecordScope } from '../../../utils/hooks/useBuildDataItemRecordScope';
import { useFieldVisibilityConditions } from '../../../utils/hooks/useFieldVisibilityConditions';
import useIsTable from '../../../utils/hooks/useIsTable';
import useMergedScope from '../../../utils/hooks/useMergedScope';
import useRecordRowLink from '../../../utils/hooks/useRecordRowLink';
import { getText } from '../../../utils/lang';
import { transformColumnarScope } from '../../../utils/scope';
import { DEBOUNCED_TYPES } from '../../../utils/useFormFieldsState';
import { CARDS } from '../forms/AutoFormSection';
import CollectionRecordActionButtons from '../view/CollectionRecordActionButtons';
import { getFormFieldConfig } from './FieldCell';
import CardRecordLayout from './layouts/CardRecordLayout';
import RowRecordLayout from './layouts/RowRecordLayout';
import TableRecordLayout from './layouts/TableRecordLayout';

export const variables = {
  title: { hidden: true },
  secondaryText: { hidden: true },
  image: { hidden: true },
  description: { hidden: true },
};

const shouldSubmitOnBlur = (field: DataField) =>
  !DEBOUNCED_TYPES.includes(field.type);

const COLLECTION_RECORD_LAYOUTS = {
  [TABLE]: TableRecordLayout,
  [TABLE_FULL]: TableRecordLayout,
  [ROWS]: RowRecordLayout,
  [CARDS]: CardRecordLayout,
  [COLUMNS]: CardRecordLayout,
  [BOARD]: CardRecordLayout,
  [SPLIT]: RowRecordLayout,
  [CALENDAR]: CardRecordLayout,
  [TIMELINE]: CardRecordLayout,
  [GANTT]: CardRecordLayout,
  [MAP]: CardRecordLayout,
};

const CollectionRecord = forwardRef(
  (
    {
      actionButtons,
      dataList,
      dataType,
      children,
      className,
      editRelatedRecordButtons,
      editorMode,
      element,
      elementPath,
      fieldConfigs,
      formatRecordScope,
      index,
      maxStickyColumnIndex,
      layout,
      project,
      record,
      recordQueryString,
      refetch,
      rootDataType,
      rowLink,
      showCardHeroImage,
      scope,
      transformRecordScope,
      viewRootPathname,
      'data-index': dataIndex,
      'data-group-key': dataGroupKey,
      draggable,
      columnWidths,
      surface,
      selectedRows,
      setSelectedRows,
    }: any,
    ref,
  ) => {
    const errorAlert = useErrorAlert();
    const RecordLayout =
      COLLECTION_RECORD_LAYOUTS[layout] || COLLECTION_RECORD_LAYOUTS[ROWS];

    const isTable = useIsTable(layout);

    const recordRowLink = useRecordRowLink(
      rowLink,
      project,
      layout,
      viewRootPathname,
      get(record, 'uuid'),
      recordQueryString,
    );

    const onUpdateError = useCallback(
      (error) => {
        errorAlert(
          getText('errors.data.message'),
          getTextFromError(error).message,
        );
      },
      [errorAlert],
    );

    const recordWithColumnarData = useMemo(
      () => transformColumnarScope(record, dataType, project.dataTypes),
      [dataType, project.dataTypes, record],
    );

    const buildRecordScope = useBuildDataItemRecordScope(
      (formRecord: any) => formRecord,
      transformRecordScope,
      dataType.name,
    );

    const recordScope = useMemo(
      () => buildRecordScope(recordWithColumnarData),
      [buildRecordScope, recordWithColumnarData],
    );

    const mergedRecordScope = useMergedScope(recordScope);
    const mergedScope = useMemo(
      () => ({
        ...scope,
        ...mergedRecordScope,
      }),
      [mergedRecordScope, scope],
    );

    const resolvedFieldsWithConditionsMet = useFieldVisibilityConditions(
      fieldConfigs,
      project,
      recordScope,
      mergedScope,
    );

    const { user } = useAuthWrapper();
    const fieldPermissionsEnabled = useIsFeatureEnabled(
      FIELD_LEVEL_PERMISSIONS,
    );

    const resolvedFieldConfigs: FormConfigWithField[] = useMemo(
      () => (isTable ? fieldConfigs : resolvedFieldsWithConditionsMet),
      [isTable, fieldConfigs, resolvedFieldsWithConditionsMet],
    );

    const formFieldConfigs = useMemo(
      () =>
        resolvedFieldConfigs
          .filter(({ config }) => config.editInline)
          .map(({ field, config }) => ({
            field,
            config: {
              ...getFormFieldConfig(
                field,
                project,
                user,
                fieldPermissionsEnabled,
                config,
              ),
              label: undefined,
            },
          })),
      [fieldPermissionsEnabled, project, resolvedFieldConfigs, user],
    );

    const recordId = get(record, 'id');
    const bulkActionsEnabled = get(element, 'props.bulkActionsEnabled', false);
    const handleCheckboxChange = useCallback(
      ({ target: { checked } }) =>
        setSelectedRows((rows: BaseRecord[]) =>
          checked
            ? [...rows, record]
            : rows.filter((row) => row.id !== record.id),
        ),
      [setSelectedRows, record],
    );

    const isRowChecked = useMemo(
      () => selectedRows.find((row: BaseRecord) => row.id === recordId),
      [selectedRows, recordId],
    );

    const recordLayout = (
      <RecordLayout
        actionButtons={
          actionButtons && (
            <CollectionRecordActionButtons
              actionButtons={actionButtons}
              collectionId={element.id}
              dataList={dataList}
              dataType={dataType}
              editRelatedRecordButtons={editRelatedRecordButtons}
              editorMode={editorMode}
              elementPath={elementPath}
              formatRecordScope={formatRecordScope}
              index={index}
              record={recordWithColumnarData}
              refetch={refetch}
              rootDataType={rootDataType}
              layout={layout}
              parentScope={scope}
              project={project}
              bulkActionsEnabled={bulkActionsEnabled}
              elementId={element.id}
              isRowChecked={isRowChecked}
              handleCheckboxChange={handleCheckboxChange}
            />
          )
        }
        className={classNames(`record-${get(record, 'uuid')}`, className)}
        key={recordId}
        dataType={dataType}
        elementId={element.id}
        element={element}
        fieldConfigs={resolvedFieldConfigs}
        layout={layout}
        maxStickyColumnIndex={maxStickyColumnIndex}
        record={recordWithColumnarData}
        scope={mergedScope}
        project={project}
        rowLink={recordRowLink}
        showCardHeroImage={showCardHeroImage}
        transformRecordScope={transformRecordScope}
        data-index={dataIndex}
        data-group-key={dataGroupKey}
        draggable={draggable}
        columnWidths={columnWidths}
        bulkActionsEnabled={bulkActionsEnabled}
        isRowChecked={isRowChecked}
        handleCheckboxChange={handleCheckboxChange}
        selectedRows={selectedRows}
        ref={ref}
      >
        {children}
      </RecordLayout>
    );

    return (
      // @ts-expect-error TS(2769): No overload matches this call.
      <ErrorBoundary>
        {formFieldConfigs.length > 0 ? (
          <AutoFormProvider
            innerClassName="max-w-full w-full"
            dataType={dataType}
            value={recordWithColumnarData}
            inviteEnabled={false}
            fields={formFieldConfigs}
            inline={isTable}
            onError={onUpdateError}
            submitOnBlur={shouldSubmitOnBlur}
            mutationType={UPDATE}
            surface={surface}
            bulkActionsEnabled={bulkActionsEnabled}
            isRowChecked={isRowChecked}
            selectedRows={selectedRows}
            transformRecordScope={transformRecordScope}
          >
            {recordLayout}
          </AutoFormProvider>
        ) : (
          recordLayout
        )}
      </ErrorBoundary>
    );
  },
);

export default CollectionRecord;
