import React, { forwardRef } from 'react';
import {
  IconArrowNarrowRight,
  IconGripVertical,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { DataField as DataFieldDefinition } from '@noloco/core/src/models/DataTypeFields';
import DataTypes from '@noloco/core/src/models/DataTypes';
import { IS_PLAYGROUND } from '../../../constants/env';
import DataFieldIcon from '../../DataFieldIcon';
import DataFieldType from './DataFieldType';

type Props = {
  className?: string;
  dataTypeName?: string;
  dataTypes: DataTypes;
  draggable?: boolean;
  dragRef?: React.ForwardedRef<HTMLDivElement>;
  field: DataFieldDefinition;
  row?: boolean;
  onClick?: (...args: any[]) => any;
  onDelete?: (...args: any[]) => any;
  showType?: boolean;
};

const DataField = forwardRef<any, Props>(
  (
    {
      className,
      children,
      draggable,
      dragRef,
      field,
      dataTypeName,
      dataTypes,
      row,
      onClick,
      onDelete,
      showType,
    },
    ref,
  ) => (
    <div
      className={classNames('group flex items-center text-sm', className)}
      onClick={onClick}
      ref={ref}
    >
      <div className="mr-3 flex w-4 flex-shrink-0 text-gray-300" ref={dragRef}>
        <DataFieldIcon
          field={field}
          className={classNames({ 'group-hover:hidden': draggable })}
        />
        <IconGripVertical
          className={classNames('hidden', { 'group-hover:block': draggable })}
        />
      </div>
      <div
        className={classNames(
          'flex',
          row ? 'w-full items-center truncate' : 'flex-col',
        )}
      >
        {dataTypeName && (
          <div className="flex">
            <span className="my-auto">{dataTypeName}</span>
            <IconArrowNarrowRight className="mx-2 w-4 flex-shrink-0 text-gray-300" />
          </div>
        )}
        <span>
          {field.display}
          {field.required && '*'}
        </span>
        {showType && (
          <DataFieldType
            className={classNames('text-gray-500', { 'ml-2': row })}
            dataTypes={dataTypes}
            field={field}
          />
        )}
        {onDelete && row && !(field as any).relatedField && !IS_PLAYGROUND && (
          <button
            className="ml-auto hidden text-gray-500 hover:text-white group-hover:block"
            onClick={() => onDelete(field)}
          >
            <IconTrash size={16} />
          </button>
        )}
        {children}
      </div>
    </div>
  ),
);

DataField.defaultProps = {
  row: true,
  showType: true,
};

export default DataField;
