import { useMemo } from 'react';
import first from 'lodash/first';
import kebabCase from 'lodash/kebabCase';
import { ViewTab } from '../../models/Element';
import useRouter from './useRouter';

const useSelectedTab = (
  tabs: ViewTab[],
  visibleTabs: ViewTab[],
): ViewTab | null | undefined => {
  const {
    query: { tab },
  } = useRouter();

  const selectedTab = useMemo(() => {
    if (tabs && tabs.length > 1) {
      const defaultTab = first(tabs);
      const firstTab = first(visibleTabs);
      if (tab) {
        const foundTab = visibleTabs.find(
          (t: any) => kebabCase(t.title) === tab,
        );

        // Return null if we are on the default tab as it makes adding to and from that tab easier
        if (foundTab) {
          if (foundTab !== defaultTab) {
            return foundTab;
          }
        } else {
          return firstTab;
        }
      } else if (defaultTab !== firstTab) {
        // Default tab is hidden so we should return the first visible tab
        return firstTab;
      }
    }

    return null;
  }, [tabs, tab, visibleTabs]);

  return selectedTab;
};

export default useSelectedTab;
