export const SUPPORTED_PROTOCOLS = [
  'http://',
  'https://',
  'mailto:',
  'tel:',
  'sms:', // Opens the default SMS client to send a text message.
  'geo:', // Opens a map application at a specific location, common in mobile apps.
  'facetime:', // Initiates a FaceTime call (iOS only).
  'skype:', // Opens Skype to start a call or chat with a user.
  'whatsapp:', // Opens WhatsApp to start a conversation with a contact.
  'slack:', // Links to Slack channels or user messages.
  'zoommtg:', // Opens Zoom for joining a specific meeting.
  'tg:', // Opens Telegram to start a chat with a user or group.
  'viber:', // Opens Viber for calling or messaging.
  'fb-messenger://', // Opens Facebook Messenger for sending messages.
  'callto:', // Alternative to 'tel:', initiates a call.
  'market://', // Opens app stores, mainly used for linking to Android’s Play Store.
  '#',
  '/',
];

export const COMMUNITY_URL = 'https://community.noloco.io';

export const WEBINARS_URL = 'https://noloco.io/webinars';

export const GUIDES_URL = 'https://guides.noloco.io';

export const YOUTUBE_CHANNEL_URL =
  'https://www.youtube.com/channel/UCHKb83uA6q6_otjSN2cpO8w';

export const ACADEMY_URL = 'https://academy.noloco.io/register';

export const EXPERT_URL = 'https://noloco.io/expert';
