import React from 'react';

export const updateScope = (
  children: React.ReactElement,
  scope: Record<any, any>,
  otherProps: Record<any, any> = {},
) =>
  React.Children.map(children, (child) =>
    React.cloneElement(child, {
      ...otherProps,
      scope,
    }),
  );
