import createReactComponent from '../createReactComponent';
export default createReactComponent('wave-sine', 'IconWaveSine', [
  [
    'path',
    {
      d: 'M21 12h-2c-.894 0 -1.662 -.857 -1.761 -2c-.296 -3.45 -.749 -6 -2.749 -6s-2.5 3.582 -2.5 8s-.5 8 -2.5 8s-2.452 -2.547 -2.749 -6c-.1 -1.147 -.867 -2 -1.763 -2h-2',
      key: 'svg-0',
    },
  ],
]);
