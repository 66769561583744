import createReactComponent from '../createReactComponent';
export default createReactComponent('template', 'IconTemplate', [
  [
    'path',
    {
      d: 'M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M14 12l6 0', key: 'svg-2' }],
  ['path', { d: 'M14 16l6 0', key: 'svg-3' }],
  ['path', { d: 'M14 20l6 0', key: 'svg-4' }],
]);
