import createReactComponent from '../createReactComponent';
export default createReactComponent('swimming', 'IconSwimming', [
  ['path', { d: 'M16 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M6 11l4 -2l3.5 3l-1.5 2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M3 16.75a2.4 2.4 0 0 0 1 .25a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 1 -.25',
      key: 'svg-2',
    },
  ],
]);
