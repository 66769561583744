import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-down-left-circle',
  'IconArrowDownLeftCircle',
  [
    ['path', { d: 'M15.536 8.464l-9.536 9.536', key: 'svg-0' }],
    ['path', { d: 'M6 14v4h4', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M15.586 8.414a2 2 0 1 0 2.828 -2.828a2 2 0 0 0 -2.828 2.828',
        key: 'svg-2',
      },
    ],
  ],
);
