import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-campaignmonitor',
  'IconBrandCampaignmonitor',
  [
    [
      'path',
      { d: 'M3 18l9 -6.462l-9 -5.538v12h18v-12l-9 5.538', key: 'svg-0' },
    ],
  ],
);
