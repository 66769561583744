import createReactComponent from '../createReactComponent';
export default createReactComponent('barrel', 'IconBarrel', [
  [
    'path',
    {
      d: 'M7.278 4h9.444a2 2 0 0 1 1.841 1.22c.958 2.26 1.437 4.52 1.437 6.78c0 2.26 -.479 4.52 -1.437 6.78a2 2 0 0 1 -1.841 1.22h-9.444a2 2 0 0 1 -1.841 -1.22c-.958 -2.26 -1.437 -4.52 -1.437 -6.78c0 -2.26 .479 -4.52 1.437 -6.78a2 2 0 0 1 1.841 -1.22z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M14 4c.667 2.667 1 5.333 1 8s-.333 5.333 -1 8', key: 'svg-1' },
  ],
  [
    'path',
    { d: 'M10 4c-.667 2.667 -1 5.333 -1 8s.333 5.333 1 8', key: 'svg-2' },
  ],
  ['path', { d: 'M4.5 16h15', key: 'svg-3' }],
  ['path', { d: 'M19.5 8h-15', key: 'svg-4' }],
]);
