import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-c-sharp', 'IconBrandCSharp', [
  [
    'path',
    {
      d: 'M10 9a3 3 0 0 0 -3 -3h-.5a3.5 3.5 0 0 0 -3.5 3.5v5a3.5 3.5 0 0 0 3.5 3.5h.5a3 3 0 0 0 3 -3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 7l-1 10', key: 'svg-1' }],
  ['path', { d: 'M20 7l-1 10', key: 'svg-2' }],
  ['path', { d: 'M14 10h7.5', key: 'svg-3' }],
  ['path', { d: 'M21 14h-7.5', key: 'svg-4' }],
]);
