import createReactComponent from '../createReactComponent';
export default createReactComponent('pencil-plus', 'IconPencilPlus', [
  [
    'path',
    {
      d: 'M8 20l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13.5 6.5l4 4', key: 'svg-1' }],
  ['path', { d: 'M16 18h4m-2 -2v4', key: 'svg-2' }],
]);
