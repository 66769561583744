import createReactComponent from '../createReactComponent';
export default createReactComponent('microscope', 'IconMicroscope', [
  ['path', { d: 'M5 21h14', key: 'svg-0' }],
  ['path', { d: 'M6 18h2', key: 'svg-1' }],
  ['path', { d: 'M7 18v3', key: 'svg-2' }],
  ['path', { d: 'M9 11l3 3l6 -6l-3 -3z', key: 'svg-3' }],
  ['path', { d: 'M10.5 12.5l-1.5 1.5', key: 'svg-4' }],
  ['path', { d: 'M17 3l3 3', key: 'svg-5' }],
  ['path', { d: 'M12 21a6 6 0 0 0 3.715 -10.712', key: 'svg-6' }],
]);
