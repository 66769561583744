import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-bumble', 'IconBrandBumble', [
  ['path', { d: 'M7 12h10', key: 'svg-0' }],
  ['path', { d: 'M9 8h6', key: 'svg-1' }],
  ['path', { d: 'M10 16h4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M16.268 3h-8.536a1.46 1.46 0 0 0 -1.268 .748l-4.268 7.509a1.507 1.507 0 0 0 0 1.486l4.268 7.509c.26 .462 .744 .747 1.268 .748h8.536a1.46 1.46 0 0 0 1.268 -.748l4.268 -7.509a1.507 1.507 0 0 0 0 -1.486l-4.268 -7.509a1.46 1.46 0 0 0 -1.268 -.748z',
      key: 'svg-3',
    },
  ],
]);
