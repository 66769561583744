import { useCallback, useMemo, useState } from 'react';
import { IconSettings } from '@tabler/icons-react';
import { get, upperFirst } from 'lodash';
import BuildModeEditorTabs from '@noloco/core/src/components/buildMode/BuildModeEditorTabs';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import { FIELDS, VISIBILITY } from '@noloco/core/src/constants/buildMode';
import { EXPORT_BUTTON } from '@noloco/core/src/constants/elements';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import {
  Element,
  ElementPath,
  VisibilityRules,
} from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { FormFieldConfig } from '@noloco/core/src/models/View';
import useEditorTabs from '@noloco/core/src/utils/hooks/useEditorTabs';
import { getText } from '@noloco/core/src/utils/lang';
import Guide from '../../Guide';
import RightPopoutMenu from '../../canvas/RightPopoutMenu';
import StringPropEditor from '../../canvas/StringPropEditor';
import VisibilityRulesEditor from '../VisibilityRulesEditor';
import FieldsListEditor, { UpdateFieldsCallback } from './FieldsListEditor';

const LANG_KEY = 'elements.VIEW.buttons.exportButton';
const IS_FIELD_SWITCH_DISABLED = () => true;

type ExportButtonEditorProps = {
  dataType: DataType;
  updateProperty: (path: ElementPath, value: any) => void;
  debouncedUpdateProperty: (path: ElementPath, value: any) => void;
  project: Project;
  elementPath: ElementPath;
  element: Element;
  exportButtonText: any;
  showExportButton: boolean;
  fields: DataField[];
  rootDataType: DataType;
};

const ExportButtonEditor = ({
  dataType,
  updateProperty,
  debouncedUpdateProperty,
  project,
  elementPath,
  element,
  exportButtonText,
  showExportButton,
  fields,
  rootDataType,
}: ExportButtonEditorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { exportButton } = element.props || {};
  const [editorTab, setEditorTab] = useEditorTabs('exportButton', FIELDS);

  const handleExportButtonClick = useCallback(
    () => (!showExportButton ? () => setIsOpen(true) : null),
    [showExportButton],
  );

  const isFieldVisible = useMemo(() => {
    return (field: DataField) => fields.find((f) => f.name === field.name);
  }, [fields]);

  const onFieldsChange = useCallback(
    (path, value) => updateProperty(['fields', ...path], value),
    [updateProperty],
  );

  const onToggleEnabled = useCallback(
    (nextEnabled) => {
      if (nextEnabled) {
        updateProperty(['exportButton'], {
          show: true,
          text: exportButtonText,
        });
      } else {
        updateProperty(['exportButton', 'show'], nextEnabled);
      }
    },
    [exportButtonText, updateProperty],
  );

  const onVisibilityRulesChange = useCallback(
    (path: ElementPath, value: VisibilityRules) =>
      updateProperty(['exportButton', 'visibilityRules', ...path], value),
    [updateProperty],
  );

  return (
    <>
      <BuildModeSwitchSection
        button={
          showExportButton && (
            <IconSettings
              className="cursor-pointer text-slate-500 hover:text-slate-400"
              onClick={() => setIsOpen(true)}
              size={16}
            />
          )
        }
        guide={
          <Guide
            hideIcon={true}
            href="https://guides.noloco.io/data-management/export-data"
          >
            {getText(LANG_KEY, 'guide')}
          </Guide>
        }
        label={getText(LANG_KEY, 'show')}
        onChange={onToggleEnabled}
        onClick={handleExportButtonClick}
        showOnlyButton={true}
        value={showExportButton || false}
      />
      {isOpen && showExportButton && (
        <RightPopoutMenu
          onClose={() => setIsOpen(false)}
          rootSelector=".noloco-project"
          source="buildMode"
          title={getText(LANG_KEY, 'show')}
          usePortal={true}
        >
          <div className="flex w-screen max-w-full flex-col">
            <BuildModeEditorTabs
              editorTab={editorTab}
              elementType={EXPORT_BUTTON}
              isNavEnabled={false}
              setEditorTab={setEditorTab}
            />
            {editorTab === FIELDS && (
              <>
                <div className="flex flex-col space-y-2 p-2">
                  <BuildModeInput label={getText(LANG_KEY, 'buttonText')}>
                    <StringPropEditor
                      // @ts-expect-error TS(2322): Type '{ contained: boolean; project: any; onChange... Remove this comment to see the full error message
                      contained={true}
                      project={project}
                      onChange={(value: any) =>
                        debouncedUpdateProperty(['exportButton', 'text'], value)
                      }
                      elementPath={elementPath}
                      placeholder=""
                      value={exportButtonText}
                    />
                  </BuildModeInput>
                </div>
                <FieldsListEditor
                  dataType={dataType}
                  dataTypes={project.dataTypes}
                  fields={dataType.fields}
                  getNewFieldConfig={(field: DataField) => ({
                    label: upperFirst(field.display),
                    hidden: false,
                  })}
                  onFieldsChange={onFieldsChange}
                  permissionType="create"
                  value={fields}
                  isItemDisabled={(field: DataField) =>
                    !(field.relationship || field.relatedField) ||
                    !isFieldVisible(field)
                  }
                  disableSwitch={IS_FIELD_SWITCH_DISABLED}
                >
                  {({
                    config,
                    field,
                    index,
                    updateFields,
                  }: {
                    config: FormFieldConfig;
                    field: DataField;
                    index: number;
                    updateFields: UpdateFieldsCallback;
                  }) => (
                    <div className="flex flex-col space-y-2">
                      {(field.relationship || field.relatedField) && (
                        <BuildModeSwitchSection
                          label={getText(LANG_KEY, 'exportIdValue')}
                          onChange={() =>
                            updateFields(
                              [index, 'exportIdValue'],
                              !get(config, 'exportIdValue', true),
                            )
                          }
                          showOnlyButton={true}
                          value={get(config, 'exportIdValue', true)}
                        />
                      )}
                    </div>
                  )}
                </FieldsListEditor>
              </>
            )}
            {editorTab === VISIBILITY && (
              <VisibilityRulesEditor
                dataType={rootDataType}
                element={exportButton}
                elementPath={elementPath}
                onChange={onVisibilityRulesChange}
                project={project}
              />
            )}
          </div>
        </RightPopoutMenu>
      )}
    </>
  );
};

export default ExportButtonEditor;
