import createReactComponent from '../createReactComponent';
export default createReactComponent('gas-station-off', 'IconGasStationOff', [
  ['path', { d: 'M15 11a2 2 0 0 1 2 2m3 3v-7l-3 -3', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M4 20v-14c0 -.548 .22 -1.044 .577 -1.405m3.423 -.595h4a2 2 0 0 1 2 2v4m0 4v6',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 20h12', key: 'svg-2' }],
  ['path', { d: 'M18 7v1a1 1 0 0 0 1 1h1', key: 'svg-3' }],
  ['path', { d: 'M4 11h7', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
