import createReactComponent from '../createReactComponent';
export default createReactComponent('text-orientation', 'IconTextOrientation', [
  [
    'path',
    {
      d: 'M9 15l-5 -5c-1.367 -1.367 -1.367 -3.633 0 -5s3.633 -1.367 5 0l5 5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5.5 11.5l5 -5', key: 'svg-1' }],
  ['path', { d: 'M21 12l-9 9', key: 'svg-2' }],
  ['path', { d: 'M21 12v4', key: 'svg-3' }],
  ['path', { d: 'M21 12h-4', key: 'svg-4' }],
]);
