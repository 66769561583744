import createReactComponent from '../createReactComponent';
export default createReactComponent('rollercoaster', 'IconRollercoaster', [
  [
    'path',
    {
      d: 'M3 21a5.55 5.55 0 0 0 5.265 -3.795l.735 -2.205a8.775 8.775 0 0 1 8.325 -6h3.675',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M20 9v12', key: 'svg-1' }],
  ['path', { d: 'M8 21v-3', key: 'svg-2' }],
  ['path', { d: 'M12 21v-10', key: 'svg-3' }],
  ['path', { d: 'M16 9.5v11.5', key: 'svg-4' }],
  ['path', { d: 'M15 3h5v3h-5z', key: 'svg-5' }],
  ['path', { d: 'M6 8l4 -3l2 2.5l-4 3l-1.8 -.5z', key: 'svg-6' }],
]);
