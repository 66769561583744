import React, { memo, useMemo } from 'react';
import { IconLayout } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { Popover } from '@noloco/components';
import CircularProgress from '@noloco/core/src/components/CircularProgress';
import { EXPERT } from '@noloco/core/src/constants/accountPlans';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import useGetFeatureFlagValue, {
  ONBOARDING_MANAGER_FLAG,
} from '@noloco/core/src/utils/hooks/useGetFeatureFlagValue';
import { getText } from '@noloco/core/src/utils/lang';
import { formatBg } from '@noloco/core/src/utils/styles';
import { APP_DASHBOARD_URL } from '../constants/env';
import { GILL } from '../constants/onboardingManagers';
import onboardingManagersConfig from '../constants/onboardingManagersConfig';
import useIsPartnerTrialing from '../utils/hooks/useIsPartnerTrialing';
import useIsTrialing from '../utils/hooks/useIsTrialing';

// @ts-expect-error TS(2769): No overload matches this call.
const TrialProgressTooltip = memo(({ startDate }) => {
  const activeOnboardingManager = useGetFeatureFlagValue(
    ONBOARDING_MANAGER_FLAG,
    GILL,
  );
  const onboardingManager = onboardingManagersConfig[activeOnboardingManager];

  const project = useSelector(projectDataSelector);
  const billingPlan = useSelector(billingPlanSelector);
  const isTrialing = useIsTrialing();
  const isPartnerTrialing = useIsPartnerTrialing();

  const isExpertPlan = billingPlan.type === EXPERT;

  const daysRemaining = useMemo(
    () =>
      billingPlan &&
      Math.max(
        Math.floor(
          DateTime.fromISO(
            isExpertPlan
              ? String(project.partnerTrialEnd)
              : String(billingPlan.trialEnd),
          ).diffNow('days').days,
        ),
        0,
      ),
    [billingPlan, project, isExpertPlan],
  );

  const totalDays = useMemo(() => {
    if (!billingPlan) {
      return 30;
    }

    if (!startDate) {
      return isExpertPlan ? 60 : 30;
    }

    if (!billingPlan.trialEnd || !project.partnerTrialEnd) {
      return 0;
    }

    const startDt = DateTime.fromISO(startDate);
    const trialEnd = DateTime.fromISO(
      isExpertPlan
        ? String(project.partnerTrialEnd)
        : String(billingPlan.trialEnd),
    );

    return Math.min(
      Math.max(Math.floor(trialEnd.diff(startDt, 'days').days), 0),
      isExpertPlan ? 60 : 30,
    );
  }, [billingPlan, startDate, isExpertPlan, project.partnerTrialEnd]);

  return (
    (isTrialing || isPartnerTrialing) && (
      <Popover
        className="mb-8"
        bg="white"
        content={
          <div className="max-h-screen-50 relative flex bg-white p-2 pt-1">
            <div className="flex w-full max-w-md flex-col">
              <div className="mb-12 flex items-center">
                <div
                  className="mr-4 h-20 w-20 flex-shrink-0 rounded-full bg-pink-100 bg-cover bg-center bg-no-repeat"
                  style={formatBg(onboardingManager.avatar)}
                />
                <div className="flex flex-col">
                  <h1 className="mb-px text-lg font-medium">
                    {getText(
                      'appAuth.onboarding.intro.title',
                      activeOnboardingManager,
                    )}
                  </h1>
                  <p className="text-sm text-gray-800">
                    {getText('appAuth.onboarding.intro.description')}
                  </p>
                  <p className="mt-px text-xs text-gray-600">
                    <span>
                      {getText(
                        'toolbar.trial.onboarding',
                        activeOnboardingManager,
                        'jobTitle',
                      )}
                    </span>
                    <span> • {onboardingManager.email} • </span>
                  </p>
                </div>
              </div>
              <h3 className="text-sm font-medium">
                {getText({ context: daysRemaining }, 'toolbar.trial.summary')}
              </h3>
              <p className="mb-12 mt-1 text-xs text-gray-800">
                {getText({ context: daysRemaining }, 'toolbar.trial.explain')}
              </p>
              <a
                className="mt-auto flex w-full items-center justify-center rounded-lg bg-pink-500 px-6 py-3 text-center text-white hover:bg-pink-600"
                href={`${APP_DASHBOARD_URL}/billing`}
              >
                <IconLayout size={16} className="mr-4 opacity-75" />
                <span>{getText('toolbar.trial.viewPlans')}</span>
              </a>
            </div>
          </div>
        }
        placement="right"
        trigger="click"
      >
        <div className="cursor-pointer text-pink-500 hover:text-pink-300">
          <CircularProgress progress={daysRemaining} maxProgress={totalDays} />
        </div>
      </Popover>
    )
  );
});

export default TrialProgressTooltip;
