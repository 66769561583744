import { useMemo } from 'react';
import { TailwindThemeProvider } from '@darraghmckay/tailwind-react-ui';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import LeftBuildModeEditor from '@noloco/core/src/components/editor/LeftBuildModeEditor';
import {
  editorModeSelector,
  leftEditorSectionSelector,
} from '@noloco/core/src/selectors/elementsSelectors';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { getPagesConfig } from '@noloco/core/src/utils/pages';
import { getPages } from '@noloco/core/src/utils/pages';
import buildModeTheme from '../canvas/buildModeTheme';

const LeftBuildModeSidebar = () => {
  const project = useSelector(projectDataSelector);
  const leftEditor = useSelector(leftEditorSectionSelector);
  const editorMode = useSelector(editorModeSelector);

  const { isV2, pagesPath } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project.elements, project.settings],
  );

  const portalPages = useMemo(
    () => (isV2 ? project.elements : get(project.elements, pagesPath, [])),
    [isV2, project.elements, pagesPath],
  );

  const pages = useMemo(() => getPages(project.elements), [project.elements]);

  return (
    <TailwindThemeProvider theme={buildModeTheme}>
      <LeftBuildModeEditor
        editorMode={editorMode}
        leftEditor={leftEditor}
        pages={pages}
        portalPages={portalPages}
        project={project}
      />
    </TailwindThemeProvider>
  );
};

export default LeftBuildModeSidebar;
