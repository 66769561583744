import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    scope: {},
    errors: {},
    ghostUserId: null,
    isEditing: {},
  },
  reducers: {
    setErrorValue: (state, { payload: { id, value } }) => {
      state.errors = {
        ...state.errors,
        [id]: value,
      };
    },
    setScopeFieldValue: (state, { payload: { id, value } }) => {
      state.scope = {
        ...state.scope,
        [id]: value,
      };
    },
    setGhostUserId: (state, { payload }) => {
      state.ghostUserId = payload;
    },
    setIsEditingData: (state, { payload: { id, value } }) => {
      state.isEditing = {
        ...state.isEditing,
        [id]: value,
      };
    },
  },
});

export const {
  setErrorValue,
  setScopeFieldValue,
  setGhostUserId,
  setIsEditingData,
} = dataSlice.actions;

export default dataSlice.reducer;
