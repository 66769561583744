import React, { useCallback, useMemo, useState } from 'react';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { Button, SelectInput } from '@noloco/components';
import { IN, NOT_IN } from '@noloco/core/src/constants/operators';
import { getText } from '@noloco/core/src/utils/lang';
import {
  getInputTypeForOperator,
  getOperatorsForFieldType,
} from '@noloco/core/src/utils/operator';
import { isMultiRelationship } from '@noloco/core/src/utils/relationships';
import { generateAdditionalFilter } from './customerEditors/CustomFiltersEditor';

const LANG_KEY = 'elements.LIST.filterInput';
const getTranslation = (...rest: any[]) => getText(LANG_KEY, ...rest);

const ArrayFilterBranchInput = ({
  additionalScopeItems,
  dataType,
  elementPath,
  onChange,
  onEmpty,
  project,
  filters,
  ValueInput,
  selectedField,
}: any) => {
  const operators = useMemo(() => {
    if (!selectedField) {
      return [];
    }

    return getOperatorsForFieldType(
      selectedField.field.type,
      selectedField.field.relationship,
    );
  }, [selectedField]);

  const [localValue, setLocalValue] = useState(filters);

  const handleValueChange = useCallback(
    (index: number, property: string) => (newValue: any) => {
      setLocalValue((currentLocalValue: any) => {
        const nextValue = [...currentLocalValue];
        nextValue[index] = {
          ...nextValue[index],
          [property]: newValue,
        };
        setLocalValue(nextValue);
        onChange(nextValue);
        return nextValue;
      });
    },
    [onChange],
  );

  const handleFilterRemove = useCallback(
    (id) => () => {
      const updatedFilters = filters.filter((filter: any) => filter.id !== id);

      if (updatedFilters.length === 0) {
        return onEmpty();
      }

      setLocalValue(updatedFilters);
      onChange(updatedFilters);
      return updatedFilters;
    },
    [filters, onChange, onEmpty],
  );

  const handleFilterAdded = useCallback(() => {
    const updated = [
      ...filters,
      generateAdditionalFilter({
        field: selectedField.field.name,
        operator: operators[0],
      }),
    ];

    setLocalValue(updated);
    onChange(updated);
    return updated;
  }, [filters, selectedField.field.name, operators, onChange]);

  const operatorOptions = operators.map((op) => ({
    label: getText('operators', op, 'label.default'),
    value: op,
  }));

  return (
    <>
      <div className="relative my-1 space-y-2 rounded-lg p-2">
        {localValue.map((operatorFilter: any, filterIndex: any) => {
          return (
            <div
              className="ml-4 flex max-w-full flex-grow flex-wrap items-center"
              key={operatorFilter.id}
            >
              <SelectInput
                className="mr-2"
                value={operatorFilter.operator}
                options={operatorOptions}
                onChange={handleValueChange(filterIndex, 'operator')}
                placeholder={getTranslation('operator.placeholder')}
                contained={true}
                showValidationErrorText={false}
              />
              <div className="flex">
                {!!getInputTypeForOperator(operatorFilter.operator) && (
                  <ValueInput
                    additionalScopeItems={additionalScopeItems}
                    contained={true}
                    dataType={dataType}
                    field={selectedField.field}
                    project={project}
                    className="w-56"
                    onChange={handleValueChange(filterIndex, 'result')}
                    multiple={
                      [IN, NOT_IN].includes(operatorFilter.operator) &&
                      (!selectedField ||
                        !selectedField.field.relationship ||
                        // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
                        !isMultiRelationship(!selectedField.field.relationship))
                    }
                    elementPath={elementPath}
                    includeUniqueColumnar={
                      selectedField &&
                      (!!selectedField.field.relationship ||
                        !!selectedField.field.relatedField ||
                        getInputTypeForOperator(operatorFilter.operator) ===
                          'array')
                    }
                    placeholder={getTranslation('result.placeholder')}
                    value={operatorFilter.result ?? []}
                  />
                )}
                <IconTrash
                  size={14}
                  onClick={handleFilterRemove(operatorFilter.id)}
                  className="my-auto ml-2 text-gray-500 hover:text-white"
                />
              </div>
            </div>
          );
        })}
        <Button
          className="ml-4 mt-2 flex items-center"
          onClick={handleFilterAdded}
          variant="secondary"
        >
          <IconPlus className="mr-2" size={16} />
          <span>{getTranslation('addAnd')}</span>
        </Button>
      </div>
    </>
  );
};

export default ArrayFilterBranchInput;
