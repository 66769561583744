import { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useDrag, useDrop } from 'react-dnd';
import { Item, ItemTypes } from '../../../constants/buildMode';
import { DIVIDER, FOLDER, VIEW } from '../../../constants/elements';
import { Project } from '../../../models/Project';
import { Page } from '../../../utils/pages';
import AddPageLine from '../../sidebar/AddPageLine';
import BuildModeBaseItem from './BuildModeBaseItem';
import DraggableBuildModeSidebarItem from './DraggableBuildModeSidebarItem';

type BuildModeParentItemProps = {
  active: boolean;
  handleDrop: (target: Item, draggedItem: Item, targetType?: string) => void;
  item: Item;
  listExpanded: boolean;
  portalPages: Page[];
  project: Project;
  showHiddenPages?: boolean;
  sidebarExpanded?: boolean;
  type: string;
};

const BuildModeParentItem = ({
  active = false,
  handleDrop,
  item,
  listExpanded,
  portalPages,
  project,
  showHiddenPages = false,
  sidebarExpanded = true,
  type,
}: BuildModeParentItemProps) => {
  const [{ isDragging, draggedItem }, dragRef] = useDrag({
    type,
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      draggedItem: monitor.getItem(),
    }),
  });

  const [{ isOver: isAbove }, aboveDropRef] = useDrop({
    accept: [ItemTypes.SINGLE_ITEM, ItemTypes.FOLDER, ItemTypes.NESTED_ITEM],
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: () => handleDrop(item, draggedItem),
  });

  const isDropEnabled = useMemo(() => {
    if (type === ItemTypes.NESTED_ITEM) {
      return !(
        (draggedItem?.type === ItemTypes.SINGLE_ITEM &&
          item.childItems?.length === 0) ||
        draggedItem?.type !== ItemTypes.SINGLE_ITEM
      );
    }

    return true;
  }, [type, draggedItem, item]);

  const [{ isOver }, dropRef] = useDrop({
    accept: ItemTypes.SINGLE_ITEM,
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: () => (isDropEnabled ? handleDrop(item, draggedItem, type) : null),
  });

  const canDrop = useMemo(() => {
    if (type === ItemTypes.FOLDER) {
      if (
        draggedItem &&
        draggedItem.new &&
        draggedItem.type === ItemTypes.FOLDER
      ) {
        return false;
      }

      return !(showHiddenPages || get(draggedItem, 'page.type') === DIVIDER);
    }

    return (
      !showHiddenPages &&
      item.parentPageType !== FOLDER &&
      item.page.type === VIEW &&
      get(item, 'page.props.dataList.dataType') ===
        get(draggedItem, 'page.props.dataList.dataType') &&
      item.id !== draggedItem.id &&
      !get(item, 'page.props.parentPage', null)
    );
  }, [type, showHiddenPages, draggedItem, item]);

  return (
    <>
      <div
        className={classNames('h-1 w-full rounded-full', {
          'bg-slate-600': isAbove,
        })}
        ref={showHiddenPages ? null : aboveDropRef}
      />
      <div
        className={classNames({
          'bg-slate-900': isOver,
          'opacity-100': !isDragging,
          'opacity-75': isDragging,
        })}
      >
        <div
          className={classNames({
            'opacity-100': !isDragging,
            'opacity-75': isDragging,
          })}
        >
          <div ref={canDrop ? dropRef : null}>
            {!showHiddenPages && (
              <AddPageLine
                page={item.page}
                portalPages={portalPages}
                project={project}
              />
            )}
            <DraggableBuildModeSidebarItem
              elementPath={item.elementPath}
              isDragging={isDragging}
              isOver={isOver}
              listExpanded={listExpanded}
              item={item}
              portalPages={portalPages}
              project={project}
              ref={dragRef}
              sidebarExpanded={sidebarExpanded}
              showHiddenPages={showHiddenPages}
            />
          </div>
          {(active || listExpanded) && (
            <div className={classNames({ 'ml-6': sidebarExpanded })}>
              {item.childItems?.map((childItem) => (
                <BuildModeBaseItem
                  handleDrop={handleDrop}
                  isNestedOrFolder={true}
                  key={childItem.id}
                  listExpanded={listExpanded}
                  item={childItem}
                  portalPages={portalPages}
                  project={project}
                  sidebarExpanded={sidebarExpanded}
                  showHiddenPages={showHiddenPages}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BuildModeParentItem;
