import React from 'react';
import classNames from 'classnames';
import { Badge, Surface, getColorShade } from '@noloco/components';
import { getColorByIndex } from '../../../utils/colors';

type OptionBadgeProps = {
  className?: string;
  m?: Record<string, number>;
  onRemove?: () => void;
  option: { display: string; color?: string; order: number };
  removable?: boolean;
  surface?: Surface;
};
const OptionBadge: React.FunctionComponent<OptionBadgeProps> = ({
  className,
  m,
  onRemove,
  option,
  removable,
  surface,
}: OptionBadgeProps) => (
  <Badge
    className={classNames(className, 'h-auto break-words leading-normal')}
    color={getColorShade(option.color || getColorByIndex(option.order), 400)}
    m={m}
    onRemove={onRemove}
    removable={removable}
    surface={surface}
  >
    {option.display}
  </Badge>
);

export default OptionBadge;
