import createReactComponent from '../createReactComponent';
export default createReactComponent('mail-search', 'IconMailSearch', [
  [
    'path',
    {
      d: 'M11 19h-6a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-2' }],
  ['path', { d: 'M3 7l9 6l9 -6', key: 'svg-3' }],
]);
