import createReactComponent from '../createReactComponent';
export default createReactComponent('badge-ad', 'IconBadgeAd', [
  [
    'path',
    {
      d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M14 9v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z', key: 'svg-1' },
  ],
  ['path', { d: 'M7 15v-4.5a1.5 1.5 0 0 1 3 0v4.5', key: 'svg-2' }],
  ['path', { d: 'M7 13h3', key: 'svg-3' }],
]);
