import createReactComponent from '../createReactComponent';
export default createReactComponent('multiplier-2x', 'IconMultiplier2x', [
  ['path', { d: 'M14 16l4 -4', key: 'svg-0' }],
  ['path', { d: 'M18 16l-4 -4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M6 10a2 2 0 1 1 4 0c0 .591 -.417 1.318 -.816 1.858l-3.184 4.143l4 0',
      key: 'svg-2',
    },
  ],
]);
