import React, { useMemo } from 'react';
import classNames from 'classnames';
import { darkModeColors } from '../../../constants/darkModeColors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import HelpText from '../HelpText';
import Title from '../Title';

export const CARDS = 'CARDS';
export const COMPACT = 'COMPACT';

const SECTION_CLASS_NAMES = {
  [CARDS]: 'shadow-lg flex flex-col mb-5 mt-3 p-8 sm:p-4 rounded-lg',
  [COMPACT]: 'flex flex-col p-2',
};

const getSectionClassNames = (
  sectionFormat: 'CARDS' | 'COMPACT',
  isDarkModeEnabled: boolean,
) => {
  return `${SECTION_CLASS_NAMES[sectionFormat]} ${
    isDarkModeEnabled ? darkModeColors.surfaces.elevation1 : 'bg-white'
  }`;
};

const AutoFormSection = ({
  children,
  config,
  fields,
  section,
  sectionFormat,
}: any) => {
  const title = config.label;
  const subtitle = null;

  const [isDarkModeEnabled] = useDarkMode();

  const sectionFieldNames = useMemo(
    () => section.fields.map((field: any) => field.name),
    [section],
  );
  const sectionFields = useMemo(
    () =>
      fields.filter(({ config }: any) =>
        sectionFieldNames.includes(config.name),
      ),
    [fields, sectionFieldNames],
  );

  return (
    <div
      className={classNames(
        'col-span-12',
        getSectionClassNames(sectionFormat, isDarkModeEnabled as boolean),
      )}
      key={section.id}
    >
      <div className="mb-5 space-y-1">
        <div className="flex">
          <Title
            subtitle={{
              hidden: !subtitle,
              value: subtitle,
            }}
            title={{
              hidden: !title,
              value: title,
            }}
            truncateTitle={false}
          />
        </div>
        {config.helpText && <HelpText>{config.helpText}</HelpText>}
      </div>
      <div className="grid grid-cols-12 gap-x-2">
        {sectionFields.map(children)}
      </div>
    </div>
  );
};

export default AutoFormSection;
