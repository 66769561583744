import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'inner-shadow-top-left-filled',
  'IconInnerShadowTopLeftFilled',
  [
    [
      'path',
      {
        d: 'M12 2c5.523 0 10 4.477 10 10s-4.477 10 -10 10s-10 -4.477 -10 -10s4.477 -10 10 -10zm0 3a7 7 0 0 0 -7 7a1 1 0 0 0 2 0a5 5 0 0 1 5 -5a1 1 0 0 0 0 -2z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
  ],
);
