const tailwindTheme = {
  spacing: {
    zero: 0,
    sm: 1.5,
    md: 3,
    lg: 6,
    xl: 8,
  },
  brandColors: {
    primary: 'pink-500',
    danger: 'red-500',
    secondary: 'gray-500',
    success: 'green-500',
    warning: 'yellow-300',
    info: 'pink-500',
    custom: '',
  },
  brandColorGroups: {
    primary: 'pink',
    secondary: 'gray',
    success: 'green',
    warning: 'yellow',
    danger: 'red',
    info: 'pink',
    custom: '',
  },
  surfaceColors: {
    dark: 'brand',
    light: 'white',
  },
  borderColors: {
    dark: 'brand-light',
    light: 'gray-300',
    default: 'gray-300',
  },
  textInput: {
    surface: 'dark',
    textColor: 'brand-lightest',
  },
  selectInput: {
    surface: 'dark',
    textColor: 'brand-lightest',
  },
  dropzone: {
    surface: 'dark',
    textColor: 'brand-lightest',
  },
  datePicker: {
    surface: 'dark',
    textColor: 'brand-lightest',
  },
};

module.exports = tailwindTheme;
