import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-toggle-horizontal',
  'IconSquareToggleHorizontal',
  [
    ['path', { d: 'M22 12h-20', key: 'svg-0' }],
    ['path', { d: 'M4 14v-8a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v8', key: 'svg-1' }],
    ['path', { d: 'M18 20a2 2 0 0 0 2 -2', key: 'svg-2' }],
    ['path', { d: 'M4 18a2 2 0 0 0 2 2', key: 'svg-3' }],
    ['path', { d: 'M14 20l-4 0', key: 'svg-4' }],
  ],
);
