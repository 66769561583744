import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-amazon', 'IconBrandAmazon', [
  [
    'path',
    {
      d: 'M17 12.5a15.198 15.198 0 0 1 -7.37 1.44a14.62 14.62 0 0 1 -6.63 -2.94',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M19.5 15c.907 -1.411 1.451 -3.323 1.5 -5c-1.197 -.773 -2.577 -.935 -4 -1',
      key: 'svg-1',
    },
  ],
]);
