import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { DateTime } from 'luxon';
import { Route } from 'react-router-dom';
import { Loader } from '@noloco/components';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import MarkdownText from '../components/MarkdownText';
import { ChartAggregation } from '../constants/chartAggregations';
import { STACKED_BAR } from '../constants/chartTypes';
import {
  CARDS,
  CHARTS,
  CollectionLayout,
  EVENT_BASED_LAYOUTS,
  GANTT,
  LAYOUT_SUPPORTS_RECORD_COLORING,
  MAP,
  PIVOT_TABLE,
  ROWS,
  SINGLE_RECORD,
  SPLIT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '../constants/collectionLayouts';
import {
  DATE,
  DECIMAL,
  INTEGER,
  OBJECT,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { COLLECTION, DETAILS, VIEW } from '../constants/elements';
import {
  CONDITIONAL_FIELD_VISIBILITY,
  FIELD_LEVEL_PERMISSIONS,
} from '../constants/features';
import {
  BETWEEN,
  EMPTY,
  GREATER_OR_EQUAL,
  LESS_OR_EQUAL,
  NOT_IN,
  OR,
  SOME,
} from '../constants/operators';
import { ASC, OrderByDirection } from '../constants/orderByDirections';
import { PivotTable, getRowGrouping } from '../constants/pivotTable';
import { DataField } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';
import {
  ActionButton,
  DepValue,
  ElementPath,
  VisibilityRules,
} from '../models/Element';
import { IconValue } from '../models/IconValue';
import { Project } from '../models/Project';
import { BaseRecord } from '../models/Record';
import StateItem from '../models/StateItem';
import { User } from '../models/User';
import {
  DataList,
  ExportButton,
  FilterField,
  FormFieldConfig,
  GroupBy,
  GroupByWithField,
  ImportButton,
  MapProps,
  Search,
} from '../models/View';
import { getFieldPathFromPath } from '../utils/charts';
import {
  appendInitialDepPath,
  conditionsAreMet,
  extractFieldsFromActionButtons,
  findDependentValues,
  findDependentValuesByParentIds,
  generateDepForField,
} from '../utils/data';
import { getDepsForField } from '../utils/data';
import {
  getFieldFromDependency,
  getFieldKey,
  getFieldReverseName,
} from '../utils/fields';
import { getPrimaryField } from '../utils/fields';
import {
  formatFilterValueForQueryString,
  formatTextInput,
  getQueryStringFilterValue,
  isEmptyFilterToken,
  paramName,
} from '../utils/filters';
import useAuthWrapper from '../utils/hooks/useAuthWrapper';
import { useNextBackLink } from '../utils/hooks/useBacklink';
import useMergedScope from '../utils/hooks/useMergedScope';
import useRouter from '../utils/hooks/useRouter';
import useTrackAppPage, { PageTypes } from '../utils/hooks/useTrackAppPage';
import {
  getNeverResultForOperator,
  getOperatorForFieldFilter,
} from '../utils/operator';
import {
  dataTypePermissions,
  mapFieldsWithPermissionsAndConfig,
} from '../utils/permissions';
import { isMultiField, isMultiRelationship } from '../utils/relationships';
import { RECORD_SCOPE } from '../utils/scope';
import { isSearchAllowed } from '../utils/search';
import DataListWrapper from './DataListWrapper';
import ViewCollectionBody from './ViewCollectionBody';
import RecordView from './sections/view/RecordView';

export const getDataFieldPropAsDep = (
  dataFieldValue: any,
  dataType: any,
  dataTypesWithRelations: any,
  elementType: any,
) => {
  const isView = elementType === VIEW;
  if (
    dataFieldValue &&
    (!dataFieldValue.path.startsWith('edges.node.') || !isView)
  ) {
    const depPath = `${isView ? 'edges.node.' : ''}${dataFieldValue.path}`;

    const dataFieldDependency = {
      ...dataFieldValue,
      path: depPath,
    };

    const dependencyField = getFieldFromDependency(
      depPath.split('.'),
      dataType,
      dataTypesWithRelations,
    );

    return { dependency: dataFieldDependency, dependencyField };
  }

  return {};
};

const getLocalDateFilterValue = (dateValue: any) => {
  const dateTime = DateTime.fromISO(dateValue, {
    zone: 'utc',
  });

  return DateTime.local(dateTime.year, dateTime.month, dateTime.day, 0, 0, 0);
};

type EditRelatedRecordButtons = {
  hideAddRelatedRecordButton?: boolean;
  hideUnlinkButton?: boolean;
  show?: boolean;
  unlinkButtonText?: string;
};

type ViewCollectionProps = {
  actionButtons?: ActionButton[];
  calendarView: string;
  charts: any[];
  className: string;
  coverPhoto: { src: string };
  dataList: DataList;
  dateEnd: DepValue | undefined;
  dateStart: DepValue | undefined;
  editorMode: boolean;
  editRelatedRecordButtons?: EditRelatedRecordButtons;
  elementPath: ElementPath;
  emptyState: any;
  enableDragAndDropEdit: boolean;
  endTime: number;
  exportButton: ExportButton;
  fields: FormFieldConfig[];
  filters: any[];
  formatRecordScope: (record: BaseRecord) => Record<string, any>;
  ganttDependency?: DepValue;
  groupBy?: StateItem;
  groupBySort: OrderByDirection;
  groupOptions: { [key: string]: { hidden: boolean } };
  groups: GroupBy[];
  hideEmptyGroups: boolean;
  hideNewButton: boolean;
  icon?: IconValue;
  importButton: ImportButton;
  innerClassName?: string;
  isRecordView?: boolean;
  layout: CollectionLayout;
  limitPerGroup: number;
  map?: MapProps;
  name?: string;
  newButton: { visibilityRules?: VisibilityRules };
  newButtonText: string;
  newLink: string | null;
  newRecordValues?: Record<string, any>;
  onClick?: () => void;
  pivotTable?: PivotTable;
  project: Project;
  qsSuffix?: string;
  record?: BaseRecord;
  recordTitle: string;
  rootDataType?: DataType;
  rootPathname: string | null;
  rowLink: string;
  scope: Record<string, any>;
  search: Search;
  sidebarSize?: any;
  startTime: number;
  summary?: { field: StateItem; type: ChartAggregation };
  subtitle: string;
  title: string;
  track?: boolean;
  viewId: string;
  viewRootPathname: string | null;
  wrapperClassName?: string;
  openFormInModal?: boolean;
};

const ViewCollection = forwardRef(
  (
    {
      actionButtons = [],
      calendarView,
      charts = [],
      className,
      coverPhoto,
      dataList,
      dateEnd,
      dateStart,
      editorMode,
      editRelatedRecordButtons = {},
      elementPath,
      emptyState,
      enableDragAndDropEdit,
      endTime,
      importButton: {
        show: showImportButton,
        text: importButtonText,
        fields: importFields,
        visibilityRules: importButtonVisibilityRules,
      } = {},
      exportButton: {
        show: showExportButton,
        text: exportButtonText,
        visibilityRules: exportButtonVisiblityRules,
      } = {},
      fields = [],
      filters = [],
      formatRecordScope,
      ganttDependency,
      groupBy,
      groupBySort = ASC,
      groupOptions = {},
      groups,
      hideEmptyGroups,
      hideNewButton,
      icon,
      innerClassName,
      isRecordView,
      layout = ROWS,
      limitPerGroup,
      map,
      name,
      newButton = {},
      newButtonText,
      newLink,
      newRecordValues,
      openFormInModal,
      onClick,
      pivotTable,
      project,
      qsSuffix = '',
      record,
      recordTitle,
      rootDataType,
      rootPathname,
      rowLink,
      scope,
      search = {},
      startTime,
      summary,
      subtitle,
      title,
      track = false,
      viewId,
      viewRootPathname,
      wrapperClassName,
    }: ViewCollectionProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const mergedScope = useMergedScope(scope);
    const {
      query: queryParams,
      query: {
        [`_date${qsSuffix}`]: calendarDate,
        [`_after${qsSuffix}`]: after,
        [`_before${qsSuffix}`]: before,
        [`_orderBy${qsSuffix}`]: qsOrderBy,
        [`_direction${qsSuffix}`]: qsDirection,
      },
      pathname,
    } = useRouter();

    const isConditionalFieldVisibilityEnabled = useIsFeatureEnabled(
      CONDITIONAL_FIELD_VISIBILITY,
    );

    const trackingProperties = useMemo(() => ({ layout }), [layout]);
    useTrackAppPage(PageTypes.VIEW, trackingProperties, track);

    const backLinkEnabled =
      viewRootPathname !== pathname ||
      (!!rowLink && rowLink !== viewId && rowLink !== '#');
    const nextBackLink = useNextBackLink(viewId, backLinkEnabled);

    const { user } = useAuthWrapper();
    const element = useMemo(
      () => get(project.elements, elementPath, {}),
      [elementPath, project.elements],
    );

    const dataType = useMemo(
      () => project.dataTypes.getByName(dataList.dataType)!,
      [dataList.dataType, project.dataTypes],
    );

    const permissions = useMemo(
      () => dataTypePermissions(dataType, user as User),
      [dataType, user],
    );
    const fieldPermissionsEnabled = useIsFeatureEnabled(
      FIELD_LEVEL_PERMISSIONS,
    );

    const EmptyState = useMemo(
      () => () => (
        <div
          className={classNames(
            'flex w-full flex-col items-center justify-center p-16 text-center',
            { 'col-span-3 md:col-span-1': layout === CARDS },
          )}
          data-testid="view-collection-empty-state"
        >
          <h2 className="text-base font-medium">{emptyState.title.value}</h2>
          {emptyState.subtitle && (
            <MarkdownText className="text-sm text-gray-400" small={false}>
              {emptyState.subtitle.value}
            </MarkdownText>
          )}
          {!emptyState.image.hidden && emptyState.image.value.src && (
            <img
              className="mt-4 w-full max-w-xs rounded-lg"
              src={emptyState.image.value.src}
              alt={emptyState.title.value}
            />
          )}
        </div>
      ),
      [layout, emptyState],
    );

    const fieldConfigs = useMemo(
      () =>
        layout === PIVOT_TABLE
          ? []
          : mapFieldsWithPermissionsAndConfig(
              fields,
              dataType,
              user as User,
              project.dataTypes,
              fieldPermissionsEnabled,
            ),
      [
        dataType,
        fieldPermissionsEnabled,
        fields,
        layout,
        project.dataTypes,
        user,
      ],
    );

    const validFilters: {
      config: FilterField;
      field: DataField;
      parent?: DataField | undefined;
    }[] = useMemo(
      () =>
        mapFieldsWithPermissionsAndConfig<FilterField>(
          filters,
          dataType,
          user as User,
          project.dataTypes,
          fieldPermissionsEnabled,
        ).filter(
          ({ config }: any) =>
            !isConditionalFieldVisibilityEnabled ||
            (config && !config.conditions) ||
            conditionsAreMet(config && config.conditions, mergedScope, project),
        ),
      [
        dataType,
        fieldPermissionsEnabled,
        filters,
        isConditionalFieldVisibilityEnabled,
        mergedScope,
        project,
        user,
      ],
    );

    const filterValues: Record<string, any> = useMemo(
      () =>
        validFilters.reduce(
          (filterAcc, { config, field, parent }) => {
            const fieldName = paramName(field, parent);

            let filterValue = get(queryParams, `${fieldName}${qsSuffix}`);
            const defaultValueForFilter = get(config, 'defaultValue');
            const shouldApplyDefaultFilter =
              filterValue === undefined && defaultValueForFilter !== undefined;

            if (shouldApplyDefaultFilter) {
              filterValue = defaultValueForFilter;
            }

            if (!isNil(filterValue)) {
              return set(
                [fieldName],
                getQueryStringFilterValue(field, config, filterValue),
                filterAcc,
              );
            }

            return filterAcc;
          },
          { _q: get(queryParams, `_q${qsSuffix}`, null) },
        ),
      [qsSuffix, queryParams, validFilters],
    );

    const globalSearchIsAllowed = useMemo(
      () => isSearchAllowed(dataList),
      [dataList],
    );

    const searchEnabled = useMemo(
      () => globalSearchIsAllowed && search && search.enabled,
      [globalSearchIsAllowed, search],
    );

    const globalSearchFilter = useMemo(() => {
      if (!searchEnabled) {
        return null;
      }

      const filterValue = get(queryParams, `_q${qsSuffix}`, null);
      if (!filterValue) {
        return null;
      }

      const isNumber = !isNaN(Number(filterValue));

      const searchableFields = fieldConfigs.filter(
        ({ field, parent }) =>
          !parent &&
          (field.type === TEXT ||
            (isNumber && (field.type === INTEGER || field.type === DECIMAL))),
      );

      if (searchableFields.length === 0) {
        return null;
      }

      return {
        operator: OR,
        branches: searchableFields.map(({ field }) => ({
          filters: [
            {
              field: field.apiName,
              operator: getOperatorForFieldFilter(field, {}),
              result:
                field.type === DECIMAL || field.type === INTEGER
                  ? Number(filterValue)
                  : String(filterValue),
            },
          ],
        })),
      };
    }, [fieldConfigs, qsSuffix, queryParams, searchEnabled]);

    const customFilters = useMemo(
      () =>
        validFilters
          .reduce((filterAcc, { config, field, parent }: any) => {
            const filterValue = filterValues[paramName(field, parent)];
            const wrapIfMultiple = (fieldKey: string, ...filters: any) =>
              field.multiple && filters.length > 0
                ? [{ field: fieldKey, operator: SOME, filters }]
                : filters;
            if (filterValue !== undefined) {
              let fieldKey = getFieldKey(field);
              if (parent?.type === OBJECT) {
                fieldKey = `${parent.apiName}.${field.apiName}`;
              }

              if (
                config.filterByEmpty &&
                isEmptyFilterToken(field, filterValue)
              ) {
                return [
                  ...filterAcc,
                  {
                    field: fieldKey,
                    operator: EMPTY,
                  },
                ];
              }

              if (
                [INTEGER, DECIMAL].includes(field.type) &&
                config.filterOperator === BETWEEN
              ) {
                const [min = '', max = ''] = (filterValue || '').split(':');
                return [
                  ...filterAcc,
                  ...wrapIfMultiple(
                    fieldKey,
                    {
                      field: fieldKey,
                      operator: GREATER_OR_EQUAL,
                      result: formatFilterValueForQueryString(
                        field,
                        formatTextInput(field.type, min),
                      ),
                    },
                    {
                      field: fieldKey,
                      operator: LESS_OR_EQUAL,
                      result: formatFilterValueForQueryString(
                        field,
                        formatTextInput(field.type, max),
                      ),
                    },
                  ),
                ];
              }

              if (field.type !== DATE) {
                return [
                  ...filterAcc,
                  ...wrapIfMultiple(fieldKey, {
                    field: fieldKey,
                    operator: getOperatorForFieldFilter(field, config),
                    result:
                      (field.relationship || field.relatedField) && filterValue
                        ? config.multiple && filterValue.edges
                          ? filterValue.edges.map((i: any) => i.node.id)
                          : [filterValue.id]
                        : filterValue,
                  }),
                ];
              }

              return [
                ...filterAcc,
                ...wrapIfMultiple(
                  fieldKey,
                  {
                    field: fieldKey,
                    operator: GREATER_OR_EQUAL,
                    result: getLocalDateFilterValue(filterValue.start).toISO(),
                  },
                  ...(filterValue.end
                    ? [
                        {
                          field: fieldKey,
                          operator: LESS_OR_EQUAL,
                          result: getLocalDateFilterValue(filterValue.end)
                            .endOf('day')
                            .toISO(),
                        },
                      ]
                    : []),
                ),
              ];
            }

            return filterAcc;
          }, [] as any[])
          .filter(Boolean),
      [filterValues, validFilters],
    );

    const isEventsBasedLayout = useMemo(
      () => EVENT_BASED_LAYOUTS.includes(layout),
      [layout],
    );

    const { dateStartDep, dateStartField, dateEndDep, dateEndField } =
      useMemo(() => {
        const dateValues: any = {};
        if (isEventsBasedLayout && dateStart) {
          const {
            dependency: _dateStartDep,
            dependencyField: _dateStartField,
          } = getDataFieldPropAsDep(
            dateStart,
            dataType,
            project.dataTypes,
            element.type,
          );
          if (_dateStartDep) {
            dateValues.dateStartDep = _dateStartDep;
            dateValues.dateStartField = _dateStartField?.field;

            if (dateEnd) {
              const {
                dependency: _dateEndDep,
                dependencyField: _dateEndField,
              } = getDataFieldPropAsDep(
                dateEnd,
                dataType,
                project.dataTypes,
                element.type,
              );

              if (_dateEndDep) {
                dateValues.dateEndDep = _dateEndDep;
                dateValues.dateEndField = _dateEndField?.field;
              }
            }
          }
        }

        return dateValues;
      }, [
        dataType,
        dateEnd,
        dateStart,
        element.type,
        project.dataTypes,
        isEventsBasedLayout,
      ]);

    const canHaveMultipleGroups =
      layout === TABLE ||
      layout === PIVOT_TABLE ||
      layout === TABLE_FULL ||
      layout === SPLIT ||
      layout === ROWS;

    const { columnGrouping = [] } = pivotTable || {};

    const groupByGroups: GroupBy[] = useMemo(() => {
      const unfilteredGroups =
        layout === PIVOT_TABLE && columnGrouping ? columnGrouping : groups;

      if (unfilteredGroups) {
        return unfilteredGroups
          .filter((group) => group.field)
          .slice(0, canHaveMultipleGroups ? 3 : 1);
      }

      if (groupBy) {
        return [
          {
            id: 'legacy',
            field: groupBy,
            sort: groupBySort,
          },
        ];
      }

      return [];
    }, [
      canHaveMultipleGroups,
      columnGrouping,
      groupBy,
      groupBySort,
      groups,
      layout,
    ]);

    const groupByFields: GroupByWithField[] = useMemo(
      () =>
        groupByGroups
          .map((group) => {
            const { dependencyField } = getDataFieldPropAsDep(
              group.field,
              dataType,
              project.dataTypes,
              element.type,
            );

            return {
              ...group,
              dataField: dependencyField,
            };
          })
          .filter((group) => group.dataField) as GroupByWithField[],
      [dataType, element.type, groupByGroups, project.dataTypes],
    );

    const { dependencyField: ganttDependencyField } = useMemo(
      () =>
        getDataFieldPropAsDep(
          ganttDependency,
          dataType,
          project.dataTypes,
          element.type,
        ),
      [dataType, element.type, ganttDependency, project.dataTypes],
    );
    const canGroup = groupByFields.length > 0;

    const { mapLatField, mapLngField } = useMemo(() => {
      if (layout === MAP && map) {
        const latField =
          map.latitude &&
          getDataFieldPropAsDep(
            map.latitude,
            dataType,
            project.dataTypes,
            element.type,
          );

        const lngField =
          map.longitude &&
          getDataFieldPropAsDep(
            map.longitude,
            dataType,
            project.dataTypes,
            element.type,
          );

        return {
          mapLatField: latField ? latField.dependencyField?.field : null,
          mapLngField: lngField ? lngField.dependencyField?.field : null,
        };
      }

      return {};
    }, [dataType, element.type, layout, map, project.dataTypes]);

    const combinedFilters = useMemo(() => {
      const baseFilters = [
        ...customFilters,
        ...(dataList.customFilters || []).map((customFilter: any) => {
          const fieldName = get(customFilter, ['field']);
          const operator = get(customFilter, ['operator']);
          const result = get(customFilter, ['result']);

          if (!isUndefined(result) || !dataType) {
            return customFilter;
          }

          const field = dataType.fields.getByDataQueryName(fieldName)!;

          return {
            ...customFilter,
            result: getNeverResultForOperator(operator as never, field),
          };
        }),
      ];

      if (globalSearchFilter) {
        baseFilters.push(globalSearchFilter);
      }

      if (dateStartField && layout !== TIMELINE && layout !== GANTT) {
        const dateValue = calendarDate
          ? DateTime.fromISO(calendarDate)
          : DateTime.now();

        baseFilters.push({
          field: dateStartField.apiName,
          operator: GREATER_OR_EQUAL,
          result: dateValue.minus({ month: 1 }).startOf('month').toISO(),
        });
        baseFilters.push({
          field: dateStartField.apiName,
          operator: LESS_OR_EQUAL,
          result: dateValue.plus({ month: 1 }).endOf('month').toISO(),
        });
      } else if (
        canGroup &&
        groupByFields[0] &&
        groupByFields[0].dataField.field.type === SINGLE_OPTION &&
        Object.keys(groupOptions).length > 0
      ) {
        baseFilters.push({
          field: groupByFields[0].dataField.field.apiName,
          operator: NOT_IN,
          result: Object.entries(groupOptions)
            .filter(
              ([optionName, optionConfig]) =>
                get(optionConfig, 'hidden', false) &&
                groupByFields[0].dataField.field.options?.some(
                  (op) => op.name === optionName,
                ),
            )
            .map(([optionName]) => optionName),
        });
      }

      return baseFilters;
    }, [
      calendarDate,
      canGroup,
      customFilters,
      dataList.customFilters,
      dataType,
      dateStartField,
      globalSearchFilter,
      groupByFields,
      groupOptions,
      layout,
    ]);

    const isFilteredByUser = useMemo(
      () => !!globalSearchFilter || customFilters.length > 0,
      [globalSearchFilter, customFilters.length],
    );

    const recordActionButtons = useMemo(
      () =>
        // For a collection page only render action buttons with
        // display.collection set to true as these can be toggled between the
        // collection and record pages. For related collection sections on a
        // record page, render all record action buttons as these can only
        // appear in the one place.
        get(element, ['props', 'record', 'actionButtons'], []).filter(
          (actionButton: any) =>
            get(element, 'type') === COLLECTION ||
            !!get(actionButton, 'display.collection'),
        ),
      [element],
    );

    const additionalDeps = useMemo(() => {
      const recordScopeId = `${element.id}:RECORD`;
      const viewScopeId = `${element.id}:VIEW`;

      let deps = findDependentValuesByParentIds(
        [recordScopeId, viewScopeId, RECORD_SCOPE],
        {
          id: recordScopeId,
          props: {
            fields,
            charts: layout === CHARTS ? charts : undefined,
            recordColoring: LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout)
              ? element.props.recordColoring
              : undefined,
          },
          type: VIEW,
        },
        project.dataTypes,
      ).map((dep: any) => ({ ...dep, path: `edges.node.${dep.path}` }));

      if (
        (layout !== CHARTS && fieldConfigs.length > 0) ||
        layout === PIVOT_TABLE
      ) {
        const fieldDeps = fieldConfigs.reduce((depAcc, { field, parent }) => {
          if (field) {
            return [
              ...depAcc,
              ...getDepsForField(
                field,
                project.dataTypes,
                element.id,
                `edges.node.${parent ? `${parent.name}.` : ''}`,
              ),
            ];
          }
          return depAcc;
        }, [] as any[]);

        const actionButtonDeps = extractFieldsFromActionButtons(
          recordActionButtons,
        ).reduce((acc: any[], actionButtonFieldObj: any) => {
          const field = dataType.fields.getByName(actionButtonFieldObj.name);
          if (!field) {
            return acc;
          }
          return [
            ...acc,
            ...getDepsForField(
              field,
              project.dataTypes,
              element.id,
              'edges.node.',
            ),
          ];
        }, [] as any[]);

        deps = deps.concat(actionButtonDeps);

        if (ganttDependencyField) {
          const prefix = appendInitialDepPath('edges.node.', ganttDependency);
          const ganttDependencyFieldDeps = getDepsForField(
            ganttDependencyField?.field,
            project.dataTypes,
            element.id,
            prefix,
          );
          const dateFieldsArray = [dateStartField?.name, dateEndField?.name];
          const additionalGanttDeps = dateFieldsArray.map((relatedFieldName) =>
            generateDepForField(
              element.id,
              prefix,
              ganttDependencyField?.field,
              isMultiField(ganttDependencyField?.field) ? '.edges.node' : '',
              relatedFieldName,
            ),
          );

          let additionalGanttReverseDeps: { id: string; path: string }[] = [];
          if (isMultiRelationship(ganttDependencyField?.field.relationship)) {
            const ganttDependencyReverseField = getFieldReverseName(
              ganttDependencyField?.field,
              dataType,
            ) as string;

            if (ganttDependencyReverseField) {
              additionalGanttReverseDeps = dateFieldsArray.map(
                (relatedFieldName) =>
                  generateDepForField(
                    element.id,
                    prefix,
                    { name: ganttDependencyReverseField },
                    '.edges.node',
                    relatedFieldName,
                  ),
              );
            }
          }

          deps = deps.concat([
            ...ganttDependencyFieldDeps,
            ...additionalGanttDeps,
            ...additionalGanttReverseDeps,
          ]);
        }

        if (canGroup) {
          const groupByDeps = groupByGroups.reduce(
            (acc, group, idx) => [
              ...acc,
              ...getDepsForField(
                get(groupByFields, [idx, 'dataField', 'field']),
                project.dataTypes,
                element.id,
                appendInitialDepPath('edges.node.', group.field),
              ),
            ],
            [] as DepValue[],
          );

          deps = deps.concat(groupByDeps);
        }

        const checkEventDeps = isEventsBasedLayout && dateStart;
        const checkMapDeps = mapLatField && mapLngField && map;

        if (checkEventDeps || checkMapDeps) {
          const recordTitleFieldName = recordTitle ?? get(record, 'title');
          const recordTitleField = recordTitleFieldName
            ? dataType.fields.getByName(recordTitleFieldName)
            : getPrimaryField(dataType);

          if (recordTitleField) {
            const recordTitleDeps = getDepsForField(
              recordTitleField,
              project.dataTypes,
              element.id,
              'edges.node.',
            );

            deps = deps.concat(recordTitleDeps);
          }
        }

        if (checkMapDeps) {
          const latDeps = getDepsForField(
            mapLatField,
            project.dataTypes,
            element.id,
            appendInitialDepPath('edges.node.', map.latitude),
          );
          const lngDeps = getDepsForField(
            mapLngField,
            project.dataTypes,
            element.id,
            appendInitialDepPath('edges.node.', map.longitude),
          );

          deps = deps.concat(latDeps).concat(lngDeps);
        }

        if (checkEventDeps) {
          if (dateStartDep) {
            fieldDeps.push(dateStartDep);

            if (dateEndDep) {
              fieldDeps.push(dateEndDep);
            }
          }
        }

        if (summary?.field) {
          const fieldName = getFieldPathFromPath(summary.field?.path!);
          const summaryField = dataType.fields.getByName(fieldName);

          if (summaryField) {
            deps = deps.concat(
              getDepsForField(
                summaryField,
                project.dataTypes,
                element.id,
                'edges.node.',
              ),
            );
          }
        }

        deps = deps.concat(fieldDeps);
      }

      const { values = [] } = pivotTable || {};
      const rowGrouping = getRowGrouping(pivotTable!);

      if (layout === PIVOT_TABLE) {
        if (rowGrouping && rowGrouping.field) {
          const prefix = appendInitialDepPath('edges.node.', rowGrouping.field);
          const field = getFieldFromDependency(
            rowGrouping.field.path.split('.'),
            dataType,
            project.dataTypes,
          )?.field;

          if (field) {
            deps = deps.concat(
              getDepsForField(field, project.dataTypes, element.id, prefix),
            );
          }

          if (values.length > 0) {
            values.forEach((value) => {
              if (value && value.field) {
                const prefix = appendInitialDepPath('edges.node.', value.field);
                const field = getFieldFromDependency(
                  value.field!.path.split('.'),
                  dataType,
                  project.dataTypes,
                )?.field;

                if (field) {
                  deps = deps.concat(
                    getDepsForField(
                      field,
                      project.dataTypes,
                      element.id,
                      prefix,
                    ),
                  );
                }
              }
            });
          }
        }
      }

      if (layout === CHARTS && charts.length > 0) {
        const chartDeps = charts
          .filter(Boolean)
          .reduce((depAcc: any, chart: any) => {
            const { dependencyField: xAxisFieldDependency } =
              getDataFieldPropAsDep(
                chart.xAxisValue,
                dataType,
                project.dataTypes,
                element.type,
              );

            const yAxisDeps = (chart.series || []).reduce(
              (seriesAcc: any, series: any) => {
                const { dependencyField: yAxisDepField } =
                  getDataFieldPropAsDep(
                    series.yAxisValue,
                    dataType,
                    project.dataTypes,
                    element.type,
                  );

                if (!yAxisDepField) {
                  return seriesAcc;
                }

                return [
                  ...seriesAcc,
                  ...getDepsForField(
                    yAxisDepField?.field,
                    project.dataTypes,
                    element.id,
                    appendInitialDepPath('edges.node.', series.yAxisValue),
                  ),
                ];
              },
              [],
            );

            const xAxisField = xAxisFieldDependency?.field!;

            const xAxisDeps = getDepsForField(
              xAxisField,
              project.dataTypes,
              element.id,
              appendInitialDepPath('edges.node.', chart.xAxisValue),
            );

            const stackedChartGrouping =
              chart.chartType === STACKED_BAR &&
              Array.isArray(chart?.groups) &&
              chart?.groups[0]?.field;

            let groupDeps: {
              dataType?: string;
              id: string;
              path: string;
              source?: string;
            }[] = [];

            if (stackedChartGrouping) {
              const { dependencyField } = getDataFieldPropAsDep(
                chart.groups[0].field,
                dataType,
                project.dataTypes,
                element.type,
              );

              if (dependencyField) {
                groupDeps = getDepsForField(
                  dependencyField?.field,
                  project.dataTypes,
                  element.id,
                  appendInitialDepPath('edges.node.', chart?.groups[0]?.field),
                );
              }
            }

            return [...depAcc, ...xAxisDeps, ...yAxisDeps, ...groupDeps];
          }, [] as any[])
          .filter(Boolean);

        deps = deps.concat(chartDeps);
      }

      if (recordActionButtons.length > 0) {
        const viewId = `${element.id}:VIEW`;
        const actionButtonDeps = findDependentValues(
          viewId,
          {
            id: viewId,
            props: {
              actionButtons: recordActionButtons,
            },
            type: DETAILS,
          },
          project.dataTypes,
        );

        if (actionButtonDeps) {
          deps = deps.concat(
            actionButtonDeps.map((dep: any) => ({
              ...dep,
              id: element.id,
              path: `edges.node.${dep.path}`,
            })),
          );
        }
      }

      const idField = dataType.fields.getByName('id')!;
      const uuidField = dataType.fields.getByName('uuid')!;
      deps = deps.concat(
        getDepsForField(idField, project.dataTypes, element.id, 'edges.node.'),
      );
      deps = deps.concat(
        getDepsForField(
          uuidField,
          project.dataTypes,
          element.id,
          'edges.node.',
        ),
      );

      return deps;
    }, [
      canGroup,
      charts,
      dataType,
      dateEndDep,
      dateEndField,
      dateStart,
      dateStartDep,
      dateStartField,
      element,
      fieldConfigs,
      fields,
      ganttDependency,
      ganttDependencyField,
      groupByFields,
      groupByGroups,
      isEventsBasedLayout,
      layout,
      map,
      mapLatField,
      mapLngField,
      pivotTable,
      project.dataTypes,
      record,
      recordActionButtons,
      recordTitle,
      summary,
    ]);

    if (layout === SINGLE_RECORD) {
      return (
        <Route path={`${rootPathname}/:tab?`}>
          <DataListWrapper
            additionalDeps={additionalDeps}
            project={project}
            elementPath={elementPath}
            scope={mergedScope}
            {...dataList}
            limit={1}
            customFilters={combinedFilters}
          >
            {({ loading, edges, rawData, error }) => {
              if (loading) {
                return (
                  <div className="flex w-full items-center justify-center p-8">
                    <Loader size="sm" />
                  </div>
                );
              }

              if (error && !rawData) {
                return (
                  <div className="flex w-full items-center justify-center p-8">
                    <em>Something went wrong</em>
                  </div>
                );
              }

              if (edges.length === 0) {
                return <EmptyState />;
              }

              const firstRecordUuid = get(edges, '0.node.uuid');

              return (
                <RecordView
                  {...record}
                  dataType={dataType}
                  element={element}
                  elementPath={elementPath}
                  icon={icon}
                  name={name}
                  onClick={onClick}
                  permissions={permissions}
                  project={project}
                  recordId={firstRecordUuid}
                  rootPathname={rootPathname}
                  scope={mergedScope}
                />
              );
            }}
          </DataListWrapper>
        </Route>
      );
    }

    return (
      <ViewCollectionBody
        actionButtons={actionButtons}
        additionalDeps={additionalDeps}
        after={after}
        before={before}
        calendarDate={calendarDate}
        calendarView={calendarView}
        canGroup={canGroup}
        charts={charts}
        className={className}
        combinedFilters={combinedFilters}
        isFilteredByUser={isFilteredByUser}
        coverPhoto={coverPhoto}
        dataList={dataList}
        dataType={dataType}
        dateEnd={dateEnd}
        dateEndField={dateEndField}
        dateStart={dateStart}
        dateStartField={dateStartField}
        editorMode={editorMode}
        editRelatedRecordButtons={editRelatedRecordButtons}
        element={element}
        elementPath={elementPath}
        EmptyState={EmptyState}
        enableDragAndDropEdit={enableDragAndDropEdit}
        endTime={endTime}
        exportButtonText={exportButtonText}
        fieldConfigs={fieldConfigs}
        filterValues={filterValues}
        formatRecordScope={formatRecordScope}
        ganttDependency={ganttDependency}
        groupByFields={groupByFields}
        groupOptions={groupOptions}
        hideEmptyGroups={hideEmptyGroups}
        hideNewButton={hideNewButton}
        hideIfEmpty={emptyState.hideIfEmpty}
        importButtonText={importButtonText}
        importFields={importFields}
        innerClassName={innerClassName}
        isRecordView={isRecordView}
        layout={layout}
        limitPerGroup={limitPerGroup}
        map={map}
        mapLatField={mapLatField}
        mapLngField={mapLngField}
        newButton={newButton}
        newButtonText={newButtonText}
        newLink={newLink}
        openFormInModal={openFormInModal}
        newRecordValues={newRecordValues}
        nextBackLink={nextBackLink}
        onClick={onClick}
        permissions={permissions}
        pivotTable={pivotTable}
        project={project}
        qsDirection={qsDirection as OrderByDirection}
        qsOrderBy={qsOrderBy}
        qsSuffix={qsSuffix}
        record={record}
        recordActionButtons={recordActionButtons}
        recordTitle={recordTitle}
        ref={ref}
        rootDataType={rootDataType}
        rowLink={rowLink}
        scope={mergedScope}
        search={search}
        searchEnabled={searchEnabled}
        showExportButton={showExportButton}
        showImportButton={showImportButton}
        startTime={startTime}
        summary={summary}
        subtitle={subtitle}
        title={title}
        validFilters={validFilters}
        viewId={viewId}
        viewRootPathname={viewRootPathname}
        wrapperClassName={wrapperClassName}
        importButtonVisibilityRules={importButtonVisibilityRules}
        exportButtonVisiblityRules={exportButtonVisiblityRules}
      />
    );
  },
);

ViewCollection.displayName = 'ViewCollection';

export default ViewCollection;
