import createReactComponent from '../createReactComponent';
export default createReactComponent('eraser-off', 'IconEraserOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M19 20h-10.5l-4.21 -4.3a1 1 0 0 1 0 -1.41l5 -4.993m2.009 -2.01l3 -3a1 1 0 0 1 1.41 0l5 5a1 1 0 0 1 0 1.41c-1.417 1.431 -2.406 2.432 -2.97 3m-2.02 2.043l-4.211 4.256',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M18 13.3l-6.3 -6.3', key: 'svg-2' }],
]);
