import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { DataType } from '../../../models/DataTypes';
import { Project } from '../../../models/Project';
import { BaseRecord } from '../../../models/Record';
import { editorModeSelector } from '../../../selectors/elementsSelectors';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import usePrevious from '../../../utils/hooks/usePrevious';
import useRecordComments from '../../../utils/hooks/useRecordComments';
import { getText } from '../../../utils/lang';
import { isInternal } from '../../../utils/user';
import RecordCommentsBody from '../comments/RecordCommentsBody';
import RecordCommentsFooter from '../comments/RecordCommentsFooter';
import RecordEmptyState from './RecordEmptyState';

type RecordCommentsSectionProps = {
  dataType: DataType;
  record: BaseRecord;
  project: Project;
  allowAttachments: boolean;
};

const shouldShowScrollbar = (commentSectionRef: HTMLDivElement | null) => {
  if (!commentSectionRef) {
    return false;
  }

  const commentsBodyWrapper = commentSectionRef.querySelector(
    '#record-comments-body-wrapper',
  );

  if (!commentsBodyWrapper) {
    return false;
  }

  const scrollContainer = commentsBodyWrapper.querySelector(
    '.comment-scroll-container',
  );

  if (!scrollContainer) {
    return false;
  }

  return scrollContainer.scrollHeight > commentsBodyWrapper.clientHeight;
};

const RecordCommentsSection = ({
  dataType,
  record,
  project,
  allowAttachments,
}: RecordCommentsSectionProps) => {
  const { user } = useAuthWrapper();
  const [isDarkModeEnabled] = useDarkMode();

  const {
    comments,
    commentText,
    pageInfo,
    filesToSend,
    setFilesToSend,
    sendNote,
    onChange,
    loading,
    loaderRef,
    sending,
    setSendNote,
    onCreateNewMessage,
    onDeleteMessage,
  } = useRecordComments({
    dataType,
    record,
    project,
  });
  const [showScrollbar, setShowScrollbar] = useState(false);
  const commentSectionRef = useRef<HTMLDivElement | null>(null);
  const previousNumberOfComments = usePrevious(comments.length);

  useLayoutEffect(() => {
    if (!loading) {
      if (!showScrollbar) {
        const firstShow = shouldShowScrollbar(commentSectionRef.current);
        if (firstShow) {
          setShowScrollbar(true);
          return;
        }

        if (previousNumberOfComments !== comments.length) {
          setTimeout(() => {
            const show = shouldShowScrollbar(commentSectionRef.current);

            if (show !== showScrollbar) {
              setShowScrollbar(show);
            }
          }, 100);
        }
      }
    }
  }, [comments.length, loading, previousNumberOfComments, showScrollbar]);

  return (
    <div
      className={classNames(
        'max-h-screen-75 flex flex-col rounded-lg border border-gray-200 bg-white text-sm shadow-md dark:border-gray-700 dark:bg-gray-800',
        {
          'h-screen': !loading && showScrollbar,
        },
      )}
      ref={commentSectionRef}
      data-testid="comments-section"
    >
      <div
        id="record-comments-body-wrapper"
        className="flex flex-grow overflow-hidden"
      >
        <RecordCommentsBody
          canDelete={isInternal(user)}
          comments={comments}
          isDarkModeEnabled={isDarkModeEnabled}
          loaderRef={loaderRef}
          loading={loading}
          onDelete={onDeleteMessage}
          pageInfo={pageInfo}
        />
      </div>
      <div className="flex w-full flex-shrink-0">
        <RecordCommentsFooter
          allowAttachments={allowAttachments}
          className="w-full overflow-hidden rounded-bl-lg rounded-br-lg px-2 pt-2"
          commentText={commentText}
          isLoading={loading || sending}
          filesToSend={filesToSend}
          onChange={onChange}
          onCreateNewMessage={onCreateNewMessage}
          sendNote={sendNote}
          setFilesToSend={setFilesToSend}
          setSendNote={setSendNote}
          shouldFocus={false}
          project={project}
          user={user}
        />
      </div>
    </div>
  );
};

const BaseRecordCommentSection = ({
  dataType,
  record,
  rootField,
  project,
  allowAttachments,
}: RecordCommentsSectionProps & { rootField: string | undefined }) => {
  const rootFieldObject = useMemo(
    () => rootField && dataType.fields.getByName(rootField),
    [dataType.fields, rootField],
  );
  const rootDataType = useMemo(() => {
    const dataTypeName = rootFieldObject && rootFieldObject.type;

    return ((dataTypeName && project.dataTypes.getByName(dataTypeName)) ??
      dataType) as DataType;
  }, [rootFieldObject, project, dataType]);

  const baseRecord = useMemo(
    () => (rootFieldObject ? get(record, [rootFieldObject.apiName]) : record),
    [record, rootFieldObject],
  );

  const editorMode = useSelector(editorModeSelector);

  if (!baseRecord) {
    if (editorMode) {
      return <RecordEmptyState title={getText('elements.COMMENTS.empty')} />;
    }

    return null;
  }

  return (
    <RecordCommentsSection
      dataType={rootDataType}
      record={baseRecord as BaseRecord}
      project={project}
      allowAttachments={allowAttachments}
    />
  );
};

export default BaseRecordCommentSection;
