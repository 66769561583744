import createReactComponent from '../createReactComponent';
export default createReactComponent('box-off', 'IconBoxOff', [
  [
    'path',
    {
      d: 'M17.765 17.757l-5.765 3.243l-8 -4.5v-9l2.236 -1.258m2.57 -1.445l3.194 -1.797l8 4.5v8.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14.561 10.559l5.439 -3.059', key: 'svg-1' }],
  ['path', { d: 'M12 12v9', key: 'svg-2' }],
  ['path', { d: 'M12 12l-8 -4.5', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
