import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-steam', 'IconBrandSteam', [
  [
    'path',
    {
      d: 'M16.5 5a4.5 4.5 0 1 1 -.653 8.953l-4.347 3.009l0 .038a3 3 0 0 1 -2.824 3l-.176 0a3 3 0 0 1 -2.94 -2.402l-2.56 -1.098v-3.5l3.51 1.755a2.989 2.989 0 0 1 2.834 -.635l2.727 -3.818a4.5 4.5 0 0 1 4.429 -5.302z',
      key: 'svg-0',
    },
  ],
  [
    'circle',
    { cx: '16.5', cy: '9.5', r: '1', fill: 'currentColor', key: 'svg-1' },
  ],
]);
