import React, { useRef } from 'react';
import { IconGripVertical, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { useDrag, useDrop } from 'react-dnd';
import { TextInput } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { ID } from '@noloco/core/src/elements/sections/collections/CollectionEvents';
import { getColorByIndex } from '@noloco/core/src/utils/colors';
import { getText } from '@noloco/core/src/utils/lang';
import ColorOptionInput from './ColorOptionInput';
import { UpsertedOption } from './NewFieldForm';

type Props = {
  deleteDisabled: boolean;
  deleteOption: ((option: UpsertedOption) => void) | undefined;
  onReorder: (draggedId: ID, newIndex: number) => void;
  option: UpsertedOption;
  readOnly: boolean;
  readOnlyColor: boolean;
  updateOptionColor: (option: UpsertedOption, nextColor: string) => void;
  updateOptionName: (option: UpsertedOption, newName: string) => void;
};

const FieldOptionsInputEntry = ({
  deleteDisabled,
  deleteOption,
  onReorder,
  option,
  readOnly,
  readOnlyColor,
  updateOptionColor,
  updateOptionName,
}: Props) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'OPTION',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = (item as any).order;
      const hoverIndex = option.order;
      // Don't replace items with themselves or use invalid indexes
      if (dragIndex === hoverIndex || isNil(dragIndex) || isNil(hoverIndex)) {
        return;
      }
      onReorder((item as any).id, hoverIndex);
      (item as any).order = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'OPTION',
    item: () => {
      return { id: option.id, order: option.order, option };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (!readOnly || !readOnlyColor) {
    drag(drop(ref));
  }

  return (
    <div
      className={classNames('flex w-full items-center justify-between pr-0.5', {
        invisible: isDragging,
      })}
      ref={ref}
      data-handler-id={handlerId}
    >
      {!readOnlyColor && (
        <IconGripVertical size={18} className={'mr-2 opacity-75'} ref={drag} />
      )}
      <ColorOptionInput
        className="mr-2"
        onChange={(nextColor: any) => updateOptionColor(option, nextColor)}
        readOnly={readOnlyColor}
        value={option.color || getColorByIndex(option.order)}
      />
      <TextInput
        border={0}
        readOnly={readOnly}
        className="w-full text-gray-800 hover:bg-gray-400 focus:bg-gray-400"
        placeholder={getText('data.options.new')}
        value={option.display}
        onChange={({ target: { value } }: any) =>
          updateOptionName(option, value)
        }
        surface={DARK}
        data-testid="field-options-edit-name-input"
      />
      {deleteOption && !readOnly && (
        <button
          className="ml-2 p-1 opacity-50 hover:opacity-100 disabled:opacity-50"
          disabled={deleteDisabled}
          onClick={() => deleteOption(option)}
          data-testid="field-options-delete-button"
        >
          <IconX size={14} color="white" />
        </button>
      )}
    </div>
  );
};

export default FieldOptionsInputEntry;
