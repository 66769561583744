import createReactComponent from '../createReactComponent';
export default createReactComponent('building-store', 'IconBuildingStore', [
  ['path', { d: 'M3 21l18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M5 21l0 -10.15', key: 'svg-2' }],
  ['path', { d: 'M19 21l0 -10.15', key: 'svg-3' }],
  ['path', { d: 'M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4', key: 'svg-4' }],
]);
