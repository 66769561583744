import createReactComponent from '../createReactComponent';
export default createReactComponent('circle-dotted', 'IconCircleDotted', [
  ['path', { d: 'M7.5 4.21l0 .01', key: 'svg-0' }],
  ['path', { d: 'M4.21 7.5l0 .01', key: 'svg-1' }],
  ['path', { d: 'M3 12l0 .01', key: 'svg-2' }],
  ['path', { d: 'M4.21 16.5l0 .01', key: 'svg-3' }],
  ['path', { d: 'M7.5 19.79l0 .01', key: 'svg-4' }],
  ['path', { d: 'M12 21l0 .01', key: 'svg-5' }],
  ['path', { d: 'M16.5 19.79l0 .01', key: 'svg-6' }],
  ['path', { d: 'M19.79 16.5l0 .01', key: 'svg-7' }],
  ['path', { d: 'M21 12l0 .01', key: 'svg-8' }],
  ['path', { d: 'M19.79 7.5l0 .01', key: 'svg-9' }],
  ['path', { d: 'M16.5 4.21l0 .01', key: 'svg-10' }],
  ['path', { d: 'M12 3l0 .01', key: 'svg-11' }],
]);
