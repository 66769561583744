import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'carousel-vertical',
  'IconCarouselVertical',
  [
    [
      'path',
      {
        d: 'M19 8v8a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1v-8a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 22v-1a1 1 0 0 1 1 -1h8a1 1 0 0 1 1 1v1', key: 'svg-1' }],
    [
      'path',
      { d: 'M17 2v1a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-1', key: 'svg-2' },
    ],
  ],
);
