import createReactComponent from '../createReactComponent';
export default createReactComponent('id-off', 'IconIdOff', [
  [
    'path',
    {
      d: 'M8 4h10a3 3 0 0 1 3 3v10m-1.437 2.561c-.455 .279 -.99 .439 -1.563 .439h-12a3 3 0 0 1 -3 -3v-10c0 -1.083 .573 -2.031 1.433 -2.559',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8.175 8.178a2 2 0 1 0 2.646 2.65', key: 'svg-1' }],
  ['path', { d: 'M15 8h2', key: 'svg-2' }],
  ['path', { d: 'M16 12h1', key: 'svg-3' }],
  ['path', { d: 'M7 16h9', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
