import createReactComponent from '../createReactComponent';
export default createReactComponent('files', 'IconFiles', [
  ['path', { d: 'M15 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2',
      key: 'svg-2',
    },
  ],
]);
