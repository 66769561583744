import createReactComponent from '../createReactComponent';
export default createReactComponent('dental-broken', 'IconDentalBroken', [
  [
    'path',
    {
      d: 'M12 5.5c-1.074 -.586 -2.583 -1.5 -4 -1.5c-2.1 0 -4 1.247 -4 5c0 4.899 1.056 8.41 2.671 10.537c.573 .756 1.97 .521 2.567 -.236c.398 -.505 .819 -1.439 1.262 -2.801c.292 -.771 .892 -1.504 1.5 -1.5c.602 0 1.21 .737 1.5 1.5c.443 1.362 .864 2.295 1.262 2.8c.597 .759 2 .993 2.567 .237c1.615 -2.127 2.671 -5.637 2.671 -10.537c0 -3.74 -1.908 -5 -4 -5c-1.423 0 -2.92 .911 -4 1.5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 5.5l1 2.5l-2 2l2 2', key: 'svg-1' }],
]);
