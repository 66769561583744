import { getText } from '../utils/lang';
import { Element } from './Element';
import ElementPropType from './elementPropTypes/ElementPropType';
import ImagePropType from './elementPropTypes/comboProps/ImagePropType';

const baseProps = {
  bgImage: new ImagePropType().setIsStyleProp(true),
};

const defaultLabelFormatter = ({ type, props }: Element) =>
  (props && props.name) || getText('elements', type, 'label');

const identity = (element: Element): Element => element;
const trueFilter = () => true;

export type BaseElementConfigArgs = {
  content?: boolean;
  section?: boolean;
  canHaveChildren?: boolean;
  deriveState?: (...varArgs: any[]) => any[];
  hidden?: boolean;
  props?: any;
  defaultProps?: any;
  styleable?: boolean;
  allowedChildTypes?: any;
  formatLabel?: (el: Element) => string;
  formatHelpText?: (el: Element) => string;
  cloneTransformation?: (el: Element) => Element;
  filterChildren?: (el: Element) => boolean;
};

export class BaseElementConfig {
  hidden: boolean;
  content: boolean;
  section: boolean;
  canHaveChildren: boolean;
  props: Record<any, ElementPropType>;
  defaultProps: Record<any, any>;
  styleable: boolean;
  deriveState: () => any[];
  allowedChildTypes?: any[];
  formatLabel: (element: Element) => any;
  formatHelpText: ((element: Element) => any) | undefined;
  cloneTransformation: (props: any, idMap?: Record<string, string>) => Element;
  filterChildren: (el: Element) => boolean;
  readonly elementConfigArgs: BaseElementConfigArgs;

  constructor(args: BaseElementConfigArgs = {}) {
    this.elementConfigArgs = args;

    const {
      content = false,
      section = false,
      canHaveChildren = true,
      deriveState = () => [],
      hidden = false,
      props = {},
      defaultProps = {},
      styleable = true,
      allowedChildTypes = undefined,
      formatLabel = defaultLabelFormatter,
      formatHelpText = () => undefined,
      cloneTransformation = identity,
      filterChildren = trueFilter,
    } = args;

    this.hidden = hidden;
    this.content = content;
    this.section = section;
    this.canHaveChildren = canHaveChildren && !content;
    this.props = {
      ...baseProps,
      ...props,
    };
    this.defaultProps = defaultProps;
    this.styleable = styleable;
    this.deriveState = deriveState;
    this.allowedChildTypes = allowedChildTypes;
    this.formatLabel = formatLabel;
    this.formatHelpText = formatHelpText;
    this.cloneTransformation = cloneTransformation;
    this.filterChildren = filterChildren;
  }

  getLabel(element: Element) {
    return this.formatLabel(element);
  }

  getHelpText(element: Element) {
    if (this.formatHelpText) {
      return this.formatHelpText(element);
    }
  }
}

export default BaseElementConfig;
