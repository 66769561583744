import createReactComponent from '../createReactComponent';
export default createReactComponent('camera-off', 'IconCameraOff', [
  [
    'path',
    {
      d: 'M8.297 4.289a.997 .997 0 0 1 .703 -.289h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v8m-1.187 2.828c-.249 .11 -.524 .172 -.813 .172h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1c.298 0 .58 -.065 .834 -.181',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10.422 10.448a3 3 0 1 0 4.15 4.098', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
