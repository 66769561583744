import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-imac-dollar',
  'IconDeviceImacDollar',
  [
    [
      'path',
      {
        d: 'M13 17h-9a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v6.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 13h11', key: 'svg-1' }],
    ['path', { d: 'M8 21h5', key: 'svg-2' }],
    ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
    [
      'path',
      {
        d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
        key: 'svg-4',
      },
    ],
    ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-5' }],
  ],
);
