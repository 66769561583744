import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import Xarrow from 'react-xarrows';
import { Dependent, Event, ID } from './CollectionEvents';

type CollectionGanttProps = {
  events: Event[];
  showArrows: boolean;
  theme: any;
};

type EventsWithDependencies = {
  id: ID;
  start: number;
  end: number;
  predecessors: Dependent[] | undefined;
  successors: Dependent[] | undefined;
};

type Arrow = {
  key: string;
  arrowStart: string;
  arrowEnd: string;
  invalidDependency: boolean;
};

const CollectionGantt = ({
  events,
  showArrows,
  theme,
}: CollectionGanttProps) => {
  const eventsWithDependencies = useMemo<EventsWithDependencies[]>(
    () =>
      events
        .filter(
          (event: Event) =>
            (event.predecessors && event.predecessors.length > 0) ||
            (event.successors && event.successors.length > 0),
        )
        .map((event: Event) => {
          const { id, predecessors, successors, start, end } = event;

          return {
            id,
            start: DateTime.fromJSDate(start).toMillis(),
            end: DateTime.fromJSDate(end).toMillis(),
            predecessors,
            successors,
          };
        }),
    [events],
  );

  const isDependencyInvalid = useCallback(
    (id: string | number, start: number): boolean => {
      const event = events.find((event: any) => event.id === id);
      if (event && get(event, 'end', null)) {
        return DateTime.fromJSDate(event.end).toMillis() > start;
      }

      return false;
    },
    [events],
  );

  const arrows = useMemo<Arrow[]>(
    () =>
      eventsWithDependencies.flatMap(
        ({ id, start, predecessors = [] }: EventsWithDependencies) =>
          predecessors
            .map(
              (predecessor: Dependent) =>
                predecessor && {
                  key: `${id}-${predecessor.id}`,
                  arrowStart: `gantt-event-${predecessor.id}-start`,
                  arrowEnd: `gantt-event-${id}-end`,
                  invalidDependency: isDependencyInvalid(predecessor.id, start),
                },
            )
            .filter(Boolean),
      ),
    [eventsWithDependencies, isDependencyInvalid],
  );

  return (
    <>
      {arrows.map(({ key, arrowStart, arrowEnd, invalidDependency }: Arrow) => (
        <Xarrow
          key={key}
          start={arrowStart}
          end={arrowEnd}
          dashness={invalidDependency}
          showXarrow={showArrows}
          passProps={{
            className: `stroke-${
              theme.brandColors[invalidDependency ? 'danger' : 'primary']
            }`,
          }}
          startAnchor="bottom"
          endAnchor="left"
          gridBreak="20%"
          path="grid"
          headSize={2}
          strokeWidth={1.5}
          divContainerStyle={{ zIndex: 75 }}
        />
      ))}
    </>
  );
};

export default CollectionGantt;
