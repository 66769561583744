import { useMemo } from 'react';
import isNil from 'lodash/isNil';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import { FIELD_VALIDATION_RULES } from '@noloco/core/src/constants/features';
import { getText } from '@noloco/core/src/utils/lang';
import useIsFeatureEnabled from '../../../utils/hooks/useIsFeatureEnabled';
import ProFeatureBadge from '../../ProFeatureBadge';
import ConditionsEditor from '../ConditionsEditor';

const LANG_KEY = 'elements.VIEW';

const FieldRequiredEditor = ({
  config,
  dataType,
  elementPath,
  field,
  fieldOptions,
  index,
  project,
  updateFields,
  userOptions,
}: any) => {
  const rulesAreEnabled = useIsFeatureEnabled(FIELD_VALIDATION_RULES);
  const popoverDisabled = useMemo(
    () => !rulesAreEnabled || !config.required,
    [config.required, rulesAreEnabled],
  );

  const rulesAreActive = useMemo(
    () => !isNil(config.requiredConditions),
    [config.requiredConditions],
  );

  return (
    <BuildModeSwitchSection
      disabled={field.required}
      label={
        <div className="flex items-center space-x-2">
          <span>{getText(LANG_KEY, 'new.required')}</span>
          <ProFeatureBadge
            feature={FIELD_VALIDATION_RULES}
            inline={true}
            showAfterTrial={!rulesAreEnabled}
          />
        </div>
      }
      onChange={(value: boolean) => updateFields([index, 'required'], value)}
      value={field.required || config.required}
    >
      <div className="flex w-full max-w-full flex-col">
        <BuildModeSwitchSection
          className="min-h-10 justify-center px-2"
          disabled={popoverDisabled}
          label={getText(LANG_KEY, 'new.requiredConditions')}
          onChange={(value: boolean) =>
            updateFields([index, 'requiredConditions'], value ? [] : null)
          }
          value={rulesAreActive}
        />
        {rulesAreActive && (
          <ConditionsEditor
            className="p-2"
            dataType={dataType}
            elementPath={elementPath}
            fieldOptions={[fieldOptions, userOptions]}
            inline={true}
            project={project}
            rules={config.requiredConditions || []}
            updateConditions={(path: any, value: any) =>
              updateFields([index, 'requiredConditions', ...path], value)
            }
          />
        )}
      </div>
    </BuildModeSwitchSection>
  );
};

export default FieldRequiredEditor;
