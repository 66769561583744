import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-etsy', 'IconBrandEtsy', [
  ['path', { d: 'M14 12h-5', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 3m0 5a5 5 0 0 1 5 -5h8a5 5 0 0 1 5 5v8a5 5 0 0 1 -5 5h-8a5 5 0 0 1 -5 -5z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M15 16h-5a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h5', key: 'svg-2' },
  ],
]);
