import createReactComponent from '../createReactComponent';
export default createReactComponent('bell-minus', 'IconBellMinus', [
  [
    'path',
    {
      d: 'M12.5 17h-8.5a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3c.047 .386 .149 .758 .3 1.107',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 17v1a3 3 0 0 0 3.504 2.958', key: 'svg-1' }],
  ['path', { d: 'M16 19h6', key: 'svg-2' }],
]);
