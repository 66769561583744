import { DataSchemaField } from './dataSchema';
import { KeyValue } from './keyValue';

// Authentication

export const NONE: Authentication = 'NONE';
export const OAUTH2: Authentication = 'OAUTH2';

export type Authentication = 'NONE' | 'OAUTH2';

export const apiAuthenticationTypes: Authentication[] = [NONE, OAUTH2];

export type OAuth2Options = {
  accessToken?: string;
  accessTokenUrl?: string;
  authorizationUrl?: string;
  clientId?: string;
  clientSecret?: string;
  refreshToken?: string;
  scopes?: string;
};

export type OAuth2CallbackState = {
  dataSourceId: number;
  projectName: string;
};

// Pagination

export const CURSOR: Pagination = 'CURSOR';
export const OFFSET: Pagination = 'OFFSET';
export const PAGE: Pagination = 'PAGE';
export const PATH: Pagination = 'PATH';

export type Pagination = 'CURSOR' | 'OFFSET' | 'PAGE' | 'PATH';

// Endpoints

export type Endpoint = {
  body: string | null;
  cursorPath: string | null;
  display: string;
  fields: DataSchemaField[] | null;
  firstPage: number | null;
  headers: KeyValue[];
  idField: string | null;
  method: string;
  pathPath: string | null;
  pagination: Pagination | null;
  parameters: KeyValue[];
  path: string;
  resultPath: string[] | null;
};

// Data Source Connection

export type ConnectionInput = {
  authentication: Authentication;
  baseUrl: string;
  endpoints: Endpoint[];
  headers: KeyValue[];
  options: OAuth2Options | null;
};

export interface ApiConnection {
  authentication: Authentication;
  baseUrl: string;
  headers: KeyValue[];
  options: OAuth2Options | null;
}
