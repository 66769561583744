import React from 'react';
import { PhoneNumber } from '../../models';
import { ErrorText, Label } from '../form';
import FormField from '../form/FormField';
import PhoneNumberInput from '../input/PhoneNumberInput';
import SubmitButton from './SubmitButton';
import EmailInput from './inputs/EmailInput';
import PasswordInput from './inputs/PasswordInput';

const RegisterForm = ({
  children,
  disabled,
  emailLabel,
  phoneNumberLabel,
  buttonText,
  confirmPasswordLabel,
  passwordLabel,
  hideEmail,
  email,
  showPhoneNumber = false,
  phoneNumber,
  footer,
  password,
  confirmPassword,
  setEmail,
  setPhoneNumber,
  setPassword,
  setConfirmPassword,
  emailErrorMessage,
  phoneNumberErrorMessage,
  passwordErrorMessage,
  confirmPasswordErrorMessage,
  surface,
  socialLogins,
}: any) => (
  <>
    {children}
    {!hideEmail && (
      <EmailInput
        onChange={setEmail}
        label={emailLabel}
        value={email}
        errorMessage={emailErrorMessage}
        surface={surface}
      />
    )}
    {showPhoneNumber && (
      <div className="mt-3">
        <Label surface={surface} m={0} className="mb-2">
          {phoneNumberLabel}
        </Label>
        <PhoneNumberInput
          inline={true}
          onChange={(value: PhoneNumber) => setPhoneNumber(value)}
          surface={surface}
          value={phoneNumber}
        />
        {phoneNumberErrorMessage && (
          <ErrorText>{phoneNumberErrorMessage}</ErrorText>
        )}
      </div>
    )}
    <PasswordInput
      onChange={setPassword}
      label={passwordLabel}
      errorMessage={passwordErrorMessage}
      surface={surface}
      value={password}
    />
    <FormField
      aria-label="Confirm password"
      className="mt-3"
      name="confirm-password"
      type="password"
      onChange={({ target: { value } }: any) => setConfirmPassword(value)}
      required
      errorType="below-solid"
      label={confirmPasswordLabel}
      placeholder=""
      value={confirmPassword}
      errorMessage={confirmPasswordErrorMessage}
      surface={surface}
    />
    <SubmitButton disabled={disabled}>{buttonText}</SubmitButton>
    {socialLogins}
    {footer}
  </>
);

export default RegisterForm;
