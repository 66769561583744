import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'layout-sidebar-right-expand',
  'IconLayoutSidebarRightExpand',
  [
    [
      'path',
      {
        d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M15 4v16', key: 'svg-1' }],
    ['path', { d: 'M10 10l-2 2l2 2', key: 'svg-2' }],
  ],
);
