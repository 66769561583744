import React from 'react';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import identity from 'lodash/identity';
import { FILE, ROLE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  AIRTABLE,
  API,
  GOOGLE_SHEETS,
  INTERNAL,
  MYSQL,
  POSTGRES,
  SALESFORCE,
  XANO,
} from '@noloco/core/src/constants/dataSources';
import { DataSource, DataType } from '@noloco/core/src/models/DataTypes';
import { getText } from '@noloco/core/src/utils/lang';
import DataSourceIcon from '../components/DataSourceIcon';
import airtableIcon from '../img/airtable-icon.svg';
import apiIcon from '../img/cloud-code.png';
import googleSheetsLogo from '../img/google-sheets-logo.png';
import mysqlLogo from '../img/mysql-square-logo.png';
import postgresLogo from '../img/postgres-logo.png';
import salesforceLogo from '../img/salesforce-logo.png';
import xanoLogo from '../img/xano-logo.png';

export const HIDDEN_DATA_TYPES = [FILE, ROLE];

export const dataSourceIcons = {
  [AIRTABLE]: airtableIcon,
  [API]: apiIcon,
  [MYSQL]: mysqlLogo,
  [POSTGRES]: postgresLogo,
  [GOOGLE_SHEETS]: googleSheetsLogo,
  [SALESFORCE]: salesforceLogo,
  [XANO]: xanoLogo,
};

const getDataSourceKey = (dataType: any) => get(dataType, 'source.id', 0);

export const filterDataTypes = (dataType: DataType) =>
  !dataType.internal && !HIDDEN_DATA_TYPES.includes(dataType.name);

export const getGroupedDataTypeOptions = (
  dataTypes: any,
  {
    emptySources = [],
    filter = identity,
    isDisabled = () => false,
    getValue,
  }: any = {},
) => {
  const filteredTypes = dataTypes
    .filter((dataType: any) => filterDataTypes(dataType) && filter(dataType))
    .sort((dtA: any, dtB: any) => parseInt(dtA.id, 10) - parseInt(dtB.id, 10));

  const groupedDataTypeOptions = Object.values(
    groupBy(filteredTypes, getDataSourceKey),
  )
    .map((dataTypeGroup) => {
      const dataSource = dataTypeGroup[0].source;
      const label =
        dataSource.type === INTERNAL
          ? getText('data.internal')
          : dataSource.display;

      return {
        dataSource,
        label,
        key: getDataSourceKey(dataTypeGroup[0]),
        icon: <DataSourceIcon type={dataSource.type} />,
        heading: true,
        options: dataTypeGroup.map((dataType) => ({
          label: dataType.display,
          dataType,
          value: getValue ? getValue(dataType) : dataType.name,
          disabled: isDisabled(dataType, dataSource),
        })),
      };
    })
    .sort((sourceA, sourceB) => sourceA.key - sourceB.key);

  const emptyDataSourceOptions = emptySources.map((dataSource: DataSource) => ({
    dataSource,
    label: dataSource.display,
    key: dataSource.id,
    icon: <DataSourceIcon type={dataSource.type} />,
    heading: true,
    options: [],
  }));

  return groupedDataTypeOptions.concat(emptyDataSourceOptions);
};
