import { useMemo } from 'react';
import {
  ACTION_BUTTONS,
  COLLECTION,
  COMMENTS,
  CONTAINER,
  DETAILS,
  DIVIDER,
  EMBED,
  FILE_GALLERY,
  HIGHLIGHTS,
  IFRAME,
  IMAGE,
  MARKDOWN,
  NOTICE,
  QUICK_LINKS,
  STAGES,
  TITLE,
  VIDEO,
} from '../../../constants/elements';
import RecordFileGallery from './FileGallery';
import RecordActionButtonSection from './RecordActionButtonsSection';
import RecordCollection from './RecordCollection';
import RecordCommentsSection from './RecordCommentsSection';
import RecordContainer from './RecordContainer';
import RecordDetails from './RecordDetails';
import RecordDivider from './RecordDivider';
import RecordEmbed from './RecordEmbed';
import RecordHighlights from './RecordHighlights';
import RecordIframe from './RecordIframe';
import RecordImage from './RecordImage';
import RecordMarkdown from './RecordMarkdown';
import RecordNotice from './RecordNotice';
import RecordQuickLinks from './RecordQuickLinks';
import RecordStages from './RecordStages';
import RecordTitle from './RecordTitle';
import RecordVideo from './RecordVideo';

const sections = {
  [ACTION_BUTTONS]: RecordActionButtonSection,
  [COLLECTION]: RecordCollection,
  [COMMENTS]: RecordCommentsSection,
  [CONTAINER]: RecordContainer,
  [DETAILS]: RecordDetails,
  [DIVIDER]: RecordDivider,
  [EMBED]: RecordEmbed,
  [FILE_GALLERY]: RecordFileGallery,
  [HIGHLIGHTS]: RecordHighlights,
  [IFRAME]: RecordIframe,
  [IMAGE]: RecordImage,
  [MARKDOWN]: RecordMarkdown,
  [NOTICE]: RecordNotice,
  [QUICK_LINKS]: RecordQuickLinks,
  [STAGES]: RecordStages,
  [TITLE]: RecordTitle,
  [VIDEO]: RecordVideo,
};

const Blank = ({ children }: any) => <div>{children}</div>;

const Section = ({ section, containerSections, ...rest }: any) => {
  const SectionComponent = useMemo(
    () => sections[section.type] || Blank,
    [section.type],
  );

  return (
    <SectionComponent containerSections={containerSections} {...rest}>
      {section.type}
    </SectionComponent>
  );
};

export default Section;
