import { configureStore } from '@reduxjs/toolkit';
import { IS_PRODUCTION } from '@noloco/core/src/constants/env';
import rootReducer from '../reducers';

const getStore = (preloadedState: any) =>
  configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
    reducer: rootReducer,
    preloadedState,
    devTools: !IS_PRODUCTION,
  });

export default getStore;
