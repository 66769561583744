import createReactComponent from '../createReactComponent';
export default createReactComponent('eyeglass-off', 'IconEyeglassOff', [
  ['path', { d: 'M5.536 5.546l-2.536 8.454', key: 'svg-0' }],
  ['path', { d: 'M16 4h2l3 10', key: 'svg-1' }],
  ['path', { d: 'M10 16h4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M19.426 19.423a3.5 3.5 0 0 1 -5.426 -2.923v-2.5m4 0h3v2.5c0 .157 -.01 .312 -.03 .463',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M10 16.5a3.5 3.5 0 0 1 -7 0v-2.5h7v2.5', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
