import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-bar-to-right',
  'IconArrowBarToRight',
  [
    ['path', { d: 'M14 12l-10 0', key: 'svg-0' }],
    ['path', { d: 'M14 12l-4 4', key: 'svg-1' }],
    ['path', { d: 'M14 12l-4 -4', key: 'svg-2' }],
    ['path', { d: 'M20 4l0 16', key: 'svg-3' }],
  ],
);
