import createReactComponent from '../createReactComponent';
export default createReactComponent('world-share', 'IconWorldShare', [
  ['path', { d: 'M20.94 13.045a9 9 0 1 0 -8.953 7.955', key: 'svg-0' }],
  ['path', { d: 'M3.6 9h16.8', key: 'svg-1' }],
  ['path', { d: 'M3.6 15h9.4', key: 'svg-2' }],
  ['path', { d: 'M11.5 3a17 17 0 0 0 0 18', key: 'svg-3' }],
  ['path', { d: 'M12.5 3a16.991 16.991 0 0 1 2.529 10.294', key: 'svg-4' }],
  ['path', { d: 'M16 22l5 -5', key: 'svg-5' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-6' }],
]);
