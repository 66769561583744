import createReactComponent from '../createReactComponent';
export default createReactComponent('sleigh', 'IconSleigh', [
  ['path', { d: 'M3 19h15a4 4 0 0 0 4 -4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M16 15h-9a4 4 0 0 1 -4 -4v-6l1.243 1.243a6 6 0 0 0 4.242 1.757h3.515v2a2 2 0 0 0 2 2h.5a1.5 1.5 0 0 0 1.5 -1.5a1.5 1.5 0 0 1 3 0v1.5a3 3 0 0 1 -3 3z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M15 15v4', key: 'svg-2' }],
  ['path', { d: 'M7 15v4', key: 'svg-3' }],
]);
