import createReactComponent from '../createReactComponent';
export default createReactComponent('share-off', 'IconShareOff', [
  ['path', { d: 'M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M15.861 15.896a3 3 0 0 0 4.265 4.22m.578 -3.417a3.012 3.012 0 0 0 -1.507 -1.45',
      key: 'svg-2',
    },
  ],
  [
    'path',
    { d: 'M8.7 10.7l1.336 -.688m2.624 -1.352l2.64 -1.36', key: 'svg-3' },
  ],
  ['path', { d: 'M8.7 13.3l6.6 3.4', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
