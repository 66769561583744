import createReactComponent from '../createReactComponent';
export default createReactComponent('cone', 'IconCone', [
  [
    'path',
    {
      d: 'M12 14c3.866 0 7 1.343 7 3s-3.134 3 -7 3s-7 -1.343 -7 -3s3.134 -3 7 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 17v-.5l-7 -12.5l-7 12.5v.5', key: 'svg-1' }],
]);
