import createReactComponent from '../createReactComponent';
export default createReactComponent('sun-off', 'IconSunOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M16 12a4 4 0 0 0 -4 -4m-2.834 1.177a4 4 0 0 0 5.66 5.654',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7',
      key: 'svg-2',
    },
  ],
]);
