import React from 'react';
import { IconSquareRotated } from '@tabler/icons-react';
import get from 'lodash/get';
import last from 'lodash/last';
import { AUTH_WRAPPER_ID } from '../constants/auth';
import { FILE } from '../constants/builtInDataTypes';
import {
  DATE,
  DECIMAL,
  INTEGER,
  OBJECT,
  SINGLE_OPTION,
} from '../constants/dataTypes';
import { ARRAY } from '../constants/elementPropTypeTypes';
import * as elements from '../constants/elements';
import PRIMITIVE_DATA_TYPES from '../constants/primitiveDataTypes';
import { DATABASE, DERIVED } from '../constants/scopeTypes';
import { DataField } from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { ElementPath } from '../models/Element';
import { Project } from '../models/Project';
import StateItem from '../models/StateItem';
import cappedMemoize from './cappedMemoize';
import { expandDataTypes, getDataTypesKey, safelyAppendPath } from './data';
import { getIconForDataField } from './dataFieldIcons';
import { sortFields } from './fields';
import { getText } from './lang';
import { getSubFieldsAsDataFields } from './objects';
import { formatStateItemAsBaseOption } from './options';
import {
  isMultiField,
  isMultiRelationship,
  isReverseMultiRelationship,
} from './relationships';
import { buildScope } from './scope';
import { DataItemOption, DataItemValueOption } from './state';

const MAX_FILTER_DEPTH = 4;
export type TypeOptionsConfig = {
  acceptableParentType?: string;
  includeCollections?: boolean;
  includeUniqueColumnar?: boolean;
  fieldFilter?: (field: DataField, dataType?: DataType) => boolean;
  forceRawPath?: boolean;
};
type DataPathSegment = { display: string };

export const getStateItemForRelatedField = (
  scopeItem: StateItem,
  field: DataField,
  relatedField: DataField,
  { forceRawPath }: TypeOptionsConfig,
) =>
  new StateItem({
    id: scopeItem.id,
    path: safelyAppendPath(
      scopeItem.path,
      `${field.name}${forceRawPath ? '' : '._columns'}.${relatedField.name}${
        (relatedField.relationship || relatedField.relatedField) &&
        !forceRawPath
          ? isMultiField(relatedField)
            ? '._columns.id'
            : '.id'
          : ''
      }`,
    ),
    source: scopeItem.source,
    dataType: ARRAY,
    display: getText(
      { fieldName: relatedField.display },
      'elements',
      elements.LIST,
      'data',
      relatedField.relationship ? 'relatedListField' : 'listField',
    ),
    args: scopeItem.args,
  });
export const formatStateItemAsOption = (
  stateItem: StateItem,
  dataPath: { display: string }[] = [],
  field?: DataField,
  dataType?: DataType,
): DataItemValueOption & { value: StateItem } => {
  const baseOption = formatStateItemAsBaseOption(
    stateItem,
    dataPath,
    field,
    dataType,
  ) as DataItemValueOption & { value: StateItem };
  const Icon = getIconForDataField({
    name: last((stateItem.path || '').split('.')),
    type: stateItem.dataType,
  } as DataField);
  baseOption.icon = <Icon size={16} />;
  return baseOption;
};

export const getTypeOptionsForField = (
  field: DataField,
  dataType: DataType,
  dataTypes: DataTypes,
  acceptableDataTypes: string[],
  {
    acceptableParentType,
    includeCollections,
    includeUniqueColumnar,
    forceRawPath,
  }: TypeOptionsConfig,
): ((
  scopeItem: StateItem,
  dataPath: DataPathSegment[],
) => (Omit<DataItemValueOption, 'options'> & {
  value: StateItem;
})[]) => {
  if (field.type === OBJECT) {
    return (scopeItem: StateItem, dataPath: DataPathSegment[]) => {
      const parent = new StateItem({
        id: scopeItem.id,
        path: field.name,
        source: DATABASE,
        dataType: field.type,
        display: field.display,
        args: { format: field.typeOptions?.format },
      });

      return getSubFieldsAsDataFields(field, {
        includeRoot: true,
        treeDisplay: true,
      }).map(({ apiName, display, type }) =>
        formatStateItemAsOption(
          new StateItem({
            id: scopeItem.id,
            path: safelyAppendPath(scopeItem.path, `${field.name}.${apiName}`),
            source: scopeItem.source,
            dataType: type,
            display,
            args: {
              ...scopeItem.args,
              parent,
            },
          }),
          dataPath,
          field,
          dataType,
        ),
      );
    };
  } else if (!field.relationship && !field.relatedField) {
    if (
      acceptableDataTypes.includes(field.type) &&
      (!acceptableParentType || dataType.name === acceptableParentType)
    ) {
      return (scopeItem: StateItem, dataPath: DataPathSegment[]) => [
        formatStateItemAsOption(
          new StateItem({
            id: scopeItem.id,
            path: safelyAppendPath(scopeItem.path, field.name),
            source: scopeItem.source,
            dataType: field.type,
            display: field.display,
            args: scopeItem.args,
          }),
          [...dataPath, scopeItem],
          field,
          dataType,
        ),
      ];
    }
  } else if (
    (field.relationship && !isMultiRelationship(field.relationship)) ||
    (field.relatedField &&
      !isReverseMultiRelationship(field.relatedField.relationship))
  ) {
    return (scopeItem: StateItem, dataPath: DataPathSegment[]) => {
      const nextScopeItem = new StateItem({
        id: scopeItem.id,
        path: safelyAppendPath(scopeItem.path, field.name),
        source: scopeItem.source,
        dataType: field.type,
        display: field.display,
        args: scopeItem.args,
      });

      return getTypeOptionsOfTypeFromParent(
        dataTypes,
        nextScopeItem,
        acceptableDataTypes,
        {
          acceptableParentType,
          forceRawPath,
          includeUniqueColumnar,
          includeCollections,
        },
        [...dataPath, scopeItem],
      );
    };
  } else if (isMultiField(field)) {
    let relatedType: DataType | undefined;
    if (includeUniqueColumnar) {
      relatedType = dataTypes.getByName(field.type);
    }

    if (
      !includeCollections &&
      !includeUniqueColumnar &&
      acceptableDataTypes.includes(DECIMAL)
    ) {
      return (scopeItem: StateItem, dataPath: DataPathSegment[]) => [
        formatStateItemAsOption(
          new StateItem({
            id: scopeItem.id,
            path: safelyAppendPath(scopeItem.path, `${field.name}.totalCount`),
            source: scopeItem.source,
            dataType: DECIMAL,
            display: getText(
              { collection: field.display },
              'elements',
              elements.LIST,
              'data.count',
            ),
            args: scopeItem.args,
          }),
          dataPath,
          field,
          dataType,
        ),
      ];
    }

    const optionsGenerator = (
      scopeItem: StateItem,
      dataPath: DataPathSegment[],
    ) => {
      const options = [];

      if (includeCollections && acceptableDataTypes.includes(field.type)) {
        options.push(
          formatStateItemAsOption(
            {
              id: scopeItem.id,
              path: safelyAppendPath(
                scopeItem.path,
                `${field.name}${forceRawPath ? '' : '.edges.node'}`,
              ),
              source: scopeItem.source,
              dataType: field.type,
              display: field.display,
              args: scopeItem.args,
            },
            undefined,
            field,
            dataType,
          ),
        );
      }

      let hasPushedIdField = false;
      if (relatedType && acceptableDataTypes.includes(relatedType.name)) {
        const idField = relatedType.fields.getByName('id');

        if (idField) {
          options.push(
            formatStateItemAsOption(
              getStateItemForRelatedField(scopeItem, field, idField, {
                forceRawPath,
              }),
              dataPath,
              field,
              dataType,
            ),
          );
          hasPushedIdField = true;
        }
      }

      if (includeUniqueColumnar && relatedType) {
        get(relatedType, ['fields'], []).forEach((relatedField: any) => {
          if (relatedField.name === 'id' && hasPushedIdField) {
            return;
          }

          if (acceptableDataTypes.includes(relatedField.type)) {
            options.push(
              formatStateItemAsOption(
                getStateItemForRelatedField(scopeItem, field, relatedField, {
                  forceRawPath,
                }),
                dataPath,
                field,
                dataType,
              ),
            );
          }
        });
      }

      if (acceptableDataTypes.includes(DECIMAL)) {
        options.push(
          formatStateItemAsOption(
            new StateItem({
              id: scopeItem.id,
              path: safelyAppendPath(
                scopeItem.path,
                `${field.name}.totalCount`,
              ),
              source: scopeItem.source,
              dataType: DECIMAL,
              display: getText('elements', elements.LIST, 'data.totalCount'),
              args: scopeItem.args,
            }),
            dataPath,
            field,
            dataType,
          ),
        );
      }

      return options;
    };

    return optionsGenerator;
  }

  return () => [];
};
const defaultFieldFilter = () => true;
type AcceptableDataTypeOptionsGenerator = (
  scopeItem: StateItem,
  dataPath: DataPathSegment[],
) => (Omit<DataItemValueOption, 'options'> & {
  value: StateItem;
})[];
const getAcceptableFieldOptionGeneratorsFromDataType = cappedMemoize(
  (
    dataType: DataType,
    dataTypes: DataTypes,
    acceptableDataTypes: string[] = PRIMITIVE_DATA_TYPES,
    {
      acceptableParentType,
      includeCollections,
      includeUniqueColumnar,
      fieldFilter = defaultFieldFilter,
      forceRawPath,
    }: TypeOptionsConfig = {},
  ): { field: DataField; options: AcceptableDataTypeOptionsGenerator }[] => {
    const options = [];

    if (acceptableDataTypes.includes(dataType.name)) {
      options.push({
        field: dataType.fields.getByName('id'),
        options: (scopeItem: StateItem, dataPath: DataPathSegment[]) => [
          formatStateItemAsOption(
            scopeItem,
            [...dataPath, scopeItem],
            dataType.fields.getByName('id'),
            dataType,
          ),
        ],
      });
    }

    return dataType.fields
      .filter((field: any) => fieldFilter(field, dataType) && !field.hidden)
      .sort(sortFields(dataType))
      .reduce((relevantFields: any, field: DataField) => {
        const fieldOptions = getTypeOptionsForField(
          field,
          dataType,
          dataTypes,
          acceptableDataTypes,
          {
            acceptableParentType,
            includeCollections,
            includeUniqueColumnar,
            forceRawPath,
          },
        );

        relevantFields.push({ field, options: fieldOptions });

        return relevantFields;
      }, options);
  },
  {
    maxKeys: 100,
    getKey: ([dataType, dataTypes, ...rest]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return `${dataType.id}:${dtKey}:${JSON.stringify(rest)}`;
    },
  },
);
const getTypeOptionsOfTypeFromDataType = cappedMemoize(
  (
    dataType: DataType,
    dataTypes: DataTypes,
    scopeItem: StateItem,
    acceptableDataTypes: string[] = PRIMITIVE_DATA_TYPES,
    config: TypeOptionsConfig = {},
    dataPath: DataPathSegment[] = [],
  ) => {
    const fieldOptionGenerators =
      getAcceptableFieldOptionGeneratorsFromDataType(
        dataType,
        dataTypes,
        acceptableDataTypes,
        config,
      );

    return fieldOptionGenerators.reduce(
      (
        acc: DataItemValueOption[],
        {
          field,
          options: optionGenerator,
        }: { field: DataField; options: AcceptableDataTypeOptionsGenerator },
      ) => {
        const getOptions = () => optionGenerator(scopeItem, dataPath);

        if (field.relationship || field.relatedField || field.type === OBJECT) {
          const nextScopeItem = new StateItem({
            ...scopeItem,
            display: field.display,
            dataType: field.type,
            path: safelyAppendPath(scopeItem.path, field.name),
          });

          const optionsWithMultiFieldOptions = maybeAddOptionToList(
            field.display,
            getOptions,
            acc,
            nextScopeItem,
          );

          return optionsWithMultiFieldOptions;
        }

        return [...acc, ...getOptions()];
      },
      [],
    );
  },
  {
    maxKeys: 1000,
    getKey: ([dataType, dataTypes, ...rest]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return `${dataType.id}:${dtKey}:${JSON.stringify(rest)}`;
    },
  },
);
export const getTypeOptionsOfTypeFromParent = cappedMemoize(
  (
    dataTypes: DataTypes,
    scopeItem: StateItem,
    acceptableDataTypes: string[] = PRIMITIVE_DATA_TYPES,
    configOptions: TypeOptionsConfig = {},
    dataPath: DataPathSegment[] = [],
  ) => {
    if (
      !scopeItem ||
      dataPath.length >= MAX_FILTER_DEPTH ||
      scopeItem.source !== DATABASE
    ) {
      return [];
    }

    const dataType = dataTypes.getByName(scopeItem.dataType);

    if (!dataType) {
      return [];
    }

    return getTypeOptionsOfTypeFromDataType(
      dataType,
      dataTypes,
      scopeItem,
      acceptableDataTypes,
      configOptions,
      dataPath,
    );
  },
  {
    maxKeys: 300,
    getKey: ([dataTypes, ...rest]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return `${dtKey}:${JSON.stringify(rest)}`;
    },
  },
);
const maybeAddOptionToList = (
  label: any,
  getOptions: any,
  list: any,
  listItem: any,
) => {
  const depth = listItem.path
    .replace(/\.edges\.node/g, '')
    .replace(/\._columns/g, '')
    .split('.').length;

  return [
    ...list,
    {
      id: listItem.id,
      label,
      icon: <IconSquareRotated size={16} />,
      getOptions: depth >= 2 ? getOptions : undefined,
      options: depth < 2 ? getOptions() : undefined,
      path: listItem.path,
    },
  ];
};
const maybeAddStateItem = (
  stateItem: StateItem,
  dataPath: { display: string }[],
  dataItems: StateItem[],
  field?: DataField,
  dataType?: DataType,
) => {
  const dataItem = formatStateItemAsOption(
    stateItem,
    dataPath,
    field,
    dataType,
  );
  const exists = dataItems.find(
    (item: any) =>
      item.value &&
      item.value.path === dataItem.value.path &&
      item.value.id === dataItem.value.id,
  );

  return exists ? dataItems : [...dataItems, dataItem];
};
export const getCollectionOptionsOfTypeFromParent = (
  dataTypes: any,
  scopeItem: any,
  dataTypeName: any,
  dataPath = [],
) => {
  if (dataPath.length >= MAX_FILTER_DEPTH || scopeItem.source !== DATABASE) {
    return [];
  }

  const dataType = dataTypes.getByName(scopeItem.dataType);
  if (!dataType) {
    return [];
  }

  if (dataPath.length === 0) {
    // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    dataPath.push(scopeItem);
  }

  return dataType.fields.reduce((relevantFields: any, field: any) => {
    if (!field.relatedField && !field.relationship) {
      return relevantFields;
    }

    if (field.relationship) {
      const isMultiRel = isMultiRelationship(field.relationship);
      if (field.type === FILE && (dataTypeName !== FILE || !isMultiRel)) {
        return relevantFields;
      }

      if ((!dataTypeName || field.type === dataTypeName) && isMultiRel) {
        return maybeAddStateItem(
          new StateItem({
            ...scopeItem,
            display: field.display,
            dataType: field.type,
            path: safelyAppendPath(scopeItem.path, field.name),
          }),
          dataPath,
          relevantFields,
          field,
          dataType,
        );
      }

      if (isMultiRelationship(field.relationship)) {
        return relevantFields;
      }

      const nextScopeItem = new StateItem({
        ...scopeItem,
        display: field.display,
        dataType: field.type,
        path: safelyAppendPath(scopeItem.path, field.name),
      });

      const getOptions = () =>
        getCollectionOptionsOfTypeFromParent(
          dataTypes,
          nextScopeItem,
          dataTypeName,
          // @ts-expect-error TS(2322): Type 'StateItem' is not assignable to type 'never'... Remove this comment to see the full error message
          [...dataPath, nextScopeItem],
        );

      return maybeAddOptionToList(
        field.display,
        getOptions,
        relevantFields,
        nextScopeItem,
      );
    }

    if (
      field.relatedField &&
      (!dataTypeName || field.type === dataTypeName) &&
      isReverseMultiRelationship(field.relatedField.relationship)
    ) {
      return maybeAddStateItem(
        new StateItem({
          ...scopeItem,
          display: field.display,
          dataType: field.type,
          path: safelyAppendPath(scopeItem.path, field.name),
        }),
        dataPath,
        relevantFields,
        field,
        dataType,
      );
    }

    if (
      field.type === 'file' ||
      isReverseMultiRelationship(field.relatedField.relationship)
    ) {
      return relevantFields;
    }

    const getOptions = () =>
      getCollectionOptionsOfTypeFromParent(
        dataTypes,
        new StateItem({
          ...scopeItem,
          display: field.display,
          dataType: field.type,
          path: safelyAppendPath(scopeItem.path, field.name),
        }),
        dataTypeName,
        // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
        [...dataPath, scopeItem],
      );

    const nextScopeItem = new StateItem({
      ...scopeItem,
      display: field.display,
      dataType: field.type,
      path: safelyAppendPath(scopeItem.path, field.name),
    });

    return maybeAddOptionToList(
      field.display,
      getOptions,
      relevantFields,
      nextScopeItem,
    );
  }, []);
};
export const getDataCollectionOptionsOfType = (
  project: any,
  elementPath: any,
  dataType: any,
  defaultFilterOptions = [],
  additionalContext = {},
) => {
  const dataTypes = project.dataTypes;
  const context = {
    dataTypes,
    collections: true,
    acceptableDataTypes: [dataType],
    getDataTypeOptions: (scopeItem: any) =>
      getCollectionOptionsOfTypeFromParent(dataTypes, scopeItem, dataType),
    ...additionalContext,
  };

  const options = [
    ...defaultFilterOptions,
    ...buildScope(project, elementPath, false, false, context),
  ];

  if (options.length === 0) {
    return [
      { label: getText('contentEditor.options.noOptions'), disabled: true },
    ];
  }

  return options;
};
const getRelativeDateOptions = cappedMemoize(
  () => {
    const predefinedOptions: {
      label: string;
      options?: DataItemOption[];
    }[] = Object.entries({
      today: ['now', 'start', 'end'],
      week: ['start', 'end'],
      month: ['start', 'end'],
      year: ['start', 'end'],
    }).map(([dateOption, options]) => ({
      label: getText('contentEditor.values', DATE, dateOption, 'current'),
      options: options.map((option) =>
        formatStateItemAsOption(
          new StateItem({
            id: 'values',
            path: `${DATE}.${dateOption}.${option}`,
            source: DERIVED,
            dataType: DATE,
            display: getText('contentEditor.values', DATE, dateOption, option),
          }),
        ),
      ),
    }));
    const customOption = {
      label: getText('contentEditor.values', DATE, 'custom'),
      value: { id: 'values', path: `${DATE}.custom` },
    };
    predefinedOptions.push(customOption);
    return predefinedOptions;
  },
  { maxKeys: 1 },
);
export const getRawValueOptionFromDataTypes = (
  acceptableDataTypes: any,
  { dateHelp }: { dateHelp?: string } = {},
) => {
  const rawValues = [];

  if (acceptableDataTypes.includes(DATE)) {
    rawValues.push({
      label: getText('contentEditor.values', DATE, 'dates'),
      help: dateHelp ?? getText('contentEditor.values', DATE, 'help'),
      options: getRelativeDateOptions(),
    });
  }

  rawValues.push({
    label: getText('contentEditor.values.OTHER.label'),
    help: getText('contentEditor.values.OTHER.help'),
    options: [
      formatStateItemAsOption(
        new StateItem({
          id: 'values',
          path: 'OTHER.empty',
          source: DERIVED,
          dataType: INTEGER,
          display: getText('contentEditor.values.OTHER.empty.label'),
        }),
      ),
    ],
  });

  return rawValues;
};
export const getDataTypeOptionsOfType = (
  project: any,
  elementPath: any,
  dataType = PRIMITIVE_DATA_TYPES,
  {
    includeSelf,
    includeUniqueColumnar,
    acceptableParentType,
    onlyIncludeSelf,
  }: any = {},
) =>
  getDataTypeOptionsOfTypes(project, elementPath, expandDataTypes(dataType), {
    includeSelf,
    acceptableParentType,
    includeUniqueColumnar,
    onlyIncludeSelf,
  });
export const getAuthUserTypeOptionsOfTypes = (
  project: any,
  acceptableDataTypes = PRIMITIVE_DATA_TYPES,
  {
    acceptableParentType,
    includeCollections,
    includeUniqueColumnar = false,
  }: any = {},
) => {
  return getTypeOptionsOfTypeFromParent(
    project.dataTypes,
    new StateItem({
      dataType: 'user',
      id: AUTH_WRAPPER_ID,
      path: '',
      source: DATABASE,
      display: getText('elements.PAGE.state.user'),
    }),
    acceptableDataTypes,
    { includeCollections, acceptableParentType, includeUniqueColumnar },
  );
};
export const getDataTypeOptionsOfTypes = cappedMemoize(
  (
    project: Project,
    elementPath: ElementPath,
    acceptableDataTypes = PRIMITIVE_DATA_TYPES,
    {
      acceptableParentType,
      includeCollections,
      includeSelf,
      onlyIncludeSelf = false,
      includeUniqueColumnar = false,
      flatRawValuesOnly = false,
      fieldFilter = defaultFieldFilter,
      forceRawPath,
    }: any = {},
  ) => {
    const dataTypes = project.dataTypes;
    const context = {
      dataTypes,
      getDataTypeOptions: (scopeItem: any) =>
        getTypeOptionsOfTypeFromParent(
          dataTypes,
          scopeItem,
          acceptableDataTypes,
          {
            includeCollections,
            acceptableParentType,
            includeUniqueColumnar,
            fieldFilter,
            forceRawPath,
          },
        ),
      acceptableDataTypes,
      includeCollections,
    };
    const scope = buildScope(
      project,
      elementPath,
      includeSelf || onlyIncludeSelf,
      onlyIncludeSelf,
      context,
    );

    if (onlyIncludeSelf || flatRawValuesOnly) {
      if (scope) {
        if (scope.options) {
          return scope.options;
        }

        return Array.isArray(scope) ? scope : [scope];
      }

      return [];
    }

    const optionGroups = [];

    if (scope.length > 0) {
      optionGroups.push(...scope);
    }

    const rawValues = getRawValueOptionFromDataTypes(acceptableDataTypes);

    if (rawValues.length > 0) {
      optionGroups.push(...rawValues);
    }

    return optionGroups;
  },
  {
    maxKeys: 350,
    getKey: ([project, elementPath, ...rest]) => {
      const dtKey = getDataTypesKey(project.dataTypes);
      return JSON.stringify({ dtKey, elementPath, ...rest });
    },
  },
);
export const getOptionScopeItemsForOptionField = (
  field: DataField,
  dataType: DataType,
): DataItemOption & { options: DataItemOption[] } => ({
  label: field.display,
  heading: true,
  options: (field.options ?? [])
    .filter((option: any) => option.display)
    .map((option: any) =>
      formatStateItemAsOption(
        new StateItem({
          id: SINGLE_OPTION,
          path: `${dataType.name}.${field.name}.${option.name}.name`,
          source: DERIVED,
          dataType: SINGLE_OPTION,
          display: option.display,
        }),
        [],
        field,
        dataType,
      ),
    ),
});
