export const AUTO = 'AUTO';
export const DAY = 'day';
export const WEEK = 'week';
export const MONTH = 'month';
export const QUARTER = 'quarter';
export const YEAR = 'year';

const timePeriods: TimePeriod[] = [AUTO, DAY, WEEK, MONTH, QUARTER, YEAR];

export type TimePeriod =
  | typeof AUTO
  | typeof DAY
  | typeof WEEK
  | typeof MONTH
  | typeof QUARTER
  | typeof YEAR;

export default timePeriods;
