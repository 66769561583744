import createReactComponent from '../createReactComponent';
export default createReactComponent('discount-off', 'IconDiscountOff', [
  ['path', { d: 'M9 15l3 -3m2 -2l1 -1', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9.148 9.145a.498 .498 0 0 0 .352 .855a.5 .5 0 0 0 .35 -.142',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M14.148 14.145a.498 .498 0 0 0 .352 .855a.5 .5 0 0 0 .35 -.142',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M5.641 5.631a9 9 0 1 0 12.719 12.738m1.68 -2.318a9 9 0 0 0 -12.074 -12.098',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
