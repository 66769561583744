import createReactComponent from '../createReactComponent';
export default createReactComponent('zoom-out-area', 'IconZoomOutArea', [
  ['path', { d: 'M13 15h4', key: 'svg-0' }],
  ['path', { d: 'M15 15m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0', key: 'svg-1' }],
  ['path', { d: 'M22 22l-3 -3', key: 'svg-2' }],
  ['path', { d: 'M6 18h-1a2 2 0 0 1 -2 -2v-1', key: 'svg-3' }],
  ['path', { d: 'M3 11v-1', key: 'svg-4' }],
  ['path', { d: 'M3 6v-1a2 2 0 0 1 2 -2h1', key: 'svg-5' }],
  ['path', { d: 'M10 3h1', key: 'svg-6' }],
  ['path', { d: 'M15 3h1a2 2 0 0 1 2 2v1', key: 'svg-7' }],
]);
