import get from 'lodash/get';
import { BASE_URI, GOOGLE_CLIENT_ID } from '../constants/auth';
import { GOOGLE_SHEETS } from '../constants/dataSources';
import {
  GOOGLE_SHEETS_SYNCING_SCOPES,
  GenericOAuthIntegration,
  OAuthCallbackState,
} from '../constants/oauth';
import { Project } from '../models/Project';
import { User } from '../models/User';

const buildOAuthUri = (integration: GenericOAuthIntegration) =>
  `${BASE_URI}/integrations/${integration}/oauth`;

export const buildConnectUri = (integration: GenericOAuthIntegration) =>
  `${buildOAuthUri(integration)}/connect`;

const buildRedirectUri = (integration: GenericOAuthIntegration) =>
  `${buildOAuthUri(integration)}/oauthcallback`;

export const buildGoogleDriveSearchParams = (
  project: Project,
  user: User,
  inOnboarding?: boolean,
  sourceName?: string,
): URLSearchParams => {
  return new URLSearchParams({
    client_id: GOOGLE_CLIENT_ID,
    redirect_uri: buildRedirectUri('google-drive'),
    approval_prompt: 'force',
    response_type: 'code',
    nonce: String(Math.random()),
    scope: GOOGLE_SHEETS_SYNCING_SCOPES.join(' '),
    access_type: 'offline',
    include_granted_scopes: 'true',
    state: serializeState({
      redirectUrl: inOnboarding
        ? `https://${document.location.hostname}/new?${new URLSearchParams({
            dataSource: GOOGLE_SHEETS,
            onboarding: 'false',
            project: project.name,
            step: 'CONNECTION_DETAILS',
            ...(sourceName ? { sourceName } : {}),
          })}`
        : undefined,
      project: project.name,
      userEmail: get(user, 'email'),
    }),
  });
};

export const buildGoogleDriveAuthorizationUrl = (
  project: Project,
  user: User,
  redirectPath?: string,
  inOnboarding?: boolean,
  sourceName?: string,
): string =>
  `https://accounts.google.com/o/oauth2/v2/auth?${buildGoogleDriveSearchParams(
    project,
    user,
    inOnboarding,
    sourceName,
  )}`;

export const deserializeState = (serializedState: string): OAuthCallbackState =>
  serializedState
    .split(',')
    .map((stateItem: string) => stateItem.split('='))
    .reduce((state: OAuthCallbackState, stateItem: string[]) => {
      if (stateItem.length >= 2 && stateItem.slice(1).every(Boolean)) {
        return {
          ...state,
          [stateItem[0]]: stateItem.slice(1).join('='),
        };
      }

      return state;
    }, {});

export const serializeState = (state: OAuthCallbackState): string =>
  Object.entries(state)
    .filter(([__, value]) => ![undefined, ''].includes(value))
    .map(([key, value]: [string, string]) => `${key}=${value}`)
    .join(',');
