import createReactComponent from '../createReactComponent';
export default createReactComponent('file-function', 'IconFileFunction', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M10.5 17h.333c.474 0 .87 -.323 .916 -.746l.502 -4.508c.047 -.423 .443 -.746 .916 -.746h.333',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M10.5 14h3', key: 'svg-3' }],
]);
