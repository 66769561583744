import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'currency-renminbi',
  'IconCurrencyRenminbi',
  [
    ['path', { d: 'M15 9v8a2 2 0 1 0 4 0', key: 'svg-0' }],
    ['path', { d: 'M19 9h-14', key: 'svg-1' }],
    ['path', { d: 'M19 5h-14', key: 'svg-2' }],
    ['path', { d: 'M9 9v4c0 2.5 -.667 4 -2 6', key: 'svg-3' }],
  ],
);
