import React from 'react';
import FormField from '../form/FormField';
import LoginSubmit from './LoginSubmit';
import SplitLayout from './SplitLayout';

const SplitLogin = ({
  children,
  errors,
  logoUrl,
  emailLabel,
  onSubmit,
  buttonText,
  forgotText,
  passwordLabel,
  registerText,
  rememberLabel,
  titleText,
  email,
  password,
  rememberMe,
  setEmail,
  setPassword,
  setRememberMe,
  splitImageUrl,
  splitChildren,
  surface,
}: any) => {
  return (
    <SplitLayout
      errors={errors}
      logoUrl={logoUrl}
      onSubmit={onSubmit}
      subTitleText={registerText}
      titleText={titleText}
      splitChildren={splitChildren}
      splitImageUrl={splitImageUrl}
    >
      <>
        <input type="hidden" name="remember" value="true" />
        <FormField
          aria-label="Email address"
          autoComplete="email"
          name="email"
          type="email"
          onChange={({ target: { value } }: any) => setEmail(value)}
          required
          label={emailLabel}
          placeholder=""
          surface={surface}
          value={email}
        />
        <FormField
          aria-label="Password"
          autoComplete="current-email"
          className="mt-3"
          name="password"
          type="password"
          onChange={({ target: { value } }: any) => setPassword(value)}
          required
          label={passwordLabel}
          placeholder=""
          surface={surface}
          value={password}
        />
        <LoginSubmit
          buttonText={buttonText}
          forgotText={forgotText}
          rememberMe={rememberMe}
          rememberLabel={rememberLabel}
          setRememberMe={setRememberMe}
        />
        {children}
      </>
    </SplitLayout>
  );
};

export default SplitLogin;
