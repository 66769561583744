import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { Modal } from '@noloco/components';
import { LG } from '@noloco/components/src/constants/tShirtSizes';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import {
  projectIntegrationsSelector,
  projectNameSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import useLocalStorageState from '@noloco/core/src/utils/hooks/useLocalStorageState';
import { useRefetchIntegrations } from '@noloco/core/src/utils/hooks/useRefetchIntegrations';
import { getText } from '@noloco/core/src/utils/lang';
import { buildConnectUri } from '@noloco/core/src/utils/oauth';
import smartSuiteIcon from '../img/smartsuite-logo.png';
import { openAuthorizationWindow } from '../utils/oauth';

const LANG_KEY =
  'settings.integrations.integrations.smart-suite.connectionDetails';
const SmartSuiteOAuthAlert = () => {
  const { isBuilder } = useIsBuilder();

  const projectName = useSelector(projectNameSelector);
  const integrations = useSelector(projectIntegrationsSelector);
  const hasLegacySmartSuiteOAuthIntegration = useMemo(
    () => integrations?.smartSuite?.domain === 'https://auth.smartsuite.com/',
    [integrations],
  );

  const [remindAfter, setRemindAfter] = useLocalStorageState<number>(
    'noloco:smartSuiteAuth:reminder',
    0,
  );

  const [submitted, setSubmitted] = useState(false);
  const isOpen = useMemo(
    () =>
      isBuilder &&
      hasLegacySmartSuiteOAuthIntegration &&
      !submitted &&
      remindAfter < Date.now(),
    [hasLegacySmartSuiteOAuthIntegration, isBuilder, remindAfter, submitted],
  );

  const { token } = useAuth();
  const refetchIntegrations = useRefetchIntegrations(projectName);
  const onConnectSmartSuiteAccount = useCallback(() => {
    openAuthorizationWindow(
      buildConnectUri('smart-suite'),
      new URLSearchParams({
        project: projectName,
        reconnect: 'true',
        token: token ?? '',
      }),
      { onClose: refetchIntegrations },
    );
    setSubmitted(true);
  }, [projectName, refetchIntegrations, token]);

  const onCancel = useCallback(() => {
    setRemindAfter(DateTime.now().plus({ days: 1 }).toMillis());
  }, [setRemindAfter]);

  return (
    <Modal
      cancelText={getText(LANG_KEY, 'reconfigure.remindMeLater')}
      confirmText={
        <div className="flex">
          <img
            src={smartSuiteIcon}
            alt="SmartSuite logo"
            className="mr-3 h-8 w-auto"
          />
          <span className="my-auto">
            {getText(LANG_KEY, 'reconfigure.button')}
          </span>
        </div>
      }
      onCancel={onCancel}
      onConfirm={onConnectSmartSuiteAccount}
      open={isOpen}
      size={LG}
      title={getText(LANG_KEY, 'reconfigure.title')}
      variant={SECONDARY}
    >
      <div className="w-full">
        <p className={classNames('mr-auto text-justify text-base')}>
          {getText(LANG_KEY, 'reconfigure.description')}
        </p>
        <p className="mt-6 text-base">
          {getText(LANG_KEY, 'reconfigure.ctaDescription')}
        </p>
      </div>
    </Modal>
  );
};

export default SmartSuiteOAuthAlert;
