import React from 'react';
import SEOSettings from '@noloco/core/src/elements/SEOSettings';
import { MediaFile } from '@noloco/core/src/models/File';
import { ProjectSettings } from '@noloco/core/src/models/Project';
import { getMediaImageSrc } from '@noloco/core/src/utils/image';

const Head = ({
  settings,
  media,
}: {
  settings: ProjectSettings;
  media: MediaFile[];
}) => {
  const {
    title,
    description,
    socialBanner = {},
    socialMediaDesc,
  } = settings || {};

  const socialBannerSrc =
    socialBanner.mediaId && getMediaImageSrc(socialBanner.mediaId, media);

  return (
    <SEOSettings
      project={{ settings }}
      title={title}
      description={description}
      openGraphTitle={title}
      openGraphDescription={socialMediaDesc}
      openGraphImage={socialBannerSrc ? { src: socialBannerSrc } : undefined}
    />
  );
};

export default Head;
