import createReactComponent from '../createReactComponent';
export default createReactComponent('keyframes', 'IconKeyframes', [
  [
    'path',
    {
      d: 'M9.225 18.412a1.595 1.595 0 0 1 -1.225 .588c-.468 0 -.914 -.214 -1.225 -.588l-4.361 -5.248a1.844 1.844 0 0 1 0 -2.328l4.361 -5.248a1.595 1.595 0 0 1 1.225 -.588c.468 0 .914 .214 1.225 .588l4.361 5.248a1.844 1.844 0 0 1 0 2.328l-4.361 5.248z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 5l4.586 5.836a1.844 1.844 0 0 1 0 2.328l-4.586 5.836',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M13 5l4.586 5.836a1.844 1.844 0 0 1 0 2.328l-4.586 5.836',
      key: 'svg-2',
    },
  ],
]);
