import { useCallback } from 'react';
import get from 'lodash/get';
import { useErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import { getText } from '@noloco/core/src/utils/lang';
import { GET_DATA_SOURCE } from '../../queries/project';

const LANG_KEY = 'data.dataSources';

export const useCheckDataSourceSyncStatus = (
  projectName: any,
  onComplete: any,
  onTimeout: any,
  pollInterval = 750,
  maxPollsMs = 60_000,
) => {
  const errorAlert = useErrorAlert();

  const [checkDataSource] = usePromiseQuery(GET_DATA_SOURCE, {
    fetchPolicy: 'no-cache',
  });

  const checkSyncStatus = useCallback(
    (dataSource, start = Date.now()) => {
      checkDataSource({
        variables: {
          projectId: projectName,
          id: dataSource.id,
        },
      })
        .then((result: any) => {
          const hasSynced = get(
            result,
            ['data', 'dataSource', 'hasSynced'],
            false,
          );
          if (hasSynced) {
            return onComplete(projectName, dataSource);
          }

          const pollDuration = Date.now() - start;
          if (pollDuration > maxPollsMs) {
            return onTimeout(projectName, dataSource);
          }

          setTimeout(() => {
            checkSyncStatus(dataSource, start);
          }, pollInterval);
        })
        .catch((e: any) => {
          errorAlert(getText(LANG_KEY, 'error'), e);
        });
    },
    [
      checkDataSource,
      errorAlert,
      maxPollsMs,
      onComplete,
      onTimeout,
      pollInterval,
      projectName,
    ],
  );

  return {
    checkSyncStatus,
  };
};
