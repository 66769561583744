import createReactComponent from '../createReactComponent';
export default createReactComponent('macro', 'IconMacro', [
  ['path', { d: 'M6 15a6 6 0 1 0 12 0', key: 'svg-0' }],
  ['path', { d: 'M18 15a6 6 0 0 0 -6 6', key: 'svg-1' }],
  ['path', { d: 'M12 21a6 6 0 0 0 -6 -6', key: 'svg-2' }],
  ['path', { d: 'M12 21v-10', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M12 11a5 5 0 0 1 -5 -5v-3l3 2l2 -2l2 2l3 -2v3a5 5 0 0 1 -5 5z',
      key: 'svg-4',
    },
  ],
]);
