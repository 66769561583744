import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Portal, Transition } from '@headlessui/react';
import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconEdit,
} from '@tabler/icons-react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { ShirtSize } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeIcon from '@noloco/core/src/components/buildMode/BuildModeIcon';
import { ESCAPE } from '@noloco/core/src/constants/shortcuts';
import KeyboardShortcutTooltip from '@noloco/core/src/elements/sections/view/KeyboardShortcutTooltip';
import useOnKeyPress from '@noloco/core/src/utils/hooks/useOnKeyPress';
import { getText } from '@noloco/core/src/utils/lang';

type PopoutMenuSource = 'buildMode';

export type PopoutMenuProps = {
  children: React.ReactNode;
  className?: string;
  disableTransition?: boolean;
  footer?: React.ReactNode;
  header?: JSX.Element;
  Icon?: (props: any) => JSX.Element;
  onClose?: (...args: any[]) => any;
  rootSelector?: string;
  size?: ShirtSize;
  source?: PopoutMenuSource;
  title: React.ReactNode;
  usePortal?: boolean;
  forceCloseOnScape?: boolean;
};

type Props = PopoutMenuProps & {
  side: 'left' | 'right';
};

const TRANSITION_CONFIG = {
  left: {
    enterFrom: '-translate-x-full',
    leaveTo: '-translate-x-full',
  },
  right: {
    enterFrom: 'translate-x-full',
    leaveTo: 'translate-x-full',
  },
};

const PopoutMenu = ({
  children,
  className,
  disableTransition,
  footer,
  header,
  Icon,
  onClose,
  rootSelector,
  side,
  size,
  source,
  title,
  usePortal,
  forceCloseOnScape = true,
  ...rest
}: Props) => {
  const [show, setShow] = useState<boolean | null>(true);
  const showUpdatedUI = useMemo(() => source === 'buildMode', [source]);

  const { enterFrom, leaveTo } = useMemo(
    () => TRANSITION_CONFIG[side ?? 'left'],
    [side],
  );

  useEffect(() => {
    if (show === null) {
      setShow(true);
    }
  }, [show]);

  const TitleIcon = useMemo(() => Icon ?? IconEdit, [Icon]);

  const handleClose = useCallback(
    (e) => {
      if (forceCloseOnScape) {
        setShow(false);
      }
      setTimeout(() => {
        onClose!(e);
      }, 250);
    },
    [onClose, forceCloseOnScape],
  );

  const CloseButton = useCallback(
    () => (
      <KeyboardShortcutTooltip
        keys={[ESCAPE]}
        label={getText('rightSidebar.close')}
        offset={[0, 8]}
        placement="bottom"
      >
        {showUpdatedUI ? (
          <BuildModeIcon
            Icon={side === 'right' ? IconArrowNarrowRight : IconArrowNarrowLeft}
            onClick={handleClose}
          />
        ) : (
          <button
            className={classNames(
              'h-full flex-shrink-0 p-3 text-gray-500 hover:bg-slate-700 hover:text-white',
              {
                'mr-auto': side !== 'right',
                'ml-auto': side === 'right',
              },
            )}
            onClick={handleClose}
          >
            {side !== 'right' && <IconArrowNarrowLeft size={18} />}
            {side === 'right' && <IconArrowNarrowRight size={18} />}
          </button>
        )}
      </KeyboardShortcutTooltip>
    ),
    [handleClose, side, showUpdatedUI],
  );

  useOnKeyPress(ESCAPE, handleClose, {
    enabled: !!show,
  });

  const sidebar = (
    <Transition
      show={!!show}
      enter="ease-out duration-300"
      enterFrom={disableTransition ? undefined : enterFrom}
      enterTo="translate-x-0"
      leave="ease-in duration-300"
      leaveFrom="translate-x-0"
      leaveTo={leaveTo}
      className={classNames(
        className,
        'max-w-84 absolute inset-y-0 z-40 flex w-full flex-grow transform flex-col overflow-hidden bg-slate-800 transition',
        {
          'left-0': side !== 'right',
          'right-0': side === 'right',
          [`max-w-${size}`]: size,
        },
      )}
      {...rest}
    >
      <div
        className={classNames('flex border-gray-700', {
          'border-l': side !== 'right',
          'border-r': side === 'right',
        })}
      >
        {showUpdatedUI ? (
          <>
            {header ? (
              <div className="flex h-12 w-full items-center justify-between border-b border-slate-700 px-2">
                <div className="flex w-full items-center">{header}</div>
                <CloseButton />
              </div>
            ) : (
              <div className="flex h-12 w-full items-center justify-between space-x-2 border-b border-slate-700 pl-2 text-xs text-slate-300">
                {side !== 'right' && <CloseButton />}
                <div className="flex items-center space-x-2 text-white">
                  <TitleIcon className="h-5 w-5" />
                  <span>{title}</span>
                </div>
                {side === 'right' && <CloseButton />}
              </div>
            )}
          </>
        ) : (
          <>
            {side !== 'right' && <CloseButton />}
            <div className="flex w-full items-center justify-center bg-slate-800 px-3 py-3 text-center text-xs text-white">
              <TitleIcon size={14} className="mr-2" />
              <span>{title}</span>
            </div>
            {side === 'right' && <CloseButton />}
          </>
        )}
      </div>
      <div className="flex-grow overflow-auto text-sm">{children}</div>
      {footer && (
        <div className="flex justify-end bg-slate-700 p-3">{footer}</div>
      )}
    </Transition>
  );

  const root =
    typeof document !== 'undefined' &&
    document.querySelector(rootSelector ?? '#root');

  return usePortal && root ? (
    <Portal>{ReactDOM.createPortal(sidebar, root)}</Portal>
  ) : (
    sidebar
  );
};

export default PopoutMenu;
