export type env = 'production' | 'qa' | 'development';

const envVar =
  process.env.REACT_APP_ENVIRONMENT !== undefined
    ? process.env.REACT_APP_ENVIRONMENT
    : process.env.ENVIRONMENT;

export const IS_PRODUCTION = envVar === 'production';
export const IS_QA = envVar === 'qa';
export const IS_SSR = process.env.SSR === 'true';

export const IS_WEBSITE_EXAMPLES =
  process.env.REACT_APP_WEBSITE_EXAMPLES === 'true';

export const VAPID_PUBLIC_KEY =
  process.env.REACT_APP_VAPID_PUBLIC_KEY ??
  'BMLHZDoGKeylywLdvj3hEKiAW_VJ4A3n5OMHkjFNbNY1LE_unWpiDClD2MTi3R4W7K5T-90PQLuMitmQ8iNbSXs';
