import createReactComponent from '../createReactComponent';
export default createReactComponent('tooltip', 'IconTooltip', [
  ['path', { d: 'M12 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 13l-1.707 -1.707a1 1 0 0 0 -.707 -.293h-2.586a2 2 0 0 1 -2 -2v-3a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-2.586a1 1 0 0 0 -.707 .293l-1.707 1.707z',
      key: 'svg-1',
    },
  ],
]);
