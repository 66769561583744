import createReactComponent from '../createReactComponent';
export default createReactComponent('feather', 'IconFeather', [
  [
    'path',
    {
      d: 'M4 20l10 -10m0 -5v5h5m-9 -1v5h5m-9 -1v5h5m-5 -5l4 -4l4 -4',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M19 10c.638 -.636 1 -1.515 1 -2.486a3.515 3.515 0 0 0 -3.517 -3.514c-.97 0 -1.847 .367 -2.483 1m-3 13l4 -4l4 -4',
      key: 'svg-1',
    },
  ],
]);
