import get from 'lodash/get';
import { DataField } from '../models/DataTypeFields';
import { BaseRecord } from '../models/Record';

const getFormFieldRecordId = (
  record: BaseRecord,
  selectedField: DataField,
): string | undefined | null => {
  if (!selectedField || !record) {
    return null;
  }

  const fieldKey =
    selectedField.relationship || selectedField.relatedField
      ? `${selectedField.name}.id`
      : selectedField.name;

  return get(record, fieldKey) as string;
};

export default getFormFieldRecordId;
