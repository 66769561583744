import createReactComponent from '../createReactComponent';
export default createReactComponent('camera-dollar', 'IconCameraDollar', [
  [
    'path',
    {
      d: 'M13 20h-8a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v1.5',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M14.935 12.375a3.001 3.001 0 1 0 -1.902 3.442', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-3' }],
]);
