import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import { useSelector } from 'react-redux';
import { CollectionLayout } from '../../constants/collectionLayouts';
import { DATE, NUMERIC_DATATYPES } from '../../constants/dataTypes';
import { DATE as DATE_FORMAT } from '../../constants/fieldFormats';
import { UPDATE } from '../../constants/workflowTriggerTypes';
import { Group } from '../../elements/sections/Collection';
import { DataField } from '../../models/DataTypeFields';
import { DataType } from '../../models/DataTypes';
import { BaseRecord, RecordEdge, RecordValue } from '../../models/Record';
import {
  CollectionField,
  DataListFilter,
  GroupByWithField,
} from '../../models/View';
import { QueryObject } from '../../queries/data';
import { getMutationQueryString } from '../../queries/project';
import { projectDataSelector } from '../../selectors/projectSelectors';
import { getDateFromValue } from '../dates';
import { FieldConfig, fieldPermissions } from '../permissions';
import { cleanEdgesNodeFromDepPath } from '../queries';
import useCollectionGroups from './useCollectionGroups';
import useScopeUser from './useScopeUser';

export type DraggableCollectionGroupsConfig = {
  customFilters: DataListFilter[];
  dataType: DataType;
  edges: RecordEdge[];
  elementId: string;
  enableDragAndDropEdit: boolean;
  fields: FieldConfig<CollectionField>[];
  groupByFields: GroupByWithField[];
  groupOptions: Record<string, { collapsed?: boolean; hidden?: boolean }>;
  hideEmptyGroups: boolean;
  layout: CollectionLayout;
  limitPerGroup?: number;
  nodeQueryObject: QueryObject;
};

const formatGroupValue = (
  value: string | null,
  field: DataField,
): RecordValue => {
  if (value === null) {
    return null;
  }

  if (field.type === DATE) {
    const date = getDateFromValue(value);
    if (date?.isValid) {
      return date.toISO();
    }

    return null;
  }

  if (NUMERIC_DATATYPES.includes(field.type)) {
    return Number(value);
  }

  return value;
};

type DraggableCollectionGroupsResult = {
  canUpdateViaDrag: boolean;
  firstSummaryIndex: number;
  groupCollapsedStates: Record<string, boolean>;
  groupOpenStates: Record<string, boolean>;
  handleCardDrop: (droppedRecord: BaseRecord, droppedGroup: Group) => void;
  isLimited: boolean;
  numFields: number;
  toggleGroupCollapsedState: (groupKey: string) => () => void;
  toggleGroupState: (groupKey: string) => () => void;
  visibleGroups: Group[];
};

const useDraggableCollectionGroups = ({
  customFilters,
  dataType,
  edges,
  elementId,
  enableDragAndDropEdit,
  fields,
  groupByFields,
  groupOptions,
  hideEmptyGroups,
  layout,
  limitPerGroup,
  nodeQueryObject,
}: DraggableCollectionGroupsConfig): DraggableCollectionGroupsResult => {
  const project = useSelector(projectDataSelector);
  const user = useScopeUser();
  const [draftEdges, setDraftEdges] = useState(edges);

  useEffect(() => setDraftEdges(edges), [customFilters, edges]);

  const {
    firstSummaryIndex,
    groupCollapsedStates,
    groupConfigs,
    groupOpenStates,
    groupsById,
    isLimited,
    numFields,
    toggleGroupCollapsedState,
    toggleGroupState,
    visibleGroups,
  } = useCollectionGroups({
    dataType,
    edges,
    elementId,
    fields,
    groupByFields,
    groupOptions,
    hideEmptyGroups,
    layout,
    limitPerGroup,
  });

  const groupPermissions = useMemo(
    () =>
      groupByFields.map((group) =>
        dataType.fields.getById(group.dataField?.field.id)
          ? fieldPermissions(
              group.dataField?.field,
              dataType.permissionsEnabled,
              dataType.permissions,
              user,
            )
          : { read: true, update: true },
      ),
    [
      dataType.fields,
      dataType.permissions,
      dataType.permissionsEnabled,
      groupByFields,
      user,
    ],
  );

  const canUpdateViaDrag = useMemo(
    () =>
      enableDragAndDropEdit &&
      groupConfigs.every(
        (groupConfig, index) =>
          !!groupConfig.field &&
          groupPermissions[index].update &&
          (groupConfig.field.type !== DATE ||
            get(groupConfig.field, ['typeOptions', 'format']) ===
              DATE_FORMAT) &&
          cleanEdgesNodeFromDepPath(groupByFields[index].field.path).split('.')
            .length === 1,
      ),
    [enableDragAndDropEdit, groupByFields, groupConfigs, groupPermissions],
  );

  const updateQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(
        UPDATE,
        dataType.name,
        dataType.fields,
        nodeQueryObject || {
          id: true,
          uuid: true,
        },
      )}
    `,
    [dataType.name, dataType.fields, nodeQueryObject],
  );

  const queryOptionsObj = useMemo(
    () => ({
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [project.name],
  );

  const [updateDataItem] = useMutation(updateQueryString, queryOptionsObj);
  const throttledUpdateDataItem = useMemo(
    () => throttle((args) => updateDataItem(args), 500),
    [updateDataItem],
  );

  const handleCardDrop = useCallback(
    (draggedRecord: BaseRecord, droppedGroup: Group) => {
      const currentDraftEdges = draftEdges;

      const recordIndex = currentDraftEdges.findIndex(
        (edge: any) => edge.node.id === draggedRecord.id,
      );

      const updateGroups = groupsById[droppedGroup.id];

      if (!updateGroups) {
        return currentDraftEdges;
      }

      const groupKeys = updateGroups.map((group, index) => {
        const {
          dataField: { field },
        } = groupByFields[index];

        const value = formatGroupValue(group.key, field);
        const fieldKeyPath = field.relationship
          ? [field.name, 'id']
          : [field.name];

        const fieldKey: string = field.relationship
          ? `${field.name}Id`
          : field.name;
        return { value, fieldKeyPath, fieldKey };
      }, {});

      const update = groupKeys.reduce(
        (updateAcc, { fieldKey, value }) => {
          updateAcc[fieldKey] = value;
          return updateAcc;
        },
        {} as Record<string, RecordValue>,
      );

      throttledUpdateDataItem({
        variables: {
          id: draggedRecord.id,
          ...update,
        },
      });

      setDraftEdges(
        groupKeys.reduce(
          (updatedEdges, { fieldKeyPath, value }) =>
            set([recordIndex, 'node', ...fieldKeyPath], value, updatedEdges),
          currentDraftEdges,
        ) as RecordEdge[],
      );
    },
    [draftEdges, groupByFields, groupsById, throttledUpdateDataItem],
  );

  return {
    canUpdateViaDrag,
    firstSummaryIndex,
    groupCollapsedStates,
    groupOpenStates,
    handleCardDrop,
    isLimited,
    numFields,
    toggleGroupCollapsedState,
    toggleGroupState,
    visibleGroups,
  };
};

export default useDraggableCollectionGroups;
