import React from 'react';
import MarkdownText from '../../../components/MarkdownText';
import { MARKDOWN } from '../../../constants/elements';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';

const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce semper eget tellus in ornare.';

const RecordMarkdown = ({
  elementPath,
  project,
  recordScope,
  ...rest
}: any) => {
  const { content } = useSectionScopeVariables(
    MARKDOWN,
    rest,
    project,
    elementPath,
    recordScope,
  );

  return <MarkdownText>{content || LOREM_IPSUM}</MarkdownText>;
};

RecordMarkdown.defaultProps = {};

export default RecordMarkdown;
