import createReactComponent from '../createReactComponent';
export default createReactComponent('square-letter-k', 'IconSquareLetterK', [
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 8v8', key: 'svg-1' }],
  ['path', { d: 'M14 8l-2.5 4l2.5 4', key: 'svg-2' }],
  ['path', { d: 'M10 12h1.5', key: 'svg-3' }],
]);
