import React, { forwardRef, useCallback, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { CountryCode, PhoneNumber, Surface } from '../../models';
import {
  failedPasswordChecks,
  isEmailValid,
  isPhoneNumberValid,
  validationChecks,
} from '../../utils';
import {
  getAreaCodeFromCountry,
  getCountryFromTimeZone,
} from '../input/PhoneNumberInput';
import CardRegister from './CardRegister';
import SimpleRegister from './SimpleRegister';
import SplitRegister from './SplitRegister';
import { AuthLayoutType, CARD, SIMPLE, SPLIT } from './authLayoutTypes';
import passwordValidationTypes from './passwordValidationTypes';

const registerLayoutTypeMap = {
  [SIMPLE]: SimpleRegister,
  [CARD]: CardRegister,
  [SPLIT]: SplitRegister,
};

const isFormValid = (
  email: string,
  hideEmail: boolean,
  password: string,
  confirmPassword: string,
  phoneNumber: PhoneNumber,
  showPhoneNumber: boolean,
) =>
  (hideEmail || isEmailValid(email)) &&
  failedPasswordChecks(password).length === 0 &&
  password === confirmPassword &&
  (!showPhoneNumber || !phoneNumber.number || isPhoneNumberValid(phoneNumber));

const getDefaultErrorMessage = (errorTexts: any, value: any) => {
  if (!errorTexts) {
    return null;
  }

  if (!value || value.length === 0) {
    return errorTexts.empty;
  }
};

const getSimpleErrorMessage = (errorTexts: any, fieldName: any, value: any) =>
  getDefaultErrorMessage(errorTexts, value) || errorTexts[fieldName].invalid;

const getPasswordErrorMessage = (errorTexts: any, password: any) => {
  const errorType = passwordValidationTypes.find(
    (checkName) => !validationChecks[checkName](password),
  );

  if (errorType && errorTexts.password[errorType]) {
    return errorTexts.password[errorType] || errorTexts.password.invalid;
  }

  return null;
};

type RegisterProps = {
  buttonText: string | React.ReactNode;
  confirmPasswordLabel?: string | React.ReactNode;
  disabled?: boolean;
  emailLabel: string | React.ReactNode;
  errors?: (string | React.ReactNode)[];
  errorTexts?: {
    empty: string;
    email: {
      invalid: string;
    };
    password: {
      invalid: string;
      tooShort: string;
      tooLong: string;
      numbers: string;
      case: string;
      symbol: string;
    };
    confirmPassword: {
      invalid: string;
    };
  };
  footer?: React.ReactNode;
  hideEmail?: boolean;
  initialEmail?: string;
  loginText: string | React.ReactNode;
  logoUrl: string;
  onClick?: (...args: any[]) => any;
  onSubmit?: (...args: any[]) => any;
  passwordLabel: string | React.ReactNode;
  phoneNumberLabel: string | React.ReactNode;
  registerText?: string | React.ReactNode;
  rememberLabel: string | React.ReactNode;
  showPhoneNumber?: boolean;
  socialLogins?: React.ReactNode;
  splitChildren?: React.ReactNode;
  splitImageUrl: string;
  surface?: Surface;
  titleText: string | React.ReactNode;
  type?: AuthLayoutType;
};

const Register = forwardRef<any, RegisterProps>(
  (
    {
      children,
      disabled,
      errors,
      logoUrl,
      emailLabel,
      phoneNumberLabel,
      footer,
      confirmPasswordLabel,
      hideEmail = false,
      showPhoneNumber = false,
      initialEmail = '',
      onSubmit = () => null,
      buttonText,
      passwordLabel,
      loginText,
      splitImageUrl,
      titleText,
      type = SIMPLE,
      errorTexts = {
        empty: 'This field can not be blank',
        email: {
          invalid: 'That is not a valid email',
        },
        password: {
          invalid: 'Your password is not strong enough',
          tooShort: 'Your password must be at least 8 characters long',
          tooLong: 'Your password must be no more than 256 characters long',
          numbers: 'Your password must include at least one number',
          case: 'Your password must include a mix of uppercase and lowercase letters',
          symbol:
            'Your password must include a special character such as ($, %, *, @)',
        },
        confirmPassword: {
          invalid: 'Your passwords do not match',
        },
      },
      surface,
      onClick,
      splitChildren,
      socialLogins,
    },
    ref,
  ) => {
    const [showErrors, setShowErrors] = useState(false);
    const [email, setEmail] = useState<string>(initialEmail || '');
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
      country: getCountryFromTimeZone(),
      number: '',
    });
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const emailErrorMessage =
      showErrors &&
      !isEmailValid(email) &&
      getSimpleErrorMessage(errorTexts, 'email', email);
    const phoneNumberErrorMessage =
      showErrors &&
      showPhoneNumber &&
      !isPhoneNumberValid(phoneNumber) &&
      getSimpleErrorMessage(errorTexts, 'phoneNumber', phoneNumber);
    const passwordErrorMessage =
      showErrors && getPasswordErrorMessage(errorTexts, password);
    const confirmPasswordErrorMessage =
      showErrors &&
      password !== confirmPassword &&
      getSimpleErrorMessage(errorTexts, 'confirmPassword', confirmPassword);

    const handleOnSubmit = useCallback(
      (event) => {
        event.preventDefault();
        setShowErrors(false);

        if (
          isFormValid(
            email,
            hideEmail,
            password,
            confirmPassword,
            phoneNumber,
            showPhoneNumber,
          )
        ) {
          onSubmit({
            email,
            password,
            confirmPassword,
            phoneNumber: `+${getAreaCodeFromCountry(
              phoneNumber.country as CountryCode,
            )} ${phoneNumber.number}`,
          });
        } else {
          setShowErrors(true);
        }
      },
      [
        onSubmit,
        email,
        hideEmail,
        password,
        confirmPassword,
        phoneNumber,
        showPhoneNumber,
      ],
    );

    const RegisterLayout = registerLayoutTypeMap[type];

    if (!RegisterLayout) {
      return null;
    }

    return (
      <div
        className="flex min-h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-100"
        ref={ref}
        onClick={onClick}
      >
        <RegisterLayout
          disabled={disabled}
          email={email}
          phoneNumber={phoneNumber}
          errors={errors}
          footer={footer}
          setEmail={setEmail}
          setPhoneNumber={setPhoneNumber}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          logoUrl={logoUrl}
          hideEmail={hideEmail}
          showPhoneNumber={showPhoneNumber}
          emailLabel={emailLabel}
          phoneNumberLabel={phoneNumberLabel}
          buttonText={buttonText}
          confirmPasswordLabel={confirmPasswordLabel}
          passwordLabel={passwordLabel}
          loginText={loginText}
          splitImageUrl={splitImageUrl}
          titleText={titleText}
          onSubmit={handleOnSubmit}
          emailErrorMessage={emailErrorMessage}
          phoneNumberErrorMessage={phoneNumberErrorMessage}
          passwordErrorMessage={passwordErrorMessage}
          confirmPasswordErrorMessage={confirmPasswordErrorMessage}
          surface={surface}
          socialLogins={socialLogins}
          splitChildren={splitChildren}
        >
          {children}
        </RegisterLayout>
        {type !== SPLIT && footer}
      </div>
    );
  },
);

export default withTheme(Register);
