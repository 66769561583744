import createReactComponent from '../createReactComponent';
export default createReactComponent('sun-high', 'IconSunHigh', [
  [
    'path',
    {
      d: 'M14.828 14.828a4 4 0 1 0 -5.656 -5.656a4 4 0 0 0 5.656 5.656z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6.343 17.657l-1.414 1.414', key: 'svg-1' }],
  ['path', { d: 'M6.343 6.343l-1.414 -1.414', key: 'svg-2' }],
  ['path', { d: 'M17.657 6.343l1.414 -1.414', key: 'svg-3' }],
  ['path', { d: 'M17.657 17.657l1.414 1.414', key: 'svg-4' }],
  ['path', { d: 'M4 12h-2', key: 'svg-5' }],
  ['path', { d: 'M12 4v-2', key: 'svg-6' }],
  ['path', { d: 'M20 12h2', key: 'svg-7' }],
  ['path', { d: 'M12 20v2', key: 'svg-8' }],
]);
