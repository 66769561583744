import React from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import {
  getCustomerInvoiceCollectionQuery,
  getFinalizeInvoiceQuery,
  getInvoiceCollectionQuery,
} from '../../../queries/project';
import useCacheQuery from '../../../utils/hooks/useCacheQuery';
import useRouter from '../../../utils/hooks/useRouter';
import InvoiceTable from './InvoiceTable';

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof InvoiceList.defaultProps;

// @ts-expect-error TS(7022): 'InvoiceList' implicitly has type 'any' because it... Remove this comment to see the full error message
const InvoiceList = ({
  onClick,
  isInternal,
  isStripeConnected,
  userId,
  emptyState,
  customerFields,
  customerField,
  title,
  showCustomerPortalButton,
  subtitle,
  project,
}: Props) => {
  const {
    query: { customerId },
  } = useRouter();

  const invoiceCollectionQuery = isInternal
    ? getInvoiceCollectionQuery(customerFields)
    : getCustomerInvoiceCollectionQuery(customerFields);

  const { data, loading } = useCacheQuery(
    gql`
      ${invoiceCollectionQuery}
    `,
    {
      skip: !userId,
      context: { projectQuery: true, projectName: project.name },
      variables:
        isInternal && customerId
          ? { where: { customerId: { equals: customerId } } }
          : null,
    },
  );
  const [finalizeInvoice, { loading: finalizeLoading }] = useMutation(gql`
    ${getFinalizeInvoiceQuery(customerFields)}
  `);

  const invoices = get(
    data,
    [isInternal ? 'invoiceCollection' : 'customerInvoiceCollection', 'edges'],
    [],
  ).map((edge: any) => edge.node);

  return (
    <InvoiceTable
      // @ts-expect-error TS(2322): Type '{ onClick: any; project: any; isInternal: an... Remove this comment to see the full error message
      onClick={onClick}
      project={project}
      isInternal={isInternal}
      isStripeConnected={isStripeConnected}
      title={title}
      subtitle={subtitle}
      customerField={customerField}
      finalizeInvoice={finalizeInvoice}
      emptyState={emptyState}
      invoices={invoices}
      showCustomerPortalButton={showCustomerPortalButton}
      finalizeLoading={finalizeLoading}
      loading={loading}
    />
  );
};

InvoiceList.defaultProps = {
  isStripeConnected: true,
};

export default InvoiceList;
