import React, { useRef } from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import { BaseRecord } from '../../../models/Record';
import { Group } from '../Collection';
import { useSetRef } from './DraggableRecord';

export type DroppableGroupConfig = {
  elementRef: React.ForwardedRef<any>;
  group: Group;
  onDrop: (droppedRecord: BaseRecord, droppedGroup: Group) => void;
};

export const useDroppableGroup = ({
  elementRef,
  group,
  onDrop,
}: DroppableGroupConfig) => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'record',
      canDrop: (item: any) => item.groupKey !== group.key,
      drop(item: any) {
        if (item.groupKey !== group.key) {
          onDrop(item.record, group);
        }
      },
      collect: (monitor: any) => ({
        isOver: monitor.isOver() && monitor.canDrop(),
      }),
    }),
    [group],
  );

  const dropRef = useSetRef(elementRef, drop);

  return { isOver, dropRef };
};

const DroppableBoardGroup = ({
  className,
  children,
  group,
  onDrop,
  dataTestid,
}: any) => {
  const elementRef = useRef<Element>();
  const { isOver, dropRef } = useDroppableGroup({
    elementRef,
    group,
    onDrop,
  });

  return (
    <div
      className={classNames(className)}
      data-testid={dataTestid}
      ref={dropRef}
    >
      {children({ isOver })}
    </div>
  );
};

export default DroppableBoardGroup;
