import createReactComponent from '../createReactComponent';
export default createReactComponent('backspace', 'IconBackspace', [
  [
    'path',
    {
      d: 'M20 6a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-11l-5 -5a1.5 1.5 0 0 1 0 -2l5 -5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 10l4 4m0 -4l-4 4', key: 'svg-1' }],
]);
