import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrows-double-ne-sw',
  'IconArrowsDoubleNeSw',
  [
    ['path', { d: 'M3 14l11 -11', key: 'svg-0' }],
    ['path', { d: 'M10 3h4v4', key: 'svg-1' }],
    ['path', { d: 'M10 17v4h4', key: 'svg-2' }],
    ['path', { d: 'M21 10l-11 11', key: 'svg-3' }],
  ],
);
