import React, { useMemo } from 'react';
import { IconAlertTriangle, IconArrowRight } from '@tabler/icons-react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';
import { Popover } from '@noloco/components';
import {
  DATABASE_RECORDS,
  FIELD_LEVEL_PERMISSIONS,
  SYNCED_RECORDS,
} from '@noloco/core/src/constants/features';
import DataTypes from '@noloco/core/src/models/DataTypes';
import { getText } from '@noloco/core/src/utils/lang';
import { dataTypeHasFieldsWithoutRules } from '@noloco/core/src/utils/permissions';
import { useFeatureLimit } from '../utils/hooks/useFeatureLimit';
import useIsFeatureEnabled from '../utils/hooks/useIsFeatureEnabled';
import useRowCounts from '../utils/hooks/useRowCounts';

type Props = {
  dataTypes: DataTypes;
};

const SYNC_LANG_KEY = 'data.dataSources.disabledWarning';
const PERMISSIONS_LANG_KEY = 'data.permissions.missingFieldsWarning';
const DATABASE_RECORDS_LANG_KEY = 'data.limits.database';
const SYNCED_RECORDS_LANG_KEY = 'data.limits.synced';

const LIST_LIMIT = 3;

const DataTabWarningDot = ({ dataTypes }: Props) => {
  const dataTypesWithSyncDisabled = useMemo(
    () =>
      dataTypes.filter(
        (dataType) => get(dataType, ['source', 'syncEnabled']) === false,
      ),
    [dataTypes],
  );

  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const dataTypesWithPermissionsToSetup = useMemo(
    () =>
      fieldPermissionsEnabled
        ? dataTypes.filter(dataTypeHasFieldsWithoutRules)
        : [],
    [dataTypes, fieldPermissionsEnabled],
  );

  const { rows, syncedRows } = useRowCounts(dataTypes);

  const databaseRecordsLimit = useFeatureLimit(DATABASE_RECORDS);
  const exceededDatabaseRecordLimit = useMemo(
    () =>
      !isNil(databaseRecordsLimit.limit) && databaseRecordsLimit.limit < rows,
    [databaseRecordsLimit.limit, rows],
  );

  const syncedRecordsLimit = useFeatureLimit(SYNCED_RECORDS);
  const exceededSyncedRecordLimit = useMemo(
    () =>
      !isNil(syncedRecordsLimit.limit) && syncedRecordsLimit.limit < syncedRows,
    [syncedRecordsLimit.limit, syncedRows],
  );

  if (
    dataTypesWithSyncDisabled.length === 0 &&
    dataTypesWithPermissionsToSetup.length === 0 &&
    !exceededDatabaseRecordLimit &&
    !exceededSyncedRecordLimit
  ) {
    return null;
  }

  return (
    <Popover
      content={
        <div className="flex py-2">
          <IconAlertTriangle
            size={22}
            className="mt-1 flex-shrink-0 text-yellow-500"
          />
          <div className="ml-3 flex flex-col space-y-6">
            {dataTypesWithSyncDisabled.length > 0 && (
              <div className="flex flex-col space-y-2">
                <h2 className="text-sm font-medium text-yellow-800">
                  {getText(SYNC_LANG_KEY, 'title')}
                </h2>
                <p className="max-w-xs text-xs text-yellow-700">
                  {getText(SYNC_LANG_KEY, 'description')}
                </p>
                <ul className="flex list-disc flex-col space-y-0.5 pl-4">
                  {dataTypesWithSyncDisabled
                    .slice(0, LIST_LIMIT)
                    .map((dataType: any) => (
                      <li
                        className="group w-full cursor-pointer justify-between"
                        key={dataType.id}
                      >
                        <Link
                          className="flex justify-between"
                          to={`/_/data/internal/${dataType.apiName}/syncs`}
                        >
                          <span className="mr-4 text-xs font-medium text-gray-600 group-hover:text-gray-700">
                            {dataType.display}
                          </span>
                          <div className="mr-2 hidden items-center text-gray-500 group-hover:flex">
                            <span className="mr-2 text-xs">
                              {getText(SYNC_LANG_KEY, 'review')}
                            </span>
                            <IconArrowRight size={14} />
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
                {dataTypesWithSyncDisabled.length > LIST_LIMIT && (
                  <p className="max-w-xs text-xs text-gray-400">
                    {getText(
                      {
                        notShown: dataTypesWithSyncDisabled.length - LIST_LIMIT,
                      },
                      SYNC_LANG_KEY,
                      'truncated',
                    )}
                  </p>
                )}
              </div>
            )}
            {dataTypesWithPermissionsToSetup.length > 0 && (
              <div className="flex flex-col space-y-2">
                <h2 className="text-sm font-medium text-yellow-800">
                  {getText(PERMISSIONS_LANG_KEY, 'title')}
                </h2>
                <p className="max-w-xs text-xs text-yellow-700">
                  {getText(PERMISSIONS_LANG_KEY, 'description')}
                </p>
                <ul className="flex list-disc flex-col space-y-0.5 pl-4">
                  {dataTypesWithPermissionsToSetup
                    .slice(0, LIST_LIMIT)
                    .map((dataType: any) => (
                      <li
                        className="group w-full cursor-pointer justify-between"
                        key={dataType.id}
                      >
                        <Link
                          className="flex justify-between"
                          to={`/_/data/internal/${dataType.apiName}/permissions`}
                        >
                          <span className="mr-4 text-xs font-medium text-gray-600 group-hover:text-gray-700">
                            {dataType.display}
                          </span>
                          <div className="mr-2 hidden items-center text-gray-500 group-hover:flex">
                            <span className="mr-2 text-xs">
                              {getText(PERMISSIONS_LANG_KEY, 'fix')}
                            </span>
                            <IconArrowRight size={14} />
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
                {dataTypesWithPermissionsToSetup.length > LIST_LIMIT && (
                  <p className="max-w-xs text-xs text-gray-400">
                    {getText(
                      {
                        notShown:
                          dataTypesWithPermissionsToSetup.length - LIST_LIMIT,
                      },
                      PERMISSIONS_LANG_KEY,
                      'truncated',
                    )}
                  </p>
                )}
              </div>
            )}
            {exceededDatabaseRecordLimit && (
              <div className="flex flex-col space-y-2">
                <h2 className="text-sm font-medium text-yellow-800">
                  {getText(DATABASE_RECORDS_LANG_KEY, 'title')}
                </h2>
                <p className="max-w-xs text-xs text-yellow-700">
                  {getText(
                    { limit: databaseRecordsLimit.limit, usage: rows },
                    DATABASE_RECORDS_LANG_KEY,
                    'description',
                  )}
                </p>
              </div>
            )}
            {exceededSyncedRecordLimit && (
              <div className="flex flex-col space-y-2">
                <h2 className="text-sm font-medium text-yellow-800">
                  {getText(SYNCED_RECORDS_LANG_KEY, 'title')}
                </h2>
                <p className="max-w-xs text-xs text-yellow-700">
                  {getText(
                    { limit: syncedRecordsLimit.limit, usage: syncedRows },
                    SYNCED_RECORDS_LANG_KEY,
                    'description',
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      }
      bg="yellow-50"
      placement="right"
      trigger="hover"
    >
      <div className="absolute right-3 top-1 h-2 w-2 rounded-full bg-yellow-400" />
    </Popover>
  );
};

export default DataTabWarningDot;
