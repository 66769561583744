import React, { useMemo } from 'react';
import first from 'lodash/first';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ElementRenderer } from '@noloco/ui/src/components/canvas/ProjectRenderer';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CUSTOM_VISIBILITY_RULES } from '../constants/features';
import { Project } from '../models/Project';
import { scopeSelector } from '../selectors/dataSelectors';
import { isElementVisible } from '../utils/elementVisibility';
import useAuthWrapper from '../utils/hooks/useAuthWrapper';
import { getText } from '../utils/lang';
import { getPagePath, getPagesConfig } from '../utils/pages';

type OwnProps = {
  auth?: boolean;
  children?: React.ReactNode;
  dataType?: string;
  editorMode?: boolean;
  elementPath: (string | number)[];
  dataProperty?: string;
  routePath?: string;
  project: Project;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Folder.defaultProps;

// @ts-expect-error TS(7022): 'Folder' implicitly has type 'any' because it does... Remove this comment to see the full error message
const Folder = ({ editorMode, elementPath, routePath, project }: Props) => {
  const element = get(project.elements, elementPath);
  const scope = useSelector(scopeSelector);
  const { user } = useAuthWrapper();

  const { path } = getPagePath('/', routePath);

  const topLevelPages = useMemo(() => {
    const { pagesPath } = getPagesConfig(project.elements, project.settings);

    return pagesPath.length > 0
      ? get(project.elements, pagesPath, [])
      : project.elements;
  }, [project]);

  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const subPages = useMemo(
    () =>
      topLevelPages
        .filter(
          (childPage: any) => get(childPage, 'props.parentPage') === element.id,
        )
        .filter((childPage: any) =>
          isElementVisible(
            childPage,
            project,
            user,
            scope,
            editorMode,
            customRulesEnabled,
          ),
        ),
    [
      customRulesEnabled,
      editorMode,
      element.id,
      project,
      scope,
      topLevelPages,
      user,
    ],
  );

  const firstSubPage = useMemo(() => first(subPages), [subPages]);

  return (
    <Switch>
      {subPages.map((subPage: any) => (
        <Route
          key={subPage.id}
          path={`${path}/${subPage.props.routePath}/:tab?`}
        >
          <ElementRenderer
            editorMode={editorMode}
            element={subPage}
            key={subPage.id}
            index={topLevelPages.indexOf(subPage)}
            project={project}
            scope={scope}
          />
        </Route>
      ))}
      {firstSubPage ? (
        <Redirect to={`${path}/${(firstSubPage as any).props.routePath}`} />
      ) : (
        <div className="flex h-screen w-full items-center justify-center">
          <h1> {getText('elements.VIEW.tabs.empty')}</h1>
        </div>
      )}
    </Switch>
  );
};

Folder.defaultProps = {
  auth: false,
  dataType: undefined,
  dataProperty: undefined,
  editorMode: false,
  routePath: undefined,
};

export default Folder;
