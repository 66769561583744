import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconFileImport } from '@tabler/icons-react';
import classNames from 'classnames';
import gql from 'graphql-tag';
import { partition } from 'lodash';
import camelCase from 'lodash/camelCase';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import SimpleBar from 'simplebar-react';
import { Loader, Modal, SelectInput } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import DataFieldIcon from '@noloco/ui/src/components/DataFieldIcon';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import Dropzone from '../../../components/dropzone/Dropzone';
import { CREATE } from '../../../constants/actionTypes';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import { DOCUMENT } from '../../../constants/fileTypes';
import { MS_XLS, TEXT_CSV } from '../../../constants/mimetypes';
import DropzonePreview from '../../../elements/sections/forms/DropzonePreview';
import { User } from '../../../models/User';
import { getImportQueryString } from '../../../queries/project';
import { isImportableField } from '../../../utils/dataImport';
import { YEAR_MONTH_DATE_FORMAT } from '../../../utils/dates';
import { isDefaultField, isIdField } from '../../../utils/defaultFields';
import { downloadCsvStringAsFile } from '../../../utils/files';
import { useGraphQlErrorAlert } from '../../../utils/hooks/useAlerts';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import useAutoFormVariables from '../../../utils/hooks/useAutoFormVariables';
import useIsWindows from '../../../utils/hooks/useIsWindows';
import { getText } from '../../../utils/lang';
import { mapFieldsWithPermissionsAndConfig } from '../../../utils/permissions';

const BASE_LANG_KEY = 'data.import';

const ImportModal = ({ fields, dataType, onClose, project }: any) => {
  const errorAlert = useGraphQlErrorAlert();
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [mappings, setMappings] = useState<any>({});
  const [isDone, setIsDone] = useState<boolean>(false);
  const [parsedResults, setParsedResults] = useState<any>(null);
  const isWindows = useIsWindows();

  const { user } = useAuthWrapper();
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const fieldConfigs = useMemo(
    () =>
      mapFieldsWithPermissionsAndConfig(
        fields,
        dataType,
        user as User,
        project.dataTypes,
        fieldPermissionsEnabled,
      ).filter(
        // @ts-expect-error typing needs updating
        ({ field, config: { hidden } }) => isImportableField(field) || hidden,
      ),
    [dataType, fieldPermissionsEnabled, fields, project.dataTypes, user],
  );

  const [visibleFieldConfigs, hiddenFieldConfigs] = useMemo(
    // @ts-expect-error typing needs updating
    () => partition(fieldConfigs, (fieldConfig) => !fieldConfig.config.hidden),
    [fieldConfigs],
  );

  // @ts-expect-error typing needs updating
  const { getQueryVariables } = useAutoFormVariables(
    project,
    dataType,
    hiddenFieldConfigs,
    CREATE,
  );

  const handleOnClose = useCallback(() => {
    setFile(null);
    setMappings({});
    setParsedResults(null);
    setIsDone(false);
    onClose();
  }, [onClose]);

  const importQueryString = useMemo(
    () => gql`
      ${getImportQueryString(dataType)}
    `,
    [dataType],
  );

  const queryOptions = useMemo(
    () => ({
      fetchPolicy: 'no-cache',
      context: {
        autQuery: true,
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [project.name],
  );

  // @ts-expect-error typing needs updating
  const [importFile] = useMutation(importQueryString, queryOptions);

  const onSelectFile = useCallback(
    ([newFile]) => {
      setFile(newFile);
      setLoading(true);
      Papa.parse(newFile, {
        worker: true,
        dynamicTyping: true,
        header: true,
        skipEmptyLines: true,
        error: (err) => {
          console.log('ERROR', err);
          setLoading(false);
        },
        complete: (results: any) => {
          setLoading(false);
          setParsedResults(results);

          const [exactMatchingFields, otherFields] = partition(
            results.meta.fields,
            (column) => {
              const formattedColumn = camelCase(column);

              return !!visibleFieldConfigs.find(
                ({ field }) =>
                  field.display === column || field.apiName === formattedColumn,
              );
            },
          );

          const newMapping: Record<string, string> = exactMatchingFields.reduce(
            (acc, column) => {
              let matchingField = visibleFieldConfigs.find(
                ({ field }) => field.display === column,
              );

              if (!matchingField) {
                matchingField = visibleFieldConfigs.find(
                  ({ field }) => field.apiName === camelCase(column),
                );
              }

              if (matchingField) {
                acc[column] = matchingField.field.name;
              }
              return acc;
            },
            {},
          );

          otherFields.forEach((column: any) => {
            const formattedColumn = camelCase(column);

            const matchingField = visibleFieldConfigs.find(
              ({ field }) =>
                formattedColumn !== '' &&
                (field.apiName.startsWith(formattedColumn) ||
                  formattedColumn.startsWith(field.apiName)) &&
                !Object.values(newMapping).includes(field.name),
            );

            if (matchingField) {
              newMapping[column] = matchingField.field.name;
            }
          });

          setMappings(newMapping);
        },
      });
    },
    [visibleFieldConfigs],
  );

  const onChangeMapping = useCallback((column: any, field: any) => {
    setMappings((currentMappings: any) => {
      const existingMapping = Object.entries(currentMappings).find(
        ([__, fieldName]) => fieldName === field,
      );

      const newMappings: any = {
        ...currentMappings,
        [column]: field,
      };

      if (existingMapping) {
        delete newMappings[existingMapping[0]];
      }

      return newMappings;
    });
  }, []);

  const onConfirm = useCallback(async () => {
    if (isDone) {
      return handleOnClose();
    }

    if (!parsedResults) {
      return null;
    }

    setLoading(true);

    const importFields = Object.entries(mappings).reduce(
      (acc, [column, fieldName]) =>
        // @ts-expect-error typing needs updating
        fieldName || fieldName === '' ? set(fieldName, column, acc) : acc,
      {},
    );

    // @ts-expect-error typing needs updating
    const { variables: hiddenValues } = getQueryVariables();

    return importFile({
      variables: {
        fields: importFields,
        file,
        values: hiddenValues,
      },
    })
      .then(() => {
        setIsDone(true);
      })
      .catch((e) => {
        errorAlert(getText(BASE_LANG_KEY, 'error'), e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    isDone,
    parsedResults,
    mappings,
    getQueryVariables,
    importFile,
    file,
    handleOnClose,
    errorAlert,
  ]);

  const onGenerateTemplateCsv = useCallback(async () => {
    const fieldNameRow = visibleFieldConfigs
      .filter(({ field }) => isIdField(field) || !isDefaultField(field))
      // @ts-expect-error typing needs updating
      .map((fieldConfig) => fieldConfig.config.label);
    const emptyRow = fieldNameRow.map(() => '');
    const rowsCSV = Papa.unparse([fieldNameRow, emptyRow]);

    downloadCsvStringAsFile(
      rowsCSV,
      `${dataType.display}-${getText(
        BASE_LANG_KEY,
        'template',
      )}-${DateTime.now().toFormat(YEAR_MONTH_DATE_FORMAT)}`,
      isWindows,
    );
  }, [visibleFieldConfigs, dataType, isWindows]);

  const unmappedFields = useMemo(() => {
    const mappedFields = Object.entries(mappings)
      .filter(([_, value]) => !isNil(value))
      .map(([key]) => key);

    return get(parsedResults, ['meta', 'fields'], []).filter(
      (field: string) => !mappedFields.includes(field),
    );
  }, [mappings, parsedResults]);

  return (
    <Modal
      onClose={handleOnClose}
      onCancel={handleOnClose}
      size={isDone ? 'lg' : 'xl'}
      confirmDisabled={loading || !parsedResults}
      canCancel={!loading && !isDone}
      onConfirm={onConfirm}
      title={getText(BASE_LANG_KEY, 'title')}
      confirmText={
        !loading ? (
          getText(BASE_LANG_KEY, isDone ? 'done' : 'confirm')
        ) : (
          <Loader size="sm" />
        )
      }
    >
      <div
        className={classNames('flex', {
          'h-96 justify-center': !file,
        })}
      >
        {!file && (
          <div className="flex flex-col space-y-4">
            <span className="mt-1 flex text-base text-gray-400 sm:flex-col">
              {getText(BASE_LANG_KEY, 'subtitle')}&nbsp;
              <button
                className="hover:teal-teal-600 flex items-center text-teal-500"
                onClick={onGenerateTemplateCsv}
              >
                <span className="text-base">
                  {getText(BASE_LANG_KEY, 'templateDownload')}
                </span>
              </button>
            </span>
            <Dropzone
              acceptedMimetypes={[TEXT_CSV, MS_XLS]}
              className="h-96"
              id="data-type-importer-file-upload"
              onChange={onSelectFile}
              maxFiles={1}
              p={4}
              surface={LIGHT}
            >
              <DropzonePreview
                id="import"
                loading={false}
                fileType={DOCUMENT}
                placeholder={getText(BASE_LANG_KEY, 'placeholder')}
                mediaItem={null}
                showIcon={true}
                onRemove={() => null}
              />
            </Dropzone>
          </div>
        )}
        {file && !parsedResults && (
          <Loader size="md" className="mx-auto my-36" />
        )}
        {parsedResults && file && (
          <div className="flex w-full flex-col">
            <div className="flex space-x-4">
              <div>
                <span className="mr-1 font-medium">
                  {getText(BASE_LANG_KEY, 'summary.file')}:
                </span>
                <span>{file.name}</span>
              </div>
              <div>
                <span className="mr-1 font-medium">
                  {getText(BASE_LANG_KEY, 'summary.rows')}:
                </span>
                <span>{parsedResults.data.length}</span>
              </div>
              <div>
                <span className="mr-1 font-medium">
                  {getText(BASE_LANG_KEY, 'summary.columns')}:
                </span>
                <span>{parsedResults.meta.fields.length}</span>
              </div>
            </div>
            {!isDone && (
              <p className="mb-2 mt-4 text-base">
                {getText(BASE_LANG_KEY, 'help')}
              </p>
            )}
            {!isDone && (
              <div className="my-4 overflow-hidden rounded border border-gray-200">
                {/* @ts-expect-error TS2786: 'SimpleBar' cannot be used as a JSX component. */}
                <SimpleBar
                  autoHide={true}
                  className="block h-full w-full max-w-full overflow-x-auto overflow-y-auto"
                >
                  <table className="block w-full">
                    <thead>
                      <tr>
                        {parsedResults.meta.fields.map(
                          (column: string | number, index: string | number) => (
                            <th
                              key={column || index}
                              className={classNames(
                                'z-50 m-0 whitespace-nowrap border-b border-gray-200 bg-gray-50 p-4 font-medium tracking-wider text-gray-900',
                                {
                                  'pl-8': index === 0,
                                  'opacity-50': !mappings[column],
                                },
                                index === parsedResults.meta.fields.length - 1
                                  ? 'w-full pr-8'
                                  : 'w-1',
                              )}
                            >
                              {column}
                            </th>
                          ),
                        )}
                      </tr>
                      <tr>
                        {parsedResults.meta.fields.map(
                          (column: string | number, index: number) => {
                            const mappingOptions = [
                              ...(mappings[column]
                                ? [
                                    {
                                      label: (
                                        <span className="text-gray-500">
                                          ---
                                        </span>
                                      ),
                                      value: undefined,
                                    },
                                  ]
                                : []),
                              ...visibleFieldConfigs.map(
                                ({ field, config }: any) => ({
                                  icon: (
                                    <DataFieldIcon field={field} size={16} />
                                  ),
                                  label: config.label || field.display,
                                  value: field.name,
                                }),
                              ),
                            ];

                            return (
                              <th
                                key={column || index}
                                className={classNames(
                                  'z-50 m-0 whitespace-nowrap border-b border-gray-100 bg-gray-50 p-4 font-medium tracking-wider text-gray-700',
                                  { 'pl-8': index === 0 },
                                  index === parsedResults.meta.fields.length - 1
                                    ? 'w-full pr-8'
                                    : 'w-1',
                                )}
                              >
                                <SelectInput
                                  options={mappingOptions}
                                  value={mappings[column]}
                                  onChange={(newField: any) =>
                                    onChangeMapping(column, newField)
                                  }
                                  placeholder={
                                    <span className="block py-2">
                                      {getText(
                                        BASE_LANG_KEY,
                                        'mappingPlaceholder',
                                      )}
                                    </span>
                                  }
                                  surface={LIGHT}
                                />
                              </th>
                            );
                          },
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {parsedResults.data
                        .slice(0, 5)
                        .map((row: any, index: React.Key) => (
                          <tr
                            key={index}
                            className={classNames(
                              'group border-t border-gray-100 bg-white hover:bg-blue-50',
                            )}
                          >
                            {parsedResults.meta.fields.map(
                              (column: React.Key, cellIndex: number) => (
                                <td
                                  key={column}
                                  className={classNames(
                                    'm-0 truncate whitespace-nowrap p-4',
                                    {
                                      'opacity-50': !mappings[column],
                                      'pl-8': cellIndex === 0,
                                      'pb-6':
                                        index ===
                                        parsedResults.data.slice(0, 5).length -
                                          1,
                                    },
                                    cellIndex === parsedResults.meta.fields - 1
                                      ? 'w-full pr-8'
                                      : 'w-1 max-w-md',
                                  )}
                                >
                                  {row[column] !== null
                                    ? String(row[column])
                                    : ''}
                                </td>
                              ),
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </SimpleBar>
              </div>
            )}
            {!isDone && unmappedFields.length > 0 && (
              <div className="space-y-2 rounded-lg bg-yellow-200 p-4 text-base">
                <p>{getText(BASE_LANG_KEY, 'unmapped')}</p>
                <ul className="flex list-disc flex-col space-y-0.5 pl-8">
                  {unmappedFields.map((field: string) => (
                    <li key={field}>{field}</li>
                  ))}
                </ul>
              </div>
            )}
            {isDone && (
              <div className="my-8 flex w-full items-start rounded-lg bg-teal-100 px-8 py-4 text-teal-900">
                <IconFileImport className="mt-2 flex-shrink-0" size={24} />
                <div className="ml-8 flex flex-col text-base">
                  <h2 className="text-xl font-medium">
                    {getText(BASE_LANG_KEY, 'completeSummary.title')}
                  </h2>
                  <p className="mt-1">
                    {getText(BASE_LANG_KEY, 'completeSummary.subtitle')}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ImportModal;
