import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-svelte', 'IconBrandSvelte', [
  [
    'path',
    {
      d: 'M15 8l-5 3l.821 -.495c1.86 -1.15 4.412 -.49 5.574 1.352a3.91 3.91 0 0 1 -1.264 5.42l-5.053 3.126c-1.86 1.151 -4.312 .591 -5.474 -1.251a3.91 3.91 0 0 1 1.263 -5.42l.26 -.16',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8 17l5 -3l-.822 .496c-1.86 1.151 -4.411 .491 -5.574 -1.351a3.91 3.91 0 0 1 1.264 -5.42l5.054 -3.127c1.86 -1.15 4.311 -.59 5.474 1.252a3.91 3.91 0 0 1 -1.264 5.42l-.26 .16',
      key: 'svg-1',
    },
  ],
]);
