import createReactComponent from '../createReactComponent';
export default createReactComponent('building-bank', 'IconBuildingBank', [
  ['path', { d: 'M3 21l18 0', key: 'svg-0' }],
  ['path', { d: 'M3 10l18 0', key: 'svg-1' }],
  ['path', { d: 'M5 6l7 -3l7 3', key: 'svg-2' }],
  ['path', { d: 'M4 10l0 11', key: 'svg-3' }],
  ['path', { d: 'M20 10l0 11', key: 'svg-4' }],
  ['path', { d: 'M8 14l0 3', key: 'svg-5' }],
  ['path', { d: 'M12 14l0 3', key: 'svg-6' }],
  ['path', { d: 'M16 14l0 3', key: 'svg-7' }],
]);
