import React, { memo } from 'react';
import { IconX } from '@tabler/icons-react';
import { LIGHT } from '@noloco/components/src/constants/surface';
import Dropzone from '../../../components/dropzone/Dropzone';
import { IMAGE } from '../../../constants/fileTypes';
import { IMAGE_MIMETYPES } from '../../../constants/mimetypes';
import { getText } from '../../../utils/lang';
import DropzonePreview from './DropzonePreview';

const IMPORT_LANG_KEY = 'signature';

type SignatureFilePreviewProps = {
  file: any;
  id?: string;
  onChange?: (value: null | any) => void;
};

const SignatureFilePreview = memo(
  ({ file, id, onChange = () => null }: SignatureFilePreviewProps) => {
    return (
      <>
        {!file && (
          <Dropzone
            acceptedMimetypes={IMAGE_MIMETYPES}
            className="h-44 w-full bg-gray-100"
            id={id}
            maxFiles={1}
            onChange={(file: any) => onChange(file)}
            p={4}
            surface={LIGHT}
          >
            <DropzonePreview
              id={id}
              className="bg-gray-100"
              fileType={IMAGE}
              mediaItem={null}
              placeholder={getText(IMPORT_LANG_KEY, 'placeholder')}
              showIcon={true}
              surface={LIGHT}
            />
          </Dropzone>
        )}
        {(file?.url || file?.edges?.[0]?.node?.url) && (
          <>
            <img
              className="mt-4 h-3/5 w-3/5 object-cover"
              src={file.url || file.edges[0].node.url}
              alt={file.name || file.edges[0].node.name}
            />
            <IconX
              size={24}
              onClick={() => onChange(null)}
              className="absolute left-0 top-0 m-2 rounded-full border border-slate-400 stroke-slate-500 opacity-50 hover:opacity-100"
            />
          </>
        )}
      </>
    );
  },
);

export default SignatureFilePreview;
