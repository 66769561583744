import createReactComponent from '../createReactComponent';
export default createReactComponent('baby-carriage', 'IconBabyCarriage', [
  ['path', { d: 'M8 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M18 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M2 5h2.5l1.632 4.897a6 6 0 0 0 5.693 4.103h2.675a5.5 5.5 0 0 0 0 -11h-.5v6',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M6 9h14', key: 'svg-3' }],
  ['path', { d: 'M9 17l1 -3', key: 'svg-4' }],
  ['path', { d: 'M16 14l1 3', key: 'svg-5' }],
]);
