import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-bolt',
  'IconDeviceDesktopBolt',
  [
    [
      'path',
      {
        d: 'M14.5 16h-10.5a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v7.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h6', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-3' }],
  ],
);
