import { NESTED_PROPS } from '../../constants/elementPropTypeTypes';
import ElementPropType from './ElementPropType';

class NestedPropsPropType extends ElementPropType {
  constructor() {
    super(NESTED_PROPS);
  }
}

export default NestedPropsPropType;
