import { GROUP } from '../../constants/elementPropTypeTypes';
import ElementPropType from './ElementPropType';

class PropGroup extends ElementPropType {
  shape: Record<string, ElementPropType>;
  isButton: boolean;

  constructor(shape: Record<string, ElementPropType>, isButton = false) {
    super(GROUP);
    this.shape = shape;
    this.isButton = isButton;
  }
}

export default PropGroup;
