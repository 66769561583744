export enum NotificationIconType {
  ICON = 'ICON',
  IMAGE = 'IMAGE',
}

export interface Notification {
  createdAt: Date;
  icon: string;
  iconType?: NotificationIconType;
  id: string;
  link?: string | null;
  message: string;
  project: string;
  readAt?: Date | null;
  user: number;
}
