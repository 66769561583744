import React, { memo } from 'react';
import { TailwindThemeProvider } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { darkModeColors } from '@noloco/core/src/constants/darkModeColors';
import { editorModeSelector } from '@noloco/core/src/selectors/elementsSelectors';
import useDarkMode from '@noloco/core/src/utils/hooks/useDarkMode';
import LeftBuildModeSidebar from '../editor/LeftBuildModeSidebar';
import AppBody from './AppBody';

const Canvas = memo(({ isPublic, nolocoTheme, sidebars, children }: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  const editorMode = useSelector(editorModeSelector);

  return (
    <TailwindThemeProvider theme={nolocoTheme}>
      <div
        className={classNames(
          'flex h-screen w-full flex-col overflow-hidden bg-slate-800',
        )}
      >
        <div className="flex max-h-full flex-grow overflow-hidden">
          {editorMode && <LeftBuildModeSidebar />}
          <div className="flex h-screen w-full flex-col">
            <div
              className={classNames(
                'canvas project-preview relative z-10 mx-auto flex h-full max-h-screen w-full flex-grow flex-col shadow sm:h-screen',
                'overflow-hidden',
                `${
                  isDarkModeEnabled
                    ? darkModeColors.surfaces.elevation0
                    : 'bg-white'
                }`,
              )}
              data-tour="canvas"
            >
              <AppBody displaySidebar={!isPublic} displayEditMode={!isPublic}>
                {children}
              </AppBody>
            </div>
          </div>
          {sidebars}
        </div>
      </div>
    </TailwindThemeProvider>
  );
});

export default Canvas;
