import React, { memo } from 'react';
import { IconMarkdown } from '@tabler/icons-react';
import classNames from 'classnames';
import { getText } from '../../utils/lang';

type BuildModeMarkdownHelpProps = {
  className?: string;
};

const BuildModeMarkdownHelp = memo(
  ({ className }: BuildModeMarkdownHelpProps) => (
    <div
      className={classNames(
        'flex cursor-pointer items-center text-xs text-gray-400',
        className,
      )}
    >
      <a
        className="flex items-center space-x-1 hover:text-gray-200"
        href="https://www.markdownguide.org/cheat-sheet/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <IconMarkdown size={16} className="group-hover:text-gray-200" />
        <span>{getText('hints.markdown.name')}</span>
      </a>
    </div>
  ),
);

export default BuildModeMarkdownHelp;
