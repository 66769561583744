import createReactComponent from '../createReactComponent';
export default createReactComponent('tank', 'IconTank', [
  [
    'path',
    {
      d: 'M2 12m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v0a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 12l1 -5h5l3 5', key: 'svg-1' }],
  ['path', { d: 'M21 9l-7.8 0', key: 'svg-2' }],
]);
