export const API_FORM: ElementType = 'API_FORM';
export const APP_LAYOUT: ElementType = 'APP_LAYOUT';
export const BADGE: ElementType = 'BADGE';
export const BUTTON: ElementType = 'BUTTON';
export const CHART: ElementType = 'CHART';
export const CONTENT: ElementType = 'CONTENT';
export const COMMENTS: ElementType = 'COMMENTS';
export const COPY: ElementType = 'COPY';
export const DATA_WRAPPER: ElementType = 'DATA_WRAPPER';
export const FORGOT_PASSWORD: ElementType = 'FORGOT_PASSWORD';
export const FORM_V2: ElementType = 'FORM:V2';
export const FOLDER: ElementType = 'FOLDER';
export const GROUP: ElementType = 'GROUP';
export const ICON: ElementType = 'ICON';
export const IFRAME: ElementType = 'IFRAME';
export const IMAGE: ElementType = 'IMAGE';
export const LABEL: ElementType = 'LABEL';
export const LIST: ElementType = 'LIST';
export const LINK: ElementType = 'LINK';
export const PROGRESS_BAR: ElementType = 'PROGRESS_BAR';
export const PROGRESS_RING: ElementType = 'PROGRESS_RING';
export const DIVIDER: ElementType = 'DIVIDER';
export const PAGE: ElementType = 'PAGE';
export const PAGE_SWITCH: ElementType = 'PAGE_SWITCH';
export const RELATIVE_DATE: ElementType = 'RELATIVE_DATE';
export const TEXT: ElementType = 'TEXT';
export const VIDEO: ElementType = 'VIDEO';
export const YOUTUBE_VIDEO: ElementType = 'YOUTUBE_VIDEO';

export const ACTION_BUTTONS: ElementType = 'ACTION_BUTTONS';
export const NEW_RECORD_BUTTON: ElementType = 'NEW_RECORD_BUTTON';
export const IMPORT_BUTTON: ElementType = 'IMPORT_BUTTON';
export const EXPORT_BUTTON: ElementType = 'EXPORT_BUTTON';
export const RECORD_VIEW: ElementType = 'RECORD_VIEW';

// SECTIONS
export const BILLING: ElementType = 'BILLING';
export const BREADCRUMBS: ElementType = 'BREADCRUMBS';
export const COLLECTION: ElementType = 'COLLECTION';
export const DETAILS: ElementType = 'DETAILS';
export const HIGHLIGHTS: ElementType = 'HIGHLIGHTS';
export const FILE_SHARING: ElementType = 'FILE_SHARING';
export const EMBED: ElementType = 'EMBED';
export const FORMS: ElementType = 'FORMS';
export const FORM: ElementType = 'FORM';
export const MESSAGING: ElementType = 'MESSAGING';
export const NOTICE: ElementType = 'NOTICE';
export const ONBOARDING_TASKS: ElementType = 'ONBOARDING_TASKS';
export const STAGES: ElementType = 'STAGES';
export const TABS: ElementType = 'TABS';
export const TITLE: ElementType = 'TITLE';
export const QUICK_LINKS: ElementType = 'QUICK_LINKS';
export const VIEW: ElementType = 'VIEW';
export const FILE_GALLERY: ElementType = 'FILE_GALLERY';
export const CONTAINER: ElementType = 'CONTAINER';

// COLLECTION ELEMENTS
export const RECORD: ElementType = 'RECORD';
export const MARKDOWN: ElementType = 'MARKDOWN';
export const SECTION: ElementType = 'SECTION';
export const FORM_SECTION: ElementType = 'FORM_SECTION';
export const FIELD_CELL: ElementType = 'FIELD_CELL';

export const elements = [
  TITLE,
  COLLECTION,
  DETAILS,
  HIGHLIGHTS,
  VIDEO,
  IFRAME,
  STAGES,
  CHART,
  ACTION_BUTTONS,
  QUICK_LINKS,
  MARKDOWN,
  FILE_GALLERY,
  NOTICE,
  DIVIDER,
  COMMENTS,
  IMAGE,
  EMBED,
  CONTAINER,
];

export type ElementType =
  | 'API_FORM'
  | 'APP_LAYOUT'
  | 'BADGE'
  | 'BUTTON'
  | 'CHART'
  | 'CONTENT'
  | 'COMMENTS'
  | 'COPY'
  | 'DATA_WRAPPER'
  | 'FORGOT_PASSWORD'
  | 'FORM:V2'
  | 'FOLDER'
  | 'GROUP'
  | 'ICON'
  | 'IFRAME'
  | 'IMAGE'
  | 'LABEL'
  | 'LIST'
  | 'LINK'
  | 'PROGRESS_BAR'
  | 'PROGRESS_RING'
  | 'DIVIDER'
  | 'PAGE'
  | 'PAGE_SWITCH'
  | 'RELATIVE_DATE'
  | 'TEXT'
  | 'VIDEO'
  | 'YOUTUBE_VIDEO'
  | 'ACTION_BUTTONS'
  | 'NEW_RECORD_BUTTON'
  | 'IMPORT_BUTTON'
  | 'EXPORT_BUTTON'
  | 'RECORD_VIEW'
  | 'BILLING'
  | 'BREADCRUMBS'
  | 'COLLECTION'
  | 'DETAILS'
  | 'HIGHLIGHTS'
  | 'FILE_SHARING'
  | 'EMBED'
  | 'FORMS'
  | 'FORM'
  | 'MESSAGING'
  | 'NOTICE'
  | 'ONBOARDING_TASKS'
  | 'STAGES'
  | 'TABS'
  | 'TITLE'
  | 'QUICK_LINKS'
  | 'VIEW'
  | 'FILE_GALLERY'
  | 'CONTAINER'
  | 'RECORD'
  | 'MARKDOWN'
  | 'SECTION'
  | 'FORM_SECTION'
  | 'FIELD_CELL';
