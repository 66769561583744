import createReactComponent from '../createReactComponent';
export default createReactComponent('user-off', 'IconUserOff', [
  [
    'path',
    {
      d: 'M8.18 8.189a4.01 4.01 0 0 0 2.616 2.627m3.507 -.545a4 4 0 1 0 -5.59 -5.552',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M6 21v-2a4 4 0 0 1 4 -4h4c.412 0 .81 .062 1.183 .178m2.633 2.618c.12 .38 .184 .785 .184 1.204v2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
