import createReactComponent from '../createReactComponent';
export default createReactComponent('smoking-no', 'IconSmokingNo', [
  ['path', { d: 'M8 13l0 4', key: 'svg-0' }],
  ['path', { d: 'M16 5v.5a2 2 0 0 0 2 2a2 2 0 0 1 2 2v.5', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M17 13h3a1 1 0 0 1 1 1v2c0 .28 -.115 .533 -.3 .714m-3.7 .286h-13a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h9',
      key: 'svg-3',
    },
  ],
]);
