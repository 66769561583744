import createReactComponent from '../createReactComponent';
export default createReactComponent('variable-plus', 'IconVariablePlus', [
  [
    'path',
    {
      d: 'M5 4c-2.5 5 -2.5 10 0 16m14 -16c1.38 2.76 2 5.52 1.855 8.448m-11.855 -3.448h1c1 0 1 1 2.016 3.527c.785 1.972 .944 3.008 1.483 3.346',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 16c1.5 0 3 -2 4 -3.5s2.5 -3.5 4 -3.5', key: 'svg-1' }],
  ['path', { d: 'M16 19h6', key: 'svg-2' }],
  ['path', { d: 'M19 16v6', key: 'svg-3' }],
]);
