import { IconGripVertical } from '@tabler/icons-react';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { ItemTypes } from '../../../constants/buildMode';
import { FOLDER, VIEW } from '../../../constants/elements';
import Icon from '../../../elements/Icon';
import { DataType } from '../../../models/DataTypes';
import { setLeftEditorSection } from '../../../reducers/elements';
import { getText } from '../../../utils/lang';

type NewDraggableItemProps = {
  dataType?: DataType;
  item?: {
    icon?: string;
    label: string;
    onClick: () => void;
    type: string;
  };
  onAddView?: (dataType: DataType) => void;
  setNewViewOpen: (newViewOpen: boolean) => void;
};

const LANG_KEY = 'leftSidebar.pages.newPageOptions';

const NewDraggableItem = ({
  dataType,
  item,
  onAddView,
  setNewViewOpen,
}: NewDraggableItemProps) => {
  const dispatch = useDispatch();
  const [_, dragRef] = useDrag({
    collect: (monitor) => ({ draggedItem: monitor.getItem() }),
    item: {
      new: true,
      page: {
        props: {
          dataList: {
            dataSource: dataType?.source.type,
            dataType: dataType?.name,
          },
        },
        type: item ? item.type : VIEW,
      },
      type: item?.type === FOLDER ? ItemTypes.FOLDER : ItemTypes.SINGLE_ITEM,
    },
    type: ItemTypes.SINGLE_ITEM,
  });

  return (
    <button
      className="group flex w-full items-center rounded-lg p-1 text-slate-400 hover:bg-slate-600 hover:text-slate-200"
      key={item?.label}
      onClick={() => {
        if (item) {
          item.onClick();
        } else {
          dataType && onAddView && onAddView(dataType);
        }
        setNewViewOpen(false);
        dispatch(setLeftEditorSection(null));
      }}
      ref={dragRef}
    >
      <IconGripVertical className="mr-2 hidden h-4 w-4 cursor-move group-hover:flex" />
      {item?.icon && (
        <Icon
          icon={{ name: item.icon }}
          className="mr-2 flex h-4 w-4 group-hover:hidden"
        />
      )}
      <span>{item ? getText(LANG_KEY, item.label) : dataType?.display}</span>
    </button>
  );
};

export default NewDraggableItem;
