import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-kick', 'IconBrandKick', [
  [
    'path',
    {
      d: 'M4 4h5v4h3v-2h2v-2h6v4h-2v2h-2v4h2v2h2v4h-6v-2h-2v-2h-3v4h-5z',
      key: 'svg-0',
    },
  ],
]);
