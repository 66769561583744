import createReactComponent from '../createReactComponent';
export default createReactComponent('shoe', 'IconShoe', [
  [
    'path',
    {
      d: 'M4 6h5.426a1 1 0 0 1 .863 .496l1.064 1.823a3 3 0 0 0 1.896 1.407l4.677 1.114a4 4 0 0 1 3.074 3.89v2.27a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14 13l1 -2', key: 'svg-1' }],
  ['path', { d: 'M8 18v-1a4 4 0 0 0 -4 -4h-1', key: 'svg-2' }],
  ['path', { d: 'M10 12l1.5 -3', key: 'svg-3' }],
]);
