import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-big-up-lines',
  'IconArrowBigUpLines',
  [
    [
      'path',
      {
        d: 'M9 12h-3.586a1 1 0 0 1 -.707 -1.707l6.586 -6.586a1 1 0 0 1 1.414 0l6.586 6.586a1 1 0 0 1 -.707 1.707h-3.586v3h-6v-3z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 21h6', key: 'svg-1' }],
    ['path', { d: 'M9 18h6', key: 'svg-2' }],
  ],
);
