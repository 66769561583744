import { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { Project } from '@noloco/core/src/models/Project';
import { getPagesConfig } from '@noloco/core/src/utils/pages';
import { useMutateProjectElements, useUpdateElements } from './projectHooks';

export const useAddElements = (project: Project, skipWebSockets = false) => {
  const [updateElements] = useUpdateElements(project);
  const [mutateElements]: any = useMutateProjectElements();

  const { isV2, pagesPath } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project],
  );

  const currentPages = useMemo(
    () => (isV2 ? project.elements : get(project.elements, pagesPath, [])),
    [isV2, pagesPath, project.elements],
  );

  return useCallback(
    (newViews: any[]) => {
      const elements =
        currentPages.length === 1
          ? [...newViews, ...currentPages]
          : [...currentPages, ...newViews];

      if (!skipWebSockets) {
        updateElements(pagesPath, elements);
      } else {
        mutateElements(pagesPath, elements, project.name);
      }
    },
    [
      currentPages,
      mutateElements,
      pagesPath,
      project.name,
      skipWebSockets,
      updateElements,
    ],
  );
};
