import { createSelector } from '@reduxjs/toolkit';
import DataTypes from '../models/DataTypes';
import { Element } from '../models/Element';
import { MediaFile } from '../models/File';
import {
  Integrations,
  Project,
  ProjectCreator,
  ProjectSettings,
  ProjectUserRecord,
} from '../models/Project';
import { ProjectDomain } from '../models/Project';
import { ProjectUpdate } from '../reducers/project';

export type ProjectSelectorState = {
  project: {
    data: Project;
    undoStack: ProjectUpdate[];
    redoStack: ProjectUpdate[];
    draftDataTypes: undefined | DataTypes;
  };
};
const projectSelector = (state: ProjectSelectorState) => state.project;
export const projectDataSelector = (state: ProjectSelectorState) =>
  state.project.data;

export const dataTypesSelector: (state: ProjectSelectorState) => DataTypes =
  createSelector([projectDataSelector], (project) => project.dataTypes);

export const draftDataTypesSelector: (
  state: ProjectSelectorState,
) => DataTypes | undefined = createSelector(
  [projectSelector],
  (projectData) => projectData.draftDataTypes,
);

export const projectElementsSelector: (
  state: ProjectSelectorState,
) => Element[] = createSelector(
  [projectDataSelector],
  (project) => project.elements,
);

export const projectIntegrationsSelector: (
  state: ProjectSelectorState,
) => Integrations | undefined = createSelector(
  [projectDataSelector],
  (project) => project.integrations,
);

export const projectMediaSelector: (
  state: ProjectSelectorState,
) => MediaFile[] = createSelector(
  [projectDataSelector],
  (project) => project.media,
);

export const projectSettingsSelector: (
  state: ProjectSelectorState,
) => ProjectSettings = createSelector(
  [projectDataSelector],
  (project) => project.settings,
);

export const projectTitleSelector: (state: any) => string | undefined =
  createSelector([projectSettingsSelector], (settings) => settings.title);

export const projectNameSelector: (state: ProjectSelectorState) => string =
  createSelector([projectDataSelector], (project) => project.name);

export const projectIdSelector: (
  state: ProjectSelectorState,
) => string | number = createSelector(
  [projectDataSelector],
  (project) => project.id,
);

export const projectDomainsSelector: (
  state: ProjectSelectorState,
) => undefined | ProjectDomain[] = createSelector(
  [projectDataSelector],
  (project) => project.domains,
);

export const projectLiveSelector: (state: ProjectSelectorState) => boolean =
  createSelector([projectDataSelector], (project) => project.live);

export const hasUnpublishedChangesSelector: (
  state: ProjectSelectorState,
) => boolean | null = createSelector(
  [projectDataSelector],
  (project) => project.hasUnpublishedChanges,
);

export const publishedVersionSelector: (
  state: ProjectSelectorState,
) => number | null = createSelector(
  [projectDataSelector],
  (project) => project.publishedVersion,
);

export const projectVersionSelector: (
  state: ProjectSelectorState,
) => number | null = createSelector(
  [projectDataSelector],
  (project) => project.version,
);

export const projectCreatorSelector: (
  state: ProjectSelectorState,
) => ProjectCreator = createSelector(
  [projectDataSelector],
  (project) => project.creator,
);

export const projectUsersSelector: (
  state: ProjectSelectorState,
) => ProjectUserRecord[] | undefined = createSelector(
  [projectDataSelector],
  (project) => project.users,
);

export const undoStackSelector: (state: ProjectSelectorState) => any =
  createSelector([projectSelector], (projectState) => projectState.undoStack);

export const redoStackSelector: (state: ProjectSelectorState) => any =
  createSelector([projectSelector], (projectState) => projectState.redoStack);
