import React, { memo } from 'react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'elements.LIST.filterInput.mergeWarning';

const FilterMergeWarning = memo(() => (
  <div className="mb-2 flex rounded-lg bg-yellow-100 p-3">
    <IconAlertTriangle
      size={22}
      className="mt-1 flex-shrink-0 text-yellow-500"
    />
    <div className="ml-3 flex flex-col">
      <h2 className="text-sm font-medium text-yellow-800">
        {getText(LANG_KEY, 'title')}
      </h2>
      <p className="mt-2 text-xs text-yellow-700">
        {getText(LANG_KEY, 'message')}
      </p>
    </div>
  </div>
));

export default FilterMergeWarning;
