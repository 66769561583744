import createReactComponent from '../createReactComponent';
export default createReactComponent('message-check', 'IconMessageCheck', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10.99 19.206l-2.99 1.794v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-3' }],
]);
