import createReactComponent from '../createReactComponent';
export default createReactComponent('letter-c', 'IconLetterC', [
  [
    'path',
    {
      d: 'M18 9a5 5 0 0 0 -5 -5h-2a5 5 0 0 0 -5 5v6a5 5 0 0 0 5 5h2a5 5 0 0 0 5 -5',
      key: 'svg-0',
    },
  ],
]);
