import createReactComponent from '../createReactComponent';
export default createReactComponent('grid-pattern', 'IconGridPattern', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 8v8', key: 'svg-1' }],
  ['path', { d: 'M14 8v8', key: 'svg-2' }],
  ['path', { d: 'M8 10h8', key: 'svg-3' }],
  ['path', { d: 'M8 14h8', key: 'svg-4' }],
]);
