import React, { forwardRef, useMemo } from 'react';
import {
  TailwindThemeProvider,
  getTailwindClassNames,
  withTheme,
} from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import { LIGHT } from '../../constants/surface';
import BasePopover from './BasePopover';

type Props = {
  bg?: string;
  closeOnOutsideClick?: boolean;
  closeOnClick?: boolean;
  content: React.ReactNode;
  className?: string;
  delayHide?: number;
  disabled?: boolean;
  isOpen?: boolean;
  onOpenChange?: (...args: any[]) => any;
  offset?: number[];
  placement?: string;
  popoverClassName?: string;
  trigger?: 'click' | 'hover' | 'none';
  shadow?: string | boolean;
};

const Popover = forwardRef<any, Props>(
  // @ts-expect-error TS2345: Type 'ReactNode' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>> | null'. Type 'undefined' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>> | null'.
  (
    {
      bg,
      children,
      className,
      closeOnClick,
      closeOnOutsideClick,
      delayHide,
      // @ts-expect-error TS(2339): Property 'delayShow' does not exist on type 'Props... Remove this comment to see the full error message
      delayShow,
      disabled,
      isOpen,
      onOpenChange,
      placement,
      popoverClassName,
      offset,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
      onClick,
      content,
      // @ts-expect-error TS(2339): Property 'rounded' does not exist on type 'Props'.
      rounded,
      shadow,
      // @ts-expect-error TS(2339): Property 'surface' does not exist on type 'Props'.
      surface,
      trigger,
      // @ts-expect-error TS(2339): Property 'showArrow' does not exist on type 'Props... Remove this comment to see the full error message
      showArrow,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
      theme,
      // @ts-expect-error TS(2339): Property 'usePortal' does not exist on type 'Props... Remove this comment to see the full error message
      usePortal,
      ...rest
    },
    ref,
  ) => {
    const baseStyles = {
      bg: bg || theme.surfaceColors[surface],
    };
    const twClassNames = getTailwindClassNames({
      ...baseStyles,
      shadow,
      flex: [true, 'col'],
      items: 'center',
      p: { y: 2, x: 3 },
      z: 50,
      overflowY: 'auto',
      overflowX: 'hidden',
      maxH: 'screen-75',
      rounded,
      ...rest,
    });

    const lightTheme = useMemo(() => {
      if (surface === LIGHT && (!bg || bg === 'white')) {
        const lightThemeObject = {
          surface: LIGHT,
          textColor: 'text-gray-800',
        };
        return set(
          'textInput',
          lightThemeObject,
          set(
            'selectInput',
            lightThemeObject,
            set('dropzone', lightThemeObject, theme),
          ),
        );
      }

      return theme;
    }, [bg, surface, theme]);

    if (disabled) {
      return children;
    }

    return (
      <TailwindThemeProvider theme={lightTheme}>
        {/* @ts-expect-error TS(2322): Type '{ children: any; className: string | undefin... Remove this comment to see the full error message */}
        <BasePopover
          {...baseStyles}
          className={popoverClassName}
          closeOnClick={closeOnClick}
          closeOnOutsideClick={closeOnOutsideClick}
          delayHide={delayHide}
          delayShow={delayShow}
          placement={placement}
          offset={offset}
          trigger={trigger}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          showArrow={showArrow}
          content={
            <div
              className={classNames(twClassNames, className)}
              onClick={onClick}
            >
              {content}
            </div>
          }
          usePortal={usePortal}
          ref={ref}
        >
          {children}
        </BasePopover>
      </TailwindThemeProvider>
    );
  },
);

Popover.defaultProps = {
  className: '',
  closeOnOutsideClick: true,
  closeOnClick: false,
  delayHide: 0,
  // @ts-expect-error TS(2322): Type '{ className: string; closeOnOutsideClick: tr... Remove this comment to see the full error message
  delayShow: 0,
  disabled: false,
  placement: 'top',
  popoverClassName: '',
  offset: [-10, 8],
  trigger: 'click',
  rounded: 'lg',
  surface: LIGHT,
  showArrow: true,
  shadow: true,
};

export default withTheme(Popover);
