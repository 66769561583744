import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-rounded-number-2',
  'IconSquareRoundedNumber2',
  [
    [
      'path',
      {
        d: 'M10 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-1',
      },
    ],
  ],
);
