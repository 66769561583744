import createReactComponent from '../createReactComponent';
export default createReactComponent('premium-rights', 'IconPremiumRights', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13.867 9.75c-.246 -.48 -.708 -.769 -1.2 -.75h-1.334c-.736 0 -1.333 .67 -1.333 1.5c0 .827 .597 1.499 1.333 1.499h1.334c.736 0 1.333 .671 1.333 1.5c0 .828 -.597 1.499 -1.333 1.499h-1.334c-.492 .019 -.954 -.27 -1.2 -.75',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 7v2', key: 'svg-2' }],
  ['path', { d: 'M12 15v2', key: 'svg-3' }],
]);
