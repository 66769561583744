import createReactComponent from '../createReactComponent';
export default createReactComponent('regex', 'IconRegex', [
  [
    'path',
    { d: 'M6.5 15a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0 -5z', key: 'svg-0' },
  ],
  ['path', { d: 'M17 7.875l3 -1.687', key: 'svg-1' }],
  ['path', { d: 'M17 7.875v3.375', key: 'svg-2' }],
  ['path', { d: 'M17 7.875l-3 -1.687', key: 'svg-3' }],
  ['path', { d: 'M17 7.875l3 1.688', key: 'svg-4' }],
  ['path', { d: 'M17 4.5v3.375', key: 'svg-5' }],
  ['path', { d: 'M17 7.875l-3 1.688', key: 'svg-6' }],
]);
