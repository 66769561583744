import createReactComponent from '../createReactComponent';
export default createReactComponent('flag', 'IconFlag', [
  [
    'path',
    {
      d: 'M5 5a5 5 0 0 1 7 0a5 5 0 0 0 7 0v9a5 5 0 0 1 -7 0a5 5 0 0 0 -7 0v-9z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
]);
