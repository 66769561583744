import createReactComponent from '../createReactComponent';
export default createReactComponent('search-off', 'IconSearchOff', [
  [
    'path',
    {
      d: 'M5.039 5.062a7 7 0 0 0 9.91 9.89m1.584 -2.434a7 7 0 0 0 -9.038 -9.057',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
]);
