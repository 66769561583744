import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-up-right-circle',
  'IconArrowUpRightCircle',
  [
    ['path', { d: 'M8.464 15.536l9.536 -9.536', key: 'svg-0' }],
    ['path', { d: 'M18 10v-4h-4', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M8.414 15.586a2 2 0 1 0 -2.828 2.828a2 2 0 0 0 2.828 -2.828',
        key: 'svg-2',
      },
    ],
  ],
);
