import createReactComponent from '../createReactComponent';
export default createReactComponent('camera-up', 'IconCameraUp', [
  [
    'path',
    {
      d: 'M12 20h-7a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v3.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 16a3 3 0 1 0 0 -6a3 3 0 0 0 0 6z', key: 'svg-1' }],
  ['path', { d: 'M19 22v-6', key: 'svg-2' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-3' }],
]);
