import createReactComponent from '../createReactComponent';
export default createReactComponent('copy', 'IconCopy', [
  [
    'path',
    {
      d: 'M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2',
      key: 'svg-1',
    },
  ],
]);
