import { STRING } from '../../constants/elementPropTypeTypes';
import ElementPropType from './ElementPropType';

class StringType extends ElementPropType {
  multiLine: boolean;
  getDataTypes: () => any;

  constructor(includeSelf = false) {
    super(STRING);
    this.multiLine = false;
    this.includeSelf = includeSelf;
    this.getDataTypes = () => undefined;
  }

  setMultiLine(multiLine: boolean) {
    this.multiLine = multiLine;
    return this;
  }

  setGetDataTypes(getDataTypes: () => any) {
    this.getDataTypes = getDataTypes;
    return this;
  }
}

export default StringType;
