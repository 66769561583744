import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'dots-circle-horizontal',
  'IconDotsCircleHorizontal',
  [
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M8 12l0 .01', key: 'svg-1' }],
    ['path', { d: 'M12 12l0 .01', key: 'svg-2' }],
    ['path', { d: 'M16 12l0 .01', key: 'svg-3' }],
  ],
);
