import { useMemo } from 'react';
import get from 'lodash/get';
import { isBuilder, isDataAdmin, isInternal } from '../user';
import { useAuth } from './useAuth';
import useHasFeatureFlag, {
  LEGACY_IS_INTERNAL_FLAG,
} from './useHasFeatureFlag';

const useUserPermissions = (): {
  isInternal: boolean;
  isBuilder: boolean;
  isDataAdmin: boolean;
} => {
  const { user } = useAuth();
  const legacyIsInternal = useHasFeatureFlag(LEGACY_IS_INTERNAL_FLAG);

  return useMemo(() => {
    if (user) {
      if (legacyIsInternal) {
        return {
          isInternal: isInternal(user),
          isBuilder: isBuilder(user),
          isDataAdmin: isDataAdmin(user),
        };
      }

      return {
        isInternal: !!get(user, 'role.internal', false),
        isBuilder: !!get(user, 'role.builder', false),
        isDataAdmin: !!get(user, 'role.dataAdmin', false),
      };
    }

    return {
      isInternal: false,
      isBuilder: false,
      isDataAdmin: false,
    };
  }, [legacyIsInternal, user]);
};

export default useUserPermissions;
