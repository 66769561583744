import createReactComponent from '../createReactComponent';
export default createReactComponent('router-off', 'IconRouterOff', [
  [
    'path',
    {
      d: 'M17 13h2a2 2 0 0 1 2 2v2m-.588 3.417c-.362 .36 -.861 .583 -1.412 .583h-14a2 2 0 0 1 -2 -2v-4a2 2 0 0 1 2 -2h8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 17v.01', key: 'svg-1' }],
  ['path', { d: 'M13 17v.01', key: 'svg-2' }],
  ['path', { d: 'M12.226 8.2a4 4 0 0 1 6.024 .55', key: 'svg-3' }],
  ['path', { d: 'M9.445 5.407a8 8 0 0 1 12.055 1.093', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
