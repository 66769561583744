import { Omit } from 'lodash';
import { DataFieldIdentifier } from '../models/ProjectArrayTypeMap';
import { DECIMAL, DataFieldType, INTEGER, TEXT } from './dataTypes';
import { Relationship } from './relationships';

export const ARRAY: DataSchemaFieldType = 'ARRAY';
export const NESTED: DataSchemaFieldType = 'NESTED';

export type DataSchemaFieldType = DataFieldType | string | 'ARRAY' | 'NESTED';

export interface DataSchemaFieldOption {
  display: string;
}
export interface DataSchemaField extends Omit<DataFieldIdentifier, 'id'> {
  display: string;
  options?: DataSchemaFieldOption[];
  path: string[];
  relationship: Relationship | null;
  type: DataSchemaFieldType;
}

// Relationships

export type RelationshipOption = {
  label: JSX.Element;
  value: string;
};

export const ID_FIELD_TYPES = [DECIMAL, INTEGER, TEXT];
