import React from 'react';
import { useDispatch } from 'react-redux';
import { setNewElementPath } from '../reducers/elements';
import { getText } from '../utils/lang';

const DummyBlock = ({ onClick, disabled, elementPath }: any) => {
  const dispatch = useDispatch();
  return (
    <div className="border-1 mx-auto my-4 flex h-64 w-full cursor-pointer items-center justify-center p-8 text-center">
      <div
        onClick={(e) => {
          if (!disabled) {
            e.stopPropagation();
            if (onClick) {
              onClick(e);
            }
            if (elementPath) {
              dispatch(setNewElementPath(elementPath));
            }
          }
        }}
        className="bg:opacity-25 flex h-full w-full items-center justify-center rounded-lg border-4 border-dashed p-20 hover:bg-gray-100"
      >
        {getText('elements.empty')}
      </div>
    </div>
  );
};

export default DummyBlock;
