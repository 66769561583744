import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-onlyfans', 'IconBrandOnlyfans', [
  [
    'path',
    { d: 'M8.5 6a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0 -13z', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M8.5 15a2.5 2.5 0 1 1 0 -5a2.5 2.5 0 0 1 0 5z', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M14 16c2.5 0 6.42 -1.467 7 -4h-6c3 -1 6.44 -3.533 7 -6h-4c-3.03 0 -3.764 -.196 -5 1.5',
      key: 'svg-2',
    },
  ],
]);
