export const ROUNDED_LARGE = 'rounded-large';
export const ROUNDED_FULL = 'rounded-full';
export const SQUARE = 'square';
export const UPPERCASE = 'uppercase';
export const SHADOW = 'shadow';

const buttonStyles: ButtonStyle[] = [
  ROUNDED_LARGE,
  ROUNDED_FULL,
  SQUARE,
  UPPERCASE,
  SHADOW,
];

export type ButtonStyle =
  | 'rounded-large'
  | 'rounded-full'
  | 'square'
  | 'uppercase'
  | 'shadow';
export default buttonStyles;
