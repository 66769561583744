import camelCase from 'lodash/camelCase';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { FOLDER, PAGE, PAGE_SWITCH, VIEW } from '../constants/elements';
import { IS_PRODUCTION, IS_QA } from '../constants/env';
import { Page as CorePageModel } from '../models/AppNavigation';
import { Element, ElementPath, StringPropValue } from '../models/Element';
import { ProjectSettings } from '../models/Project';
import { lookupOfArray } from './arrays';

type PageGroupAndPath = {
  group: Element;
  elementPath: ElementPath;
};

export type Page = CorePageModel;

export const pagePathIdIsRecordPage = (pagePathId: string) =>
  pagePathId.includes(':RECORD');

export const getChildPageGroupAndPath = (
  elements: Element[] | undefined,
  elementPath: ElementPath = [],
): null | PageGroupAndPath => {
  const els = elements && Array.isArray(elements) ? elements : [];
  for (let index = 0; index < els.length; index++) {
    const element = els[index];

    if (element && element.type === PAGE_SWITCH) {
      return { group: element, elementPath: [...elementPath, index] };
    }

    if (element && element.children) {
      const childGroups = getChildPageGroupAndPath(element.children, [
        ...elementPath,
        index,
        'children',
      ]);

      if (childGroups) {
        return childGroups;
      }
    }
  }
  return null;
};

const strinigyStringProp = (stringProp: StringPropValue) => {
  if (!stringProp) {
    return '';
  }

  if (!Array.isArray(stringProp)) {
    return String(stringProp);
  }

  return stringProp
    .map((t) => t.text)
    .filter(Boolean)
    .join('');
};

export const getPages = (
  elements: Element[] | undefined,
  pagePath: ElementPath = [],
  elementPath: ElementPath = [],
): Page[] => {
  if (!elements || !Array.isArray(elements)) {
    return [];
  }

  const elementMap = lookupOfArray(elements, 'id');
  return elements.reduce((pageGroups, element, index) => {
    if (!element) {
      return pageGroups;
    }

    if (
      element.type === PAGE ||
      element.type === VIEW ||
      element.type === FOLDER
    ) {
      const { group, elementPath: groupElementPath } =
        getChildPageGroupAndPath(element.children, [
          ...elementPath,
          index,
          'children',
        ]) || {};

      const children = !group
        ? []
        : getPages(
            group.children,
            [...pagePath, element.id],
            [...(groupElementPath as ElementPath), 'children'],
          );

      const subPages = get(element, 'props.SubPages', []);

      let page = {
        ...element,
        hasChildGroup: !!group,
        childGroupPath: groupElementPath,
        pagePath: [...pagePath, element.id],
        elementPath: [...elementPath, index],
        children,
      };

      const parentPageId = get(element, 'props.parentPage');
      const parentPage = parentPageId && elementMap && elementMap[parentPageId];

      if (element.type === VIEW && (!parentPage || parentPage.type !== VIEW)) {
        page.children.push({
          ...set(
            'props',
            {
              ...get(page, 'props'),
              name: strinigyStringProp(get(page, 'props.new.title', [])),
              routePath: 'new',
            },
            page,
          ),
          id: 'new',
          children: undefined,
          pagePath: [...pagePath, element.id, 'new'],
          elementPath: [...elementPath, index, 'props', 'new'],
        });
        page.children.push({
          ...set(
            'props',
            {
              ...get(page, 'props'),
              name: `${get(page, 'props.name', '')} Record`,
              routePath: 'view',
              dataType: get(page, 'props.dataList.dataType'),
              dataProperty: 'uuid',
            },
            page,
          ),
          id: `${page.id}:RECORD`,
          children: undefined,
          pagePath: [...pagePath, element.id, `${page.id}:RECORD`],
          elementPath: [...elementPath, index, 'props', 'record'],
        });
      }

      return [
        ...pageGroups,
        page,
        ...subPages.map((subPage: Element, subIndex: number) => ({
          ...subPage,
          hasChildGroup: false,
          childGroupPath: null,
          pagePath: [...pagePath, subPage.id],
          elementPath: [...elementPath, index, 'props', 'SubPages', subIndex],
          parent: page,
          children: [],
        })),
      ];
    }

    return [
      ...pageGroups,
      ...getPages(element.children, pagePath, [
        ...elementPath,
        index,
        'children',
      ]),
    ];
  }, [] as Page[]);
};

export const findPageInPath = (
  pageItemId: string,
  pagePath: ElementPath,
  pages: Page[] = [],
): null | Page => {
  const page = pages.find(({ id }) => id === pageItemId);
  if (page) {
    return page;
  }

  for (let index = 0; index < pages.length; index++) {
    const pageX = pages[index];
    if (pagePath.includes(pageX.id)) {
      return findPageInPath(pageItemId, pagePath, pageX.children);
    }
  }

  return null;
};

export const findPageById = (
  pageItemId: string,
  pages: Page[] = [],
): null | Page => {
  const page = pages.find(({ id }) => id === pageItemId);
  if (page) {
    return page;
  }

  for (let index = 0; index < pages.length; index++) {
    const pageX = pages[index];
    const childPage = findPageById(pageItemId, pageX.children);
    if (childPage) {
      return childPage;
    }
  }

  return null;
};

export const getPageParamName = (
  dataTypeName: string,
  dataProperty: string,
): string =>
  dataTypeName && dataProperty && camelCase(`${dataTypeName} ${dataProperty}`);

export const replaceDoubleSlashes = (path: string): string =>
  path.replace(/\/+/g, '/');

export const getApiEndpointPagePath = (
  path: string,
  routePath: string,
  endpoint: any,
) => {
  const parameters = endpoint.parameters.map(
    (parameter: any) => `:${parameter.name}`,
  );
  const combinedPathAr = [path !== '/' ? path : '', routePath, ...parameters];
  const combinedPath = combinedPathAr.filter(Boolean).join('/');
  return {
    path: replaceDoubleSlashes(
      combinedPath.startsWith('/') || combinedPath.length === 0
        ? combinedPath
        : `/${combinedPath}`,
    ),
    parameters,
  };
};

export const getPagePath = (
  path: string,
  routePath: string,
  dataTypeName?: string,
  dataProperty?: string,
): { path: string; property: string | undefined } => {
  const property =
    dataTypeName &&
    dataProperty &&
    getPageParamName(dataTypeName, dataProperty);

  const combinedPathAr = [
    path !== '/' ? path : '',
    routePath,
    property ? `:${property}` : undefined,
  ];
  const combinedPath = combinedPathAr.filter(Boolean).join('/');
  return {
    path: replaceDoubleSlashes(
      combinedPath.startsWith('/') ? combinedPath : `/${combinedPath}`,
    ),
    property,
  };
};

export const getBaseProjectDomain = (): string => {
  if (IS_PRODUCTION) {
    return `noloco.co`;
  } else if (IS_QA) {
    return 'nolocoqa.app';
  } else {
    return 'nolocolocal.dev';
  }
};
export const getProjectDomain = (projectName: string): string => {
  return `https://${projectName}.${getBaseProjectDomain()}`;
};

export const getCurrentPageFromPathname = (
  projectPages: Element[],
  pathname: string,
): Element | undefined => {
  const splitPathname = pathname.replace(/^\//, '').split('/');
  const firstPage = projectPages.find(
    (page) => page?.props?.routePath === first(splitPathname),
  );

  if (splitPathname.length === 1) {
    return firstPage;
  } else if (firstPage && firstPage.id && splitPathname.length > 1) {
    const childPage = projectPages.find(
      (page) =>
        page?.props?.parentPage === firstPage.id &&
        page?.props?.routePath === splitPathname[1],
    );

    if (childPage) {
      return childPage;
    }
  }

  return firstPage;
};
export const getPagesConfig = (
  elements: Element[],
  settings: ProjectSettings,
): {
  isV2: boolean;
  portalPath: ElementPath;
  pagesPath: ElementPath;
  projectPages: Element[];
} => {
  const isV2 = get(settings, 'flags.v2', false);
  const portalPageIndex = isV2
    ? -1
    : get(elements, '0.children', []).length - 1;
  const portalPath = isV2
    ? []
    : `0.children.${portalPageIndex}.children.0`.split('.');
  const pagesPath = [...portalPath, ...(isV2 ? [] : ['children'])];

  return {
    isV2,
    portalPath,
    pagesPath,
    projectPages: isV2 ? elements : get(elements, pagesPath, []),
  };
};
