import createReactComponent from '../createReactComponent';
export default createReactComponent('photo-sensor', 'IconPhotoSensor', [
  ['path', { d: 'M17 5h2a2 2 0 0 1 2 2v2', key: 'svg-0' }],
  ['path', { d: 'M21 15v2a2 2 0 0 1 -2 2h-2', key: 'svg-1' }],
  ['path', { d: 'M7 19h-2a2 2 0 0 1 -2 -2v-2', key: 'svg-2' }],
  ['path', { d: 'M3 9v-2a2 2 0 0 1 2 -2h2', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M7 9m0 1a1 1 0 0 1 1 -1h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1z',
      key: 'svg-4',
    },
  ],
]);
