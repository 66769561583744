import createReactComponent from '../createReactComponent';
export default createReactComponent('section', 'IconSection', [
  ['path', { d: 'M20 20h.01', key: 'svg-0' }],
  ['path', { d: 'M4 20h.01', key: 'svg-1' }],
  ['path', { d: 'M8 20h.01', key: 'svg-2' }],
  ['path', { d: 'M12 20h.01', key: 'svg-3' }],
  ['path', { d: 'M16 20h.01', key: 'svg-4' }],
  ['path', { d: 'M20 4h.01', key: 'svg-5' }],
  ['path', { d: 'M4 4h.01', key: 'svg-6' }],
  ['path', { d: 'M8 4h.01', key: 'svg-7' }],
  ['path', { d: 'M12 4h.01', key: 'svg-8' }],
  ['path', { d: 'M16 4l0 .01', key: 'svg-9' }],
  [
    'path',
    {
      d: 'M4 8m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z',
      key: 'svg-10',
    },
  ],
]);
