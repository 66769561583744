import createReactComponent from '../createReactComponent';
export default createReactComponent('vector', 'IconVector', [
  [
    'path',
    {
      d: 'M3 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M17 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M3 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M5 7l0 10', key: 'svg-4' }],
  ['path', { d: 'M19 7l0 10', key: 'svg-5' }],
  ['path', { d: 'M7 5l10 0', key: 'svg-6' }],
  ['path', { d: 'M7 19l10 0', key: 'svg-7' }],
]);
