import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'clock-exclamation',
  'IconClockExclamation',
  [
    ['path', { d: 'M20.986 12.502a9 9 0 1 0 -5.973 7.98', key: 'svg-0' }],
    ['path', { d: 'M12 7v5l3 3', key: 'svg-1' }],
    ['path', { d: 'M19 16v3', key: 'svg-2' }],
    ['path', { d: 'M19 22v.01', key: 'svg-3' }],
  ],
);
