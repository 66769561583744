import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'message-circle-pin',
  'IconMessageCirclePin',
  [
    [
      'path',
      {
        d: 'M12.337 19.974a9.891 9.891 0 0 1 -4.637 -.974l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c1.63 1.39 2.554 3.21 2.736 5.085',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M19 18v.01', key: 'svg-2' }],
  ],
);
