import React, { forwardRef, useState } from 'react';
import classNames from 'classnames';
import { Button, Modal } from '@noloco/components';
import { UPDATE } from '../../constants/actionTypes';
import useRouter from '../../utils/hooks/useRouter';
import { getText } from '../../utils/lang';
import DeleteItemConfirmButton from './DeleteItemConfirmButton';
import FormSection from './FormSection';
import AdditionalElements from './collections/AdditionalElements';

type Props = {};

const Details = forwardRef<any, Props>(
  (
    {
      // @ts-expect-error TS(2339): Property 'additionalElements' does not exist on ty... Remove this comment to see the full error message
      additionalElements,
      // @ts-expect-error TS(2339): Property 'allowDeleting' does not exist on type 'P... Remove this comment to see the full error message
      allowDeleting,
      // @ts-expect-error TS(2339): Property 'allowEditing' does not exist on type 'Pr... Remove this comment to see the full error message
      allowEditing,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
      className,
      // @ts-expect-error TS(2339): Property 'confirmDeleteText' does not exist on typ... Remove this comment to see the full error message
      confirmDeleteText,
      // @ts-expect-error TS(2339): Property 'dataType' does not exist on type 'Props'... Remove this comment to see the full error message
      dataType,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Prop... Remove this comment to see the full error message
      editorMode,
      // @ts-expect-error TS(2339): Property 'editButtonText' does not exist on type '... Remove this comment to see the full error message
      editButtonText,
      // @ts-expect-error TS(2339): Property 'elementId' does not exist on type 'Props... Remove this comment to see the full error message
      elementId,
      // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type 'Pro... Remove this comment to see the full error message
      elementPath,
      // @ts-expect-error TS(2339): Property 'fields' does not exist on type 'Props'.
      fields: formFields,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
      onClick,
      // @ts-expect-error TS(2339): Property 'pageAfterDelete' does not exist on type ... Remove this comment to see the full error message
      pageAfterDelete,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Props'.
      project,
      // @ts-expect-error TS(2339): Property 'title' does not exist on type 'Props'.
      title,
      // @ts-expect-error TS(2339): Property 'scope' does not exist on type 'Props'.
      scope,
      // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'Props'... Remove this comment to see the full error message
      subtitle,
      // @ts-expect-error TS(2339): Property 'which' does not exist on type 'Props'.
      which,
    },
    ref,
  ) => {
    const [showEditModal, setShowEditModal] = useState(false);

    const { push } = useRouter();
    const closeDataItemModal = () => {
      setShowEditModal(false);
    };

    const editableDataType =
      (allowEditing || allowDeleting) &&
      dataType &&
      project.dataTypes.getByName(dataType);

    return (
      <div className={classNames(className)} ref={ref} onClick={onClick}>
        <div className="flex flex-col rounded-lg border border-gray-200 bg-white shadow-md">
          {(!title.hidden || !subtitle.hidden || allowEditing) && (
            <div className="border-b border-gray-100 p-8">
              {(!title.hidden || allowEditing) && (
                <div className="flex items-center justify-between">
                  {!title.hidden && (
                    <h2 className="text-2xl font-medium">{title.value}</h2>
                  )}
                  <div className="flex items-center">
                    {allowEditing && (
                      <Button
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowEditModal(true);
                          return false;
                        }}
                      >
                        {editButtonText ||
                          getText('core.COLLECTION.form.editButton')}
                      </Button>
                    )}
                    {allowDeleting && editableDataType && which && (
                      <DeleteItemConfirmButton
                        className={classNames({ 'ml-2': allowEditing })}
                        confirmDeleteText={confirmDeleteText}
                        dataType={editableDataType}
                        project={project}
                        onDelete={() => {
                          if (
                            !editorMode &&
                            pageAfterDelete &&
                            pageAfterDelete.to
                          ) {
                            push(pageAfterDelete.to);
                          }
                        }}
                        itemId={which}
                      >
                        {getText('core.COLLECTION.form.deleteButton')}
                      </DeleteItemConfirmButton>
                    )}
                  </div>
                </div>
              )}
              {!subtitle.hidden && (
                <p className="mt-2 text-gray-500">{subtitle.value}</p>
              )}
            </div>
          )}
          <div className="flex flex-wrap p-8 sm:flex-col">
            <AdditionalElements
              // @ts-expect-error TS(2322): Type '{ className: string; innerClassName: string;... Remove this comment to see the full error message
              className="grid w-full grid-cols-2 gap-x-8 gap-y-4 sm:grid-cols-1"
              innerClassName="flex flex-col min-w-0"
              elementPath={elementPath}
              editorMode={editorMode}
              project={project}
              additionalElements={additionalElements}
              scope={scope}
            />
          </div>
        </div>
        {allowEditing && which && showEditModal && formFields && dataType && (
          <Modal
            onClose={closeDataItemModal}
            onCancel={closeDataItemModal}
            onConfirm={() => {
              const form = document.querySelector(
                `.element-${elementId}-form form`,
              );
              if (form) {
                form.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                );
              }
            }}
            title={getText(
              { dataType: dataType.display },
              'core.COLLECTION.form.edit',
            )}
            confirmText={getText('core.COLLECTION.form.save')}
            variant="primary"
          >
            <FormSection
              className={`element-${elementId}-form`}
              dataType={editableDataType.name}
              fields={formFields}
              T
              editorMode={editorMode}
              type={UPDATE}
              which={which}
              onSuccess={() => {
                console.log('Success');
              }}
              successMessage={{
                message: getText(
                  { dataType: editableDataType.display },
                  'core.COLLECTION.form.success',
                ),
              }}
              errorMessage={{
                message: getText(
                  { dataType: editableDataType.display },
                  'core.COLLECTION.form.error',
                ),
              }}
              project={project}
            />
          </Modal>
        )}
      </div>
    );
  },
);

Details.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ additionalElements: never[]; }' is not ass... Remove this comment to see the full error message
  additionalElements: [],
};

export default Details;
