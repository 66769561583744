import createReactComponent from '../createReactComponent';
export default createReactComponent('box-align-left', 'IconBoxAlignLeft', [
  [
    'path',
    {
      d: 'M9.752 19.753v-16h-5a1 1 0 0 0 -1 1v14a1 1 0 0 0 1 1h5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14.752 19.753h-.01', key: 'svg-1' }],
  ['path', { d: 'M19.753 19.753h-.011', key: 'svg-2' }],
  ['path', { d: 'M19.753 14.752h-.011', key: 'svg-3' }],
  ['path', { d: 'M19.753 8.752h-.011', key: 'svg-4' }],
  ['path', { d: 'M19.753 3.752h-.011', key: 'svg-5' }],
  ['path', { d: 'M14.752 3.752h-.01', key: 'svg-6' }],
]);
