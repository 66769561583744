import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circuit-voltmeter',
  'IconCircuitVoltmeter',
  [
    ['path', { d: 'M12 12m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0', key: 'svg-0' }],
    ['path', { d: 'M5 12h-3', key: 'svg-1' }],
    ['path', { d: 'M19 12h3', key: 'svg-2' }],
    ['path', { d: 'M10 10l2 4l2 -4', key: 'svg-3' }],
  ],
);
