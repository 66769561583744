import React, { useCallback } from 'react';
import { IconApps } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { Modal } from '@noloco/components';
import { projectTitleSelector } from '../../../selectors/projectSelectors';
import { getText } from '../../../utils/lang';

type AppInstallationModalProps = {
  deferredPrompt: any;
  setDeferredPrompt: (prompt: any) => void;
  setShowInstallPrompt: (showInstallPrompt: boolean) => void;
};

const LANG_KEY = 'settings.pwa.installation';

const AppInstallationModal = ({
  deferredPrompt,
  setDeferredPrompt,
  setShowInstallPrompt,
}: AppInstallationModalProps) => {
  const title = useSelector(projectTitleSelector);

  const dismissPrompt = useCallback(() => {
    setDeferredPrompt(null);
    setShowInstallPrompt(false);
  }, [setDeferredPrompt, setShowInstallPrompt]);

  const installApp = useCallback(async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      dismissPrompt();
    }
  }, [deferredPrompt, dismissPrompt]);

  return (
    <Modal
      cancelText={getText(LANG_KEY, 'notNow')}
      confirmText={getText(LANG_KEY, 'install')}
      icon={<IconApps size={20} />}
      onCancel={() => dismissPrompt()}
      onConfirm={() => installApp()}
      title={getText({ title }, LANG_KEY, 'title')}
      variant="primary"
    >
      <p>{getText(LANG_KEY, 'description')}</p>
    </Modal>
  );
};

export default AppInstallationModal;
