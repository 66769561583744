import createReactComponent from '../createReactComponent';
export default createReactComponent('droplet-code', 'IconDropletCode', [
  [
    'path',
    {
      d: 'M18.907 13.147a6.586 6.586 0 0 0 -.843 -2.27l-4.89 -7.26c-.42 -.625 -1.287 -.803 -1.936 -.397a1.376 1.376 0 0 0 -.41 .397l-4.893 7.26c-1.695 2.838 -1.035 6.441 1.567 8.546a7.123 7.123 0 0 0 3.99 1.561',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-1' }],
  ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-2' }],
]);
