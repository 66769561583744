type Option = {
  value: any;
  label: any;
  options?: Option[];
  getOptions?: () => Option[];
};

export const findOption = (
  options: Option[],
  value: any,
  selectedValue: any,
): Option | undefined => {
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (option.options) {
      const potentialOption = findOption(option.options, value, selectedValue);
      if (potentialOption) {
        return potentialOption;
      }
    } else if (option.getOptions) {
      const potentialOption: any = findOption(
        option.getOptions(),
        value,
        selectedValue,
      );
      if (potentialOption) {
        return potentialOption;
      }
    } else {
      if (option.value === value || option.value === selectedValue) {
        return option;
      }
    }
  }
};

export const safeValue = (value: any) =>
  typeof value !== 'object' ? value : 'Invalid value';
