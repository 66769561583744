import createReactComponent from '../createReactComponent';
export default createReactComponent('writing-off', 'IconWritingOff', [
  ['path', { d: 'M16 7h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M16 16v1l2 2l.5 -.5m1.5 -2.5v-11c0 -1.121 -.879 -2 -2 -2s-2 .879 -2 2v7',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M18 19h-13a2 2 0 1 1 0 -4h4a2 2 0 1 0 0 -4h-3', key: 'svg-2' },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
