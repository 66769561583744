import { useCallback } from 'react';
import classNames from 'classnames';
import { darkModeColors } from '../../../constants/darkModeColors';
import DataTypes, { DataType } from '../../../models/DataTypes';
import { RecordEdge } from '../../../models/Record';
import { CollectionField } from '../../../models/View';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { FieldConfig } from '../../../utils/permissions';
import { axisFormatter } from '../../Chart';
import CollectionColumnSummaryCell from './CollectionColumnSummaryCell';

interface TableSummaryFooterProps {
  bulkActionsEnabled?: boolean;
  className: string;
  dataType?: DataType;
  dataTypes?: DataTypes;
  edges?: RecordEdge[];
  fieldConfigs?: FieldConfig<CollectionField>[];
  summaryRow?: (string | number)[];
}

const TableSummaryFooter = ({
  bulkActionsEnabled = false,
  className,
  dataType,
  dataTypes,
  edges = [],
  fieldConfigs = [],
  summaryRow,
}: TableSummaryFooterProps) => {
  const [isDarkModeEnabled] = useDarkMode();

  const valueFormatter = useCallback(
    (field, rawValue) =>
      axisFormatter(
        field,
        dataType!,
        dataTypes!,
        edges.map(({ node }) => node),
      )(rawValue),
    [dataType, edges, dataTypes],
  );

  return (
    <tr
      className={classNames(
        className,
        'shadow-xs sticky z-20 border-b text-left text-xs font-medium uppercase tracking-wider',
        `${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation0} ${darkModeColors.text.primary} ${darkModeColors.borders.one} ${darkModeColors.divides.one}`
            : 'shadow-t border-gray-200 bg-gray-50 text-gray-800'
        }`,
      )}
    >
      {summaryRow &&
        summaryRow.map((cell, index) => {
          const isFirst = index === 0;
          const isLast = index === summaryRow.length - 1;

          return (
            <td
              className={classNames({
                'pl-6 pr-3': isFirst,
                'pr-6': isLast,
                'px-3': !isLast && !isFirst,
                'text-right': !isFirst,
              })}
              key={cell}
            >
              {cell ?? 0}
            </td>
          );
        })}
      {bulkActionsEnabled && <td className="w-9"></td>}
      {fieldConfigs.map(({ field, parent, config }) => (
        <CollectionColumnSummaryCell
          className="py-1"
          config={config}
          field={field}
          key={field.id}
          parent={parent}
          rows={edges}
          valueFormatter={valueFormatter}
        />
      ))}
    </tr>
  );
};

export default TableSummaryFooter;
