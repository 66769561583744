import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circle-chevrons-down',
  'IconCircleChevronsDown',
  [
    ['path', { d: 'M15 9l-3 3l-3 -3', key: 'svg-0' }],
    ['path', { d: 'M15 13l-3 3l-3 -3', key: 'svg-1' }],
    ['path', { d: 'M12 3a9 9 0 1 0 0 18a9 9 0 0 0 0 -18z', key: 'svg-2' }],
  ],
);
