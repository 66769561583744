import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-youtube', 'IconBrandYoutube', [
  [
    'path',
    {
      d: 'M3 5m0 4a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v6a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 9l5 3l-5 3z', key: 'svg-1' }],
]);
