import createReactComponent from '../createReactComponent';
export default createReactComponent('bulb-filled', 'IconBulbFilled', [
  [
    'path',
    {
      d: 'M4 11a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M12 2a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z',
      fill: 'currentColor',
      key: 'svg-1',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M21 11a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z',
      fill: 'currentColor',
      key: 'svg-2',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M4.893 4.893a1 1 0 0 1 1.32 -.083l.094 .083l.7 .7a1 1 0 0 1 -1.32 1.497l-.094 -.083l-.7 -.7a1 1 0 0 1 0 -1.414z',
      fill: 'currentColor',
      key: 'svg-3',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M17.693 4.893a1 1 0 0 1 1.497 1.32l-.083 .094l-.7 .7a1 1 0 0 1 -1.497 -1.32l.083 -.094l.7 -.7z',
      fill: 'currentColor',
      key: 'svg-4',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M14 18a1 1 0 0 1 1 1a3 3 0 0 1 -6 0a1 1 0 0 1 .883 -.993l.117 -.007h4z',
      fill: 'currentColor',
      key: 'svg-5',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M12 6a6 6 0 0 1 3.6 10.8a1 1 0 0 1 -.471 .192l-.129 .008h-6a1 1 0 0 1 -.6 -.2a6 6 0 0 1 3.6 -10.8z',
      fill: 'currentColor',
      key: 'svg-6',
      strokeWidth: '0',
    },
  ],
]);
