import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'panorama-vertical-off',
  'IconPanoramaVerticalOff',
  [
    [
      'path',
      {
        d: 'M7 3h10.53c.693 0 1.18 .691 .935 1.338c-1.098 2.898 -1.573 5.795 -1.425 8.692m.828 4.847c.172 .592 .37 1.185 .595 1.778a1 1 0 0 1 -.934 1.345h-11c-.692 0 -1.208 -.692 -.962 -1.34c1.697 -4.486 1.903 -8.973 .619 -13.46',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
  ],
);
