import { useEffect, useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { CLIENT_GHOST_AUTH_TOKEN } from '../../constants/auth';
import {
  getCurrentUserTokenQueryString,
  getGhostUserTokenQueryString,
} from '../../queries/project';
import { clearUserData, saveUserData } from './useAuth';
import useCacheQuery from './useCacheQuery';

export const useSaveGhostUserData = (
  projectName: string,
  ghostUserId: any,
  useGhostQuery: any,
) => {
  const userTokenQuery = useMemo(
    () =>
      !useGhostQuery
        ? getCurrentUserTokenQueryString()
        : getGhostUserTokenQueryString(ghostUserId),
    [ghostUserId, useGhostQuery],
  );

  const { loading, data } = useCacheQuery(
    gql`
      ${userTokenQuery}
    `,
    {
      context: { projectQuery: true, withCoreAuth: true, projectName },
      errorPolicy: 'all',
    },
  );

  const token = get(data, [
    useGhostQuery ? 'ghostUser' : 'currentUser',
    'token',
  ]);

  useEffect(() => {
    if (!useGhostQuery) {
      clearUserData(CLIENT_GHOST_AUTH_TOKEN);
    }
  }, [ghostUserId, useGhostQuery]);

  useEffect(() => {
    if (token && useGhostQuery) {
      saveUserData(CLIENT_GHOST_AUTH_TOKEN, token);
    }
  }, [ghostUserId, token, useGhostQuery]);

  return { loading };
};
