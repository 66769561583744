import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrows-diagonal-minimize',
  'IconArrowsDiagonalMinimize',
  [
    ['path', { d: 'M6 10h4v-4', key: 'svg-0' }],
    ['path', { d: 'M4 4l6 6', key: 'svg-1' }],
    ['path', { d: 'M18 14h-4v4', key: 'svg-2' }],
    ['path', { d: 'M14 14l6 6', key: 'svg-3' }],
  ],
);
