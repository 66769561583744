import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { IconCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Loader, Notice } from '@noloco/components';
import { PRIMARY, WARNING } from '@noloco/components/src/constants/variants';
import { CREATE, UPDATE } from '../../constants/actionTypes';
import { darkModeColors } from '../../constants/darkModeColors';
import { BLANK_QUERY_STRING } from '../../queries/project';
import { AutoFormProvider } from '../../utils/hooks/useAutoForm';
import useAutoFormConfig from '../../utils/hooks/useAutoFormConfig';
import useDarkMode from '../../utils/hooks/useDarkMode';
import useDarkModeSurface from '../../utils/hooks/useDarkModeSurface';
import SectionButton from './SectionButton';
import Title from './Title';
import RelatedCellItem from './collections/RelatedCellItem';
import AutoForm from './forms/AutoForm';

const Label = ({ htmlFor, label, required }: any) => (
  <label className="relative text-sm font-medium" htmlFor={htmlFor}>
    <span>{label}</span>
    {required && (
      <span className="absolute right-0 top-0 -mr-2 -mt-1.5 text-base opacity-75">
        *
      </span>
    )}
  </label>
);

export const hasInvalidVisibleFields = (
  invalidFieldNames: any,
  visibleFieldConfigs: any,
) => {
  const visibleFieldNames = visibleFieldConfigs.map(
    ({ field }: any) => field.name,
  );
  const invalidVisibleFields = invalidFieldNames.filter((fieldName: any) =>
    visibleFieldNames.includes(fieldName),
  );
  return invalidVisibleFields.length > 0;
};

const FormSection = forwardRef<any, any>(
  (
    {
      className,
      dataType,
      depth,
      fields,
      sectionFormat,
      sections,
      errorMessage,
      successMessage,
      newRecordStateId,
      onLoadingChange,
      onSuccess,
      subtitle,
      title,
      recordDeps,
      showSuccess,
      submitButton,
      onClick,
      type,
      which,
      project,
      queryOptions,
      onSubmit,
      hideFormOnSuccess,
      disableFeatureCheck = false,
      neverAllowNewRecords = false,
      transformRecordScope,
    },
    ref,
  ) => {
    const surface = useDarkModeSurface();

    const [loading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(false);

    const [isDarkModeEnabled] = useDarkMode();

    const [invalidFields, setInvalidFields] = useState([]);
    const onFieldFailsValidation = useCallback(
      (field: any) => {
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        if (!invalidFields.includes(field.name)) {
          // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
          setInvalidFields([...invalidFields, field.name]);
        }
      },
      [invalidFields],
    );
    const onFieldPassesValidation = useCallback(
      (field: any) => {
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        if (invalidFields.includes(field.name)) {
          setInvalidFields(
            invalidFields.filter(
              (invalidFieldName) => invalidFieldName !== field.name,
            ),
          );
        }
      },
      [invalidFields],
    );

    useEffect(() => {
      if (onLoadingChange) {
        onLoadingChange(loading);
        return () => onLoadingChange(false);
      }
    }, [onLoadingChange, loading]);

    const dt = useMemo(
      () => project.dataTypes.getByName(dataType),
      [dataType, project.dataTypes],
    );
    const {
      dataTypeName,
      dataTypeWithRelations,
      fieldConfigs,
      itemQueryString,
      sectionConfigs,
    } = useAutoFormConfig(dt, fields, project, which, sections);

    const {
      data,
      loading: queryLoading,
      error,
    } = useQuery(
      gql`
        ${itemQueryString || BLANK_QUERY_STRING}
      `,
      {
        variables: { id: which },
        errorPolicy: 'all',
        skip: !dt || type === CREATE || !which || !itemQueryString,
        context: {
          projectQuery: true,
          projectName: project.name,
        },
      },
    );

    if (error) {
      console.error('Error loading record for form:', error);
    }

    const onError = useCallback((formError: any) => {
      setSuccess(false);
      if (typeof formError === 'object') {
        if (formError.graphQLErrors && formError.graphQLErrors.length > 0) {
          setErrors(formError.graphQLErrors.map((er: any) => er.message));
        } else if (formError.networkError && formError.networkError.result) {
          const networkError = get(formError, 'networkError.result.message');
          // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
          setErrors([networkError]);
        } else if (formError.message) {
          setErrors(formError.message.split('\n'));
        } else {
          // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
          setErrors(String(formError).split('\n'));
        }
      } else {
        // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
        setErrors(String(formError).split('\n'));
      }
    }, []);

    const handleOnSuccess = useCallback(
      (dataItem: any) => {
        setErrors(null);
        setSuccess(true);
        if (onSuccess) {
          onSuccess(dataItem);
        }

        if (onSubmit) {
          onSubmit(null, { value: dataItem });
        }
      },
      [onSubmit, onSuccess],
    );

    return (
      <div
        className={classNames('mx-auto flex w-full flex-col', className)}
        ref={ref}
        onClick={onClick}
      >
        {(title || subtitle) && (
          <Title
            subtitle={{
              hidden: !subtitle,
              value: subtitle,
            }}
            title={{
              hidden: !title,
              value: title,
            }}
            className="mb-4"
          />
        )}
        {errors && (
          <Notice
            className="mb-6 mt-2"
            title={errorMessage.message}
            type={WARNING}
            subtitle={
              <div className="flex flex-col space-y-1">
                {(errors as any).map((error: any) => (
                  <span key={error}>{error}</span>
                ))}
              </div>
            }
          />
        )}
        {success && showSuccess && (
          <Notice
            icon={IconCheck}
            type={PRIMARY}
            title={successMessage.message}
          />
        )}
        {!queryLoading && fieldConfigs && (
          <>
            {hideFormOnSuccess && success ? null : (
              <AutoFormProvider
                innerClassName="mb-5"
                Label={Label}
                value={get(data, [dataTypeName])}
                onError={onError}
                onSuccess={handleOnSuccess}
                dataType={dataTypeWithRelations}
                depth={depth}
                fields={fieldConfigs}
                sections={sectionConfigs}
                newRecordStateId={newRecordStateId}
                onFieldFailsValidation={onFieldFailsValidation}
                onFieldPassesValidation={onFieldPassesValidation}
                onLoadingChange={setIsLoading}
                onLoadingFinish={() => setIsLoading(false)}
                mutationType={type}
                onAddDataItem={() => null}
                queryOptions={queryOptions}
                ReadOnlyCell={RelatedCellItem}
                recordDeps={recordDeps}
                surface={surface}
                neverAllowNewRecords={neverAllowNewRecords}
                transformRecordScope={transformRecordScope}
              >
                <AutoForm
                  className="grid grid-cols-12 gap-x-2"
                  sectionFormat={sectionFormat}
                >
                  {submitButton &&
                    (({ fieldsForValidation }: any) => (
                      <div className="mb-32 mt-3">
                        <SectionButton
                          // @ts-expect-error TS(2322): Type '{ dataTypeName: any; disabled: boolean; load... Remove this comment to see the full error message
                          dataTypeName={dataTypeName}
                          disabled={
                            loading ||
                            (type === UPDATE && !which) ||
                            hasInvalidVisibleFields(
                              invalidFields,
                              fieldsForValidation,
                            )
                          }
                          loading={loading}
                          mutationType={type}
                          button={submitButton}
                          submitFormOnClick={true}
                          placeholder="Submit"
                          disableFeatureCheck={disableFeatureCheck}
                        />
                      </div>
                    ))}
                </AutoForm>
              </AutoFormProvider>
            )}
          </>
        )}
        {queryLoading && (
          <div className="flex h-96 w-full items-center justify-center">
            <Loader
              className={
                isDarkModeEnabled
                  ? darkModeColors.text.primary
                  : 'text-gray-800'
              }
              size="md"
            />
          </div>
        )}
      </div>
    );
  },
);

FormSection.defaultProps = {
  dataType: 'user',
  fields: [],
  sections: null,
  showSuccess: true,
  type: CREATE,
};

FormSection.displayName = 'FormSection';

export default FormSection;
