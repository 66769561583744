import createReactComponent from '../createReactComponent';
export default createReactComponent('barbell-off', 'IconBarbellOff', [
  ['path', { d: 'M2 12h1', key: 'svg-0' }],
  ['path', { d: 'M6 8h-2a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M6.298 6.288a1 1 0 0 0 -.298 .712v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1 -1v-8',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M9 12h3', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M15 15v2a1 1 0 0 0 1 1h1c.275 0 .523 -.11 .704 -.29m.296 -3.71v-7a1 1 0 0 0 -1 -1h-1a1 1 0 0 0 -1 1v4',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M18 8h2a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1', key: 'svg-5' }],
  ['path', { d: 'M22 12h-1', key: 'svg-6' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
