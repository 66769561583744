import createReactComponent from '../createReactComponent';
export default createReactComponent('bow', 'IconBow', [
  ['path', { d: 'M17 3h4v4', key: 'svg-0' }],
  ['path', { d: 'M21 3l-15 15', key: 'svg-1' }],
  ['path', { d: 'M3 18h3v3', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M16.5 20c1.576 -1.576 2.5 -4.095 2.5 -6.5c0 -4.81 -3.69 -8.5 -8.5 -8.5c-2.415 0 -4.922 .913 -6.5 2.5l12.5 12.5z',
      key: 'svg-3',
    },
  ],
]);
