import camelCase from 'lodash/camelCase';
import first from 'lodash/first';
import get from 'lodash/get';
import initial from 'lodash/initial';
import isArray from 'lodash/isArray';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import {
  ARRAY,
  DataSchemaField,
  DataSchemaFieldOption,
  DataSchemaFieldType,
  NESTED,
} from '../constants/dataSchema';
import dataTypes, {
  BOOLEAN,
  DATE,
  DECIMAL,
  DataFieldType,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { DEFAULT_FIELDS } from '../constants/defaultFields';

const getTypeForValue = (value: any): DataSchemaFieldType => {
  switch (typeof value) {
    case 'boolean':
      return BOOLEAN;
    case 'number':
      return Number.isInteger(value) ? INTEGER : DECIMAL;
    case 'string':
      if (Date.parse(value)) {
        return DATE;
      } else {
        return TEXT;
      }
    default:
      return TEXT;
  }
};

const filterToDefinedValues = (value: any) =>
  value !== undefined && value !== null;

const getTypeForValues = (values: any[]): DataSchemaFieldType => {
  const definedValues = values.filter(filterToDefinedValues);
  const firstDefinedValue = first(definedValues);

  if (firstDefinedValue === undefined) {
    return TEXT;
  }

  if (isArray(firstDefinedValue)) {
    return ARRAY;
  }

  if (firstDefinedValue instanceof Date) {
    return DATE;
  }

  if (typeof firstDefinedValue === 'object') {
    return NESTED;
  }

  return definedValues.map(getTypeForValue).reduce((acc, value) => {
    if (acc !== value) {
      return TEXT;
    }

    return acc;
  });
};

export const getPathBeyondId = (
  path: string[],
  idField: string | null | undefined,
) => {
  if (!idField) {
    return [];
  }

  const idPath = initial(idField.split('.'));

  return path.reduceRight((acc: string[], value, index) => {
    if (value !== get(idPath, [index])) {
      return [value, ...acc];
    }

    return acc;
  }, []);
};

const toTruthyStrings = (value: any) => !!value && typeof value === 'string';

export const getSampleOptions = (
  type: string,
  sample: any[],
): DataSchemaFieldOption[] | undefined => {
  if (!dataTypes.includes(type as DataFieldType)) {
    return;
  }

  if (type === SINGLE_OPTION) {
    return uniq(sample.filter(toTruthyStrings)).map((display: string) => ({
      display,
    }));
  }

  if (type === MULTIPLE_OPTION) {
    return sample
      .reduce((acc: string[], value: any) => {
        if (!isArray(value)) {
          return acc;
        }

        return uniq([...acc, ...value.filter(toTruthyStrings)]);
      }, [])
      .map((display: string) => ({ display }));
  }
};

export const buildFields = (
  path: string[],
  records: Record<string, any>[],
  idField?: string | null,
  includeDefault = true,
  includeEmpty = true,
) => {
  const sampleRecord = first(records);
  if (!sampleRecord) {
    return null;
  }

  const displayPath = getPathBeyondId(path, idField);

  return Object.keys(sampleRecord)
    .filter((key) => {
      if (!includeEmpty && !key.trim()) {
        return false;
      }

      if (!includeDefault && DEFAULT_FIELDS.includes(camelCase(key))) {
        return false;
      }

      return true;
    })
    .map(
      (key) =>
        ({
          apiName: key,
          display: [...displayPath, key].map(startCase).join(' > '),
          name: key,
          path: [...path, key],
          type: getTypeForValues(records.map((record) => get(record, key))),
        }) as DataSchemaField,
    );
};

const findPaths =
  (predicate: (data: any) => boolean) =>
  (data: any, path: string[] = []): string[][] => {
    if (!data) {
      return [];
    }

    if (predicate(data)) {
      return [path];
    }

    if (typeof data !== 'object' || isArray(data)) {
      return [];
    }

    return Object.entries(data)
      .map(([key, value]) => findPaths(predicate)(value, [...path, key]))
      .filter((childPath) => childPath !== null)
      .reduce((acc, childPath) => [...acc, ...childPath], []);
  };

export const findArrayPaths = findPaths((data: any) => isArray(data));
export const findStringPaths = findPaths(
  (data: any) => typeof data === 'string',
);

const checkPaths = (
  path: string[],
  otherPath: string[],
  shortCircuit: (a: string[], b: string[]) => boolean,
) => {
  if (shortCircuit(path, otherPath)) {
    return false;
  }

  return path.every((element, index) => element === otherPath[index]);
};

const isPrefix = (path: string[], otherPath: string[]) =>
  checkPaths(path, otherPath, (a, b) => b.length <= a.length);
const isEqual = (path: string[], otherPath: string[]) =>
  checkPaths(path, otherPath, (a, b) => b.length !== a.length);

export const getExcludedFields = (
  includedFields: DataSchemaField[],
  records: any[] | null,
  path: string[] = [],
): DataSchemaField[] => {
  if (!records) {
    return [];
  }

  const fields = buildFields(path, records);
  if (!fields) {
    return [];
  }

  return fields.reduce((acc: DataSchemaField[], field: DataSchemaField) => {
    if (
      includedFields.some((includedField) =>
        isEqual(field.path, includedField.path),
      )
    ) {
      return acc;
    }

    const childIncludedFields = includedFields.filter((includedField) =>
      isPrefix(field.path, includedField.path),
    );
    if (childIncludedFields.length > 0) {
      return [
        ...acc,
        ...getExcludedFields(
          childIncludedFields,
          records.map((record) => get(record, [field.name])),
          field.path,
        ),
      ];
    }

    return [...acc, field];
  }, []);
};
