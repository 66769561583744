import get from 'lodash/get';
import initial from 'lodash/initial';
import { FILE } from '../constants/builtInDataTypes';
import {
  ACTION_BUTTONS,
  COLLECTION,
  COMMENTS,
  DETAILS,
  FILE_GALLERY,
  HIGHLIGHTS,
  IFRAME,
  IMAGE,
  STAGES,
  TITLE,
  VIDEO,
  VIEW,
} from '../constants/elements';
import { FIELD } from '../constants/imageTypes';
import { DATABASE } from '../constants/scopeTypes';
import { DataField } from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { DepValue, Element } from '../models/Element';
import { User } from '../models/User';
import { ensureArray } from './arrays';
import {
  extractFieldsFromActionButtons,
  findDependentValues,
  getDepsForConditions,
  getDepsForField,
} from './data';
import { getFieldByName, getRootFieldDataType } from './fields';
import { isOptionType } from './options';
import { mapFieldsWithPermissionsAndConfig } from './permissions';
import { removeEdgesNodeFromPath } from './queries';
import { RECORD_SCOPE } from './scope';

export const getDepsForFieldConfig = (
  field: any,
  config: any,
  elementId: any,
  dataType: any,
  dataTypes: any,
) => {
  let deps: any = [];

  if (field.relationship && config.customFilters) {
    config.customFilters.forEach((customFilter: any) => {
      if (customFilter.result && Array.isArray(customFilter.result)) {
        customFilter.result
          .filter(
            (segment: any) => segment.data && segment.data.id === RECORD_SCOPE,
          )
          .forEach((segment: any) => {
            deps.push({ ...segment.data, id: elementId, source: DATABASE });
          });
      }
    });
  }

  if (isOptionType(field.type) && config.optionsConfig) {
    deps = Object.values(config.optionsConfig).reduce((acc, optionConfig) => {
      if (!optionConfig || !(optionConfig as any).conditions) {
        return acc;
      }
      return [
        // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
        ...acc,
        ...getDepsForConditions(
          (optionConfig as any).conditions,
          dataType,
          dataTypes,
          elementId,
        ),
      ];
    }, deps);
  }

  return deps;
};

export const getDepsFromSection = (
  section: any,
  dataType: any,
  dataTypes: any,
  user: any,
  elementId: any,
  fieldPermissionsEnabled: any,
) => {
  if (!section) {
    return [];
  }

  const { type, props = {} } = section;

  switch (type) {
    case ACTION_BUTTONS: {
      const actionButtons = get(props, 'actionButtons', []);
      return extractFieldsFromActionButtons(actionButtons).reduce(
        (depsAcc: any[], { name }: DataField) => {
          const field = dataType.fields.getByName(name);
          return [...depsAcc, ...getDepsForField(field, dataTypes, elementId)];
        },
        [],
      );
    }
    case COMMENTS: {
      const rootField = get(props, 'rootField');
      const field = dataType.fields.getByName(rootField);

      if (field) {
        return getDepsForField(field, dataTypes, elementId);
      }

      return [];
    }
    case COLLECTION: {
      const collectionFilter = get(props, 'dataList.filter.path', '').split(
        '.',
      );

      if (collectionFilter && collectionFilter.length > 1) {
        const depPath = initial(collectionFilter).join('.');
        return [
          {
            id: elementId,
            path: `${depPath}.id`,
          },
          {
            id: elementId,
            path: `${depPath}.uuid`,
          },
        ];
      }

      return [];
    }
    case IFRAME:
    case VIDEO: {
      if (props && props.type === 'field' && props.field) {
        const field = dataType.fields.getByName(props.field);
        if (field) {
          return getDepsForField(field, dataTypes, elementId);
        }
      }
      return [];
    }
    case FILE_GALLERY: {
      if (props && props.field) {
        const field = dataType.fields.getByName(props.field);
        if (field) {
          return getDepsForField(field, dataTypes, elementId);
        }
      }
      return [];
    }
    case STAGES: {
      if (props.stages) {
        const field = dataType.fields.getByName(props.stages.path);
        if (!field) {
          return [];
        }

        return [
          props.stages,
          ...getDepsForFieldConfig(
            field,
            props,
            elementId,
            dataType,
            dataTypes,
          ),
        ];
      }

      return [];
    }
    case TITLE:
    case HIGHLIGHTS:
    case DETAILS: {
      const actionButtons = get(props, 'actionButtons', []);
      const fields = get(props, 'fields', []);
      const rootField = get(props, 'rootField');
      const rootFieldObject = getFieldByName(rootField, dataType);
      const rootDataType = getRootFieldDataType(rootField, dataType, dataTypes);

      const deps: DepValue[] = [];

      if (type === TITLE) {
        const imageType = section.props?.image?.value?.type;
        if (imageType === FIELD) {
          const fieldName = section.props?.image?.value?.fieldName;
          if (fieldName) {
            const field = dataType.fields.getByName(fieldName);
            if (field && field.type === FILE) {
              deps.push(...getDepsForField(field, dataTypes, elementId));
            }
          }
        }
      }

      return mapFieldsWithPermissionsAndConfig(
        [...fields, ...extractFieldsFromActionButtons(actionButtons)],
        rootDataType,
        user,
        dataTypes,
        fieldPermissionsEnabled,
      ).reduce((depAcc, { config, field }) => {
        if (field) {
          return [
            ...depAcc,
            ...getDepsForField(
              field,
              dataTypes,
              elementId,
              rootFieldObject ? `${rootFieldObject.apiName}.` : undefined,
            ),
            ...getDepsForFieldConfig(
              field,
              config,
              elementId,
              dataType,
              dataTypes,
            ),
            ...((config as any).conditions
              ? getDepsForConditions(
                  (config as any).conditions,
                  dataType,
                  dataTypes,
                  elementId,
                )
              : []),
            ...((config as any).requiredConditions
              ? getDepsForConditions(
                  (config as any).requiredConditions,
                  dataType,
                  dataTypes,
                  elementId,
                )
              : []),
          ];
        }
        return depAcc;
      }, deps);
    }
    case IMAGE: {
      const imageType = get(props, 'image.value.type', null);
      const fieldName = get(props, 'image.value.fieldName', null);

      if (imageType === FIELD && fieldName) {
        const field = dataType.fields.getByName(fieldName);
        if (field) {
          return getDepsForField(field, dataTypes, elementId);
        }
      }

      return [];
    }
    default:
      return [];
  }
};

export const extractDepsFromSections = (
  sections: any,
  dataType: any,
  dataTypes: any,
  user: any,
  elementId: any,
  fieldPermissionsEnabled: any,
) =>
  ensureArray(sections)
    .filter(Boolean)
    .reduce(
      (depsAcc: any, section: any) => [
        ...depsAcc,
        ...getDepsFromSection(
          section,
          dataType,
          dataTypes,
          user,
          elementId,
          fieldPermissionsEnabled,
        ),
      ],
      [],
    );

export const getDepsForViewSections = (
  element: Element,
  mappedSections: any,
  dataTypes: DataTypes,
  dataType: DataType,
  user: User,
  actionButtons: any[] = [],
  tabs: any[] = [],
  title: any = null,
  fieldPermissionsEnabled = true,
) => {
  const viewId = `${element.id}:VIEW`;
  let deps = findDependentValues(
    viewId,
    {
      id: viewId,
      children: mappedSections,
      props: {
        record: {
          subtitle: get(element, 'props.record.subtitle'),
          image: get(element, 'props.record.image'),
          editButtonText: get(element, 'props.record.editButtonText'),
          doneButtonText: get(element, 'props.record.doneButtonText'),
          editButton: get(element, 'props.record.editButton'),
          actionButtons: actionButtons.filter(
            (actionButton) =>
              // Note: !== false is required as by default this will be undefined and still be displayed.
              get(actionButton, ['display', 'record']) !== false,
          ),
          tabs,
        },
      },
      type: VIEW,
    },
    dataTypes,
  );

  const sectionDeps = extractDepsFromSections(
    mappedSections,
    dataType,
    dataTypes,
    user,
    element.id,
    fieldPermissionsEnabled,
  );

  deps = deps.concat(sectionDeps);

  return mapFieldsWithPermissionsAndConfig(
    [
      ...(title
        ? [
            {
              name: title,
            },
          ]
        : []),
      ...extractFieldsFromActionButtons(actionButtons),
    ],
    dataType,
    user,
    dataTypes,
    fieldPermissionsEnabled,
  )
    .reduce((depAcc, { field }) => {
      if (field) {
        return [...depAcc, ...getDepsForField(field, dataTypes, element.id)];
      }
      return depAcc;
    }, deps)
    .map(removeEdgesNodeFromPath);
};
