import createReactComponent from '../createReactComponent';
export default createReactComponent('chisel', 'IconChisel', [
  ['path', { d: 'M14 14l1.5 1.5', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M18.347 15.575l2.08 2.079a1.96 1.96 0 0 1 -2.773 2.772l-2.08 -2.079a1.96 1.96 0 0 1 2.773 -2.772z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M3 6l3 -3l7.414 7.414a2 2 0 0 1 .586 1.414v2.172h-2.172a2 2 0 0 1 -1.414 -.586l-7.414 -7.414z',
      key: 'svg-2',
    },
  ],
]);
