import { DateTime } from 'luxon';
import { BillingOrTeamPlan } from '../models/BillingPlan';
import { BaseUser, User } from '../models/User';
import { getUsagePeriod } from './billing';

export const isInternal = (user: User | undefined): boolean =>
  !!user && (!!user.isInternal || (!!user.role && user.role.internal));

export const isBuilder = (user: User | undefined): boolean =>
  !!user?.role?.builder;

export const isDataAdmin = (user: User): boolean =>
  user && !!user.role && user.role.dataAdmin;

export const getFullName = (user: BaseUser) =>
  `${user.firstName || ''}${user.lastName ? ` ${user.lastName}` : ''}`;

export const isActive = (user: User, teamPlan: BillingOrTeamPlan) => {
  const { start } = getUsagePeriod(teamPlan);
  return (
    user.lastActiveAt &&
    DateTime.fromJSDate(new Date(user.lastActiveAt)) >= start
  );
};
