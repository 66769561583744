import React from 'react';
import DataFieldIcon from '@noloco/ui/src/components/DataFieldIcon';
import { OBJECT } from '../constants/dataTypes';
import { ARRAY } from '../constants/elementPropTypeTypes';
import { DATABASE } from '../constants/scopeTypes';
import { DataType } from '../models/DataTypes';
import { Project } from '../models/Project';
import StateItem from '../models/StateItem';
import { getSubFieldsAsDataFields } from './objects';
import { isMultiField } from './relationships';
import { RECORD_SCOPE } from './scope';

const defaultShouldOnlyIncludeRelatedId = () => false;
const defaultFieldFilter = () => true;

export const getDataTypeFieldOptions = (
  dataType: DataType,
  project: Project,
  {
    onlyRelatedId = false,
    fieldFilter = defaultFieldFilter,
    stateId = RECORD_SCOPE,
    shouldOnlyIncludeRelatedId = defaultShouldOnlyIncludeRelatedId,
  } = {},
) => {
  return (
    dataType.fields
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
      .filter((field: any) => fieldFilter(field, dataType))
      .map((field: any) => {
        const label = (
          <div className="flex items-center">
            <DataFieldIcon field={field} size={14} />
            <span className="ml-2">{field.display}</span>
          </div>
        );

        const fieldOption = {
          label,
          plainLabel: field.display,
          buttonLabel: field.display,
          value: new StateItem({
            id: stateId,
            path: field.name,
            dataType: field.type,
            display: field.display,
            source: DATABASE,
          }),
          field,
        };

        if (
          (!field.relationship && !field.relatedField) ||
          (onlyRelatedId && isMultiField(field)) ||
          // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
          (onlyRelatedId && shouldOnlyIncludeRelatedId(field))
        ) {
          if (field.type !== OBJECT) {
            return fieldOption;
          }

          const parent = new StateItem({
            id: stateId,
            path: field.name,
            dataType: field.type,
            display: field.display,
            source: DATABASE,
            args: { format: field.typeOptions?.format },
          });

          return {
            label,
            plainLabel: field.display,
            options: getSubFieldsAsDataFields(field, { treeDisplay: true }).map(
              (subField) => ({
                label: (
                  <div className="flex items-center">
                    <DataFieldIcon field={subField} size={14} />
                    <span className="ml-2">{subField.display}</span>
                  </div>
                ),
                plainLabel: subField.display,
                value: new StateItem({
                  id: stateId,
                  path: `${field.name}.${subField.apiName}`,
                  dataType: subField.type,
                  display: subField.display,
                  source: DATABASE,
                  args: {
                    parent,
                  },
                }),
              }),
            ),
            field,
          };
        }

        let relatedType = null;

        if (field.relationship) {
          relatedType = project.dataTypes.getByName(field.type);
        }

        if (field.relatedField) {
          relatedType = project.dataTypes.getByName(field.type);
        }

        if (relatedType) {
          return {
            label,
            plainLabel: field.display,
            options: [
              ...(onlyRelatedId ? [fieldOption] : []),
              {
                label: `${field.name} values`,
                heading: true,
                options: relatedType.fields
                  .filter(
                    (rField: any) => rField.name !== 'id' || !onlyRelatedId,
                  )
                  .map((rField: any) => ({
                    label: (
                      <div className="flex items-center">
                        <DataFieldIcon field={rField} size={14} />
                        <span className="ml-2">{rField.display}</span>
                      </div>
                    ),

                    plainLabel: rField.display,

                    value: new StateItem({
                      id: stateId,
                      path: `${field.name}.${
                        isMultiField(field) ? '_columns.' : ''
                      }${rField.name}`,
                      dataType: isMultiField(field) ? ARRAY : rField.type,
                      display: field.display,
                      source: DATABASE,
                    }),
                  })),
              },
            ],
            field,
          };
        }

        return null;
      })
      .filter(Boolean)
  );
};
