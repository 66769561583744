import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-laravel', 'IconBrandLaravel', [
  [
    'path',
    {
      d: 'M3 17l8 5l7 -4v-8l-4 -2.5l4 -2.5l4 2.5v4l-11 6.5l-4 -2.5v-7.5l-4 -2.5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 18v4', key: 'svg-1' }],
  ['path', { d: 'M7 15.5l7 -4', key: 'svg-2' }],
  ['path', { d: 'M14 7.5v4', key: 'svg-3' }],
  ['path', { d: 'M14 11.5l4 2.5', key: 'svg-4' }],
  ['path', { d: 'M11 13v-7.5l-4 -2.5l-4 2.5', key: 'svg-5' }],
  ['path', { d: 'M7 8l4 -2.5', key: 'svg-6' }],
  ['path', { d: 'M18 10l4 -2.5', key: 'svg-7' }],
]);
