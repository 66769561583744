import React, { FunctionComponent, useMemo, useState } from 'react';
import { IconHelp, IconPlus } from '@tabler/icons-react';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { Badge, Tooltip } from '@noloco/components';
import {
  API,
  INTERNAL,
  MYSQL,
  POSTGRES,
} from '@noloco/core/src/constants/dataSources';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { IS_PLAYGROUND } from '../../constants/env';
import { GET_DATA_SOURCES } from '../../queries/project';
import { getGroupedDataTypeOptions } from '../../utils/dataTypes';
import DataTypeListItem from './DataTypeListItem';
import DataSourceMenu from './data/DataSourceMenu';
import DeleteDataTypeModal from './data/DeleteDataTypeModal';

const missingTableLink = {
  [MYSQL]: 'https://guides.noloco.io/data/mysql#syncing-tables',
  [POSTGRES]: 'https://guides.noloco.io/data/postgresql#syncing-tables',
};

type Props = {
  project: Project;
};

const LANG_KEY = 'leftSidebar.data';

const DataList: FunctionComponent<Props> = ({ project }) => {
  const {
    query: { dataType: selectedDataTypeName = 'user' },
  } = useRouter();
  const [dataTypeToDelete, setDataTypeToDelete] = useState<DataType | null>(
    null,
  );
  const { isBuilder } = useIsBuilder();

  const { data, refetch } = useCacheQuery(GET_DATA_SOURCES, {
    variables: { projectId: project.name },
    skip: !isBuilder,
  });
  const dataListGroups = useMemo(
    () =>
      getGroupedDataTypeOptions(project.dataTypes, {
        emptySources: get(data, ['dataSources'], []).filter(
          ({ type, types }: any) => type === API && types.length === 0,
        ),
      }),
    [data, project.dataTypes],
  );

  return (
    <div
      className="flex flex-grow flex-col overflow-hidden border-b border-r border-slate-800"
      data-tour="data-list"
    >
      <div className="flex w-full flex-col overflow-hidden">
        {/* @ts-expect-error TS(2786): 'SimpleBar' cannot be used as a JSX component */}
        <SimpleBar
          autoHide={true}
          className="dark-bar flex flex-grow flex-col overflow-auto"
        >
          {dataListGroups.map(({ label, icon, dataSource, key, options }) => (
            <div className="mb-2 flex flex-col" key={key || 'internal'}>
              <div className="flex h-10 w-full items-center border-b border-slate-900 bg-slate-900 p-2 text-gray-200">
                {icon}
                <span className="ml-3 text-xs font-medium tracking-wider">
                  {label}
                </span>
                {dataSource.type !== INTERNAL &&
                  dataSource &&
                  dataSource.id && (
                    <div className="ml-auto flex gap-x-2">
                      {[MYSQL, POSTGRES].includes(dataSource.type) && (
                        <Tooltip
                          content={
                            <div className="space-y-2">
                              <div>
                                <h3 className="mb-2 text-xs font-medium uppercase tracking-wider text-gray-500">
                                  {getText(LANG_KEY, 'missing.intro')}
                                </h3>
                              </div>
                              <div>{getText(LANG_KEY, 'missing.hint')}</div>
                              <div>
                                {getText(LANG_KEY, 'missing.guides1')}
                                <a
                                  className="text-blue-400 hover:text-blue-500"
                                  href={missingTableLink[dataSource.type]}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {getText(LANG_KEY, 'missing.link')}
                                </a>
                                {getText(LANG_KEY, 'missing.guides2')}
                              </div>
                            </div>
                          }
                        >
                          <span>
                            <IconHelp className="opacity-75" size={16} />
                          </span>
                        </Tooltip>
                      )}
                      {isBuilder && (
                        <DataSourceMenu
                          onDelete={refetch}
                          projectName={project.name}
                          source={dataSource}
                        />
                      )}
                    </div>
                  )}
              </div>
              <div className="flex w-full flex-col px-2 pt-2 text-xs">
                {options.map(({ dataType }) => (
                  <DataTypeListItem
                    dataType={dataType}
                    groupSize={options.length}
                    key={dataType.id}
                    selectedDataTypeName={selectedDataTypeName}
                    setDataTypeToDelete={setDataTypeToDelete}
                    project={project}
                    isBuilder={isBuilder}
                  />
                ))}
              </div>
              {[MYSQL, POSTGRES].includes(dataSource.type) && (
                <Link
                  to={`/_/setup/${kebabCase(dataSource.type)}/${
                    dataSource.id
                  }/query`}
                >
                  <button className="group flex w-full items-center rounded-lg p-2 text-left text-xs hover:bg-gray-600">
                    <IconPlus
                      size={16}
                      className="mr-2 flex-shrink-0 opacity-75"
                    />
                    <span className="text-gray-100 group-hover:text-white">
                      {getText(LANG_KEY, 'sync.customQuery.label')}
                    </span>
                    <Badge
                      m={{ r: 0, l: 'auto' }}
                      color="pink"
                      ignoreDarkMode={true}
                    >
                      {getText(LANG_KEY, 'beta')}
                    </Badge>
                  </button>
                </Link>
              )}
            </div>
          ))}
        </SimpleBar>
      </div>
      {dataTypeToDelete && !IS_PLAYGROUND && isBuilder && (
        <DeleteDataTypeModal
          onClose={() => setDataTypeToDelete(null)}
          projectName={project.name}
          dataType={dataTypeToDelete}
        />
      )}
    </div>
  );
};

export default DataList;
