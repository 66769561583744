import createReactComponent from '../createReactComponent';
export default createReactComponent('photo-off', 'IconPhotoOff', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M7 3h11a3 3 0 0 1 3 3v11m-.856 3.099a2.991 2.991 0 0 1 -2.144 .901h-12a3 3 0 0 1 -3 -3v-12c0 -.845 .349 -1.608 .91 -2.153',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5', key: 'svg-2' }],
  [
    'path',
    { d: 'M16.33 12.338c.574 -.054 1.155 .166 1.67 .662l3 3', key: 'svg-3' },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
