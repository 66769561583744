import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'layout-distribute-vertical',
  'IconLayoutDistributeVertical',
  [
    ['path', { d: 'M4 4l0 16', key: 'svg-0' }],
    ['path', { d: 'M20 4l0 16', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M9 6m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
        key: 'svg-2',
      },
    ],
  ],
);
