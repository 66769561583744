import createReactComponent from '../createReactComponent';
export default createReactComponent('tractor', 'IconTractor', [
  ['path', { d: 'M7 15m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-0' }],
  ['path', { d: 'M7 15l0 .01', key: 'svg-1' }],
  ['path', { d: 'M19 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M10.5 17l6.5 0', key: 'svg-3' }],
  [
    'path',
    { d: 'M20 15.2v-4.2a1 1 0 0 0 -1 -1h-6l-2 -5h-6v6.5', key: 'svg-4' },
  ],
  ['path', { d: 'M18 5h-1a1 1 0 0 0 -1 1v4', key: 'svg-5' }],
]);
