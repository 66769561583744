import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-open-source',
  'IconBrandOpenSource',
  [
    [
      'path',
      {
        d: 'M12 3a9 9 0 0 1 3.618 17.243l-2.193 -5.602a3 3 0 1 0 -2.849 0l-2.193 5.603a9 9 0 0 1 3.617 -17.244z',
        key: 'svg-0',
      },
    ],
  ],
);
