import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-wechat', 'IconBrandWechat', [
  [
    'path',
    {
      d: 'M16.5 10c3.038 0 5.5 2.015 5.5 4.5c0 1.397 -.778 2.645 -2 3.47l0 2.03l-1.964 -1.178a6.649 6.649 0 0 1 -1.536 .178c-3.038 0 -5.5 -2.015 -5.5 -4.5s2.462 -4.5 5.5 -4.5z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M11.197 15.698c-.69 .196 -1.43 .302 -2.197 .302a8.008 8.008 0 0 1 -2.612 -.432l-2.388 1.432v-2.801c-1.237 -1.082 -2 -2.564 -2 -4.199c0 -3.314 3.134 -6 7 -6c3.782 0 6.863 2.57 7 5.785l0 .233',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M10 8h.01', key: 'svg-2' }],
  ['path', { d: 'M7 8h.01', key: 'svg-3' }],
  ['path', { d: 'M15 14h.01', key: 'svg-4' }],
  ['path', { d: 'M18 14h.01', key: 'svg-5' }],
]);
