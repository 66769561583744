import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconAlertTriangle } from '@tabler/icons-react';
import camelCase from 'lodash/camelCase';
import { Loader, Modal, TextInput } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { DELETE } from '@noloco/core/src/constants/deleteDataTypeActions';
import { deleteDataTypeAction } from '@noloco/core/src/utils/deleteDataTypes';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { DELETE_DATA_TYPE, DISABLE_DATA_TYPE } from '../../../queries/project';
import { useRemoveDataType } from '../../../utils/hooks/useUpdateDataTypes';

type Props = {
  dataType: any;
  onClose: (...args: any[]) => any;
  projectName: string;
};

const DeleteDataTypeModal = ({ dataType, projectName, onClose }: Props) => {
  const removeDataType = useRemoveDataType();
  const { push } = useRouter();
  const [deleteDataType] = useMutation(DELETE_DATA_TYPE);
  const [disableDataType] = useMutation(DISABLE_DATA_TYPE);
  const [loading, setLoading] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const action = deleteDataTypeAction(dataType);
  if (!action) {
    return null;
  }
  const actionText = camelCase(action);

  const handleConfirmDelete = () => {
    setLoading(true);
    const mutation = action === DELETE ? deleteDataType : disableDataType;

    mutation({
      variables: {
        projectName,
        dataTypeId: dataType.id,
      },
    })
      .then(({ data }) => {
        if (data.deleteDataType || data.disableDataType) {
          removeDataType({ dataType: dataType });
          push(`/_/data/internal`);
        }
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const relatedFields = dataType.fields.filter(
    ({ relatedField, internal }: any) => !!relatedField && !internal,
  );

  return (
    <Modal
      icon={<IconAlertTriangle size={18} />}
      title={getText('data.dataTypes', actionText, 'title')}
      confirmText={
        loading ? (
          <Loader type="Bars" size="sm" className="text-white" />
        ) : (
          getText('data.dataTypes', actionText, 'confirm')
        )
      }
      cancelText={getText('data.dataTypes', actionText, 'cancel')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirmDelete}
      confirmDisabled={confirmText !== dataType.display || loading}
      variant="danger"
    >
      <>
        <p className="mb-4">
          {getText(
            { typeName: dataType.display },
            'data.dataTypes',
            actionText,
            'disclaimer',
          )}
        </p>
        <p className="mb-4">
          {getText(
            { typeName: dataType.display },
            'data.dataTypes',
            actionText,
            'check',
          )}
        </p>
        {relatedFields.length > 0 && (
          <div className="my-4">
            <p className="mb-2 font-medium">
              {getText('data.dataTypes', actionText, 'related')}
            </p>
            {relatedFields.map((relatedField: any) => (
              <div
                className="my-1 ml-4 flex items-center"
                key={relatedField.name}
              >
                <span>{relatedField.type}</span>
                <span className="mx-1">-&gt;</span>
                <span>{relatedField.relatedField.display}</span>
              </div>
            ))}
          </div>
        )}
        <span className="mt-2 font-medium">
          {getText(
            { typeName: dataType.display },
            'data.dataTypes',
            actionText,
            'confirmName.label',
          )}
        </span>
        <TextInput
          className="my-3"
          placeholder={dataType.display}
          surface={LIGHT}
          onChange={({ target: { value } }: any) => setConfirmText(value)}
          value={confirmText}
        />
      </>
    </Modal>
  );
};

export default DeleteDataTypeModal;
