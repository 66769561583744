import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'wash-dryclean-off',
  'IconWashDrycleanOff',
  [
    [
      'path',
      {
        d: 'M20.048 16.033a9 9 0 0 0 -12.094 -12.075m-2.321 1.682a9 9 0 0 0 12.733 12.723',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
  ],
);
