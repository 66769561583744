import createReactComponent from '../createReactComponent';
export default createReactComponent('number-0', 'IconNumber0', [
  ['path', { d: 'M16 16v-8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 20a4 4 0 0 0 4 -4v-8a4 4 0 1 0 -8 0v8a4 4 0 0 0 4 4z',
      key: 'svg-1',
    },
  ],
]);
