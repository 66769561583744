import createReactComponent from '../createReactComponent';
export default createReactComponent('math-pi-divide-2', 'IconMathPiDivide2', [
  [
    'path',
    {
      d: 'M10 15h3a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 12h14', key: 'svg-1' }],
  ['path', { d: 'M10 9v-6', key: 'svg-2' }],
  ['path', { d: 'M14 3v6', key: 'svg-3' }],
  ['path', { d: 'M15 3h-6', key: 'svg-4' }],
]);
