import createReactComponent from '../createReactComponent';
export default createReactComponent('pointer-share', 'IconPointerShare', [
  [
    'path',
    {
      d: 'M15.646 13.09l-.868 -.868l3.113 -2.09a1.2 1.2 0 0 0 -.309 -2.228l-13.582 -3.904l3.904 13.563a1.2 1.2 0 0 0 2.228 .308l2.09 -3.093l.607 .607',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 22l5 -5', key: 'svg-1' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-2' }],
]);
