import React, { forwardRef } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import caretSizes from './caretSizes';

const Option = forwardRef(
  (
    {
      // @ts-expect-error TS(2339): Property 'active' does not exist on type '{}'.
      active,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
      className,
      // @ts-expect-error TS(2339): Property 'hasChildOptions' does not exist on type ... Remove this comment to see the full error message
      hasChildOptions,
      // @ts-expect-error TS(2339): Property 'size' does not exist on type '{}'.
      size,
      // @ts-expect-error TS(2339): Property 'option' does not exist on type '{}'.
      option,
      // @ts-expect-error TS(2339): Property 'minW' does not exist on type '{}'.
      minW,
      // @ts-expect-error TS(2339): Property 'selected' does not exist on type '{}'.
      selected = false,
      // @ts-expect-error TS(2339): Property 'useButtonLabel' does not exist on type '... Remove this comment to see the full error message
      useButtonLabel = false,
      // @ts-expect-error TS(2339): Property 'coloredOptionType' does not exist on type '... Remove this comment to see the full error message
      coloredOptionType = false,
      ...rest
    },
    ref,
  ) =>
    (!hasChildOptions ||
      get(option, ['options', 'length'], 0) > 0 ||
      option.getOptions) && (
      <div
        className={classNames(
          className,
          'relative flex w-full max-w-full cursor-pointer select-none flex-col rounded-lg',
          minW,
          {
            'text-bold': selected && !coloredOptionType,
            'bg-blue-400 bg-opacity-75 font-medium': active,
            'text-blue-800': active && !coloredOptionType,
            '!cursor-not-allowed opacity-75': option.disabled,
          },
        )}
        onClick={option.onClick ? () => option.onClick(option) : undefined}
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
        {...rest}
      >
        <div className="flex items-center justify-between">
          <div className="flex max-w-full items-center overflow-hidden">
            {option.icon && <span className="mr-2">{option.icon}</span>}
            <div className="flex max-w-full flex-col overflow-hidden">
              <span
                className={classNames(
                  'block flex items-center truncate',
                  selected && !coloredOptionType
                    ? 'font-semibold'
                    : 'font-normal',
                  { 'text-xs uppercase text-gray-400': option.heading },
                )}
                data-testid="select-option-label"
              >
                {(useButtonLabel && option.buttonLabel) ||
                  option.label ||
                  option.value}
              </span>
              {option.help && (
                <span
                  className="mt-1 block truncate text-sm font-normal"
                  data-testid="select-option-help"
                >
                  {option.help}
                </span>
              )}
            </div>
          </div>
          {hasChildOptions && !option.heading && (
            <span className="pointer-events-none ml-2 flex-shrink-0">
              <IconChevronRight size={caretSizes[size]} />
            </span>
          )}
        </div>
      </div>
    ),
);

export default Option;
