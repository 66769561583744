import { ENUM } from '../../constants/elementPropTypeTypes';
import ElementPropType from './ElementPropType';

const defaultLabelFormat = (value: any) => String(value);

class EnumType extends ElementPropType {
  formatLabel: (...varargs: any[]) => string | JSX.Element | null;
  constructor(validValues: any[], display = () => true) {
    super(ENUM);
    super.validValues(validValues);
    this.formatLabel = defaultLabelFormat;
    this.display = display;
  }

  setFormatLabel(
    labelFormatFn: (...varargs: any[]) => string | JSX.Element | null,
  ) {
    this.formatLabel = labelFormatFn;
    return this;
  }
}

export default EnumType;
