import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'player-play-filled',
  'IconPlayerPlayFilled',
  [
    [
      'path',
      {
        d: 'M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
  ],
);
