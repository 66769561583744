import set from 'lodash/fp/set';
import get from 'lodash/get';
import badgeTypes from '@noloco/components/src/components/badge/badgeTypes';
import buttonStyles from '@noloco/components/src/components/button/buttonStyles';
import buttonTypes from '@noloco/components/src/components/button/buttonTypes';
import horizontalNavTypes from '@noloco/components/src/components/navigation/horizontalNavTypes';
import variants from '@noloco/components/src/constants/variants';
import { CREATE, UPDATE } from '../constants/actionTypes';
import { FILE } from '../constants/builtInDataTypes';
import chartAggregations from '../constants/chartAggregations';
import { AREA, BAR, LINE } from '../constants/chartTypes';
import collectionLayouts, {
  BOARD,
  CALENDAR,
  CONVERSATION,
  TABLE,
} from '../constants/collectionLayouts';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  INTEGER,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { INTERNAL } from '../constants/dataWrapperTypes';
import * as elements from '../constants/elements';
import { PAGE } from '../constants/linkTypes';
import orderByDirections from '../constants/orderByDirections';
import { MANY_TO_MANY } from '../constants/relationships';
import { DATABASE } from '../constants/scopeTypes';
import { SPAN } from '../constants/textTypes';
import timePeriods from '../constants/timePeriods';
import BaseElementConfig from '../models/BaseElementConfig';
import { Project } from '../models/Project';
import StateItem from '../models/StateItem';
import ArrayType from '../models/elementPropTypes/ArrayPropType';
import BoolType from '../models/elementPropTypes/BoolPropType';
import ComboPropType from '../models/elementPropTypes/ComboPropType';
import CustomExtractionPropType from '../models/elementPropTypes/CustomExtractionPropType';
import DataFieldPropType from '../models/elementPropTypes/DataFieldPropType';
import DataPropType from '../models/elementPropTypes/DataPropPropType';
import DataTypeNamePropType from '../models/elementPropTypes/DataTypeNamePropType';
import EnumType from '../models/elementPropTypes/EnumPropType';
import KeyMapPropType from '../models/elementPropTypes/KeyMapPropType';
import NodeType from '../models/elementPropTypes/NodePropType';
import NumberType from '../models/elementPropTypes/NumberPropType';
import PropGroup from '../models/elementPropTypes/PropGroup';
import RawDataPropType from '../models/elementPropTypes/RawDataPropPropType';
import StringType from '../models/elementPropTypes/StringPropType';
import StringPropType from '../models/elementPropTypes/StringPropType';
import VariablePropPropType from '../models/elementPropTypes/VariablePropPropType';
import ChildElementPropType from '../models/elementPropTypes/comboProps/ChildElementPropType';
import { buildCustomFilterProp } from '../models/elementPropTypes/comboProps/CustomFilterPropType';
import DataListPropType from '../models/elementPropTypes/comboProps/DataListPropType';
import ImagePropType from '../models/elementPropTypes/comboProps/ImagePropType';
import LinkPropType, {
  extractPageDataDependencies,
  pageDataPropTransformation,
} from '../models/elementPropTypes/comboProps/LinkPropType';
import VideoPropType from '../models/elementPropTypes/comboProps/VideoPropType';
import { deriveViewState } from '../utils/elementState';
import { getText } from '../utils/lang';
import { getFieldsPropGroup, setFormFields } from './utils/formOptions';
import { cloneCustomRules } from './utils/propTransformations';
import {
  deriveCollectionState,
  deriveListInputState,
  extractCustomRulesPropDeps,
  extractRawDataPropDeps,
} from './utils/stateConfig';

const enumWithDefaults = (types: string[]) => ['default', ...types];

const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce semper eget tellus in ornare.';

const pageCloneTransformation = (element: any) => {
  const routePath = get(element, ['props', 'routePath']);
  const name = get(element, ['props', 'name']);
  const clonedRoutePath =
    !routePath || routePath === '' ? 'clone' : `${routePath}-clone`;
  let updatedElement = set(['props', 'routePath'], clonedRoutePath, element);
  updatedElement = set(
    ['props', 'new', 'publicForms'],
    undefined,
    updatedElement,
  );
  return set(['props', 'name'], `${name} (clone)`, updatedElement);
};

const VALIDATION_RULES_DEF = new ArrayType({
  field: new RawDataPropType(() => []).setPropTransformation((value) => value),
  value: new StringType().setUseRawValue(true).setAutomaticallyResolve(false),
}).setAutomaticallyResolve(false);

const FORM_FIELD_DEF = {
  conditions: new CustomExtractionPropType(
    extractCustomRulesPropDeps,
  ).setAutomaticallyResolve(false),
  highlights: new ArrayType({
    conditions: new CustomExtractionPropType(
      extractCustomRulesPropDeps,
    ).setAutomaticallyResolve(false),
  }),
  customFilters: new ArrayType(buildCustomFilterProp(false)),
  defaultValue: new StringType()
    .setUseRawValue(true)
    .setAutomaticallyResolve(false),
  helpText: new StringType().setAutomaticallyResolve(false),
  newRecordTitle: new StringType().setAutomaticallyResolve(false),
  value: new StringType().setUseRawValue(true).setAutomaticallyResolve(false),
  optionsConfig: new KeyMapPropType({
    conditions: new CustomExtractionPropType(
      extractCustomRulesPropDeps,
    ).setAutomaticallyResolve(false),
    helpText: new StringType().setAutomaticallyResolve(false),
  }),
  requiredConditions: new CustomExtractionPropType(
    extractCustomRulesPropDeps,
  ).setAutomaticallyResolve(false),
  validationRules: VALIDATION_RULES_DEF,
  elementConfig: new ComboPropType({
    maxValue: new StringPropType()
      .setAutomaticallyResolve(false)
      .setUseRawValue(true),
  }),
};
const CHART_TYPE = new ArrayType({
  helpText: new StringType(),
  xAxisLabel: new StringType(),
  yAxisLabel: new StringType(),
  xAxisValue: new RawDataPropType().setAutomaticallyResolve(false),
  series: new ArrayType({
    yAxisValue: new RawDataPropType().setAutomaticallyResolve(false),
    conditions: new CustomExtractionPropType(
      extractCustomRulesPropDeps,
    ).setAutomaticallyResolve(false),
  }),
  groups: new ArrayType({
    field: new RawDataPropType()
      // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
      .setExtractPropTypesDependencies(extractRawDataPropDeps)
      .setOnlyIncludeSelf(true),
  }),
  max: new StringType().setUseRawValue(true),
  visibilityRules: new ComboPropType({
    customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
      .setAutomaticallyResolve(false)
      .setPropTransformation(cloneCustomRules),
  }),
});

const FORM_FIELDS_TYPE = new ArrayType(FORM_FIELD_DEF);

const NAVIGATE_TO_EMAIL_TYPE = {
  email: new StringPropType()
    .setUseRawValue(true)
    .setAutomaticallyResolve(false),
  subject: new StringPropType()
    .setUseRawValue(true)
    .setAutomaticallyResolve(false),
};
const NAVIGATE_TO_PAGE_TYPE = {
  pageData: new RawDataPropType(() => [TEXT, INTEGER, DECIMAL])
    .setExtractPropTypesDependencies(extractPageDataDependencies)
    .setPropTransformation(pageDataPropTransformation),
};
const NAVIGATE_TO_PHONE_TYPE = {
  phone: new StringPropType()
    .setUseRawValue(true)
    .setAutomaticallyResolve(false),
};
const NAVIGATE_TO_URL_TYPE = {
  url: new StringPropType().setUseRawValue(true).setAutomaticallyResolve(false),
};

const ACTION_BUTTONS_TYPE = new ArrayType({
  title: new StringPropType()
    .setUseRawValue(false)
    .setAutomaticallyResolve(false),
  description: new StringPropType()
    .setUseRawValue(false)
    .setAutomaticallyResolve(false),
  tooltip: new StringPropType()
    .setUseRawValue(false)
    .setAutomaticallyResolve(false),
  actions: new ArrayType({
    formFields: FORM_FIELDS_TYPE,
    detailsFields: FORM_FIELDS_TYPE,
    navigate: new ComboPropType({
      ...NAVIGATE_TO_EMAIL_TYPE,
      ...NAVIGATE_TO_PAGE_TYPE,
      ...NAVIGATE_TO_PHONE_TYPE,
      ...NAVIGATE_TO_URL_TYPE,
    }),
    copy: new ComboPropType({
      value: new StringPropType().setAutomaticallyResolve(false),
    }),
    iframe: new ComboPropType({
      source: new StringPropType().setAutomaticallyResolve(false),
    }),
    addComment: new ComboPropType({
      body: new StringPropType()
        .setUseRawValue(true)
        .setAutomaticallyResolve(false),
    }),
  }),
  link: new LinkPropType().setUseRawValue(true).setAutomaticallyResolve(false),
  notification: new ComboPropType({
    text: new StringPropType()
      .setUseRawValue(false)
      .setAutomaticallyResolve(false),
  }),
  visibilityRules: new ComboPropType({
    customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
      .setAutomaticallyResolve(false)
      .setPropTransformation(cloneCustomRules),
  }),
});

const BASE_RECORD_VIEW_TYPE = {
  subtitle: new StringType().setAutomaticallyResolve(false),
  image: new ImagePropType(undefined, true).setAutomaticallyResolve(false),
  editButtonText: new StringType().setAutomaticallyResolve(false),
  doneButtonText: new StringType().setAutomaticallyResolve(false),
  editButton: new ComboPropType({
    visibilityRules: new ComboPropType({
      customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
        .setAutomaticallyResolve(false)
        .setPropTransformation(cloneCustomRules),
    }),
  }),
};

const baseElementsConfig: Record<string, BaseElementConfig> = {
  [elements.BUTTON]: new BaseElementConfig({
    content: true,
    props: {
      submitFormOnClick: new BoolType(),
      variant: new EnumType(variants),
      type: new EnumType(enumWithDefaults(buttonTypes)),
      style: new EnumType(enumWithDefaults(buttonStyles)),
    },
  }),
  [elements.BADGE]: new BaseElementConfig({
    content: true,
    props: {
      variant: new EnumType(variants),
      type: new EnumType(enumWithDefaults(badgeTypes)),
    },
  }),
  [elements.CHART]: new BaseElementConfig({
    canHaveChildren: false,
    props: {
      chartType: new EnumType([LINE, BAR, AREA]),
      dataList: new DataListPropType(undefined),
      series: new ArrayType({
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
      }),
      xAxisValue: new RawDataPropType((__: any, project: Project) => [
        TEXT,
        SINGLE_OPTION,
        DATE,
        INTEGER,
        DECIMAL,
        BOOLEAN,
        ...project.dataTypes.map((type) => type.name),
      ])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      yAxisValue: new RawDataPropType(() => [DATE, INTEGER, DECIMAL, BOOLEAN])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      timePeriod: new EnumType(timePeriods)
        .setFormatLabel((timePeriod) =>
          getText('elements', elements.CHART, 'timePeriod', timePeriod),
        )
        .setDisplay((props = {}) => get(props, 'xAxisValue.dataType') === DATE),
      aggregation: new EnumType(chartAggregations).setFormatLabel(
        (aggregation) =>
          getText('elements', elements.CHART, 'aggregation', aggregation),
      ),
      sortBy: new DataFieldPropType(
        (element) => get(element, 'props.xAxisValue.dataType'),
        (field) => !field.relationship,
      ).setDisplay((props = {}, __1, __2, project: Project) => {
        const chartType = get(props, 'chartType');
        const dataType = get(props, 'xAxisValue.dataType');
        return (
          chartType === BAR &&
          dataType &&
          project.dataTypes.some(({ name }) => name === dataType)
        );
      }),
      sortDirection: new EnumType(orderByDirections)
        .setFormatLabel((val, element, __, project) => {
          const sortByField = get(element.props, 'sortBy');
          const dataTypeName = get(element.props, 'xAxisValue.dataType');
          const dataType = project.dataTypes.getByName(dataTypeName);
          const field = dataType && dataType.fields.getByName(sortByField);

          if (!field) {
            return null;
          }

          return `${field.display}: ${getText(
            'elements',
            elements.LIST,
            'sort',
            field.type,
            val,
          )}`;
        })
        .setDisplay((props = {}, __1, __2, project) => {
          const sortByField = get(props, 'sortBy');
          const chartType = get(props, 'chartType');
          const dataTypeName = get(props, 'xAxisValue.dataType');
          const dataType = project.dataTypes.getByName(dataTypeName);
          const field = dataType && dataType.fields.getByName(sortByField);
          return field && sortByField && chartType === BAR && dataType;
        }),
      title: new StringType(),
      subtitle: new StringType(),
      filters: new PropGroup({
        filters: new ArrayType({
          defaultValue: new StringType()
            .setUseRawValue(true)
            .setAutomaticallyResolve(true),
          field: new DataFieldPropType(
            (element) => get(element, 'props.dataList.dataType'),
            (field) =>
              field.relationship !== MANY_TO_MANY && field.type !== FILE,
          ),
          label: new StringType(),
          placeholder: new StringType(),
          multiple: new BoolType().setDisplay(
            (
              props: { dataList?: { dataType?: string } } = {},
              __,
              propPath,
              project,
            ) => {
              const { dataList: { dataType } = {} } = props;
              const fieldName = get(props, [...propPath.slice(0, -1), 'field']);
              if (!dataType || !fieldName) {
                return false;
              }
              const dt = project.dataTypes.getByName(dataType);
              if (!dt) {
                return false;
              }
              const field = dt.fields.getByName(fieldName);
              return field.relationship || field.type === SINGLE_OPTION;
            },
          ),
          // position: new EnumType(['TOP', 'LEFT', 'RIGHT']),
        }),
      }).setDisplay(() => true),
      emptyState: new ComboPropType({
        title: new VariablePropPropType({
          hideable: false,
          placeholder: () =>
            getText(
              'core',
              elements.COLLECTION,
              'variables.emptyState.title.placeholder',
            ),
        }),
        image: new VariablePropPropType({
          type: new ImagePropType(undefined, true),
          elementType: elements.IMAGE,
        }),
        hideIfEmpty: new BoolType().setDefault(false),
      }),
    },
    deriveState: deriveListInputState,
  }),
  [elements.CONTENT]: new BaseElementConfig({
    hidden: true,
    props: {
      items: new StringType(),
    },
  }),
  [elements.GROUP]: new BaseElementConfig({
    defaultProps: {
      w: 'full',
    },
  }),
  [elements.IFRAME]: new BaseElementConfig({
    canHaveChildren: false,
    props: {
      source: new StringType(),
      title: new StringType(),
      fullScreen: new BoolType(),
    },
  }),
  [elements.IMAGE]: new BaseElementConfig({
    canHaveChildren: false,
    props: {
      image: new VariablePropPropType({
        type: new ImagePropType(undefined, true),
        elementType: elements.IMAGE,
      }),
    },
  }),
  [elements.LINK]: new BaseElementConfig({
    props: {
      link: new LinkPropType(),
    },
  }),
  [elements.COMMENTS]: new BaseElementConfig({
    canHaveChildren: false,
    props: {},
  }),
  [elements.DIVIDER]: new BaseElementConfig({
    canHaveChildren: false,
    props: {},
  }),
  [elements.FOLDER]: new BaseElementConfig({}),
  [elements.PAGE]: new BaseElementConfig({
    styleable: false,
    props: {
      sections: new NodeType(undefined),
      dataSource: new EnumType([INTERNAL]),
      dataType: new DataTypeNamePropType(),
      SubPages: new NodeType(undefined),
      title: new StringType(),
      subtitle: new StringType(),
      tabs: new ArrayType({
        visibilityRules: new ComboPropType({
          customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
            .setAutomaticallyResolve(false)
            .setPropTransformation(cloneCustomRules),
        }),
      }),
      isSubPage: new BoolType(),
    },
    deriveState: ({ id, props }, project, elementPath, context) => {
      let state: any[] = [];

      if (props.dataType) {
        const dataType = project.dataTypes.getByName(props.dataType);
        if (dataType) {
          state = state.concat(
            context.getDataTypeOptions(
              new StateItem({
                id,
                dataType: props.dataType,
                path: '',
                source: DATABASE,
                display: props.name
                  ? props.name
                  : getText(
                      { dataType: props.dataType },
                      'elements',
                      elements.PAGE,
                      'state.pageType',
                    ),
              }),
            ),
          );
        }
      }
      return state;
    },
    cloneTransformation: pageCloneTransformation,
  }),
  [elements.PAGE_SWITCH]: new BaseElementConfig({
    hidden: false,
    styleable: false,
    allowedChildTypes: [elements.PAGE],
  }),
  [elements.TEXT]: new BaseElementConfig({
    content: true,
    props: {
      type: new EnumType([SPAN, elements.LINK, elements.BUTTON]).setDefault(
        'span',
      ),
    },
  }),
  [elements.VIDEO]: new BaseElementConfig({
    canHaveChildren: false,
    props: {
      video: new VideoPropType(),
      autoPlay: new BoolType(),
      muted: new BoolType(),
      loop: new BoolType(),
      controls: new BoolType(),
      poster: new ImagePropType(),
    },
  }),
  [elements.YOUTUBE_VIDEO]: new BaseElementConfig({
    props: {
      videoId: new StringType(),
      autoPlay: new BoolType().setDefault(false),
      showControls: new BoolType().setDefault(true),
      startSeconds: new StringType(),
    },
  }),

  /* --- SECTIONS ----- */
  [elements.BILLING]: new BaseElementConfig({
    canHaveChildren: false,
    section: true,
    props: {
      header: new PropGroup({
        title: new StringType(),
        subtitle: new StringType(),
        subscriptionsTitle: new StringType(),
        subscriptionsSubtitle: new StringType(),
      }),
      emptyState: new PropGroup({
        emptyState: new ComboPropType({
          title: new VariablePropPropType({
            hideable: false,
            placeholder: () =>
              getText(
                'core',
                elements.COLLECTION,
                'variables.emptyState.title.placeholder',
              ),
          }),
          image: new VariablePropPropType({
            type: new ImagePropType(undefined, true),
            elementType: elements.IMAGE,
          }),
        }),
      }),
      showCustomerPortalButton: new BoolType().setDisplay(
        (__1, __2, __3, project) =>
          get(project, 'integrations.stripe.account.id'),
      ),
      tabs: new PropGroup({
        tabs: new ComboPropType({
          invoices: new StringPropType(),
          subscriptions: new StringPropType(),
        }),
      }).setDisplay((__1, __2, __3, project) =>
        get(project, 'integrations.stripe.account.id'),
      ),
    },
  }),
  [elements.BREADCRUMBS]: new BaseElementConfig({
    section: true,
    canHaveChildren: false,
  }),
  [elements.COLLECTION]: new BaseElementConfig({
    canHaveChildren: false,
    section: true,
    props: {
      data: new PropGroup({
        layout: new EnumType(collectionLayouts),
        dataList: new DataListPropType(undefined),
      }),
      recordColoring: new ArrayType({
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
      }),
      vars: new PropGroup({
        groupBy: new RawDataPropType((__, project: Project) => [
          TEXT,
          SINGLE_OPTION,
          ...project.dataTypes.map((type) => type.name),
        ])
          // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
          .setExtractPropTypesDependencies(extractRawDataPropDeps)
          .setOnlyIncludeSelf(true)
          .setDisplay((props = {}) => props.layout === BOARD),
        groups: new ArrayType({
          field: new RawDataPropType((__, project: Project) => [
            TEXT,
            SINGLE_OPTION,
            ...project.dataTypes.map((type) => type.name),
          ])
            // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
            .setExtractPropTypesDependencies(extractRawDataPropDeps)
            .setOnlyIncludeSelf(true),
        }),
        limitPerGroup: new NumberType().setDisplay(
          (props = {}) => props.layout === BOARD,
        ),
        dateStart: new RawDataPropType(() => [DATE])
          // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
          .setExtractPropTypesDependencies(extractRawDataPropDeps)
          .setOnlyIncludeSelf(true)
          .setDisplay((props = {}) => props.layout === CALENDAR),
        dateEnd: new RawDataPropType(() => [DATE])
          // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
          .setExtractPropTypesDependencies(extractRawDataPropDeps)
          .setOnlyIncludeSelf(true)
          .setDisplay((props = {}) => props.layout === CALENDAR),
        ganttDependency: new RawDataPropType()
          // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
          .setExtractPropTypesDependencies(extractRawDataPropDeps)
          .setOnlyIncludeSelf(true),
        variables: new ComboPropType({
          image: new VariablePropPropType({
            type: new ImagePropType(undefined, true),
            elementType: elements.IMAGE,
          }),
          title: new VariablePropPropType({
            hideable: false,
            placeholder: () =>
              getText(
                'core',
                elements.COLLECTION,
                'variables.title.placeholder',
              ),
            hasLabel: (element) =>
              element.props && element.props.layout === TABLE,
          }),
          description: new VariablePropPropType({
            placeholder: () => LOREM_IPSUM,
          }),
          secondaryText: new VariablePropPropType({
            hasLabel: (element) =>
              element.props && element.props.layout !== CONVERSATION,
            placeholder: () => LOREM_IPSUM,
          }),
        }),
        additionalElements: new ArrayType({
          label: new StringType(),
          element: new ChildElementPropType(),
          fullWidth: new BoolType(),
        }),
      }),
      header: new PropGroup({
        title: new StringType(),
        subtitle: new StringType(),
      }),
      filters: new PropGroup({
        filters: new ArrayType({
          field: new DataFieldPropType(
            (element) => get(element, 'props.dataList.dataType'),
            (field) =>
              field.relationship !== MANY_TO_MANY && field.type !== FILE,
          ),
          label: new StringType(),
          filterByEmptyLabel: new StringType(),
          placeholder: new StringType(),
          multiple: new BoolType().setDisplay(
            (
              props: { dataList?: { dataType?: string } } = {},
              __,
              propPath,
              project,
            ) => {
              const { dataList: { dataType } = {} } = props;
              const fieldName = get(props, [...propPath.slice(0, -1), 'field']);
              if (!dataType || !fieldName) {
                return false;
              }
              const dt = project.dataTypes.getByName(dataType);
              if (!dt) {
                return false;
              }
              const field = dt.fields.getByName(fieldName);
              return field.relationship || field.type === SINGLE_OPTION;
            },
          ),
          filter: new RawDataPropType((element, project, propPath = []) => {
            const dataType = get(element, 'props.dataList.dataType');
            const fieldName = get(element.props, [
              ...propPath.slice(0, -1),
              'field',
            ]);
            if (!dataType || !fieldName) {
              return [];
            }
            const dt = project.dataTypes.getByName(dataType);
            if (!dt) {
              return [];
            }
            const field = dt.fields.getByName(fieldName);
            return field ? [field.type] : [];
          })
            .setShowCollections(true)
            .setDisplay(
              (
                props: { dataList?: { dataType?: string } } = {},
                __,
                propPath,
                project,
              ) => {
                const { dataList: { dataType } = {} } = props;
                const fieldName = get(props, [
                  ...propPath.slice(0, -1),
                  'field',
                ]);
                if (!dataType || !fieldName) {
                  return false;
                }
                const dt = project.dataTypes.getByName(dataType);
                if (!dt) {
                  return false;
                }
                const field = dt.fields.getByName(fieldName);
                return field.relationship;
              },
            ),
          customFilters: new ArrayType(buildCustomFilterProp(true)),
          defaultValue: new StringType()
            .setUseRawValue(true)
            .setAutomaticallyResolve(true),
          helpText: new StringType().setAutomaticallyResolve(false),
          conditions: new CustomExtractionPropType(
            extractCustomRulesPropDeps,
          ).setAutomaticallyResolve(false),
        }),
      }).setDisplay(() => true),
      emptyState: new PropGroup({
        emptyState: new ComboPropType({
          title: new VariablePropPropType({
            hideable: false,
            placeholder: () =>
              getText(
                'core',
                elements.COLLECTION,
                'variables.emptyState.title.placeholder',
              ),
          }),
          image: new VariablePropPropType({
            type: new ImagePropType(undefined, true),
            elementType: elements.IMAGE,
          }),
        }),
      }),
      link: new PropGroup({
        link: new LinkPropType(undefined, true),
      }),
      formOptions: new PropGroup({
        addNewButton: new BoolType().setOnChange(
          (
            canAddNew,
            previousCanAddNew,
            { updateProperty, element, project },
          ) => {
            const allowEditing = get(element, 'props.allowEditing', false);
            const inlineEditing = get(element, 'props.inlineEditing', false);
            if (
              canAddNew &&
              !previousCanAddNew &&
              !allowEditing &&
              !inlineEditing
            ) {
              const dataTypeName = get(element, 'props.dataList.dataType');
              setFormFields(dataTypeName, project.dataTypes, updateProperty);
            }
          },
        ),
        newButton: new ComboPropType({
          visibilityRules: new ComboPropType({
            customRules: new CustomExtractionPropType(
              extractCustomRulesPropDeps,
            )
              .setAutomaticallyResolve(false)
              .setPropTransformation(cloneCustomRules),
          }),
        }),
        newButtonText: new StringType().setDisplay(
          (props = {}) => props.addNewButton,
        ),
        exportButton: new ComboPropType({
          show: new BoolType(),
          text: new StringPropType(),
          visibilityRules: new ComboPropType({
            customRules: new CustomExtractionPropType(
              extractCustomRulesPropDeps,
            )
              .setAutomaticallyResolve(false)
              .setPropTransformation(cloneCustomRules),
          }),
        }),
        importButton: new ComboPropType({
          show: new BoolType(),
          text: new StringPropType(),
          fields: new ArrayType({
            value: new StringType().setUseRawValue(true),
          }),
          visibilityRules: new ComboPropType({
            customRules: new CustomExtractionPropType(
              extractCustomRulesPropDeps,
            )
              .setAutomaticallyResolve(false)
              .setPropTransformation(cloneCustomRules),
          }),
        }),
        allowEditing: new BoolType().setOnChange(
          (canEdit, previousCanEdit, { updateProperty, element, project }) => {
            const addNewButton = get(element, 'props.addNewButton', false);
            const inlineEditing = get(element, 'props.inlineEditing', false);

            if (
              canEdit &&
              !previousCanEdit &&
              !addNewButton &&
              !inlineEditing
            ) {
              const dataTypeName = get(element, 'props.dataList.dataType');
              setFormFields(dataTypeName, project.dataTypes, updateProperty);
            }
          },
        ),
        editButtonText: new StringType(true).setDisplay(
          (props = {}) => props.allowEditing,
        ),
        inlineEditing: new BoolType().setOnChange(
          (canEdit, previousCanEdit, { updateProperty, element, project }) => {
            const addNewButton = get(element, 'props.addNewButton', false);
            const allowEditing = get(element, 'props.allowEditing', false);
            if (canEdit && !previousCanEdit && !addNewButton && !allowEditing) {
              const dataTypeName = get(element, 'props.dataList.dataType');
              setFormFields(dataTypeName, project.dataTypes, updateProperty);
            }
          },
        ),
        allowDeleting: new BoolType(),
        confirmDeleteText: new StringType(true).setDisplay(
          (props = {}) => props.allowDeleting,
        ),
      }).setDisplay(() => true),
      fields: new ArrayType({
        customFilters: new ArrayType(
          buildCustomFilterProp(false),
        ).setAutomaticallyResolve(false),
        value: new StringType().setUseRawValue(true),
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
        elementConfig: new ComboPropType({
          maxValue: new StringPropType()
            .setAutomaticallyResolve(false)
            .setUseRawValue(true),
        }),
      }),
      formFields: getFieldsPropGroup((element) =>
        get(element, 'props.dataList.dataType'),
      ).setDisplay(
        (props = {}) =>
          props.addNewButton || props.allowEditing || props.inlineEditing,
      ),
      charts: CHART_TYPE,
      record: new ComboPropType({
        actionButtons: ACTION_BUTTONS_TYPE,
      }),
    },
    deriveState: deriveCollectionState,
    formatLabel: () => getText('elements', elements.COLLECTION, 'state.label'),
    formatHelpText: () =>
      getText('elements', elements.COLLECTION, 'state.label'),
  }),
  [elements.DETAILS]: new BaseElementConfig({
    section: true,
    canHaveChildren: false,
    props: {
      title: new VariablePropPropType({
        placeholder: () => '',
      }),
      subtitle: new VariablePropPropType({
        placeholder: () => '',
      }),
      collapsable: new BoolType(),
      additionalElements: new ArrayType({
        label: new StringType(),
        element: new ChildElementPropType(),
        fullWidth: new BoolType(),
      }),
      formOptions: new PropGroup({
        allowEditing: new BoolType().setOnChange(
          (canEdit, previousCanEdit, { updateProperty, element, project }) => {
            if (canEdit && !previousCanEdit) {
              const dataTypeName = get(element, 'props.dataType');
              setFormFields(dataTypeName, project.dataTypes, updateProperty);
            }
          },
        ),
        editButtonText: new StringType().setDisplay(
          (props = {}) => props.allowEditing,
        ),
        allowDeleting: new BoolType(),
        dataType: new DataTypeNamePropType(),
        which: new DataPropType(({ props: { dataType = undefined } = {} }) =>
          dataType ? [dataType] : [],
        ).setDisplay((props = {}) => props.allowEditing || props.allowDeleting),
        pageAfterDelete: new LinkPropType()
          .setEditorProps({
            options: [PAGE],
            label: 'Page after delete',
          })
          .setDisplay((props = {}) => props.allowDeleting),
        confirmDeleteText: new StringType().setDisplay(
          (props = {}) => props.allowDeleting,
        ),
      }),
      fields: FORM_FIELDS_TYPE,
      formFields: getFieldsPropGroup().setDisplay(
        (props = {}) => props.addNewButton || props.allowEditing,
      ),
      actionButtons: ACTION_BUTTONS_TYPE,
    },
  }),
  [elements.EMBED]: new BaseElementConfig({
    section: true,
    canHaveChildren: false,
    props: {
      code: new StringPropType(),
    },
  }),
  [elements.FILE_SHARING]: new BaseElementConfig({
    section: true,
    canHaveChildren: false,
  }),
  [elements.MESSAGING]: new BaseElementConfig({
    section: true,
    canHaveChildren: false,
    props: {
      clientTopMessage: new StringPropType(),
    },
  }),
  [elements.TITLE]: new BaseElementConfig({
    section: true,
    canHaveChildren: false,
    props: {
      image: new VariablePropPropType({
        type: new ImagePropType(undefined, true),
        elementType: elements.IMAGE,
      }),
      title: new VariablePropPropType({
        hideable: false,
        placeholder: () =>
          getText('core', elements.COLLECTION, 'variables.title.placeholder'),
        hasLabel: (element) => element.props && element.props.layout === TABLE,
      }),
      subtitle: new VariablePropPropType({
        placeholder: () => LOREM_IPSUM,
      }),
      coverPhoto: new VariablePropPropType({
        type: new ImagePropType(undefined, true),
        elementType: elements.IMAGE,
        hideable: true,
      }),
      buttons: new ArrayType({
        text: new StringType(),
        link: new LinkPropType(),
      }),
      actionButtons: ACTION_BUTTONS_TYPE,
    },
  }),
  [elements.FORM_V2]: new BaseElementConfig({
    canHaveChildren: false,
    section: true,
    props: {
      dataType: new DataTypeNamePropType().setOnChange(
        (newValue, oldValue, { updateProperty, project }) => {
          if (newValue !== oldValue) {
            setFormFields(newValue, project.dataTypes, updateProperty);
          }
        },
      ),
      type: new EnumType([CREATE, UPDATE]),
      which: new DataPropType(
        ({
          props: { dataType, type } = {},
        }: {
          props: { dataType?: string; type?: string };
        }) => (type ? [dataType] : []),
      ).setDisplay((props = {}) => props.type === UPDATE),
      fields: getFieldsPropGroup(),
      header: new PropGroup({
        title: new StringType(),
        subtitle: new StringType(),
      }),
      submitButton: new PropGroup({
        submitButton: new ComboPropType({
          text: new StringType(),
          variant: new EnumType(variants),
          type: new EnumType(enumWithDefaults(buttonTypes)),
        }),
      }),
      successMessage: new PropGroup({
        successMessage: new ComboPropType({
          message: new StringType(),
        }),
      }),
      errorMessage: new PropGroup({
        errorMessage: new ComboPropType({
          message: new StringType(),
        }),
      }),
      helpText: new StringPropType(),
      validationRules: VALIDATION_RULES_DEF,
    },
  }),
  [elements.HIGHLIGHTS]: new BaseElementConfig({
    canHaveChildren: false,
    section: true,
    props: {
      fields: FORM_FIELDS_TYPE,
      highlights: new ArrayType({
        label: new StringType(),
        text: new StringType(),
      }).setMinimum(1),
    },
  }),
  [elements.QUICK_LINKS]: new BaseElementConfig({
    canHaveChildren: false,
    section: true,
    props: {
      links: new ArrayType({
        title: new StringType(),
        description: new StringType(),
        link: new LinkPropType(),
        visibilityRules: new ComboPropType({
          customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
            .setAutomaticallyResolve(false)
            .setPropTransformation(cloneCustomRules),
        }),
      })
        .setMinimum(1)
        .setIncludeSelf(true),
      dense: new BoolType(),
    },
  }),
  [elements.MARKDOWN]: new BaseElementConfig({
    canHaveChildren: false,
    props: {
      markdownText: new StringPropType().setMultiLine(true),
      content: new StringPropType().setMultiLine(true),
    },
  }),
  [elements.FILE_GALLERY]: new BaseElementConfig({
    canHaveChildren: false,
    props: {},
  }),
  [elements.NOTICE]: new BaseElementConfig({
    canHaveChildren: false,
    section: true,
    props: {
      title: new VariablePropPropType({
        hideable: false,
        placeholder: () => getText('elements', elements.NOTICE, 'placeholder'),
        hasLabel: (element) => element.props && element.props.layout === TABLE,
      }),
      subtitle: new VariablePropPropType({
        placeholder: () => '',
      }),
      actionButtons: ACTION_BUTTONS_TYPE,
    },
  }),
  [elements.ONBOARDING_TASKS]: new BaseElementConfig({
    canHaveChildren: false,
    section: true,
    props: {
      title: new StringType(),
      subtitle: new StringType(),
    },
  }),
  [elements.RECORD]: new BaseElementConfig({
    canHaveChildren: false,
    props: {
      field: new RawDataPropType((__, project: Project) =>
        project.dataTypes.map((type) => type.name),
      )
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setIncludeCollections(true),
      emptyState: new StringType(),
    },
  }),
  [elements.RECORD_VIEW]: new BaseElementConfig({
    canHaveChildren: false,
    props: BASE_RECORD_VIEW_TYPE,
  }),
  [elements.TABS]: new BaseElementConfig({
    section: true,
    canHaveChildren: false,
    props: { tabType: new EnumType(horizontalNavTypes) },
  }),
  [elements.SECTION]: new BaseElementConfig({
    props: {
      fields: new ArrayType({
        customFilters: new ArrayType(buildCustomFilterProp()),
        value: new StringType().setUseRawValue(true),
      }),
    },
  }),
  [elements.FORM_SECTION]: new BaseElementConfig({
    props: {
      title: new VariablePropPropType({
        placeholder: () => '',
      }),
      subtitle: new VariablePropPropType({
        placeholder: () => '',
      }),
      fields: new ArrayType({
        config: new ComboPropType(FORM_FIELD_DEF),
      }),
      helpText: new StringPropType(),
    },
  }),
  [elements.STAGES]: new BaseElementConfig({
    props: {
      stages: new DataPropType(() => [SINGLE_OPTION])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true)
        .setAutomaticallyResolve(false),
      optionsConfig: new KeyMapPropType({
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
      }),
    },
  }),
  [elements.ACTION_BUTTONS]: new BaseElementConfig({
    section: true,
    canHaveChildren: false,
    props: {
      actionButtons: ACTION_BUTTONS_TYPE,
    },
  }),
  [elements.FIELD_CELL]: new BaseElementConfig({
    props: {
      elementConfig: new ComboPropType({
        maxValue: new StringPropType()
          .setAutomaticallyResolve(false)
          .setUseRawValue(true),
      }),
    },
  }),
  [elements.VIEW]: new BaseElementConfig({
    section: true,
    canHaveChildren: false,
    props: {
      layout: new EnumType(collectionLayouts),
      dataList: new DataListPropType(undefined),
      limitPerGroup: new StringPropType(),
      // `groupBy` is deprectaed in favour of `groups` but we need to continue to support it
      groupBy: new RawDataPropType((__, project: Project) => [
        TEXT,
        SINGLE_OPTION,
        ...project.dataTypes.map((type) => type.name),
      ])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      groups: new ArrayType({
        field: new RawDataPropType((__, project: Project) => [
          TEXT,
          SINGLE_OPTION,
          ...project.dataTypes.map((type) => type.name),
        ])
          // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
          .setExtractPropTypesDependencies(extractRawDataPropDeps)
          .setOnlyIncludeSelf(true),
      }),
      dateStart: new RawDataPropType(() => [DATE])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      dateEnd: new RawDataPropType(() => [DATE])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      ganttDependency: new RawDataPropType()
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      title: new StringType(),
      subtitle: new StringType(),
      coverPhoto: new ImagePropType(),
      pivotTable: new ComboPropType({
        rowGrouping: new ArrayType({
          field: new RawDataPropType((__, project: Project) => [
            BOOLEAN,
            DATE,
            INTEGER,
            SINGLE_OPTION,
            TEXT,
            ...project.dataTypes.map((type) => type.name),
          ])
            // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
            .setExtractPropTypesDependencies(extractRawDataPropDeps)
            .setOnlyIncludeSelf(true),
        }),
        columnGrouping: new ArrayType({
          field: new RawDataPropType((__, project: Project) => [
            BOOLEAN,
            DATE,
            INTEGER,
            SINGLE_OPTION,
            TEXT,
            ...project.dataTypes.map((type) => type.name),
          ])
            // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
            .setExtractPropTypesDependencies(extractRawDataPropDeps)
            .setOnlyIncludeSelf(true),
        }),
        values: new ArrayType({
          field: new RawDataPropType((__, project: Project) => [
            DECIMAL,
            INTEGER,
            ...project.dataTypes.map((type) => type.name),
          ])
            // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
            .setExtractPropTypesDependencies(extractRawDataPropDeps)
            .setOnlyIncludeSelf(true),
        }),
      }),
      emptyState: new ComboPropType({
        title: new VariablePropPropType({
          hideable: false,
          placeholder: () =>
            getText(
              'core',
              elements.COLLECTION,
              'variables.emptyState.title.placeholder',
            ),
        }),
        subtitle: new VariablePropPropType({
          hideable: false,
          placeholder: () => '',
        }),
        image: new VariablePropPropType({
          type: new ImagePropType(undefined, true),
          elementType: elements.IMAGE,
        }),
      }),
      fields: new ArrayType({
        customFilters: new ArrayType(
          buildCustomFilterProp(false),
        ).setAutomaticallyResolve(false),
        value: new StringType().setUseRawValue(true),
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
        elementConfig: new ComboPropType({
          maxValue: new StringPropType()
            .setAutomaticallyResolve(false)
            .setUseRawValue(true),
        }),
      }),
      filters: new ArrayType({
        customFilters: new ArrayType(buildCustomFilterProp(true)),
        defaultValue: new StringType()
          .setUseRawValue(true)
          .setAutomaticallyResolve(true),
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
        helpText: new StringType(),
        filterByEmptyLabel: new StringType(),
      }),
      charts: CHART_TYPE,
      newButton: new ComboPropType({
        visibilityRules: new ComboPropType({
          customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
            .setAutomaticallyResolve(false)
            .setPropTransformation(cloneCustomRules),
        }),
      }),
      newButtonText: new StringType(),
      actionButtons: ACTION_BUTTONS_TYPE,
      editRelatedRecordButtons: new ComboPropType({
        config: new ComboPropType({
          customFilters: new ArrayType(buildCustomFilterProp()),
        }),
        show: new BoolType(),
        unlinkButtonText: new StringPropType(),
        hideUnlinkButton: new BoolType(),
        hideAddRelatedRecordButton: new BoolType(),
      }),
      exportButton: new ComboPropType({
        show: new BoolType(),
        text: new StringPropType(),
        visibilityRules: new ComboPropType({
          customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
            .setAutomaticallyResolve(false)
            .setPropTransformation(cloneCustomRules),
        }),
      }),
      importButton: new ComboPropType({
        show: new BoolType(),
        text: new StringPropType(),
        fields: new ArrayType({
          value: new StringType().setUseRawValue(true),
        }),
        visibilityRules: new ComboPropType({
          customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
            .setAutomaticallyResolve(false)
            .setPropTransformation(cloneCustomRules),
        }),
      }),
      new: new ComboPropType({
        title: new StringType(),
        subtitle: new StringType(),
        coverPhoto: new ImagePropType(),
        saveButtonText: new StringType(),
        fields: FORM_FIELDS_TYPE,
        onSave: {
          navigate: new LinkPropType()
            .setUseRawValue(true)
            .setAutomaticallyResolve(false),
        },
      }),
      record: new ComboPropType({
        sections: new NodeType(undefined),
        actionButtons: ACTION_BUTTONS_TYPE,
        tabs: new ArrayType({
          visibilityRules: new ComboPropType({
            customRules: new CustomExtractionPropType(
              extractCustomRulesPropDeps,
            )
              .setAutomaticallyResolve(false)
              .setPropTransformation(cloneCustomRules),
          }),
        }),
        ...BASE_RECORD_VIEW_TYPE,
      }),
      recordColoring: new ArrayType({
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
      }),
    },
    deriveState: deriveViewState,
    cloneTransformation: pageCloneTransformation,
    formatHelpText: () =>
      getText('elements', elements.VIEW, 'state.recordView.help'),
  }),
  [elements.CONTAINER]: new BaseElementConfig({
    canHaveChildren: true,
    props: {},
  }),
};

export default baseElementsConfig;
