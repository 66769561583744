export const getArrayBatches = <T>(data: T[], batchSize: number): T[][] => {
  if (batchSize <= 0) {
    throw new Error('batchSize must be greater than 0');
  }
  const numBatches = Math.ceil(data.length / batchSize);
  return [...Array(numBatches)].map((x, i) =>
    data.slice(batchSize * i, batchSize * (i + 1)),
  );
};

// Reduce over each batch and maintain the original item index
export const forEachPromiseBatch = async <T, P>(
  data: T[],
  batchSize: number,
  promiseFn: (item: T, index: number, batchIndex: number) => Promise<P>,
) => {
  const batches = getArrayBatches(data, batchSize);

  return batches.reduce(
    (previous, batch, batchIndex) =>
      previous.then(async (results: Awaited<P[]>) => {
        const batchResults = await Promise.all(
          batch.map((dataItem: T, index: number) =>
            promiseFn(dataItem, batchSize * batchIndex + index, batchIndex),
          ),
        );
        return Promise.resolve([...results, ...batchResults]);
      }),
    Promise.resolve([] as Awaited<P[]>),
  );
};

export const forEachPromise = async <T>(
  data: T[],
  promiseFn: (item: T, index: number, batchIndex: number) => Promise<any>,
) => forEachPromiseBatch(data, 1, promiseFn);

export const everyPromise = async <T>(
  data: T[],
  predicateFn: (item: T) => Promise<boolean>,
) => {
  for (let i = 0; i < data.length; i++) {
    const result = await predicateFn(data[i]);

    if (!result) {
      return false;
    }
  }

  return true;
};
