import { useMemo } from 'react';
import get from 'lodash/get';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { USER } from '../../../constants/builtInDataTypes';
import { DataType } from '../../../models/DataTypes';
import { Element, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { Page } from '../../../utils/pages';
import BuildModeChartsEditor from '../BuildModeChartsEditor';

type BuildModeViewChartsTabProps = {
  dataType?: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  element: Page;
  elementPath: ElementPath;
  project: Project;
  section?: Element;
  sectionPropPath?: ElementPath;
  updateProperty: UpdatePropertyCallback;
};

const BuildModeViewChartsTab = ({
  debouncedUpdateProperty,
  element,
  elementPath,
  project,
  section,
  sectionPropPath = [],
  updateProperty,
}: BuildModeViewChartsTabProps) => {
  const collectionElement = useMemo(
    () => (section ?? element) as Page | Element,
    [section, element],
  );

  const collectionElementPath = useMemo(
    () => (section ? sectionPropPath : elementPath),
    [section, sectionPropPath, elementPath],
  );

  const { charts, dataList = {} } = collectionElement.props || {};

  const dataType = useMemo(() => {
    const typeName = get(dataList, 'dataType');

    return (
      (typeName && project.dataTypes.getByName(typeName)) ??
      project.dataTypes.getByName(USER)
    );
  }, [dataList, project.dataTypes]);

  return (
    <BuildModeChartsEditor
      charts={charts}
      dataType={dataType}
      debouncedUpdateProperty={debouncedUpdateProperty}
      element={collectionElement}
      elementPath={collectionElementPath}
      project={project}
      updateProperty={updateProperty}
    />
  );
};

export default BuildModeViewChartsTab;
