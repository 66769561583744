import createReactComponent from '../createReactComponent';
export default createReactComponent('bounce-left', 'IconBounceLeft', [
  [
    'path',
    {
      d: 'M20 15.5c-3 -1 -5.5 -.5 -8 4.5c-.5 -3 -1.5 -5.5 -3 -8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 9a2 2 0 1 1 0 -4a2 2 0 0 1 0 4z', key: 'svg-1' }],
]);
