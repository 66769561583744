import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { Notification } from '../../models/Notification';
import {
  READ_NOTIFICATIONS_MUTATION,
  UNREAD_NOTIFICATIONS_MUTATION,
} from '../../queries/notifications';

export const useReadNotification = () => {
  const [markAsReadMutation] = useMutation(READ_NOTIFICATIONS_MUTATION);
  const [markAsUnreadMutation] = useMutation(UNREAD_NOTIFICATIONS_MUTATION);

  const onRead = useCallback(
    (read) => (notification: Notification) => () => {
      const mutation = read ? markAsReadMutation : markAsUnreadMutation;

      mutation({
        context: {
          projectName: notification.project,
          projectQuery: true,
        },
        variables: { notifications: [notification.id] },
      });
    },
    [markAsReadMutation, markAsUnreadMutation],
  );

  const markAsRead = useMemo(() => onRead(true), [onRead]);
  const markAsUnread = useMemo(() => onRead(false), [onRead]);

  return {
    markAsRead,
    markAsUnread,
  };
};
