import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import Loader from '@noloco/components/src/components/loading/Loader';
import { MYSQL, POSTGRES } from '@noloco/core/src/constants/dataSources';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import AddAirtableBase from './dataTable/AddAirtableBase';
import AddApi from './dataTable/AddApi';
import AddGoogleSheet from './dataTable/AddGoogleSheet';
import AddHubSpot from './dataTable/AddHubSpot';
import AddSmartSuiteSolution from './dataTable/AddSmartSuiteSolution';
import AddSqlDatabase from './dataTable/AddSqlDatabase';
import AddXanoWorkspace from './dataTable/AddXanoWorkspace';

const LazyCustomQueryBuilder = lazy(
  () => import('./leftSidebar/data/CustomQueryBuilder'),
);

const DataSourceSetupRoutes = () => {
  const project = useSelector(projectDataSelector);

  return (
    <Switch>
      <Route path="/_/setup/api/:id?">
        <AddApi project={project} />
      </Route>
      <Route path="/_/setup/airtable/:id?">
        <AddAirtableBase project={project} />
      </Route>
      <Route path="/_/setup/google-sheets/:id?">
        <AddGoogleSheet project={project} />
      </Route>
      <Route path="/_/setup/hubspot/:id?">
        <AddHubSpot project={project} />
      </Route>
      <Route path="/_/setup/mysql/:id?/query/:dataTypeId?">
        <Suspense fallback={<Loader />}>
          <LazyCustomQueryBuilder project={project} sqlType={MYSQL} />
        </Suspense>
      </Route>
      <Route path="/_/setup/mysql/:id?">
        <AddSqlDatabase project={project} sqlType={MYSQL} />
      </Route>
      <Route path="/_/setup/postgres/:id?/query/:dataTypeId?">
        <Suspense fallback={<Loader />}>
          <LazyCustomQueryBuilder project={project} sqlType={POSTGRES} />
        </Suspense>
      </Route>
      <Route path="/_/setup/postgres/:id?">
        <AddSqlDatabase project={project} sqlType={POSTGRES} />
      </Route>
      <Route path="/_/setup/smart-suite/:id?">
        <AddSmartSuiteSolution project={project} />
      </Route>
      <Route path="/_/setup/xano/:id?">
        <AddXanoWorkspace project={project} />
      </Route>
      <Redirect to="/_/setup/airtable" />
    </Switch>
  );
};

export default DataSourceSetupRoutes;
