import createReactComponent from '../createReactComponent';
export default createReactComponent('trolley', 'IconTrolley', [
  ['path', { d: 'M11 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M6 16l3 2', key: 'svg-1' }],
  ['path', { d: 'M12 17l8 -12', key: 'svg-2' }],
  ['path', { d: 'M17 10l2 1', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M9.592 4.695l3.306 2.104a1.3 1.3 0 0 1 .396 1.8l-3.094 4.811a1.3 1.3 0 0 1 -1.792 .394l-3.306 -2.104a1.3 1.3 0 0 1 -.396 -1.8l3.094 -4.81a1.3 1.3 0 0 1 1.792 -.394z',
      key: 'svg-4',
    },
  ],
]);
