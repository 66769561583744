import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import safeRegex from 'safe-regex2';
import { isWebUri } from 'valid-url';
import isIP from 'validator/lib/isIP';
import { dtToUTCMaintainWallTime } from '@noloco/components/src/utils/dateTimeConversions';
import { isEmailValid } from '@noloco/components/src/utils/validation';
import {
  ARRAY,
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  DataFieldType,
  INTEGER,
  MULTIPLE_OPTION,
  OBJECT,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { DATE as DATE_FORMAT } from '../constants/fieldFormats';
import {
  AFTER,
  AFTER_OR_EQUAL,
  BEFORE,
  BEFORE_OR_EQUAL,
  CONTAINS,
  ConditionOperator,
  DOES_NOT_CONTAIN,
  EMAIL,
  EMPTY,
  EQUAL,
  FALSE,
  GREATER,
  GREATER_OR_EQUAL,
  IN,
  IP_ADDRESS,
  LESS,
  LESS_OR_EQUAL,
  NOT_EMPTY,
  NOT_EQUAL,
  NOT_IN,
  Operator,
  REGEX,
  TRUE,
  URL,
} from '../constants/operators';
import { Relationship } from '../constants/relationships';
import { DataField } from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { RecordValue } from '../models/Record';
import cappedMemoize from './cappedMemoize';
import { getDateFromValue } from './dates';
import { getFieldFromDependency } from './fields';
import { isOptionType } from './options';
import { isMultiRelationship } from './relationships';
import { flattenStateItem } from './state';
import { formatUrl } from './urls';

export const getOperatorsForFieldType = (
  fieldType: string,
  relationship?: Relationship,
): Operator[] => {
  switch (fieldType) {
    case ARRAY:
      return [CONTAINS, DOES_NOT_CONTAIN, EMPTY, NOT_EMPTY];
    case DATE:
      return [
        EQUAL,
        NOT_EQUAL,
        AFTER,
        BEFORE,
        AFTER_OR_EQUAL,
        BEFORE_OR_EQUAL,
        EMPTY,
        NOT_EMPTY,
      ];
    case DECIMAL:
    case INTEGER:
    case DURATION:
      return [
        EQUAL,
        NOT_EQUAL,
        GREATER,
        GREATER_OR_EQUAL,
        LESS,
        LESS_OR_EQUAL,
        EMPTY,
        NOT_EMPTY,
        IN,
        NOT_IN,
      ];
    case TEXT:
      return [EQUAL, NOT_EQUAL, CONTAINS, DOES_NOT_CONTAIN, EMPTY, NOT_EMPTY];
    case SINGLE_OPTION:
      return [EQUAL, NOT_EQUAL, EMPTY, NOT_EMPTY, IN, NOT_IN];
    case MULTIPLE_OPTION:
      return [EMPTY, NOT_EMPTY, IN, NOT_IN];
    case BOOLEAN:
      return [TRUE, FALSE];
    default: {
      if (relationship && !isMultiRelationship(relationship)) {
        return [EQUAL, NOT_EQUAL, EMPTY, NOT_EMPTY, IN, NOT_IN];
      }
      return [EMPTY, NOT_EMPTY, IN, NOT_IN];
    }
  }
};

export const getInputTypeForOperator = (operator: Operator) => {
  if ([EMPTY, NOT_EMPTY, TRUE, FALSE].includes(operator)) {
    return null;
  }

  if ([IN, NOT_IN].includes(operator)) {
    return 'array';
  }

  return 'string';
};

export const shouldOperatorHaveValue = (operator: Operator): boolean =>
  ![TRUE, FALSE, EMPTY, NOT_EMPTY].includes(operator);

export const getOperatorsForField = (
  fieldValue: any,
  dataType: DataType,
  dataTypes: DataTypes,
): Operator[] => {
  const fieldPath = flattenStateItem(fieldValue.property);
  const fieldDependency = getFieldFromDependency(
    fieldPath.split('.'),
    dataType,
    dataTypes,
  );

  if (!fieldDependency) {
    return [];
  }
  const { field } = fieldDependency;

  return getOperatorsForFieldType(field.type);
};

const operatorContains = (
  value1: RecordValue,
  value2: RecordValue,
): boolean => {
  if (!value1) {
    return false;
  }

  if (Array.isArray(value1)) {
    return value1.includes(value2 as string);
  }

  return String(value1).includes(value2 as string);
};

const isRegexSafe = cappedMemoize(
  (regexString: string) => safeRegex(regexString),
  {
    maxKeys: 30,
  },
);

export const compareValues = (
  value1: any,
  operator: ConditionOperator,
  value2: any,
): boolean => {
  switch (operator) {
    case NOT_EQUAL:
      return value1 !== value2;
    case CONTAINS:
      return operatorContains(value1, value2);
    case DOES_NOT_CONTAIN:
      return !operatorContains(value1, value2);
    case AFTER:
    case GREATER:
      return value1 > value2;
    case BEFORE:
    case LESS:
      return value1 < value2;
    case AFTER_OR_EQUAL:
    case GREATER_OR_EQUAL:
      return value1 >= value2;
    case BEFORE_OR_EQUAL:
    case LESS_OR_EQUAL:
      return value1 <= value2;
    case EMPTY:
      return (
        value1 === null ||
        value1 === undefined ||
        value1 === '' ||
        (Array.isArray(value1) && value1.length === 0)
      );
    case NOT_EMPTY:
      return !(
        value1 === null ||
        value1 === undefined ||
        value1 === '' ||
        (Array.isArray(value1) && value1.length === 0)
      );
    case TRUE:
      return !!value1;
    case FALSE:
      return !value1;
    case IN: {
      if (Array.isArray(value1) && value2) {
        if (typeof value2 === 'number') {
          return value1.includes(value2) || value1.includes(String(value2));
        }

        if (Array.isArray(value2)) {
          return value2.some((subVal) => value1.includes(subVal));
        }

        // This probably means it's a multi-option field
        const splitValue2 = value2
          .split(',')
          .map((v: string) => v.trim())
          .filter(Boolean);
        return value1.some((option) => splitValue2.includes(option));
      }

      return (
        !isNil(value2) &&
        String(value2)
          .split(',')
          .map((v) => v.trim())
          .filter(Boolean)
          .includes(String(value1))
      );
    }
    case NOT_IN: {
      return (
        !isNil(value2) &&
        !compareValues(value1, IN as ConditionOperator, value2)
      );
    }
    case EMAIL:
      return value1 && isEmailValid(String(value1));
    case URL: {
      return value1 && !!isWebUri(formatUrl(String(value1)));
    }
    case IP_ADDRESS: {
      return value1 && isIP(String(value1));
    }
    case REGEX: {
      if (!value1 || !value2 || !isRegexSafe(String(value2))) {
        return false;
      }

      try {
        const pattern = new RegExp(String(value2));
        return pattern.test(String(value1));
      } catch (e) {
        console.log('Invalid pattern:', e);
        return false;
      }
    }
    default:
    case EQUAL: {
      if (value1 instanceof DateTime && value2 instanceof DateTime) {
        return value1.equals(value2);
      }

      if (typeof value1 === 'string') {
        return value1 === String(value2);
      }

      return value1 === value2;
    }
  }
};

const formatDateForFilter = (result: any, field?: DataField) => {
  if (field && field.type === DATE) {
    let dateTime = getDateFromValue(result);

    if (!dateTime?.isValid) {
      return undefined;
    }

    const dateFormat = get(field, 'typeOptions.format');
    const timeZone = get(field, 'typeOptions.timeZone');

    if ((!dateFormat || dateFormat !== DATE_FORMAT) && !timeZone) {
      return dateTime.toUTC().toISO();
    }

    return dtToUTCMaintainWallTime(dateTime).toISO();
  }

  return result;
};

export const getResultForOperator = (
  operator: ConditionOperator,
  result: any,
  field: DataField,
) => {
  if (result === undefined) {
    return undefined;
  }

  switch (operator) {
    case BEFORE_OR_EQUAL:
    case AFTER_OR_EQUAL:
    case BEFORE:
    case AFTER:
      return formatDateForFilter(result, field);
    case IN:
    case NOT_IN: {
      if (Array.isArray(result)) {
        if (result.length === 0) {
          return undefined;
        }
        return result;
      }

      if (
        field &&
        (isOptionType(field.type as DataFieldType) ||
          field.type === TEXT ||
          field.relationship ||
          field.name === 'id')
      ) {
        return String(result)
          .split(',')
          .map((s) => s.trim())
          .filter(Boolean);
      }

      return [result];
    }
    default: {
      if (Array.isArray(result)) {
        return result.length > 0 ? String(result[0]).trim() : undefined;
      }

      if (field.type === BOOLEAN) {
        if (typeof result === 'boolean') {
          return result;
        }

        return String(result).trim().toLowerCase() === 'true';
      }

      if (field.type === DATE) {
        return formatDateForFilter(result, field);
      }

      return String(result).trim();
    }
  }
};

export const getNeverResultForOperator = (
  operator: ConditionOperator,
  field: DataField,
) => {
  if (operator === IN || operator === NOT_IN) {
    return [];
  }

  if (get(field, ['type']) === DATE) {
    return formatDateForFilter(null, field);
  }

  return null;
};

export const getOperatorForFieldFilter = (
  field: DataField,
  filter: { operator?: Operator; multiple?: boolean },
): Operator => {
  if (filter && filter.operator) {
    return filter.operator;
  }

  if (filter.multiple) {
    return IN;
  }

  switch (field.type) {
    case OBJECT:
    case TEXT:
      return CONTAINS;
    case DECIMAL:
    case INTEGER:
    case BOOLEAN:
    case SINGLE_OPTION:
      return EQUAL;
    case DATE:
      return GREATER_OR_EQUAL;
    default:
      return IN;
  }
};
