import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { setScopeFieldValue } from '../../reducers/data';
import { elementScopeSelector } from '../../selectors/dataSelectors';

const useSetScopeFieldValue = (
  loading: any,
  elementId: any,
  scopeValue: any,
) => {
  const dispatch = useDispatch();
  const debounceDispatch = debounce(dispatch, 300);
  const currentValue = useSelector(elementScopeSelector(elementId));

  useEffect(() => {
    const objectHasChanged = !isEqual(scopeValue, currentValue);
    if (objectHasChanged) {
      debounceDispatch(
        setScopeFieldValue({ id: elementId, value: scopeValue }),
      );
    }
  }, [currentValue, debounceDispatch, elementId, scopeValue]);
};

export default useSetScopeFieldValue;
