import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { DARK } from '../../constants/surface';
import { Surface } from '../../models';
import Tooltip from './Tooltip';

type Props = {
  className?: string;
  date: Date | string | undefined;
  surface?: Surface;
  tooltipPrefix?: string;
  tooltipSuffix?: string;
  live?: boolean;
};

const RelativeDateTimeTooltip = ({
  className,
  date,
  surface,
  tooltipPrefix,
  tooltipSuffix,
  live = false,
}: Props) => {
  const parsedDate = useMemo(() => {
    if (date) {
      return new Date(date);
    }
  }, [date]);

  const localeString = useMemo(() => {
    if (parsedDate) {
      return parsedDate.toLocaleString();
    }
  }, [parsedDate]);

  const [relativeTime, setRelativeTime] = useState<string | null>(null);

  useEffect(() => {
    if (parsedDate) {
      setRelativeTime(DateTime.fromJSDate(parsedDate).toRelative());
      if (live) {
        const interval = setInterval(() => {
          setRelativeTime(DateTime.fromJSDate(parsedDate).toRelative());
        }, 10000);

        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [live, parsedDate]);

  return (
    <div
      className={classNames(
        {
          'text-gray-500': surface !== DARK && !date,
          'text-black': surface !== DARK && date,
          'text-gray-400': surface === DARK && !date,
          'text-white': surface === DARK && date,
        },
        className,
      )}
    >
      <Tooltip
        content={
          <span>
            {tooltipPrefix}
            {localeString}
            {tooltipSuffix}
          </span>
        }
        disabled={!parsedDate}
      >
        <p
          className={classNames({
            'opacity-50': !date,
            'cursor-pointer': date,
          })}
        >
          {relativeTime ?? '--'}
        </p>
      </Tooltip>
    </div>
  );
};

export default RelativeDateTimeTooltip;
