import usLocale from 'date-fns/locale/en-US';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import round from 'lodash/round';
import { DateTime } from 'luxon';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
  MULTIPLE_OPTION,
  OBJECT,
  SINGLE_OPTION,
} from '../constants/dataTypes';
import { DURATION_TIME, TIME_24 } from '../constants/dateFormatOptions';
import {
  COORDINATES,
  CURRENCY,
  DATE_RANGE,
  PERCENTAGE,
  TIME,
  UNFORMATTED_NUMBER,
} from '../constants/fieldFormats';
import { DEFAULT_PRECISION } from '../constants/numbers';
import { DataField, FieldTypeOptions } from '../models/DataTypeFields';
import { Coordinates, DateRange, RecordValue } from '../models/Record';
import { formatDateValue } from './dates';
import { formatPercentageForDisplay, getFieldPrecision } from './numbers';
import { getRootValue } from './objects';
import { getOptionByName } from './options';
import { isAmPmTime } from './time';

export const formatNumberValue = (
  value: any,
  type: any,
  typeOptions: FieldTypeOptions = {},
) => {
  if (isNil(value) || value === '') {
    return value;
  }

  const { format } = typeOptions;
  const fieldPrecision = getFieldPrecision(type, typeOptions);

  if (format === PERCENTAGE) {
    return formatPercentageForDisplay(value, fieldPrecision);
  }

  if (isUndefined(fieldPrecision)) {
    return value;
  }

  return value.toFixed(fieldPrecision);
};

export const formatTimeValue = (value: string, locale: any) => {
  let duration = DateTime.fromISO(value);

  if (!duration.isValid && value.split(':')[0].length === 1) {
    duration = DateTime.fromFormat(value, 'h:mm:ss');
  }

  const formattedDurationValue = duration
    .toLocal()
    .setLocale(locale.code as string)
    .toLocaleString(DateTime.DATETIME_SHORT);

  return duration.isValid
    ? duration.toFormat(
        isAmPmTime(formattedDurationValue as string) ? DURATION_TIME : TIME_24,
      )
    : value;
};

export const formatFieldValue = (
  value: RecordValue,
  field: DataField,
  config: any = {},
  localeName: string = usLocale.code!,
  locale = usLocale,
) => {
  const { typeOptions, type } = field;
  const { format } = typeOptions ?? {};

  if (type === INTEGER || type === DECIMAL) {
    const maximumFractionDigits = getFieldPrecision(
      type,
      field.typeOptions!,
      DEFAULT_PRECISION,
    );
    const minimumFractionDigits = maximumFractionDigits;

    if (format === PERCENTAGE) {
      return `${formatPercentageForDisplay(
        value as number,
        minimumFractionDigits,
      )}%`;
    }

    const roundedValue = round(value as number, maximumFractionDigits);

    const localeStringOptions = {
      maximumFractionDigits,
      minimumFractionDigits,
    };

    if (format === CURRENCY) {
      return `${get(
        field,
        'typeOptions.symbol',
        '$',
      )}${roundedValue.toLocaleString(localeName, localeStringOptions)}`;
    }

    const prefix = get(field, 'typeOptions.prefix', '');
    const suffix = get(field, 'typeOptions.suffix', '');
    const formattedValue =
      format === UNFORMATTED_NUMBER
        ? roundedValue
        : roundedValue.toLocaleString(localeName, localeStringOptions);

    const spacedPrefix = prefix ? `${prefix} ` : '';
    const spacedSuffix = suffix ? ` ${suffix}` : '';
    return `${spacedPrefix}${formattedValue}${spacedSuffix}`;
  }

  if (type === DATE) {
    return formatDateValue(value, field, config);
  }
  if (type === BOOLEAN) {
    return value ? 'true' : 'false';
  }

  if (field.type === DURATION && format === TIME && locale) {
    return formatTimeValue(value as string, locale);
  }

  if (format === COORDINATES) {
    if (!value) {
      return null;
    }

    const coordinatesValue = value as Coordinates;
    if (isNil(coordinatesValue.latitude) && isNil(coordinatesValue.longitude)) {
      return null;
    }

    const formattedLatitude =
      formatNumberValue(coordinatesValue.latitude, DECIMAL) ?? '';
    const formattedLongitude =
      formatNumberValue(coordinatesValue.longitude, DECIMAL) ?? '';

    return `${formattedLatitude}, ${formattedLongitude}`;
  }

  if (format === DATE_RANGE) {
    if (!value) {
      return null;
    }

    const dateRangeValue = value as DateRange;
    if (isNil(dateRangeValue.from) && isNil(dateRangeValue.to)) {
      return null;
    }

    const formattedStart = formatDateValue(dateRangeValue.from, field, config);
    const formattedEnd = formatDateValue(dateRangeValue.to, field, config);

    return `${formattedStart} - ${formattedEnd}`;
  }

  if (type === OBJECT && format) {
    if (!value) {
      return null;
    }

    return getRootValue(format, value);
  }

  if (field.type === SINGLE_OPTION && value) {
    const option = getOptionByName(value as string, field);

    if (option) {
      return option.display;
    }
  }

  if (field.type === MULTIPLE_OPTION && value && Array.isArray(value)) {
    return value
      .map((rawOption) => getOptionByName(rawOption, field)?.display)
      .filter(Boolean)
      .join(', ');
  }
};
