import omit from 'lodash/omit';

const elementProps = [
  '0',
  '1',
  'editorMode',
  'onClick',
  'onMouseOver',
  'onMouseOut',
  'isSelected',
  'elementId',
  'elementPath',
  'loading',
  'project',
  'ref',
  'scope',
  'bgImage',
  'isSelected',
];
export const omitElementProps = (restProps: Record<any, any>) =>
  omit(restProps, elementProps);
