import { useSelector } from 'react-redux';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { isPartnerTrialActive } from '@noloco/core/src/utils/billing';

const useIsPartnerTrialing = () => {
  const project = useSelector(projectDataSelector);
  const billingPlan = useSelector(billingPlanSelector);
  const isPartnerTrialing = isPartnerTrialActive(billingPlan, project);

  return isPartnerTrialing;
};

export default useIsPartnerTrialing;
