import createReactComponent from '../createReactComponent';
export default createReactComponent('shield-checkered', 'IconShieldCheckered', [
  [
    'path',
    {
      d: 'M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 3v18', key: 'svg-1' }],
  ['path', { d: 'M3.5 12h17', key: 'svg-2' }],
]);
