import React, { useCallback, useMemo, useState } from 'react';
import {
  IconAdjustmentsAlt,
  IconDatabase,
  IconUsers,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { useSelector } from 'react-redux';
import { Loader, Modal } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { CREATE, UPDATE } from '@noloco/core/src/constants/actionTypes';
import { USER } from '@noloco/core/src/constants/builtInDataTypes';
import { USER_ROLE } from '@noloco/core/src/constants/defaultRoleReferences';
import Checkbox from '@noloco/core/src/elements/Checkbox';
import AutoForm from '@noloco/core/src/elements/sections/forms/AutoForm';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Role } from '@noloco/core/src/models/User';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { AutoFormProvider } from '@noloco/core/src/utils/hooks/useAutoForm';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import useFormFieldsState from '@noloco/core/src/utils/useFormFieldsState';
import { USER_QUERY } from '../../queries/userTable';
import { useUserRoles } from '../../utils/hooks/useUserRoles';
import DataCell from '../dataTable/DataCell';
import FormLabel from './FormLabel';

const LANG_KEY = 'userTable.form';

type Props = {
  userType: DataType;
};

export const NEW_ID = '~new';

const EditUserModal = ({ userType }: Props) => {
  const [loading, setIsLoading] = useState(false);
  const {
    push,
    location,
    query: { userId },
  }: any = useRouter();
  const errorAlert = useGraphQlErrorAlert();
  const project = useSelector(projectDataSelector);
  const [sendInvite, setSendInvite] = useState(false);

  const mutationType = useMemo(
    () => (userId === NEW_ID ? CREATE : UPDATE),
    [userId],
  );

  const { data, loading: userLoading } = useCacheQuery(USER_QUERY, {
    context: { authQuery: true, projectQuery: true, projectName: project.name },
    variables: { id: userId },
    skip: !userId || userId === NEW_ID,
  });

  const { roles, loading: rolesLoading } = useUserRoles(
    project.name,
    project.dataTypes,
    {
      dataAdmin: true,
      builder: true,
      internal: true,
    },
  );

  const user = useMemo(() => {
    if (userLoading || mutationType === CREATE) {
      return {};
    }

    return get(data, 'user', {});
  }, [data, userLoading, mutationType]);

  const [draftValues] = useFormFieldsState(
    USER,
    mutationType === UPDATE ? userId : undefined,
    false,
  );

  const userValues = useMemo(
    () => ({ ...user, ...draftValues }),
    [draftValues, user],
  );

  const roleId = useMemo(() => get(userValues, 'role.id'), [userValues]);

  const role = useMemo(
    () =>
      (roleId && roles.find((r: Role) => r.id === roleId)) ||
      roles.find((r: Role) => r.referenceId === USER_ROLE),
    [roleId, roles],
  );

  const onClose = useCallback(() => {
    push(`/_/users/${location.search}`);
  }, [location.search, push]);

  const onError = useCallback(
    (error: Error) => {
      errorAlert(getText(LANG_KEY, 'error'), error);
    },
    [errorAlert],
  );

  const formFields = useMemo(
    () =>
      ['firstName', 'lastName', 'email', 'role'].map((fieldName) => {
        const field = userType.fields.getByName(fieldName)!;
        return {
          field,
          config: {
            label: upperFirst(field.display),
            name: field.name,
          },
        };
      }),
    [userType.fields],
  );

  const formClassName = `edit-user-${userId.replace('~', '')}-form`;

  const onConfirm = useCallback(() => {
    const form = document.querySelector(`.${formClassName}`);
    if (form) {
      form.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  }, [formClassName]);

  const firstName = useMemo(
    () => userValues.firstName ?? getText(LANG_KEY, 'roleSummary.placeholder'),
    [userValues.firstName],
  );

  if (rolesLoading || userLoading) {
    return <Loader className="mx-auto py-6" />;
  }

  return (
    <Modal
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      confirmText={loading ? <Loader size="sm" /> : getText(LANG_KEY, 'save')}
      closeOnOutsideClick={false}
      title={getText(LANG_KEY, 'title', mutationType)}
    >
      <div className="flex flex-col">
        <AutoFormProvider
          authQuery={true}
          fields={formFields}
          dataType={userType}
          inline={false}
          value={user}
          Label={FormLabel}
          inviteEnabled={sendInvite && userId === NEW_ID}
          mutationType={mutationType}
          onLoadingChange={setIsLoading}
          onLoadingFinish={() => setIsLoading(false)}
          onSuccess={onClose}
          submitOnBlur={false}
          onError={onError}
          readOnly={false}
          surface={LIGHT}
          ReadOnlyCell={DataCell}
        >
          <AutoForm
            className={classNames(
              'grid grid-cols-2 gap-x-6 gap-y-4 pb-6',
              formClassName,
            )}
          />
        </AutoFormProvider>
        {userId === NEW_ID && (
          <Checkbox
            disabled={role?.builder}
            className="mt-3 flex items-center"
            onChange={() => setSendInvite(!sendInvite)}
            value={sendInvite || role?.builder}
          >
            {getText(LANG_KEY, 'invite')}
          </Checkbox>
        )}
        {userId === NEW_ID && role?.builder && (
          <span className="mt-3">{getText(LANG_KEY, 'adminInvite')}</span>
        )}
        {role && (
          <div className="mt-6 flex flex-col space-y-3">
            <h3 className="text-lg font-medium">
              {getText(LANG_KEY, 'roleSummary.title')}
            </h3>
            <div className="flex items-center space-x-4">
              <IconUsers size={20} />
              <span>
                {getText(
                  { firstName },
                  LANG_KEY,
                  'roleSummary.internal',
                  role.internal,
                )}
              </span>
            </div>
            <div className="flex items-center space-x-4">
              <IconDatabase size={20} />
              <span>
                {getText(
                  { firstName },
                  LANG_KEY,
                  'roleSummary.dataAdmin',
                  role.dataAdmin,
                )}
              </span>
            </div>
            <div className="flex items-center space-x-4">
              <IconAdjustmentsAlt size={20} />
              <span>
                {getText(
                  { firstName },
                  LANG_KEY,
                  'roleSummary.builder',
                  role.builder,
                )}
              </span>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditUserModal;
