import createReactComponent from '../createReactComponent';
export default createReactComponent('door-off', 'IconDoorOff', [
  ['path', { d: 'M3 21h18', key: 'svg-0' }],
  ['path', { d: 'M6 21v-15', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M7.18 3.175c.25 -.112 .528 -.175 .82 -.175h8a2 2 0 0 1 2 2v9',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M18 18v3', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
