import { useMemo } from 'react';
import withVisibilityRules from '../../../components/canvas/withVisibilityRules';
import DataTypes, { DataType } from '../../../models/DataTypes';
import { Element } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { RecordEdge } from '../../../models/Record';
import CollectionChartWrapper from '../charts/CollectionChartWrapper';

type CollectionChartsProps = {
  charts: any;
  dataType: DataType;
  dataTypes: DataTypes;
  edges: RecordEdge[];
  editorMode: boolean;
  element: Element;
  EmptyState: () => JSX.Element;
  project: Project;
  scope: Record<string, any>;
  nodeQueryObject: any;
};

const CollectionCharts = ({
  charts,
  dataType,
  dataTypes,
  edges,
  editorMode,
  element,
  EmptyState,
  project,
  nodeQueryObject,
  scope,
}: CollectionChartsProps) => {
  const data = useMemo(
    () =>
      edges.map((edge: RecordEdge) => ({
        ...edge.node,
        _dataType: dataType.apiName,
      })),
    [edges, dataType],
  );

  if (charts.length === 0) {
    return null;
  }

  return (
    <div className="grid grid-cols-12 gap-4 p-0">
      {charts.filter(Boolean).map((chart: any) => {
        const ChartWithVisibilityRules = withVisibilityRules(
          CollectionChartWrapper,
          editorMode,
          chart.visibilityRules,
        );

        return (
          <ChartWithVisibilityRules
            chart={chart}
            data={data}
            dataType={dataType}
            dataTypes={dataTypes}
            element={element}
            EmptyState={EmptyState}
            key={chart.id}
            project={project}
            scope={scope}
            visibilityRulesScope={scope}
            edges={edges}
            nodeQueryObject={nodeQueryObject}
          />
        );
      })}
    </div>
  );
};

export default CollectionCharts;
