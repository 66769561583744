import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-cancel',
  'IconDeviceDesktopCancel',
  [
    [
      'path',
      {
        d: 'M12.5 16h-8.5a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v7.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h5', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-3' }],
    ['path', { d: 'M17 21l4 -4', key: 'svg-4' }],
  ],
);
