import first from 'lodash/first';
import get from 'lodash/get';
import range from 'lodash/range';
import snakeCase from 'lodash/snakeCase';
import tailwindColors from 'tailwindcss/colors';
import { Color } from '@noloco/components/src/models/theme';
import { getColorShade } from '@noloco/components/src/utils/colors';
import { baseColors } from '../constants/tailwindStyles';
import { ThemeSettings } from '../models/Project';
import { conditionsAreMet } from './data';
import { CUSTOM_PRIMARY_COLOR } from './styles';

export const COLOR_SHADES = range(0, 1000, 100).map((shade) =>
  Math.max(shade, 50),
);

export const getColorByIndex = (index: number, shade = 500) =>
  getColorShade(
    baseColors[baseColors.length - (index % baseColors.length) - 1],
    shade,
  );

export const getColorWithoutShadeByIndex = (index: number) =>
  getColorByIndex(index).split('-')[0];

export const mapAirtableColor = (color: string) => {
  if (color === undefined) {
    return 'gray';
  } else {
    const convertedColor = first(snakeCase(color).split('_'));
    if (baseColors.includes(convertedColor as Color)) {
      return convertedColor;
    }
  }
};

export const getColorsForColorGroup = (colorGroup: any, customColors: any) => {
  if (colorGroup === CUSTOM_PRIMARY_COLOR && customColors) {
    return customColors;
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return tailwindColors[colorGroup] || tailwindColors.blue;
};

export const formatHexCode = (str: any) =>
  (str ? `#${str.replaceAll('#', '')}` : '')
    .replace(/([^0-9a-fA-F#])/g, '')
    .substring(0, 7);

export const isValidHexCode = (str: any) =>
  str && /^#([0-9a-fA-F]{2}){3}$/.test(str);

export const restrictInputToHexCodes = (event: any) => {
  event.target.value = formatHexCode(event.target.value);
};

export const updateCustomShadeCssVariable = (shade: any, value: any) =>
  document.documentElement.style.setProperty(`--nlc-primary-${shade}`, value);

export const updateCustomThemeCssVariables = (customColors: any) => {
  COLOR_SHADES.forEach((colorShade) => {
    updateCustomShadeCssVariable(colorShade, customColors[colorShade]);
  });
};

export const getPrimaryColorGroup = (theme: ThemeSettings) => {
  const primaryColor = theme?.brandColorGroups?.primary;
  const defaultColorGroup = tailwindColors.blue;
  if (primaryColor === CUSTOM_PRIMARY_COLOR) {
    return get(theme, 'customColors.primary', defaultColorGroup);
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return tailwindColors[primaryColor] || defaultColorGroup;
};

export const getColorBasedOnConditions = (
  colorConditions: any[],
  scope: any,
  project: any,
) => {
  if (colorConditions) {
    for (const { conditions, color } of colorConditions) {
      if (conditionsAreMet(conditions, scope, project)) {
        return color;
      }
    }
  }
  return undefined;
};
