import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-android', 'IconBrandAndroid', [
  ['path', { d: 'M4 10l0 6', key: 'svg-0' }],
  ['path', { d: 'M20 10l0 6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M7 9h10v8a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-8a5 5 0 0 1 10 0',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M8 3l1 2', key: 'svg-3' }],
  ['path', { d: 'M16 3l-1 2', key: 'svg-4' }],
  ['path', { d: 'M9 18l0 3', key: 'svg-5' }],
  ['path', { d: 'M15 18l0 3', key: 'svg-6' }],
]);
