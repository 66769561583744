import createReactComponent from '../createReactComponent';
export default createReactComponent('cane', 'IconCane', [
  [
    'path',
    {
      d: 'M9 21l6.324 -11.69c.54 -.974 1.756 -4.104 -1.499 -5.762c-3.255 -1.657 -5.175 .863 -5.825 2.032',
      key: 'svg-0',
    },
  ],
]);
