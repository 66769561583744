import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'math-function-off',
  'IconMathFunctionOff',
  [
    ['path', { d: 'M14 10h1c.882 0 .986 .777 1.694 2.692', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M13 17c.864 0 1.727 -.663 2.495 -1.512m1.717 -2.302c.993 -1.45 2.39 -3.186 3.788 -3.186',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M3 19c0 1.5 .5 2 2 2s2 -4 3 -9c.237 -1.186 .446 -2.317 .647 -3.35m.727 -3.248c.423 -1.492 .91 -2.402 1.626 -2.402c1.5 0 2 .5 2 2',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M5 12h6', key: 'svg-3' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
  ],
);
