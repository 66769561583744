import {
  BOTTOM_BORDER,
  BOX_SHADOW,
  INSET_SHADOW,
  NO_BORDER,
  ROUNDED_FULL,
  ROUNDED_LARGE,
  ROUNDED_SMALL,
  SQUARE,
  THICK_BORDER,
} from './inputStyles';

const getInputTheme = (borderColor: any) => ({
  default: {},
  [ROUNDED_SMALL]: { border: [true, borderColor], rounded: true },
  [ROUNDED_LARGE]: { border: [true, borderColor], rounded: 'lg' },
  [ROUNDED_FULL]: { border: [true, borderColor], rounded: 'full' },
  [SQUARE]: { border: [true, borderColor], rounded: false },
  [THICK_BORDER]: { border: [4, borderColor] },
  [NO_BORDER]: { border: false },
  [INSET_SHADOW]: { border: [true, borderColor], shadow: 'inner' },
  [BOX_SHADOW]: { border: [true, borderColor], shadow: true },
  [BOTTOM_BORDER]: { rounded: false, borderB: 4, border: borderColor },
});

export default getInputTheme;
