import createReactComponent from '../createReactComponent';
export default createReactComponent('shield-minus', 'IconShieldMinus', [
  [
    'path',
    {
      d: 'M12.46 20.871c-.153 .046 -.306 .089 -.46 .129a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 -.916 9.015',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
]);
