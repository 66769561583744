import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-tablet-share',
  'IconDeviceTabletShare',
  [
    [
      'path',
      {
        d: 'M12 21h-6a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v9',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12.57 16.178a1 1 0 1 0 .016 1.633', key: 'svg-1' }],
    ['path', { d: 'M16 22l5 -5', key: 'svg-2' }],
    ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-3' }],
  ],
);
