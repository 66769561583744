import createReactComponent from '../createReactComponent';
export default createReactComponent('edit-circle', 'IconEditCircle', [
  [
    'path',
    {
      d: 'M12 15l8.385 -8.415a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 5l3 3', key: 'svg-1' }],
  ['path', { d: 'M9 7.07a7 7 0 0 0 1 13.93a7 7 0 0 0 6.929 -6', key: 'svg-2' }],
]);
