import createReactComponent from '../createReactComponent';
export default createReactComponent('database-import', 'IconDatabaseImport', [
  [
    'path',
    {
      d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 6v6c0 1.657 3.582 3 8 3c.856 0 1.68 -.05 2.454 -.144m5.546 -2.856v-6',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M4 12v6c0 1.657 3.582 3 8 3c.171 0 .341 -.002 .51 -.006',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 22v-6', key: 'svg-3' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-4' }],
]);
