import { INTERNAL } from '../constants/dataWrapperTypes';
import * as elements from '../constants/elements';
import { DATABASE } from '../constants/scopeTypes';
import { Element } from '../models/Element';
import { Project } from '../models/Project';
import StateItem from '../models/StateItem';
import { getText } from './lang';

export const getStateItemOption = (stateItem: StateItem) => ({
  value: stateItem,
  label: stateItem.display,
});

export const deriveViewState = (
  { id, props: { dataList } }: Element,
  project: Project,
  elementPath: string[],
  context: any,
) => {
  let state: any[] = [];
  if (
    dataList.dataType &&
    (!dataList.dataSource || dataList.dataSource === INTERNAL)
  ) {
    const dataType = project.dataTypes.getByName(dataList.dataType);

    if (dataType) {
      const label = getText(
        { dataType: dataType.display },
        'elements',
        elements.LIST,
        'state.listItem',
      );

      // If we're not requesting a collecion, we're requesting the individual values of one of the records
      if (!context.collections) {
        state = state.concat(
          context.getDataTypeOptions(
            new StateItem({
              id: `${id}:VIEW`,
              dataType: dataList.dataType,
              path: '',
              source: DATABASE,
              display: label,
            }),
          ),
        );
      } else {
        state = state.concat(
          context.getDataTypeOptions(
            new StateItem({
              id: `${id}:VIEW`,
              dataType: dataList.dataType,
              path: 'edges.node',
              source: DATABASE,
              display: label,
            }),
          ),
        );
      }
    }
  }

  return state;
};

export const getDataListState = (
  id: string,
  props: Record<any, any> = {},
  project: Project,
  context: any,
) => {
  let state: any[] = [];
  if (props.dataType && (!props.dataSource || props.dataSource === INTERNAL)) {
    const dataType = project.dataTypes.getByName(props.dataType);

    if (dataType) {
      const label = getText(
        { dataType: dataType.display },
        'elements',
        elements.LIST,
        'state.listItem',
      );

      state = state.concat(
        context.getDataTypeOptions(
          new StateItem({
            id,
            dataType: props.dataType,
            path: 'edges.node',
            source: DATABASE,
            display: label,
          }),
        ),
      );
    }
  }

  return state;
};
