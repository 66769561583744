import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-ipad-minus',
  'IconDeviceIpadMinus',
  [
    [
      'path',
      {
        d: 'M12.5 21h-6.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v10',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 18h3', key: 'svg-1' }],
    ['path', { d: 'M16 19h6', key: 'svg-2' }],
  ],
);
