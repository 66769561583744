import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import { TailwindThemeProvider } from '@darraghmckay/tailwind-react-ui';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { draftDataTypesSelector } from '@noloco/core/src/selectors/projectSelectors';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import useIsTrialExpired from '@noloco/core/src/utils/hooks/useIsTrialExpired';
import useUserPermissions from '@noloco/core/src/utils/hooks/useUserPermissions';
import { useSwapDraftDataTypes } from '../utils/hooks/useUpdateDataTypes';
import AdminLoadableFallback from './AdminLoadableFallback';
import { LeftTabs } from './AdminNavbar';
import DataSourceSetupRoutes from './DataSourceSetupRoutes';
import DataTabRoutes from './DataTabRoutes';
import UsersTable from './usersTable/UsersTable';

const loadableOptions = {
  fallback: <AdminLoadableFallback />,
};

const LazyWorkflows = lazy(() => import('./dataTable/Workflows'));

const LazySettingsNav = lazy(() => import('./SettingsNav'));

const LazyVisualizeDataTables = lazy(
  () => import('./visualize/VizualizeProjectDataTables'),
);

const AdminRoutes = ({ nolocoTheme, userFirstName }: any) => {
  const { isBuilder, isDataAdmin } = useUserPermissions();
  const { isNolocoAdmin } = useIsBuilder();
  const isTrialExpired = useIsTrialExpired();

  const hideRoutesWhenTrialExpired = useMemo(
    () => isTrialExpired && !isNolocoAdmin,
    [isNolocoAdmin, isTrialExpired],
  );

  const dispatch = useDispatch();
  const swapDraftDataTypes = useSwapDraftDataTypes();
  const draftDataTypes = useSelector(draftDataTypesSelector);
  useEffect(() => {
    if (draftDataTypes) {
      swapDraftDataTypes();
    }
  }, [dispatch, draftDataTypes, swapDraftDataTypes]);

  return (
    <TailwindThemeProvider theme={nolocoTheme}>
      <Switch>
        {isDataAdmin && !hideRoutesWhenTrialExpired && (
          <Route path="/_/setup">
            <DataSourceSetupRoutes />
          </Route>
        )}
        {isDataAdmin && !hideRoutesWhenTrialExpired && (
          <Route path={`/_/${LeftTabs.DATA}/:subTab?/:dataType?`} exact={false}>
            <DataTabRoutes userFirstName={userFirstName} />
          </Route>
        )}
        {isDataAdmin && (
          <Route path={`/_/${LeftTabs.USERS}`} exact={false}>
            <UsersTable />
          </Route>
        )}
        {isBuilder && !hideRoutesWhenTrialExpired && (
          <Route exact={false} path={`/_/workflows/:workflowId?`}>
            <Suspense {...loadableOptions}>
              <LazyWorkflows />
            </Suspense>
          </Route>
        )}
        {isBuilder && (
          <Route path={`/_/${LeftTabs.SETTINGS}`}>
            {' '}
            <Suspense {...loadableOptions}>
              <LazySettingsNav />{' '}
            </Suspense>
          </Route>
        )}
        {isDataAdmin && !hideRoutesWhenTrialExpired && (
          <Route path={`/_/visualize-data`}>
            {' '}
            <Suspense {...loadableOptions}>
              <LazyVisualizeDataTables />{' '}
            </Suspense>
          </Route>
        )}
        {hideRoutesWhenTrialExpired && (
          <Redirect to={`/_/${LeftTabs.SETTINGS}/billing`} />
        )}
      </Switch>
    </TailwindThemeProvider>
  );
};

export default AdminRoutes;
