import createReactComponent from '../createReactComponent';
export default createReactComponent('clock-question', 'IconClockQuestion', [
  ['path', { d: 'M20.975 11.33a9 9 0 1 0 -5.717 9.06', key: 'svg-0' }],
  ['path', { d: 'M12 7v5l2 2', key: 'svg-1' }],
  ['path', { d: 'M19 22v.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-3',
    },
  ],
]);
