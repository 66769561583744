import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import usLocale from 'date-fns/locale/en-US';
import { DateTime, Settings } from 'luxon';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import locales from '../../locales';

const US_LOCALE = 'en-US';

const DEFAULT_NUMBER_LOCALE = {
  thousandSeparator: ',',
  decimalSeparator: '.',
};

const TEST_NUMBER = 123456.789;

const formatNumberForLocale = (localeCode: any) =>
  new Intl.NumberFormat(localeCode).format(TEST_NUMBER);

export const getLocaleName = () =>
  Intl.DateTimeFormat().resolvedOptions().locale || US_LOCALE;

const appendNumberLocaleOptions = (locale: any) => {
  const localeName = getLocaleName();
  const formattedNumberString = formatNumberForLocale(localeName);
  const matches = formattedNumberString.match(/([^\d\n])+/g);
  if (matches && matches.length === 2) {
    locale.thousandSeparator = matches[0];
    locale.decimalSeparator = matches[1];
    return locale;
  }

  locale.thousandSeparator = DEFAULT_NUMBER_LOCALE.thousandSeparator;
  locale.decimalSeparator = DEFAULT_NUMBER_LOCALE.decimalSeparator;
  return locale;
};

let localePromise: Promise<{ default: Locale }> | null = null;

// Set default locale based on OS
Settings.defaultLocale = DateTime.now().resolvedLocaleOptions().locale;

const loadLocale: (localeName: string) => Promise<Locale | null> = async (
  localeName: string,
) => {
  let locale = null;
  if (localePromise === null && locales[localeName] !== undefined) {
    localePromise = locales[localeName]();

    locale = (await localePromise)?.default;
    registerLocale(locale.code!, locale);
  }

  if (locale) {
    return locale;
  }

  if (!locale && localeName.includes('-')) {
    console.log('Error loading locale', localeName);
    localePromise = null;
    return loadLocale(localeName.split('-')[0]);
  }

  return null;
};

const getLocale: () => Promise<Locale> = async () => {
  const localeName = getLocaleName();
  const locale = await loadLocale(localeName);

  if (locale && locale.code) {
    Settings.defaultLocale = DateTime.now().setLocale(locale.code).locale;
    return locale;
  }

  return usLocale;
};

const useLocaleProvider: () => Locale = () => {
  const [locale, setLocale] = useState(appendNumberLocaleOptions(usLocale));
  useEffect(() => {
    registerLocale(US_LOCALE, usLocale);
    setDefaultLocale(US_LOCALE);
  }, []);

  useEffect(() => {
    getLocale().then((newLocale) => {
      if (newLocale) {
        setLocale(appendNumberLocaleOptions(newLocale));
      }
    });
  }, []);

  return locale;
};

const localeContext = createContext(usLocale);

export const LocaleProvider = ({ children }: any) => {
  const locale = useLocaleProvider();
  return (
    <localeContext.Provider value={locale}>{children}</localeContext.Provider>
  );
};

const useLocale = () => {
  return useContext(localeContext);
};

export const useLocales = () => {
  const locale = useContext(localeContext);

  return useMemo(() => {
    const locs: Record<string, Locale> = {
      [US_LOCALE]: usLocale,
    };

    if (locale && locale.code && locale.code !== US_LOCALE) {
      locs[locale.code] = locale;
    }

    return locs;
  }, [locale]);
};

export default useLocale;
