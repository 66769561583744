import {
  INVOICE,
  INVOICE_LINE_ITEM,
  MEMBERSHIP,
  SUBSCRIPTION,
} from './builtInDataTypes';

const TYPES_WITHOUT_MUTATIONS = [
  MEMBERSHIP,
  INVOICE,
  INVOICE_LINE_ITEM,
  SUBSCRIPTION,
];

export default TYPES_WITHOUT_MUTATIONS;
