import React, { memo } from 'react';
import { IconEyeCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import { Popover } from '@noloco/components';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import VisibilityRulesEditor from '../VisibilityRulesEditor';

type Props = {
  alwaysHidden: boolean;
  children?: JSX.Element;
  dataType?: DataType;
  element: { visibilityRules?: any };
  elementPath: ElementPath;
  onChange?: (path: ElementPath, value: any) => void;
  placement?: string;
  project: Project;
};

const PopoverVisibilityRulesEditor = memo(
  ({
    alwaysHidden,
    children,
    dataType,
    element,
    elementPath,
    onChange,
    placement = 'right',
    project,
  }: Props) => (
    <Popover
      bg="slate-800"
      border={[true, 'slate-700']}
      closeOnOutsideClick={true}
      content={
        !alwaysHidden && (
          <VisibilityRulesEditor
            dataType={dataType}
            element={element}
            elementPath={elementPath}
            onChange={onChange}
            project={project}
          />
        )
      }
      disabled={alwaysHidden}
      placement={placement}
      showArrow={false}
    >
      {children ?? (
        <button
          className={classNames('flex', {
            'text-slate-500 hover:text-slate-400': !alwaysHidden,
            'cursor-default': alwaysHidden,
          })}
        >
          <IconEyeCheck
            className={classNames({ 'opacity-50': alwaysHidden })}
            size={16}
          />
        </button>
      )}
    </Popover>
  ),
);

export default PopoverVisibilityRulesEditor;
