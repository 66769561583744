import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'column-insert-right',
  'IconColumnInsertRight',
  [
    [
      'path',
      {
        d: 'M6 4h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M15 12l4 0', key: 'svg-1' }],
    ['path', { d: 'M17 10l0 4', key: 'svg-2' }],
  ],
);
