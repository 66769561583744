import { useCallback, useMemo } from 'react';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import initial from 'lodash/initial';
import {
  UpdateProjectCallback,
  useAddChild,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { getUniqueRoutePath } from '@noloco/ui/src/utils/layout';
import { getSidebarItemProps } from '@noloco/ui/src/utils/sidebar';
import { Item, ItemTypes } from '../../../constants/buildMode';
import {
  DIVIDER,
  FOLDER,
  IFRAME,
  LINK,
  PAGE,
  VIEW,
} from '../../../constants/elements';
import { DataType } from '../../../models/DataTypes';
import { Element } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { NEW_PAGE_SAVED, trackEvent } from '../../../utils/analytics';
import useAddPage from '../../../utils/hooks/useAddPage';
import { useAddView } from '../../../utils/hooks/useAddView';
import useRouter from '../../../utils/hooks/useRouter';
import { getText } from '../../../utils/lang';
import { Page, getPagesConfig } from '../../../utils/pages';
import BuildModeBaseItem from './BuildModeBaseItem';
import BuildModeParentItem from './BuildModeParentItem';

type BuildModeSidebarNavProps = {
  activePage?: Page;
  listExpanded: boolean;
  portalPages: Page[];
  project: Project;
  showHiddenPages?: boolean;
  sidebarExpanded?: boolean;
  updateProject: UpdateProjectCallback;
};

const reorderPages = (pages: Page[], newIndex: number, oldIndex: number) => {
  const newPagesOrder = [...pages];
  const droppedPage = get(pages, oldIndex);

  newPagesOrder.splice(oldIndex, 1);
  newPagesOrder.splice(newIndex, 0, droppedPage);

  return newPagesOrder;
};

const BuildModeSidebarNav = ({
  activePage,
  listExpanded,
  portalPages,
  project,
  showHiddenPages = false,
  sidebarExpanded = true,
  updateProject,
}: BuildModeSidebarNavProps) => {
  const { push } = useRouter();
  const onAddPage = useAddPage(project);
  const onAddView = useAddView(project);
  const {
    isV2,
    pagesPath,
    projectPages: elements,
  } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project],
  );
  const [addChild] = useAddChild(
    project,
    isV2 ? [] : initial(pagesPath),
    isV2 ? [] : ['children'],
  );
  const getElementPath = useCallback(
    (id: string) => [portalPages.findIndex((page) => page.id === id)],
    [portalPages],
  );

  const items = useMemo(
    () =>
      portalPages
        .map((portalPage) => {
          const portalPageId = get(portalPage, 'id');
          const parentPageId = get(portalPage, 'props.parentPage', null);
          const parentPageType = get(portalPage, 'type', null);
          const childItems = portalPages
            .filter(
              (childItem) =>
                get(childItem, 'props.parentPage') === portalPageId,
            )
            .map((childPage) => ({
              elementPath: getElementPath(childPage.id),
              id: childPage.id,
              page: childPage,
              parentPageId: childPage.props.parentPage,
              parentPageType,
              type: ItemTypes.SINGLE_ITEM,
            })) as Item[];

          return {
            childItems,
            elementPath: getElementPath(portalPageId),
            id: portalPageId,
            page: portalPage,
            ...(parentPageId ? { parentPageId } : {}),
            type:
              childItems.length === 0 && parentPageType !== FOLDER
                ? ItemTypes.SINGLE_ITEM
                : parentPageType === FOLDER
                  ? ItemTypes.FOLDER
                  : ItemTypes.NESTED_ITEM,
          };
        })
        .filter(
          (item) =>
            !get(item, 'page.props.parentPage') &&
            get(item, 'page.props.hide', false) === showHiddenPages,
        ),
    [portalPages, showHiddenPages, getElementPath],
  );

  const isParentActive = useCallback(
    (item: Item) => {
      if (activePage?.id === item.id) {
        return true;
      }

      return !!item.childItems?.find(
        (childItem) => childItem.id === activePage?.id,
      );
    },
    [activePage],
  );

  const onAddSidebarDivider = useCallback(
    (index) => addChild(getSidebarItemProps({ type: DIVIDER }), index),
    [addChild],
  );

  const onAddSidebarLink = useCallback(
    (index, parentPage = null) => {
      const existingLinks = elements.filter((element) => element.type === LINK);
      const linkName = getText(
        { count: existingLinks.length + 1 },
        'leftSidebar.pages.link',
      );
      addChild(
        getSidebarItemProps({ type: LINK, name: linkName, parentPage }),
        index,
      );
    },
    [addChild, elements],
  );

  const onAddBlankPage = useCallback(
    (index, parentPage = null) =>
      onAddPage(getSidebarItemProps({ type: PAGE, parentPage }), index),
    [onAddPage],
  );

  const onAddIframe = useCallback(
    (index, parentPage = null) =>
      onAddPage(getSidebarItemProps({ type: IFRAME, parentPage }), index),
    [onAddPage],
  );

  const onAddPageFolder = useCallback(
    (index) => {
      trackEvent(NEW_PAGE_SAVED);
      const existingFolders = elements.filter(
        (element) => element.type === FOLDER,
      );
      const folderName = getText(
        { count: existingFolders.length + 1 },
        'leftSidebar.pages.folder',
      );
      const newFolder = getSidebarItemProps({
        type: FOLDER,
        name: folderName,
        routePath: getUniqueRoutePath(String(folderName), project),
      });
      addChild(newFolder, index);

      setTimeout(() => {
        push(`/${newFolder?.props.routePath}`);
      }, 500);
    },
    [addChild, elements, project, push],
  );

  const actions = useMemo(
    () => ({
      [DIVIDER]: onAddSidebarDivider,
      [FOLDER]: onAddPageFolder,
      [IFRAME]: (index: number, parentPageId: string) =>
        onAddIframe(index, parentPageId),
      [LINK]: (index: number, parentPageId: string) =>
        onAddSidebarLink(index, parentPageId),
      [PAGE]: (index: number, parentPageId: string) =>
        onAddBlankPage(index, parentPageId),
      [VIEW]: (
        index: number | undefined,
        parentPage: Element | undefined,
        dataType: DataType,
      ) => onAddView(dataType, index, parentPage),
    }),
    [
      onAddBlankPage,
      onAddIframe,
      onAddPageFolder,
      onAddSidebarDivider,
      onAddSidebarLink,
      onAddView,
    ],
  );

  const handleDrop = useCallback(
    (target: Item, draggedItem: Item, targetType = ItemTypes.SINGLE_ITEM) => {
      const targetIndex = first(target.elementPath) as number;
      const draggedItemIndex = first(draggedItem.elementPath) as number;

      if (draggedItem && draggedItem.new) {
        const dataType = project.dataTypes.getByName(
          draggedItem.page.props.dataList.dataType,
        );

        const action = (actions as any)[draggedItem.page.type];
        if (action) {
          if (draggedItem.page.type === VIEW) {
            return action(
              targetIndex,
              targetType === ItemTypes.FOLDER ||
                targetType === ItemTypes.NESTED_ITEM
                ? target.page
                : portalPages.find(
                    (portalPage) => portalPage.id === target.parentPageId,
                  ),
              dataType,
            );
          }

          return action(
            targetIndex,
            targetType === ItemTypes.FOLDER ||
              targetType === ItemTypes.NESTED_ITEM
              ? target.id
              : target.parentPageId,
            dataType,
          );
        }
      }

      const updatedDraggedItem = {
        ...draggedItem,
        page: {
          ...draggedItem.page,
          props: {
            ...get(draggedItem, 'page.props'),
            hide: false,
          },
        },
      };

      // page moved from outside to in-between pages in folder
      if (targetType === ItemTypes.SINGLE_ITEM && target.parentPageId) {
        const newOrder = reorderPages(
          set(
            draggedItemIndex,
            {
              ...updatedDraggedItem.page,
              props: {
                ...updatedDraggedItem.page.props,
                parentPage: target.parentPageId,
              },
            },
            portalPages,
          ),
          targetIndex,
          draggedItemIndex,
        );

        return updateProject(['elements'], newOrder);
      }

      // movement inside folder or nested view
      if (
        targetType === ItemTypes.SINGLE_ITEM &&
        target.parentPageId &&
        updatedDraggedItem.parentPageId &&
        target.parentPageId === updatedDraggedItem.parentPageId
      ) {
        const newOrder = reorderPages(
          portalPages,
          targetIndex,
          draggedItemIndex,
        );

        return updateProject(['elements'], newOrder);
      }

      // page moved out of either folder or nested view
      if (
        targetType === ItemTypes.SINGLE_ITEM &&
        updatedDraggedItem.parentPageId &&
        !target.parentPageId
      ) {
        const newOrder = reorderPages(
          set(
            draggedItemIndex,
            {
              ...updatedDraggedItem.page,
              props: {
                ...updatedDraggedItem.page.props,
                parentPage: null,
              },
            },
            portalPages,
          ),
          targetIndex,
          draggedItemIndex,
        );

        return updateProject(['elements'], newOrder);
      }

      // pages moved inside folder or nested view
      if (
        targetType === ItemTypes.NESTED_ITEM ||
        targetType === ItemTypes.FOLDER
      ) {
        return updateProject(
          ['elements'],
          set(
            draggedItemIndex,
            {
              ...updatedDraggedItem.page,
              props: {
                ...updatedDraggedItem.page.props,
                parentPage: get(target, 'page.id'),
              },
            },
            portalPages,
          ),
        );
      }

      const newOrder = reorderPages(
        set(draggedItemIndex, updatedDraggedItem.page, portalPages),
        targetIndex,
        draggedItemIndex,
      );

      updateProject(['elements'], newOrder);
    },
    [portalPages, updateProject, actions, project.dataTypes],
  );

  return (
    <>
      {!showHiddenPages && items.length === 0 && (
        <div className="flex h-10 items-center justify-center text-slate-500">
          {getText('leftSidebar.editor.empty')}
        </div>
      )}
      {items.map((item) => {
        if (item.type === ItemTypes.FOLDER) {
          return (
            <BuildModeParentItem
              active={isParentActive(item)}
              handleDrop={handleDrop}
              item={item}
              key={item.id}
              listExpanded={listExpanded}
              portalPages={portalPages}
              project={project}
              showHiddenPages={showHiddenPages}
              sidebarExpanded={sidebarExpanded}
              type={ItemTypes.FOLDER}
            />
          );
        }

        if (item.type === ItemTypes.NESTED_ITEM) {
          return (
            <BuildModeParentItem
              active={isParentActive(item)}
              handleDrop={handleDrop}
              item={item}
              key={item.id}
              listExpanded={listExpanded}
              portalPages={portalPages}
              project={project}
              showHiddenPages={showHiddenPages}
              sidebarExpanded={sidebarExpanded}
              type={ItemTypes.NESTED_ITEM}
            />
          );
        }

        return (
          <BuildModeBaseItem
            handleDrop={handleDrop}
            item={item}
            key={item.id}
            listExpanded={listExpanded}
            portalPages={portalPages}
            project={project}
            sidebarExpanded={sidebarExpanded}
            showHiddenPages={showHiddenPages}
          />
        );
      })}
    </>
  );
};

export default BuildModeSidebarNav;
