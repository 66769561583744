import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-watch-check',
  'IconDeviceWatchCheck',
  [
    [
      'path',
      {
        d: 'M11 18h-2a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v5.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 18v3h2.5', key: 'svg-1' }],
    ['path', { d: 'M9 6v-3h6v3', key: 'svg-2' }],
    ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-3' }],
  ],
);
