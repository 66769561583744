import React, { forwardRef } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { LinkType } from '../constants/linkTypes';
import useRouter from '../utils/hooks/useRouter';
import { getLinkProps } from '../utils/urls';

const trackingParams = [
  'ref',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

const getTrackingParams = (query: any) =>
  trackingParams.reduce((paramAcc, param) => {
    if (query[param]) {
      return {
        ...paramAcc,
        [param]: query[param],
      };
    }
    return paramAcc;
  }, {});

type LinkProps = {
  editorMode: boolean;
  children?: React.ReactNode;
  className?: string;
  link?: {
    type?: LinkType;
    to?: string;
    href?: string;
  };
  onClick?: () => void;
  style?: any;
};

const Link = forwardRef<any, LinkProps>(
  ({ className, children, editorMode, link, onClick, style }, ref) => {
    const { pathname, query } = useRouter();
    const { active: __, ...linkProps } = getLinkProps(
      link,
      editorMode,
      getTrackingParams(query),
    );

    const active = linkProps.to && linkProps.to === pathname;

    return (
      <Box
        className={className}
        {...linkProps}
        active={active}
        onClick={onClick}
        style={style}
        ref={ref}
      >
        {children}
      </Box>
    );
  },
);

export default Link;
