import createReactComponent from '../createReactComponent';
export default createReactComponent('wiper', 'IconWiper', [
  ['path', { d: 'M12 18m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 9l5.5 5.5a5 5 0 0 1 7 0l5.5 -5.5a12 12 0 0 0 -18 0',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 18l-2.2 -12.8', key: 'svg-2' }],
]);
