import createReactComponent from '../createReactComponent';
export default createReactComponent('blur', 'IconBlur', [
  [
    'path',
    {
      d: 'M12 21a9.01 9.01 0 0 0 2.32 -.302a9 9 0 0 0 1.74 -16.733a9 9 0 1 0 -4.06 17.035z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 3v17', key: 'svg-1' }],
  ['path', { d: 'M12 12h9', key: 'svg-2' }],
  ['path', { d: 'M12 9h8', key: 'svg-3' }],
  ['path', { d: 'M12 6h6', key: 'svg-4' }],
  ['path', { d: 'M12 18h6', key: 'svg-5' }],
  ['path', { d: 'M12 15h8', key: 'svg-6' }],
]);
