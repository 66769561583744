import createReactComponent from '../createReactComponent';
export default createReactComponent('badge-4k', 'IconBadge4k', [
  [
    'path',
    {
      d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 9v2a1 1 0 0 0 1 1h1', key: 'svg-1' }],
  ['path', { d: 'M10 9v6', key: 'svg-2' }],
  ['path', { d: 'M14 9v6', key: 'svg-3' }],
  ['path', { d: 'M17 9l-2 3l2 3', key: 'svg-4' }],
  ['path', { d: 'M15 12h-1', key: 'svg-5' }],
]);
