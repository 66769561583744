import React, { Suspense, lazy } from 'react';
import {
  IconBrandYoutube,
  IconChartBar,
  IconChevronsRight,
  IconClick,
  IconCode,
  IconCreditCard,
  IconDatabase,
  IconDots,
  IconFile,
  IconFileCode,
  IconFiles,
  IconFolder,
  IconId,
  IconInfoSquare,
  IconLayout2,
  IconLayoutList,
  IconLink,
  IconListCheck,
  IconMarkdown,
  IconMenu,
  IconMessages,
  IconMouse,
  IconNote,
  IconPhoto,
  IconSeparator,
  IconShape,
  IconSpeakerphone,
  IconSquare,
  IconTypography,
  IconVersions,
  IconVideo,
} from '@tabler/icons-react';
import { Badge, Loader } from '@noloco/components';
import MarkdownText from '../components/MarkdownText';
import OnboardingTasks from '../components/onboardingTasks/OnboardingTasks';
import * as elements from '../constants/elements';
import ElementConfig from '../models/ElementConfig';
import Button from './Button';
import Chart from './Chart';
import Content from './Content';
import Folder from './Folder';
import Group from './Group';
import Iframe from './Iframe';
import Image from './Image';
import Link from './Link';
import Page from './Page';
import PageSwitch from './PageSwitch';
import Record from './Record';
import Text from './Text';
import Video from './Video';
import View from './View';
import baseElementConfig from './baseElementConfig';
import baseElementsConfig from './baseElementConfig';
import Billing from './sections/Billing';
import Breadcrumbs from './sections/Breadcrumbs';
import Collection from './sections/Collection';
import Details from './sections/Details';
import FileSharing from './sections/FileSharing';
import FormSection from './sections/FormSection';
import Highlights from './sections/Highlights';
import Messaging from './sections/Messaging';
import QuickLinks from './sections/QuickLinks';
import Tabs from './sections/Tabs';
import Title from './sections/Title';
import RecordFileGallery from './sections/view/FileGallery';

const LazyYoutubeVideo = lazy(() => import('./YoutubeVideo'));

/*
 * This is the config for the elements that are available in the builder.
 *
 * The config extends the base config from baseElementConfig, which excludes all react based code.
 * Notably, some props are duplicated in both places due to being `combo` props, which would merge poorly otherwise.
 */
const elementsConfig: Record<string, ElementConfig> = {
  [elements.BUTTON]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.BUTTON],
    {
      component: Button,
      Icon: IconMouse,
    },
  ),
  [elements.BADGE]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.BADGE],
    {
      component: Badge,
      Icon: IconNote,
    },
  ),
  [elements.CHART]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.CHART],
    {
      component: Chart,
      Icon: IconChartBar,
    },
  ),
  [elements.CONTENT]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.CONTENT],
    {
      component: Content,
    },
  ),
  [elements.GROUP]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.GROUP],
    {
      component: Group,
      Icon: IconShape,
    },
  ),
  [elements.IFRAME]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.IFRAME],
    {
      component: Iframe,
      Icon: IconFileCode,
    },
  ),
  [elements.IMAGE]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.IMAGE],
    {
      component: Image,
      Icon: IconPhoto,
    },
  ),
  [elements.LINK]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.LINK],
    {
      component: Link,
      Icon: IconLink,
    },
  ),
  [elements.COMMENTS]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.COMMENTS],
    {
      Icon: IconMessages,
    },
  ),
  [elements.DIVIDER]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.DIVIDER],
    {
      Icon: IconSeparator,
    },
  ),
  [elements.FOLDER]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.FOLDER],
    {
      component: Folder,
      Icon: IconFolder,
    },
  ),
  [elements.PAGE]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.PAGE],
    {
      component: Page,
      Icon: IconFile,
    },
  ),
  [elements.PAGE_SWITCH]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.PAGE_SWITCH],
    {
      component: PageSwitch,
      Icon: IconFiles,
    },
  ),
  [elements.TEXT]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.TEXT],
    {
      component: Text,
      Icon: IconTypography,
    },
  ),
  [elements.VIDEO]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.VIDEO],
    {
      component: Video,
      Icon: IconVideo,
    },
  ),
  [elements.YOUTUBE_VIDEO]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.YOUTUBE_VIDEO],
    {
      component: (props: any) => (
        <Suspense fallback={<Loader />}>
          <LazyYoutubeVideo {...props} />
        </Suspense>
      ),
      Icon: IconBrandYoutube,
    },
  ),

  /* --- SECTIONS ----- */
  [elements.BILLING]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.BILLING],
    {
      component: Billing,
      Icon: IconCreditCard,
    },
  ),
  [elements.BREADCRUMBS]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.BREADCRUMBS],
    {
      component: Breadcrumbs,
      Icon: IconDots,
    },
  ),
  [elements.COLLECTION]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.COLLECTION],
    {
      component: Collection,
      Icon: IconLayoutList,
    },
  ),
  [elements.DETAILS]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.DETAILS],
    {
      component: Details,
      Icon: IconId,
    },
  ),
  [elements.EMBED]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.EMBED],
    {
      Icon: IconCode,
    },
  ),
  [elements.FILE_SHARING]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.FILE_SHARING],
    {
      component: FileSharing,
      Icon: IconFiles,
    },
  ),
  [elements.MESSAGING]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.MESSAGING],
    {
      component: Messaging,
      Icon: IconMessages,
    },
  ),
  [elements.TITLE]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.TITLE],
    {
      component: Title,
      Icon: IconSpeakerphone,
    },
  ),
  [elements.FORM_V2]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.FORM_V2],
    {
      component: FormSection,
      Icon: IconMenu,
    },
  ),
  [elements.HIGHLIGHTS]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.HIGHLIGHTS],
    {
      Icon: IconInfoSquare,
      component: Highlights,
    },
  ),
  [elements.QUICK_LINKS]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.QUICK_LINKS],
    {
      Icon: IconLink,
      component: QuickLinks,
    },
  ),
  [elements.MARKDOWN]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.MARKDOWN],
    {
      Icon: IconMarkdown,
      component: MarkdownText,
    },
  ),
  [elements.FILE_GALLERY]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.FILE_GALLERY],
    {
      component: RecordFileGallery,
      Icon: IconFiles,
    },
  ),
  [elements.NOTICE]: ElementConfig.fromBaseConfig(
    baseElementsConfig[elements.NOTICE],
    {
      Icon: IconInfoSquare,
    },
  ),
  [elements.ONBOARDING_TASKS]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.ONBOARDING_TASKS],
    {
      Icon: IconListCheck,
      component: OnboardingTasks,
    },
  ),
  [elements.RECORD]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.RECORD],
    {
      Icon: IconDatabase,
      component: Record,
    },
  ),
  [elements.RECORD_VIEW]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.RECORD_VIEW],
    {
      Icon: IconDatabase,
    },
  ),
  [elements.TABS]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.TABS],
    {
      component: Tabs,
      Icon: IconVersions,
    },
  ),
  [elements.SECTION]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.SECTION],
    {
      component: null,
    },
  ),
  [elements.FORM_SECTION]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.FORM_SECTION],
    {
      component: null,
    },
  ),
  [elements.STAGES]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.STAGES],
    {
      component: null,
      Icon: IconChevronsRight,
    },
  ),
  [elements.ACTION_BUTTONS]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.ACTION_BUTTONS],
    {
      component: null,
      Icon: IconClick,
    },
  ),
  [elements.FIELD_CELL]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.FIELD_CELL],
    {
      component: null,
    },
  ),
  [elements.VIEW]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.VIEW],
    {
      component: View,
      Icon: IconLayout2,
    },
  ),
  [elements.CONTAINER]: ElementConfig.fromBaseConfig(
    baseElementConfig[elements.CONTAINER],
    { Icon: IconSquare },
  ),
};

export default elementsConfig;
