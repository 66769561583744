import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-couchsurfing',
  'IconBrandCouchsurfing',
  [
    [
      'path',
      {
        d: 'M3.1 13c3.267 0 5.9 -.167 7.9 -.5c3 -.5 4 -2 4 -3.5a3 3 0 1 0 -6 0c0 1.554 1.807 3 3 4c1.193 1 2 2.5 2 3.5a1.5 1.5 0 1 1 -3 0c0 -2 4 -3.5 7 -3.5h2.9',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-1' }],
  ],
);
