import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-deno', 'IconBrandDeno', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13.47 20.882l-1.47 -5.882c-2.649 -.088 -5 -1.624 -5 -3.5c0 -1.933 2.239 -3.5 5 -3.5s4 1 5 3c.024 .048 .69 2.215 2 6.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 11h.01', key: 'svg-2' }],
]);
