import React, { useMemo } from 'react';
import { getText } from '@noloco/core/src/utils/lang';
import OnboardingTask from './OnboardingTask';

const LANG_KEY = 'onboardingChecklist.category';

const OnboardingCategory = ({
  category,
  onEnableEditorMode,
  subtitle,
  tasks,
  title,
  hasExternalUsers,
  updateSettings,
}: any) => {
  const remainingTasks = useMemo(
    () => tasks.filter((task: any) => !task.complete).length,
    [tasks],
  );

  return (
    <div className="flex flex-col">
      <h2 className="text-lg font-medium">{title}</h2>
      <h4 className="text-sm text-gray-300">{subtitle}</h4>

      <div className="mt-6 flex items-end justify-between">
        <div className="h-1.5 w-full max-w-sm rounded-lg bg-gray-600">
          <div
            className="h-1.5 rounded-lg bg-blue-300 transition-all duration-200"
            style={{
              width: `${
                ((tasks.length - remainingTasks) / tasks.length) * 100
              }%`,
            }}
          />
        </div>
        <span className="-mb-1 ml-1 mr-2 flex-shrink-0 text-sm font-medium text-gray-300">
          {getText(
            { context: remainingTasks },
            LANG_KEY,
            remainingTasks > 0 ? 'remainingTasks' : 'complete',
          )}
        </span>
      </div>
      <div className="mt-2 divide-y divide-gray-500 rounded-lg border border-gray-500">
        {tasks.map((task: any) => (
          <OnboardingTask
            key={task.id}
            category={category}
            onEnableEditorMode={onEnableEditorMode}
            task={task}
            hasExternalUsers={hasExternalUsers}
            updateSettings={updateSettings}
          />
        ))}
      </div>
    </div>
  );
};

export default OnboardingCategory;
