import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { scopeSelector } from '../../selectors/dataSelectors';

const useMergedScope = (scope: Record<string, any>) => {
  const dataScope = useSelector(scopeSelector);
  return useMemo(() => ({ ...scope, ...dataScope }), [dataScope, scope]);
};

export default useMergedScope;
