import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'adjustments-dollar',
  'IconAdjustmentsDollar',
  [
    ['path', { d: 'M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M6 4v4', key: 'svg-1' }],
    ['path', { d: 'M6 12v8', key: 'svg-2' }],
    ['path', { d: 'M13.366 14.54a2 2 0 1 0 -.216 3.097', key: 'svg-3' }],
    ['path', { d: 'M12 4v10', key: 'svg-4' }],
    ['path', { d: 'M12 18v2', key: 'svg-5' }],
    ['path', { d: 'M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-6' }],
    ['path', { d: 'M18 4v1', key: 'svg-7' }],
    ['path', { d: 'M18 9v1', key: 'svg-8' }],
    [
      'path',
      {
        d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
        key: 'svg-9',
      },
    ],
    ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-10' }],
  ],
);
