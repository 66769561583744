import createReactComponent from '../createReactComponent';
export default createReactComponent('bulldozer', 'IconBulldozer', [
  ['path', { d: 'M2 17a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M12 17a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M19 13v4a2 2 0 0 0 2 2h1', key: 'svg-2' }],
  ['path', { d: 'M14 19h-10', key: 'svg-3' }],
  ['path', { d: 'M4 15h10', key: 'svg-4' }],
  ['path', { d: 'M9 11v-5h2a3 3 0 0 1 3 3v6', key: 'svg-5' }],
  ['path', { d: 'M5 15v-3a1 1 0 0 1 1 -1h8', key: 'svg-6' }],
  ['path', { d: 'M19 17h-3', key: 'svg-7' }],
]);
