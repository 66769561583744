import React from 'react';
import FormField from './FormField';

const CERTIFICATE_PLACEHOLDER =
  'LS0tLS1CRUdJTiBDRVJUSUZJQ0FURS0tLS0tCi4uLgotLS0tLUVORCBDRVJUSUZJQ0FURS0tLS0t==';

const PEM_CERTIFICATE_PLACEHOLDER = `-----BEGIN CERTIFICATE-----
${CERTIFICATE_PLACEHOLDER}
-----END CERTIFICATE-----`;

const PublicCertificateFormField = ({
  name,
  onChange,
  placeholderFormat,
  ...rest
}: any) => (
  <FormField
    inputType="text-area"
    name={name || 'cert'}
    onChange={({ target: { value } }: any) => onChange(value)}
    placeholder={
      placeholderFormat === 'pem'
        ? PEM_CERTIFICATE_PLACEHOLDER
        : CERTIFICATE_PLACEHOLDER
    }
    type="text"
    {...rest}
  />
);

export default PublicCertificateFormField;
