import createReactComponent from '../createReactComponent';
export default createReactComponent('mickey', 'IconMickey', [
  [
    'path',
    {
      d: 'M5.5 3a3.5 3.5 0 0 1 3.25 4.8a7.017 7.017 0 0 0 -2.424 2.1a3.5 3.5 0 1 1 -.826 -6.9z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18.5 3a3.5 3.5 0 1 1 -.826 6.902a7.013 7.013 0 0 0 -2.424 -2.103a3.5 3.5 0 0 1 3.25 -4.799z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 14m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0', key: 'svg-2' }],
]);
