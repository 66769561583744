import React, { useCallback, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import FormattedNumberInput from './FormattedNumberInput';

export const getInlineStyles = (theme: any) => {
  const { surface: themeSurface = 'default' } = theme.textInput || {};
  const textColor = theme.textColors.on[themeSurface] || theme.textColors.body;

  return {
    border: 'transparent',
    bg: 'transparent',
    text: ['xs', textColor],
    P: { x: 0, y: 0 },
  };
};

const InlineFormattedNumberInput = ({
  id,
  autoFocus,
  className,
  decimalScale,
  value,
  onBlur,
  onChange,
  onFocus,
  onFocusChange,
  prefix,
  suffix,
  style,
  styleObj,
  surface,
  theme,
  thousandSeparator,
  ...rest
}: any) => {
  const handleOnBlur = useCallback(
    (event: any) => {
      if (onBlur) {
        onBlur(event);
      }
      onFocusChange(false);
    },
    [onBlur, onFocusChange],
  );

  const handleOnFocus = useCallback(
    (event: any) => {
      if (onFocus) {
        onFocus(event);
      }
      onFocusChange(true);
    },
    [onFocus, onFocusChange],
  );

  const inlineStyleProps = useMemo(() => getInlineStyles(theme), [theme]);

  return (
    <FormattedNumberInput
      id={id}
      autoFocus={autoFocus}
      inline={true}
      className={classNames(className, 'text-right')}
      decimalScale={decimalScale}
      fixedDecimalScale={true}
      value={value}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onChange={onChange}
      thousandSeparator={thousandSeparator}
      style={style}
      styleObj={styleObj}
      surface={surface}
      prefix={prefix}
      suffix={suffix}
      {...inlineStyleProps}
      {...rest}
    />
  );
};

InlineFormattedNumberInput.defaultProps = {
  onFocusChange: () => null,
};

export default withTheme(InlineFormattedNumberInput);
