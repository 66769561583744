import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-rounded-number-4',
  'IconSquareRoundedNumber4',
  [
    ['path', { d: 'M10 8v3a1 1 0 0 0 1 1h3', key: 'svg-0' }],
    ['path', { d: 'M14 8v8', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-2',
      },
    ],
  ],
);
