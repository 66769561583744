import React, { Dispatch, useCallback, useMemo, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { Button, TextArea } from '@noloco/components';
import {
  LINK,
  OUTLINE,
} from '@noloco/components/src/components/button/buttonTypes';
import { DARK } from '@noloco/components/src/constants/surface';
import { MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import { ID } from '@noloco/core/src/elements/sections/collections/CollectionEvents';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { getText } from '@noloco/core/src/utils/lang';
import { UpsertedOption } from './NewFieldForm';

type BulkAddOptionsProps = {
  dataField?: DataField;
  dataTypeId?: ID;
  newDataField?: boolean;
  options: UpsertedOption[];
  projectName?: string;
  setOptions: Dispatch<React.SetStateAction<UpsertedOption[]>>;
  setShowBulkAddScreen: (showBulkAddScreen: boolean) => void;
};

const BulkAddOptions = ({
  newDataField = false,
  options,
  setOptions,
  setShowBulkAddScreen,
}: BulkAddOptionsProps) => {
  const [newOptions, setNewOptions] = useState<
    ({ display: string } | UpsertedOption)[]
  >(newDataField ? options : []);

  const filteredOptions = useMemo(
    () => newOptions.filter((option) => option.display !== ''),
    [newOptions],
  );

  const optionsText = useMemo(
    () => filteredOptions.map((option) => option.display).join('\n'),
    [filteredOptions],
  );

  const handleOnChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setNewOptions(
        value
          .split('\n')
          .map((optionValue) => optionValue.trim())
          .filter((optionValue) => optionValue !== '')
          .map((display) => ({ display })),
      ),
    [setNewOptions],
  );

  const handleBack = useCallback(
    () => setShowBulkAddScreen(false),
    [setShowBulkAddScreen],
  );

  const handleSubmit = useCallback(async () => {
    const newOptionsOrdered = filteredOptions.map((option, index) => ({
      ...option,
      order: options.length + index,
      id: uuidV4(),
    }));
    const allOptions = [...options, ...newOptionsOrdered];
    setOptions(allOptions);
    setShowBulkAddScreen(false);
  }, [setShowBulkAddScreen, setOptions, filteredOptions, options]);

  return (
    <>
      {newDataField && (
        <div className="text-white">{getText('data.fields.bulkAdd.title')}</div>
      )}
      <div className="flex flex-col md:hidden">
        <span className="mt-1 text-xs font-medium text-gray-400">
          {getText('data.fields.bulkAdd.subtitle')}
        </span>
        <TextArea
          className="mt-2 w-full p-1"
          size={SM}
          surface={DARK}
          type="textarea"
          minRows={5}
          autoFocus={true}
          onChange={handleOnChange}
          placeholder=""
          value={optionsText}
        />
      </div>
      <div className="mt-4 flex items-center justify-between">
        <Button onClick={handleBack} small={MD} type={LINK}>
          {getText('data.fields.bulkAdd.back')}
        </Button>
        <Button
          onClick={handleSubmit}
          size={MD}
          type={OUTLINE}
          disabled={options.length === 0}
        >
          {getText(
            { context: filteredOptions.length },
            'data.fields.bulkAdd.add',
          )}
        </Button>
      </div>
    </>
  );
};

export default BulkAddOptions;
