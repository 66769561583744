import createReactComponent from '../createReactComponent';
export default createReactComponent('mail-off', 'IconMailOff', [
  [
    'path',
    {
      d: 'M9 5h10a2 2 0 0 1 2 2v10m-2 2h-14a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 7l9 6l.565 -.377m2.435 -1.623l6 -4', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
