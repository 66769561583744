import createReactComponent from '../createReactComponent';
export default createReactComponent('artboard-off', 'IconArtboardOff', [
  ['path', { d: 'M12 8h3a1 1 0 0 1 1 1v3', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15.716 15.698a1 1 0 0 1 -.716 .302h-6a1 1 0 0 1 -1 -1v-6c0 -.273 .11 -.52 .287 -.7',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 8h1', key: 'svg-2' }],
  ['path', { d: 'M3 16h1', key: 'svg-3' }],
  ['path', { d: 'M8 3v1', key: 'svg-4' }],
  ['path', { d: 'M16 3v1', key: 'svg-5' }],
  ['path', { d: 'M20 8h1', key: 'svg-6' }],
  ['path', { d: 'M20 16h1', key: 'svg-7' }],
  ['path', { d: 'M8 20v1', key: 'svg-8' }],
  ['path', { d: 'M16 20v1', key: 'svg-9' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-10' }],
]);
