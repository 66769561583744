import React, { useCallback, useMemo, useState } from 'react';
import { IconFirstAidKit } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { Button, Loader } from '@noloco/components/src';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { getText } from '@noloco/core/src/utils/lang';
import { useUpdateElements } from '../utils/hooks/projectHooks';
import { validateProjectElements } from '../utils/validateProjects';

const BuilderValidateProjectButton = () => {
  const project = useSelector(projectDataSelector);
  const [isWaiting, setIsWaiting] = useState(false);
  const [updateProjectElements, { isLoading }] = useUpdateElements(project);

  const { changes, updatedElements } = useMemo(
    () => validateProjectElements(project),
    [project],
  );

  const onConfirm = useCallback(() => {
    if (changes.length > 0 && updatedElements.length > 0) {
      setIsWaiting(true);
      updateProjectElements([], updatedElements);

      setTimeout(() => {
        document.location = document.location.pathname;
      }, 3000);
    }
  }, [changes.length, updateProjectElements, updatedElements]);

  console.log('[Invalid Project Configuration]', { changes, updatedElements });

  if (changes.length === 0 && !isWaiting && !isLoading) {
    return null;
  }

  return (
    <div className="mt-4 flex flex-col space-y-3">
      <p className="max-w-lg text-sm text-gray-600">
        {getText({ count: changes.length }, 'validateProject.explainer')}
      </p>
      <Button onClick={onConfirm} className="mr-auto mt-4">
        {!isLoading && !isWaiting && (
          <div className="flex items-center space-x-2">
            <IconFirstAidKit size={16} />
            <span>{getText('validateProject.apply')}</span>
          </div>
        )}
        {(isLoading || isWaiting) && <Loader className="mx-auto" size={SM} />}
      </Button>
    </div>
  );
};

export default BuilderValidateProjectButton;
