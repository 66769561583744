import React, { useCallback, useState } from 'react';
import {
  IconInfoCircle,
  IconThumbDown,
  IconThumbUp,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader, Tooltip } from '@noloco/components';
import { XS } from '@noloco/components/src/constants/tShirtSizes';
import MarkdownText from '@noloco/core/src/components/MarkdownText';
import {
  DOWN_VOTE,
  HintFeedback,
  UP_VOTE,
} from '@noloco/core/src/constants/hints';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'editor.hints';

type Props = {
  className?: string;
  hint: string | null | undefined;
  vote: (feedback: HintFeedback) => Promise<void>;
};

const Hint = ({ className, hint, vote }: Props) => {
  const [loading, setLoading] = useState(false);
  const [voted, setVoted] = useState<HintFeedback | null>(null);

  const onClick = useCallback(
    (feedback: HintFeedback) => () => {
      setLoading(true);
      setVoted(feedback);

      return vote(feedback)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    },
    [setLoading, vote],
  );

  if (!hint) {
    return null;
  }

  return (
    <div className={classNames('flex w-full items-center', className)}>
      <Tooltip
        content={
          <div className="whitespace-pre-wrap">
            <MarkdownText>{hint}</MarkdownText>
          </div>
        }
        placement="right"
      >
        <p
          className={classNames(
            'flex cursor-pointer items-center text-blue-400 hover:text-blue-500',
          )}
        >
          <span className="mr-2 text-xs font-medium">
            {getText(LANG_KEY, 'tooltip')}
          </span>
          <IconInfoCircle size={16} />
        </p>
      </Tooltip>
      {!loading && (
        <div className="ml-auto flex space-x-1">
          <IconThumbUp
            className={classNames('cursor-pointer hover:opacity-100', {
              'text-blue-500 opacity-100': voted === UP_VOTE,
              'opacity-75': voted !== UP_VOTE,
            })}
            onClick={onClick(UP_VOTE)}
            size={16}
          />
          <IconThumbDown
            className={classNames('cursor-pointer hover:opacity-100', {
              'text-blue-500 opacity-100': voted === DOWN_VOTE,
              'opacity-75': voted !== DOWN_VOTE,
            })}
            onClick={onClick(DOWN_VOTE)}
            size={16}
          />
        </div>
      )}
      {loading && <Loader className="ml-auto" size={XS} />}
    </div>
  );
};

export default Hint;
