import React, { memo } from 'react';
import identity from 'lodash/identity';
import { ButtonType } from '@noloco/components/src/components/button/buttonTypes';
import { ONE_CLICK } from '../../../constants/actionButtons';
import { VIEW } from '../../../constants/elements';
import { DataType } from '../../../models/DataTypes';
import {
  ActionButton as ActionButtonType,
  ElementPath,
} from '../../../models/Element';
import { Project } from '../../../models/Project';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { RECORD_SCOPE } from '../../../utils/scope';
import ActionButton from './ActionButton';

type CollectionActionButtonsType = {
  actionButtons: ActionButtonType[];
  buttonType?: ButtonType;
  dataType: DataType;
  editorMode: boolean;
  elementPath: ElementPath;
  project: Project;
  scope: Record<string, any>;
};

const CollectionActionButtons = memo(
  ({
    actionButtons,
    buttonType,
    dataType,
    editorMode,
    elementPath,
    project,
    scope,
  }: CollectionActionButtonsType) => {
    const { actionButtons: parsedActionButtons } = useSectionScopeVariables(
      VIEW,
      { actionButtons },
      project,
      elementPath,
      scope,
      skipPropResolvingByValueIds([RECORD_SCOPE]),
    );

    return parsedActionButtons.map(
      (collectionActionButton: any, actionButtonIndex: any) => (
        <ActionButton
          actionButton={collectionActionButton}
          buttonType={buttonType}
          dataType={dataType}
          defaultExecution={ONE_CLICK}
          editorMode={editorMode}
          index={actionButtonIndex}
          key={collectionActionButton.id}
          onDeleteRecord={identity}
          record={undefined}
          project={project}
          recordScope={scope}
        />
      ),
    );
  },
);

export default CollectionActionButtons;
