import createReactComponent from '../createReactComponent';
export default createReactComponent('device-ipad-bolt', 'IconDeviceIpadBolt', [
  [
    'path',
    {
      d: 'M13.5 21h-7.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 18h4', key: 'svg-1' }],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-2' }],
]);
