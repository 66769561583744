import createReactComponent from '../createReactComponent';
export default createReactComponent('bell-exclamation', 'IconBellExclamation', [
  [
    'path',
    {
      d: 'M15 17h-11a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6a2 2 0 1 1 4 0a7 7 0 0 1 4 6v1.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 17v1a3 3 0 0 0 6 0v-1', key: 'svg-1' }],
  ['path', { d: 'M19 16v3', key: 'svg-2' }],
  ['path', { d: 'M19 22v.01', key: 'svg-3' }],
]);
