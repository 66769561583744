import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-autofit-down',
  'IconArrowAutofitDown',
  [
    [
      'path',
      { d: 'M12 20h-6a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8', key: 'svg-0' },
    ],
    ['path', { d: 'M18 4v17', key: 'svg-1' }],
    ['path', { d: 'M15 18l3 3l3 -3', key: 'svg-2' }],
  ],
);
