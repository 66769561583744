import createReactComponent from '../createReactComponent';
export default createReactComponent('clock-edit', 'IconClockEdit', [
  [
    'path',
    {
      d: 'M21 12a9 9 0 1 0 -9.972 8.948c.32 .034 .644 .052 .972 .052',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 7v5l2 2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z',
      key: 'svg-2',
    },
  ],
]);
