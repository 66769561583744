import createReactComponent from '../createReactComponent';
export default createReactComponent('capture-off', 'IconCaptureOff', [
  ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-0' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-1' }],
  ['path', { d: 'M16 20h2c.554 0 1.055 -.225 1.417 -.589', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M9.87 9.887a3 3 0 0 0 4.255 4.23m.58 -3.416a3.012 3.012 0 0 0 -1.4 -1.403',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M4 8v-2c0 -.548 .22 -1.044 .577 -1.405', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
