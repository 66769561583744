import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-git', 'IconBrandGit', [
  ['path', { d: 'M16 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M12 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-2' }],
  ['path', { d: 'M12 15v-6', key: 'svg-3' }],
  ['path', { d: 'M15 11l-2 -2', key: 'svg-4' }],
  ['path', { d: 'M11 7l-1.9 -1.9', key: 'svg-5' }],
  [
    'path',
    {
      d: 'M13.446 2.6l7.955 7.954a2.045 2.045 0 0 1 0 2.892l-7.955 7.955a2.045 2.045 0 0 1 -2.892 0l-7.955 -7.955a2.045 2.045 0 0 1 0 -2.892l7.955 -7.955a2.045 2.045 0 0 1 2.892 0z',
      key: 'svg-6',
    },
  ],
]);
