import React, { forwardRef, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { SelectInput, SwitchButton, TextInput } from '@noloco/components';
import Guide from '@noloco/ui/src/components/Guide';
import { DataTypeValue } from '@noloco/ui/src/components/canvas/DataTypeInput';
import StringPropEditor from '@noloco/ui/src/components/canvas/StringPropEditor';
import VisibilityRulesEditor from '@noloco/ui/src/components/editor/VisibilityRulesEditor';
import ChartPaletteEditor from '@noloco/ui/src/components/editor/customerEditors/ChartPaletteEditor';
import DraggableListItem from '@noloco/ui/src/components/editor/customerEditors/DraggableListItem';
import {
  UPDATE_DEBOUNCE_MS,
  UpdatePropertyCallback,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import useHasFeatureFlag, {
  STACKED_BAR_CHART,
} from '../../../src/utils/hooks/useHasFeatureFlag';
import {
  OPTIONS,
  VIEW_CHARTS_SETTINGS,
  VISIBILITY,
} from '../../constants/buildMode';
import chartAggregations from '../../constants/chartAggregations';
import {
  BAR,
  ChartType,
  FUNNEL,
  GAUGE,
  LINE,
  PIE,
  RADAR,
  STACKED_BAR,
  STATISTIC,
} from '../../constants/chartTypes';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  INTEGER,
  SINGLE_OPTION,
  TEXT,
} from '../../constants/dataTypes';
import { CHART_ITEM } from '../../constants/draggableItemTypes';
import { VIEW } from '../../constants/elements';
import { ASC } from '../../constants/orderByDirections';
import { DATABASE } from '../../constants/scopeTypes';
import timePeriods from '../../constants/timePeriods';
import Icon from '../../elements/Icon';
import { ID } from '../../elements/sections/collections/CollectionEvents';
import { DataType } from '../../models/DataTypes';
import { Element, ElementPath } from '../../models/Element';
import { Project } from '../../models/Project';
import StateItem from '../../models/StateItem';
import { GroupBy } from '../../models/View';
import { getFieldFromAxisValuePath, isMetricChart } from '../../utils/charts';
import useEditorTabs from '../../utils/hooks/useEditorTabs';
import { getText } from '../../utils/lang';
import { getTypeOptionsOfTypeFromParent } from '../../utils/renderedOptions';
import { WithDraggable } from '../withDnD';
import BuildModeChartSeriesEditor from './BuildModeChartSeriesEditor';
import { CHART_ICONS } from './BuildModeChartsEditor';
import BuildModeEditorTabs from './BuildModeEditorTabs';
import { BuildModeGroupByInput } from './BuildModeGroupByInput';
import BuildModeInput from './BuildModeInput';
import BuildModeSection from './BuildModeSection';
import BuildModeSwitchSection from './BuildModeSwitchSection';

type BuildModeChartItemEditorProps = {
  activeListItem: number | null;
  chart: any;
  dataType: DataType;
  element: Element;
  elementPath: ElementPath;
  index: number;
  isOver: boolean;
  listOptions: {
    icon?: JSX.Element;
    label?: string;
    value: string | number;
  }[];
  onClone: (id: ID, newItemId: ID, tab?: any) => void;
  onRemove: (id: ID) => any;
  onUpdate: UpdatePropertyCallback;
  popoutOpen: boolean;
  project: Project;
  setActiveListItem: (activeListItem: number | null) => void;
  setPopoutOpen: (popoutOpen: boolean) => void;
};

const LANG_KEY = 'elements.VIEW.charts';

const RADIAL_TYPES = [PIE, FUNNEL, RADAR];

const getAcceptableDataTypesForXAxis = (chartType: any, dataTypes: any) => {
  if (chartType === FUNNEL) {
    return [SINGLE_OPTION];
  }

  if (chartType === STATISTIC) {
    return [INTEGER, DECIMAL, DATE];
  }

  if (chartType === GAUGE) {
    return [INTEGER, DECIMAL];
  }

  const baseTypes = [
    TEXT,
    SINGLE_OPTION,
    BOOLEAN,
    ...dataTypes.map((type: any) => type.name),
  ];

  if (chartType !== PIE && chartType !== RADAR) {
    baseTypes.push(DATE);
    baseTypes.push(INTEGER);
    baseTypes.push(DECIMAL);
  }

  return baseTypes;
};

const CUSTOM_OPTION_COLOR_CHART_TYPES = [BAR, FUNNEL, PIE];

const BuildModeChartItemEditor = forwardRef(
  (
    {
      activeListItem,
      chart,
      dataType,
      element,
      elementPath,
      index,
      isOver,
      listOptions,
      onClone,
      onRemove,
      onUpdate,
      popoutOpen,
      project,
      setActiveListItem,
      setPopoutOpen,
    }: BuildModeChartItemEditorProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [editorTab, setEditorTab] = useEditorTabs('record.charts', OPTIONS);
    const stackedBarChartEnabled = useHasFeatureFlag(STACKED_BAR_CHART);

    const {
      chartType,
      groupBy,
      groupBySort = ASC,
      groups,
      layout,
      hideEmptyGroups,
      groupOptions,
    } = chart || {};

    const groupByGroups: GroupBy[] = useMemo(() => {
      if (groups && groups.length > 0) {
        return groups;
      }

      return [{ id: 'new' }];
    }, [groups]);

    const stateItem = useMemo(
      () =>
        dataType &&
        new StateItem({
          id: `${element.id}${element.type === VIEW ? ':VIEW' : ''}`,
          path: element.type === VIEW ? '' : 'edges.node',
          source: DATABASE,
          dataType: dataType.name,
          display: dataType.display,
        }),
      [dataType, element.id, element.type],
    );

    const chartTypeOptions = useMemo(() => {
      const chartIconsArray = stackedBarChartEnabled
        ? Object.entries(CHART_ICONS)
        : Object.entries(CHART_ICONS).filter(
            ([chartOption]) => chartOption !== STACKED_BAR,
          );

      return chartIconsArray.map(([chartOption, chartIcon]) => ({
        icon: <Icon className="h-4 w-4" icon={chartIcon} />,
        label: getText('elements.CHART.chartType', chartOption),
        value: chartOption,
      }));
    }, [stackedBarChartEnabled]);

    const isMetric = useMemo(() => isMetricChart(chartType), [chartType]);

    const timePeriodOptions = useMemo(
      () =>
        timePeriods.map((timePeriodOption) => ({
          value: timePeriodOption,
          label: getText('elements.CHART.timePeriod', timePeriodOption),
        })),
      [],
    );

    const aggregationOptions = useMemo(
      () =>
        chartAggregations.map((aggregationOption) => ({
          value: aggregationOption,
          label: getText('elements.CHART.aggregation', aggregationOption),
        })),
      [],
    );

    const xAxisOptions = useMemo(() => {
      const acceptableDataTypes = getAcceptableDataTypesForXAxis(
        chartType,
        project.dataTypes,
      );

      return getTypeOptionsOfTypeFromParent(
        project.dataTypes,
        stateItem,
        acceptableDataTypes,
      );
    }, [chartType, project, stateItem]);

    const xAxisField = useMemo(
      () =>
        chart.xAxisValue &&
        getFieldFromAxisValuePath(chart.xAxisValue.path, dataType),
      [chart.xAxisValue, dataType],
    );

    const onUpdateGroupByProperty = useCallback(
      (path, value) => onUpdate([index, 'groups', ...path], value),
      [index, onUpdate],
    );

    return (
      <DraggableListItem
        canDelete={true}
        draggable={true}
        icon={CHART_ICONS[(chartType as ChartType) || LINE]}
        id={chart.id}
        index={index}
        isOver={isOver}
        onClone={onClone}
        onRemove={onRemove}
        ref={ref}
        title={
          chart.title || getText({ n: index + 1 }, LANG_KEY, 'placeholder')
        }
        activeListItem={activeListItem}
        setActiveListItem={setActiveListItem}
        popoutOpen={popoutOpen}
        setPopoutOpen={setPopoutOpen}
        listOptions={listOptions}
      >
        <>
          <BuildModeEditorTabs
            editorTab={editorTab}
            elementType={CHART_ITEM}
            setEditorTab={setEditorTab}
          />
          <hr className="w-full border-slate-700" />
          {editorTab === OPTIONS && (
            <>
              <div className="flex flex-col space-y-4 p-2">
                <Guide
                  className="my-2 text-sm"
                  href="https://guides.noloco.io/charts/overview"
                  showTooltip={true}
                  video="https://www.youtube.com/embed/4Y82sFpzfMo?si=Q3-m5u8Hi0cLxLKA"
                >
                  {getText(LANG_KEY, 'guide')}
                </Guide>
                <BuildModeInput label={getText(LANG_KEY, 'chartType')}>
                  <SelectInput
                    options={chartTypeOptions}
                    onChange={(nextChartType: any) =>
                      onUpdate([index, 'chartType'], nextChartType)
                    }
                    value={chartType}
                  />
                </BuildModeInput>
                <BuildModeInput
                  label={getText(
                    LANG_KEY,
                    !isMetric ? 'xAxisValue.title' : 'metric.label',
                  )}
                >
                  <SelectInput
                    Button={DataTypeValue}
                    className="text-black"
                    value={chart.xAxisValue}
                    options={xAxisOptions}
                    onChange={(value: any) =>
                      onUpdate([index, 'xAxisValue'], value)
                    }
                    placeholder={getText(
                      LANG_KEY,
                      !isMetric ? 'xAxisValue' : 'metric',
                      'placeholder',
                    )}
                    shiftRight={true}
                    searchable={true}
                  />
                </BuildModeInput>
                {!isMetric && get(chart, 'xAxisValue.dataType') === DATE && (
                  <BuildModeInput label={getText(LANG_KEY, 'timePeriod.label')}>
                    <SelectInput
                      options={timePeriodOptions}
                      onChange={(nextTimePeriod: any) =>
                        onUpdate([index, 'timePeriod'], nextTimePeriod)
                      }
                      placeholder={getText(LANG_KEY, 'timePeriod.label')}
                      value={chart.timePeriod}
                    />
                  </BuildModeInput>
                )}
                <BuildModeInput label={getText(LANG_KEY, 'aggregation.label')}>
                  <SelectInput
                    options={aggregationOptions}
                    onChange={(nextAggregation: any) =>
                      onUpdate([index, 'aggregation'], nextAggregation)
                    }
                    placeholder={getText(LANG_KEY, 'aggregation.label')}
                    value={chart.aggregation}
                  />
                </BuildModeInput>
                {chartType === GAUGE && (
                  <BuildModeInput label={getText(LANG_KEY, 'max.title')}>
                    <StringPropEditor
                      // @ts-expect-error TS(2322): Type '{ className: string; project: any; multiLine... Remove this comment to see the full error message
                      className="mt-1"
                      project={project}
                      multiLine={true}
                      onChange={(maxValue: any) =>
                        onUpdate([index, 'max'], maxValue)
                      }
                      placeholder="100"
                      value={chart.max}
                      elementPath={elementPath}
                    />
                  </BuildModeInput>
                )}
                {chartType === STACKED_BAR && (
                  <BuildModeGroupByInput
                    canMultiGroup={false}
                    dataType={dataType}
                    dataTypes={project.dataTypes}
                    groupBy={groupBy}
                    groupByGroups={groupByGroups}
                    groupBySort={groupBySort}
                    groupOptions={groupOptions}
                    groups={groups ?? []}
                    hasAdvancedGrouping={false}
                    hideEmptyGroups={hideEmptyGroups}
                    layout={layout}
                    stateItem={stateItem}
                    updateProperty={onUpdateGroupByProperty}
                  />
                )}
                {chartType !== STATISTIC &&
                  (!chart.useOptionColors ||
                    !CUSTOM_OPTION_COLOR_CHART_TYPES.includes(chartType) ||
                    xAxisField?.type !== SINGLE_OPTION) && (
                    <BuildModeInput label={getText(LANG_KEY, 'palette')}>
                      <ChartPaletteEditor
                        onChange={(value) =>
                          onUpdate([index, 'palette'], value)
                        }
                        project={project}
                        value={chart.palette}
                      />
                    </BuildModeInput>
                  )}
                {xAxisField &&
                  xAxisField.type === SINGLE_OPTION &&
                  CUSTOM_OPTION_COLOR_CHART_TYPES.includes(chartType) && (
                    <BuildModeSwitchSection
                      label={getText('elements.STAGES.input.useColors')}
                      onChange={(enabled: boolean) =>
                        onUpdate([index, 'useOptionColors'], enabled)
                      }
                      value={chart.useOptionColors}
                    />
                  )}
              </div>
              {!isMetric && (
                <BuildModeChartSeriesEditor
                  chart={chart}
                  dataType={dataType}
                  element={element}
                  elementPath={elementPath}
                  index={index}
                  onUpdate={onUpdate}
                  project={project}
                  stateItem={stateItem}
                />
              )}
              <BuildModeSection
                id={VIEW_CHARTS_SETTINGS}
                className="border-t"
                title={getText(LANG_KEY, 'config')}
              >
                <div className="flex flex-col space-y-4 p-2">
                  <BuildModeInput label={getText(LANG_KEY, 'title')}>
                    <TextInput
                      debounceMs={UPDATE_DEBOUNCE_MS}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onUpdate([index, 'title'], event.target.value)
                      }
                      placeholder=""
                      value={chart.title}
                    />
                  </BuildModeInput>
                  <BuildModeInput
                    label={getText(LANG_KEY, 'helpText')}
                    markdown={true}
                  >
                    <StringPropEditor
                      // @ts-expect-error TS(2322): Type '{ className: string; project: any; multiLine... Remove this comment to see the full error message
                      className="mt-1"
                      project={project}
                      multiLine={true}
                      onChange={(helpText: any) =>
                        onUpdate([index, 'helpText'], helpText)
                      }
                      value={chart.helpText}
                      elementPath={elementPath}
                    />
                  </BuildModeInput>
                  {!RADIAL_TYPES.includes(chartType) &&
                    chartType !== STATISTIC && (
                      <BuildModeInput label={getText(LANG_KEY, 'xAxisLabel')}>
                        <StringPropEditor
                          // @ts-expect-error TS(2322): Type '{ className: string; project: any; multiLine... Remove this comment to see the full error message
                          project={project}
                          onChange={(xAxisLabel: any) =>
                            onUpdate([index, 'xAxisLabel'], xAxisLabel)
                          }
                          value={chart.xAxisLabel}
                          elementPath={elementPath}
                        />
                      </BuildModeInput>
                    )}
                  {!RADIAL_TYPES.includes(chartType) && !isMetric && (
                    <BuildModeInput label={getText(LANG_KEY, 'yAxisLabel')}>
                      <StringPropEditor
                        // @ts-expect-error TS(2322): Type '{ className: string; project: any; multiLine... Remove this comment to see the full error message
                        project={project}
                        onChange={(yAxisLabel: any) =>
                          onUpdate([index, 'yAxisLabel'], yAxisLabel)
                        }
                        value={chart.yAxisLabel}
                        elementPath={elementPath}
                      />
                    </BuildModeInput>
                  )}
                  <BuildModeInput label={getText(LANG_KEY, 'columnSpan.label')}>
                    <SwitchButton
                      className="w-full rounded-lg"
                      inverseColors={true}
                      onChange={(value) =>
                        onUpdate([index, 'columnSpan'], value)
                      }
                      options={[3, 4, 6, 9, 12].map((colSpanValue) => ({
                        label: getText(LANG_KEY, 'columnSpan', colSpanValue),
                        value: colSpanValue,
                      }))}
                      value={chart.columnSpan || 6}
                    />
                  </BuildModeInput>
                </div>
              </BuildModeSection>
            </>
          )}
          {editorTab === VISIBILITY && (
            <VisibilityRulesEditor
              dataType={dataType}
              element={chart}
              elementPath={[...elementPath, 'props', 'charts', index]}
              onChange={(path: ElementPath, value: any) =>
                onUpdate([index, 'visibilityRules', ...path], value)
              }
              project={project}
            />
          )}
        </>
      </DraggableListItem>
    );
  },
);

BuildModeChartItemEditor.displayName = 'BuildModeChartItemEditor';

export default WithDraggable(BuildModeChartItemEditor, CHART_ITEM);
