import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { FIELD_LEVEL_PERMISSIONS } from '../../constants/features';
import { DataField } from '../../models/DataTypeFields';
import { DataType } from '../../models/DataTypes';
import { fieldPermissions } from '../permissions';
import useScopeUser from './useScopeUser';

export const useHasPermissionToUpdateRelatedRecordCollection = (
  dataType: DataType,
  dataTypeField: DataField | null | undefined,
) => {
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const user = useScopeUser();

  if (!fieldPermissionsEnabled || !dataTypeField) {
    return true;
  }

  const { update } = fieldPermissions(
    dataTypeField,
    dataType.permissionsEnabled,
    dataType.permissions,
    user,
  );

  return update;
};
