import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import RightPanel from '../../../components/RightPanel';
import { DataField } from '../../../models/DataTypeFields';
import { Project } from '../../../models/Project';
import { RecordValue } from '../../../models/Record';
import { FilterField } from '../../../models/View';
import { getText } from '../../../utils/lang';
import CollectionFilterWrapper from './CollectionFilterWrapper';

type CollectionFilterMobileSidebarProps = {
  filters: { field: DataField; config: FilterField }[];
  filterValues: Record<string, RecordValue>;
  onClose: () => void;
  project: Project;
  updateFilterValue: (
    field: DataField,
    newValue: RecordValue,
    config: FilterField,
  ) => void;
};

const LANG_KEY = 'elements.VIEW.filters';

const CollectionFilterMobileSidebar = ({
  filters,
  filterValues,
  onClose,
  project,
  updateFilterValue,
}: CollectionFilterMobileSidebarProps) => (
  <RightPanel
    className="hidden sm:flex"
    onClose={onClose}
    title={getText(LANG_KEY, 'sidebarTitle')}
    usePortal={true}
  >
    <div className="mb-2 mt-4 flex h-full flex-grow flex-col space-y-4">
      {filters.map(({ config, field }: any) => (
        <CollectionFilterWrapper
          className={classNames(
            'collection-filter',
            `collection-filter-${field.id}`,
          )}
          config={config}
          key={get(field, 'name')}
          field={field}
          project={project}
          updateFilterValue={updateFilterValue}
          value={filterValues[field.apiName]}
        />
      ))}
    </div>
  </RightPanel>
);

export default CollectionFilterMobileSidebar;
