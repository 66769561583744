import curry from 'lodash/curry';
import { GetDataItems } from '../models/ActionConfig';
import DataTypes from '../models/DataTypes';
import { Action } from '../models/Element';
import StateItem from '../models/StateItem';
import LinkPropType from '../models/elementPropTypes/comboProps/LinkPropType';
import { getDataTypeStateOptions } from '../utils/actions';
import {
  getDataItemsForPropsShape,
  isPrimitiveType,
  safelyAppendPath,
} from '../utils/data';
import {
  CREATE,
  DELETE,
  IFRAME,
  NAVIGATE,
  ON_DEMAND,
  OPEN_STRIPE_PORTAL,
  UPDATE,
  VIEW,
} from './actionTypes';
import { INTEGER } from './dataTypes';

const getDataTypeMutationScope = (action: Action, dataTypes: DataTypes) => {
  const { dataType: dataTypeName } = action;
  const dataType = dataTypeName && dataTypes.getByName(dataTypeName);
  if (dataType) {
    return getDataTypeStateOptions(action.id, dataType);
  }
  return [];
};

const getDataTypeActionDataItems = curry(
  (dataFieldName: keyof Action, action: Action) => {
    const fields = action[dataFieldName] || {};
    return Object.values(fields)
      .map((field) => {
        if (isPrimitiveType((field as any).dataType)) {
          return field;
        }
        return new StateItem({
          ...field,
          path: safelyAppendPath((field as any).path, 'id'),
          dataType: INTEGER,
        });
      })
      .filter(Boolean);
  },
);

const getFieldDataTypeActionDataItems = getDataTypeActionDataItems('fields');

const getDataTypeMutationDataItems = (action: Action) =>
  getFieldDataTypeActionDataItems(action);

const fallbackLoader = (() => []) as GetDataItems<Action>;
const actions: Record<string, GetDataItems<Action>> = {
  [VIEW]: () => [],
  [CREATE]: getDataTypeMutationDataItems as GetDataItems<Action>,
  [UPDATE]: getDataTypeMutationDataItems as GetDataItems<Action>,
  [DELETE]: getDataTypeMutationScope as GetDataItems<Action>,
  [ON_DEMAND]: fallbackLoader,
  [IFRAME]: fallbackLoader,
  [OPEN_STRIPE_PORTAL]: fallbackLoader,
  [NAVIGATE]: ((action: any) =>
    getDataItemsForPropsShape(
      {
        navigate: new LinkPropType(),
      },
      action,
      {},
      [],
    )) as GetDataItems<Action>,
};

export default actions;
