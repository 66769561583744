import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CURRENT_PLANS } from '@noloco/core/src/constants/accountPlanTypes';
import {
  ENTERPRISE,
  FREE,
  STARTER,
} from '@noloco/core/src/constants/accountPlans';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { isFeatureEnabledForPlan } from '@noloco/core/src/utils/features';
import { useFeatureUsage } from './useFeatureUsage';
import { usePlanFeatures } from './usePlanFeatures';

const useValidFeaturePlan = (feature: any) => {
  const teamPlan = useSelector(billingPlanSelector);
  const planConfig = usePlanFeatures();
  const currentUsage = useFeatureUsage(feature);

  const lowestPlan = useMemo(
    () =>
      planConfig
        ? [...CURRENT_PLANS, ENTERPRISE].find((planName) =>
            isFeatureEnabledForPlan(
              { ...teamPlan, type: planName },
              feature,
              currentUsage,
            ),
          )
        : null,
    [currentUsage, feature, planConfig, teamPlan],
  );

  return lowestPlan && lowestPlan !== STARTER && lowestPlan !== FREE
    ? lowestPlan
    : null;
};

export default useValidFeaturePlan;
