import createReactComponent from '../createReactComponent';
export default createReactComponent('nfc', 'IconNfc', [
  ['path', { d: 'M11 20a3 3 0 0 1 -3 -3v-11l5 5', key: 'svg-0' }],
  ['path', { d: 'M13 4a3 3 0 0 1 3 3v11l-5 -5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M4 4m0 3a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-10a3 3 0 0 1 -3 -3z',
      key: 'svg-2',
    },
  ],
]);
