import React from 'react';
import classNames from 'classnames';
import { BOARD, COLUMNS, TABLE } from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { COLLECTION_ITEM_STYLES } from '../Collection';
import DraggableRecord from './DraggableRecord';

const DraggableRow = ({
  canUpdateViaDrag,
  edge,
  group,
  isOver,
  isTable,
  layout,
  Row,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();

  return (
    <DraggableRecord
      className={classNames(
        {
          [`${COLLECTION_ITEM_STYLES[TABLE](isDarkModeEnabled as boolean)}`]:
            isTable,
          group: isTable,
          'w-full': layout !== COLUMNS,
          flex: layout === COLUMNS,
          'pb-6': layout === COLUMNS,
          'bg-pink-50': isOver && isTable,
          'pb-4': layout === BOARD,
        },
        'border-b',
        `${isDarkModeEnabled ? darkModeColors.borders.two : 'border-gray-100'}`,
      )}
      draggable={canUpdateViaDrag}
      groupKey={group.key}
      record={edge.node}
    >
      <Row edge={edge} isLast={false} index={edge.index} record={edge.node} />
    </DraggableRecord>
  );
};

export default DraggableRow;
