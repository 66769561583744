import createReactComponent from '../createReactComponent';
export default createReactComponent('sun-moon', 'IconSunMoon', [
  ['path', { d: 'M9.173 14.83a4 4 0 1 1 5.657 -5.657', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M11.294 12.707l.174 .247a7.5 7.5 0 0 0 8.845 2.492a9 9 0 0 1 -14.671 2.914',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 12h1', key: 'svg-2' }],
  ['path', { d: 'M12 3v1', key: 'svg-3' }],
  ['path', { d: 'M5.6 5.6l.7 .7', key: 'svg-4' }],
  ['path', { d: 'M3 21l18 -18', key: 'svg-5' }],
]);
