import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { ROWS } from '../../../constants/collectionLayouts';
import { HIGHLIGHTS } from '../../../constants/elements';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import { Project } from '../../../models/Project';
import { User } from '../../../models/User';
import { FormFieldConfig } from '../../../models/View';
import { lookupOfArray } from '../../../utils/arrays';
import { conditionsAreValid } from '../../../utils/conditions';
import { conditionsAreMet } from '../../../utils/data';
import { getRootFieldDataType } from '../../../utils/fields';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import useMergedScope from '../../../utils/hooks/useMergedScope';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { mapFieldsWithPermissionsAndConfig } from '../../../utils/permissions';
import Highlights from '../Highlights';
import FieldCell from '../collections/FieldCell';
import RecordEmptyState from './RecordEmptyState';

const RecordHighlights = ({
  backLink,
  columns,
  dataType,
  editorMode,
  fields,
  project,
  record,
  rootField,
  recordScope,
  sectionWidth,
  elementPath,
}: any) => {
  const { user } = useAuthWrapper();
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);

  const { fields: parsedFields } = useSectionScopeVariables(
    HIGHLIGHTS,
    { fields },
    project,
    elementPath,
    recordScope,
  );

  const parsedFieldLookup = useMemo(
    () => lookupOfArray<FormFieldConfig, string>(parsedFields, 'name'),
    [parsedFields],
  );

  const rootDataType = useMemo(
    () => getRootFieldDataType(rootField, dataType, project.dataTypes),
    [dataType, project.dataTypes, rootField],
  );

  const fieldConfigs = useMemo(
    () =>
      mapFieldsWithPermissionsAndConfig(
        fields,
        rootDataType,
        user as User,
        project.dataTypes,
        fieldPermissionsEnabled,
      ),
    [fieldPermissionsEnabled, fields, project.dataTypes, rootDataType, user],
  );

  const rootFieldObject = useMemo(
    () => rootField && dataType.fields.getByName(rootField),
    [dataType.fields, rootField],
  );

  const valuePrefix = useMemo(
    () => (rootFieldObject ? [rootFieldObject.apiName] : []),
    [rootFieldObject],
  );

  const scope = useMergedScope(recordScope);
  const transformRecordScope = useCallback(
    (currentScope: any) => ({
      ...currentScope,
      ...scope,
    }),
    [scope],
  );

  const getCellBackgroundColor = (
    config: any,
    scope: any,
    project: Project,
  ): string | null =>
    config.highlights?.find(
      (colorCondition: any) =>
        conditionsAreMet(colorCondition.conditions, scope, project) &&
        conditionsAreValid(colorCondition.conditions),
    )?.color ?? null;

  const highlights = useMemo(
    () =>
      fieldConfigs
        .filter(
          ({ config }) =>
            !(config as any).conditions ||
            conditionsAreMet((config as any).conditions, scope, project),
        )
        .map(({ field, permissions, config }) => ({
          id: (config as any).id || `field_${field.id}`,
          columnWidth: (config as any).columnWidth,
          className: classNames('md:col-span-1', {
            'col-span-3 lg:col-span-6':
              (config as any).columnWidth === 3 || !(config as any).columnWidth,
            'col-span-4 lg:col-span-6': (config as any).columnWidth === 4,
            'col-span-6': (config as any).columnWidth === 6,
            'col-span-8 lg:col-span-12': (config as any).columnWidth === 8,
            'col-span-9 lg:col-span-12': (config as any).columnWidth === 9,
            'col-span-12': (config as any).columnWidth === 12,
          }),
          label: (config as any).label.hidden
            ? null
            : (config as any).label.value,
          text: (
            <FieldCell
              backLink={backLink}
              columns={columns}
              dataType={rootDataType}
              showLabel={false}
              config={config}
              field={field}
              layout={ROWS}
              className="flex flex-col"
              permissions={permissions}
              record={record}
              value={get(record, [...valuePrefix, field.apiName])}
              project={project}
              key={field.name}
              section={true}
              transformScope={transformRecordScope}
            />
          ),
          helpText: parsedFieldLookup[config.name].helpText,
          bg: getCellBackgroundColor(config, scope, project),
        })),
    [
      fieldConfigs,
      scope,
      project,
      backLink,
      columns,
      rootDataType,
      record,
      valuePrefix,
      transformRecordScope,
      parsedFieldLookup,
    ],
  );

  if (highlights.length === 0 && !editorMode) {
    return null;
  }

  if (highlights.length === 0 && editorMode) {
    return <RecordEmptyState />;
  }

  return (
    <Highlights
      highlights={highlights}
      editorMode={false}
      sectionWidth={sectionWidth}
    />
  );
};

RecordHighlights.defaultProps = {
  fields: [],
};

export default RecordHighlights;
