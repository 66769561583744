import createReactComponent from '../createReactComponent';
export default createReactComponent('ruler-off', 'IconRulerOff', [
  [
    'path',
    {
      d: 'M8 4h11a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1h-4m-3.713 .299a1 1 0 0 0 -.287 .701v7a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1v-14c0 -.284 .118 -.54 .308 -.722',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 8h2', key: 'svg-1' }],
  ['path', { d: 'M4 12h3', key: 'svg-2' }],
  ['path', { d: 'M4 16h2', key: 'svg-3' }],
  ['path', { d: 'M12 4v3', key: 'svg-4' }],
  ['path', { d: 'M16 4v2', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
