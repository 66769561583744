import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'lock-square-rounded',
  'IconLockSquareRounded',
  [
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M8 11m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M10 11v-2a2 2 0 1 1 4 0v2', key: 'svg-2' }],
  ],
);
