import createReactComponent from '../createReactComponent';
export default createReactComponent('table-shortcut', 'IconTableShortcut', [
  [
    'path',
    {
      d: 'M3 13v-8a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 10h18', key: 'svg-1' }],
  ['path', { d: 'M10 3v11', key: 'svg-2' }],
  ['path', { d: 'M2 22l5 -5', key: 'svg-3' }],
  ['path', { d: 'M7 21.5v-4.5h-4.5', key: 'svg-4' }],
]);
