import { useCallback } from 'react';
import { TABLE } from '@noloco/core/src/constants/collectionLayouts';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import { SUGGEST_LAYOUT } from '../../queries/project';
import { generateViewFromDataSource } from '../layout';

export const useBuildDataTypeLayout = (
  addBuiltPage: (dataType: DataType) => void,
) => {
  const [suggestLayout] = usePromiseQuery(SUGGEST_LAYOUT);

  return useCallback(
    async (project: Project, dataType: DataType) => {
      let layout = TABLE;
      let layoutConfig = {};

      try {
        const { data } = await suggestLayout({
          variables: {
            projectName: project.name,
            dataTypeId: dataType.id,
          },
        });

        if (data?.suggestLayout?.layout) {
          layout = data?.suggestLayout?.layout;
          layoutConfig = data?.suggestLayout;
        }
      } catch (error) {
        console.error('Error suggesting layout for', dataType.id, error);
      }

      const newView = generateViewFromDataSource(
        dataType.display,
        dataType,
        project,
        {
          userGenerated: false,
          createdAt: new Date(),
        },
        {
          userGenerated: false,
          createdAt: new Date(),
        },
        layout,
        false,
        layoutConfig,
      );

      addBuiltPage(dataType);
      return newView;
    },
    [addBuiltPage, suggestLayout],
  );
};
