import createReactComponent from '../createReactComponent';
export default createReactComponent('cast', 'IconCast', [
  ['path', { d: 'M3 19l.01 0', key: 'svg-0' }],
  ['path', { d: 'M7 19a4 4 0 0 0 -4 -4', key: 'svg-1' }],
  ['path', { d: 'M11 19a8 8 0 0 0 -8 -8', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M15 19h3a3 3 0 0 0 3 -3v-8a3 3 0 0 0 -3 -3h-12a3 3 0 0 0 -2.8 2',
      key: 'svg-3',
    },
  ],
]);
