import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrows-left-right',
  'IconArrowsLeftRight',
  [
    ['path', { d: 'M21 17l-18 0', key: 'svg-0' }],
    ['path', { d: 'M6 10l-3 -3l3 -3', key: 'svg-1' }],
    ['path', { d: 'M3 7l18 0', key: 'svg-2' }],
    ['path', { d: 'M18 20l3 -3l-3 -3', key: 'svg-3' }],
  ],
);
