import createReactComponent from '../createReactComponent';
export default createReactComponent('tools-off', 'IconToolsOff', [
  [
    'path',
    {
      d: 'M16 12l4 -4a2.828 2.828 0 1 0 -4 -4l-4 4m-2 2l-7 7v4h4l7 -7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14.5 5.5l4 4', key: 'svg-1' }],
  ['path', { d: 'M12 8l-5 -5m-2 2l-2 2l5 5', key: 'svg-2' }],
  ['path', { d: 'M7 8l-1.5 1.5', key: 'svg-3' }],
  ['path', { d: 'M16 12l5 5m-2 2l-2 2l-5 -5', key: 'svg-4' }],
  ['path', { d: 'M16 17l-1.5 1.5', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
