import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { OrderByDirection } from '../constants/orderByDirections';
import { Element, ElementPath } from '../models/Element';
import { Project } from '../models/Project';
import useCollectionQuery from '../utils/hooks/useCollectionQuery';
import { getText } from '../utils/lang';

type InternalDataListWrapperProps = {
  additionalDeps?: any[];
  after?: any;
  autoRefresh: boolean;
  before?: any;
  countOnly?: boolean;
  children: (...args: any[]) => any;
  customFilters?: any[];
  dataType: string;
  element: Element;
  elementPath: ElementPath;
  filter?: any;
  limit?: number;
  orderBy?: {
    direction?: any; // TODO: PropTypes.oneOf(orderByDirections)
    field?: string;
  };
  project: Project;
  skipLambdas?: boolean;
};

const InternalDataListWrapper = ({
  additionalDeps,
  after,
  before,
  autoRefresh,
  children,
  countOnly,
  dataType,
  element,
  elementPath,
  filter,
  customFilters,
  project,
  limit,
  orderBy,
  skipLambdas,
}: InternalDataListWrapperProps) => {
  const {
    edges,
    listDataType,
    rawData,
    nodes,
    pageInfo,
    totalCount,
    nodeQueryObject,
    connection,
    loading,
    error,
    parentValuePath,
    valuePath,
    skip,
  } = useCollectionQuery(dataType, project, element, elementPath, {
    additionalDeps,
    after,
    before,
    autoRefresh,
    countOnly,
    customFilters,
    filter,
    limit,
    orderBy,
    skipLambdas,
  });

  if (!dataType || !listDataType) {
    return children({
      loading: false,
      limit,
      edges,
      totalCount,
      pageInfo: { hasNextPage: false },
      parentValuePath,
      nodeQueryObject,
      nodes,
    });
  }

  if (skip) {
    return (
      <div className="md:col-span-0 col-span-3 my-4 w-full rounded bg-red-500 p-4 text-white">
        {error ||
          getText(
            { dataType },
            'errors',
            listDataType ? 'list' : 'typeNotFound',
          )}
      </div>
    );
  }
  return children({
    edges,
    rawData,
    nodes,
    pageInfo,
    totalCount,
    nodeQueryObject,
    connection,
    loading,
    error,
    valuePath,
  });
};

type DataListWrapperProps = {
  additionalDeps?: any[];
  after?: any;
  autoRefresh: boolean;
  before?: any;
  countOnly?: boolean;
  children: (...args: any[]) => any;
  customFilters?: any[];
  dataType: string;
  elementId?: string;
  elementPath: ElementPath;
  endpoint?: any;
  filter?: any;
  limit?: number;
  orderBy?: {
    direction?: OrderByDirection;
    field?: string;
  };
  project: Project;
  scope: any;
  skipLambdas?: boolean;
};

const DataListWrapper = ({
  additionalDeps,
  after,
  before,
  autoRefresh,
  children,
  countOnly,
  dataType,
  elementPath,
  filter,
  customFilters,
  project,
  limit,
  orderBy,
  skipLambdas,
}: DataListWrapperProps) => {
  const element = get(project.elements, elementPath, {});

  return (
    <InternalDataListWrapper
      additionalDeps={additionalDeps}
      after={after}
      before={before}
      countOnly={countOnly}
      element={element}
      autoRefresh={autoRefresh}
      dataType={dataType}
      elementPath={elementPath}
      filter={filter}
      customFilters={customFilters}
      project={project}
      limit={limit}
      orderBy={orderBy}
      skipLambdas={skipLambdas}
    >
      {children}
    </InternalDataListWrapper>
  );
};

DataListWrapper.defaultProps = {
  additionalDeps: [],
  autoRefresh: false,
  countOnly: false,
  customFilters: [],
};

const mapStateToProps = ({ queries }: any) => ({
  queries,
});

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(DataListWrapper);
