import get from 'lodash/get';
import isFinite from 'lodash/isFinite';
import { usePlanFeatures } from './usePlanFeatures';
import { usePlanOverages } from './usePlanOverages';

export const useFeatureLimit = (feature: any) => {
  const planConfig = usePlanFeatures();
  const planOverages = usePlanOverages();

  const limit = get(planConfig, feature);
  const overage = get(planOverages, feature);

  return {
    limit: isFinite(limit) ? limit : undefined,
    overage,
  };
};
