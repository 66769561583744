import createReactComponent from '../createReactComponent';
export default createReactComponent('users-minus', 'IconUsersMinus', [
  ['path', { d: 'M5 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0', key: 'svg-0' }],
  [
    'path',
    { d: 'M3 21v-2a4 4 0 0 1 4 -4h4c.948 0 1.818 .33 2.504 .88', key: 'svg-1' },
  ],
  ['path', { d: 'M16 3.13a4 4 0 0 1 0 7.75', key: 'svg-2' }],
  ['path', { d: 'M16 19h6', key: 'svg-3' }],
]);
