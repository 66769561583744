import createReactComponent from '../createReactComponent';
export default createReactComponent('keyboard', 'IconKeyboard', [
  [
    'path',
    {
      d: 'M2 6m0 2a2 2 0 0 1 2 -2h16a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-16a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 10l0 .01', key: 'svg-1' }],
  ['path', { d: 'M10 10l0 .01', key: 'svg-2' }],
  ['path', { d: 'M14 10l0 .01', key: 'svg-3' }],
  ['path', { d: 'M18 10l0 .01', key: 'svg-4' }],
  ['path', { d: 'M6 14l0 .01', key: 'svg-5' }],
  ['path', { d: 'M18 14l0 .01', key: 'svg-6' }],
  ['path', { d: 'M10 14l4 .01', key: 'svg-7' }],
]);
