import createReactComponent from '../createReactComponent';
export default createReactComponent('smoking', 'IconSmoking', [
  [
    'path',
    {
      d: 'M3 13m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 13l0 4', key: 'svg-1' }],
  ['path', { d: 'M16 5v.5a2 2 0 0 0 2 2a2 2 0 0 1 2 2v.5', key: 'svg-2' }],
]);
