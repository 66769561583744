import createReactComponent from '../createReactComponent';
export default createReactComponent('microphone-2', 'IconMicrophone2', [
  ['path', { d: 'M15 12.9a5 5 0 1 0 -3.902 -3.9', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15 12.9l-3.902 -3.899l-7.513 8.584a2 2 0 1 0 2.827 2.83l8.588 -7.515z',
      key: 'svg-1',
    },
  ],
]);
