import createReactComponent from '../createReactComponent';
export default createReactComponent('wand', 'IconWand', [
  ['path', { d: 'M6 21l15 -15l-3 -3l-15 15l3 3', key: 'svg-0' }],
  ['path', { d: 'M15 6l3 3', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2',
      key: 'svg-3',
    },
  ],
]);
