import createReactComponent from '../createReactComponent';
export default createReactComponent('shield-lock', 'IconShieldLock', [
  [
    'path',
    {
      d: 'M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 11m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M12 12l0 2.5', key: 'svg-2' }],
]);
