import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import shortId from 'shortid';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  AREA,
  BAR,
  ChartType,
  FUNNEL,
  GAUGE,
  LINE,
  PIE,
  RADAR,
  STACKED_BAR,
  STATISTIC,
} from '../../constants/chartTypes';
import { CHART_ITEM } from '../../constants/draggableItemTypes';
import Icon from '../../elements/Icon';
import { DataType } from '../../models/DataTypes';
import { Element, ElementPath } from '../../models/Element';
import { IconValue } from '../../models/IconValue';
import { Project } from '../../models/Project';
import useItemListEditor from '../../utils/hooks/useItemListEditor';
import { getText } from '../../utils/lang';
import { WithDropable } from '../withDnD';
import BuildModeChartItemEditor from './BuildModeChartItemEditor';
import BuildModeIcon from './BuildModeIcon';

type BuildModeChartsEditorProps = {
  charts: any;
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  element: Element;
  elementPath: ElementPath;
  project: Project;
  updateProperty: UpdatePropertyCallback;
};

const LANG_KEY = 'elements.VIEW.charts';

export const CHART_ICONS: Record<ChartType, IconValue> = {
  [AREA]: { name: 'ChartArea' },
  [BAR]: { name: 'ChartBar' },
  [FUNNEL]: { name: 'Filter' },
  [GAUGE]: { name: 'Gauge' },
  [LINE]: { name: 'ChartLine' },
  [PIE]: { name: 'ChartDonut' },
  [RADAR]: { name: 'ChartRadar' },
  [STATISTIC]: { name: 'Numbers' },
  [STACKED_BAR]: { name: 'ChartInfographic' },
};

const BuildModeChartsEditor = forwardRef(
  (
    {
      charts = [],
      dataType,
      debouncedUpdateProperty,
      element,
      elementPath,
      project,
      updateProperty,
    }: BuildModeChartsEditorProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [activeListItem, setActiveListItem] = useState<number | null>(null);
    const [popoutOpen, setPopoutOpen] = useState(false);

    const updateCharts = useCallback(
      (path, value) => updateProperty(['charts', ...path], value),
      [updateProperty],
    );

    const debounceUpdateCharts = useCallback(
      (path, value) => debouncedUpdateProperty(['charts', ...path], value),
      [debouncedUpdateProperty],
    );

    const createNewChart = useCallback(() => {
      return {
        id: shortId.generate(),
        chartType: LINE,
        series: [
          {
            id: shortId.generate(),
          },
        ],
      };
    }, []);

    const {
      formatItem,
      draftItems: draftCharts,
      findItem,
      onSaveOrder,
      onRemoveItem: onRemoveChart,
      onCloneItem: onCloneChart,
      onAddNewItem: onAddNewChart,
      onDebounceUpdateItem: onDebounceUpdateChart,
    } = useItemListEditor(
      charts,
      updateCharts,
      debounceUpdateCharts,
      createNewChart,
    );

    const listOptions = useMemo(
      () =>
        draftCharts.map((draftChart, index) => ({
          icon: (
            <Icon
              className="h-4 w-4"
              icon={CHART_ICONS[(draftChart.chartType as ChartType) || LINE]}
            />
          ),
          label:
            draftChart.title ||
            getText({ n: index + 1 }, LANG_KEY, 'placeholder'),
          value: index,
        })),
      [draftCharts],
    );

    return (
      <div className="border-t border-slate-700">
        <div
          className="mx-2 mt-2 flex items-center space-x-2 rounded-lg p-1 text-slate-200 hover:bg-slate-700"
          onClick={onAddNewChart}
        >
          <BuildModeIcon Icon={IconPlus} />
          <span>{getText('rightSidebar.editor.addChart')}</span>
        </div>
        <div className="mb-2 flex flex-col space-y-2 p-2 text-sm" ref={ref}>
          {draftCharts
            .filter((chart) => chart)
            .map((chart, index) => (
              <BuildModeChartItemEditor
                activeListItem={activeListItem}
                chart={chart}
                dataType={dataType}
                draggable={true}
                element={element}
                elementPath={elementPath}
                findItem={findItem}
                index={index}
                item={formatItem(chart)}
                key={chart.id}
                listOptions={listOptions}
                onClone={onCloneChart}
                onRemove={onRemoveChart}
                onSaveOrder={onSaveOrder}
                onUpdate={onDebounceUpdateChart}
                popoutOpen={popoutOpen}
                project={project}
                setActiveListItem={setActiveListItem}
                setPopoutOpen={setPopoutOpen}
              />
            ))}
        </div>
      </div>
    );
  },
);

BuildModeChartsEditor.displayName = 'BuildModeChartsEditor';

export default WithDropable(BuildModeChartsEditor, CHART_ITEM);
