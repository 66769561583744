import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-dollar',
  'IconDeviceDesktopDollar',
  [
    [
      'path',
      {
        d: 'M13 16h-9a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v5.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h6.5', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    [
      'path',
      {
        d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
        key: 'svg-3',
      },
    ],
    ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-4' }],
  ],
);
