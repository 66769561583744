import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'ease-out-control-point',
  'IconEaseOutControlPoint',
  [
    ['path', { d: 'M3 21s10 -16 18 -16', key: 'svg-0' }],
    ['path', { d: 'M7 5a2 2 0 1 1 -4 0a2 2 0 0 1 4 0z', key: 'svg-1' }],
    ['path', { d: 'M7 5h2', key: 'svg-2' }],
    ['path', { d: 'M14 5h-2', key: 'svg-3' }],
  ],
);
