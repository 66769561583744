import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { IconBell, IconBellExclamation } from '@tabler/icons-react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Popover, Tooltip, getColorShade } from '@noloco/components';
import { Notification } from '../../models/Notification';
import { NOTIFICATIONS_QUERY } from '../../queries/notifications';
import { projectNameSelector } from '../../selectors/projectSelectors';
import { useRefetchNotifications } from '../../utils/hooks/useServerEvents';
import { getText } from '../../utils/lang';
import NotificationsList from './NotificationsList';

const LANG_KEY = 'notifications';

type NotificationsProps = {
  primaryColor: string;
};

const Notifications = ({ primaryColor }: NotificationsProps) => {
  const projectName = useSelector(projectNameSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [read, setRead] = useState(false);

  const { data, error, fetchMore, loading, refetch } = useQuery(
    NOTIFICATIONS_QUERY,
    {
      context: {
        projectName,
        projectQuery: true,
      },
      variables: {
        read,
      },
    },
  );
  useRefetchNotifications(refetch);

  const notifications = useMemo(() => {
    if (loading || error) {
      return [];
    }

    return data.notifications.notifications;
  }, [data, error, loading]);

  const pageInfo = useMemo(() => {
    if (loading || error) {
      return {};
    }

    return data.notifications.pageInfo;
  }, [data, error, loading]);

  const hasNextPage = useMemo(() => {
    if (loading || error) {
      return false;
    }

    return data.notifications.pageInfo.hasNextPage;
  }, [data, error, loading]);

  const hasUnread = useMemo(() => {
    if (loading || error) {
      return false;
    }

    return data.notifications.pageInfo.hasUnread;
  }, [data, error, loading]);

  const onChangeRead = useCallback(
    (read: boolean) => {
      setRead(read);
      refetch({ read });
    },
    [refetch, setRead],
  );
  const onLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        read,
        before: pageInfo.oldest,
      },
      updateQuery: (previousResults, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResults;
        }

        return {
          notifications: {
            ...fetchMoreResult.notifications,
            notifications: [
              ...previousResults.notifications.notifications,
              ...fetchMoreResult.notifications.notifications,
            ],
          },
          __typename: 'Notifications',
        };
      },
    });
  }, [fetchMore, pageInfo.oldest, read]);
  const onOpenChange = useCallback(
    (nextOpen: boolean) => {
      setIsOpen(nextOpen);
      refetch({ read });
    },
    [read, refetch],
  );

  if (error) {
    return (
      <Tooltip content={getText(LANG_KEY, 'error')}>
        <IconBellExclamation className="text-gray-200" size={20} />
      </Tooltip>
    );
  }

  return (
    <Popover
      closeOnOutsideClick={true}
      content={
        <NotificationsList
          hasNextPage={hasNextPage}
          loading={loading}
          notifications={notifications.filter(({ readAt }: Notification) =>
            read ? !!readAt : !readAt,
          )}
          read={read}
          onChangeRead={onChangeRead}
          onLoadMore={onLoadMore}
          onOpenChange={onOpenChange}
        />
      }
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      p={{ x: 0, y: 0 }}
      trigger={isOpen ? 'none' : 'click'}
    >
      <div className="relative cursor-pointer text-gray-200">
        {hasUnread && (
          <span
            className={classNames(
              'absolute -right-2 -top-2 h-2 w-2 animate-pulse rounded-md border text-xs shadow-2xl',
              `bg-${getColorShade(primaryColor, 400)}`,
            )}
          />
        )}
        <IconBell size={16} />
      </div>
    </Popover>
  );
};

export default Notifications;
