import React, { useCallback, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Button, Dropdown, Loader, getColorShade } from '@noloco/components';
import { NOT_IN } from '../../../constants/operators';
import { BaseRecord, RecordEdge } from '../../../models/Record';
import { getFieldKey } from '../../../utils/fields';
import { useHasPermissionToUpdateRelatedRecordCollection } from '../../../utils/hooks/useHasPermissionToUpdateRelatedRecordCollection';
import { useUpdateRelatedRecordCollection } from '../../../utils/hooks/useUpdateRelatedRecordCollection';
import { getText } from '../../../utils/lang';
import { RECORD_SCOPE } from '../../../utils/scope';
import RelationalDataFieldInput from '../collections/filters/RelationalDataFieldInput';

const AddRelatedRecordButton = ({
  config,
  dataList,
  dataType,
  newButtonVisible,
  project,
  refetch,
  rootDataType,
  scope,
  surface,
  theme,
}: any) => {
  const {
    dataTypeField,
    fieldApiArgName,
    isMultiRelationship,
    relationalDataField,
    update,
    updateLoading,
  } = useUpdateRelatedRecordCollection(
    dataList,
    project,
    rootDataType,
    dataType,
  );

  const canUpdateField = useHasPermissionToUpdateRelatedRecordCollection(
    dataType,
    dataTypeField,
  );

  const primaryColor = theme.brandColors.primary;

  const additionalFields = useMemo(
    () => ({
      __args: {
        ...(config.orderBy ? { orderBy: config.orderBy } : {}),
        where: [
          ...(config.customFilters ? config.customFilters : []),
          {
            field: getFieldKey(dataTypeField),
            operator: NOT_IN,
            result: [parseInt(get(scope, [RECORD_SCOPE, 'id'], 0))],
          },
        ],
      },
    }),
    [config.customFilters, config.orderBy, dataTypeField, scope],
  );

  const onAddRelatedRecord = useCallback(
    (nodeToAdd: any) => {
      const variables = {
        id: nodeToAdd.id,
      };

      if (isMultiRelationship) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        variables[fieldApiArgName] = [scope[RECORD_SCOPE].id];
      } else {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        variables[fieldApiArgName] = scope[RECORD_SCOPE].id;
      }

      return update({ variables }).then(refetch);
    },
    [fieldApiArgName, isMultiRelationship, refetch, scope, update],
  );

  const DropdownInput = useMemo(
    () => (props: any) => (
      <Dropdown {...props} Button={({ children }: any) => children} />
    ),
    [],
  );

  const existingLinkedRecordIds = useMemo(
    () =>
      get(scope, [RECORD_SCOPE, dataList.filter.path, 'edges'], []).map(
        ({ node }: RecordEdge) => node.id,
      ),
    [dataList.filter.path, scope],
  );

  if (!relationalDataField) {
    return null;
  }

  return (
    <RelationalDataFieldInput
      additionalFields={additionalFields}
      allowNewRecords={false}
      dataTypes={project.dataTypes}
      disabled={!canUpdateField}
      field={relationalDataField}
      filter={config.collectionFilter}
      inline={false}
      onChange={onAddRelatedRecord}
      project={project}
      projectName={project.name}
      resultFilter={({ id }: BaseRecord) =>
        !existingLinkedRecordIds.includes(id)
      }
      Input={DropdownInput}
      surface={surface}
    >
      <Button
        className={classNames(
          'flex h-8 items-center space-x-2 rounded-br-lg rounded-tr-lg border-l',
          `border-${getColorShade(primaryColor, 600)}`,
          {
            'rounded-br-lg rounded-tr-lg border-l': newButtonVisible,
            'rounded-lg': !newButtonVisible,
          },
        )}
        disabled={!canUpdateField || updateLoading}
        variant="primary"
        border={[false]}
        rounded={false}
      >
        <div className="py-px">
          {updateLoading ? (
            <Loader size="xs" />
          ) : newButtonVisible ? (
            <IconChevronDown size={16} />
          ) : (
            <div className="flex space-x-2">
              <span>
                {getText(
                  { dataType: dataType.display },
                  'core.COLLECTION.form.link',
                )}
              </span>
              <IconChevronDown size={16} />
            </div>
          )}
        </div>
      </Button>
    </RelationalDataFieldInput>
  );
};

export default withTheme(AddRelatedRecordButton);
