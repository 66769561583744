import createReactComponent from '../createReactComponent';
export default createReactComponent('rating-16-plus', 'IconRating16Plus', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M11.5 13.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 15v-6', key: 'svg-2' }],
  ['path', { d: 'M15.5 12h3', key: 'svg-3' }],
  ['path', { d: 'M17 10.5v3', key: 'svg-4' }],
  ['path', { d: 'M10 13.5v-3a1.5 1.5 0 0 1 1.5 -1.5h1', key: 'svg-5' }],
]);
