import React from 'react';
import { IconDragDrop } from '@tabler/icons-react';
import { ElementPath } from '../../models/Element';
import BuildModeSwitchSection from './BuildModeSwitchSection';

type EnableDragAndDropEditToggleProps = {
  label: string;
  enableDragAndDropEdit: boolean;
  updateProperty: (path: ElementPath, value: boolean) => void;
};

const EnableDragAndDropEditToggle = ({
  label,
  enableDragAndDropEdit,
  updateProperty,
}: EnableDragAndDropEditToggleProps) => (
  <BuildModeSwitchSection
    label={
      <>
        <IconDragDrop size={16} />
        <span>{label}</span>
      </>
    }
    onChange={(value: boolean) =>
      updateProperty(['enableDragAndDropEdit'], value)
    }
    value={enableDragAndDropEdit}
  />
);

export default EnableDragAndDropEditToggle;
