import createReactComponent from '../createReactComponent';
export default createReactComponent('eye-closed', 'IconEyeClosed', [
  [
    'path',
    {
      d: 'M21 9c-2.4 2.667 -5.4 4 -9 4c-3.6 0 -6.6 -1.333 -9 -4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 15l2.5 -3.8', key: 'svg-1' }],
  ['path', { d: 'M21 14.976l-2.492 -3.776', key: 'svg-2' }],
  ['path', { d: 'M9 17l.5 -4', key: 'svg-3' }],
  ['path', { d: 'M15 17l-.5 -4', key: 'svg-4' }],
]);
