import { useSelector } from 'react-redux';
import { ROLE } from '@noloco/core/src/constants/builtInDataTypes';
import { USER_ROLES } from '@noloco/core/src/constants/features';
import DataTypes from '@noloco/core/src/models/DataTypes';
import { Role } from '@noloco/core/src/models/User';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { isFeatureEnabled } from '@noloco/core/src/utils/features';
import useDataTypeQuery from '@noloco/core/src/utils/hooks/useDataTypeQuery';

export const useUserRoles = (
  projectName: string,
  dataTypes: DataTypes,
  additionalFields?: Partial<Role>,
) => {
  const teamPlan = useSelector(billingPlanSelector);

  const { loading, nodes, refetch } = useDataTypeQuery(
    ROLE,
    dataTypes,
    projectName,
    additionalFields,
  );

  const roles = nodes
    // Sort in descending order.
    .sort((roleA: any, roleB: any) => Number(roleB.id) - Number(roleA.id))
    // Enabled the first <limit> roles.
    // @ts-expect-error TS(7006): Parameter 'role' implicitly has an 'any' type.
    .map((role, index) => ({
      ...role,

      enabled: isFeatureEnabled(
        teamPlan,
        USER_ROLES,
        nodes.length - (index + 1),
      ),
    }));

  return {
    loading,
    refetch,
    roles,
  };
};
