import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { HorizontalNav, HorizontalNavItem } from '@noloco/components';
import { COMPANY, INVOICE } from '../../constants/builtInDataTypes';
import { getPreviewableFieldsQueryObject } from '../../queries/data';
import cappedMemoize from '../../utils/cappedMemoize';
import useIsBillingEnabled from '../../utils/hooks/useIsBillingEnabled';
import useRouter from '../../utils/hooks/useRouter';
import useScopeUser from '../../utils/hooks/useScopeUser';
import { getText } from '../../utils/lang';
import { replaceDoubleSlashes } from '../../utils/pages';
import InvoiceList from './billing/InvoiceList';
import InvoiceTable from './billing/InvoiceTable';
import SubscriptionList from './billing/SubscriptionList';
import SubscriptionTable from './billing/SubscriptionTable';

const THIRTY_DAYS_MS = 60 * 60 * 24 * 30 * 1000;

const getDemoInvoices = cappedMemoize(
  () =>
    [...Array(5)].map((__, index) => ({
      id: index,
      customer: {
        id: index,
        name: `Company ${index + 1}`,
      },
      description: `Invoice ${index + 1}`,
      currency: 'usd',
      total: Math.floor(Math.random() * 10000),
      createdAt: new Date().toISOString(),
      dueDate: new Date().toISOString(),
      status: ['draft', 'open', 'paid', 'uncollectable', 'void'][index],
    })),
  { maxKeys: 1 },
);

const getDemoSubscriptions = cappedMemoize(
  () =>
    [...Array(5)].map((__, index) => ({
      id: index,
      customer: {
        id: index,
        name: `Company ${index + 1}`,
      },
      name: `Plan ${index + 1}`,
      interval: ['month', 'year'][index % 2],
      currency: 'usd',
      quantity: 1,
      amount: Math.floor(Math.random() * 10000),
      createdAt: new Date().toISOString(),
      currentPeriodStart: new Date().toISOString(),
      currentPeriodEnd: new Date(
        new Date().getTime() + THIRTY_DAYS_MS,
      ).toISOString(),
      status: ['active', 'past_due', 'canceled', 'active', 'unpaid'][index],
    })),
  {
    maxKeys: 1,
  },
);

type Props = {};

const Billing = ({
  // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(2339): Property 'emptyState' does not exist on type 'Prop... Remove this comment to see the full error message
  emptyState,
  // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
  onClick,
  // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'Props'... Remove this comment to see the full error message
  subtitle,
  // @ts-expect-error TS(2339): Property 'subscriptionsTitle' does not exist on ty... Remove this comment to see the full error message
  subscriptionsTitle,
  // @ts-expect-error TS(2339): Property 'subscriptionsSubtitle' does not exist on... Remove this comment to see the full error message
  subscriptionsSubtitle,
  // @ts-expect-error TS(2339): Property 'showCustomerPortalButton' does not exist... Remove this comment to see the full error message
  showCustomerPortalButton,
  // @ts-expect-error TS(2339): Property 'title' does not exist on type 'Props'.
  title,
  // @ts-expect-error TS(2339): Property 'tabs' does not exist on type 'Props'.
  tabs,
  // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Props'.
  project,
}: Props) => {
  const { match, pathname } = useRouter();
  const { id, isInternal } = useScopeUser();

  const {
    isStripeConnected,
    isStripeCompleted,
    isEnabled: isCompleted,
  } = useIsBillingEnabled(project.integrations);

  const invoiceDataType = project.dataTypes.getByName(INVOICE);
  const customerField =
    invoiceDataType && invoiceDataType.fields.getByName('customer');

  const companyWithRelations = project.dataTypes.getByName(COMPANY);
  const companyFields = getPreviewableFieldsQueryObject(
    companyWithRelations.fields,
  );

  return (
    <div
      className={classNames(
        className,
        'mx-auto w-full max-w-screen-lg p-8 md:px-4',
      )}
    >
      {isInternal && !isCompleted && (
        <div className="p-4" onClick={onClick}>
          <div className="rounded-lg bg-red-100 p-4 text-red-900">
            You must connect your stripe account in 'Settings &gt; Payments'
            before using the billing component
          </div>
        </div>
      )}
      <Switch>
        <Route>
          <>
            {isStripeConnected && (
              <div className="mb-4">
                <HorizontalNav type="pills">
                  <HorizontalNavItem
                    active={!pathname.endsWith('subscriptions')}
                    is={Link}
                    to={`${pathname.replace('/subscriptions', '')}`}
                  >
                    {get(
                      tabs,
                      'invoices',
                      getText('core.BILLING.tabs.invoices'),
                    )}
                  </HorizontalNavItem>
                  <HorizontalNavItem
                    active={pathname.endsWith('subscriptions')}
                    is={Link}
                    to={replaceDoubleSlashes(
                      `${pathname.replace(
                        /\/subscriptions\/?/,
                        '',
                      )}/subscriptions`,
                    )}
                  >
                    {get(
                      tabs,
                      'subscriptions',
                      getText('core.BILLING.tabs.subscriptions'),
                    )}
                  </HorizontalNavItem>
                </HorizontalNav>
              </div>
            )}
            <Switch>
              <Route path={`${match.path}/subscriptions`}>
                <>
                  {isCompleted && (
                    <SubscriptionList
                      invoiceDataType={invoiceDataType}
                      onClick={onClick}
                      isCompleted={isCompleted}
                      isStripeConnected={isStripeCompleted}
                      isInternal={isInternal}
                      userId={id}
                      emptyState={emptyState}
                      customerFields={companyFields}
                      customerField={customerField}
                      billingDataType={companyWithRelations}
                      title={subscriptionsTitle || title}
                      subtitle={subscriptionsSubtitle || subtitle}
                      project={project}
                    />
                  )}
                  {!isCompleted && (
                    <SubscriptionTable
                      // @ts-expect-error TS(2322): Type '{ className: string; loading: boolean; onCli... Remove this comment to see the full error message
                      className="mt-8 opacity-75"
                      loading={false}
                      onClick={onClick}
                      isInternal={false}
                      subscriptions={isInternal ? getDemoSubscriptions() : []}
                      emptyState={emptyState}
                      customerField={customerField}
                      title={subscriptionsTitle || title}
                      subtitle={subscriptionsSubtitle || subtitle}
                      finalizeLoading={null}
                      finalizeInvoice={null}
                      project={project}
                    />
                  )}
                </>
              </Route>
              <Route>
                <>
                  {isCompleted && (
                    <InvoiceList
                      invoiceDataType={invoiceDataType}
                      onClick={onClick}
                      isCompleted={isCompleted}
                      isStripeConnected={isStripeCompleted}
                      isInternal={isInternal}
                      userId={id}
                      emptyState={emptyState}
                      customerFields={companyFields}
                      customerField={customerField}
                      billingDataType={companyWithRelations}
                      showCustomerPortalButton={showCustomerPortalButton}
                      title={title}
                      subtitle={subtitle}
                      project={project}
                    />
                  )}
                  {!isCompleted && (
                    <InvoiceTable
                      // @ts-expect-error TS(2322): Type '{ className: string; loading: boolean; onCli... Remove this comment to see the full error message
                      className="mt-8 opacity-75"
                      loading={false}
                      onClick={onClick}
                      onSelectInvoice={() => null}
                      isInternal={false}
                      invoices={isInternal ? getDemoInvoices() : []}
                      emptyState={emptyState}
                      customerField={customerField}
                      title={title}
                      subtitle={subtitle}
                      finalizeLoading={null}
                      finalizeInvoice={null}
                      project={project}
                    />
                  )}
                </>
              </Route>
            </Switch>
          </>
        </Route>
      </Switch>
    </div>
  );
};

export default Billing;
