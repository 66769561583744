import React, { useMemo } from 'react';
import { Notice } from '@noloco/components';
import { PRIMARY } from '@noloco/components/src/constants/variants';
import MarkdownText from '../../../components/MarkdownText';
import { END } from '../../../constants/align';
import { NOTICE } from '../../../constants/elements';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { RECORD_SCOPE } from '../../../utils/scope';
import { getIconFromValue } from '../../Icon';
import RecordActionButtonSection from './RecordActionButtonsSection';

const RecordNotice = ({
  actionButtons = [],
  backLink,
  dataType,
  elementPath,
  project,
  record,
  recordScope,
  rootPathname,
  ...rest
}: any) => {
  const { appearance, title, subtitle, icon } = useSectionScopeVariables(
    NOTICE,
    rest,
    project,
    elementPath,
    recordScope,
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  const iconValue = useMemo(() => getIconFromValue(icon), [icon]);

  return (
    <Notice
      className="items-start"
      title={!title?.hidden ? title?.value : undefined}
      subtitle={
        !subtitle.hidden ? (
          <MarkdownText>{subtitle?.value}</MarkdownText>
        ) : undefined
      }
      icon={iconValue}
      type={appearance || PRIMARY}
    >
      {actionButtons.length > 0 && (
        <RecordActionButtonSection
          align={END}
          actionButtons={actionButtons}
          backLink={backLink}
          dataType={dataType}
          elementPath={elementPath}
          editorMode={false}
          record={record}
          rootPathname={rootPathname}
          project={project}
          recordScope={recordScope}
        />
      )}
    </Notice>
  );
};

export default RecordNotice;
