import { useEffect, useMemo, useState } from 'react';

const useBroadcastChannel = (name: string) => {
  const [data, setData] = useState(null);
  const channel = useMemo(() => new BroadcastChannel(name), [name]);

  useEffect(() => {
    channel.onmessage = ({ data }) => setData(data);

    return () => {
      channel.close();
    };
  }, [channel, data]);

  return data;
};

export default useBroadcastChannel;
