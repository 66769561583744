import createReactComponent from '../createReactComponent';
export default createReactComponent('file-analytics', 'IconFileAnalytics', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 17l0 -5', key: 'svg-2' }],
  ['path', { d: 'M12 17l0 -1', key: 'svg-3' }],
  ['path', { d: 'M15 17l0 -3', key: 'svg-4' }],
]);
