import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-watch-question',
  'IconDeviceWatchQuestion',
  [
    [
      'path',
      {
        d: 'M14 18h-5a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v2',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 18v3h6v-2', key: 'svg-1' }],
    ['path', { d: 'M9 6v-3h6v3', key: 'svg-2' }],
    ['path', { d: 'M19 22v.01', key: 'svg-3' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-4',
      },
    ],
  ],
);
