import createReactComponent from '../createReactComponent';
export default createReactComponent('triangles', 'IconTriangles', [
  [
    'path',
    {
      d: 'M9.974 21h8.052a.975 .975 0 0 0 .81 -1.517l-4.025 -6.048a.973 .973 0 0 0 -1.622 0l-4.025 6.048a.977 .977 0 0 0 .81 1.517z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4.98 16h14.04c.542 0 .98 -.443 .98 -.989a1 1 0 0 0 -.156 -.534l-7.02 -11.023a.974 .974 0 0 0 -1.648 0l-7.02 11.023a1 1 0 0 0 .294 1.366a.973 .973 0 0 0 .53 .157z',
      key: 'svg-1',
    },
  ],
]);
