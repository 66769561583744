import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FormField,
  Register as RegisterLayout,
  getColorShade,
} from '@noloco/components';
import { AuthLayoutType } from '@noloco/components/src/components/auth/authLayoutTypes';
import { LIGHT } from '@noloco/components/src/constants/surface';
import PoweredByNoloco from '../components/PoweredByNoloco';
import SocialLogin from '../components/SocialLogin';
import { Theme } from '../constants/tailwindStyles';
import { ProjectSettings } from '../models/Project';
import { User } from '../models/User';
import { FETCH_INVITATION_QUERY } from '../queries/auth';
import {
  projectIntegrationsSelector,
  projectMediaSelector,
  projectNameSelector,
} from '../selectors/projectSelectors';
import { isPasswordSignInEnabled } from '../utils/auth';
import { extractErrorMessages } from '../utils/errors';
import { useAuth } from '../utils/hooks/useAuth';
import { useUpdateUserCache } from '../utils/hooks/useAuthWrapper';
import useRouter from '../utils/hooks/useRouter';
import { getProjectAuthLogo } from '../utils/image';
import { getText } from '../utils/lang';
import SocialOnlyLogin from './SocialOnlyLogin';

type Props = {
  settings: ProjectSettings;
  type?: AuthLayoutType;
  theme: Theme;
};

const Join = forwardRef<any, Props>(({ settings, type, theme }, ref) => {
  const {
    query: { invitationToken },
    push,
  } = useRouter();

  const projectName = useSelector(projectNameSelector);
  const integrations = useSelector(projectIntegrationsSelector);
  const media = useSelector(projectMediaSelector);

  const {
    data: invitationData,
    error: invitationErrors,
    loading: invitationLoading,
  } = useQuery(
    gql`
      ${FETCH_INVITATION_QUERY}
    `,
    {
      context: {
        projectQuery: true,
        projectName: projectName,
      },
      variables: {
        token: invitationToken,
      },
      skip: !invitationToken,
    },
  );

  const googleClientId = get(integrations, 'google.signIn.clientId');
  const updateUserCache = useUpdateUserCache();
  const [hasSetDetails, setHasSetDetails] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errors, setErrors] = useState([]);
  const { acceptInvite } = useAuth();

  const primaryColor = theme.brandColors.primary;

  useEffect(() => {
    if (errors.length === 0) {
      if (invitationErrors && invitationErrors.graphQLErrors) {
        // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
        setErrors(invitationErrors.graphQLErrors.map((e) => e.message));
      } else if (!invitationToken) {
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
        setErrors([getText('auth.join.error')]);
      }
    }
  }, [invitationErrors, errors, invitationToken]);

  const invitation = invitationData && invitationData.invitation;

  useEffect(() => {
    if (!invitationLoading && invitation && !hasSetDetails) {
      if (invitation.firstName) {
        setFirstName(invitation.firstName);
      }
      if (invitation.lastName) {
        setLastName(invitation.lastName);
      }
      setHasSetDetails(true);
    }
  }, [hasSetDetails, invitation, invitationLoading]);

  const onSocialLogin = (user: User) => {
    updateUserCache(user);
  };

  const handleOnSubmit = (registerData: any) => {
    if (!firstName) {
      return setErrors([
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
        getText({ field: 'first name' }, 'errors.forms.required'),
      ]);
    }
    if (!lastName) {
      return setErrors([
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
        getText({ field: 'last name' }, 'errors.forms.required'),
      ]);
    }

    acceptInvite(registerData.password, registerData.confirmPassword, {
      email: invitation.email,
      firstName,
      lastName,
      invitationToken,
    })
      .then((user: any) => {
        updateUserCache(user);
        push('/');
      })
      .catch((error: any) => {
        const errors = extractErrorMessages(error);
        if (errors.length > 0) {
          // @ts-expect-error TS(2345): Argument of type 'String[]' is not assignable to p... Remove this comment to see the full error message
          setErrors(errors);
        }
        console.warn('ERROR', JSON.stringify(error, undefined, 2));
      });
  };
  const logoUrl = getProjectAuthLogo(settings, media);

  const passwordSignUpEnabled = useMemo(
    () => isPasswordSignInEnabled(settings),
    [settings],
  );

  const loginText = (
    <span>
      <span>{getText('auth.register.or')}</span>
      <Link
        to="/login"
        className={classNames(
          'ml-1 font-medium transition duration-150 ease-in-out focus:underline focus:outline-none',
          `text-${getColorShade(primaryColor, 600)} hover:text-${getColorShade(
            primaryColor,
            600,
          )}`,
        )}
      >
        {getText('auth.register.loginLink')}
      </Link>
    </span>
  );

  if (!passwordSignUpEnabled && googleClientId) {
    return (
      <SocialOnlyLogin
        errors={errors}
        googleClientId={googleClientId}
        logoUrl={logoUrl}
        onSocialLogin={onSocialLogin}
        projectName={projectName}
        setErrors={setErrors}
        subTitleText={loginText}
        titleText={getText('auth.join.title')}
      />
    );
  }

  return (
    <RegisterLayout
      ref={ref}
      disabled={!!invitationErrors || !invitationToken}
      errors={errors}
      logoUrl={logoUrl}
      hideEmail={true}
      showPhoneNumber={false}
      buttonText={getText('auth.join.button')}
      emailLabel={getText('auth.join.fields.email')}
      passwordLabel={getText('auth.fields.password')}
      confirmPasswordLabel={getText('auth.fields.confirmPassword')}
      rememberLabel={getText('auth.fields.rememberMe')}
      loginText={loginText}
      titleText={getText('auth.join.title')}
      onSubmit={handleOnSubmit}
      type={type}
      errorTexts={{
        empty: getText('auth.register.validation.empty'),
        email: {
          invalid: getText('auth.register.validation.email.invalid'),
        },
        password: {
          invalid: getText('auth.register.validation.password.invalid'),
          tooShort: getText('auth.register.validation.password.tooShort'),
          tooLong: getText('auth.register.validation.password.tooLong'),
          numbers: getText('auth.register.validation.password.numbers'),
          case: getText('auth.register.validation.password.case'),
          symbol: getText('auth.register.validation.password.symbol'),
        },
        confirmPassword: {
          invalid: getText('auth.register.validation.confirmPassword.invalid'),
        },
      }}
      socialLogins={
        googleClientId &&
        !invitationErrors && (
          <SocialLogin
            clientId={googleClientId}
            setErrors={setErrors}
            onLogin={onSocialLogin}
          />
        )
      }
      footer={
        <PoweredByNoloco
          className="mx-auto flex-wrap justify-center text-gray-800"
          projectName={projectName}
          utmSource="noloco_join"
        />
      }
      surface={LIGHT}
    >
      <div className="mb-4 flex flex-wrap">
        <div className="flex w-full flex-wrap">
          <FormField
            className="mb-4 w-full"
            aria-label="given-name"
            autoComplete="given-name"
            name="given-name"
            type="text"
            onChange={({ target: { value } }: any) => setFirstName(value)}
            required
            errorType="below-solid"
            label={getText('auth.fields.firstName')}
            placeholder=""
            value={firstName}
            surface={LIGHT}
          />
          <FormField
            className="w-full"
            aria-label="family-name"
            autoComplete="family-name"
            name="family-name"
            type="text"
            onChange={({ target: { value } }: any) => setLastName(value)}
            required
            errorType="below-solid"
            label={getText('auth.fields.lastName')}
            placeholder=""
            value={lastName}
            surface={LIGHT}
          />
        </div>
      </div>
    </RegisterLayout>
  );
});

export default withTheme(Join);
