import React, { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import {
  IconChevronDown,
  IconChevronRight,
  IconDotsVertical,
  IconExternalLink,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip, VerticalNavItem, getColorShade } from '@noloco/components';
import { SINGLE_RECORD } from '../constants/collectionLayouts';
import { DIVIDER, LINK, VIEW } from '../constants/elements';
import { IS_SSR } from '../constants/env';
import { PAGE } from '../constants/linkTypes';
import { SIDE_MENU, TOP_MENU } from '../constants/menuStyles';
import Icon from '../elements/Icon';
import ViewCollectionCount from '../elements/sections/view/ViewCollectionCount';
import { scopeSelector } from '../selectors/dataSelectors';
import { selectedPagePathSelector } from '../selectors/elementsSelectors';
import { resolveNavigationObject } from '../utils/navigation';

const SidebarItem = ({
  active,
  depth,
  elementPathSuffix,
  editorMode,
  editingPage,
  icon,
  index,
  isNavExpanded,
  hasChildPages,
  hideIcon,
  menuStyle,
  onEditSelectPage,
  page,
  portalPath,
  project,
  to,
  theme,
}: any) => {
  const scope = useSelector(scopeSelector);
  const selectedPagePath = useSelector(selectedPagePathSelector);
  const primaryColor = theme.brandColorGroups.primary;
  const isV2 = get(project, 'settings.flags.v2', false);

  const elementPath = useMemo(
    () => [
      ...portalPath,
      ...(isV2 ? [] : ['children']),
      index,
      ...elementPathSuffix,
    ],
    [elementPathSuffix, index, isV2, portalPath],
  );

  const { navItemIs, href, navItemTo } = useMemo(() => {
    if (page.type !== LINK) {
      return { navItemTo: to, navItemIs: Link };
    }

    const link = get(page, 'props.link');
    if (!link || editorMode) {
      return { href: undefined, navItemIs: 'span' };
    }

    const resolvedLink = resolveNavigationObject(link, scope, project);

    if (resolvedLink.href && resolvedLink.href.startsWith('/')) {
      return {
        navItemTo: resolvedLink.href,
        navItemIs: Link,
      };
    }

    if (resolvedLink.to) {
      return { navItemTo: resolvedLink.to, navItemIs: Link };
    }

    return { href: resolvedLink.href, navItemIs: 'a' };
  }, [editorMode, page, project, scope, to]);

  if (page.type === DIVIDER) {
    return (
      <div
        className={classNames(
          'group mx-3 my-0.5 flex h-8 items-center justify-center rounded-lg py-0.5',
          {
            'px-3': menuStyle === SIDE_MENU,
            [`cursor-move hover:bg-${getColorShade(primaryColor, 600)}`]:
              editorMode,
          },
        )}
      >
        {menuStyle === SIDE_MENU && (
          <hr
            className={classNames(
              'w-full',
              `border-${getColorShade(primaryColor, 500)}`,
            )}
          />
        )}
        {menuStyle === TOP_MENU && (
          <div
            className={classNames(
              'h-full w-px rounded-lg border-r',
              `border-${getColorShade(primaryColor, 500)}`,
            )}
          />
        )}
        {editorMode && (
          <button
            className={classNames(
              `items-center rounded-full p-1 opacity-75 group-hover:flex hover:bg-${getColorShade(
                primaryColor,
                700,
              )} hover:opacity-100`,
              {
                hidden:
                  editingPage !== page.id &&
                  !selectedPagePath
                    .join('.')
                    .startsWith(['PORTAL', page.id].join('.')),
              },
            )}
            onClick={() => onEditSelectPage(page.id, elementPath)}
          >
            <IconDotsVertical size={12} />
          </button>
        )}
      </div>
    );
  }

  return (
    <Tooltip
      content={get(page, 'props.name', '')}
      placement="right"
      popoverClassName="sm:hidden"
      disabled={isNavExpanded}
      bg="white"
    >
      <VerticalNavItem
        className="group relative flex-grow"
        active={active}
        type="rounded"
        is={navItemIs}
        href={href}
        to={navItemTo}
        target={href ? '_blank' : undefined}
        key={page.id}
        {...(menuStyle === TOP_MENU
          ? {
              p: { x: 1.5, y: 0.5 },
              m: { x: 0.5, y: 0 },
            }
          : {})}
      >
        <div className="flex h-8 items-center">
          {!hideIcon && (
            <Icon
              icon={page.props.icon || { name: icon }}
              className={classNames(
                'h-5 w-5 flex-shrink-0 opacity-75 sm:ml-0 sm:mr-2',
                {
                  'ml-0 mr-2': isNavExpanded,
                  'mx-auto': !isNavExpanded,
                },
              )}
            />
          )}
          {!hideIcon && !isNavExpanded && !page.props.icon?.name && (
            <span className="ml-0.5 flex h-5 w-5 flex-shrink-0 items-center justify-center text-center text-lg opacity-75">
              {page.props.name?.[0]}
            </span>
          )}
          <span
            className={classNames('mr-2 max-w-full overflow-hidden', {
              'hidden sm:block': !isNavExpanded,
              'break-words': !editorMode,
              truncate: editorMode,
              'whitespace-nowrap': menuStyle === TOP_MENU,
              'ml-1': !hideIcon && !page.props.icon?.name && depth === 1,
            })}
          >
            {page.props.name}
          </span>
          <div
            className={classNames('ml-auto h-8 items-center justify-end', {
              flex: isNavExpanded,
              'hidden sm:flex': !isNavExpanded,
            })}
          >
            {page.type === LINK &&
              get(page, 'props.link.type', null) !== PAGE &&
              href &&
              !navItemTo && (
                <div className="ml-2 mr-1 items-center opacity-75 group-hover:opacity-100">
                  <IconExternalLink size={15} />
                </div>
              )}
            {!IS_SSR &&
              page.type === VIEW &&
              page.props.showRecordCount &&
              page.props.display !== SINGLE_RECORD &&
              editingPage !== page.id && (
                <ViewCollectionCount
                  className={classNames({
                    'group-hover:hidden': editorMode,
                  })}
                  element={page}
                  editorMode={editorMode}
                  elementPath={elementPath}
                  project={project}
                />
              )}
            {hasChildPages && editingPage !== page.id && (
              <button
                className={classNames(
                  'ml-2 mr-1 items-center opacity-75 group-hover:opacity-100',
                  { 'group-hover:hidden': editorMode },
                )}
              >
                {active || menuStyle === TOP_MENU ? (
                  <IconChevronDown size={14} />
                ) : (
                  <IconChevronRight size={14} />
                )}
              </button>
            )}
            {editorMode && (
              <button
                className={classNames(
                  `items-center rounded-full p-1 opacity-75 group-hover:flex hover:bg-${getColorShade(
                    primaryColor,
                    700,
                  )} hover:opacity-100`,
                  {
                    hidden:
                      editingPage !== page.id &&
                      !selectedPagePath
                        .join('.')
                        .startsWith(['PORTAL', page.id].join('.')),
                  },
                )}
                onClick={() => onEditSelectPage(page.id, elementPath)}
              >
                <IconDotsVertical size={12} />
              </button>
            )}
          </div>
        </div>
      </VerticalNavItem>
    </Tooltip>
  );
};

SidebarItem.defaultProps = {
  elementPathSuffix: [],
};

export default withTheme(SidebarItem);
