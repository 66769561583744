import { createSelector } from '@reduxjs/toolkit';
import { LeftEditorSectionOptions } from '../constants/buildMode';
import { ElementPath } from '../models/Element';

export type ElementsSelectorState = any;
export const elementsSelector = (state: ElementsSelectorState) =>
  state.elements;

const emptyArray: any[] = [];

export const editorModeSelector: (state: ElementsSelectorState) => boolean =
  createSelector(
    [elementsSelector],
    (elements) => (elements && elements.editorMode) || false,
  );

export const selectedPagePathSelector: (state: ElementsSelectorState) => any[] =
  createSelector(
    [elementsSelector],
    (elements) => (elements && elements.selectedPagePath) || emptyArray,
  );

export const selectedElementPathSelector: (
  state: ElementsSelectorState,
) => any[] = createSelector(
  [elementsSelector],
  (elements) => (elements && elements.selected) || emptyArray,
);

export const hasSelectedElementPathSelector: (
  state: ElementsSelectorState,
) => boolean = createSelector(
  [selectedElementPathSelector],
  (selectedElements) => selectedElements.length > 0,
);

export const selectedSectionSelector: (state: ElementsSelectorState) => any =
  createSelector(
    [elementsSelector],
    (elements) => (elements && elements.selectedSectionPath) || emptyArray,
  );

export const highlightedSectionSelector: (state: ElementsSelectorState) => any =
  createSelector(
    [elementsSelector],
    (elements) => (elements && elements.highlightedSectionPath) || emptyArray,
  );

export const leftEditorSectionSelector: (
  state: ElementsSelectorState,
) => LeftEditorSectionOptions = createSelector(
  [elementsSelector],
  (elements) => (elements && elements.leftEditorSection) || null,
);

export const isSelectedSelector = (
  elementPath: ElementPath,
): ((state: ElementsSelectorState) => boolean) =>
  createSelector(
    [selectedElementPathSelector],
    (selectedPath) =>
      selectedPath && selectedPath.join('.') === elementPath.join('.'),
  );

export const editingPageSelector: (state: ElementsSelectorState) => any =
  createSelector(
    [elementsSelector],
    (elements) => elements && elements.editingPage,
  );

export const newElementPathSelector: (state: ElementsSelectorState) => string =
  createSelector(
    [elementsSelector],
    (elements) => elements && elements.newElementPath,
  );

export const showAdvancedPageSettingsSelector: (
  state: ElementsSelectorState,
) => boolean = createSelector(
  [elementsSelector],
  (elements) => elements.showAdvancedPageSettings || false,
);

export const sidebarRecordViewTitleSelector: (
  state: ElementsSelectorState,
) => any = createSelector(
  [elementsSelector],
  (elements) => (elements && elements.sidebarRecordViewTitle) || null,
);

export const isFieldListEditorOpenSelector: (
  state: ElementsSelectorState,
) => boolean = createSelector(
  [elementsSelector],
  (elements) => (elements && elements.fieldListEditorOpen) || false,
);
