import classNames from 'classnames';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getText } from '../../../utils/lang';

type RecordEmptyStateProps = {
  title?: string;
};

const RecordEmptyState = ({ title }: RecordEmptyStateProps) => {
  const [isDarkModeEnabled] = useDarkMode();

  return (
    <div
      className={classNames(
        'flex items-center justify-center rounded-lg border p-8 shadow-md',
        {
          'border-slate-600 bg-slate-800': isDarkModeEnabled,
          'border-gray-200 bg-white': !isDarkModeEnabled,
        },
      )}
    >
      <div className="w-full py-20 text-center text-sm text-slate-600">
        {title ?? getText('elements.DETAILS.view.empty')}
      </div>
    </div>
  );
};

export default RecordEmptyState;
