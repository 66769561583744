import React, { useCallback, useState } from 'react';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';
import { Loader, Popover } from '@noloco/components';
import { XS } from '@noloco/components/src/constants/tShirtSizes';
import { getText } from '../../../utils/lang';

type Props = {
  onDelete: () => Promise<void>;
};

const LANG_KEY = 'core.COMMENT';

const RecordCommentMenu = ({ onDelete }: Props) => {
  const handleDelete = useCallback(() => {
    setIsDeleting(true);
    onDelete().finally(() => setIsDeleting(false));
  }, [onDelete]);

  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <Popover
      content={
        <div>
          <button
            className="flex space-x-2 p-1 text-xs text-red-600 hover:text-red-800"
            disabled={isDeleting}
            onClick={handleDelete}
          >
            {isDeleting ? <Loader size={XS} /> : <IconTrash size={16} />}
            <span>{getText(LANG_KEY, 'delete')}</span>
          </button>
        </div>
      }
    >
      <button>
        <IconDotsVertical
          className="ml-2 text-gray-500 hover:text-gray-700"
          size={16}
        />
      </button>
    </Popover>
  );
};

export default RecordCommentMenu;
