import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-imac-question',
  'IconDeviceImacQuestion',
  [
    [
      'path',
      {
        d: 'M14 17h-10a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v7.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 13h11.5', key: 'svg-1' }],
    ['path', { d: 'M8 21h7', key: 'svg-2' }],
    ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
    ['path', { d: 'M14 17l.5 4', key: 'svg-4' }],
    ['path', { d: 'M19 22v.01', key: 'svg-5' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-6',
      },
    ],
  ],
);
