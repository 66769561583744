import { useMemo } from 'react';
import { getCachedConditionFieldOptions } from '@noloco/ui/src/components/editor/customerEditors/FieldConditionsEditor';
import { DataType } from '../../models/DataTypes';
import { Project } from '../../models/Project';

const useRecordScopeOptions = (
  elementId: string,
  dataType: DataType | null,
  project: Project,
) => {
  const recordScopeOptions = useMemo(() => {
    if (!dataType) {
      return null;
    }

    return getCachedConditionFieldOptions(dataType, project, {
      stateId: `${elementId}:RECORD`,
    });
  }, [dataType, elementId, project]);

  return recordScopeOptions;
};

export default useRecordScopeOptions;
