import { useCallback, useMemo } from 'react';
import { IconPlus } from '@tabler/icons-react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import shortid from 'shortid';
import FilterMergeWarning from '@noloco/ui/src/components/editor/FilterMergeWarning';
import { OR } from '../../../constants/operators';
import { DataType } from '../../../models/DataTypes';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { useFieldOperatorConflicts } from '../../../utils/hooks/useFieldOperatorConflicts';
import { getText } from '../../../utils/lang';
import BuildModeAndFilterInput from './BuildModeAndFilterInput';
import BuildModeOrFilterInput from './BuildModeOrFilterInput';

type BuildModeCustomFiltersEditorProps = {
  additionalScopeItems?: any;
  customFilters: any;
  elementPath: ElementPath;
  onUpdateCustomFilters: (customFilters: any) => void;
  project: Project;
  selectedDataType: DataType;
};

const ADDITIONAL_FILTER_BASE = {
  field: null,
  operator: null,
  result: null,
};

export const generateAdditionalFilter = (overrides = {}) => ({
  ...ADDITIONAL_FILTER_BASE,
  id: shortid.generate(),
  ...overrides,
});

const LANG_KEY = 'elements.LIST.filterInput';

const BuildModeCustomFiltersEditor = ({
  additionalScopeItems = [],
  customFilters,
  elementPath,
  onUpdateCustomFilters,
  project,
  selectedDataType,
}: BuildModeCustomFiltersEditorProps) => {
  const orFilter = useMemo(() => {
    const foundFilter = customFilters.find(
      (customFilter: any) => customFilter.operator === OR,
    );

    if (!foundFilter) {
      return undefined;
    }

    return {
      filter: foundFilter,
      index: customFilters.indexOf(foundFilter),
    };
  }, [customFilters]);

  const andFilters = useMemo(() => {
    if (orFilter) {
      return customFilters.filter(
        (_: any, index: number) => index !== orFilter.index,
      );
    }

    return customFilters;
  }, [customFilters, orFilter]);

  const getFilterIndex = useCallback(
    (id) =>
      customFilters.findIndex((customFilter: any) => customFilter.id === id),
    [customFilters],
  );

  const { fieldOperatorCounts, showFieldOperatorWarning } =
    useFieldOperatorConflicts(andFilters);

  const operatorValidationError = useCallback(
    (additionalFilter) =>
      get(
        fieldOperatorCounts,
        [additionalFilter.field, additionalFilter.operator],
        0,
      ) > 1,
    [fieldOperatorCounts],
  );

  const onAddAndFilter = useCallback(
    () =>
      onUpdateCustomFilters(
        [...customFilters, generateAdditionalFilter()].sort((a, b) => {
          // @ts-expect-error TS(2447): The '^' operator is not allowed for boolean types.... Remove this comment to see the full error message
          if ((a.operator === OR) ^ (b.operator === OR)) {
            return a.operator === OR ? 1 : -1;
          }

          return 0;
        }),
      ),
    [customFilters, onUpdateCustomFilters],
  );

  const onAddOrBlock = useCallback(
    () =>
      onUpdateCustomFilters([
        ...customFilters,
        {
          operator: OR,
          branches: [
            {
              filters: [generateAdditionalFilter()],
              id: shortid.generate(),
            },
          ],
        },
      ]),
    [customFilters, onUpdateCustomFilters],
  );

  const onUpdateFilter = useCallback(
    (index: number, value: any) =>
      onUpdateCustomFilters(set(index, value, customFilters)),
    [onUpdateCustomFilters, customFilters],
  );

  const onRemoveFilter = useCallback(
    (index: number) =>
      onUpdateCustomFilters(
        customFilters.filter(
          (_: any, filterIndex: number) => filterIndex !== index,
        ),
      ),
    [onUpdateCustomFilters, customFilters],
  );

  return (
    <div>
      {andFilters.length === 0 && isNil(orFilter) && (
        <div className="flex w-full items-center space-x-2 font-mono text-gray-200">
          <div
            className="flex cursor-pointer items-center justify-center space-x-2 rounded-lg bg-slate-700 p-2 hover:bg-slate-500"
            onClick={onAddAndFilter}
          >
            <IconPlus size={16} />
            <span>{getText(LANG_KEY, 'addAnd')}</span>
          </div>
          <div
            className="flex w-full cursor-pointer items-center justify-center space-x-2 rounded-lg bg-slate-700 p-2 hover:bg-slate-500"
            onClick={onAddOrBlock}
          >
            <IconPlus size={16} />
            <span>{getText(LANG_KEY, 'conditionGroup')}</span>
          </div>
        </div>
      )}
      {andFilters.length > 0 && (
        <>
          {showFieldOperatorWarning && <FilterMergeWarning />}
          {andFilters.map((additionalFilter: any) => {
            const index = getFilterIndex(additionalFilter.id);

            return (
              <>
                <BuildModeAndFilterInput
                  additionalScopeItems={additionalScopeItems}
                  canAddFilter={index === andFilters.length - 1}
                  canAddOrFilter={isNil(orFilter)}
                  dataType={selectedDataType}
                  elementPath={elementPath}
                  key={
                    additionalFilter.id || `${additionalFilter.field}-${index}`
                  }
                  onAddAndFilter={onAddAndFilter}
                  onAddOrBlock={onAddOrBlock}
                  onChange={(value: any) => onUpdateFilter(index, value)}
                  onRemove={() => onRemoveFilter(index)}
                  operatorValidationError={operatorValidationError(
                    additionalFilter,
                  )}
                  project={project}
                  value={additionalFilter}
                />
                {(index !== andFilters.length - 1 || !isNil(orFilter)) && (
                  <div className="relative py-2">
                    <div className="absolute inset-y-0 left-1/2 w-px -translate-x-1/2 transform border-l border-dashed border-slate-700"></div>
                    <div className="relative z-10 flex items-center justify-center bg-slate-800 text-slate-400">
                      {getText(LANG_KEY, 'addAnd')}
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </>
      )}
      {!isNil(orFilter) && (
        <BuildModeOrFilterInput
          additionalScopeItems={additionalScopeItems}
          dataType={selectedDataType}
          elementPath={elementPath}
          generateAdditionalFilter={generateAdditionalFilter}
          onChange={(value: any) => onUpdateFilter(orFilter.index, value)}
          onRemove={() => onRemoveFilter(orFilter.index)}
          project={project}
          value={orFilter.filter}
        />
      )}
    </div>
  );
};

export default BuildModeCustomFiltersEditor;
