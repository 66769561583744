import createReactComponent from '../createReactComponent';
export default createReactComponent('device-ipad', 'IconDeviceIpad', [
  [
    'path',
    {
      d: 'M18 2a3 3 0 0 1 2.995 2.824l.005 .176v14a3 3 0 0 1 -2.824 2.995l-.176 .005h-12a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-14a3 3 0 0 1 2.824 -2.995l.176 -.005h12zm-3 15h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z',
      key: 'svg-0',
    },
  ],
]);
