import createReactComponent from '../createReactComponent';
export default createReactComponent('clock-2', 'IconClock2', [
  [
    'path',
    {
      d: 'M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 7v5l3 3', key: 'svg-1' }],
  ['path', { d: 'M4 12h1', key: 'svg-2' }],
  ['path', { d: 'M19 12h1', key: 'svg-3' }],
  ['path', { d: 'M12 19v1', key: 'svg-4' }],
]);
