import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'currency-dogecoin',
  'IconCurrencyDogecoin',
  [
    ['path', { d: 'M6 12h6', key: 'svg-0' }],
    ['path', { d: 'M9 6v12', key: 'svg-1' }],
    ['path', { d: 'M6 18h6a6 6 0 1 0 0 -12h-6', key: 'svg-2' }],
  ],
);
