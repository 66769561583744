import createReactComponent from '../createReactComponent';
export default createReactComponent('table-alias', 'IconTableAlias', [
  [
    'path',
    {
      d: 'M3 12v-7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 10h18', key: 'svg-1' }],
  ['path', { d: 'M10 3v10', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M2 17a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-4z',
      key: 'svg-3',
    },
  ],
]);
