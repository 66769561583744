import createReactComponent from '../createReactComponent';
export default createReactComponent('letter-s', 'IconLetterS', [
  [
    'path',
    {
      d: 'M17 8a4 4 0 0 0 -4 -4h-2a4 4 0 0 0 0 8h2a4 4 0 0 1 0 8h-2a4 4 0 0 1 -4 -4',
      key: 'svg-0',
    },
  ],
]);
