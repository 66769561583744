import React from 'react';
import { Modal } from '@noloco/components';
import YoutubeVideo from '@noloco/core/src/elements/YoutubeVideo';

const VideoModal = ({
  confirmText,
  description,
  onClose,
  title,
  videoId,
}: any) => (
  <Modal
    title={<span className="mb-1 text-2xl font-medium">{title}</span>}
    closeOnOutsideClick={false}
    size="xl"
    confirmText={
      <span className="mb-1 text-lg font-medium">{confirmText}</span>
    }
    canCancel={false}
    onConfirm={onClose}
  >
    <div className="bg-white text-base">
      <p>{description}</p>
      <YoutubeVideo
        className="mx-auto my-8 max-w-5xl overflow-hidden rounded-lg"
        videoId={videoId}
        autoPlay={false}
        showControls={true}
      />
    </div>
  </Modal>
);

export default VideoModal;
