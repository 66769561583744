import createReactComponent from '../createReactComponent';
export default createReactComponent('replace-off', 'IconReplaceOff', [
  [
    'path',
    {
      d: 'M7 3h1a1 1 0 0 1 1 1v1m-.303 3.717a1 1 0 0 1 -.697 .283h-4a1 1 0 0 1 -1 -1v-4c0 -.28 .115 -.532 .3 -.714',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M19 15h1a1 1 0 0 1 1 1v1m-.303 3.717a1 1 0 0 1 -.697 .283h-4a1 1 0 0 1 -1 -1v-4c0 -.28 .115 -.532 .3 -.714',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M21 11v-3a2 2 0 0 0 -2 -2h-6l3 3m0 -6l-3 3', key: 'svg-2' }],
  ['path', { d: 'M3 13v3a2 2 0 0 0 2 2h6l-3 -3m0 6l3 -3', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
