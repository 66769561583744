import createReactComponent from '../createReactComponent';
export default createReactComponent('bolt-off', 'IconBoltOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15.212 15.21l-4.212 5.79v-7h-6l3.79 -5.21m1.685 -2.32l2.525 -3.47v6m1 1h5l-2.104 2.893',
      key: 'svg-1',
    },
  ],
]);
