import React, { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { Button, ErrorText } from '@noloco/components';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import { DataSchemaField } from '@noloco/core/src/constants/dataSchema';
import { buildFieldTypeOptions } from '@noloco/core/src/utils/apis';
import { buildFields } from '@noloco/core/src/utils/dataSchema';
import { getText } from '@noloco/core/src/utils/lang';
import DataSchemaEditor from '../dataSchema/DataSchemaEditor';

const LANG_KEY = 'data.api.endpoints.data';

type Props = {
  data: any;
  endpointOptions: any[];
  error: any;
  fields: DataSchemaField[] | null;
  idField: string | null;
  onOpenFormatTab: () => void;
  onUpdateFields: (fields: DataSchemaField[]) => void;
  resultPath: string[] | null;
};

const ApiEndpointTestResult = ({
  data,
  endpointOptions,
  error,
  fields,
  idField,
  onOpenFormatTab,
  onUpdateFields,
  resultPath,
}: Props) => {
  const extractedDataArray: any[] | null = useMemo(() => {
    if (data && isArray(data)) {
      return data.slice(0, 5);
    }

    if (resultPath === null) {
      return null;
    }

    const extractedData = get(data, resultPath, null);
    if (!isArray(extractedData)) {
      return null;
    }

    return extractedData.slice(0, 5);
  }, [data, resultPath]);

  const extractedFields = useMemo(
    () =>
      extractedDataArray !== null ? buildFields([], extractedDataArray) : null,
    [extractedDataArray],
  );
  useEffect(() => {
    if (extractedFields && !fields) {
      onUpdateFields(extractedFields);
    }
  }, [extractedFields, fields, onUpdateFields]);

  if (error) {
    return (
      <div className="m-auto flex flex-col space-y-2">
        <ErrorText className="mt-6">{error}</ErrorText>
      </div>
    );
  }

  if (data && extractedDataArray === null) {
    return (
      <div className="m-auto flex flex-col space-y-2">
        <ErrorText>{getText(LANG_KEY, 'error.structure')}</ErrorText>
        <Button
          className="mx-auto"
          onClick={onOpenFormatTab}
          variant={SECONDARY}
        >
          {getText(LANG_KEY, 'error.cta.format')}
        </Button>
      </div>
    );
  }

  return (
    <div className="w-full">
      <DataSchemaEditor
        buildFieldTypeOptions={buildFieldTypeOptions}
        fields={fields}
        idField={idField}
        onUpdateFields={onUpdateFields}
        records={extractedDataArray}
        relationshipOptions={endpointOptions}
      />
    </div>
  );
};

export default ApiEndpointTestResult;
