import { Dispatch, SetStateAction } from 'react';
import { getText } from '../lang';
import { useLoadingAlert } from './useAlerts';

export const useShowUpdatingAlert = (
  setIsLoading: Dispatch<SetStateAction<boolean>> | undefined,
) => {
  const loadingAlert = useLoadingAlert();
  return async (callback: () => Promise<any>, message?: string) => {
    const updatingAlert = loadingAlert(
      message ?? getText('elements.VIEW.display.bulkActions.updating'),
      undefined,
      {
        timeout: 0,
        position: 'bottom center',
      },
    );
    try {
      setIsLoading?.(true);
      const result = await callback();
      updatingAlert.close();
      return result;
    } catch (error) {
      updatingAlert.close();
      throw error;
    } finally {
      setIsLoading?.(false);
    }
  };
};
