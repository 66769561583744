import createReactComponent from '../createReactComponent';
export default createReactComponent('mug', 'IconMug', [
  [
    'path',
    {
      d: 'M4.083 5h10.834a1.08 1.08 0 0 1 1.083 1.077v8.615c0 2.38 -1.94 4.308 -4.333 4.308h-4.334c-2.393 0 -4.333 -1.929 -4.333 -4.308v-8.615a1.08 1.08 0 0 1 1.083 -1.077',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16 8h2.5c1.38 0 2.5 1.045 2.5 2.333v2.334c0 1.288 -1.12 2.333 -2.5 2.333h-2.5',
      key: 'svg-1',
    },
  ],
]);
