import { Integrations } from '../../models/Project';

const useIsBillingEnabled = (integrations: Integrations | undefined) => {
  const { stripe } = integrations || {};

  const isStripeConnected = stripe && stripe.account && stripe.account.id;
  const isStripeCompleted = isStripeConnected && stripe.account.chargesEnabled;

  return {
    stripe,
    isStripeConnected,
    isStripeCompleted,
    isEnabled: isStripeCompleted,
  };
};

export default useIsBillingEnabled;
