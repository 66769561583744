import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-pin',
  'IconDeviceDesktopPin',
  [
    [
      'path',
      {
        d: 'M12.5 16h-8.5a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v6',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M19 18v.01', key: 'svg-2' }],
    ['path', { d: 'M7 20h5', key: 'svg-3' }],
    ['path', { d: 'M9 16v4', key: 'svg-4' }],
  ],
);
