import createReactComponent from '../createReactComponent';
export default createReactComponent('device-watch-off', 'IconDeviceWatchOff', [
  [
    'path',
    {
      d: 'M10 6h5a3 3 0 0 1 3 3v5m-.89 3.132a2.99 2.99 0 0 1 -2.11 .868h-6a3 3 0 0 1 -3 -3v-6c0 -.817 .327 -1.559 .857 -2.1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 18v3h6v-3', key: 'svg-1' }],
  ['path', { d: 'M9 5v-2h6v3', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
