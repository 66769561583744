import createReactComponent from '../createReactComponent';
export default createReactComponent('ice-skating', 'IconIceSkating', [
  [
    'path',
    {
      d: 'M5.905 5h3.418a1 1 0 0 1 .928 .629l1.143 2.856a3 3 0 0 0 2.207 1.83l4.717 .926a2.084 2.084 0 0 1 1.682 2.045v.714a1 1 0 0 1 -1 1h-13.895a1 1 0 0 1 -1 -1.1l.8 -8a1 1 0 0 1 1 -.9z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 19h17a1 1 0 0 0 1 -1', key: 'svg-1' }],
  ['path', { d: 'M9 15v4', key: 'svg-2' }],
  ['path', { d: 'M15 15v4', key: 'svg-3' }],
]);
