import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-wix', 'IconBrandWix', [
  [
    'path',
    {
      d: 'M3 9l1.5 6l1.379 -5.515a.64 .64 0 0 1 1.242 0l1.379 5.515l1.5 -6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 11.5v3.5', key: 'svg-1' }],
  ['path', { d: 'M16 9l5 6', key: 'svg-2' }],
  ['path', { d: 'M21 9l-5 6', key: 'svg-3' }],
  ['path', { d: 'M13 9h.01', key: 'svg-4' }],
]);
