import createReactComponent from '../createReactComponent';
export default createReactComponent('box-align-top', 'IconBoxAlignTop', [
  [
    'path',
    { d: 'M4 9.505h16v-5a1 1 0 0 0 -1 -1h-14a1 1 0 0 0 -1 1v5z', key: 'svg-0' },
  ],
  ['path', { d: 'M4 14.505v-.01', key: 'svg-1' }],
  ['path', { d: 'M4 19.505v-.01', key: 'svg-2' }],
  ['path', { d: 'M9 19.505v-.01', key: 'svg-3' }],
  ['path', { d: 'M15 19.505v-.01', key: 'svg-4' }],
  ['path', { d: 'M20 19.505v-.01', key: 'svg-5' }],
  ['path', { d: 'M20 14.505v-.01', key: 'svg-6' }],
]);
