import { DateTime } from 'luxon';
import { MONTH, PlanInterval, YEAR } from '../constants/accountPlanIntervals';
import { EXPERT, FREE } from '../constants/accountPlans';
import { TRIALING, TRIAL_EXPIRED } from '../constants/accountStatus';
import {
  BillingOrTeamPlan,
  BillingPlan,
  UsagePeriod,
} from '../models/BillingPlan';
import { Project } from '../models/Project';

export const isTrialActive = (teamPlan: BillingOrTeamPlan): boolean =>
  !!teamPlan &&
  teamPlan.type !== EXPERT &&
  teamPlan.status === TRIALING &&
  !!teamPlan.trialEnd &&
  DateTime.now() < DateTime.fromJSDate(new Date(teamPlan.trialEnd));

// Note: these functions shouldn't be used as opposites
//       because a valid plan will fail assertion this because they aren't in status `trialing`
export const isPaidPlanTrialExpired = (
  teamPlan: BillingOrTeamPlan | null,
): boolean =>
  !!teamPlan &&
  teamPlan.type !== FREE &&
  (teamPlan.status === TRIALING || teamPlan.status === TRIAL_EXPIRED) &&
  !!teamPlan.trialEnd &&
  DateTime.now() > DateTime.fromJSDate(new Date(teamPlan.trialEnd));

export const isTrailingPlanExpired = (billingPlan: BillingPlan) =>
  Boolean(
    billingPlan &&
      billingPlan.type === FREE &&
      billingPlan.trialEnd &&
      new Date(billingPlan.trialEnd) < new Date(),
  );

const toThisMonth = (
  today: DateTime,
  teamPlanPeriodEnd: string | Date | null,
  teamPlanInterval: PlanInterval | null,
) => {
  if (!teamPlanPeriodEnd) {
    return;
  }

  const periodEnd = DateTime.fromJSDate(new Date(teamPlanPeriodEnd));

  if (!teamPlanInterval || teamPlanInterval === MONTH) {
    return periodEnd;
  }

  if (teamPlanInterval === YEAR) {
    if (today.day <= periodEnd.day) {
      return today.set({ day: periodEnd.day });
    }

    if (today.day > periodEnd.day) {
      return today.plus({ months: 1 }).set({ day: periodEnd.day });
    }
  }
};

export const isPartnerTrialActive = (
  teamPlan: BillingOrTeamPlan,
  project: Project,
): boolean =>
  !!teamPlan &&
  teamPlan.type === EXPERT &&
  !!project.partnerTrialEnd &&
  DateTime.now() < DateTime.fromJSDate(new Date(project.partnerTrialEnd));

export const isPartnerTrialExpired = (
  teamPlan: BillingOrTeamPlan,
  project: Project,
): boolean =>
  !!teamPlan &&
  teamPlan.type === EXPERT &&
  project &&
  !!project.partnerTrialEnd &&
  DateTime.now() > DateTime.fromJSDate(new Date(project.partnerTrialEnd));

export const getUsagePeriod = (teamPlan: BillingOrTeamPlan): UsagePeriod => {
  const today = DateTime.utc().startOf('day');

  // Fallback to calendar month
  const startOfMonth = today.startOf('month');
  const endOfMonth = today.endOf('month');

  const periodEnd = toThisMonth(today, teamPlan.periodEnd, teamPlan.interval);

  // Try to extrapolate billing period from periodEnd
  const end = (periodEnd ? periodEnd.minus({ days: 3 }) : endOfMonth).endOf(
    'day',
  );
  const start = (periodEnd ? end.minus({ months: 1 }) : startOfMonth).startOf(
    'day',
  );
  return {
    start,
    end,
  };
};
