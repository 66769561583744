import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-exclamation',
  'IconDeviceDesktopExclamation',
  [
    [
      'path',
      {
        d: 'M15 16h-11a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h8', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    ['path', { d: 'M15 16v4', key: 'svg-3' }],
    ['path', { d: 'M19 16v3', key: 'svg-4' }],
    ['path', { d: 'M19 22v.01', key: 'svg-5' }],
  ],
);
