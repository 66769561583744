import { EnumType } from 'json-to-graphql-query';
import { DepValue } from '../models/Element';

export const cleanEdgesNodeFromDepPath = (path: string, replaceValue = '') =>
  path.replace(/^(edges\.node\.)?/, replaceValue);

export const removeEdgesNodeFromPath = (dep: DepValue) => ({
  ...dep,
  path: cleanEdgesNodeFromDepPath(dep.path),
});

export const transformQueryArg = (key: string, value: any) => {
  switch (key) {
    case 'orderBy': {
      return { [key]: { ...value, direction: new EnumType(value.direction) } };
    }
    default: {
      return { [key]: value };
    }
  }
};

export const transformQueryArgs = (queryArgs: Record<any, any>) => {
  if (Object.keys(queryArgs).length === 0) {
    return undefined;
  }

  return Object.entries(queryArgs).reduce(
    (newArgs, [key, value]) => ({
      ...newArgs,
      ...transformQueryArg(key, value),
    }),
    {},
  );
};
