import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { DYNAMIC } from '../constants/imageTypes';

const placeholder = 'https://media.noloco.app/65-placeholder.svg';

type Props = {
  children?: React.ReactElement;
  className?: string;
  editorMode?: boolean;
  image?: {
    src?: string;
  };
  type?: any; // TODO: PropTypes.oneOf([UPLOAD, DYNAMIC])
  w?: number | string;
};

const Image = forwardRef<any, Props>(
  (
    {
      className,
      children: __,
      editorMode,
      image,
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'Props'.
      style,
      // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Props'.
      name,
      // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'Props'.
      loading,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
      onClick,
    },
    ref,
  ) => {
    const { src } = image || {};

    return (
      <img
        alt={name}
        className={classNames(className, {
          'animate-pulse bg-gray-400':
            loading && !editorMode && (!src || src === placeholder),
        })}
        onClick={onClick}
        ref={ref}
        style={style}
        src={src || placeholder}
      />
    );
  },
);

Image.defaultProps = {
  children: undefined,
  className: '',
  image: {},
  type: DYNAMIC,
};

export default Image;
