import { countries } from 'countries-list';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { PhoneNumber } from '@noloco/components/src/models/phoneNumber';
import { OBJECT, TEXT } from '../constants/dataTypes';
import {
  ADDRESS,
  COORDINATES,
  DATE_RANGE,
  DUE_DATE,
  FULL_NAME,
  FieldFormat,
  PHONE_NUMBER,
} from '../constants/fieldFormats';
import { FORMATS_WITH_ROOT, SUB_FIELD_CONFIG } from '../constants/objects';
import DataTypeFields, {
  BaseDataFieldOption,
  DataField,
} from '../models/DataTypeFields';
import { Address, FullName } from '../models/Record';
import { getText } from './lang';

const getRootValueForAddress = (address: Address): string =>
  [
    address.street,
    address.suiteAptBldg,
    address.city,
    address.stateRegion,
    address.postalCode,
    address.country,
  ]
    .filter(Boolean)
    .join(', ');

const getRootValueForFullName = (fullName: FullName): string =>
  [fullName.title, fullName.first, fullName.middle, fullName.last]
    .filter(Boolean)
    .join(' ');

const getRootValueForPhoneNumber = (phoneNumber: PhoneNumber): string => {
  const countryCode = get(countries, [phoneNumber.country, 'phone']);
  if (
    countryCode &&
    phoneNumber.number &&
    phoneNumber.number.startsWith(`+${countryCode}`)
  ) {
    return phoneNumber.number;
  }

  if (!countryCode) {
    return phoneNumber.number ?? '';
  }

  let localNumber = phoneNumber.number;
  if (phoneNumber.number.startsWith('0')) {
    localNumber = localNumber.slice(1);
  }

  return `+${countryCode} ${localNumber}`;
};

export const getRootValue = (
  format: FieldFormat,
  value: any,
): string | null => {
  switch (format) {
    case ADDRESS:
      return getRootValueForAddress(value as Address);
    case FULL_NAME:
      return getRootValueForFullName(value as FullName);
    case PHONE_NUMBER:
      return getRootValueForPhoneNumber(value as PhoneNumber);
    default:
      return null;
  }
};

export const getSubFieldsAsDataFields = (
  field: DataField,
  {
    includeRoot,
    treeDisplay,
  }: { includeRoot?: boolean; treeDisplay?: boolean } = {},
): DataTypeFields => {
  const baseSubFields = Object.entries(
    SUB_FIELD_CONFIG[field.typeOptions?.format as string],
  ).map(([subFieldName, subField]) => {
    const display = getText(
      'data.types',
      OBJECT,
      field.typeOptions?.format as string,
      subFieldName,
      'label',
    );

    return {
      id: `${field.typeOptions?.format}.${subFieldName}` as any,
      apiName: subFieldName,
      display: treeDisplay ? display : `${field.display} > ${display}`,
      name: subFieldName,
      options: [],
      ...subField,
    } as DataField;
  });

  if (
    includeRoot &&
    FORMATS_WITH_ROOT.includes(field.typeOptions?.format as FieldFormat)
  ) {
    baseSubFields.unshift({
      id: `${field.typeOptions?.format}._root` as any,
      apiName: '_root',
      display: field.display,
      name: '_root',
      type: TEXT,
    } as DataField);
  }

  if (field.typeOptions?.format === FULL_NAME) {
    return new DataTypeFields(
      baseSubFields.reduce((acc: DataField[], baseSubField) => {
        if (baseSubField.name === 'title') {
          if (field.typeOptions?.subFields?.title) {
            return [
              ...acc,
              {
                ...baseSubField,
                options: get(
                  field,
                  ['typeOptions', 'subFields', 'title', 'options'],
                  [],
                )
                  .filter(({ display }: BaseDataFieldOption) => !!display)
                  .map(({ display }: BaseDataFieldOption) => ({
                    display,
                    name: display,
                  })),
              } as DataField,
            ];
          } else {
            return acc;
          }
        }

        if (baseSubField.name === 'middle') {
          if (field.typeOptions?.subFields?.middle) {
            return [...acc, baseSubField];
          } else {
            return acc;
          }
        }

        return [...acc, baseSubField];
      }, []),
    );
  }

  if (field.typeOptions?.format === PHONE_NUMBER) {
    return new DataTypeFields(
      baseSubFields.filter(({ name }) => name !== 'extension'),
    );
  }

  return new DataTypeFields(baseSubFields);
};

export const isEmpty = (field: DataField, value: any): boolean => {
  if (isNil(value)) {
    return true;
  }

  switch (field.typeOptions?.format) {
    case COORDINATES:
      return isNil(value.latitude) || isNil(value.longitude);
    case DATE_RANGE:
      return isNil(value.from) || isNil(value.to);
    case DUE_DATE:
      return isNil(value.to);
    case PHONE_NUMBER:
      return isNil(value.number) || value.number === '';
    default:
      return Object.values(value).filter(Boolean).length === 0;
  }
};
