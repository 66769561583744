import { createSlice } from '@reduxjs/toolkit';

const querySlice = createSlice({
  name: 'queries',
  initialState: {} as Record<string, any>,
  reducers: {
    setQuery: (state, { payload }) => {
      state[payload.id] = payload;
    },
  },
});

export const { setQuery } = querySlice.actions;

export default querySlice.reducer;
