import createReactComponent from '../createReactComponent';
export default createReactComponent('mail-question', 'IconMailQuestion', [
  [
    'path',
    {
      d: 'M15 19h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 22v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 7l9 6l9 -6', key: 'svg-3' }],
]);
