import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'creative-commons-nc',
  'IconCreativeCommonsNc',
  [
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M15 9h-4.5a1.5 1.5 0 0 0 0 3h3a1.5 1.5 0 0 1 0 3h-4.5',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M12 7v2', key: 'svg-2' }],
    ['path', { d: 'M12 15v2', key: 'svg-3' }],
    ['path', { d: 'M6 6l3 3', key: 'svg-4' }],
    ['path', { d: 'M15 15l3 3', key: 'svg-5' }],
  ],
);
