import { useCallback, useMemo, useState } from 'react';
import { IconGitFork, IconTrash } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import shortid from 'shortid';
import { DataType } from '../../../models/DataTypes';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import BuildModeOrFilterBranchInput from './BuildModeOrFilterBranchInput';

type BuildModeOrFilterInputProps = {
  additionalScopeItems: any[];
  elementPath: ElementPath;
  generateAdditionalFilter: (overrides?: any) => {
    id: string;
    field: null;
    operator: null;
    result: null;
  };
  project: Project;
  onChange: (value: any) => void;
  onRemove: () => void;
  dataType: DataType;
  value: any;
};

const BuildModeOrFilterInput = ({
  additionalScopeItems,
  elementPath,
  generateAdditionalFilter,
  project,
  onChange,
  onRemove,
  dataType,
  value,
}: BuildModeOrFilterInputProps) => {
  const [localValue, setLocalValue] = useState(value);
  const handleOrBranchAdded = useCallback(() => {
    const branches = [
      ...localValue.branches,
      {
        filters: [generateAdditionalFilter()],
        id: shortid.generate(),
      },
    ];
    const newLocalValue = {
      ...localValue,
      branches,
    };

    setLocalValue(newLocalValue);
    onChange(newLocalValue);
    return newLocalValue;
  }, [generateAdditionalFilter, localValue, onChange]);

  const branches = useMemo(() => get(localValue, 'branches', []), [localValue]);

  return (
    <div className="flex w-full flex-col items-center rounded-lg bg-slate-700">
      <div
        className={classNames(
          'mr-2 flex w-full items-center justify-end space-x-2',
          { 'h-8': branches.length === 0, 'mt-2': branches.length > 0 },
        )}
      >
        <div className="cursor-pointer rounded-lg p-1 hover:bg-slate-500">
          <IconGitFork onClick={handleOrBranchAdded} size={12} />
        </div>
        <div className="cursor-pointer rounded-lg p-1 hover:bg-slate-500 hover:text-red-300">
          <IconTrash onClick={onRemove} size={12} />
        </div>
      </div>
      <div
        className={classNames({
          'relative w-full rounded-lg p-2': branches.length > 0,
        })}
      >
        {branches.map((branch: any, branchIndex: any) => (
          <BuildModeOrFilterBranchInput
            additionalScopeItems={additionalScopeItems}
            branch={branch}
            dataType={dataType}
            elementPath={elementPath}
            generateAdditionalFilter={generateAdditionalFilter}
            index={branchIndex}
            key={branch.id}
            onChange={onChange}
            project={project}
            setValue={setLocalValue}
            value={localValue}
          />
        ))}
      </div>
    </div>
  );
};

BuildModeOrFilterInput.defaultProps = {
  additionalScopeItems: [],
};

export default BuildModeOrFilterInput;
