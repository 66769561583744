import createReactComponent from '../createReactComponent';
export default createReactComponent('milkshake', 'IconMilkshake', [
  ['path', { d: 'M17 10a5 5 0 0 0 -10 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 10m0 1a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M7 13l1.81 7.243a1 1 0 0 0 .97 .757h4.44a1 1 0 0 0 .97 -.757l1.81 -7.243',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M12 5v-2', key: 'svg-3' }],
]);
