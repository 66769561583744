import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-ipad-horizontal-off',
  'IconDeviceIpadHorizontalOff',
  [
    [
      'path',
      {
        d: 'M8 4h12a2 2 0 0 1 2 2v12m-2 2h-16a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 17h6', key: 'svg-1' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
  ],
);
