import createReactComponent from '../createReactComponent';
export default createReactComponent('clipboard-copy', 'IconClipboardCopy', [
  [
    'path',
    {
      d: 'M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h3m9 -9v-5a2 2 0 0 0 -2 -2h-2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M13 17v-1a1 1 0 0 1 1 -1h1m3 0h1a1 1 0 0 1 1 1v1m0 3v1a1 1 0 0 1 -1 1h-1m-3 0h-1a1 1 0 0 1 -1 -1v-1',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
      key: 'svg-2',
    },
  ],
]);
