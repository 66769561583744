import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  IconHistory,
  IconHistoryToggle,
  IconInfoCircle,
  IconPlaneDeparture,
} from '@tabler/icons-react';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  Loader,
  Modal,
  Popover,
  TextArea,
  Tooltip,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { darkModeColors } from '@noloco/core/src/constants/darkModeColors';
import { APP_VERSIONS } from '@noloco/core/src/constants/features';
import { ESCAPE } from '@noloco/core/src/constants/shortcuts';
import { BaseUser } from '@noloco/core/src/models/User';
import { PROJECT_HAS_UNPUBLISHED_CHANGES_QUERY } from '@noloco/core/src/queries/core';
import {
  setHasUnpublishedChanges,
  setProject,
  setPublishedVersion,
} from '@noloco/core/src/reducers/project';
import { dataTypesSelector } from '@noloco/core/src/selectors/projectSelectors';
import {
  PREVIOUS_VERSION_PUBLISHED,
  PUBLISH_CLICKED,
  REVERTED_EDITOR_TO_PREVIOUS_VERSION,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import {
  useGraphQlErrorAlert,
  useInfoAlert,
} from '@noloco/core/src/utils/hooks/useAlerts';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import useDarkMode from '@noloco/core/src/utils/hooks/useDarkMode';
import useOnKeyPress from '@noloco/core/src/utils/hooks/useOnKeyPress';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import {
  getBaseProjectDomain,
  getProjectDomain,
} from '@noloco/core/src/utils/pages';
import { getFullName } from '@noloco/core/src/utils/user';
import {
  PROJECT_QUERY,
  PROJECT_VERSION_HISTORY_QUERY,
  PUBLISH_PROJECT,
  PUBLISH_PROJECT_BY_VERSION,
  REVERT_EDITOR_TO_PROJECT_VERSION,
} from '../queries/project';
import FeatureLockedButton from './FeatureLockedButton';
import Guide from './Guide';

type PublishedByFragment = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
};

type VersionHistoryEntryType = {
  publishedAt: string;
  version: number;
  publishedBy: PublishedByFragment | null;
  publishMessage: string | null;
};

const VersionHistoryEntryList = ({
  projectName,
  currentPublishedProjectVersion,
  closeAll,
}: {
  projectName: string;
  currentPublishedProjectVersion: number;
  closeAll: () => void;
}) => {
  const { loading: versionHistoryLoading, data: versionHistoryEntries } =
    useCacheQuery(PROJECT_VERSION_HISTORY_QUERY, {
      variables: { projectName },
    });

  return !versionHistoryLoading ? (
    <div className="overflow-auto">
      <Guide href="https://guides.noloco.io/settings/publishing/app-version-history">
        {getText('toolbar.publish.versionHistory.guideLinkText')}
      </Guide>
      {versionHistoryEntries?.projectVersionHistory?.length === 0
        ? getText('toolbar.publish.versionHistory.emptyState')
        : versionHistoryEntries?.projectVersionHistory?.map(
            (vhed: VersionHistoryEntryType) => (
              <VersionHistoryEntry
                key={vhed.version}
                versionHistoryEntryData={vhed}
                projectName={projectName}
                currentPublishedVersion={currentPublishedProjectVersion}
                closeAll={closeAll}
              />
            ),
          )}
    </div>
  ) : (
    <div className="flex justify-center">
      <Loader size="lg" />
    </div>
  );
};

const VersionHistoryEntry = ({
  versionHistoryEntryData,
  projectName,
  currentPublishedVersion,
  closeAll,
}: {
  versionHistoryEntryData: VersionHistoryEntryType;
  projectName: string;
  currentPublishedVersion: number;
  closeAll: () => void;
}) => {
  const [isDarkModeEnabled] = useDarkMode();
  const dispatch = useDispatch();
  const publishedDate = DateTime.fromISO(versionHistoryEntryData.publishedAt);
  const relativeCalendar = publishedDate.toRelativeCalendar();
  const relativeTimeDisplay =
    relativeCalendar === 'today'
      ? `${publishedDate.toLocaleString(DateTime.TIME_24_SIMPLE)}`
      : relativeCalendar;

  const [publishProjectByVersion] = useMutation(PUBLISH_PROJECT_BY_VERSION);

  const [revertEditorToProjectVersion] = useMutation(
    REVERT_EDITOR_TO_PROJECT_VERSION,
  );

  const infoAlert = useInfoAlert();

  const [isPublishPreviousVersionLoading, setIsPublishPreviousVersionLoading] =
    useState(false);

  const [
    isRevertEditorToPreviousVersionLoading,
    setIsRevertEditorToPreviousVersionLoading,
  ] = useState(false);

  const [fetchRevertedProject] = usePromiseQuery(PROJECT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      projectId: projectName,
    },
  });

  const dataTypes = useSelector(dataTypesSelector);

  const hasPublishMessage = versionHistoryEntryData?.publishMessage !== null;

  const publishMessageDisplay = hasPublishMessage
    ? versionHistoryEntryData.publishMessage
    : getText(
        {
          versionNumber: versionHistoryEntryData.version,
        },
        'toolbar.publish.versionHistory.defaultPublishMessage',
      );

  const isCurrentPublishedVersion =
    versionHistoryEntryData.version === currentPublishedVersion;

  const publishVersionOnClick = useCallback(async () => {
    setIsPublishPreviousVersionLoading(true);
    await publishProjectByVersion({
      variables: {
        name: projectName,
        targetVersion: versionHistoryEntryData.version,
      },
    });
    dispatch(setPublishedVersion(versionHistoryEntryData.version));
    trackEvent(PREVIOUS_VERSION_PUBLISHED);
    setIsPublishPreviousVersionLoading(false);
    infoAlert(
      getText(
        { versionNumber: versionHistoryEntryData.version },
        'toolbar.publish.versionHistory.publishedVersionAlert',
      ),
    );
    closeAll();
  }, [
    closeAll,
    dispatch,
    infoAlert,
    projectName,
    publishProjectByVersion,
    versionHistoryEntryData.version,
  ]);

  const revertEditorOnClick = useCallback(async () => {
    setIsRevertEditorToPreviousVersionLoading(true);
    await revertEditorToProjectVersion({
      variables: {
        name: projectName,
        targetVersion: versionHistoryEntryData.version,
      },
    });
    fetchRevertedProject().then(({ data: revertedProjectData }) => {
      const newProject = revertedProjectData.project;
      newProject.dataTypes = dataTypes;
      dispatch(setProject(newProject));
    });
    trackEvent(REVERTED_EDITOR_TO_PREVIOUS_VERSION);
    setIsRevertEditorToPreviousVersionLoading(false);
    infoAlert(
      getText(
        { versionNumber: versionHistoryEntryData.version },
        'toolbar.publish.versionHistory.revertedEditorToVersionAlert',
      ),
    );
    closeAll();
  }, [
    closeAll,
    dataTypes,
    dispatch,
    fetchRevertedProject,
    infoAlert,
    projectName,
    revertEditorToProjectVersion,
    versionHistoryEntryData.version,
  ]);

  return (
    <div
      className={`mt-4 flex flex-col rounded-lg border-2 p-4 ${
        isDarkModeEnabled ? darkModeColors.borders.one : 'border-slate-300'
      }`}
    >
      <div className="flex w-full justify-between pb-3">
        <span
          className={`font-medium ${
            isDarkModeEnabled ? darkModeColors.text.primary : 'text-slate-700'
          }`}
        >
          {publishMessageDisplay}
        </span>
        <Popover
          placement="bottom-end"
          trigger="click"
          className={`overflow-hidden bg-white`}
          p={0}
          rounded="lg"
          shadow="lg"
          showArrow={false}
          closeOnClick={false}
          content={
            <div className="flex flex-col p-4">
              <button
                className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
                onClick={publishVersionOnClick}
              >
                <div className="flex items-center">
                  {isPublishPreviousVersionLoading ? (
                    <Loader size="sm" />
                  ) : (
                    <IconPlaneDeparture size={16} />
                  )}
                  <span className="ml-2">
                    {getText(
                      'toolbar.publish.versionHistory.publishThisVersion',
                    )}
                  </span>
                </div>
              </button>
              <button
                className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
                onClick={revertEditorOnClick}
              >
                <div className="flex items-center">
                  {isRevertEditorToPreviousVersionLoading ? (
                    <Loader type="bars" size="sm" />
                  ) : (
                    <IconHistory size={16} />
                  )}
                  <span className="ml-2">
                    {getText(
                      'toolbar.publish.versionHistory.revertEditorToThisVersion',
                    )}
                  </span>
                </div>
              </button>
            </div>
          }
        >
          <span>...</span>
        </Popover>
      </div>
      <div className="flex w-full justify-between">
        <span className="text-sm">
          #{versionHistoryEntryData.version}
          <span className="ml-2">
            {versionHistoryEntryData.publishedBy
              ? getFullName(versionHistoryEntryData.publishedBy as BaseUser)
              : ''}
          </span>
        </span>
        <Tooltip
          content={
            <span
              className={`${
                isDarkModeEnabled ? darkModeColors.text.primary : ''
              }`}
            >
              {publishedDate.toLocaleString(DateTime.DATETIME_MED)}
            </span>
          }
        >
          <div className="flex">
            {isCurrentPublishedVersion ? (
              <Badge className="mr-2 mt-0.5" color="green">
                <span className="uppercase">
                  {getText(
                    'toolbar.publish.versionHistory.isCurrentVersionBadge',
                  )}
                </span>
              </Badge>
            ) : null}
            <span className={`text-sm ${isDarkModeEnabled}`}>
              {relativeTimeDisplay}
            </span>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const PublishButton = ({
  hasUnpublishedChanges,
  projectName,
  currentPublishedProjectVersion,
  domains,
  publishedVersion,
}: any) => {
  const {
    query: { __publish },
    replaceQueryParams,
  } = useRouter();
  const dispatch = useDispatch();
  const errorAlert = useGraphQlErrorAlert();
  const [publishProject] = useMutation(PUBLISH_PROJECT);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false || __publish === 'true');
  const [versionHistoryModalIsOpen, setVersionHistoryModalIsOpen] =
    useState(false);
  const [isPublishComplete, setIsPublishComplete] = useState(false);

  const { data: projectData } = useQuery(
    gql`
      ${PROJECT_HAS_UNPUBLISHED_CHANGES_QUERY}
    `,
    {
      variables: {
        projectId: projectName,
      },
      skip: hasUnpublishedChanges !== null,
    },
  );

  useMemo(() => {
    if (
      hasUnpublishedChanges === null &&
      projectData?.project?.hasUnpublishedChanges !== undefined
    ) {
      dispatch(
        setHasUnpublishedChanges(projectData.project.hasUnpublishedChanges),
      );
    }
  }, [dispatch, hasUnpublishedChanges, projectData]);

  const [publishMessage, setPublishMessage] = useState('');

  const onPublishClick = useCallback(
    (finalPublishMessage: string | null = null) => {
      trackEvent(PUBLISH_CLICKED);
      setIsLoading(true);
      const variables: Record<any, any> = {
        name: projectName,
      };

      if (finalPublishMessage) {
        variables.publishMessage = finalPublishMessage;
      }

      publishProject({
        variables,
      })
        .then((result) => {
          setIsOpen(false);
          setIsLoading(false);
          setIsPublishComplete(true);
          dispatch(setHasUnpublishedChanges(false));
          dispatch(
            setPublishedVersion(result?.data?.publishProject?.publishedVersion),
          );
        })
        .catch((error) => {
          console.error(error);
          errorAlert(getText('toolbar.publish.error'), error);
          setIsLoading(false);
        });
    },
    [publishProject, projectName, dispatch, errorAlert],
  );

  const onOpenChange = useCallback(
    (nextIsOpen) => {
      if (!nextIsOpen && __publish) {
        replaceQueryParams({ __publish: undefined });
      }
      setIsOpen(nextIsOpen);
    },
    [__publish, replaceQueryParams],
  );

  useEffect(() => {
    if (__publish === 'true' && !isOpen) {
      setIsOpen(true);
    }
  }, [__publish, isOpen]);

  const nolocoDomain = getProjectDomain(projectName);

  const closeAll = () => {
    setVersionHistoryModalIsOpen(false);
    setIsOpen(false);
  };

  useOnKeyPress(ESCAPE, () => onOpenChange(false), { enabled: isOpen });

  return (
    <>
      <Tooltip
        disabled={isOpen}
        content={
          <div className="max-64 flex flex-col">
            <div className="flex items-center space-x-2">
              <span>{getText('leftSidebar.nav.publish')}</span>
            </div>
            {hasUnpublishedChanges && (
              <span className="mt-2 flex items-center">
                <div className="mr-2 h-2 w-2 flex-shrink-0 rounded-full bg-pink-400" />
                <span>{getText('toolbar.publish.unpublishedChanges')}</span>
              </span>
            )}
          </div>
        }
        placement="right"
        bg="white"
      >
        <div className="flex w-full items-center justify-center">
          <Popover
            trigger={null}
            isOpen={isOpen}
            placement="right"
            surface={LIGHT}
            closeOnOutsideClick={!versionHistoryModalIsOpen}
            onOpenChange={onOpenChange}
            bg="white"
            content={
              <div className="flex w-screen max-w-sm flex-col p-2 text-slate-800">
                <p className="text-sm">{getText('toolbar.publish.explain')}</p>
                <label className="mt-4 text-sm uppercase">
                  {getText('toolbar.publish.domains')}
                </label>
                <div className="my-2 h-px w-full bg-slate-800" />
                <a
                  href={nolocoDomain}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-base font-medium"
                >
                  {projectName}.{getBaseProjectDomain()}
                </a>
                {publishedVersion && (
                  <span className="text-sm text-slate-700">
                    {getText('toolbar.publish.published')}
                  </span>
                )}
                {!publishedVersion && (
                  <span className="text-sm text-slate-700">
                    {getText('toolbar.publish.notPublished')}
                  </span>
                )}
                <div className="my-2 h-px w-full bg-slate-800" />
                {domains.length === 0 && (
                  <>
                    <span className="w-80 text-base font-medium">
                      {getText('toolbar.publish.customDomain')}
                    </span>
                    <Link
                      to={`/_/settings/domain`}
                      onClick={() => setIsOpen(false)}
                      className="hover:teal-teal-600 text-sm text-teal-500"
                    >
                      {getText('toolbar.publish.connectDomain')}
                    </Link>
                    <div className="my-2 h-px w-full bg-slate-800" />
                  </>
                )}
                {domains.map((domain: any) => (
                  <React.Fragment key={domain.name}>
                    <a
                      href={`https://${domain.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-lg font-medium"
                    >
                      {domain.name}
                    </a>
                    <span className="text-sm text-slate-300">
                      {getText('toolbar.publish.customDomain')}
                    </span>
                    <div className="my-2 h-px w-80 bg-slate-800" />
                  </React.Fragment>
                ))}
                <div className="pt-2">
                  <div className="flex pb-2">
                    <label className="mt-4 text-sm uppercase">
                      {getText('toolbar.publish.publishMessage.label')}
                    </label>
                    <Tooltip
                      delayShow={400}
                      content={
                        <span className="text-sm">
                          {getText('toolbar.publish.publishMessage.explain')}
                        </span>
                      }
                      placement="right"
                    >
                      <span className="pl-1 pt-4">
                        <IconInfoCircle size={16} className="text-slate-500" />
                      </span>
                    </Tooltip>
                  </div>
                  <TextArea
                    bg="bg-white"
                    placeholder={getText(
                      'toolbar.publish.publishMessage.placeholder',
                    )}
                    onChange={({ target: { value } }: any) =>
                      setPublishMessage(value)
                    }
                    className="text-gray-900"
                    surface={LIGHT}
                    rows={4}
                  />
                  <div className="flex">
                    <FeatureLockedButton
                      type="link"
                      feature={APP_VERSIONS}
                      onClick={() => setVersionHistoryModalIsOpen(true)}
                      iconClassName="text-slate-500"
                    >
                      {getText('toolbar.publish.versionHistory.linkText')}
                    </FeatureLockedButton>
                    <Button
                      className="ml-auto mt-2 flex"
                      disabled={isLoading || isPublishComplete}
                      variant="primary"
                      onClick={() => onPublishClick(publishMessage)}
                    >
                      {!isLoading ? (
                        <div className="flex">
                          <IconPlaneDeparture size={16} />
                          <span className="ml-2">
                            {getText('toolbar.publish.button')}
                          </span>
                        </div>
                      ) : (
                        <Loader size="sm" />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            }
          >
            <button
              onClick={() => {
                onOpenChange(!isOpen);
              }}
              className="relative flex h-14 cursor-pointer items-center justify-center bg-slate-900 px-3 py-2 text-slate-500 hover:text-slate-200"
              data-tour="publish"
              data-test="admin-nav-publish"
            >
              <div className="mx-auto flex">
                <IconPlaneDeparture size={20} />
              </div>
              {hasUnpublishedChanges && (
                <div className="absolute right-1 top-1 h-2 w-2 rounded-full bg-pink-400" />
              )}
            </button>
          </Popover>
        </div>
      </Tooltip>
      {isPublishComplete && (
        <Modal
          onClose={() => setIsPublishComplete(false)}
          onConfirm={() => setIsPublishComplete(false)}
          icon={<IconPlaneDeparture size={20} />}
          title={getText('toolbar.publish.congrats')}
          confirmText={getText('toolbar.publish.ok')}
          variant="primary"
        >
          <p>{getText('toolbar.publish.success')}</p>
        </Modal>
      )}
      {versionHistoryModalIsOpen && (
        <Modal
          title={getText('toolbar.publish.versionHistory.listTitle')}
          icon={<IconHistoryToggle />}
          canCancel={false}
          canConfirm={true}
          closeOnOutsideClick={true}
          confirmText={getText(
            'toolbar.publish.versionHistory.modalConfirmButtonText',
          )}
          onConfirm={() => closeAll()}
        >
          <VersionHistoryEntryList
            projectName={projectName}
            currentPublishedProjectVersion={currentPublishedProjectVersion}
            closeAll={closeAll}
          />
        </Modal>
      )}
    </>
  );
};

PublishButton.defaultProps = {
  domains: [],
};

export default PublishButton;
