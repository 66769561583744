import React, { useMemo } from 'react';
import { SelectInput } from '@noloco/components';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { isValidPrimaryFieldType } from '@noloco/core/src/utils/primaryFields';
import DataFieldIcon from '../../DataFieldIcon';

const RecordTitleEditor = ({
  dataType,
  onChange,
  value,
}: {
  dataType: DataType;
  onChange: (fieldName: string) => void;
  value: string | undefined;
}) => {
  const titleOptions = useMemo(
    () =>
      dataType.fields
        .filter((field) => isValidPrimaryFieldType(field))
        .map((field: any) => ({
          value: field.name,

          label: (
            <div className="flex items-center">
              <DataFieldIcon
                size={16}
                field={field}
                className="mr-2 opacity-75"
              />
              <span>{field.display}</span>
            </div>
          ),
        })),
    [dataType.fields],
  );

  return (
    <SelectInput
      className="w-full text-black"
      contained={true}
      value={value}
      options={titleOptions}
      onChange={onChange}
      usePortal={false}
    />
  );
};

export default RecordTitleEditor;
