import React, { memo, useCallback, useMemo } from 'react';
import { IconAlertTriangle, IconX } from '@tabler/icons-react';
import first from 'lodash/first';
import { Popover } from '@noloco/components';
import { FIELD_LEVEL_PERMISSIONS } from '@noloco/core/src/constants/features';
import { DepValue, StringPropSegment } from '@noloco/core/src/models/Element';
import StateItem from '@noloco/core/src/models/StateItem';
import { User } from '@noloco/core/src/models/User';
import useAuthWrapper from '@noloco/core/src/utils/hooks/useAuthWrapper';
import { getText } from '@noloco/core/src/utils/lang';
import { nestedFieldPermissions } from '@noloco/core/src/utils/permissions';
import { DataItemOption, flattenStateItem } from '@noloco/core/src/utils/state';
import useIsFeatureEnabled from '../../../utils/hooks/useIsFeatureEnabled';
import ContentDisplayName, {
  DisplayWrapper,
  findOption,
} from '../ContentDisplayName';
import DynamicValuePopoverBody from './DynamicValuePopoverBody';

type DynamicDropdownInputProps = {
  checkFieldPermissions: boolean;
  dataOptions: DataItemOption[];
  disabled?: boolean;
  isOpen: boolean;
  placeholder: any;
  onChange: (value: StringPropSegment[] | null) => void;
  onClose: () => void;
  optionTextValue: string | null;
  multiple: boolean;
  setDynamic: (dynamic: boolean) => void;
  value: StringPropSegment[] | undefined;
};

const DynamicDropdownInput = memo(
  ({
    checkFieldPermissions,
    dataOptions,
    disabled,
    placeholder,
    onChange,
    isOpen,
    onClose,
    optionTextValue,
    multiple,
    setDynamic,
    value,
  }: DynamicDropdownInputProps) => {
    const { user } = useAuthWrapper();
    const fieldPermissionsEnabled = useIsFeatureEnabled(
      FIELD_LEVEL_PERMISSIONS,
    );

    const permissionValidationErrors = useMemo(() => {
      if (
        checkFieldPermissions &&
        fieldPermissionsEnabled &&
        value &&
        Array.isArray(value)
      ) {
        const errors = value
          .filter((dataItem) => dataItem.data)
          .map(({ data }) => {
            const flattened = flattenStateItem(data as DepValue);
            const { option } = findOption(dataOptions, flattened);
            if (option && option.field && option.dataType) {
              const { read } = nestedFieldPermissions(
                option.field,
                option.dataType,
                undefined,
                undefined,
                user as User,
                fieldPermissionsEnabled,
              );

              if (!read) {
                return getText(
                  { field: option.field.display },
                  'fields.permissionsWarning.fieldReadWarning',
                );
              }
            }
            return undefined;
          })
          .filter(Boolean);

        if (errors.length > 0) {
          return errors;
        }
      }

      return undefined;
    }, [
      checkFieldPermissions,
      dataOptions,
      fieldPermissionsEnabled,
      user,
      value,
    ]);

    const selectValue: StringPropSegment[] | null = useMemo(() => {
      if (multiple) {
        // If a legacy text option exists and contains more than just commas
        if (optionTextValue && /[a-zA-Z0-9]/.test(optionTextValue)) {
          return [{ text: optionTextValue }];
        }

        if (value) {
          return value.filter((stateItem: any) => stateItem.data);
        }
        return [];
      }

      if (optionTextValue) {
        return [{ text: optionTextValue }];
      }

      if (value && value.length > 0) {
        const stateItem = first(value)?.data;
        if (stateItem) {
          return [{ data: stateItem }];
        }
      }

      return null;
    }, [multiple, optionTextValue, value]);

    const removeOption = useCallback(
      (option: StringPropSegment) => {
        if (value) {
          const flattenedOption = flattenStateItem(option.data!);
          const optionIndex = value?.findIndex(
            (valueOption) =>
              valueOption.data &&
              flattenStateItem(valueOption.data) === flattenedOption,
          );

          if (optionIndex >= 0) {
            const nextValue = [...value];
            nextValue.splice(optionIndex, 2); // remove the value and the following comma
            onChange(nextValue);
          }
        }
      },
      [onChange, value],
    );

    const onRawSelectChange = useCallback(
      (newValue: StateItem | null) => {
        if (multiple && newValue) {
          if (!value) {
            onChange([{ data: newValue }]);
          } else {
            const existingValues = value.filter((valueItem) => valueItem.data);

            const nextValue = existingValues.reduce((acc, stateItem) => {
              acc.push(stateItem);
              acc.push({ text: ',' });
              return acc;
            }, [] as StringPropSegment[]);

            nextValue.push({ data: { id: newValue.id, path: newValue.path } });
            onChange(nextValue);
          }
        } else if (newValue) {
          onChange([{ data: { id: newValue.id, path: newValue.path } }]);
        } else {
          onChange(null);
        }
      },
      [multiple, onChange, value],
    );

    return (
      <Popover
        p={0}
        disabled={disabled}
        placement="top-start"
        showArrow={false}
        trigger={isOpen ? 'none' : undefined}
        isOpen={isOpen ? true : undefined}
        onOpenChange={isOpen ? onClose : undefined}
        surface="dark"
        content={
          <DynamicValuePopoverBody
            options={dataOptions}
            onSelect={onRawSelectChange}
            setDynamic={setDynamic}
            value={selectValue}
          />
        }
      >
        <div className="flex w-full flex-col">
          <div className="flex h-8 w-full items-center rounded-lg bg-slate-700 px-1 py-1 pl-2 text-white">
            {selectValue && multiple && selectValue.length > 0 && (
              <div className="flex flex-wrap items-center gap-0.5">
                {selectValue.map((option) => (
                  <DisplayWrapper
                    displayAsToken={true}
                    key={flattenStateItem(option.data!)}
                  >
                    <div className="group flex items-center space-x-2">
                      <ContentDisplayName
                        checkFieldPermissions={true}
                        compact={false}
                        dataOptions={dataOptions}
                        data={option.data!}
                      />
                      <button onClick={() => removeOption(option)}>
                        <IconX
                          className="flex-shrink-0 opacity-50 group-hover:opacity-100"
                          size={14}
                        />
                      </button>
                    </div>
                  </DisplayWrapper>
                ))}
              </div>
            )}
            {selectValue && !multiple && selectValue.length > 0 && (
              <div className="flex flex-wrap items-center py-1">
                <ContentDisplayName
                  checkFieldPermissions={true}
                  compact={false}
                  dataOptions={dataOptions}
                  data={selectValue[0].data!}
                />
              </div>
            )}
            {(!selectValue || selectValue.length === 0) && (
              <div className="text-sm text-gray-400">
                {placeholder || <span>&nbsp;</span>}
              </div>
            )}
          </div>
          {permissionValidationErrors && (
            <div className="flex space-x-2">
              <IconAlertTriangle
                size={24}
                className="flex-shrink-0 text-red-500"
              />
              <div className="flex flex-col space-y-1">
                {permissionValidationErrors.map(
                  (permissionValidationErrors) => (
                    <span key={permissionValidationErrors}>
                      {permissionValidationErrors}
                    </span>
                  ),
                )}
              </div>
            </div>
          )}
        </div>
      </Popover>
    );
  },
);

export default DynamicDropdownInput;
