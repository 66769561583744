import createReactComponent from '../createReactComponent';
export default createReactComponent('mail-filled', 'IconMailFilled', [
  [
    'path',
    {
      d: 'M22 7.535v9.465a3 3 0 0 1 -2.824 2.995l-.176 .005h-14a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-9.465l9.445 6.297l.116 .066a1 1 0 0 0 .878 0l.116 -.066l9.445 -6.297z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M19 4c1.08 0 2.027 .57 2.555 1.427l-9.555 6.37l-9.555 -6.37a2.999 2.999 0 0 1 2.354 -1.42l.201 -.007h14z',
      fill: 'currentColor',
      key: 'svg-1',
      strokeWidth: '0',
    },
  ],
]);
