import createReactComponent from '../createReactComponent';
export default createReactComponent('container-off', 'IconContainerOff', [
  ['path', { d: 'M20 4v.01', key: 'svg-0' }],
  ['path', { d: 'M20 20v.01', key: 'svg-1' }],
  ['path', { d: 'M20 16v.01', key: 'svg-2' }],
  ['path', { d: 'M20 12v.01', key: 'svg-3' }],
  ['path', { d: 'M20 8v.01', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M8.297 4.289a1 1 0 0 1 .703 -.289h6a1 1 0 0 1 1 1v7m0 4v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1v-11',
      key: 'svg-5',
    },
  ],
  ['path', { d: 'M4 4v.01', key: 'svg-6' }],
  ['path', { d: 'M4 20v.01', key: 'svg-7' }],
  ['path', { d: 'M4 16v.01', key: 'svg-8' }],
  ['path', { d: 'M4 12v.01', key: 'svg-9' }],
  ['path', { d: 'M4 8v.01', key: 'svg-10' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-11' }],
]);
