import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'currency-litecoin',
  'IconCurrencyLitecoin',
  [
    [
      'path',
      { d: 'M18 19h-8.194a2 2 0 0 1 -1.98 -2.283l1.674 -11.717', key: 'svg-0' },
    ],
    ['path', { d: 'M14 9l-9 4', key: 'svg-1' }],
  ],
);
