import React from 'react';
import { IconSquare, Icon as IconType } from '@tabler/icons-react';
import BaseElementConfig, { BaseElementConfigArgs } from './BaseElementConfig';

type ComponentType =
  | React.ComponentType
  | React.ForwardRefExoticComponent<any>
  | null;

type ElementConfigAdditions = {
  component?: ComponentType;
  Icon?: IconType;
};

class ElementConfig extends BaseElementConfig {
  component?: ComponentType;
  Icon: IconType;

  constructor({
    component = null,
    Icon = IconSquare,
    ...baseConfig
  }: BaseElementConfigArgs & ElementConfigAdditions = {}) {
    super(baseConfig);
    this.component = component;
    this.Icon = Icon;
  }

  static fromBaseConfig(
    baseConfig: BaseElementConfig,
    additions: ElementConfigAdditions = {},
  ) {
    return new ElementConfig({
      ...baseConfig.elementConfigArgs,
      ...additions,
    });
  }
}

export default ElementConfig;
