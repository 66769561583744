import createReactComponent from '../createReactComponent';
export default createReactComponent('html', 'IconHtml', [
  ['path', { d: 'M13 16v-8l2 5l2 -5v8', key: 'svg-0' }],
  ['path', { d: 'M1 16v-8', key: 'svg-1' }],
  ['path', { d: 'M5 8v8', key: 'svg-2' }],
  ['path', { d: 'M1 12h4', key: 'svg-3' }],
  ['path', { d: 'M7 8h4', key: 'svg-4' }],
  ['path', { d: 'M9 8v8', key: 'svg-5' }],
  ['path', { d: 'M20 8v8h3', key: 'svg-6' }],
]);
