import React, { useCallback } from 'react';
import FormFieldsEditor from '@noloco/ui/src/components/editor/customerEditors/FormFieldsEditor';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { FormFieldConfig } from '../../../models/View';

type BuildModeFormFieldsTabProps = {
  dataType: DataType;
  elementPath: ElementPath;
  fields: FormFieldConfig[];
  project: Project;
  updateProperty: (elementPath: ElementPath, value: any) => void;
};

const valueFieldFilter = (field: DataField) => !field.readOnly;

const BuildModeFormFieldsTab = ({
  dataType,
  elementPath,
  fields,
  project,
  updateProperty,
}: BuildModeFormFieldsTabProps) => {
  const onFieldsChange = useCallback(
    (path, value) => updateProperty(['fields', ...path], value),
    [updateProperty],
  );

  return (
    <FormFieldsEditor
      dataType={dataType}
      elementPath={elementPath}
      formFields={fields}
      onFieldsChange={onFieldsChange}
      permissionType="create"
      project={project}
      sticky={true}
      valueFieldFilter={valueFieldFilter}
    />
  );
};

export default BuildModeFormFieldsTab;
