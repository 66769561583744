import createReactComponent from '../createReactComponent';
export default createReactComponent('truck-delivery', 'IconTruckDelivery', [
  ['path', { d: 'M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 9l4 0', key: 'svg-3' }],
]);
