import React, { useCallback } from 'react';
import { IconUnlink } from '@tabler/icons-react';
import { Button, Loader } from '@noloco/components';
import { Variant } from '@noloco/components/src/constants/variants';
import { useHasPermissionToUpdateRelatedRecordCollection } from '../../../utils/hooks/useHasPermissionToUpdateRelatedRecordCollection';
import { useUpdateRelatedRecordCollection } from '../../../utils/hooks/useUpdateRelatedRecordCollection';
import { getText } from '../../../utils/lang';
import { RECORD_SCOPE } from '../../../utils/scope';

const BASE_LANG_KEY = 'data.unlinkRelatedRecord';

type OwnProps = {
  buttonType?: 'text';
  variant?: Variant;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof RemoveRelatedRecordButton.defaultProps;

// @ts-expect-error TS(7022): 'RemoveRelatedRecordButton' implicitly has type 'a... Remove this comment to see the full error message
const RemoveRelatedRecordButton = ({
  buttonText,
  buttonType,
  dataList,
  dataType,
  project,
  record,
  refetch,
  rootDataType,
  scope,
  variant,
}: Props) => {
  const {
    dataTypeField,
    fieldApiArgName,
    isMultiRelationship,
    update,
    updateLoading,
  } = useUpdateRelatedRecordCollection(
    dataList,
    project,
    rootDataType,
    dataType,
  );

  const canUpdateField = useHasPermissionToUpdateRelatedRecordCollection(
    dataType,
    dataTypeField,
  );

  const onRemoveRelatedRecord = useCallback(
    (nodeToRemove: any) => {
      const variables = {
        id: nodeToRemove.id,
      };

      if (isMultiRelationship) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        variables[fieldApiArgName] = [scope[RECORD_SCOPE].id];
      } else {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        variables[fieldApiArgName] = null;
      }

      return update({ variables }).then(refetch);
    },
    [fieldApiArgName, isMultiRelationship, refetch, scope, update],
  );

  return (
    canUpdateField && (
      <Button
        className="flex items-center space-x-2"
        disabled={updateLoading}
        variant={variant}
        type={variant === 'primary' ? 'outline' : buttonType}
        onClick={() => onRemoveRelatedRecord(record)}
      >
        {updateLoading ? (
          <Loader size="xs" />
        ) : (
          <>
            <IconUnlink className="opacity-75" size={16} />
            <span>{buttonText || getText(BASE_LANG_KEY, 'button')}</span>
          </>
        )}
      </Button>
    )
  );
};

RemoveRelatedRecordButton.defaultProps = {
  variant: 'secondary',
};

export default RemoveRelatedRecordButton;
