import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { Switch } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { Project } from '@noloco/core/src/models/Project';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import { getText } from '@noloco/core/src/utils/lang';
import { GET_USER_LISTS } from '../../queries/project';
import { getGroupedDataTypeOptions } from '../../utils/dataTypes';
import { useUserRoleOptions } from '../../utils/hooks/useUserRoleOptions';
import UserListItemForm from '../settings/UserListItemForm';
import { StatusIcon } from './DataSourceImportStatusSection';

const LANG_KEY = 'data.dataSources.connect.userList';

type DataSourceImportSetupUserListsProps = {
  project: Project;
  createdDataSource: any;
  surface: any;
};

const DataSourceImportSetupUserLists = ({
  createdDataSource,
  project,
  surface,
}: DataSourceImportSetupUserListsProps) => {
  const [draftValue, setDraftValue] = useState({});
  const [containsUserList, setContainsUserList] = useState(false);
  const [{ error, pending }, setImportStatus] = useState({
    error: false,
    pending: true,
  });
  const { data: userListsData } = useCacheQuery(GET_USER_LISTS, {
    variables: { projectId: project.name },
  });

  const userLists = useMemo(
    () => get(userListsData, 'userLists', []),
    [userListsData],
  );

  const onChangeDraftValue = (path: any, value: any) =>
    setDraftValue(set(path, value));

  const existingListTypeIds = useMemo(
    () => userLists.map((userList: any) => userList.dataType.id),
    [userLists],
  );

  const tableOptions = useMemo(
    () =>
      getGroupedDataTypeOptions(project.dataTypes, {
        // @ts-expect-error TS(7006): Parameter 'dataType' implicitly has an 'any' type.
        filter: (dataType) => dataType.source.id === createdDataSource?.id,
        isDisabled: (dataType: any) =>
          existingListTypeIds.includes(dataType.id),
        getValue: (dataType: any) => dataType.id,
      }),
    [project.dataTypes, createdDataSource, existingListTypeIds],
  );

  const roleOptions = useUserRoleOptions(project, (role: any) => role.builder);

  const onCloseModal = useCallback(() => {
    setContainsUserList(false);
    setDraftValue({});
  }, []);

  return (
    <>
      <div
        className={classNames(
          'flex w-full items-center justify-between rounded-lg border p-3',
          {
            'border-brand-light text-white': surface !== LIGHT,
            'border-gray-200 bg-white text-gray-700': surface === LIGHT,
          },
        )}
      >
        <div className="flex items-center">
          <div className="flex w-16 flex-shrink-0 justify-center">
            <StatusIcon
              error={error}
              loading={false}
              pending={pending}
              surface={surface}
            />
          </div>
          <div className="ml-3 flex flex-col">
            <span className="text-lg font-medium tracking-wider">
              {getText(LANG_KEY, 'title')}
            </span>
            <span className="font-gray-200 mt-1 text-sm">
              {getText(LANG_KEY, 'subtitle')}
            </span>
          </div>
        </div>
        {pending && (
          <div className="mr-8">
            <Switch
              value={containsUserList}
              onChange={() => setContainsUserList((prevValue) => !prevValue)}
            />
          </div>
        )}
      </div>
      {containsUserList && (
        <UserListItemForm
          project={project}
          value={draftValue}
          onChange={onChangeDraftValue}
          onClose={onCloseModal}
          isUpdate={(draftValue as any).id}
          roleOptions={roleOptions}
          tableOptions={tableOptions}
          userType={project.dataTypes[0]}
          setImportStatus={setImportStatus}
        />
      )}
    </>
  );
};

export default DataSourceImportSetupUserLists;
