import createReactComponent from '../createReactComponent';
export default createReactComponent('edit-off', 'IconEditOff', [
  [
    'path',
    {
      d: 'M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M10.507 10.498l-1.507 1.502v3h3l1.493 -1.498m2 -2.01l4.89 -4.907a2.1 2.1 0 0 0 -2.97 -2.97l-4.913 4.896',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 5l3 3', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
