export const TRIALING: AccountStatus = 'trialing';
export const TRIAL_EXPIRED: AccountStatus = 'trial_expired';
export const ACTIVE: AccountStatus = 'active';
export const CANCELED: AccountStatus = 'canceled';

const accountStatus = [TRIALING, ACTIVE, CANCELED];

export type AccountStatus =
  | 'trialing'
  | 'active'
  | 'canceled'
  | 'trial_expired';

export default accountStatus;
