import first from 'lodash/first';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { DIVIDER, FOLDER, LINK, VIEW } from '../constants/elements';
import { PAGE } from '../constants/linkTypes';
import { SUPPORTED_PROTOCOLS } from '../constants/urls';
import { Element } from '../models/Element';
import { Project } from '../models/Project';
import { User } from '../models/User';
import { isElementVisible } from './elementVisibility';
import {
  Page,
  findPageInPath,
  getPagesConfig,
  replaceDoubleSlashes,
} from './pages';
import { flattenStateItem } from './state';

export const formatUrl = (url: string | undefined | null) =>
  !url ||
  SUPPORTED_PROTOCOLS.some((protocol) => url.startsWith(protocol)) ||
  url.includes('://')
    ? url
    : `https://${url}`;

export const formatQueryString = (queryString: string | null) =>
  queryString ? `?${queryString}` : '';

const buildPageUrl = (pathArr: string[]) =>
  replaceDoubleSlashes(
    `/${pathArr.filter((path: any) => !!path && path !== '/').join('/')}`,
  );

export const getPage = (
  pageIdPath: any,
  pageData: any,
  pages: Page[],
  scope: any,
): {
  lastPage: Page | null;
  pagePath: string[];
  pageTo: string;
} => {
  const pageAndPath = pageIdPath.reduce(
    (
      pathAcc: {
        lastPage: Page | null;
        pagePath: string[];
      },
      pageId: string,
    ) => {
      const pageItem = findPageInPath(pageId, pageIdPath, pages);
      pathAcc.lastPage = pageItem;
      if (pageId === 'new') {
        pathAcc.pagePath.push('new');
        return pathAcc;
      }

      if (!pageItem || !pageItem.props) {
        return pathAcc;
      }

      const { parentPage, routePath, dataProperty, dataType } = pageItem.props;

      if (parentPage && !dataProperty) {
        const parentPageItem = findPageInPath(parentPage, pageIdPath, pages);
        if (parentPageItem) {
          pathAcc.pagePath.push(parentPageItem.props.routePath, routePath);
          return pathAcc;
        }
      }

      if (pageItem.props.isSubPage && pageItem.parent) {
        pathAcc.pagePath.push(pageItem.parent.props.routePath, routePath);
        return pathAcc;
      }

      const pageDataItem = dataType && get(pageData, dataType);
      const valuePath = pageDataItem && flattenStateItem(pageDataItem);
      const pathValue =
        pageDataItem && valuePath && get(scope, valuePath.split('.'));
      pathAcc.pagePath.push(routePath, pathValue);
      return pathAcc;
    },
    {
      lastPage: null,
      pagePath: [],
    },
  );

  return {
    ...pageAndPath,
    pageTo: buildPageUrl(pageAndPath.pagePath),
  };
};

export const getPageTo = (
  pageIdPath: any,
  pageData: any,
  pages: Page[],
  scope: any,
) => getPage(pageIdPath, pageData, pages, scope).pageTo;

export const isPageActive = (page: Page, pathname: string, to: string) => {
  const splitPathname = pathname.split('/');

  return (
    page.type !== LINK &&
    page.type !== DIVIDER &&
    (!to || to === '/'
      ? pathname === '/'
      : to
          .split('/')
          .every(
            (pathSegment, index) =>
              splitPathname.length - 1 >= index &&
              pathSegment === splitPathname[index],
          ))
  );
};

export const getActivePage = (pages: Page[], pathname: string) =>
  pages.find((page) =>
    isPageActive(page, pathname, getPageTo(['PORTAL', page.id], {}, pages, {})),
  );

const objectToQueryString = (params: any) =>
  Object.entries(params)
    .filter(([__, val]) => val !== undefined)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
const appendParams = (url: string, params: any) =>
  `${url}${
    params && Object.values(params).filter(Boolean).length > 0
      ? `${url && url.includes('?') ? '&' : '?'}${objectToQueryString(params)}`
      : ''
  }`;

export const getLinkProps = (
  { type, active, to, href, target }: any,
  editorMode: boolean,
  trackingParams = {},
) => {
  if (type === PAGE || (to && !href)) {
    return {
      active,
      is: Link,
      to: appendParams(to, trackingParams),
    };
  }

  return {
    is: 'a',
    href: appendParams(href, trackingParams),
    target,
    ...(target === '_blank' ? { rel: 'noopener noreferrer' } : {}),
  };
};

const hasViewParent = (view: Element, elements: Element[]) => {
  const parentPageId = get(view, 'props.parentPage');
  if (!parentPageId) {
    return false;
  }
  const parent = elements.find((el: Element) => el.id === parentPageId);
  return !parent || parent.type !== FOLDER;
};

export const getViewsForDataType = (
  dataTypeName: string,
  project: Project,
): Element[] => {
  const { isV2, pagesPath } = getPagesConfig(
    project.elements,
    project.settings,
  );
  const elements = isV2 ? project.elements : get(project.elements, pagesPath);
  return elements.filter(
    (v: Element) =>
      v.type === VIEW &&
      get(v, 'props.dataList.dataType') === dataTypeName &&
      !hasViewParent(v, elements),
  );
};

export const getViewForDataType = (dataTypeName: string, project: Project) =>
  first(getViewsForDataType(dataTypeName, project));

const getParentPage = (view: Element, project: Project) => {
  const parentPageId = get(view, 'props.parentPage');
  if (parentPageId) {
    const { isV2, pagesPath } = getPagesConfig(
      project.elements,
      project.settings,
    );
    const elements = isV2 ? project.elements : get(project.elements, pagesPath);
    return elements.find((el: Element) => el.id === parentPageId);
  }
  return null;
};

export const getViewRoutePrefixForView = (
  view: Element | undefined,
  project: Project,
) => {
  const routePath = get(view, 'props.routePath');

  if (view && routePath) {
    const parentPageId = get(view, 'props.parentPage');
    if (parentPageId) {
      const parent = getParentPage(view, project);

      if (parent && parent.type === FOLDER) {
        return `/${parent.props.routePath}/${routePath}`;
      }
    }

    return `/${routePath}`;
  }

  return null;
};

export const getViewById = (viewId: string, project: Project) => {
  const { projectPages } = getPagesConfig(project.elements, project.settings);
  return projectPages.find((v: Element) => v.type === VIEW && v.id === viewId);
};

export const getViewRoutePrefixForViewId = (
  viewId: string | null,
  project: Project,
) => {
  const { projectPages } = getPagesConfig(project.elements, project.settings);

  const view = projectPages.find(
    (v: Element) =>
      get(v, 'type', null) === VIEW &&
      v.id === viewId &&
      !hasViewParent(v, projectPages),
  );

  return getViewRoutePrefixForView(view, project);
};

export const getViewRoutePrefixForDataType = (
  dataTypeName: string,
  project: Project,
) => {
  const view = getViewForDataType(dataTypeName, project);
  return getViewRoutePrefixForView(view, project);
};

export const getAllowedViewForDataType = (
  dataTypeName: string,
  project: Project,
  user: User,
  scope: any,
  customRulesEnabled: boolean,
  viewFilter: (element: Element) => boolean = () => true,
): Element | undefined => {
  const editorMode = false;
  const views = getViewsForDataType(dataTypeName, project);
  return views.find((testView) => {
    const parent = getParentPage(testView, project);

    if (
      parent &&
      !isElementVisible(
        parent,
        project,
        user,
        scope,
        editorMode,
        customRulesEnabled,
      )
    ) {
      return false;
    }

    return (
      viewFilter(testView) &&
      isElementVisible(
        testView,
        project,
        user,
        scope,
        editorMode,
        customRulesEnabled,
      )
    );
  });
};

export const getAllowedViewRoutePrefixForDataType = (
  dataTypeName: string,
  project: Project,
  user: User,
  scope: any,
  customRulesEnabled: boolean,
  viewFilter: (element: Element) => boolean = () => true,
) => {
  const view = getAllowedViewForDataType(
    dataTypeName,
    project,
    user,
    scope,
    customRulesEnabled,
    viewFilter,
  );
  return getViewRoutePrefixForView(view, project);
};
