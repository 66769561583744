import createReactComponent from '../createReactComponent';
export default createReactComponent('square-forbid', 'IconSquareForbid', [
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 9l6 6', key: 'svg-1' }],
]);
