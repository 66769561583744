import { partionedForEach } from '../utils/paritionedLoops';

interface Identifier {
  id: number;
}

interface IdentifierMap<T extends Identifier> {
  idMap: Record<number, T>;
}

type MapItem<T extends Identifier> = string | string[] | number | number[] | T;

type MapType<T extends Identifier> =
  | Record<number | string, MapItem<T>>
  | string[]
  | number[];

export interface BaseJsonMaps<T extends Identifier> extends IdentifierMap<T> {
  [key: string]: MapType<T>;
}

export type JsonMaps<
  T extends Identifier,
  U extends Omit<BaseJsonMaps<T>, 'idMap'>,
> = BaseJsonMaps<T> & U;

class BaseArrayTypeMap<T extends Identifier> extends Array<T> {
  idMap: null | Record<number, T>;
  maps: Record<string, MapType<T>>;

  constructor(arrayOrLength?: number | T[] | BaseArrayTypeMap<T>) {
    super();
    if (arrayOrLength === undefined) {
      // Already initialized
    } else if (typeof arrayOrLength === 'number') {
      this.length = arrayOrLength;
    } else {
      this.push(...(arrayOrLength as T[]));
    }

    if (arrayOrLength instanceof BaseArrayTypeMap) {
      //Avoid re-calculating these maps if already populated
      this.idMap = arrayOrLength.idMap;
    } else {
      this.idMap = null;
    }

    this.maps = {};
  }

  private getArray(): T[] {
    return [...this];
  }

  async asyncSetup() {
    if (this.idMap) {
      return this;
    }

    await this._mapEntriesAsync();
    return this;
  }

  syncSetup() {
    if (this.idMap) {
      return this;
    }

    this._mapEntries();
    return this;
  }

  _mapEntry(entry: T) {
    if (this.idMap) {
      this.idMap[entry.id] = entry;
    }
  }

  async _mapEntriesAsync() {
    this.idMap = {};

    await partionedForEach(
      this as unknown as T[],
      (entry) => this._mapEntry(entry),
      {},
    );
  }

  _mapEntries() {
    this.idMap = {};

    this.forEach((entry: T) => this._mapEntry(entry));

    this.maps.idMap = this.idMap;
  }

  getById(id: number) {
    if (!this.idMap) {
      this._mapEntries();
    }
    return this.idMap ? this.idMap[id] : undefined;
  }

  getByIdOrThrow(id: number) {
    const entry = this.getById(id);
    if (!entry) {
      throw new Error(`Entry with id ${id} not found`);
    }

    return entry;
  }

  protected getByIdOrUndefined(id: number | undefined) {
    if (id === undefined) {
      return undefined;
    }

    return this.getById(id);
  }

  getByIds(ids: number[]): T[] {
    return ids.map((id) => this.getByIdOrUndefined(id)).filter(Boolean) as T[];
  }

  /**
   * This enables:
   * - Passing in/out of a worker thread
   * - Persisting this on the cache instead of dropping
   */
  toJSON() {
    if (!this.idMap || !this.maps.idMap) {
      this._mapEntries();
    }

    return this.maps;
  }

  static _fromJSON<T extends Identifier>(json: BaseJsonMaps<T>) {
    const values = Object.values(json.idMap);
    const arrayMap = new this<T>(values);
    arrayMap.idMap = json.idMap;

    Object.entries(json).forEach(([mapName, map]) => {
      if (mapName !== 'idMap') {
        // @ts-expect-error [mapName] isn't defined on array
        arrayMap[mapName as string] = map;
        // @ts-expect-error [mapName] isn't defined on array
        arrayMap.maps[mapName] = arrayMap[mapName as string];
      }
    });

    return arrayMap;
  }

  concat(arg: any[]) {
    const concatResult = super.concat(arg);
    if (this.idMap) {
      arg.forEach((entry: T) => {
        this._mapEntry(entry);
      });
    }
    return concatResult;
  }

  entries() {
    return super.entries();
  }

  every = super.every;

  filter(
    predicate: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any,
  ) {
    return this.getArray().filter(predicate, thisArg);
  }

  findIndex(
    predicate: (value: T, index: number, obj: T[]) => unknown,
    thisArg?: any,
  ) {
    return super.findIndex(predicate, thisArg);
  }

  find(
    predicate: (value: T, index: number, obj: T[]) => unknown,
    thisArg?: any,
  ): T | undefined {
    return super.find(predicate, thisArg);
  }

  forEach(callbackFn: (value: T, index: number, obj: T[]) => unknown) {
    return super.forEach(callbackFn);
  }

  includes(searchElement: T) {
    return super.includes(searchElement);
  }

  join(...args: any[]) {
    return super.join(...args);
  }

  map<U>(
    callbackfn: (value: T, index: number, array: T[]) => U,
    thisArg?: any,
  ) {
    return this.getArray().map(callbackfn, thisArg);
  }

  push(...items: T[]) {
    const pushResult = super.push(...items);
    if (this.idMap) {
      items.forEach((entry: T) => {
        this._mapEntry(entry);
      });
    }
    return pushResult;
  }

  slice(start?: number, end?: number) {
    return this.getArray().slice(start, end);
  }

  sort(compareFn?: (a: T, b: T) => number) {
    return super.sort(compareFn);
  }
}

export default BaseArrayTypeMap;
