import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-desktop-heart',
  'IconDeviceDesktopHeart',
  [
    [
      'path',
      {
        d: 'M10 16h-6a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v6',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h3.5', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    [
      'path',
      {
        d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
        key: 'svg-3',
      },
    ],
  ],
);
