import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  IconCheck,
  IconCircleNumber1,
  IconCircleNumber2,
  IconExternalLink,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import {
  Button,
  Label,
  Loader,
  SelectInput,
  Surface,
} from '@noloco/components';
import { TEXT } from '@noloco/components/src/components/button/buttonTypes';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { useRefetchIntegrations } from '@noloco/core/src/utils/hooks/useRefetchIntegrations';
import { getText } from '@noloco/core/src/utils/lang';
import { buildConnectUri } from '@noloco/core/src/utils/oauth';
import smartSuiteLogo from '../../img/smartsuite-logo.png';
import { GET_SMART_SUITE_WORKSPACES } from '../../queries/project';
import { openAuthorizationWindow } from '../../utils/oauth';
import Guide from '../Guide';

const LANG_KEY = 'data.smartSuite';

type Props = {
  authToken: string;
  disabled: boolean | undefined;
  hasSmartSuiteOAuthIntegration: boolean;
  authorizationRedirect?: string;
  onChangeSolutionId: (value: number | null) => void;
  onChangeWorkspaceId: (value: number | null) => void;
  onNameChange?: (value: string | null) => void;
  projectName?: string;
  solutionId: number | undefined | null;
  surface: Surface;
  workspaceId: number | undefined | null;
};

const REMOVE_INVALID_DISPLAY_CHARS = /[^a-zA-ZÀ-Ÿ0-9-_\s]+/g;
const ConnectSmartSuiteSolution = ({
  authToken,
  disabled,
  hasSmartSuiteOAuthIntegration,
  authorizationRedirect,
  onChangeSolutionId,
  onChangeWorkspaceId,
  onNameChange,
  projectName,
  solutionId,
  surface,
  workspaceId,
}: Props) => {
  const [hasSmartSuiteAccount, setHasSmartSuiteAccount] = useState(
    hasSmartSuiteOAuthIntegration ? true : null,
  );

  const { data, refetch, loading } = useQuery(GET_SMART_SUITE_WORKSPACES, {
    skip: !hasSmartSuiteOAuthIntegration,
    variables: { projectId: projectName },
    notifyOnNetworkStatusChange: true,
  });
  const availableWorkspaces = useMemo(
    () =>
      get(data, ['smartSuiteWorkspaces'], []).filter(({ solutions }: any) =>
        solutions.some(({ connected }: any) => !connected),
      ),
    [data],
  );
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);
  const handleOnWorkspaceChange = useCallback(
    (id: number) => {
      onChangeWorkspaceId(id);
      setSelectedWorkspace(
        availableWorkspaces.find((workspace: any) => workspace.id === id),
      );
    },
    [availableWorkspaces, onChangeWorkspaceId],
  );
  useEffect(() => {
    if (!workspaceId && availableWorkspaces.length === 1) {
      handleOnWorkspaceChange(availableWorkspaces[0].id);
    }
  }, [availableWorkspaces, handleOnWorkspaceChange, workspaceId]);
  const workspaceOptions = useMemo(
    () =>
      availableWorkspaces.map((workspace: any) => ({
        label: workspace.name,
        value: workspace.id,
      })),
    [availableWorkspaces],
  );

  const handleOnSolutionChange = useCallback(
    (id: number) => {
      onChangeSolutionId(id);
      if (onNameChange) {
        const name =
          (selectedWorkspace?.solutions ?? []).find(
            (solution: any) => solution.id === id,
          )?.name ?? '';
        const cleanedName = name.replace(REMOVE_INVALID_DISPLAY_CHARS, '');
        onNameChange(cleanedName);
      }
    },
    [onChangeSolutionId, onNameChange, selectedWorkspace],
  );
  const solutionOptions = useMemo(() => {
    if (!selectedWorkspace) {
      return [];
    }

    return selectedWorkspace.solutions
      .filter((solution: any) => !solution.connected)
      .map((solution: any) => ({
        label: solution.name,
        value: solution.id,
      }));
  }, [selectedWorkspace]);

  const refetchIntegrations = useRefetchIntegrations(projectName as string);
  const onConnectSmartSuiteAccount = useCallback(
    () =>
      openAuthorizationWindow(
        buildConnectUri('smart-suite'),
        new URLSearchParams({
          token: authToken,
          ...(projectName ? { project: projectName } : {}),
        }),
        { onClose: refetchIntegrations, redirectUri: authorizationRedirect },
      ),
    [authToken, projectName, refetchIntegrations, authorizationRedirect],
  );

  return (
    <div className="mb-8 flex flex-col">
      {(!hasSmartSuiteAccount || !hasSmartSuiteOAuthIntegration) && (
        <div className="my-6 flex flex-col">
          <div className="flex items-center justify-between">
            <label
              className={classNames(
                'text-sm font-medium leading-5',
                surface === DARK ? 'text-gray-100' : 'text-gray-700',
              )}
            >
              {getText(LANG_KEY, 'noAccount.question')}
            </label>
            <div className="ml-4 flex items-center space-x-2">
              <Button
                variant="secondary"
                className={classNames('flex items-center', {
                  'ring ring-pink-500 ring-opacity-75': !hasSmartSuiteAccount,
                })}
                onClick={() => setHasSmartSuiteAccount(false)}
              >
                {getText(LANG_KEY, 'noAccount.no')}
              </Button>
              <Button
                variant="secondary"
                className={classNames('flex items-center', {
                  'ring ring-pink-500 ring-opacity-75': hasSmartSuiteAccount,
                })}
                onClick={() => setHasSmartSuiteAccount(true)}
              >
                {getText(LANG_KEY, 'noAccount.yes')}
              </Button>
            </div>
          </div>
          <p
            className={classNames(
              'mt-4 text-xs',
              surface === DARK ? 'text-gray-200' : 'text-gray-500',
            )}
          >
            {getText(LANG_KEY, 'noAccount.note')}
          </p>
          {!hasSmartSuiteAccount && (
            <a
              className="mt-4 block"
              href="https://app.smartsuite.com/signup/start?ref=noloco"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                variant="secondary"
                className="flex items-center"
                opacity={100}
              >
                <img
                  src={smartSuiteLogo}
                  alt="SmartSuite logo"
                  className="mr-3 h-6 w-auto"
                />
                {getText(LANG_KEY, 'noAccount.createSmartSuiteAccount')}
                <IconExternalLink className="ml-4" size={20} />
              </Button>
            </a>
          )}
        </div>
      )}
      {hasSmartSuiteAccount && (
        <div>
          <Label
            className={classNames('flex items-center', {
              'text-white': surface !== LIGHT,
              'text-gray-600': surface === LIGHT,
            })}
            m={{ b: 3, t: 6 }}
          >
            <IconCircleNumber1 size={22} className="mr-2" />
            <span>{getText(LANG_KEY, 'oauth.label')}</span>
          </Label>
          <Button
            variant="secondary"
            disabled={hasSmartSuiteOAuthIntegration}
            onClick={onConnectSmartSuiteAccount}
            className="flex items-center hover:bg-gray-100"
            opacity={100}
          >
            <img
              src={smartSuiteLogo}
              alt="SmartSuite logo"
              className="mr-3 h-6 w-auto"
            />
            {getText(
              LANG_KEY,
              'oauth.button',
              hasSmartSuiteOAuthIntegration ? 'connected' : 'connect',
            )}
            {hasSmartSuiteOAuthIntegration && (
              <IconCheck className="ml-4 text-teal-400" size={16} />
            )}
          </Button>
        </div>
      )}
      {hasSmartSuiteAccount && (
        <div className="mt-6">
          <div className="flex items-center">
            <Label className="flex items-center" surface={surface}>
              <IconCircleNumber2 size={22} className="mr-2" />
              <span>{getText(LANG_KEY, 'oauth.workspace.label')}</span>
            </Label>
            <div className="order2 ml-auto">
              <Button
                type={TEXT}
                size="sm"
                surface={surface}
                className="flex items-center justify-center"
                onClick={() => {
                  refetch();
                  onChangeSolutionId(null);
                  onChangeWorkspaceId(null);
                }}
                disabled={loading || disabled || !hasSmartSuiteOAuthIntegration}
              >
                {loading && <Loader size="xs" className="mr-2" />}
                {getText(LANG_KEY, 'refresh')}
              </Button>
            </div>
          </div>
          <p
            className={classNames('mb-2 text-sm', {
              'text-gray-200': surface !== LIGHT,
              'text-gray-600': surface === LIGHT,
            })}
          >
            {getText(LANG_KEY, 'oauth.workspace.description')}
          </p>
          <SelectInput
            disabled={!hasSmartSuiteOAuthIntegration || disabled || loading}
            options={workspaceOptions}
            value={workspaceId}
            onChange={handleOnWorkspaceChange}
            placeholder={getText(LANG_KEY, 'oauth.workspace.placeholder')}
            searchable={true}
            surface={surface}
          />
          <Label className="mt-6 flex items-center" surface={surface}>
            <IconCircleNumber2 size={22} className="mr-2" />
            <span>{getText(LANG_KEY, 'oauth.solution.label')}</span>
          </Label>
          <p
            className={classNames('mb-2 text-sm', {
              'text-gray-200': surface !== LIGHT,
              'text-gray-600': surface === LIGHT,
            })}
          >
            {getText(LANG_KEY, 'oauth.solution.description')}
          </p>
          <SelectInput
            disabled={
              !hasSmartSuiteOAuthIntegration ||
              !selectedWorkspace ||
              disabled ||
              loading
            }
            options={solutionOptions}
            value={solutionId}
            onChange={handleOnSolutionChange}
            placeholder={getText(LANG_KEY, 'oauth.solution.placeholder')}
            searchable={true}
            surface={surface}
          />
          <Guide
            className="mt-4 text-sm"
            href="https://guides.noloco.io/data/smartsuite#why-isnt-my-smartsuite-solution-appearing-in-the-dropdown-to-connect"
          >
            {getText(LANG_KEY, 'oauth.solutionMissing')}
          </Guide>
        </div>
      )}
    </div>
  );
};

export default ConnectSmartSuiteSolution;
