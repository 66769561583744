import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-rotary-last-right',
  'IconArrowRotaryLastRight',
  [
    ['path', { d: 'M9 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
    ['path', { d: 'M9 15v6', key: 'svg-1' }],
    ['path', { d: 'M11.5 9.5l6.5 -6.5', key: 'svg-2' }],
    ['path', { d: 'M13 3h5v5', key: 'svg-3' }],
  ],
);
