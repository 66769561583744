import { useMemo, useState } from 'react';
import { SwitchButton } from '@noloco/components/src';
import { ErrorText } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { DataField } from '../../../models/DataTypeFields';
import {
  canvasElementToImageFile,
  createCanvasFromText,
} from '../../../utils/canvas';
import CanvasInput from './CanvasInput';
import SignatureFilePreview from './SignatureFilePreview';

const DRAW = 'Draw';
const TYPE = 'Type';
const UPLOAD = 'Upload';

export type SignatureType = 'Draw' | 'Type' | 'Upload';

const inputOptions = [DRAW, TYPE, UPLOAD];

export type SignatureInputProps = {
  field: DataField;
  value: any;
  onChange: (...args: any) => void;
  onChangeWithOnBlur: (...args: any) => void;
  validationError?: string | JSX.Element;
  fileTypeValidation?: any;
  placeholder?: string;
};

const SignatureInput = ({
  field,
  value,
  onChange,
  onChangeWithOnBlur,
  validationError,
  placeholder,
}: SignatureInputProps) => {
  const [inputOption, setInputOption] = useState(DRAW);
  const [textSignature, setTextSignature] = useState('');

  const handleOnBlur = () => {
    if (textSignature) {
      const canvas = createCanvasFromText(textSignature);
      const file = canvasElementToImageFile(canvas);
      onChange(file);
    } else {
      onChange(null);
    }
  };

  const options = useMemo(
    () =>
      inputOptions.map((option: string) => ({
        label: option,
        value: option,
      })),
    [],
  );

  const handleChangeInputOption = (value: any) => {
    if (inputOption !== value) {
      if (textSignature) {
        setTextSignature('');
      }
      onChange(null);
      setInputOption(value);
    }
  };

  return (
    <>
      {validationError && (
        <ErrorText className="mb-1">{validationError}</ErrorText>
      )}
      <div className="relative flex h-44 items-center justify-center rounded-lg border border-gray-200 bg-gray-100">
        <SwitchButton
          className="absolute right-0 top-0 z-10 m-2 rounded-lg"
          fullWidth={false}
          onChange={handleChangeInputOption}
          options={options}
          surface={LIGHT}
          value={inputOption}
        />
        {inputOption === DRAW && (
          <CanvasInput className="h-44" onChange={onChange} />
        )}
        {inputOption === TYPE && (
          <input
            type="text"
            className="font-signature w-3/5 border-b border-gray-200 bg-transparent px-1 pt-1 text-center text-xl focus:border-gray-300"
            placeholder={placeholder}
            onChange={(e: any) => setTextSignature(e.target.value)}
            onBlur={handleOnBlur}
            value={textSignature}
          />
        )}
        {inputOption === UPLOAD && (
          <SignatureFilePreview
            id={String(field.id)}
            file={value}
            onChange={onChangeWithOnBlur}
          />
        )}
      </div>
    </>
  );
};

export default SignatureInput;
