import createReactComponent from '../createReactComponent';
export default createReactComponent('bomb', 'IconBomb', [
  [
    'path',
    {
      d: 'M15.349 5.349l3.301 3.301a1.2 1.2 0 0 1 0 1.698l-.972 .972a7.5 7.5 0 1 1 -5 -5l.972 -.972a1.2 1.2 0 0 1 1.698 0z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 7l1.293 -1.293a2.414 2.414 0 0 0 .707 -1.707a1 1 0 0 1 1 -1h1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 13a3 3 0 0 1 3 -3', key: 'svg-2' }],
]);
