import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-dolby-digital',
  'IconBrandDolbyDigital',
  [
    [
      'path',
      {
        d: 'M21 6v12h-.89c-3.34 0 -6.047 -2.686 -6.047 -6s2.707 -6 6.046 -6h.891z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M3.063 6v12h.891c3.34 0 6.046 -2.686 6.046 -6s-2.707 -6 -6.046 -6h-.89z',
        key: 'svg-1',
      },
    ],
  ],
);
