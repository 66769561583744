import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-cupra', 'IconBrandCupra', [
  [
    'path',
    {
      d: 'M4.5 10l-2.5 -4l15.298 6.909a.2 .2 0 0 1 .09 .283l-3.388 5.808',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M10 19l-3.388 -5.808a.2 .2 0 0 1 .09 -.283l15.298 -6.909l-2.5 4',
      key: 'svg-1',
    },
  ],
]);
