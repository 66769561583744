import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'temperature-minus',
  'IconTemperatureMinus',
  [
    [
      'path',
      { d: 'M8 13.5a4 4 0 1 0 4 0v-8.5a2 2 0 0 0 -4 0v8.5', key: 'svg-0' },
    ],
    ['path', { d: 'M8 9l4 0', key: 'svg-1' }],
    ['path', { d: 'M16 9l6 0', key: 'svg-2' }],
  ],
);
