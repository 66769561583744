import { useCallback } from 'react';
import shortid from 'shortid';
import { Button } from '@noloco/components/src';
import { baseColors } from '@noloco/core/src/constants/tailwindStyles';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import ColorConditionsEditor from '../ColorConditionsEditor';

export const getNewColorCondition = () => ({
  id: shortid.generate(),
  color: baseColors[2],
  conditions: [
    [
      {
        field: null,
        operator: null,
        value: null,
      },
    ],
  ],
});

type FieldColorsConditionsEditorProps = {
  values: any;
  onChange: (path: any, value: any) => void;
  dataType: DataType;
  fieldOptions: any;
  project: Project;
  elementPath: ElementPath;
};

const FieldColorsConditionsEditor = ({
  values,
  onChange,
  dataType,
  fieldOptions,
  project,
  elementPath,
}: FieldColorsConditionsEditorProps) => {
  const addColorCondition = () =>
    onChange([], [...values, getNewColorCondition()]);

  const removeColorCondition = useCallback(
    (id) => {
      onChange(
        [],
        values.filter((item: any) => item.id !== id),
      );
    },
    [values, onChange],
  );

  return (
    <>
      <div className={'flex max-w-full flex-col space-y-2'}>
        {values?.map((value: any, index: number) => (
          <ColorConditionsEditor
            key={value.id}
            inline={true}
            dataType={dataType}
            fieldOptions={fieldOptions}
            updateConditions={(path: ElementPath, value: any) =>
              onChange([index, 'conditions', ...path], value)
            }
            updateColor={(value: any) => onChange([index, 'color'], value)}
            removeColorCondition={() => removeColorCondition(value.id)}
            rules={value.conditions}
            project={project}
            elementPath={elementPath}
            colorValue={value.color}
          />
        ))}
        <Button
          className="mr-auto"
          onClick={addColorCondition}
          variant="secondary"
        >
          <span className="block px-2">
            {getText('colorConditionEditor.new')}
          </span>
        </Button>
      </div>
    </>
  );
};

export default FieldColorsConditionsEditor;
