import React from 'react';

const Dots = ({ className, fill, height, width }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 120 30"
    preserveAspectRatio="xMidYMid"
  >
    <circle cx="15" cy="15" r="15" fill={fill}>
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="53.5;46.5;53.5;53.5"
        keyTimes="0;0.3;0.6;1"
        dur="1s"
        begin="-0.6s"
      ></animate>
    </circle>
    <circle cx="60" cy="15" r="15" fill={fill}>
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="53.5;46.5;53.5;53.5"
        keyTimes="0;0.3;0.6;1"
        dur="1s"
        begin="-0.39999999999999997s"
      ></animate>
    </circle>
    <circle cx="105" cy="15" r="15" fill={fill}>
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="53.5;46.5;53.5;53.5"
        keyTimes="0;0.3;0.6;1"
        dur="1s"
        begin="-0.19999999999999998s"
      ></animate>
    </circle>
  </svg>
);

export default Dots;
