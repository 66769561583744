import React from 'react';
import { IconEdit } from '@tabler/icons-react';
import classNames from 'classnames';
import { Tooltip } from '@noloco/components';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import EditCollectionNameModal from '../data/EditCollectionNameModal';

type EditDataTypeButtonProps = {
  dataType: DataType;
  project: Project;
  showEditCollectionNameModal: boolean;
  setShowEditCollectionNameModal: (
    showEditCollectionNameModal: boolean,
  ) => void;
  hasFieldsWithoutRules: boolean;
  isSelected: boolean;
};

const EditDataTypeButton = ({
  dataType,
  project,
  showEditCollectionNameModal,
  setShowEditCollectionNameModal,
  hasFieldsWithoutRules,
  isSelected,
}: EditDataTypeButtonProps) => {
  if (showEditCollectionNameModal) {
    return (
      <EditCollectionNameModal
        dataType={dataType}
        onClose={() => setShowEditCollectionNameModal(false)}
        project={project}
      />
    );
  }

  return (
    <Tooltip
      disabled={hasFieldsWithoutRules}
      content={getText('leftSidebar.data.edit')}
    >
      <button
        onClick={() => setShowEditCollectionNameModal(true)}
        className={classNames(
          'relative ml-auto mr-2 text-gray-500 hover:text-white',
          {
            'invisible group-hover:visible': !isSelected,
          },
        )}
      >
        <IconEdit size={16} />
      </button>
    </Tooltip>
  );
};

export default EditDataTypeButton;
