export const openAuthorizationWindow = (
  authorizationUrl: string,
  params: URLSearchParams,
  { onClose, redirectUri }: { onClose?: () => void; redirectUri?: string },
) => {
  const child = window.open(
    `${authorizationUrl}?${params}`,
    '_blank',
    'location=yes,height=720,width=600,scrollbars=yes,status=yes',
  );

  if (child) {
    let intervalId: any;
    intervalId = setInterval(() => {
      if (child.closed) {
        clearInterval(intervalId);

        if (onClose) {
          onClose();
        }

        if (redirectUri) {
          document.location = redirectUri;
        }
      }
    }, 100);
  }
};
