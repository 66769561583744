import createReactComponent from '../createReactComponent';
export default createReactComponent('soup-off', 'IconSoupOff', [
  ['path', { d: 'M3 19h16', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15 11h6c0 1.691 -.525 3.26 -1.42 4.552m-2.034 2.032a7.963 7.963 0 0 1 -4.546 1.416h-2a8 8 0 0 1 -8 -8h8',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 5v3', key: 'svg-2' }],
  ['path', { d: 'M15 5v3', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
