import React, { useCallback, useMemo, useState } from 'react';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { TextInput } from '@noloco/components';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeLabel from '@noloco/core/src/components/buildMode/BuildModeLabel';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  DETAILS,
  HIGHLIGHTS,
  MARKDOWN,
} from '@noloco/core/src/constants/elements';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Element, ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { FormFieldConfig } from '@noloco/core/src/models/View';
import {
  canBeCopiedToClipboard,
  getRootFieldDataType,
} from '@noloco/core/src/utils/fields';
import { getText } from '@noloco/core/src/utils/lang';
import { isMultiRelationship } from '@noloco/core/src/utils/relationships';
import { UpdatePropertyCallback } from '../../../utils/hooks/projectHooks';
import { getFieldsForDetailsSection } from '../../../utils/layout';
import Guide from '../../Guide';
import StringPropEditor from '../../canvas/StringPropEditor';
import AdvancedFormFieldEditor from './AdvancedFormFieldEditor';
import ColumnWidthEditor from './ColumnWidthEditor';
import FieldColorEditor from './FieldColorEditor';
import FieldConditionsEditor, {
  getCachedConditionFieldOptions,
} from './FieldConditionsEditor';
import FieldElementTypeEditor from './FieldElementTypeEditor';
import FieldViewLinkEditor from './FieldViewLinkEditor';
import FieldsListEditor, { UpdateFieldsCallback } from './FieldsListEditor';
import SectionRootFieldEditor from './SectionRootFieldEditor';

type SectionFieldsListEditorProps = {
  dataType: DataType;
  elementPath: ElementPath;
  fields: DataField[];
  fieldsPath?: any[];
  isRecordView: boolean;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
  isFormHelpText?: boolean;
  updateProperty: UpdatePropertyCallback;
  element?: Element;
};

const FORMS_LANG_KEY = 'elements.FORMS';
const MAX_DEFAULT_SECTION_FIELDS = 4;
const SECTION_FIELD_FILTER = (field: DataField) => field.type !== FILE;

const SectionFieldsListEditor = ({
  dataType,
  elementPath,
  fields = [],
  isRecordView = true,
  project,
  section,
  fieldsPath = ['fields'],
  sectionPropPath,
  isFormHelpText = true,
  updateProperty,
  element,
}: SectionFieldsListEditorProps) => {
  const { rootField } = section.props || {};
  const [showFormOptions, setShowFormOptions] = useState(false);
  const onFieldsChange = useCallback(
    (path, value) => updateProperty([...fieldsPath, ...path], value),
    [updateProperty, fieldsPath],
  );

  const recordScopeConditionFieldOptions = useMemo(
    () => getCachedConditionFieldOptions(dataType, project),
    [dataType, project],
  );
  const sectionScopeOptions = useMemo(
    () =>
      getCachedConditionFieldOptions(
        dataType,
        project,
        element?.id
          ? {
              stateId: `${element.id}:VIEW`,
            }
          : undefined,
      ),
    [dataType, project, element],
  );

  const rootDataType = useMemo(
    () =>
      rootField
        ? getRootFieldDataType(rootField, dataType, project.dataTypes)
        : dataType,
    [dataType, project.dataTypes, rootField],
  );

  const onRootFieldChange = useCallback(
    (rootFieldName: string | null, sectionDataType: DataType) => {
      const nextFields = getFieldsForDetailsSection(
        sectionDataType.fields,
        MAX_DEFAULT_SECTION_FIELDS,
        SECTION_FIELD_FILTER,
      );

      return updateProperty([], {
        ...section.props,
        fields: nextFields,
        rootField: rootFieldName,
      });
    },
    [section.props, updateProperty],
  );

  return (
    <>
      <div className="flex items-center border-t border-slate-700 p-2 pt-4">
        <BuildModeInput
          inline={true}
          label={getText('elements.VIEW.fields.parent.title')}
        >
          <SectionRootFieldEditor
            dataType={dataType}
            onChange={onRootFieldChange}
            project={project}
            value={rootField}
          />
        </BuildModeInput>
      </div>
      <FieldsListEditor
        dataType={rootDataType}
        dataTypes={project.dataTypes}
        fields={rootDataType.fields}
        getNewFieldConfig={(field: any) => ({
          label: { value: upperFirst(field.display) },
        })}
        onFieldsChange={onFieldsChange}
        permissionType="update"
        sticky={true}
        value={fields}
      >
        {({
          config,
          debounceUpdateFields,
          field,
          index,
          updateFields,
        }: {
          config: FormFieldConfig;
          debounceUpdateFields: UpdateFieldsCallback;
          field: DataField;
          index: number;
          updateFields: UpdateFieldsCallback;
        }) => (
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <BuildModeSwitchSection
                label={getText('elements.VIEW.fields.label')}
                onChange={(value: boolean) =>
                  updateFields([index, 'label', 'hidden'], !value)
                }
                value={!get(config, 'label.hidden')}
              />
              <TextInput
                disabled={config.hidden}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  debounceUpdateFields(
                    [index, 'label', 'value'],
                    event.target.value,
                  )
                }
                placeholder={field.display}
                value={get(config, 'label.value')}
              />
            </div>
            <BuildModeInput
              label={getText(FORMS_LANG_KEY, 'helpText.label')}
              markdown={true}
            >
              <StringPropEditor
                // @ts-expect-error TS(2322): Type '{ additionalScopeItems: { label: string; opt... Remove this comment to see the full error message
                additionalScopeItems={[
                  {
                    help: isFormHelpText
                      ? getText(FORMS_LANG_KEY, 'helpText.formData.help')
                      : getText(FORMS_LANG_KEY, 'helpText.recordData.help'),
                    label: isFormHelpText
                      ? getText(FORMS_LANG_KEY, 'helpText.formData.label')
                      : getText(FORMS_LANG_KEY, 'helpText.recordData.label'),
                    options: sectionScopeOptions.fieldOptions.options,
                  },
                ]}
                contained={true}
                elementPath={elementPath}
                multiLine={true}
                onChange={(value: any) => {
                  debounceUpdateFields([index, 'helpText'], value);
                }}
                placeholder={getText(
                  FORMS_LANG_KEY,
                  'helpText.placeholder.generic',
                )}
                project={project}
                value={get(config, 'helpText')}
              />
            </BuildModeInput>
            {section.type === HIGHLIGHTS && (
              <div className="flex flex-col space-y-4">
                <FieldElementTypeEditor
                  config={config}
                  field={field}
                  index={index}
                  updateFields={updateFields}
                  elementPath={sectionPropPath}
                  project={project}
                />
                <FieldViewLinkEditor
                  config={config}
                  label={getText('elements.VIEW.fields.rowLink.label')}
                  field={field}
                  onChange={(nextRowLink) =>
                    updateFields([index, 'rowLink'], nextRowLink)
                  }
                  project={project}
                  value={config.rowLink}
                />
                <BuildModeInput
                  label={getText('elements.VIEW.fields.columnSpan.label')}
                >
                  <ColumnWidthEditor
                    onChange={(colWidthValue) =>
                      updateFields([index, 'columnWidth'], colWidthValue)
                    }
                    value={config.columnWidth!}
                  />
                </BuildModeInput>
                <FieldColorEditor
                  conditionFieldOptions={Object.values(
                    recordScopeConditionFieldOptions,
                  )}
                  dataType={dataType}
                  elementPath={elementPath}
                  onChange={(path: any, value: any) =>
                    updateFields([index, 'highlights', ...path], value)
                  }
                  project={project}
                  value={get(config, ['highlights'])}
                />
              </div>
            )}
            {section.type === DETAILS && (
              <div className="flex flex-col space-y-4">
                {!config.editInline && (
                  <FieldElementTypeEditor
                    config={config}
                    field={field}
                    index={index}
                    updateFields={updateFields}
                    elementPath={sectionPropPath}
                    project={project}
                  />
                )}
                <FieldViewLinkEditor
                  config={config}
                  label={getText('elements.VIEW.fields.rowLink.label')}
                  field={field}
                  onChange={(nextRowLink) =>
                    updateFields([index, 'rowLink'], nextRowLink)
                  }
                  project={project}
                  value={config.rowLink}
                />
                <BuildModeInput
                  label={getText('elements.VIEW.fields.columnSpan.label')}
                >
                  <ColumnWidthEditor
                    onChange={(colWidthValue) =>
                      updateFields([index, 'columnWidth'], colWidthValue)
                    }
                    value={
                      (config.columnWidth ||
                        (config.columnSpan ? config.columnSpan * 4 : null))!
                    }
                  />
                </BuildModeInput>
                {!get(config, 'editInline', false) &&
                  canBeCopiedToClipboard(field) && (
                    <BuildModeSwitchSection
                      label={getText('elements.VIEW.fields.copyToClipboard')}
                      onChange={(value: boolean) =>
                        updateFields([index, 'copyToClipboard'], value)
                      }
                      value={get(config, 'copyToClipboard', false)}
                    />
                  )}
                {!field.readOnly &&
                  (!config.elementType || config.elementType === MARKDOWN) &&
                  isRecordView && (
                    <>
                      <BuildModeSwitchSection
                        guide={
                          <Guide
                            hideIcon={true}
                            href="https://guides.noloco.io/field-formatting/in-line-editing"
                            showTooltip={true}
                            video={
                              'https://www.youtube.com/embed/sS0hU0kE0LA?si=Db8RRaBUS92RbdYp'
                            }
                          >
                            {getText(
                              'elements.VIEW.fields.inlineEditing.guide',
                            )}
                          </Guide>
                        }
                        label={getText(
                          'elements.VIEW.fields.inlineEditing.label',
                        )}
                        onChange={(value: any) =>
                          updateFields([index, 'editInline'], value)
                        }
                        value={get(config, 'editInline', false)}
                      />
                      <button
                        className="group flex cursor-pointer items-center justify-between text-left"
                        onClick={() => setShowFormOptions(!showFormOptions)}
                      >
                        <BuildModeLabel className="group-hover:text-slate-200">
                          {getText('elements.VIEW.fields.inputOptions')}
                        </BuildModeLabel>
                        <div className="text-slate-400 group-hover:text-slate-200">
                          {showFormOptions ? (
                            <IconChevronDown size={16} />
                          ) : (
                            <IconChevronRight size={16} />
                          )}
                        </div>
                      </button>
                      {showFormOptions && (
                        <div className="flex flex-col">
                          <AdvancedFormFieldEditor
                            config={config}
                            dataType={dataType}
                            elementPath={elementPath}
                            field={field}
                            fieldOptions={
                              recordScopeConditionFieldOptions.fieldOptions
                            }
                            index={index}
                            project={project}
                            updateFields={updateFields}
                            userOptions={
                              recordScopeConditionFieldOptions.userOptions
                            }
                            showFieldTypeOptions={
                              field.type !== FILE ||
                              !isMultiRelationship(field.relationship)
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
              </div>
            )}
            <FieldConditionsEditor
              conditionFieldOptions={Object.values(
                recordScopeConditionFieldOptions,
              )}
              dataType={dataType}
              elementPath={elementPath}
              onChange={(path: ElementPath, value: any) =>
                updateFields([index, 'conditions', ...path], value)
              }
              project={project}
              value={get(config, 'conditions')}
            />
          </div>
        )}
      </FieldsListEditor>
    </>
  );
};

export default SectionFieldsListEditor;
