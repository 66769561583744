import createReactComponent from '../createReactComponent';
export default createReactComponent('toggle-right', 'IconToggleRight', [
  ['path', { d: 'M16 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M2 6m0 6a6 6 0 0 1 6 -6h8a6 6 0 0 1 6 6v0a6 6 0 0 1 -6 6h-8a6 6 0 0 1 -6 -6z',
      key: 'svg-1',
    },
  ],
]);
