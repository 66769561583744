import createReactComponent from '../createReactComponent';
export default createReactComponent('building-castle', 'IconBuildingCastle', [
  [
    'path',
    {
      d: 'M15 19v-2a3 3 0 0 0 -6 0v2a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14h4v3h3v-3h4v3h3v-3h4v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 11l18 0', key: 'svg-1' }],
]);
