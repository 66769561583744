import createReactComponent from '../createReactComponent';
export default createReactComponent('wifi-off', 'IconWifiOff', [
  ['path', { d: 'M12 18l.01 0', key: 'svg-0' }],
  ['path', { d: 'M9.172 15.172a4 4 0 0 1 5.656 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M6.343 12.343a7.963 7.963 0 0 1 3.864 -2.14m4.163 .155a7.965 7.965 0 0 1 3.287 2',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M3.515 9.515a12 12 0 0 1 3.544 -2.455m3.101 -.92a12 12 0 0 1 10.325 3.374',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
