import BaseArrayTypeMap, { JsonMaps } from './BaseArrayTypeMap';
import { FieldPermission } from './Permission';

export type ExtendedFieldPermission = FieldPermission & {
  dataFieldId?: number;
};

class FieldPermissions extends BaseArrayTypeMap<ExtendedFieldPermission> {
  fieldMap: Record<string, number>;

  constructor(arrayOrLength: ExtendedFieldPermission[] | number) {
    super(arrayOrLength);

    if (arrayOrLength instanceof FieldPermissions) {
      this.fieldMap = arrayOrLength.fieldMap || {};
    } else {
      this.fieldMap = {};
    }

    this.maps.fieldMap = this.fieldMap;
  }

  static fromJSON(
    json: JsonMaps<
      ExtendedFieldPermission,
      {
        fieldMap: Record<string, number>;
      }
    >,
  ) {
    return FieldPermissions._fromJSON(json) as FieldPermissions;
  }

  _mapEntry(fieldPermission: ExtendedFieldPermission) {
    super._mapEntry(fieldPermission);

    if (fieldPermission.dataField) {
      this.fieldMap[fieldPermission.dataField.id] = fieldPermission.id;
    } else if (fieldPermission.dataFieldId) {
      this.fieldMap[fieldPermission.dataFieldId] = fieldPermission.id;
    }
  }

  getByFieldId(fieldId: string | number) {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByIdOrUndefined(this.fieldMap[fieldId]);
  }
}

export default FieldPermissions;
