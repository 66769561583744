import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-rotary-first-left',
  'IconArrowRotaryFirstLeft',
  [
    ['path', { d: 'M16 10a3 3 0 1 1 0 -6a3 3 0 0 1 0 6z', key: 'svg-0' }],
    ['path', { d: 'M16 10v10', key: 'svg-1' }],
    ['path', { d: 'M13.5 9.5l-8.5 8.5', key: 'svg-2' }],
    ['path', { d: 'M10 18h-5v-5', key: 'svg-3' }],
  ],
);
