import { useCallback, useMemo } from 'react';
import {
  IconArrowsSort,
  IconCalendarTime,
  IconChevronDown,
  IconChevronRight,
  IconPlus,
  IconSortAscending,
  IconSortDescending,
  IconX,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import shortid from 'shortid';
import {
  Button,
  Dropdown,
  SelectInput,
  Switch,
  Tooltip,
} from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import { DataTypeValue } from '@noloco/ui/src/components/canvas/DataTypeInput';
import FieldOptionsEditor from '@noloco/ui/src/components/editor/customerEditors/FieldOptionsEditor';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  CALENDAR,
  CollectionLayout,
  MAP,
} from '../../constants/collectionLayouts';
import { darkModeColors } from '../../constants/darkModeColors';
import {
  BOOLEAN,
  DATE,
  INTEGER,
  SINGLE_OPTION,
  TEXT,
} from '../../constants/dataTypes';
import { LIST } from '../../constants/elements';
import { ASC, DESC, OrderByDirection } from '../../constants/orderByDirections';
import timePeriods, { AUTO, TimePeriod } from '../../constants/timePeriods';
import DataTypes, { DataType } from '../../models/DataTypes';
import { DepValue } from '../../models/Element';
import StateItem from '../../models/StateItem';
import { GroupBy } from '../../models/View';
import {
  DataFieldDependency,
  getFieldFromDependency,
} from '../../utils/fields';
import { getText } from '../../utils/lang';
import { isOptionType } from '../../utils/options';
import { getTypeOptionsOfTypeFromParent } from '../../utils/renderedOptions';
import { withNullOption } from '../../utils/settings';
import BuildModeInput from './BuildModeInput';

type BuildModeGroupByInputProps = {
  canMultiGroup: boolean;
  clearable?: boolean;
  dataType?: DataType;
  dataTypes: DataTypes;
  groupBy?: DepValue;
  groupByGroups: GroupBy[];
  groupBySort?: OrderByDirection;
  groupOptions?: { [key: string]: { hidden: boolean } };
  groups: GroupBy[];
  hasAdvancedGrouping: boolean;
  hideEmptyGroups: boolean;
  label?: string;
  layout: CollectionLayout;
  stateItem: StateItem;
  updateProperty: UpdatePropertyCallback;
};

const LANG_KEY = 'elements.VIEW';

export const DropdownButton = ({ children }: { children: Element }) => (
  <Button className="bg-slate-700 hover:bg-slate-900">{children}</Button>
);

export const BuildModeGroupByInput = ({
  canMultiGroup,
  clearable = true,
  dataType,
  dataTypes,
  groupBy,
  groupByGroups = [],
  groupBySort = ASC,
  groupOptions,
  groups,
  hasAdvancedGrouping,
  hideEmptyGroups,
  label,
  layout,
  stateItem,
  updateProperty,
}: BuildModeGroupByInputProps) => {
  const groupByFields = useMemo(
    () =>
      groupByGroups.map(
        (group) =>
          group.field &&
          getFieldFromDependency(
            group.field.path.split('.'),
            dataType,
            dataTypes,
          ),
      ),
    [dataType, groupByGroups, dataTypes],
  );

  const groupBySortOptions = useMemo(
    () =>
      groupByFields.map((groupField: DataFieldDependency | null | undefined) =>
        [ASC, DESC].map((sort) => ({
          label: getText(
            'elements',
            LIST,
            'sort',
            groupField &&
              !groupField.field.relationship &&
              !groupField.field.relatedField
              ? groupField.field.type
              : TEXT,
            sort,
          ),
          value: sort,
        })),
      ),
    [groupByFields],
  );

  const isGroupedByOptionField = useMemo(
    () =>
      groupByFields.some(
        (dependency) => dependency && isOptionType(dependency.field?.type),
      ),
    [groupByFields],
  );

  const groupByOptions = useMemo(() => {
    const acceptableDataTypes = [
      TEXT,
      SINGLE_OPTION,
      INTEGER,
      DATE,
      BOOLEAN,
      ...dataTypes.map((type: any) => type.name),
    ];

    if (clearable) {
      return withNullOption(
        getTypeOptionsOfTypeFromParent(
          dataTypes,
          stateItem,
          acceptableDataTypes,
        ),
      );
    }

    return getTypeOptionsOfTypeFromParent(
      dataTypes,
      stateItem,
      acceptableDataTypes,
    );
  }, [clearable, dataTypes, stateItem]);

  const onChangeGroupBySort = useCallback(
    (value, index) => {
      if (groups && groups.length > 0) {
        return updateProperty([index, 'sort'], value);
      }

      updateProperty(['groupBySort'], value);
    },
    [groups, updateProperty],
  );

  const onChangeGroupByField = useCallback(
    (field, index) => {
      if (groups) {
        if (groups.length === 0) {
          return updateProperty([], [{ id: shortid.generate(), field }]);
        }

        return updateProperty([index, 'field'], field);
      }

      updateProperty(['groupBy'], field);
    },
    [groups, updateProperty],
  );

  const onAddGroup = useCallback(() => {
    if (groups && groups.length === 3) {
      return;
    }

    const nextGroups = groups ?? [];

    if (!groups) {
      // We need to incorporate the old groupBy
      nextGroups.push({
        id: shortid.generate(),
        field: groupBy,
        sort: groupBySort,
      });
    }

    nextGroups.push({
      id: shortid.generate(),
    });

    updateProperty([], nextGroups);
  }, [groupBy, groupBySort, groups, updateProperty]);

  const onClearGroup = useCallback(
    (index: number) => {
      if (index === 0) {
        if (!groups) {
          return updateProperty(['groupBy'], null);
        }
      }

      if (groups) {
        updateProperty(
          [],
          groups.filter((__: GroupBy, idx: number) => idx !== index),
        );
      }
    },

    [groups, updateProperty],
  );

  const getSortIcon = useCallback((sort) => {
    switch (sort) {
      case ASC:
        return <IconSortAscending size={16} />;
      case DESC:
        return <IconSortDescending size={16} />;
      default:
        return <IconArrowsSort size={16} />;
    }
  }, []);

  return (
    <>
      <BuildModeInput
        label={label ?? getText(LANG_KEY, 'display.groupBy.grouping')}
      >
        <div
          className="flex flex-col space-y-2"
          data-testid="collection-group-by-input"
        >
          {groupByGroups.map(
            (
              { id, field: groupByValue, sort = ASC, timePeriod = AUTO },
              index,
            ) => (
              <div
                className={classNames('flex w-full items-center space-x-2', {
                  'opacity-50': !canMultiGroup && index !== 0,
                })}
                key={id}
              >
                <SelectInput
                  shiftRight={true}
                  Button={DataTypeValue}
                  contained={true}
                  className="w-full text-black"
                  value={groupByValue}
                  disabled={!canMultiGroup && index !== 0}
                  options={groupByOptions}
                  onChange={(value: DepValue) =>
                    onChangeGroupByField(value, index)
                  }
                  placeholder={getText(LANG_KEY, 'display.groupBy.placeholder')}
                  searchable={true}
                />
                {groupByValue && layout !== MAP && layout !== CALENDAR && (
                  <Dropdown
                    Button={DropdownButton}
                    onChange={(value: OrderByDirection) =>
                      onChangeGroupBySort(value, index)
                    }
                    options={groupBySortOptions[index]}
                    size={MD}
                    value={sort}
                    stopPropagation={true}
                  >
                    <Tooltip
                      content={
                        <span className={darkModeColors.text.primary}>
                          {getText(LANG_KEY, 'display.groupBy.sort')}
                        </span>
                      }
                      placement="top"
                      showArrow={false}
                      surface={DARK}
                    >
                      {getSortIcon(sort)}
                    </Tooltip>
                  </Dropdown>
                )}
                {groupByValue?.dataType === DATE && (
                  <Dropdown
                    Button={DropdownButton}
                    onChange={(value: TimePeriod) =>
                      updateProperty([index, 'timePeriod'], value)
                    }
                    options={timePeriods.map((timePeriodOption) => ({
                      label: getText(
                        'elements.CHART.timePeriod',
                        timePeriodOption,
                      ),
                      value: timePeriodOption,
                    }))}
                    size={MD}
                    value={timePeriod}
                    stopPropagation={true}
                  >
                    <Tooltip
                      content={
                        <span className={darkModeColors.text.primary}>
                          {getText(LANG_KEY, 'display.groupBy.timePeriod')}
                        </span>
                      }
                      placement="top"
                      showArrow={false}
                      surface={DARK}
                    >
                      <IconCalendarTime size={16} />
                    </Tooltip>
                  </Dropdown>
                )}
                {groupByGroups.length > 1 && (
                  <Button
                    className="bg-slate-800 hover:bg-slate-900"
                    onClick={() => onClearGroup(index)}
                  >
                    <IconX size={16} />
                  </Button>
                )}
              </div>
            ),
          )}
          {canMultiGroup &&
            groupByGroups.length > 0 &&
            groupByFields.filter(Boolean).length === groupByGroups.length &&
            groupByGroups.length <= 2 && (
              <Button
                className="flex w-fit items-center space-x-2 bg-slate-700 hover:bg-slate-900"
                onClick={onAddGroup}
                size={SM}
              >
                <IconPlus className="opacity-75" size={16} />
                <span>{getText(LANG_KEY, 'display.groupBy.addGroup')}</span>
              </Button>
            )}
        </div>
      </BuildModeInput>
      {groupByGroups.length > 0 && (
        <>
          {hasAdvancedGrouping && isGroupedByOptionField && (
            <div className="flex items-center justify-between text-gray-400">
              <label className="text-xs">
                {getText(LANG_KEY, 'display.showEmptyGroups')}
              </label>
              <Switch
                onChange={(value: boolean) =>
                  updateProperty(['hideEmptyGroups'], !value)
                }
                size={SM}
                value={!hideEmptyGroups}
              />
            </div>
          )}
          {groupByFields[0] && hasAdvancedGrouping && (
            <FieldOptionsEditor
              field={groupByFields[0].field}
              onChange={(path, value) =>
                updateProperty(['groupOptions', ...path], value)
              }
              values={groupOptions}
            >
              {({ onChange, value }: any) => (
                <Tooltip
                  content={getText(
                    LANG_KEY,
                    'display.groupBy.collapsed',
                    get(value, 'collapsed', false),
                  )}
                  bg="white"
                >
                  <button
                    className="p-1"
                    onClick={() =>
                      onChange(['collapsed'], !get(value, 'collapsed', false))
                    }
                  >
                    {get(value, 'collapsed', false) ? (
                      <IconChevronRight size={16} />
                    ) : (
                      <IconChevronDown size={16} />
                    )}
                  </button>
                </Tooltip>
              )}
            </FieldOptionsEditor>
          )}
        </>
      )}
    </>
  );
};
