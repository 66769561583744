import createReactComponent from '../createReactComponent';
export default createReactComponent('globe-off', 'IconGlobeOff', [
  [
    'path',
    {
      d: 'M8.36 8.339a4 4 0 0 0 5.281 5.31m2 -1.98a4 4 0 0 0 -5.262 -5.325',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M6.75 16a8.015 8.015 0 0 0 9.799 .553m2.016 -2a8.015 8.015 0 0 0 -2.565 -11.555',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 18v4', key: 'svg-2' }],
  ['path', { d: 'M8 22h8', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
