import React, {
  Suspense,
  forwardRef,
  lazy,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import {
  IconChevronDown,
  IconChevronRight,
  IconGripVertical,
} from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader, Tooltip } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { useUpdateProperty } from '@noloco/ui/src/utils/hooks/projectHooks';
import { DIVIDER, LINK } from '../../../constants/elements';
import Icon from '../../../elements/Icon';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { setLeftEditorSection } from '../../../reducers/elements';
import { scopeSelector } from '../../../selectors/dataSelectors';
import useRouter from '../../../utils/hooks/useRouter';
import { getText } from '../../../utils/lang';
import { resolveNavigationObject } from '../../../utils/navigation';
import { Page, getPagesConfig } from '../../../utils/pages';
import EditableLabel from '../../editor/EditableLabel';
import { sanitiseRoutePath } from '../../editor/PageEditor';
import BuildModeOptionsMenu from '../BuildModeOptionsMenu';

const LazyIconEditor = lazy(() => import('../../editor/IconEditor'));

type BuildModeNavItemProps = {
  active: boolean;
  elementPath: ElementPath;
  handlePageClone: (page?: Page) => void;
  handlePageRemove: () => void;
  hasChildPages?: boolean;
  hideIcon?: boolean;
  icon?: string;
  isDragging: boolean;
  isOver: boolean;
  page: Page;
  project: Project;
  sidebarExpanded?: boolean;
  to: string;
};

const DEBOUNCE_TIME = 500;

const BuildModeNavItem = forwardRef(
  (
    {
      active,
      elementPath,
      handlePageClone,
      handlePageRemove,
      hasChildPages,
      hideIcon,
      icon,
      isDragging,
      isOver,
      page,
      project,
      sidebarExpanded = true,
      to,
    }: BuildModeNavItemProps,
    dragRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { replace, push } = useRouter();
    const dispatch = useDispatch();
    const [updateProperty] = useUpdateProperty(elementPath, project);
    const debouncedUpdateProperty = useMemo(
      () => debounce(updateProperty, DEBOUNCE_TIME),
      [updateProperty],
    );
    const [localPageName, setLocalPageName] = useState(page.props.name);
    const scope = useSelector(scopeSelector);
    const [inputIsOpened, setInputIsOpened] = useState(false);
    const [iconEditorIsOpen, setIconEditorIsOpen] = useState(false);
    const [optionsMenuIsOpen, setOptionsMenuIsOpen] = useState(false);
    const [localName, setLocalName] = useState(page.props.name);
    const [localRoutePath, setLocalRoutePath] = useState(page.props.routePath);
    const parentPage = get(page, 'props.parentPage', null);
    const pages = useMemo(
      () =>
        get(
          getPagesConfig(project.elements, project.settings),
          'projectPages',
          [],
        ).filter((page) => ![DIVIDER, LINK].includes(page.type)),
      [project],
    );

    const siblingPages = useMemo(
      () =>
        pages.filter(
          ({ id, props: { parentPage: parentPageId } = {} }) =>
            id !== page.id &&
            ((!parentPage && !parentPageId) ||
              (parentPage && parentPage === parentPageId)),
        ),
      [page.id, pages, parentPage],
    );

    const siblingRoutePaths = siblingPages.map(
      ({ props: { routePath: siblingRoutePath = '' } = {} }) =>
        siblingRoutePath,
    );

    const replaceRoute = useMemo(
      () => debounce((routePath) => replace(routePath), DEBOUNCE_TIME),
      [replace],
    );

    const handlePageNameUpdate = useCallback(() => {
      setLocalName(localPageName);
      setInputIsOpened(false);

      if (
        (!localName && !localRoutePath) ||
        sanitiseRoutePath(localName) === localRoutePath
      ) {
        const nextRoutePath = sanitiseRoutePath(localPageName);
        if (!siblingRoutePaths.includes(nextRoutePath)) {
          return updateProperty(['name'], localPageName);
        }

        setLocalRoutePath(nextRoutePath);
        updateProperty([], {
          ...page.props,
          name: localPageName,
          routePath: nextRoutePath,
        });
        return replaceRoute(nextRoutePath);
      }

      updateProperty(['name'], localPageName);
      replaceRoute(localRoutePath);
    }, [
      localName,
      localPageName,
      localRoutePath,
      page.props,
      replaceRoute,
      siblingRoutePaths,
      updateProperty,
    ]);

    const { navItemIs, href, navItemTo } = useMemo(() => {
      if (page.type !== LINK) {
        return { navItemTo: to, navItemIs: Link };
      }

      const link = get(page, 'props.link');
      if (!link) {
        return { href: undefined, navItemIs: 'span' };
      }

      const resolvedLink = resolveNavigationObject(link, scope, project);

      if (resolvedLink.href && resolvedLink.href.startsWith('/')) {
        return {
          navItemTo: resolvedLink.href,
          navItemIs: Link,
        };
      }

      if (resolvedLink.to) {
        return { navItemTo: resolvedLink.to, navItemIs: Link };
      }

      return { href: resolvedLink.href, navItemIs: 'a' };
    }, [page, project, scope, to]);

    const handleIconUpdate = useCallback(
      (path: ElementPath, icon: string | null) =>
        updateProperty(['icon', ...path], icon),
      [updateProperty],
    );

    const hideEndIcons = useMemo(
      () =>
        !active || (!inputIsOpened && !iconEditorIsOpen && !optionsMenuIsOpen),
      [active, inputIsOpened, iconEditorIsOpen, optionsMenuIsOpen],
    );

    return (
      <Box
        className={classNames('group relative my-0.5 flex-grow', {
          'text-slate-200 hover:bg-slate-600': !active,
          'bg-slate-900 text-white': active || isOver,
          'rounded-lg px-1': !isOver,
          'px-1': isOver,
          'opacity-75': isDragging,
          'mx-3': sidebarExpanded,
          'mx-6': !sidebarExpanded,
          'bg-slate-900':
            page.type === LINK && (optionsMenuIsOpen || iconEditorIsOpen),
        })}
        is={navItemIs}
        key={page.id}
        target={href ? '_blank' : undefined}
        to={navItemTo}
        onClick={() => dispatch(setLeftEditorSection(null))}
      >
        <Tooltip
          content={<span className="text-slate-200">{page.props.name}</span>}
          disabled={sidebarExpanded}
          placement="right"
          surface={DARK}
          offset={[0, 16]}
        >
          <div
            className={classNames('flex h-8 w-full items-center', {
              'justify-center': !sidebarExpanded,
            })}
          >
            {sidebarExpanded && (
              <div
                className={classNames(
                  'absolute -left-4 ml-0.5 cursor-move items-center opacity-75 hover:opacity-100 group-hover:flex',
                  {
                    hidden:
                      !inputIsOpened && !iconEditorIsOpen && !optionsMenuIsOpen,
                  },
                )}
                ref={dragRef}
              >
                <IconGripVertical size={16} />
              </div>
            )}
            <div
              className={classNames(
                'flex items-center justify-center rounded-md',
                { 'mr-1': sidebarExpanded },
              )}
            >
              {!hideIcon && (
                <div
                  className={classNames(
                    'flex items-center rounded-md opacity-75 hover:opacity-100',
                    { 'p-1 hover:bg-slate-700': sidebarExpanded },
                  )}
                  onClick={() =>
                    sidebarExpanded ? setIconEditorIsOpen(true) : null
                  }
                >
                  {iconEditorIsOpen && (
                    <Suspense fallback={<Loader />}>
                      <LazyIconEditor
                        placement={'right'}
                        customInput={
                          <div className="h-4 w-4">
                            <Icon
                              icon={page.props.icon || { name: icon }}
                              className="h-full w-full"
                            />
                          </div>
                        }
                        clearable={true}
                        updateProperty={handleIconUpdate}
                        elementProps={page.props.icon || { name: icon }}
                        surface={DARK}
                        iconEditorIsOpen={iconEditorIsOpen}
                        setIconEditorIsOpen={setIconEditorIsOpen}
                      />
                    </Suspense>
                  )}
                  {!iconEditorIsOpen && (
                    <Icon
                      icon={page.props.icon || { name: icon }}
                      className="h-4 w-4"
                    />
                  )}
                </div>
              )}
            </div>
            {sidebarExpanded && (
              <>
                <EditableLabel
                  value={localPageName}
                  onChange={setLocalPageName}
                  onUpdate={handlePageNameUpdate}
                  placeholder={getText('elements.PAGE.name.label')}
                  active={active}
                  isLink={page.type === LINK}
                  onEditModeEnter={() => {
                    if (page.type !== LINK) {
                      push(navItemTo);
                    }
                  }}
                  rightOffset="8"
                  iconEditorIsOpen={iconEditorIsOpen}
                />
                <div className="ml-auto flex h-8 items-center justify-end">
                  {hasChildPages && (
                    <button
                      className={classNames(
                        'ml-2 mr-1 items-center opacity-75 group-hover:hidden group-hover:opacity-100',
                        {
                          hidden:
                            inputIsOpened ||
                            iconEditorIsOpen ||
                            optionsMenuIsOpen,
                        },
                      )}
                    >
                      {active ? (
                        <IconChevronDown size={16} />
                      ) : (
                        <IconChevronRight size={16} />
                      )}
                    </button>
                  )}
                  <BuildModeOptionsMenu
                    debouncedUpdateProperty={debouncedUpdateProperty}
                    elementPath={elementPath}
                    handleClone={handlePageClone}
                    handleRemove={handlePageRemove}
                    hidden={hideEndIcons}
                    isOpen={optionsMenuIsOpen}
                    onOpenChange={(open: boolean) => setOptionsMenuIsOpen(open)}
                    page={page}
                    project={project}
                    updateProperty={updateProperty}
                  />
                </div>
              </>
            )}
          </div>
        </Tooltip>
      </Box>
    );
  },
);

export default BuildModeNavItem;
