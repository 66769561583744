import createReactComponent from '../createReactComponent';
export default createReactComponent('writing', 'IconWriting', [
  [
    'path',
    {
      d: 'M20 17v-12c0 -1.121 -.879 -2 -2 -2s-2 .879 -2 2v12l2 2l2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 7h4', key: 'svg-1' }],
  [
    'path',
    { d: 'M18 19h-13a2 2 0 1 1 0 -4h4a2 2 0 1 0 0 -4h-3', key: 'svg-2' },
  ],
]);
