import React, { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Loader } from '@noloco/components';
import { formatDisplayField } from '../../../utils/dataTypes';
import { getClassNameFromHeaderWidth } from '../../utils/headerWidth';
import Title from '../Title';
import RecordCommentsButton from './RecordCommentsButton';
import ViewBreadcrumbs from './ViewBreadcrumbs';

export const getTitleValue = (titleField: any, data: any) =>
  titleField && !titleField.relationship
    ? formatDisplayField(titleField, get(data, titleField.apiName))
    : null;

const RecordViewTitle = ({
  backLink,
  children,
  className,
  comments = {},
  data,
  dataType,
  headerWidth,
  icon,
  loading,
  isSplitLayout,
  name,
  image,
  rootPathname,
  title,
  subtitle,
  hideBreadcrumbs,
}: any) => {
  const titleField = useMemo(
    () => title && dataType && dataType.fields.getByName(title),
    [dataType, title],
  );

  const titleValue = useMemo(
    () => getTitleValue(titleField, data),
    [data, titleField],
  );

  const imageValue = useMemo(
    () => (image && image.src ? { hidden: false, value: image } : undefined),
    [image],
  );

  return (
    <Title
      className={classNames(
        'mx-auto w-full bg-center py-6 sm:py-3',
        getClassNameFromHeaderWidth(headerWidth),
        className,
      )}
      breadcrumbs={
        (!hideBreadcrumbs || comments.show) && (
          <div className="relative flex min-h-[50px] w-full items-center justify-between">
            {!hideBreadcrumbs && (
              <ViewBreadcrumbs
                className={classNames(
                  'mb-2 sm:mb-1 sm:w-11/12 sm:text-xs',
                  isSplitLayout ? 'hidden md:flex' : 'flex',
                )}
                additionalLinks={
                  !isSplitLayout
                    ? [
                        {
                          to: `/view/${get(data, 'uuid')}`,
                          name: !loading ? (
                            (titleField && titleValue) || dataType.display
                          ) : (
                            <Loader size="sm" />
                          ),
                        },
                      ]
                    : []
                }
                backLink={backLink}
                icon={isSplitLayout ? { name: 'ArrowBack' } : icon}
                name={name}
                rootPathname={rootPathname}
              />
            )}
            {comments.show && (
              <div className="absolute right-0 mr-2">
                <RecordCommentsButton record={data} />
              </div>
            )}
          </div>
        )
      }
      image={imageValue}
      subtitle={{
        hidden: !subtitle,
        value: subtitle,
      }}
      title={{
        hidden: !titleField || titleField.relationship,
        value: !loading ? (
          titleValue
        ) : (
          <Loader size="sm" className="text-gray-600" type="Dots" />
        ),
      }}
    >
      {children}
    </Title>
  );
};

export default RecordViewTitle;
