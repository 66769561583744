import get from 'lodash/get';
import { ImageType, UPLOAD } from '../constants/imageTypes';
import { MediaFile } from '../models/File';
import { ProjectSettings } from '../models/Project';

export const getMediaImage = (
  mediaId: number,
  media: MediaFile[],
): MediaFile | undefined => media.find(({ id }) => id === mediaId);

export const getMediaImageSrc = (
  mediaId: number,
  media: MediaFile[],
): string | null | undefined => {
  if (!mediaId) {
    return null;
  }
  const selectedMedia = getMediaImage(mediaId, media);
  if (selectedMedia) {
    return selectedMedia?.uri;
  }

  return null;
};

export const getImageSrc = (
  type: ImageType,
  src: string,
  mediaId: number,
  media: MediaFile[],
  editorMode: boolean,
  placeholder: string,
) => {
  if (type !== UPLOAD) {
    return src && src.trim && src.trim().length > 0 ? src : placeholder;
  }

  const mediaImageSrc = getMediaImageSrc(mediaId, media);
  if (mediaImageSrc) {
    return mediaImageSrc;
  }

  return editorMode ? placeholder : null;
};

const PLACE_HOLDER_LOGO = 'https://media.noloco.app/344-infinity-white@2x.png';

export const getProjectLogo = (
  settings: ProjectSettings,
  media: MediaFile[],
) => {
  const logoId = get(settings, 'logo.mediaId');

  const logoUrl = logoId && getMediaImageSrc(logoId, media);

  return logoUrl || PLACE_HOLDER_LOGO;
};

export const getProjectAuthLogo = (
  settings: ProjectSettings,
  media: MediaFile[],
) => {
  const loginLogoId = get(settings, 'login.logo.mediaId', null);
  const authLogoId = get(settings, 'authLogo.mediaId', null);
  const logoId = loginLogoId || authLogoId;

  const logoUrl = logoId && getMediaImageSrc(logoId, media);

  return logoUrl || getProjectLogo(settings, media);
};
