import createReactComponent from '../createReactComponent';
export default createReactComponent('plane-off', 'IconPlaneOff', [
  [
    'path',
    {
      d: 'M9.788 5.758l-.788 -2.758h3l4 7h4a2 2 0 1 1 0 4h-2m-2.718 1.256l-3.282 5.744h-3l2 -7h-4l-2 2h-3l2 -4l-2 -4h3l2 2h3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
]);
