import createReactComponent from '../createReactComponent';
export default createReactComponent('device-airpods', 'IconDeviceAirpods', [
  [
    'path',
    {
      d: 'M6 4a4 4 0 0 1 4 3.8l0 .2v10.5a1.5 1.5 0 0 1 -3 0v-6.5h-1a4 4 0 0 1 -4 -3.8l0 -.2a4 4 0 0 1 4 -4z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18 4a4 4 0 0 0 -4 3.8l0 .2v10.5a1.5 1.5 0 0 0 3 0v-6.5h1a4 4 0 0 0 4 -3.8l0 -.2a4 4 0 0 0 -4 -4z',
      key: 'svg-1',
    },
  ],
]);
