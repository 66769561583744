import React, { useCallback, useMemo } from 'react';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import DataCell from '@noloco/ui/src/components/dataTable/DataCell';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import MarkdownText from '../../../components/MarkdownText';
import { UPDATE } from '../../../constants/actionTypes';
import { ROWS } from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import { DETAILS, TEXT } from '../../../constants/elements';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import { User } from '../../../models/User';
import { FormFieldConfig } from '../../../models/View';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import { getRootFieldDataType } from '../../../utils/fields';
import { useErrorAlert } from '../../../utils/hooks/useAlerts';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import { AutoFormProvider } from '../../../utils/hooks/useAutoForm';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useDarkModeSurface from '../../../utils/hooks/useDarkModeSurface';
import useMergedScope from '../../../utils/hooks/useMergedScope';
import useRouter from '../../../utils/hooks/useRouter';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { getText } from '../../../utils/lang';
import { mapFieldsWithPermissionsAndConfig } from '../../../utils/permissions';
import { RECORD_SCOPE } from '../../../utils/scope';
import { ValidationError } from '../../../utils/validationRules';
import FieldCell, { getFormFieldConfig } from '../collections/FieldCell';
import AutoForm from '../forms/AutoForm';
import ActionButton from './ActionButton';
import RecordEmptyState from './RecordEmptyState';

const Label = ({ config, htmlFor, label, required }: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  const textColor = isDarkModeEnabled
    ? darkModeColors.text.secondary
    : 'text-gray-500';
  return (
    <label
      className={classNames(
        'mb-2 text-xs font-medium tracking-wider',
        textColor,
      )}
      htmlFor={htmlFor}
    >
      <span>{label}</span>
      {required && !config.readOnly && (
        <span className="ml-px opacity-75">*</span>
      )}
    </label>
  );
};

const columnWidth = (config: any) =>
  config.columnWidth || config.columnSpan * 4;

const RecordDetails = ({
  actionButtons,
  backLink,
  columns,
  dataType,
  editorMode,
  elementPath,
  fields,
  rootPathname,
  project,
  isEditingData,
  onLoadingChange,
  onError,
  record,
  rootField,
  subtitle,
  title,
  sectionId,
  recordScope,
  sectionWidth,
  toggleSectionExpandedState,
  isExpanded,
  isModal,
  collapsable,
}: any) => {
  const { user } = useAuthWrapper();
  const { push } = useRouter();
  const surface = useDarkModeSurface();
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const [isDarkModeEnabled] = useDarkMode();
  const errorAlert = useErrorAlert();

  const {
    actionButtons: parsedActionButtons,
    title: resolvedTitle,
    subtitle: resolvedSubTitle,
    collapsable: isCollapsable,
  } = useSectionScopeVariables(
    DETAILS,
    { actionButtons, title, subtitle, collapsable },
    project,
    elementPath,
    recordScope,
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  const rootDataType = useMemo(
    () => getRootFieldDataType(rootField, dataType, project.dataTypes),
    [dataType, project.dataTypes, rootField],
  );

  const fieldConfigs = useMemo(
    () =>
      mapFieldsWithPermissionsAndConfig(
        fields,
        rootDataType,
        user as User,
        project.dataTypes,
        fieldPermissionsEnabled,
      ),
    [fieldPermissionsEnabled, fields, project.dataTypes, rootDataType, user],
  );

  const rootFieldObject = useMemo(
    () => rootField && dataType.fields.getByName(rootField),
    [dataType.fields, rootField],
  );

  const valuePrefix = useMemo(
    () => (rootFieldObject ? [rootFieldObject.apiName] : []),
    [rootFieldObject],
  );

  const scope = useMergedScope(recordScope);
  const transformRecordScope = useCallback(
    (currentScope: any) => ({
      ...scope,
      ...currentScope,
    }),
    [scope],
  );

  const isSmallContainer = !!sectionWidth && sectionWidth <= 6;

  const formFields = useMemo(
    () =>
      fieldConfigs.map(({ field, config, permissions }) => ({
        field,
        config: {
          ...getFormFieldConfig(
            field,
            project,
            user,
            fieldPermissionsEnabled,
            config as FormFieldConfig,
          ),
          className: classNames('md:col-span-1', {
            'col-span-3 lg:col-span-6':
              columnWidth(config) === 3 ||
              (!(config as any).columnWidth &&
                !(config as any).columnSpan &&
                sectionWidth !== 6),
            'col-span-6 lg:col-span-12':
              !(config as any).columnWidth &&
              !(config as any).columnSpan &&
              sectionWidth === 6,
            'col-span-4 lg:col-span-6': columnWidth(config) === 4,
            'col-span-6': columnWidth(config) === 6,
            'col-span-8 lg:col-span-12': columnWidth(config) === 8,
            'col-span-9 lg:col-span-12': columnWidth(config) === 9,
            'col-span-12':
              columnWidth(config) === 12 ||
              (!(config as any).columnWidth &&
                !(config as any).columnSpan &&
                sectionWidth === 3),
            'lg:col-span-12': isSmallContainer,
          }),
          label:
            (config as any).label &&
            !(config as any).label.hidden &&
            (config as any).label.value,
          readOnly:
            !permissions.update ||
            field.readOnly ||
            (config as any).elementType === TEXT ||
            (!isEditingData && !(config as any).editInline),
          ReadOnlyCell: () => (
            <FieldCell
              backLink={backLink}
              columns={columns}
              config={config}
              dataType={rootDataType}
              field={field}
              layout={ROWS}
              className="flex flex-col"
              readOnly={true}
              showLabel={false}
              showInput={false}
              permissions={permissions}
              record={record}
              value={get(record, [...valuePrefix, field.apiName])}
              project={project}
              key={field.name}
              section={true}
              transformScope={transformRecordScope}
            />
          ),
        },
      })),
    [
      fieldConfigs,
      project,
      user,
      fieldPermissionsEnabled,
      sectionWidth,
      isSmallContainer,
      isEditingData,
      backLink,
      columns,
      rootDataType,
      record,
      valuePrefix,
      transformRecordScope,
    ],
  );

  const formatRecordScopeValue = useCallback(
    (formRecord: any) =>
      rootFieldObject
        ? set(formRecord, [rootFieldObject.apiName], record)
        : formRecord,
    [record, rootFieldObject],
  );

  const hasActionButtons =
    parsedActionButtons && parsedActionButtons.filter(Boolean).length > 0;

  const hasTitle = !!(
    resolvedTitle &&
    resolvedTitle.value &&
    resolvedTitle.value !== ''
  );

  const hasSubtitle = !!(
    resolvedSubTitle &&
    !resolvedSubTitle.hidden &&
    resolvedSubTitle.value &&
    resolvedSubTitle.value !== ''
  );

  const isSectionExpanded = useMemo(
    () => (hasTitle ? get(isExpanded, sectionId, false) : true),
    [hasTitle, isExpanded, sectionId],
  );

  const handleOnClick = useCallback(() => {
    toggleSectionExpandedState(sectionId);
    if (!isSectionExpanded) {
      setTimeout(() => {
        const section = document.getElementById(`section-id-${sectionId}`);
        section?.scrollIntoView({
          behavior: 'smooth',
          inline: 'start',
          block: 'start',
        });
      }, 100);
    }
  }, [isSectionExpanded, sectionId, toggleSectionExpandedState]);

  const shouldRender = formFields.length > 0 || hasTitle || hasSubtitle;

  const handleDeleteRecord = useCallback(
    (__, onNext) => {
      if (backLink && backLink.enabled) {
        push(backLink.to);
      } else {
        push(rootPathname);
      }
      onNext();
    },
    [backLink, push, rootPathname],
  );

  const showFields = useMemo(
    () =>
      (!isCollapsable || (isCollapsable && isSectionExpanded)) &&
      formFields.length > 0,
    [isCollapsable, isSectionExpanded, formFields],
  );

  const showToggle = useMemo(
    () => isCollapsable && hasTitle,
    [isCollapsable, hasTitle],
  );

  if (!shouldRender && !editorMode) {
    return null;
  }

  if (!shouldRender && editorMode) {
    return <RecordEmptyState />;
  }

  return (
    <div
      className={`flex flex-col rounded-lg text-sm ${
        isDarkModeEnabled
          ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
          : 'border-gray-200 bg-white'
      } ${!isModal ? 'border shadow-md' : ''}`}
      data-testid="details-section"
    >
      {(hasTitle || hasSubtitle || hasActionButtons) && (
        <div
          onClick={showToggle ? handleOnClick : undefined}
          className={classNames(
            `flex flex-col p-6 ${isCollapsable ? 'hover:cursor-pointer' : ''}`,
            isDarkModeEnabled
              ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
              : 'bg-gray-50',
            { 'rounded-lg': !showFields, 'rounded-t-lg border-b': showFields },
          )}
        >
          <div className="flex w-full max-w-full items-start justify-between sm:flex-wrap sm:gap-2">
            <div className="flex items-center space-x-2">
              {showToggle &&
                (isSectionExpanded ? (
                  <IconChevronDown size={16} />
                ) : (
                  <IconChevronRight size={16} />
                ))}
              {hasTitle && (
                <h2 className="text-2xl font-medium tracking-wider sm:overflow-visible sm:whitespace-normal">
                  {resolvedTitle.value}
                </h2>
              )}
            </div>
            {hasActionButtons && (
              <div
                className={classNames(
                  'ml-auto flex flex-wrap items-center justify-end gap-2',
                )}
              >
                {parsedActionButtons
                  .filter(Boolean)
                  .map((actionButton: any, actionButtonIndex: any) => (
                    <ActionButton
                      actionButton={actionButton}
                      dataType={dataType}
                      editorMode={editorMode}
                      index={actionButtonIndex}
                      key={actionButtonIndex}
                      record={record}
                      recordScope={recordScope}
                      onDeleteRecord={handleDeleteRecord}
                      project={project}
                    />
                  ))}
              </div>
            )}
          </div>
          {hasSubtitle && (
            <div className={classNames('w-full', { 'mt-2': hasTitle })}>
              <MarkdownText disabledHeadings={false}>
                {resolvedSubTitle.value}
              </MarkdownText>
            </div>
          )}
        </div>
      )}
      {showFields && (
        <AutoFormProvider
          fields={formFields}
          dataType={rootDataType}
          inline={false}
          formatRecordScope={formatRecordScopeValue}
          transformRecordScope={transformRecordScope}
          value={
            rootFieldObject ? get(record, rootFieldObject.apiName) : record
          }
          Label={Label}
          mutationType={UPDATE}
          onLoadingChange={onLoadingChange}
          submitOnBlur={true}
          onError={(error) => {
            if (!(error instanceof ValidationError)) {
              errorAlert(getText('project.errors.generic'));
            }
            onError();
          }}
          onErrorResetFormFieldValues={true}
          readOnly={
            rootFieldObject && !get(record, [rootFieldObject.apiName, 'id'])
          }
          surface={surface}
          ReadOnlyCell={DataCell}
        >
          <AutoForm
            className={classNames('grid gap-x-4 gap-y-8 p-6 md:grid-cols-1', {
              'grid-cols-12': fieldConfigs.length > 0,
            })}
          />
        </AutoFormProvider>
      )}
    </div>
  );
};

RecordDetails.defaultProps = {
  fields: [],
  isExpanded: {},
  isModal: false,
};

export default RecordDetails;
