import createReactComponent from '../createReactComponent';
export default createReactComponent('screen-share-off', 'IconScreenShareOff', [
  [
    'path',
    {
      d: 'M21 12v3a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h9',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 20l10 0', key: 'svg-1' }],
  ['path', { d: 'M9 16l0 4', key: 'svg-2' }],
  ['path', { d: 'M15 16l0 4', key: 'svg-3' }],
  ['path', { d: 'M17 8l4 -4m-4 0l4 4', key: 'svg-4' }],
]);
