import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
// eslint-disable-next-line no-restricted-imports
import useDarkMode from '@noloco/core/src/utils/hooks/useDarkMode';
import { DARK } from '../../constants/surface';
import { PRIMARY } from '../../constants/variants';
import { Surface } from '../../models/index';
import { getColorShade } from '../../utils/colors';
import {
  ROUNDED_FULL,
  ROUNDED_LARGE,
  SHADOW,
  SQUARE,
  UPPERCASE,
} from '../button/buttonStyles';
import { FILLED, OUTLINE, THICK_OUTLINE } from './badgeTypes';

export const typeStyles = (color: string, useDarkModeColors: boolean) => {
  const bgOrOutlineColor = getColorShade(color, useDarkModeColors ? 700 : 200);
  const baseStyles = {
    bg: bgOrOutlineColor,
    text: [getColorShade(color, useDarkModeColors ? 200 : 700), 'xs'],
  };

  return {
    default: baseStyles,
    [FILLED]: baseStyles,
    [OUTLINE]: {
      ...baseStyles,
      bg: 'transparent',
      border: [true, bgOrOutlineColor],
    },
    [THICK_OUTLINE]: {
      ...baseStyles,
      bg: 'transparent',
      border: [2, bgOrOutlineColor],
    },
  };
};

export const themeStyles = {
  default: { rounded: true },
  [ROUNDED_LARGE]: { rounded: 'lg' },
  [ROUNDED_FULL]: { rounded: 'full' },
  [SQUARE]: { rounded: false },
  [UPPERCASE]: { uppercase: true },
  [SHADOW]: { rounded: true, shadow: true },
};

type BadgeProps = {
  ignoreDarkMode?: boolean;
  children: React.ReactNode;
  className?: string;
  onRemove: () => void;
  removable?: boolean;
  style?: any; // TODO: oneOfWithDefault(buttonStyles)
  surface?: Surface;
  theme: {};
  type?: any; // TODO: oneOfWithDefault(buttonTypes)
  // @ts-expect-error TS(2749): 'oneOfVariants' refers to a value, but is being us... Remove this comment to see the full error message
  variant?: oneOfVariants;
};

const Badge = forwardRef<any, BadgeProps>(
  (
    {
      ignoreDarkMode,
      // @ts-expect-error TS(2339): Property 'color' does not exist on type 'BadgeProp... Remove this comment to see the full error message
      color,
      children,
      className,
      onRemove,
      removable,
      style,
      surface,
      type,
      theme,
      variant,
      ...rest
    }: BadgeProps,
    ref,
  ) => {
    const badgeColor = color || (theme as any).brandColors[variant];
    const [isDarkModeEnabled] = useDarkMode();
    const useDarkModeColors =
      surface !== undefined
        ? surface === DARK
        : !ignoreDarkMode && isDarkModeEnabled;

    return (
      <Box
        className={classNames(
          'inline-flex max-w-full flex-row items-center justify-center whitespace-normal',
          className,
        )}
        is="span"
        ref={ref}
        p={{ x: removable ? 2 : 3, y: 0 }}
        m={{ r: 'auto' }}
        {...rest}
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        {...themeStyles[style]}
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        {...typeStyles(badgeColor, useDarkModeColors as boolean)[type]}
      >
        <div className="flex items-center justify-center">{children}</div>
        {removable && (
          <IconX
            className={classNames(
              'my-auto ml-1 cursor-pointer',
              `hover:text-${getColorShade(color, 500)}`,
            )}
            size={12}
            onClick={onRemove}
          />
        )}
      </Box>
    );
  },
);

Badge.defaultProps = {
  className: '',
  type: 'default',
  style: 'default',
  variant: PRIMARY,
};

Badge.displayName = 'Badge';

export default withTheme(Badge);
