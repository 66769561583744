import createReactComponent from '../createReactComponent';
export default createReactComponent('printer', 'IconPrinter', [
  [
    'path',
    {
      d: 'M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M7 13m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z',
      key: 'svg-2',
    },
  ],
]);
