import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circle-arrow-right',
  'IconCircleArrowRight',
  [
    ['path', { d: 'M12 3a9 9 0 1 0 0 18a9 9 0 0 0 0 -18', key: 'svg-0' }],
    ['path', { d: 'M16 12l-4 -4', key: 'svg-1' }],
    ['path', { d: 'M16 12h-8', key: 'svg-2' }],
    ['path', { d: 'M12 16l4 -4', key: 'svg-3' }],
  ],
);
