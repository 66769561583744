import createReactComponent from '../createReactComponent';
export default createReactComponent('helmet', 'IconHelmet', [
  [
    'path',
    {
      d: 'M12 4a9 9 0 0 1 5.656 16h-11.312a9 9 0 0 1 5.656 -16z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20 9h-8.8a1 1 0 0 0 -.968 1.246c.507 2 1.596 3.418 3.268 4.254c2 1 4.333 1.5 7 1.5',
      key: 'svg-1',
    },
  ],
]);
