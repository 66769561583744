import createReactComponent from '../createReactComponent';
export default createReactComponent('armchair-2-off', 'IconArmchair2Off', [
  [
    'path',
    {
      d: 'M5 10v-4a3 3 0 0 1 .128 -.869m2.038 -2.013c.264 -.078 .544 -.118 .834 -.118h8a3 3 0 0 1 3 3v4',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16.124 12.145a3 3 0 1 1 3.756 3.724m-.88 3.131h-14v-3a3 3 0 1 1 3 -3v2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 12h4', key: 'svg-2' }],
  ['path', { d: 'M7 19v2', key: 'svg-3' }],
  ['path', { d: 'M17 19v2', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
