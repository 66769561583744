import createReactComponent from '../createReactComponent';
export default createReactComponent('plane-inflight', 'IconPlaneInflight', [
  [
    'path',
    {
      d: 'M15 11.085h5a2 2 0 1 1 0 4h-15l-3 -6h3l2 2h3l-2 -7h3l4 7z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 21h18', key: 'svg-1' }],
]);
