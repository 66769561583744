import { useMemo } from 'react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { Redirect } from 'react-router-dom';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import BuildModeSectionOptions from '../../../components/buildMode/BuildModeSectionOptions';
import { USER } from '../../../constants/builtInDataTypes';
import { darkModeColors } from '../../../constants/darkModeColors';
import { PAGE } from '../../../constants/elements';
import { CUSTOM_VISIBILITY_RULES } from '../../../constants/features';
import { Element, ElementPath, ViewTab } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { shouldRenderComponent } from '../../../utils/elementVisibility';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useRouter from '../../../utils/hooks/useRouter';
import useScopeUser from '../../../utils/hooks/useScopeUser';
import useSelectedTab from '../../../utils/hooks/useSelectedTab';
import useTrackAppPage, {
  PageTypes,
} from '../../../utils/hooks/useTrackAppPage';
import { getClassNameFromHeaderWidth } from '../../utils/headerWidth';
import Title from '../Title';
import RecordViewSections from './RecordViewSections';
import RecordViewTabs from './RecordViewTabs';

const nullFn = () => null;

type BlankPageProps = {
  editorMode?: boolean;
  elementId: string;
  elementPath: ElementPath;
  headerWidth?: number | string;
  project: Project;
  routePathName?: string;
  scope: Record<string, any>;
  sections: Element[];
  subtitle?: string;
  tabs: ViewTab[];
  title?: string;
};

const BlankPage = ({
  editorMode,
  elementId,
  elementPath,
  headerWidth,
  project,
  routePathName,
  scope,
  sections,
  subtitle,
  tabs = [],
  title,
}: BlankPageProps) => {
  const {
    pathname,
    location,
    query: { tab },
  } = useRouter();
  const [isDarkModeEnabled] = useDarkMode();
  const user = useScopeUser();

  const userTypeWithRelations = useMemo(
    () => project.dataTypes.getByName(USER)!,
    [project.dataTypes],
  );

  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);

  const pageId = `${elementId}:PAGE`;
  const recordScope = useMemo(
    () => ({
      [pageId]: {
        _dataType: USER,
        ...user,
      },
    }),
    [pageId, user],
  );

  useTrackAppPage(PageTypes.BLANK);

  const visibleTabs: ViewTab[] = useMemo(() => {
    if (!Array.isArray(tabs)) {
      return [];
    }

    return editorMode
      ? tabs
      : tabs.filter((tab) =>
          shouldRenderComponent(
            user,
            tab.visibilityRules,
            project,
            scope,
            customRulesEnabled,
          ),
        );
  }, [customRulesEnabled, editorMode, project, scope, tabs, user]);

  const selectedTab = useSelectedTab(tabs, visibleTabs);

  const fullScreen = useMemo(() => {
    if ((sections as any).length === 1) {
      return get(first(sections), 'props.fullScreen', false);
    }

    return false;
  }, [sections]);

  const showHeader = useMemo(
    () => visibleTabs.length > 1 || title || subtitle,
    [visibleTabs.length, title, subtitle],
  );

  if (tabs.length > 1 && visibleTabs.length > 0) {
    // If we have more than one tab, and at least one visible tab, redirect the URL to that tab
    if (!tab) {
      return (
        <Redirect
          to={`${pathname}/${kebabCase((first as any)(visibleTabs).title)}${
            location.search || ''
          }`}
        />
      );
    } else if (selectedTab && kebabCase(selectedTab.title) !== tab) {
      // If the tab in the URL doesn't match the one we're rendering, redirect to it
      const updatedPathname = pathname.replace(
        new RegExp(`${tab}$`),
        kebabCase(selectedTab.title),
      );
      return <Redirect to={`${updatedPathname}${location.search || ''}`} />;
    }
  }

  return (
    <div className={classNames('flex w-full flex-wrap', `view-${elementId}`)}>
      {showHeader && (
        <div
          className={classNames(
            'sticky top-0 z-40 flex w-full flex-col overflow-hidden border-b sm:static',
            {
              [`${darkModeColors?.surfaces.elevation1} ${darkModeColors.borders.one}`]:
                isDarkModeEnabled,
              'bg-white': !isDarkModeEnabled,
              'px-3': headerWidth !== 12,
              'px-6': headerWidth === 12,
            },
          )}
        >
          <Title
            className={classNames(
              'mx-auto w-full bg-center py-6 sm:py-3',
              getClassNameFromHeaderWidth(headerWidth),
            )}
            subtitle={{
              hidden: !subtitle,
              value: subtitle,
            }}
            title={{
              hidden: !title,
              value: title,
            }}
            headerWidth={headerWidth}
          />
          <RecordViewTabs
            editorMode={editorMode}
            headerWidth={headerWidth}
            project={project}
            recordId="blank"
            recordScope={recordScope}
            rootPathname={routePathName}
            tab={tab}
            visibleTabs={visibleTabs}
          />
        </div>
      )}
      <div
        className={classNames('flex w-full flex-wrap', {
          'mb-8 ml-auto mr-auto gap-y-4 py-4 pb-32 pl-4 pr-4': !fullScreen,
          'max-w-screen': fullScreen,
        })}
      >
        <RecordViewSections
          data={user}
          dataType={userTypeWithRelations}
          editorMode={editorMode}
          elementPath={elementPath}
          isEditingData={false}
          onError={nullFn}
          onLoadingChange={nullFn}
          pageId={pageId}
          isRecordView={false}
          project={project}
          recordScope={recordScope}
          rootPathname={null}
          sections={sections}
          selectedTab={selectedTab}
          tabs={tabs}
          visibleTabs={visibleTabs}
        />
        {editorMode && !fullScreen && (
          <BuildModeSectionOptions
            dataType={userTypeWithRelations}
            elementPath={elementPath}
            elementType={PAGE}
            popover={false}
            project={project}
            sections={sections}
            selectedTab={selectedTab}
          />
        )}
      </div>
    </div>
  );
};

export default BlankPage;
