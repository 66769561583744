import createReactComponent from '../createReactComponent';
export default createReactComponent('ribbon-health', 'IconRibbonHealth', [
  [
    'path',
    {
      d: 'M7 21s9.286 -9.841 9.286 -13.841a3.864 3.864 0 0 0 -1.182 -3.008a4.13 4.13 0 0 0 -3.104 -1.144a4.13 4.13 0 0 0 -3.104 1.143a3.864 3.864 0 0 0 -1.182 3.01c0 4 9.286 13.84 9.286 13.84',
      key: 'svg-0',
    },
  ],
]);
