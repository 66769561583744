import createReactComponent from '../createReactComponent';
export default createReactComponent('id-badge-off', 'IconIdBadgeOff', [
  [
    'path',
    {
      d: 'M7.141 3.125a3 3 0 0 1 .859 -.125h8a3 3 0 0 1 3 3v9m-.13 3.874a3 3 0 0 1 -2.87 2.126h-8a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 .128 -.869',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11.179 11.176a2 2 0 1 0 2.635 2.667', key: 'svg-1' }],
  ['path', { d: 'M10 6h4', key: 'svg-2' }],
  ['path', { d: 'M9 18h6', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
