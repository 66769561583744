import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, CheckList, Label, Loader, Surface } from '@noloco/components';
import {
  CheckListOption,
  CheckListOptions,
} from '@noloco/components/src/components/checklist/CheckList';
import { DARK } from '@noloco/components/src/constants/surface';
import HelpText from '@noloco/core/src/elements/sections/HelpText';
import useSetDocumentTitle from '@noloco/core/src/utils/hooks/useSetDocumentTitle';
import { getText } from '@noloco/core/src/utils/lang';
import { HUBSPOT } from '../../../../../core/src/constants/dataSources';
import { HubSpotObjectGroup } from '../../../../../core/src/models/HubSpotObjectGroup';
import {
  HubSpotObjectToScopeGroup,
  HubSpotObjectsByGroup,
  HubSpotScopeGroupToObjects,
} from '../../../../../core/src/models/HubSpotObjects';
import { HubSpotScopeGroup } from '../../../../../core/src/models/HubSpotScopeGroups';
import { Project } from '../../../../../core/src/models/Project';
import hubSpotLogo from '../../img/hubspot-logo.png';
import { GET_HUBSPOT_CUSTOM_OBJECTS } from '../../queries/project';
import { useAddDataSource } from '../../utils/hooks/useAddDataSource';
import Guide from '../Guide';
import DataSourceImportStatus from './DataSourceImportStatus';

const LANG_KEY = 'data.hubspot';
const HUBSPOT_SINGLE_SOURCE_DISPLAY_NAME = 'HubSpot';
const FORM_STEPS = 1;

const AddHubSpot = ({
  inOnboarding = false,
  project,
  onConnect,
  surface = DARK,
}: {
  inOnboarding?: boolean;
  project: Project;
  onConnect?: () => void;
  surface?: Surface;
}) => {
  const bottomRef = useRef<null | HTMLDivElement>(null);
  useSetDocumentTitle(`${getText(LANG_KEY, 'title')} HubSpot`);
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const projectHubSpotIntegration = project.integrations?.hubspot;
  const isValidConnection = useMemo(
    () => projectHubSpotIntegration?.id && !authenticationFailed,
    [authenticationFailed, projectHubSpotIntegration],
  );
  const [objectTypes, setObjectTypes] = useState<string[]>([]);
  const { data, loading } = useQuery(GET_HUBSPOT_CUSTOM_OBJECTS, {
    variables: { projectId: project.name },
    notifyOnNetworkStatusChange: true,
  });

  const {
    builtPages,
    createdDataSource,
    dataSourceId,
    error,
    existingDataSource,
    hasSyncedData,
    inProgressPages,
    isConnecting,
    isNameValid,
    onClickNext,
    onDataTypesSelected,
    onFinish,
    skippedPages,
    step,
  } = useAddDataSource({
    project,
    connection: {
      objectTypes,
    },
    display: HUBSPOT_SINGLE_SOURCE_DISPLAY_NAME,
    type: HUBSPOT,
    formSteps: FORM_STEPS,
    onAuthenticationFail: () => setAuthenticationFailed(true),
    inOnboarding,
    canShowTableChooser: false,
    usePreBuiltLayout: true,
  });

  useEffect(() => {
    if (dataSourceId && existingDataSource) {
      setObjectTypes(() => existingDataSource.connection.objectTypes ?? []);
    } else if (!dataSourceId) {
      setObjectTypes(() =>
        (projectHubSpotIntegration?.scopeGroups ?? []).reduce(
          (acc: string[], scope) => {
            if (scope === HubSpotScopeGroup.CUSTOM_OBJECTS) {
              return acc.concat(
                (data?.hubspotCustomObjects ?? []).map(
                  ({ name }: { name: string }) => name,
                ),
              );
            }

            const objectChoiceForScope =
              HubSpotScopeGroupToObjects[
                scope as keyof typeof HubSpotScopeGroupToObjects
              ];

            if (!objectChoiceForScope) {
              // Files have a scope group but aren't selectable here
              return acc;
            }
            acc.push(...objectChoiceForScope);
            return acc;
          },
          [],
        ),
      );
    }
  }, [
    existingDataSource,
    data?.hubspotCustomObjects,
    projectHubSpotIntegration?.scopeGroups,
    dataSourceId,
  ]);

  useLayoutEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTo({
        top: bottomRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [step, bottomRef]);

  const scopeGroups = useMemo(
    () => projectHubSpotIntegration?.scopeGroups ?? [],
    [projectHubSpotIntegration?.scopeGroups],
  );

  const objectTypeOptions: CheckListOptions = useMemo(() => {
    return Object.entries(HubSpotObjectsByGroup).map(([groupName, objects]) => {
      const additionalOptions: CheckListOption[] = [];
      const missingCustomObjectScope = !scopeGroups.includes(
        HubSpotScopeGroup.CUSTOM_OBJECTS,
      );

      if (groupName === HubSpotObjectGroup.CRM) {
        (data?.hubspotCustomObjects ?? []).forEach((customObject: any) => {
          additionalOptions.push({
            disabled: missingCustomObjectScope,
            tooltip: missingCustomObjectScope
              ? getText(
                  {
                    scopeName: getText(
                      LANG_KEY,
                      `scopeGroups.${HubSpotScopeGroup.CUSTOM_OBJECTS}`,
                    ),
                  },
                  LANG_KEY,
                  `chooseObjectTypes.missingScope`,
                )
              : undefined,
            label: customObject.label,
            value: customObject.name,
          });
        });
      }
      const options: CheckListOption[] = objects.map((object) => {
        const missingScopeForObject = !scopeGroups.includes(
          HubSpotObjectToScopeGroup[object],
        );
        return {
          disabled: missingScopeForObject,
          tooltip: missingScopeForObject
            ? getText(
                {
                  scopeName: getText(
                    LANG_KEY,
                    `scopeGroups.${HubSpotObjectToScopeGroup[object]}`,
                  ),
                },
                LANG_KEY,
                `chooseObjectTypes.missingScope`,
              )
            : undefined,
          label: getText(LANG_KEY, `builtInObjects.${object}`),
          value: object,
        };
      });
      return {
        groupName: getText(LANG_KEY, `objectGroups.${groupName}`),
        options: options.concat(additionalOptions),
      };
    });
  }, [data?.hubspotCustomObjects, scopeGroups]);

  const isStepValid = useMemo(
    () => isNameValid && objectTypes.length > 0,
    [isNameValid, objectTypes.length],
  );

  return (
    <div className="flex w-full">
      <div
        className={classNames('w-full max-w-xl overflow-auto p-8 text-white', {
          'bg-slate-700': !inOnboarding,
        })}
      >
        {!inOnboarding && (
          <>
            <h1 className="flex items-center text-xl">
              <span>{getText(LANG_KEY, 'title')}</span>
              <img
                src={hubSpotLogo}
                alt="HubSpot logo"
                className="ml-2 mr-2 h-8"
              />
              <span>{getText(LANG_KEY, 'name')}</span>
            </h1>
            <Guide
              className="mb-8 mt-4"
              href="https://guides.noloco.io/data/hubspot"
            >
              {getText(LANG_KEY, 'help')}
            </Guide>
          </>
        )}
        <Label
          className={classNames('mb-3 mt-6', {
            'text-gray-700': inOnboarding,
            'text-white': !inOnboarding,
          })}
        >
          {getText(LANG_KEY, 'connectAccount.label')}
        </Label>
        <Link
          to={`/_/settings/integrations/hubspot/reconfigure?sourceId=${
            dataSourceId ?? ''
          }`}
        >
          <Button
            variant="secondary"
            className="flex items-center"
            opacity={100}
          >
            <img
              src={hubSpotLogo}
              className="mr-3 h-6 w-auto"
              alt="Sign in With HubSpot Logo"
            />
            {getText(
              LANG_KEY,
              'connectAccount.button',
              projectHubSpotIntegration?.id ? 'reconnect' : 'connect',
            )}
          </Button>
        </Link>

        {isValidConnection && (
          <>
            <Label
              className={classNames('mb-3 mt-6', {
                'text-gray-700': inOnboarding,
                'text-white': !inOnboarding,
              })}
            >
              {getText(LANG_KEY, 'chooseObjectTypes.label')}
              <HelpText>{getText(LANG_KEY, 'chooseObjectTypes.help')}</HelpText>
              {!loading && (
                <CheckList
                  onChange={(newValues) => {
                    setObjectTypes(() => newValues);
                  }}
                  surface={surface}
                  options={objectTypeOptions}
                  value={objectTypes}
                />
              )}
              {loading && (
                <div className="mt-4">
                  <Loader />
                </div>
              )}
            </Label>
          </>
        )}
        {step < 1 && (
          <Button
            className="mt-6 flex items-center disabled:opacity-75"
            disabled={!isStepValid}
            onClick={onClickNext}
          >
            <span>{getText(LANG_KEY, 'next')}</span>
            <IconArrowNarrowRight size={16} className="ml-2 opacity-75" />
          </Button>
        )}
      </div>
      <div
        className={classNames('max-h-screen w-full overflow-y-auto', {
          'bg-slate-800': !inOnboarding,
        })}
        ref={inOnboarding ? null : bottomRef}
      >
        <div
          className={classNames('flex w-full items-center justify-center', {
            'max-h-screen-75 overflow-y-auto': inOnboarding,
          })}
          ref={inOnboarding ? bottomRef : null}
        >
          {(step >= 1 || error) && (
            <DataSourceImportStatus
              builtPages={builtPages}
              createdDataSource={createdDataSource}
              error={error}
              formSteps={FORM_STEPS}
              hasSyncedData={hasSyncedData}
              inProgressPages={inProgressPages}
              isConnecting={isConnecting}
              onDataTypesSelected={onDataTypesSelected}
              onFinish={inOnboarding ? onConnect : onFinish}
              project={project}
              skippedPages={skippedPages}
              sourceType={HUBSPOT}
              step={step}
              inOnboarding={inOnboarding}
              skipSelectDataTypes={true}
              usePreBuiltLayout={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddHubSpot;
