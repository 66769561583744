import createReactComponent from '../createReactComponent';
export default createReactComponent('go-game', 'IconGoGame', [
  ['path', { d: 'M6 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M6 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M18 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M3 12h7m4 0h7', key: 'svg-4' }],
  ['path', { d: 'M3 6h1m4 0h13', key: 'svg-5' }],
  ['path', { d: 'M3 18h1m4 0h8m4 0h1', key: 'svg-6' }],
  ['path', { d: 'M6 3v1m0 4v8m0 4v1', key: 'svg-7' }],
  ['path', { d: 'M12 3v7m0 4v7', key: 'svg-8' }],
  ['path', { d: 'M18 3v13m0 4v1', key: 'svg-9' }],
]);
