import React, { useCallback, useEffect, useState } from 'react';
import { RadioGroup as BaseRadioGroup } from '@headlessui/react';

type RadioGroupProps<T> = {
  className?: string;
  options: any[];
  optionClassName?: string;
  label?: string | JSX.Element;
  onChange?: (value: T) => void;
  value: T | undefined;
};

const RadioGroup = <T extends any>({
  className,
  options,
  optionClassName,
  label,
  onChange,
  value,
}: RadioGroupProps<T>) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleOnChange = useCallback(
    (newValue: any) => {
      setSelectedValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <BaseRadioGroup
      className={className}
      value={selectedValue}
      onChange={handleOnChange}
    >
      {label && <BaseRadioGroup.Label>{label}</BaseRadioGroup.Label>}
      {options.map((option: any) => (
        <BaseRadioGroup.Option
          key={option.value}
          className={optionClassName}
          value={option.value}
        >
          {({ active, checked }) =>
            option.label && typeof option.label === 'function' ? (
              option.label({
                ...option,
                active,
                checked,
                value: option.value,
                currentValue: selectedValue,
              })
            ) : (
              <span>{option.label || option.value}</span>
            )
          }
        </BaseRadioGroup.Option>
      ))}
    </BaseRadioGroup>
  );
};

export default RadioGroup;
