import createReactComponent from '../createReactComponent';
export default createReactComponent('yin-yang', 'IconYinYang', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  ['path', { d: 'M12 3a4.5 4.5 0 0 0 0 9a4.5 4.5 0 0 1 0 9', key: 'svg-1' }],
  [
    'circle',
    { cx: '12', cy: '7.5', r: '.5', fill: 'currentColor', key: 'svg-2' },
  ],
  [
    'circle',
    { cx: '12', cy: '16.5', r: '.5', fill: 'currentColor', key: 'svg-3' },
  ],
]);
