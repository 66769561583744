import createReactComponent from '../createReactComponent';
export default createReactComponent('sunglasses', 'IconSunglasses', [
  ['path', { d: 'M8 4h-2l-3 10', key: 'svg-0' }],
  ['path', { d: 'M16 4h2l3 10', key: 'svg-1' }],
  ['path', { d: 'M10 16h4', key: 'svg-2' }],
  ['path', { d: 'M21 16.5a3.5 3.5 0 0 1 -7 0v-2.5h7v2.5', key: 'svg-3' }],
  ['path', { d: 'M10 16.5a3.5 3.5 0 0 1 -7 0v-2.5h7v2.5', key: 'svg-4' }],
  ['path', { d: 'M4 14l4.5 4.5', key: 'svg-5' }],
  ['path', { d: 'M15 14l4.5 4.5', key: 'svg-6' }],
]);
