import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'building-lighthouse',
  'IconBuildingLighthouse',
  [
    ['path', { d: 'M12 3l2 3l2 15h-8l2 -15z', key: 'svg-0' }],
    ['path', { d: 'M8 9l8 0', key: 'svg-1' }],
    ['path', { d: 'M3 11l2 -2l-2 -2', key: 'svg-2' }],
    ['path', { d: 'M21 11l-2 -2l2 -2', key: 'svg-3' }],
  ],
);
