import createReactComponent from '../createReactComponent';
export default createReactComponent('error-404', 'IconError404', [
  ['path', { d: 'M3 7v4a1 1 0 0 0 1 1h3', key: 'svg-0' }],
  ['path', { d: 'M7 7v10', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10 8v8a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M17 7v4a1 1 0 0 0 1 1h3', key: 'svg-3' }],
  ['path', { d: 'M21 7v10', key: 'svg-4' }],
]);
