import get from 'lodash/fp/get';
import set from 'lodash/fp/set';
import initial from 'lodash/initial';
import last from 'lodash/last';

const addToDroppedPathAtIndex = (
  droppedSiblings: any,
  dropPath: any,
  draggedElement: any,
  projectElements: any,
) => {
  const droppedSiblingsClone = [...(droppedSiblings || [])];
  const newIndex = last(dropPath);
  // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  droppedSiblingsClone.splice(newIndex, 0, draggedElement);

  const newPathString = dropPath.join('.');
  const elementsWithDragged =
    dropPath.length === 1
      ? droppedSiblingsClone
      : // @ts-expect-error TS(2769): No overload matches this call.
        set(initial(dropPath), droppedSiblingsClone, projectElements);

  return {
    newPathString,
    newIndex,
    elementsWithDragged,
  };
};

export const addNewElementAsChild = (
  dropPath: any,
  droppedElement: any,
  newElement: any,
  projectElements: any,
  updateProject: any,
) => {
  const newIndex = droppedElement.children.length;
  const newPath = [...dropPath, 'children', newIndex];
  const elementsWithDragged = set(newPath, newElement, projectElements);
  updateProject(newPath, get(newPath, elementsWithDragged));
  return newPath;
};

export const addNewElementToPathAtIndex = (
  droppedSiblings: any,
  dropPath: any,
  newElement: any,
  projectElements: any,
  updateProject: any,
) => {
  const { newPathString, elementsWithDragged } = addToDroppedPathAtIndex(
    droppedSiblings,
    dropPath,
    newElement,
    projectElements,
  );

  const siblingPath = initial(newPathString.split('.'));
  // @ts-expect-error TS(2769): No overload matches this call.
  return updateProject(siblingPath, get(siblingPath, elementsWithDragged));
};

export const moveDraggedToPathAtIndex = (
  droppedSiblings: any,
  dropPath: any,
  draggedElement: any,
  draggedItem: any,
  projectElements: any,
) => {
  const { newPathString, newIndex, elementsWithDragged } =
    addToDroppedPathAtIndex(
      droppedSiblings,
      dropPath,
      draggedElement,
      projectElements,
    );
  return finishDrag(
    dropPath,
    draggedItem,
    newPathString,
    newIndex,
    elementsWithDragged,
  );
};

export const addDraggedAsChildOfPath = (
  dropPath: any,
  droppedElement: any,
  draggedElement: any,
  draggedItem: any,
  projectElements: any,
) => {
  const newIndex = droppedElement.children.length;
  const newPath = [...dropPath, 'children', newIndex];
  const newPathString = newPath.join('.');
  const elementsWithDragged = set(newPath, draggedElement, projectElements);
  return finishDrag(
    dropPath,
    draggedItem,
    newPathString,
    newIndex,
    elementsWithDragged,
  );
};

// If the item is dropped higher up in the tree it can possibly change where the
// original dragged path was, so its common index needs to be adjusted
const adjustDraggedSiblingPath = (
  draggedPath: any,
  newPathString: any,
  newIndex: any,
) => {
  const newPathRoot = initial(newPathString.split('.'));
  const isAffectedPath = draggedPath
    .join('.')
    .startsWith(newPathRoot.join('.'));
  if (!isAffectedPath) {
    return initial(draggedPath);
  }
  const newPath = [...draggedPath];
  const currentIndex = parseInt(newPath[newPathRoot.length], 10);
  if (currentIndex >= newIndex) {
    newPath[newPathRoot.length] = currentIndex + 1;
  }
  return initial(newPath);
};

export const finishDrag = (
  dropPath: any,
  draggedItem: any,
  newPathString: any,
  newIndex: any,
  elementsWithDragged: any,
) => {
  const adjustedDraggedSiblingsPath = adjustDraggedSiblingPath(
    draggedItem.path,
    newPathString,
    newIndex,
  );
  const adjustedSiblings =
    adjustedDraggedSiblingsPath.length === 0
      ? elementsWithDragged
      : get(adjustedDraggedSiblingsPath, elementsWithDragged);

  const draggedSiblingsWoDragged = adjustedSiblings.filter(
    ({ id }: any, index: any) =>
      id !== draggedItem.id ||
      [...adjustedDraggedSiblingsPath, index].join('.') === newPathString,
  );

  const newElements =
    adjustedDraggedSiblingsPath.length === 0
      ? draggedSiblingsWoDragged
      : set(
          adjustedDraggedSiblingsPath,
          draggedSiblingsWoDragged,
          elementsWithDragged,
        );

  const lowestCommonAncestorPath = adjustedDraggedSiblingsPath.reduce(
    (acc, path, index) =>
      path === dropPath[index] && index === acc.length ? [...acc, path] : acc,
    [],
  );

  draggedItem.updateProject(
    lowestCommonAncestorPath,
    lowestCommonAncestorPath.length === 0
      ? newElements
      : get(lowestCommonAncestorPath, newElements),
  );

  return newPathString.split('.');
};
