import {
  Icon,
  IconBrandYoutube,
  IconCreditCard,
  IconFileCode,
  IconFiles,
  IconId,
  IconInfoSquare,
  IconLayoutList,
  IconLink,
  IconListCheck,
  IconMessages,
  IconPhoto,
  IconSpeakerphone,
  IconVideo,
} from '@tabler/icons-react';
import { CARDS } from '../constants/collectionLayouts';
import { SECTIONS, SIMPLE } from '../constants/elementGroups';
import * as elements from '../constants/elements';

export type CompoundElement = {
  type: string;
  labelKey: string[];
  Icon: Icon;
  props?: Record<string, any>;
};

const compoundElements: Record<string, CompoundElement[]> = {
  [SIMPLE]: [
    {
      type: elements.TITLE,
      labelKey: ['elements', elements.TITLE],
      Icon: IconSpeakerphone,
    },
    {
      type: elements.COLLECTION,
      labelKey: ['elements', elements.COLLECTION],
      Icon: IconLayoutList,
      props: {
        layout: CARDS,
      },
    },
    {
      type: elements.DETAILS,
      labelKey: ['elements', elements.DETAILS],
      Icon: IconId,
    },
    {
      type: elements.HIGHLIGHTS,
      labelKey: ['elements', elements.HIGHLIGHTS],
      Icon: IconInfoSquare,
      props: {
        highlights: [
          {
            label: [{ text: 'Monthly revenue' }],
            text: [{ text: '$75,000' }],
          },
          {
            label: [{ text: 'Annual revenue' }],
            text: [{ text: '$812,000' }],
          },
        ],
      },
    },
    {
      type: elements.QUICK_LINKS,
      labelKey: ['elements', elements.QUICK_LINKS],
      Icon: IconLink,
      props: {
        links: [
          {
            title: [{ text: 'Book a meeting' }],
            description: [
              {
                text: 'Book a meeting with one of our team. Simply choose the time that suits you best.',
              },
            ],
            icon: { name: 'Calendar' },
          },
          {
            title: [{ text: 'Pay your bill' }],
            description: [{ text: 'View your past and upcoming invoices.' }],
            icon: { name: 'CreditCard' },
          },
          {
            title: [{ text: 'View shared files' }],
            description: [
              {
                text: 'See all the files we have shared with you, and upload any files we need from you.',
              },
            ],
            icon: { name: 'Files' },
          },
          {
            title: [{ text: 'Ask us a question' }],
            description: [
              {
                text: 'Use our chat feature to ask us any questions. We will tyically get back to you within a few hours.',
              },
            ],
            icon: { name: 'Messages' },
          },
        ],
      },
    },
    {
      type: elements.IMAGE,
      labelKey: ['elements', elements.IMAGE],
      Icon: IconPhoto,
      props: {
        h: 'auto',
        w: 'full',
      },
    },
    {
      type: elements.VIDEO,
      labelKey: ['elements', elements.VIDEO],
      Icon: IconVideo,
      props: {
        h: 'auto',
        w: 'full',
      },
    },
    {
      type: elements.YOUTUBE_VIDEO,
      labelKey: ['elements', elements.YOUTUBE_VIDEO],
      Icon: IconBrandYoutube,
    },
    {
      type: elements.IFRAME,
      labelKey: ['elements', elements.IFRAME],
      Icon: IconFileCode,
      props: {
        h: 'screen',
        w: 'full',
      },
    },
  ],
  [SECTIONS]: [
    {
      type: elements.BILLING,
      labelKey: ['elements', elements.BILLING],
      Icon: IconCreditCard,
      props: {
        pl: 8,
        pr: 8,
        mt: 8,
        mb: 8,
        title: [{ text: 'Invoices' }],
        subscriptionsTitle: [{ text: 'Subscriptions' }],
        emptyState: {
          title: {
            value: [{ text: "You don't have any invoices or subscriptions" }],
          },
        },
      },
    },
    {
      type: elements.MESSAGING,
      labelKey: ['elements', elements.MESSAGING],
      Icon: IconMessages,
      props: {
        clientTopMessage: [
          {
            text: "Send a message here to chat to our team. We'll get back to you as soon as possible.",
          },
        ],
      },
    },
    {
      type: elements.FILE_SHARING,
      labelKey: ['elements', elements.FILE_SHARING],
      Icon: IconFiles,
    },
    {
      type: elements.ONBOARDING_TASKS,
      labelKey: ['elements', elements.ONBOARDING_TASKS],
      Icon: IconListCheck,
      props: {
        title: 'You have tasks to complete',
        subtitle: 'Complete the following tasks to get started...',
      },
    },
  ],
};

export default compoundElements;
