import React, { useCallback, useEffect, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconChevronLeft, IconChevronRight, IconX } from '@tabler/icons-react';
import range from 'lodash/range';
import { DateTime } from 'luxon';
import BaseDatePicker from 'react-datepicker';
// eslint-disable-next-line no-restricted-imports
import { getText } from '@noloco/core/src/utils/lang';
import {
  dtToLocalTZMaintainWallTime,
  dtToTzMaintainWallTime,
  dtToUTCMaintainWallTime,
} from '../../utils/dateTimeConversions';
import useBreakpoints from '../../utils/hooks/useBreakpoints';
import useLocale from '../../utils/hooks/useLocale';
import { Popover } from '../popover';
import { listBoxStyles } from '../select/selectStyles';
import {
  PresetRangeOptions,
  getDateRangeFromPresetString,
  presetRanges,
} from './datePickerPresetRangesUtils';
import { ROUNDED_LARGE } from './inputStyles';
import themeStyles from './inputTheme';

const OFFSET = [0, 6];

const getYear = (date: any) => (date ? date.getFullYear() : null);
const getMonth = (date: any) => (date ? date.getMonth() : null);

const years = range(1900, 2100);

const months = range(12);

const getLocalDate = (dateTime: DateTime) =>
  dtToLocalTZMaintainWallTime(dateTime, false);

const formatDateTimeValue = (
  dateValue: DateTime | null,
  selectTime: any,
  timeZone: any,
) => {
  if (!dateValue) {
    return null;
  }

  if (selectTime) {
    if (timeZone) {
      return dateValue.setZone(timeZone);
    }

    return dateValue;
  }

  const dateTime = dateValue.toUTC();
  const localDateTime = getLocalDate(dateTime);

  return localDateTime;
};

export const formatLocalDateTimeResult = (
  localDateTime: any,
  selectTime: any,
  timeZone: any,
): DateTime => {
  if (selectTime) {
    if (timeZone) {
      return dtToTzMaintainWallTime(localDateTime, timeZone, true);
    }

    return localDateTime.toUTC();
  }

  return dtToUTCMaintainWallTime(localDateTime, false);
};

const formatDateTimeResult = (
  dateValue: Date | null,
  selectTime: any,
  timeZone: any,
) => {
  if (!dateValue) {
    return null;
  }

  const localDateTime = DateTime.fromJSDate(dateValue);
  return formatLocalDateTimeResult(localDateTime, selectTime, timeZone);
};

const formatDateTimeRangeResult = (
  dates: [Date | null, Date | null],
  selectTime: any,
) => {
  const [start, end] = dates;

  return [
    // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
    formatDateTimeResult(start, selectTime),
    // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
    formatDateTimeResult(end, selectTime),
  ];
};

const convertValueToDate = (dateTime: any, selectTime: any, timeZone?: any) => {
  if (!dateTime) {
    return null;
  }

  if (selectTime) {
    if (timeZone) {
      dateTime = dtToLocalTZMaintainWallTime(dateTime, true);
    }

    return dateTime.toJSDate();
  }

  return getLocalDate(dateTime).toJSDate();
};

const getStartDate = (
  value: any,
  selectsRange: any,
  selectTime: any,
  timeZone: any,
) =>
  selectsRange
    ? value && formatDateTimeValue(value.start, selectTime, timeZone)
    : formatDateTimeValue(value, selectTime, timeZone);

const getEndDate = (
  value: any,
  selectsRange: any,
  selectTime: any,
  timeZone: any,
) =>
  selectsRange
    ? value && formatDateTimeValue(value.end, selectTime, timeZone)
    : formatDateTimeValue(value, selectTime, timeZone);

type OwnDatePickerPopoverProps = {
  clearable?: boolean;
  value: { start: DateTime; end: DateTime } | DateTime | null;
  placeholder?: React.ReactNode | string;
  footer?: JSX.Element;
  isRange?: boolean;
  onOpenChange?: (nextOpen: boolean) => void;
  placement?: string;
  style?: any; // TODO: oneOfWithDefault(inputStyles)
  selectTime?: boolean;
  showTimeSelectOnly?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'DatePickerPopoverProps' circularly ref... Remove this comment to see the full error message
type DatePickerPopoverProps = OwnDatePickerPopoverProps &
  typeof DatePickerPopover.defaultProps;

// @ts-expect-error TS(7022): 'DatePickerPopover' implicitly has type 'any' beca... Remove this comment to see the full error message
const DatePickerPopover = ({
  bg,
  borderColor,
  children,
  clearable,
  disabled,
  isRange,
  onBlur,
  onChange,
  onOpenChange,
  footer,
  open,
  placement = 'bottom-start',
  selectTime,
  showTimeSelectOnly,
  style,
  surface,
  timeZone,
  theme,
  value,
}: DatePickerPopoverProps) => {
  const locale = useLocale();
  const [startDate, setStartDate] = useState<DateTime | null>(
    getStartDate(value, isRange, selectTime, timeZone),
  );

  const { sm: isSmScreen } = useBreakpoints();

  const [endDate, setEndDate] = useState<DateTime | null>(
    getEndDate(value, isRange, selectTime, timeZone),
  );

  useEffect(() => {
    setStartDate(getStartDate(value, isRange, selectTime, timeZone));
    setEndDate(getEndDate(value, isRange, selectTime, timeZone));
  }, [selectTime, isRange, timeZone, value]);

  let bgValue =
    (bg !== 'transparent' && bg) || theme?.surfaceColors[surface] || 'white';

  let borderColorValue = borderColor || theme?.borderColors[surface];

  const handleBlur = useCallback(
    (isOpen: any) => {
      if (!isOpen && onBlur) {
        if (isRange) {
          onBlur({
            start: startDate,
            end: endDate,
          });
        } else {
          onBlur(startDate);
        }
      }

      if (onOpenChange) {
        onOpenChange(isOpen);
      }
    },
    [endDate, isRange, onBlur, onOpenChange, startDate],
  );

  const handleChange = useCallback(
    (dates: any) => {
      if (isRange) {
        const [start, end] = formatDateTimeRangeResult(dates, false);
        setStartDate(start);
        setEndDate(end);
        if (onChange) {
          onChange({
            start: start,
            end: end,
          });
        }
      } else {
        const result = formatDateTimeResult(dates, selectTime, timeZone);
        setStartDate(result);
        if (onChange) {
          onChange(result);
        }
      }
    },
    [isRange, onChange, selectTime, timeZone],
  );

  const handlePresetClick = useCallback(
    (option: PresetRangeOptions) => {
      handleChange(getDateRangeFromPresetString(option));
    },
    [handleChange],
  );

  return (
    <Popover
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      {...themeStyles(borderColorValue)[style]}
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      {...listBoxStyles[style]}
      bg={surface || theme?.surfaceColors[surface] ? bgValue : undefined}
      disabled={disabled}
      closeOnOutsideClick={true}
      onOpenChange={handleBlur}
      content={
        <div
          className={`datepicker ${surface || theme?.datePicker?.surface} `}
          data-testid="date-picker-popover"
        >
          <div className="flex flex-row">
            <BaseDatePicker
              showTimeSelect={selectTime && !isRange && !isSmScreen}
              showTimeSelectOnly={showTimeSelectOnly}
              startDate={
                isRange
                  ? startDate && convertValueToDate(startDate, false, timeZone)
                  : undefined
              }
              endDate={
                isRange
                  ? endDate && convertValueToDate(endDate, false)
                  : undefined
              }
              selected={
                startDate
                  ? convertValueToDate(startDate, selectTime, timeZone)
                  : undefined
              }
              locale={locale.code}
              onChange={handleChange}
              minDate={null} //to avoid highlighting last number clicked on different months
              //https://github.com/Hacker0x01/react-datepicker/issues/2930#issuecomment-1887696099
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }: any) => (
                <div
                  className={`mb-2 flex justify-center text-sm ${
                    theme?.datePicker?.textColor
                      ? theme.datePicker.textColor
                      : 'text-gray-500'
                  }`}
                >
                  <button
                    className="mr-2 bg-transparent"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <IconChevronLeft size={16} />
                  </button>
                  <select
                    className="mr-2 bg-transparent"
                    data-testid="date-picker-years"
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    className="bg-transparent"
                    value={getMonth(date)}
                    data-testid="date-picker-months"
                    onChange={({ target: { value } }) =>
                      changeMonth(parseInt(value, 10))
                    }
                  >
                    {months.map((option, monthIndex) => (
                      <option key={option} value={option}>
                        {locale.localize?.month(monthIndex) ?? monthIndex + 1}
                      </option>
                    ))}
                  </select>
                  <button
                    className="ml-2"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <IconChevronRight size={16} />
                  </button>
                  {clearable && (
                    <button
                      className="ml-2 mr-4"
                      onClick={() =>
                        handleChange(isRange ? [null, null] : null)
                      }
                    >
                      <IconX size={16} />
                    </button>
                  )}
                </div>
              )}
              inline={true}
              selectsRange={isRange}
            />
            {isRange && (
              <div className="flex max-h-64 flex-col space-y-0.5 overflow-y-auto py-2 text-left text-xs">
                {presetRanges.map((option) => (
                  <div
                    key={`preset-${option}`}
                    onClick={() => handlePresetClick(option)}
                    className="mr-2 cursor-pointer rounded-md px-2 py-0.5 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-500"
                  >
                    {getText(
                      `elements.COLLECTION.calendar.filterPreset.${option}`,
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {footer}
        </div>
      }
      isOpen={open}
      trigger={open ? 'none' : 'click'}
      showArrow={false}
      offset={OFFSET}
      placement={placement}
    >
      {children({ startDate, endDate, selectsRange: isRange, selectTime })}
    </Popover>
  );
};

DatePickerPopover.defaultProps = {
  clearable: true,
  isRange: false,
  inline: false,
  style: ROUNDED_LARGE,
  selectTime: false,
  showTimeSelectOnly: false,
  theme: 'default',
};

export default withTheme(DatePickerPopover);
