import React, { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import { Surface } from '@noloco/components/src';
import { DARK } from '@noloco/components/src/constants/surface';
import DatePicker from '../../../../components/DatePicker';
import { DataField } from '../../../../models/DataTypeFields';

type Props = {
  disabled?: boolean;
  field: DataField;
  inline?: boolean;
  onChange: (value: any) => void;
  surface: Surface;
  validationError?: string;
  value: any;
};

const DateRangeInput = ({
  disabled,
  field,
  inline,
  onChange,
  surface,
  validationError,
  value,
}: Props) => {
  const rangeValue = useMemo(() => {
    if (!value) {
      return { start: null, end: null };
    }

    return {
      start: value.from ? DateTime.fromISO(value.from) : null,
      end: value.to ? DateTime.fromISO(value.to) : null,
    };
  }, [value]);

  return (
    <DatePicker
      disabled={disabled}
      {...(inline
        ? {
            border: 0,
            bg: 'transparent',
            p: { x: 0, y: 0, l: 2 },
            text: ['xs', surface === DARK ? 'gray-200' : 'gray-700'],
            h: 8,
          }
        : {})}
      // @ts-expect-error TS(2322): Type '{ inline: any; validationError: any; value: ... Remove this comment to see the full error message
      inline={inline}
      isRange={true}
      validationError={validationError}
      value={rangeValue}
      onChange={({ start, end }: { start: DateTime; end: DateTime }) => {
        if (start && end) {
          onChange({ from: start.toISO(), to: end.toISO() });
        }
      }}
      selectTime={field.typeOptions?.time}
      surface={surface}
      timeZone={get(field, 'typeOptions.timeZone')}
      w="full"
    />
  );
};

export default withTheme(DateRangeInput);
