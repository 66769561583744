import createReactComponent from '../createReactComponent';
export default createReactComponent('device-imac-star', 'IconDeviceImacStar', [
  [
    'path',
    {
      d: 'M10 17h-6a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v7.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 13h10', key: 'svg-1' }],
  ['path', { d: 'M8 21h3', key: 'svg-2' }],
  ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z',
      key: 'svg-4',
    },
  ],
]);
