import createReactComponent from '../createReactComponent';
export default createReactComponent('ruler-measure', 'IconRulerMeasure', [
  [
    'path',
    {
      d: 'M19.875 12c.621 0 1.125 .512 1.125 1.143v5.714c0 .631 -.504 1.143 -1.125 1.143h-15.875a1 1 0 0 1 -1 -1v-5.857c0 -.631 .504 -1.143 1.125 -1.143h15.75z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 12v2', key: 'svg-1' }],
  ['path', { d: 'M6 12v3', key: 'svg-2' }],
  ['path', { d: 'M12 12v3', key: 'svg-3' }],
  ['path', { d: 'M18 12v3', key: 'svg-4' }],
  ['path', { d: 'M15 12v2', key: 'svg-5' }],
  ['path', { d: 'M3 3v4', key: 'svg-6' }],
  ['path', { d: 'M3 5h18', key: 'svg-7' }],
  ['path', { d: 'M21 3v4', key: 'svg-8' }],
]);
