import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-heart-monitor',
  'IconDeviceHeartMonitor',
  [
    [
      'path',
      {
        d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M4 9h6l1 -2l2 4l1 -2h6', key: 'svg-1' }],
    ['path', { d: 'M4 14h16', key: 'svg-2' }],
    ['path', { d: 'M14 17v.01', key: 'svg-3' }],
    ['path', { d: 'M17 17v.01', key: 'svg-4' }],
  ],
);
