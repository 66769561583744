import createReactComponent from '../createReactComponent';
export default createReactComponent('cloud-rain', 'IconCloudRain', [
  [
    'path',
    {
      d: 'M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 13v2m0 3v2m4 -5v2m0 3v2', key: 'svg-1' }],
]);
