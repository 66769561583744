import { HubSpotObjectGroup } from './HubSpotObjectGroup';

export enum HubSpotScopeGroup {
  CARTS = 'carts',
  CONTACTS_AND_ENGAGEMENTS = 'contacts_and_engagements',
  COMPANIES = 'companies',
  CUSTOM_OBJECTS = 'custom_objects',
  DEALS = 'deals',
  FEEDBACK_SUBMISSIONS = 'feedback_submissions',
  FILES = 'files',
  GOALS = 'goals',
  INVOICES = 'invoices',
  LEADS = 'leads',
  LINE_ITEMS = 'line_items',
  ORDERS = 'orders',
  PAYMENTS = 'payments',
  PRODUCTS = 'products',
  EMAIL_ENGAGEMENTS = 'sales_email',
  QUOTES = 'quotes',
  SUBSCRIPTIONS = 'subscriptions',
  TICKETS = 'tickets',
  USERS = 'users',
}

export const HubSpotScopesByObjectGroup: Record<
  HubSpotObjectGroup | 'users',
  HubSpotScopeGroup[]
> = {
  [HubSpotObjectGroup.CRM]: [
    HubSpotScopeGroup.CONTACTS_AND_ENGAGEMENTS,
    HubSpotScopeGroup.COMPANIES,
    HubSpotScopeGroup.CUSTOM_OBJECTS,
    HubSpotScopeGroup.DEALS,
    HubSpotScopeGroup.FEEDBACK_SUBMISSIONS,
    HubSpotScopeGroup.FILES,
    HubSpotScopeGroup.GOALS,
    HubSpotScopeGroup.LEADS,
    HubSpotScopeGroup.TICKETS,
  ],
  [HubSpotObjectGroup.ENGAGEMENTS]: [
    HubSpotScopeGroup.CONTACTS_AND_ENGAGEMENTS,
    HubSpotScopeGroup.EMAIL_ENGAGEMENTS,
  ],
  [HubSpotObjectGroup.COMMERCE]: [
    HubSpotScopeGroup.CARTS,
    HubSpotScopeGroup.INVOICES,
    HubSpotScopeGroup.LINE_ITEMS,
    HubSpotScopeGroup.ORDERS,
    HubSpotScopeGroup.PAYMENTS,
    HubSpotScopeGroup.PRODUCTS,
    HubSpotScopeGroup.QUOTES,
    HubSpotScopeGroup.SUBSCRIPTIONS,
  ],
  users: [HubSpotScopeGroup.USERS],
};

export const REQUIRED_HUBSPOT_SCOPE_GROUPS = new Set([
  HubSpotScopeGroup.CONTACTS_AND_ENGAGEMENTS,
  HubSpotScopeGroup.COMPANIES,
  HubSpotScopeGroup.DEALS,
  HubSpotScopeGroup.LINE_ITEMS,
  HubSpotScopeGroup.FILES,
  HubSpotScopeGroup.PRODUCTS,
  HubSpotScopeGroup.TICKETS,
  HubSpotScopeGroup.USERS,
]);
