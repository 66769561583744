import first from 'lodash/first';

export const cloneCustomRules = (customRules: any, idMap: any) => {
  if (!customRules) {
    return undefined;
  }

  // @ts-expect-error TS(2488): Type '[string, any] | undefined' must have a '[Sym... Remove this comment to see the full error message
  const [sourceId, targetId] = first(Object.entries(idMap));

  return customRules.map((andConditions: any) =>
    andConditions
      .filter((condition: any) => condition.field)
      .map(({ field, operator, value }: any) => ({
        field: {
          ...field,
          id: field.id.replace(sourceId, targetId),
        },
        operator,
        value,
      })),
  );
};
