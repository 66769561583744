import React, { useCallback, useMemo, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconPlus, IconX } from '@tabler/icons-react';
import { Button, ErrorText, Tooltip, getColorShade } from '@noloco/components';
import { getText } from '../../../utils/lang';
import DataFieldInput, { DataFieldInputProps } from './DataFieldInput';

type ArrayFormInputProps = DataFieldInputProps & {
  theme: any;
};
const LANG_KEY = 'data.typeOptions.multiple';
const ArrayFormInput: React.FunctionComponent<ArrayFormInputProps> = ({
  disabled,
  field,
  id,
  onChange,
  theme,
  value,
  validationError,
  ...rest
}: ArrayFormInputProps) => {
  const values = useMemo(() => {
    if (!Array.isArray(value) || value.length === 0) {
      return [null];
    }
    return value;
  }, [value]);
  const [localValues, setLocalValues] = useState(values);
  const fieldWithoutMultiple = useMemo(() => {
    const { multiple: _unused, ...rest } = field;
    return rest;
  }, [field]);

  const handleChange = useCallback(
    (index: number) => (newValue: any) => {
      const newValues = [...localValues];
      newValues[index] = newValue;
      setLocalValues(newValues);
      onChange(newValues);
    },
    [localValues, onChange],
  );
  const handleRemove = useCallback(
    (index: number) => () =>
      onChange([
        ...localValues.slice(0, index),
        ...localValues.slice(index + 1),
      ]),
    [localValues, onChange],
  );
  const handleAdd = useCallback(
    () => setLocalValues([...localValues, null]),
    [localValues],
  );
  const primaryColor = theme.brandColors.primary;

  return (
    <div
      className="transparent flex w-full space-x-4"
      data-testid="array-input"
    >
      <div className="w-full">
        {localValues.map((value: any, index: number) => (
          <div className="my-2 flex">
            <DataFieldInput
              disabled={disabled}
              id={`${id}-${index}`}
              field={fieldWithoutMultiple}
              key={`input-${field.name}-${index}`}
              onChange={handleChange(index)}
              value={value}
              {...rest}
            />
            <Tooltip content={getText(LANG_KEY, 'removeTooltip')} bg="white">
              <IconX
                size={16}
                className={`my-auto ml-2 text-gray-400 hover:text-${getColorShade(
                  primaryColor,
                  500,
                )}`}
                onClick={handleRemove(index)}
              />
            </Tooltip>
          </div>
        ))}
        <Tooltip content={getText(LANG_KEY, 'addTooltip')} bg="white">
          <Button className="text-white" onClick={handleAdd}>
            <IconPlus size={16} />
          </Button>
        </Tooltip>
        {validationError && (
          <ErrorText className="mt-2 text-left">{validationError}</ErrorText>
        )}
      </div>
    </div>
  );
};

export default withTheme(ArrayFormInput);
