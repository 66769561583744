import React, { memo } from 'react';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { BaseConditionValueEditorProps } from '../../models/BaseConditionValueEditorProps';
import ConditionValueInput from './ConditionValueInput';

type BackendConditionValueEditorProps = Pick<
  BaseConditionValueEditorProps,
  | 'contained'
  | 'dataOptions'
  | 'disabled'
  | 'field'
  | 'dataType'
  | 'includeRelationships'
  | 'multiple'
  | 'onChange'
  | 'placeholder'
  | 'project'
  | 'surface'
  | 'value'
>;

const BackendConditionValueEditor = memo(
  ({
    contained,
    dataOptions,
    disabled,
    field,
    dataType,
    includeRelationships,
    multiple,
    onChange,
    placeholder,
    project,
    surface,
    value,
  }: BackendConditionValueEditorProps) => (
    <ConditionValueInput
      checkFieldPermissions={false}
      contained={contained}
      dataOptions={dataOptions}
      dataType={dataType}
      disabled={disabled}
      elementPath={['condition']}
      field={field}
      includeRelationships={includeRelationships}
      includeSelf={false}
      multiple={multiple}
      onChange={onChange}
      project={project}
      placeholder={placeholder}
      surface={surface}
      value={value}
    />
  ),
);

(BackendConditionValueEditor as any).defaultProps = {
  acceptableDataTypes: undefined,
  includeRelationships: false,
  includeUniqueColumnar: false,
  surface: LIGHT,
};

export default BackendConditionValueEditor;
