import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'presentation-analytics',
  'IconPresentationAnalytics',
  [
    ['path', { d: 'M9 12v-4', key: 'svg-0' }],
    ['path', { d: 'M15 12v-2', key: 'svg-1' }],
    ['path', { d: 'M12 12v-1', key: 'svg-2' }],
    ['path', { d: 'M3 4h18', key: 'svg-3' }],
    [
      'path',
      { d: 'M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-10', key: 'svg-4' },
    ],
    ['path', { d: 'M12 16v4', key: 'svg-5' }],
    ['path', { d: 'M9 20h6', key: 'svg-6' }],
  ],
);
