import React, { useMemo } from 'react';
import {
  IconCircleDashed,
  IconCircleX,
  IconFile,
  IconPhoto,
  IconVideo,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Surface, Tooltip } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import ConfirmDeleteButton from '@noloco/ui/src/components/dataTable/ConfirmDeleteButton';
import {
  CIRCLE,
  FILE_NAME,
  FileDisplay,
  INLINE,
  THUMBNAIL,
} from '../../../constants/fileDisplay';
import { DOCUMENT, FileType, IMAGE, VIDEO } from '../../../constants/fileTypes';
import { IMAGE_HEIC } from '../../../constants/mimetypes';
import { File } from '../../../models/File';
import {
  getExtension,
  getFileTypeFromMimetype,
  getNameWithoutExtension,
} from '../../../utils/files';
import { useFilePreview } from '../../../utils/hooks/useFilePreview';
import { getText } from '../../../utils/lang';
import { formatBg } from '../../../utils/styles';
import AttachmentIcon from '../messaging/AttachmentIcon';
import FileDownloadButton from './FileDownloadButton';

const LANG_KEY = 'core.DROPZONE';

const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (event) =>
  event.stopPropagation();

type FileNameProps = { name: string | undefined };

const FileName = ({ name }: FileNameProps) => {
  const extension = useMemo(() => getExtension(name), [name]);

  const extensionWithoutName = useMemo(
    () => getNameWithoutExtension(name, extension),
    [extension, name],
  );

  return (
    <Tooltip content={name} placement="bottom">
      <div className="flex max-w-full">
        <span className="truncate">{extensionWithoutName}</span>
        <span>{extension}</span>
      </div>
    </Tooltip>
  );
};

const InlineVideo = ({ maxH, src }: { maxH: number; src: string }) => (
  <video
    className={classNames(`max-h-${maxH} mx-auto rounded`)}
    src={src}
    autoPlay={false}
    muted={true}
    controls={false}
  />
);

type FilePreviewProps = {
  file: File;
  fileType: FileType;
  mimetype: string;
  display?: FileDisplay;
  src: string;
  name: string;
  maxH: number;
  surface: Surface;
  onRemove: (id: number) => void;
};

const FilePreview = ({
  file,
  fileType,
  mimetype,
  display = THUMBNAIL,
  src,
  name,
  maxH,
  surface,
  onRemove,
}: FilePreviewProps) => {
  const canPreviewImage = fileType === IMAGE && mimetype !== IMAGE_HEIC;

  const { showFileButtons, showFileName, squareRounding } =
    useFilePreview(maxH);

  if (display === FILE_NAME) {
    return <FileName name={name} />;
  }

  if (display === INLINE && fileType === VIDEO) {
    return <InlineVideo maxH={maxH} src={src} />;
  }

  return (
    <div
      className={classNames(
        `h-${maxH} group/dropzone-preview relative flex items-center justify-center`,
        {
          'border dark:border-gray-700':
            display === (THUMBNAIL || !canPreviewImage) && surface !== DARK,
          'border border-slate-800':
            display === (THUMBNAIL || !canPreviewImage) && surface === DARK,
          [`w-${maxH} bg-white bg-cover bg-center dark:bg-gray-800`]:
            display !== (INLINE || !canPreviewImage) && surface !== DARK,
          [`w-${maxH} bg-slate-800 bg-cover bg-center`]:
            display !== (INLINE || !canPreviewImage) && surface === DARK,
          [squareRounding]: display === THUMBNAIL || !canPreviewImage,
          'rounded-full': display === CIRCLE && canPreviewImage,
        },
      )}
      data-testid="file-preview"
      style={canPreviewImage && display !== INLINE ? formatBg(src) : undefined}
    >
      {canPreviewImage && display === INLINE && (
        <img className={classNames('h-full w-auto')} src={src} alt={name} />
      )}
      {!canPreviewImage && (
        <Tooltip
          content={name}
          placement="bottom"
          popoverClassName={classNames({ 'text-gray-200': surface === DARK })}
          disabled={showFileName}
          surface={surface}
        >
          <span
            className={classNames('inline-block', {
              'mb-4': showFileName,
              'text-gray-800 dark:text-gray-200': surface !== DARK,
              'text-gray-500': surface === DARK,
            })}
          >
            <AttachmentIcon mimetype={mimetype} size={24} />
          </span>
        </Tooltip>
      )}
      {display === THUMBNAIL && showFileButtons && (
        <div
          className={classNames(
            'absolute right-1 top-1 hidden h-6 items-center space-x-1 rounded-md bg-gray-700 bg-opacity-50 px-1 text-white group-hover/dropzone-preview:flex dark:bg-gray-900 dark:text-gray-200',
          )}
          onClick={stopPropagation}
        >
          {onRemove && (
            <Tooltip content={name} placement="top">
              <ConfirmDeleteButton
                tooltip={true}
                onDelete={() => onRemove(file.id as number)}
              />
            </Tooltip>
          )}
          <Tooltip content={name} placement="top">
            <FileDownloadButton
              className="opacity-50 hover:opacity-100"
              file={file}
            />
          </Tooltip>
        </div>
      )}
      {(!canPreviewImage || display === THUMBNAIL) && showFileName && (
        <div
          className={classNames(
            'absolute bottom-0 left-0 right-0 h-6 items-center bg-gray-100 px-1 text-xs text-gray-800 dark:bg-gray-700 dark:text-gray-100',
            {
              'hidden group-hover/dropzone-preview:flex': canPreviewImage,
              flex: !canPreviewImage,
            },
          )}
        >
          <FileName name={name} />
        </div>
      )}
    </div>
  );
};

const getFileTypeIcon = (fileType: any) => {
  switch (fileType) {
    case VIDEO:
      return IconVideo;
    case DOCUMENT:
      return IconFile;
    default:
      return IconPhoto;
  }
};

const DropzonePreview = ({
  className,
  disabled = false,
  fileType = IMAGE,
  uploadingImgData,
  loading,
  display,
  mediaItem,
  placeholder,
  showIcon = true,
  maxH,
  onRemove,
  surface,
}: any) => {
  const handleRemove = (event: any) => {
    event.stopPropagation();
    onRemove(event);
  };

  const Icon = showIcon && getFileTypeIcon(fileType);

  const { squareRounding } = useFilePreview(maxH);

  return (
    <>
      {!uploadingImgData && !mediaItem && (
        <>
          {showIcon && <Icon size={36} className="mb-4" />}
          <span>{placeholder || getText(LANG_KEY, 'label')}</span>
          <div
            aria-hidden="true"
            className="invisible h-0 overflow-hidden whitespace-nowrap text-sm"
          >
            {placeholder || getText(LANG_KEY, 'label')}
          </div>
        </>
      )}
      {(uploadingImgData || mediaItem) && (
        <div className={className}>
          {uploadingImgData && (
            <FilePreview
              maxH={maxH}
              name={uploadingImgData.file.name}
              mimetype={uploadingImgData.file.mimetype}
              file={uploadingImgData.file}
              display={display}
              fileType={fileType}
              src={uploadingImgData.preview}
              surface={surface}
              onRemove={onRemove}
            />
          )}
          {mediaItem && !uploadingImgData && !loading && (
            <FilePreview
              maxH={maxH}
              name={mediaItem.name}
              fileType={
                mediaItem.fileType ||
                (mediaItem.mimetype &&
                  getFileTypeFromMimetype(mediaItem.mimetype))
              }
              display={display}
              file={mediaItem}
              mimetype={mediaItem.mimetype}
              src={mediaItem.uri || mediaItem.url}
              surface={surface}
              onRemove={onRemove}
            />
          )}
          {loading && (
            <div
              className={classNames(
                'absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75',
                squareRounding,
              )}
            >
              <IconCircleDashed size={36} className="animate-spin" />
            </div>
          )}
          {!loading && !disabled && (
            <div className="absolute right-0 top-0 -mr-4 -mt-4 rounded-full bg-gray-200 bg-opacity-25 text-gray-600">
              <IconCircleX size={24} onClick={handleRemove} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

DropzonePreview.defaultProps = {
  disabled: false,
  fileType: IMAGE,
  showIcon: true,
};

export default DropzonePreview;
