import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-badge-left-filled',
  'IconArrowBadgeLeftFilled',
  [
    [
      'path',
      {
        d: 'M17 6h-6a1 1 0 0 0 -.78 .375l-4 5a1 1 0 0 0 0 1.25l4 5a1 1 0 0 0 .78 .375h6l.112 -.006a1 1 0 0 0 .669 -1.619l-3.501 -4.375l3.5 -4.375a1 1 0 0 0 -.78 -1.625z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
  ],
);
