import createReactComponent from '../createReactComponent';
export default createReactComponent('parachute', 'IconParachute', [
  ['path', { d: 'M22 12a10 10 0 1 0 -20 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M22 12c0 -1.66 -1.46 -3 -3.25 -3c-1.8 0 -3.25 1.34 -3.25 3c0 -1.66 -1.57 -3 -3.5 -3s-3.5 1.34 -3.5 3c0 -1.66 -1.46 -3 -3.25 -3c-1.8 0 -3.25 1.34 -3.25 3',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M2 12l10 10l-3.5 -10', key: 'svg-2' }],
  ['path', { d: 'M15.5 12l-3.5 10l10 -10', key: 'svg-3' }],
]);
