import createReactComponent from '../createReactComponent';
export default createReactComponent('droplet-search', 'IconDropletSearch', [
  [
    'path',
    {
      d: 'M18.064 10.877l-4.89 -7.26c-.42 -.625 -1.287 -.803 -1.936 -.397a1.376 1.376 0 0 0 -.41 .397l-4.893 7.26c-1.695 2.838 -1.035 6.441 1.567 8.546a7.13 7.13 0 0 0 4.168 1.572',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-2' }],
]);
