import { useMemo } from 'react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import initial from 'lodash/initial';
import { useSelector } from 'react-redux';
import { USER } from '../../constants/builtInDataTypes';
import { LIST, PAGE, VIEW } from '../../constants/elements';
import { DATABASE } from '../../constants/scopeTypes';
import { QueryObject } from '../../queries/data';
import {
  BLANK_QUERY_STRING,
  getCollectionDataQueryString,
  getPageQueryString,
  getQueryVariableArgs,
} from '../../queries/project';
import { queriesSelector } from '../../selectors/queriesSelectors';
import {
  findDependentValues,
  formatFilterAndMergeCustomFilters,
  mapDepPathToApiPath,
  transformDepsToQueryObject,
} from '../data';
import { getFieldFromDependency } from '../fields';
import { transformQueryArgs } from '../queries';
import { isMultiField } from '../relationships';

export const getQueryObjectFromDeps = (
  additionalDeps: any,
  element: any,
  dataType: any,
  dataTypesWithRelations: any,
  parentPath = [],
) => {
  if (!element) {
    return {
      deps: [],
      nodeQueryObject: {},
      queryObject: { id: true },
    };
  }

  const deps = [
    ...findDependentValues(element.id, element, dataTypesWithRelations),
    ...additionalDeps,
  ];

  deps.push({ path: 'pageInfo.hasNextPage', id: element.id, source: DATABASE });
  deps.push({
    path: 'pageInfo.hasPreviousPage',
    id: element.id,
    source: DATABASE,
  });
  deps.push({ path: 'pageInfo.startCursor', id: element.id, source: DATABASE });
  deps.push({ path: 'pageInfo.endCursor', id: element.id, source: DATABASE });
  deps.push({ path: 'totalCount', id: element.id, source: DATABASE });

  const queryObject = transformDepsToQueryObject(
    dataType,
    dataTypesWithRelations,
    deps,
    parentPath,
  );

  return {
    deps,
    nodeQueryObject: get(queryObject, [...parentPath, 'edges', 'node']),
    queryObject,
  };
};

const setNestedWhereFilters = (
  where = [],
  queryObject: any,
  dataType: any,
  parentPath = [],
) => {
  const wherePath = [...parentPath, '__args', 'where'];
  const currentWhere = get(queryObject, wherePath, {});
  const mergedWhere = formatFilterAndMergeCustomFilters(
    where,
    dataType,
    currentWhere,
  );

  return set(wherePath, mergedWhere, queryObject);
};

const getQueryString = (
  dataType: any,
  dataTypesWithRelations: any,
  element: any,
  { where = [], ...args } = { skip: 0 },
  additionalDeps = [],
  additionalArgs: { __variables?: Record<string, any> } = {},
  countOnly = false,
) => {
  const { deps, nodeQueryObject, queryObject } = getQueryObjectFromDeps(
    additionalDeps,
    element,
    dataType,
    dataTypesWithRelations,
  );

  let queryObjectWithFilters = setNestedWhereFilters(
    where,
    queryObject,
    dataType,
  );

  if (additionalArgs.__variables) {
    queryObjectWithFilters.__variables = additionalArgs.__variables;
  }

  if (countOnly) {
    queryObjectWithFilters = set('edges', undefined, queryObjectWithFilters);
    queryObjectWithFilters = set('pageInfo', undefined, queryObjectWithFilters);
  }

  return {
    dataType,
    queryObject: queryObjectWithFilters,
    nodeQueryObject,
    query: getCollectionDataQueryString(
      dataType.apiName,
      queryObjectWithFilters,
      args,
    ),
    skip: !deps || deps.length === 0,
  };
};

const getQueryAndVariables = (
  project: any,
  elementPath: any,
  dataType: any,
  defaultFilter: any,
  element: any,
  queries: any,
  queryArgs: any,
  additionalDeps = [],
  additionalArgs: { __variables?: Record<string, any> } = {},
  countOnly = false,
) => {
  const listDataType = project.dataTypes.getByName(dataType);

  if (!listDataType) {
    return {
      skip: true,
    };
  }

  const dataTypes = project.dataTypes;

  if (!defaultFilter || !defaultFilter.id || !queries[defaultFilter.id]) {
    return {
      ...getQueryString(
        listDataType,
        dataTypes,
        element,
        queryArgs,
        additionalDeps,
        additionalArgs,
        countOnly,
      ),
      variables: queryArgs,
      valuePath: `${listDataType.apiName}Collection`,
    };
  }

  const {
    dataType: parentDataTypeName,
    dataProperty,
    variables = {},
    type,
    valuePath: parentValuePath = '',
  } = queries[defaultFilter.id];

  const dtName =
    parentDataTypeName === 'currentUser' ? USER : parentDataTypeName;
  const parentDataType = project.dataTypes.getByName(dtName);
  const valuePath = defaultFilter.path;

  const parentPath = mapDepPathToApiPath(
    (parentValuePath + valuePath).split('.'),
    parentDataType,
    project.dataTypes,
  );

  const splitValuePath = valuePath.split('.');

  const isValidFilter = splitValuePath.every((__: any, idx: any) => {
    const pathSegmentField = getFieldFromDependency(
      splitValuePath.slice(0, idx + 1),
      parentDataType,
      dataTypes,
    );

    return (
      pathSegmentField &&
      (pathSegmentField.field.relationship ||
        pathSegmentField.field.relatedField) &&
      (idx < splitValuePath.length - 1 || isMultiField(pathSegmentField.field))
    );
  });

  const restVariables = variables;

  if (!isValidFilter) {
    console.log('invalid', {
      dtName,
      parentDataType,
      valuePath,
      parentPath,
      dataTypes,
      paths: splitValuePath.map((__: any, idx: number) =>
        getFieldFromDependency(
          splitValuePath.slice(0, idx + 1),
          parentDataType,
          dataTypes,
        ),
      ),
    });
    return {
      error: 'Invalid filter',
      skip: true,
      query: BLANK_QUERY_STRING,
      variables: restVariables,
      valuePath: `${parentDataType.apiName}.${parentPath.join('.')}`,
    };
  }

  const { deps, nodeQueryObject, queryObject } = getQueryObjectFromDeps(
    additionalDeps,
    element,
    listDataType,
    dataTypes,
    parentPath,
  );

  const { where = [], ...restQueryArgs } = queryArgs;

  let queryObjectWithNestedIds = initial(parentPath).reduce(
    (updatedObject, pathSlice, pathSliceIndex) =>
      set(
        parentPath.slice(0, pathSliceIndex + 1),
        {
          id: true,
          uuid: true,
          ...get(updatedObject, parentPath.slice(0, pathSliceIndex + 1)),
        },
        // @ts-expect-error TS(2769): No overload matches this call.
        updatedObject,
      ),
    queryObject,
  );

  if (!parentValuePath && parentPath.length === 1) {
    queryObjectWithNestedIds = {
      id: true,
      uuid: true,
      // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
      ...queryObjectWithNestedIds,
    };
  }

  const argsPath = [...parentPath, '__args'];
  let queryObjectWithArgs = set<QueryObject>(
    argsPath,
    transformQueryArgs(restQueryArgs),
    // @ts-expect-error TS(2769): No overload matches this call.
    queryObjectWithNestedIds,
  );

  if (additionalArgs.__variables) {
    // @ts-expect-error TS(2769): No overload matches this call.
    queryObjectWithArgs.__variables = additionalArgs.__variables;
    const queryVars = getQueryVariableArgs(additionalArgs.__variables);
    // @ts-expect-error TS(2769): No overload matches this call.
    queryObjectWithArgs = set<QueryObject>(
      argsPath,
      {
        ...(get(queryObjectWithArgs, argsPath, {}) as object),
        ...queryVars,
      },
      queryObjectWithArgs,
    );
  }

  const queryObjectWithFilters = setNestedWhereFilters(
    where,
    queryObjectWithArgs,
    listDataType,
    parentPath,
  );

  let finalQueryObject = queryObjectWithFilters;

  if (countOnly) {
    finalQueryObject = set(
      [...parentPath, 'edges'],
      undefined,
      finalQueryObject,
    );
    finalQueryObject = set(
      [...parentPath, 'pageInfo'],
      undefined,
      finalQueryObject,
    );
  }

  const parentApiName =
    parentDataTypeName === 'currentUser'
      ? parentDataTypeName
      : parentDataType.apiName;

  let gqlQueryString;
  if (type === PAGE || type === VIEW) {
    gqlQueryString = getPageQueryString(
      parentApiName,
      variables[dataProperty]
        ? { [dataProperty]: variables[dataProperty] }
        : undefined,
      finalQueryObject,
    );
  } else if (type === LIST) {
    gqlQueryString = getCollectionDataQueryString(
      listDataType.apiName,
      finalQueryObject,
    );
  }

  return {
    dataType: listDataType,
    skip: deps.length === 0,
    query: gqlQueryString,
    queryObject: queryObjectWithFilters,
    nodeQueryObject,
    variables: restVariables,
    valuePath: `${parentApiName}.${parentPath.join('.')}`,
  };
};

const useDataListQueryObject = (
  dataTypeName: any,
  project: any,
  elementPath: any,
  {
    additionalArgs = {},
    additionalDeps,
    after,
    before,
    filter,
    orderBy,
    limit,
    customFilters,
    countOnly,
  }: any = {},
) => {
  const queries = useSelector(queriesSelector);

  const element = useMemo(
    () => get(project.elements, elementPath),
    [elementPath, project.elements],
  );

  const queryArgs = useMemo(
    () => ({
      ...(after && !before ? { after } : {}),
      ...(before ? { before } : {}),
      ...(orderBy ? { orderBy } : {}),
      ...(limit ? { first: limit } : {}),
      ...(customFilters ? { where: customFilters } : {}),
    }),
    [after, before, customFilters, limit, orderBy],
  );

  const dataListQueryObject = useMemo(() => {
    if (!dataTypeName) {
      return {};
    }

    try {
      return getQueryAndVariables(
        project,
        elementPath,
        dataTypeName,
        filter,
        element,
        queries,
        queryArgs,
        additionalDeps,
        additionalArgs,
        countOnly,
      );
    } catch (e) {
      console.error(e);

      return {
        skip: true,
      };
    }
  }, [
    additionalArgs,
    additionalDeps,
    countOnly,
    dataTypeName,
    element,
    elementPath,
    filter,
    project,
    queries,
    queryArgs,
  ]);

  return dataListQueryObject;
};

export default useDataListQueryObject;
