import { useMemo } from 'react';
import { IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import { Tooltip, getColorShade } from '@noloco/components';
import { darkModeColors } from '../../constants/darkModeColors';
import { Project } from '../../models/Project';
import useDarkMode from '../../utils/hooks/useDarkMode';
import { getText } from '../../utils/lang';
import { Page } from '../../utils/pages';
import BuildModeSidebarItemOptions from '../buildMode/sidebar/BuildModeSidebarItemOptions';

type AddPageLineProps = {
  portalPages: Page[];
  primaryColor?: string;
  project: Project;
  page?: Page;
  parentPage?: Page;
};

const AddPageLine = ({
  portalPages,
  primaryColor = 'slate',
  project,
  page,
  parentPage,
}: AddPageLineProps) => {
  const [isDarkModeEnabled] = useDarkMode();
  const newPageIndex = useMemo(() => {
    if (!page) {
      return portalPages.length;
    }

    const index = portalPages.findIndex(
      (portalPage: Page) => portalPage.id === page.id,
    );

    return index >= 0 ? index : portalPages.length - 1;
  }, [portalPages, page]);

  return (
    <Tooltip
      bg={
        isDarkModeEnabled
          ? darkModeColors.surfaces.elevation2LiteralColor
          : 'white'
      }
      content={
        <div
          className={classNames('pb-1', {
            [darkModeColors.text.primary]: isDarkModeEnabled,
          })}
        >
          {getText('leftSidebar.pages.newPageOptions.addPageHere')}
        </div>
      }
      delayShow={250}
      placement="top"
      offset={[0, 16]}
    >
      <div className="group flex h-0.5 w-full">
        <BuildModeSidebarItemOptions
          modules={!parentPage}
          newPageIndex={newPageIndex}
          parentPage={parentPage}
          project={project}
        >
          <div className="z-50 hidden h-full w-full px-3 group-hover:flex">
            <div className="relative flex w-full">
              <div className="absolute bottom-0 left-0 right-0 top-0 flex cursor-pointer items-center justify-center">
                <IconPlus
                  size={18}
                  className={`rounded-full p-1 bg-${getColorShade(
                    primaryColor,
                    500,
                  )} text-gray-100`}
                />
              </div>
              <hr
                className={classNames(
                  'w-full rounded-lg border shadow-xl',
                  `border-${getColorShade(
                    primaryColor,
                    500,
                  )} bg-${getColorShade(primaryColor, 500)}`,
                )}
              />
            </div>
          </div>
        </BuildModeSidebarItemOptions>
      </div>
    </Tooltip>
  );
};

export default AddPageLine;
