import createReactComponent from '../createReactComponent';
export default createReactComponent('bluetooth-off', 'IconBluetoothOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M16.438 16.45l-4.438 3.55v-8m0 -4v-4l5 4l-2.776 2.22m-2.222 1.779l-5 4',
      key: 'svg-1',
    },
  ],
]);
