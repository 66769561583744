import createReactComponent from '../createReactComponent';
export default createReactComponent('circle-dashed', 'IconCircleDashed', [
  ['path', { d: 'M8.56 3.69a9 9 0 0 0 -2.92 1.95', key: 'svg-0' }],
  ['path', { d: 'M3.69 8.56a9 9 0 0 0 -.69 3.44', key: 'svg-1' }],
  ['path', { d: 'M3.69 15.44a9 9 0 0 0 1.95 2.92', key: 'svg-2' }],
  ['path', { d: 'M8.56 20.31a9 9 0 0 0 3.44 .69', key: 'svg-3' }],
  ['path', { d: 'M15.44 20.31a9 9 0 0 0 2.92 -1.95', key: 'svg-4' }],
  ['path', { d: 'M20.31 15.44a9 9 0 0 0 .69 -3.44', key: 'svg-5' }],
  ['path', { d: 'M20.31 8.56a9 9 0 0 0 -1.95 -2.92', key: 'svg-6' }],
  ['path', { d: 'M15.44 3.69a9 9 0 0 0 -3.44 -.69', key: 'svg-7' }],
]);
