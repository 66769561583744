import VisibilityRulesEditor from '@noloco/ui/src/components/editor/VisibilityRulesEditor';
import { DataType } from '../../../models/DataTypes';
import { Element, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { Page } from '../../../utils/pages';

type BuildModeViewVisibilityTabProps = {
  dataType: DataType;
  element: Page;
  elementPath: ElementPath;
  project: Project;
  section?: Element;
  sectionPropPath?: ElementPath;
};

const BuildModeViewVisibilityTab = ({
  dataType,
  element,
  elementPath,
  project,
  section,
  sectionPropPath = [],
}: BuildModeViewVisibilityTabProps) => (
  <>
    <hr className="w-full border-slate-700" />
    <VisibilityRulesEditor
      dataType={dataType}
      element={section ?? element}
      elementPath={section ? sectionPropPath : elementPath}
      project={project}
    />
  </>
);

export default BuildModeViewVisibilityTab;
