import createReactComponent from '../createReactComponent';
export default createReactComponent('arrow-fork', 'IconArrowFork', [
  ['path', { d: 'M16 3h5v5', key: 'svg-0' }],
  ['path', { d: 'M8 3h-5v5', key: 'svg-1' }],
  [
    'path',
    { d: 'M21 3l-7.536 7.536a5 5 0 0 0 -1.464 3.534v6.93', key: 'svg-2' },
  ],
  ['path', { d: 'M3 3l7.536 7.536a5 5 0 0 1 1.464 3.534v.93', key: 'svg-3' }],
]);
