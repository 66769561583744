import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-rounded-letter-m',
  'IconSquareRoundedLetterM',
  [
    ['path', { d: 'M9 16v-8l3 5l3 -5v8', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-1',
      },
    ],
  ],
);
