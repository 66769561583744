import React, { memo } from 'react';
import get from 'lodash/get';
import { CARDS } from '../../../../constants/collectionLayouts';
import { getValuePathForFieldConfig } from '../../../../utils/fields';
import FieldCell from '../FieldCell';

const CardFieldCell = memo(
  ({
    // @ts-expect-error TS(2339): Property 'backLink' does not exist on type '{}'.
    backLink,
    // @ts-expect-error TS(2339): Property 'config' does not exist on type '{}'.
    config,
    // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
    dataType,
    // @ts-expect-error TS(2339): Property 'elementId' does not exist on type '{}'.
    elementId,
    // @ts-expect-error TS(2339): Property 'field' does not exist on type '{}'.
    field,
    // @ts-expect-error TS(2339): Property 'parent' does not exist on type '{}'.
    parent,
    // @ts-expect-error TS(2339): Property 'permissions' does not exist on type '{}'... Remove this comment to see the full error message
    permissions,
    // @ts-expect-error TS(2339): Property 'record' does not exist on type '{}'.
    record,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'transformRecordScope' does not exist on ... Remove this comment to see the full error message
    transformRecordScope,
    // @ts-expect-error TS(2339): Property 'bulkActionsEnabled' does not exist on ... Remove this comment to see the full error message
    bulkActionsEnabled,
    // @ts-expect-error TS(2339): Property 'isRowChecked' does not exist on ... Remove this comment to see the full error message
    isRowChecked,
    // @ts-expect-error TS(2339): Property 'selectedRows' does not exist on ... Remove this comment to see the full error message
    selectedRows,
  }) => (
    <FieldCell
      backLink={backLink}
      className="mr-auto flex w-full flex-col"
      showLabel={true}
      config={config}
      dataType={dataType}
      field={field}
      transformScope={transformRecordScope}
      layout={CARDS}
      permissions={permissions}
      record={record}
      value={get(record, getValuePathForFieldConfig(field, parent))}
      project={project}
      key={field.name}
      skipResolvingForValueIds={[`${elementId}:RECORD`]}
      bulkActionsEnabled={bulkActionsEnabled}
      isRowChecked={isRowChecked}
      selectedRows={selectedRows}
    />
  ),
);

export default CardFieldCell;
