import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-mobile-bolt',
  'IconDeviceMobileBolt',
  [
    [
      'path',
      {
        d: 'M13.5 21h-5.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-1' }],
    ['path', { d: 'M11 4h2', key: 'svg-2' }],
    ['path', { d: 'M12 17v.01', key: 'svg-3' }],
  ],
);
