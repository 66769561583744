import createReactComponent from '../createReactComponent';
export default createReactComponent('discount', 'IconDiscount', [
  ['path', { d: 'M9 15l6 -6', key: 'svg-0' }],
  [
    'circle',
    { cx: '9.5', cy: '9.5', r: '.5', fill: 'currentColor', key: 'svg-1' },
  ],
  [
    'circle',
    { cx: '14.5', cy: '14.5', r: '.5', fill: 'currentColor', key: 'svg-2' },
  ],
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-3' }],
]);
