import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  IconArrowNarrowRight,
  IconChevronDown,
  IconChevronRight,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import {
  Button,
  FormField,
  Loader,
  PrivateKeyFormField,
  PublicCertificateFormField,
  Switch,
} from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { MYSQL, POSTGRES } from '@noloco/core/src/constants/dataSources';
import { NOLOCO_STATIC_IP_ADDRESSES } from '@noloco/core/src/constants/ipAddresses';
import { SqlConnection } from '@noloco/core/src/models/SqlConnection';
import useSetDocumentTitle from '@noloco/core/src/utils/hooks/useSetDocumentTitle';
import { getText } from '@noloco/core/src/utils/lang';
import { isSchemaRequiredForDataSourceType } from '@noloco/core/src/utils/sql';
import mysqlLogo from '../../img/mysql-square-logo.png';
import postgresLogo from '../../img/postgres-logo.png';
import { useAddDataSource } from '../../utils/hooks/useAddDataSource';
import Guide from '../Guide';
import DataSourceImportStatus from './DataSourceImportStatus';

const FORM_STEPS = 4;

const DEFAULT_FORM_VALUES = {
  [MYSQL]: {
    databaseName: 'MySQL Database',
    port: '3306',
  },
  [POSTGRES]: {
    databaseName: 'Postgres Database',
    port: '5432',
    schema: 'public',
  },
};

const SQL_TYPE_GUIDES = {
  [MYSQL]: 'https://guides.noloco.io/data/mysql',
  [POSTGRES]: 'https://guides.noloco.io/data/postgresql',
};

const SQL_TYPE_LOGOS = {
  [MYSQL]: mysqlLogo,
  [POSTGRES]: postgresLogo,
};

const AddSqlDatabase = ({
  project,
  sqlType,
  inOnboarding = false,
  onConnect,
  surface = DARK,
}: any) => {
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const langKey = useMemo(() => `data.${sqlType.toLowerCase()}`, [sqlType]);
  const schemaIsRequired = useMemo(
    () => isSchemaRequiredForDataSourceType(sqlType),
    [sqlType],
  );

  const [databaseName, setDatabaseName] = useState(
    get(DEFAULT_FORM_VALUES, [sqlType, 'databaseName']),
  );

  useSetDocumentTitle(getText(langKey, 'documentTitle'));

  const [ca, setCa] = useState<string | undefined>(undefined);
  const [clientCert, setClientCert] = useState<string | undefined>(undefined);
  const [clientKey, setClientKey] = useState<string | undefined>(undefined);
  const [database, setDatabase] = useState<string | undefined>(undefined);
  const [host, setHost] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [port, setPort] = useState<string | undefined>(
    get(DEFAULT_FORM_VALUES, [sqlType, 'port']),
  );
  const [readOnly, setReadOnly] = useState<boolean | undefined>(false);
  const [schema, setSchema] = useState<string | undefined>(
    get(DEFAULT_FORM_VALUES, [sqlType, 'schema']),
  );
  const [selfSigned, setSelfSigned] = useState<boolean | undefined>(false);
  const [user, setUser] = useState<string | undefined>(undefined);
  const [useSsl, setUseSsl] = useState<boolean | undefined>(false);

  const [advancedSettingsIsOpen, setAdvancedSettingsIsOpen] = useState(false);
  const toggleAdvancedSettingsIsOpen = useCallback(
    () => setAdvancedSettingsIsOpen((current) => !current),
    [],
  );

  const AdvancedSettingsIcon = advancedSettingsIsOpen
    ? IconChevronDown
    : IconChevronRight;

  const {
    createdDataSource,
    builtPages,
    error,
    existingDataSource,
    hasSyncedData,
    inProgressPages,
    isConnect,
    isConnecting,
    isNameValid,
    isUpdate,
    isUpdating,
    onClickNext,
    onDataTypesSelected,
    onFinish,
    skippedPages,
    step,
  } = useAddDataSource({
    project,
    connection: {
      ca,
      clientCert,
      clientKey,
      database,
      host,
      password,
      port,
      readOnly,
      schema,
      user,
      useSsl,
    },
    display: databaseName,
    type: sqlType,
    formSteps: FORM_STEPS,
    onAuthenticationFail: null,
    inOnboarding,
    canShowTableChooser: true,
  });

  useLayoutEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTo({
        top: bottomRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [step, bottomRef]);

  const updateConnectionState = useCallback((connection: SqlConnection) => {
    setDatabase(connection.database);
    setHost(connection.host);
    setPassword(connection.password);
    setPort(connection.port);
    setSchema(connection.schema);
    setUser(connection.user);
    setUseSsl(connection.useSsl);
    setReadOnly(connection.readOnly);
    setSelfSigned(connection.selfSigned);
    setCa(connection.ca);
    setClientCert(connection.clientCert);
    setClientKey(connection.clientKey);
  }, []);

  const setStateForExistingDatasource = useCallback(() => {
    if (existingDataSource && Object.keys(existingDataSource).length > 0) {
      setDatabaseName(existingDataSource.display);
      updateConnectionState(existingDataSource.connection);
    }
  }, [existingDataSource, updateConnectionState]);

  useEffect(() => {
    setStateForExistingDatasource();
  }, [existingDataSource, setStateForExistingDatasource]);

  const isStepValid = useMemo(
    () =>
      [
        isNameValid,
        host && port,
        database && (schema || !schemaIsRequired),
        user,
      ][step],
    [database, host, isNameValid, port, schema, schemaIsRequired, step, user],
  );

  const formIsReadOnly = useMemo(
    () => isConnecting || isUpdating || step >= FORM_STEPS,
    [isConnecting, isUpdating, step],
  );

  return (
    <div className="flex w-full">
      <div
        className={classNames(
          'w-full max-w-xl overflow-y-auto p-8 text-white',
          {
            'bg-slate-700': !inOnboarding,
            'max-h-screen-75': inOnboarding,
          },
        )}
      >
        {!inOnboarding && (
          <>
            <h1 className="flex items-center text-xl">
              <span>{getText(langKey, 'title')}</span>
              <img
                src={SQL_TYPE_LOGOS[sqlType]}
                alt={getText(langKey, 'logoAlt')}
                className="mx-2 h-8"
              />
              <span>{getText(langKey, 'sourceName')}</span>
            </h1>
            <Guide className="mb-8 mt-4" href={SQL_TYPE_GUIDES[sqlType]}>
              {getText(langKey, 'help')}
            </Guide>
          </>
        )}
        <FormField
          errorMessage={
            !databaseName || isNameValid
              ? null
              : getText(langKey, 'name.invalid')
          }
          errorType="below-solid"
          help={getText(langKey, 'name.help')}
          label={getText(langKey, 'name.label')}
          name="name"
          onChange={({ target: { value } }: any) => setDatabaseName(value)}
          placeholder={getText(langKey, 'name.placeholder')}
          readOnly={formIsReadOnly}
          required
          type="text"
          surface={surface}
          value={databaseName}
        />
        {step > 0 && (
          <>
            <FormField
              className="mt-6"
              help={getText(langKey, 'host.help')}
              label={getText(langKey, 'host.label')}
              name="host"
              onChange={({ target: { value } }: any) => setHost(value)}
              placeholder={getText(langKey, 'host.placeholder')}
              readOnly={formIsReadOnly}
              required
              surface={surface}
              type="text"
              value={host}
            />
            <FormField
              className="mt-6"
              help={getText(langKey, 'port.help')}
              label={getText(langKey, 'port.label')}
              name="port"
              onChange={({ target: { value } }: any) => setPort(value)}
              placeholder={getText(langKey, 'port.placeholder')}
              readOnly={formIsReadOnly}
              required
              type="text"
              surface={surface}
              value={port}
            />
          </>
        )}
        {step > 1 && (
          <>
            <FormField
              className="mt-6"
              help={getText(langKey, 'database.help')}
              label={getText(langKey, 'database.label')}
              name="database"
              onChange={({ target: { value } }: any) => setDatabase(value)}
              placeholder={getText(langKey, 'database.placeholder')}
              readOnly={formIsReadOnly}
              required
              surface={surface}
              type="text"
              value={database}
            />
            {schemaIsRequired && (
              <FormField
                className="mt-6"
                help={getText(langKey, 'schema.help')}
                label={getText(langKey, 'schema.label')}
                name="schema"
                onChange={({ target: { value } }: any) => setSchema(value)}
                placeholder={getText(langKey, 'schema.placeholder')}
                readOnly={formIsReadOnly}
                required
                surface={surface}
                type="text"
                value={schema}
              />
            )}
          </>
        )}
        {step > 2 && (
          <>
            <FormField
              className="mt-6"
              help={getText(langKey, 'user.help')}
              label={getText(langKey, 'user.label')}
              name="user"
              onChange={({ target: { value } }: any) => setUser(value)}
              placeholder={getText(langKey, 'user.placeholder')}
              readOnly={formIsReadOnly}
              required
              surface={surface}
              type="text"
              value={user}
            />
            <FormField
              className="mt-6"
              help={getText(langKey, 'password.help')}
              label={getText(langKey, 'password.label')}
              name="password"
              onChange={({ target: { value } }: any) => setPassword(value)}
              placeholder={getText(langKey, 'password.placeholder')}
              readOnly={formIsReadOnly}
              surface={surface}
              type="password"
              value={password}
            />
            <>
              <div
                className={classNames(
                  'group mt-6 flex cursor-pointer items-center',
                  {
                    'text-gray-600': inOnboarding,
                    'text-white': !inOnboarding,
                  },
                )}
                onClick={toggleAdvancedSettingsIsOpen}
              >
                <AdvancedSettingsIcon
                  className="mr-4 opacity-75 group-hover:opacity-100"
                  size={16}
                />
                <label className="cursor-pointer text-sm">
                  {getText(langKey, 'advancedSettings')}
                </label>
              </div>
              {advancedSettingsIsOpen && (
                <div className="space-y-6 pl-4">
                  <div className="mt-6 flex items-center justify-between">
                    <div>
                      <label
                        className={classNames(
                          'mb-2 block text-sm font-medium leading-5',
                          {
                            'text-gray-600': inOnboarding,
                            'text-white': !inOnboarding,
                          },
                        )}
                      >
                        {getText(langKey, 'readOnly.label')}
                      </label>
                      <div
                        className={classNames('mb-2 text-sm', {
                          'text-gray-600': inOnboarding,
                          'text-white': !inOnboarding,
                        })}
                      >
                        {getText(langKey, 'readOnly.help')}
                      </div>
                    </div>
                    <Switch
                      onChange={(readOnly: any) => setReadOnly(readOnly)}
                      value={readOnly}
                    />
                  </div>
                  <hr />
                  <div className="flex items-center justify-between">
                    <div>
                      <label
                        className={classNames(
                          'mb-2 block text-sm font-medium leading-5',
                          {
                            'text-gray-600': inOnboarding,
                            'text-white': !inOnboarding,
                          },
                        )}
                      >
                        {getText(langKey, 'useSsl.label')}
                      </label>
                      <div
                        className={classNames('mb-2 text-sm', {
                          'text-gray-600': inOnboarding,
                          'text-white': !inOnboarding,
                        })}
                      >
                        {getText(langKey, 'useSsl.help')}
                      </div>
                    </div>
                    <Switch
                      onChange={(useSsl: any) => setUseSsl(useSsl)}
                      value={useSsl}
                    />
                  </div>
                  {useSsl && (
                    <>
                      <PublicCertificateFormField
                        className="mt-6"
                        help={getText(langKey, 'ca.help')}
                        label={getText(langKey, 'ca.label')}
                        onChange={setCa}
                        placeholderFormat="pem"
                        readOnly={formIsReadOnly}
                        value={ca}
                        surface={surface}
                      />
                      <div className="mt-6 flex items-center justify-between">
                        <div>
                          <label
                            className={classNames(
                              'mb-2 block text-sm font-medium leading-5',
                              {
                                'text-gray-600': inOnboarding,
                                'text-white': !inOnboarding,
                              },
                            )}
                          >
                            {getText(langKey, 'selfSigned.label')}
                          </label>
                          <div
                            className={classNames('mb-2 text-sm', {
                              'text-gray-600': inOnboarding,
                              'text-gray-200': !inOnboarding,
                            })}
                          >
                            {getText(langKey, 'selfSigned.help')}
                          </div>
                        </div>
                        <Switch
                          onChange={(value: any) => setSelfSigned(value)}
                          value={selfSigned}
                        />
                      </div>
                      {selfSigned && (
                        <>
                          <PublicCertificateFormField
                            className="mt-6"
                            help={getText(langKey, 'clientCert.help')}
                            label={getText(langKey, 'clientCert.label')}
                            onChange={setClientCert}
                            placeholderFormat="pem"
                            readOnly={formIsReadOnly}
                            value={clientCert}
                            surface={surface}
                          />
                          <PrivateKeyFormField
                            className="mt-6"
                            help={getText(langKey, 'clientKey.help')}
                            label={getText(langKey, 'clientKey.label')}
                            onChange={setClientKey}
                            readOnly={formIsReadOnly}
                            value={clientKey}
                            surface={surface}
                          />
                        </>
                      )}
                    </>
                  )}
                  <hr />
                  <div className="flex items-center justify-between">
                    <div>
                      <label
                        className={classNames(
                          'mb-2 block text-sm font-medium leading-5',
                          {
                            'text-gray-600': inOnboarding,
                            'text-white': !inOnboarding,
                          },
                        )}
                      >
                        {getText('settings.network.staticIpAddresses.label')}
                      </label>
                      <div
                        className={classNames('mb-2 text-sm', {
                          'text-gray-600': inOnboarding,
                          'text-gray-200': !inOnboarding,
                        })}
                      >
                        {getText('settings.network.staticIpAddresses.help')}
                      </div>
                      <ul
                        className={classNames('ml-8 list-disc', {
                          'text-gray-600': inOnboarding,
                          'text-white': !inOnboarding,
                        })}
                      >
                        {NOLOCO_STATIC_IP_ADDRESSES.map((ipAddress) => (
                          <li className="text-sm" key={ipAddress}>
                            {ipAddress}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </>
          </>
        )}
        <Button
          className="mt-6 flex items-center disabled:opacity-75"
          disabled={!isStepValid}
          onClick={onClickNext}
        >
          {isUpdate && isUpdating ? (
            <>
              <span>{getText(langKey, 'updating')}</span>
              <Loader size="sm" className="ml-2" />
            </>
          ) : (
            <>
              <span>{getText(langKey, 'next')}</span>
              <IconArrowNarrowRight size={16} className="ml-2 opacity-75" />
            </>
          )}
        </Button>
      </div>
      <div
        className={classNames('max-h-screen w-full overflow-y-auto', {
          'bg-slate-800': !inOnboarding,
        })}
        ref={inOnboarding ? null : bottomRef}
      >
        <div
          className={classNames('flex w-full items-center justify-center', {
            'max-h-screen-75 overflow-y-auto': inOnboarding,
          })}
          ref={inOnboarding ? bottomRef : null}
        >
          {((isConnect && step >= 4) || error) && (
            <DataSourceImportStatus
              createdDataSource={createdDataSource}
              builtPages={builtPages}
              error={error}
              formSteps={FORM_STEPS}
              hasSyncedData={hasSyncedData}
              inProgressPages={inProgressPages}
              isConnecting={isConnecting}
              onDataTypesSelected={onDataTypesSelected}
              onFinish={inOnboarding ? onConnect : onFinish}
              project={project}
              skippedPages={skippedPages}
              sourceType={sqlType}
              step={step}
              inOnboarding={inOnboarding}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddSqlDatabase;
