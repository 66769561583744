import React from 'react';
import classNames from 'classnames';
import { Loader, Modal } from '@noloco/components';
import { XS } from '@noloco/components/src/constants/tShirtSizes';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import { getText } from '../utils/lang';

type Props = {
  loading: boolean;
  onSubscribe: () => Promise<any>;
  open: boolean;
  setOpen: (newOpen: boolean) => void;
};

const LANG_KEY = 'settings.pwa.notifications.consent.modal';

const NotificationConsentModal = ({
  loading,
  onSubscribe,
  open,
  setOpen,
}: Props) => {
  const { sm: isSmScreen } = useBreakpoints();

  return (
    <Modal
      className={classNames({ 'bg-white dark:bg-gray-800': isSmScreen })}
      confirmText={
        loading ? <Loader size={XS} /> : getText(LANG_KEY, 'confirm')
      }
      loading={loading}
      onCancel={() => setOpen(false)}
      onConfirm={() => onSubscribe().then(() => setOpen(false))}
      open={open || loading}
      title={getText(LANG_KEY, 'title')}
    >
      {getText(LANG_KEY, 'text')}
    </Modal>
  );
};

NotificationConsentModal.displayName = 'NotificationConsentModal';

export default NotificationConsentModal;
