import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-spacehey', 'IconBrandSpacehey', [
  ['path', { d: 'M17 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M14 20h6v-6a3 3 0 0 0 -6 0v6z', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M11 8v2.5a3.5 3.5 0 0 1 -3.5 3.5h-.5a3 3 0 0 1 0 -6h4z',
      key: 'svg-2',
    },
  ],
]);
