import createReactComponent from '../createReactComponent';
export default createReactComponent('stethoscope', 'IconStethoscope', [
  [
    'path',
    {
      d: 'M6 4h-1a2 2 0 0 0 -2 2v3.5h0a5.5 5.5 0 0 0 11 0v-3.5a2 2 0 0 0 -2 -2h-1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 15a6 6 0 1 0 12 0v-3', key: 'svg-1' }],
  ['path', { d: 'M11 3v2', key: 'svg-2' }],
  ['path', { d: 'M6 3v2', key: 'svg-3' }],
  ['path', { d: 'M20 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
]);
