import React, { forwardRef } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';

const NolocoIcon = forwardRef<any, { className: string }>(
  ({ className, ...rest }, ref) => (
    <Box className={classNames(className)} ref={ref} {...rest}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 215 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M84.8772 27.5237C88.9604 23.5494 88.9766 16.9653 84.5494 13.3782C82.3849 11.6245 80.2093 9.99309 78.2277 8.672C57.2277 -5.328 25.2276 -3.528 8.12765 22.572C-5.17235 42.872 -1.97233 69.072 16.4277 86.372C32.6277 101.572 60.0276 103.772 79.0276 89.972C87.7276 83.672 104.428 65.8722 112.228 58.4722V58.4722C114.072 56.7365 116.087 60.4602 116.763 62.9009C122.59 83.9501 142.094 99.372 164.828 99.372C192.028 99.372 214.628 77.172 214.728 50.372C214.828 22.672 192.728 0.272001 165.028 0.172001C153.837 0.0729665 143.332 3.79898 134.874 10.0873C134.71 10.2097 134.532 10.3177 134.387 10.4627V10.4627C134.229 10.6201 134.039 10.7406 133.864 10.8785C132.152 12.2283 130.45 13.7501 128.928 15.272C123.128 20.972 115.428 28.972 108.728 35.272C96.0277 47.172 85.8277 58.272 71.9277 69.672C59.4277 81.472 40.5276 80.372 29.5276 68.272C15.7276 52.972 22.0277 29.072 41.7277 23.172C53.6277 19.572 64.7277 22.972 74.2277 31.672V31.672C75.9475 33.3918 78.7778 33.4606 80.5207 31.7641L84.8772 27.5237ZM164.328 77.872C148.028 77.472 135.628 64.772 135.928 48.572C136.228 33.572 149.728 21.072 165.428 21.372C181.528 21.672 194.228 34.672 193.828 50.672C193.528 65.672 180.028 78.172 164.328 77.872Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  ),
);

export default NolocoIcon;
