import { ACTIVE } from '../constants/accountStatus';
import {
  ALL,
  LOGGED_IN,
  NOT_LOGGED_IN,
} from '../constants/authVisibilityRules';
import { CLIENT, INTERNAL } from '../constants/internalUsersVisibilityRules';
import {
  WITHOUT_PLANS,
  WITH_PLANS,
} from '../constants/membershipVisibilityRules';
import { WITHOUT_ROLES, WITH_ROLES } from '../constants/roleVisibilityRules';
import { Condition } from '../models/Element';
import { conditionIsTrue } from './data';
import { isConnected } from './payments';
import { isInternal } from './user';

export const shouldCheckVisibilityRules = (visibilityRules: any) => {
  const { auth, roleRule, type, customRules } = visibilityRules;

  return !(
    (!auth || auth === ALL) &&
    (!roleRule || roleRule === ALL) &&
    (!type || type === ALL) &&
    (!customRules || customRules.length === 0)
  );
};
export const shouldRenderComponent = (
  currentUser: any,
  {
    auth,
    membership,
    roleRule,
    type,
    plans = [],
    roles = [],
    customRules = [],
  }: any,
  project: any,
  scope: any,
  customRulesEnabled: any,
) => {
  if (!currentUser || currentUser.loading) {
    return false;
  }

  if (auth && auth !== ALL) {
    if (auth === NOT_LOGGED_IN && currentUser.id) {
      return false;
    }

    if (auth === LOGGED_IN && !currentUser.id) {
      return false;
    }
  }

  if (type && type !== ALL) {
    if (type === INTERNAL && !isInternal(currentUser)) {
      return false;
    }

    if (type === CLIENT && isInternal(currentUser)) {
      return false;
    }
  }

  if (roleRule && roleRule !== ALL) {
    if (!currentUser.id) {
      return false;
    }

    const userRoleId = currentUser.role && currentUser.role.referenceId;

    if (roles.length > 0) {
      const hasSpecifiedRole = roles.includes(userRoleId);

      if (roleRule === WITH_ROLES) {
        if (!userRoleId || !hasSpecifiedRole) {
          return false;
        }
      }

      if (roleRule === WITHOUT_ROLES) {
        if (userRoleId && hasSpecifiedRole) {
          return false;
        }
      }
    }
  }

  if (membership && membership !== ALL) {
    if (!currentUser.id) {
      return false;
    }

    const { stripe } = project.integrations;

    const isMembershipConnected = isConnected(stripe);
    if (!isMembershipConnected) {
      return true;
    }

    const subscription = currentUser.membership;
    const planId = subscription && subscription.plan.id;

    if (membership === WITH_PLANS) {
      if (!subscription) {
        return false;
      }

      const hasActivePlan =
        plans.includes(planId) &&
        (subscription.plan.status === ACTIVE ||
          !subscription.plan.interval ||
          subscription.plan.interval === 'one-time');

      if (!hasActivePlan) {
        return false;
      }
    }

    if (membership === WITHOUT_PLANS) {
      if (subscription && plans.includes(planId)) {
        return false;
      }
    }
  }

  if (customRulesEnabled && customRules.length > 0) {
    const ruleConditionsAreMet = customRules.some(
      (andConditions: Condition[]) =>
        andConditions.every(
          (condition: Condition) =>
            conditionIsTrue(condition, scope, project).result,
        ),
    );

    if (!ruleConditionsAreMet) {
      return false;
    }
  }

  // Should always fallback to true
  return true;
};
export const isElementVisible = (
  element: any,
  project: any,
  user: any,
  scope: any,
  editorMode = false,
  customRulesEnabled: any,
) =>
  editorMode ||
  !element.visibilityRules ||
  !shouldCheckVisibilityRules(element.visibilityRules) ||
  shouldRenderComponent(
    user,
    element.visibilityRules,
    project,
    scope,
    customRulesEnabled,
  );
