import { useMemo } from 'react';
import uniqBy from 'lodash/fp/uniqBy';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { USER } from '../../constants/builtInDataTypes';
import useDataTypeQuery from './useDataTypeQuery';

const USER_FIELDS = {
  __args: { first: 30 },
  __variables: { after: 'String' },
  isInternal: true,
  email: true,
  isActive: true,
};

const useFetchUsers = (
  dataTypes: any,
  projectName: any,
  filter = '',
  additionalFields: any = {},
  queryOptions: any = {},
) => {
  const args = useMemo(
    () =>
      additionalFields.__args
        ? { ...USER_FIELDS.__args, ...additionalFields.__args }
        : USER_FIELDS.__args,
    [additionalFields.__args],
  );

  const variables = useMemo(
    () =>
      additionalFields.__variables
        ? { ...USER_FIELDS.__variables, ...additionalFields.__variables }
        : USER_FIELDS.__variables,
    [additionalFields.__variables],
  );

  const where = useMemo(() => {
    if (!filter) {
      return queryOptions.variables?.where;
    }

    const filterWords = filter.trim().split(' ');
    if (filterWords.length === 0) {
      return queryOptions.variables?.where;
    }

    return {
      OR: [
        ...filterWords.map((filterWord) => ({
          email: { contains: filterWord },
        })),
        ...filterWords.map((filterWord) => ({
          firstName: { contains: filterWord },
        })),
        ...filterWords.map((filterWord) => ({
          lastName: { contains: filterWord },
        })),
      ],
    };
  }, [filter, queryOptions.variables?.where]);

  const {
    loading,
    nodes: users,
    pageInfo,
    fetchMore,
  } = useDataTypeQuery(
    USER,
    dataTypes,
    projectName,
    {
      ...USER_FIELDS,
      ...additionalFields,
      __args: {
        ...args,
      },
      __variables: {
        ...variables,
        ...(where ? { where: 'UserWhereInput' } : {}),
      },
    },
    {
      ...queryOptions,
      variables: {
        ...queryOptions.variables,
        ...(where
          ? { where: { ...queryOptions?.variables?.where, ...where } }
          : {}),
      },
    },
  );

  const [loaderRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: pageInfo.hasNextPage,
    onLoadMore: () => {
      fetchMore({
        variables: { after: pageInfo && pageInfo.endCursor },
        // @ts-expect-error TS(2345): Argument of type '{ variables: { after: any; }; di... Remove this comment to see the full error message
        disabled: !pageInfo,
        updateQuery: (previousResults, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResults;
          }
          return {
            userCollection: {
              ...fetchMoreResult.userCollection,
              edges: uniqBy('node.id', [
                ...previousResults.userCollection.edges,
                ...fetchMoreResult.userCollection.edges,
              ]),
            },
            __typename: 'UserConnection',
          };
        },
      });
    },
    disabled: false,
    rootMargin: '100px 0px 0px 0px',
  });

  return {
    loading,
    filter,
    users,
    loaderRef,
    pageInfo,
    fetchMore,
  };
};

export default useFetchUsers;
