import createReactComponent from '../createReactComponent';
export default createReactComponent('salad', 'IconSalad', [
  [
    'path',
    {
      d: 'M4 11h16a1 1 0 0 1 1 1v.5c0 1.5 -2.517 5.573 -4 6.5v1a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-1c-1.687 -1.054 -4 -5 -4 -6.5v-.5a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18.5 11c.351 -1.017 .426 -2.236 .5 -3.714v-1.286h-2.256c-2.83 0 -4.616 .804 -5.64 2.076',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M5.255 11.008a12.204 12.204 0 0 1 -.255 -2.008v-1h1.755c.98 0 1.801 .124 2.479 .35',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M8 8l1 -4l4 2.5', key: 'svg-3' }],
  ['path', { d: 'M13 11v-.5a2.5 2.5 0 1 0 -5 0v.5', key: 'svg-4' }],
]);
