import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-adobe', 'IconBrandAdobe', [
  [
    'path',
    {
      d: 'M12.893 4.514l7.977 14a.993 .993 0 0 1 -.394 1.365a1.04 1.04 0 0 1 -.5 .127h-3.476l-4.5 -8l-2.5 4h1.5l2 4h-8.977c-.565 0 -1.023 -.45 -1.023 -1c0 -.171 .045 -.34 .13 -.49l7.977 -13.993a1.034 1.034 0 0 1 1.786 0z',
      key: 'svg-0',
    },
  ],
]);
