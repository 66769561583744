export type Variant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'warning'
  | 'info'
  | 'custom'
  | 'success';

export const PRIMARY: Variant = 'primary';
export const SUCCESS: Variant = 'success';
export const SECONDARY: Variant = 'secondary';
export const DANGER: Variant = 'danger';
export const WARNING: Variant = 'warning';
export const INFO: Variant = 'info';
export const CUSTOM: Variant = 'custom';

export const variants: Variant[] = [
  PRIMARY,
  SECONDARY,
  DANGER,
  WARNING,
  INFO,
  CUSTOM,
];

export default variants;
