import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '../../utils';

const SubmitButton = ({ children, disabled, theme }: any) => {
  const primaryColor = theme.brandColors.primary;
  return (
    <div className="mt-6">
      <button
        type="submit"
        disabled={disabled}
        className={classNames(
          'group relative flex w-full justify-center rounded-lg border border-transparent px-4 py-1.5 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out focus:outline-none disabled:opacity-75',
          `bg-${getColorShade(primaryColor, 600)}`,
          `hover:bg-${getColorShade(primaryColor, 700)}`,
          `active:bg-${getColorShade(primaryColor, 700)}`,
          `focus:border-${getColorShade(primaryColor, 700)}`,
          `focus:shadow-outline-${getColorShade(primaryColor, 500)}`,
        )}
      >
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            className={classNames(
              'h-4 w-4 transition duration-150 ease-in-out',
              `text-${getColorShade(
                primaryColor,
                400,
              )} group-hover:text-${getColorShade(primaryColor, 400)}`,
            )}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clipRule="evenodd"
            />
          </svg>
        </span>
        {children}
      </button>
    </div>
  );
};

export default withTheme(SubmitButton);
