import createReactComponent from '../createReactComponent';
export default createReactComponent('mountain-off', 'IconMountainOff', [
  [
    'path',
    {
      d: 'M18.281 14.26l-4.201 -8.872a2.3 2.3 0 0 0 -4.158 0l-.165 .349m-1.289 2.719l-5.468 11.544h17',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7.5 11l2 2.5l2 -2', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
