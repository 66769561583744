import createReactComponent from '../createReactComponent';
export default createReactComponent('tags-off', 'IconTagsOff', [
  [
    'path',
    {
      d: 'M6 6h-.975a2.025 2.025 0 0 0 -2.025 2.025v2.834c0 .537 .213 1.052 .593 1.432l6.116 6.116a2.025 2.025 0 0 0 2.864 0l2.834 -2.834c.028 -.028 .055 -.056 .08 -.085',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17.573 18.407l.418 -.418m2 -2l.419 -.419a2.025 2.025 0 0 0 0 -2.864l-7.117 -7.116',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M6 9h-.01', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
