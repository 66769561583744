import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-rounded-chevrons-right',
  'IconSquareRoundedChevronsRight',
  [
    ['path', { d: 'M9 9l3 3l-3 3', key: 'svg-0' }],
    ['path', { d: 'M13 9l3 3l-3 3', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-2',
      },
    ],
  ],
);
