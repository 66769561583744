import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { billingPlanSelector } from '../../selectors/billingPlanSelectors';
import { isTrailingPlanExpired } from '../billing';

const useIsTrialExpired = () => {
  const billingPlan = useSelector(billingPlanSelector);

  const isExpired = useMemo(
    () => isTrailingPlanExpired(billingPlan),
    [billingPlan],
  );

  return isExpired;
};

export default useIsTrialExpired;
