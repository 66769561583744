import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-bear-right-2',
  'IconArrowBearRight2',
  [
    ['path', { d: 'M15 3h5v5', key: 'svg-0' }],
    [
      'path',
      { d: 'M20 3l-7.536 7.536a5 5 0 0 0 -1.464 3.534v6.93', key: 'svg-1' },
    ],
    ['path', { d: 'M4 5l4.5 4.5', key: 'svg-2' }],
  ],
);
