import React, { memo } from 'react';
import { DARK } from '@noloco/components/src/constants/surface';
import { BaseConditionValueEditorProps } from '../../models/BaseConditionValueEditorProps';
import DynamicValueInput from './dynamicValueInput/DynamicValueInput';

interface ConditionValueInputProps extends BaseConditionValueEditorProps {
  checkFieldPermissions?: boolean;
  className?: string;
}

const ConditionValueInput = memo(
  ({
    additionalScopeItems = [],
    acceptableDataTypes,
    checkFieldPermissions = true,
    dataOptions,
    dataType,
    disabled,
    elementPath,
    field,
    fieldFilter,
    includeRelationships,
    includeUniqueColumnar,
    includeSelf,
    multiple,
    onChange,
    placeholder,
    surface = DARK,
    value,
  }: ConditionValueInputProps) => (
    <DynamicValueInput
      additionalScopeItems={additionalScopeItems}
      acceptableDataTypes={acceptableDataTypes}
      checkFieldPermissions={checkFieldPermissions}
      dataOptions={dataOptions}
      dataType={dataType}
      disabled={disabled}
      elementPath={elementPath!}
      field={field}
      fieldFilter={fieldFilter}
      includeSelf={includeSelf}
      includeRelationships={includeRelationships}
      includeUniqueColumnar={includeUniqueColumnar}
      multiple={multiple}
      onChange={onChange}
      placeholder={placeholder}
      surface={surface}
      value={value}
    />
  ),
);

export default ConditionValueInput;
