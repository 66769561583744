import createReactComponent from '../createReactComponent';
export default createReactComponent('photo-pause', 'IconPhotoPause', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13 21h-7a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v7',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 16l5 -5c.928 -.893 2.072 -.893 3 0l3 3', key: 'svg-2' }],
  ['path', { d: 'M14 14l1 -1c.928 -.893 2.072 -.893 3 0', key: 'svg-3' }],
  ['path', { d: 'M17 17v5', key: 'svg-4' }],
  ['path', { d: 'M21 17v5', key: 'svg-5' }],
]);
