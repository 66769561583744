import createReactComponent from '../createReactComponent';
export default createReactComponent('adjustments-alt', 'IconAdjustmentsAlt', [
  ['path', { d: 'M4 8h4v4h-4z', key: 'svg-0' }],
  ['path', { d: 'M6 4l0 4', key: 'svg-1' }],
  ['path', { d: 'M6 12l0 8', key: 'svg-2' }],
  ['path', { d: 'M10 14h4v4h-4z', key: 'svg-3' }],
  ['path', { d: 'M12 4l0 10', key: 'svg-4' }],
  ['path', { d: 'M12 18l0 2', key: 'svg-5' }],
  ['path', { d: 'M16 5h4v4h-4z', key: 'svg-6' }],
  ['path', { d: 'M18 4l0 1', key: 'svg-7' }],
  ['path', { d: 'M18 9l0 11', key: 'svg-8' }],
]);
