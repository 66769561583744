import createReactComponent from '../createReactComponent';
export default createReactComponent('http-head', 'IconHttpHead', [
  ['path', { d: 'M3 16v-8', key: 'svg-0' }],
  ['path', { d: 'M7 8v8', key: 'svg-1' }],
  ['path', { d: 'M3 12h4', key: 'svg-2' }],
  ['path', { d: 'M14 8h-4v8h4', key: 'svg-3' }],
  ['path', { d: 'M10 12h2.5', key: 'svg-4' }],
  ['path', { d: 'M17 16v-6a2 2 0 1 1 4 0v6', key: 'svg-5' }],
  ['path', { d: 'M17 13h4', key: 'svg-6' }],
]);
