import React from 'react';
import PopoutMenu, { PopoutMenuProps } from './PopoutMenu';

const RightPopoutMenu = ({ children, ...rest }: PopoutMenuProps) => (
  <PopoutMenu side="right" {...rest}>
    {children}
  </PopoutMenu>
);

export default RightPopoutMenu;
