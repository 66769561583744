import createReactComponent from '../createReactComponent';
export default createReactComponent('kayak', 'IconKayak', [
  [
    'path',
    {
      d: 'M6.414 6.414a2 2 0 0 0 0 -2.828l-1.414 -1.414l-2.828 2.828l1.414 1.414a2 2 0 0 0 2.828 0z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17.586 17.586a2 2 0 0 0 0 2.828l1.414 1.414l2.828 -2.828l-1.414 -1.414a2 2 0 0 0 -2.828 0z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M6.5 6.5l11 11', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M22 2.5c-9.983 2.601 -17.627 7.952 -20 19.5c9.983 -2.601 17.627 -7.952 20 -19.5z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M6.5 12.5l5 5', key: 'svg-4' }],
  ['path', { d: 'M12.5 6.5l5 5', key: 'svg-5' }],
]);
