import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-tumblr', 'IconBrandTumblr', [
  [
    'path',
    {
      d: 'M14 21h4v-4h-4v-6h4v-4h-4v-4h-4v1a3 3 0 0 1 -3 3h-1v4h4v6a4 4 0 0 0 4 4',
      key: 'svg-0',
    },
  ],
]);
