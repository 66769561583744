import createReactComponent from '../createReactComponent';
export default createReactComponent('bed-off', 'IconBedOff', [
  ['path', { d: 'M3 7v11', key: 'svg-0' }],
  ['path', { d: 'M3 14h11', key: 'svg-1' }],
  ['path', { d: 'M18 14h3', key: 'svg-2' }],
  ['path', { d: 'M21 18v-8a2 2 0 0 0 -2 -2h-7', key: 'svg-3' }],
  ['path', { d: 'M11 11v3', key: 'svg-4' }],
  ['path', { d: 'M7 10m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
