export const FULL_BACKGROUND: VerticalNavType = 'full-background';
export const FULL_BACKGROUND_BORDER: VerticalNavType = 'full-background-border';
export const ROUNDED: VerticalNavType = 'rounded';
export const ROUNDED_FULL: VerticalNavType = 'rounded-full';
export const BOLD: VerticalNavType = 'bold';
export const BORDERED: VerticalNavType = 'bordered';

const verticalNavTypes: VerticalNavType[] = [
  FULL_BACKGROUND,
  FULL_BACKGROUND_BORDER,
  ROUNDED,
  ROUNDED_FULL,
  BOLD,
  BORDERED,
];

export type VerticalNavType =
  | 'full-background'
  | 'full-background-border'
  | 'rounded'
  | 'rounded-full'
  | 'bold'
  | 'bordered';

export default verticalNavTypes;
