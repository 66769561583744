import createReactComponent from '../createReactComponent';
export default createReactComponent('meteor-off', 'IconMeteorOff', [
  [
    'path',
    {
      d: 'M9.75 5.761l3.25 -2.761l-1 5l9 -5l-5 9h5l-2.467 2.536m-1.983 2.04l-2.441 2.51a6.5 6.5 0 1 1 -8.855 -9.506l2.322 -1.972',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9.5 14.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
