import { createSelector } from '@reduxjs/toolkit';
import { AUTH_WRAPPER_ID } from '../constants/auth';

const EMPTY_USER = {};

export type DataSelectorState = {
  data: Record<any, any>;
};
const dataSelector = (state: DataSelectorState) => state.data;

export const ghostUserIdSelector: (state: DataSelectorState) => number =
  createSelector([dataSelector], (data) => data && data.ghostUserId);

export const scopeSelector: (state: DataSelectorState) => any | undefined =
  createSelector([dataSelector], (data) => data && data.scope);

export const elementScopeSelector = (
  elementId: string,
): ((state: DataSelectorState) => any | undefined) =>
  createSelector([scopeSelector], (scope) => scope[elementId]);

const isEditingSelector = createSelector(
  [dataSelector],
  (data) => data.isEditing,
);

export const isEditingDataSelector = (
  elementId: string,
): ((state: DataSelectorState) => boolean | undefined) =>
  createSelector(
    [isEditingSelector],
    (isEditing) => (isEditing && isEditing[elementId]) || false,
  );

export const userScopeSelector: (state: DataSelectorState) => any | undefined =
  createSelector(
    [scopeSelector],
    (scope) => scope[AUTH_WRAPPER_ID] || EMPTY_USER,
  );
