import createReactComponent from '../createReactComponent';
export default createReactComponent('calendar-search', 'IconCalendarSearch', [
  [
    'path',
    {
      d: 'M11.5 21h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3v4', key: 'svg-1' }],
  ['path', { d: 'M8 3v4', key: 'svg-2' }],
  ['path', { d: 'M4 11h16', key: 'svg-3' }],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-4' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-5' }],
]);
