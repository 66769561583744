import useSubmitModalForm from './useSubmitNestedForm';

const useActionsWithFormInputs = (action: any) => {
  const { submitModalForm } = useSubmitModalForm(`.action-${action.id}-form`);

  return {
    submitForm: submitModalForm,
  };
};

export default useActionsWithFormInputs;
