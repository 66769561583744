import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'gender-genderless',
  'IconGenderGenderless',
  [
    ['path', { d: 'M12 10a5 5 0 1 1 0 10a5 5 0 0 1 0 -10z', key: 'svg-0' }],
    ['path', { d: 'M12 10v-7', key: 'svg-1' }],
    ['path', { d: 'M7 15h10', key: 'svg-2' }],
  ],
);
