import createReactComponent from '../createReactComponent';
export default createReactComponent('cheese', 'IconCheese', [
  [
    'path',
    {
      d: 'M4.519 20.008l16.481 -.008v-3.5a2 2 0 1 1 0 -4v-3.5h-16.722',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M21 9l-9.385 -4.992c-2.512 .12 -4.758 1.42 -6.327 3.425c-1.423 1.82 -2.288 4.221 -2.288 6.854c0 2.117 .56 4.085 1.519 5.721',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M15 13v.01', key: 'svg-2' }],
  ['path', { d: 'M8 13v.01', key: 'svg-3' }],
  ['path', { d: 'M11 16v.01', key: 'svg-4' }],
]);
