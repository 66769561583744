import React from 'react';
import LoginSubmit from './LoginSubmit';
import SimpleLayout from './SimpleLayout';

const SimpleLogin = ({
  children,
  errors,
  logoUrl,
  emailLabel,
  onSubmit,
  buttonText,
  forgotText,
  passwordLabel,
  registerText,
  rememberLabel,
  titleText,
  email,
  password,
  rememberMe,
  setEmail,
  setPassword,
  setRememberMe,
}: any) => {
  return (
    <SimpleLayout
      errors={errors}
      logoUrl={logoUrl}
      onSubmit={onSubmit}
      subTitleText={registerText}
      titleText={titleText}
    >
      <>
        <input type="hidden" name="remember" value="true" />
        <div>
          <input
            aria-label="Email address"
            autoComplete="email"
            name="email"
            type="email"
            onChange={({ target: { value } }) => setEmail(value)}
            required
            className="focus:shadow-outline-blue relative block w-full appearance-none rounded-none rounded-t border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
            placeholder={emailLabel}
            value={email}
          />
        </div>
        <div className="-mt-px">
          <input
            aria-label="Password"
            autoComplete="current-password"
            name="password"
            type="password"
            onChange={({ target: { value } }) => setPassword(value)}
            required
            className="focus:shadow-outline-blue relative block w-full appearance-none rounded-none rounded-b border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
            placeholder={passwordLabel}
            value={password}
          />
        </div>
        <LoginSubmit
          buttonText={buttonText}
          forgotText={forgotText}
          rememberLabel={rememberLabel}
          rememberMe={rememberMe}
          setRememberMe={setRememberMe}
        />
        {children}
      </>
    </SimpleLayout>
  );
};

export default SimpleLogin;
