import React from 'react';
import VisibilityRulesEditor from '@noloco/ui/src/components/editor/VisibilityRulesEditor';
import { DataType } from '../../../models/DataTypes';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { Page } from '../../../utils/pages';

type BuildModeRecordVisibilityTabProps = {
  dataType: DataType;
  element: Page;
  elementPath: ElementPath;
  project: Project;
};

const BuildModeRecordVisibilityTab = ({
  dataType,
  element,
  elementPath,
  project,
}: BuildModeRecordVisibilityTabProps) => (
  <VisibilityRulesEditor
    dataType={dataType}
    element={element}
    project={project}
    elementPath={elementPath}
  />
);

export default BuildModeRecordVisibilityTab;
