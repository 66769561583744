import React, { useMemo } from 'react';
import {
  IconCreditCard,
  IconDots,
  IconFileDownload,
  IconRefresh,
  IconUser,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Badge, Loader, Popover } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { IS_PRODUCTION } from '@noloco/ui/src/constants/env';
import { DATE_SHORT } from '../../../constants/dateFormatOptions';
import RelationalDataFieldInput from '../../../elements/sections/collections/filters/RelationalDataFieldInput';
import { currencyToSymbol, formatCurrency } from '../../../utils/currency';
import { getDataTypeByName } from '../../../utils/data';
import useRouter from '../../../utils/hooks/useRouter';
import { getText } from '../../../utils/lang';
import Title from '../Title';
import CollectionTableHead from '../collections/CollectionTableHead';
import RelatedCellItem from '../collections/RelatedCellItem';
import { Td } from './InvoiceTable';

const getStatusVariant = (status: any) =>
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  ({
    active: 'success',
    past_due: 'danger',
    canceled: 'warning',
    incomplete: 'warning',
  })[status] || 'warning';

type SubscriptionTableProps = {};

const SubscriptionTable = ({
  // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Subsc... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'Subscri... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(2339): Property 'isInternal' does not exist on type 'Subs... Remove this comment to see the full error message
  isInternal,
  // @ts-expect-error TS(2339): Property 'subscriptions' does not exist on type 'S... Remove this comment to see the full error message
  subscriptions,
  // @ts-expect-error TS(2339): Property 'emptyState' does not exist on type 'Subs... Remove this comment to see the full error message
  emptyState,
  // @ts-expect-error TS(2339): Property 'customerField' does not exist on type 'S... Remove this comment to see the full error message
  customerField,
  // @ts-expect-error TS(2339): Property 'title' does not exist on type 'Subscript... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'Subscr... Remove this comment to see the full error message
  subtitle,
  // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Subscri... Remove this comment to see the full error message
  project,
}: SubscriptionTableProps) => {
  const {
    pushQueryParams,
    query: { customerId },
  } = useRouter();

  const headers = useMemo(
    () =>
      [
        'description',
        'amount',
        'interval',
        'status',
        'currentPeriodEnd',
        '',
      ].map((heading) => ({
        label: heading ? (
          <span
            className={classNames('w-full', {
              'block text-right': heading === 'amount',
            })}
          >
            {getText('core.BILLING.list', heading)}
          </span>
        ) : (
          ''
        ),
      })),
    [],
  );

  const EmptyState = useMemo(
    () => () => (
      <div className="flex w-full flex-col items-center justify-center p-16 text-center">
        <h2 className="text-base font-medium">{emptyState.title.value}</h2>
        {!emptyState.image.hidden && emptyState.image.value.src && (
          <img
            className="mt-4 w-full max-w-xs rounded-lg"
            src={emptyState.image.value.src}
            alt={emptyState.title.value}
          />
        )}
      </div>
    ),
    [emptyState],
  );

  const subscriptionDataType = getDataTypeByName(
    project.dataTypes,
    'subscription',
  );

  return (
    <div className={classNames(className, 'flex flex-col')}>
      {(title || subtitle) && (
        <Title
          subtitle={{
            hidden: !subtitle,
            value: subtitle,
          }}
          title={{
            hidden: !title,
            value: title,
          }}
          className="mb-3"
        >
          {isInternal && (
            <>
              <RelationalDataFieldInput
                field={subscriptionDataType.fields.getByName('customer')}
                resultFilter={(customer: any) => customer.customerPaymentsId}
                dataTypes={project.dataTypes}
                multiple={false}
                projectName={project.name}
                placeholder={getText('core.BILLING.list.filter')}
                value={customerId}
                onChange={(value: any) =>
                  pushQueryParams({ customerId: value ? value.id : null })
                }
                isPlayground={false}
                surface={LIGHT}
                project={project}
              />
            </>
          )}
        </Title>
      )}
      <div className="w-full max-w-full overflow-x-auto rounded-lg border border-gray-200 bg-white shadow-lg">
        {loading && (
          <div className="flex w-full items-center justify-center p-8">
            <Loader type="Bars" size="sm" />
          </div>
        )}
        {!loading && subscriptions.length === 0 && <EmptyState />}
        {!loading && subscriptions.length > 0 && (
          <table className="min-w-full divide-y divide-gray-200">
            <CollectionTableHead
              additionalElements={headers}
              variables={{
                title: {
                  label: getText('core.BILLING.list.recipient'),
                  hidden: !isInternal,
                },
                secondaryText: {
                  hidden: true,
                },
              }}
            />
            <tbody className="divide-y divide-gray-200 bg-white">
              {subscriptions.map((subscription: any) => (
                <tr
                  key={subscription.id}
                  className="group w-full px-8 hover:bg-gray-100 hover:bg-opacity-25"
                >
                  {isInternal && (
                    <Td isFirst={true}>
                      <RelatedCellItem
                        dataTypes={project.dataTypes}
                        value={subscription.customer}
                        field={customerField}
                      />
                    </Td>
                  )}
                  <Td isFirst={!isInternal}>
                    <div className="flex items-center justify-between">
                      <span className="mr-4 block max-w-lg truncate">
                        {subscription.name}
                      </span>
                    </div>
                  </Td>
                  <Td className="text-right">
                    {currencyToSymbol(subscription.currency)}
                    {formatCurrency(subscription.amount)}
                  </Td>
                  <Td>
                    {subscription.interval &&
                      getText(
                        'core.BILLING.form.interval.options',
                        subscription.interval,
                      )}
                  </Td>
                  <Td>
                    <Badge variant={getStatusVariant(subscription.status)}>
                      {getText(
                        'core.BILLING.list.subscriptionStatus',
                        subscription.status,
                      )}
                    </Badge>
                  </Td>
                  <Td>
                    {subscription.currentPeriodEnd
                      ? DateTime.fromISO(
                          subscription.currentPeriodEnd,
                          // @ts-expect-error TS(2559): Type '"D"' has no properties in common with type '... Remove this comment to see the full error message
                        ).toLocaleString(DATE_SHORT)
                      : ''}
                  </Td>
                  <Td>
                    {(isInternal || subscription.latestInvoice) && (
                      <Popover
                        closeOnOutsideClick={true}
                        placement="bottom-end"
                        className="overflow-hidden bg-white"
                        p={0}
                        rounded="lg"
                        shadow="lg"
                        content={
                          <div className="flex w-80 flex-col text-left">
                            {isInternal && (
                              <>
                                <a
                                  href={`https://dashboard.stripe.com/${
                                    IS_PRODUCTION ? '' : 'test/'
                                  }subscriptions/${subscription.id}`}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  className="flex items-center px-6 py-4 text-left hover:bg-gray-100"
                                >
                                  <IconRefresh
                                    className="opacity-75"
                                    size={14}
                                  />
                                  <span className="ml-2">
                                    {getText(
                                      'core.BILLING.list.actions.open.subscription',
                                    )}
                                  </span>
                                </a>
                                <a
                                  href={`https://dashboard.stripe.com/${
                                    IS_PRODUCTION ? '' : 'test/'
                                  }customers/${subscription.accountCustomerId}`}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  className="flex items-center px-6 py-4 text-left hover:bg-gray-100"
                                >
                                  <IconUser className="opacity-75" size={14} />
                                  <span className="ml-2">
                                    {getText(
                                      'core.BILLING.list.actions.open.customer',
                                    )}
                                  </span>
                                </a>
                              </>
                            )}
                            {subscription.latestInvoice &&
                              subscription.latestInvoice.pdf && (
                                <a
                                  href={subscription.latestInvoice.pdf}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  className="flex items-center px-6 py-4 text-left hover:bg-gray-100"
                                >
                                  <IconFileDownload
                                    className="opacity-75"
                                    size={14}
                                  />
                                  <span className="ml-2">
                                    {getText(
                                      'core.BILLING.list.actions.download',
                                    )}
                                  </span>
                                </a>
                              )}
                            {subscription.latestInvoice &&
                              subscription.latestInvoice.url &&
                              !isInternal && (
                                <a
                                  href={subscription.latestInvoice.url}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  className="flex items-center px-6 py-4 text-left hover:bg-gray-100"
                                >
                                  <IconCreditCard
                                    className="opacity-75"
                                    size={14}
                                  />
                                  <span className="ml-2">
                                    {getText(
                                      'core.BILLING.list.actions',
                                      subscription.latestInvoice.status ===
                                        'paid'
                                        ? 'details'
                                        : 'pay',
                                    )}
                                  </span>
                                </a>
                              )}
                          </div>
                        }
                      >
                        <button className="invisible flex items-center justify-center rounded-full p-2 text-gray-500 hover:bg-gray-100 focus:visible focus:bg-gray-100 group-hover:visible">
                          <IconDots size={16} />
                        </button>
                      </Popover>
                    )}
                  </Td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SubscriptionTable;
