import { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import gql from 'graphql-tag';
import set from 'lodash/fp/set';
import { USER } from '@noloco/core/src/constants/builtInDataTypes';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { getImportQueryString } from '@noloco/core/src/queries/project';
import { DataSchemaField } from '@noloco/core/types/src/constants/dataSchema';

export const useImportCsvFile = (project: Project) => {
  const client = useApolloClient();

  const queryOptions = useMemo(
    () => ({
      fetchPolicy: 'no-cache' as MutationFetchPolicy,
      context: {
        autQuery: true,
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [project.name],
  );

  return useCallback(
    (dataType: DataType, fields: DataSchemaField[], file: any) => {
      if (!dataType) {
        return Promise.resolve(null);
      }

      const query = gql`
        ${getImportQueryString(dataType ?? project.dataTypes.getByName(USER)!)}
      `;

      const importFields = fields.reduce((acc, column) => {
        const dataField = dataType.fields.find(
          ({ display }) => display === column.display,
        );

        if (!dataField) {
          return acc;
        }

        if (column.path.length !== 1) {
          return acc;
        }

        const [key] = column.path;

        return set(dataField.apiName, key, acc);
      }, {});

      client.query({
        query,
        ...queryOptions,
        variables: {
          fields: importFields,
          file,
        },
      });
    },
    [client, project.dataTypes, queryOptions],
  );
};
