import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-patreon', 'IconBrandPatreon', [
  ['path', { d: 'M3 3h3v18h-3z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15 9.5m-6.5 0a6.5 6.5 0 1 0 13 0a6.5 6.5 0 1 0 -13 0',
      key: 'svg-1',
    },
  ],
]);
