import createReactComponent from '../createReactComponent';
export default createReactComponent('versions', 'IconVersions', [
  [
    'path',
    {
      d: 'M10 5m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 7l0 10', key: 'svg-1' }],
  ['path', { d: 'M4 8l0 8', key: 'svg-2' }],
]);
