import { useCallback, useMemo } from 'react';
import {
  IconBolt,
  IconBrandYoutube,
  IconDatabase,
  IconFile,
  IconHammer,
  IconHelp,
  IconLifebuoy,
  IconMessage,
  IconMessage2Question,
  IconSchool,
  IconSettings,
  IconUserCircle,
  IconUsers,
  IconVideo,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Loader, Popover, Tooltip } from '@noloco/components';
import NolocoIcon from '@noloco/core/src/components/NolocoIcon';
import { LIVE_CHAT } from '@noloco/core/src/constants/features';
import {
  ACADEMY_URL,
  COMMUNITY_URL,
  EXPERT_URL,
  GUIDES_URL,
  WEBINARS_URL,
  YOUTUBE_CHANNEL_URL,
} from '@noloco/core/src/constants/urls';
import {
  dataTypesSelector,
  hasUnpublishedChangesSelector,
  projectCreatorSelector,
  projectDomainsSelector,
  projectNameSelector,
  publishedVersionSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import { LOGO_CLICKED, trackEvent } from '@noloco/core/src/utils/analytics';
import { getValidPlanLangKey } from '@noloco/core/src/utils/features';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import useIsTrialExpired from '@noloco/core/src/utils/hooks/useIsTrialExpired';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import useScript from '@noloco/core/src/utils/hooks/useScript';
import { useSegmentAdminDestinationsMiddleware } from '@noloco/core/src/utils/hooks/useSegmentSourceMiddlewares';
import { getText } from '@noloco/core/src/utils/lang';
import { APP_DASHBOARD_URL } from '../constants/env';
import { useSubscribeToProjectUpdates } from '../utils/hooks/projectHooks';
import useAppPageUrl from '../utils/hooks/useAppPageUrl';
import useBuilderOnlyScripts from '../utils/hooks/useBuilderOnlyScripts';
import useFetchProjectForSettings from '../utils/hooks/useFetchProjectForSettings';
import useIntercom from '../utils/hooks/useIntercom';
import useIsFeatureEnabled from '../utils/hooks/useIsFeatureEnabled';
import useValidFeaturePlan from '../utils/hooks/useValidFeaturePlan';
import AirtableOauthAlert from './AirtableOauthAlert';
import DataTabWarningDot from './DataTabWarningDot';
import FullstoryRecording from './FullstoryRecording';
import OnboardingChecklistSummary from './OnboardingChecklistSummary';
import PublishButton from './PublishButton';
import SmartSuiteOAuthAlert from './SmartSuiteOAuthAlert';
import TrialProgressTooltip from './TrialProgressTooltip';
import OnboardingChecklistRoute from './onboarding/OnboardingChecklistRoute';

export const LeftTabs = {
  ELEMENTS: 'elements',
  DATA: 'data',
  WORKFLOWS: 'workflows',
  USERS: 'users',
  SETTINGS: 'settings',
};

export const leftTabIcons = {
  [LeftTabs.ELEMENTS]: IconFile,
  [LeftTabs.DATA]: IconDatabase,
  [LeftTabs.WORKFLOWS]: IconBolt,
  [LeftTabs.USERS]: IconUsers,
  [LeftTabs.SETTINGS]: IconSettings,
};

export const TRIAL_EXPIRED_NAV_ITEMS = [
  LeftTabs.ELEMENTS,
  LeftTabs.USERS,
  LeftTabs.SETTINGS,
];

const INSTATUS_EMBED_CODE = 'https://status.noloco.io/widget/script.js';

const AdminNavbar = ({ settings, showBuilderExp, showDataTab }: any) => {
  const {
    pathname,
    query: { __onboarding },
  } = useRouter();

  const dataTypes = useSelector(dataTypesSelector);
  const projectName = useSelector(projectNameSelector);
  const currentPublishedProjectVersion = useSelector(publishedVersionSelector);
  const domains = useSelector(projectDomainsSelector);

  const hasUnpublishedChanges = useSelector(hasUnpublishedChangesSelector);
  const publishedVersion = useSelector(publishedVersionSelector);
  const creator = useSelector(projectCreatorSelector);

  const { user } = useAuth();
  const { isBuilder, isNolocoAdmin } = useIsBuilder();

  const isTrialExpired = useIsTrialExpired();
  const hideRoutesWhenTrialExpired = useMemo(
    () => isTrialExpired && !isNolocoAdmin,
    [isNolocoAdmin, isTrialExpired],
  );

  const { loading } = useFetchProjectForSettings(!isBuilder);

  useSubscribeToProjectUpdates();
  const { showIntercom } = useIntercom(settings);
  useBuilderOnlyScripts();
  useScript(INSTATUS_EMBED_CODE);
  useSegmentAdminDestinationsMiddleware(!!isBuilder && !!user?._nolocoUserId);

  const appPageUrl = useAppPageUrl();
  const userHasChatAccess = useIsFeatureEnabled(LIVE_CHAT);
  const planForChatAccess = useValidFeaturePlan(LIVE_CHAT);

  const showIntercomChat = useCallback(() => {
    showIntercom();
  }, [showIntercom]);

  return (
    <div className="flex h-screen w-16 flex-col border-r border-slate-700 bg-slate-900 text-white sm:hidden">
      <a
        href={APP_DASHBOARD_URL}
        className="block h-16 w-16 px-3 py-4"
        onClick={() => {
          trackEvent(LOGO_CLICKED);
        }}
      >
        <NolocoIcon className="h-full bg-slate-900 text-white hover:text-teal-500" />
      </a>
      {[
        LeftTabs.ELEMENTS,
        ...(showDataTab ? [LeftTabs.DATA, LeftTabs.USERS] : []),
        ...(showBuilderExp ? [LeftTabs.WORKFLOWS, LeftTabs.SETTINGS] : []),
      ].map((tab) => {
        if (
          hideRoutesWhenTrialExpired &&
          !TRIAL_EXPIRED_NAV_ITEMS.includes(tab)
        ) {
          return null;
        }

        const Icon = leftTabIcons[tab];
        const isActive =
          tab === LeftTabs.ELEMENTS
            ? !pathname.startsWith(`/_/`)
            : pathname.startsWith(`/_/${tab}`);
        return (
          <Tooltip
            content={getText('leftSidebar.nav', tab)}
            placement="right"
            key={tab}
            bg="white"
          >
            <Link
              key={tab}
              className={classNames(
                'relative flex h-10 cursor-pointer items-center justify-center px-3 py-2',
                {
                  'bg-slate-900 text-white': isActive,
                  'bg-slate-900 text-slate-500 hover:text-slate-200': !isActive,
                },
              )}
              to={tab === LeftTabs.ELEMENTS ? appPageUrl : `/_/${tab}`}
              data-tour={`${tab}-link`}
              data-testid={`admin-nav-${tab.toLowerCase()}`}
            >
              <Icon size={20} className="mx-auto" />
              {tab === LeftTabs.DATA && (
                <DataTabWarningDot dataTypes={dataTypes} />
              )}
            </Link>
          </Tooltip>
        );
      })}
      {showBuilderExp && !hideRoutesWhenTrialExpired && (
        <PublishButton
          domains={domains}
          hasUnpublishedChanges={hasUnpublishedChanges}
          projectName={projectName}
          currentPublishedProjectVersion={currentPublishedProjectVersion}
          publishedVersion={publishedVersion}
        />
      )}
      <Popover
        delayHide={300}
        content={
          <div className="flex w-80 flex-col bg-white p-2 text-left text-sm">
            <OnboardingChecklistSummary />
            <button
              disabled={!userHasChatAccess}
              className={classNames(
                'flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100',
                {
                  'cursor-not-allowed': !userHasChatAccess,
                },
              )}
              onClick={() => {
                if (userHasChatAccess) {
                  showIntercomChat();
                }
              }}
            >
              <div
                className={classNames('flex items-center', {
                  'opacity-75': !userHasChatAccess,
                })}
              >
                <IconMessage size={16} />
                <span className="ml-2">{getText('toolbar.help.liveChat')}</span>
              </div>
              {!userHasChatAccess && (
                <Badge color="pink-500" m={{ l: 'auto', r: 0 }}>
                  {getText(
                    'billing.plans',
                    getValidPlanLangKey(planForChatAccess),
                    'name',
                  )}
                </Badge>
              )}
            </button>
            <a
              href={COMMUNITY_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconMessage2Question size={16} />
              <span className="ml-2">{getText('toolbar.help.community')}</span>
            </a>
            <a
              href={GUIDES_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconLifebuoy size={16} />
              <span className="ml-2">{getText('toolbar.help.support')}</span>
            </a>
            <a
              href={ACADEMY_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-gray-100"
            >
              <IconSchool size={16} />
              <span className="ml-2">{getText('toolbar.help.academy')}</span>
            </a>
            <a
              href={YOUTUBE_CHANNEL_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconBrandYoutube size={16} />
              <span className="ml-2">{getText('toolbar.help.youtube')}</span>
            </a>
            <a
              href={WEBINARS_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconVideo size={16} />
              <span className="ml-2">{getText('toolbar.help.webinars')}</span>
            </a>
            <a
              href={`${APP_DASHBOARD_URL}/billing`}
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconUserCircle size={16} />
              <span className="ml-2">{getText('toolbar.help.billing')}</span>
            </a>
            <a
              href={EXPERT_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconHammer size={16} />
              <span className="ml-2">{getText('toolbar.help.expert')}</span>
            </a>
          </div>
        }
        className="overflow-hidden bg-white"
        p={0}
        rounded="lg"
        trigger="hover"
        placement="right"
        shadow="lg"
        bg="white"
      >
        <div className="flex items-center" data-tour="help-wrapper">
          <button
            className="flex h-14 w-16 cursor-pointer items-center justify-center px-3 py-2 text-slate-500 hover:text-slate-200 hover:opacity-75"
            data-tour="help"
          >
            <IconHelp size={20} />
          </button>
        </div>
      </Popover>
      {loading && (
        <div className="my-2 flex items-center justify-center">
          <Loader size="sm" />
        </div>
      )}
      {!__onboarding && !hideRoutesWhenTrialExpired && (
        <div className="mx-4 my-2 h-0.5 rounded-lg bg-slate-600" />
      )}
      <OnboardingChecklistRoute />
      <div className="mb-4 mt-auto flex flex-col space-y-1">
        {/* @ts-expect-error TS(2322): Type '{ startDate: any; }' is not assignable to ty... Remove this comment to see the full error message */}
        <TrialProgressTooltip startDate={get(creator, 'createdAt')} />
      </div>
      <FullstoryRecording projectName={projectName} />
      {isBuilder && <AirtableOauthAlert />}
      {isBuilder && <SmartSuiteOAuthAlert />}
    </div>
  );
};

export default AdminNavbar;
