import { useSelector } from 'react-redux';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { isTrialActive } from '@noloco/core/src/utils/billing';

const useIsTrialing = () => {
  const billingPlan = useSelector(billingPlanSelector);
  const isTrialing = isTrialActive(billingPlan);

  return isTrialing;
};

export default useIsTrialing;
