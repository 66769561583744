import React, { useCallback, useMemo, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { IconSearch } from '@tabler/icons-react';
import classNames from 'classnames';
import last from 'lodash/last';
import SimpleBar from 'simplebar-react';
import { TextInput } from '@noloco/components';
import { TextInputProps } from '@noloco/components/src/components/input/TextInput';
import { filterOptions } from '@noloco/components/src/components/select/OptionList';
import { StringPropSegment } from '@noloco/core/src/models/Element';
import StateItem from '@noloco/core/src/models/StateItem';
import { isTimeDirection, isTimeFrame } from '@noloco/core/src/utils/baseScope';
import { getText } from '@noloco/core/src/utils/lang';
import { DataItemOption } from '@noloco/core/src/utils/state';
import { CustomDateFilter, CustomTimeFilter } from './CustomDateFilter';
import DynamicSwitch from './DynamicSwitch';
import DynamicValueOption from './DynamicValueOption';
import DynamicValueSelectedOption from './DynamicValueSelectedOption';

type DynamicValuePopoverBodyProps = {
  options: DataItemOption[];
  onSelect: (value: StateItem) => void;
  setDynamic?: (dynamic: boolean) => void;
  value?: StringPropSegment[] | null;
};
const defaultCustomTimeFilter: CustomTimeFilter = {
  frame: 'week',
  count: '1',
  direction: 'ago',
};
const SearchInput = ({ val, ...props }: { val: string } & TextInputProps) => (
  <TextInput {...props} value={val} />
);

const LANG_KEY = 'dynamicValueInput';

const DynamicValuePopoverBody = ({
  options,
  onSelect,
  setDynamic,
  value,
}: DynamicValuePopoverBodyProps) => {
  const [search, setSearch] = useState('');

  const sources = useMemo(() => options, [options]);

  const [selectedGroups, setSelectedGroups] = useState<DataItemOption[]>([
    sources[0],
  ]);

  const onChooseGroup = useCallback((groups: DataItemOption[]) => {
    setSelectedGroups(groups);
    setSearch('');
  }, []);

  const groupOptions = useMemo(() => {
    let lastGroupOptions: DataItemOption[] = [];
    const lastGroup = last(selectedGroups);

    if (lastGroup) {
      if (lastGroup.getOptions) {
        lastGroupOptions = lastGroup.getOptions();
      } else if (lastGroup.options) {
        lastGroupOptions = lastGroup.options;
      }
    }

    return lastGroupOptions.filter(filterOptions(search));
  }, [search, selectedGroups]);

  const onExpandOption = useCallback(
    (option: DataItemOption) =>
      setSelectedGroups((currentGroups) => [...currentGroups, option]),
    [],
  );

  const onCollapseOption = useCallback((option: DataItemOption) => {
    setSelectedGroups((currentGroups) => {
      const index = currentGroups.indexOf(option);
      if (index < 0) {
        return currentGroups;
      }

      return currentGroups.slice(0, index);
    });
  }, []);

  const firstValuePath = value?.[0]?.data?.path;
  const customTimeFilterValue: CustomTimeFilter = useMemo(() => {
    if (!firstValuePath?.startsWith('DATE.custom')) {
      return defaultCustomTimeFilter;
    }
    const [, _, frame, direction, count] = firstValuePath.split('.');

    if (
      !isTimeFrame(frame) ||
      !isTimeDirection(direction) ||
      isNaN(Number(count))
    ) {
      return defaultCustomTimeFilter;
    }
    return { frame, direction, count };
  }, [firstValuePath]);

  const onCustomTimeFilterChange = useCallback(
    (value: CustomTimeFilter) => {
      if (!value.count || isNaN(Number(value.count))) {
        return;
      }
      onSelect(
        new StateItem({
          id: 'values',
          path: `DATE.custom.${value.frame}.${value.direction}.${value.count}`,
          source: 'DERIVED',
          dataType: 'DATE',
          display: '',
        }),
      );
    },
    [onSelect],
  );

  const onChooseComboOption = useCallback(
    (option: DataItemOption) => {
      if (option.getOptions || option.options) {
        onExpandOption(option);
      } else if (option.value?.path.startsWith('DATE.custom')) {
        onCustomTimeFilterChange(customTimeFilterValue);
        onExpandOption(option);
      } else if (option.value) {
        onSelect(option.value);
      }
    },
    [onExpandOption, customTimeFilterValue, onCustomTimeFilterChange, onSelect],
  );

  return (
    <div className="flex h-80 max-h-80 max-w-screen-sm overflow-hidden bg-slate-800 text-white">
      <div className="flex w-48 flex-shrink-0 flex-col border-r border-slate-500 bg-slate-700">
        <div className="flex h-12 items-center border-b border-slate-500 p-2">
          <span className="whitespace-nowrap text-xs">
            {getText(LANG_KEY, 'source')}
          </span>
        </div>
        {/* @ts-expect-error TS(2786): 'SimpleBar' cannot be used as a JSX component. */}
        <SimpleBar autoHide={true} className="max-h-full overflow-y-auto">
          <div className="space-y-1 p-2">
            {sources.map((option) => (
              <div
                className={classNames(
                  'flex cursor-pointer flex-col justify-center rounded-lg p-2',
                  {
                    'hover:bg-slate-800': selectedGroups[0] !== option,
                    'bg-slate-900': selectedGroups[0] === option,
                  },
                )}
                onClick={() => onChooseGroup([option])}
              >
                <span className="text-sm font-medium">{option.label}</span>
                {option.help && (
                  <span className="text-xs font-light opacity-75">
                    {option.help}
                  </span>
                )}
              </div>
            ))}
          </div>
        </SimpleBar>
        {setDynamic && (
          <div className="mt-auto flex h-10 border-t border-slate-500 p-2">
            <DynamicSwitch value={true} onChange={setDynamic} />
          </div>
        )}
      </div>
      <Combobox<any, DataItemOption | null>
        value={null}
        onChange={onChooseComboOption}
      >
        <div className="flex w-full flex-col overflow-hidden bg-slate-700">
          <div className="flex h-12 flex-shrink-0 items-center justify-between overflow-hidden border-b border-gray-500 p-2">
            <span className="mr-4 whitespace-nowrap text-xs">
              {getText(LANG_KEY, 'values')}
            </span>
            <Combobox.Input
              as={SearchInput}
              border={[true, 'slate-500']}
              className="w-full"
              icon={<IconSearch size={16} />}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => setSearch(value)}
              placeholder=""
              val={search}
              value={search}
            />
          </div>
          {selectedGroups.length > 1 && (
            <div className="relative flex flex-col space-y-1 p-2 pb-0">
              {selectedGroups.slice(1).map((option) => (
                <DynamicValueSelectedOption
                  option={option}
                  onCollapseOption={onCollapseOption}
                />
              ))}
            </div>
          )}
          {/* @ts-expect-error TS(2786): 'SimpleBar' cannot be used as a JSX component. */}
          <SimpleBar
            autoHide={true}
            className="relative flex max-h-full flex-col overflow-y-auto p-2"
          >
            <Combobox.Options static className="flex flex-col space-y-1">
              {groupOptions.map((option, index) => (
                <DynamicValueOption option={option} key={index} />
              ))}
            </Combobox.Options>
            {last(selectedGroups)?.value?.path?.startsWith('DATE.custom') ? (
              <CustomDateFilter
                onChange={onCustomTimeFilterChange}
                value={customTimeFilterValue}
              />
            ) : groupOptions.length === 0 ? (
              <span className="my-4 flex justify-center text-center text-xs opacity-50">
                {getText(LANG_KEY, 'empty')}
              </span>
            ) : null}
          </SimpleBar>
        </div>
      </Combobox>
    </div>
  );
};

export default DynamicValuePopoverBody;
