import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-snapchat', 'IconBrandSnapchat', [
  [
    'path',
    {
      d: 'M16.882 7.842a4.882 4.882 0 0 0 -9.764 0c0 4.273 -.213 6.409 -4.118 8.118c2 .882 2 .882 3 3c3 0 4 2 6 2s3 -2 6 -2c1 -2.118 1 -2.118 3 -3c-3.906 -1.709 -4.118 -3.845 -4.118 -8.118zm-13.882 8.119c4 -2.118 4 -4.118 1 -7.118m17 7.118c-4 -2.118 -4 -4.118 -1 -7.118',
      key: 'svg-0',
    },
  ],
]);
