import createReactComponent from '../createReactComponent';
export default createReactComponent('vector-spline', 'IconVectorSpline', [
  [
    'path',
    {
      d: 'M17 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M3 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M17 5c-6.627 0 -12 5.373 -12 12', key: 'svg-2' }],
]);
