import createReactComponent from '../createReactComponent';
export default createReactComponent('clock-off', 'IconClockOff', [
  [
    'path',
    {
      d: 'M5.633 5.64a9 9 0 1 0 12.735 12.72m1.674 -2.32a9 9 0 0 0 -12.082 -12.082',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 7v1', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
