import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { AUTH_WRAPPER_ID } from '../constants/auth';

const EMPTY_QUERIES = {};

export type QueriesState = Record<any, any>;
export const queriesSelector = (state: QueriesState) =>
  state.queries || EMPTY_QUERIES;

export const queryStateSelector = (
  elementId: string,
  disabled: boolean = false,
): ((state: QueriesState) => any) =>
  createSelector(
    [queriesSelector],
    (queries) => !disabled && queries[elementId],
  );

export const authWrapperQuerySelector: (state: QueriesState) => any =
  createSelector([queriesSelector], (queries) =>
    get(queries, [AUTH_WRAPPER_ID, 'query']),
  );
