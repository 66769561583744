import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  DataFieldType,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from './dataTypes';

const PRIMITIVE_DATA_TYPES: DataFieldType[] = [
  DATE,
  DECIMAL,
  INTEGER,
  TEXT,
  BOOLEAN,
  DURATION,
  SINGLE_OPTION,
  MULTIPLE_OPTION,
];

export type PrimitiveDataType =
  | 'DATE'
  | 'DECIMAL'
  | 'INTEGER'
  | 'TEXT'
  | 'BOOLEAN'
  | 'DURATION'
  | 'SINGLE_OPTION'
  | 'MULTIPLE_OPTION';

export default PRIMITIVE_DATA_TYPES;
