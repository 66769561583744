import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader, Modal } from '@noloco/components';
import { User } from '@noloco/core/src/models/User';
import { projectNameSelector } from '@noloco/core/src/selectors/projectSelectors';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { USER_QUERY } from '../../queries/userTable';

type Props = {
  mode: 'deactivate' | 'reactivate';
  onClick: (user: User) => Promise<any>;
};

const LANG_KEY = 'userTable.actions';

const UserDeactivationModal = ({ mode, onClick }: Props) => {
  const { push, query, location } = useRouter();
  const projectName = useSelector(projectNameSelector);
  const [isLoading, setIsLoading] = useState(false);

  const { userId } = query as any;

  const { data, loading: userLoading } = useCacheQuery(USER_QUERY, {
    context: { authQuery: true, projectQuery: true, projectName },
    variables: { id: userId },
    skip: !userId,
  });

  const onClose = useCallback(
    () => push(`/_/users${location.search}`),
    [location.search, push],
  );
  const onConfirm = useCallback(() => {
    setIsLoading(true);
    onClick(data.user).then(onClose);
  }, [data, onClick, onClose]);

  return (
    <Modal
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      confirmDisabled={userLoading}
      confirmText={
        isLoading ? <Loader size="sm" /> : getText(LANG_KEY, mode, 'save')
      }
      closeOnOutsideClick={false}
      title={getText(LANG_KEY, mode, 'label')}
    >
      {getText(LANG_KEY, mode, 'description')}
    </Modal>
  );
};

export default UserDeactivationModal;
