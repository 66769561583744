import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import round from 'lodash/round';
import { Tooltip } from '@noloco/components';
import { MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import useWindowSize from '@noloco/components/src/utils/hooks/useWindowSize';
import { darkModeColors } from '../../../constants/darkModeColors';
import { PERCENTAGE } from '../../../constants/fieldFormats';
import { DataField } from '../../../models/DataTypeFields';
import useDarkMode from '../../../utils/hooks/useDarkMode';

const DEFAULT_MAX_PERCENT_PRECISION = 2;

const scaleMetric = (
  value: number,
  max: number,
  precision: number | undefined,
) => round((value / max) * 100, precision ?? DEFAULT_MAX_PERCENT_PRECISION);

const getGaugeSize = (radius: number, percent: number) => {
  const size = radius < 100 ? SM : MD;
  const containerSize = radius * 2 + (size === MD ? 100 : 50);
  const circumference = 2 * Math.PI * radius;
  const arcLength = circumference * 0.8;
  const valueLength = arcLength * (percent / 100);

  return {
    containerSize,
    circumference,
    arcLength,
    size,
    valueLength,
  };
};

const BASE_RADIUS = 150;

const GaugeChart = ({
  data,
  fills,
  max = 100,
  primaryAxisFormatter,
  xAxisLabel,
  xAxisField,
}: {
  data: { x: number | null };
  fills: string[];
  max: number;
  primaryAxisFormatter: (x: number) => any;
  xAxisLabel: any | undefined;
  xAxisField: DataField;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [radius, setRadius] = useState(BASE_RADIUS);
  const [isDarkModeEnabled] = useDarkMode();

  const percent = useMemo(
    () => scaleMetric(data.x ?? 0, max, xAxisField?.typeOptions?.precision),
    [data.x, max, xAxisField],
  );
  const { containerSize, circumference, arcLength, size, valueLength } =
    useMemo(() => getGaugeSize(radius, percent), [percent, radius]);

  const setRadiusSize = useCallback((size) => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      wrapperRef.current.parentElement &&
      size
    ) {
      const parentWidth = wrapperRef.current.parentElement.clientWidth;

      setRadius((currentRadius) =>
        Math.min(
          (parentWidth - (currentRadius > 100 ? 100 : 50)) / 2,
          BASE_RADIUS,
        ),
      );
    }
  }, []);

  useLayoutEffect(() => {
    setRadiusSize(windowSize);
  }, [setRadiusSize, windowSize]);

  // Determine the font size based on the length of the number
  const numberFontSize = useMemo(() => {
    const value = primaryAxisFormatter(data.x ?? 0);
    const length = value.toString().length;

    if (length > 15) {
      return 'text-xs';
    }
    if (length > 10) {
      return size === MD ? 'text-lg' : 'text-sm';
    }

    return size === MD ? 'text-2xl' : 'text-lg';
  }, [data.x, primaryAxisFormatter, size]);

  return (
    <div
      ref={wrapperRef}
      className="relative mx-auto"
      style={{ height: containerSize, width: containerSize }}
    >
      <svg className="h-full w-full" style={{ transform: `rotateZ(126.8deg)` }}>
        <circle
          className="fill-none"
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={size === MD ? 20 : 10}
          stroke={first(fills)}
          strokeOpacity={isDarkModeEnabled ? 0.4 : 0.2}
          strokeLinecap="round"
          strokeDasharray={`${arcLength},${circumference}`}
        ></circle>
        <circle
          className="fill-none"
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={size === MD ? 30 : 15}
          strokeLinecap="round"
          stroke={first(fills)}
          strokeDasharray={`${valueLength},${circumference}`}
        ></circle>
      </svg>
      <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center space-y-2 px-20 py-20 text-center">
        <Tooltip
          popoverClassName="pb-0.5"
          content={
            <span
              className={classNames({
                [darkModeColors.text.primary]: isDarkModeEnabled,
              })}
            >
              {get(xAxisField, 'typeOptions.format', null) === PERCENTAGE
                ? primaryAxisFormatter(data.x ?? 0)
                : `${primaryAxisFormatter(data.x ?? 0)} / ${max} (${percent}%)`}
            </span>
          }
          bg={
            isDarkModeEnabled
              ? darkModeColors.surfaces.elevation2LiteralColor
              : 'white'
          }
        >
          <span
            className={classNames(
              'w-34 truncate font-medium tracking-wider',
              numberFontSize,
              isDarkModeEnabled ? 'text-gray-100' : 'text-gray-600',
            )}
          >
            {primaryAxisFormatter(data.x ?? 0)}
          </span>
        </Tooltip>
        {xAxisLabel && (
          <span
            className={classNames(
              'block truncate font-medium tracking-wider',
              {
                'text-xl': size === MD,
                'text-base': size === SM,
              },
              isDarkModeEnabled ? 'text-gray-200' : 'text-gray-500',
              'w-full sm:w-auto',
            )}
          >
            {xAxisLabel}
          </span>
        )}
      </div>
    </div>
  );
};

export default GaugeChart;
