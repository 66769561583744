import createReactComponent from '../createReactComponent';
export default createReactComponent('shield-down', 'IconShieldDown', [
  [
    'path',
    {
      d: 'M12.444 20.876c-.147 .044 -.295 .085 -.444 .124a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 .117 6.343',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 16v6', key: 'svg-1' }],
  ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-2' }],
]);
