import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

const useCacheQuery = (
  query: any,
  options: { pollInterval?: number; [key: number | string | symbol]: any } = {},
) => {
  const { startPolling, stopPolling, ...result } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    ...options,
    pollInterval: undefined,
  });

  useEffect(() => {
    if (options.pollInterval) {
      startPolling(options.pollInterval);
    }
    return () => {
      if (options.pollInterval) {
        // Directly stopPolling seems to fix this issue
        // https://github.com/apollographql/apollo-client/issues/9431
        stopPolling();
      }
    };
  }, [options.pollInterval, startPolling, stopPolling]);

  let inCache = true;
  if (result.loading) {
    const cacheResult = result.client.readQuery({
      query: query,
      variables: result.variables,
    });

    if (!cacheResult) {
      inCache = false;
    }
  }

  result.loading = !inCache;

  return result;
};

export default useCacheQuery;
