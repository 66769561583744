import React, { useCallback } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconMessage } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Badge } from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import { CMD, KEY_SQUARE_BRACKETS_CLOSE } from '../../../constants/shortcuts';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useOnKeyPress from '../../../utils/hooks/useOnKeyPress';
import useRouter from '../../../utils/hooks/useRouter';
import { getText } from '../../../utils/lang';
import KeyboardShortcutTooltip from './KeyboardShortcutTooltip';

const RecordCommentsButton = ({ record, theme }: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  const {
    query: { _comments },
    pushQueryParams,
  } = useRouter();
  const commentCount = get(record, 'nolocoCommentsCollection.totalCount');
  const primaryColor = theme.brandColors.primary;

  const onToggleCommentSidebar = useCallback(
    (event: any) => {
      if (event) {
        event.preventDefault();
      }
      const commentsAreOpen = _comments === 'true';
      pushQueryParams({ _comments: commentsAreOpen ? 'false' : 'true' });
    },
    [_comments, pushQueryParams],
  );

  useOnKeyPress(']', onToggleCommentSidebar, {
    ctrlKey: true,
    enabled: true,
  });

  return (
    <div className="flex items-center justify-center sm:w-1/12">
      <KeyboardShortcutTooltip
        buildMode={false}
        keys={[CMD, KEY_SQUARE_BRACKETS_CLOSE]}
        label={getText('core.COMMENT.toggle.tooltip')}
        placement="left"
      >
        <button className="relative" onClick={onToggleCommentSidebar}>
          <Badge
            className="flex items-center"
            m={{ r: 0 }}
            p={{ x: 1, y: 0.5 }}
            type={_comments ? 'filled' : 'outline'}
          >
            <IconMessage
              size={16}
              color={
                isDarkModeEnabled
                  ? darkModeColors.icons.primary
                  : 'currentColor'
              }
            />
          </Badge>
          {commentCount > 0 && (
            <span
              className={classNames(
                'absolute left-4 top-0 -mt-3 mr-1 flex h-5 items-center justify-center rounded-lg border px-1.5 py-0.5 text-center text-xs text-white',
                `bg-${primaryColor}`,
              )}
            >
              {commentCount}
            </span>
          )}
        </button>
      </KeyboardShortcutTooltip>
    </div>
  );
};

export default withTheme(RecordCommentsButton);
