import createReactComponent from '../createReactComponent';
export default createReactComponent('spy-off', 'IconSpyOff', [
  ['path', { d: 'M3 11h8m4 0h6', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5 11v-4c0 -.571 .16 -1.105 .437 -1.56m2.563 -1.44h8a3 3 0 0 1 3 3v4',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M14.88 14.877a3 3 0 1 0 4.239 4.247m.59 -3.414a3.012 3.012 0 0 0 -1.425 -1.422',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M10 17h4', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
