import createReactComponent from '../createReactComponent';
export default createReactComponent('box-align-right', 'IconBoxAlignRight', [
  [
    'path',
    {
      d: 'M14.248 19.753v-16h5a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9.248 19.753h.01', key: 'svg-1' }],
  ['path', { d: 'M4.247 19.753h.011', key: 'svg-2' }],
  ['path', { d: 'M4.247 14.752h.011', key: 'svg-3' }],
  ['path', { d: 'M4.247 8.752h.011', key: 'svg-4' }],
  ['path', { d: 'M4.247 3.752h.011', key: 'svg-5' }],
  ['path', { d: 'M9.248 3.752h.01', key: 'svg-6' }],
]);
