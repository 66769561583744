import SafeStorage from './SafeStorage';

const ANALYTICS_DEBUG_ENABLED =
  typeof localStorage !== 'undefined' &&
  localStorage.getItem('noloco.analytics.debug') === 'true';

export type FrontendEvents =
  | UserEvent
  | WebsiteExample
  | OnboardingEvents
  | LifecycleStage;

enum UserEvents {
  CHANGE_USER_POPOVER_OPENED = 'view as user popover opened',
  CHANGE_USER_SELECTED = 'view as user selected',
  CHOSE_DATA_SOURCE = 'chose data source',
  LOGO_CLICKED = 'logo clicked',
  PUBLISH_CLICKED = 'publish button clicked',
  NEW_ELEMENT_CLICKED = 'new element clicked',
  ELEMENT_ADDED = 'element added',
  NEW_PAGE_SAVED = 'new page saved',
  NEW_DATA_SOURCE_CLICKED = 'new data source clicked',
  NEW_DATA_TYPE_SAVED = 'new data type saved',

  ONBOARDING_CHECKLIST_OPENED = 'onboard checklist opened',
  ONBOARDING_CHECKLIST_CLOSED = 'onboard checklist closed',
  ONBOARDING_CHECKLIST_COMPLETED = 'onboard checklist completed',
  ONBOARDING_CHECKLIST_CTA_CLICKED = 'onboard checklist cta clicked',
  NEW_DATA_FIELD_CLICKED = 'new data field clicked',
  NEW_DATA_FIELD_SAVED = 'new data field saved',
  DATA_FIELD_UPDATED = 'data field updated',

  NEW_LOOKUP_FIELD_SAVED = 'new lookup field saved',
  NEW_ROLLUP_FIELD_SAVED = 'new rollup field saved',
  NEW_DATA_WORKFLOW_CLICKED = 'new data workflow clicked',
  CLONE_DATA_WORKFLOW_CLICKED = 'clone data workflow clicked',
  ACTION_ADDED = 'action added',
  ACTION_DELETED = 'action deleted',
  NEW_PROJECT_WIZARD_NEXT_CLICKED = 'new project wizard next clicked',
  NEW_PROJECT_WIZARD_BACK_CLICKED = 'new project wizard back clicked',
  PLAYGROUND_TOUR_CLOSED = 'playground tour closed',
  PLAYGROUND_TOUR_STEP_VIEWED = 'playground tour step viewed',
  SESSION_START = 'SESSION_START',
  USER_ROLE_ADDED = 'user role added',
  USER_ROLE_UPDATED = 'user role updated',
  GUIDE_LINK_CLICKED = 'guide link clicked',
  WORKFLOW_TEST_MENU_OPENED = 'workflow test menu opened',
  WORKFLOW_TEST_BUTTON_CLICKED = 'workflow test button clicked',

  PREVIOUS_VERSION_PUBLISHED = 'Published previous version',
  REVERTED_EDITOR_TO_PREVIOUS_VERSION = 'Published previous version',

  DARK_MODE_TOGGLED_BY_USER = 'user toggled dark mode',
  DARK_MODE_TOGGLED_FOR_PROJECT = 'project admin toggled dark mode',
}

type UserEvent = UserEvents;

export const {
  CHANGE_USER_POPOVER_OPENED,
  CHANGE_USER_SELECTED,
  CHOSE_DATA_SOURCE,
  LOGO_CLICKED,
  PUBLISH_CLICKED,
  NEW_ELEMENT_CLICKED,
  ELEMENT_ADDED,
  NEW_PAGE_SAVED,
  NEW_DATA_SOURCE_CLICKED,
  NEW_DATA_TYPE_SAVED,
  ONBOARDING_CHECKLIST_OPENED,
  ONBOARDING_CHECKLIST_CLOSED,
  ONBOARDING_CHECKLIST_COMPLETED,
  ONBOARDING_CHECKLIST_CTA_CLICKED,
  NEW_DATA_FIELD_CLICKED,
  NEW_DATA_FIELD_SAVED,
  DATA_FIELD_UPDATED,
  NEW_LOOKUP_FIELD_SAVED,
  NEW_ROLLUP_FIELD_SAVED,
  NEW_DATA_WORKFLOW_CLICKED,
  CLONE_DATA_WORKFLOW_CLICKED,
  ACTION_ADDED,
  ACTION_DELETED,
  NEW_PROJECT_WIZARD_NEXT_CLICKED,
  NEW_PROJECT_WIZARD_BACK_CLICKED,
  PLAYGROUND_TOUR_CLOSED,
  PLAYGROUND_TOUR_STEP_VIEWED,
  SESSION_START,
  USER_ROLE_ADDED,
  USER_ROLE_UPDATED,
  GUIDE_LINK_CLICKED,
  WORKFLOW_TEST_MENU_OPENED,
  WORKFLOW_TEST_BUTTON_CLICKED,
  PREVIOUS_VERSION_PUBLISHED,
  REVERTED_EDITOR_TO_PREVIOUS_VERSION,
  DARK_MODE_TOGGLED_BY_USER,
  DARK_MODE_TOGGLED_FOR_PROJECT,
} = UserEvents;

// Example Project Events
export const WEBSITE_EXAMPLES = {
  LAYOUT_CHANGED: 'example layout changed',
  COLLECTION_FIELD_TOGGLED: 'example collection field toggled',
  COLLECTION_LABEL_CHANGED: 'example collection label changed',
  FORM_FIELD_TOGGLED: 'example form field toggled',
  FORM_LABEL_CHANGED: 'example form label changed',
  FORM_REQUIRED_TOGGLED: 'example form required toggled',
  RECORD_SECTION_TOGGLED: 'example record section toggled',
  ACTION_BUTTON_CLICKED: 'example action button clicked',
  ACTION_BUTTON_CONFIRMED: 'example action button confirmed',
  ACTION_BUTTON_CANCELED: 'example action button canceled',
  WORKFLOW_STARTED: 'example workflow start clicked',
} as const;
type WebsiteExample = (typeof WEBSITE_EXAMPLES)[keyof typeof WEBSITE_EXAMPLES];

// Onboarding Events
enum OnboardingEvents {
  PORTAL_NEW_APP = 'New app button in portals landing page clicked',
  CREATE_NEW_APP = 'Create new app button in templates step clicked',
  PROJECT_CREATED = 'Project created',
  BACK_BUTTON = 'Onboarding back button clicked',
  CLOSE_ONBOARDING_DATA_SOURCE = 'Onboarding closed',
  BRING_TO_APP = 'Onboarding completed',
  DATA_SOURCE_SELECTED = 'Data source selected',
  PROJECT_NAME_SELECTED = 'Unique project name selected',
  PREVIEW_TEMPLATE = 'Templates preview link clicked',
  USE_TEMPLATE = 'Use template clicked',
  VIEW_AIRTABLE_BASE = 'Templates airtable source link clicked',
  VIEW_TEMPLATE_INFO = 'Templates info link clicked',
  CONFIGURE_TEMPLATE_SUBMIT = 'Submit button clicked',
  SELECTED_COLLECTIONS = 'Changed onboarding collection',
  CHANGED_VIEWS = 'Changed onboarding collection view',
  ONBOARDING_MODAL_CLOSED = 'Closed onboarding modal',
  RECOMMENDED_DATA_TYPE_ADD_FIELD = 'Added a field to a recommended data type',
  RECOMMENDED_DATA_TYPE_CHANGE_FIELD = 'Changed a field on a recommended data type',
  RECOMMENDED_DATA_TYPE_RENAME = 'Renamed a recommended data type',
}

export const {
  PORTAL_NEW_APP,
  CREATE_NEW_APP,
  PROJECT_CREATED,
  BACK_BUTTON,
  CLOSE_ONBOARDING_DATA_SOURCE,
  BRING_TO_APP,
  DATA_SOURCE_SELECTED,
  PROJECT_NAME_SELECTED,
  PREVIEW_TEMPLATE,
  USE_TEMPLATE,
  VIEW_AIRTABLE_BASE,
  VIEW_TEMPLATE_INFO,
  CONFIGURE_TEMPLATE_SUBMIT,
  SELECTED_COLLECTIONS,
  CHANGED_VIEWS,
  ONBOARDING_MODAL_CLOSED,
  RECOMMENDED_DATA_TYPE_ADD_FIELD,
  RECOMMENDED_DATA_TYPE_CHANGE_FIELD,
  RECOMMENDED_DATA_TYPE_RENAME,
} = OnboardingEvents;

// Lifecycle Events
enum LifecycleStages {
  TEAM_INBOUND_LEAD = 'team inbound lead',
  TEAM_LEAD = 'team lead',
  TEAM_MQL = 'team mql',
  TEAM_SQL = 'team sql',
  TEAM_OPPORTUNITY = 'team opportunity',
}

export type LifecycleStage = LifecycleStages;

export const {
  TEAM_INBOUND_LEAD,
  TEAM_LEAD,
  TEAM_MQL,
  TEAM_SQL,
  TEAM_OPPORTUNITY,
} = LifecycleStages;

enum Category {
  BUILDER = 'builder',
  ONBOARDING = 'onboarding',
  MARKETING_WEBSITE = 'marketing-website',
}
const { BUILDER, ONBOARDING, MARKETING_WEBSITE } = Category;
type PageEvent = 'Dashboard' | 'App' | Category;

const debugEvent = (event: any, eventObject = {}) => {
  if (ANALYTICS_DEBUG_ENABLED) {
    console.group('Track:', event);

    if ((eventObject as any).category) {
      console.log('Category:', (eventObject as any).category);
    }
    Object.entries(eventObject).forEach(([key, val]) => {
      console.log(`${key}:`, val);
    });

    console.groupEnd();
  }
};

const wrapPropertiesWithUserEmail = (
  properties: Record<string, any> | undefined,
) => {
  if (!properties) {
    return undefined;
  }

  const { email } = new SafeStorage().getJson('ajs_user_traits', {});

  return { ...properties, email };
};

export const trackPage = (
  category: PageEvent,
  pathname: string,
  properties?: Record<string, any>,
) => {
  window.analytics.page(
    category,
    pathname,
    wrapPropertiesWithUserEmail(properties),
  );
  debugEvent('Page View', { category, pathname, ...properties });
};

export const analyticsIdentity = (properties: any) => {
  window.analytics.identify(properties.userId, properties);
  debugEvent('Identify', properties);
};

const getCategory = (eventName: FrontendEvents): Category => {
  if (eventName in OnboardingEvents) {
    return ONBOARDING;
  } else if (eventName in WEBSITE_EXAMPLES) {
    return MARKETING_WEBSITE;
  }

  return BUILDER;
};

export const trackEvent = (
  event: FrontendEvents,
  label?: any,
  value?: any,
  otherProperties = {},
) => {
  const eventObject = {
    category: getCategory(event),
    label,
    value,
    ...(label ? { [label]: value } : {}),
    ...wrapPropertiesWithUserEmail(otherProperties),
  };

  window.analytics.track(event, eventObject);
  debugEvent(event, eventObject);
};

export const trackElementAdded = (type: any, name: any) => {
  trackEvent(ELEMENT_ADDED, 'type', type, { name });
};

export const trackPlaygroundTourClosed = (step: any) => {
  trackEvent(PLAYGROUND_TOUR_CLOSED, 'step', step);
};

export const trackPlaygroundTourStepViewed = (step: any) => {
  trackEvent(PLAYGROUND_TOUR_STEP_VIEWED, 'step', step);
};

export const trackNewProjectWizardNextClicked = (step: any) => {
  trackEvent(NEW_PROJECT_WIZARD_NEXT_CLICKED, 'step', step);
};

export const trackNewProjectWizardBackClicked = (step: any) => {
  trackEvent(NEW_PROJECT_WIZARD_BACK_CLICKED, 'step', step);
};

export const trackNewDataSourceClicked = (source: any) => {
  trackEvent(NEW_DATA_SOURCE_CLICKED, 'source', source);
};

export const trackChoseDataSource = (source: any) => {
  trackEvent(CHOSE_DATA_SOURCE, 'source', source);
};

export const trackOnboardingChecklistCtaClicked = (taskId: any) => {
  trackEvent(ONBOARDING_CHECKLIST_CTA_CLICKED, 'task', taskId);
};

export const trackGuideLinkClick = (url: string) => {
  trackEvent(GUIDE_LINK_CLICKED, 'guide', new URL(url).pathname);
};

export const trackWorkflowTestMenuOpened = (
  workflowId: number,
  referenceId: string,
) => {
  trackEvent(WORKFLOW_TEST_MENU_OPENED, 'workflowId', workflowId, {
    referenceId,
  });
};

export const trackWorkflowTestButtonClicked = (
  workflowId: number,
  referenceId: string,
) => {
  trackEvent(WORKFLOW_TEST_BUTTON_CLICKED, 'workflowId', workflowId, {
    referenceId,
  });
};
