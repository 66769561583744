import React, { memo } from 'react';
import { DARK } from '@noloco/components/src/constants/surface';
import DynamicValueInput from './dynamicValueInput/DynamicValueInput';

const StringPropEditor = memo(
  ({
    // @ts-expect-error TS(2339): Property 'additionalScopeItems' does not exist on ... Remove this comment to see the full error message
    additionalScopeItems = [],
    // @ts-expect-error TS(2339): Property 'acceptableDataTypes' does not exist on t... Remove this comment to see the full error message
    acceptableDataTypes,
    // @ts-expect-error TS(2339): Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
    className,
    // @ts-expect-error TS(2339): Property 'contained' does not exist on type '{ chi... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{ child... Remove this comment to see the full error message
    multiLine,
    // @ts-expect-error TS(2339): Property 'onChange' does not exist on type '{ chil... Remove this comment to see the full error message
    onChange,
    // @ts-expect-error TS(2339): Property 'includeUniqueColumnar' does not exist on... Remove this comment to see the full error message
    includeUniqueColumnar,
    // @ts-expect-error TS(2339): Property 'includeSelf' does not exist on type '{ c... Remove this comment to see the full error message
    includeSelf,
    // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type '{ c... Remove this comment to see the full error message
    placeholder,
    // @ts-expect-error TS(2339): Property 'surface' does not exist on type '{ child... Remove this comment to see the full error message
    surface,
    // @ts-expect-error TS(2339): Property 'value' does not exist on type '{ childre... Remove this comment to see the full error message
    value,
  }) => (
    <DynamicValueInput
      additionalScopeItems={additionalScopeItems}
      acceptableDataTypes={acceptableDataTypes}
      className={className}
      includeUniqueColumnar={includeUniqueColumnar}
      includeSelf={includeSelf}
      elementPath={elementPath}
      multiLine={multiLine}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      surface={surface}
    />
  ),
);

(StringPropEditor as any).defaultProps = {
  acceptableDataTypes: undefined,
  includeUniqueColumnar: false,
  surface: DARK,
};

export default StringPropEditor;
