import createReactComponent from '../createReactComponent';
export default createReactComponent('a-b-2', 'IconAB2', [
  [
    'path',
    {
      d: 'M16 21h3c.81 0 1.48 -.67 1.48 -1.48l.02 -.02c0 -.82 -.69 -1.5 -1.5 -1.5h-3v3z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16 15h2.5c.84 -.01 1.5 .66 1.5 1.5s-.66 1.5 -1.5 1.5h-2.5v-3z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M4 9v-4c0 -1.036 .895 -2 2 -2s2 .964 2 2v4', key: 'svg-2' }],
  [
    'path',
    { d: 'M2.99 11.98a9 9 0 0 0 9 9m9 -9a9 9 0 0 0 -9 -9', key: 'svg-3' },
  ],
  ['path', { d: 'M8 7h-4', key: 'svg-4' }],
]);
