import createReactComponent from '../createReactComponent';
export default createReactComponent('square-half', 'IconSquareHalf', [
  ['path', { d: 'M12 4v16', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 13l7.5 -7.5', key: 'svg-2' }],
  ['path', { d: 'M12 18l8 -8', key: 'svg-3' }],
  ['path', { d: 'M15 20l5 -5', key: 'svg-4' }],
  ['path', { d: 'M12 8l4 -4', key: 'svg-5' }],
]);
