import {
  AIRTABLE,
  DataSourceType,
  SMART_SUITE,
} from '../constants/dataSources';
import {
  DATE,
  DECIMAL,
  DURATION,
  DataFieldType,
  INTEGER,
  OBJECT,
  TEXT,
} from '../constants/dataTypes';
import {
  DATE as DATE_FORMAT,
  DATE_TIME,
  FieldFormat,
  PHONE_NUMBER,
  UNFORMATTED_NUMBER,
} from '../constants/fieldFormats';
import { FieldTypeOptions } from '../models/DataTypeFields';

type ValidTypeOptionsForFieldSource = Record<keyof FieldTypeOptions, boolean>;

const BASE_VALID_TYPE_OPTIONS: ValidTypeOptionsForFieldSource = {
  aiGenerationOptions: false,
  max: false,
  format: false,
  precision: false,
  symbol: false,
  timeZone: false,
  formula: false,
  allowNegative: false,
  min: false,
  step: false,
  prefix: false,
  suffix: false,
  subFields: false,
  time: false,
};

const mergeValidTypeOptions = (
  options: Partial<ValidTypeOptionsForFieldSource>,
): ValidTypeOptionsForFieldSource => ({
  ...BASE_VALID_TYPE_OPTIONS,
  ...options,
});

export const getValidTypeOptionsForFieldSource = (
  fieldType: DataFieldType | string,
  fieldSource: DataSourceType,
): ValidTypeOptionsForFieldSource => {
  switch (fieldSource) {
    case AIRTABLE: {
      switch (fieldType) {
        case INTEGER:
        case DECIMAL:
        case TEXT:
          return mergeValidTypeOptions({ format: true });
        default:
          return BASE_VALID_TYPE_OPTIONS;
      }
    }
    case SMART_SUITE: {
      switch (fieldType) {
        case DATE:
          return mergeValidTypeOptions({ format: true });
        default:
          return BASE_VALID_TYPE_OPTIONS;
      }
    }
    default:
      switch (fieldType) {
        case TEXT:
          return mergeValidTypeOptions({
            aiGenerationOptions: true,
            format: true,
            formula: true,
          });
        case INTEGER:
          return mergeValidTypeOptions({
            aiGenerationOptions: true,
            allowNegative: true,
            format: true,
            formula: true,
            max: true,
            min: true,
            prefix: true,
            step: true,
            suffix: true,
            symbol: true,
          });
        case DECIMAL:
          return mergeValidTypeOptions({
            aiGenerationOptions: true,
            allowNegative: true,
            format: true,
            formula: true,
            max: true,
            min: true,
            precision: true,
            prefix: true,
            suffix: true,
            symbol: true,
          });
        case DATE:
          return mergeValidTypeOptions({ format: true, timeZone: true });
        case DURATION:
          return mergeValidTypeOptions({ format: true });
        case OBJECT:
          return mergeValidTypeOptions({
            format: true,
            time: true,
            subFields: true,
          });
        default:
          return BASE_VALID_TYPE_OPTIONS;
      }
  }
};

export const getAllowedOptionsForFieldSource = (
  fieldType: DataFieldType,
  fieldSource: DataSourceType,
): FieldFormat[] | undefined => {
  switch (fieldSource) {
    case AIRTABLE: {
      switch (fieldType) {
        case INTEGER:
        case DECIMAL:
          return [UNFORMATTED_NUMBER];
        case TEXT:
          return [PHONE_NUMBER];
        default:
          return [];
      }
    }
    case SMART_SUITE: {
      switch (fieldType) {
        case DATE:
          return [DATE_FORMAT, DATE_TIME];
        default:
          return undefined;
      }
    }
    default:
      return undefined;
  }
};
