import createReactComponent from '../createReactComponent';
export default createReactComponent('location-broken', 'IconLocationBroken', [
  [
    'path',
    {
      d: 'M12.896 19.792l-2.896 -5.792l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-3.487 9.657',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M21.5 21.5l-5 -5', key: 'svg-1' }],
  ['path', { d: 'M16.5 21.5l5 -5', key: 'svg-2' }],
]);
