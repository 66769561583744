import createReactComponent from '../createReactComponent';
export default createReactComponent('chart-grid-dots', 'IconChartGridDots', [
  ['path', { d: 'M18 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M6 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M6 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M18 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M8 18h8', key: 'svg-4' }],
  ['path', { d: 'M18 20v1', key: 'svg-5' }],
  ['path', { d: 'M18 3v1', key: 'svg-6' }],
  ['path', { d: 'M6 20v1', key: 'svg-7' }],
  ['path', { d: 'M6 10v-7', key: 'svg-8' }],
  ['path', { d: 'M12 3v18', key: 'svg-9' }],
  ['path', { d: 'M18 8v8', key: 'svg-10' }],
  ['path', { d: 'M8 12h13', key: 'svg-11' }],
  ['path', { d: 'M21 6h-1', key: 'svg-12' }],
  ['path', { d: 'M16 6h-13', key: 'svg-13' }],
  ['path', { d: 'M3 12h1', key: 'svg-14' }],
  ['path', { d: 'M20 18h1', key: 'svg-15' }],
  ['path', { d: 'M3 18h1', key: 'svg-16' }],
  ['path', { d: 'M6 14v2', key: 'svg-17' }],
]);
