import { CUSTOM_EXTRACTION } from '../../constants/elementPropTypeTypes';
import DataTypes from '../DataTypes';
import { DepValue, Element } from '../Element';
import ElementPropType from './ElementPropType';

class CustomExtractionPropType extends ElementPropType {
  constructor(
    extractPropTypesDependencies: (
      prop: Record<any, any>,
      dependencies: DepValue[],
      elementProps?: Record<any, DepValue>,
      propsShape?: Record<any, ElementPropType>,
      element?: Element,
      dataTypes?: DataTypes,
      includeSelf?: boolean,
    ) => DepValue[],
  ) {
    super(CUSTOM_EXTRACTION);
    this.setExtractPropTypesDependencies(extractPropTypesDependencies);
  }
}

export default CustomExtractionPropType;
