import createReactComponent from '../createReactComponent';
export default createReactComponent('test-pipe', 'IconTestPipe', [
  [
    'path',
    {
      d: 'M20 8.04l-12.122 12.124a2.857 2.857 0 1 1 -4.041 -4.04l12.122 -12.124',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 13h8', key: 'svg-1' }],
  ['path', { d: 'M19 15l1.5 1.6a2 2 0 1 1 -3 0l1.5 -1.6z', key: 'svg-2' }],
  ['path', { d: 'M15 3l6 6', key: 'svg-3' }],
]);
