import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-google-drive',
  'IconBrandGoogleDrive',
  [
    ['path', { d: 'M12 10l-6 10l-3 -5l6 -10z', key: 'svg-0' }],
    ['path', { d: 'M9 15h12l-3 5h-12', key: 'svg-1' }],
    ['path', { d: 'M15 15l-6 -10h6l6 10z', key: 'svg-2' }],
  ],
);
