import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-gmail', 'IconBrandGmail', [
  [
    'path',
    { d: 'M16 20h3a1 1 0 0 0 1 -1v-14a1 1 0 0 0 -1 -1h-3v16z', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M5 20h3v-16h-3a1 1 0 0 0 -1 1v14a1 1 0 0 0 1 1z', key: 'svg-1' },
  ],
  ['path', { d: 'M16 4l-4 4l-4 -4', key: 'svg-2' }],
  ['path', { d: 'M4 6.5l8 7.5l8 -7.5', key: 'svg-3' }],
]);
