import createReactComponent from '../createReactComponent';
export default createReactComponent('devices-dollar', 'IconDevicesDollar', [
  [
    'path',
    { d: 'M13 19v-10a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v1.5', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 9h2', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-4' }],
]);
