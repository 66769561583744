import React, { memo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { SelectInput, getColorShade } from '@noloco/components';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import {
  DANGER,
  PRIMARY,
  SECONDARY,
  WARNING,
} from '@noloco/components/src/constants/variants';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'elements.VIEW.actionButtons.variants';

// @ts-expect-error TS(2339): Property 'theme' does not exist on type '{ childre... Remove this comment to see the full error message
const Label = memo(({ theme, variant }) => (
  <div className="flex">
    <div
      className={classNames(
        'mr-2 h-5 w-5 flex-shrink-0 rounded-full',
        `bg-${getColorShade(theme.brandColorGroups[variant], 400)}`,
      )}
    />
    <div className="my-auto">{getText(LANG_KEY, variant)}</div>
  </div>
));

const variants = [SECONDARY, PRIMARY, 'success', WARNING, DANGER];

// @ts-expect-error TS(2339): Property 'appearance' does not exist on type '{ ch... Remove this comment to see the full error message
const ActionButtonAppearanceEditor = memo(({ appearance, onChange, theme }) => {
  const options = variants.map((variant) => ({
    // @ts-expect-error TS(2322): Type '{ theme: any; variant: string; }' is not ass... Remove this comment to see the full error message
    label: <Label theme={theme} variant={variant} />,
    value: variant,
  }));

  return (
    <SelectInput
      className="w-full text-black"
      onChange={onChange}
      options={options}
      size={SM}
      value={appearance || SECONDARY}
    />
  );
});

export default withTheme(ActionButtonAppearanceEditor);
