import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-alipay', 'IconBrandAlipay', [
  [
    'path',
    {
      d: 'M19 3h-14a2 2 0 0 0 -2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2 -2v-14a2 2 0 0 0 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 7h10', key: 'svg-1' }],
  ['path', { d: 'M12 3v7', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M21 17.314c-2.971 -1.923 -15 -8.779 -15 -1.864c0 1.716 1.52 2.55 2.985 2.55c3.512 0 6.814 -5.425 6.814 -8h-6.604',
      key: 'svg-3',
    },
  ],
]);
