import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-notion', 'IconBrandNotion', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 7h3l6 6', key: 'svg-1' }],
  ['path', { d: 'M8 7v10', key: 'svg-2' }],
  ['path', { d: 'M7 17h2', key: 'svg-3' }],
  ['path', { d: 'M15 7h2', key: 'svg-4' }],
  ['path', { d: 'M16 7v10h-1l-7 -7', key: 'svg-5' }],
]);
