import createReactComponent from '../createReactComponent';
export default createReactComponent('ironing-3', 'IconIroning3', [
  ['path', { d: 'M12 15h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 6h7.459a3 3 0 0 1 2.959 2.507l.577 3.464l.81 4.865a1 1 0 0 1 -.985 1.164h-16.82a7 7 0 0 1 7 -7h9.8',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 15h.01', key: 'svg-2' }],
  ['path', { d: 'M15 15h.01', key: 'svg-3' }],
]);
