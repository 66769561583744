import createReactComponent from '../createReactComponent';
export default createReactComponent('robot', 'IconRobot', [
  [
    'path',
    {
      d: 'M7 7h10a2 2 0 0 1 2 2v1l1 1v3l-1 1v3a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-3l-1 -1v-3l1 -1v-1a2 2 0 0 1 2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 16h4', key: 'svg-1' }],
  [
    'circle',
    { cx: '8.5', cy: '11.5', r: '.5', fill: 'currentColor', key: 'svg-2' },
  ],
  [
    'circle',
    { cx: '15.5', cy: '11.5', r: '.5', fill: 'currentColor', key: 'svg-3' },
  ],
  ['path', { d: 'M9 7l-1 -4', key: 'svg-4' }],
  ['path', { d: 'M15 7l1 -4', key: 'svg-5' }],
]);
