import React from 'react';
import get from 'lodash/get';
import { getText } from '@noloco/core/src/utils/lang';
import BasicFormFieldEditor from './BasicFormFieldEditor';
import FieldRequiredEditor from './FieldRequiredEditor';
import FieldValidationRulesEditor from './FieldValidationRulesEditor';
import RelationalFieldFormFieldsEditor from './RelationalFieldFormFieldsEditor';

const AdvancedFormFieldEditor = ({
  config,
  children,
  dataType,
  elementPath,
  field,
  fieldOptions,
  index,
  project,
  updateFields,
  userOptions,
  showFieldTypeOptions,
}: any) => (
  <div className="flex flex-col space-y-4">
    <BasicFormFieldEditor
      config={config}
      dataType={dataType}
      elementPath={elementPath}
      field={field}
      index={index}
      updateFields={updateFields}
      recordScopeOptions={fieldOptions.options}
      showFieldTypeOptions={showFieldTypeOptions}
      project={project}
    />
    {children}
    {(field.relationship || field.relatedField) && !config.hidden && (
      <RelationalFieldFormFieldsEditor
        config={config}
        dataType={dataType}
        elementPath={elementPath}
        field={field}
        index={index}
        project={project}
        recordScopeOptions={fieldOptions.options}
        updateFields={updateFields}
      />
    )}
    <FieldRequiredEditor
      config={config}
      dataType={dataType}
      elementPath={elementPath}
      field={field}
      fieldOptions={fieldOptions}
      index={index}
      project={project}
      updateFields={updateFields}
      userOptions={userOptions}
    />
    <FieldValidationRulesEditor
      dataType={dataType}
      elementPath={elementPath}
      field={field}
      onChange={(path: any, value: any) =>
        updateFields([index, 'validationRules', ...path], value)
      }
      project={project}
      recordScopeOptions={[
        {
          label: getText('elements.FORMS.helpText.formData.label'),
          help: getText('elements.FORMS.helpText.formData.help'),
          options: fieldOptions.options,
        },
      ]}
      value={get(config, 'validationRules')}
    />
  </div>
);

export default AdvancedFormFieldEditor;
