import { DepParser } from 'fast-formula-parser';
import replace from 'lodash/replace';
import { DataField, DataFieldArray } from '../../models/DataTypeFields';
import { DataType } from '../../models/DataTypes';
import { getText } from '../lang';

const ERROR_LANG_KEY = 'errors.formula';
const SHEET_POSITION = { row: 1, col: 1, sheet: 'formulaCalculator' };

const stripCurlyWrappers = (formula: string) =>
  replace(formula, /[{}][{}]/g, '_');
const variableToApiName = (variableName: string) => variableName.slice(1, -1);

class FormulaDependencyParser {
  formula: any;
  maxCol: any;
  nameToId: any;
  parser: any;
  shouldSurfaceError: any;
  constructor(formula: any, shouldSurfaceError = false) {
    this.formula = stripCurlyWrappers(formula);
    this.nameToId = {};
    this.maxCol = 0;
    this.shouldSurfaceError = shouldSurfaceError;
    this.parser = new DepParser({
      onVariable: (name: any) => {
        return {
          col: this.getColumnForVariable(name),
          row: 1,
          name,
        };
      },
    });
  }

  getColumnForVariable(variable: any) {
    // We need each variable to be a unique (but consistent) column
    if (this.nameToId[variable]) {
      return this.nameToId[variable];
    }
    this.maxCol += 1;
    this.nameToId = { ...this.nameToId, [variable]: this.maxCol };
    return this.maxCol;
  }

  parseDependencies() {
    return this.parser
      .parse(this.formula, SHEET_POSITION, this.shouldSurfaceError)
      .map((variableReference: { col: number; row: number; name: string }) =>
        variableToApiName(variableReference.name),
      );
  }
}

export const getFormulaDependencies = (formula: string): string[] => {
  try {
    return new FormulaDependencyParser(formula, false).parseDependencies();
  } catch (e) {
    return [];
  }
};
export const getFormulaDependencyFields = (
  formula: string,
  dataTypes: any,
  dataTypeName: string,
): DataFieldArray<DataField> => {
  const dataType = dataTypes.getByName(dataTypeName) as DataType | undefined;
  if (!dataType) {
    throw new Error(
      `Could not find dataType for dataTypeName: ${dataTypeName}`,
    );
  }
  const requiredDependencies = getFormulaDependencies(formula);
  return new DataFieldArray<DataField>(
    requiredDependencies
      .map((dependency) => dataType.fields.getByName(dependency))
      .filter(Boolean) as DataFieldArray<DataField>,
  );
};

export const getDependenciesWithErrors = (formula: any) => {
  try {
    if (!formula) {
      return {
        error: getText(ERROR_LANG_KEY, 'empty'),
        valid: false,
      };
    }

    const dependencies = new FormulaDependencyParser(
      formula,
      true,
    ).parseDependencies();

    return {
      valid: true,
      dependencies,
    };
  } catch (e) {
    return {
      error: (e as any)._error
        ? getText(ERROR_LANG_KEY, 'invalidFormula')
        : getText(ERROR_LANG_KEY, 'unknownError'),
      valid: false,
    };
  }
};
