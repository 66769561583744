import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-windows', 'IconBrandWindows', [
  [
    'path',
    {
      d: 'M17.8 20l-12 -1.5c-1 -.1 -1.8 -.9 -1.8 -1.9v-9.2c0 -1 .8 -1.8 1.8 -1.9l12 -1.5c1.2 -.1 2.2 .8 2.2 1.9v12.1c0 1.2 -1.1 2.1 -2.2 1.9z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 5l0 14', key: 'svg-1' }],
  ['path', { d: 'M4 12l16 0', key: 'svg-2' }],
]);
