import createReactComponent from '../createReactComponent';
export default createReactComponent('mood-plus', 'IconMoodPlus', [
  ['path', { d: 'M20.985 12.528a9 9 0 1 0 -8.45 8.456', key: 'svg-0' }],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
  ['path', { d: 'M19 16v6', key: 'svg-2' }],
  ['path', { d: 'M9 10h.01', key: 'svg-3' }],
  ['path', { d: 'M15 10h.01', key: 'svg-4' }],
  [
    'path',
    { d: 'M9.5 15c.658 .64 1.56 1 2.5 1s1.842 -.36 2.5 -1', key: 'svg-5' },
  ],
]);
