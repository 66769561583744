import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Switch } from '@noloco/components';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeLabel from '@noloco/core/src/components/buildMode/BuildModeLabel';
import { useUpdateRelatedRecordCollection } from '@noloco/core/src/utils/hooks/useUpdateRelatedRecordCollection';
import { getText } from '@noloco/core/src/utils/lang';
import StringPropEditor from '../../canvas/StringPropEditor';
import { getConditionFieldOptions } from './FieldConditionsEditor';
import RelationalFieldFormFieldsEditor from './RelationalFieldFormFieldsEditor';

const LANG_KEY = 'elements.VIEW.buttons.editRelatedRecordButtons';

const EditRelatedRecordsEditor = ({
  config,
  dataList,
  dataType,
  debouncedUpdateProperty,
  elementPath,
  enabled,
  project,
  rootDataType,
  unlinkButtonText,
  updateProperty,
  hideUnlinkRecordButton,
  hideRelatedRecordButton,
}: any) => {
  const conditionFieldOptions = useMemo(
    () => getConditionFieldOptions(rootDataType, project),
    [project, rootDataType],
  );

  const { relationalDataField } = useUpdateRelatedRecordCollection(
    dataList,
    project,
    rootDataType,
    dataType,
  );

  const updateFields = useCallback(
    (path, value) => {
      updateProperty(['editRelatedRecordButtons', ...path], value);
    },
    [updateProperty],
  );

  return (
    <div className="mb-2 flex w-full flex-col space-y-2 rounded-lg bg-slate-900">
      <div
        className={classNames(
          'flex min-h-10 items-center justify-between border-dashed border-slate-600 p-2',
          { 'border-b': enabled },
        )}
      >
        <BuildModeLabel>{getText(LANG_KEY, 'show')}</BuildModeLabel>
        <Switch
          size={SM}
          disabled={dataType && dataType.readOnly}
          value={enabled}
          onChange={(value: boolean) => {
            updateProperty(['editRelatedRecordButtons'], {
              config: config,
              show: value,
              unlinkButtonText: unlinkButtonText,
              hideAddRelatedRecordButton: !value,
              hideUnlinkButton: !value,
            });
          }}
        />
      </div>
      {enabled && (
        <div className="flex flex-col space-y-2 p-2">
          <div className="flex items-center justify-between">
            <BuildModeLabel>
              {getText(LANG_KEY, 'switch.relatedRecord')}
            </BuildModeLabel>
            <Switch
              size={SM}
              disabled={
                (dataType && dataType.readOnly) || hideUnlinkRecordButton
              }
              value={!hideRelatedRecordButton}
              onChange={(value: boolean) =>
                updateProperty(
                  ['editRelatedRecordButtons', 'hideAddRelatedRecordButton'],
                  !value,
                )
              }
            />
          </div>
          <div className="flex items-center justify-between">
            <BuildModeLabel>
              {getText(LANG_KEY, 'switch.unlinkButton')}
            </BuildModeLabel>
            <Switch
              size={SM}
              disabled={
                (dataType && dataType.readOnly) || hideRelatedRecordButton
              }
              value={!hideUnlinkRecordButton}
              onChange={(value: boolean) =>
                updateProperty(
                  ['editRelatedRecordButtons', 'hideUnlinkButton'],
                  !value,
                )
              }
            />
          </div>
          <BuildModeLabel>
            {getText(LANG_KEY, 'unlinkButtonText')}
          </BuildModeLabel>
          <StringPropEditor
            // @ts-expect-error TS(2322): Type '{ contained: boolean; project: any; onChange... Remove this comment to see the full error message
            contained={true}
            project={project}
            onChange={(value: any) =>
              debouncedUpdateProperty(
                ['editRelatedRecordButtons', 'unlinkButtonText'],
                value,
              )
            }
            elementPath={elementPath}
            placeholder=""
            value={unlinkButtonText}
          />
          <RelationalFieldFormFieldsEditor
            config={config}
            dataType={rootDataType}
            disableAllowNewRecords={true}
            elementPath={elementPath}
            field={relationalDataField}
            index="config"
            project={project}
            recordScopeOptions={get(
              conditionFieldOptions,
              ['fieldOptions', 'options'],
              [],
            )}
            updateFields={updateFields}
          />
        </div>
      )}
    </div>
  );
};

export default EditRelatedRecordsEditor;
