import createReactComponent from '../createReactComponent';
export default createReactComponent('cast-off', 'IconCastOff', [
  ['path', { d: 'M3 19h.01', key: 'svg-0' }],
  ['path', { d: 'M7 19a4 4 0 0 0 -4 -4', key: 'svg-1' }],
  ['path', { d: 'M11 19a8 8 0 0 0 -8 -8', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M15 19h3a3 3 0 0 0 .875 -.13m2 -2a3 3 0 0 0 .128 -.868v-8a3 3 0 0 0 -3 -3h-9m-3.865 .136a3 3 0 0 0 -1.935 1.864',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
