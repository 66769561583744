import set from 'lodash/fp/set';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import { FILE } from '../constants/builtInDataTypes';
import { OBJECT } from '../constants/dataTypes';
import { MANY_TO_ONE, ONE_TO_MANY } from '../constants/relationships';
import { UPDATE } from '../constants/workflowTriggerTypes';
import { DataField } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';
import { BaseRecord, RecordEdge, RecordValue } from '../models/Record';
import { FormConfigWithField, FormFieldConfig } from '../models/View';
import { MutationType } from '../queries/project';
import { formatValueForField } from './data';
import { getFieldReverseApiName, sortFields } from './fields';
import {
  isMultiRelationship,
  isReverseMultiRelationship,
} from './relationships';

export const reduceFormConfigToQueryVariables = <RecordType>(
  mutationType: MutationType,
  fieldConfigs: { field: DataField; config: FormFieldConfig }[],
  existingRecord: RecordType | null,
  draftRecord: Record<string, any>,
  draftUploads: Record<string, any>,
  validateFieldValue: (
    newValue: RecordValue,
    field: DataField,
    config: FormFieldConfig,
  ) => void = () => {},
  getExistingIds: (existingRecord: any, field: DataField) => string[] = (
    existingRecord: any,
    field: DataField,
  ) =>
    get(existingRecord, [field.apiName, 'edges'], []).map(
      (edge: RecordEdge) => edge.node.id,
    ),
) =>
  fieldConfigs.reduce(
    (dataAcc: any, { field, config }: any) => {
      const useConfigValue = config.hidden || config.prefilled;
      if (field.relatedField) {
        const relatedField = field.relatedField;
        if (!isReverseMultiRelationship(relatedField.relationship)) {
          const fieldKey = getFieldReverseApiName(relatedField, {
            apiName: field.type,
          } as DataType);

          if (useConfigValue) {
            if (config.value) {
              return set(
                [`${fieldKey}Id`],
                parseInt(String(config.value).trim(), 10),
                dataAcc,
              );
            }

            return set([`${fieldKey}Id`], config.value, dataAcc);
          }

          if (!draftRecord[field.apiName]) {
            validateFieldValue(null, field, config);
            return set([`${fieldKey}Id`], null, dataAcc);
          }

          return set(
            [`${fieldKey}Id`],
            draftRecord[field.apiName]?.id,
            dataAcc,
          );
        }

        if (relatedField.relationship === MANY_TO_ONE) {
          let newIds = [];
          if (useConfigValue && !isUndefined(config.value)) {
            if (config.value === null) {
              newIds = [];
            } else if (Array.isArray(config.value)) {
              newIds = config.value.map((val: any) => String(val));
            } else {
              newIds = [String(config.value).trim()];
            }
          } else if (!useConfigValue) {
            newIds = get(draftRecord, [field.apiName, 'edges'], []).map(
              (edge: any) => edge.node.id,
            );
          }
          validateFieldValue(
            newIds.length > 0 ? true : undefined,
            field,
            config,
          );

          return set([`${relatedField.reverseName}Id`], newIds, dataAcc);
        }

        const existingIds: string[] =
          mutationType === UPDATE ? getExistingIds(existingRecord, field) : [];
        let newIds: any = [];
        if (useConfigValue && !isUndefined(config.value)) {
          if (config.value === null) {
            newIds = [];
          } else if (Array.isArray(config.value)) {
            newIds = config.value.map((val: any) => String(val));
          } else {
            newIds = [String(config.value).trim()];
          }
        } else if (!useConfigValue) {
          newIds = get(draftRecord, [field.apiName, 'edges'], []).map(
            (edge: any) => edge.node.id,
          );
        }

        validateFieldValue(newIds.length > 0 ? true : undefined, field, config);

        const idsToBeToggled = [
          ...new Set([
            ...newIds.filter((id: any) => !existingIds.includes(id)),
            ...existingIds.filter((id) => !newIds.includes(id)),
          ]),
        ];
        return set([`${relatedField.reverseName}Id`], idsToBeToggled, dataAcc);
      }

      if (!field.relationship) {
        const newValue = useConfigValue
          ? formatValueForField(config.value, field, false)
          : draftRecord[field.apiName];
        validateFieldValue(newValue, field, config);

        if (field.type === OBJECT) {
          // The value may have a graphql typename that we need to remove
          const formattedValue = field.multiple
            ? newValue.map((value: {}) => ({
                ...value,
                __typename: undefined,
              }))
            : {
                ...newValue,
                __typename: undefined,
              };
          return set([field.apiName], formattedValue, dataAcc);
        }

        return set([field.apiName], newValue, dataAcc);
      }

      if (field.type === FILE && !useConfigValue) {
        let fileDataAcc = dataAcc;
        if (draftUploads[field.apiName] !== undefined) {
          if (!isMultiRelationship(field.relationship)) {
            const file = draftUploads[field.apiName]
              ? draftUploads[field.apiName][0]
              : null;

            validateFieldValue(file, field, config);
            fileDataAcc = set([field.apiName], file, fileDataAcc);
          } else {
            const files = draftUploads[field.apiName]
              ? draftUploads[field.apiName].map((file: any) => file[0])
              : null;

            validateFieldValue(files, field, config);
            fileDataAcc = set([field.apiName], files, fileDataAcc);
          }
        } else {
          validateFieldValue(get(draftRecord, field.name, null), field, config);
        }

        if (isMultiRelationship(field.relationship)) {
          const fileIdsKey = `${field.apiName}Id`;
          const removedIds = get(draftRecord, [fileIdsKey]);
          if (removedIds !== undefined) {
            fileDataAcc = set([fileIdsKey], removedIds, fileDataAcc);
          } else {
            const existingIds = get(
              existingRecord,
              [field.apiName, 'edges'],
              [],
            ).map((edge: RecordEdge) => edge.node.id);
            const currentIds = get(
              draftRecord,
              [field.apiName, 'edges'],
              [],
            ).map((edge: RecordEdge) => edge.node.id);

            const idDiff = existingIds.filter(
              (id: string) => !currentIds.includes(id),
            );

            if (idDiff.length > 0) {
              fileDataAcc = set([fileIdsKey], idDiff, fileDataAcc);
            }
          }
        }
        return fileDataAcc;
      }

      if (draftRecord[field.apiName] === undefined && !useConfigValue) {
        validateFieldValue(undefined, field, config);
        return dataAcc;
      }

      if (!isMultiRelationship(field.relationship)) {
        if (useConfigValue) {
          if (config.value) {
            return set(
              [`${field.apiName}Id`],
              parseInt(String(config.value).trim(), 10),
              dataAcc,
            );
          }

          return set([`${field.apiName}Id`], config.value, dataAcc);
        }

        if (!draftRecord[field.apiName]) {
          validateFieldValue(null, field, config);
          return set([`${field.apiName}Id`], null, dataAcc);
        }
        return set(
          [`${field.apiName}Id`],
          (draftRecord[field.apiName] as BaseRecord).id,
          dataAcc,
        );
      }

      if (field.relationship === ONE_TO_MANY) {
        let newIds = [];
        if (useConfigValue && config.value) {
          newIds = Array.isArray(config.value)
            ? config.value.map((val: any) => String(val))
            : [String(config.value).trim()];
        } else if (!useConfigValue) {
          newIds = get(draftRecord, [field.apiName, 'edges'], []).map(
            (edge: any) => edge.node.id,
          );
        }
        validateFieldValue(newIds.length > 0 ? true : undefined, field, config);

        return set([`${field.apiName}Id`], newIds, dataAcc);
      }

      const existingIds: string[] =
        mutationType === UPDATE ? getExistingIds(existingRecord, field) : [];
      let newIds: any = [];
      if (useConfigValue && !isUndefined(config.value)) {
        if (config.value === null) {
          newIds = [];
        } else if (Array.isArray(config.value)) {
          newIds = config.value.map((val: any) => String(val));
        } else {
          newIds = [String(config.value).trim()];
        }
      } else if (!useConfigValue) {
        newIds = get(draftRecord, [field.apiName, 'edges'], []).map(
          (edge: any) => edge.node.id,
        );
      }

      validateFieldValue(newIds.length > 0 ? true : undefined, field, config);

      const idsToBeToggled = [
        ...new Set([
          ...newIds.filter((id: any) => !existingIds.includes(id)),
          ...existingIds.filter((id) => !newIds.includes(id)),
        ]),
      ];
      return set([`${field.apiName}Id`], idsToBeToggled, dataAcc);
    },
    { id: draftRecord.id },
  );

export const getAllFieldConfigsForDataType = (
  dataType: DataType,
  mutationType: MutationType,
): FormConfigWithField[] =>
  dataType.fields
    .slice()
    .sort(sortFields(dataType))
    .filter(
      (field: DataField) =>
        !field.readOnly &&
        !field.hidden &&
        !['id', 'uuid', 'invitationToken'].includes(field.name) &&
        field.type !== 'invoice' &&
        field.name !== 'updatedAt' &&
        (mutationType === UPDATE || field.name !== 'createdAt'),
    )
    .map((field: DataField) => ({
      field,
      config: { label: field.display, name: field.name },
    }));
