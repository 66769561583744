export const IS_PLAYGROUND = process.env.REACT_APP_PLAYGROUND === 'true';

const envVar =
  process.env.REACT_APP_ENVIRONMENT !== undefined
    ? process.env.REACT_APP_ENVIRONMENT
    : process.env.ENVIRONMENT;

export const IS_PRODUCTION = envVar === 'production';
export const IS_QA = envVar === 'qa';

export const STRIPE_PK =
  process.env.REACT_APP_STRIPE_PK ||
  'pk_test_51IBkMZFuSJNgcRASONIudvqq8mvunBo4wxcorkeEXmiCVgH0OjvqPi3bV7zp0ilr7xYZpjeYrFHlkopRfO6it0Hx007jzgAP2r';

export const STRIPE_CLIENT_ID =
  process.env.REACT_APP_STRIPE_CLIENT_ID ||
  'ca_IxhU8tK9F9Yu50inEKqaYSz4sj5rjyKP';

export const FLAGSMITH_ENV_ID =
  process.env.REACT_APP_FLAGSMITH_ENV_ID ||
  process.env.FLAGSMITH_ENV_ID ||
  '9RPg9SniwuQiELbdQeb2JA';

const getDashboardUrl = () => {
  if (IS_PRODUCTION) {
    return 'https://portals.noloco.io';
  } else if (IS_QA) {
    return 'https://qa.noloco.io';
  }

  return 'https://app.nolocolocal.io';
};

export const APP_DASHBOARD_URL = getDashboardUrl();
