import createReactComponent from '../createReactComponent';
export default createReactComponent('cloud-check', 'IconCloudCheck', [
  [
    'path',
    {
      d: 'M11 18.004h-4.343c-2.572 -.004 -4.657 -2.011 -4.657 -4.487c0 -2.475 2.085 -4.482 4.657 -4.482c.393 -1.762 1.794 -3.2 3.675 -3.773c1.88 -.572 3.956 -.193 5.444 1c1.488 1.19 2.162 3.007 1.77 4.769h.99c1.388 0 2.585 .82 3.138 2.007',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-1' }],
]);
