import classNames from 'classnames';
import { useUpdateProperty } from '@noloco/ui/src/utils/hooks/projectHooks';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { PivotTable } from '../../../../constants/pivotTable';
import { DataType } from '../../../../models/DataTypes';
import { Element, ElementPath } from '../../../../models/Element';
import { Project } from '../../../../models/Project';
import { RecordEdge } from '../../../../models/Record';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import usePivotTable from '../../../../utils/hooks/usePivotTable';
import { variables } from '../CollectionRecord';
import CollectionTableHead from '../CollectionTableHead';
import TableSummaryFooter from '../TableSummaryFooter';
import PivotTableGroupHeader from './PivotTableGroupHeader';
import PivotTableRecordLayout from './PivotTableRecordLayout';

type PivotTableLayoutProps = {
  className?: string;
  dataType: DataType;
  edges: RecordEdge[];
  element: Element;
  elementPath: ElementPath;
  isSmScreen: boolean;
  pivotTable: PivotTable;
  project: Project;
  showTableSummary?: boolean;
};

const PivotTableLayout = ({
  className,
  dataType,
  edges,
  element,
  elementPath,
  isSmScreen,
  pivotTable,
  project,
  showTableSummary = true,
}: PivotTableLayoutProps) => {
  const [updateProperty] = useUpdateProperty(elementPath, project);
  const [isDarkModeEnabled] = useDarkMode();

  const {
    columns,
    getRowData,
    groupHeaders,
    rowGroups,
    summaryRow,
    valueColumns,
  } = usePivotTable({
    dataType,
    dataTypes: project.dataTypes,
    edges,
    element,
    pivotTable,
    updateProperty,
  });

  return (
    <div className={classNames(className, 'h-full w-full overflow-y-auto')}>
      <table
        className={classNames('relative w-full min-w-full divide-y', {
          'divide-gray-200': !isDarkModeEnabled,
          [darkModeColors.divides.two]: isDarkModeEnabled,
        })}
      >
        <CollectionTableHead
          maxStickyColumnIndex={null}
          sticky={true}
          additionalElements={columns}
          variables={variables}
          isSmScreen={isSmScreen}
        />
        <tbody
          className={classNames('divide-y', {
            'divide-gray-200 bg-white': !isDarkModeEnabled,
            [`${darkModeColors.surfaces.elevation1} ${darkModeColors.divides.two}`]:
              isDarkModeEnabled,
          })}
        >
          {groupHeaders.map((groupHeader, index) => (
            <PivotTableGroupHeader
              group={groupHeader}
              columns={columns}
              index={index}
              key={groupHeader.field?.id}
              updateProperty={updateProperty}
            />
          ))}
          {valueColumns.length > 1 && (
            <PivotTableGroupHeader
              columns={columns}
              valueColumns={valueColumns}
            />
          )}
          {rowGroups.map((group) => (
            <PivotTableRecordLayout group={group} rows={getRowData(group)} />
          ))}
          {showTableSummary && (
            <TableSummaryFooter
              className="bottom-0 h-6"
              summaryRow={summaryRow}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PivotTableLayout;
