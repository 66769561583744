const MIN_FONT_SIZE_PX = 1;
const MAX_FONT_SIZE_PX = 60;

const findOptimalFontSize = (
  min: number,
  max: number,
  text: string,
  canvasWidth: number,
  fontFamily: string,
  canvasContext: CanvasRenderingContext2D,
  margin = 10,
) => {
  while (min <= max) {
    const midFontSize = Math.floor((min + max) / 2);
    const fontString = `${midFontSize}px ${fontFamily}`;
    canvasContext.font = fontString;
    const textWidth = canvasContext.measureText(text).width + 2 * margin;

    if (textWidth <= canvasWidth) {
      const nextFontSize = midFontSize + 1;
      canvasContext.font = `${nextFontSize}px ${fontFamily}`;
      const nextTextWidth = canvasContext.measureText(text).width + 2 * margin;

      if (nextTextWidth > canvasWidth) {
        break;
      }

      min = nextFontSize;
    } else {
      max = midFontSize - 1;
    }
  }
  return min;
};

export const createCanvasFromText = (
  text: string,
  fontWeight = 'bold',
  fontStyle = '"Dancing Script"',
  width = 600,
  height = 200,
  backgroundColor = 'white',
  textColor = 'black',
) => {
  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');

  if (!canvasContext) {
    return null;
  }

  canvas.width = width;
  canvas.height = height;

  const optimalFontSize = findOptimalFontSize(
    MIN_FONT_SIZE_PX,
    MAX_FONT_SIZE_PX,
    text,
    width,
    fontStyle,
    canvasContext,
  );

  canvasContext.fillStyle = backgroundColor;
  canvasContext.fillRect(0, 0, width, height);
  canvasContext.fillStyle = textColor;
  canvasContext.font = `${fontWeight} ${optimalFontSize}px ${fontStyle}`;
  canvasContext.textAlign = 'center';
  canvasContext.textBaseline = 'middle';

  canvasContext.fillText(text, width / 2, height / 2);

  return canvas;
};

export const canvasElementToImageFile = (
  canvasElement: HTMLCanvasElement | null,
  fileName = 'canvasImage.png',
  fileType = 'image/png',
): [File, ArrayBuffer, string] | null => {
  if (!canvasElement) {
    return null;
  }

  const dataUrl = canvasElement.toDataURL(fileType);
  const byteString = atob(dataUrl.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type: fileType });
  const file = new File([blob], fileName, {
    type: fileType,
    lastModified: new Date().getTime(),
  });

  return [file, arrayBuffer, dataUrl];
};
