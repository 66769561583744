import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'layout-distribute-horizontal',
  'IconLayoutDistributeHorizontal',
  [
    ['path', { d: 'M4 4l16 0', key: 'svg-0' }],
    ['path', { d: 'M4 20l16 0', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M6 9m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
        key: 'svg-2',
      },
    ],
  ],
);
