import createReactComponent from '../createReactComponent';
export default createReactComponent('manual-gearbox', 'IconManualGearbox', [
  ['path', { d: 'M5 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M12 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M19 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M5 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M12 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
  ['path', { d: 'M5 8l0 8', key: 'svg-5' }],
  ['path', { d: 'M12 8l0 8', key: 'svg-6' }],
  ['path', { d: 'M19 8v2a2 2 0 0 1 -2 2h-12', key: 'svg-7' }],
]);
