import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { Button, Modal } from '@noloco/components';
import { AIRTABLE } from '@noloco/core/src/constants/dataSources';
import {
  dataTypesSelector,
  projectIntegrationsSelector,
  projectNameSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useGetFeatureFlagValue from '@noloco/core/src/utils/hooks/useGetFeatureFlagValue';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import useLocalStorageState from '@noloco/core/src/utils/hooks/useLocalStorageState';
import { useRefetchIntegrations } from '@noloco/core/src/utils/hooks/useRefetchIntegrations';
import { getText } from '@noloco/core/src/utils/lang';
import { buildConnectUri } from '@noloco/core/src/utils/oauth';
import airtableIcon from '../img/airtable-icon.svg';
import { openAuthorizationWindow } from '../utils/oauth';

const LANG_KEY =
  'settings.integrations.integrations.airtable.connectionDetails';
const AirtableOauthAlert = () => {
  const { isBuilder } = useIsBuilder();

  const projectName = useSelector(projectNameSelector);
  const integrations = useSelector(projectIntegrationsSelector);
  const hasAirtableOAuthIntegration = useMemo(
    () => !!integrations?.airtable,
    [integrations],
  );
  const dataTypes = useSelector(dataTypesSelector);
  const hasAirtableDataSources = useMemo(
    () =>
      hasAirtableOAuthIntegration ||
      dataTypes?.some((dataType) => dataType.source?.type === AIRTABLE),
    [dataTypes, hasAirtableOAuthIntegration],
  );

  const rawAllowedProjects = useGetFeatureFlagValue(
    'airtable_webhook_projects',
    '',
  );
  const isAllowedProject = useMemo(
    () =>
      rawAllowedProjects
        .split(',')
        .map((project: string) => project.trim())
        .includes(projectName),
    [rawAllowedProjects, projectName],
  );

  const shouldRequestWebhookPermissions =
    isAllowedProject &&
    integrations?.airtable?.scopes &&
    !integrations?.airtable?.scopes?.includes('webhook:manage');
  const shouldMigrateFromApiKeyAuth =
    !hasAirtableOAuthIntegration && hasAirtableDataSources;

  const shouldReconfigureOrConnect = useMemo(
    () => shouldRequestWebhookPermissions || shouldMigrateFromApiKeyAuth,
    [shouldMigrateFromApiKeyAuth, shouldRequestWebhookPermissions],
  );

  const hasAirtableIntegration = useMemo(
    () => !!integrations?.airtable,
    [integrations?.airtable],
  );

  const [remindAfter, setRemindAfter] = useLocalStorageState<number>(
    'noloco:airtableAuth:reminder',
    0,
  );

  const shouldBeOpen = useMemo(
    () =>
      hasAirtableIntegration &&
      shouldReconfigureOrConnect &&
      isBuilder &&
      remindAfter < Date.now(),
    [
      hasAirtableIntegration,
      isBuilder,
      remindAfter,
      shouldReconfigureOrConnect,
    ],
  );

  const { token } = useAuth();
  const refetchIntegrations = useRefetchIntegrations(projectName);
  const onConnectAirtableAccount = useCallback(
    () =>
      openAuthorizationWindow(
        buildConnectUri('airtable'),
        new URLSearchParams({
          token: token ?? '',
          project: projectName,
          reconnect: hasAirtableOAuthIntegration ? 'true' : 'false',
        }),
        { onClose: refetchIntegrations },
      ),
    [token, projectName, hasAirtableOAuthIntegration, refetchIntegrations],
  );

  return (
    <Modal
      open={shouldBeOpen}
      size="md"
      onCancel={() =>
        setRemindAfter(DateTime.now().plus({ days: 1 }).toMillis())
      }
      canCancel={hasAirtableOAuthIntegration}
      canConfirm={false}
      cancelText={getText(LANG_KEY, 'reconfigure.remindMeLater')}
      closeOnOutsideClick={false}
    >
      <div className="w-full">
        <h1 className={classNames('mx-auto px-4 text-2xl font-medium')}>
          {getText(LANG_KEY, 'reconfigure.title')}
        </h1>
        <p className={classNames('mx-auto mt-6 px-4 text-justify text-base')}>
          {getText(
            LANG_KEY,
            'reconfigure.description',
            hasAirtableOAuthIntegration ? 'hasOauth' : 'noOauth',
          )}
        </p>
        <p className="mt-6 px-4 text-center text-base">
          {getText(LANG_KEY, 'reconfigure.ctaDescription')}
        </p>
        <div className="mt-3 w-full px-2">
          <Button
            variant="secondary"
            onClick={onConnectAirtableAccount}
            className="mx-auto flex items-center"
          >
            <img
              src={airtableIcon}
              alt="Airtable logo"
              className="mr-3 h-8 w-auto"
            />
            {getText(LANG_KEY, 'reconfigure.button')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AirtableOauthAlert;
