import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { AccountPlan } from '../constants/accountPlans';
import { PLAN, PROJECT } from '../constants/usage';
import { BillingPlan } from '../models/BillingPlan';
import { PlanAddons, PlanUsage, ProjectUsage } from '../models/Usage';

export type BillingPlanState = { billingPlan: BillingPlan };
export const billingPlanSelector = (state: BillingPlanState) =>
  state.billingPlan;

export const billingPlanTypeSelector: (
  state: BillingPlanState,
) => AccountPlan | undefined = createSelector(
  [billingPlanSelector],
  (billingPlan) => (billingPlan ? billingPlan.type : undefined),
);

export const planAddonsSelector: (
  state: BillingPlanState,
) => PlanAddons | undefined = createSelector(
  [billingPlanSelector],
  (billingPlan) => (billingPlan ? billingPlan.addons : undefined),
);

export const planUsageSelector: (
  state: BillingPlanState,
) => PlanUsage | undefined = createSelector(
  [billingPlanSelector],
  (billingPlan) => get(billingPlan, ['usage', PLAN]) as PlanUsage | undefined,
);

export const projectUsageSelector: (
  state: BillingPlanState,
) => ProjectUsage | undefined = createSelector(
  [billingPlanSelector],
  (billingPlan) =>
    get(billingPlan, ['usage', PROJECT]) as ProjectUsage | undefined,
);

type UserState = { user: { teams: { plan: BillingPlan }[] } };

export const billingPlansSelector = (state: UserState) =>
  state.user?.teams?.map((team) => team.plan);
