import React from 'react';
import { Surface } from '../../../models';
import FormField from '../../form/FormField';

type EmailInputProps = {
  className?: string;
  errorMessage: string;
  label: JSX.Element | string;
  onChange: (value: string) => void;
  value: string | undefined;
  surface: Surface;
};

const EmailInput = ({
  className,
  errorMessage,
  label,
  onChange,
  value,
  surface,
}: EmailInputProps) => (
  <FormField
    aria-label="Email address"
    autoComplete="email"
    className={className}
    name="email"
    type="email"
    onChange={({ target: { value } }: any) => onChange(value)}
    required
    errorType="below-solid"
    label={label}
    placeholder=""
    value={value}
    errorMessage={errorMessage}
    surface={surface}
  />
);

export default EmailInput;
