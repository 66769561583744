import React from 'react';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import BuilderValidateProjectButton from './BuilderValidateProjectButton';
import ProjectErrorMessage from './ProjectErrorMessage';

const BuilderProjectErrorMessage = () => {
  const { isBuilder } = useIsBuilder();

  if (!isBuilder) {
    return <ProjectErrorMessage />;
  }

  return (
    <ProjectErrorMessage>
      <BuilderValidateProjectButton />
    </ProjectErrorMessage>
  );
};

export default BuilderProjectErrorMessage;
