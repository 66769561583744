import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'currency-euro-off',
  'IconCurrencyEuroOff',
  [
    [
      'path',
      {
        d: 'M17.2 7c-1.977 -2.26 -4.954 -2.602 -7.234 -1.04m-1.913 2.079c-1.604 2.72 -1.374 6.469 .69 8.894c2.292 2.691 6 2.758 8.356 .18',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M10 10h-5m0 4h8', key: 'svg-1' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
  ],
);
