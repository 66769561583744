import Confetti from 'react-dom-confetti';

const CONFETTI_CONFIG = {
  angle: 15,
  spread: 100,
  startVelocity: 50,
  elementCount: 50,
  dragFriction: 0.15,
  duration: 3000,
  stagger: 3,
  width: '7px',
  height: '33px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const BaseConfetti = ({ active }: { active: boolean }) => (
  <Confetti active={active} className="z-50" config={CONFETTI_CONFIG} />
);

export default BaseConfetti;
