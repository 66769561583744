import { useMemo } from 'react';
import get from 'lodash/get';
import { FOLDER } from '../../constants/elements';
import { Project } from '../../models/Project';
import { getPagesConfig } from '../pages';
import useRouter from './useRouter';

const usePagePathname = (
  routePath: string,
  parentPage: string | undefined | null,
  project: Project,
) => {
  const { match } = useRouter();
  const topLevelPages = useMemo(() => {
    const { pagesPath } = getPagesConfig(project.elements, project.settings);

    return pagesPath.length > 0
      ? get(project.elements, pagesPath, [])
      : project.elements;
  }, [project]);

  const parentPageElement = useMemo(
    () =>
      parentPage && topLevelPages.find((page: any) => page.id === parentPage),
    [parentPage, topLevelPages],
  );

  const { routePrefix, viewRoutePrefix } = useMemo(() => {
    if (parentPageElement) {
      const parentIsFolder = parentPageElement.type === FOLDER;
      const basePrefix = `/${parentPageElement.props.routePath}/${routePath}`;
      const prefix = `/${parentPageElement.props.routePath}`;
      return {
        routePrefix: basePrefix,
        viewRoutePrefix: parentIsFolder ? basePrefix : prefix,
      };
    }

    const route = routePath ? `/${routePath}` : match.path;
    return {
      routePrefix: route,
      viewRoutePrefix: route,
    };
  }, [match.path, parentPageElement, routePath]);

  return {
    parentPageElement,
    routePrefix,
    viewRoutePrefix,
  };
};

export default usePagePathname;
