import { useCallback, useMemo } from 'react';
import * as H from 'history';
import queryString from 'query-string';
import { match } from 'react-router';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

const useRouter = <T extends {} = Record<string, string>>(): {
  push: (path: string) => void;
  mergeParams: (newParams: Partial<T>) => { pathname: string; search: string };
  pushQueryParams: (newParams: Partial<T>) => void;
  replaceQueryParams: (newParams: Partial<T>) => void;
  replace: (path: string) => void;
  pathname: string;
  query: T;
  match: match<any>;
  location: H.Location;
  history: H.History;
} => {
  const params = useParams<T>();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const queryParams = queryString.parse(location.search);
  const mergeParams = useCallback(
    (newParams: any) => {
      const { pathName, ...restParams } = newParams;
      return {
        pathname: pathName || location.pathname,
        search: queryString.stringify({ ...queryParams, ...restParams }),
      };
    },
    [location.pathname, queryParams],
  );

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      mergeParams,
      pushQueryParams: (newParams: any) => history.push(mergeParams(newParams)),
      replaceQueryParams: (newParams: any) =>
        history.replace(mergeParams(newParams)),
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...params,
        ...queryParams,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
    };
  }, [history, mergeParams, location, queryParams, params, match]);
};

export default useRouter;
