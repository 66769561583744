import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'bleach-no-chlorine',
  'IconBleachNoChlorine',
  [
    [
      'path',
      {
        d: 'M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M6.576 19l7.907 -13.733', key: 'svg-1' }],
    ['path', { d: 'M11.719 19.014l5.346 -9.284', key: 'svg-2' }],
  ],
);
