import { createSlice } from '@reduxjs/toolkit';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { ACTIVE_WORKFLOWS, Feature, LIVE_APPS } from '../constants/features';
import { PLAN } from '../constants/usage';
import { BillingPlan } from '../models/BillingPlan';
import {
  changeWorkflow,
  cloneWorkflow,
  removeWorkflow,
  updateProjectStatus,
} from './project';

const billingPlanDataSlice = createSlice({
  name: 'billingPlan',
  initialState: null as null | BillingPlan,
  reducers: {
    decrementUsage: (state, { payload: { category, property } }) => {
      const path = [category, property];
      if (state?.usage) {
        const currentUsage = get(state.usage, path, 0);
        state.usage = set(path, currentUsage - 1, state.usage);
      }
    },
    incrementUsage: (
      state,
      {
        payload: { category, property },
      }: { payload: { category: string; property: Feature }; type?: string },
    ) => {
      const path = [category, property];
      if (state?.usage) {
        const currentUsage = get(state.usage, path, 0);
        state.usage = set(path, currentUsage + 1, state.usage);
      }
    },
    setBillingPlan: (state, { payload }) => payload,
    setBillingPlanAddons: (state, { payload }) => {
      if (state) {
        state.addons = payload;
      }
    },
    setBillingPlanUsage: (state, { payload }) => {
      if (state) {
        state.usage = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeWorkflow, (state, { payload }) => {
        if (!state) {
          return;
        }
        const path = [PLAN, ACTIVE_WORKFLOWS];
        const currentActiveWorkflows = get(state.usage, path, 0);

        if (
          state?.usage &&
          payload.workflow.enabled &&
          !payload.previousWorkflow.enabled
        ) {
          state.usage = set(path, currentActiveWorkflows + 1, state.usage);
        }

        if (
          state?.usage &&
          !payload.workflow.enabled &&
          payload.previousWorkflow.enabled
        ) {
          state.usage = set(path, currentActiveWorkflows - 1, state.usage);
        }
      })
      .addCase(cloneWorkflow, (state, { payload }) => {
        if (!state) {
          return;
        }
        const path = [PLAN, ACTIVE_WORKFLOWS];
        const currentActiveWorkflows = get(state.usage, path, 0);

        if (state?.usage && payload.workflow.enabled) {
          state.usage = set(path, currentActiveWorkflows + 1, state.usage);
        }
      })
      .addCase(removeWorkflow, (state, { payload }) => {
        if (!state) {
          return;
        }
        const path = [PLAN, ACTIVE_WORKFLOWS];
        const currentActiveWorkflows = get(state.usage, path, 0);

        if (state?.usage && payload.enabled) {
          state.usage = set(path, currentActiveWorkflows - 1, state.usage);
        }
      })
      .addCase(updateProjectStatus, (state, { payload }) => {
        if (payload) {
          billingPlanDataSlice.caseReducers.incrementUsage(state, {
            payload: {
              category: PLAN,
              property: LIVE_APPS,
            },
            type: 'incrementUsage',
          });
        } else {
          billingPlanDataSlice.caseReducers.decrementUsage(state, {
            payload: {
              category: PLAN,
              property: LIVE_APPS,
            },
            type: 'decrementUsage',
          });
        }
      });
  },
});

export const {
  decrementUsage,
  incrementUsage,
  setBillingPlan,
  setBillingPlanAddons,
  setBillingPlanUsage,
} = billingPlanDataSlice.actions;

export default billingPlanDataSlice.reducer;
