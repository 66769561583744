import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-tablet-down',
  'IconDeviceTabletDown',
  [
    [
      'path',
      {
        d: 'M12.5 21h-6.5a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v8',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 16v6', key: 'svg-1' }],
    ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-2' }],
    ['path', { d: 'M11 17a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-3' }],
  ],
);
