import { useCallback, useMemo, useState } from 'react';
import SafeStorage from '../SafeStorage';

const useLocalStorageState = <T>(localStorageKey: string, defaultValue: T) => {
  const safeStorage = useMemo(() => new SafeStorage(), []);
  const [value, setValue] = useState(
    safeStorage.getJson(localStorageKey, defaultValue),
  );

  const onChangeValue = useCallback(
    (nextValue: any) => {
      safeStorage.setJson(localStorageKey, nextValue);
      setValue(nextValue);
    },
    [localStorageKey, safeStorage],
  );

  return [value, onChangeValue];
};

export default useLocalStorageState;
