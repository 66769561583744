import createReactComponent from '../createReactComponent';
export default createReactComponent('paint', 'IconPaint', [
  [
    'path',
    {
      d: 'M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 6h1a2 2 0 0 1 2 2a5 5 0 0 1 -5 5l-5 0v2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10 15m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-2',
    },
  ],
]);
