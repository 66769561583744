import createReactComponent from '../createReactComponent';
export default createReactComponent('skateboard-off', 'IconSkateboardOff', [
  ['path', { d: 'M7 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M15 15a2 2 0 0 0 2 2m2 -2a2 2 0 0 0 -2 -2', key: 'svg-1' }],
  [
    'path',
    { d: 'M3 9c0 .552 .895 1 2 1h5m4 0h5c1.105 0 2 -.448 2 -1', key: 'svg-2' },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
