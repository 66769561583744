import { IconCornerDownRight, IconLink } from '@tabler/icons-react';
import { DataItemOption } from '@noloco/core/src/utils/state';

type DynamicValueSelectedOptionProps = {
  option: DataItemOption;
  onCollapseOption: (option: DataItemOption) => void;
};

const DynamicValueSelectedOption = ({
  option,
  onCollapseOption,
}: DynamicValueSelectedOptionProps) => (
  <div
    className="flex cursor-pointer items-center rounded-lg bg-slate-900 px-3 py-1.5"
    onClick={() => onCollapseOption(option)}
  >
    <IconCornerDownRight size={14} className="mr-2 flex-shrink-0 opacity-50" />
    <span className="truncate text-xs text-gray-200">{option.label}</span>
    <IconLink size={14} className="ml-auto flex-shrink-0" />
  </div>
);

export default DynamicValueSelectedOption;
