import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { Link } from 'react-router-dom';
import withVisibilityRules from '../../../components/canvas/withVisibilityRules';
import useRouter from '../../../utils/hooks/useRouter';
import TabBody from './TabBody';

const getTabLink = (
  rootPathname: any,
  recordId: any,
  queryRecordId: any,
  tabPath: any,
  search: any,
) =>
  `${rootPathname}${
    recordId ? '' : `/view/${queryRecordId}`
  }/${tabPath}${search}`;

const scrollToTop = () =>
  document.getElementById('inner-project-canvas')?.scrollTo(0, 0);

const RecordViewTabItem = ({
  className,
  editorMode,
  tabDef,
  tab,
  tabIndex,
  is,
  queryRecordId,
  project,
  recordId,
  recordScope,
  rootPathname,
  ...rest
}: any) => {
  const { location } = useRouter();

  if (!tabDef || !tabDef.title) {
    return null;
  }

  const tabPath = kebabCase(tabDef.title);

  const NavItemWithVisbilityRules = withVisibilityRules(
    is,
    editorMode,
    tabDef.visibilityRules,
  );

  return (
    <NavItemWithVisbilityRules
      className={className}
      is={Link}
      to={getTabLink(
        rootPathname,
        recordId,
        queryRecordId,
        tabPath,
        location.search,
      )}
      active={tabPath === tab || (!tab && tabIndex === 0)}
      project={project}
      visibilityRulesScope={recordScope}
      onClick={scrollToTop}
      {...rest}
    >
      <TabBody icon={tabDef.icon}>{tabDef.title}</TabBody>
    </NavItemWithVisbilityRules>
  );
};

export default RecordViewTabItem;
