import { LG } from '@noloco/components/src/constants/tShirtSizes';

export const getClassNameFromHeaderWidth = (
  headerWidth: number | string | undefined,
) => {
  switch (headerWidth) {
    case 9:
      return 'max-w-3/4';
    case 12:
      return 'max-w-full';
    case LG:
      return 'w-full max-w-screen-2xl mx-auto';
    default:
      return 'max-w-6xl';
  }
};
