import createReactComponent from '../createReactComponent';
export default createReactComponent('device-imac-off', 'IconDeviceImacOff', [
  [
    'path',
    {
      d: 'M7 3h13a1 1 0 0 1 1 1v12c0 .28 -.115 .532 -.3 .713m-3.7 .287h-13a1 1 0 0 1 -1 -1v-12c0 -.276 .112 -.526 .293 -.707',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 13h10m4 0h4', key: 'svg-1' }],
  ['path', { d: 'M8 21h8', key: 'svg-2' }],
  ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
  ['path', { d: 'M14 17l.5 4', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
