import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'tools-kitchen-2-off',
  'IconToolsKitchen2Off',
  [
    [
      'path',
      {
        d: 'M14.386 10.409c.53 -2.28 1.766 -4.692 4.614 -7.409v12m-4 0h-1c0 -.313 0 -.627 0 -.941',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 19v2h-1v-3', key: 'svg-1' }],
    ['path', { d: 'M8 8v13', key: 'svg-2' }],
    [
      'path',
      { d: 'M5 5v2a3 3 0 0 0 4.546 2.572m1.454 -2.572v-3', key: 'svg-3' },
    ],
    ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
  ],
);
