import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'external-link-off',
  'IconExternalLinkOff',
  [
    [
      'path',
      {
        d: 'M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M10 14l2 -2m2.007 -2.007l6 -6', key: 'svg-1' }],
    ['path', { d: 'M15 4h5v5', key: 'svg-2' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
  ],
);
