import VisibilityRulesWrapper from '../../../components/canvas/VisibilityRulesWrapper';
import { DataType } from '../../../models/DataTypes';
import { Element, ElementPath, ViewTab } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { getText } from '../../../utils/lang';
import RecordEmptyState from './RecordEmptyState';
import { getSectionPath } from './RecordViewSection';
import ViewSection from './ViewSection';

type RecordContainerProps = {
  backLink: any;
  containerSections: { index: number; section: Element }[];
  dataType: DataType;
  draggable?: boolean;
  editorMode: boolean;
  elementPath: ElementPath;
  hasSelectedElement: boolean;
  isEditingData: boolean;
  isExpanded: boolean;
  isRecordView: boolean;
  onError: any;
  onLoadingChange: any;
  pageId: string;
  project: Project;
  record: any;
  recordScope: any;
  rootPathname: any;
  selectedTab?: ViewTab;
  tabs: ViewTab[];
  toggleSectionExpandedState: (sectionId: string) => void;
  visibleSections: { section: Element; index: number }[];
};

const RecordContainer = ({
  backLink,
  containerSections,
  dataType,
  draggable,
  editorMode,
  elementPath,
  hasSelectedElement,
  isEditingData,
  isExpanded,
  isRecordView,
  onError,
  onLoadingChange,
  pageId,
  project,
  record,
  recordScope,
  rootPathname,
  selectedTab,
  tabs,
  toggleSectionExpandedState,
  visibleSections,
}: RecordContainerProps) => {
  if (containerSections.length === 0) {
    if (editorMode) {
      return <RecordEmptyState title={getText('elements.CONTAINER.empty')} />;
    }

    return null;
  }

  return (
    <div className="grid grid-cols-12">
      {containerSections.map(({ section, index }) => (
        <VisibilityRulesWrapper
          editorMode={editorMode}
          key={section.id}
          project={project}
          visibilityRules={section.visibilityRules}
          visibilityRulesScope={recordScope}
        >
          <ViewSection
            backLink={backLink}
            dataType={dataType}
            draggable={draggable}
            droppable={editorMode && hasSelectedElement}
            editorMode={editorMode}
            elementPath={elementPath}
            index={index}
            isEditingData={isEditingData}
            isExpanded={isExpanded}
            isRecordView={isRecordView}
            key={section.id}
            onError={onError}
            onLoadingChange={onLoadingChange}
            pageId={pageId}
            path={getSectionPath(index)}
            project={project}
            record={record}
            rootPathname={rootPathname}
            section={section}
            selectedTab={selectedTab}
            tabs={tabs}
            toggleSectionExpandedState={toggleSectionExpandedState}
            visibleSections={visibleSections}
          />
        </VisibilityRulesWrapper>
      ))}
    </div>
  );
};

export default RecordContainer;
