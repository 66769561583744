import createReactComponent from '../createReactComponent';
export default createReactComponent('satellite-off', 'IconSatelliteOff', [
  [
    'path',
    {
      d: 'M7.707 3.707l5.586 5.586m-1.293 2.707l-1.293 1.293a1 1 0 0 1 -1.414 0l-5.586 -5.586a1 1 0 0 1 0 -1.414l1.293 -1.293',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 10l-3 3l3 3l3 -3', key: 'svg-1' }],
  ['path', { d: 'M10 6l3 -3l3 3l-3 3', key: 'svg-2' }],
  ['path', { d: 'M12 12l1.5 1.5', key: 'svg-3' }],
  ['path', { d: 'M14.5 17c.69 0 1.316 -.28 1.769 -.733', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M15 21c1.654 0 3.151 -.67 4.237 -1.752m1.507 -2.507a6 6 0 0 0 .256 -1.741',
      key: 'svg-5',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
