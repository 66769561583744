import createReactComponent from '../createReactComponent';
export default createReactComponent('social', 'IconSocial', [
  ['path', { d: 'M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M19 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M12 14m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-3' }],
  ['path', { d: 'M12 7l0 4', key: 'svg-4' }],
  ['path', { d: 'M6.7 17.8l2.8 -2', key: 'svg-5' }],
  ['path', { d: 'M17.3 17.8l-2.8 -2', key: 'svg-6' }],
]);
