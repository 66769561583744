import createReactComponent from '../createReactComponent';
export default createReactComponent('mouse', 'IconMouse', [
  [
    'path',
    {
      d: 'M6 3m0 4a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4h-4a4 4 0 0 1 -4 -4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 7l0 4', key: 'svg-1' }],
]);
