import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-powershell', 'IconBrandPowershell', [
  [
    'path',
    {
      d: 'M4.887 20h11.868c.893 0 1.664 -.665 1.847 -1.592l2.358 -12c.212 -1.081 -.442 -2.14 -1.462 -2.366a1.784 1.784 0 0 0 -.385 -.042h-11.868c-.893 0 -1.664 .665 -1.847 1.592l-2.358 12c-.212 1.081 .442 2.14 1.462 2.366c.127 .028 .256 .042 .385 .042z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 8l4 4l-6 4', key: 'svg-1' }],
  ['path', { d: 'M12 16h3', key: 'svg-2' }],
]);
