import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-down-square',
  'IconArrowDownSquare',
  [
    ['path', { d: 'M12 7v14', key: 'svg-0' }],
    ['path', { d: 'M9 18l3 3l3 -3', key: 'svg-1' }],
    ['path', { d: 'M14 3v4h-4v-4z', key: 'svg-2' }],
  ],
);
