import createReactComponent from '../createReactComponent';
export default createReactComponent('cricket', 'IconCricket', [
  [
    'path',
    {
      d: 'M11.105 18.79l-1 .992a4.159 4.159 0 0 1 -6.038 -5.715l.157 -.166l8.282 -8.401l1.5 1.5l3.45 -3.391a2.08 2.08 0 0 1 3.057 2.815l-.116 .126l-3.391 3.45l1.5 1.5l-3.668 3.617',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10.5 7.5l6 6', key: 'svg-1' }],
  ['path', { d: 'M14 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
]);
