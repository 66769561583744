import { useMemo } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { VIEW } from '@noloco/core/src/constants/actionTypes';
import { FILE, ROLE, USER } from '@noloco/core/src/constants/builtInDataTypes';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { VISIBLE_INTERNAL_TYPES } from '@noloco/core/src/utils/dataTypes';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import useDataTypeQuery from '@noloco/core/src/utils/hooks/useDataTypeQuery';
import useFetchUsers from '@noloco/core/src/utils/hooks/useFetchUsers';
import { useInvalidateProjectData } from '@noloco/core/src/utils/hooks/useServerEvents';
import { getPages, getPagesConfig } from '@noloco/core/src/utils/pages';
import { getPageTo } from '@noloco/core/src/utils/urls';
import { GET_USER_LISTS } from '../../queries/project';
import { useUserRoles } from './useUserRoles';

type Category = 'data' | 'users' | 'configure';
type Task = {
  id: string;
  guide: string;
  complete: boolean;
  cta: string;
  required: boolean;
};

const categories: Record<string, Category> = {
  DATA: 'data',
  USERS: 'users',
  CONFIGURE: 'configure',
};

const isUserGenerated = (element: any) =>
  get(element, 'meta.userGenerated') !== false;

const getTasks = (
  project: any,
  user: any,
  projectPages: any,
  userLists: any,
  roles: any,
  hasConnectedDataSource: any,
  customDataType: any,
  hasCustomDataTypeRecords: any,
  customView: any,
  customViewTypeNodes: any,
  hasExternalUsers: boolean,
  anotherCustomView: any,
  users: any,
): Record<Category, Task[]> => {
  const hasCustomRole = roles.length > 2;

  const firstPage = projectPages.find((page: any) => page.type === VIEW);
  const userPage = projectPages.find(
    (page: any) =>
      page.type === VIEW && get(page, 'props.dataList.dataType') === USER,
  );
  const pages = getPages(projectPages);
  const firstPagePath = firstPage && getPageTo([firstPage.id], {}, pages, {});
  const customViewPath =
    customView && getPageTo([customView.id], {}, pages, {});
  const userPagePath =
    (userPage && getPageTo([userPage.id], {}, pages, {})) ?? '/users/';
  const workflows = project.dataTypes.some(
    (dataType: any) => dataType.workflows.length > 0,
  );
  const actionButtons = project.elements.some(
    (element: any) => get(element, 'props.record.actionButtons', []).length > 0,
  );

  const builders = project.users ?? [];

  return {
    [categories.DATA]: [
      ...(hasConnectedDataSource
        ? [
            {
              id: 'connect-data-source',
              guide: 'https://guides.noloco.io/data/data-overview',
              complete: hasConnectedDataSource,
              cta: '/_/data/internal/?__new=true',
              required: true,
            },
          ]
        : [
            {
              id: 'create-collection',
              guide: 'https://guides.noloco.io/data/data-overview',
              complete: customDataType && hasCustomDataTypeRecords,
              cta: '/_/data/internal/?__new=true',
              required: true,
            },
          ]),
      {
        id: 'invite-builder',
        guide: 'https://guides.noloco.io/users-and-permissions/collaborators',
        complete:
          builders.filter(
            (builder: any) =>
              builder.user.email === user.email ||
              (user.email !== builder.user.email && !!builder.user.firstName),
          ).length >= 2,
        cta: '/_/users/manage/~new',
        required: false,
      },
      {
        id: 'external-users',
        complete: userLists.length > 0 || users.length > 0,
        required: true,
        switch: true,
      },
      ...(hasExternalUsers
        ? [
            {
              id: 'user-list',
              complete: userLists.length > 0,
              guide: 'https://guides.noloco.io/settings/user-lists',
              cta: '/_/settings/sign-up',
              required: true,
            },
          ]
        : [
            {
              id: 'no-user-list',
              complete: users.length > 0,
              cta: '/_/users/manage/~new',
              required: true,
            },
          ]),
    ],
    [categories.USERS]: [
      {
        id: 'configure-collection',
        guide: 'https://guides.noloco.io/collections/display',
        complete: !!customView,
        editMode: true,
        cta: `${userPagePath}/?__new-view=true`,
        required: true,
      },
      {
        id: 'configure-record-view',
        complete:
          !!customView &&
          customView.props.record.sections.some((section: any) =>
            isUserGenerated(section),
          ),
        editMode: true,
        disabled: !customView || customViewTypeNodes.length === 0,
        guide: 'https://guides.noloco.io/record-pages/overview',
        cta:
          customViewTypeNodes.length > 0
            ? `${customViewPath}/view/${customViewTypeNodes[0].uuid}`
            : '/',
        required: true,
      },
      {
        id: 'configure-another-collection',
        guide: 'https://guides.noloco.io/collections/adding-collection-views',
        complete: !!anotherCustomView,
        editMode: true,
        cta: `${userPagePath}/?__new-view=true`,
        required: true,
      },
    ],
    [categories.CONFIGURE]: [
      {
        id: 'roles-and-permissions',
        guide:
          'https://guides.noloco.io/users-and-permissions/user-roles-and-permissions',
        complete:
          hasCustomRole &&
          project.dataTypes.some(
            (dataType: any) =>
              dataType.permissionsEnabled && dataType.permissions.length > 0,
          ),
        cta: hasCustomRole
          ? '/_/data/internal/user/permissions'
          : '/_/users/roles',
        required: false,
      },
      {
        id: 'workflows',
        complete: workflows,
        guide: 'https://guides.noloco.io/workflows/workflows',
        cta: '/_/workflows',
        required: false,
      },
      {
        id: 'actionButton',
        complete: actionButtons,
        guide: 'https://guides.noloco.io/actions/action-buttons',
        editMode: true,
        cta:
          customViewTypeNodes.length > 0
            ? `${customViewPath}/view/${customViewTypeNodes[0].uuid}`
            : '/',
        required: false,
      },
      {
        id: 'publish',
        complete: project.publishedVersion >= 1,
        guide: 'https://guides.noloco.io/settings/publishing',
        cta: `${firstPagePath}?__publish=true`,
        required: true,
      },
      {
        id: 'go-live',
        complete: project.live,
        cta: '/_/settings/project',
        guide: 'https://guides.noloco.io/settings/general-settings/live-mode',
        required: true,
      },
    ],
  } as Record<Category, Task[]>;
};

type OnboardingChecklistResult = {
  categories: Record<string, Category>;
  tasks: Record<Category, Task[]>;
  complete: boolean;
  progressPx: number;
  canHideChecklist: boolean;
  loading: boolean;
  hasExternalUsers: boolean;
};

const useOnboardingChecklist = (): OnboardingChecklistResult => {
  const { user } = useAuth();
  const project = useSelector(projectDataSelector);
  const complete = get(project.settings, 'flags.onboardingComplete', false);

  const hasExternalUsers = get(
    project,
    'settings.onboarding.hasExternalUsers',
    false,
  );

  const hasConnectedDataSource = project.dataTypes.some(
    (dataType: any) => dataType.source.type !== INTERNAL,
  );

  const customDataType = useMemo(
    () =>
      project.dataTypes.find(
        (dataType: any) =>
          !dataType.internal &&
          !VISIBLE_INTERNAL_TYPES.includes(dataType.name) &&
          dataType.name !== FILE &&
          dataType.name !== ROLE,
      ),
    [project.dataTypes],
  );

  const {
    nodes: customTypeNodes,
    loading: customTypeLoading,
    refetch: customTypeRefetch,
  } = useDataTypeQuery(
    customDataType && customDataType.name,
    project.dataTypes,
    project.name,
    { __args: { first: 1 } },
    {
      context: { authQuery: true },
      skip: !customDataType || complete,
    },
  );

  useInvalidateProjectData(customTypeRefetch, {
    throttleMs: 30_000,
    skip: !customDataType || complete,
  });

  const hasCustomDataTypeRecords = useMemo(() => {
    if (hasConnectedDataSource) {
      return true;
    }

    if (customTypeLoading) {
      return false;
    }

    return customTypeNodes.length > 0;
  }, [customTypeLoading, customTypeNodes.length, hasConnectedDataSource]);

  const { roles, loading: rolesLoading } = useUserRoles(
    project.name,
    project.dataTypes,
  );

  const { projectPages } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project],
  );

  const customView = useMemo(
    () =>
      projectPages.find(
        (page: any) => page.type === VIEW && isUserGenerated(page),
      ),
    [projectPages],
  );

  const anotherCustomView = useMemo(
    () =>
      projectPages.find(
        (page: any) =>
          page.type === VIEW &&
          page.id !== customView?.id &&
          isUserGenerated(page),
      ),
    [projectPages, customView],
  );

  const customViewType = useMemo(() => {
    if (!customView) {
      return null;
    }

    const viewDataType = get(customView, 'props.dataList.dataType');
    return project.dataTypes.getByName(viewDataType);
  }, [customView, project.dataTypes]);

  const { nodes: customViewTypeNodes, refetch: customViewTypeRefetch } =
    useDataTypeQuery(
      customViewType && customViewType.name,
      project.dataTypes,
      project.name,
      { __args: { first: 1 } },
      {
        context: { authQuery: true },
        skip: !customViewType || complete,
      },
    );
  useInvalidateProjectData(customViewTypeRefetch, {
    throttleMs: 30_000,
    skip: !customViewType || complete,
  });

  const { data: userListsData, loading: userListLoading } = useCacheQuery(
    GET_USER_LISTS,
    {
      variables: { projectId: project.name },
    },
  );

  const userLists = useMemo(
    () => get(userListsData, 'userLists', []),
    [userListsData],
  );

  const { users } = useFetchUsers(project.dataTypes, project.name);

  const tasks = useMemo(
    () =>
      getTasks(
        project,
        user,
        projectPages,
        userLists,
        roles,
        hasConnectedDataSource,
        customDataType,
        hasCustomDataTypeRecords,
        customView,
        customViewTypeNodes,
        hasExternalUsers,
        anotherCustomView,
        users,
      ),
    [
      project,
      user,
      projectPages,
      userLists,
      roles,
      hasConnectedDataSource,
      customDataType,
      hasCustomDataTypeRecords,
      customView,
      customViewTypeNodes,
      hasExternalUsers,
      anotherCustomView,
      users,
    ],
  );

  const allTasks: any = useMemo(
    () =>
      Object.values(tasks).reduce(
        (acc, taskGroup) => [...acc, ...taskGroup],
        [],
      ),
    [tasks],
  );

  const progressPx = useMemo(
    () =>
      Math.round(
        (allTasks.filter((task: any) => task.complete).length /
          allTasks.length) *
          100,
      ),
    [allTasks],
  );

  const canHideChecklist = useMemo(
    () => allTasks.every((task: any) => !task.required || task.complete),
    [allTasks],
  );

  return {
    categories,
    tasks,
    complete: complete ?? false,
    progressPx,
    canHideChecklist,
    loading: userListLoading || rolesLoading || customTypeLoading,
    hasExternalUsers,
  };
};

export default useOnboardingChecklist;
