import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-mongodb', 'IconBrandMongodb', [
  ['path', { d: 'M12 3v19', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M18 11.227c0 3.273 -1.812 4.77 -6 9.273c-4.188 -4.503 -6 -6 -6 -9.273c0 -4.454 3.071 -6.927 6 -9.227c2.929 2.3 6 4.773 6 9.227z',
      key: 'svg-1',
    },
  ],
]);
