import React from 'react';
import { IconValue } from '../../../models/IconValue';
import Icon from '../../Icon';

type TabBodyProps = {
  children: React.ReactNode;
  icon: IconValue;
};

const TabBody: React.FC<TabBodyProps> = ({ children, icon }) => (
  <div className="flex items-center space-x-1">
    {icon && <Icon icon={icon} className="h-4 w-4 opacity-75" />}
    <span>{children}</span>
  </div>
);

export default TabBody;
