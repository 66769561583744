import createReactComponent from '../createReactComponent';
export default createReactComponent('trash-off', 'IconTrashOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  ['path', { d: 'M4 7h3m4 0h9', key: 'svg-1' }],
  ['path', { d: 'M10 11l0 6', key: 'svg-2' }],
  ['path', { d: 'M14 14l0 3', key: 'svg-3' }],
  [
    'path',
    { d: 'M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l.077 -.923', key: 'svg-4' },
  ],
  ['path', { d: 'M18.384 14.373l.616 -7.373', key: 'svg-5' }],
  ['path', { d: 'M9 5v-1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3', key: 'svg-6' }],
]);
