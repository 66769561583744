import { useEffect, useState } from 'react';
import usePrevious from '@noloco/core/src/utils/hooks/usePrevious';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';

const useAppPageUrl = () => {
  const [appPageUrl, setAppPageUrl] = useState('/');
  const previousAppPageUrl = usePrevious(appPageUrl);
  const { pathname } = useRouter();

  useEffect(() => {
    if (
      pathname &&
      !pathname.startsWith('/_/') &&
      pathname !== previousAppPageUrl
    ) {
      setAppPageUrl(pathname);
    }
  }, [pathname, previousAppPageUrl]);

  return appPageUrl;
};

export default useAppPageUrl;
