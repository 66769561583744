import createReactComponent from '../createReactComponent';
export default createReactComponent('deer', 'IconDeer', [
  ['path', { d: 'M3 3c0 2 1 3 4 3c2 0 3 1 3 3', key: 'svg-0' }],
  ['path', { d: 'M21 3c0 2 -1 3 -4 3c-2 0 -3 .333 -3 3', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 18c-1 0 -4 -3 -4 -6c0 -2 1.333 -3 4 -3s4 1 4 3c0 3 -3 6 -4 6',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M15.185 14.889l.095 -.18a4 4 0 1 1 -6.56 0', key: 'svg-3' }],
  ['path', { d: 'M17 3c0 1.333 -.333 2.333 -1 3', key: 'svg-4' }],
  ['path', { d: 'M7 3c0 1.333 .333 2.333 1 3', key: 'svg-5' }],
  ['path', { d: 'M7 6c-2.667 .667 -4.333 1.667 -5 3', key: 'svg-6' }],
  ['path', { d: 'M17 6c2.667 .667 4.333 1.667 5 3', key: 'svg-7' }],
  ['path', { d: 'M8.5 10l-1.5 -1', key: 'svg-8' }],
  ['path', { d: 'M15.5 10l1.5 -1', key: 'svg-9' }],
  ['path', { d: 'M12 15h.01', key: 'svg-10' }],
]);
