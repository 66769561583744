import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-google-home',
  'IconBrandGoogleHome',
  [
    [
      'path',
      {
        d: 'M19.072 21h-14.144a1.928 1.928 0 0 1 -1.928 -1.928v-6.857c0 -.512 .203 -1 .566 -1.365l7.07 -7.063a1.928 1.928 0 0 1 2.727 0l7.071 7.063c.363 .362 .566 .853 .566 1.365v6.857a1.928 1.928 0 0 1 -1.928 1.928z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 13v4h10v-4l-5 -5', key: 'svg-1' }],
    ['path', { d: 'M14.8 5.2l-11.8 11.8', key: 'svg-2' }],
    ['path', { d: 'M7 17v4', key: 'svg-3' }],
    ['path', { d: 'M17 17v4', key: 'svg-4' }],
  ],
);
