import React from 'react';
import classNames from 'classnames';
import { Button, Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { PageInfo } from '@noloco/core/src/models/Record';
import { getText } from '@noloco/core/src/utils/lang';

type Props = {
  className?: string;
  count: number;
  pageInfo: PageInfo;
  setPaginationQuery: (pagination: { after?: string; before?: string }) => void;
  surface?: Surface;
  totalCount: number;
};

const SimpleTableFooter = ({
  className,
  count,
  pageInfo,
  setPaginationQuery,
  surface = DARK,
  totalCount,
}: Props) => (
  <div
    className={classNames(className, 'flex items-center justify-between p-3')}
  >
    <span
      className={classNames('text-xs font-medium', {
        'text-gray-200': surface === DARK,
        'text-gray-800': surface === LIGHT,
      })}
    >
      {getText(
        { count: count, totalCount: totalCount },
        'core.COLLECTION.pagination.showing',
      )}
    </span>
    <div className="flex items-center">
      <Button
        className={classNames(
          'rounded-lg border-2 font-medium disabled:opacity-50',
          {
            'border-gray-100 text-gray-200': surface === DARK,
            'border-gray-900 text-gray-800': surface === LIGHT,
          },
        )}
        disabled={!pageInfo.hasPreviousPage}
        variant="custom"
        onClick={() => setPaginationQuery({ before: pageInfo.startCursor })}
      >
        {getText('core.COLLECTION.pagination.previous')}
      </Button>
      <Button
        className={classNames(
          'ml-3 rounded-lg border-2 font-medium disabled:opacity-50',
          {
            'border-gray-100 text-gray-200': surface === DARK,
            'border-gray-900 text-gray-800': surface === LIGHT,
          },
        )}
        disabled={!pageInfo.hasNextPage}
        variant="custom"
        onClick={() => setPaginationQuery({ after: pageInfo.endCursor })}
      >
        {getText('core.COLLECTION.pagination.next')}
      </Button>
    </div>
  </div>
);

export default SimpleTableFooter;
