import get from 'lodash/get';
import { DateTime } from 'luxon';
import { BOOLEAN, DATE } from '../constants/dataTypes';
import DataTypes from '../models/DataTypes';
import { getOptionByName, isOptionType } from './options';

export const getSingleOptionValueFromDataTypes = (
  valuePath: string[],
  dataTypes: DataTypes,
) => {
  const [__, dataTypeName, fieldName, optionName, outputType] = valuePath;

  const optionField = dataTypes
    .getByName(dataTypeName)
    ?.fields.getByName(fieldName);

  if (optionField && isOptionType(optionField.type)) {
    const option = getOptionByName(optionName, optionField);

    if (option) {
      if (outputType === 'name') {
        return option.name;
      }

      return option.display;
    }
  }

  return undefined;
};
export type TimeFrame = 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';
export type TimeDirection = 'ago' | 'from';
type TimeFilter = {
  frame: TimeFrame;
  count: string;
  direction: TimeDirection;
};
type DateWhen = 'now' | 'start' | 'end';

export const isTimeFrame = (value: string): value is TimeFrame =>
  ['hour', 'day', 'week', 'month', 'quarter', 'year'].includes(value);

export const isTimeDirection = (value: string): value is TimeDirection =>
  ['ago', 'from'].includes(value);

export const getDateFromCustomFilterValue = ({
  frame,
  direction,
  count,
}: TimeFilter): DateTime => {
  const map = {
    hour: 'hours',
    day: 'days',
    week: 'weeks',
    month: 'months',
    quarter: 'quarters',
    year: 'years',
  };
  const now = DateTime.local();
  let date: DateTime = now;
  const numberCount = Number(count);
  if (direction === 'from') {
    date = now.plus({ [map[frame]]: numberCount });
    if (frame !== 'hour') {
      date = date.endOf('day');
    }
  } else {
    date = now.minus({ [map[frame]]: numberCount });
    if (frame !== 'hour') {
      date = date.startOf('day');
    }
  }
  return date;
};

const getCustomDateString = (path: string[]): (() => string) | undefined => {
  const [, _, __, frame, direction, count] = path;

  if (
    !isTimeFrame(frame) ||
    !isTimeDirection(direction) ||
    isNaN(Number(count))
  ) {
    return;
  }
  const date = getDateFromCustomFilterValue({
    frame,
    direction,
    count,
  });
  return () => date.toISO();
};

const getRecordValueFromValuePath = (valueKey: string[]) => {
  const [, , idKey, idTextValue] = valueKey;
  if (idTextValue && idKey === 'id') {
    const idValue = parseInt(idTextValue, 10);

    return isNaN(idValue) ? null : idValue;
  }

  return null;
};

const getRelativeDateFromValuePath = (path: string[]) => {
  const now = DateTime.local();
  const relativeDates = {
    today: {
      now: () => now.startOf('minute').toISO(),
      start: () => now.startOf('day').toISO(),
      end: () => now.endOf('day').toISO(),
    },
    week: {
      start: () => now.startOf('week').toISO(),
      end: () => now.endOf('week').toISO(),
    },
    month: {
      start: () => now.startOf('month').toISO(),
      end: () => now.endOf('month').toISO(),
    },
    year: {
      start: () => now.startOf('year').toISO(),
      end: () => now.endOf('year').toISO(),
    },
  };

  let getDate: (() => string) | undefined;
  const pathString = path.join('.');

  if (pathString.startsWith('values.DATE.custom.')) {
    getDate = getCustomDateString(path);
  } else {
    const [, _, timeFrame, when] = path;
    getDate = get(relativeDates, [
      timeFrame as TimeFrame | 'today',
      when as DateWhen,
    ]);
  }

  if (!getDate) {
    return relativeDates.today.start();
  }
  return getDate();
};

export const getStaticValuesFromValuePath = (valuePath: string[]) => {
  const [__, valueType, valueKey] = valuePath;

  if (valueType === 'OTHER') {
    if (valueKey === 'empty') {
      return null;
    }
  } else if (valueType === BOOLEAN) {
    return String(valueKey) === 'true';
  } else if (valueType === DATE) {
    return getRelativeDateFromValuePath(valuePath);
  } else if (valueType === 'RECORD') {
    return getRecordValueFromValuePath(valuePath);
  }

  return undefined;
};
