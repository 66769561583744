import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'player-record-filled',
  'IconPlayerRecordFilled',
  [
    [
      'path',
      {
        d: 'M8 5.072a8 8 0 1 1 -3.995 7.213l-.005 -.285l.005 -.285a8 8 0 0 1 3.995 -6.643z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
  ],
);
