export const COLLECTION_LAYOUT = 'COLLECTION_LAYOUT';
export const FILTER_FIELDS = 'FILTER_FIELDS';

export type DataTypeHint = 'COLLECTION_LAYOUT' | 'FILTER_FIELDS';
export const DATA_TYPE_HINTS: DataTypeHint[] = [
  COLLECTION_LAYOUT,
  FILTER_FIELDS,
];

export const UP_VOTE = 'UP_VOTE';
export const DOWN_VOTE = 'DOWN_VOTE';

export type HintFeedback = 'UP_VOTE' | 'DOWN_VOTE';
