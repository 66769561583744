import createReactComponent from '../createReactComponent';
export default createReactComponent('folder-off', 'IconFolderOff', [
  [
    'path',
    {
      d: 'M8 4h1l3 3h7a2 2 0 0 1 2 2v8m-2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 1.189 -1.829',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
]);
