import flagsmith from 'flagsmith';

export const ONBOARDING_MANAGER_FLAG = 'onboarding-manager';

export const DATA_INVALIDATION_DEFAULT_THROTTLE_MS =
  'data-invalidation-default-throttle-ms';

const useGetFeatureFlagValue = (feature: any, defaultValue?: any) => {
  return flagsmith.getValue(feature) || defaultValue;
};

export default useGetFeatureFlagValue;
