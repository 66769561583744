import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import shortId from 'shortid';
import { Button, Notice, TextInput, Tooltip } from '@noloco/components';
import { WithDropable } from '@noloco/core/src/components/withDnD';
import { OBJECT } from '@noloco/core/src/constants/dataTypes';
import { RECORD_FIELD } from '@noloco/core/src/constants/draggableItemTypes';
import DataTypeFields, {
  DataField,
} from '@noloco/core/src/models/DataTypeFields';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import {
  BaseFieldConfig,
  FormFieldConfig,
  FormSectionConfig,
} from '@noloco/core/src/models/View';
import useDragAndDropReOrder from '@noloco/core/src/utils/hooks/useDragAndDropReOrder';
import { getText } from '@noloco/core/src/utils/lang';
import {
  PermissionType,
  getFieldFromConfig,
  mapFieldsFromConfig,
} from '@noloco/core/src/utils/permissions';
import FieldListItem from './FieldListItem';

const cleanFilterValue = (filterValue: string) =>
  filterValue.replace(/\s/g, '').toLowerCase();

const filterFields = (filterValue: string, field: DataField) => {
  const cleanValue = cleanFilterValue(filterValue);

  return (
    !filterValue ||
    field.name.toLowerCase().includes(cleanValue) ||
    field.display.toLowerCase().includes(cleanValue)
  );
};

const getId = (entry: any) =>
  `${entry.name}${entry.parent ? `:${entry.parent}` : ''}`;

const DEFAULT_GET_DISABLED_TEXT = () => undefined;

export type UpdateFieldsCallback = (path: ElementPath, value: any) => void;

type FieldsListEditorProps = {
  allowChildFieldsFilter?: (parent: DataField) => boolean;
  allowFieldSections: boolean;
  children: <T>(args: {
    config: T & BaseFieldConfig;
    field: DataField;
    parent: DataField;
    index: number;
    section: FormSectionConfig;
    updateFields: UpdateFieldsCallback;
    debounceUpdateFields: UpdateFieldsCallback;
  }) => any;
  dataType: DataType;
  dataTypes: DataTypes;
  disableSwitch?: (field: DataField) => boolean;
  fields: DataTypeFields[];
  filter?: (field: DataField) => boolean;
  getDisabledText: (field: DataField) => any | undefined;
  getNewFieldConfig: <T>(field: DataField) => T & Omit<BaseFieldConfig, 'name'>;
  isItemDisabled?: (field: DataField) => boolean;
  onFieldsChange: UpdateFieldsCallback;
  permissionType: PermissionType;
  refetchData?: () => Promise<any>;
  sticky?: boolean;
  value: FormFieldConfig[];
};

const FieldsListEditor = forwardRef(
  (
    {
      allowChildFieldsFilter = () => false,
      allowFieldSections,
      children,
      dataType,
      dataTypes,
      disableSwitch,
      fields,
      filter = () => true,
      getDisabledText = DEFAULT_GET_DISABLED_TEXT,
      getNewFieldConfig,
      isItemDisabled,
      onFieldsChange,
      permissionType,
      refetchData = () => Promise.resolve(null),
      sticky = false,
      value,
    }: FieldsListEditorProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [activeField, setActiveField] = useState<number | null>(null);
    const [popoutOpen, setPopoutOpen] = useState(false);
    const hasSections = useMemo(
      () => value.some((config: any) => config && config.isSection),
      [value],
    );

    const orphanedFields = useMemo(
      () =>
        value.filter(
          (config, index) =>
            !getFieldFromConfig(config, index, dataType, dataTypes).field &&
            !(config as FormFieldConfig).isSection,
        ),
      [dataType, dataTypes, value],
    );

    const onRemoveOrphanedFields = useCallback(() => {
      const nextFields = value.filter(
        (config) => !orphanedFields.includes(config),
      );

      onFieldsChange([], nextFields);
    }, [onFieldsChange, orphanedFields, value]);

    const onAddSection = useCallback(() => {
      const sectionId = shortId.generate();
      const label = '';
      const name = `section:${sectionId}`;

      const section = { id: sectionId, isSection: true, label, name };

      if (hasSections) {
        onFieldsChange([], [...value, section]);
      } else {
        onFieldsChange([], [section, ...value]);
      }
    }, [hasSections, onFieldsChange, value]);

    const onDeleteSection = useCallback(
      (sectionId) => () => {
        const sectionIndex = value.findIndex(
          ({ id, isSection }: any) => isSection && id === sectionId,
        );
        const newValue = value.filter(({ id }: any) => id !== sectionId);

        const nextSectionIndex = newValue.findIndex(
          ({ isSection }: any) => isSection,
        );
        if (sectionIndex === 0 && nextSectionIndex > 0) {
          onFieldsChange(
            [],
            [
              newValue[nextSectionIndex],
              ...newValue.slice(0, nextSectionIndex),
              ...newValue.slice(nextSectionIndex + 1),
            ],
          );
        } else {
          onFieldsChange([], newValue);
        }
      },
      [onFieldsChange, value],
    );

    const updateOrder = useCallback(
      (newOrder) => onFieldsChange([], newOrder),
      [onFieldsChange],
    );

    const {
      formatItem,
      draftItems: draftFields,
      findItem: findField,
      onSaveOrder,
    } = useDragAndDropReOrder(
      value,
      getId,
      updateOrder,
      (orderedItems: any) =>
        !hasSections || get(orderedItems, [0, 'isSection']),
    );

    const [filterValue, setFilterValue] = useState<string>('');
    const updateFields = useCallback(
      (path, newValue) => onFieldsChange(path, newValue),
      [onFieldsChange],
    );

    const debounceUpdateFields = useMemo(
      () => debounce(onFieldsChange, 500),
      [onFieldsChange],
    );

    const onEnableField = useCallback(
      (field, parentField) => {
        const newFields = [
          ...draftFields,
          {
            name: field.name,
            parent: parentField ? parentField.name : undefined,
            ...getNewFieldConfig(field),
          },
        ];
        updateFields([], newFields);
      },
      [draftFields, getNewFieldConfig, updateFields],
    );

    const onDisableField = useCallback(
      (field, parentField) => {
        const newFields = draftFields.filter(
          ({ name, parent }: any) =>
            !(
              name === field.name &&
              (!parentField || parent === parentField.name)
            ),
        );
        updateFields([], newFields);
      },
      [draftFields, updateFields],
    );

    const fieldNames = useMemo(() => {
      if (!draftFields) {
        return [];
      }

      return draftFields.map((field: any) => {
        if (!field.parent) {
          return field.name;
        }

        const parentField = dataType.fields.getByName(field.parent);
        if (!parentField) {
          return field.name;
        }

        if (parentField.type === OBJECT) {
          return `${field.parent}.${field.name}`;
        }

        return field.name;
      });
    }, [dataType, draftFields]);

    const draftFieldsWithConfig = useMemo(
      () =>
        draftFields
          .filter(Boolean)
          .map(mapFieldsFromConfig(dataType, dataTypes))
          .filter(
            ({ config, field }: any) =>
              config.isSection || (field && filterFields(filterValue, field)),
          ),
      [draftFields, dataType, dataTypes, filterValue],
    );

    return (
      <div className="flex flex-col pb-6" ref={ref}>
        {orphanedFields.length > 0 && (
          <Notice
            className="mb-4"
            subtitle={getText(
              'elements.VIEW.fields.staleFieldsWarning.subtitle',
            )}
            type="warning"
          >
            <Tooltip
              content={
                <div className="mb-1 flex flex-col">
                  <span className="text-bold mb-1">
                    {getText('elements.VIEW.fields.staleFieldsWarning.tooltip')}
                  </span>
                  <ul className="pl-4">
                    {orphanedFields.map((orphanedField) => (
                      <li
                        className="text-mono list-disc"
                        key={orphanedField.name}
                      >
                        {orphanedField.name}
                      </li>
                    ))}
                  </ul>
                </div>
              }
            >
              <Button
                className="ml-2 whitespace-nowrap"
                variant="warning"
                size="sm"
                onClick={onRemoveOrphanedFields}
              >
                {getText('elements.VIEW.fields.staleFieldsWarning.cta')}
              </Button>
            </Tooltip>
          </Notice>
        )}
        <div
          className={classNames({
            'px-2 pt-2': !sticky,
            'sticky top-24 z-10 bg-slate-800 p-2': sticky,
          })}
        >
          <TextInput
            className="sticky top-0 z-20"
            p={{ x: 4, y: 3 }}
            value={filterValue}
            placeholder={getText('elements.VIEW.fields.filter')}
            clearable={true}
            onChange={({ target: { value: newFilterValue } }) => {
              setFilterValue(newFilterValue);
              if (!newFilterValue) {
                // @ts-expect-error TS(2554): Expected 2 arguments, but got 0.
                onSaveOrder();
              }
            }}
          />
        </div>
        <hr className="my-2 w-full border-slate-700" />
        <div className="space-y-2 px-2">
          {draftFieldsWithConfig.map(
            ({ config, field, parent, parentFieldType, index }: any) => {
              const section = config.isSection ? config : undefined;

              return (
                <FieldListItem
                  activeField={activeField}
                  dataType={dataType}
                  dataTypes={dataTypes}
                  disabledText={getDisabledText(field)}
                  draggable={!filterValue && !(config.isSection && index === 0)}
                  enabled={true}
                  field={field}
                  fieldsList={draftFieldsWithConfig}
                  findItem={findField}
                  hasSections={hasSections}
                  index={index}
                  item={formatItem(config)}
                  key={`${config.name}:${config.parent || ''}`}
                  onChangeEnabled={() => onDisableField(field, parent)}
                  onDeleteSection={onDeleteSection(config.id)}
                  onSaveOrder={onSaveOrder}
                  parentField={parent}
                  parentFieldType={parentFieldType}
                  permissionType={permissionType}
                  popoutOpen={popoutOpen}
                  refetchData={refetchData}
                  section={section}
                  setActiveField={setActiveField}
                  setPopoutOpen={setPopoutOpen}
                  disabled={isItemDisabled ? isItemDisabled(field) : false}
                  disableSwitch={disableSwitch ? disableSwitch(field) : false}
                >
                  {children({
                    config,
                    debounceUpdateFields,
                    field: config.isSection ? config : field,
                    index,
                    parent,
                    section,
                    updateFields,
                  })}
                </FieldListItem>
              );
            },
          )}
          {allowFieldSections && (
            <div className="mb-2 flex">
              <Button className="h-10 w-full" onClick={onAddSection}>
                {getText('elements.FORMS.sections.add')}
              </Button>
            </div>
          )}
        </div>
        {draftFields.length > 0 && (
          <hr className="my-2 w-full border-slate-700" />
        )}
        <div className="space-y-2 px-2">
          {fields
            .filter(
              (field: any) =>
                !fieldNames.includes(field.name) &&
                filter(field) &&
                !field.hidden &&
                filterFields(filterValue, field),
            )
            .map((field: any) => (
              <FieldListItem
                allowChildFields={allowChildFieldsFilter(field)}
                dataType={dataType}
                dataTypes={dataTypes}
                disabledText={getDisabledText(field)}
                enabled={false}
                onChangeEnabled={onEnableField}
                findField={findField}
                item={{ id: field.name }}
                field={field}
                permissionType={permissionType}
                refetchData={refetchData}
                selectedChildFields={draftFields
                  .filter(
                    (fieldConfig: any) => fieldConfig.parent === field.name,
                  )
                  .map((fieldConfig: any) => fieldConfig.name)}
                key={field.name}
                disabled={isItemDisabled ? isItemDisabled(field) : false}
                disableSwitch={disableSwitch ? disableSwitch(field) : false}
              />
            ))}
        </div>
      </div>
    );
  },
);

FieldsListEditor.displayName = 'FieldsListEditor';

export default WithDropable(FieldsListEditor, RECORD_FIELD);
