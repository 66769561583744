import createReactComponent from '../createReactComponent';
export default createReactComponent('ship', 'IconShip', [
  [
    'path',
    {
      d: 'M2 20a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 18l-1 -5h18l-2 4', key: 'svg-1' }],
  ['path', { d: 'M5 13v-6h8l4 6', key: 'svg-2' }],
  ['path', { d: 'M7 7v-4h-1', key: 'svg-3' }],
]);
