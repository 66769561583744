import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'separator-vertical',
  'IconSeparatorVertical',
  [
    ['path', { d: 'M12 4l0 16', key: 'svg-0' }],
    ['path', { d: 'M8 8l-4 4l4 4', key: 'svg-1' }],
    ['path', { d: 'M16 16l4 -4l-4 -4', key: 'svg-2' }],
  ],
);
