import React, { forwardRef } from 'react';
import { LIGHT } from '../../constants/surface';
import { Surface } from '../../models';
import Popover from './Popover';

const OFFSET = [0, 4];

type Props = {
  bg?: string;
  children?: any;
  content: React.ReactNode;
  delayShow?: number;
  delayHide?: number;
  disabled?: boolean;
  isOpen?: boolean;
  offset?: number[];
  p?: number | { t?: number; b?: number; l?: number; r?: number };
  placement?: any;
  popoverClassName?: string;
  showArrow?: any;
  surface?: Surface;
  trigger?: 'click' | 'hover' | 'none';
};

const Tooltip = forwardRef<any, Props>(
  (
    {
      bg,
      children,
      content,
      disabled,
      delayHide,
      offset = OFFSET,
      popoverClassName,
      surface = LIGHT,
      trigger,
      ...rest
    },
    ref,
  ) => (
    <Popover
      bg={bg}
      content={<div className="max-w-xs text-xs">{content}</div>}
      disabled={disabled}
      delayHide={delayHide}
      offset={offset}
      popoverClassName={popoverClassName}
      ref={ref}
      surface={surface}
      trigger={trigger}
      {...rest}
    >
      {children}
    </Popover>
  ),
);

Tooltip.defaultProps = {
  disabled: false,
  trigger: 'hover',
};

export default Tooltip;
