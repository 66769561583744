import { useMemo } from 'react';
import { MULTIPLE_OPTION, SINGLE_OPTION } from '../../constants/dataTypes';

export const useOptionTextValue = (field: any, value: any) => {
  const isOption = useMemo(
    () =>
      field && (field.type === SINGLE_OPTION || field.type === MULTIPLE_OPTION),
    [field],
  );

  const optionTextValue = useMemo(() => {
    if (!isOption || !value || !Array.isArray(value)) {
      return null;
    }

    return value
      .map((valueItem) => valueItem.text)
      .filter(Boolean)
      .join('');
  }, [isOption, value]);

  return {
    isOption,
    optionTextValue,
  };
};
