import { useCallback, useMemo } from 'react';
import VisibilityRulesWrapper from '../../../components/canvas/VisibilityRulesWrapper';
import { WithDropable } from '../../../components/withDnD';
import { SECTION } from '../../../constants/draggableItemTypes';
import { Element } from '../../../models/Element';
import cappedMemoize from '../../../utils/cappedMemoize';
import ViewSection from './ViewSection';

export const getSectionPath = cappedMemoize((index) => [index], {
  maxKeys: 500,
});

const RecordViewSection = ({
  backLink,
  data,
  dataType,
  draggable,
  editorMode,
  elementPath,
  findSection,
  formatSection,
  hasSelectedElement,
  index,
  isEditingData,
  isExpanded,
  isRecordView,
  onError,
  onLoadingChange,
  onSaveOrder,
  pageId,
  project,
  recordScope,
  rootPathname,
  section,
  selectedTab,
  tabSections,
  toggleSectionExpandedState,
  visibleTabs,
}: any) => {
  const item = useMemo(
    () => (section ? formatSection(section) : null),
    [formatSection, section],
  );

  const sectionHasValidContainer = useMemo(
    () =>
      section.container &&
      tabSections.find(
        (tabSection: { section: Element; index: number }) =>
          tabSection.section.id === section.container,
      )?.section,
    [section, tabSections],
  );

  const onSectionError = useCallback(() => {
    onError(section.id);
  }, [onError, section]);

  const onSectionLoadingChange = useCallback(
    (nextLoading: any) => {
      onLoadingChange(section.id, nextLoading);
    },
    [onLoadingChange, section],
  );

  if (!section || sectionHasValidContainer) {
    return null;
  }

  return (
    <VisibilityRulesWrapper
      editorMode={editorMode}
      key={section.id}
      project={project}
      visibilityRules={section.visibilityRules}
      visibilityRulesScope={recordScope}
    >
      <ViewSection
        backLink={backLink}
        dataType={dataType}
        draggable={draggable}
        droppable={editorMode && hasSelectedElement}
        editorMode={editorMode}
        elementPath={elementPath}
        findItem={findSection}
        index={index}
        isEditingData={isEditingData}
        isExpanded={isExpanded}
        isRecordView={isRecordView}
        item={item}
        key={section.id}
        onError={onSectionError}
        onLoadingChange={onSectionLoadingChange}
        onSaveOrder={onSaveOrder}
        pageId={pageId}
        path={getSectionPath(index)}
        project={project}
        record={data}
        rootPathname={rootPathname}
        section={section}
        selectedTab={selectedTab}
        tabs={visibleTabs}
        toggleSectionExpandedState={toggleSectionExpandedState}
        visibleSections={tabSections}
      />
    </VisibilityRulesWrapper>
  );
};

export default WithDropable(RecordViewSection, SECTION);
