import { useCallback, useEffect } from 'react';

const useIsWindowVisible = (
  handleWindowVisibilityChange: (isVisible: boolean) => void,
) => {
  const onVisibilityChange = useCallback(() => {
    handleWindowVisibilityChange(!document.hidden);
  }, [handleWindowVisibilityChange]);

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  });
};

export default useIsWindowVisible;
