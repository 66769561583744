import createReactComponent from '../createReactComponent';
export default createReactComponent('contrast-2', 'IconContrast2', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 18h2a6 6 0 0 0 6 -6a6 6 0 0 1 6 -6h2', key: 'svg-1' }],
]);
