import createReactComponent from '../createReactComponent';
export default createReactComponent('function-off', 'IconFunctionOff', [
  [
    'path',
    {
      d: 'M9 15.5v.25c0 .69 .56 1.25 1.25 1.25a1.38 1.38 0 0 0 1.374 -1.244l.376 -3.756m.363 -3.63l.013 -.126a1.38 1.38 0 0 1 1.374 -1.244c.69 0 1.25 .56 1.25 1.25v.25',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8 4h10a2 2 0 0 1 2 2v10m-.586 3.414a2 2 0 0 1 -1.414 .586h-12a2 2 0 0 1 -2 -2v-12c0 -.547 .22 -1.043 .576 -1.405',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 12h3', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
