import createReactComponent from '../createReactComponent';
export default createReactComponent('signal-3g', 'IconSignal3g', [
  [
    'path',
    { d: 'M17 8h-2a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2v-4h-1', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M6 8h2.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-2.5',
      key: 'svg-1',
    },
  ],
]);
