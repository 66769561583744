import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-storybook', 'IconBrandStorybook', [
  ['path', { d: 'M5 4l.5 16.5l13.5 .5v-18z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 15c.6 1.5 1.639 2 3.283 2h-.283c1.8 0 3 -.974 3 -2.435c0 -1.194 -.831 -1.799 -2.147 -2.333l-1.975 -.802c-1.15 -.467 -1.878 -1.422 -1.878 -2.467c0 -.97 .899 -1.786 2.087 -1.893l.613 -.055c1.528 -.138 3 .762 3.3 1.985',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 3.5v1', key: 'svg-2' }],
]);
