import { useMemo } from 'react';
import { DataType } from '../../models/DataTypes';
import { FormConfigWithField, Section } from '../../models/View';
import { getPageQueryString } from '../../queries/project';
import { getDepFieldsForForm } from '../fieldDependencies';

type AutoFormConfig = {
  dataTypeName: string;
  dataTypeWithRelations: DataType;
  fieldConfigs: FormConfigWithField[];
  itemQueryString: string;
  sectionConfigs: Section[];
};

const useAutoFormConfig = (
  dataType: any,
  fields: any,
  project: any,
  recordId: any,
  sections?: any,
): AutoFormConfig => {
  const dataTypeName = useMemo(() => dataType && dataType.name, [dataType]);
  const fieldConfigs = useMemo(
    () =>
      dataType &&
      fields
        .map((fieldConfig: any) => ({
          field: dataType.fields.getByName(fieldConfig.field),
          config: fieldConfig,
        }))
        .filter((fieldConfig: any) => fieldConfig.field),
    [dataType, fields],
  );

  const formFieldDependencies = useMemo(
    () => getDepFieldsForForm(fieldConfigs, dataType, project.dataTypes),
    [dataType, fieldConfigs, project.dataTypes],
  );
  const itemQueryString = useMemo(
    () =>
      dataType &&
      getPageQueryString(
        dataType.name,
        { id: recordId },
        formFieldDependencies,
      ),
    [dataType, formFieldDependencies, recordId],
  );

  const sectionConfigs = useMemo(
    () =>
      sections &&
      sections.map((sectionConfig: any) => ({
        config: {
          conditions: sectionConfig.conditions,
          helpText: sectionConfig.helpText,
          label: sectionConfig.label,
          name: sectionConfig.name,
          placeholder: sectionConfig.placeholder,
          value: sectionConfig.value,
        },

        section: {
          fields: sectionConfig.fields,
          id: sectionConfig.id,
        },
      })),
    [sections],
  );

  return {
    dataTypeName,
    dataTypeWithRelations: dataType,
    fieldConfigs,
    itemQueryString,
    sectionConfigs,
  };
};

export default useAutoFormConfig;
