import createReactComponent from '../createReactComponent';
export default createReactComponent('candle', 'IconCandle', [
  [
    'path',
    { d: 'M9 21h6v-9a1 1 0 0 0 -1 -1h-4a1 1 0 0 0 -1 1v9z', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M12 3l1.465 1.638a2 2 0 1 1 -3.015 .099l1.55 -1.737z', key: 'svg-1' },
  ],
]);
