import React from 'react';
import MarkdownText from '../../components/MarkdownText';
import { darkModeColors } from '../../constants/darkModeColors';
import useDarkMode from '../../utils/hooks/useDarkMode';

interface HelpTextProps {
  children: string;
}

const HelpText: React.FC<HelpTextProps> = ({ children }) => {
  const [isDarkModeEnabled] = useDarkMode();

  return (
    <div
      className={`flex py-0.5 text-xs ${
        isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-600'
      }`}
    >
      <MarkdownText small={true}>{children}</MarkdownText>
    </div>
  );
};

export default HelpText;
