import React from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';
import { Button, Popover } from '@noloco/components';
import {
  ButtonType,
  OUTLINE,
} from '@noloco/components/src/components/button/buttonTypes';

type MobileActionButtonsWrapperProps = {
  children: React.ReactNode;
  newButtonVisible: boolean;
  rounded?: boolean;
  type?: ButtonType;
};

const MobileActionButtonsWrapper = ({
  children,
  newButtonVisible,
  rounded = true,
  type,
}: MobileActionButtonsWrapperProps): JSX.Element => (
  <Popover
    placement="bottom-end"
    content={<div className="flex w-48 flex-col space-y-1">{children}</div>}
  >
    <Button
      className={classNames(
        'hidden h-8 items-center space-x-2 border-l sm:flex',
        {
          'rounded-br-lg rounded-tr-lg border-l': newButtonVisible && rounded,
          'rounded-lg': !newButtonVisible && rounded,
          'border-b border-r border-t': type === OUTLINE,
          'rounded-br-none rounded-tr-none': !rounded,
        },
      )}
      type={type}
      variant="primary"
      border={[false]}
      rounded={false}
    >
      <div className="py-px">
        <IconChevronDown size={16} />
      </div>
    </Button>
  </Popover>
);

export default MobileActionButtonsWrapper;
