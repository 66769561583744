import createReactComponent from '../createReactComponent';
export default createReactComponent('row-insert-top', 'IconRowInsertTop', [
  [
    'path',
    {
      d: 'M4 18v-4a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 9v-4', key: 'svg-1' }],
  ['path', { d: 'M10 7l4 0', key: 'svg-2' }],
]);
