import createReactComponent from '../createReactComponent';
export default createReactComponent('arrows-minimize', 'IconArrowsMinimize', [
  ['path', { d: 'M5 9l4 0l0 -4', key: 'svg-0' }],
  ['path', { d: 'M3 3l6 6', key: 'svg-1' }],
  ['path', { d: 'M5 15l4 0l0 4', key: 'svg-2' }],
  ['path', { d: 'M3 21l6 -6', key: 'svg-3' }],
  ['path', { d: 'M19 9l-4 0l0 -4', key: 'svg-4' }],
  ['path', { d: 'M15 9l6 -6', key: 'svg-5' }],
  ['path', { d: 'M19 15l-4 0l0 4', key: 'svg-6' }],
  ['path', { d: 'M15 15l6 6', key: 'svg-7' }],
]);
