import createReactComponent from '../createReactComponent';
export default createReactComponent('file-search', 'IconFileSearch', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v4.5',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M18.5 19.5l2.5 2.5', key: 'svg-3' }],
]);
