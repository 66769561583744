import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-big-right-lines-filled',
  'IconArrowBigRightLinesFilled',
  [
    [
      'path',
      {
        d: 'M12.089 3.634a2 2 0 0 0 -1.089 1.78l-.001 2.585l-1.999 .001a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 .993 .883l1.999 -.001l.001 2.587a2 2 0 0 0 3.414 1.414l6.586 -6.586a2 2 0 0 0 0 -2.828l-6.586 -6.586a2 2 0 0 0 -2.18 -.434l-.145 .068z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M3 8a1 1 0 0 1 .993 .883l.007 .117v6a1 1 0 0 1 -1.993 .117l-.007 -.117v-6a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-1',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M6 8a1 1 0 0 1 .993 .883l.007 .117v6a1 1 0 0 1 -1.993 .117l-.007 -.117v-6a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-2',
        strokeWidth: '0',
      },
    ],
  ],
);
