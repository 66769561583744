import createReactComponent from '../createReactComponent';
export default createReactComponent('parentheses-off', 'IconParenthesesOff', [
  [
    'path',
    { d: 'M5.743 5.745a12.253 12.253 0 0 0 1.257 14.255', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M17 4a12.25 12.25 0 0 1 2.474 11.467m-1.22 2.794a12.291 12.291 0 0 1 -1.254 1.739',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
