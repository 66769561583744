import createReactComponent from '../createReactComponent';
export default createReactComponent('shield', 'IconShield', [
  [
    'path',
    {
      d: 'M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3',
      key: 'svg-0',
    },
  ],
]);
