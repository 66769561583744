import createReactComponent from '../createReactComponent';
export default createReactComponent('meteor', 'IconMeteor', [
  [
    'path',
    {
      d: 'M21 3l-5 9h5l-6.891 7.086a6.5 6.5 0 1 1 -8.855 -9.506l7.746 -6.58l-1 5l9 -5z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9.5 14.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0',
      key: 'svg-1',
    },
  ],
]);
