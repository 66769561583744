import { useCallback } from 'react';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import { useSelector } from 'react-redux';
import { useUpdateProperty } from '@noloco/ui/src/utils/hooks/projectHooks';
import { Element, ElementPath } from '../../models/Element';
import {
  editorModeSelector,
  hasSelectedElementPathSelector,
  selectedSectionSelector,
} from '../../selectors/elementsSelectors';
import { projectDataSelector } from '../../selectors/projectSelectors';
import useDragAndDropReOrder, {
  DragAndDropReOrderResult,
} from './useDragAndDropReOrder';

type SectionDragAndDropConfig = {
  sections: Element[];
  elementPath: ElementPath;
  sectionPath: ElementPath;
  onUpdateOrder?: (selectedSectionPath: ElementPath) => void;
};

type SectionDragAndDropResult = DragAndDropReOrderResult<Element> & {
  draggable: (index: number) => boolean;
};

const useSectionsDragAndDrop = ({
  elementPath,
  sections,
  sectionPath,
  onUpdateOrder,
}: SectionDragAndDropConfig): SectionDragAndDropResult => {
  const editorMode = useSelector(editorModeSelector);
  const project = useSelector(projectDataSelector);
  const hasSelectedElement = useSelector(hasSelectedElementPathSelector);
  const selectedSectionPath = useSelector(selectedSectionSelector);

  const [updateProperty] = useUpdateProperty(elementPath, project);

  const updateOrder = useCallback(
    (newOrder: any, selectedElementIndex: any) => {
      updateProperty(sectionPath, newOrder);
      if (onUpdateOrder) {
        onUpdateOrder([selectedElementIndex]);
      }
    },
    [updateProperty, sectionPath, onUpdateOrder],
  );

  const copySectionWidthFromDropped = useCallback(
    (droppedSection, existingSection) => {
      if (existingSection) {
        const sectionWidth = existingSection.sectionWidth;
        if (sectionWidth) {
          return set('sectionWidth', sectionWidth, droppedSection);
        }
      }

      return droppedSection;
    },
    [],
  );

  const { formatItem, draftItems, findItem, onSaveOrder } =
    useDragAndDropReOrder<Element>(
      sections,
      (section: any) => section.id,
      updateOrder,
      undefined,
      copySectionWidthFromDropped,
    );

  const draggable = useCallback(
    (index: number) =>
      editorMode && hasSelectedElement && first(selectedSectionPath) === index,
    [editorMode, hasSelectedElement, selectedSectionPath],
  );

  return {
    formatItem,
    draftItems,
    findItem,
    onSaveOrder,
    draggable,
  };
};

export default useSectionsDragAndDrop;
