import createReactComponent from '../createReactComponent';
export default createReactComponent('server-bolt', 'IconServerBolt', [
  [
    'path',
    {
      d: 'M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M15 20h-9a3 3 0 0 1 -3 -3v-2a3 3 0 0 1 3 -3h12', key: 'svg-1' },
  ],
  ['path', { d: 'M7 8v.01', key: 'svg-2' }],
  ['path', { d: 'M7 16v.01', key: 'svg-3' }],
  ['path', { d: 'M20 15l-2 3h3l-2 3', key: 'svg-4' }],
]);
