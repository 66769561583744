import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import { Calendar } from '@noloco/react-big-calendar';
import withDragAndDrop from '@noloco/react-big-calendar/lib/addons/dragAndDrop';
import { DATE } from '../../../constants/fieldFormats';
import { DataField } from '../../../models/DataTypeFields';
import { Project } from '../../../models/Project';
import useRouter from '../../../utils/hooks/useRouter';
import { Event } from './CollectionEvents';
import CalendarCurrentTimeIndicator from './calendar/CalendarCurrentTimeIndicator';
import CalendarDateCellWrapper from './calendar/CalendarDateCellWrapper';
import CalendarDayColumnWrapper from './calendar/CalendarDayColumnWrapper';
import CalendarEvent from './calendar/CalendarEvent';
import CalendarEventWrapper from './calendar/CalendarEventWrapper';
import CalendarLabel from './calendar/CalendarHeader';
import CalendarTimeSlotWrapper from './calendar/CalendarTimeSlotWrapper';
import CalendarToolbar from './calendar/CalendarToolbar';

const DnDCalendar = withDragAndDrop(Calendar);
const STEP = 15;

type CollectionCalendarProps = {
  canUpdateEventDates: boolean | undefined;
  className?: string;
  currentView: string;
  date: string;
  dateEndField: DataField;
  dateStartField: DataField;
  defaultViews: string[];
  draftEvents: Event[];
  enableDragAndDropEdit: boolean;
  enableShortcuts: boolean;
  endTime: number;
  loading: boolean;
  localizer: any;
  newButtonLink: string;
  newButtonVisible: boolean;
  project: Project;
  qsSuffix: string;
  Row: any;
  rowLink: string;
  setView: (view: string) => void;
  startTime: number;
  updateEvent: (
    event: any,
    start: any,
    end: any,
    _groupBy?: {
      field: any;
      color: string;
    },
  ) => void;
};

const CollectionCalendar = ({
  canUpdateEventDates,
  className,
  currentView,
  date,
  dateEndField,
  dateStartField,
  defaultViews,
  draftEvents,
  enableDragAndDropEdit,
  enableShortcuts,
  endTime,
  loading,
  localizer,
  newButtonLink,
  newButtonVisible,
  project,
  qsSuffix,
  Row,
  rowLink,
  setView,
  startTime,
  updateEvent,
}: CollectionCalendarProps) => {
  const { pushQueryParams, push } = useRouter();

  const [min, max] = useMemo(() => {
    let [min, max] = [new Date(1972, 0, 0, 0, 0), new Date(1972, 0, 0, 23, 59)];
    if (startTime) {
      min = new Date(1972, 0, 0, startTime, 0);
    }
    if (endTime) {
      max = new Date(1972, 0, 0, endTime, 0);
    }
    return [min, max];
  }, [startTime, endTime]);

  const components = useMemo(
    () => ({
      dateCellWrapper: CalendarDateCellWrapper,
      dayColumnWrapper: CalendarDayColumnWrapper,
      eventWrapper: (props: any) => (
        <CalendarEventWrapper
          {...props}
          Row={Row}
          rowLink={rowLink}
          project={project}
        />
      ),
      timeSlotWrapper: CalendarTimeSlotWrapper,
      event: CalendarEvent,
      day: {
        header: CalendarLabel,
      },
      week: {
        header: CalendarLabel,
      },
      work_week: {
        header: CalendarLabel,
      },
      month: {
        header: CalendarLabel,
      },
      toolbar: (props: any) => (
        <CalendarToolbar
          {...props}
          enableShortcuts={enableShortcuts}
          loading={loading}
        />
      ),
      currentTimeIndicator: CalendarCurrentTimeIndicator,
    }),
    [Row, loading, project, enableShortcuts, rowLink],
  );

  const handleEventDrop = useCallback(
    ({ event, start, end }: any) => updateEvent(event, start, end),
    [updateEvent],
  );

  const handleEventResize = useCallback(
    ({ event, start, end }: any) => updateEvent(event, start, end),
    [updateEvent],
  );

  const handleNavigationChange = useCallback(
    (nextDate: any) =>
      pushQueryParams({
        [`_date${qsSuffix}`]: nextDate.toISOString(),
      }),
    [pushQueryParams, qsSuffix],
  );

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const startDate = DateTime.fromJSDate(start).toISO({
        includeOffset: false,
      });
      const endDate = DateTime.fromJSDate(end).toISO({ includeOffset: false });

      const absoluteNewButtonLink = new URL(
        `https://${window.location.hostname}${newButtonLink}`,
      );
      absoluteNewButtonLink.searchParams.set(dateStartField.name, startDate);
      absoluteNewButtonLink.searchParams.set(dateEndField.name, endDate);

      push(
        `${absoluteNewButtonLink.pathname}?${absoluteNewButtonLink.searchParams}`,
      );
    },
    [newButtonLink, dateStartField, dateEndField, push],
  );

  return (
    <div className={classNames('h-full', className)}>
      <DnDCalendar
        allDayAccessor={() =>
          get(dateStartField, 'typeOptions.format') === DATE
        }
        components={components}
        date={date ? new Date(date) : undefined}
        draggableAccessor={() => enableDragAndDropEdit && canUpdateEventDates}
        endAccessor="end"
        events={draftEvents}
        loading={loading}
        localizer={localizer}
        max={max}
        min={min}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        onNavigate={handleNavigationChange}
        onSelectSlot={handleSelectSlot}
        onView={setView}
        resizableAccessor={(event: any) => canUpdateEventDates && event.hasEnd}
        selectable={newButtonVisible}
        startAccessor="start"
        step={STEP}
        view={currentView}
        views={defaultViews}
      />
    </div>
  );
};

export default CollectionCalendar;
