import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';

type SidebarCollapseButtonProps = {
  buildMode?: boolean;
  isOpen: boolean;
  onClick: (nextOpen: boolean) => void;
};

const SidebarCollapseButton = ({
  buildMode = false,
  isOpen,
  onClick,
}: SidebarCollapseButtonProps) => (
  <button
    className={classNames(
      'right-0 z-50 flex h-8 w-3.5 items-center justify-center shadow-md sm:hidden',
      {
        'absolute top-2 bg-slate-600 text-slate-200 opacity-75 hover:opacity-100':
          buildMode,
        'absolute top-6 bg-white text-gray-500 hover:text-gray-800 dark:bg-gray-600 dark:text-gray-100':
          !buildMode,
        'rounded-bl-lg rounded-tl-lg bg-opacity-75': isOpen,
        '-mr-3.5 rounded-br-lg rounded-tr-lg': !isOpen,
      },
    )}
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      onClick(!isOpen);
    }}
  >
    {isOpen ? <IconChevronLeft size={15} /> : <IconChevronRight size={15} />}
  </button>
);

export default SidebarCollapseButton;
