import React, { useContext, useMemo, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { Modal, Popover, getColorShade } from '@noloco/components';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import { DnDContext } from '@noloco/react-big-calendar/lib/addons/dragAndDrop/DnDContext';
import { ROWS } from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useRecordRowLink from '../../../../utils/hooks/useRecordRowLink';
import useRouter from '../../../../utils/hooks/useRouter';
import { getText } from '../../../../utils/lang';
import { stringifyPercent } from '../../../../utils/styles';
import CollectionEventPopoverContent from './CollectionEventPopoverContent';

const CalendarEventWrapper = ({
  className,
  children,
  event,
  onClick,
  theme,
  style,
  type,
  project,
  Row,
  rowLink,
}: any) => {
  const {
    query: { button, step },
    push,
  } = useRouter();
  const primaryColor = theme.brandColors.primary;
  const { draggable } = useContext<any>(DnDContext);
  const { sm: isSmScreen } = useBreakpoints();
  const [eventModalOpen, setEventModalOpen] = useState<boolean>(false);

  const recordRowLink = useRecordRowLink(
    rowLink,
    project,
    ROWS,
    event.rootPathname,
    get(event, 'record.uuid'),
  );

  const actionButtonIsOpen = useMemo(() => !!button && !!step, [button, step]);
  const [isDarkModeEnabled] = useDarkMode();

  const isBeingDragged = useMemo(
    () =>
      draggable.dragAndDropAction.interacting &&
      draggable.dragAndDropAction.event === event,
    [
      draggable.dragAndDropAction.event,
      draggable.dragAndDropAction.interacting,
      event,
    ],
  );

  const isTime = type === 'time';

  const pcStyle = useMemo(
    () =>
      style && isTime
        ? {
            ...style,
            top: stringifyPercent(style.top),
            left: stringifyPercent(Math.max(0, style.xOffset)),
            width: stringifyPercent(style.width),
            height: stringifyPercent(style.height),
          }
        : undefined,
    [isTime, style],
  );

  return (
    <>
      <Popover
        bg={
          isDarkModeEnabled
            ? darkModeColors.surfaces.elevation1LiteralColor
            : undefined
        }
        className="overflow-hidden"
        closeOnOutsideClick={!actionButtonIsOpen}
        content={
          <CollectionEventPopoverContent
            event={event}
            isDarkModeEnabled={isDarkModeEnabled}
            recordRowLink={recordRowLink as string}
            Row={Row}
          />
        }
        disabled={isSmScreen}
        p={{ x: 0, y: 0 }}
        placement="right"
      >
        <div
          className={classNames(
            className,
            'flex-wrap overflow-hidden rounded-lg border',
            {
              'absolute overflow-hidden': isTime,
              'pl-2': !isTime,
              'opacity-0':
                isBeingDragged &&
                (!className || !className.includes('drag-preview')),
              'opacity-75': className && className.includes('drag-preview'),
            },
            `bg-${getColorShade(event.color || primaryColor, 600)}`,
            `border-${getColorShade(event.color || primaryColor, 800)}`,
          )}
          style={pcStyle}
          onClick={() => {
            if (onClick) {
              onClick();
            }
            setEventModalOpen(true);
          }}
        >
          {children}
        </div>
      </Popover>
      {isSmScreen && eventModalOpen && (
        <Modal
          confirmText={getText('elements.COLLECTION.calendar.open')}
          onCancel={() => setEventModalOpen(false)}
          onClose={() => setEventModalOpen(false)}
          canConfirm={recordRowLink && event.rootPathname}
          onConfirm={() => {
            if (recordRowLink && event.rootPathname) {
              push(recordRowLink);
            }
          }}
          title={children}
        >
          <CollectionEventPopoverContent
            event={event}
            hideOpenButton={true}
            isDarkModeEnabled={isDarkModeEnabled}
            recordRowLink={recordRowLink as string}
            Row={Row}
          />
        </Modal>
      )}
    </>
  );
};

export default withTheme(CalendarEventWrapper);
