import createReactComponent from '../createReactComponent';
export default createReactComponent('map-pin-heart', 'IconMapPinHeart', [
  ['path', { d: 'M15 11a3 3 0 1 0 -3.973 2.839', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M11.76 21.47a1.991 1.991 0 0 1 -1.173 -.57l-4.244 -4.243a8 8 0 1 1 13.657 -5.588',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
      key: 'svg-2',
    },
  ],
]);
