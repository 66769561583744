import get from 'lodash/get';
import last from 'lodash/last';
import shortId from 'shortid';
import { NAVIGATE } from '@noloco/core/src/constants/actionTypes';
import { FORMULA } from '@noloco/core/src/constants/dataTypes';
import {
  ARRAY,
  COMBO,
  DATA_PROP,
  GROUP,
  KEY_MAP,
  NODE,
  RAW_DATA_PROP,
  STRING,
  VARIABLE,
} from '@noloco/core/src/constants/elementPropTypeTypes';
import * as elementTypes from '@noloco/core/src/constants/elements';
import baseElementConfigs from '@noloco/core/src/elements/baseElementConfig';
import { cloneCustomRules } from '@noloco/core/src/elements/utils/propTransformations';
import BaseElementConfig from '@noloco/core/src/models/BaseElementConfig';
import { Element } from '@noloco/core/src/models/Element';
import StringPropType from '@noloco/core/src/models/elementPropTypes/StringPropType';
import ChildElementPropType from '@noloco/core/src/models/elementPropTypes/comboProps/ChildElementPropType';

export const generateNewElementFromNodeShape = ({
  type,
  children = [],
  actions = [],
  props = {},
  ...rest
}: any) => {
  const newElement = generateNewElement(type, rest);
  const config = baseElementConfigs[type];

  // @ts-expect-error TS(7006): Parameter 'childShape' implicitly has an 'any' typ... Remove this comment to see the full error message
  const generatedChildren = children.map((childShape) =>
    generateNewElementFromNodeShape(childShape),
  );
  return {
    ...newElement,
    children: [
      ...(!config.content || generatedChildren.length === 0
        ? newElement.children || []
        : []),
      ...generatedChildren,
    ],
    actions,
    props: {
      ...newElement.props,
      ...Object.entries(config.props || {}).reduce(
        (props, [propKey, definition]): any => {
          if (
            (definition as any).type === NODE &&
            props[propKey] &&
            Array.isArray(props[propKey])
          ) {
            const propValue = props[propKey].map((propChild: any): any =>
              generateNewElementFromNodeShape(propChild),
            );
            return {
              ...props,
              [propKey]: propValue,
            };
          }

          return props;
        },
        props,
      ),
    },
  };
};

export const generateNewElement = (
  elementName: any,
  { elementId, static: isStatic, children = [], ...rest }: any = {},
) => {
  const config = baseElementConfigs[elementName];
  const newId = elementId || shortId.generate();
  return {
    id: newId,
    type: elementName,
    props: Object.entries(config.props || {}).reduce(
      (props, [propKey, definition]): any => {
        if (
          (definition as any).type === NODE &&
          (definition as any).defaultNodeShape
        ) {
          const propValue = [
            generateNewElementFromNodeShape(
              (definition as any).defaultNodeShape,
            ),
          ];
          return {
            ...props,
            [propKey]: propValue,
          };
        }

        return props;
      },
      { ...config.defaultProps, ...rest },
    ),
    children: [
      ...(config.content && children.length === 0
        ? [
            {
              id: shortId.generate(),
              type: elementTypes.CONTENT,
              props: {
                items: [{ text: '' }],
              },
            },
          ]
        : []),
      ...children,
    ],
    ...(isStatic ? { static: true } : {}),
  };
};

const cloneDataItem = (value: any, idMap: any) => {
  const newId = idMap[value.id] || value.id;
  return {
    ...value,
    id: newId,
  };
};

const cloneStringPropType = (dataArray = [], idMap = {}) => {
  if (!Array.isArray(dataArray)) {
    return dataArray;
  }
  return dataArray.map((dataItem): any => {
    if ((dataItem as any).data) {
      if ((dataItem as any).data.dataType === FORMULA) {
        return {
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          ...dataItem,
          formula: cloneStringPropType((dataItem as any).formula, idMap),
        };
      }

      return {
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        ...dataItem,
        data: cloneDataItem((dataItem as any).data, idMap),
      };
    }
    return dataItem;
  });
};
const cloneAction = (action: any, idMap: any) => {
  const { fields = {}, type } = action;
  if (type === NAVIGATE) {
    return action;
  }

  return {
    ...action,
    fields: Object.entries(fields).reduce(
      (fieldMap, [fieldKey, fieldValue]) => ({
        ...fieldMap,
        [fieldKey]: cloneDataItem(fieldValue, idMap),
      }),
      {},
    ),
  };
};

const comboPropTransformation = (shape: any, value: any, idMap: any) =>
  Object.entries(shape).reduce((valueAcc, [propName, propDef]): any => {
    if (!value[propName]) {
      return valueAcc;
    }

    return {
      ...valueAcc,
      [propName]: getPropTransformation(propDef, value[propName], idMap),
    };
  }, value);

const getPropTransformation = (propDefinition: any, value: any, idMap: any) => {
  const { shape = {}, type, propTransformation } = propDefinition;
  if (!value) {
    return value;
  }

  if (propTransformation) {
    return propTransformation(value, idMap, { cloneDataItem });
  }

  if (type === STRING) {
    return cloneStringPropType(value, idMap);
  }

  if (type === DATA_PROP || type === RAW_DATA_PROP) {
    return cloneDataItem(value, idMap);
  }

  if (type === ARRAY) {
    return value.map((arrayVal: any) =>
      Object.entries(shape).reduce(
        (valueAcc, [propName, propDef]) => ({
          ...valueAcc,
          [propName]: getPropTransformation(propDef, arrayVal[propName], idMap),
        }),
        arrayVal,
      ),
    );
  }

  if (type === KEY_MAP) {
    return Object.entries(value).reduce(
      (acc, [key, nestedValue]): any => ({
        ...acc,
        [key]: comboPropTransformation(shape, nestedValue, idMap),
      }),
      {},
    );
  }

  if (type === COMBO && propDefinition instanceof ChildElementPropType) {
    return cloneElement(value, idMap);
  }

  if (type === COMBO || type === GROUP) {
    return comboPropTransformation(shape, value, idMap);
  }

  if (type === VARIABLE) {
    return getPropTransformation(
      {
        type: COMBO,
        shape: { label: new StringPropType(), value: propDefinition.propType },
      },
      value,
      idMap,
    );
  }

  if (type === NODE && Array.isArray(value)) {
    return value.map((node) => cloneElement(node, idMap));
  }

  return value;
};

const getConditionsTransformations = (conditions: any, idMap: any) =>
  Object.entries(conditions).reduce(
    (newConditions, [conditionKey, condition]) => ({
      ...newConditions,
      [conditionKey]: {
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        ...condition,
        conditions: (condition as any).conditions.map((orConditionGroup: any) =>
          orConditionGroup.map((andCondition: any) => ({
            field: getPropTransformation(
              { type: DATA_PROP },
              andCondition.field,
              idMap,
            ),
            operator: andCondition.operator,
            value: getPropTransformation(
              { type: STRING },
              andCondition.value,
              idMap,
            ),
          })),
        ),
      },
    }),
    conditions,
  );

export const transformProps = (
  propsConfig: any,
  elementProps: any,
  idMap = {},
) =>
  Object.entries(propsConfig).reduce((props, [propKey, propDefinition]) => {
    if ((propDefinition as any).type === GROUP) {
      return getPropTransformation(propDefinition, props, idMap);
    }

    return {
      ...props,
      [propKey]: getPropTransformation(propDefinition, props[propKey], idMap),
    };
  }, elementProps || {});

export const cloneElement = (element: any, idMap: any = {}): Element => {
  const newId = shortId.generate();

  idMap[element.id] = newId;
  idMap[`${element.id}:VIEW`] = `${newId}:VIEW`;
  idMap[`${element.id}:RECORD`] = `${newId}:RECORD`;

  const elementConfig =
    baseElementConfigs[element.type] || new BaseElementConfig();

  return elementConfig.cloneTransformation({
    ...element,
    id: newId,
    children: (element.children || []).map((child: any) =>
      cloneElement(child, idMap),
    ),
    actions: (element.actions || []).map((action: any) =>
      cloneAction(action, idMap),
    ),
    container: element.container ? idMap[element.container] : undefined,
    props: transformProps(elementConfig.props, element.props, idMap),
    ...(element.conditions
      ? { conditions: getConditionsTransformations(element.conditions, idMap) }
      : {}),
    visibilityRules: element.visibilityRules
      ? {
          ...element.visibilityRules,
          customRules: cloneCustomRules(
            element.visibilityRules.customRules,
            idMap,
          ),
        }
      : undefined,
  });
};

export const getSelectedElementConfig = (
  projectElements: any,
  selectedPath: any,
) => {
  // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
  const isNodeProp = isNaN(last(selectedPath));
  if (isNodeProp) {
    return new BaseElementConfig();
  }
  const selectedElement = get(projectElements, selectedPath);
  const selectedConfig =
    selectedElement && baseElementConfigs[selectedElement.type];
  return selectedConfig || new BaseElementConfig();
};
