import createReactComponent from '../createReactComponent';
export default createReactComponent('adjustments-pin', 'IconAdjustmentsPin', [
  ['path', { d: 'M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M6 4v4', key: 'svg-1' }],
  ['path', { d: 'M6 12v8', key: 'svg-2' }],
  ['path', { d: 'M13.071 14.31a2 2 0 1 0 -1.071 3.69', key: 'svg-3' }],
  ['path', { d: 'M12 4v10', key: 'svg-4' }],
  ['path', { d: 'M12 18v2', key: 'svg-5' }],
  ['path', { d: 'M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-6' }],
  ['path', { d: 'M18 4v1', key: 'svg-7' }],
  ['path', { d: 'M18 9v2.5', key: 'svg-8' }],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-9',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-10' }],
]);
