import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { DYNAMIC } from '../constants/imageTypes';

const placeholder =
  'https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_480_1_5MG.mp4';

type Props = {
  children?: React.ReactElement;
  className?: string;
  image?: {
    src?: string;
  };
  type?: any; // TODO: PropTypes.oneOf([UPLOAD, DYNAMIC])
  w?: number | string;
};

const Video = forwardRef<any, Props>(
  (
    {
      // @ts-expect-error TS(2339): Property 'autoPlay' does not exist on type 'Props'... Remove this comment to see the full error message
      autoPlay,
      className,
      children: __,
      // @ts-expect-error TS(2339): Property 'controls' does not exist on type 'Props'... Remove this comment to see the full error message
      controls,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Prop... Remove this comment to see the full error message
      editorMode,
      // @ts-expect-error TS(2339): Property 'loop' does not exist on type 'Props'.
      loop,
      // @ts-expect-error TS(2339): Property 'muted' does not exist on type 'Props'.
      muted,
      // @ts-expect-error TS(2339): Property 'poster' does not exist on type 'Props'.
      poster,
      // @ts-expect-error TS(2339): Property 'video' does not exist on type 'Props'.
      video,
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'Props'.
      style,
      // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Props'.
      name,
      // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'Props'.
      loading,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
      onClick,
    },
    ref,
  ) => {
    const { src } = video || {};
    const { src: posterSrc } = poster || {};
    return (
      <video
        autoPlay={autoPlay}
        controls={controls}
        className={classNames(className, {
          'animate-pulse bg-gray-400':
            loading && !editorMode && (!src || src === placeholder),
        })}
        loop={loop}
        muted={editorMode || muted}
        onClick={onClick}
        ref={ref}
        style={style}
        src={src || placeholder}
        poster={posterSrc}
        title={name}
      />
    );
  },
);

Video.defaultProps = {
  children: undefined,
  className: '',
  image: {},
  type: DYNAMIC,
};

export default Video;
