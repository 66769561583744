import { useCallback } from 'react';
import { GraphQLError } from 'graphql';
import get from 'lodash/get';
import {
  AlertCustomOptions,
  AlertType,
  positions,
  useAlert as useBaseAlert,
} from 'react-alert';
import {
  DANGER,
  INFO,
  PRIMARY,
  Variant,
  WARNING,
} from '@noloco/components/src/constants/variants';
import { NotificationIconType } from '../../models/Notification';

export const DEFAULT_TIMEOUT_MS = 5000;

export const useAlert = (type: Variant | 'LOADING') => {
  const alert = useBaseAlert();

  return useCallback(
    (
      message: string,
      text?: string,
      options: AlertCustomOptions & {
        icon?: string;
        clearable?: boolean;
        iconType?: NotificationIconType;
        onClick?: () => void;
        onDismiss?: () => void;
        text?: string;
        timeout?: number;
      } = {},
    ) =>
      alert.show(message, {
        type: type as AlertType,
        text,
        clearable: true,
        timeout: DEFAULT_TIMEOUT_MS,
        position: positions.TOP_RIGHT,
        ...options,
      }),
    [alert, type],
  );
};

export const useRemoveAllAlerts = () => {
  const alert = useBaseAlert();

  return useCallback(() => alert.removeAll(), [alert]);
};

export const useErrorAlert = () => useAlert(DANGER);
export const useWarningAlert = () => useAlert(WARNING);
export const useInfoAlert = () => useAlert(INFO);
export const useSuccessAlert = () => useAlert(PRIMARY);
export const useLoadingAlert = () => useAlert('LOADING');

export const getTextFromError = (error: any) => {
  if (!error) {
    return {};
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const errors = error.graphQLErrors as GraphQLError[];
    const gqlError = errors[0];
    const title = gqlError.extensions?.title;
    return {
      title,
      message: errors.map((er) => er.message).join('\n'),
    };
  }

  const networkError = get(error, 'networkError.result.message');
  if (networkError) {
    return { message: networkError };
  }

  if (error.message) {
    return { message: error.message };
  }

  if (Array.isArray(error)) {
    return {
      message: error
        .filter((er: any) => !!er.message)
        .map((er: any) => er.message)
        .join('\n'),
    };
  }

  return { message: String(error) };
};

export const useGraphQlErrorAlert = () => {
  const errorAlert = useAlert(DANGER);
  return useCallback(
    (defaultTitle: any, error: any, options = {}) => {
      const { title, message } = getTextFromError(error);
      return errorAlert(title || defaultTitle, message, options);
    },
    [errorAlert],
  );
};
