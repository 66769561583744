import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-off', 'IconFlagOff', [
  ['path', { d: 'M5 5v16', key: 'svg-0' }],
  ['path', { d: 'M19 5v9', key: 'svg-1' }],
  [
    'path',
    { d: 'M7.641 3.645a5 5 0 0 1 4.359 1.355a5 5 0 0 0 7 0', key: 'svg-2' },
  ],
  [
    'path',
    {
      d: 'M5 14a5 5 0 0 1 7 0a4.984 4.984 0 0 0 3.437 1.429m3.019 -.966c.19 -.14 .371 -.294 .544 -.463',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
