import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-foursquare', 'IconBrandFoursquare', [
  [
    'path',
    {
      d: 'M7 3h10c.644 0 1.11 .696 .978 1.33l-1.984 9.859a1.014 1.014 0 0 1 -1 .811h-2.254c-.308 0 -.6 .141 -.793 .382l-4.144 5.25c-.599 .752 -1.809 .331 -1.809 -.632v-16c0 -.564 .44 -1 1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 9l5 0', key: 'svg-1' }],
]);
