import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-meta', 'IconBrandMeta', [
  [
    'path',
    {
      d: 'M12 10.174c1.766 -2.784 3.315 -4.174 4.648 -4.174c2 0 3.263 2.213 4 5.217c.704 2.869 .5 6.783 -2 6.783c-1.114 0 -2.648 -1.565 -4.148 -3.652a27.627 27.627 0 0 1 -2.5 -4.174z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 10.174c-1.766 -2.784 -3.315 -4.174 -4.648 -4.174c-2 0 -3.263 2.213 -4 5.217c-.704 2.869 -.5 6.783 2 6.783c1.114 0 2.648 -1.565 4.148 -3.652c1 -1.391 1.833 -2.783 2.5 -4.174z',
      key: 'svg-1',
    },
  ],
]);
