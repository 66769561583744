import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DataType } from '../../models/DataTypes';
import { clearFieldValues } from '../../reducers/formFields';
import useRouter from './useRouter';

export const useClearFormFieldValuesOnNavigateAway = (dataType: DataType) => {
  const dispatch = useDispatch();
  const { pathname } = useRouter();

  useEffect(() => {
    return () => {
      if (pathname !== document.location.pathname) {
        dispatch(
          clearFieldValues({
            dataTypeName: dataType.name,
            id: 'NEW',
            fieldNames: dataType.fields.map((field: any) => field.name),
            updateTime: Date.now(),
          }),
        );
      }
    };
  }, [dataType.fields, dataType.name, dispatch, pathname]);
};
