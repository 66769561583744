import createReactComponent from '../createReactComponent';
export default createReactComponent('diamonds', 'IconDiamonds', [
  [
    'path',
    {
      d: 'M10.831 20.413l-5.375 -6.91c-.608 -.783 -.608 -2.223 0 -3l5.375 -6.911a1.457 1.457 0 0 1 2.338 0l5.375 6.91c.608 .783 .608 2.223 0 3l-5.375 6.911a1.457 1.457 0 0 1 -2.338 0z',
      key: 'svg-0',
    },
  ],
]);
