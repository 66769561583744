import createReactComponent from '../createReactComponent';
export default createReactComponent('bell-down', 'IconBellDown', [
  [
    'path',
    {
      d: 'M12.5 17h-8.5a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6a2 2 0 1 1 4 0a7 7 0 0 1 4 6v1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 17v1a3 3 0 0 0 3.518 2.955', key: 'svg-1' }],
  ['path', { d: 'M19 16v6', key: 'svg-2' }],
  ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-3' }],
]);
