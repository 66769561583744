import createReactComponent from '../createReactComponent';
export default createReactComponent('mail-x', 'IconMailX', [
  [
    'path',
    {
      d: 'M13.5 19h-8.5a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 7l9 6l9 -6', key: 'svg-1' }],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-2' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-3' }],
]);
