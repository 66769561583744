import React, { useCallback } from 'react';
import classNames from 'classnames';
import { CENTER, END, START } from '../../../constants/align';
import { DETAILS } from '../../../constants/elements';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useRouter from '../../../utils/hooks/useRouter';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { getText } from '../../../utils/lang';
import { RECORD_SCOPE } from '../../../utils/scope';
import ActionButton from './ActionButton';
import RecordEmptyState from './RecordEmptyState';

const RecordActionButtonSection = ({
  backLink,
  className,
  dataType,
  elementPath,
  editorMode,
  record,
  rootPathname,
  project,
  recordScope,
  ...rest
}: any) => {
  const { push } = useRouter();
  const { align, actionButtons = [] } = useSectionScopeVariables(
    DETAILS,
    rest,
    project,
    elementPath,
    recordScope,
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  const handleDeleteRecord = useCallback(
    (__, onNext) => {
      if (backLink && backLink.enabled) {
        push(backLink.to);
      } else {
        push(rootPathname);
      }
      onNext();
    },
    [backLink, push, rootPathname],
  );

  if (actionButtons.length === 0 && editorMode) {
    return (
      <RecordEmptyState title={getText('elements.ACTION_BUTTONS.configure')} />
    );
  }

  return (
    <div
      className={classNames(
        className,
        'flex flex-wrap items-end gap-2 sm:ml-0 sm:w-full sm:pl-0',
        {
          'mr-auto justify-start': align === START || !align,
          'mx-auto justify-center': align === CENTER,
          'ml-auto justify-end': align === END,
        },
      )}
    >
      {actionButtons.map((actionButton: any, actionButtonIndex: any) => (
        <ActionButton
          actionButton={actionButton}
          dataType={dataType}
          editorMode={editorMode}
          index={actionButtonIndex}
          key={actionButtonIndex.id}
          record={record}
          recordScope={recordScope}
          onDeleteRecord={handleDeleteRecord}
          project={project}
        />
      ))}
    </div>
  );
};

RecordActionButtonSection.defaultProps = {};

export default RecordActionButtonSection;
