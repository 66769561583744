import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'device-tablet-check',
  'IconDeviceTabletCheck',
  [
    [
      'path',
      {
        d: 'M11.5 21h-5.5a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v9.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12.314 16.05a1 1 0 0 0 -1.042 1.635', key: 'svg-1' }],
    ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-2' }],
  ],
);
