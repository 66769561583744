import { useEffect } from 'react';

type SegmentEvent = {
  anonymousId: string;
  userId: string;
  event: string;
  type: 'track' | 'page' | 'group' | 'identify';
  context: Record<string, any>;
  integrations: Record<string, boolean | undefined>;
  properties: Record<string, any>;
  timestamp: Date;
};

type SegmentPayload = {
  obj: SegmentEvent;
  opts: Record<string, any>;
  integrations: Record<string, boolean>;
  raw: SegmentEvent;
};

type SegmentMiddleWare = (props: {
  payload: SegmentPayload;
  next: (payload: SegmentPayload) => void;
  integrations: Record<string, boolean | undefined>;
}) => void;

let adminDestinationsMiddleware: SegmentMiddleWare | undefined;
let nonAdminDestinationsMiddleware: SegmentMiddleWare | undefined;

const ADMIN_DESTINATION_NAMES = [
  'Fullstory',
  'Fullstory Cloud Mode (Actions)',
  'MailChimp',
  'Customer.io',
  'Customer.io (Actions)',
  'HubSpot Cloud Mode (Actions)',
  'SatisMeter',
  'Intercom Cloud Mode (Actions)',
];

type TrueFalse = 'true' | 'false';

const ADMIN_DESTINATION_CONFIG = [true, false].reduce(
  (acc, enabled) => {
    acc[String(enabled) as TrueFalse] = ADMIN_DESTINATION_NAMES.reduce(
      (destinations, destination) => {
        destinations[destination] = enabled;
        return destinations;
      },
      {} as Record<string, boolean>,
    );

    return acc;
  },
  {} as Record<TrueFalse, Record<string, boolean>>,
);

const getIntegrationsWithDestinations = (
  integrations: Record<string, boolean>,
  adminEnabled: boolean,
) => ({
  ...integrations,
  All: adminEnabled ? true : undefined,
  ...ADMIN_DESTINATION_CONFIG[String(adminEnabled) as TrueFalse],
});

export const useSegmentAdminDestinationsMiddleware = (enabled: boolean) => {
  useEffect(() => {
    if (enabled && window.analytics && !adminDestinationsMiddleware) {
      adminDestinationsMiddleware = ({ payload, next }) => {
        const userId = payload.obj.userId;

        // Only enable these integrations if the userID is an Admin ID
        // Sometimes they slip through
        if (userId && !String(userId).includes(':')) {
          payload.obj.integrations = getIntegrationsWithDestinations(
            payload.integrations,
            true,
          );
        }

        next(payload);
      };

      window.analytics.addSourceMiddleware(adminDestinationsMiddleware);
    }
  }, [enabled]);
};

export const addSegmentNonAdminDestinationsMiddleware = () => {
  if (window.analytics && !nonAdminDestinationsMiddleware) {
    nonAdminDestinationsMiddleware = ({ payload, next }) => {
      payload.obj.integrations = getIntegrationsWithDestinations(
        payload.integrations,
        false,
      );

      next(payload);
    };

    window.analytics.addSourceMiddleware(nonAdminDestinationsMiddleware);
  }
};
