import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import {
  LG,
  MD,
  SM,
  ShirtSize,
} from '@noloco/components/src/constants/tShirtSizes';

type CircularProgressProps = {
  children?: any;
  className?: string;
  hideValue?: boolean;
  progress?: number;
  maxProgress?: number;
  size?: ShirtSize;
};

const sizes = {
  [SM]: {
    r: 16,
    sizeClassName: 'w-12 h-12',
    strokeWidth: 3,
    textClassName: 'text-xs',
  },
  [MD]: {
    r: 20,
    sizeClassName: 'w-16 h-16',
    strokeWidth: 4,
    textClassName: 'font-bold text-lg',
  },
  [LG]: {
    r: 48,
    sizeClassName: 'w-40 h-40',
    strokeWidth: 8,
    textClassName: 'font-bold text-2xl',
  },
};

const CircularProgress = forwardRef<any, CircularProgressProps>(
  (
    {
      children,
      className,
      hideValue,
      progress = 0,
      maxProgress = 100,
      size = MD,
    },
    ref,
  ) => {
    const { r, sizeClassName, strokeWidth, textClassName } = sizes[size];

    const circumference = useMemo(() => r * 2 * Math.PI, [r]);
    const dashOffset = useMemo(
      () => circumference - (progress / maxProgress) * circumference,
      [circumference, progress, maxProgress],
    );

    const center = useMemo(() => r + strokeWidth * 3, [r, strokeWidth]);

    return (
      <div
        className={classNames(
          'flex items-center justify-center overflow-hidden rounded-full',
          className,
        )}
        ref={ref}
      >
        <svg className={classNames(sizeClassName, 'flex-shrink-0')}>
          <circle
            className="text-white"
            strokeWidth={strokeWidth}
            stroke="currentColor"
            fill="transparent"
            r={r}
            cx={center}
            cy={center}
          />
          <circle
            className="transition-all ease-in-out"
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            transform={`rotate(-90 ${center} ${center})`}
            r={r}
            cx={center}
            cy={center}
          />
        </svg>
        {!hideValue && (
          <span className={`absolute ${textClassName}`}>
            {children || progress}
          </span>
        )}
      </div>
    );
  },
);

export default CircularProgress;
