const cappedMemoize = (
  fn: (...args: any) => any,
  { maxKeys = 10, getKey = JSON.stringify } = {},
): any | undefined => {
  const cache: Record<string, any> = {};

  const set = (key: string, value: any): void => {
    const keys = Object.keys(cache);
    if (keys.length >= maxKeys) {
      delete cache[keys[0]];
    }

    cache[key] = value;
  };

  return (...args: any): any | undefined => {
    const key = getKey(args);
    if (key in cache) {
      return cache[key];
    }

    const value = fn(...args);
    set(key, value);
    return value;
  };
};

export default cappedMemoize;
