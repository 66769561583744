import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'rotate-clockwise-2',
  'IconRotateClockwise2',
  [
    ['path', { d: 'M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5', key: 'svg-0' }],
    ['path', { d: 'M5.63 7.16l0 .01', key: 'svg-1' }],
    ['path', { d: 'M4.06 11l0 .01', key: 'svg-2' }],
    ['path', { d: 'M4.63 15.1l0 .01', key: 'svg-3' }],
    ['path', { d: 'M7.16 18.37l0 .01', key: 'svg-4' }],
    ['path', { d: 'M11 19.94l0 .01', key: 'svg-5' }],
  ],
);
