import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-yahoo', 'IconBrandYahoo', [
  ['path', { d: 'M3 6l5 0', key: 'svg-0' }],
  ['path', { d: 'M7 18l7 0', key: 'svg-1' }],
  ['path', { d: 'M4.5 6l5.5 7v5', key: 'svg-2' }],
  ['path', { d: 'M10 13l6 -5', key: 'svg-3' }],
  ['path', { d: 'M12.5 8l5 0', key: 'svg-4' }],
  ['path', { d: 'M20 11l0 4', key: 'svg-5' }],
  ['path', { d: 'M20 18l0 .01', key: 'svg-6' }],
]);
