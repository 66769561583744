import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'building-pavilion',
  'IconBuildingPavilion',
  [
    ['path', { d: 'M3 21h7v-3a2 2 0 0 1 4 0v3h7', key: 'svg-0' }],
    ['path', { d: 'M6 21l0 -9', key: 'svg-1' }],
    ['path', { d: 'M18 21l0 -9', key: 'svg-2' }],
    [
      'path',
      {
        d: 'M6 12h12a3 3 0 0 0 3 -3a9 8 0 0 1 -9 -6a9 8 0 0 1 -9 6a3 3 0 0 0 3 3',
        key: 'svg-3',
      },
    ],
  ],
);
