import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { IconPower } from '@tabler/icons-react';
import first from 'lodash/first';
import { Loader, Modal, Switch } from '@noloco/components';
import { DataSource, DataType } from '@noloco/core/src/models/DataTypes';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import {
  ENABLE_DATA_TYPES,
  GET_DISABLED_DATA_TYPES,
  GET_FULL_DATA_SOURCE,
} from '../../../queries/project';
import { useAddDataTypes } from '../../../utils/hooks/useUpdateDataTypes';

type Props = {
  dataSource: DataSource;
  projectName: string;
  onClose: () => void;
};

const LANG_KEY = 'data.dataTypes.enable';

const EnableDataTypeModal = ({ dataSource, projectName, onClose }: Props) => {
  const addDataTypes = useAddDataTypes();
  const { push } = useRouter();
  const [loading, setLoading] = useState(false);
  const [enableDataTypeIds, setEnableDataTypeIds] = useState<number[]>([]);

  const [enableDataTypes] = useMutation(ENABLE_DATA_TYPES);
  const { data: { disabledDataTypes = [] } = {}, loading: dataTypesLoading } =
    useQuery(GET_DISABLED_DATA_TYPES, {
      fetchPolicy: 'no-cache',
      variables: {
        projectId: projectName,
        dataSourceId: dataSource.id,
      },
    });
  const [getDataSource] = usePromiseQuery(GET_FULL_DATA_SOURCE, {
    fetchPolicy: 'no-cache',
  });

  const getEnabledDataType = useCallback(
    () =>
      getDataSource({
        variables: { projectId: projectName, id: dataSource.id },
      }).then(({ data }: any) => {
        if (data && data.dataSource) {
          const enabledDataTypes: DataType[] = data.dataSource.types.filter(
            (dataType: DataType) => enableDataTypeIds.includes(dataType.id),
          );

          addDataTypes(enabledDataTypes);
          push(`/_/data/internal/${first(enabledDataTypes)?.name}`);
          onClose();
        }
      }),
    [
      getDataSource,
      projectName,
      dataSource.id,
      addDataTypes,
      push,
      onClose,
      enableDataTypeIds,
    ],
  );

  const handleEnableDataType = useCallback(() => {
    if (enableDataTypeIds.length === 0) {
      return onClose();
    }

    setLoading(true);
    enableDataTypes({
      variables: {
        projectName,
        dataTypeIds: enableDataTypeIds,
      },
    })
      .then(({ data }) => {
        if (data.enableDataTypes) {
          return getEnabledDataType();
        }
      })
      .finally(() => setLoading(false));
  }, [
    onClose,
    enableDataTypes,
    projectName,
    enableDataTypeIds,
    getEnabledDataType,
  ]);

  return (
    <Modal
      icon={dataTypesLoading ? <Loader size="sm" /> : <IconPower size={18} />}
      title={getText(LANG_KEY, 'title')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleEnableDataType}
      confirmText={loading ? <Loader size="sm" /> : getText(LANG_KEY, 'done')}
      confirmDisabled={loading || dataTypesLoading}
      variant="danger"
    >
      {!dataTypesLoading && disabledDataTypes.length === 0 && (
        <p className="text-gray-700">
          {getText(LANG_KEY, 'noCollectionFound')}
        </p>
      )}
      {!dataTypesLoading && disabledDataTypes.length > 0 && (
        <div>
          <p className="mb-2 text-gray-700">{getText(LANG_KEY, 'subTitle')}</p>
          <p className="mb-2 text-gray-700">
            {getText(LANG_KEY, 'toggleCollection')}
          </p>
          {disabledDataTypes.map(
            (disabledDataType: { id: number; display: string }) => (
              <div key={disabledDataType.id} className="flex items-center">
                <Switch
                  size="sm"
                  className="my-2 mr-4"
                  onChange={(value: boolean) => {
                    value
                      ? setEnableDataTypeIds([
                          ...enableDataTypeIds,
                          disabledDataType.id,
                        ])
                      : setEnableDataTypeIds(
                          enableDataTypeIds.filter(
                            (id) => id !== disabledDataType.id,
                          ),
                        );
                  }}
                />
                <span>{disabledDataType.display}</span>
              </div>
            ),
          )}
        </div>
      )}
    </Modal>
  );
};

export default EnableDataTypeModal;
