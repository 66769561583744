import createReactComponent from '../createReactComponent';
export default createReactComponent('clock-heart', 'IconClockHeart', [
  ['path', { d: 'M20.956 11.107a9 9 0 1 0 -9.579 9.871', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 7v5l.5 .5', key: 'svg-2' }],
]);
