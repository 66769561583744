import createReactComponent from '../createReactComponent';
export default createReactComponent('jetpack', 'IconJetpack', [
  ['path', { d: 'M10 6a3 3 0 1 0 -6 0v7h6v-7z', key: 'svg-0' }],
  ['path', { d: 'M14 13h6v-7a3 3 0 0 0 -6 0v7z', key: 'svg-1' }],
  [
    'path',
    { d: 'M5 16c0 2.333 .667 4 2 5c1.333 -1 2 -2.667 2 -5', key: 'svg-2' },
  ],
  [
    'path',
    { d: 'M15 16c0 2.333 .667 4 2 5c1.333 -1 2 -2.667 2 -5', key: 'svg-3' },
  ],
  ['path', { d: 'M10 8h4', key: 'svg-4' }],
  ['path', { d: 'M10 11h4', key: 'svg-5' }],
]);
